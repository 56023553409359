import React, {useState} from 'react'
import {
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Card,
    CardFooter,
    CardBody,
    CardHeader,
    Label,
} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'
import FormulaInput from '../../../../common/FormulaInput'

export default (props) => {
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'CreateVariable',
        description: props.element.data.opts.description || '',
        variables: props.element.data.opts.variables || [],
        submitted: props.element.data.opts.submitted || true,
    })

    const renderVariableRow = ({
        id,
        name = null,
        type = 'Text',
        expression = {
            text: '',
            plainText: '',
            mentions: [],
            type: 'formula',
            map: [],
        },
    }) => {
        const idx = parameters.variables.findIndex((m) => m.id === id) + 1

        return (
            <Row className="mb-4">
                <Col xs="12">
                    <Card>
                        <CardHeader className="m-0 p-0">
                            <h5 className="m-0 p-0 pl-4 pt-4">
                                Variable #{idx}
                            </h5>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Row>
                                <Col xs="12">
                                    <hr />
                                </Col>
                                <Col xs="6" className="mb-2">
                                    <TextInput
                                        onValueChange={(v) => {
                                            let idx =
                                                parameters.variables.findIndex(
                                                    (m) => m.id === id
                                                )
                                            parameters.variables[idx].name =
                                                v.replace(/\s/g, '')
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Variable Name"
                                        existing={props.variables
                                            .filter((v) => v.id !== id)
                                            .map((v) => v.name)}
                                        initial={name || ''}
                                        disabled={false}
                                    />
                                </Col>
                                <Col xs="6" className="mb-2">
                                    <SelectInput
                                        onChange={(v) => {
                                            let idx =
                                                parameters.variables.findIndex(
                                                    (m) => m.id === id
                                                )
                                            parameters.variables[idx].type = v
                                            setParameters({...parameters})
                                        }}
                                        fieldName="Variable Type"
                                        default={
                                            type
                                                ? {
                                                      label: props.fieldTypes.find(
                                                          (fType) =>
                                                              fType.type ===
                                                              type
                                                      ).label,
                                                      value: type,
                                                  }
                                                : null
                                        }
                                        options={[
                                            {
                                                label: 'Text',
                                                value: 'Text',
                                            },
                                            {
                                                label: 'Timestamp',
                                                value: 'Timestamp',
                                            },
                                            {
                                                label: 'Number',
                                                value: 'Number',
                                            },
                                            {
                                                label: 'Boolean',
                                                value: 'Boolean',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs="12">
                                    <Label>Set default value (optional):</Label>
                                    <br />
                                    <FormulaInput
                                        variables={props.variables.filter(
                                            (v) =>
                                                !v.multi &&
                                                v.transformId !==
                                                    props.element.id
                                        )}
                                        formulas={props.formulas}
                                        default={expression || null}
                                        type="formula"
                                        onChange={(expression) => {
                                            let idx =
                                                parameters.variables.findIndex(
                                                    (m) => m.id === id
                                                )
                                            parameters.variables[
                                                idx
                                            ].expression = expression
                                            parameters.variables[
                                                idx
                                            ].expression.type = 'formula'
                                            setParameters({...parameters})
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button
                                size="xs"
                                onClick={() => {
                                    parameters.variables =
                                        parameters.variables.filter(
                                            (m) => m.id !== id
                                        )
                                    setParameters({...parameters})
                                }}
                                color="danger">
                                Remove
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }

    const submit = () => {
        props.onChange(parameters, parameters.variables)
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>

                        <FormGroup className="text-right">
                            <Button
                                size="xs"
                                onClick={() => {
                                    const id = uuidv4()
                                    parameters.variables.push({
                                        id,
                                        variableId: id,
                                        type: 'Text',
                                        name: '',
                                        transformId: props.element.id,
                                        multi: false,
                                    })
                                    setParameters({...parameters})
                                }}>
                                Add variable
                            </Button>
                        </FormGroup>

                        <FormGroup>
                            {parameters.variables.map((m) =>
                                renderVariableRow(m)
                            )}
                        </FormGroup>
                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
