import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import CRUD from 'SDK/ui/crud'

import * as API from 'SDK/api'

export default class ScrapHistoryModule extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'ScrapHistoryModule'
    requiredOutputs = ['Part Count', 'In-Cycle', 'Reject']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            devices: [],
            device: null,
            data: [],
            value: 0,
            start: moment().startOf('day'),
            end: moment().endOf('day'),
        }
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: moment(date).endOf('day'),
        })
    }

    async fetchRunTimes(deviceId) {
        this.setState({loading: true})
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: deviceId || this.state.device.deviceId,
                    name: {$in: ['In-Cycle', 'Reject']},
                    timeStart: {
                        $gte: this.state.start,
                    },
                    timeEnd: {
                        $lte: this.state.end,
                        $ne: null,
                    },
                },
                options: {
                    sort: {
                        timeStart: -1,
                    },
                },
            },
            2
        )

        let runtimes = data.filter((o) => o.name === 'In-Cycle'),
            scraps = data.filter((o) => o.name === 'Reject')

        for (let i in runtimes) {
            const range = [
                moment(runtimes[i].timeStart).valueOf(),
                moment(runtimes[i].timeEnd).valueOf(),
            ]
            runtimes[i].scrap = scraps
                .filter((s) => {
                    const t = moment(s.timeStart).valueOf()
                    return t >= range[0] && t <= range[1]
                })
                .reduce((a, b) => a + b.value, 0)
        }
        this.setState({data: runtimes, loading: false})
    }

    columns() {
        return [
            {
                Header: 'From',
                accessor: 'timeStartPretty',
            },
            {
                Header: 'To',
                accessor: 'timeEndPretty',
            },
            {
                Header: 'Duration',
                accessor: 'duration',
            },
            {
                Header: 'Reject',
                accessor: 'scrap',
            },
            {
                id: 'edit',
                accessor: '_id',
                Cell: ({value}) => (
                    <Button
                        color="primary"
                        size="xs"
                        className="btn"
                        onClick={(e) => {
                            e.stopPropagation()
                            this.toggleModal()
                            this.setState({editing: value})
                        }}>
                        Change Amount
                    </Button>
                ),
                sortable: false,
                filterable: false,
                Header: '',
            },
        ]
    }

    dataTableFormatter(record) {
        record.duration =
            moment(record.timeEnd).diff(moment(record.timeStart), 'minutes') +
            ' minutes'
        record.timeStartPretty = moment(record.timeStart).format('MMM D, h:mma')
        record.timeEndPretty = moment(record.timeEnd).format('MMM D, h:mma')
        return record
    }

    async toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async submit() {
        this.setState({message: 'Submitting rejects...'})

        const runRecord = this.state.data.find(
            (d) => d._id === this.state.editing
        )

        let scrapRecord = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Reject',
                    timeStart: runRecord.timeStart,
                },
            },
            2
        )

        if (!scrapRecord.length) {
            const timestamp = moment(runRecord.timeStart).toDate()
            await API.post(
                'states',
                {
                    nodeId: this.state.device.deviceId,
                    deviceId: this.state.device.deviceId,
                    name: 'Reject',
                    timestamp: timestamp,
                    timeStart: timestamp,
                    timeEnd: timestamp,
                    value: parseInt(this.state.value),
                },
                2
            )
        } else {
            scrapRecord[0].value = parseInt(this.state.value)
            await API.patch('states/' + scrapRecord[0]._id, scrapRecord[0], 2)
        }
        this.generate()
        this.toggleModal()
    }

    handleDeviceChange(device) {
        this.setState({
            device: this.state.devices.find((d) => d.deviceId === device.value),
        })
    }

    generate() {
        this.fetchRunTimes()
    }

    async componentWillMount() {
        const devices = await API.get('devices'),
            device = devices.length ? devices[0] : null
        this.setState({devices, device})
        this.fetchRunTimes()
    }

    componentWillUnmount() {}

    render() {
        const selectedDevice = this.state.device
            ? {label: this.state.device.name, value: this.state.device.deviceId}
            : null
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center">
                                    <strong>Reject History</strong>
                                </CardTitle>
                                <Row>
                                    <Col xs="12">
                                        <Form>
                                            <Row>
                                                <Col xs="12" sm="4">
                                                    <label>From:</label>
                                                    <DatePicker
                                                        selected={
                                                            this.state.start
                                                        }
                                                        selectsStart
                                                        startDate={
                                                            this.state.start
                                                        }
                                                        endDate={this.state.end}
                                                        onChange={
                                                            this
                                                                .handleStartChange
                                                        }
                                                        filterDate={(date) => {
                                                            return (
                                                                moment() > date
                                                            )
                                                        }}
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    <label>To:</label>
                                                    <DatePicker
                                                        selected={
                                                            this.state.end
                                                        }
                                                        selectsEnd
                                                        startDate={
                                                            this.state.start
                                                        }
                                                        endDate={this.state.end}
                                                        onChange={
                                                            this.handleEndChange
                                                        }
                                                        filterDate={(date) => {
                                                            return (
                                                                moment() > date
                                                            )
                                                        }}
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    <Label>Asset:</Label>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        name="deviceId"
                                                        value={selectedDevice}
                                                        onChange={
                                                            this
                                                                .handleDeviceChange
                                                        }
                                                        options={this.state.devices.map(
                                                            (d) => {
                                                                return {
                                                                    label: d.name,
                                                                    value: d.deviceId,
                                                                }
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="text-right">
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                                <CRUD
                                    uniqueElementId="_id"
                                    emptyCrudMainText="No Run Times Found"
                                    emptyCrudSubText=""
                                    crudTableColumns={this.columns()}
                                    crudTableResolveDataMapFn={
                                        this.dataTableFormatter
                                    }
                                    crudTableData={this.state.data}
                                    rowClickFn={() => {}}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.showModal ? (
                    <Modal
                        isOpen={this.state.showModal}
                        toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>
                            Set Reject Amount
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="12">
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="number"
                                                name="reject"
                                                value={this.state.value}
                                                onChange={(value) => {
                                                    if (value.target.value < 0)
                                                        return
                                                    this.setState({
                                                        value: value.target
                                                            .value,
                                                    })
                                                }}
                                            />
                                            <IntlMessages id="Reject Amount" />
                                        </Label>
                                    </Form>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.submit}>Save Changes</Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
