import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'

export default class OEEViewTable extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        const oee =
            Math.round((this.props.data[4] / this.props.data[0]) * 100 * 100) /
            100
        return (
            <>
                <Row>
                    <Col className="text-center">
                        <p>
                            <strong>OEE Calculation (in minutes)</strong>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col xs="6" className="text-right">
                                <h5>Potential Production Time</h5>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h5>
                                    <strong>
                                        {Math.floor(this.props.data[0])}
                                    </strong>
                                </h5>
                            </Col>
                            <Col
                                xs={{size: '6', offset: 6}}
                                className="text-center">
                                <h5>minus</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="text-right">
                                <h5>Time Losses</h5>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h5>
                                    <strong>
                                        {Math.floor(this.props.data[1])}
                                    </strong>
                                </h5>
                            </Col>
                            <Col
                                xs={{size: '6', offset: 6}}
                                className="text-center">
                                <h5>minus</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="text-right">
                                <h5>Speed Losses</h5>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h5>
                                    <strong>
                                        {Math.floor(this.props.data[2])}
                                    </strong>
                                </h5>
                            </Col>
                            <Col
                                xs={{size: '6', offset: 6}}
                                className="text-center">
                                <h5>minus</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="text-right">
                                <h5>Quality Losses</h5>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h5>
                                    <strong>
                                        {Math.floor(this.props.data[3])}
                                    </strong>
                                </h5>
                            </Col>
                            <Col
                                xs={{size: '6', offset: 6}}
                                className="text-center">
                                <h5>equals</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="text-right">
                                <h5>Production Time After Losses</h5>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h5>
                                    <strong>
                                        {Math.floor(this.props.data[4])}
                                    </strong>
                                </h5>
                            </Col>
                            <Col
                                xs={{size: '6', offset: 6}}
                                className="text-center">
                                <h5>divided by</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="text-right">
                                <h5>Potential Production Time</h5>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h5>
                                    <strong>
                                        {Math.floor(this.props.data[0])}
                                    </strong>
                                </h5>
                            </Col>
                        </Row>
                        <Row style={{borderTop: '0.5px solid lightgray'}}>
                            <Col xs="6" className="text-right">
                                <h1>
                                    <strong>OEE</strong>
                                </h1>
                            </Col>
                            <Col xs="6" className="text-left">
                                <h1>
                                    <strong>{oee}%</strong>
                                </h1>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
