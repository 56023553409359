import React, {Component, Fragment} from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
} from 'reactstrap'
import axios from 'axios'
import Select from 'react-select'
import Switch from 'rc-switch'

import {Colxx, Separator} from 'components/CustomBootstrap'
import CustomSelectInput from 'components/CustomSelectInput'

const LOGIC_OPTIONS = [
    {
        label: 'Duration',
        value: 'uniform_sampling',
        key: 0,
    },
    {
        label: 'Count',
        value: 'counting',
        key: 1,
    },
]

const SAMPLE_UNIT_OPTIONS = [
    {
        label: 'Microseconds',
        value: 'micro',
        key: 0,
    },
    {
        label: 'Milliseconds',
        value: 'milli',
        key: 1,
    },
    {
        label: 'Seconds',
        value: 'second',
        key: 2,
    },
    {
        label: 'Minutes',
        value: 'minute',
        key: 3,
    },
    {
        label: 'Hours',
        value: 'hour',
        key: 4,
    },
    {
        label: 'Days',
        value: 'day',
        key: 5,
    },
    {
        label: 'Months',
        value: 'month',
        key: 6,
    },
]

const UNIFORM_TYPE_OPTIONS = [
    {
        label: 'Average',
        value: 'average',
        key: 0,
    },
    {
        label: 'Minimum',
        value: 'minimum',
        key: 1,
    },
    {
        label: 'Maximum',
        value: 'maximum',
        key: 2,
    },
]

const COUNT_TYPE_OPTIONS = [
    {
        label: 'Greater or equal',
        value: 'greater_equal',
        key: 0,
    },
    {
        label: 'Less or equal',
        value: 'less_equal',
        key: 1,
    },
]

const SAMPLE_TYPE_OPTIONS = [
    {
        label: 'Analog',
        value: 'analog',
        key: 0,
    } /*{
  label: 'Temperature',
  value: 'temperature',
  key: 0
}*/,
]

export default class extends Component {
    constructor(props) {
        super(props)

        const obj = this.props.obj

        this.index = obj.pinId.substring(1)

        this.PIN_NUMBER_OPTIONS = Array.from(
            {length: this.props.numberOfPins},
            (_, i) => {
                return {label: i + 1, value: i + 1}
            }
        )

        this.state = {
            pinId: obj.pinId,
            pinNumber: this.PIN_NUMBER_OPTIONS[obj.pinNumber - 1],
            nickname: obj.nickname,
            pinEnable: obj.pinEnable,
            logic: LOGIC_OPTIONS.find((x) => x.value === obj.logic),
            uniformSampleRate: obj.uniformSampleRate,
            uniformSampleNumber: obj.uniformSampleNumber,
            uniformSampleRateUnit: SAMPLE_UNIT_OPTIONS.find(
                (x) => x.value === obj.uniformSampleRateUnit
            ),
            uniformType: UNIFORM_TYPE_OPTIONS.find(
                (x) => x.value === obj.uniformType
            ),
            uniformThreshold: obj.uniformThreshold,
            countSampleRate: obj.countSampleRate,
            countSampleNumber: obj.countSampleNumber,
            countSampleRateUnit: SAMPLE_UNIT_OPTIONS.find(
                (x) => x.value === obj.countSampleRateUnit
            ),
            countType: COUNT_TYPE_OPTIONS.find(
                (x) => x.value === obj.countType
            ),
            countThreshold: obj.countThreshold,
            duration: obj.logic === 'uniform_sampling',
            aliasBorderColor: 'rgb(215,215,215)',
            uniformSampleRateBorderColor: 'rgb(215,215,215)',
            uniformSampleNumberBorderColor: 'rgb(215,215,215)',
            uniformThresholdBorderColor: 'rgb(215,215,215)',
            countSampleRateBorderColor: 'rgb(215,215,215)',
            countSampleNumberBorderColor: 'rgb(215,215,215)',
            countThresholdBorderColor: 'rgb(215,215,215)',
            sampleType: SAMPLE_TYPE_OPTIONS.find(
                (x) => x.value === obj.sampleType
            ),
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}
    componentDidUpdate(prevProps, prevState) {
        let p = Object.assign({}, this.state)

        p.logic = p.logic.value
        p.uniformSampleRateUnit = p.uniformSampleRateUnit.value
        p.uniformType = p.uniformType.value
        p.countSampleRateUnit = p.countSampleRateUnit.value
        p.countType = p.countType.value
        p.sampleType = p.sampleType.value
        p.pendingUpdate = true

        // set integers
        p.pinNumber = parseInt(p.pinNumber.value)
        p.uniformSampleRate = parseInt(p.uniformSampleRate)
        p.uniformSampleNumber = parseInt(p.uniformSampleNumber)
        p.uniformThreshold = parseInt(p.uniformThreshold)
        p.countSampleRate = parseInt(p.countSampleRate)
        p.countSampleNumber = parseInt(p.countSampleNumber)
        p.countThreshold = parseInt(p.countThreshold)

        delete p.duration
        delete p.aliasBorderColor
        delete p.uniformSampleRateBorderColor
        delete p.uniformSampleNumberBorderColor
        delete p.uniformThresholdBorderColor
        delete p.countSampleRateBorderColor
        delete p.countSampleNumberBorderColor
        delete p.countThresholdBorderColor

        this.props.updateWorkingCopy(this.index, p)
    }
    render() {
        const obj = this.props.obj
        return (
            <Fragment>
                <Row className="mb-4">
                    <Colxx xs="12" sm="2">
                        <label>Enabled:</label>
                        <Switch
                            className="custom-switch custom-switch-primary"
                            checked={this.state.pinEnable}
                            onChange={(pinEnable) => {
                                this.setState({pinEnable})
                            }}
                        />
                    </Colxx>
                    <Colxx xs="3">
                        <label>Alias:</label>
                        <Input
                            style={{borderColor: this.state.aliasBorderColor}}
                            type="text"
                            name="nickname"
                            value={this.state.nickname}
                            onChange={(nickname) => {
                                let v = nickname.target.value,
                                    color = 'rgb(215,215,215)'
                                if (v === '') {
                                    color = '#dc3545'
                                    this.props.validate(this.index, false)
                                } else {
                                    this.props.validate(this.index, true)
                                }
                                this.setState({
                                    nickname: nickname.target.value,
                                    aliasBorderColor: color,
                                })
                            }}
                        />
                    </Colxx>
                    <Colxx xs="12" sm="2">
                        <label>Pin:</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="pin-number"
                            value={this.state.pinNumber}
                            onChange={(pinNumber) => {
                                this.setState({
                                    pinNumber:
                                        this.PIN_NUMBER_OPTIONS[
                                            pinNumber.value - 1
                                        ],
                                })
                            }}
                            options={this.PIN_NUMBER_OPTIONS}
                        />
                    </Colxx>
                    <Colxx xs="12" sm="2">
                        <label>Sampling:</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="logic"
                            value={this.state.logic}
                            onChange={(logic) => {
                                const duration =
                                    LOGIC_OPTIONS[logic.key].value ===
                                    'uniform_sampling'
                                this.setState({
                                    logic: LOGIC_OPTIONS[logic.key],
                                    duration: duration,
                                })
                            }}
                            options={LOGIC_OPTIONS}
                        />
                    </Colxx>
                    <Colxx xs="12" sm="3">
                        <label>Sensor:</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="sampleType"
                            value={this.state.sampleType}
                            onChange={(sampleType) => {
                                this.setState({
                                    sampleType:
                                        SAMPLE_TYPE_OPTIONS[sampleType.key],
                                })
                            }}
                            options={SAMPLE_TYPE_OPTIONS}
                        />
                    </Colxx>
                </Row>
                <Row>
                    {this.state.duration ? (
                        <>
                            <Colxx xs="12" sm="2">
                                <label>Rate:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state
                                                .uniformSampleRateBorderColor,
                                    }}
                                    type="number"
                                    name="uniformSampleRate"
                                    value={this.state.uniformSampleRate}
                                    onChange={(uniformSampleRate) => {
                                        let v = uniformSampleRate.target.value,
                                            color = 'rgb(215,215,215)'
                                        if (!v || v <= 0) {
                                            color = '#dc3545'
                                            this.props.validate(
                                                this.index,
                                                false
                                            )
                                        } else {
                                            this.props.validate(
                                                this.index,
                                                true
                                            )
                                        }
                                        this.setState({
                                            uniformSampleRate: v,
                                            uniformSampleRateBorderColor: color,
                                        })
                                    }}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="3">
                                <label>Unit:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="uniformSampleUnit"
                                    value={this.state.uniformSampleRateUnit}
                                    onChange={(uniformSampleRateUnit) => {
                                        this.setState({
                                            uniformSampleRateUnit:
                                                SAMPLE_UNIT_OPTIONS[
                                                    uniformSampleRateUnit.key
                                                ],
                                        })
                                    }}
                                    options={SAMPLE_UNIT_OPTIONS}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="2">
                                <label>Number:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state
                                                .uniformSampleNumberBorderColor,
                                    }}
                                    type="number"
                                    name="uniformSampleNumber"
                                    value={this.state.uniformSampleNumber}
                                    onChange={(uniformSampleNumber) => {
                                        let v =
                                                uniformSampleNumber.target
                                                    .value,
                                            color = 'rgb(215,215,215)'

                                        if (!v || v <= 0) {
                                            color = '#dc3545'
                                            this.props.validate(
                                                this.index,
                                                false
                                            )
                                        } else {
                                            this.props.validate(
                                                this.index,
                                                true
                                            )
                                        }
                                        this.setState({
                                            uniformSampleNumber: v,
                                            uniformSampleNumberBorderColor:
                                                color,
                                        })
                                    }}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="3">
                                <label>Type:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="uniformType"
                                    value={this.state.uniformType}
                                    onChange={(uniformType) => {
                                        this.setState({
                                            uniformType:
                                                UNIFORM_TYPE_OPTIONS[
                                                    uniformType.key
                                                ],
                                        })
                                    }}
                                    options={UNIFORM_TYPE_OPTIONS}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="2">
                                <label>Threshold:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state
                                                .uniformThresholdBorderColor,
                                    }}
                                    type="number"
                                    name="uniformThreshold"
                                    value={this.state.uniformThreshold}
                                    onChange={(uniformThreshold) => {
                                        let v = uniformThreshold.target.value,
                                            color = 'rgb(215,215,215)'
                                        if (!v || v < 0) {
                                            color = '#dc3545'
                                            this.props.validate(
                                                this.index,
                                                false
                                            )
                                        } else {
                                            this.props.validate(
                                                this.index,
                                                true
                                            )
                                        }
                                        this.setState({
                                            uniformThreshold: v,
                                            uniformThresholdBorderColor: color,
                                        })
                                    }}
                                />
                            </Colxx>
                        </>
                    ) : (
                        <>
                            <Colxx xs="12" sm="2">
                                <label>Rate:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state
                                                .countSampleRateBorderColor,
                                    }}
                                    type="number"
                                    name="countSampleRate"
                                    value={this.state.countSampleRate}
                                    onChange={(countSampleRate) => {
                                        let v = countSampleRate.target.value,
                                            color = 'rgb(215,215,215)'
                                        if (!v || v <= 0) {
                                            color = '#dc3545'
                                            this.props.validate(
                                                this.index,
                                                false
                                            )
                                        } else {
                                            this.props.validate(
                                                this.index,
                                                true
                                            )
                                        }
                                        this.setState({
                                            countSampleRate: v,
                                            countSampleRateBorderColor: color,
                                        })
                                    }}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="3">
                                <label>Unit:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="countSampleUnit"
                                    value={this.state.countSampleRateUnit}
                                    onChange={(countSampleRateUnit) => {
                                        this.setState({
                                            countSampleRateUnit:
                                                SAMPLE_UNIT_OPTIONS[
                                                    countSampleRateUnit.key
                                                ],
                                        })
                                    }}
                                    options={SAMPLE_UNIT_OPTIONS}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="2">
                                <label>Number:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state
                                                .countSampleNumberBorderColor,
                                    }}
                                    type="number"
                                    name="countSampleNumber"
                                    value={this.state.countSampleNumber}
                                    onChange={(countSampleNumber) => {
                                        let v = countSampleNumber.target.value,
                                            color = 'rgb(215,215,215)'
                                        if (!v || v <= 0) {
                                            color = '#dc3545'
                                            this.props.validate(
                                                this.index,
                                                false
                                            )
                                        } else {
                                            this.props.validate(
                                                this.index,
                                                true
                                            )
                                        }
                                        this.setState({
                                            countSampleNumber: v,
                                            countSampleNumberBorderColor: color,
                                        })
                                    }}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="3">
                                <label>Type:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="countType"
                                    value={this.state.countType}
                                    onChange={(countType) => {
                                        this.setState({
                                            countType:
                                                COUNT_TYPE_OPTIONS[
                                                    countType.key
                                                ],
                                        })
                                    }}
                                    options={COUNT_TYPE_OPTIONS}
                                />
                            </Colxx>
                            <Colxx xs="12" sm="2">
                                <label>Threshold:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state
                                                .countThresholdBorderColor,
                                    }}
                                    type="number"
                                    name="countThreshold"
                                    value={this.state.countThreshold}
                                    onChange={(countThreshold) => {
                                        let v = countThreshold.target.value,
                                            color = 'rgb(215,215,215)'
                                        if (!v || v < 0) {
                                            color = '#dc3545'
                                            this.props.validate(
                                                this.index,
                                                false
                                            )
                                        } else {
                                            this.props.validate(
                                                this.index,
                                                true
                                            )
                                        }
                                        this.setState({
                                            countThreshold: v,
                                            countThresholdBorderColor: color,
                                        })
                                    }}
                                />
                            </Colxx>
                        </>
                    )}
                </Row>
            </Fragment>
        )
    }
}
