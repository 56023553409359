import React, {Component, Fragment} from 'react'
import {Row, Col, Button, Form, Label} from 'reactstrap'
import DatePicker from 'react-datepicker'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'

export default class DateRangePicker extends Component {
    id = 'DateRangePicker'
    field = 'dateRange'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue = this.props.existing

        this.state = {
            start: moment().subtract(1, 'week').startOf('day'),
            end: moment().startOf('day'),
            reportLimit: this.props.reportLimit ? this.props.reportLimit : 12,
        }

        if (this.props && this.props.cb) {
            this.props.cb('dateRange', initialValue)
        }
    }

    handleCallback() {
        if (
            moment.duration(this.state.end.diff(this.state.start)).asMonths() >
            this.state.reportLimit
        ) {
            alert('Please report less than a month at a time')
        } else {
            this.updateCallback()
        }
    }

    updateCallback() {
        if (this.props && this.props.cb) {
            this.props.cb('dateRange', {
                start: this.state.start,
                end: this.state.end,
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Row className="mt-2">
                    <Col>
                        <label>From:</label>
                        <DatePicker
                            selected={this.state.start}
                            selectsStart
                            startDate={this.state.start}
                            endDate={this.state.end}
                            onChange={(start) => {
                                this.setState({start}, this.handleCallback)
                            }}
                            filterDate={(date) => {
                                return moment() > date
                            }}
                        />
                    </Col>
                    <Col>
                        <label>To:</label>
                        <DatePicker
                            selected={this.state.end}
                            selectsEnd
                            startDate={this.state.start}
                            endDate={this.state.end}
                            onChange={(end) => {
                                this.setState({end}, this.handleCallback)
                            }}
                            filterDate={(date) => {
                                return moment() > date
                            }}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
