import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

import Heatmap from '../Common/Visuals/Heatmap'

export default class IPSMap extends Component {
    static propTypes = {
        model: PropTypes.object,
        objects: PropTypes.array,
        height: PropTypes.number,
        width: PropTypes.number,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.img = React.createRef()
    }

    render() {
        return (
            <TransformWrapper
                options={{
                    limitToBounds: true,
                }}
                wheel={{
                    step: 2,
                }}
                defaultScale={1}
                doubleClick={{
                    disabled: true,
                }}>
                {({scale, ...rest}) => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            overflowX: 'hidden',
                            border: '1px solid black',
                        }}>
                        <TransformComponent>
                            <img
                                ref={this.img}
                                style={{
                                    width: '100%',
                                    maxHeight: this.props.height,
                                    opacity: 0.25,
                                }}
                                src={this.props.model.backgroundImage}
                                alt="background"
                            />
                            {this.props.objects.map((o) => {
                                return React.cloneElement(o, {
                                    scale,
                                    xScale: this.img.current
                                        ? this.img.current.offsetWidth /
                                          this.props.model.backgroundImageWidth
                                        : 1,
                                    yScale: this.img.current
                                        ? this.img.current.offsetHeight /
                                          this.props.model.backgroundImageHeight
                                        : 1,
                                    backgroundWidth: this.img.current
                                        ? this.img.current.offsetWidth
                                        : null,
                                    backgroundHeight: this.img.current
                                        ? this.img.current.offsetHeight
                                        : null,
                                    onHover: () => {},
                                    onClick: () => {},
                                })
                            })}
                        </TransformComponent>
                    </div>
                )}
            </TransformWrapper>
        )
    }
}
