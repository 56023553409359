import React, {Component, Fragment} from 'react'
import Chart from 'chart.js'
import moment from 'moment'
import autobind from 'auto-bind'

import {Row, Col} from 'reactstrap'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {tileColor} from 'SDK/helpers'

Chart.pluginService.register({
    beforeDraw: function (chart, easing) {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center
            var fontStyle = centerConfig.fontStyle || 'Nunito'
            var txt = centerConfig.text
            var color = centerConfig.color || '#000'
            var sidePadding = centerConfig.sidePadding || 20
            var sidePaddingCalculated =
                (sidePadding / 100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = '30px ' + fontStyle

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth
            var newFontSize = Math.floor(30 * widthRatio)
            var elementHeight = chart.innerRadius * 2

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight)

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
            ctx.font = fontSizeToUse + 'px ' + fontStyle
            ctx.fillStyle = color

            //Draw text in center
            ctx.fillText(txt, centerX, centerY)
        }
    },
})

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            loaded: false,
            chartdata: {},
        }
    }

    async makeTimelineDonut() {
        console.log(
            this.props.timerange[1],
            moment(this.props.timerange[1]).isAfter(moment())
                ? moment().toISOString()
                : this.props.timerange[1]
        )
        const response = await API.post('timeline', {
            timeStart: this.props.timerange[0],
            timeEnd: moment(this.props.timerange[1]).isAfter(moment())
                ? moment().toISOString()
                : this.props.timerange[1],
            deviceId: this.props.device.deviceId,
        })
        if (!response) {
            return // alert('There was an error fetching data.');
        }
        let compact = [
                {
                    state: response.state[0],
                    from: 0,
                    to: 0,
                    color: tileColor(response.state[0]),
                },
            ],
            lastValue = response.state[0],
            compactIndex = 0

        for (let i = 1, j = response.state.length; i < j; i++) {
            let curr = response.state[i],
                prev = response.state[i - 1]

            if (curr === prev || (prev === null && curr === lastValue)) {
                // same as previous, reduce
                response.state[i] = null
                compact[compactIndex].to = i
            } else {
                // new start
                compact.push({
                    state: curr,
                    from: i,
                    to: i,
                    color:
                        response.state[i] !== 'none'
                            ? tileColor(response.state[i])
                            : '#fff',
                })
                compactIndex++
                lastValue = curr
            }
        }

        this.setState({
            loaded: true,
            chartdata: {
                labels: compact.map((x) => x.state),
                datasets: [
                    {
                        data: compact.map((x) => {
                            return x.to - x.from + 1
                        }),
                        backgroundColor: compact.map((x) => x.color),
                        borderColor: 'black',
                        borderWidth: 0.03,
                        compact: compact,
                    },
                ],
            },
        })
        const node = this.node
        new Chart(node, {
            type: 'doughnut',
            data: this.state.chartdata,
            options: {
                legend: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    center: {
                        text: parseInt(this.props.utilization * 100) + '%',
                    },
                },
                cutoutPercentage: 75,
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let row =
                                    data.datasets[0].compact[tooltipItem.index],
                                start = moment(response.dates[0]).add(
                                    row.from,
                                    'minutes'
                                ),
                                end = moment(response.dates[0]).add(
                                    row.to,
                                    'minutes'
                                ),
                                startStr = '',
                                endStr = ''
                            if (start.isSame(moment(), 'day'))
                                startStr = 'Today ' + start.format('h:mma')
                            else startStr = 'Yesterday ' + start.format('h:mma')

                            if (end.isSame(moment(), 'day'))
                                endStr = 'Today ' + end.format('h:mma')
                            else endStr = 'Yesterday ' + end.format('h:mma')
                            return (
                                startStr +
                                ' - ' +
                                endStr +
                                ' (' +
                                end.diff(start, 'minutes') +
                                ' minutes)'
                            )
                        },
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index]
                        },
                    },
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl =
                            document.getElementById('chartjs-tooltip')

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div')
                            tooltipEl.id = 'chartjs-tooltip'
                            tooltipEl.innerHTML =
                                "<table style='padding:10px;color:#fff!important;background-color:#000;'></table>"
                            document.body.appendChild(tooltipEl)
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0
                            return
                        }

                        // Set caret Position
                        tooltipEl.classList.remove(
                            'above',
                            'below',
                            'no-transform'
                        )
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign)
                        } else {
                            tooltipEl.classList.add('no-transform')
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || []
                            var bodyLines = tooltipModel.body.map(getBody)

                            var innerHtml = '<thead>'

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>'
                            })
                            innerHtml += '</thead><tbody>'

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i]
                                var style =
                                    'background:' + colors.backgroundColor
                                style += '; border-color:' + colors.borderColor
                                style += '; border-width: 2px'
                                var span = '<span style="' + style + '"></span>'
                                innerHtml +=
                                    '<tr><td>' + span + body + '</td></tr>'
                            })
                            innerHtml += '</tbody>'

                            var tableRoot = tooltipEl.querySelector('table')
                            tableRoot.innerHTML = innerHtml
                        }

                        // `this` will be the overall tooltip
                        var position =
                            this._chart.canvas.getBoundingClientRect()

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1
                        tooltipEl.style.position = 'absolute'
                        tooltipEl.style.left =
                            position.left +
                            window.pageXOffset +
                            tooltipModel.caretX +
                            'px'
                        tooltipEl.style.top =
                            position.top +
                            window.pageYOffset +
                            tooltipModel.caretY +
                            'px'
                        tooltipEl.style.fontFamily =
                            tooltipModel._bodyFontFamily
                        tooltipEl.style.fontSize =
                            tooltipModel.bodyFontSize + 'px'
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
                        tooltipEl.style.padding =
                            tooltipModel.yPadding +
                            'px ' +
                            tooltipModel.xPadding +
                            'px'
                        tooltipEl.style.pointerEvents = 'none'
                    },
                },
            },
        })
    }

    componentDidMount() {
        this.makeTimelineDonut()
        this.subscriber.add(
            this.makeTimelineDonut,
            1000 * 60 * 5,
            'makeTimelineDonut'
        )
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const isLoading = !this.state.loaded
        return isLoading ? null : (
            <Row>
                <Col>
                    <canvas
                        style={{width: 100, height: 100}}
                        ref={(node) => (this.node = node)}
                    />
                </Col>
            </Row>
        )
    }
}
