import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import {BrowserView, MobileOnlyView} from 'react-device-detect'

import CustomSelectInput from 'components/CustomSelectInput'

import GenericWidgets from 'SDK/ui/widgets'
import * as API from 'SDK/api'
import GenericWidgetModal from '../genericWidgetConfigurationModal'

import './index.css'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.showUserSettings = false

        if (this.props.component) {
            this.component = GenericWidgets.find((g) => {
                const instance = new g()
                return instance.id === this.props.component.component
            })

            if (this.component) {
                this.component = new this.component()
            }
            this.showUserSettings = this.component.propComponents.length > 1
        }

        this.state = {
            showModal: false,
        }
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async onClick() {
        let viewSettings = null

        if (this.props.manager) {
            viewSettings = await API.get(
                'view-settings/' +
                    this.props.environmentId +
                    '/' +
                    this.props.sectionId +
                    '/' +
                    this.props.viewId +
                    '?default=true',
                2
            )
        } else {
            viewSettings = await API.get(
                'view-settings/' +
                    this.props.environmentId +
                    '/' +
                    this.props.sectionId +
                    '/' +
                    this.props.viewId,
                2
            )
        }

        let component = GenericWidgets.find((g) => {
            const instance = new g()
            return instance.id === this.props.component.component
        })
        let existingProps = viewSettings
            ? viewSettings.props
            : this.props.component.props

        this.setState({
            showModal: true,
            component,
            existingProps,
        })
    }

    async removeUserSettings() {
        if (!this.props.manager) {
            await API.remove(
                'view-settings/' +
                    this.props.environmentId +
                    '/' +
                    this.props.sectionId +
                    '/' +
                    this.props.viewId,
                2
            )
        }
        this.props.reload()
    }

    async saveUserSettings(obj) {
        if (this.props.manager) {
            await API.post(
                'view-settings/' +
                    this.props.environmentId +
                    '/' +
                    this.props.sectionId +
                    '/' +
                    this.props.viewId +
                    '?default=true',
                obj,
                2
            )
        } else {
            await API.post(
                'view-settings/' +
                    this.props.environmentId +
                    '/' +
                    this.props.sectionId +
                    '/' +
                    this.props.viewId,
                obj,
                2
            )
        }
        this.props.reload()
    }

    render() {
        const showUserSettings = this.showUserSettings && !this.props.manager

        return (
            <Fragment>
                {this.props.manager && (
                    <Button outline color="info" onClick={this.onClick}>
                        Default Settings
                    </Button>
                )}
                {showUserSettings && (
                    <div className="view-settings-button">
                        <Button
                            color="primary"
                            size="xs"
                            className="btn"
                            onClick={this.onClick}>
                            <i className="iconsmind-User" /> User Settings
                        </Button>
                    </div>
                )}
                {this.state.showModal ? (
                    <GenericWidgetModal
                        manager={this.props.manager}
                        toggleModal={this.toggleModal}
                        modal={this.state.showModal}
                        component={this.state.component}
                        existingProps={this.state.existingProps}
                        loadComponent={this.saveUserSettings}
                        reset={this.removeUserSettings}
                    />
                ) : null}
            </Fragment>
        )
    }
}
