import React from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, Alert} from 'reactstrap'

import * as elements from '../elements'
import * as Editors from './editors'

const TransformEditor = (props) => {
    const type = props.element.type

    return (
        <>
            <Modal
                size="lg"
                isOpen={true}
                toggle={
                    type === 'input' && !props.element.data.opts.submitted
                        ? undefined
                        : props.close
                }>
                <ModalHeader
                    toggle={
                        type === 'input' && !props.element.data.opts.submitted
                            ? undefined
                            : props.close
                    }>
                    {props.element.data.opts.label}
                </ModalHeader>
                <ModalBody>
                    <Alert>
                        {
                            elements[
                                (type === 'default' ? 'transform' : type) + 's'
                            ].find(
                                (el) => el.type === props.element.data.opts.type
                            ).documentation
                        }
                    </Alert>
                    {React.createElement(
                        Editors[props.element.data.opts.type],
                        {
                            dataModels: props.dataModels,
                            fieldTypes: props.fieldTypes,
                            formulas: props.formulas,
                            onChange: props.onSubmit,
                            element: props.element,
                            variables: props.variables,
                            elements: props.elements,
                            flowId: props.flowId,
                        }
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}

TransformEditor.propTypes = {
    close: PropTypes.func.isRequired,
    element: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    dataModels: PropTypes.array.isRequired,
    fieldTypes: PropTypes.array.isRequired,
    formulas: PropTypes.array.isRequired,
    variables: PropTypes.array.isRequired,
    elements: PropTypes.array.isRequired,
    flowId: PropTypes.string.isRequired,
}

export default TransformEditor
