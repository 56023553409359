import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import IntlMessages from 'util/IntlMessages'
import {Nav, NavItem} from 'reactstrap'
import {NavLink} from 'react-router-dom'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {withRouter} from 'react-router-dom'
import Sortable from 'react-sortablejs'

import {connect} from 'react-redux'
import {
    setContainerClassnames,
    addContainerClassname,
    changeDefaultClassnames,
} from 'redux/actions'

import * as API from 'SDK/api'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.handleWindowResize = this.handleWindowResize.bind(this)
        this.addEvents = this.addEvents.bind(this)
        this.handleDocumentClick = this.handleDocumentClick.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleProps = this.handleProps.bind(this)
        this.removeEvents = this.removeEvents.bind(this)
        this.getContainer = this.getContainer.bind(this)
        this.getMenuClassesForResize = this.getMenuClassesForResize.bind(this)
        this.setSelectedLiActive = this.setSelectedLiActive.bind(this)

        const userObj = JSON.parse(localStorage['userObject'])

        this.isAccumine =
            userObj.username === 'accumine' || userObj.role === 'Administrator'

        this.role = JSON.parse(localStorage['userObject']).role

        this.state = {
            selectedParentMenu: '',
            viewingParentMenu: '',
            showLogoutOnSideNav: false,
            dashboards: [],
            interfaces: [],
            sectionOrder: [],
            environment: this.props.environment,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            l: nextProps.lastUpdate,
            environment: nextProps.environment,
        })
    }

    toolsightRedirect(path) {
        let factoryId = JSON.parse(localStorage['userObject']).factoryId
        window.open(
            'https://' + factoryId + '.accuminetech.com/' + path,
            '_black'
        )
    }
    handleLogout() {
        localStorage.clear()
        this.props.logoutUser(this.props.history)
    }

    handleWindowResize(event) {
        if (event && !event.isTrusted) {
            return
        }
        const {containerClassnames} = this.props
        let nextClasses = this.getMenuClassesForResize(containerClassnames)
        this.props.setContainerClassnames(0, nextClasses.join(' '))
        if (
            document.documentElement.offsetWidth < 992 &&
            !this.state.showLogoutOnSideNav
        ) {
            this.setState({
                showLogoutOnSideNav: true,
            })
        } else if (
            document.documentElement.offsetWidth >= 992 &&
            this.state.showLogoutOnSideNav
        ) {
            this.setState({
                showLogoutOnSideNav: false,
            })
        }
    }

    handleDocumentClick(e) {
        const container = this.getContainer()
        let isMenuClick = false
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains('menu-button') ||
                e.target.classList.contains('menu-button-mobile'))
        ) {
            isMenuClick = true
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            (e.target.parentElement.classList.contains('menu-button') ||
                e.target.parentElement.classList.contains('menu-button-mobile'))
        ) {
            isMenuClick = true
        } else if (
            e.target.parentElement &&
            e.target.parentElement.parentElement &&
            e.target.parentElement.parentElement.classList &&
            (e.target.parentElement.parentElement.classList.contains(
                'menu-button'
            ) ||
                e.target.parentElement.parentElement.classList.contains(
                    'menu-button-mobile'
                ))
        ) {
            isMenuClick = true
        }
        if (
            (container.contains(e.target) && container !== e.target) ||
            isMenuClick
        ) {
            return
        }
        this.toggle(e)
        this.setState({
            viewingParentMenu: '',
        })
    }

    getMenuClassesForResize(classes) {
        const {menuHiddenBreakpoint, subHiddenBreakpoint} = this.props
        let nextClasses = classes.split(' ').filter((x) => x != '')
        const windowWidth = window.innerWidth
        if (windowWidth < menuHiddenBreakpoint) {
            nextClasses.push('menu-mobile')
        } else if (windowWidth < subHiddenBreakpoint) {
            nextClasses = nextClasses.filter((x) => x != 'menu-mobile')
            if (
                nextClasses.includes('menu-default') &&
                !nextClasses.includes('menu-sub-hidden')
            ) {
                nextClasses.push('menu-sub-hidden')
            }
        } else {
            nextClasses = nextClasses.filter((x) => x != 'menu-mobile')
            if (
                nextClasses.includes('menu-default') &&
                nextClasses.includes('menu-sub-hidden')
            ) {
                nextClasses = nextClasses.filter((x) => x != 'menu-sub-hidden')
            }
        }
        return nextClasses
    }

    getContainer() {
        return ReactDOM.findDOMNode(this)
    }

    toggle() {
        const {containerClassnames, menuClickCount} = this.props
        const currentClasses = containerClassnames
            ? containerClassnames.split(' ').filter((x) => x != '')
            : ''

        if (currentClasses.includes('menu-sub-hidden') && menuClickCount == 3) {
            this.props.setContainerClassnames(2, containerClassnames)
        } else if (
            currentClasses.includes('menu-hidden') ||
            currentClasses.includes('menu-mobile')
        ) {
            this.props.setContainerClassnames(0, containerClassnames)
        }
    }

    handleProps() {
        this.addEvents()
    }

    addEvents() {
        ;['click', 'touchstart'].forEach((event) =>
            document.addEventListener(event, this.handleDocumentClick, true)
        )
    }
    removeEvents() {
        ;['click', 'touchstart'].forEach((event) =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        )
    }
    setSelectedLiActive() {
        const oldli = document.querySelector('.sub-menu  li.active')
        if (oldli != null) {
            oldli.classList.remove('active')
        }

        /* set selected parent menu */
        const selectedlink = document.querySelector('.sub-menu  a.active')
        if (selectedlink != null) {
            selectedlink.parentElement.classList.add('active')
            this.setState({
                selectedParentMenu:
                    selectedlink.parentElement.parentElement.getAttribute(
                        'data-parent'
                    ),
            })
        } else {
            var selectedParentNoSubItem = document.querySelector(
                '.main-menu  li a.active'
            )
            if (selectedParentNoSubItem != null) {
                this.setState({
                    selectedParentMenu:
                        selectedParentNoSubItem.getAttribute('data-flag'),
                })
            } else if (this.state.selectedParentMenu == '') {
                this.setState({
                    selectedParentMenu: 'gogo',
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setSelectedLiActive()
            this.toggle()
            window.scrollTo(0, 0)
        }

        this.handleProps()
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize)
        this.handleWindowResize()
        this.handleProps()
        this.setSelectedLiActive()
    }

    componentWillUnmount() {
        this.removeEvents()
        window.removeEventListener('resize', this.handleWindowResize)
        clearInterval(this.interval)
    }

    changeDefaultMenuType(e, containerClassnames) {
        e.preventDefault()
        let nextClasses = this.getMenuClassesForResize(containerClassnames)
        this.props.setContainerClassnames(0, nextClasses.join(' '))
    }

    openSubMenu(e, selectedParent) {
        e.preventDefault()
        const {containerClassnames, menuClickCount} = this.props
        if (this.state.viewingParentMenu === selectedParent)
            this.props.setContainerClassnames(0, containerClassnames)
        const currentClasses = containerClassnames
            ? containerClassnames.split(' ').filter((x) => x != '')
            : ''

        if (!currentClasses.includes('menu-mobile')) {
            if (
                currentClasses.includes('menu-sub-hidden') &&
                (menuClickCount == 2 || menuClickCount == 0)
            ) {
                this.props.setContainerClassnames(3, containerClassnames)
            } else if (
                currentClasses.includes('menu-hidden') &&
                (menuClickCount == 1 || menuClickCount == 3)
            ) {
                this.props.setContainerClassnames(2, containerClassnames)
            } else if (
                currentClasses.includes('menu-default') &&
                !currentClasses.includes('menu-sub-hidden') &&
                (menuClickCount == 1 || menuClickCount == 3)
            ) {
                this.props.setContainerClassnames(0, containerClassnames)
            }
        } else {
            this.props.addContainerClassname(
                'sub-show-temporary',
                containerClassnames
            )
        }
        this.setState({
            viewingParentMenu: selectedParent,
        })
    }
    changeViewingParentMenu(menu) {
        this.toggle()

        this.setState({
            viewingParentMenu: menu,
        })
    }

    hasSectionAccess(section) {
        if (this.isAccumine) return true
        let authorized = false

        section.views.forEach((view) => {
            if (
                view.permissions &&
                view.permissions.find((p) => p === this.role)
            ) {
                authorized = true
            }
        })
        return authorized
    }

    hasViewAccess(view) {
        if (this.isAccumine) return true
        let authorized = false

        if (view.permissions && view.permissions.find((p) => p === this.role)) {
            authorized = true
        }

        return authorized
    }

    render() {
        return (
            <div className="sidebar">
                <div className="main-menu">
                    <div className="scroll">
                        <PerfectScrollbar
                            options={{
                                suppressScrollX: true,
                                wheelPropagation: false,
                            }}>
                            <Nav vertical className="list-unstyled">
                                <Sortable
                                    key={
                                        Math.random() +
                                        this.props.editingEnvironment
                                    }
                                    onChange={(order, sortable, evt) => {
                                        this.props.handleSort(null, order)
                                    }}
                                    options={{
                                        disabled:
                                            !this.props.editingEnvironment,
                                    }}>
                                    {this.state.environment.sections.map(
                                        (a) => {
                                            if (this.hasSectionAccess(a)) {
                                                return (
                                                    <NavItem
                                                        data-id={a.id}
                                                        key={a.id}
                                                        className={classnames({
                                                            active:
                                                                (this.state
                                                                    .selectedParentMenu ==
                                                                    a.name &&
                                                                    this.state
                                                                        .viewingParentMenu ==
                                                                        '') ||
                                                                this.state
                                                                    .viewingParentMenu ==
                                                                    a.id,
                                                        })}>
                                                        {a.views.length === 1 &&
                                                        !this.props
                                                            .editingEnvironment ? (
                                                            <NavLink
                                                                to={
                                                                    '/app/env/' +
                                                                    this.state
                                                                        .environment
                                                                        ._id +
                                                                    '/' +
                                                                    a.id +
                                                                    '/' +
                                                                    a.views[0]
                                                                        .id +
                                                                    '/view'
                                                                }>
                                                                {a.name}
                                                            </NavLink>
                                                        ) : (
                                                            <NavLink
                                                                to={a.name}
                                                                onClick={(e) =>
                                                                    this.openSubMenu(
                                                                        e,
                                                                        a.id
                                                                    )
                                                                }>
                                                                {a.name}
                                                            </NavLink>
                                                        )}
                                                    </NavItem>
                                                )
                                            }
                                        }
                                    )}
                                </Sortable>

                                {this.props.editingEnvironment ? (
                                    <NavItem
                                        className={classnames({
                                            active:
                                                (this.state
                                                    .selectedParentMenu == '' &&
                                                    this.state
                                                        .viewingParentMenu ==
                                                        '') ||
                                                this.state.viewingParentMenu ==
                                                    '',
                                        })}>
                                        <NavLink
                                            to="#"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.props.handleAddNewSectionEvent()
                                            }}>
                                            <i className="iconsmind-Add" />{' '}
                                            New...
                                        </NavLink>
                                    </NavItem>
                                ) : null}
                            </Nav>
                        </PerfectScrollbar>
                    </div>
                </div>

                <div className="sub-menu">
                    <div className="scroll">
                        <PerfectScrollbar
                            options={{
                                suppressScrollX: true,
                                wheelPropagation: false,
                            }}>
                            {this.state.environment.sections.map((a) => {
                                const baseRoute =
                                    '/app/env/' +
                                    this.state.environment._id +
                                    '/' +
                                    a.id +
                                    '/'
                                return (
                                    <Nav
                                        key={Math.random()}
                                        className={classnames({
                                            'd-block':
                                                (this.state
                                                    .selectedParentMenu ==
                                                    a.name &&
                                                    this.state
                                                        .viewingParentMenu ==
                                                        '') ||
                                                this.state.viewingParentMenu ==
                                                    a.id,
                                        })}
                                        data-parent={a.id}>
                                        <Sortable
                                            key={
                                                Math.random() +
                                                this.props.editingEnvironment
                                            }
                                            onChange={(
                                                order,
                                                sortable,
                                                evt
                                            ) => {
                                                this.props.handleSort(
                                                    a.id,
                                                    order
                                                )
                                            }}
                                            options={{
                                                disabled:
                                                    !this.props
                                                        .editingEnvironment,
                                            }}>
                                            {a.views.map((b) => {
                                                if (this.hasViewAccess(b)) {
                                                    if (
                                                        b.divider &&
                                                        !this.props
                                                            .editingEnvironment
                                                    ) {
                                                        return (
                                                            <NavItem
                                                                key={b.id}
                                                                data-id={b.id}>
                                                                <NavLink
                                                                    to="#"
                                                                    style={{
                                                                        color: 'grey',
                                                                        cursor: 'text',
                                                                    }}>
                                                                    {b.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    } else if (
                                                        b.divider &&
                                                        this.props
                                                            .editingEnvironment
                                                    ) {
                                                        return (
                                                            <NavItem
                                                                key={b.id}
                                                                data-id={b.id}>
                                                                <NavLink
                                                                    to="#"
                                                                    style={{
                                                                        color: 'grey',
                                                                        cursor: 'text',
                                                                    }}>
                                                                    {b.name}{' '}
                                                                    <i
                                                                        className="simple-icon-close"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            this.props.handleDeleteViewEvent(
                                                                                a.id,
                                                                                b.id
                                                                            )
                                                                        }}
                                                                    />
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    } else if (
                                                        !b.divider &&
                                                        this.props
                                                            .editingEnvironment
                                                    ) {
                                                        return (
                                                            <NavItem
                                                                key={b.id}
                                                                data-id={b.id}>
                                                                <NavLink
                                                                    to={
                                                                        baseRoute +
                                                                        b.id +
                                                                        '/view'
                                                                    }>
                                                                    <i className="simple-icon-arrow-right" />{' '}
                                                                    {b.name}{' '}
                                                                    <i
                                                                        className="simple-icon-close"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            this.props.handleDeleteViewEvent(
                                                                                a.id,
                                                                                b.id
                                                                            )
                                                                        }}
                                                                    />
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    } else {
                                                        return (
                                                            <NavItem
                                                                key={b.id}
                                                                data-id={b.id}>
                                                                <NavLink
                                                                    to={
                                                                        baseRoute +
                                                                        b.id +
                                                                        '/view'
                                                                    }>
                                                                    <i className="simple-icon-arrow-right" />{' '}
                                                                    {b.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    }
                                                }
                                            })}
                                        </Sortable>
                                        {this.props.editingEnvironment ? (
                                            <>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        style={{
                                                            color: 'grey',
                                                            cursor: 'text',
                                                        }}>
                                                        Section Editor
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            this.props.handleAddNewViewEvent(
                                                                a.id
                                                            )
                                                        }}>
                                                        <i className="simple-icon-plus" />{' '}
                                                        Add View
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            this.props.handleAddTableEvent(
                                                                a.id
                                                            )
                                                        }}>
                                                        <i className="simple-icon-plus" />{' '}
                                                        Add Table Generator
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            this.props.handleRenameSectionEvent(
                                                                a.id
                                                            )
                                                        }}>
                                                        <i className="simple-icon-note" />{' '}
                                                        Rename Section
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            this.props.handleDeleteSectionEvent(
                                                                a.id
                                                            )
                                                        }}>
                                                        <i className="simple-icon-trash" />{' '}
                                                        Delete Section
                                                    </NavLink>
                                                </NavItem>
                                            </>
                                        ) : null}
                                    </Nav>
                                )
                            })}
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({menu}) => {
    const {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount,
    } = menu
    return {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount,
    }
}
export default withRouter(
    connect(mapStateToProps, {
        setContainerClassnames,
        addContainerClassname,
        changeDefaultClassnames,
    })(Sidebar)
)
