import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import {secondsToHHMMSS} from 'SDK/helpers'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class ManualStates extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'ManualStates'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
            manualstates: [],
            activemanualstate: null,
            timer: '',
            loading: true,
        }
    }

    async fetchManualStates() {
        const manualstates = await API.get('manualstates', 2)
        if (manualstates) {
            this.setState({manualstates})
        }
    }

    async fetchActiveManualState() {
        this.setState({loading: true})
        const activemanualstates = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: this.state.manualstates.map((m) => m.name),
                    },
                    timeEnd: null,
                },
            },
            2
        )

        if (activemanualstates && activemanualstates.length) {
            this.setState({activemanualstate: activemanualstates[0]})
        } else {
            this.setState({activemanualstate: null})
        }
        this.setState({loading: false})
    }

    incrementActiveStateTimer() {
        if (this.state.activemanualstate) {
            this.setState({
                timer: secondsToHHMMSS(
                    moment().diff(
                        moment(this.state.activemanualstate.timeStart),
                        'seconds'
                    )
                ),
            })
        }
    }

    async startState(name) {
        this.setState({loading: true})

        const timestamp = moment().toISOString()

        // if there's already an active manual state, end it
        if (this.state.activemanualstate) {
            await API.patch(
                'states/' + this.state.activemanualstate._id,
                {timeEnd: timestamp},
                2
            )
        }
        // start selected state
        await API.post(
            'states',
            {
                deviceId: this.props.deviceId,
                nodeId: this.props.deviceId,
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                name: name,
                metaData: [],
                value: true,
            },
            2
        )

        await this.fetchActiveManualState()

        this.setState({loading: false})
    }

    renderActiveState() {
        const def = this.state.manualstates.find(
            (m) => m.name === this.state.activemanualstate.name
        )
        if (!def) return <></>

        return (
            <Col xs="12" className="mb-1">
                <Card style={{background: def.color, border: '5px solid #fff'}}>
                    <CardBody>
                        <Row>
                            <Col xs="6" style={{color: '#fff', margin: 0}}>
                                <h1>{def.name}</h1>
                            </Col>
                            <Col
                                xs="6"
                                style={{
                                    color: '#fff',
                                    margin: 0,
                                    textAlign: 'right',
                                }}>
                                <h1>{this.state.timer}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{color: '#fff', margin: 0}}>
                                <p style={{margin: 0}}>{def.description}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    renderOptions() {
        let options = this.state.manualstates
        if (this.state.activemanualstate) {
            options = options.filter(
                (o) => o.name !== this.state.activemanualstate.name
            )
        }

        return options.map((o) => {
            return (
                <Col key={o._id} xs="12" sm="3" className="m-0">
                    <Card
                        style={{
                            background: o.color,
                            cursor: 'pointer',
                            border: '5px solid #fff',
                        }}
                        onClick={() => {
                            this.startState(o.name)
                        }}>
                        <CardBody>
                            <Row>
                                <Col xs="12" style={{color: '#fff'}}>
                                    <p style={{textAlign: 'center'}}>
                                        {o.name}
                                    </p>
                                </Col>
                                {/*<Col xs="12" style={{color: '#fff', margin:0}}><p style={{margin:0}}>{o.description}</p></Col>*/}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        })
    }

    async fetchLastPart() {
        const lastPartData = await API.get(
            'states/' + this.props.deviceId + '/Part Count/recent'
        )
        const goodParts = await API.get(
            'states/' + this.props.deviceId + '/Good Part/recent'
        )
        const badParts = await API.get(
            'states/' + this.props.deviceId + '/Reject Part/recent'
        )

        let lastPieceCategoryData = goodParts.concat(badParts)
        lastPieceCategoryData = lastPieceCategoryData.sort(
            (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
        )
        lastPieceCategoryData.reverse()

        if (lastPartData && lastPartData.length) {
            this.setState({
                lastPartTimestamp: moment(lastPartData[0].timestamp),
            })
            if (lastPieceCategoryData && lastPieceCategoryData.length) {
                if (
                    moment(lastPartData[0].timestamp).isSame(
                        moment(lastPieceCategoryData[0].timestamp)
                    )
                ) {
                    this.setState({pieceToCategorize: false})
                } else {
                    this.setState({pieceToCategorize: true})
                }
            } else {
                this.setState({pieceToCategorize: true})
            }
        } else {
            this.setState({pieceToCategorize: false})
        }
    }

    async categorizePiece(status) {
        this.setState({loading: true})

        const timestamp = this.state.lastPartTimestamp.toISOString()

        await API.post(
            'states',
            {
                deviceId: this.props.deviceId,
                nodeId: this.props.deviceId,
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: timestamp,
                name: status === 'reject' ? 'Reject Part' : 'Good Part',
                metaData: [],
                value: true,
            },
            2
        )

        await this.fetchLastPart()

        this.setState({loading: false})
    }

    async componentWillMount() {
        await this.fetchManualStates()
        await this.fetchActiveManualState()
        await this.fetchLastPart()

        this.subscriber.add(this.fetchManualStates, 5000, 'fetchManualStates()')
        this.subscriber.add(
            this.fetchActiveManualState,
            5000,
            'fetchActiveManualStates()'
        )
        this.subscriber.add(
            this.incrementActiveStateTimer,
            1000,
            'incrementActiveStateTimer()'
        )
        this.subscriber.add(this.fetchLastPart, 5000, 'fetchLastPart()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                {this.state.loading && <div className="loading" />}
                <Row>
                    {this.state.activemanualstate ? (
                        this.renderActiveState()
                    ) : (
                        <Col className="text-center">
                            <h5 className="text-center">
                                No activity selected
                            </h5>
                        </Col>
                    )}
                </Row>
                {/*<Row>
          <Col className="text-center"><h5>Click on box below to change activity</h5></Col>
        </Row>*/}
                <Row>{this.renderOptions()}</Row>
                <hr />
                {this.state.pieceToCategorize ? (
                    <Row>
                        <Col xs="6" sm="6" className="mb-0">
                            <Card
                                style={{
                                    background: 'red',
                                    cursor: 'pointer',
                                    border: '5px solid #fff',
                                }}
                                onClick={() => {
                                    this.categorizePiece('reject')
                                }}>
                                <CardBody>
                                    <p style={{color: '#fff', margin: 0}}>
                                        Tag Reject Piece
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="6" sm="6" className="mb-0">
                            <Card
                                style={{
                                    background: 'green',
                                    cursor: 'pointer',
                                    border: '5px solid #fff',
                                }}
                                onClick={() => {
                                    this.categorizePiece('good')
                                }}>
                                <CardBody>
                                    <p style={{color: '#fff', margin: 0}}>
                                        Tag Good Piece
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xs="12" className="mb-0">
                            <Card
                                style={{
                                    background: 'grey',
                                    cursor: 'pointer',
                                    border: '5px solid #fff',
                                }}
                                onClick={this.goodPiece}>
                                <CardBody>
                                    <p
                                        style={{
                                            color: '#fff',
                                            textAlign: 'center',
                                            margin: 0,
                                        }}>
                                        No action required for part quality
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Fragment>
        )
    }
}
