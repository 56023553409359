import React, {useState, useRef, useEffect} from 'react'
import ReactFlow, {
    ReactFlowProvider,
    removeElements,
    getIncomers,
    Controls,
    Background,
} from 'react-flow-renderer'
import {v4 as uuidv4} from 'uuid'
import {cloneDeep, isEqual} from 'lodash-es'

import Sidebar from './sidebar'
import Editor from './editor'
import VariablesModal from './variablesModal'
import DecideModal from './decideModal'
import Toolbar from './toolbar'
import CustomNode from './CustomNode'
import Debugger from './Debugger'
import {validator, ValidatorModal} from './validator'
import './diagram.css'

const DnDFlow = (props) => {
    // refs
    const reactFlowWrapper = useRef(null)

    // state
    const [debugMode, setDebugMode] = useState(false)
    const [reactFlowInstance, setReactFlowInstance] = useState(null)
    const [elements, setElements] = useState(props.flow.elements || [])
    const [settings, setSettings] = useState(props.flow.settings)
    const [variables, setVariables] = useState(props.flow.variables || [])
    const [availableVariables, setAvailableVariables] = useState([])
    const [editingElement, setEditingElement] = useState(null)
    const [editingConnection, setEditingConnection] = useState({
        sourceId: '',
        targetId: '',
        edgeId: '',
    })
    const [editorModalOpen, setEditorModalOpen] = useState(false)
    const [variablesModalOpen, setVariablesModalOpen] = useState(false)
    const [decideModalOpen, setDecideModalOpen] = useState(false)
    const [validatorModalOpen, setValidatorModalOpen] = useState(false)
    const [validatorErrors, setValidatorErrors] = useState([])

    // methods
    const onElementsRemove = (elementsToRemove) => {
        let _variables = variables
        for (let el of elementsToRemove) {
            const variablesRemoved = _variables.filter(
                    (v) => v.transformId == el.id
                ),
                otherElements = elements.filter((other) => other.id !== el.id)
            for (let v of variablesRemoved) {
                for (let e of otherElements) {
                    if (JSON.stringify(e).includes(v.id)) {
                        return alert(
                            `Broken dependencies found: ${e.data.label} is using ${v.name}.`
                        )
                    }
                }
            }
            _variables = _variables.filter((v) => v.transformId !== el.id)
        }
        setVariables([..._variables])
        setElements((els) => removeElements(elementsToRemove, els))
    }
    const onLoad = (_reactFlowInstance) =>
        setReactFlowInstance(_reactFlowInstance)
    const onDragOver = (event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }

    const onConnect = (params) => {
        let edge = {
                id: uuidv4(),
                type: 'step',
                source: params.source,
                sourceHandle: params.sourceHandle,
                target: params.target,
                targetHandle: params.targetHandle,
                data: {opts: {}},
                arrowHeadType: 'arrowclosed',
                style: {
                    strokeWidth: 5,
                },
            },
            els = elements.concat([edge]),
            _edge = els.find((el) => el.id === edge.id),
            source = els.find((el) => el.id === params.source),
            target = els.find((el) => el.id === params.target)
        if (source.data.opts.type === 'DECIDE') {
            if (
                !source.data.opts.outcomes ||
                source.data.opts.outcomes.length === 0
            ) {
                return alert('Define outcomes before making connections.')
            }
            if (
                source.data.opts.outcomes.length >=
                els.filter((edge) => edge.source === source.id).length
            ) {
                setEditingConnection({
                    sourceId: params.source,
                    targetId: params.target,
                    edgeId: edge.id,
                })
                setDecideModalOpen(true)
            }
        } else if (source.data.opts.type === 'LOOP') {
            if (
                params.sourceHandle &&
                params.sourceHandle.startsWith('loopStart_')
            ) {
                target.data.opts.loopStart = true
                source.data.opts.loopStartTransformId = target.id
                target.data.opts.parentTransformId = source.id
            } else if (
                params.targetHandle &&
                params.targetHandle.startsWith('loopEnd_')
            ) {
                //source.data.opts.loopEndTransformId = target.id;
                //target.data.opts.loopEnd = true;
            } else {
                source.data.opts.loopExitTransformId = target.id
            }
        } else if (
            params.targetHandle &&
            params.targetHandle.startsWith('loopEnd_')
        ) {
            target.data.opts.loopEndTransformId = source.id
            _edge.data.opts.ignore = true
            source.data.opts.loopEnd = true
        }

        if (
            source.data.opts.parentTransformId &&
            target.id !== source.data.opts.parentTransformId
        ) {
            target.data.opts.parentTransformId =
                source.data.opts.parentTransformId
        }
        setElements([...els])
    }
    const onEditorSubmit = (params, providedVariables) => {
        let newElements = cloneDeep(elements)
        const idx = newElements.findIndex((el) => el.id === editingElement.id)
        newElements[idx].data = {...newElements[idx].data}
        newElements[idx].data.opts = {...newElements[idx].data.opts, ...params}
        newElements[idx].data.label = newElements[idx].data.opts.name

        let newVariables = cloneDeep(variables)
        for (let v of providedVariables) {
            const idx = newVariables.findIndex((_v) => _v.id === v.id)
            if (idx === -1) {
                newVariables.push(v)
            } else {
                /**
                 * If we sense a change in a variable, we assign it a new id.
                 * This id is then propagated through any elements that use the variable
                 */
                if (!isEqual(newVariables[idx], v)) {
                    const newId = uuidv4(),
                        oldId = v.id
                    newVariables[idx] = v
                    newVariables[idx].id = newId

                    for (let i in newElements) {
                        newElements[i] = JSON.parse(
                            JSON.stringify(newElements[i]).replace(
                                new RegExp(oldId, 'g'),
                                newId
                            )
                        )
                    }
                } else {
                    newVariables[idx] = v
                }
            }
        }

        const errors = validator(newElements, newVariables).filter(
            (a) => a.transformId === editingElement.id
        )
        if (errors.length) {
            setValidatorErrors(errors)
            setValidatorModalOpen(true)
        } else {
            setElements([...newElements])
            setVariables([...newVariables])
            setEditorModalOpen(false)
        }
    }
    const onDrop = (event) => {
        event.preventDefault()

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
        const data = JSON.parse(
            event.dataTransfer.getData('application/reactflow')
        )

        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        })
        const newElement = {
            id: uuidv4(),
            type: data.nodeType,
            position,
            data,
        }
        newElement.data.label = newElement.data.opts.label

        setElements((es) => es.concat(newElement))

        if (newElement.type === 'input') {
            setEditingElement(newElement)
            setEditorModalOpen(true)
        }
    }
    let previousNodes = []
    const fetchPreviousNodes = (element) => {
        const incomers = getIncomers(
            element,
            elements.filter(
                (el) =>
                    !previousNodes
                        .map((prevNode) => prevNode.id)
                        .includes(el.id)
            )
        )
        previousNodes = previousNodes.concat(
            incomers.filter((incomer) => incomer.type !== 'step')
        )
        incomers.forEach(fetchPreviousNodes)
    }
    const onElementSelect = (event, element) => {
        previousNodes = []
        fetchPreviousNodes(element)
        previousNodes.push(element)
        setAvailableVariables(
            previousNodes
                .map((n) => variables.find((v) => v.transformId === n.id))
                .filter((v) => v)
        )
        setEditingElement(elements.find((el) => el.id === element.id))
        setEditorModalOpen(true)
    }

    const setDecisionOutcome = ({id, name}) => {
        let idx
        // edge
        idx = elements.findIndex((edge) => edge.id === editingConnection.edgeId)
        elements[idx].label = name
        elements[idx].data = {opts: {decisionOutcomeId: id}}

        // outcome
        idx = elements.findIndex(
            (node) => node.id === editingConnection.sourceId
        )
        const outcomeIdx = elements[idx].data.opts.outcomes.findIndex(
            (outcome) => outcome.id === id
        )
        elements[idx].data.opts.outcomes[outcomeIdx].transformId =
            editingConnection.targetId

        setElements([...elements])
        setDecideModalOpen(false)
    }

    return (
        <>
            <Toolbar
                elements={elements}
                variables={variables}
                flow={props.flow}
                flows={props.flows}
                backToTable={props.backToTable}
                mqtt={props.mqtt}
                settings={settings}
                setSettings={setSettings}
                debugMode={debugMode}
                setDebugMode={setDebugMode}
                status={props.status}
                data={props.data}
                dataModels={props.dataModels}
            />

            <>
                {debugMode ? (
                    <Debugger
                        mqtt={props.mqtt}
                        flow={props.flow}
                        status={props.status}
                    />
                ) : (
                    <div className="dndflow">
                        <ReactFlowProvider>
                            <Sidebar
                                inputSelected={elements.find(
                                    (el) => el.type === 'input'
                                )}
                                openVariablesModal={() =>
                                    setVariablesModalOpen(true)
                                }
                            />
                            <div
                                className="reactflow-wrapper"
                                ref={reactFlowWrapper}>
                                <ReactFlow
                                    elements={elements}
                                    onConnect={onConnect}
                                    onElementsRemove={onElementsRemove}
                                    onLoad={onLoad}
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                    onNodeDoubleClick={onElementSelect}
                                    snapToGrid={true}
                                    nodeTypes={{
                                        input: CustomNode,
                                        default: CustomNode,
                                        output: CustomNode,
                                    }}
                                    onNodeDragStop={(event, node) => {
                                        let element = elements.find(
                                            (el) => el.id === node.id
                                        )
                                        element.position = node.position
                                        setElements([...elements])
                                    }}>
                                    <Controls />
                                    <Background
                                        variant="dots"
                                        gap={12}
                                        size={0}
                                        style={{background: '#fff'}}
                                    />
                                </ReactFlow>
                            </div>
                        </ReactFlowProvider>
                    </div>
                )}
            </>

            {editorModalOpen ? (
                <Editor
                    close={() => setEditorModalOpen(false)}
                    element={editingElement}
                    dataModels={props.dataModels}
                    fieldTypes={props.fieldTypes}
                    onSubmit={onEditorSubmit}
                    formulas={props.formulas}
                    variables={availableVariables}
                    elements={elements}
                    flowId={props.flow.id}
                />
            ) : null}

            {variablesModalOpen ? (
                <VariablesModal
                    close={() => setVariablesModalOpen(false)}
                    variables={variables}
                    elements={elements}
                />
            ) : null}

            {decideModalOpen ? (
                <DecideModal
                    submit={setDecisionOutcome}
                    editingConnection={editingConnection}
                    elements={elements}
                />
            ) : null}

            {validatorModalOpen ? (
                <ValidatorModal
                    errors={validatorErrors}
                    toggle={() => setValidatorModalOpen(!validatorModalOpen)}
                />
            ) : null}
        </>
    )
}

export default DnDFlow
