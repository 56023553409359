import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
} from 'reactstrap'
import Switch from 'rc-switch'
import {CirclePicker} from 'react-color'
import autobind from 'auto-bind'
import Select from 'react-select'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

const OptionTile = (props) => {
    return (
        <Col xs="12" className="mb-2">
            <Card style={{cursor: 'pointer'}} onClick={props.clickHandler}>
                <CardBody>
                    <h4>{props.name}</h4>
                    <p>{props.description}</p>
                </CardBody>
            </Card>
        </Col>
    )
}

const CopyOptions = (props) => {
    if (!props.deviceId) {
        return <Fragment />
    } else {
        const flows = props.flows.filter(
            (o) => o.deviceId === props.deviceId.value
        )
        return (
            <Row className="mt-4">
                {flows.map((f, i) => {
                    return (
                        <Col key={i} xs="6" sm="6" className="mb-2">
                            <Card
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    props.handleClick(f._id)
                                }}>
                                <CardBody>
                                    <h5>{f.name}</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        )
    }
}

const TemplateOptions = (props) => {
    return (
        <Row className="mt-4">
            {props.templates
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((f, i) => {
                    return (
                        <Col key={i} xs="12" sm="12" className="mb-2">
                            <Card
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    props.handleClick(f)
                                }}>
                                <CardBody>
                                    <h5>{f.name}</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
        </Row>
    )
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            assets: [],
            loading: false,
            copy: false,
            init: true,
            template: false,
            copyDevieId: null,
        }

        this.TILE_OPTIONS = [
            {
                name: 'DIY',
                description: 'Build your own',
                clickHandler: () => {
                    this.props.toggleModal()
                },
            },
            {
                name: 'Copy Existing',
                description: 'Start with an from existing data flow',
                clickHandler: () => {
                    this.setState({init: false, copy: true, template: false})
                },
            },
            {
                name: 'Start From Template',
                description: 'Start with an Accumine Marketplace data flow',
                clickHandler: () => {
                    this.setState({init: false, copy: false, template: true})
                },
            },
        ]
    }

    async componentWillMount() {
        let assets = await API.get('devices'),
            flows = await API.get('flows', 2),
            templates = await API.get('flows/templates', 2)
        this.setState({
            assets: assets.map((x) => {
                return {label: x.name, value: x.deviceId}
            }),
            flows: flows,
            templates,
        })
    }

    render() {
        const {modal, toggleModal, flow} = this.props
        const name = flow ? flow.name : ''
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Flow Wizard</ModalHeader>
                    <ModalBody>
                        {this.state.init ? (
                            <Row className="mb-2">
                                {this.TILE_OPTIONS.map((t, i) => {
                                    return <OptionTile key={i} {...t} />
                                })}
                            </Row>
                        ) : null}
                        {this.state.copy ? (
                            <Fragment>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="logic"
                                    value={this.state.copyAssetSelection}
                                    onChange={(copyAssetSelection) => {
                                        this.setState({copyAssetSelection})
                                    }}
                                    options={this.state.assets}
                                />
                                <CopyOptions
                                    flows={this.state.flows}
                                    deviceId={this.state.copyAssetSelection}
                                    handleClick={this.props.loadFlow}
                                />
                            </Fragment>
                        ) : null}
                        {this.state.template ? (
                            <Fragment>
                                <h5>System Templates</h5>
                                <TemplateOptions
                                    templates={this.state.templates.filter(
                                        (a) => a.systemTemplate
                                    )}
                                    handleClick={this.props.loadFlow}
                                />
                                <hr />
                                <h5>User Created Templates</h5>
                                <TemplateOptions
                                    templates={this.state.templates.filter(
                                        (a) => !a.systemTemplate
                                    )}
                                    handleClick={this.props.loadFlow}
                                />
                            </Fragment>
                        ) : null}
                    </ModalBody>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
