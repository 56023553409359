import React, { Component, Fragment } from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Label,
    Form,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import { secondsToHHMMSS } from 'SDK/helpers'

// Material Entry
import Material from 'SDK/ui/AMC2023/Material/Material'

import './DowntimeEntryTile.css'

const color = (name) => {
    if (name === 'In-Cycle') return 'rgb(46, 204, 113)'
    else if (
        name === 'Downtime Under 5 Minutes' ||
        name === 'Uncategorized Downtime'
    )
        return 'rgb(231, 76, 60)'
    else return '#f0ad4e'
}
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

const Spinner = () => {
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'

                }}
            >
                <p style={{ color: '#000000', fontWeight: '500'}}>Submitting change</p>
                <div
                    style={{
                        border: '5px solid rgba(0, 0, 0, 0.1)',
                        borderTop: '5px solid #007bff',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        left: '50%',
                        animation: 'spin 1s linear infinite',
                    }}
                >
                </div>
            </div>
        </>
    );
};

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            forceEntry: false,
            status: 'unknown',
            selection: null,
            commentEntry: '',
        }
    }

    async determineStatus() {
        // running, downUnderThreshold, downOverThresholdWithNoEntry, downOverThresholdWithEntry, unknown

        const data = this.props.data /* API.post('historical/raw', {
      query: {
        deviceId: this.props.deviceId,
        name: {$in: ['In-Cycle', 'Downtime', 'Downtime Category', 'Downtime Reason', 'Downtime Comment', 'Downtime User'] },
        timeEnd: null
      }
    }, 2);*/

        if (!data || !data.length) {
            return
        }

        let status = '',
            color = 'lightgray',
            timestamp = null,
            category = '',
            reason = '',
            comment = '',
            user = '',
            downtime = data.find((o) => o.name === 'Downtime'),
            inCycle = data.find((o) => o.name === 'In-Cycle'),
            downtimeCategory = data.find((o) => o.name === 'Downtime Category'),
            downtimeReason = data.find((o) => o.name === 'Downtime Reason'),
            downtimeComment = data.find((o) => o.name === 'Downtime Comment'),
            downtimeUser = data.find((o) => o.name === 'Downtime User')

        if (inCycle) {
            status = 'running'
            color = 'rgb(46, 204, 113)'
            timestamp = moment(inCycle.timeStart)
        } else if (
            downtime &&
            moment().diff(moment(downtime.timeStart), 'seconds') <
            this.downtimeThreshold &&
            !downtimeCategory &&
            !downtimeReason
        ) {
            timestamp = moment(downtime.timeStart)
            status = 'downUnderThreshold'
            color = 'rgb(231, 76, 60)'
        } else if (
            (downtime &&
                moment().diff(moment(downtime.timeStart), 'seconds') >=
                this.downtimeThreshold) ||
            (downtimeCategory && downtimeReason)
        ) {
            timestamp = moment(downtime.timeStart)
            if (downtimeCategory && downtimeReason) {
                status = 'downOverThresholdWithEntry'
                color = '#f0ad4e'
                category = downtimeCategory.value
                reason = downtimeReason.value
                if (downtimeComment) comment = downtimeComment.value
                if (downtimeUser) user = downtimeUser.value
            } else {
                color = 'rgb(231, 76, 60)'
                status = 'downOverThresholdWithNoEntry'
            }
        }

        this.setState({
            status,
            color,
            timestamp,
            category,
            reason,
            comment,
            user
        })
    }

    updateElapsed() {
        this.setState({
            elapsed: moment().diff(this.state.timestamp),
        })
    }

    handleDowntimeSelection(selection) {
        this.setState({ selection })
    }

    submit() {
        const userId = JSON.parse(localStorage['userObject'])._id
        const record = this.state.reasoncodes.find(
            (a) => a._id === this.state.selection.value
        )
        API.post(
            'tablets/data',
            [
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: record.reason,
                    name: 'Downtime Reason',
                    src: 'RealTimeDowntimeEntry/DowntimeEntryTile.js',
                },
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: record.category,
                    name: 'Downtime Category',
                    src: 'RealTimeDowntimeEntry/DowntimeEntryTile.js',
                },
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: this.state.commentEntry,
                    name: 'Downtime Comment',
                    src: 'RealTimeDowntimeEntry/DowntimeEntryTile.js',
                },
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: userId,
                    name: 'Downtime User',
                    src: 'RealTimeDowntimeEntry/DowntimeEntryTile.js',
                },
            ],
            2
        )
        this.setState({
            forceEntry: false,
            selection: null,
            commentEntry: '',
        })
    }

    async undoReason() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: [
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        for (let i in data) {
            API.remove('states/' + data[i]._id, 2)
        }
    }

    async changeReason() {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: [
                            'Downtime',
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        const timeEnd = moment().toDate()
        for (let i in data) {
            data[i].timeEnd = timeEnd
            await API.patch('states/' + data[i]._id, data[i], 2)
        }

        const downtimeState = data.find((d) => d.name === 'Downtime')

        let newDowntime = {
            nodeId: downtimeState ? downtimeState.nodeId : this.props.deviceId,
            deviceId: this.props.deviceId,
            value: true,
            name: 'Downtime',
            timestamp: timeEnd,
            timeStart: timeEnd,
            timeEnd: null,
            metaData: [],
        }
        await API.post('states', newDowntime, 2)
        this.setState({ forceEntry: true })
    }

    renderRunningStatus() {
        return (
            <Row>
                <Col className="text-center">
                    <h1>
                        {secondsToHHMMSS(Math.floor(this.state.elapsed / 1000))}
                    </h1>
                    <h5>Asset is running</h5>
                </Col>
            </Row>
        )
    }

    renderDowntimeUnderThresholdStatus() {
        return (
            <Row>
                <Col className="text-center">
                    <h1>
                        {secondsToHHMMSS(Math.floor(this.state.elapsed / 1000))}
                    </h1>
                    <h5>Asset is down</h5>
                </Col>
            </Row>
        )
    }

    handleDowntimeCommentEntry(e) {
        this.setState({ commentEntry: e.target.value })
    }

    renderDowntimeOverThresholdWithNoEntry() {
        let reasoncodes = this.state.reasoncodes.filter((code) => {
            if (!code.assets) {
                return true
            } else {
                return code.assets.find((a) => a === this.props.deviceId)
            }
        })

        let categories = [...new Set(reasoncodes.map((r) => r.category))]
        categories = categories.sort((a, b) => a.localeCompare(b))

        let options = categories.map((c) => {
            const suboptions = reasoncodes
                .filter((r) => r.category === c)
                .map((r) => {
                    return {
                        label: r.reason,
                        value: r._id,
                    }
                })
                .sort((a, b) => a.label.localeCompare(b.label))

            return {
                label: c,
                options: suboptions,
            }
        })
        return (
            <Row>
                <Col className="text-center">
                    <Row>
                        <Col>
                            <h1>
                                {secondsToHHMMSS(
                                    Math.floor(this.state.elapsed / 1000)
                                )}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form>
                                <Label className="form-group has-top-label">
                                    <Select
                                        components={{ Input: CustomSelectInput }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="reason"
                                        value={this.state.selection}
                                        onChange={this.handleDowntimeSelection}
                                        options={options}
                                        styles={customStyles}
                                    />
                                    <IntlMessages id="Reason" />
                                </Label>
                                <Label className="form-group has-top-label">
                                    <Input
                                        type="textarea"
                                        name="commentEntry"
                                        rows={5}
                                        value={this.state.commentEntry}
                                        onChange={
                                            this.handleDowntimeCommentEntry
                                        }
                                    />
                                    <IntlMessages id={'Optional Comment'} />
                                </Label>
                                <Button
                                    className="btn btn-primary btn-block"
                                    disabled={!this.state.selection}
                                    onClick={this.submit}>
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    handleSpinner = () => {
        this.setState({ isLoading: true });
        const timeout = setTimeout(() => {
            this.setState({ isLoading: false });
            clearTimeout(timeout)
        }, 10000);
    }

    renderDowntimeOverThresholdWithEntry() {
        return (
            <Row>
                <Col className="text-center">
                    <Row>
                        <Col>
                            <h1>
                                {secondsToHHMMSS(
                                    Math.floor(this.state.elapsed / 1000)
                                )}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                {this.state.category} > {this.state.reason}
                            </h5>
                            <p>{this.state.comment}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6">
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.undoReason()
                                    this.handleSpinner()
                                }}>
                                Undo
                            </Button>
                        </Col>
                        <Col xs="12" sm="6">
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.changeReason()
                                    this.handleSpinner()
                                }}>
                                Change
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    async componentWillMount() {
        //const devices = await API.get('devices');
        this.downtimeThreshold = this.props.downtimeThresholdSeconds || 300
        this.setState({
            name: this.props.name,
            reasoncodes: this.props.reasoncodes,
        })
        this.subscriber.add(this.determineStatus, 5000, 'fetchStatus()')
        this.subscriber.add(this.updateElapsed, 500, 'updateElapsed()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const running = this.state.status === 'running',
            downUnderThreshold =
                this.state.status === 'downUnderThreshold' &&
                !this.state.forceEntry,
            downOverThresholdWithNoEntry =
                this.state.status === 'downOverThresholdWithNoEntry' ||
                this.state.forceEntry,
            downOverThresholdWithEntry =
                this.state.status === 'downOverThresholdWithEntry' &&
                !this.state.forceEntry

        const hmi = document.documentElement.offsetWidth === 1024

        const color =
            this.props.flash && downOverThresholdWithNoEntry
                ? 'black'
                : this.state.color

        return (
            <Fragment>
                <Col xs="12" sm={hmi ? '6' : '4'} className="mb-4">
                    <Card
                        style={{
                            height: '100%',
                            backgroundColor: color,
                            color: '#fff',
                        }}>
                        {this.state.isLoading ?
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(255, 255, 255, 0.5)',
                                    zIndex: 999
                                }}>
                                <Spinner color="primary" />
                            </div> :
                            <CardBody>
                                <CardTitle className="text-center">
                                    <strong>
                                        {this.state.name} - Downtime Reason Entry
                                    </strong>
                                </CardTitle>
                                {running ? this.renderRunningStatus() : null}
                                {downUnderThreshold
                                    ? this.renderDowntimeUnderThresholdStatus()
                                    : null}
                                {downOverThresholdWithNoEntry
                                    ? this.renderDowntimeOverThresholdWithNoEntry()
                                    : null}
                                {downOverThresholdWithEntry
                                    ? this.renderDowntimeOverThresholdWithEntry()
                                    : null}
                            </CardBody>
                        }
                        {this.props.materialEntry ? (
                            <CardFooter>
                                <Material
                                    options={[{ value: 123, label: 'PART 1' }]}
                                    deviceId={this.props.deviceId}
                                    deviceName={this.state.name}
                                />
                            </CardFooter>
                        ) : null}
                    </Card>
                </Col>
            </Fragment>
        )
    }
}
