import React, {Component, Fragment} from 'react'
import {Input} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class RealTimeAggregation extends Component {
    id = 'RealTimeAggregation'
    field = 'aggregation'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : 1

        this.state = {
            value: initialValue,
            color: 'rgb(215,215,215)',
            valid: false,
        }

        if (this.props && this.props.cb) {
            this.props.cb('aggregation', parseInt(initialValue))
        }
    }

    handleValueChange(e) {
        const value = e.target.value

        if (value < 1) {
            this.setState({
                valid: false,
                color: '#dc3545',
                message: 'Must be more than 1 second aggregation',
            })
        } else {
            this.setState({
                value,
                color: 'rgb(215,215,215)',
                message: '',
            })
        }

        if (this.props.cb) {
            this.props.cb('aggregation', parseInt(value))
        }
    }

    render() {
        return (
            <Fragment>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <label>Data Aggregation (seconds):</label>
                <Input
                    value={this.state.value}
                    type="number"
                    style={{borderColor: this.state.color}}
                    onChange={this.handleValueChange}
                />
            </Fragment>
        )
    }
}
