import React, {Component, Fragment} from 'react'
import {Tooltip} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        const isMobile = document.documentElement.offsetWidth < 992
        return (
            <Fragment>
                {isMobile ? (
                    <div className="position-relative d-inline-block">
                        <div className="dropdown-menu-right dropdown show">
                            <button
                                id="mobileLogoutButton"
                                className="header-icon btn btn-empty"
                                onClick={this.props.handleLogout}>
                                <i className="simple-icon-login"></i>
                            </button>
                        </div>
                    </div>
                ) : null}
            </Fragment>
        )
    }
}
