import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Button,
} from 'reactstrap'
import autoBind from 'auto-bind'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'

import TextInput from '../../../common/text-input'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        const {loadedSavedReport} = this.props

        this.state = {
            report_name: loadedSavedReport ? loadedSavedReport.report_name : '',
            cache_enabled: loadedSavedReport
                ? loadedSavedReport.cache_enabled
                : false,
            cache_ttl_hours: loadedSavedReport
                ? loadedSavedReport.cache_ttl_hours
                : 24,
        }
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={open}
                    toggle={toggle}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggle}>
                        <strong>Save Report</strong>
                    </ModalHeader>
                    <ModalBody>
                        <h5 style={{color: '#145388'}}>REPORT NAME</h5>
                        <p className="text-muted">
                            You can place "/" in the Report Name to organize the
                            reports in a directory like structure.
                        </p>
                        <p className="text-muted">
                            For example, a report name of "HR/Employees Report"
                            will add "Employees Report" in the "HR" folder.
                        </p>

                        <TextInput
                            onValueChange={(report_name) =>
                                this.setState({report_name})
                            }
                            initial={this.state.report_name}
                            onValidChange={() => {}}
                            fieldName="Report Name"
                            existing={this.props.savedReports.map(
                                (a) => a.report_name
                            )}
                        />
                        <hr />
                        <h5 style={{color: '#145388'}}>ENABLE CACHING</h5>
                        <p className="text-muted">
                            Enabling caching will save report data for a
                            specified period of time.
                        </p>
                        <Label>
                            <Switch
                                checked={this.state.cache_enabled}
                                onChange={(cache_enabled) =>
                                    this.setState({cache_enabled})
                                }
                            />{' '}
                            Use caching
                        </Label>

                        {this.state.cache_enabled ? (
                            <>
                                <hr />
                                <h5 style={{color: '#145388'}}>
                                    CACHE TIME-TO-LIVE (TTL)
                                </h5>
                                <p className="text-muted">
                                    How long should the cached report stick
                                    around for?
                                </p>
                                <TextInput
                                    onValueChange={(cache_ttl_hours) =>
                                        this.setState({cache_ttl_hours})
                                    }
                                    onValidChange={() => {}}
                                    initial={this.state.cache_ttl_hours}
                                    fieldName="Time To Live (hours)"
                                    existing={[]}
                                />
                            </>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            block
                            onClick={() => this.props.saveReport(this.state)}>
                            SAVE REPORT
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
