import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button, Collapse} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'
import Cron, {HEADER} from 'react-cron-generator'

import './cronbuilder.css'
import TextInput from '../../../../common/text-input'
import TimezoneSelect from 'SDK/ui/TimezoneSelect'

export default (props) => {
    const [advancedIsOpen, toggleAdvanced] = useState(false)
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'ScheduledTrigger',
        description: props.element.data.opts.description || '',
        cron: props.element.data.opts.cron || '',
        variableId: props.element.data.opts.variableId || null,
        timezone: props.element.data.opts.timezone || localStorage['timezone'],
        submitted: props.element.data.opts.submitted || true,
    })

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: 'Flow Start Timestamp',
            transformId: props.element.id,
            type: 'Timestamp',
            multi: false,
        }

        parameters.variableId = variable.id
        parameters.cron = parameters.cron.split(' ')
        parameters.cron.pop()
        parameters.cron = parameters.cron.join(' ')

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Cron
                                onChange={(v) => {
                                    parameters.cron = v
                                    setParameters({...parameters})
                                }}
                                value={parameters.cron}
                                showResultText={true}
                                showResultCron={false}
                                options={{
                                    headers: [
                                        HEADER.MINUTES,
                                        HEADER.HOURLY,
                                        HEADER.DAILY,
                                        HEADER.WEEKLY,
                                        HEADER.MONTHLY,
                                    ],
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <p
                                color="link"
                                style={{cursor: 'pointer'}}
                                onClick={() => toggleAdvanced(!advancedIsOpen)}>
                                {advancedIsOpen ? (
                                    <>
                                        <i className="simple-icon-arrow-down" />{' '}
                                        Hide advanced
                                    </>
                                ) : (
                                    <>
                                        <i className="simple-icon-arrow-right" />{' '}
                                        Show advanced
                                    </>
                                )}
                            </p>
                            <Collapse isOpen={advancedIsOpen}>
                                <FormGroup>
                                    <TimezoneSelect
                                        default={parameters.timezone}
                                        onChange={(timezone) => {
                                            parameters.timezone = timezone
                                            setParameters({...timezone})
                                        }}
                                    />
                                </FormGroup>
                            </Collapse>
                        </FormGroup>
                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
