import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'

import Notes from './Notes'
import MaintenanceApp from './Maintenance'

const computeHeight = () => {
    const w = document.documentElement.offsetWidth,
        h = document.documentElement.offsetHeight

    if (w < 769) {
        return h - 70
    } else if (w < 1200) {
        return h - 80
    } else if (w < 1440) {
        return h - 90
    } else {
        return h - 120
    }
}

const computeWidth = () => {
    const w = document.documentElement.offsetWidth

    if (w < 769) {
        return 0
    } else {
        return 10
    }
}
export default class SidePane extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    toggleApp(app) {
        let state = this.state
        state[app] = !state[app]
        this.setState({state})
    }

    render() {
        return (
            <Fragment>
                <div
                    className="side-panel"
                    style={{
                        backgroundColor: '#fff',
                        position: 'fixed',
                        right: 0,
                        width: '75px', //computeWidth() + 'vw',
                        height: '100%',
                        zIndex: 100,
                        boxShadow: '-5px 0px 47px -16px rgba(0,0,0,0.75)',
                    }}>
                    <Row
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            this.toggleApp('notes')
                        }}>
                        <Col className="text-center mt-2">
                            <i
                                className="iconsmind-Pen"
                                style={{fontSize: '12pt', fontWeight: 100}}></i>
                            <p style={{fontSize: '9pt', fontWeight: 900}}>
                                Notes
                            </p>
                        </Col>
                    </Row>
                    <Row
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            this.toggleApp('maintenance')
                        }}>
                        <Col className="text-center mt-2">
                            <i
                                className="iconsmind-Maximize"
                                style={{fontSize: '12pt', fontWeight: 100}}></i>
                            <p style={{fontSize: '9pt', fontWeight: 900}}>
                                Maintenance
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr style={{margin: 0}} />
                        </Col>
                    </Row>
                    <Row
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            alert(
                                'Talk to your Accumine Account Manager to enable further integrations.'
                            )
                        }}>
                        <Col className="text-center mt-2">
                            <i
                                className="iconsmind-Add"
                                style={{fontSize: '18pt', fontWeight: 100}}></i>
                        </Col>
                    </Row>
                </div>
                <Notes
                    open={this.state.notes}
                    close={() => {
                        this.toggleApp('notes')
                    }}
                    computeHeight={computeHeight}
                    mqttClient={this.props.mqttClient}
                />
                <MaintenanceApp
                    open={this.state.maintenance}
                    close={() => {
                        this.toggleApp('maintenance')
                    }}
                    computeHeight={computeHeight}
                    mqttClient={this.props.mqttClient}
                />
            </Fragment>
        )
    }
}
