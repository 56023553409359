import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class ShiftPicker extends Component {
    id = 'ShiftPicker'
    field = 'selectedShifts'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props && this.props.existing
                ? this.props.existing.map((state) => {
                      return {label: state, value: state}
                  })
                : []

        this.state = {
            widgets: [],
            selectedStates: initialValue,
            color: 'rgb(215,215,215)',
            reasoncodes: [],
        }

        if (this.props && this.props.cb) {
            this.props.cb('selectedShifts', initialValue)
        }
    }

    async componentWillMount() {
        await this.getShifts()
    }

    async getShifts() {
        try {
            const shifts = await API.get('shifts', 2)
            this.setState({
                shifts: shifts.map((shift) => {
                    return {value: shift.name, label: shift.name}
                }),
            })
        } catch (error) {
            console.log('Could not load shifts', error)
        }
    }

    render() {
        return (
            <Fragment>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <label>Available Shifts:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="shifts"
                    isMulti
                    value={this.state.shift}
                    onChange={(selectedShifts) => {
                        this.setState({selectedShifts})
                        if (this.props && this.props.cb) {
                            this.props.cb(
                                'selectedShifts',
                                selectedShifts.map((shift) => shift.value)
                            )
                        }
                    }}
                    options={this.state.shifts}
                />
            </Fragment>
        )
    }
}
