import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Slider from 'rc-slider'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'

import 'rc-slider/assets/index.css'

import * as API from 'SDK/api'

const sliderHandle = (props) => {
    const {value, dragging, index, offset, ...restProps} = props
    const positionStyle = {
        position: 'absolute',
        left: `${offset}%`,
    }
    const denormalized = Math.floor(value * (99 - 12) + 12)
    //console.log('denormalized: ' + denormalized)
    let volts = Math.round((denormalized / 99) * 5 * 100) / 100
    return (
        <Fragment key={index}>
            <div className="rc-slider-tooltip" style={positionStyle}>
                <p style={{whiteSpace: 'nowrap', marginBottom: '0px'}}>
                    {volts + ' volts'}
                </p>
            </div>
            <Slider.Handle
                value={value}
                placement="top"
                offset={offset}
                {...restProps}
            />
        </Fragment>
    )
}

export class SliderTooltip extends React.Component {
    render() {
        return (
            <Slider
                handle={this.props.handle || sliderHandle}
                {...this.props}
            />
        )
    }
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )
        console.log(this.node)

        this.originalVref = this.node.settings.vref.value
        this.state = {
            reference: (this.originalVref - 12) / (99 - 12),
            showActions: false,
            show: true,
        }
    }
    async save() {
        try {
            let node = {...this.node}
            node.settings.vref = {
                value: this.state.reference,
                pendingUpdate: true,
            }

            let response = await API.patch(
                'nodes/' + node.nodeId,
                {
                    settings: node.settings,
                },
                2
            )
            if (response.success) {
                this.setState({showActions: false})
                this.originalVref = this.state.reference
            } else {
                return alert(
                    'There was an error saving your change. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error saving your change. Please try again.'
            )
        }
    }
    handleChange(reference) {
        let updated = Math.floor(reference * (99 - 12) + 12)
        if (updated !== this.originalVref) {
            this.setState({
                showActions: true,
                reference: updated,
            })
        } else {
            this.setState({showActions: false, reference: updated})
        }
    }
    revert() {
        this.setState({
            show: false,
        })
        const self = this
        setTimeout(() => {
            self.setState({
                show: true,
            })
        }, 100)
    }

    render() {
        let {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Row>
                    <Col>
                        <div className="text-center">
                            <h4 className="text-center">Reference Voltage</h4>
                            <small className="text-center text-muted">
                                Use this setting to amplify or dampen captured
                                data signals.
                            </small>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {this.state.show ? (
                            <SliderTooltip
                                min={0}
                                max={1}
                                step={0.05}
                                defaultValue={this.state.reference}
                                className="mb-5"
                                onChange={(reference) => {
                                    this.handleChange(reference)
                                }}
                            />
                        ) : null}
                    </Col>
                </Row>
                {this.state.showActions ? (
                    <Row className="mt-2">
                        <Col xs="12" className="text-right">
                            <Button
                                size="xs"
                                className="mr-1"
                                color="success"
                                onClick={this.save}>
                                Save
                            </Button>
                            {/*<Button size="xs" className="" color="danger" onClick={this.revert}>Revert</Button>*/}
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
