import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, CardFooter, Container} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import Factory from '../Common/Twin/Factory'
import AssetUsageDetail from './AssetUsageDetail'
import AssetUsageSummary from './AssetUsageSummary'
import PeakUsageSummary from './PeakUsageSummary'
import Wizard from './Wizard'
import Controls from './Controls'

import CapacityAnalytics from '../Common/Twin/CapacityAnalytics'
import {
    capacityRecommendation,
    maximumPeakUsage,
    aggregateStates,
} from '../Common/Twin/Metrics'

export default class IPSCapacity extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'IPSCapacity'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        const windowHeight = document.documentElement.offsetHeight,
            windowWidth = document.documentElement.offsetWidth

        this.height =
            windowWidth > 1439 ? windowHeight - 210 : windowHeight - 180

        this.capacityAnalyzer = new CapacityAnalytics()

        this.state = {
            generationUnix: 0,
            timerange: null,
            assets: null,
        }
    }

    async generate({timerange, assetGroup, schedule}) {
        this.setState({loading: true})
        const assets = assetGroup
                ? this.factory._assets.filter((a) =>
                      a.groups.find((g) => g === assetGroup)
                  )
                : this.factory._assets,
            timeStart = timerange.timerange[0],
            timeEnd = timerange.timerange[1]

        this.capacityAnalyzer
            .assets(assets)
            .from(timeStart)
            .to(timeEnd)
            .schedule(schedule || 'alltime')

        await this.capacityAnalyzer.fetch()

        this.setState({
            timerange: timerange.timerange,
            assets,
            schedule,
            generationUnix: moment().unix(),
            recommendation: await capacityRecommendation(
                this.capacityAnalyzer,
                assetGroup || 'All Assets'
            ),
            maximumPeakUsage: await maximumPeakUsage(this.capacityAnalyzer),
            aggregatedStates: await aggregateStates(this.capacityAnalyzer),
            loading: false,
        })
    }

    async componentWillMount() {
        this.factory = new Factory()
        await this.factory.init()
        this.capacityAnalyzer
            .factory(this.factory)
            .state(['In-Cycle', 'Downtime', 'Offline'])

        this.setState({ready: true})
    }

    render() {
        return (
            <Fragment>
                {this.state.ready ? (
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <Controls
                                    factory={this.factory}
                                    onChange={this.generate}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                sm="6"
                                md="4"
                                className="mb-2"
                                style={{paddingRight: '5px'}}>
                                <Card style={{height: this.height}}>
                                    <CardBody
                                        style={{
                                            overflow: 'hidden',
                                            marginBottom: '10px',
                                        }}>
                                        <AssetUsageDetail
                                            data={this.state.aggregatedStates}
                                            loading={this.state.loading}
                                        />
                                    </CardBody>
                                    <CardFooter>
                                        <AssetUsageSummary
                                            data={this.state.aggregatedStates}
                                        />
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col
                                xs="12"
                                sm="6"
                                md="8"
                                style={{paddingLeft: '5px'}}>
                                <Row>
                                    <Col xs="4" style={{paddingRight: '0px'}}>
                                        <Card
                                            style={{
                                                height: this.height * 0.15,
                                                overflow: 'hidden',
                                            }}>
                                            <CardBody style={{paddingTop: 10}}>
                                                <p className="text-muted">
                                                    Maximum Peak Usage
                                                </p>
                                                {this.state.maximumPeakUsage ? (
                                                    <h2>
                                                        {
                                                            this.state
                                                                .maximumPeakUsage
                                                        }
                                                        <small>%</small>
                                                    </h2>
                                                ) : (
                                                    <h2>
                                                        <small>No data</small>
                                                    </h2>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="8" style={{paddingRight: '0px'}}>
                                        <Card
                                            style={{
                                                height: this.height * 0.15,
                                                overflow: 'hidden',
                                            }}>
                                            <CardBody style={{paddingTop: 10}}>
                                                <p className="text-muted">
                                                    Recommended Action
                                                </p>
                                                <h2>
                                                    {this.state.recommendation}
                                                </h2>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Card
                                            style={{
                                                height: this.height * 0.35,
                                                overflow: 'hidden',
                                            }}>
                                            <CardBody
                                                style={{
                                                    height: '100%',
                                                    paddingTop: 10,
                                                }}>
                                                <PeakUsageSummary
                                                    unix={
                                                        this.state
                                                            .generationUnix
                                                    }
                                                    assets={this.state.assets}
                                                    timerange={
                                                        this.state.assets
                                                    }
                                                    height={this.height * 0.35}
                                                    capacityAnalyzer={
                                                        this.capacityAnalyzer
                                                    }
                                                    loading={this.state.loading}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Card
                                            style={{
                                                height: this.height * 0.47,
                                                overflow: 'hidden',
                                            }}>
                                            <CardBody
                                                style={{
                                                    height: '100%',
                                                    paddingTop: 10,
                                                }}>
                                                <Wizard
                                                    unix={
                                                        this.state
                                                            .generationUnix
                                                    }
                                                    assets={this.state.assets}
                                                    timerange={
                                                        this.state.assets
                                                    }
                                                    height={this.height * 0.35}
                                                    shifts={this.state.schedule}
                                                    capacityAnalyzer={
                                                        this.capacityAnalyzer
                                                    }
                                                    loading={this.state.loading}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
