import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import autobind from 'auto-bind'
import Switch from 'rc-switch'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DataTablePagination from 'components/DataTables/pagination'
import PropTypes from 'prop-types'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {getReportAssets} from 'SDK/api/common'

const columns = [
    {
        Header: 'Reason',
        accessor: 'name',
    },
    {
        Header: 'Hours',
        accessor: 'hours',
        width: 75,
    },
]

export default class DowntimeReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'DowntimeReport'
    requiredOutputs = ['Downtime', 'Downtime Reason']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            ready: false,
            loading: false,
            assets: [],
            selectedAssets: [],
            data: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            byShift: false,
            hours: false,
            shifts: [],
            type: '',
            reasoncodes: [],
            reasoncoderecords: [],
        }
    }
    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }
    handleAssetChange(e) {
        this.setState({
            selectedAssets: e,
        })
    }
    async getAssets() {
        const assets = await getReportAssets()
        if (!assets) return alert('Could not fetch factory assets.')

        this.setState({
            assets: assets,
            selectedAssets: assets.map((a, i) => {
                return {label: a.name, value: a.deviceId, key: i}
            }),
        })
    }

    async getShifts() {
        const shifts = await API.get('shifts')
        if (!shifts) return alert('Could not fetch factory shifts.')

        this.setState({
            shifts: [...new Set(shifts.map((x) => x.name))],
        })
    }

    async getReasonCodes() {
        let reasoncodes = await API.get('codes')
        if (!reasoncodes)
            return alert('There was an error fetching factory reason codes.')

        this.setState({
            reasoncodes: reasoncodes.map((code) => code.reason),
            reasoncoderecords: reasoncodes,
        })
    }

    generate() {
        if (this.state.byShift) {
            this.generateShift()
        } else {
            this.generateTotal()
        }
    }

    async generateTotal() {
        // validation
        if (this.state.selectedAssets.length === 0)
            return alert('At least one asset must be selected.')

        this.setState({loading: true})

        await this.getReasonCodes()

        let promises = []
        const {reasoncodes, selectedAssets, start, end} = this.state

        selectedAssets.forEach((asset) => {
            reasoncodes.forEach((code) => {
                const reasoncode = this.state.reasoncoderecords.find(
                    (r) => r.reason === code
                )
                if (reasoncode) {
                    if (
                        (reasoncode.assets &&
                            reasoncode.assets.find((a) => a === asset.value)) ||
                        !reasoncode.assets
                    ) {
                        promises.push(
                            API.post('historical', {
                                timeStart: start.toISOString(),
                                timeEnd: end.toISOString(),
                                state: 'Downtime Reason',
                                deviceId: asset.value,
                                metaDataFilter: [['downtimereason', code]],
                                unit: 'years',
                            })
                        )
                    }
                }
            })
        })

        const results = await Promise.all(promises)
        let dom = []

        selectedAssets.forEach((asset) => {
            let codes = results.filter((r) => r.query.deviceId === asset.value)
            codes = codes.map((c) => {
                return {
                    name: c.query.metaDataFilter[0][1],
                    percent: Math.floor(c.durationPercentage[0] * 100),
                    hours: Math.round((c.duration[0] / 60) * 100) / 100,
                }
            })
            codes.sort((a, b) => b.hours - a.hours)
            codes = codes.map((c) => {
                c.percent += '%'
                if (c.hours > 0 && c.percent === '0%') c.percent = '< 1%'
                return c
            })
            codes = codes.filter((c) => c.hours > 0)
            dom.push(
                <Col key={asset.value} xs="12" sm="6" className="mb-2">
                    <Card>
                        <CardHeader className="text-center">
                            <h4>{asset.label}</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <ReactTable
                                        defaultPageSize={5}
                                        data={codes}
                                        columns={columns}
                                        minRows={0}
                                        PaginationComponent={
                                            DataTablePagination
                                        }
                                        loading={false}
                                        filterable={false}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        })
        this.setState({dom: dom, loading: false})
    }

    async generateShift() {
        // validation
        if (this.state.selectedAssets.length === 0)
            return alert('At least one asset must be selected.')

        this.setState({loading: true})

        await this.getReasonCodes()
        await this.getShifts()

        let promises = []
        const {reasoncodes, selectedAssets, start, end, shifts} = this.state

        selectedAssets.forEach((asset) => {
            reasoncodes.forEach((code) => {
                const reasoncode = this.state.reasoncoderecords.find(
                    (r) => r.reason === code
                )
                if (reasoncode) {
                    if (
                        (reasoncode.assets &&
                            reasoncode.assets.find((a) => a === asset.value)) ||
                        !reasoncode.assets
                    ) {
                        shifts.forEach((shift) => {
                            promises.push(
                                API.post('historical', {
                                    timeStart: start.toISOString(),
                                    timeEnd: end.toISOString(),
                                    state: 'Downtime Reason',
                                    deviceId: asset.value,
                                    metaDataFilter: [['downtimereason', code]],
                                    unit: 'years',
                                    shift: shift,
                                })
                            )
                        })
                    }
                }
            })
        })

        const results = await Promise.all(promises)
        let dom = []

        let shiftColumns = [
            {
                Header: 'Reason',
                accessor: 'name',
            },
        ]

        for (let i in shifts)
            shiftColumns.push({
                Header: shifts[i],
                accessor: shifts[i],
                width: 100,
            })

        selectedAssets.forEach((asset) => {
            let codes = results.filter((r) => r.query.deviceId === asset.value)
            codes = codes.map((c) => {
                return {
                    name: c.query.metaDataFilter[0][1],
                    shift: c.query.shift,
                    hours: Math.round((c.duration[0] / 60) * 100) / 100,
                }
            })

            let aggCodes = []

            reasoncodes.forEach((code) => {
                const relevantResults = codes.filter((r) => r.name === code),
                    total = relevantResults.reduce(
                        (total, next) => total + next.hours,
                        0
                    )

                let aggResult = {
                    name: code,
                    total: total,
                }
                relevantResults.forEach((r) => {
                    aggResult[r.shift] = r.hours
                })
                aggCodes.push(aggResult)
            })
            aggCodes.sort((a, b) => b.total - a.total)
            aggCodes = aggCodes.filter((code) => code.total > 0)

            dom.push(
                <Col key={asset.value} xs="12" sm="6" className="mb-2">
                    <Card>
                        <CardHeader className="text-center">
                            <h4>{asset.label}</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <ReactTable
                                        defaultPageSize={5}
                                        data={aggCodes}
                                        columns={shiftColumns}
                                        minRows={0}
                                        PaginationComponent={
                                            DataTablePagination
                                        }
                                        loading={false}
                                        filterable={false}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        })
        this.setState({dom: dom, loading: false})
    }

    async componentWillMount() {
        await this.getAssets()
    }

    render() {
        const readyToExport = this.state.ready && !this.state.loading
        return (
            <>
                <h2 className="text-center">
                    This report has been archived and replaced by the{' '}
                    <strong>Pareto Chart Report</strong>.
                </h2>
                <h5 className="text-center">
                    Please use the Pareto report for downtime analysis. If it is
                    not available in the reporting section, please contact your
                    Accumine Account Manager.
                </h5>
            </>
        )
        /*
    return (
      <Fragment>
        <Row>
          <Col xs="12" className="mb-4">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" className="mb-4">
                    <label>Assets:</label>
                    <Select
                      components={{ Input: CustomSelectInput }}
                      className="react-select"
                      classNamePrefix="react-select"
                      isMulti
                      name="assets"
                      value={this.state.selectedAssets}
                      onChange={this.handleAssetChange}
                      options={this.state.assets.map((a, i) => {
                        return {label: a.name, value: a.deviceId, key: i};
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="2">
                    <label>From:</label>
                    <DatePicker
                      selected={this.state.start}
                      selectsStart
                      startDate={this.state.start}
                      endDate={this.state.end}
                      onChange={this.handleStartChange}
                      filterDate={(date) => { return moment() > date }}
                      disabled={this.state.loading}
                    />
                  </Col>
                  <Col xs="12" sm="2">
                    <label>To:</label>
                    <DatePicker
                      selected={this.state.end}
                      selectsEnd
                      startDate={this.state.start}
                      endDate={this.state.end}
                      onChange={this.handleEndChange}
                      filterDate={(date) => { return moment() > date }}
                      disabled={this.state.loading}
                    />
                  </Col>
                  <Col xs="12" sm="3">
                    <label>
                      By Shift (group by shift):
                    </label>
                    <Switch
                      className="custom-switch custom-switch-primary"
                      checked={this.state.byShift}
                      onChange={byShift => {
                        this.setState({ byShift });
                      }}
                      disabled={this.state.loading}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" style={{"textAlign": "right"}}>
                    <br/>
                    <Button color="primary"
                      onClick={this.generate}
                      disabled={this.state.loading}
                    >
                      <i className="iconsmind-Arrow-Refresh" />{" "} Generate
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {!this.state.loading ? this.state.dom : null}
        </Row>
        {this.state.loading ? <div className="loading" /> : null}
      </Fragment>
    );
    */
    }
}
