import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonToolbar,
    ButtonGroup,
    Button,
    Input,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {MentionsInput, Mention} from 'react-mentions'
import textAreaStyle from './style'
import * as API from 'SDK/api'
import Scrollbars from 'react-custom-scrollbars'
import NoteReply from './NoteReply'

export default class Note extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            noteId: this.props.noteObject.noteId,
            userId: this.props.noteObject.userId,
            timestamp: this.props.noteObject.timestamp,
            text: this.props.noteObject.text,
            notifyUsers: this.props.noteObject.notifyUsers,
            archived: this.props.noteObject.archived,
            optionDropdownOpen: false,
            tags: this.props.tags,
            mentions: [],
            plainText: this.props.noteObject.plainText,
            downtimeData: this.props.noteObject.downtimeData,
            attachments: this.props.noteObject.attachments,
            repliesOpen: false,
            replies: this.props.noteObject.replies,
            editingReply: {},
        }
    }

    mentionedUsers() {
        return (
            this.state.mentions.filter((m) => m.id.includes('users_')).length >
            0
        )
    }

    toggleOptionDropdown() {
        this.setState({optionDropdownOpen: !this.state.optionDropdownOpen})
    }

    save() {
        let tags = {
            shifts: this.state.mentions
                .filter((m) => m.id.includes('shifts_'))
                .map((m) => m.id.split('shifts_')[1]),
            users: this.state.mentions
                .filter((m) => m.id.includes('users_'))
                .map((m) => m.id.split('users_')[1]),
            devices: this.state.mentions
                .filter((m) => m.id.includes('devices_'))
                .map((m) => m.id.split('devices_')[1]),
        }

        this.props.saveNote({
            noteId: this.state.noteId,
            userId: this.state.userId,
            timestamp: this.state.timestamp,
            text: this.state.text,
            plainText: this.state.plainText,
            notifyUsers: true, //this.state.notifyUsers,
            archived: this.state.archived,
            tags,
            downtimeData: this.state.downtimeData,
            attachments: this.state.attachments,
            replies: this.state.replies,
        })
    }

    archive() {
        this.props.archiveNote(this.state.noteId)
    }

    delete() {
        this.props.delete(this.state.noteId)
    }

    handleOutsideClick(e) {
        //if (this.node && !this.node.contains(e.target) && this.props.editing) {
        //    if (this.state.text === '') {
        //        this.delete();
        //    }
        //}
    }

    fullUsername() {
        const userRecord = this.props.resources.find(
            (r) => r.id === 'users_' + this.state.userId
        )
        if (userRecord) {
            return userRecord.display
        } else {
            return ''
        }
    }

    toggleReplies() {
        this.setState({repliesOpen: !this.state.repliesOpen})
    }

    saveReply() {
        let {editingReply} = this.state
        let reply = {...editingReply}
        reply.timestamp = moment()
        reply.userId = JSON.parse(localStorage['userObject'])._id
        reply.replyId = uuidv4()
        this.setState({editingReply: {}})
        this.props.saveReply(this.props.noteObject._id, reply)
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleOutsideClick, false)
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleOutsideClick, false)
    }

    render() {
        const mentionedUsers = this.mentionedUsers()
        return (
            <Fragment>
                <div ref={(node) => (this.node = node)}>
                    <Row className="mt-4 ml-2 mr-2 mb-2">
                        <Col>
                            <Card
                                style={{
                                    boxShadow:
                                        '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.2)',
                                }}>
                                <CardBody style={{padding: 0}}>
                                    <MentionsInput
                                        placeholder="Use @ to mention people, stations & shifts..."
                                        value={this.state.text}
                                        disabled={!this.props.editing}
                                        onChange={(
                                            event,
                                            newValue,
                                            newPlainTextValue,
                                            mentions
                                        ) => {
                                            this.setState({
                                                text: newValue,
                                                plainText: newPlainTextValue,
                                                mentions,
                                            })
                                        }}
                                        style={textAreaStyle}>
                                        <Mention
                                            trigger="@"
                                            data={this.props.resources}
                                            style={{
                                                color: '#2a93d5',
                                                zIndex: 1,
                                                position: 'relative',
                                            }}
                                        />
                                    </MentionsInput>
                                </CardBody>
                                {this.props.editing ? (
                                    <CardFooter>
                                        <Row>
                                            {/*<Col xs="9" className="ml-2">
                                            {mentionedUsers ? 
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            this.setState({ notifyUsers: e.target.checked });
                                                        }}
                                                    />{' '}
                                                        Notify mentioned users
                                                </Label>
                                            : null }
                                                    </Col>*/}
                                            <Col
                                                xs={{size: 2, offset: 6}}
                                                className="mr-1"
                                                style={{padding: 0}}>
                                                <Button
                                                    color="danger"
                                                    size="xs"
                                                    onClick={this.delete}
                                                    //disabled={this.state.text === ''}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col
                                                xs={{size: 2}}
                                                className="ml-3"
                                                style={{padding: 0}}>
                                                <Button
                                                    size="xs"
                                                    onClick={this.save}
                                                    disabled={
                                                        this.state.text === ''
                                                    }>
                                                    Add
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                ) : (
                                    <CardFooter style={{paddingRight: 0}}>
                                        <Row>
                                            <Col
                                                xs="5"
                                                className="text-muted"
                                                style={{paddingLeft: 0}}>
                                                {this.state.timestamp.fromNow()}
                                            </Col>
                                            <Col
                                                xs="7"
                                                className="text-right"
                                                style={{paddingRight: 20}}>
                                                {this.props.owned ? (
                                                    <Dropdown
                                                        size="xs"
                                                        isOpen={
                                                            this.state
                                                                .optionDropdownOpen
                                                        }
                                                        toggle={
                                                            this
                                                                .toggleOptionDropdown
                                                        }>
                                                        <DropdownToggle
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                border: 'none',
                                                                color: 'black',
                                                                paddingRight: 0,
                                                            }}>
                                                            <i className="simple-icon-options-vertical" />
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem
                                                                onClick={
                                                                    this.archive
                                                                }>
                                                                Archive
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={
                                                                    this.delete
                                                                }>
                                                                Delete
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                ) : (
                                                    this.fullUsername()
                                                )}
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                )}
                            </Card>
                            {!this.props.editing ? (
                                <>
                                    {this.state.repliesOpen ? (
                                        <Row>
                                            <Col
                                                xs={{size: 10, offset: 1}}
                                                className="text-center text-muted"
                                                style={{
                                                    backgroundColor:
                                                        'rgba(0,0,0,0.03)',
                                                    boxShadow:
                                                        '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.2)',
                                                    borderBottomLeftRadius: 5,
                                                    borderBottomRightRadius: 5,
                                                    height: 300,
                                                }}>
                                                <Row>
                                                    <Col
                                                        onClick={
                                                            this.toggleReplies
                                                        }
                                                        className="text-center"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}>
                                                        <i className="iconsmind-Arrow-Up" />{' '}
                                                        Hide replies
                                                        <hr
                                                            style={{margin: 0}}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Scrollbars autoHeight>
                                                            {this.props.noteObject.replies.map(
                                                                (r) => {
                                                                    return (
                                                                        <NoteReply
                                                                            resources={
                                                                                this
                                                                                    .props
                                                                                    .resources
                                                                            }
                                                                            key={Math.random()}
                                                                            reply={
                                                                                r
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </Scrollbars>
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 30,
                                                        height: 30,
                                                        width: '100%',
                                                    }}>
                                                    <Col
                                                        style={{
                                                            padding: 0,
                                                            textAlign: 'right',
                                                        }}>
                                                        <MentionsInput
                                                            placeholder="Reply..."
                                                            value={
                                                                this.state
                                                                    .editingReply
                                                                    .text
                                                            }
                                                            singleLine
                                                            onChange={(
                                                                event,
                                                                text,
                                                                plainText,
                                                                mentions
                                                            ) => {
                                                                let tags = {
                                                                    shifts: mentions
                                                                        .filter(
                                                                            (
                                                                                m
                                                                            ) =>
                                                                                m.id.includes(
                                                                                    'shifts_'
                                                                                )
                                                                        )
                                                                        .map(
                                                                            (
                                                                                m
                                                                            ) =>
                                                                                m.id.split(
                                                                                    'shifts_'
                                                                                )[1]
                                                                        ),
                                                                    users: mentions
                                                                        .filter(
                                                                            (
                                                                                m
                                                                            ) =>
                                                                                m.id.includes(
                                                                                    'users_'
                                                                                )
                                                                        )
                                                                        .map(
                                                                            (
                                                                                m
                                                                            ) =>
                                                                                m.id.split(
                                                                                    'users_'
                                                                                )[1]
                                                                        ),
                                                                    devices:
                                                                        mentions
                                                                            .filter(
                                                                                (
                                                                                    m
                                                                                ) =>
                                                                                    m.id.includes(
                                                                                        'devices_'
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    m
                                                                                ) =>
                                                                                    m.id.split(
                                                                                        'devices_'
                                                                                    )[1]
                                                                            ),
                                                                }
                                                                const editingReply =
                                                                    {
                                                                        text,
                                                                        plainText,
                                                                        source: 'app',
                                                                        tags,
                                                                    }

                                                                this.setState({
                                                                    editingReply,
                                                                })
                                                            }}
                                                            style={
                                                                textAreaStyle
                                                            }>
                                                            <Mention
                                                                trigger="@"
                                                                data={
                                                                    this.props
                                                                        .resources
                                                                }
                                                            />
                                                        </MentionsInput>
                                                        <Button
                                                            size="xs"
                                                            className="btn-primary mt-2"
                                                            onClick={
                                                                this.saveReply
                                                            }>
                                                            Reply
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row>
                                            <Col
                                                xs={{size: 6, offset: 3}}
                                                className="text-center text-muted"
                                                style={{
                                                    backgroundColor:
                                                        'rgba(0,0,0,0.03)',
                                                    boxShadow:
                                                        '0 4px 2px -2px gray',
                                                    borderBottomLeftRadius: 5,
                                                    borderBottomRightRadius: 5,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={this.toggleReplies}>
                                                <i className="iconsmind-Speach-Bubble" />{' '}
                                                Replies (
                                                {
                                                    this.props.noteObject
                                                        .replies.length
                                                }
                                                )
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    }
}
