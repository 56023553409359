import React, {Fragment, Component} from 'react'
import {Progress, Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

export default class SidewayStatusBarSummary extends Component {
    static propTypes = {
        active: PropTypes.number.isRequired,
        dormant: PropTypes.number.isRequired,
        offline: PropTypes.number.isRequired,
        unit: PropTypes.oneOf(['number', 'percentage']).isRequired,
        height: PropTypes.any,
    }
    static defaultProps = {
        height: '100%',
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.active = this.props.active
        this.dormant = this.props.dormant
        this.offline = this.props.offline
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            !(
                this.active == nextProps.active &&
                this.dormant == nextProps.dormant &&
                this.offline == nextProps.offline
            )
        ) {
            this.active = nextProps.active
            this.dormant = nextProps.dormant
            this.offline = nextProps.offline
            return true
        } else {
            return false
        }
    }

    render() {
        const unit = this.props.unit === 'percentage' ? '%' : '',
            total = this.props.offline + this.props.dormant + this.props.active

        return (
            <Row>
                <Col>
                    {total > 0 ? (
                        <Progress multi style={{height: '15px'}}>
                            <Progress
                                bar
                                color="danger"
                                value={(this.props.offline / total) * 100}>
                                {this.props.offline}
                                {unit}
                            </Progress>
                            <Progress
                                bar
                                color="warning"
                                value={(this.props.dormant / total) * 100}>
                                {this.props.dormant}
                                {unit}
                            </Progress>
                            <Progress
                                bar
                                color="success"
                                value={(this.props.active / total) * 100}>
                                {this.props.active}
                                {unit}
                            </Progress>
                        </Progress>
                    ) : (
                        <Progress multi style={{height: '15px'}}>
                            <Progress bar color="default" value={100}>
                                No data
                            </Progress>
                        </Progress>
                    )}
                </Col>
            </Row>
        )
    }
}
