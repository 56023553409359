import React, {Component} from 'react'
import autobind from 'auto-bind'
import axios from 'axios'
import moment from 'moment'

import {logoutUser} from 'redux/actions'

import './NetworkWarning.css'

export default class NetworkWarning extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            connected: true,
            disconnectedSince: null,
        }

        axios.interceptors.response.use(
            (response) => {
                if (!this.state.connected) {
                    this.setState({
                        connected: true,
                    })
                }

                return response
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear()
                    logoutUser(this.props.history)
                    this.props.history.push('/login')
                } else if (error.message && error.message === 'Network Error') {
                    if (this.state.connected) {
                        this.setState({
                            connected: false,
                            disconnectedSince: moment(),
                        })
                    }
                } else if (
                    error.response &&
                    error.response.data &&
                    error.response.data.includes(
                        'is not assigned to any instance of'
                    )
                ) {
                    alert(error.response.data)
                }
                return Promise.reject(error)
            }
        )

        this.interval = setInterval(() => {
            if (
                !this.state.connected &&
                this.state.disconnectedSince !== null &&
                this.state.disconnectedSince.isBefore(
                    moment().add(-1, 'minutes')
                )
            ) {
                this.setState({connected: true})
            }
        }, 1000 * 10)
    }
    async componentWillMount() {}

    render() {
        return (
            <>
                {!this.state.connected ? (
                    <div className="blink">
                        <span className="name mr-1" style={{color: '#fff'}}>
                            <i className="iconsmind-Danger" /> Connection to
                            Accumine Server interrupted!
                        </span>
                    </div>
                ) : null}
            </>
        )
    }
}
