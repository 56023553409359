import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap'
import Sortable from 'react-sortablejs'
import autobind from 'auto-bind'
import moment from 'moment'
import Clock from 'react-clock'
import PropTypes from 'prop-types'

// Accumine SDK imports
import * as API from 'SDK/api'
import {SocketClient} from 'SDK/api/socket'
import Subscriber from 'SDK/subscriber'
import {getLiveAssets} from 'SDK/api/common'

// Accumine component imports
import LiveTile from './tile'
import HMIEntry from './HMIEntry'
import './index.css'

import DowntimeEntryModule from '../DowntimeEntryModule'

// Material Entry
import MaterialAPI from 'SDK/ui/AMC2023/Material/calls'

export default class TVDisplay extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'realtimeclock',
            component: 'RealTimeClock',
        },
        {
            prop: 'clickToEnterDowntime',
            component: 'ClickToEnterDowntime',
        },
        {
            prop: 'hmiEntry',
            component: 'HMIEntry',
        } /*{
    prop: 'sortByInCycle',
    component: 'SortByInCycle'
  }, {
    prop: 'sortByTime',
    component: 'SortByTime'
  },*/,
        {
            prop: 'hideLightsOutEntry',
            component: 'HideLightsOutEntry',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        } /*, {
    prop: 'selectedReasonCodes',
    component: 'ReasonCodePicker'
  }*/,
    ]
    showBorder = false
    id = 'TVDisplay'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        name: PropTypes.string,
        //downtimeWarningThreshold: PropTypes.number,
        realtimeclock: PropTypes.bool,
        clickToEnterDowntime: PropTypes.bool,
        hmiEntry: PropTypes.bool,
        deviceIds: PropTypes.array,
        selectedReasonCodes: PropTypes.array,
        sortByTime: PropTypes.bool,
        sortByInCycle: PropTypes.bool,
    }
    static defaultProps = {
        //downtimeWarningThreshold: 5,
        realtimeclock: false,
        clickToEnterDowntime: false,
        hmiEntry: false,
        deviceIds: [],
        selectedReasonCodes: [],
        sortByTime: false,
        sortByInCycle: false,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            timerange: null,
            assets: [],
            data: null,
            order: [],
            showDowntimeEntryModal: false,
            clickedDeviceId: null,
            downtimeReasons: [],
            modalHMI: false,
            materialEntry: false,
        }
    }

    toggleDowntimeEntryModal() {
        this.setState({
            showDowntimeEntryModal: !this.state.showDowntimeEntryModal,
        })
    }

    async getAssets() {
        const assets = await API.get('devices')
        if (!assets) {
            //alert('There was an error initalizing this view.');
        } else {
            this.setState(
                {assets, order: assets.map((x) => x.deviceId)},
                () => {
                    this.reorderAssets()
                    this.fetchLiveData()
                }
            )
        }
    }

    async fetchLiveData() {
        let data = await API.post('live', {
            deviceId: this.state.assets.map((x) => x.deviceId),
        })
        let downtimeReasons = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: this.state.assets.map((a) => a.deviceId)},
                    name: 'Downtime Reason',
                    timeEnd: null,
                },
            },
            2
        )
        if (!data) {
            //alert('There was an error fetching data');
        } else {
            this.setState({data, downtimeReasons})
        }
    }

    compareByInCycle(a, b) {
        let rowA = this.state.data[a].activeStates
            .map((state) => (state.elapsed ? state.name : null))
            .filter((el) => el)
        let rowB = this.state.data[b].activeStates
            .map((state) => (state.elapsed ? state.name : null))
            .filter((el) => el)
        let rowAtime = this.state.data[a].activeStates
            .map((state) => (state.elapsed ? state.elapsed : null))
            .filter((el) => el)
        let rowBtime = this.state.data[b].activeStates
            .map((state) => (state.elapsed ? state.elapsed : null))
            .filter((el) => el)
        if (rowA[0] === 'In-Cycle' && rowB[0] !== 'In-Cycle') {
            return -1
            //} else if(rowA[0] === 'In-Cycle' && rowB[0] === 'In-Cycle' && rowAtime[0] > rowBtime[0]) {
            //  return -1;
        } else {
            return 1
        }
    }

    compareByTime(a, b) {
        let rowA = this.state.data[a].activeStates
            .map((state) => (state.elapsed ? state.elapsed : null))
            .filter((el) => el)
        let rowB = this.state.data[b].activeStates
            .map((state) => (state.elapsed ? state.elapsed : null))
            .filter((el) => el)
        if (rowA[0] > rowB[0]) {
            return -1
        } else {
            return 1
        }
    }

    filterReasonCodes(a) {
        let rowA = this.state.data[a.deviceId].activeStates
            .map((state) => (state.elapsed ? state.name : null))
            .filter((el) => el)
        return this.props.selectedReasonCodes.includes(rowA)
    }

    reorderAssets(o) {
        if (this.state.data) {
            let order = this.state.order,
                newOrder = []
            if (this.props.sortByInCycle) order.sort(this.compareByInCycle)
            if (this.props.sortByTime) order.sort(this.compareByTime)
            //if(this.props.selectedReasonCodes.length) newOrder = order.filter((o)=>{this.filterReasonCodes(o)});
            this.setState({order})
        }
    }

    async fetchLiveDataForDevice(record) {
        let newData = await API.post('live', {
            deviceId: [record.deviceId],
        })
        let newDowntimeReasons = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: record.deviceId,
                    name: 'Downtime Reason',
                    timeEnd: null,
                },
            },
            2
        )
        if (!newData) {
            //alert('There was an error fetching data');
        } else {
            let {data, downtimeReasons} = this.state
            data[record.deviceId] = newData[record.deviceId]
            downtimeReasons = downtimeReasons.filter(
                (r) => r.deviceId !== record.deviceId
            )
            downtimeReasons = downtimeReasons.concat(newDowntimeReasons)
            this.setState({data, downtimeReasons})
        }
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
        let viewSettings = await API.get(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId,
            2
        )
        viewSettings.order = order
        await API.post(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId,
            viewSettings,
            2
        )
    }

    updateTime() {
        this.setState({
            date: new Date(),
            flash: !this.state.flash,
        })
    }

    handleClickOnTile(deviceId) {
        if (this.props.clickToEnterDowntime) {
            this.setState(
                {
                    clickedDeviceId: deviceId,
                },
                this.toggleDowntimeEntryModal
            )
        }
    }
    async getReasonCodes() {
        const data = await API.get('reasoncodes', 2)
        this.setState({reasonCodes: data})
    }
    //TODO refactor needed
    async UNSAFE_componentWillMount() {
        await this.getAssets()
        await this.getReasonCodes()
        await this.fetchLiveData()
        this.setState({materialEntry: await MaterialAPI.isEnabled()})

        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/In-Cycle/#`,
            this.fetchLiveDataForDevice
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/Downtime Reason/#`,
            this.fetchLiveDataForDevice
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/Lights Out/#`,
            this.fetchLiveDataForDevice
        )

        this.subscriber.add(this.fetchLiveData, 1000 * 60, 'tv_display')

        if (this.props.realtimeclock) {
            this.subscriber.add(this.updateTime, 1000, 'updateTime()')
        }
    }

    async componentDidMount() {
        this.reorderAssets()
        this.fetchLiveData()
    }

    StandardTemplate({TitleField, properties, title, description}) {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={12} key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
        try {
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/States/In-Cycle/#`,
                this.fetchLiveDataForDevice
            )
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/States/Downtime Reason/#`,
                this.fetchLiveDataForDevice
            )
        } catch (error) {
            console.log(error)
        }
    }
    render() {
        const isLoaded =
                this.state.assets.length > 0 && this.state.data !== null,
            height = document.documentElement.offsetHeight * 0.83 + 'px',
            {hmiEntry} = this.props,
            tileSize = this.state.assets.length > 1 ? 2 : 12
        return (
            <Fragment>
                <div style={{height, position: 'relative'}}>
                    <Sortable
                        className="row icon-cards-row"
                        onChange={this.handleSort}>
                        {isLoaded ? (
                            this.state.order.map((x, i) => {
                                const m = this.state.assets.find(
                                    (a) => a.deviceId === x
                                )
                                const downtimeReason =
                                    this.state.downtimeReasons.find(
                                        (d) => d.deviceId === m.deviceId
                                    )
                                const color =
                                    downtimeReason &&
                                    this.state.reasonCodes &&
                                    this.state.reasonCodes.find(
                                        (rc) =>
                                            rc.reason === downtimeReason.value
                                    )
                                        ? this.state.reasonCodes.find(
                                              (rc) =>
                                                  rc.reason ===
                                                  downtimeReason.value
                                          ).color
                                        : null
                                return (
                                    <LiveTile
                                        key={i}
                                        device={m}
                                        data={this.state.data[x]}
                                        size={tileSize}
                                        //downtimeWarningThreshold={this.props.downtimeWarningThreshold}
                                        handleClickOnTile={
                                            this.handleClickOnTile
                                        }
                                        HMIEntryEnabled={hmiEntry}
                                        HMIEntry={HMIEntry}
                                        MaterialEntry={this.state.materialEntry}
                                        downtimeReason={downtimeReason}
                                        color={color}
                                        flash={this.state.flash}
                                    />
                                )
                            })
                        ) : (
                            <div className="loading"></div>
                        )}
                    </Sortable>
                    {this.props.realtimeclock ? (
                        <div className="tv-display-clock">
                            <Clock renderNumbers value={new Date()} />
                        </div>
                    ) : null}
                    {this.state.showDowntimeEntryModal ? (
                        <Modal
                            isOpen={this.state.showDowntimeEntryModal}
                            toggle={this.toggleDowntimeEntryModal}>
                            <ModalBody style={{padding: 0}}>
                                <DowntimeEntryModule
                                    deviceId={this.state.clickedDeviceId}
                                    threshold={
                                        this.state.assets.find(
                                            (a) =>
                                                a.deviceId ===
                                                this.state.clickedDeviceId
                                        ).downtimeThresholdSeconds || 300
                                    }
                                    hideLightsOutEntry={
                                        this.props.hideLightsOutEntry
                                    }
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.toggleDowntimeEntryModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Modal>
                    ) : null}
                </div>
            </Fragment>
        )
    }
}
