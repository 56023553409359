import autoBind from 'auto-bind'
import React from 'react'
import {Chart} from 'react-google-charts'
import moment from 'moment'

export default class ChartWrapper extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {}
        this.id = Math.random()
    }

    shouldComponentUpdate(prevProps) {
        return this.props.lastFetch.isAfter(prevProps.lastFetch)
    }

    render() {
        return (
            <Chart
                chartType={this.props.chartType}
                loader={<>Loading chart...</>}
                columns={this.props.columns}
                rows={this.props.rows}
                options={this.props.chartOptions}
                //chartPackages={['corechart']}
                rootProps={{'data-testid': this.id}}
            />
        )
    }
}
