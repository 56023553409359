export default function RowConverter(dataModel, row, ignoreTypes) {
    let converted = {
        _str: '',
    }

    for (let fieldName in row) {
        const field = dataModel.fields.find((f) => f.name === fieldName)

        if (field) {
            if (
                field.archived === false &&
                !ignoreTypes.find((type) => type === field.type)
            ) {
                converted[field.name] = row[fieldName]
                converted._str += field.name + ': ' + row[fieldName] + ', '
            }
        } else {
            converted[fieldName] = row[fieldName]
        }
    }
    converted._str = converted._str.substring(0, converted._str.length - 2)
    return converted
}
