import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
    LOGIN_USER,
    LOGIN_USER_SSO,
    REGISTER_USER,
    LOGOUT_USER,
} from 'constants/actionTypes'
import {apiUrl2} from 'constants/defaultValues'
import urlJoin from 'proper-url-join'
import axios from 'axios'

import {loginUserSuccess, registerUserSuccess} from './actions'

const loginWithUsernamePasswordAsync = async (
    username,
    password,
    factoryId,
    token
) => {
    if (!token) {
        return await axios
            .post(urlJoin(apiUrl2, '/users/login'), {
                user: {
                    username: username,
                    password: password,
                    factoryId: factoryId,
                },
            })
            .then((authUser) => authUser)
            .catch((error) => error)
    } else {
        return await axios
            .get(
                urlJoin(apiUrl2, 'users/whoami'),
                {headers: {Authorization: token}},
                2
            )
            .then((authUser) => authUser)
            .catch((error) => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error === 'FactoryID not initialized'
                ) {
                    location.reload()
                }
                return error
            })
    }
}

function* loginWithUsernamePassword({payload}) {
    const {username, password, factoryId, token} = payload.user
    const {history} = payload
    try {
        const loginUser = yield call(
            loginWithUsernamePasswordAsync,
            username,
            password,
            factoryId,
            token
        )
        if (loginUser.status === 200 && !loginUser.data.error) {
            if (loginUser.data.globalERPNaming) {
                localStorage['globalERPNaming'] = true
            } else {
                delete localStorage['globalERPNaming']
            }
            localStorage.setItem('user_id', loginUser.data.user._id)
            localStorage.setItem(
                'userObject',
                JSON.stringify(loginUser.data.user)
            )
            yield put(loginUserSuccess(loginUser.data.user))
            history.push('/')
        } else {
            // catch throw
            alert('FactoryID/Username/Password is incorrect. Please try again.')
            //alert(loginUser.data.error)
        }
    } catch (error) {
        // catch throw
        console.log('login error : ', error)
    }
}

export const loginWithFedTokenAsync = async (ssoToken) => {
    if (ssoToken) {
        return await axios
            .post(urlJoin(apiUrl2, `/users/fedlogin?l2lFedLogin=${ssoToken}`))
            .then((authUser) => authUser)
            .catch((error) => error)
    }
}

export function* loginWithSSO({payload}) {
    const {ssoToken} = payload.ssoToken
    const {history} = payload
    try {
        // call login function with payload
        const ssoLoginUser = yield call(loginWithFedTokenAsync, ssoToken)
        if (ssoLoginUser.status === 200 && !ssoLoginUser.data.error) {
            localStorage['globalERPNaming'] = true
        } else {
            delete localStorage['globalERPNaming']
        }
        localStorage.setItem('user_id', ssoLoginUser.data.user._id)
        localStorage.setItem(
            'userObject',
            JSON.stringify(ssoLoginUser.data.user)
        )
        yield put(loginUserSuccess(ssoLoginUser.data.user))
        history.push('/')
    } catch (error) {
        // catch throw
        alert('SSO Login failed. Please login with Username and Password')
        history.push('/login')
    }
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth
        .createUserWithEmailAndPassword(email, password)
        .then((authUser) => authUser)
        .catch((error) => error)

function* registerWithEmailPassword({payload}) {
    const {email, password} = payload.user
    const {history} = payload
    try {
        const registerUser = yield call(
            registerWithEmailPasswordAsync,
            email,
            password
        )
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid)
            yield put(registerUserSuccess(registerUser))
            history.push('/')
        } else {
            // catch throw
            console.log('register failed :', registerUser.message)
        }
    } catch (error) {
        // catch throw
        console.log('register error : ', error)
    }
}

const logoutAsync = async (history) => {
    await auth
        .signOut()
        .then((authUser) => authUser)
        .catch((error) => error)
    history.push('/')
}

function* logout({payload}) {
    const {history} = payload
    try {
        yield call(logoutAsync, history)
        localStorage.removeItem('user_id')
    } catch (error) {
        console.error(error)
    }
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword)
}
export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithUsernamePassword)
}

export function* watchLoginUserSSO() {
    yield takeEvery(LOGIN_USER_SSO, loginWithSSO)
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout)
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLoginUserSSO),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
    ])
}
