import React, {Component, Fragment} from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import Pin from './pin'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        this.workingCopy = this.node.settings.pins

        this.state = {
            pins: this.node.settings.pins,
            accordion: [false, false, false, false, false, false],
            validation: [true, true, true, true, true, true],
            isValid: true,
        }
    }
    updateWorkingCopy(index, obj) {
        this.workingCopy[index] = obj
    }
    async save() {
        try {
            let node = {...this.node}
            node.settings.pins = this.workingCopy

            let response = await API.patch(
                'nodes/' + node.nodeId,
                {
                    settings: node.settings,
                },
                2
            )
            if (response.success) {
                this.props.toggleModal('dataSamplingModal')
            } else {
                return alert(
                    'There was an error saving your change. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error saving your change. Please try again.'
            )
        }
    }
    validate(tab, valid) {
        let validation = this.state.validation
        validation[tab] = valid
        this.setState({
            validation: validation,
            isValid:
                this.state.validation.find((x) => x === false) === undefined,
        })
    }
    toggleAccordion(tab) {
        if (this.state.validation.find((x) => x === false) !== undefined) {
            return
        }
        const prevState = this.state.accordion
        const state = prevState.map((x, index) => (tab === index ? !x : false))
        this.setState({
            accordion: state,
        })
    }
    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {modal, toggleModal} = this.props
        let {pins} = this.state
        const streams = [0, 1, 2, 3, 4, 5]
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    size="lg"
                    toggle={() => {
                        toggleModal('dataSamplingModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('dataSamplingModal')
                        }}>
                        Data Sampling
                    </ModalHeader>
                    <ModalBody>
                        {streams.map((stream) => {
                            return (
                                <div key={stream} className="border">
                                    <Button
                                        block
                                        color="link"
                                        className="text-left"
                                        onClick={() =>
                                            this.toggleAccordion(stream)
                                        }
                                        aria-expanded={
                                            this.state.accordion[stream]
                                        }>
                                        Stream {stream + 1}
                                    </Button>
                                    <Collapse
                                        isOpen={this.state.accordion[stream]}>
                                        <div className="p-4">
                                            <Pin
                                                obj={pins[stream]}
                                                validate={this.validate}
                                                updateWorkingCopy={
                                                    this.updateWorkingCopy
                                                }
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            )
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('dataSamplingModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.save}
                            disabled={!this.state.isValid}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
