import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Card, CardHeader, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import {JsonToTable} from 'react-json-to-table'
import _ from 'lodash'
import {Typeahead} from 'react-bootstrap-typeahead'

import IntlMessages from 'util/IntlMessages'
import {DATA_MODEL_ID_MAP} from '../../Block/DATA_MODEL_ID_MAP'

import Navigation from '../../Navigation'
import {paginate} from '../../Paginate'
import count from '../../Scripts/CalculateOperationsCounts'
import * as API from 'SDK/api'

function deepOmit(obj, keysToOmit) {
    var keysToOmitIndex = _.keyBy(
        Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
    ) // create an index object of the keys that should be omitted

    function omitFromObject(obj) {
        // the inner function which will be called recursivley
        return _.transform(obj, function (result, value, key) {
            // transform to a new object
            if (key in keysToOmitIndex) {
                // if the key is in the index skip it
                return
            }

            result[key] = _.isObject(value) ? omitFromObject(value) : value // if the key is an object run it through the inner function - omitFromObject
        })
    }

    return omitFromObject(obj) // return the inner function result
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Block Batch Report - All Operations',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            ready: false,
            generatingData: false,
            batches: [],
            selectedBatch: [],
            data: {},
            dom: null,
        }
    }

    async fetchBatches() {
        let batches = await paginate('5fc9015bae96bc6be2c1a13e', [], {})
        batches = batches.map((m) => {
            return {
                label: m['Batch Number'],
                value: m,
            }
        })
        this.setState({batches, ready: true})
    }

    async fetchData() {
        this.setState({ready: false})
        const {selectedBatch} = this.state

        if (selectedBatch.length === 0) return

        let data = {}
        for (let operation in DATA_MODEL_ID_MAP) {
            const op = DATA_MODEL_ID_MAP[operation]
            const {result} = await API.post(
                'data-models/' + op.dataModelId + '/paginate',
                {
                    filter: [
                        {
                            type: 'Text',
                            logic: 'is',
                            value: [selectedBatch[0].value['Batch Number']],
                            path: op.masterBlockFieldPath,
                        },
                    ],
                    sort: {},
                    limit: 1,
                    next: null,
                    previous: null,
                    timezone: localStorage['timezone'],
                },
                2
            )

            if (result.results.length) {
                data[operation] = deepOmit(result.results[0], [
                    '_id',
                    'dataModelId',
                    'deleted',
                    'imported',
                    'blockBatchID',
                ])
                delete data[operation]['Block Batch Link']
            }
        }

        data['Block'] = deepOmit(this.state.selectedBatch[0].value, [
            '_id',
            'dataModelId',
            'deleted',
            'imported',
            'blockBatchID',
        ])
        data['Block']['Powder Batch'] =
            data['Block']['Powder Batch']['Batch Number']
        data['Block']['Obsolete'] = String(data['Block']['Obsolete'])

        let dom = [
            <Row className="mt-4" key={1}>
                <Col xs={{size: 8, offset: 2}}>
                    <Card>
                        <CardHeader className="text-center">
                            <h5>
                                <strong>Block Info</strong>
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <JsonToTable json={data['Block']} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>,
        ]

        let yieldData = []

        let idx = 1
        for (let record in data) {
            if (record !== 'Block' && record !== 'Block Heights') {
                dom.push(
                    <Row className="mt-4" key={++idx}>
                        <Col xs={{size: 8, offset: 2}}>
                            <Card>
                                <CardHeader className="text-center">
                                    <h5>
                                        <strong>{record} Info</strong>
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <JsonToTable json={data[record]} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )

                const counts = count(data, record)

                yieldData.push({
                    Operation: record,
                    'Blocks In': counts.in,
                    Scrap: counts.scrap,
                    'Scrap %':
                        (Math.round((counts.scrap / counts.in) * 100) / 100) *
                            100 +
                        '%',
                    Rework: counts.rework,
                    'Rework %':
                        (Math.round((counts.rework / counts.in) * 100) / 100) *
                            100 +
                        '%',
                    'Blocks Out': counts.out,
                })
            }
        }

        dom.push(
            <Row className="mt-4">
                <Col xs={{size: 8, offset: 2}}>
                    <Card>
                        <CardHeader className="text-center">
                            <h5>
                                <strong>Yield Info</strong>
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <JsonToTable json={yieldData} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )

        this.setState({dom, ready: true})
    }

    handleBatchChange(selectedBatch) {
        this.setState({selectedBatch}, this.fetchData)
    }

    async componentDidMount() {
        this.fetchBatches()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Block Batch Report - All Operations</h1>
                    </Col>
                </Row>

                {this.state.ready ? (
                    <Fragment>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Typeahead
                                            id={Math.random()}
                                            labelKey={(option) =>
                                                `${option.label}`
                                            }
                                            options={this.state.batches.sort(
                                                (a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                            )}
                                            renderMenuItemChildren={(option) =>
                                                option.label
                                            }
                                            onChange={this.handleBatchChange}
                                        />
                                        <IntlMessages id="Block Batch Number" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>

                        {this.state.dom}
                    </Fragment>
                ) : (
                    <div className="loading" />
                )}

                {this.state.lading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
