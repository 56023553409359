import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

import CreateNewBlockBatch from './CreateNewBlockBatch'
import EnterBMRData from './EnterBMRData'
import Split from './Split'
import Navigation from '../Navigation'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Block',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            selectedSection: null,
            initialBatchNumber: null,
        }
    }

    reset() {
        this.setState({selectedSection: null})
    }

    async setInitialBlockBatchValues({batchNumber, powderBatch, size}) {
        await API.post(
            'data-models/5fc9015bae96bc6be2c1a13e/add-record',
            {
                'Batch Number': batchNumber,
                'Powder Batch': powderBatch._id,
                'Block Description': size._id,
            },
            2
        )

        this.setState({
            selectedSection: 'Enter BMR Data',
            initialBatchNumber: batchNumber,
        })
    }

    render() {
        return (
            <Fragment>
                {this.state.selectedSection === null ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <Navigation path={this.path} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Block</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <ButtonGroup vertical>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Create New Block Batch',
                                            })
                                        }}>
                                        CREATE NEW BLOCK BATCH
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Split Block Batch',
                                            })
                                        }}>
                                        SPLIT BLOCK BATCH
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Enter BMR Data',
                                            })
                                        }}>
                                        ENTER BMR DATA
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <Fragment>
                        {this.state.selectedSection ===
                        'Create New Block Batch' ? (
                            <CreateNewBlockBatch
                                setInitialBlockBatchValues={
                                    this.setInitialBlockBatchValues
                                }
                                path={this.path}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Enter BMR Data' ? (
                            <EnterBMRData
                                initialBatchNumber={
                                    this.state.initialBatchNumber
                                }
                                path={this.path}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Split Block Batch' ? (
                            <Split path={this.path} reset={this.reset} />
                        ) : null}
                    </Fragment>
                )}
            </Fragment>
        )
    }
}
