const EventEmitter = require('events').EventEmitter
const R = require('ramda')
/** @class
 * Base class for all transforms. This class implements the basic functions
 * of the NodeJS Transform class.
 */
export default class BaseTransform extends EventEmitter {
    constructor() {
        super()

        this._pipes = []
        this._buffer = []
        this._processing = false
        this.on(
            'data',
            function (obj) {
                for (let i = 0, l = this._pipes.length; i < l; i++) {
                    this._pipes[i].write(obj)
                }
            }.bind(this)
        )
        this.once('_start', this._onStart.bind(this))
        this.on('_next', this._onNext.bind(this))
        this.on('_end', this._onEnd.bind(this))
    }
    isProcessing() {
        return this._processing
    }
    destroy() {
        this.removeAllListeners()
        this.unpipe()
    }
    write(obj) {
        obj = R.clone(obj)
        this._buffer.push(obj)
        if (!this.isProcessing()) {
            this.emit('_start')
        }
    }
    pipe(transform) {
        if (!(transform instanceof BaseTransform)) {
            throw new Error('Provided transform not supported')
        }
        this._pipes.push(transform)
    }
    unpipe() {
        this._pipes = []
    }
    push(obj) {
        this.emit('data', obj)
    }
    _transform(chunk, callback) {
        this.push(chunk)
        callback()
    }
    _onStart() {
        let obj = this._buffer.shift()
        if (obj) {
            this._processing = true
            this._transform(
                obj,
                function () {
                    this.emit('_next')
                }.bind(this)
            )
        } else {
            this.emit('_end')
        }
    }
    _onNext() {
        let obj = this._buffer.shift()
        if (obj) {
            this._transform(
                obj,
                function () {
                    this.emit('_next')
                }.bind(this)
            )
        } else {
            this.emit('_end')
        }
    }
    _onEnd() {
        this._processing = false
        this.removeAllListeners('_start')
        this.once('_start', this._onStart.bind(this))
    }
}
