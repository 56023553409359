import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button, Label, Input} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'

export default (props) => {
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'Loop',
        description: props.element.data.opts.description || '',
        loopThroughVariableId:
            props.element.data.opts.loopThroughVariableId || null,
        storeOutputInSeparateVariableId:
            props.element.data.opts.storeOutputInSeparateVariableId || null,
        tempLoopVariableId: props.element.data.opts.tempLoopVariableId || null,
        submitted: props.element.data.opts.submitted || true,
        loopStartTransformId:
            props.element.data.opts.loopStartTransformId || null,
        loopEndTransformId: props.element.data.opts.loopEndTransformId || null,
        loopExitTransformId:
            props.element.data.opts.loopExitTransformId || null,
    })

    // TODO: remove storeOutputInSeparateVariable variable from variable array if no longer need it
    const submit = () => {
        const loopThroughVariable = props.variables.find(
            (v) => v.id === parameters.loopThroughVariableId
        )
        const existingTempLoopVariable = props.variables.find(
            (v) => v.id === parameters.tempLoopVariableId
        )
        let variables = [
            {
                id: existingTempLoopVariable
                    ? existingTempLoopVariable.id
                    : uuidv4(),
                name: `${parameters.name}__Loop_Element`,
                transformId: props.element.id,
                type: loopThroughVariable.type,
                multi: false,
                fields: loopThroughVariable.fields,
            },
        ]

        if (parameters.storeOutputInSeparateVariableId !== null) {
            const existingStoreOutputInSeparateVariable = props.variables.find(
                (v) => v.id === parameters.storeOutputInSeparateVariableId
            )
            variables.push({
                id: existingStoreOutputInSeparateVariable
                    ? existingStoreOutputInSeparateVariable.id
                    : uuidv4(),
                name: `${parameters.name}Output`,
                transformId: props.element.id,
                type: loopThroughVariable.type,
                multi: true,
                fields: loopThroughVariable.fields,
            })
        }

        parameters.tempLoopVariableId = variables[0].id

        props.onChange(parameters, variables)
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>

                        <FormGroup>
                            <SelectInput
                                onChange={(id) => {
                                    parameters.loopThroughVariableId = id
                                    setParameters({...parameters})
                                }}
                                fieldName="Loop Through..."
                                default={
                                    parameters.loopThroughVariableId
                                        ? {
                                              label: props.variables.find(
                                                  (v) =>
                                                      v.id ===
                                                      parameters.loopThroughVariableId
                                              ).name,
                                              value: props.variables.find(
                                                  (v) =>
                                                      v.id ===
                                                      parameters.loopThroughVariableId
                                              ).id,
                                          }
                                        : null
                                }
                                options={props.variables
                                    .filter(
                                        (v) =>
                                            v.multi &&
                                            v.transformId !== props.element.id
                                    )
                                    .map((v) => {
                                        return {
                                            label: v.name,
                                            value: v.id,
                                        }
                                    })}
                            />
                        </FormGroup>

                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={
                                        parameters.storeOutputInSeparateVariableId !==
                                        null
                                    }
                                    onChange={() => {
                                        parameters.storeOutputInSeparateVariableId =
                                            parameters.storeOutputInSeparateVariableId ===
                                            null
                                                ? ''
                                                : null
                                        setParameters({...parameters})
                                    }}
                                />{' '}
                                Store iterated elements in separate variable{' '}
                                {parameters.storeOutputInSeparateVariableId !==
                                null ? (
                                    <div
                                        className="text-muted"
                                        style={{display: 'inline-block'}}>
                                        | Variable will be named "
                                        {parameters.name}Output"
                                    </div>
                                ) : null}
                            </Label>
                        </FormGroup>

                        {/*{parameters.storeOutputInSeparateVariableId !== null ?
							<FormGroup>
								<TextInput
									onValueChange={setStoreOutputInSeparateVariableName(name)}
									onValidChange={() => {}}
									fieldName="Variable Name"
									existing={props.variables.filter(v => v.id !== parameters.storeOutputInSeparateVariableId).map(v => v.name)}
									initial={name || ''}
									disabled={false}
								/>
							</FormGroup>
						: null}*/}

                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
