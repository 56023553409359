import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {Scrollbars} from 'react-custom-scrollbars'
import Switch from 'rc-switch'
import ReactToPrint from 'react-to-print'
import autobind from 'auto-bind'
import moment from 'moment'
import printCSS from './printCSS.css'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const status = this.props.status
            ? this.props.status
            : this.props.editing.metaData.find((m) => m.name === 'status').value
        const workflow = this.props.workflows ? this.props.workflows[0] : []

        this.state = {
            status: {
                label: status,
                value: status,
            },
            valid: true,
            message: false,
            options: workflow.value.map((item) => {
                return {label: item.name, value: item.name}
            }),
            deadline: this.props.editing.metaData.find(
                (o) => o.name === 'deadline'
            ).value,
            isComplete: this.props.editing.isComplete,
            equipment: null,
        }

        this.fetchDeviceName()
    }

    async save() {
        const workflow = this.props.workflows[0]
        const timestamp = moment().toISOString()
        let updateObj = Object.assign(this.props.editing, {})
        if (this.state.isComplete) {
            updateObj.timeEnd = moment().toISOString()
            updateObj.isComplete = this.state.isComplete
        }
        updateObj.store.push(updateObj.metaData)
        updateObj.metaData.find((m) => m.name === 'status').value =
            this.state.status.value
        updateObj.metaData.find((m) => m.name === 'color').value =
            workflow.value.find((o) => o.name === this.state.status.value).color
        updateObj.metaData.find((m) => m.name === 'description').value =
            this.state.description
        updateObj.metaData.find((m) => m.name === 'deadline').value =
            this.state.deadline
        updateObj.metaData.find((m) => m.name === 'operator').value =
            this.state.operator
        updateObj.metaData.find((m) => m.name === 'technician').value =
            this.state.technician
        updateObj.metaData.find((m) => m.name === 'timeUpdated').value =
            timestamp
        updateObj.workTime = this.state.workTime

        await API.patch('states/' + this.props.editing._id, updateObj, 2)
        this.props.toggleModal('editModal')
    }

    async fetchDeviceName() {
        let assets = await API.get('devices')
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                deviceName: assets.find(
                    (a) => a.deviceId === this.props.editing.deviceId
                ).name,
            })
        }
    }

    handleDateChange(date) {
        this.setState({
            deadline: date,
        })
    }

    render() {
        const {modal, toggleModal, editing} = this.props
        const deviceName = this.state.deviceName ? this.state.deviceName : 'N/A'
        const equipmentName =
            editing.equipment && editing.equipment.label
                ? editing.equipment.label
                : 'N/A'
        const ticketId = editing._id
        const formDate = moment().format('dddd, MMMM Do YYYY, h:mm:ss a')

        return (
            <Fragment>
                <Modal
                    style={{backgroundColor: '#FFFFFF'}}
                    size="lg"
                    isOpen={modal}
                    toggle={() => {
                        this.props.toggleModal('editModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            this.props.toggleModal('editModal')
                        }}>
                        View Work Order
                    </ModalHeader>
                    <ModalBody style={{backgroundColor: '#FFFFFF'}}>
                        <div
                            ref={(el) => (this.componentRef = el)}
                            style={{marginTop: 25}}>
                            <Row style={{backgroundColor: '#FFFFFF'}}>
                                <Col xs="12" className="text-center">
                                    <div>
                                        <strong>{deviceName}</strong>
                                    </div>
                                    <div>{equipmentName}</div>
                                    <div>
                                        <small>
                                            <strong>{ticketId}</strong>
                                        </small>
                                    </div>
                                    <div>
                                        <small>{formDate}</small>
                                    </div>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    padding: 15,
                                    marginTop: 15,
                                    marginBottom: 30,
                                }}>
                                <Col xs="8">
                                    <div
                                        style={{
                                            fontSize: '9pt',
                                            color: '#999999',
                                        }}>
                                        Name
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #DDDDDD',
                                            marginBottom: 10,
                                        }}>
                                        <strong>
                                            {deviceName} - {equipmentName}
                                        </strong>
                                    </div>
                                    <br />
                                    <div
                                        style={{
                                            fontSize: '9pt',
                                            color: '#999999',
                                        }}>
                                        Description
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #DDDDDD',
                                            marginBottom: 10,
                                        }}>
                                        {editing.description
                                            ? editing.description
                                            : 'N/A'}
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div
                                        style={{
                                            fontSize: '9pt',
                                            color: '#999999',
                                        }}>
                                        Department
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #DDDDDD',
                                            marginBottom: 10,
                                        }}>
                                        <strong>
                                            {editing.department.value
                                                ? editing.department.value
                                                : 'N/A'}
                                        </strong>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '9pt',
                                            color: '#999999',
                                        }}>
                                        Asset
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #DDDDDD',
                                            marginBottom: 10,
                                        }}>
                                        <strong>
                                            {this.state.deviceName
                                                ? this.state.deviceName
                                                : 'N/A'}
                                        </strong>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '9pt',
                                            color: '#999999',
                                        }}>
                                        Equipment
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #DDDDDD',
                                            marginBottom: 10,
                                        }}>
                                        <strong>
                                            {editing.equipment &&
                                            editing.equipment.label
                                                ? editing.equipment.label
                                                : 'N/A'}
                                        </strong>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '9pt',
                                            color: '#999999',
                                        }}>
                                        Assignment
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #DDDDDD',
                                            marginBottom: 10,
                                        }}>
                                        <strong>
                                            {editing.assignment.label
                                                ? editing.assignment.label
                                                : 'N/A'}
                                        </strong>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row style={{backgroundColor: '#FFFFFF'}}>
                                <Col className="text-center">
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button color="danger" href="#">
                                                Print this out!
                                            </Button>
                                        )}
                                        content={() => this.componentRef}
                                    />
                                </Col>
                            </Row>
                            <hr />
                        </div>
                        <Row style={{backgroundColor: '#FFFFFF'}}>
                            <Col xs="6">
                                <Row>
                                    <Col xs="12">
                                        <label>Change Status:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="asset"
                                            value={this.state.status}
                                            onChange={(status) => {
                                                this.setState({status})
                                            }}
                                            options={this.state.options}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 15}}>
                                    <Col xs="12">
                                        <label>Work Time (minutes):</label>
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state.inputBorderColor,
                                            }}
                                            type="number"
                                            name="workTime"
                                            value={this.state.workTime}
                                            onChange={(field) => {
                                                let v = field.target.value
                                                this.setState({workTime: v})
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 15}}>
                                    <Col xs="12" className="mb-4">
                                        <label>Description & Parts Used:</label>
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state.inputBorderColor,
                                            }}
                                            type="text"
                                            name="name"
                                            value={this.state.description}
                                            onChange={(name) => {
                                                let v = name.target.value,
                                                    color = 'rgb(215,215,215)',
                                                    valid = true,
                                                    message = ''
                                                if (v === '') {
                                                    color = '#dc3545'
                                                    valid = false
                                                }
                                                this.setState({
                                                    description: v,
                                                    valid: valid,
                                                    inputBorderColor: color,
                                                    message: message,
                                                })
                                            }}
                                            onKeyPress={(e) => {
                                                if (
                                                    e.key === 'Enter' &&
                                                    this.state.valid
                                                ) {
                                                    this.save()
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 15}}>
                                    <Col xs="12" className="mb-4">
                                        <label>Technician</label>
                                        <small> (Individual doing work)</small>
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state.inputBorderColor,
                                            }}
                                            type="text"
                                            name="name"
                                            value={this.state.technician}
                                            onChange={(field) => {
                                                this.setState({
                                                    technician:
                                                        field.target.value,
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col xs="12" className="mb-4">
                                        <label>Operator</label>
                                        <small> (Individual on machine)</small>
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state.inputBorderColor,
                                            }}
                                            type="text"
                                            name="name"
                                            value={this.state.operator}
                                            onChange={(field) => {
                                                this.setState({
                                                    operator:
                                                        field.target.value,
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Date:</label>
                                        <DatePicker
                                            selectsStart
                                            selected={
                                                this.state.deadline
                                                    ? moment(
                                                          this.state.deadline
                                                      )
                                                    : moment()
                                            }
                                            onChange={this.handleDateChange}
                                            minDate={moment().toDate()}
                                            placeholderText="Select a day"
                                        />
                                        <small>Optional</small>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 15}}>
                                    <Col>
                                        <label>Is this ticket complete?</label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={this.state.isComplete}
                                            onChange={(isComplete) => {
                                                this.setState({isComplete})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="6" className="mb-6">
                                <div>
                                    <strong>History:</strong>
                                </div>
                                <hr />
                                <Scrollbars
                                    autoHeightMin={400}
                                    autoHeightMax={400}
                                    autoHeight>
                                    <div style={{padding: 25}}>
                                        {editing.store
                                            .slice(0)
                                            .reverse()
                                            .map((item, idx) => {
                                                const status = item.find(
                                                    (o) => o.name === 'status'
                                                ).value
                                                const description = item.find(
                                                    (o) =>
                                                        o.name === 'description'
                                                ).value
                                                const color = item.find(
                                                    (o) => o.name === 'color'
                                                ).value
                                                const timestamp = item.find(
                                                    (o) =>
                                                        o.name === 'timeUpdated'
                                                ).value
                                                const deadline = item.find(
                                                    (o) => o.name === 'deadline'
                                                ).value
                                                const operator = item.find(
                                                    (o) => o.name === 'operator'
                                                )
                                                    ? item.find(
                                                          (o) =>
                                                              o.name ===
                                                              'operator'
                                                      ).value
                                                    : 'N/A'
                                                const technician = item.find(
                                                    (o) =>
                                                        o.name === 'technician'
                                                )
                                                    ? item.find(
                                                          (o) =>
                                                              o.name ===
                                                              'technician'
                                                      ).value
                                                    : 'N/A'
                                                return (
                                                    <div
                                                        key={idx}
                                                        style={{
                                                            fontSize: '8pt',
                                                        }}>
                                                        <div
                                                            style={{
                                                                marginBottom: 10,
                                                                fontSize: 8,
                                                            }}>
                                                            {moment(
                                                                timestamp
                                                            ).calendar(null, {
                                                                lastDay:
                                                                    '[Yesterday], LT',
                                                                sameDay:
                                                                    '[Today], LT',
                                                                nextDay:
                                                                    '[Tomorrow], LT',
                                                                lastWeek:
                                                                    'ddd, MMM D, LT',
                                                                nextWeek:
                                                                    'ddd, MMM D, LT',
                                                                sameElse:
                                                                    'ddd, MMM D, LT',
                                                            })}
                                                        </div>
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    color,
                                                                borderRadius: 10,
                                                                padding: 5,
                                                                color: '#FFFFFF',
                                                            }}>
                                                            {status}
                                                        </div>
                                                        <br />
                                                        <div
                                                            style={{
                                                                marginBottom: 10,
                                                            }}>
                                                            <strong>
                                                                Description
                                                            </strong>
                                                            <br />
                                                            {description}
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginBottom: 10,
                                                            }}>
                                                            <strong>
                                                                Operator
                                                            </strong>
                                                            <br />
                                                            {operator}
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginBottom: 10,
                                                            }}>
                                                            <strong>
                                                                Technician
                                                            </strong>
                                                            <br />
                                                            {technician}
                                                        </div>
                                                        <div>
                                                            <span
                                                                style={{
                                                                    padding: 5,
                                                                    fontSize:
                                                                        '8pt',
                                                                    borderRadius: 5,
                                                                    backgroundColor:
                                                                        'rgba(240,240,240,1)',
                                                                    border: '1px solid rgba(220,220,220,1)',
                                                                }}>
                                                                {moment(
                                                                    deadline
                                                                ).calendar(
                                                                    null,
                                                                    {
                                                                        lastDay:
                                                                            '[Yesterday]',
                                                                        sameDay:
                                                                            '[Today]',
                                                                        nextDay:
                                                                            '[Tomorrow]',
                                                                        lastWeek:
                                                                            'ddd, MMM D',
                                                                        nextWeek:
                                                                            'ddd, MMM D',
                                                                        sameElse:
                                                                            'ddd, MMM D',
                                                                    }
                                                                )}
                                                            </span>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                )
                                            })}
                                        <div style={{fontSize: '8pt'}}>
                                            <div
                                                style={{
                                                    marginBottom: 10,
                                                    fontSize: 8,
                                                }}>
                                                {moment(
                                                    editing.timestamp
                                                ).calendar(null, {
                                                    lastDay: '[Yesterday], LT',
                                                    sameDay: '[Today], LT',
                                                    nextDay: '[Tomorrow], LT',
                                                    lastWeek: 'ddd, MMM D, LT',
                                                    nextWeek: 'ddd, MMM D, LT',
                                                    sameElse: 'ddd, MMM D, LT',
                                                })}
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: '#555555',
                                                    borderRadius: 10,
                                                    padding: 5,
                                                    color: '#FFFFFF',
                                                }}>
                                                TICKET CREATED
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </Scrollbars>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{backgroundColor: '#FFFFFF'}}>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
