import React, {Fragment, Component} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import autobind from 'auto-bind'

import SidewayStatusBarSummary from '../Common/Visuals/SidewayStatusBarSummary'

export default class AssetUsageSummary extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        return (
            <Fragment>
                <div className="loat-none-xs">
                    <div className="d-inline-block">
                        <h5 className="d-inline">Asset Usage Summary</h5>
                        <span className="text-muted text-small d-block"></span>
                    </div>
                </div>
                {this.props.data ? (
                    <SidewayStatusBarSummary
                        active={parseInt(
                            this.props.data.reduce(
                                (total, next) => total + next.active,
                                0
                            ) / this.props.data.length
                        )}
                        dormant={parseInt(
                            this.props.data.reduce(
                                (total, next) => total + next.dormant,
                                0
                            ) / this.props.data.length
                        )}
                        offline={parseInt(
                            this.props.data.reduce(
                                (total, next) => total + next.offline,
                                0
                            ) / this.props.data.length
                        )}
                        height={'40%'}
                        unit={'percentage'}
                    />
                ) : null}
            </Fragment>
        )
    }
}
