import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import flatten from 'flat'
import autobind from 'auto-bind'
import {CSVLink} from 'react-csv'

import Navigation from '../../Navigation'
import TimestampFilter from '../../../Schemas/QueryBuilder/Filter/Timestamp'
import {paginate} from '../../Paginate'
import {DATA_MODEL_ID_MAP} from '../../Block/DATA_MODEL_ID_MAP'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Block Batch Data By Press Time Report',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            loading: true,
            timefilter: {},
            dataModels: [],
            records: [],
        }
    }

    async submit() {
        this.setState({loading: true, records: []})
        const designatedTimestamp = 'Start Date'
        const dataModelId = DATA_MODEL_ID_MAP['Press'].dataModelId

        let filter = []
        if (designatedTimestamp) {
            filter.push({...this.state.timefilter})
            filter[0].path = designatedTimestamp
            filter[0].type = 'DesignatedTimestamp'
        }

        let records = await paginate(dataModelId, filter, {})

        let hashTable = {}

        records = await Promise.all(
            records.map(async (record) => {
                const blockBatchNumber = Object.byString(
                    record,
                    DATA_MODEL_ID_MAP['Press'].masterBlockFieldPath
                )

                // get other operation data
                for (let operation in DATA_MODEL_ID_MAP) {
                    if (operation === 'Press') continue
                    const op = DATA_MODEL_ID_MAP[operation]
                    const {result} = await API.post(
                        'data-models/' + op.dataModelId + '/paginate',
                        {
                            filter: [
                                {
                                    type: 'Text',
                                    logic: 'is',
                                    value: [blockBatchNumber],
                                    path: op.masterBlockFieldPath,
                                },
                            ],
                            sort: {},
                            limit: 1,
                            next: null,
                            previous: null,
                            timezone: localStorage['timezone'],
                        },
                        2
                    )

                    if (result.results[0]) {
                        let obj = {[operation]: result.results[0]}
                        record = {...record, ...obj}
                    }
                }
                record = flatten(record)

                for (let k in record) {
                    if (
                        k.includes('_id') ||
                        k.includes('dataModelId') ||
                        k.includes('deleted')
                    ) {
                        delete record[k]
                        continue
                    }

                    let label = k.split('.')

                    for (let l of label) {
                        if (!hashTable[l]) {
                            const dataModel = this.state.dataModels.find((d) =>
                                d.fields.find((f) => f._id === l)
                            )

                            if (dataModel) {
                                const field = dataModel.fields.find(
                                    (f) => f._id === l
                                )
                                hashTable[l] = field.name
                            }
                        }
                    }

                    label = label.map((l) => hashTable[l] || l).join('.')
                    record[label] = record[k]
                    if (label !== k) delete record[k]
                }
                return record
            })
        )

        this.setState({records, loading: false, ready: true})
    }

    async componentDidMount() {
        this.setState({
            dataModels: await API.get('data-models', 2),
            loading: false,
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Block Batch Data By Press Time Report</h1>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm={{size: 6, offset: 3}}>
                        <Row>
                            <Col>
                                <TimestampFilter
                                    logic={''}
                                    value={''}
                                    dataModelId={''}
                                    fieldId={''}
                                    path={''}
                                    fieldName={'Timerange'}
                                    onSubmit={(timefilter) =>
                                        this.setState({timefilter})
                                    }
                                    isDesignatedTimeEnd={false}
                                    autoSubmit={true}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Button
                                    size="xl"
                                    block
                                    onClick={this.submit}
                                    disabled={this.state.loading}>
                                    GENERATE
                                </Button>
                            </Col>
                        </Row>
                        {this.state.records.length ? (
                            <Row className="mt-4">
                                <Col>
                                    <CSVLink
                                        className="btn btn-block"
                                        data={this.state.records}
                                        filename="Accumine Platform - Block Batch Data By Press Time Export.csv">
                                        Click To Export Data
                                    </CSVLink>
                                </Col>
                            </Row>
                        ) : null}
                    </Col>
                </Row>

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
