import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Label,
    Form,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {secondsToHHMMSS} from 'SDK/helpers'

export default class LightsOutModule extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    id = 'LightsOutModule'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            device: null,
            lightsOutMode: false,
            lightsOutSince: '',
        }
    }

    async goLightsOut() {
        const deviceId = this.props.deviceId
        await API.post(
            'tablets/data',
            [
                {
                    nodeId: deviceId,
                    name: 'Lights Out',
                    value: true,
                    timestamp: moment().valueOf(),
                },
            ],
            2
        )
        this.checkLightsOut()
    }

    async stopLightsOut() {
        const deviceId = this.props.deviceId
        await API.post(
            'tablets/data',
            [
                {
                    nodeId: deviceId,
                    name: 'Lights Out',
                    value: false,
                    timestamp: moment().valueOf(),
                },
            ],
            2
        )
        this.checkLightsOut()
    }

    async checkLightsOut(deviceId = this.props.deviceId) {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: [deviceId]},
                    name: {$in: ['Lights Out']},
                    timeEnd: null,
                },
            },
            2
        )

        if (data.length > 0) {
            this.setState({
                lightsOutMode: true,
                lightsOutSince: moment(data[0].timeStart).format(
                    'hh:mm MMMM DD, YYYY'
                ),
            })
        } else {
            this.setState({
                lightsOutMode: false,
                lightsOutSince: null,
            })
        }
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        this.setState({
            device: devices.find((d) => d.deviceId === this.props.deviceId),
        })
        this.subscriber.add(this.checkLightsOut, 1500, 'checkLightsOut')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const {lightsOutMode, lightsOutSince} = this.state
        return (
            <Fragment>
                {!lightsOutMode ? (
                    <Button
                        color="success"
                        size="lg"
                        onClick={this.goLightsOut}>
                        Go Lights Out
                    </Button>
                ) : null}
                {lightsOutMode ? (
                    <Button
                        color="secondary"
                        size="lg"
                        onClick={this.stopLightsOut}>
                        In Lights Out Mode {lightsOutSince}
                    </Button>
                ) : null}
            </Fragment>
        )
    }
}
