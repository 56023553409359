import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import {Typeahead} from 'react-bootstrap-typeahead'
import IntlMessages from 'util/IntlMessages'
import {DATA_MODEL_ID_MAP} from '../../Block/DATA_MODEL_ID_MAP'

import Navigation from '../../Navigation'
import {paginate} from '../../Paginate'

import * as API from 'SDK/api'

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    var a = s.split('.')
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i]
        if (k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Block Batch Report',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            ready: false,
            generatingData: false,
            batches: [],
            selectedBatch: [],
            data: {
                partNumber: '',
                description: '',
                goodPress: 0,
                qaSampleTK: 0,
                qaSampleFinal: 0,
                operatorGoodBlockCount: 0,
                comments: '',
                totalQASamples: 0,
                totalScrap: 0,
            },
        }
    }

    async fetchBatches() {
        let batches = await paginate('5fc9015bae96bc6be2c1a13e', [], {})
        batches = batches.map((m) => {
            return {
                label: m['Batch Number'],
                value: m,
            }
        })
        this.setState({batches, ready: true})
    }

    async fetchData() {
        this.setState({loading: true})
        const {selectedBatch} = this.state

        if (!selectedBatch.length) return

        let operationRecords = {}
        for (let operation in DATA_MODEL_ID_MAP) {
            const op = DATA_MODEL_ID_MAP[operation]
            const {result} = await API.post(
                'data-models/' + op.dataModelId + '/paginate',
                {
                    filter: [
                        {
                            type: 'Text',
                            logic: 'is',
                            value: [selectedBatch[0].label],
                            path: op.masterBlockFieldPath,
                        },
                    ],
                    sort: {},
                    limit: 1,
                    next: null,
                    previous: null,
                    timezone: localStorage['timezone'],
                },
                2
            )
            operationRecords[operation] = result.results.length
                ? result.results[0]
                : null
        }

        let data = {
            partNumber: '',
            description: '',
            goodPress: 0,
            qaSampleTK: 0,
            qaSampleFinal: 0,
            operatorGoodBlockCount: 0,
            comments: '',
            totalQASamples: 0,
            totalScrap: 0,
        }

        const is3DA =
            selectedBatch[0].value['Block Description']['Type'] === '3DA'

        // part number
        try {
            data.partNumber =
                selectedBatch[0].value['Block Description']['Part Number']
        } catch (error) {
            console.log('Cannot get part number')
        }

        // description
        try {
            data.description =
                selectedBatch[0].value['Block Description']['Block Description']
        } catch (error) {
            console.log('Cannot get description')
        }

        // good press
        try {
            let scrap = 0
            for (let s of DATA_MODEL_ID_MAP['Press'].scrapFieldPaths) {
                const v = Object.byString(operationRecords['Press'], s)
                if (v) {
                    scrap += v
                }
            }
            data.goodPress = operationRecords['Press']['Parts Pressed'] - scrap
        } catch (error) {
            console.log('Cannot get good press')
        }

        // QA Sample TK
        try {
            data.qaSampleTK =
                operationRecords['Kiln']['Unload']['Kiln Unload Scrap'][
                    'QA Sample'
                ]
        } catch (error) {
            console.log('Cannot get qa sample tk')
        }

        // QA Sample Final
        if (is3DA) {
            try {
                data.qaSampleFinal =
                    operationRecords['Final Inspection (3DA Batches)']['Scrap'][
                        'QA Sample'
                    ]
            } catch (error) {
                console.log('Cannot get qa sample final')
            }
        } else {
            try {
                data.qaSampleFinal =
                    operationRecords['Test & Inspect']['Test & Inspect Scrap'][
                        'QA Sample'
                    ]
            } catch (error) {
                console.log('Cannot get qa sample final')
            }
        }

        // Operator Good Block Count
        if (is3DA) {
            try {
                data.operatorGoodBlockCount =
                    operationRecords['Final Inspection (3DA Batches)'][
                        'Good Block Count'
                    ]
            } catch (error) {
                console.log('Cannot get operator good block count')
            }
        } else {
            try {
                data.operatorGoodBlockCount =
                    operationRecords['Test & Inspect']['Good Block Count']
            } catch (error) {
                console.log('Cannot get operator good block count')
            }
        }

        // Comments
        if (is3DA) {
            try {
                data.comments =
                    operationRecords['Final Inspection (3DA Batches)'][
                        'Comments'
                    ]
            } catch (error) {
                console.log('Cannot get comments')
            }
        } else {
            try {
                data.comments = operationRecords['Test & Inspect']['Comments']
            } catch (error) {
                console.log('Cannot get comments')
            }
        }

        // Total QA Samples
        try {
            data.totalQASamples = data.qaSampleTK + data.qaSampleFinal
        } catch (error) {
            console.log('Cannot get total qa samples')
        }

        // Total Scrap
        try {
            data.totalScrap =
                data.goodPress -
                data.operatorGoodBlockCount -
                data.totalQASamples
        } catch (error) {
            console.log('Cannot get total scrap')
        }

        this.setState({data, loading: false})
    }

    handleBatchChange(selectedBatch) {
        this.setState({selectedBatch}, this.fetchData)
    }

    async componentDidMount() {
        this.fetchBatches()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Block Batch Report</h1>
                    </Col>
                </Row>

                {this.state.ready ? (
                    <Fragment>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Typeahead
                                            id={Math.random()}
                                            labelKey={(option) =>
                                                `${option.label}`
                                            }
                                            options={this.state.batches.sort(
                                                (a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                            )}
                                            renderMenuItemChildren={(option) =>
                                                option.label
                                            }
                                            onChange={this.handleBatchChange}
                                        />
                                        <IntlMessages id="Block Batch Number" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        {!this.state.loading &&
                        this.state.selectedBatch.length ? (
                            <Row>
                                <Col xs="12" sm={{size: 8, offset: 2}}>
                                    <Row>
                                        <Col xs="4">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Batch Number
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state
                                                                .selectedBatch[0]
                                                                .label
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs="4">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Part Number
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .partNumber
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs="4">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Description
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .description
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs="4">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Good Press
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .goodPress
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs="4">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            QA Sample TK
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .qaSampleTK
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs="4">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            QA Sample Final
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .qaSampleFinal
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs="12">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Operator Good Block
                                                            Count
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .operatorGoodBlockCount
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs="12">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Comments
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .comments
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs="12">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Total QA Samples
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .totalQASamples
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs="12">
                                            <Card>
                                                <CardBody
                                                    className="text-center"
                                                    style={{padding: 0}}>
                                                    <h5>
                                                        <strong>
                                                            Total Scrap
                                                        </strong>
                                                    </h5>
                                                    <p>
                                                        {
                                                            this.state.data
                                                                .totalScrap
                                                        }
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ) : null}
                    </Fragment>
                ) : (
                    <div className="loading" />
                )}

                {this.state.lading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
