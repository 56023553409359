import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import DatePicker from 'react-datepicker'
import {CSVLink} from 'react-csv'
import ReactPdfTable from './table'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
} from '@react-pdf/renderer'
import fetchData from './fetchData'

function chunkArrayInGroups(arr, size) {
    let chunked = []
    for (let i = 0; i < arr.length; i += size) {
        chunked.push(arr.slice(i, i + size))
    }
    return chunked
}

Font.register({
    family: 'Open Sans',
    fonts: [
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
        },
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
            fontWeight: 600,
        },
    ],
})

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Open Sans',
        fontSize: 12,
    },
    section: {
        margin: 10,
        padding: 10,
    },
})

const ReportPage = ({rows, dateRange}) => {
    return (
        <Page size="Letter" style={styles.page} orientation="landscape">
            <View style={styles.section}>
                <Text style={{fontSize: 30, textAlign: 'center'}}>
                    Equipment Performance Detail Report
                </Text>
                <Text style={{textAlign: 'center'}}>{dateRange}</Text>
                <ReactPdfTable.TableRow>
                    <ReactPdfTable.TableCell
                        text="Date"
                        width={8}
                        align="left"
                    />
                    <ReactPdfTable.TableCell
                        text="Machine"
                        width={10}
                        align="left"
                    />
                    <ReactPdfTable.TableCell
                        text="Part Number"
                        width={17}
                        align="left"
                    />
                    <ReactPdfTable.TableCell text="Goal (pcs)" align="left" />
                    <ReactPdfTable.TableCell text="Actual" align="left" />
                    <ReactPdfTable.TableCell
                        text="Avg Cycle Secs"
                        align="left"
                    />
                    <ReactPdfTable.TableCell text="Perf %" align="left" bold />
                    <ReactPdfTable.TableCell text="Uptime Min" align="left" />
                    <ReactPdfTable.TableCell text="Downtime Min" align="left" />
                    <ReactPdfTable.TableCell
                        text="Operating Min"
                        align="left"
                    />
                    <ReactPdfTable.TableCell text="Util %" bold align="left" />
                    <ReactPdfTable.TableCell text="Actual Pcs" align="left" />
                    <ReactPdfTable.TableCell text="Good Qty" align="left" />
                    <ReactPdfTable.TableCell text="Scrap Qty" align="left" />
                    <ReactPdfTable.TableCell text="Acc %" bold align="left" />
                    <ReactPdfTable.TableCell />
                    <ReactPdfTable.TableCell text="OEE" bold align="center" />
                </ReactPdfTable.TableRow>
                {rows.map((r, i) => {
                    if (r.isTotalRow) {
                        return (
                            <ReactPdfTable.TableRow key={r.key}>
                                <ReactPdfTable.TableCell width={8} />
                                <ReactPdfTable.TableCell width={10} />
                                <ReactPdfTable.TableCell width={17} />
                                <ReactPdfTable.TableCell />
                                <ReactPdfTable.TableCell />
                                <ReactPdfTable.TableCell
                                    text="Avg:"
                                    bold
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Perf %'] + '%'}
                                    align="center"
                                    bold
                                />
                                <ReactPdfTable.TableCell />
                                <ReactPdfTable.TableCell />
                                <ReactPdfTable.TableCell
                                    text="Avg:"
                                    bold
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Util %'] + '%'}
                                    bold
                                    align="center"
                                />
                                <ReactPdfTable.TableCell />
                                <ReactPdfTable.TableCell />
                                <ReactPdfTable.TableCell
                                    text="Avg:"
                                    bold
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Acc %'] + '%'}
                                    bold
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text="Avg:"
                                    bold
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['OEE'] + '%'}
                                    bold
                                    align="center"
                                />
                            </ReactPdfTable.TableRow>
                        )
                    } else {
                        const color = i % 2 ? 'white' : 'lightgrey'
                        return (
                            <ReactPdfTable.TableRow key={r.key}>
                                <ReactPdfTable.TableCell
                                    width={8}
                                    backgroundColor={color}
                                    text={r.tableRow['Date']}
                                />
                                <ReactPdfTable.TableCell
                                    width={10}
                                    backgroundColor={color}
                                    text={r.tableRow['Machine']}
                                />
                                <ReactPdfTable.TableCell
                                    width={17}
                                    backgroundColor={color}
                                    text={r.tableRow['Part Number']}
                                />
                                <ReactPdfTable.TableCell
                                    backgroundColor={color}
                                    text={r.tableRow['Goal (pcs)']}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Actual']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Avg Cycle Secs']}
                                    backgroundColor={color}
                                    align="left"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Perf %'] + '%'}
                                    bold
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Uptime Min']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Downtime Min']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Operating Min']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Util %'] + '%'}
                                    bold
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Actual Pcs']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Good Qty']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Scrap Qty']}
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['Acc %'] + '%'}
                                    bold
                                    backgroundColor={color}
                                    align="center"
                                />
                                <ReactPdfTable.TableCell
                                    backgroundColor={color}
                                />
                                <ReactPdfTable.TableCell
                                    text={r.tableRow['OEE'] + '%'}
                                    bold
                                    backgroundColor={color}
                                    align="center"
                                />
                            </ReactPdfTable.TableRow>
                        )
                    }
                })}
            </View>
            <View style={{padding: 10, margin: 10}}>
                <Text style={{textAlign: 'center'}}>
                    OEE % = Perf% x Util% x Acc%
                </Text>
            </View>
        </Page>
    )
}

export default class HubbellAikenEquipmentPerformanceReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'HubbellAikenEquipmentPerformanceReport'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            dataModels: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            loading: true,
            selectedDevices: [],
            data: null,
            csvData: [],
        }
    }

    async generate() {
        this.setState({loading: true})

        try {
            const data = await fetchData(
                this.state.selectedDevices.length
                    ? this.state.selectedDevices
                    : this.state.devices,
                this.state.start,
                this.state.end
            )
            let csvData = [
                [
                    'Date',
                    'Machine',
                    'Part Number',
                    'Goal (pcs)',
                    'Actual',
                    'Avg Cycle Secs',
                    'Perf %',
                    'Uptime Min',
                    'Downtime Min',
                    'Operating Min',
                    'Util %',
                    'Actual Pcs',
                    'Good Qty',
                    'Scrap Qty',
                    'Acc %',
                    '',
                    'OEE',
                ],
            ]

            for (let row of data.rows) {
                const tRow = row.tableRow
                if (!row.isTotalRow) {
                    csvData.push([
                        moment(row.timestamp).format('YYYY-MM-DD'),
                        tRow['Machine'],
                        tRow['Part Number'],
                        tRow['Goal (pcs)'],
                        tRow['Actual'],
                        tRow['Avg Cycle Secs'],
                        tRow['Perf %'] + '%',
                        tRow['Uptime Min'],
                        tRow['Downtime Min'],
                        tRow['Operating Min'],
                        tRow['Util %'] + '%',
                        tRow['Actual Pcs'],
                        tRow['Good Qty'],
                        tRow['Scrap Qty'],
                        tRow['Acc %'] + '%',
                        '',
                        tRow['OEE'] + '%',
                    ])
                } else {
                    csvData.push([
                        '',
                        '',
                        '',
                        '',
                        '',
                        'Avg:',
                        tRow['Perf %'] + '%', //value
                        '',
                        '',
                        'Avg:',
                        tRow['Util %'] + '%', //value
                        '',
                        '',
                        'Avg:',
                        tRow['Acc %'] + '%',
                        'Avg:',
                        tRow['OEE'] + '%',
                    ])
                }
            }

            this.setState({data, csvData})
            if (!data) {
                alert('No work orders found during selected time period.')
            }
        } catch (error) {
            console.log(error)
            alert('Could not generate report. Please try again.')
        }

        this.setState({loading: false})
    }

    async componentDidMount() {
        try {
            const devices = await API.get('devices'),
                dataModels = await API.get('data-models', 2)

            this.setState({
                devices: devices.map((a) => {
                    return {
                        label: a.name,
                        value: a.deviceId,
                    }
                }),
                dataModels,
                loading: false,
            })
        } catch (error) {
            console.log(error)
            alert('Cannot load settings, please try refreshing the page.')
        }
    }

    render() {
        const width = window.innerWidth * 0.8,
            height = window.innerHeight * 0.7
        return (
            <Fragment>
                {this.state.loading ? <div className="loading" /> : null}
                <Row>
                    <Col xs="12" sm="12">
                        <label>Filter Asset(s):</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="devices"
                            placeholder="Leave empty to include all assets"
                            isMulti
                            value={this.state.selectedDevices}
                            onChange={(selectedDevices) =>
                                this.setState({selectedDevices})
                            }
                            options={this.state.devices}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="4">
                        <label>From:</label>
                        <DatePicker
                            selected={this.state.start}
                            selectsStart
                            startDate={this.state.start}
                            endDate={this.state.end}
                            onChange={(start) => this.setState({start})}
                            filterDate={(date) => {
                                return moment() > date
                            }}
                            disabled={this.state.loading}
                        />
                    </Col>
                    <Col xs="12" sm="4">
                        <label>To:</label>
                        <DatePicker
                            selected={this.state.end}
                            selectsEnd
                            startDate={this.state.start}
                            endDate={this.state.end}
                            onChange={(end) => this.setState({end})}
                            filterDate={(date) => {
                                return moment() > date
                            }}
                            disabled={this.state.loading}
                        />
                    </Col>
                    <Col xs="12" sm="4">
                        <br />
                        <Button
                            color="primary"
                            onClick={this.generate}
                            disabled={this.state.loading}>
                            <i className="iconsmind-Arrow-Refresh" /> Generate
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>

                {this.state.data ? (
                    <>
                        <CSVLink
                            data={this.state.csvData}
                            filename="Equipment Performance Report.csv">
                            Click to export spreadsheet
                        </CSVLink>
                        <PDFViewer width={width} height={height}>
                            <Document>
                                {chunkArrayInGroups(
                                    this.state.data.rows,
                                    25
                                ).map((rows, idx) => (
                                    <ReportPage
                                        key={idx}
                                        rows={rows}
                                        dateRange={this.state.data.dateRange}
                                    />
                                ))}
                            </Document>
                        </PDFViewer>
                    </>
                ) : null}
            </Fragment>
        )
    }
}
