import moment from 'moment'

const TimelineGenerator = (data, priority, start, end) => {
    let distinctTimestamps = [],
        nonOverlappingIntervals = []

    if (moment().isBefore(end)) {
        end = moment()
    }

    for (let i in data) {
        data[i].timeStart = moment(data[i].timeStart).isBefore(start)
            ? moment(start)
            : moment(data[i].timeStart)
        data[i].timeEnd =
            data[i].timeEnd && moment(data[i].timeEnd).isBefore(end)
                ? moment(data[i].timeEnd)
                : moment(end)
        data[i].priority = priority.findIndex((a) => a === data[i].name)

        distinctTimestamps.push(moment(data[i].timeStart).valueOf())
        distinctTimestamps.push(moment(data[i].timeEnd).valueOf())
    }
    distinctTimestamps = [...new Set(distinctTimestamps)].sort()
    for (let i = 0; i < distinctTimestamps.length - 1; i++) {
        let interval = {
            start: moment(distinctTimestamps[i]).valueOf(),
            end: moment(distinctTimestamps[i + 1]).valueOf(),
            value: '',
            _id: null,
        }
        const overlapping = data.filter(
            (a) => a.timeStart <= interval.start && interval.start < a.timeEnd
        )
        const highestPriority = Math.min(...overlapping.map((a) => a.priority))

        interval.value = priority[highestPriority]
        interval._id = overlapping.find((a) => a.priority === highestPriority)
        if (interval._id) {
            interval._id = interval._id._id
            nonOverlappingIntervals.push(interval)
        } else {
            console.log('missing data for: ' + interval)
        }
    }
    return nonOverlappingIntervals
}

export default TimelineGenerator
