import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    ListGroup,
    ListGroupItem,
} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import GenericWidgets from 'SDK/ui/widgets'
import GenericWidgetModal from './genericWidgetConfigurationModal'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            outputs: [],
            custom: [],
            genericSelected: null,
            generic: GenericWidgets,
            genericModalOpen: false,
        }
    }

    toggleGenericModal() {
        this.setState({
            genericModalOpen: !this.state.genericModalOpen,
        })
    }

    loadCustomComponent(component) {
        this.props.loadCustomComponent(component)
        this.props.toggleModal()
    }

    configureGenericComponent(component) {
        this.setState({
            genericModalOpen: true,
            genericSelected: component,
        })
    }

    async fetchOutputs() {
        let states = await API.get('definitions', 1)

        states = [...new Set(states.map((x) => x.name))]

        const records = await API.get('flows', 2)
        if (!records) {
            alert('Cannot fetch list of outputs!')
            return
        }

        let outputs = []

        for (let i in records) {
            outputs = outputs.concat(
                records[i].template
                    .filter((n) => n.transform === '$output')
                    .map((n) => n.params.a)
                    .sort()
            )
        }

        outputs = [...new Set(outputs)]

        outputs = outputs.concat(states)

        this.setState({outputs})
    }

    async componentWillMount() {
        await this.fetchOutputs()
        const custom = await API.get('library', 2)
        if (!custom) {
            return alert('Could not fetch library')
        }
        this.setState({custom})
    }

    render() {
        const {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Visualization Library
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                {this.state.custom.map((x, i) => {
                                    return (
                                        <Button
                                            className="btn btn-secondary btn-block"
                                            key={x._id}
                                            onClick={() => {
                                                this.loadCustomComponent(x)
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}>
                                            {x.name}
                                        </Button>
                                    )
                                })}
                                <Link
                                    className="btn btn-danger btn-block"
                                    to="/app/core/insight-engine">
                                    Manage custom widgets
                                </Link>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                {this.state.genericModalOpen ? (
                    <GenericWidgetModal
                        toggleModal={this.toggleGenericModal}
                        modal={this.state.genericModalOpen}
                        component={this.state.genericSelected}
                        loadComponent={this.props.loadGenericComponent}
                    />
                ) : null}

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
