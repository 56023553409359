const Moment = require('moment')
const {EventEmitter} = require('events')

var dic = require('../transform/transform').default
dic.$input = require('../transform/input').default
dic.$output = require('../transform/output').default

class Flow extends EventEmitter {
    constructor(template) {
        super()

        this.$template = template
        this.$dic = {}
        this.$outputs = {}
        this.$transforms = {}

        this._marked = false
    }
    build() {
        let template = this.$template

        for (let i = 0, l = template.length; i < l; i++) {
            let operation = template[i]

            if (operation.transform === '$input') {
                this.$dic[operation.params.a] = operation.id
            } else if (operation.transform === '$output') {
                if (template.internalURL !== '') {
                    if (operation.params.b.includes(template.externalURL)) {
                        operation.params.b = operation.params.b.replace(
                            template.externalURL,
                            template.internalURL
                        )
                    }
                    operation.params.c = template.internalURL
                } else {
                    operation.params.c = template.externalURL
                }
                operation.params.b +=
                    '/' + template.companyId + '/' + template.flowId
                operation.params.c +=
                    '/' +
                    template.throttleURL +
                    '/' +
                    template.companyId +
                    '/' +
                    template.flowId
            }

            if (!this.$transforms[operation.id]) {
                this.$transforms[operation.id] = new dic[operation.transform](
                    operation.id,
                    operation.inputs,
                    operation.params
                )
            }
            this.$transforms[operation.id].on(
                'error',
                function (error) {
                    this.emit('error', 'Error from ' + operation.id)
                    this.emit('error', error)
                }.bind(this)
            )
            this.$transforms[operation.id].on(
                'latest',
                function (latest) {
                    this.updateLatestTime(latest)
                }.bind(this)
            )
        }

        for (let i = 0, l = template.length; i < l; i++) {
            let inputs = template[i].inputs
            let child = template[i].id

            for (let j = 0, m = inputs.length; j < m; j++) {
                let parent = inputs[j]
                try {
                    this.$transforms[parent].pipe(this.$transforms[child])
                } catch (error) {
                    throw new Error(
                        'Input reference: ' + parent + ' does not exist'
                    )
                }
            }
        }
    }
    write(data) {
        if (!this._marked) {
            for (let name in data) {
                let id = this.$dic[name]
                if (id !== undefined) {
                    this.$transforms[id].write(
                        data[name].map((item) => {
                            item.timestamp = Moment.utc(
                                item.timestamp
                            ).valueOf()
                            return item
                        })
                    )
                }
            }
        }
    }
    destroy() {
        this._marked = true

        return new Promise(
            function (resolve, reject) {
                let count = 0
                for (let id in this.$transforms) {
                    let transform = this.$transforms[id]
                    if (transform.isProcessing()) {
                        count++
                        let timeout = setTimeout(reject, 5000)
                        transform.once('_end', () => {
                            if (--count === 0) {
                                clearTimeout(timeout)
                                transform.destroy()
                                resolve()
                            }
                        })
                    } else {
                        transform.destroy()
                        resolve()
                    }
                }
            }.bind(this)
        )
    }
    toJSON() {
        let stringified = {}
        stringified.$template = this.$template
        stringified.$transforms = this.$transforms
        return stringified
    }
    static fromJSON(stringified) {
        let flow = new Flow(stringified.$template)

        for (let i in flow.$template) {
            let id = flow.$template[i].id
            flow.$transforms[id] = dic[flow.$template[i].transform].fromJSON(
                stringified.$transforms[id]
            )
        }

        flow.build()
        return flow
    }
}

export default Flow
