import moment from 'moment'
import {Button} from 'reactstrap'
import dot from 'dot-object'
import {cloneDeep} from 'lodash-es'
import {evaluate} from 'mathjs'

const simpleFormatter = (props) =>
    props.row[props.column.key] === undefined ? '' : props.row[props.column.key]

const timestampFormatter = (props) =>
    props.row[props.column.key] === undefined
        ? ''
        : moment(props.row[props.column.key]).format('lll')

const manualDataLinkFormatter = (props, renderContextTable, dataModels) => {
    if (props.row[props.column.key] === undefined) {
        return ''
    } else {
        let filteredOut = false
        if (props.column.field.foreignOptionFilter) {
            try {
                const obj = dot.object(cloneDeep(props.row))
                let plainText = props.column.field.foreignOptionFilter.plainText
                for (let instance of props.column.field.foreignOptionFilter
                    .map) {
                    const dataModel = dataModels.find(
                        (a) => a.name === instance.display
                    )
                    plainText = plainText.replace(
                        new RegExp(instance.display, 'g'),
                        'str' + dataModel._id
                    )
                }
                let foreignRow = cloneDeep(props.row)
                for (let key in foreignRow) {
                    if (!key.startsWith(props.column.name)) {
                        delete foreignRow[key]
                    }
                }
                foreignRow = dot.object(foreignRow)
                foreignRow = foreignRow[props.column.name]

                filteredOut = !evaluate(plainText, {
                    ['str' + foreignRow.dataModelId]: foreignRow,
                    ['str' + obj.dataModelId]: obj,
                })
            } catch (error) {
                console.log(error)
            }
        }
        const filter = [
            {
                path: '_id',
                logic: 'is',
                value: [props.row[props.column.key]],
                type: 'ObjectID',
            },
        ]
        return (
            <Button
                style={
                    filteredOut
                        ? {borderRadius: 0, background: 'red', color: 'white'}
                        : {}
                }
                size="xs"
                color="link"
                onClick={(e) =>
                    renderContextTable(
                        e,
                        props.column.field.foreignDataModelId,
                        filter
                    )
                }>
                {filteredOut ? (
                    <>
                        <i className="simple-icon-exclamation" /> Invalid
                        Selection
                    </>
                ) : (
                    <>
                        <i className="simple-icon-link" />{' '}
                        {props.row[props.column.pathToDisplay]}
                    </>
                )}
            </Button>
        )
    }
}

const multiSubModelFormatter = (props, renderContextTable) => {
    let obj = dot.object(cloneDeep(props.row))[props.column.key]
    if (obj === undefined) {
        return ''
    } else {
        return (
            <Button
                size="xs"
                color="link"
                onClick={(e) =>
                    renderContextTable(
                        e,
                        props.column.field.dataModelId,
                        [],
                        obj
                    )
                }>
                <i className="simple-icon-layers" /> {obj.length}
            </Button>
        )
    }
}

const formatters = {
    Text: simpleFormatter,
    TextArea: simpleFormatter,
    Email: simpleFormatter,
    PrimaryID: simpleFormatter,
    ForeignID: manualDataLinkFormatter,
    Number: simpleFormatter,
    Boolean: (props) => (props.row[props.column.key] ? 'Yes' : 'No'),
    Color: (props) =>
        props.row[props.column.key] === undefined
            ? ''
            : props.row[props.column.key],
    DesignatedTimestamp: timestampFormatter,
    DesignatedTimeStart: timestampFormatter,
    DesignatedTimeEnd: timestampFormatter,
    Timestamp: timestampFormatter,
    MultiSubModel: multiSubModelFormatter,
    PickList: simpleFormatter,
}

export default formatters
