import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import DatePicker from 'react-datepicker'
import {paginate} from '../Schemas/common/Paginate'
import ReactPdfTable from './table'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
} from '@react-pdf/renderer'
import fetchData from './fetchData'

function chunkArrayInGroups(arr, size) {
    let chunked = []
    for (let i = 0; i < arr.length; i += size) {
        chunked.push(arr.slice(i, i + size))
    }
    return chunked
}

Font.register({
    family: 'Open Sans',
    fonts: [
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
        },
        {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
            fontWeight: 600,
        },
    ],
})

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Open Sans',
        fontSize: 12,
    },
    section: {
        margin: 10,
        padding: 10,
    },
})

const ReportPage = ({data}) => {
    return (
        <Page size="Letter" style={styles.page} orientation="landscape">
            <View style={styles.section}>
                <Text style={{fontSize: 30, textAlign: 'center'}}>
                    Operator Performance Detail Report
                </Text>
                <Text style={{textAlign: 'center'}}>
                    {data.dateRangeString}
                </Text>
                <Text
                    style={{
                        marginTop: 10,
                        fontSize: 15,
                        fontWeight: 'bold',
                    }}>
                    {data.employeeString}
                </Text>

                <ReactPdfTable.TableRow>
                    <ReactPdfTable.TableCell
                        text="Date"
                        width={10}
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Perf %"
                        bold
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Machine"
                        width={15}
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Part Number"
                        width={18}
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Avg Cycle Secs"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Up time Min"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Down time Min"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Operating Min"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Util %"
                        bold
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Actual Pcs"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Goal Pcs"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Good Qty"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Scrap Qty"
                        align="left"
                        backgroundColor="lightgrey"
                    />
                    <ReactPdfTable.TableCell
                        text="Acc %"
                        bold
                        align="left"
                        backgroundColor="lightgrey"
                    />
                </ReactPdfTable.TableRow>
                {data.rows.map((r) => {
                    return (
                        <ReactPdfTable.TableRow key={r.key}>
                            <ReactPdfTable.TableCell
                                width={10}
                                bold={r.isTotalRow}
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                                text={r.isTotalRow ? 'TOTALS' : r.date}
                                align="left"
                            />
                            <ReactPdfTable.TableCell
                                text={r.performancePercentage + '%'}
                                align="left"
                                bold
                                backgroundColor="lightgrey"
                            />
                            <ReactPdfTable.TableCell
                                text={r.machine}
                                width={15}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.partNumber}
                                width={18}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.averageCycleTime}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.uptimeMins}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.downtimeMins}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.operatingMins}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.utilPercentage + '%'}
                                align="left"
                                bold
                                backgroundColor="lightgrey"
                            />
                            <ReactPdfTable.TableCell
                                text={r.actualPcs}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.goalPcs}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.goodQty}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.scrapQty}
                                align="left"
                                backgroundColor={
                                    r.isTotalRow ? 'lightgrey' : 'white'
                                }
                            />
                            <ReactPdfTable.TableCell
                                text={r.accPercentage + '%'}
                                align="left"
                                bold
                                backgroundColor="lightgrey"
                            />
                        </ReactPdfTable.TableRow>
                    )
                })}
            </View>
            <View style={{padding: 10, margin: 10}}>
                <Text style={{textAlign: 'center'}}>
                    Time totals are converted from minutes to hours
                </Text>
                <Text style={{marginTop: 20}}>
                    Employee Signature: ______________________________________
                    Date: _______________
                </Text>
                <Text style={{marginTop: 20}}>
                    Supervisor Signature: _____________________________________
                    Date: _______________
                </Text>
            </View>
        </Page>
    )
}

export default class HubbellAikenPDFReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'HubbellAikenPDFReport'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            dataModels: [],
            employees: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            loading: true,
            selectedDevices: [],
            selectedEmployees: [],
            data: null,
        }
    }

    async generate() {
        this.setState({loading: true})

        try {
            const data = await fetchData(
                this.state.selectedDevices.length
                    ? this.state.selectedDevices
                    : this.state.devices,
                this.state.selectedEmployees.length
                    ? this.state.selectedEmployees
                    : this.state.employees,
                this.state.start,
                this.state.end
            )
            this.setState({data})
            if (!data) {
                alert("No sign in's found during selected time period.")
            }
        } catch (error) {
            console.log(error)
            alert('Could not generate report. Please try again.')
        }

        this.setState({loading: false})
    }

    async componentDidMount() {
        try {
            const devices = await API.get('devices'),
                dataModels = await API.get('data-models', 2),
                employees = await paginate(
                    dataModels.find((a) => a.name === 'Aiken/Employees')._id,
                    [],
                    {}
                )
            this.setState({
                devices: devices.map((a) => {
                    return {
                        label: a.name,
                        value: a.deviceId,
                    }
                }),
                dataModels,
                employees: employees
                    .sort((a, b) => a.Employee_ID - b.Employee_ID)
                    .map((a) => {
                        return {
                            label: `${a.First_Name} ${a.Last_Name}`,
                            key: a.Employee_ID,
                            value: a.Employee_ID,
                        }
                    }),
                loading: false,
            })
        } catch (error) {
            console.log(error)
            alert('Cannot load settings, please try refreshing the page.')
        }
    }

    render() {
        const width = window.innerWidth * 0.8,
            height = window.innerHeight * 0.7
        return (
            <Fragment>
                {this.state.loading ? <div className="loading" /> : null}
                <Row>
                    <Col xs="12" sm="12">
                        <label>Filter Employee(s):</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="employees"
                            isMulti
                            placeholder="Leave empty to include all employees"
                            value={this.state.selectedEmployees}
                            onChange={(selectedEmployees) =>
                                this.setState({selectedEmployees})
                            }
                            options={this.state.employees}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12">
                        <label>Filter Asset(s):</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="devices"
                            placeholder="Leave empty to include all assets"
                            isMulti
                            value={this.state.selectedDevices}
                            onChange={(selectedDevices) =>
                                this.setState({selectedDevices})
                            }
                            options={this.state.devices}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="4">
                        <label>From:</label>
                        <DatePicker
                            selected={this.state.start}
                            selectsStart
                            startDate={this.state.start}
                            endDate={this.state.end}
                            onChange={(start) => this.setState({start})}
                            filterDate={(date) => {
                                return moment() > date
                            }}
                            disabled={this.state.loading}
                        />
                    </Col>
                    <Col xs="12" sm="4">
                        <label>To:</label>
                        <DatePicker
                            selected={this.state.end}
                            selectsEnd
                            startDate={this.state.start}
                            endDate={this.state.end}
                            onChange={(end) => this.setState({end})}
                            filterDate={(date) => {
                                return moment() > date
                            }}
                            disabled={this.state.loading}
                        />
                    </Col>
                    <Col xs="12" sm="4">
                        <br />
                        <Button
                            color="primary"
                            onClick={this.generate}
                            disabled={this.state.loading}>
                            <i className="iconsmind-Arrow-Refresh" /> Generate
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                {this.state.data ? (
                    <PDFViewer width={width} height={height}>
                        <Document>
                            {this.state.data.map((employee) =>
                                chunkArrayInGroups(employee.rows, 15).map(
                                    (rows, idx) => (
                                        <ReportPage
                                            key={idx}
                                            data={{...employee, rows}}
                                        />
                                    )
                                )
                            )}
                        </Document>
                    </PDFViewer>
                ) : null}
            </Fragment>
        )
    }
}
