import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'
import Sortable from 'react-sortablejs'
import {CompactPicker} from 'react-color'

const DEFAULT_COLOR = 'rgba(0,0,100,0.9)'

var STATUS_DEFAULTS = [
    {id: 0, name: 'Opened', color: DEFAULT_COLOR},
    {id: 1, name: 'On Hold', color: DEFAULT_COLOR},
    {id: 2, name: 'In Progress', color: DEFAULT_COLOR},
    {id: 3, name: 'Complete', color: DEFAULT_COLOR},
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const workflow = this.props.workflows.length
            ? this.props.workflows[0]
            : null

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            name: '',
            description: '',
            color: '#fff',
            valid: false,
            message: false,
            workflow: workflow,
            order: workflow ? this.props.workflows[0].value : STATUS_DEFAULTS,
            status: {name: '', color: 'rgba(0,0,100,0.9)'},
        }
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
    }

    async update(workflow) {
        const timestamp = moment().toISOString()
        const updatedWorkflow = {
            timestamp: timestamp,
            timeStart: timestamp,
            timeEnd: null,
            name: 'Workflow',
            metaData: [
                {
                    name: 'setByUser',
                    value: JSON.parse(localStorage['userObject'])._id,
                },
            ],
            value: this.state.order,
        }

        await API.patch('states/' + workflow._id, updatedWorkflow, 2)

        this.props.onSave()
        this.props.toggleModal()
    }

    async save() {
        const timestamp = moment().toISOString()

        await API.post(
            'states',
            {
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                name: 'Workflow',
                metaData: [
                    {
                        name: 'setByUser',
                        value: JSON.parse(localStorage['userObject'])._id,
                    },
                ],
                value: this.state.order,
            },
            2
        )
        this.props.onSave()
        this.props.toggleModal()
    }

    handleChangeComplete = (color) => {
        let status = this.state.status
        status.color = color.hex
        this.setState({status})
    }

    addStatus = () => {
        let status = this.state.status
        let order = this.state.order
        status.id = moment().unix()
        order.push(status)
        this.setState({order, status})
        this.clearStatus()
    }

    clearStatus = () => {
        this.setState({status: {id: 0, name: '', color: DEFAULT_COLOR}})
    }

    removeItem = (itemId) => {
        let order = this.state.order
        let filtered = order.filter(function (value, index, arr) {
            return value.id != itemId
        })
        this.setState({order: filtered})
    }

    render() {
        const {modal, toggleModal, tickets, workflows} = this.props
        const {workflow} = this.state

        return (
            <Fragment>
                <Modal size="lg" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Create Workflow
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="6" className="mb-4">
                                <strong>Re-order the workflow:</strong>
                                <hr />
                                <Sortable
                                    list={this.state.order}
                                    setList={(newState) =>
                                        this.setState({order: newState})
                                    }
                                    animation={200}
                                    delayOnTouchStart={true}
                                    delay={2}
                                    onChange={(newOrder, sort) => {
                                        const newItems = _.sortBy(
                                            this.state.order,
                                            (item) => {
                                                return newOrder.indexOf(
                                                    item.id.toString()
                                                )
                                            }
                                        )
                                        this.setState({order: newItems})
                                    }}>
                                    {this.state.order.map((item) => (
                                        <div
                                            key={item.id}
                                            data-id={item.id}
                                            style={{
                                                backgroundColor: item.color,
                                                color: '#FFFFFF',
                                                padding: 15,
                                                marginTop: 7.5,
                                            }}>
                                            {item.name}
                                            <span
                                                color="none"
                                                style={{
                                                    float: 'right',
                                                    height: '100%',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    this.removeItem(item.id)
                                                }}>
                                                <i
                                                    className="simple-icon-trash"
                                                    style={{color: '#FFFFFF'}}
                                                />
                                            </span>
                                        </div>
                                    ))}
                                </Sortable>
                            </Col>
                            <Col xs="6" className="mb-4">
                                <strong>Create new workflow item:</strong>
                                <hr />
                                <Label for="name">Name</Label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.status.name}
                                    onChange={(name) => {
                                        let status = this.state.status
                                        status.name = name.target.value
                                        this.setState({status})
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                                <Label
                                    for="CompactPicker"
                                    style={{marginTop: 7.5, marginBottom: 7.5}}>
                                    Choose Color:
                                </Label>
                                <br />
                                <CompactPicker
                                    color={this.state.status.color}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                                <br />
                                <Button
                                    color="secondary"
                                    disabled={false}
                                    onClick={this.addStatus}
                                    style={{marginTop: 15}}>
                                    Add Workflow Item
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={() => {
                                workflow ? this.update(workflow) : this.save()
                            }}
                            style={{marginTop: 15}}>
                            Update Workflow
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
