import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button, Collapse} from 'reactstrap'

import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'

export default (props) => {
    const [advancedIsOpen, toggleAdvanced] = useState(false)
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'DeleteRecord',
        description: props.element.data.opts.description || '',
        variableId: props.element.data.opts.variableId || null,
        requestURL:
            props.element.data.opts.requestURL ||
            'https://data-models.accuminetech.com/data-models',
        submitted: props.element.data.opts.submitted || true,
    })

    const submit = () => {
        props.onChange(parameters, [])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                onChange={(variableId) => {
                                    parameters.variableId = variableId
                                    setParameters({...parameters})
                                }}
                                fieldName="Delete"
                                default={
                                    parameters.variableId
                                        ? {
                                              label: props.variables.find(
                                                  (v) =>
                                                      v.id ===
                                                      parameters.variableId
                                              ).name,
                                              value: parameters.variableId,
                                          }
                                        : null
                                }
                                options={props.variables
                                    .filter((v) => v.type === 'Record')
                                    .map((v) => {
                                        return {
                                            label: v.name,
                                            value: v.id,
                                        }
                                    })}
                            />
                        </FormGroup>

                        <hr />

                        <FormGroup>
                            <p
                                color="link"
                                style={{cursor: 'pointer'}}
                                onClick={() => toggleAdvanced(!advancedIsOpen)}>
                                {advancedIsOpen ? (
                                    <>
                                        <i className="simple-icon-arrow-down" />{' '}
                                        Hide advanced
                                    </>
                                ) : (
                                    <>
                                        <i className="simple-icon-arrow-right" />{' '}
                                        Show advanced
                                    </>
                                )}
                            </p>
                            <Collapse isOpen={advancedIsOpen}>
                                <FormGroup>
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.requestURL = v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Request URL"
                                        existing={[]}
                                        initial={parameters.requestURL}
                                        disabled={false}
                                    />
                                </FormGroup>
                            </Collapse>
                        </FormGroup>

                        <hr />

                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
