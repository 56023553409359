import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import moment from 'moment'
import {Textfit} from 'react-textfit'
import autobind from 'auto-bind'

import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {tileColor} from 'SDK/helpers'

import Donut from './donut'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            activeState: {
                name: '',
                since: '',
            },
            activeBreak: false,
        }
    }

    async setStatus() {
        let states = this.props.live.activeStates
        states = states.filter(
            (state) =>
                state.name === 'Downtime' ||
                state.name === 'In-Cycle' ||
                state.name === 'Machine Waiting' ||
                state.name === 'Machine Off'
        )

        if (this.props.live.activeShiftBreaks.length) {
            this.setState({
                activeBreak: this.props.live.activeShiftBreaks[0].name,
            })
        } else {
            this.setState({
                activeBreak: false,
            })
        }

        if (states.length > 0) {
            let activeState = states.sort((a, b) => a.priority - b.priority)[0]
            if (activeState.name === 'Downtime') {
                /*const downtimeReason = await API.post('historical/raw', {
          query: {
            deviceId: this.props.device.deviceId,
            name: 'Downtime Reason',
            timeEnd: null
          }
        }, 2);*/
                const downtimeReason = this.props.downtimeReason
                    ? [this.props.downtimeReason]
                    : []
                if (downtimeReason.length) {
                    activeState.name = downtimeReason[0].value
                }
            }
            this.setState({
                activeState: {
                    name: activeState.name,
                    color: tileColor(activeState.name),
                    since: moment
                        .duration(moment(activeState.timestamp).diff(moment()))
                        .humanize(true),
                },
            })
        }
    }

    componentDidMount() {
        this.setStatus()
        this.subscriber.add(this.setStatus, 1000 * 1, 'setStatus')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Col
                data-id={this.props.device.deviceId}
                lg="3"
                md="4"
                className="mb-4">
                <Card>
                    <CardHeader
                        className="p-1"
                        style={{
                            textAlign: 'center',
                            background: this.state.activeState.color,
                            color: '#fff',
                        }}>
                        <h3>
                            <strong>
                                <Textfit
                                    mode="single"
                                    forceSingleModeWidth={false}>
                                    {this.props.device.name}
                                </Textfit>
                            </strong>
                        </h3>
                        <p
                            style={{
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginBottom: '0px',
                            }}>
                            {this.state.activeState.name}
                        </p>
                    </CardHeader>
                    <CardBody>
                        <Donut
                            device={this.props.device}
                            timerange={this.props.timerange}
                            utilization={this.props.utilization}
                        />
                    </CardBody>
                    {this.state.activeBreak ? (
                        <CardFooter>
                            <p style={{textAlign: 'center'}}>
                                {this.state.activeBreak}
                            </p>
                        </CardFooter>
                    ) : null}
                </Card>
            </Col>
        )
    }
}
