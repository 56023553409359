import React, {Component, Fragment} from 'react'
import IntlMessages from '../../util/IntlMessages'
import {Row, Card, CardTitle, Form, Label, Input, Button} from 'reactstrap'
import {NavLink} from 'react-router-dom'

import {Colxx} from 'components/CustomBootstrap'
import {connect} from 'react-redux'
import {loginUser, loginUserSSO} from 'redux/actions'
import queryString from 'query-string'

import {version} from '../../../package.json'
import ConnectLogo from '../../assets/img/L2L_ConnectLogo_greenblack.svg'

export class LoginLayout extends Component {
    constructor(props) {
        super(props)

        this.updateUsernameValue = this.updateUsernameValue.bind(this)
        this.updatePasswordValue = this.updatePasswordValue.bind(this)
        this.updateFactoryIdValue = this.updateFactoryIdValue.bind(this)
        this.enterPressed = this.enterPressed.bind(this)

        let params = queryString.parse(this.props.location.search)

        this.state = {
            username: params.user ? params.user : '',
            password: params.password ? params.password : '',
            factoryId: params.factory ? params.factory : '',
            fedLoginToken: params.l2lFedLogin ? params.l2lFedLogin : '',
        }
    }
    onUserLogin() {
        if (
            this.state.username !== '' &&
            this.state.password !== '' &&
            this.state.factoryId !== ''
        ) {
            this.props.loginUser(this.state, this.props.history)
        }
    }
    updateUsernameValue(e) {
        this.setState({
            username: e.target.value.trim(),
        })
    }
    updatePasswordValue(e) {
        this.setState({
            password: e.target.value,
        })
    }
    updateFactoryIdValue(e) {
        this.setState({
            factoryId: e.target.value.trim().toLowerCase(),
        })
    }
    enterPressed(event) {
        if (event.key === 'Enter') {
            this.onUserLogin()
        }
    }

    componentDidMount() {
        document.body.classList.add('background')
        let params = this.props.location.search
            .substring(1, this.props.location.search.length)
            .split('&')
            .map((x) => {
                return {k: x.split('=')[0], v: x.split('=')[1]}
            })

        if (this.state.fedLoginToken !== '') {
            this.props.loginUserSSO(
                {ssoToken: this.state.fedLoginToken},
                this.props.history
            )
        }
        if (params.find((x) => x.k === 'token')) {
            this.props.loginUser(
                {
                    username: '',
                    password: '',
                    factoryId: '',
                    token: params.find((x) => x.k === 'token').v,
                },
                this.props.history
            )
        }
    }
    componentWillUnmount() {
        document.body.classList.remove('background')
    }
    render() {
        const isButtonDisabled =
            this.state.username === '' ||
            this.state.password === '' ||
            this.state.factoryId === ''
        return (
            <Fragment>
                <div className="fixed-background" />
                <main>
                    <div className="container">
                        <Row className="h-100">
                            <Colxx xxs="12" md="8" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="form-side">
                                        <NavLink to={`/`} className="white">
                                            <img
                                                className="logo-single"
                                                src={ConnectLogo}
                                                alt="L2L Connect Logo"
                                            />
                                        </NavLink>
                                        <CardTitle className="mb-4">
                                            <IntlMessages id="user.login-title" />
                                        </CardTitle>
                                        <Form>
                                            <Label className="form-group has-float-label mb-4">
                                                <Input
                                                    type="text"
                                                    defaultValue={
                                                        this.state.factoryId
                                                    }
                                                    onChange={
                                                        this
                                                            .updateFactoryIdValue
                                                    }
                                                    onKeyPress={
                                                        this.enterPressed
                                                    }
                                                />
                                                <IntlMessages id="user.factoryId" />
                                            </Label>
                                            <Label className="form-group has-float-label mb-4">
                                                <Input
                                                    type="text"
                                                    defaultValue={
                                                        this.state.username
                                                    }
                                                    onChange={
                                                        this.updateUsernameValue
                                                    }
                                                    onKeyPress={
                                                        this.enterPressed
                                                    }
                                                />
                                                <IntlMessages id="user.username" />
                                            </Label>
                                            <Label className="form-group has-float-label mb-4">
                                                <Input
                                                    type="password"
                                                    defaultValue={
                                                        this.state.password
                                                    }
                                                    onChange={
                                                        this.updatePasswordValue
                                                    }
                                                    onKeyPress={
                                                        this.enterPressed
                                                    }
                                                />
                                                <IntlMessages
                                                    id="user.password"
                                                    defaultValue={
                                                        this.state.password
                                                    }
                                                />
                                            </Label>
                                            <div className="d-flex align-items-center">
                                                {/*<NavLink to={`/forgot-password`}>
                          <IntlMessages id="user.forgot-password-question" />
                        </NavLink>*/}
                                                <Button
                                                    color="primary"
                                                    className="login-btn btn-shadow"
                                                    size="lg"
                                                    disabled={isButtonDisabled}
                                                    onClick={() =>
                                                        this.onUserLogin()
                                                    }
                                                    onKeyPress={this.enterPressed.bind(
                                                        this
                                                    )}>
                                                    <IntlMessages id="user.login-button" />
                                                </Button>
                                            </div>
                                            <div className="text-center">
                                                Version {version}
                                            </div>
                                        </Form>
                                    </div>
                                </Card>
                            </Colxx>
                        </Row>
                    </div>
                </main>
            </Fragment>
        )
    }
}
const mapStateToProps = ({authUser}) => {
    const {user, loading} = authUser
    return {user, loading}
}

export default connect(mapStateToProps, {
    loginUser,
    loginUserSSO,
})(LoginLayout)
