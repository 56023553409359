import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import autobind from 'auto-bind'

import DetailModal from './modals/edit'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            modalOpen: false,
        }
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen,
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-0">Data Model Details</h1>
                        <h4 className="text-muted">
                            Click below to update the name and/or description of
                            this data model.
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={this.toggleModal}>
                            Update Data Model
                        </Button>
                        <hr />
                    </Col>
                </Row>
                {this.state.modalOpen ? (
                    <DetailModal
                        toggle={this.toggleModal}
                        open={this.state.modalOpen}
                        {...this.props}
                    />
                ) : null}
            </Fragment>
        )
    }
}
