import * as API from 'SDK/api'
import moment from 'moment'

class MaterialAPI {
    static getModels = async () => {
        const dataModelsList = await API.get('data-models', 2)
        const partModel = dataModelsList.find((d) => d.name === 'MES/Parts')
        const hmiModel = dataModelsList.find(
            (d) => d.name === 'MES/Material Entry'
        )
        const downtimeModel = dataModelsList.find(
            (d) => d.name === 'Classic/Downtime Reason'
        )
        return {partModel, downtimeModel, hmiModel}
    }

    static isEnabled = async () => {
        const models = await this.getModels()
        if (models.partModel && models.downtimeModel && models.hmiModel) {
            return true
        } else {
            return false
        }
    }

    static getDowntimeEntryRecords = async (dataModelId, deviceId, range) => {
        const reasons = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: deviceId,
                    name: 'Downtime Reason',
                    timeStart: {
                        $gte: moment(range[0]).toISOString(),
                    },
                    timeEnd: {
                        $lte: moment(range[1]).toISOString(),
                    },
                },
            },
            2
        )

        if (reasons) {
            return reasons
        }
    }

    static getMaterialRecords = async (dataModelId, deviceId) => {
        const orderBy = 'ascending'
        let {result, count} = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {
                    fieldName: 'partNumber',
                    direction: orderBy,
                },
                limit: 1000,
                next: null,
                previous: null,
                filter: [
                    {
                        type: 'PrimaryID',
                        logic: 'contains',
                        value: [deviceId],
                        path: 'asset.deviceId',
                    },
                ],
                timezone: localStorage['timezone'],
            },
            2
        )

        if (count) {
            return result.results
        }
    }

    static getHmiEntryRecords = async (dataModelId, deviceId, range) => {
        const orderBy = 'ascending'
        let {result, count} = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {
                    fieldName: 'timestamp',
                    direction: orderBy,
                },
                limit: 1000,
                next: null,
                previous: null,
                filter: [
                    {
                        path: 'asset.deviceId',
                        logic: 'is',
                        type: 'Text',
                        value: [deviceId],
                    },
                    {
                        logic: 'between',
                        value: [
                            moment(range[0]).toISOString(),
                            moment(range[1]).toISOString(),
                        ],
                        prettyString: '',
                        dataModelId: dataModelId,
                        fieldName: 'Start_Time',
                        type: 'DesignatedTimeStart',
                        path: 'Start_Time',
                    },
                ],
            },
            2
        )

        if (count) {
            return result.results
        }
    }

    static saveHmiRecord = async (dataModelId, deviceId, materialId) => {
        const record = {
            Start_Time: moment().toISOString(),
            asset: deviceId,
            material: materialId,
            End_Time: 'active',
        }

        let data = await API.post(
            `data-models/${dataModelId}/add-record`,
            record,
            2
        )

        if (data) {
            return data
        } else {
            return false
        }
    }

    static editHmiRecord = async (dataModelId, deviceId, material) => {
        let data = await API.patch(
            `data-models/${dataModelId}/edit-record`,
            {
                ...material,
                End_Time: moment().toISOString(),
            },
            2
        )

        console.log(data)

        if (data) {
            return true
        } else {
            return false
        }
    }

    static getActiveHmiRecord = async (dataModelId, deviceId) => {
        const orderBy = 'ascending'
        let {result, count} = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {
                    fieldName: 'timestamp',
                    direction: orderBy,
                },
                limit: 1,
                next: null,
                previous: null,
                filter: [
                    {
                        path: 'End_Time',
                        logic: 'isActive',
                        type: 'DesignatedTimeEnd',
                    },
                    {
                        path: 'asset.deviceId',
                        logic: 'is',
                        type: 'Text',
                        value: [deviceId],
                    },
                ],
            },
            2
        )

        if (count) {
            return result.results
        }
    }
}

export default MaterialAPI
