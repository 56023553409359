import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import MapMarker from './Map/MapMarker'

const DEFAULT_INTERVAL = 60000

export default class Map extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    requiredOutputs = ['Position']
    showBorder = false
    id = 'Map'
    static propTypes = {
        deviceIds: PropTypes.array,
        name: PropTypes.string,
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    constructor(props) {
        super(props)
        autobind(this)
        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
        }
    }
    async componentWillMount() {
        this.setState({assets: await API.get('devices')})
    }

    render() {
        return (
            <Fragment>
                <Row style={{padding: 25}}>
                    {this.state.assets.map((x, idx) => {
                        return <SingleMap key={idx} deviceId={x.deviceId} />
                    })}
                </Row>
            </Fragment>
        )
    }
}

class SingleMap extends Component {
    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    componentWillMount() {
        this.fetchData()
        this.subscriber.add(this.fetchData, DEFAULT_INTERVAL, 'fetchData')
    }

    backward() {
        this.setState({
            start: moment(this.state.start.add(-1, 'days')),
            end: moment(this.state.end.add(-1, 'days')),
        })
        this.fetchData()
    }

    forward() {
        if (this.state.start.isSame(moment(), 'day')) {
            return
        }
        this.setState({
            start: moment(this.state.start.add(1, 'days')),
            end: moment(this.state.end.add(1, 'days')),
        })
        this.fetchData()
    }

    async fetchData() {
        const start = moment(this.state.start).toISOString(),
            end = moment(this.state.end).endOf('day').toISOString()

        let {data} = await API.post('historical/raw', {
            deviceId: this.props.deviceId,
            name: {
                $in: ['Position'],
            },
            $or: [
                {
                    timeStart: {
                        $gte: start,
                        $lt: end,
                    },
                },
                {
                    timeStart: {
                        $lt: start,
                    },
                    timeEnd: {
                        $gt: start,
                    },
                },
                {
                    timeStart: {
                        $lt: end,
                    },
                    timeEnd: null,
                },
            ],
        })

        this.setState({data: data, loading: false}, this.getMarkers)
    }

    constructor(props) {
        super(props)
        autobind(this)
        this.subscriber = new Subscriber()

        this.state = {
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            map: {
                center: {
                    lat: 41.850033,
                    lng: -94.6500523,
                },
                zoom: 2,
            },
        }
    }

    getMarkers() {
        let elements = []

        this.state.data.forEach((data) => {
            let lat = data.metaData.find((m) => m.name === 'Lat')
            let long = data.metaData.find((m) => m.name === 'Long')
            if (lat && long) {
                elements.push(
                    <MapMarker
                        lng={long.value}
                        lat={lat.value}
                        style={{
                            borderRadius: 100,
                            height: 20,
                            width: 20,
                            backgroundColor: 'rgba(100,100,200,1)',
                        }}
                    />
                )
            }
        })

        if (elements.length > 0) {
            this.setState({markers: elements}, this.setMapPosition)
        } else {
            this.setState({markers: []})
        }
    }

    setMapPosition() {
        this.setState({
            map: {
                center: {
                    lat: this.state.markers[0].props.lat,
                    lng: this.state.markers[0].props.lng,
                },
                zoom: 18,
            },
        })
    }

    render() {
        const back = moment(this.state.start)
                .add(-1, 'days')
                .format('MMM D, YYYY'),
            forward = moment(this.state.start)
                .add(1, 'days')
                .format('MMM D, YYYY'),
            disableForward = this.state.start.isSame(moment(), 'day'),
            todayLiteral = this.state.start.format('MM-DD-YYYY')

        return (
            <Fragment>
                <Col className="text-left mt-4">
                    <Button size="xs" color="primary" onClick={this.backward}>
                        <i className="simple-icon-arrow-left" />
                    </Button>
                </Col>
                <Col className="text-center" style={{fontWeight: '900'}}>
                    {todayLiteral}
                </Col>
                <Col className="text-right">
                    <Button
                        size="xs"
                        color="primary"
                        disabled={disableForward}
                        onClick={this.forward}>
                        <i className="simple-icon-arrow-right" />
                    </Button>
                </Col>
                <div style={{height: '50vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: 'AIzaSyC6XaAMd-9WMzPoTS1X9PKDZBACvwpVZKE',
                        }}
                        center={this.state.map.center}
                        zoom={this.state.map.zoom}>
                        {this.state.markers}
                    </GoogleMapReact>
                </div>
            </Fragment>
        )
    }
}
