import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

const OPTIONS_DEPARTMENT = [
    {
        label: 'Maintenance',
        value: 'Maintenance',
    },
    {
        label: 'Tool Room',
        value: 'Tool Room',
    },
    {
        label: 'Engineering',
        value: 'Engineering',
    },
    {
        label: 'Facilities',
        value: 'Facilities',
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            name: '',
            description: '',
            color: '#fff',
            valid: {
                department: false,
                assignment: false,
                description: false,
            },
            message: false,
            deadline: moment(),
            userOptions: [],
            equipmentOptions: [],
            equipmentHistory: [],
            devices: [],
        }

        this.getUserOptions()
        this.getEquipmentOptions()
        this.fetchDevices()
    }

    handleDateChange(date) {
        this.setState({
            deadline: date,
        })
    }

    isFormValid(badKeyCallback) {
        let isFormValid = true
        let badKeys = []
        for (const [key, value] of Object.entries(this.state.valid)) {
            isFormValid = isFormValid && value === true
            value === false ? badKeys.push(key) : null
        }
        badKeyCallback(badKeys)
        return isFormValid
    }

    async save() {
        if (
            !this.isFormValid((badKeys) => {
                badKeys.length &&
                    alert(
                        "Can't submit form. These fields are empty: " +
                            badKeys.toString()
                    )
            })
        ) {
            return false
        }

        const timestamp = moment().toISOString()
        const {workflows} = this.props
        const workflow = workflows[0]

        await API.post(
            'states',
            {
                deviceId: this.state.deviceId,
                nodeId: this.state.deviceId,
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                name: 'Maintenance Ticket',
                store: [],
                isComplete: false,
                line: this.state.line,
                department: this.state.department,
                description: this.state.description,
                assignment: this.state.assignment,
                equipment: this.state.equipment,
                metaData: [
                    {
                        name: 'status',
                        value: workflow.value[0].name,
                    },
                    {
                        name: 'color',
                        value: workflow.value[0].color,
                    },
                    {
                        name: 'description',
                        value: this.state.description,
                    },
                    {
                        name: 'deadline',
                        value: this.state.deadline,
                    },
                    {
                        name: 'timeUpdated',
                        value: timestamp,
                    },
                    {
                        name: 'operator',
                        value: 'N/A',
                    },
                    {
                        name: 'technician',
                        value: 'N/A',
                    },
                    {
                        name: 'userId',
                        value: JSON.parse(localStorage['userObject'])._id,
                    },
                ],
                value: this.state.name,
            },
            2
        )

        this.props.toggleModal()
    }

    async getUserOptions() {
        const users = await API.get('users?all=true', 2)
        if (!users) return alert('Could not fetch users')

        let userOptions = users.map((u) => {
            return {
                label: `${u.username} (${u.firstName} ${u.lastName})`,
                value: u.username,
            }
        })
        this.setState({userOptions})
    }

    async getEquipmentOptions() {
        let equipment = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Equipment',
                },
            },
            2
        )

        if (equipment) {
            this.setState({equipmentHistory: equipment})
        }
    }

    setEquipmentOptions(lineName) {
        if (
            !this.state.equipmentHistory[0] ||
            !this.state.equipmentHistory[0].store
        )
            return
        let equipmentOptions = []

        this.state.equipmentHistory[0].store.map((u) => {
            if (u.Line == lineName)
                equipmentOptions.push({
                    label: u.Asset_Desc,
                    value: u.Asset,
                    line: lineName,
                })
        })

        this.setState({equipmentOptions})
    }

    async fetchDevices() {
        let devices = await API.get('devices')
        if (!devices) {
            return alert('There was an error fetching data')
        }
        this.setState({devices})
    }

    getDeviceName(deviceId) {
        return this.state.devices.find((a) => a.deviceId === deviceId)
            ? this.state.devices.find((a) => a.deviceId === deviceId).name
            : 'N/A'
    }

    render() {
        const {modal, toggleModal, tickets} = this.props

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Create Ticket
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                Department:
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="environment"
                                    value={this.state.department}
                                    onChange={(department) => {
                                        let valid = this.state.valid
                                        valid['department'] = department && true
                                        this.setState({department, valid})
                                    }}
                                    options={OPTIONS_DEPARTMENT}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                Supervisor:
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="environment"
                                    value={this.state.assignment}
                                    onChange={(assignment) => {
                                        let valid = this.state.valid
                                        valid['assignment'] = assignment && true
                                        this.setState({assignment, valid})
                                    }}
                                    options={this.state.userOptions}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <AssetPicker
                                    cb={(field, deviceId) => {
                                        this.setState({deviceId})
                                        this.setEquipmentOptions(
                                            this.getDeviceName(deviceId)
                                        )
                                    }}
                                />
                                <small>Optional</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                Equipment:
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="environment"
                                    value={this.state.equipment}
                                    onChange={(equipment) => {
                                        let valid = this.state.valid
                                        valid['equipment'] = equipment && true
                                        this.setState({equipment, valid})
                                    }}
                                    options={this.state.equipmentOptions}
                                />
                                <small>Optional</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Description:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.description}
                                    onChange={(description) => {
                                        let valid = this.state.valid
                                        valid['description'] =
                                            description && true
                                        this.setState({
                                            description:
                                                description.target.value,
                                            valid,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Date:</label>
                                <DatePicker
                                    selectsStart
                                    selected={
                                        this.state.deadline
                                            ? moment(this.state.deadline)
                                            : moment()
                                    }
                                    onChange={this.handleDateChange}
                                    minDate={moment().toDate()}
                                    placeholderText="Select a day"
                                />
                                <small>Optional</small>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
