import * as API from 'SDK/api'

const BATCH_RAW_AMOUNTS_MAP = {
    Antimony: 'Antimony',
    Binder: 'Binder',
    Bismuth: 'Bismuth',
    Boron: 'Boron',
    CMC: 'CMC',
    Cobalt: 'Cobalt',
    Defoamer: 'Defoamer',
    Dispersant: 'Dispersant',
    Glass: 'Glass',
    Laponite: 'Laponite',
    Manganese: 'Manganese',
    Mullite: 'Mullite',
    Nickel: 'Nickel',
    Plasticizer: 'Plasticizer',
    'Potassium Acetate': 'Potassium Acetate',
    'Silver Nitrate': 'Silver Nitrate',
    Tin: 'Tin',
    TiO2: 'TiO2',
    Zinc: 'Zinc',
}

const processAdditiveBatch = async (batchRecord, batchRawAmounts) => {
    let errored = false
    let batchType = batchRecord['Type']['Value'] + ' Additive',
        batchRawAmountRecord = batchRawAmounts.find(
            (a) => a['Batch Type'] === batchType
        )

    if (!batchRawAmountRecord) {
        alert(
            'Could not find batch type! Cannot process raw material de-incrementing.'
        )
        errored = true
    }

    let lotsToUpdate = []
    for (let k in batchRecord) {
        if (
            batchRecord[k].constructor === Array &&
            batchRecord[k].length > 0 &&
            batchRecord[k][0].Lot.dataModelId === '5fc554e6cca7411395293a68'
        ) {
            let lots = batchRecord[k]
            // find type of raw material
            const rawMaterial = lots[0]['Lot']['Raw Chemical']['Value']

            // find out how much to de-increment lot
            let left =
                batchRawAmountRecord[BATCH_RAW_AMOUNTS_MAP[rawMaterial]] || 0

            for (let i in lots) {
                if (left > 0) {
                    const lotQty = lots[i]['Lot']['Raw Quantity'],
                        amountToDeduct = left > lotQty ? lotQty : left
                    lots[i]['Lot']['Raw Quantity'] -= amountToDeduct
                    left -= amountToDeduct
                    lotsToUpdate.push(lots[i])
                }
            }

            if (left > 0) {
                alert(
                    'There is not enough ' +
                        rawMaterial +
                        ' in the selected lot(s) to create this batch.'
                )
                errored = true
            }
        }
    }

    if (errored) {
        await API.remove(
            'data-models/5fc689840283dc2398844995/delete-record/' +
                batchRecord._id,
            2
        )
        return
    }

    for (let i in lotsToUpdate) {
        for (let j in lotsToUpdate[i].Lot) {
            if (
                lotsToUpdate[i].Lot[j].constructor === Object &&
                lotsToUpdate[i].Lot[j]._id
            ) {
                lotsToUpdate[i].Lot[j] = lotsToUpdate[i].Lot[j]._id
            }
        }
        await API.patch(
            'data-models/5fc554e6cca7411395293a68/edit-record',
            lotsToUpdate[i].Lot,
            2
        )
    }
}

const processCalcinedAdditiveBatch = async (batchRecord, batchRawAmounts) => {
    let errored = false
    let batchType =
            batchRecord['Additive Batch']['Type']['Value'] + ' Cal Additive',
        batchRawAmountRecord = batchRawAmounts.find(
            (a) => a['Batch Type'] === batchType
        )

    if (!batchRawAmountRecord) {
        errored = true
        alert(
            'Could not find batch type! Cannot process raw material de-incrementing.'
        )
    }

    let lotsToUpdate = []

    for (let k in batchRecord) {
        if (
            batchRecord[k].constructor === Object &&
            batchRecord[k].dataModelId === '5fc554e6cca7411395293a68'
        ) {
            let lot = batchRecord[k]

            // find type of raw material
            const rawMaterial = lot['Raw Chemical']['Value']

            // find out how much to de-increment lot
            const deincrementValue =
                batchRawAmountRecord[BATCH_RAW_AMOUNTS_MAP[rawMaterial]]

            // update raw material lot
            lot['Raw Quantity'] = lot['Raw Quantity'] - (deincrementValue || 0)

            if (lot['Raw Quantity'] < 0) {
                alert(
                    'There is enough ' +
                        rawMaterial +
                        ' in the selected lot to create this batch.'
                )
                errored = true
            }

            lotsToUpdate.push(lot)
        }
    }

    if (errored) {
        await API.remove(
            'data-models/5fc7d67607ceae4f317ffcdf/delete-record/' +
                batchRecord._id,
            2
        )
        return
    }

    for (let i in lotsToUpdate) {
        for (let j in lotsToUpdate[i]) {
            if (
                lotsToUpdate[i][j].constructor === Object &&
                lotsToUpdate[i][j]._id
            ) {
                lotsToUpdate[i][j] = lotsToUpdate[i][j]._id
            }
        }
        await API.patch(
            'data-models/5fc554e6cca7411395293a68/edit-record',
            lotsToUpdate[i],
            2
        )
    }
}

const processPVABatch = async (batchRecord, batchRawAmounts) => {
    let errored = false
    let batchRawAmountRecord = batchRawAmounts.find(
        (a) => a['Batch Type'] === 'PVA'
    )

    if (!batchRawAmountRecord) {
        alert(
            'Could not find batch type! Cannot process raw material de-incrementing.'
        )
        errored = true
    }

    let lotsToUpdate = []

    for (let k in batchRecord) {
        if (
            batchRecord[k].constructor === Object &&
            batchRecord[k].dataModelId === '5fc554e6cca7411395293a68'
        ) {
            let lot = batchRecord[k]

            // find type of raw material
            const rawMaterial = lot['Raw Chemical']['Value']

            // find out how much to de-increment lot
            const deincrementValue =
                batchRawAmountRecord[BATCH_RAW_AMOUNTS_MAP[rawMaterial]]

            // update raw material lot
            lot['Raw Quantity'] = lot['Raw Quantity'] - (deincrementValue || 0)

            if (lot['Raw Quantity'] < 0) {
                errored = true
                alert(
                    'There is not enough ' +
                        rawMaterial +
                        ' in the selected lot to create this batch.'
                )
            }

            lotsToUpdate.push(lot)
        }
    }

    if (errored) {
        await API.remove(
            'data-models/5fc7df2a07ceae4f317ffcf6/delete-record/' +
                batchRecord._id,
            2
        )
        return
    }

    for (let i in lotsToUpdate) {
        for (let j in lotsToUpdate[i]) {
            if (
                lotsToUpdate[i][j].constructor === Object &&
                lotsToUpdate[i][j]._id
            ) {
                lotsToUpdate[i][j] = lotsToUpdate[i][j]._id
            }
        }
        await API.patch(
            'data-models/5fc554e6cca7411395293a68/edit-record',
            lotsToUpdate[i],
            2
        )
    }
}

const processDispersionBatch = async (batchRecord, batchRawAmounts) => {
    let errored = false
    if (
        !batchRecord['Cal Additive Batches'] ||
        batchRecord['Cal Additive Batches'].length === 0
    ) {
        return alert(
            'Could not find batch type! Cannot process raw material de-incrementing.'
        )
    }
    let batchType =
        batchRecord['Cal Additive Batches'][0]['Cal Additive Batch'][
            'Additive Batch'
        ]['Type']['Value'] + ' Dispersion'

    let batchRawAmountRecord = batchRawAmounts.find(
        (a) => a['Batch Type'] === batchType
    )

    if (!batchRawAmountRecord) {
        alert(
            'Could not find batch type! Cannot process raw material de-incrementing.'
        )
        errored = true
    }

    let lotsToUpdate = []

    for (let k in batchRecord) {
        if (
            batchRecord[k].constructor === Object &&
            batchRecord[k].dataModelId === '5fc554e6cca7411395293a68'
        ) {
            let lot = batchRecord[k]

            // find type of raw material
            const rawMaterial = lot['Raw Chemical']['Value']

            // find out how much to de-increment lot
            const deincrementValue =
                batchRawAmountRecord[BATCH_RAW_AMOUNTS_MAP[rawMaterial]]

            // update raw material lot
            lot['Raw Quantity'] = lot['Raw Quantity'] - (deincrementValue || 0)

            if (lot['Raw Quantity'] < 0) {
                alert(
                    'There is not enough ' +
                        rawMaterial +
                        ' in the selected lot to create this batch.'
                )
                errored = true
            }

            lotsToUpdate.push(lot)
        }
    }

    if (errored) {
        await API.remove(
            'data-models/5fc7e1e907ceae4f317ffd00/delete-record/' +
                batchRecord._id,
            2
        )
        return
    }

    for (let i in lotsToUpdate) {
        for (let j in lotsToUpdate[i]) {
            if (
                lotsToUpdate[i][j].constructor === Object &&
                lotsToUpdate[i][j]._id
            ) {
                lotsToUpdate[i][j] = lotsToUpdate[i][j]._id
            }
        }
        await API.patch(
            'data-models/5fc554e6cca7411395293a68/edit-record',
            lotsToUpdate[i],
            2
        )
    }

    // mark cal additive batches as used
    for (let i in batchRecord['Cal Additive Batches']) {
        let calAdditiveBatch =
            batchRecord['Cal Additive Batches'][i]['Cal Additive Batch']
        calAdditiveBatch.Used = true
        await API.patch(
            'data-models/5fc7d67607ceae4f317ffcdf/edit-record',
            calAdditiveBatch,
            2
        )
    }
}

const processPowderBatch = async (batchRecord, batchRawAmounts) => {
    // mark dispersion batches as used
    for (let i in batchRecord['Dispersion Batches']) {
        let dispersionBatch =
            batchRecord['Dispersion Batches'][i]['Dispersion Batch']
        dispersionBatch.Used = true
        await API.patch(
            'data-models/5fc7e1e907ceae4f317ffd00/edit-record',
            dispersionBatch,
            2
        )
    }
}

const processCollarBatch = async (batchRecord, batchRawAmounts) => {
    let errored = false
    let batchRawAmountRecord = batchRawAmounts.find(
        (a) => a['Batch Type'] === 'Collar_Mix Standard'
    )

    if (!batchRawAmountRecord) {
        errored = true
        alert(
            'Could not find batch type! Cannot process raw material de-incrementing.'
        )
    }

    let lotsToUpdate = []

    for (let k in batchRecord) {
        if (
            batchRecord[k].constructor === Object &&
            batchRecord[k].dataModelId === '5fc554e6cca7411395293a68'
        ) {
            let lot = batchRecord[k]

            // find type of raw material
            const rawMaterial = lot['Raw Chemical']['Value']

            // find out how much to de-increment lot
            const deincrementValue =
                batchRawAmountRecord[BATCH_RAW_AMOUNTS_MAP[rawMaterial]]

            // update raw material lot
            lot['Raw Quantity'] = lot['Raw Quantity'] - (deincrementValue || 0)

            if (lot['Raw Quantity'] < 0) {
                errored = true
                alert(
                    'There is not enough ' +
                        rawMaterial +
                        ' in the selected lot to create this batch.'
                )
            }

            lotsToUpdate.push(lot)
        }
    }
    if (errored) {
        await API.remove(
            'data-models/5fc7ef7c07ceae4f317ffd37/delete-record/' +
                batchRecord._id,
            2
        )
        return
    }

    for (let i in lotsToUpdate) {
        for (let j in lotsToUpdate[i]) {
            if (
                lotsToUpdate[i][j].constructor === Object &&
                lotsToUpdate[i][j]._id
            ) {
                lotsToUpdate[i][j] = lotsToUpdate[i][j]._id
            }
        }
        await API.patch(
            'data-models/5fc554e6cca7411395293a68/edit-record',
            lotsToUpdate[i],
            2
        )
    }
}

export const deincrementRawMaterial = async (batchRecord) => {
    let lotNumbers = []

    /*for (let k in batchRecord) {
		if (batchRecord[k].constructor === Object && batchRecord[k]._id) {
			lotNumbers.push(batchRecord[k]['Raw Lot Number']);
		}
	}*/

    const batchRawAmounts = await fetchBatchRawAmounts()

    if (batchRecord.dataModelId === '5fc689840283dc2398844995') {
        // Additive Batch
        processAdditiveBatch(batchRecord, batchRawAmounts)
    } else if (batchRecord.dataModelId === '5fc7d67607ceae4f317ffcdf') {
        // Cal Additive Batch
        processCalcinedAdditiveBatch(batchRecord, batchRawAmounts)
    } else if (batchRecord.dataModelId === '5fc7df2a07ceae4f317ffcf6') {
        // PVA Batch
        processPVABatch(batchRecord, batchRawAmounts)
    } else if (batchRecord.dataModelId === '5fc7e1e907ceae4f317ffd00') {
        // Dispersion Batch
        processDispersionBatch(batchRecord, batchRawAmounts)
    } else if (batchRecord.dataModelId === '5fc7e66407ceae4f317ffd22') {
        // Powder Batch
        processPowderBatch(batchRecord, batchRawAmounts)
    } else if (batchRecord.dataModelId === '5fc7ef7c07ceae4f317ffd37') {
        // Collar batch
        processCollarBatch(batchRecord, batchRawAmounts)
    } else {
        return alert(
            'Unknown batch provided to raw material de-incrementing engine.'
        )
    }
}

const fetchBatchRawAmounts = async () => {
    const {result} = await API.post(
        'data-models/5fd8cec685b11877b6c4eba5/paginate',
        {
            filter: [],
            sort: {},
            limit: 30,
            next: null,
            previous: null,
            timezone: localStorage['timezone'],
        },
        2
    )

    return result.results
}

const fetchRawMaterialLots = async (lotNumbers) => {
    const {result} = await API.post(
        'data-models/5fc554e6cca7411395293a68/paginate',
        {
            filter: [
                {
                    type: 'Text',
                    logic: 'is',
                    value: lotNumbers,
                    path: 'Raw Lot Number',
                },
            ],
            sort: {},
            limit: 20,
            next: null,
            previous: null,
            timezone: localStorage['timezone'],
        },
        2
    )

    return result.results
}
