import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Button,
} from 'reactstrap'
import {Colxx, Separator} from 'components/CustomBootstrap'

export default class EditorModal extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        toggle: PropTypes.func.isRequired,
        attributes: PropTypes.func.isRequired,
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                columnSize: PropTypes.number.isRequired,
                onClickFn: PropTypes.func.isRequired,
                actionName: PropTypes.string.isRequired,
                buttonColor: PropTypes.string.isRequired,
            })
        ).isRequired,
        messages: PropTypes.array,
        editingElement: PropTypes.object,
    }

    static defaultProps = {
        actions: [],
        messages: [],
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.isOpen &&
            this.props.editingElement === undefined &&
            prevProps.editingElement !== null &&
            prevProps.editingElement !== undefined
        ) {
            this.props.toggle(false)
        }
    }

    render() {
        const {
            isOpen,
            toggle,
            title,
            attributes,
            actions,
            messages,
            editingElement,
        } = this.props
        let attributeArray = []
        if (editingElement) attributeArray = attributes(editingElement)

        return (
            <Modal isOpen={isOpen} toggle={toggle} wrapClassName="modal-right">
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    {attributeArray.map((a, i) => {
                        return (
                            <h4 key={i}>
                                {a[0]}: <strong>{a[1]}</strong>
                            </h4>
                        )
                    })}
                    <hr />
                    <Row>
                        {actions.map((a, i) => {
                            return (
                                <Colxx
                                    key={i}
                                    xs="12"
                                    sm={a.columnSize}
                                    className="mb-4"
                                    style={{textAlign: 'center'}}>
                                    <Button
                                        color={a.buttonColor}
                                        className="btn-block"
                                        onClick={() => {
                                            a.onClickFn(
                                                this.props.editingElement
                                            )
                                        }}>
                                        {a.actionName}
                                    </Button>
                                </Colxx>
                            )
                        })}
                    </Row>
                    <Row>
                        <Colxx xs="12" style={{textAlign: 'center'}}>
                            {messages.map((m, i) => {
                                return <p key={i}>{m}</p>
                            })}
                        </Colxx>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close Manager
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
