const MAX_LINKS = 10

export const TRANSFORMS = [
    {
        name: 'Add',
        id: '$add',
        description: 'Adds the values of all current inputs',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Add Attribute',
        id: '$addAttribute',
        description: 'Adds time based attributes',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Advance Time',
        id: '$advanceTime',
        description:
            'Subracts a given number of milliseconds to the input value timestamp',
        params: [
            {
                type: 'number',
                name: 'Interval in milliseconds to subtract',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'And',
        id: '$and',
        description:
            'Performs the logical AND operator to a set of inputs across a stream',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Count',
        id: '$count',
        description: 'Emits one output for every n times an input occurs',
        params: [
            {
                type: 'number',
                name: 'The number of items to count before emitting an output',
                default: 1,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Delay Time',
        id: '$delayTime',
        description:
            'Adds a given number of milliseconds to the input value timestamp',
        params: [
            {
                type: 'number',
                name: 'Interval in milliseconds to add',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Falling Edge',
        id: '$fallingEdge',
        description:
            'Triggers an output when the input goes from HIGH to LOW. The output will alternate between HIGH and LOW',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Filter',
        id: '$filter',
        description: 'Filters the input based on a provided function',
        params: [
            {
                type: 'textarea',
                name: 'The function used to perform the filtering. This should return true or false',
                default: 'function(v) { return v; }',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Hold Time',
        id: '$holdTime',
        description:
            'Holds the output at the value of the last input until at least a given number of milliseconds have passed (similar to debouncing)',
        params: [
            {
                type: 'number',
                name: 'Interval in milliseconds to hold',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Merge',
        id: '$merge',
        description: 'Merges all inputs into one output across a stream',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Moving Average',
        id: '$movingAverage',
        description: 'Computes an n point moving average according the formula',
        params: [
            {
                type: 'number',
                name: 'The number of points to compute the moving average',
                default: 10,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Multiply',
        id: '$multiply',
        description: 'Multiplies the values of all current inputs',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Negate',
        id: '$negate',
        description: 'Negates the current input (i.e. multplies by -1)',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Not',
        id: '$not',
        description:
            'Performs the logical NOT operator to one input across a stream',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Or',
        id: '$or',
        description:
            'Performs the logical OR operator to a set of inputs across a stream',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Rising Edge',
        id: '$risingEdge',
        description:
            'Triggers an output when the input goes from LOW to HIGH. The output will alternate between HIGH and LOW',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Round Time',
        id: '$roundTime',
        description:
            'Rounds the input timestamp based on a minimum value and unit. Example: a = 5, b = "seconds", the input timestamp will be rounded to the nearest multiple of 5 seconds. Useful to perform before the UniformTime transform if multiple inputs are being manipulated (so they all have the same timestamps).',
        params: [
            {
                type: 'number',
                name: 'The minimum value to round',
                default: 1,
            },
            {
                type: 'text',
                name: 'The time unit (hours, minutes, seconds, milliseconds)',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Threshold',
        id: '$threshold',
        description:
            'Performs a thresholding of the input. Example: a = [5, 10] the output will be 0, 1, or 2 representing input <= 5,5 < input <= 10, and input > 10',
        params: [
            {
                type: 'number',
                name: 'Minimum value',
            },
            {
                type: 'number',
                name: 'Maximum value',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Truthy',
        id: '$truthy',
        description:
            'Manipulates the input value based on a provided function. Can be used to truthify strings or visa versa. Can perform arbitrary manipulation of the input value',
        params: [
            {
                type: 'textarea',
                name: 'Function to call to manipulate the input value. Accepts one argument: the value of the current input. Should return the changed value',
                default: 'function(v) { return v; }',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Uniform Time',
        id: '$uniformTime',
        description:
            'Produces a uniform time series from non-uniform time series data. Outputs are produced at regular intervals every "a" milliseconds after the first input',
        params: [
            {
                type: 'number',
                name: 'Interval in milliseconds between output values',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Xor',
        id: '$xor',
        description:
            'Performs the logical XOR operator to a set of inputs across a stream',
        params: false,
        links: [2, MAX_LINKS],
    },
    {
        name: 'Timeout',
        id: '$timeout',
        description:
            'Emits an output with value of TRUE if it does not receive another input within the chosen interval. Does not start timing until it receives the first input.',
        params: [
            {
                type: 'number',
                name: 'The interval in milliseconds',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Duration',
        id: '$duration',
        description:
            'Calculates the duration in milliseconds a non-zero input stays constant',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Duration By Interval',
        id: '$durationByInterval',
        description:
            'Counts the duration of non-zero inputs within a configurable interval in milliseconds',
        params: [
            {
                type: 'number',
                name: 'The interval in milliseconds',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Count By Interval',
        id: '$countByInterval',
        description:
            'Counts the non-zero inputs within a configurable interval in milliseconds',
        params: [
            {
                type: 'number',
                name: 'The interval in milliseconds',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Map',
        id: '$map',
        description:
            'Modifies the input based on a provided function. Note this is different from Truthy as the function gets passed the input object, not just the value.',
        params: [
            {
                type: 'textarea',
                name: 'Function to call to modify the input object. Accepts one argument: the current input. Should return the modified object.',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Add Constant',
        id: '$addConstant',
        description: 'Adds a constant value to the input.',
        params: [
            {
                type: 'number',
                name: 'The constant value to add',
                default: 1,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Multiply Constant',
        id: '$multiplyConstant',
        description: 'Multiplies the input by a constant value.',
        params: [
            {
                type: 'number',
                name: 'The constant value to multiply',
                default: 1,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Merge Duration',
        id: '$mergeDuration',
        description:
            'Merges duration items with gaps less than the specified value in milliseconds',
        params: [
            {
                type: 'number',
                name: 'he maximum time of the gap in milliseconds between two consecutive durations to merge',
                default: 1000,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Front Moving Average',
        id: '$frontMovingAverage',
        description:
            'Computes an n point moving average according the formula: y(n) = 1/N * SUM from n = n to n-N of x(n)',
        params: [
            {
                type: 'number',
                name: 'The number of points to compute the moving average.',
                default: 1,
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Front Rolling Average',
        id: '$frontRollingAverage',
        description:
            'Computes a rolling average keeping up to a maximum of "a" values. The current average will be computed even if there are not "a" values yet.',
        params: [
            {
                type: 'number',
                name: 'The number of points to compute the moving average.',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Filter Duplicate Values',
        id: '$filterDuplicateValues',
        description: 'Filters inputs with duplicate, consecutive values',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Filter Duplicate Timestamps',
        id: '$filterDuplicateTimestamps',
        description: 'Filters inputs with duplicate, consecutive timestamps',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Pass Through',
        id: '$passThrough',
        description:
            'Passes the input object to the output object without modification',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Absolute Value',
        id: '$absoluteValue',
        description: 'Takes the absolute value of the provided input',
        params: false,
        links: [1, 1],
    },
    {
        name: 'Time Series Cluster',
        id: '$timeSeriesCluster',
        description: 'custom...',
        params: [
            {
                type: 'number',
                name: 'Minimum Threshold',
            },
            {
                type: 'number',
                name: 'Sample Time',
            },
            {
                type: 'number',
                name: 'Filter',
            },
            {
                type: 'number',
                name: 'Max Distance',
            },
            {
                type: 'number',
                name: 'Min Slope',
            },
        ],
        links: [1, 1],
    },
    {
        name: 'Between',
        id: '$between',
        description:
            'Returns true between a start signal from input 1 and end signal from input 2',
        params: false,
        links: [2, 2],
    },
]
