import React, {Component, Fragment} from 'react'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class DataModelPicker extends Component {
    id = 'DataModelPicker'
    field = 'dataModelId'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : null

        this.state = {
            dataModels: [],
            selectedDataModel: initialValue,
        }

        if (this.props && this.props.cb) {
            this.props.cb('dataModelId', initialValue)
        }
    }

    getSelection() {
        return this.state.selectedDataModel.value
    }
    handleDataModelChange(e) {
        this.setState({
            selectedDataModel: e,
        })

        if (this.props.cb) {
            this.props.cb('dataModelId', e.value)
        }
    }
    async getDataModels() {
        let dataModels = await API.get('data-models', 2)
        dataModels = dataModels.filter((model) => !model.parentDataModelId)
        if (!dataModels) return alert('Could not fetch data models.')

        let selectedDataModel = null

        if (this.state.selectedDataModel) {
            selectedDataModel = {
                label: dataModels.find(
                    (a) => a._id === this.state.selectedDataModel
                )._id,
                value: this.state.selectedDataModel,
            }
        }

        this.setState({dataModels, selectedDataModel})
    }

    async componentWillMount() {
        await this.getDataModels()
    }

    render() {
        return (
            <Fragment>
                <label>Data Model:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="dataModel"
                    value={this.state.selectedDataModel}
                    onChange={this.handleDataModelChange}
                    options={this.state.dataModels.map((a, i) => {
                        return {label: a.name, value: a._id, key: i}
                    })}
                />
            </Fragment>
        )
    }
}
