import React, {Component, Fragment} from 'react'
import {Button, Row, Col} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddModal from './add'
import RemoveModal from './remove'
import EditModal from './edit'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Username',
        accessor: 'username',
    },
    {
        Header: 'Role',
        accessor: 'role',
    },
]

export default class UserManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'UserManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            users: [],
            allUsers: [],
            editingUser: null,
            addModal: false,
        }
    }
    async fetchUsers() {
        let users = await API.get('users', 2)
        users = users.filter((u) => u.username !== 'accumine')
        users = users.filter((u) => u.firstName && u.lastName)

        let allUsers = await API.get('users?all=true', 2)
        allUsers = allUsers.filter((u) => u.firstName && u.lastName)
        this.setState({users, allUsers})
    }
    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal,
        })
    }
    toggleModal(modalName, user) {
        let state = this.state
        state.editingUser = user
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('editModal', code),
                actionName: 'Edit',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('removeModal', code),
                actionName: 'Remove',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(user) {
        user.name = user.firstName + ' ' + user.lastName
        return user
    }

    editorModalAttributes(user) {
        return [
            ['Name', user.name],
            ['Username', user.username],
            ['Role', user.role],
        ]
    }

    async addNewUserToResources(user) {
        if (user) {
            const environments = await API.get('environments', 2)
            let env = environments.find(
                (e) => e._id === this.props.environmentId
            )
            if (env && env.resources && env.resources.users) {
                env.resources.users.push(user._id)
                await API.patch('environments/' + env._id, env, 2)
            }
        }
    }

    componentWillMount() {
        this.subscriber.add(this.fetchUsers, 3000, 'fetchUsers()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstEl = (
                <Button color="primary" onClick={this.toggleAddModal}>
                    Add Your First User
                </Button>
            ),
            tableExists = this.state.users.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col
                            xs="12"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Button
                                color="primary"
                                onClick={this.toggleAddModal}>
                                Add User
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Users Found"
                    emptyCrudSubText={addFirstEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.users}
                    editorModalTitle="User Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addModal ? (
                    <AddModal
                        toggleModal={this.toggleAddModal}
                        modal={this.state.addModal}
                        users={this.state.allUsers}
                        postSaveHook={this.addNewUserToResources}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        editingUser={this.state.editingUser}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        users={this.state.allUsers}
                        editingUser={this.state.editingUser}
                    />
                ) : null}
            </Fragment>
        )
    }
}
