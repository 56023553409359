import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormText,
    InputGroup,
    InputGroupAddon,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'

const dict = {
    open: 'Open Mesh',
    close: 'Close Mesh',
    start: 'Start Mesh',
    stop: 'Stop Mesh',
    reset: 'Reset Meth',
    pause: 'Pause Mesh',
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            alias: !this.props.editingNode ? '' : this.props.editingNode.alias,
            mac: !this.props.editingNode ? '' : this.props.editingNode.nodeId,
            aliasBorderColor: 'rgb(215,215,215)',
            macBorderColor: 'rgb(215,215,215)',
            valid: false,
            message: false,
            confirmDelete: false,
            loading: false,
            shellhubHardwareMac: '',
        }
    }

    validateMac(value) {
        let clean = value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase(),
            message = ''

        if (clean === '') {
            message = 'MAC Address is a required field.'
        } else if (
            this.props.nodes.find((node) => node.nodeId === clean) &&
            !this.props.editingNode
        ) {
            message = 'This MAC Address already exists.'
        }
        return message
    }

    validateAlias(value) {
        let message = ''
        if (value === '') {
            message = 'Nickname is a required field.'
        } else if (
            this.props.nodes.find(
                (node) => node.alias === value && this.state.mac !== node.nodeId
            )
        ) {
            message = 'This nickname already exists.'
        }
        return message
    }

    askToConfirmDelete() {
        this.setState({confirmDelete: true})
    }
    revertDelete() {
        this.setState({confirmDelete: false})
    }
    async delete() {
        this.setState({loading: true})
        try {
            let response = await API.remove(
                'nodes/' + this.props.editingNode.nodeId,
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error deleting this Gateway.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error deleting this Gateway.')
        }
    }

    revertChanges() {
        this.setState({
            alias: !this.props.editingNode ? '' : this.props.editingNode.alias,
            mac: !this.props.editingNode ? '' : this.props.editingNode.nodeId,
        })
    }

    async saveChanges() {
        this.setState({loading: true})
        try {
            let response = await API.patch(
                'nodes/' + this.state.mac,
                {
                    alias: this.state.alias,
                },
                2
            )

            if (response.success) {
                setTimeout(this.closeModalAfterAction, 1500)
            } else {
                this.setState({loading: false})
                return alert('There was an error saving your changes.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error saving your changes.')
        }
    }

    async add() {
        this.setState({loading: true})
        try {
            let response = await API.post(
                'nodes/' + this.state.mac + '/register',
                {
                    nodeType: 'GWY',
                    nodeComm: 'TCP',
                    alias: this.state.alias,
                },
                2
            )

            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                return alert(
                    'There was an error adding your Gateway. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error adding your gateway.')
        }
    }

    async handleAction(action) {
        this.setState({loading: true})
        try {
            let {settings} = this.props.editingNode
            settings.globalAction = action
            const response = await API.patch(
                'nodes/' + this.props.editingNode.nodeId,
                {
                    settings: settings,
                },
                2
            )
            this.setState({loading: false})
            if (!response.success) {
                return alert('There was an error triggering the action.')
            } else {
                const x = (
                    <Fragment>
                        <p style={{marginBottom: '0px'}}>
                            <strong>{dict[action]}</strong> has been triggered.
                        </p>
                    </Fragment>
                )
                this.setState({actionMessage: x})
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error triggering the action.')
        }
    }

    closeModalAfterAction() {
        this.setState({loading: false})
        this.props.toggleModal()
    }

    renderDeleteGatewayButton() {
        if (!this.state.confirmDelete && !this.changesPending()) {
            return (
                <Button color="danger" onClick={this.askToConfirmDelete}>
                    Delete Gateway
                </Button>
            )
        } else {
            return null
        }
    }

    renderDeleteConfirmation() {
        if (this.state.confirmDelete) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Are you sure?</p>
                    <Button size="sm" color="danger" onClick={this.delete}>
                        Yes
                    </Button>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.revertDelete}>
                        No
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    renderSaveChangesConfirmation() {
        if (this.changesPending() && this.props.editingNode) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Save Changes?</p>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.saveChanges}
                        disabled={!this.state.valid}>
                        Save
                    </Button>
                    <Button
                        size="sm"
                        color="danger"
                        onClick={this.revertChanges}>
                        Discard
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    renderNewGateway() {
        if (!this.props.editingNode) {
            return (
                <Fragment>
                    <Button
                        color="primary"
                        onClick={this.add}
                        disabled={!this.state.valid}>
                        Add Gateway
                    </Button>
                </Fragment>
            )
        }
    }

    renderActionsMenu() {
        if (
            this.props.editingNode !== null &&
            this.props.nodes.find(
                (x) => x.nodeId === this.props.editingNode.nodeId
            ).settings.globalAction === ''
        ) {
            return (
                <Fragment>
                    <Row className="mb-4">
                        <Col>
                            <hr />
                            <div className="text-center">
                                <h4 className="text-center">Mesh Network</h4>
                                <small className="text-center text-muted">
                                    Triggering global mesh network actions
                                    affects all SensorBots associated with this
                                    gateway.
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('open')
                                }}>
                                Open Mesh
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('close')
                                }}>
                                Close Mesh
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('start')
                                }}>
                                Start Mesh
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('stop')
                                }}>
                                Stop Mesh
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('pause')
                                }}>
                                Pause Mesh
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('reset')
                                }}>
                                Reset Mesh
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )
        } else if (
            this.props.editingNode !== null &&
            this.props.nodes.find(
                (x) => x.nodeId === this.props.editingNode.nodeId
            ).settings.globalAction !== ''
        ) {
            const pendingAction = this.props.nodes.find(
                (x) => x.nodeId === this.props.editingNode.nodeId
            ).settings.globalAction
            return (
                <Fragment>
                    <Row className="mb-4">
                        <Col>
                            <hr />
                            <div className="text-center">
                                <h4 className="text-center">Mesh Network</h4>
                                <small className="text-center text-muted">
                                    Triggering global mesh network actions
                                    affects all SensorBots associated with this
                                    gateway.
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <p
                                style={{marginBottom: '5px'}}
                                className="text-muted">
                                Pending Action:
                            </p>
                            <h2>{dict[pendingAction]}</h2>
                            <Button
                                size="sm"
                                onClick={() => {
                                    this.handleAction('')
                                }}>
                                Cancel Action
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )
        }
    }

    changesPending() {
        let changesPending = true
        if (this.props.editingNode) {
            changesPending = !(
                this.props.editingNode.alias === this.state.alias &&
                this.props.editingNode.nodeId === this.state.mac
            )
        }
        return changesPending
    }

    async saveShellhubHardwareMac() {
        this.setState({loading: true})
        try {
            const response = await API.patch(
                'nodes/' + this.props.editingNode.nodeId,
                {
                    shellhubHardwareMac: this.state.shellhubHardwareMac
                        .replace(/[^a-zA-Z0-9 ]/g, '')
                        .toLowerCase(),
                },
                2
            )
            if (!response.success) {
                return alert('There was an error saving.')
            }
            this.props.sync()
        } catch (error) {
            console.log(error)
            return alert('There was an error saving.')
        }
        this.setState({loading: false})
    }

    componentWillMount() {}

    componentWillUnmount() {}

    render() {
        let {modal, toggleModal, assets} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggleModal}>
                        {this.state.alias}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state.aliasBorderColor,
                                            }}
                                            type="text"
                                            name="alias"
                                            value={this.state.alias}
                                            onChange={(alias) => {
                                                const message =
                                                        this.validateAlias(
                                                            alias.target.value
                                                        ),
                                                    color =
                                                        message === ''
                                                            ? 'rgb(215,215,215)'
                                                            : '#dc3545',
                                                    valid =
                                                        message === '' &&
                                                        this.validateMac(
                                                            this.state.mac
                                                        ) === ''
                                                this.setState({
                                                    alias: alias.target.value,
                                                    valid: valid,
                                                    aliasBorderColor: color,
                                                    aliasInputMessage: message,
                                                })
                                            }}
                                        />
                                        <IntlMessages id="Nickname" />
                                        <FormText color="muted">
                                            {this.state.aliasInputMessage}
                                        </FormText>
                                    </Label>
                                    <Label className="form-group has-top-label">
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state.macBorderColor,
                                            }}
                                            type="text"
                                            name="mac"
                                            value={this.state.mac}
                                            onChange={(mac) => {
                                                const message =
                                                        this.validateMac(
                                                            mac.target.value
                                                        ),
                                                    color =
                                                        message === ''
                                                            ? 'rgb(215,215,215)'
                                                            : '#dc3545',
                                                    valid =
                                                        message === '' &&
                                                        this.validateAlias(
                                                            this.state.alias
                                                        ) === ''
                                                this.setState({
                                                    mac: mac.target.value
                                                        .replace(
                                                            /[^a-zA-Z0-9 ]/g,
                                                            ''
                                                        )
                                                        .toLowerCase(),
                                                    valid: valid,
                                                    macBorderColor: color,
                                                    macInputMessage: message,
                                                })
                                            }}
                                            disabled={
                                                this.props.editingNode !== null
                                            }
                                        />
                                        <IntlMessages id="MAC Address" />
                                        <FormText color="muted">
                                            {this.state.macInputMessage}
                                        </FormText>
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        {/*
            {this.props.editingNode ?
              <Row className="mb-4">
                <Col>
                  <hr/>
                  <div className="text-center">
                  <h4 className="text-center">Remote Access</h4>
                  <Button block color="link" className="m-0 p-0" onClick={() => window.open('https://accumine.freshdesk.com/support/solutions/61000134238')}>Open Documentation</Button>
                  </div>
                </Col>
              </Row>
            : null }

            {this.props.editingNode && this.props.editingNode.shellhubHardwareMac ?
              <>
                <Row>
                  <Col>
                    <Select
                      placeholder="Select Connection Method"
                      components={{ Input: CustomSelectInput }}
                      className="react-select"
                      classNamePrefix="react-select"
                      onChange={selectedShellhubConnectionMethod => this.setState({ selectedShellhubConnectionMethod })}
                      options={[
                        {
                          label: 'Putty Client (easier)',
                          value: 'putty'
                        },
                        {
                          label: 'Command Line SSH (harder)',
                          value: 'commandLine'
                        }
                      ]}
                      value={this.state.selectedShellhubConnectionMethod}
                    />

                    {this.state.selectedShellhubConnectionMethod && this.state.selectedShellhubConnectionMethod.value === 'commandLine' ? 
                      <Button
                        className="mt-2"
                        block
                        color="success"
                        onClick={() => {
                          navigator.clipboard.writeText(`ssh -p 9822 enterprise@accumine.${this.props.editingNode.shellhubHardwareMac}@remote.accuminetech.com`);
                          alert('Copied to clipboard');
                        }}
                      >
                        Copy SSH ID to Clipboard
                      </Button>
                    : null }

                    {this.state.selectedShellhubConnectionMethod && this.state.selectedShellhubConnectionMethod.value === 'putty' ? 
                      <>
                        <p className="m-0 p-0 mt-2"><strong>Hostname:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          {`accumine.${this.props.editingNode.shellhubHardwareMac}@remote.accuminetech.com`}
                        </p>
                        <p className="m-0 p-0 mt-2"><strong>Port:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          9822
                        </p>
                        <p className="m-0 p-0 mt-2"><strong>Username:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          enterprise
                        </p>
                        <p className="m-0 p-0 mt-2"><strong>Password:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          Contact your Accumine Account Manager
                        </p>
                      </>
                    : null }
                    
                  </Col>
                </Row>
              </>
            : null}

            {this.props.editingNode && !this.props.editingNode.shellhubHardwareMac ?
              <>
                <Row>
                  <Col>
                    <Alert color="warning">
                      Please verify the hardware mac address of the device labelled on the underside of the Gateway.
                      <Row>
                        <Col className="text-center">
                          <p className="m-0 p-0 mt-2" style={{fontWeight: 'bold'}}>MAC ADDRESS:</p>
                          <p className="m-0 p-0" style={{fontWeight: 'bold'}}>XX-XX-XX-XX-XX-XX</p>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <Input
                        value={this.state.shellhubHardwareMac}
                        placeholder={'XX-XX-XX-XX-XX-XX'}
                        onChange={e => this.setState({ shellhubHardwareMac: e.target.value })}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="success" onClick={this.saveShellhubHardwareMac}>Save</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </>
            : null }
              </>
            : null}
            */}

                        {this.renderActionsMenu()}
                    </ModalBody>
                    <ModalFooter>
                        {this.renderDeleteGatewayButton()}
                        {this.renderDeleteConfirmation()}
                        {this.renderSaveChangesConfirmation()}
                        {this.renderNewGateway()}
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
