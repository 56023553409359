import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Textfit} from 'react-textfit'

import HourlyTargetActualChart from './HourlyTargetActualChart'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {fetchShiftTimerange} from 'SDK/api/common'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            live: {
                name: '...',
                timestamp: null,
                color: '',
                elapsed: '0:00',
            },
            actual: 0,
            target: 0,
            total: 0,
            partNumber: 'No Scheduled Production',
            left: 0,
            progress: 0,
            hoursRemaining: '',
            activeBreak: false,
        }
    }

    async fetchDeviceName() {
        let assets = await API.get('devices')
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                name: assets.find((a) => a.deviceId === this.props.deviceId)
                    .name,
            })
        }
    }

    async fetchLiveData() {
        let live = await API.post('live', {
            deviceId: this.props.deviceId,
        })
        if (!live) {
            return alert('There was an error fetching data')
        } else if (live[this.props.deviceId].activeStates.length) {
            if (live[this.props.deviceId].activeShiftBreaks.length) {
                this.setState({
                    activeBreak:
                        live[this.props.deviceId].activeShiftBreaks[0].name,
                })
            } else {
                this.setState({activeBreak: false})
            }
            let shifts = live[this.props.deviceId].activeShifts
            live[this.props.deviceId].activeStates = live[
                this.props.deviceId
            ].activeStates.filter(
                (state) =>
                    state.name === 'Downtime' || state.name === 'In-Cycle'
            )
            live = live[this.props.deviceId].activeStates[0]
            if (live.name === 'Downtime') {
                const downtimeReason = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Downtime Reason',
                            timeEnd: null,
                        },
                    },
                    2
                )

                if (downtimeReason.length) {
                    live.name = downtimeReason[0].value
                }
            }
            live.color = tileColor(live.name)
            live.elapsed = secondsToHHMMSS(
                moment().diff(moment(live.timestamp), 'seconds')
            )
            let timerange = [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().toISOString(),
            ]

            if (this.props.realtimeactiveshift) {
                if (shifts.length) {
                    timerange = await fetchShiftTimerange(
                        this.props.deviceId,
                        shifts[0]
                    )
                    timerange[0] = moment(timerange[0])
                        .startOf('minute')
                        .toISOString()
                    timerange[1] = moment().startOf('minute').toISOString()
                }
            }
            this.setState({live, timerange})
        }
    }

    updateElapsed() {
        if (this.state.live.timestamp === null) return
        let live = {...this.state.live}
        live.elapsed = secondsToHHMMSS(
            moment().diff(moment(live.timestamp), 'seconds')
        )
        this.setState({live})
    }

    async fetchActualThisHour() {
        if (!this.state.timerange) {
            let self = this
            return setTimeout(() => {
                self.fetchActualThisHour()
            }, 1000)
        }
        const minuteOffset = moment(this.state.timerange[0]).minute(),
            current = moment()

        let startOfHour = moment(current).startOf('hour')

        if (minuteOffset > 0) {
            if (current.minute() <= minuteOffset) {
                startOfHour = moment(current).add(-1, 'hours')
                startOfHour.set('minute', minuteOffset)
            } else {
                startOfHour = moment(current)
                startOfHour.set('minute', minuteOffset)
            }
        }
        startOfHour.startOf('minute')
        let {data} = await API.post('historical/aggregate2', {
            timeStart: startOfHour.toISOString(),
            timeEnd: moment().toISOString(),
            state: ['Part Count'],
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })

        let parts = data.devices[this.props.deviceId]['Part Count'][0]
        if (parts.constructor === Object) parts = parts.count

        let total = await API.post('historical/aggregate2', {
            timeStart: this.state.timerange[0],
            timeEnd: this.state.timerange[1],
            state: ['Part Count'],
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })

        let totalParts =
            total.data.devices[this.props.deviceId]['Part Count'][0]
        if (totalParts.constructor === Object) totalParts = totalParts.count

        this.setState({
            actual: parts,
            total: totalParts,
        })
    }

    async fetchTargetThisHour() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {$in: ['Hourly Target', 'Scheduled Target']},
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                target: data[0].metaData[0].value,
            })
        }
    }

    async fetchPartNumber() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Part Number',
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                partNumber: data[0].metaData[0].value,
            })
        }
    }

    async fetchProgress() {
        const target = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Target Parts',
                    timeEnd: null,
                },
            },
            2
        )
        if (target && target.length) {
            let {data} = await API.post('historical/aggregate2', {
                timeStart: moment(target[0].timeStart).toISOString(),
                timeEnd: moment().toISOString(),
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'total',
                logic: 'count',
            })

            let actual = data.devices[this.props.deviceId]['Part Count'][0]
            if (actual.constructor === Object) actual = actual.count

            let left = target[0].metaData[0].value - actual,
                progress = parseInt((actual / left) * 100),
                timeElapsedThisHour = moment().diff(moment().startOf('hour')),
                cycleTime = timeElapsedThisHour / this.state.actual,
                hoursRemaining = (left * cycleTime) / (60 * 60 * 1000)

            if (isNaN(hoursRemaining)) {
                hoursRemaining = ''
            } else {
                hoursRemaining =
                    '(~' + Math.round((hoursRemaining / 100) * 100) + ' hours)'
            }

            this.setState({
                left,
                progress,
                hoursRemaining,
            })
        }
    }

    componentWillMount() {
        this.fetchDeviceName()
        this.subscriber.add(this.fetchLiveData, 15 * 1000, 'fetchLiveData')
        this.subscriber.add(this.updateElapsed, 750, 'updateElapsed')
        this.subscriber.add(
            this.fetchActualThisHour,
            60 * 1000,
            'fetchActualThisHour'
        )
        this.subscriber.add(
            this.fetchTargetThisHour,
            60 * 1000,
            'fetchTargetThisHour'
        )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const activeState = this.state.live,
            height = document.documentElement.offsetHeight * 0.8

        return (
            <Col
                xs={this.props.multi ? 6 : 12}>
                <Card
                    style={{
                        color: '#fff',
                        backgroundColor: 'rgba(68, 70, 79, 0.5)',
                    }}>
                    <CardHeader
                        style={{cursor: 'pointer', margin: 0, padding: '5px'}}>
                        <Row>
                            <Col className="text-center">
                                <strong style={{
                                    fontSize: this.props.multi
                                        ? '600%'
                                        : '1000%',
                                    lineHeight: this.props.multi
                                            ? 1.5
                                            : 1.2,
                                }}>{this.state.name}</strong>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{padding: 0}}>
                        {this.state.activeBreak ? (
                            <div
                                style={{
                                    lineHeight:1,
                                    backgroundColor: activeState.color,
                                }}>
                                <Row>
                                    <Col className="text-center mt-2">
                                        <h1 style={{
                                            fontSize: this.props.multi
                                                ? '500%'
                                                : '750%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1.1,
                                            paddingBottom: 0,
                                        }}>
                                            {this.state.activeBreak}
                                        </h1>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: activeState.color,
                                    textAlign: 'center',
                                }}>
                                <Row>
                                    <Col xs="12">
                                        <Textfit mode="single" max={65}>
                                            {this.state.live.name}
                                        </Textfit>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <span style={{
                                            fontSize: this.props.multi
                                                ? '500%'
                                                : '900%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1.2,
                                            paddingBottom: 0,
                                        }}>{this.state.live.elapsed}</span>
                                    </Col>
                                </Row>
                            </div>
                        )}

                        <Row>
                            <Col>
                                <hr style={{margin: 0}} />
                            </Col>
                        </Row>

                        <div>
                            <Row>
                                <Col xs="4" className="text-center mt-2">
                                    <h1
                                        style={{
                                            fontSize: this.props.multi
                                                ? '350%'
                                                : '600%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1,
                                            paddingBottom: 0,
                                        }}>
                                        Hourly Actual
                                    </h1>
                                </Col>
                                <Col xs="4" className="text-center mt-2">
                                    <h1
                                        style={{
                                            fontSize: this.props.multi
                                                ? '350%'
                                                : '600%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1,
                                            paddingBottom: 0,
                                        }}>
                                        Hourly Target
                                    </h1>
                                </Col>
                                <Col xs="4" className="text-center mt-2">
                                    <h1
                                        style={{
                                            fontSize: this.props.multi
                                                ? '350%'
                                                : '600%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1,
                                            paddingBottom: 0,
                                        }}>
                                        Total
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="4" className="text-center mt-2">
                                    <h1
                                        style={{
                                            fontSize: this.props.multi
                                                ? '750%'
                                                : '1400%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1,
                                            paddingBottom: 0,
                                            marginBottom: 0,
                                        }}>
                                        <strong>{this.state.actual}</strong>
                                    </h1>
                                </Col>
                                <Col xs="4" className="text-center mt-2">
                                    <h1
                                        style={{
                                            fontSize: this.props.multi
                                                ? '750%'
                                                : '1400%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1,
                                            paddingBottom: 0,
                                            marginBottom: 0,
                                        }}>
                                        <strong>{this.state.target}</strong>
                                    </h1>
                                </Col>
                                <Col xs="4" className="text-center mt-2">
                                    <h1
                                        style={{
                                            fontSize: this.props.multi
                                                ? '750%'
                                                : '1400%',
                                            lineHeight: this.props.multi
                                                ? 1.5
                                                : 1,
                                            paddingBottom: 0,
                                            marginBottom: 0,
                                        }}>
                                        <strong>{this.state.total}</strong>
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col>
                                <hr style={{margin: 0}} />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                padding: 0,
                                margin: 0,
                                minHeight: height * 0.8 * 0.36,
                                backgroundColor: 'rgba(68, 70, 79, 0.5)',
                            }}>
                            <Col>
                                {this.state.timerange && (
                                    <HourlyTargetActualChart
                                        height={'100%'}
                                        timerange={this.state.timerange}
                                        deviceId={this.props.deviceId}
                                        target={this.state.target}
                                        color={this.state.live.color}
                                    />
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}
