import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class CategoryPicker extends Component {
    id = 'CategoryPicker'
    field = 'selectedCategories'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props && this.props.existing
                ? this.props.existing.map((state) => {
                      return {label: state, value: state}
                  })
                : []

        this.state = {
            widgets: [],
            selectedStates: initialValue,
            color: 'rgb(215,215,215)',
            reasoncodes: [],
        }

        if (this.props && this.props.cb) {
            this.props.cb('selectedCategories', initialValue)
        }
    }

    async componentWillMount() {
        await this.getCategories()
    }

    async getCategories() {
        try {
            const data = await API.get('reasoncodes', 2)
            const reasonCategories = [
                ...new Set(data.map((item) => item.category)),
            ]
            this.setState({
                categories: reasonCategories.map((category) => {
                    return {label: category, value: category}
                }),
            })
        } catch (error) {
            console.log('Could not load reason codes', error)
        }
    }

    render() {
        return (
            <Fragment>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <label>Available Reason Categories:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="reasonCodes"
                    isMulti
                    value={this.state.selectedReasonCodes}
                    onChange={(selectedReasonCategories) => {
                        this.setState({selectedReasonCategories})
                        if (this.props && this.props.cb) {
                            this.props.cb(
                                'selectedReasonCategories',
                                selectedReasonCategories.map(
                                    (category) => category.value
                                )
                            )
                        }
                    }}
                    options={this.state.reasonCategories}
                />
            </Fragment>
        )
    }
}
