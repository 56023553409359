import React, {Component, Fragment} from 'react'
import Switch from 'rc-switch'
import autobind from 'auto-bind'

export default class RealTimeClock extends Component {
    id = 'RealTimeClock'
    field = 'realtimeclock'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : false

        this.state = {
            value: initialValue,
        }

        if (this.props && this.props.cb) {
            this.props.cb('realtimeclock', initialValue)
        }
    }

    handleValueChange(value) {
        this.setState({value})

        if (this.props.cb) {
            this.props.cb('realtimeclock', value)
        }
    }

    render() {
        return (
            <Fragment>
                <label>Show clock in bottom right corner:</label>
                <Switch
                    className="custom-switch custom-switch-primary"
                    checked={this.state.value}
                    onChange={this.handleValueChange}
                />
            </Fragment>
        )
    }
}
