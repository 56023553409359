import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash, iteratePlaceholderName} from 'SDK/helpers'
import moment from 'moment'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const {editingPart} = this.props

        let description = editingPart.description || ''

        this.selectedAssets = editingPart.assets

        this.state = {
            nameInputBorderColor: 'rgb(215,215,215)',
            fromInputBorderColor: 'rgb(215,215,215)',
            toInputBorderColor: 'rgb(215,215,215)',
            breakTimeInputBorderColor: 'rgb(215,215,215)',
            name: editingPart.name,
            target: editingPart.target,
            valid: true,
            message: false,
            description: description,
            assets: [],
            selectedAssets: [],
        }
    }
    async getAssets() {
        const assets = await API.get('devices')
        this.setState({
            assets: assets.map((x) => {
                return {label: x.name, value: x.deviceId}
            }),
        })
    }
    async save() {
        const {editingPart} = this.props
        const part = {
            nodeId: this.props.deviceId,
            deviceId: this.props.deviceId,
            name: 'Part',
            timestamp: moment(),
            timeStart: moment(),
            timeEnd: moment(),
            value: this.state.name,
            metaData: [
                {
                    name: 'part',
                    value: this.state.name,
                },
                {
                    name: 'target',
                    value: this.state.target,
                },
            ],
        }

        let response = await API.patch('states/' + editingPart._id, part, 2)

        if (response) {
            this.props.toggleModal('editModal')
        } else {
            return alert(
                'There was an error editing your Part. Please try again.'
            )
        }
    }

    async componentDidMount() {
        await this.getAssets()

        let selectedAssets = []

        if (this.selectedAssets) {
            for (let i in this.selectedAssets) {
                const name = this.state.assets.find(
                    (a) => a.value === this.selectedAssets[i]
                ).label
                selectedAssets.push({
                    label: name,
                    value: this.selectedAssets[i],
                })
            }
        } else {
            selectedAssets = this.state.assets
        }

        this.setState({selectedAssets})
    }

    render() {
        const {modal, toggleModal, parts} = this.props

        return (
            <Fragment>
                <Modal
                    size="lg"
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('editModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('editModal')
                        }}>
                        Edit Part
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } /* else if (this.props.parts.find(part => part.name === v)) {
                      color = '#dc3545';
                      valid = false;
                      message = 'A Part with this name already exists.';
                    }*/
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            nameInputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Target:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="number"
                                    name="name"
                                    value={this.state.target}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } /* else if (this.props.parts.find(part => part.name === v)) {
                      color = '#dc3545';
                      valid = false;
                      message = 'A Part with this name already exists.';
                    }*/
                                        this.setState({
                                            target: v,
                                            valid: valid,
                                            nameInputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Description:</label>
                                <Input
                                    type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={(e) => {
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Assets:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="assets"
                                    isMulti
                                    value={this.state.selectedAssets}
                                    onChange={(selectedAssets) => {
                                        this.setState({selectedAssets})
                                    }}
                                    options={this.state.assets}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('editModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
