import io from 'socket.io-client'
import {logoutUser} from 'redux/actions'

const BASE_URL = window.location.host.includes('accuminetech.com')
    ? 'https://api.accuminetech.com/'
    : 'http://' + window.location.host.split(':')[0] + ':3000/'

const SocketClient = () => {
    let token = ''
    try {
        token = JSON.parse(localStorage['userObject']).token
    } catch (error) {
        throw Error(error)
    }

    const socket = io.connect(BASE_URL + '?token=' + token)

    socket.on('connect', () => {
        console.log('SocketClient: connected')
    })
    socket.on('error', (error) => {
        console.log('SockeClient: error: ' + error)
        if (error == 401) {
            localStorage.clear()
            window.location.reload()
        }
    })
    return socket
}

export {SocketClient}
