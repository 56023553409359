import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    Row,
    Col,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import * as API from 'SDK/api'
import NumberKeypad from './NumberKeypad'
import {GoogleSpreadsheet} from 'google-spreadsheet'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            workOrderNumber: '',
            partNumber: '',
            expectedParts: '',
            loading: false,
        }
    }

    async start() {
        this.setState({loading: true})
        try {
            const {_id} = this.props.dataModels.find(
                (d) => d.name === 'Aiken/Work Orders'
            )
            if (!_id) {
                throw Error('Cannot find Aiken/Work Orders data model')
            }
            const sheetsFileIDModel = this.props.dataModels.find(
                (d) => d.name === 'Aiken/File ID'
            )
            if (!sheetsFileIDModel) {
                throw Error('Cannot find Aiken/File ID data model')
            }

            const sheetsFileIDResponse = await API.post(
                `data-models/${sheetsFileIDModel._id}/paginate`,
                {
                    filter: [],
                },
                2
            )

            if (sheetsFileIDResponse.result.results.length === 0) {
                throw Error('Could not find Sheets File ID')
            }

            const doc = new GoogleSpreadsheet(
                sheetsFileIDResponse.result.results[0].File_ID
            )
            doc.useApiKey('AIzaSyCxcLx4KwA183ZbtCEuG_cL3e7pv5GWKNs')
            await doc.loadInfo() // loads document properties and worksheets
            const targetRatesSheet = doc.sheetsByTitle['Target Rates']
            const workCentersSheet = doc.sheetsByTitle['Machine WorkCenters']
            const targetRatesRows = await targetRatesSheet.getRows()
            const workCentersRows = await workCentersSheet.getRows()

            const workCenterRows = workCentersRows.filter(
                (r) => r['Asset'] === this.props.deviceName
            )

            const targetRateRow = targetRatesRows.find(
                (r) =>
                    r['PartNum:'].toLowerCase() ===
                        this.state.partNumber.toLowerCase() &&
                    workCenterRows
                        .map((w) => w['WorkCenter'])
                        .includes(r['WorkCenter:']) &&
                    workCenterRows
                        .map((w) => w['OperationCode'])
                        .includes(r['OperationCode:'])
            )

            if (!targetRateRow) {
                throw Error('Cannot find Part Number target.')
            }

            const startTime = moment().toISOString()

            await API.post(
                'states',
                {
                    nodeId: this.props.deviceId,
                    deviceId: this.props.deviceId,
                    name: 'Scheduled Target',
                    timestamp: startTime,
                    timeStart: startTime,
                    timeEnd: null,
                    value: Number(targetRateRow['PartsPerHr:']),
                    metaData: [
                        {
                            name: 'scheduledTarget',
                            value: Number(targetRateRow['PartsPerHr:']),
                        },
                    ],
                },
                2
            )

            await API.post(
                `data-models/${_id}/add-record`,
                {
                    Machine: this.props.deviceId,
                    Start_Time: startTime,
                    End_Time: 'active',
                    Work_Order_Number: this.state.workOrderNumber,
                    Part_Number: targetRateRow['PartNum:'],
                    Material_Description: targetRateRow['MaterialDesc:'],
                    MRP_Code: targetRateRow['MRP_Code:'],
                    Work_Center: targetRateRow['WorkCenter:'],
                    Operation_Code: targetRateRow['OperationCode:'],
                    Operation_Description: targetRateRow['OperationDesc:'],
                    Parts_Per_Cycle: Number(targetRateRow['PartsPerCycle:']),
                    Cycles_Per_Hour: Number(targetRateRow['CyclesPerHour:']),
                    Parts_Per_Hour: Number(targetRateRow['PartsPerHr:']),
                    Expected_Parts: Number(this.state.expectedParts),
                },
                2
            )
            this.props.forceRefresh()
            this.setState({
                workOrderNumber: '',
                partNumber: '',
                expectedParts: 0,
            })
            this.props.toggleModal()
        } catch (error) {
            console.log(error)
            alert(`Could not start work order: ${error}`)
            this.setState({loading: false})
        }
        this.setState({loading: false})
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={true} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>
                        {this.props.workOrder
                            ? 'FINISH WORK ORDER'
                            : 'START WORK ORDER'}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading ? (
                            <div className="loading" />
                        ) : null}
                        <Row>
                            <Col xs="12">
                                <Label>
                                    <strong>SCAN PART NUMBER:</strong>
                                </Label>
                                <Input
                                    type="text"
                                    disabled={this.props.workOrder}
                                    value={this.state.partNumber}
                                    onChange={(e) => {
                                        this.setState({
                                            partNumber: e.target.value,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="12">
                                <Label>
                                    <strong>SCAN WORK ORDER NUMBER:</strong>
                                </Label>
                                <Input
                                    type="text"
                                    disabled={this.props.workOrder}
                                    value={this.state.workOrderNumber}
                                    onChange={(e) => {
                                        this.setState({
                                            workOrderNumber: e.target.value,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="12">
                                <Label>
                                    <strong>ENTER EXPECTED PARTS:</strong>
                                </Label>
                                <NumberKeypad
                                    value={this.state.expectedParts}
                                    onChange={(expectedParts) =>
                                        this.setState({expectedParts})
                                    }
                                    showDecimal={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <hr />
                                <Button
                                    disabled={
                                        this.state.partNumber === '' ||
                                        this.state.workOrderNumber === '' ||
                                        !(String(this.state.expectedParts) > 0)
                                    }
                                    block
                                    onClick={this.start}>
                                    START WORK ORDER
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
