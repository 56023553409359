import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Input, Label, Button} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import * as API from 'SDK/api'

import Navigation from '../Navigation'
import {paginate} from '../Paginate'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Add New Lot/Add To Inventory',
            fn: () => {},
        })

        this.state = {
            rawMaterialOptions: [],
            selectedRawMaterial: null,

            lotOptions: [],
            selectedLot: null,

            lotAddQtyValue: 0,

            lotNumberValue: '',
        }
    }

    async fetchRawMaterials() {
        const records = await paginate('5fc530725d4bb3001bba8f43', [], {})

        this.setState({
            rawMaterialOptions: records.map((r) => {
                return {label: r['Value'], value: r}
            }),
        })
    }

    async fetchLots() {
        const records = await paginate(
            '5fc554e6cca7411395293a68',
            [
                {
                    type: 'Text',
                    logic: 'is',
                    value: [this.state.selectedRawMaterial.label],
                    path: 'Raw Chemical.Value',
                },
            ],
            {}
        )

        this.setState({
            lotOptions: records.map((r) => {
                return {label: r['Raw Lot Number'], value: r}
            }),
        })
    }

    onRawMaterialSelectionChange(selectedRawMaterial) {
        this.setState({selectedRawMaterial}, this.fetchLots)
    }

    onLotSelectionChange(selectedLot) {
        this.setState({selectedLot})
    }

    onLotQtyAdd(e) {
        const v = e.target.value
        if (v < 0) return
        if (this.state.lotAddQtyValue + v < 0) return

        this.setState({lotAddQtyValue: parseFloat(v)})
    }

    onLotNumberChange(e) {
        const v = e.target.value
        //if (v < 0) return;
        //if (this.state.lotOptions.find(lot => lot.label === v) !== undefined) {
        //	return alert('This lot number already exists.');
        //} else {
        this.setState({lotNumberValue: v})
        //}
    }

    async submit() {
        this.setState({loading: true})
        if (this.state.selectedLot.value === 'new') {
            // we are adding a new lot to the system

            // check if there's already a lot with the exact same lot #
            const {result} = await API.post(
                'data-models/5fc554e6cca7411395293a68/paginate',
                {
                    sort: {},
                    limit: 1,
                    next: null,
                    previous: null,
                    filter: [
                        {
                            type: 'Text',
                            logic: 'is',
                            value: [this.state.lotNumberValue],
                            path: 'Raw Lot Number',
                        },
                    ],
                    timezone: localStorage['timezone'],
                },
                2
            )

            if (result.results.length > 0) {
                alert('There is already a lot with the provided lot number.')
                this.setState({loading: false})
                return
            }

            const response = await API.post(
                'data-models/5fc554e6cca7411395293a68/add-record',
                {
                    'Raw Chemical': this.state.selectedRawMaterial.value._id,
                    'Raw Lot Number': this.state.lotNumberValue,
                    'Raw Released': false,
                    //'5fc666070283dc2398844985': '2020-12-01T16:00:00.627Z',
                    'Raw Quantity': this.state.lotAddQtyValue,
                    'Raw Comment': 'N/A',
                    Total_Quantity: this.state.lotAddQtyValue,
                },
                2
            )
            if (response) {
                // go back to previous page
                this.props.reset()
            }
        } else {
            // we are adding inventory to an existing lot in the system
            if (this.state.lotAddQtyValue <= 0)
                return alert('The quantity added must be greater than zero.')

            let record = this.state.selectedLot.value
            record['Raw Chemical'] = record['Raw Chemical']._id
            record['Total_Quantity'] =
                parseFloat(record['Raw Quantity']) + this.state.lotAddQtyValue
            record['Raw Quantity'] =
                parseFloat(record['Raw Quantity']) + this.state.lotAddQtyValue

            const response = await API.patch(
                'data-models/5fc554e6cca7411395293a68/edit-record',
                record,
                2
            )
            if (response) {
                // go back to previous page
                this.props.reset()
            }
        }
        this.setState({loading: false})
    }

    componentDidMount() {
        this.fetchRawMaterials()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Add New Lot/Add To Inventory</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{size: 4, offset: 4}}>
                        <Form>
                            <Label className="form-group has-top-label">
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="reason"
                                    value={this.state.selectedRawMaterial}
                                    onChange={this.onRawMaterialSelectionChange}
                                    options={this.state.rawMaterialOptions.sort(
                                        (a, b) => a.label.localeCompare(b.label)
                                    )}
                                />
                                <IntlMessages id="Select raw material" />
                            </Label>
                        </Form>
                    </Col>
                </Row>
                {this.state.selectedRawMaterial ? (
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Form>
                                <Label className="form-group has-top-label">
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="reason"
                                        value={this.state.selectedLot}
                                        onChange={this.onLotSelectionChange}
                                        options={[
                                            {
                                                label: 'ADD NEW LOT',
                                                value: 'new',
                                            },
                                        ].concat(
                                            this.state.lotOptions.sort((a, b) =>
                                                a.label.localeCompare(b.label)
                                            )
                                        )}
                                    />
                                    <IntlMessages id="Select lot" />
                                </Label>
                            </Form>
                        </Col>
                    </Row>
                ) : null}
                {this.state.selectedLot &&
                this.state.selectedLot.value === 'new' ? (
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Form>
                                <Label className="form-group has-top-label">
                                    <Input
                                        type="text"
                                        name="lot-number"
                                        value={this.state.lotNumberValue}
                                        onChange={this.onLotNumberChange}
                                    />
                                    <IntlMessages id="Lot Number" />
                                </Label>
                            </Form>
                        </Col>
                    </Row>
                ) : null}
                {this.state.selectedLot ? (
                    <>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Input
                                            type="number"
                                            name="add-qty"
                                            value={this.state.lotAddQtyValue}
                                            onChange={this.onLotQtyAdd}
                                        />
                                        <IntlMessages id="Add Quantity (kg)" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Button size="xl" block onClick={this.submit}>
                                    SUBMIT
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
