import React, {Component, Fragment} from 'react'
import {Row, Col, InputGroup, Input, InputGroupAddon, Button} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import {generateHash} from 'SDK/helpers'

import PublishTemplateModal from './PublishTemplateModal'

export default class TemplateManager extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let search = window.location.search
        let params = new URLSearchParams(search)

        this.environmentId = params.get('environmentId')
        this.sectionId = params.get('sectionId')
        this.viewId = params.get('viewId')
        this.visualId = params.get('visualId')

        this.addingNewVisualToView =
            this.environmentId &&
            this.sectionId &&
            this.viewId &&
            this.visualId &&
            this.visualId === 'new'
        this.editingVisualToView =
            this.environmentId &&
            this.sectionId &&
            this.viewId &&
            this.visualId &&
            this.visualId !== 'new'
        const existing = this.props.library.find(
            (x) => x._id === this.props.libraryObject._id
        )

        let name = 'New Visualization'
        if (existing) name = existing.name

        this.state = {
            new: this.props.libraryObject._id === undefined,
            color: 'rgb(215,215,215)',
            message: '',
            valid: true,
            name: this.editingVisualToView
                ? JSON.parse(localStorage[this.visualId]).name
                : 'New Visualization',
            showPublishTemplateModal: false,
        }
    }

    async save() {
        if (this.props.library.find((c) => c.name === this.state.name)) {
            this.setState({
                valid: false,
                color: '#dc3545',
                message: 'A visualization with the same name already exists!',
            })
            return
        } else {
            this.setState({
                valid: true,
                color: 'rgb(215,215,215)',
                message: '',
            })
        }

        let obj = {
            name: this.state.name,
            timerange: {
                live: this.props.libraryObject.live,
                timeStart: this.props.libraryObject.timeStart,
                timeEnd: this.props.libraryObject.timeEnd,
            },
            output: this.props.libraryObject.output,
            showBy: this.props.libraryObject.showBy,
            groupBy: this.props.libraryObject.groupBy,
            timeUnit: this.props.libraryObject.timeUnit,
            metric: this.props.libraryObject.metric,
            filter: this.props.libraryObject.filter,
            visualization: this.props.libraryObject.visualization,
        }
        if (this.props.libraryObject.lowerLimit) {
            obj.lowerLimit = this.props.libraryObject.lowerLimit
        }
        if (this.props.libraryObject.upperLimit) {
            obj.upperLimit = this.props.libraryObject.upperLimit
        }

        try {
            await API.post('library', obj, 2)
            this.props.toggle('2')
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error adding this visualization to the library'
            )
        }
    }

    async clone() {
        this.props.getComponentConfiguration()
        setTimeout(async () => {
            if (this.props.library.find((c) => c.name === this.state.name)) {
                this.setState({
                    valid: false,
                    color: '#dc3545',
                    message:
                        'A visualization with the same name already exists!',
                })
                return
            } else {
                this.setState({
                    valid: true,
                    color: 'rgb(215,215,215)',
                    message: '',
                })
            }
            let obj = {
                name: this.state.name,
                timerange: {
                    live: this.props.libraryObject.live,
                    timeStart: this.props.libraryObject.timeStart,
                    timeEnd: this.props.libraryObject.timeEnd,
                },
                output: this.props.libraryObject.output,
                showBy: this.props.libraryObject.showBy,
                groupBy: this.props.libraryObject.groupBy,
                timeUnit: this.props.libraryObject.timeUnit,
                metric: this.props.libraryObject.metric,
                filter: this.props.libraryObject.filter,
                visualization: this.props.libraryObject.visualization,
            }
            if (this.props.libraryObject.lowerLimit) {
                obj.lowerLimit = this.props.libraryObject.lowerLimit
            }
            if (this.props.libraryObject.upperLimit) {
                obj.upperLimit = this.props.libraryObject.upperLimit
            }

            try {
                await API.post('library', obj, 2)
                this.props.toggle('2')
            } catch (error) {
                console.log(error)
                return alert(
                    'There was an error adding this visualization to the library'
                )
            }
        }, 1000)
    }

    async update() {
        if (
            this.props.library.find(
                (c) =>
                    c.name === this.state.name &&
                    c._id !== this.props.libraryObject._id
            )
        ) {
            this.setState({
                valid: false,
                color: '#dc3545',
                message: 'A visualization with the same name already exists!',
            })
            return
        } else {
            this.setState({
                valid: true,
                color: 'rgb(215,215,215)',
                message: '',
            })
        }

        let obj = {
            name: this.state.name,
            timerange: {
                live: this.props.libraryObject.live,
                timeStart: this.props.libraryObject.timeStart,
                timeEnd: this.props.libraryObject.timeEnd,
            },
            output: this.props.libraryObject.output,
            showBy: this.props.libraryObject.showBy,
            groupBy: this.props.libraryObject.groupBy,
            timeUnit: this.props.libraryObject.timeUnit,
            metric: this.props.libraryObject.metric,
            filter: this.props.libraryObject.filter,
            visualization: this.props.libraryObject.visualization,
        }
        if (this.props.libraryObject.lowerLimit) {
            obj.lowerLimit = this.props.libraryObject.lowerLimit
        }
        if (this.props.libraryObject.upperLimit) {
            obj.upperLimit = this.props.libraryObject.upperLimit
        }

        try {
            await API.patch('library/' + this.props.libraryObject._id, obj, 2)
        } catch (error) {
            console.log(error)
            return alert('There was an error saving your changes')
        }
    }

    addToDashboard() {
        let obj = {
            id: generateHash(),
            name: this.state.name,
            timerange: {
                live: this.props.libraryObject.live,
                timeStart: this.props.libraryObject.timeStart,
                timeEnd: this.props.libraryObject.timeEnd,
            },
            output: this.props.libraryObject.output,
            showBy: this.props.libraryObject.showBy,
            groupBy: this.props.libraryObject.groupBy,
            timeUnit: this.props.libraryObject.timeUnit,
            metric: this.props.libraryObject.metric,
            filter: this.props.libraryObject.filter,
            visualization: this.props.libraryObject.visualization,
        }
        if (this.props.libraryObject.lowerLimit) {
            obj.lowerLimit = this.props.libraryObject.lowerLimit
        }
        if (this.props.libraryObject.upperLimit) {
            obj.upperLimit = this.props.libraryObject.upperLimit
        }

        localStorage['visual'] = JSON.stringify(obj)
        this.props.history.push(
            '/app/env/' +
                this.environmentId +
                '/' +
                this.sectionId +
                '/' +
                this.viewId +
                '/edit'
        )
    }

    editToDashboard() {
        let obj = {
            id: this.visualId,
            name: this.state.name,
            timerange: {
                live: this.props.libraryObject.live,
                timeStart: this.props.libraryObject.timeStart,
                timeEnd: this.props.libraryObject.timeEnd,
            },
            output: this.props.libraryObject.output,
            showBy: this.props.libraryObject.showBy,
            groupBy: this.props.libraryObject.groupBy,
            timeUnit: this.props.libraryObject.timeUnit,
            metric: this.props.libraryObject.metric,
            filter: this.props.libraryObject.filter,
            visualization: this.props.libraryObject.visualization,
        }
        if (this.props.libraryObject.lowerLimit) {
            obj.lowerLimit = this.props.libraryObject.lowerLimit
        }
        if (this.props.libraryObject.upperLimit) {
            obj.upperLimit = this.props.libraryObject.upperLimit
        }

        localStorage['visual'] = JSON.stringify(obj)
        this.props.history.push(
            '/app/env/' +
                this.environmentId +
                '/' +
                this.sectionId +
                '/' +
                this.viewId +
                '/edit'
        )
    }

    async remove() {
        try {
            await API.remove('library/' + this.props.libraryObject._id, 2)
            this.props.reset()
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error deleting this visualization from the library'
            )
        }
    }

    togglePublishTemplateModal() {
        if (this.state.showPublishTemplateModal) {
            this.setState({
                showPublishTemplateModal: false,
            })
        } else {
            let obj = {
                name: this.state.name,
                timerange: {
                    live: this.props.libraryObject.live,
                    timeStart: this.props.libraryObject.timeStart,
                    timeEnd: this.props.libraryObject.timeEnd,
                },
                output: this.props.libraryObject.output,
                showBy: this.props.libraryObject.showBy,
                groupBy: this.props.libraryObject.groupBy,
                timeUnit: this.props.libraryObject.timeUnit,
                metric: this.props.libraryObject.metric,
                filter: {},
                visualization: this.props.libraryObject.visualization,
            }
            if (this.props.libraryObject.lowerLimit) {
                obj.lowerLimit = this.props.libraryObject.lowerLimit
            }
            if (this.props.libraryObject.upperLimit) {
                obj.upperLimit = this.props.libraryObject.upperLimit
            }
            this.setState({
                showPublishTemplateModal: true,
                publishTemplateConfig: obj,
            })
        }
    }

    render() {
        return (
            <>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <InputGroup className="mb-3">
                    <Input
                        value={this.state.name}
                        style={{
                            borderColor: this.state.color,
                            borderTop: 'transparent',
                            borderRight: 'transparent',
                            borderLeft: 'transparent',
                            backgroundColor: 'transparent',
                        }}
                        onChange={(e) => {
                            this.setState({name: e.target.value})
                        }}
                    />
                    <InputGroupAddon addonType="append">
                        {this.addingNewVisualToView && (
                            <Button
                                outline
                                color="primary"
                                onClick={this.addToDashboard}>
                                Add To Dashboard
                            </Button>
                        )}
                        {this.editingVisualToView && (
                            <Button
                                outline
                                color="primary"
                                onClick={this.editToDashboard}>
                                Save Changes
                            </Button>
                        )}
                        <Button
                            outline="primary"
                            onClick={this.togglePublishTemplateModal}>
                            Publish
                        </Button>
                        {/*{this.state.new ?
            <Button outline color="primary" onClick={this.save}>Add To Library</Button>
          :
            <>
              <Button outline color="primary" onClick={this.clone}>Add As New</Button>
              <Button outline color="primary" onClick={this.update}>Update Existing</Button>
              <Button outline color="danger" onClick={this.remove}>Delete</Button>
            </>
        }*/}
                    </InputGroupAddon>
                </InputGroup>
                {this.state.showPublishTemplateModal ? (
                    <PublishTemplateModal
                        toggleModal={this.togglePublishTemplateModal}
                        modal={this.state.showPublishTemplateModal}
                        publishTemplateConfig={this.state.publishTemplateConfig}
                    />
                ) : null}
            </>
        )
    }
}
