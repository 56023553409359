import moment from 'moment'

import * as API from 'SDK/api'

export default class DataStore {
    constructor() {
        this.config = {
            timerange: null,
            state: null,
            durationBased: true,
        }

        this.devices = []
        this.shifts = []
        this.groups = []

        this.shiftResponse = []
        this.totalResponse = []
    }
    setConfig(timerange, state, durationBased = true) {
        this.config = {
            timerange: timerange,
            state: state,
            durationBased: durationBased,
        }
    }
    calculateDayBasedCapacity(shiftName) {
        const shift = this.shifts.find((x) => x.name === shiftName)
        let days = 0
        for (
            let m = moment(this.config.timerange.timeStart);
            m.isBefore(moment(this.config.timerange.timeEnd));
            m.add(1, 'days')
        ) {
            if (shift.days.find((x) => x === m.day()) !== undefined) days++
        }
        return days
    }
    async getShifts() {
        const shifts = await API.get('shifts')
        if (!shifts) throw Error('could not fetch shifts')

        this.shifts = shifts.filter(function ({name}) {
            return !this[name] && (this[name] = name)
        }, {})
    }
    async getDevices() {
        this.devices = await API.get('devices')
        if (!this.devices) throw Error('could not fetch devices')
    }
    getGroups() {
        this.groups = this.devices.map((device) => device.groups || []).flat()
        this.groups = [...new Set(this.groups)]
    }
    getGroupDevices(group) {
        return this.devices
            .filter(
                (device) =>
                    device.groups && device.groups.find((g) => g === group)
            )
            .map((device) => device.deviceId)
    }
    async makeDataset() {
        await this.getShifts()
        await this.getDevices()
        this.getGroups()

        await this.fetchShiftData()
        await this.fetchTotalData()
    }
    async fetchShiftData() {
        //console.log(this.config.state)
        let promises = [],
            self = this
        this.devices.forEach((device) => {
            self.shifts.forEach((shift) => {
                if (shift.assets.find((a) => a === device.deviceId)) {
                    promises.push(
                        API.post('historical', {
                            timeStart: moment(
                                self.config.timerange.timeStart
                            ).toISOString(),
                            timeEnd: moment(self.config.timerange.timeEnd)
                                .subtract(1, 'hours')
                                .toISOString(),
                            deviceId: device.deviceId,
                            state: self.config.state,
                            shift: shift.name,
                            unit: 'years',
                        })
                    )
                }
            })
        })
        let response = await Promise.all(promises)
        this.shiftResponse = response
    }
    async fetchTotalData() {
        const {data} = await API.post('historical/aggregate2', {
            timeStart: this.config.timerange.timeStart.toISOString(),
            timeEnd: this.config.timerange.timeEnd.toISOString(),
            deviceId: this.devices.map((d) => d.deviceId),
            state: [this.config.state],
            logic: this.config.durationBased ? 'duration' : 'count',
            groupByTimeUnit: 'total',
        })
        if (!data) throw Error('could not retrieve total data')
        this.totalResponse = data
    }
    totalPlantDuration() {
        let n = this.devices.length,
            capacity = moment(this.config.timerange.timeEnd).diff(
                moment(this.config.timerange.timeStart)
            ),
            totalMs = 0
        for (let device in this.totalResponse.devices) {
            totalMs += this.totalResponse.devices[device][this.config.state][0]
        }
        return totalMs / (n * capacity)
    }
    totalPlantCount() {
        let capacity = moment(this.config.timerange.timeEnd).diff(
                moment(this.config.timerange.timeStart),
                'days'
            ),
            totalCt = 0
        for (let device in this.totalResponse.devices) {
            totalCt +=
                this.totalResponse.devices[device][this.config.state][0]
                    .count ||
                this.totalResponse.devices[device][this.config.state][0]
        }
        if (capacity < 1) capacity = 1
        return totalCt / capacity
    }
    scheduledPlantDuration() {
        return (
            this.shiftResponse.reduce(
                (total, next) => total + next.durationPercentage[0],
                0
            ) / this.shiftResponse.length
        )
    }
    shiftPlantDuration(shift) {
        const filtered = this.shiftResponse.filter(
            (res) => res.query.shift === shift
        )
        return (
            filtered.reduce(
                (total, next) => total + next.durationPercentage[0],
                0
            ) / filtered.length
        )
    }
    shiftPlantCount(shift) {
        let filtered = this.shiftResponse.filter(
                (res) => res.query.shift === shift
            ),
            totalCt = filtered.reduce(
                (total, next) => total + next.count[0],
                0
            ),
            capacity =
                this.shiftResponse.find((x) => x.query.shift === shift)
                    .capacity[0] / 1440

        if (capacity < 1) capacity = 1

        return totalCt / capacity
    }
    totalGroupDuration(group) {
        let associatedDevices = this.getGroupDevices(group),
            n = associatedDevices.length,
            capacity = moment(this.config.timerange.timeEnd).diff(
                moment(this.config.timerange.timeStart)
            ),
            totalMs = 0
        for (let device in this.totalResponse.devices) {
            if (associatedDevices.find((d) => d === device)) {
                totalMs +=
                    this.totalResponse.devices[device][this.config.state][0]
            }
        }
        return totalMs / (n * capacity)
    }
    totalGroupCount(group) {
        let associatedDevices = this.getGroupDevices(group),
            capacity = moment(this.config.timerange.timeEnd).diff(
                moment(this.config.timerange.timeStart),
                'days'
            ),
            totalCt = 0
        for (let device in this.totalResponse.devices) {
            if (associatedDevices.find((d) => d === device)) {
                totalCt +=
                    this.totalResponse.devices[device][this.config.state][0]
                        .count ||
                    this.totalResponse.devices[device][this.config.state][0]
            }
        }
        if (capacity < 1) capacity = 1
        return totalCt / capacity
    }
    scheduledGroupDuration(group) {
        const associatedDevices = this.getGroupDevices(group),
            filtered = this.shiftResponse.filter((res) =>
                associatedDevices.find((d) => d === res.query.deviceId)
            )
        return (
            filtered.reduce(
                (total, next) => total + next.durationPercentage[0],
                0
            ) / filtered.length
        )
    }
    shiftGroupDuration(group, shift) {
        const associatedDevices = this.getGroupDevices(group),
            filtered = this.shiftResponse.filter(
                (res) =>
                    associatedDevices.find((d) => d === res.query.deviceId) &&
                    res.query.shift === shift
            )
        return (
            filtered.reduce(
                (total, next) => total + next.durationPercentage[0],
                0
            ) / filtered.length
        )
    }
    shiftGroupCount(group, shift) {
        let associatedDevices = this.getGroupDevices(group),
            filtered = this.shiftResponse.filter(
                (res) =>
                    res.query.shift === shift &&
                    associatedDevices.find((x) => x === res.query.deviceId)
            ),
            totalCt = filtered.reduce(
                (total, next) => total + next.count[0],
                0
            ),
            capacity =
                this.shiftResponse.find((x) => x.query.shift === shift)
                    .capacity[0] / 1440

        if (capacity < 1) capacity = 1
        else capacity = this.calculateDayBasedCapacity(shift)
        return totalCt / capacity
    }
    totalDeviceDuration(device) {
        const capacity = moment(this.config.timerange.timeEnd).diff(
            moment(this.config.timerange.timeStart)
        )

        return (
            this.totalResponse.devices[device][this.config.state][0] / capacity
        )
    }
    totalDeviceCount(device) {
        let capacity = moment(this.config.timerange.timeEnd).diff(
            moment(this.config.timerange.timeStart),
            'days'
        )
        if (capacity < 1) capacity = 1

        return (
            (this.totalResponse.devices[device][this.config.state][0].count ||
                this.totalResponse.devices[device][this.config.state][0]) /
            capacity
        )
    }
    scheduledDeviceDuration(device) {
        const filtered = this.shiftResponse.filter(
            (res) => res.query.deviceId === device
        )
        return (
            filtered.reduce(
                (total, next) => total + next.durationPercentage[0],
                0
            ) / filtered.length
        )
    }
    shiftDeviceDuration(device, shift) {
        const filtered = this.shiftResponse.filter(
            (res) => res.query.deviceId === device && res.query.shift === shift
        )
        return (
            filtered.reduce(
                (total, next) => total + next.durationPercentage[0],
                0
            ) / filtered.length
        )
    }
    shiftDeviceCount(device, shift) {
        let r = this.shiftResponse.find(
            (x) => x.query.shift === shift && x.query.deviceId === device
        )
        let capacity = r.capacity[0] / 1440
        if (capacity < 1) capacity = 1
        else capacity = this.calculateDayBasedCapacity(shift)
        return r.count[0] / capacity
    }
}
