import React, {useEffect, useState} from 'react'
import {Form, FormGroup, Row, Col, Button, Label, Collapse} from 'reactstrap'
import * as API from 'SDK/api'
import SchemaForm, {Widgets} from '@rjsf/bootstrap-4'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import {cloneDeep} from 'lodash'
import {v4 as uuidv4} from 'uuid'

import Link from '../../../../common/Form/Link'
import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'
import FormulaInput from '../../../../common/FormulaInput'

const makeUISchema = (schemaLayer, uiSchemaLayer, parentKey) => {
    const dataModelId = schemaLayer['dataModelId'].default
    for (let key in schemaLayer) {
        const fieldType = schemaLayer[key].fieldType
        delete schemaLayer[key].default
        schemaLayer[key].description = ' '

        schemaLayer[key].__fieldType__ = fieldType
        schemaLayer[key].__fieldPath__ =
            parentKey === '' ? key : parentKey + '.' + key

        if (key !== 'dataModelId') {
            schemaLayer[key].dataModelId = dataModelId
            if (fieldType === 'ForeignID') {
                /**
                 * foreignID can be either a string (_id) or embedded object when being
                 * sent to the data-modeller. here, we do not embed the full document so we
                 * are changing the type to string instead of anyof(string, object)
                 */
                delete schemaLayer[key].anyOf

                schemaLayer[key].type = 'string'

                uiSchemaLayer[key] = {
                    'ui:field': 'link',
                    name: schemaLayer[key].title,
                    description: schemaLayer[key].description,
                    foreignDataModelId: schemaLayer[key].foreignDataModelId,
                    filters: [],
                }
            } else if (fieldType === 'Code') {
                uiSchemaLayer[key] = {
                    'ui:field': 'code',
                    name: schemaLayer[key].title,
                    description: schemaLayer[key].description,
                }
            } else if (fieldType === 'SingleSubModel') {
                uiSchemaLayer[key] = {}
                makeUISchema(
                    schemaLayer[key].properties,
                    uiSchemaLayer[key],
                    parentKey === '' ? key : parentKey + '.' + key
                )
            } else if (fieldType === 'MultiSubModel') {
                uiSchemaLayer[key] = {items: {}}
                makeUISchema(
                    schemaLayer[key].items.properties,
                    uiSchemaLayer[key].items,
                    parentKey === '' ? key : parentKey + '.' + key
                )
            } else if (fieldType === 'DesignatedTimeEnd') {
                delete schemaLayer[key].anyOf
                schemaLayer[key].type = 'string'
                schemaLayer[key].format = 'date-time'
            }
        } else {
            uiSchemaLayer[key] = {
                'ui:widget': 'hidden',
            }
            schemaLayer[key].__fieldType__ = 'dataModelId'
        }
    }
}

const defaultWidgets = {
    boolean: 'CheckboxWidget',
    checkbox: 'CheckboxWidget',
    radio: 'RadioWidget',
    select: 'SelectWidget',
    hidden: 'HiddenWidget',
    string: 'TextWidget',
    text: 'TextWidget',
    password: 'PasswordWidget',
    email: 'EmailWidget',
    hostname: 'TextWidget',
    ipv4: 'TextWidget',
    ipv6: 'TextWidget',
    uri: 'URLWidget',
    'data-url': 'FileWidget',
    textarea: 'TextareaWidget',
    date: 'DateWidget',
    datetime: 'DateTimeWidget',
    'date-time': 'DateTimeWidget',
    'alt-date': 'AltDateWidget',
    'alt-datetime': 'AltDateTimeWidget',
    color: 'ColorWidget',
    file: 'FileWidget',
    number: 'TextWidget',
    updown: 'UpDownWidget',
    range: 'RangeWidget',
    integer: 'TextWidget',
    checkboxes: 'CheckboxesWidget',
    files: 'FileWidget',
}

export default (props) => {
    const [advancedIsOpen, toggleAdvanced] = useState(false)
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'CreateRecord',
        description: props.element.data.opts.description || '',
        dataModelId: props.element.data.opts.dataModelId || null,
        recordTemplate: props.element.data.opts.recordTemplate || null,
        requestURL:
            props.element.data.opts.requestURL ||
            'https://data-models.accuminetech.com/data-models',
        variableId: props.element.data.opts.variableId || null,
        submitted: props.element.data.opts.submitted || true,
    })
    const [validator, setValidator] = useState(null)
    const [uiSchema, setUISchema] = useState({})

    const CustomWidget = (_props) => {
        const fieldPath = _props.schema.__fieldPath__,
            fieldType = _props.schema.__fieldType__,
            DefaultWidget =
                Widgets[
                    defaultWidgets[_props.schema.format || _props.schema.type]
                ]

        const [value, setValue] = useState(
            cloneDeep(parameters.recordTemplate[fieldPath])
        )

        return (
            <>
                {fieldType === 'dataModelId' ? <></> : null}
                {fieldType !== 'dataModelId' ? (
                    <>
                        <Row>
                            <Col xs="8">
                                <Label>
                                    <strong>{_props.label}</strong>
                                </Label>
                            </Col>
                            <Col xs="4" className="text-right">
                                <Label>
                                    <Switch
                                        checked={
                                            value && value['@@isExpression@@']
                                        }
                                        onChange={() => {
                                            if (
                                                value &&
                                                value['@@isExpression@@']
                                            ) {
                                                // expression => static
                                                v = ''
                                                setValue('')
                                            } else {
                                                // static => expression
                                                setValue({
                                                    '@@isExpression@@': true,
                                                    text: '',
                                                    plainText: '',
                                                    mentions: [],
                                                    type: 'formula',
                                                    map: [],
                                                })
                                            }
                                            //parameters.recordTemplate[fieldPath] = v;
                                            //setParameters({...parameters});
                                        }}
                                    />{' '}
                                    Use Expression
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {value && value['@@isExpression@@'] ? (
                                    <>
                                        {fieldType === 'ForeignID' ? (
                                            <p className="text-muted">
                                                Calculated value must be the
                                                linked record "_id".
                                            </p>
                                        ) : null}
                                        {fieldType === 'DesignatedTimeEnd' ? (
                                            <p className="text-muted">
                                                If no end time, calculated value
                                                should be "active".
                                            </p>
                                        ) : null}
                                        <FormulaInput
                                            variables={props.variables.filter(
                                                (v) =>
                                                    !v.multi &&
                                                    v.transformId !==
                                                        props.element.id
                                            )}
                                            formulas={props.formulas}
                                            type="formula"
                                            onChange={(expression) => {
                                                let v = expression
                                                v.type = 'formula'
                                                v['@@isExpression@@'] = true
                                                setValue({...v})
                                                _props.onChange(v.plainText)
                                                parameters.recordTemplate[
                                                    fieldPath
                                                ] = v
                                                setParameters({...parameters})
                                            }}
                                            default={value}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {fieldType === 'ForeignID' ? (
                                            <Link
                                                {..._props}
                                                formContext={
                                                    _props.registry.formContext
                                                }
                                                name={_props.label}
                                                onChange={(v) => {
                                                    _props.onChange(v)
                                                    setValue({...v})

                                                    parameters.recordTemplate[
                                                        fieldPath
                                                    ] = v
                                                    setParameters({
                                                        ...parameters,
                                                    })
                                                }}
                                            />
                                        ) : (
                                            <DefaultWidget
                                                {..._props}
                                                value={value}
                                                onChange={(v) => {
                                                    _props.onChange(v)
                                                    setValue(v)
                                                    parameters.recordTemplate[
                                                        fieldPath
                                                    ] = v
                                                    setParameters({
                                                        ...parameters,
                                                    })
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </>
                ) : null}
            </>
        )
    }

    useEffect(async () => {
        if (props.element.data.opts.dataModelId) {
            let validator = await API.get(
                `data-models/${props.element.data.opts.dataModelId}/validator`,
                2
            )
            makeUISchema(validator.properties, uiSchema, '')
            setValidator(validator)
        }
    }, [])

    const submit = () => {
        delete parameters.recordTemplate._id
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Text',
            multi: false,
        }

        parameters.variableId = variable.id

        props.onChange(parameters, [])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                onChange={async (m) => {
                                    let validator = await API.get(
                                        `data-models/${m}/validator`,
                                        2
                                    )
                                    makeUISchema(
                                        validator.properties,
                                        uiSchema,
                                        ''
                                    )
                                    parameters.dataModelId = m
                                    parameters.recordTemplate = {}
                                    setValidator(validator)
                                    setParameters({...parameters})
                                }}
                                fieldName="Data Model"
                                default={
                                    parameters.dataModelId
                                        ? {
                                              label: props.dataModels.find(
                                                  (m) =>
                                                      m._id ===
                                                      parameters.dataModelId
                                              ).name,
                                              value: parameters.dataModelId,
                                          }
                                        : null
                                }
                                options={props.dataModels.map((m) => {
                                    return {
                                        label: m.name,
                                        value: m._id,
                                    }
                                })}
                            />
                        </FormGroup>

                        {validator ? (
                            <SchemaForm
                                schema={validator}
                                uiSchema={uiSchema}
                                formContext={{
                                    dataModels: props.dataModels,
                                    schema: validator,
                                }}
                                widgets={{
                                    AltDateTimeWidget: CustomWidget,
                                    AltDateWidget: CustomWidget,
                                    CheckboxesWidget: CustomWidget,
                                    CheckboxWidget: CustomWidget,
                                    ColorWidget: CustomWidget,
                                    DateTimeWidget: CustomWidget,
                                    DateWidget: CustomWidget,
                                    EmailWidget: CustomWidget,
                                    FileWidget: CustomWidget,
                                    HiddenWidget: CustomWidget,
                                    PasswordWidget: CustomWidget,
                                    RadioWidget: CustomWidget,
                                    RangeWidget: CustomWidget,
                                    SelectWidget: CustomWidget,
                                    TextareaWidget: CustomWidget,
                                    TextWidget: CustomWidget,
                                    UpDownWidget: CustomWidget,
                                    URLWidget: CustomWidget,
                                }}>
                                <div></div>
                            </SchemaForm>
                        ) : null}

                        <hr />

                        <FormGroup>
                            <p
                                color="link"
                                style={{cursor: 'pointer'}}
                                onClick={() => toggleAdvanced(!advancedIsOpen)}>
                                {advancedIsOpen ? (
                                    <>
                                        <i className="simple-icon-arrow-down" />{' '}
                                        Hide advanced
                                    </>
                                ) : (
                                    <>
                                        <i className="simple-icon-arrow-right" />{' '}
                                        Show advanced
                                    </>
                                )}
                            </p>
                            <Collapse isOpen={advancedIsOpen}>
                                <FormGroup>
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.requestURL = v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Request URL"
                                        existing={[]}
                                        initial={parameters.requestURL}
                                        disabled={false}
                                    />
                                </FormGroup>
                            </Collapse>
                        </FormGroup>

                        <hr />

                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
