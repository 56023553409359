import React, {Component, Fragment} from 'react'
import {Button, Row, Col} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddModal from './add'
import RemoveModal from './remove'
import EditModal from './edit'
import BarcodeSheets from './BarcodeSheets'

const columns = [
    {
        Header: 'Category',
        accessor: 'category',
    },
    {
        Header: 'Rework Code',
        accessor: 'reason',
    },
    {
        Header: 'Assets',
        accessor: 'assetString',
    },
]

export default class ReworkCodeManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ReworkCodeManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            reasoncodes: [],
            devices: [],
            editingCode: null,
            addModal: false,
        }
    }
    async fetchReasonCodes() {
        let reasoncodes = await API.get('reworkcodes', 2)
        if (reasoncodes) this.setState({reasoncodes})
    }
    async fetchAssets() {
        let devices = await API.get('devices')
        if (devices) this.setState({devices})
    }
    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal,
        })
    }
    toggleModal(modalName, code) {
        let state = this.state
        state.editingCode = code
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('editModal', code),
                actionName: 'Edit',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('removeModal', code),
                actionName: 'Remove',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(code) {
        if (code.offsetCapacity === true) code.offsetCapacity = 'Yes'
        else code.offsetCapacity = 'No'

        if (code.assets) {
            code.assetString =
                code.assets.length +
                ' asset' +
                (code.assets.length > 1 ? 's' : '')
        } else {
            code.assetString = 'Assign rework code to set of assets'
        }

        return code
    }

    editorModalAttributes(code) {
        return [
            ['Rework Code', code.reason],
            ['Category', code.category],
        ]
    }

    componentWillMount() {
        this.subscriber.add(this.fetchReasonCodes, 3000, 'fetchReasonCodes()')
        this.subscriber.add(this.fetchAssets, 3000, 'fetchAssets()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstCodeEl = (
                <Button color="primary" onClick={this.toggleAddModal}>
                    Add Your First Rework Code
                </Button>
            ),
            tableExists = this.state.reasoncodes.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row className="mb-2">
                        <Col xs="12" className="text-right">
                            <Button
                                color="primary"
                                className="mr-2"
                                onClick={this.toggleAddModal}>
                                Add Rework Code
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Rework Codes Found"
                    emptyCrudSubText={addFirstCodeEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.reasoncodes}
                    editorModalTitle="Rework Code Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addModal ? (
                    <AddModal
                        toggleModal={this.toggleAddModal}
                        modal={this.state.addModal}
                        reasoncodes={this.state.reasoncodes}
                        devices={this.state.devices}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        reasoncodes={this.state.reasoncodes}
                        editingCode={this.state.editingCode}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        reasoncodes={this.state.reasoncodes}
                        editingCode={this.state.editingCode}
                        devices={this.state.devices}
                    />
                ) : null}
            </Fragment>
        )
    }
}
