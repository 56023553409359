import React, {Component, Fragment} from 'react'
import {Row} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import Tile from './tile'

export default class OEEInteractiveTimeline extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'flash',
            component: 'RealTimeTileFlash',
        },
        {
            prop: 'aggregation',
            component: 'RealTimeAggregation',
        },
        {
            prop: 'black',
            component: 'BlackText',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'OEEInteractiveTimeline'
    requiredOutputs = ['Part Count', 'In-Cycle', 'Downtime', 'Work Order']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        aggregation: PropTypes.number,
        flash: PropTypes.bool,
        black: PropTypes.bool,
    }
    static defaultProps = {
        black: false,
        aggregation: 1,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
        }
    }

    async componentWillMount() {
        let devices = await API.get('devices')
        devices = this.props.deviceIds
            ? devices.filter((device) =>
                  this.props.deviceIds.includes(device.deviceId)
              )
            : devices
        console.log(devices)
        this.setState({
            devices,
        })
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    {this.state.devices.length
                        ? this.state.devices.map((device, i) => {
                              return (
                                  <Tile
                                      key={i}
                                      deviceId={device.deviceId}
                                      device={device}
                                      name={device.name}
                                      aggregation={this.props.aggregation}
                                      flash={this.props.flash}
                                      black={this.props.black}
                                      threshold={
                                          device.downtimeThresholdSeconds
                                      }
                                      HMIEntryEnabled={true}
                                  />
                              )
                          })
                        : null}
                </Row>
            </Fragment>
        )
    }
}
