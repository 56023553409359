import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button, Table} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'

const getRandomColor = () => {
    let letters = '0123456789ABCDEF'.split(''),
        color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export default class MCSWorkOrderTable extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'MCSWorkOrderTable'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            loading: true,
            assets: [],
            data: [],
        }
    }

    async componentWillMount() {
        this.setState({assets: await API.get('devices')})

        let checkIns = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Employee Check-In',
                    timeEnd: {$ne: null},
                },
                options: {
                    sort: {timeStart: -1},
                    limit: 10,
                },
            },
            2
        )

        for (let i in checkIns) {
            checkIns[i].workcenter = this.state.assets.find(
                (a) => a.deviceId === checkIns[i].deviceId
            ).name
            checkIns[i].timerange =
                moment(checkIns[i].timeStart).format('lll') +
                ' - ' +
                moment(checkIns[i].timeEnd).format('lll')
            checkIns[i].item = checkIns[i].metaData.find(
                (a) => a.name === 'Item Number'
            ).value
            checkIns[i].expectedCycleTime =
                Math.ceil(
                    3600 /
                        checkIns[i].metaData.find(
                            (a) => a.name === 'Expected Hourly Run Rate'
                        ).value
                ) + ' secs'

            const containers = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: checkIns[i].deviceId,
                        name: 'Job',
                        timeStart: {
                            $gte: moment(checkIns[i].timeStart).toISOString(),
                        },
                        timeEnd: {
                            $lte: moment(checkIns[i].timeEnd).toISOString(),
                        },
                    },
                },
                2
            )

            let partCountType = checkIns[i].metaData.find(
                (a) => a.name === 'Run Type'
            ).value
            if (partCountType !== '' && partCountType !== 'NONE') {
                partCountType = partCountType + ' Part Count'
            } else {
                partCountType = 'Part Count'
            }

            checkIns[i].totalParts = 0

            const pieces = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: checkIns[i].deviceId,
                        name: partCountType,
                        timeStart: {
                            $gte: moment(checkIns[i].timeStart).toISOString(),
                        },
                        timeEnd: {
                            $lte: moment(checkIns[i].timeEnd).toISOString(),
                        },
                    },
                },
                2
            )
            checkIns[i].totalParts += pieces.reduce((a, b) => a + b.value, 0)

            checkIns[i].containers = containers.length

            const changeover = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: checkIns[i].deviceId,
                        name: 'Setup',
                        timeEnd: {
                            $gte: moment(checkIns[i].timeStart).toISOString(),
                            $lte: moment(checkIns[i].timeEnd).toISOString(),
                        },
                    },
                },
                2
            )
            if (changeover.length) {
                checkIns[i].changeoverTime = moment
                    .duration(
                        moment(changeover[0].timeEnd).diff(
                            moment(changeover[0].timeStart)
                        )
                    )
                    .humanize()
            }
        }

        this.setState({data: checkIns, loading: false})
    }

    render() {
        return (
            <Fragment>
                {this.state.loading ? <div className="loading" /> : null}
                <Row>
                    <Col>
                        <h3 className="text-center">
                            Last 10 employee check-ins
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Workcenter</th>
                                    <th>Timerange</th>
                                    <th>Item #</th>
                                    <th>Containers</th>
                                    <th>Total Pieces</th>
                                    <th>Changeover Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((a) => {
                                    return (
                                        <tr key={Math.random()}>
                                            <td>{a.workcenter}</td>
                                            <td>{a.timerange}</td>
                                            <td>{a.item}</td>
                                            <td>{a.containers}</td>
                                            <td>{a.totalParts}</td>
                                            <td>{a.changeoverTime}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
