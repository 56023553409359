import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import autobind from 'auto-bind'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            value: this.props.editingDataModel.active,
        }
    }

    allowArchive() {
        const {_id} = this.props.editingDataModel
        return this.props.dataModels
            .filter((datamodel) => datamodel._id !== _id && !datamodel.archived)
            .every((datamodel) =>
                datamodel.fields.every(
                    (field) => field.foreignDataModelId !== _id
                )
            )
    }

    render() {
        const allowArchive = this.allowArchive()
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-0">Archive</h1>
                        <h4 className="text-muted">
                            Click below to archive this data model.
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.editingDataModel.archived ? (
                            <p className="text-muted">
                                {this.props.editingDataModel.name} is archived
                            </p>
                        ) : (
                            <>
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        this.props.handleArchiveDataModel()
                                    }}
                                    disabled={!allowArchive}>
                                    Archive {this.props.editingDataModel.name}
                                </Button>
                                {!allowArchive ? (
                                    <p className="text-muted">
                                        Unlink{' '}
                                        {this.props.editingDataModel.name} from
                                        other data models before archiving
                                    </p>
                                ) : null}
                            </>
                        )}
                        <hr />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
