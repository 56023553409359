import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'

// Accumine SDK imports
import * as API from 'SDK/api'

const TIMERANGE_DATA = [
    {label: 'Rolling', value: 'rolling'},
    {label: 'Fixed', value: 'fixed'},
]

const ROLLING_DATA = [
    {label: 'Last hour', value: 1},
    {label: 'Last 2 hours', value: 2},
    {label: 'Last 3 hours', value: 3},
    {label: 'Last 4 hours', value: 4},
    {label: 'Last 5 hours', value: 5},
    {label: 'Last 6 hours', value: 6},
    {label: 'Last 7 hours', value: 7},
    {label: 'Last 8 hours', value: 8},
    {label: 'Last 9 hours', value: 9},
    {label: 'Last 10 hours', value: 10},
    {label: 'Last 11 hours', value: 11},
    {label: 'Last 12 hours', value: 12},
    {label: 'Last 13 hours', value: 13},
    {label: 'Last 14 hours', value: 14},
    {label: 'Last 15 hours', value: 15},
    {label: 'Last 16 hours', value: 16},
    {label: 'Last 17 hours', value: 17},
    {label: 'Last 18 hours', value: 18},
    {label: 'Last 19 hours', value: 19},
    {label: 'Last 20 hours', value: 20},
    {label: 'Last 21 hours', value: 21},
    {label: 'Last 22 hours', value: 22},
    {label: 'Last 23 hours', value: 23},
    {label: 'Last 24 hours', value: 24},
]

const FIXED_DATA = [
    {label: '12 am', value: 0},
    {label: '1 am', value: 1},
    {label: '2 am', value: 2},
    {label: '3 am', value: 3},
    {label: '4 am', value: 4},
    {label: '5 am', value: 5},
    {label: '6 am', value: 6},
    {label: '7 am', value: 7},
    {label: '8 am', value: 8},
    {label: '9 am', value: 9},
    {label: '10 am', value: 10},
    {label: '11 am', value: 11},
    {label: '12 pm', value: 12},
    {label: '1 pm', value: 13},
    {label: '2 pm', value: 14},
    {label: '3 pm', value: 15},
    {label: '4 pm', value: 16},
    {label: '5 pm', value: 17},
    {label: '6 pm', value: 18},
    {label: '7 pm', value: 19},
    {label: '8 pm', value: 20},
    {label: '9 pm', value: 21},
    {label: '10 pm', value: 22},
    {label: '11 pm', value: 23},
]

const METRIC_OPTIONS = [
    {label: 'Parts Per Minute', value: 'minute'},
    {label: 'Parts Per Hour', value: 'hour'},
]

export default class DisplayConfiguration extends Component {
    deprecated = true
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'DisplayConfiguration'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            selectedOption: null,
            selectedTimerange: 'Rolling',
            selectedRolling: 24,
            selectedLiveAssets: [],
            selectedMetric: null,
            assets: [],
            updating: false,
        }
    }
    async getOptions() {
        const options = await API.get('options', 2),
            allDevices = await API.get('devices')

        if (!options || !allDevices) {
            return alert('Could not retrieve settings.')
        }

        let selectedLiveAssets = options.liveDevices
            ? options.liveDevices.map((d, i) => {
                  return {
                      label: allDevices.find((x) => x.deviceId === d).name,
                      value: d,
                      key: i,
                  }
              })
            : allDevices.map((d, i) => {
                  return {
                      label: d.name,
                      value: d.deviceId,
                      key: i,
                  }
              })

        this.setState({
            selectedTimerange: options.timeline.type.toLowerCase(),
            selectedRolling: options.timeline.rolling.length,
            selectedFixedStart: options.timeline.fixed.from.hour,
            selectedFixedEnd: options.timeline.fixed.to.hour,
            assets: allDevices,
            selectedLiveAssets: selectedLiveAssets,
            selectedMetric: options.partMetric,
        })
    }
    handleTimerangeChange(e) {
        this.setState({
            selectedTimerange: e.value,
        })
    }
    handleRollingChange(e) {
        this.setState({
            selectedRolling: e.value,
        })
    }
    handleFixedStartChange(e) {
        this.setState({
            selectedFixedStart: e.value,
        })
    }
    handleFixedEndChange(e) {
        this.setState({
            selectedFixedEnd: e.value,
        })
    }
    handleLiveAssetChange(e) {
        this.setState({
            selectedLiveAssets: e,
        })
    }
    handleMetricChange(e) {
        this.setState({
            selectedMetric: e.value,
        })
    }
    async submitChanges() {
        this.setState({updating: true})
        let timeline = {
            type: this.state.selectedTimerange,
            rolling: {
                length: this.state.selectedRolling,
            },
            fixed: {
                from: {
                    day: 'today',
                    hour: this.state.selectedFixedStart,
                },
                to: {
                    day: 'today',
                    hour: this.state.selectedFixedEnd,
                },
            },
        }
        let liveDevices = this.state.selectedLiveAssets.map((x) => x.value)

        const response = await API.post(
            'options',
            {
                liveDevices: liveDevices,
                timeline: timeline,
                partMetric: this.state.selectedMetric,
            },
            2
        )
        if (!response) alert('There was an error saving your changes')

        this.setState({updating: false})
    }

    async componentWillMount() {
        await this.getOptions()
    }

    render() {
        const selectedTimerange = this.state.selectedTimerange
        const selectedRolling = this.state.selectedRolling
        const selectedFixedStart = this.state.selectedFixedStart
        const selectedFixedEnd = this.state.selectedFixedEnd
        const selectedMetric = this.state.selectedMetric

        return (
            <Fragment>
                <Row className="mb-4">
                    <Col xxs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>Live View Settings</CardTitle>
                                <Row>
                                    <Col xs="12" md="6" className="mb-5">
                                        <label>Timerange:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="timerange"
                                            value={TIMERANGE_DATA.find(
                                                (option) =>
                                                    option.value ===
                                                    selectedTimerange
                                            )}
                                            onChange={
                                                this.handleTimerangeChange
                                            }
                                            options={TIMERANGE_DATA}
                                            isSearchable={false}
                                            isDisabled={this.state.updating}
                                        />
                                    </Col>
                                    {selectedTimerange === 'rolling' ? (
                                        <Col xs="12" md="6" className="mb-5">
                                            <label>How many hours?</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="rolling"
                                                value={ROLLING_DATA.find(
                                                    (option) =>
                                                        option.value ===
                                                        selectedRolling
                                                )}
                                                onChange={
                                                    this.handleRollingChange
                                                }
                                                options={ROLLING_DATA}
                                                isSearchable={false}
                                                isDisabled={this.state.updating}
                                            />
                                        </Col>
                                    ) : null}
                                    {selectedTimerange === 'fixed' ? (
                                        <Col xs="6" md="3" className="mb-5">
                                            <label>From:</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="fixed-start"
                                                value={FIXED_DATA.find(
                                                    (option) =>
                                                        option.value ===
                                                        selectedFixedStart
                                                )}
                                                onChange={
                                                    this.handleFixedStartChange
                                                }
                                                options={FIXED_DATA}
                                                isSearchable={false}
                                                isDisabled={this.state.updating}
                                            />
                                        </Col>
                                    ) : null}
                                    {selectedTimerange === 'fixed' ? (
                                        <Col xs="6" md="3" className="mb-5">
                                            <label>To:</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="fixed-end"
                                                value={FIXED_DATA.find(
                                                    (option) =>
                                                        option.value ===
                                                        selectedFixedEnd
                                                )}
                                                onChange={
                                                    this.handleFixedEndChange
                                                }
                                                options={FIXED_DATA}
                                                isSearchable={false}
                                                isDisabled={this.state.updating}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Assets to include:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="live-assets"
                                            value={
                                                this.state.selectedLiveAssets
                                            }
                                            onChange={
                                                this.handleLiveAssetChange
                                            }
                                            options={this.state.assets.map(
                                                (a, i) => {
                                                    return {
                                                        label: a.name,
                                                        value: a.deviceId,
                                                        key: i,
                                                    }
                                                }
                                            )}
                                            isDisabled={this.state.updating}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>
                                            Primary Production Metric:
                                        </label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="live-metric"
                                            value={METRIC_OPTIONS.find(
                                                (option) =>
                                                    option.value ===
                                                    selectedMetric
                                            )}
                                            onChange={this.handleMetricChange}
                                            options={METRIC_OPTIONS}
                                            isDisabled={this.state.updating}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs="12"
                                        className="mb-4"
                                        style={{textAlign: 'right'}}>
                                        <Button
                                            color="primary"
                                            onClick={this.submitChanges}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
