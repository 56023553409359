import React, {Component} from 'react'
import autobind from 'auto-bind'
import {logUserEvent} from 'SDK/api/common'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.disabled = navigator.appVersion.includes('Raspbian')
    }

    render() {
        return (
            <div className="position-relative d-inline-block">
                <div className="dropdown-menu-right dropdown show">
                    {!this.disabled ? (
                        <button
                            className="header-icon notificationButton btn btn-empty"
                            onClick={() => {
                                logUserEvent('KNOWLEDGE BASE')
                                var win = window.open(
                                    'https://support.leading2lean.com/hc/en-us/sections/7678219546765-Connect',
                                    '_blank'
                                )
                                win.focus()
                            }}>
                            <i className="simple-icon-graduation"></i>
                        </button>
                    ) : null}
                </div>
            </div>
        )
    }
}
