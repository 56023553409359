import * as API from 'SDK/api'
import moment from 'moment'

const fetchCycleTime = (start, end, relevantDevices) => {
    return {
        filter: [
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                path: 'timeStart',
            },
            {
                path: 'timeEnd',
            },
            {
                path: 'deviceId',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '628fc3dc5632f5001320ef88',
                        referenceId: '628fc452ac75c000138bd303',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '628fc3dc5632f5001320ef88',
                    type: 'mean',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchInCycle = (start, end, relevantDevices) => {
    return {
        filter: [
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76da96dbd30013d326f1',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                path: 'deviceId',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76da96dbd30013d326f1',
                        referenceId: '622a06d587fc6819387f5cf7',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76da96dbd30013d326f1',
                    type: 'sum',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchPartCount = (start, end, relevantDevices) => {
    return {
        filter: [
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76da97ca2600133c639f',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                dataModelId: '60bf76da97ca2600133c639f',
                path: 'deviceId',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76da97ca2600133c639f',
                        referenceId: '62278698be117900133ad90c',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76da97ca2600133c639f',
                    type: 'sum',
                    path: 'value',
                },
            },
        ],
    }
}

const fetchScrapQty = (start, end, relevantDevices) => {
    return {
        filter: [
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76dae2151c0013ff9c10',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                path: 'deviceId',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76dae2151c0013ff9c10',
                        referenceId: '622a06b687fc6819387f5cf6',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76dae2151c0013ff9c10',
                    type: 'sum',
                    path: 'value',
                },
            },
        ],
    }
}

const fetchNoDemandTime = (start, end, relevantDevices) => {
    return {
        filter: [
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
            {
                path: 'value',
                type: 'Text',
                logic: 'is',
                value: ['No Demand'],
            },
        ],
        groups: [
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76d9cc18090013e81573',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                dataModelId: '60bf76d9cc18090013e81573',
                path: 'deviceId',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76d9cc18090013e81573',
                        referenceId: '62a9ff0390b9630013a9a778',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76d9cc18090013e81573',
                    type: 'sum',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchWorkOrderData = (start, end, devices) => {
    return {
        filter: [
            {
                path: 'Machine.deviceId',
                type: 'Text',
                logic: 'is',
                value: devices.map((a) => a.value),
            },
        ],
        groups: [
            {
                path: 'Part_Number',
            },
            {
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                path: 'Machine.deviceId',
            },
            {
                path: 'Parts_Per_Hour',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [],
                aggregate: {
                    type: 'sum',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchData = async (devices, start, end) => {
    let results = []
    {
        let {data} = await API.post(
            'data-models/61e197a942c319001389da6f/aggregate',
            fetchWorkOrderData(start, end, devices),
            2
        )
        // console.log("Work Order Data", data);
        for (let row of data) {
            let [partNumber, timestamp, deviceId, partsPerHour] =
                row['@@key@@'].split('__')
            results.push({
                __key__: `${partNumber}__${timestamp}__${deviceId}`,
                partNumber,
                timestamp,
                deviceId,
                partsPerHour,
                allCycles: [],
                tableRow: {
                    Date: moment(timestamp).format('MM/DD/YYYY'),
                    Machine: '',
                    'Part Number': partNumber,
                    'Goal (pcs)': 0,
                    Actual: 0,
                    'Perf %': 0,
                    'Uptime Min': 0,
                    'Downtime Min': 0,
                    'Operating Min': row['@@duration@@'],
                    'Util %': 0,
                    'Actual Pcs': 0,
                    'Good Qty': 0,
                    'Scrap Qty': 0,
                    'Acc %': 0,
                    OEE: 0,
                    'Avg Cycle Secs': 0,
                },
            })
        }
    }

    if (results.length === 0) {
        return null
    }

    const relevantDevices = [...new Set(results.map((r) => r.deviceId))]

    {
        let {data} = await API.post(
            'data-models/60bf76d9cc18090013e81573/aggregate',
            fetchNoDemandTime(start, end, relevantDevices),
            2
        )
        //console.log("No Demand Time", data);
        for (let row of data) {
            const idx = results.findIndex((a) => a.__key__ === row['@@key@@'])
            if (idx >= 0) {
                results[idx].tableRow['Operating Min'] -= row['@@duration@@']
            } else {
                console.log('No Demand Time: Missing row - ' + row)
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76da97ca2600133c639f/aggregate',
            fetchPartCount(start, end, relevantDevices),
            2
        )
        //console.log("Part Count", data);
        for (let row of data) {
            const idx = results.findIndex((a) => a.__key__ === row['@@key@@'])
            if (idx >= 0) {
                results[idx].tableRow['Actual'] += row['value']
                results[idx].tableRow['Actual Pcs'] += row['value']
            } else {
                console.log('Part Count: Missing row - ' + row)
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76dae2151c0013ff9c10/aggregate',
            fetchScrapQty(start, end, relevantDevices),
            2
        )
        //console.log("Scrap", data);
        for (let row of data) {
            const idx = results.findIndex((a) => a.__key__ === row['@@key@@'])
            if (idx >= 0) {
                results[idx].tableRow['Scrap Qty'] += row['value']
            } else {
                console.log('Scrap: Missing row - ' + row)
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76da96dbd30013d326f1/aggregate',
            fetchInCycle(start, end, relevantDevices),
            2
        )
        //console.log("InCycle", data);
        for (let row of data) {
            const idx = results.findIndex((a) => a.__key__ === row['@@key@@'])
            if (idx >= 0) {
                results[idx].tableRow['Uptime Min'] += row['@@duration@@']
            } else {
                console.log('InCycle: Missing row - ' + row)
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76da97ca2600133c639f/aggregate',
            fetchCycleTime(start, end, relevantDevices),
            2
        )
        //console.log("Cycle Time", data);
        data = data.sort(
            (a, b) =>
                moment(a.timeStart).valueOf() - moment(b.timeStart).valueOf()
        )
        for (let row of data) {
            const idx = results.findIndex((a) => {
                return (
                    a.partNumber === row['Aiken/Work Orders.Part_Number'] &&
                    a.deviceId === row['deviceId'] &&
                    moment(row.timeStart).isBetween(
                        moment(a.timestamp).startOf('day'),
                        moment(a.timestamp).endOf('day')
                    )
                )
            })
            if (idx >= 0) {
                results[idx].allCycles.push(row)
            } else {
                console.log('Cycle Time: Missing row - ' + row)
            }
        }
        for (let i in results) {
            results[i].allCycles = results[i].allCycles.sort(
                (a, b) =>
                    moment(a.timeStart).valueOf() -
                    moment(b.timeStart).valueOf()
            )
            results[i].allCycles.pop()
            results[i].tableRow['Avg Cycle Secs'] =
                results[i].allCycles.reduce((a, b) => {
                    return (
                        a +
                        moment(b.timeEnd).diff(moment(b.timeStart), 'seconds')
                    )
                }, 0) / results[i].allCycles.length
            results[i].tableRow['Avg Cycle Secs'] = Math.ceil(
                results[i].tableRow['Avg Cycle Secs']
            )
        }
    }

    results = results.map((row) => {
        row.tableRow['Machine'] = devices.find(
            (a) => a.value === row.deviceId
        ).label

        if (!isFinite(row.tableRow['Avg Cycle Secs'])) {
            row.tableRow['Avg Cycle Secs'] = 0
        }

        if (row.tableRow['Uptime Min'] > row.tableRow['Operating Min']) {
            row.tableRow['Uptime Min'] = row.tableRow['Operating Min']
        }

        row.tableRow['Uptime Min'] = Math.round(row.tableRow['Uptime Min'])
        row.tableRow['Operating Min'] = Math.round(
            row.tableRow['Operating Min']
        )

        row.tableRow['Downtime Min'] =
            row.tableRow['Operating Min'] - row.tableRow['Uptime Min']

        row.tableRow['Util %'] =
            Math.round(
                (row.tableRow['Uptime Min'] / row.tableRow['Operating Min']) *
                    100
            ) || 0

        if (!isFinite(row.tableRow['Util %'])) {
            row.tableRow['Util %'] = 0
        }

        row.tableRow['Good Qty'] =
            row.tableRow['Actual'] - row.tableRow['Scrap Qty']

        row.tableRow['Acc %'] =
            Math.round(
                (row.tableRow['Good Qty'] / row.tableRow['Actual']) * 100
            ) || 0
        if (!isFinite(row.tableRow['Acc %'])) {
            row.tableRow['Acc %'] = 0
        }

        row.tableRow['Goal (pcs)'] =
            Math.round(
                row.partsPerHour * (row.tableRow['Operating Min'] / 60)
            ) || 0

        row.tableRow['Perf %'] =
            Math.round(
                (row.tableRow['Actual'] / row.tableRow['Goal (pcs)']) * 100
            ) || 0
        if (!isFinite(row.tableRow['Perf %'])) {
            row.tableRow['Perf %'] = 0
        }

        row.tableRow['OEE'] =
            (row.tableRow['Acc %'] / 100) *
            (row.tableRow['Perf %'] / 100) *
            (row.tableRow['Util %'] / 100)
        row.tableRow['OEE'] = Math.round(row.tableRow['OEE'] * 100) || 0
        if (!isFinite(row.tableRow['OEE'])) {
            row.tableRow['OEE'] = 0
        }

        return row
    })

    results.sort((a, b) => a.tableRow.Date.localeCompare(b.tableRow.Date))

    let totalPerformancePercentage =
            Math.round(
                (results.reduce((a, b) => a + b.tableRow['Actual'], 0) /
                    results.reduce((a, b) => a + b.tableRow['Goal (pcs)'], 0)) *
                    100
            ) || 0,
        totalUtilPercentage =
            Math.round(
                (results.reduce((a, b) => a + b.tableRow['Uptime Min'], 0) /
                    results.reduce(
                        (a, b) => a + b.tableRow['Operating Min'],
                        0
                    )) *
                    100
            ) || 0,
        totalAccPercentage =
            Math.round(
                (results.reduce((a, b) => a + b.tableRow['Good Qty'], 0) /
                    results.reduce((a, b) => a + b.tableRow['Actual'], 0)) *
                    100
            ) || 0

    if (!isFinite(totalPerformancePercentage)) {
        totalPerformancePercentage = 0
    }
    if (!isFinite(totalUtilPercentage)) {
        totalUtilPercentage = 0
    }
    if (!isFinite(totalAccPercentage)) {
        totalAccPercentage = 0
    }

    let totalRow = {
        isTotalRow: true,
        tableRow: {
            'Perf %': totalPerformancePercentage,
            'Util %': totalUtilPercentage,
            'Acc %': totalAccPercentage,
            OEE:
                Math.round(
                    (totalPerformancePercentage / 100) *
                        (totalUtilPercentage / 100) *
                        (totalAccPercentage / 100) *
                        100
                ) || 0,
        },
    }

    if (!isFinite(totalRow.tableRow['OEE'])) {
        totalRow.tableRow['OEE'] = 0
    }

    results.push(totalRow)

    return {
        dateRange:
            moment(start).format('MM/DD/YYYY') +
            ' - ' +
            moment(end).format('MM/DD/YYYY'),
        rows: results,
    }
}

export default fetchData
