import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Collapse, Button} from 'reactstrap'
import {Scrollbars} from 'react-custom-scrollbars'
import autobind from 'auto-bind'

import {TRANSFORMS} from './transforms'
import {OUTPUTS} from './outputs'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            accordion: [true, false, false, false],
        }
    }

    toggleAccordion(tab) {
        const prevState = this.state.accordion
        const state = prevState.map((x, index) => (tab === index ? !x : false))
        this.setState({
            accordion: state,
        })
    }

    render() {
        return (
            <Fragment>
                <Card style={{height: '100%'}}>
                    <CardBody>
                        <Scrollbars>
                            <Fragment>
                                <div className="border">
                                    <Button
                                        block
                                        color="link"
                                        className="text-left"
                                        onClick={() => this.toggleAccordion(0)}
                                        aria-expanded={this.state.accordion[0]}>
                                        Inputs (Selected Asset)
                                    </Button>
                                    <Collapse isOpen={this.state.accordion[0]}>
                                        <div className="p-4">
                                            {this.props.inputs.map(
                                                (input, i) => {
                                                    return (
                                                        <Row
                                                            style={{
                                                                marginRight:
                                                                    '5px',
                                                            }}
                                                            key={i}
                                                            draggable={true}
                                                            onDragStart={(
                                                                event
                                                            ) => {
                                                                event.dataTransfer.setData(
                                                                    'storm-diagram-node',
                                                                    JSON.stringify(
                                                                        {
                                                                            type: 'input',
                                                                            record: input,
                                                                        }
                                                                    )
                                                                )
                                                            }}>
                                                            <Col
                                                                xs="12"
                                                                style={{
                                                                    background:
                                                                        '#145388',
                                                                    borderRadius:
                                                                        '5px',
                                                                    padding:
                                                                        '5px',
                                                                    margin: '5px',
                                                                    color: '#fff',
                                                                }}>
                                                                <i className="iconsmind-Data" />{' '}
                                                                {input.name}
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="border">
                                    <Button
                                        block
                                        color="link"
                                        className="text-left"
                                        onClick={() => this.toggleAccordion(1)}
                                        aria-expanded={this.state.accordion[1]}>
                                        Inputs (Other Assets)
                                    </Button>
                                    <Collapse isOpen={this.state.accordion[1]}>
                                        <div className="p-4">
                                            {this.props.allOtherAssets.map(
                                                (asset) => {
                                                    let inputs = []
                                                    asset.availableInputs
                                                        .sort((a, b) =>
                                                            a.name > b.name
                                                                ? 1
                                                                : -1
                                                        )
                                                        .map((input, i) => {
                                                            inputs.push(
                                                                <Row
                                                                    style={{
                                                                        marginRight:
                                                                            '5px',
                                                                    }}
                                                                    key={i}
                                                                    draggable={
                                                                        true
                                                                    }
                                                                    onDragStart={(
                                                                        event
                                                                    ) => {
                                                                        event.dataTransfer.setData(
                                                                            'storm-diagram-node',
                                                                            JSON.stringify(
                                                                                {
                                                                                    type: 'input',
                                                                                    record: input,
                                                                                }
                                                                            )
                                                                        )
                                                                    }}>
                                                                    <Col
                                                                        xs="12"
                                                                        style={{
                                                                            background:
                                                                                '#145388',
                                                                            borderRadius:
                                                                                '5px',
                                                                            padding:
                                                                                '5px',
                                                                            margin: '5px',
                                                                            color: '#fff',
                                                                        }}>
                                                                        <i className="iconsmind-Data" />{' '}
                                                                        {input.name +
                                                                            ' (' +
                                                                            asset.name +
                                                                            ')'}
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    return inputs
                                                }
                                            )}
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="border">
                                    <Button
                                        block
                                        color="link"
                                        className="text-left"
                                        onClick={() => this.toggleAccordion(2)}
                                        aria-expanded={this.state.accordion[2]}>
                                        Transforms
                                    </Button>
                                    <Collapse isOpen={this.state.accordion[2]}>
                                        <div className="p-4">
                                            {TRANSFORMS.sort((a, b) =>
                                                a.name > b.name ? 1 : -1
                                            ).map((transform, i) => {
                                                return (
                                                    <Row
                                                        style={{
                                                            marginRight: '5px',
                                                        }}
                                                        key={i}
                                                        draggable={true}
                                                        onDragStart={(
                                                            event
                                                        ) => {
                                                            event.dataTransfer.setData(
                                                                'storm-diagram-node',
                                                                JSON.stringify({
                                                                    type: 'transform',
                                                                    name: transform.name,
                                                                })
                                                            )
                                                        }}>
                                                        <Col
                                                            xs="12"
                                                            style={{
                                                                background:
                                                                    '#d39e00',
                                                                borderRadius:
                                                                    '5px',
                                                                padding: '5px',
                                                                margin: '5px',
                                                                color: '#fff',
                                                            }}>
                                                            <i className="iconsmind-Formula" />{' '}
                                                            {transform.name}
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="border">
                                    <Button
                                        block
                                        color="link"
                                        className="text-left"
                                        onClick={() => this.toggleAccordion(3)}
                                        aria-expanded={this.state.accordion[3]}>
                                        Outputs
                                    </Button>
                                    <Collapse isOpen={this.state.accordion[3]}>
                                        <div className="p-4">
                                            {OUTPUTS.sort((a, b) =>
                                                a.name > b.name ? 1 : -1
                                            ).map((output, i) => {
                                                return (
                                                    <Row
                                                        style={{
                                                            marginRight: '5px',
                                                        }}
                                                        key={i}
                                                        draggable={true}
                                                        onDragStart={(
                                                            event
                                                        ) => {
                                                            event.dataTransfer.setData(
                                                                'storm-diagram-node',
                                                                JSON.stringify({
                                                                    type: 'output',
                                                                    name: output.name,
                                                                })
                                                            )
                                                        }}>
                                                        <Col
                                                            xs="12"
                                                            style={{
                                                                background:
                                                                    '#28a745',
                                                                borderRadius:
                                                                    '5px',
                                                                padding: '5px',
                                                                margin: '5px',
                                                                color: '#fff',
                                                            }}>
                                                            <i className="iconsmind-Formula" />{' '}
                                                            {output.name}
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </div>
                                    </Collapse>
                                </div>
                            </Fragment>
                        </Scrollbars>
                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
