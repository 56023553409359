import * as API from 'SDK/api'

export const paginate = async (dataModelId, filter, sort) => {
    let data = [],
        next = null,
        previous = null,
        hasNext = true

    while (hasNext) {
        try {
            const {result} = await API.post(
                'data-models/' + dataModelId + '/paginate',
                {
                    filter,
                    sort,
                    next,
                    previous,
                    limit: 1000,
                    timezone: localStorage['timezone'],
                },
                2
            )

            data = data.concat(result.results)

            hasNext = result.hasNext
            next = result.next
        } catch (error) {
            console.log(error)
            alert('Could not fetch data.')
            hasNext = false
        }
    }
    return data
}
