import React, {useState, useEffect} from 'react'
import {
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Input,
    Label,
    Collapse,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import modelToDot from '../../../helpers/modelToDot'
import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'
import FilterModal from '../../../../SchemaViewer/modals/Filter'
import ModelTree from '../../../../common/ModelTree'

export default (props) => {
    const [advancedIsOpen, toggleAdvanced] = useState(false)
    const [parameters, setParameters] = useState({
        dataModelId: props.element.data.opts.dataModelId || null,
        requestBody: props.element.data.opts.requestBody || {
            filter: [],
            sort: {},
            limit: 1,
        },
        name: props.element.data.opts.name || 'GetRecords',
        description: props.element.data.opts.description || '',
        requestURL:
            props.element.data.opts.requestURL ||
            'https://data-models.accuminetech.com/data-models',
        variableId: props.element.data.opts.variableId || null,
        submitted: props.element.data.opts.submitted || true,
    })

    const [filterModalOpen, setFilterModalOpen] = useState(false)
    const [sortModalOpen, setSortModalOpen] = useState(false)

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.transformId === props.element.id
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Record',
            multi: !(parameters.requestBody.limit === 1),
            fields: modelToDot(parameters.dataModelId, props.dataModels, false),
            dataModelId: parameters.dataModelId,
        }

        parameters.variableId = variable.id

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                onChange={(m) => {
                                    parameters.dataModelId = m
                                    parameters.requestBody = {
                                        filter: [],
                                        sort: {},
                                        limit: 1,
                                    }
                                    setParameters({...parameters})
                                }}
                                fieldName="Data Model"
                                default={
                                    parameters.dataModelId
                                        ? {
                                              label: props.dataModels.find(
                                                  (m) =>
                                                      m._id ===
                                                      parameters.dataModelId
                                              ).name,
                                              value: parameters.dataModelId,
                                          }
                                        : null
                                }
                                options={props.dataModels.map((m) => {
                                    return {
                                        label: m.name,
                                        value: m._id,
                                    }
                                })}
                            />
                        </FormGroup>

                        {parameters.dataModelId ? (
                            <FormGroup>
                                <Row>
                                    <Col xs="6">
                                        <Button
                                            color="secondary"
                                            size="sm"
                                            block
                                            onClick={() =>
                                                setFilterModalOpen(true)
                                            }>
                                            Filter (
                                            {
                                                parameters.requestBody.filter
                                                    .length
                                            }
                                            )
                                        </Button>
                                    </Col>
                                    <Col xs="6">
                                        <Button
                                            color="secondary"
                                            size="sm"
                                            block
                                            onClick={() =>
                                                setSortModalOpen(true)
                                            }>
                                            Sort (
                                            {!parameters.requestBody.sort
                                                .fieldName ? (
                                                'creation time, descending'
                                            ) : (
                                                <>
                                                    {
                                                        parameters.requestBody
                                                            .sort.fieldName
                                                    }
                                                    ,{' '}
                                                    {
                                                        parameters.requestBody
                                                            .sort.direction
                                                    }
                                                </>
                                            )}
                                            )
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        ) : null}

                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="limit"
                                    checked={parameters.requestBody.limit === 1}
                                    onChange={(e) => {
                                        if (e.currentTarget.value === 'on') {
                                            parameters.requestBody.limit = 1
                                            setParameters({...parameters})
                                        }
                                    }}
                                />
                                Single Record
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="limit"
                                    checked={parameters.requestBody.limit > 1}
                                    onChange={(e) => {
                                        if (e.currentTarget.value === 'on') {
                                            parameters.requestBody.limit = 300
                                            setParameters({...parameters})
                                        }
                                    }}
                                />
                                Multi Records (max. 300)
                            </Label>
                        </FormGroup>

                        <hr />

                        <FormGroup>
                            <p
                                color="link"
                                style={{cursor: 'pointer'}}
                                onClick={() => toggleAdvanced(!advancedIsOpen)}>
                                {advancedIsOpen ? (
                                    <>
                                        <i className="simple-icon-arrow-down" />{' '}
                                        Hide advanced
                                    </>
                                ) : (
                                    <>
                                        <i className="simple-icon-arrow-right" />{' '}
                                        Show advanced
                                    </>
                                )}
                            </p>
                            <Collapse isOpen={advancedIsOpen}>
                                <FormGroup>
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.requestURL = v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Request URL"
                                        existing={[]}
                                        initial={parameters.requestURL}
                                        disabled={false}
                                    />
                                </FormGroup>

                                {parameters.requestBody.limit > 1 && (
                                    <FormGroup>
                                        <Label>Max records to fetch:</Label>
                                        <Input
                                            type="number"
                                            name="limit"
                                            value={parameters.requestBody.limit}
                                            onChange={(e) => {
                                                let v = e.target.value
                                                if (v > 300) {
                                                    v = 300
                                                } else if (v < 1) {
                                                    v = 1
                                                }
                                                parameters.requestBody.limit =
                                                    parseInt(v)
                                                setParameters({...parameters})
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            </Collapse>
                        </FormGroup>

                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
            {filterModalOpen ? (
                <FilterModal
                    open={true}
                    toggle={() => setFilterModalOpen(!filterModalOpen)}
                    dataModel={props.dataModels.find(
                        (m) => m._id === parameters.dataModelId
                    )}
                    dataModels={props.dataModels}
                    updateFilters={(filter) => {
                        parameters.requestBody.filter = filter
                        setParameters({...parameters})
                    }}
                    filters={parameters.requestBody.filter}
                    refresh={() => {}}
                    fieldTypes={props.fieldTypes}
                    formulas={props.formulas}
                    variables={props.variables.filter(
                        (v) => !v.multi && v.transformId !== props.element.id
                    )}
                />
            ) : null}

            {sortModalOpen && (
                <Modal
                    size="sm"
                    isOpen={true}
                    wrapClassName="modal-right"
                    toggle={() => {
                        if (!parameters.requestBody.sort.fieldName) {
                            return alert('Please choose a field to sort by')
                        }
                        setSortModalOpen(!sortModalOpen)
                    }}>
                    <ModalHeader
                        toggle={() => {
                            if (!parameters.requestBody.sort.fieldName) {
                                return alert('Please choose a field to sort by')
                            }
                            setSortModalOpen(!sortModalOpen)
                        }}>
                        Sort Data
                    </ModalHeader>
                    <ModalBody>
                        <SelectInput
                            onChange={(direction) => {
                                parameters.requestBody.sort.direction =
                                    direction
                                setParameters({...parameters})
                            }}
                            fieldName="Direction"
                            default={
                                parameters.requestBody.sort
                                    ? parameters.requestBody.sort.direction ===
                                      'descending'
                                        ? {
                                              label: 'Descending',
                                              value: 'descending',
                                          }
                                        : {
                                              label: 'Ascending',
                                              value: 'ascending',
                                          }
                                    : null
                            }
                            options={[
                                {
                                    label: 'Ascending',
                                    value: 'ascending',
                                },
                                {
                                    label: 'Descending',
                                    value: 'descending',
                                },
                            ]}
                        />
                        <hr />
                        <p>
                            Selected field:{' '}
                            {parameters.requestBody.sort.fieldName ||
                                'no field selected'}
                        </p>
                        <ModelTree
                            showMap={(item) =>
                                props.fieldTypes.find(
                                    (f) => f.type === item.type && f.sortable
                                )
                            }
                            onSelect={(_, {selectedNodes}) => {
                                const {path} = selectedNodes[0]
                                parameters.requestBody.sort.fieldName = path
                                if (!parameters.requestBody.sort.direction) {
                                    parameters.requestBody.sort.direction =
                                        'ascending'
                                }
                                setParameters({...parameters})
                            }}
                            dataModel={props.dataModels.find(
                                (d) => d._id === parameters.dataModelId
                            )}
                            dataModels={props.dataModels}
                            includeJoins={false}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="default"
                            onClick={() => {
                                parameters.requestBody.sort = {}
                                setParameters({...parameters})
                                setSortModalOpen(false)
                            }}>
                            Reset
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => {
                                if (!parameters.requestBody.sort.fieldName) {
                                    return alert(
                                        'Please choose a field to sort by'
                                    )
                                }
                                setSortModalOpen(!sortModalOpen)
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    )
}
