import React, {Component, Fragment} from 'react'
import {Bar} from 'react-chartjs-2'
import moment from 'moment'

import {ThemeColors} from 'util/ThemeColors'
const colors = ThemeColors()

let chartConfig = {
    responsive: true,
    maintainAspectRatio: true,
    padding: {
        top: 0,
    },
    title: {
        text: '',
        display: true,
    },
    legend: {
        display: true,
    },
    scales: {
        yAxes: [
            {
                stacked: true,
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: 'rgba(0,0,0,0.1)',
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                    padding: 20,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const data = {
            labels: this.props.hours,
            datasets: [
                {
                    label: '%',
                    data: this.props.data.map((a) =>
                        parseInt((a / this.props.target) * 100)
                    ),
                    backgroundColor: 'rgb(46, 204, 113)',
                },
            ],
        }
        chartConfig.title.text = 'Parts: Actual/Target Last 24 hours'
        return <Bar data={data} options={chartConfig} />
    }
}
