import {v4 as uuid} from 'uuid'
import moment from 'moment'

import * as API from 'SDK/api'

import TIMERANGES from '../TIMERANGES'

export default class RouteDataset {
    constructor({datasetId = uuid(), assets, timerange, schedule, model}) {
        this.sync({
            datasetId,
            assets,
            timerange,
            schedule,
            model,
        })
    }

    sync({datasetId, assets, timerange, schedule, model}) {
        this.datasetId = datasetId
        this.assets = assets
        this.timerange = timerange
        this.schedule = schedule
        this.model = model
    }

    async publish() {
        await API.post('ips/cloud/route-datasets', this, 2)
    }

    async remove() {
        await API.remove('ips/cloud/route-datasets/' + this.datasetId, 2)
    }

    async processDevice(deviceId, timeStart, timeEnd, schedule) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId,
                    timeStart: {
                        $gte: timeStart,
                    },
                    timeEnd: {
                        $lt: timeEnd,
                    },
                    name: 'Location',
                },
                options: {
                    sort: {
                        timestamp: 1,
                    },
                },
            },
            2
        )

        if (schedule) {
            let scheduleArray = []
            for (let shift of schedule) {
                if (shift.assets.find((a) => a === deviceId)) {
                    scheduleArray = scheduleArray.concat(shift._schedule)
                }
            }
            data = data.filter((row) =>
                scheduleArray.some((range) =>
                    moment(row.timestamp).isBetween(range[0], range[1])
                )
            )
        }

        const length = data.length
        return data.map((row, index, array) => {
            return {
                from: row.value,
                path:
                    row.value +
                    '::' +
                    (index < length - 1 ? array[index + 1].value : ''),
                date: moment(row.timestamp).startOf('day'),
            }
        })
    }

    async makeShiftSchedule(timerange) {
        let shifts = await API.get('shifts', 2)

        if (this.schedule !== 'scheduled') {
            shifts = shifts.filter((s) => s.name === this.schedule)
        }

        shifts = shifts.map((s) => {
            s._schedule = []
            return s
        })

        for (
            let m = moment(timerange[0]);
            m.isBefore(timerange[1]);
            m.add(1, 'days')
        ) {
            const dow = m.day()
            for (let shift of shifts) {
                if (shift.days.find((d) => d == dow) === undefined) continue

                let range = [
                    moment(m).set({
                        hour: shift.timeStart.hour,
                        minute: shift.timeStart.minute,
                    }),
                    moment(m).set({
                        hour: shift.timeEnd.hour,
                        minute: shift.timeEnd.minute,
                    }),
                ]

                if (range[0].isAfter(range[1])) {
                    range[1].add(1, 'days')
                }
                shift._schedule.push(range)
            }
        }
        return shifts
    }

    async fetch() {
        let timerange = TIMERANGES.find(
                (t) => t.name === this.timerange
            ).timerange(),
            schedule =
                this.schedule === 'alltime'
                    ? null
                    : await this.makeShiftSchedule(timerange),
            allData = await Promise.all(
                this.assets.map((a) =>
                    this.processDevice(a, timerange[0], timerange[1], schedule)
                )
            ),
            data = {},
            timelineData = [],
            routes = []

        allData.forEach((dataset) => {
            dataset.forEach((x) => {
                data[x.path] = (data[x.path] || 0) + 1
                const existing = timelineData.find(
                    (t) => t.from === x.from && t.date.isSame(x.date)
                )
                if (existing) {
                    existing.value += 1
                } else {
                    timelineData.push({
                        from: x.from,
                        date: x.date,
                        value: 1,
                    })
                }
            })
        })

        for (let route in data) {
            const path = route.split('::')
            if (path[1] !== '') {
                routes.push({
                    from: path[0],
                    to: path[1],
                    count: data[route],
                })
            }
        }

        routes.sort((a, b) => a.count - b.count)

        const rawMax = Math.max(...routes.map((x) => x.count))

        for (let route of routes) {
            route.density = route.count / rawMax
        }

        const densityMax = Math.max(...routes.map((x) => x.density)),
            densityMin = Math.min(...routes.map((x) => x.density))

        routes = routes.map((r) => {
            r.density = (r.density - densityMin) / (densityMax - densityMin)
            return r
        })
        return {routes, timelineData}
    }
}
