import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Alert,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import moment from 'moment'
import {cloneDeep} from 'lodash'
import Creatable from 'react-select/creatable'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash, iteratePlaceholderName} from 'SDK/helpers'
import TimezoneSelect from 'SDK/ui/TimezoneSelect'

const DAYS_OPTIONS = [
    {
        label: 'Monday',
        value: 1,
    },
    {
        label: 'Tuesday',
        value: 2,
    },
    {
        label: 'Wednesday',
        value: 3,
    },
    {
        label: 'Thursday',
        value: 4,
    },
    {
        label: 'Friday',
        value: 5,
    },
    {
        label: 'Saturday',
        value: 6,
    },
    {
        label: 'Sunday',
        value: 0,
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.descriptions = [
            ...new Set(
                ['Default'].concat(
                    this.props.shifts
                        .filter((a) => a.description)
                        .map((a) => a.description)
                )
            ),
        ]

        const {editingShift} = this.props,
            timeStart =
                (editingShift.timeStart.hour < 10 ? '0' : '') +
                editingShift.timeStart.hour +
                ':' +
                (editingShift.timeStart.minute < 10 ? '0' : '') +
                editingShift.timeStart.minute,
            timeEnd =
                (editingShift.timeEnd.hour < 10 ? '0' : '') +
                editingShift.timeEnd.hour +
                ':' +
                (editingShift.timeEnd.minute < 10 ? '0' : '') +
                editingShift.timeEnd.minute

        let breaks = []
        if (editingShift.breaks) {
            breaks = editingShift.breaks.map((b) => {
                b.timeStart =
                    (b.timeStart.hour < 10 ? '0' : '') +
                    b.timeStart.hour +
                    ':' +
                    (b.timeStart.minute < 10 ? '0' : '') +
                    b.timeStart.minute
                b.timeEnd =
                    (b.timeEnd.hour < 10 ? '0' : '') +
                    b.timeEnd.hour +
                    ':' +
                    (b.timeEnd.minute < 10 ? '0' : '') +
                    b.timeEnd.minute
                return b
            })
        }

        let description = editingShift.description || ''

        this.selectedAssets = editingShift.assets.filter((a) => a !== null)

        this.state = {
            nameInputBorderColor: 'rgb(215,215,215)',
            fromInputBorderColor: 'rgb(215,215,215)',
            toInputBorderColor: 'rgb(215,215,215)',
            breakTimeInputBorderColor: 'rgb(215,215,215)',
            name: editingShift.name,
            timeStart: timeStart,
            timeEnd: timeEnd,
            days: editingShift.days.map((day) => {
                return DAYS_OPTIONS.find((x) => x.value === day)
            }),
            breaks: breaks,
            valid: true,
            message: false,
            description: description,
            assets: [],
            selectedAssets: [],
            timezone: editingShift.timezone,
        }
    }
    handleTimezoneSelection(timezone) {
        this.setState({timezone})
    }
    async getAssets() {
        const assets = await API.get('devices?all=true')
        this.setState({
            assets: assets.map((x) => {
                return {label: x.name, value: x.deviceId}
            }),
        })
    }
    async save() {
        let breakTime = 0,
            breaks = cloneDeep(this.state.breaks),
            error = false

        const shiftStartMinutes =
                parseInt(this.state.timeStart.split(':')[0]) * 60 +
                parseInt(this.state.timeStart.split(':')[1]),
            shiftEndMinutes =
                parseInt(this.state.timeEnd.split(':')[0]) * 60 +
                parseInt(this.state.timeEnd.split(':')[1])

        if (shiftStartMinutes === shiftEndMinutes) {
            error = true
            alert(`Shift must have a valid duration.`)
        }

        breaks = breaks.map((b) => {
            b.timeStart = {
                hour: parseInt(b.timeStart.split(':')[0]),
                minute: parseInt(b.timeStart.split(':')[1]),
            }
            b.timeEnd = {
                hour: parseInt(b.timeEnd.split(':')[0]),
                minute: parseInt(b.timeEnd.split(':')[1]),
            }

            const timeStartMinutes = b.timeStart.hour * 60 + b.timeStart.minute,
                timeEndMinutes = b.timeEnd.hour * 60 + b.timeEnd.minute

            if (shiftStartMinutes > shiftEndMinutes) {
                // overnight shift
                if (
                    (!error &&
                        timeStartMinutes < shiftStartMinutes &&
                        timeStartMinutes > shiftEndMinutes) ||
                    (timeEndMinutes > shiftEndMinutes &&
                        timeEndMinutes < shiftStartMinutes)
                ) {
                    alert(
                        `Break "${b.name}" must be within the specified shift time.`
                    )
                    error = true
                }
                if (!error && timeStartMinutes === timeEndMinutes) {
                    alert(`Break "${b.name}" must have a valid duration.`)
                    error = true
                }
                // first day
                if (
                    !error &&
                    timeStartMinutes >= timeEndMinutes &&
                    timeStartMinutes >= shiftStartMinutes &&
                    timeEndMinutes >= shiftStartMinutes
                ) {
                    alert(`Break "${b.name}" must have a valid duration.`)
                    error = true
                }
                // second day
                if (
                    !error &&
                    timeStartMinutes >= timeEndMinutes &&
                    timeStartMinutes <= shiftEndMinutes &&
                    timeEndMinutes <= shiftEndMinutes
                ) {
                    alert(`Break "${b.name}" must have a valid duration.`)
                    error = true
                }
            } else {
                // day shift
                if (!error && timeStartMinutes >= timeEndMinutes) {
                    alert(`Break "${b.name}" must have a valid duration.`)
                    error = true
                }
                if (
                    (!error && timeStartMinutes < shiftStartMinutes) ||
                    timeEndMinutes > shiftEndMinutes
                ) {
                    alert(
                        `Break "${b.name}" must be within the specified shift time.`
                    )
                    error = true
                }
            }
            if (timeStartMinutes <= timeEndMinutes) {
                breakTime += timeEndMinutes - timeStartMinutes
            } else {
                breakTime += 1440 - timeStartMinutes + timeEndMinutes
            }

            return b
        })

        if (error) {
            return
        }

        let response = await API.patch(
            'shifts/' + this.props.editingShift._id,
            {
                days: this.state.days.map((day) => day.value),
                name: this.state.name,
                timeStart: {
                    hour: parseInt(this.state.timeStart.split(':')[0]),
                    minute: parseInt(this.state.timeStart.split(':')[1]),
                },
                timeEnd: {
                    hour: parseInt(this.state.timeEnd.split(':')[0]),
                    minute: parseInt(this.state.timeEnd.split(':')[1]),
                },
                breaks: breaks,
                breakTime: breakTime,
                description: this.state.description,
                assets: this.state.selectedAssets.map((x) => x.value),
                timezone: this.state.timezone,
            },
            2
        )
        if (response) {
            this.props.toggleModal('editModal')
        } else {
            return alert(
                'There was an error editing your Shift. Please try again.'
            )
        }
    }
    renderBreakList() {
        return this.state.breaks.map((b) => {
            return (
                <tr key={b.id}>
                    <td>
                        <Input
                            value={
                                this.state.breaks.find((a) => a.id === b.id)
                                    .name
                            }
                            style={{
                                borderTop: 'transparent',
                                borderRight: 'transparent',
                                borderLeft: 'transparent',
                                backgroundColor: 'transparent',
                            }}
                            onChange={(e) => {
                                const breaks = this.state.breaks
                                breaks.find((a) => a.id === b.id).name =
                                    e.target.value
                                this.setState({breaks})
                            }}
                        />
                    </td>
                    <td>
                        <Input
                            type="time"
                            value={b.timeStart}
                            style={{
                                borderTop: 'transparent',
                                borderRight: 'transparent',
                                borderLeft: 'transparent',
                                backgroundColor: 'transparent',
                            }}
                            onChange={(e) => {
                                const breaks = this.state.breaks
                                breaks.find((a) => a.id === b.id).timeStart =
                                    e.target.value
                                this.setState({breaks})
                            }}
                        />
                    </td>
                    <td>
                        <Input
                            type="time"
                            value={b.timeEnd}
                            style={{
                                borderTop: 'transparent',
                                borderRight: 'transparent',
                                borderLeft: 'transparent',
                                backgroundColor: 'transparent',
                            }}
                            onChange={(e) => {
                                const breaks = this.state.breaks
                                breaks.find((a) => a.id === b.id).timeEnd =
                                    e.target.value
                                this.setState({breaks})
                            }}
                        />
                    </td>
                    <td
                        style={{
                            textAlign: 'right',
                            paddingTop: '20px',
                        }}>
                        <Button
                            size="xs"
                            color="danger"
                            onClick={() => {
                                let breaks = this.state.breaks
                                breaks = breaks.filter((a) => a.id !== b.id)
                                this.setState({breaks})
                            }}>
                            <i className="simple-icon-trash" />
                        </Button>
                    </td>
                </tr>
            )
        })
    }
    addBreak() {
        let breaks = this.state.breaks
        breaks.push({
            id: generateHash(),
            name: iteratePlaceholderName(breaks, 'New Break'),
            timeStart: '00:00',
            timeEnd: '00:00',
        })

        this.setState({
            breaks,
        })
    }

    async componentDidMount() {
        await this.getAssets()

        let selectedAssets = []

        if (this.selectedAssets) {
            for (let i in this.selectedAssets) {
                const name = this.state.assets.find(
                    (a) => a.value === this.selectedAssets[i]
                ).label
                selectedAssets.push({
                    label: name,
                    value: this.selectedAssets[i],
                })
            }
        } else {
            selectedAssets = this.state.assets
        }

        this.setState({selectedAssets})
    }

    render() {
        const {modal, toggleModal, shifts} = this.props

        return (
            <Fragment>
                <Modal
                    size="lg"
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('editModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('editModal')
                        }}>
                        Edit Shift
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            nameInputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                                <Alert color="primary">
                                    You can reuse the same shift name across
                                    different <b>Shift Groups</b>. For example,
                                    "Day Shift" can be configured differently in
                                    the "Weld" Shift Group versus the "Press"
                                    Shift Group.
                                </Alert>
                            </Col>
                            <Col xs="12" sm="3" className="mb-4">
                                <label>From:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.fromInputBorderColor,
                                    }}
                                    type="time"
                                    name="timeStart"
                                    value={this.state.timeStart}
                                    onChange={(e) => {
                                        let v = e.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        }
                                        this.setState({
                                            timeStart: v,
                                            valid: valid,
                                            fromInputBorderColor: color,
                                        })
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="3" className="mb-4">
                                <label>To:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.toInputBorderColor,
                                    }}
                                    type="time"
                                    name="timeEnd"
                                    value={this.state.timeEnd}
                                    onChange={(e) => {
                                        let v = e.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        }
                                        this.setState({
                                            timeEnd: v,
                                            valid: valid,
                                            toInputBorderColor: color,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <TimezoneSelect
                                    default={
                                        this.state.timezone || moment.tz.guess()
                                    }
                                    onChange={this.handleTimezoneSelection}
                                />
                                <Alert color="primary">
                                    Ensure that each shift has the same
                                    timezone. Multi-timezone shift schedules are
                                    not recommended outside of complex
                                    deployments.
                                </Alert>
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Description:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="shift-group"
                                    value={{
                                        label:
                                            this.state.description || 'Default',
                                        value:
                                            this.state.description || 'Default',
                                    }}
                                    onChange={(description) =>
                                        this.setState({
                                            description: description.value,
                                        })
                                    }
                                    options={this.descriptions.map((a) => {
                                        return {
                                            label: a || 'Default',
                                            value: a || 'Default',
                                        }
                                    })}
                                />
                                <Alert color="primary">
                                    Adding a <b>Shift Group</b> allows you to
                                    configure shift variations for different
                                    assets. Start typing to create a new{' '}
                                    <b>Shift Group</b>
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Assets:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="assets"
                                    isMulti
                                    value={this.state.selectedAssets}
                                    onChange={(selectedAssets) => {
                                        this.setState({selectedAssets})
                                    }}
                                    options={this.state.assets}
                                />
                                <Alert color="primary">
                                    Any assets included above will use this
                                    shift schedule for real-time displays &
                                    reports.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Days:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="days"
                                    isMulti
                                    value={this.state.days}
                                    onChange={(days) => {
                                        this.setState({days: days})
                                    }}
                                    options={DAYS_OPTIONS}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Breaks:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert color="primary">
                                    Include start-up time, lunches, clean-up,
                                    etc. Total break time will be subtracted
                                    from shift capacity when running Utilization
                                    reports (by shift) & OEE reports because
                                    breaks are treated as scheduled downtime.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{width: '50%'}}>Name</th>
                                            <th style={{width: '10%'}}>From</th>
                                            <th style={{width: '10%'}}>To</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderBreakList()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    size="xs"
                                    className="btn-block"
                                    onClick={this.addBreak}>
                                    Add Break
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('editModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
