import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    deleteFlow() {
        API.remove('flows/' + this.props.flowId, 2)
        this.props.history.push('/app/core/flows')
    }

    render() {
        const {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Are you sure?
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <p className="text-center">
                                    Deleting this flow is final.
                                </p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteFlow}>
                            I'm sure
                        </Button>
                        <Button color="primary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
