import React, {Fragment, Component} from 'react'
import {Row, Col, Button} from 'reactstrap'
import autobind from 'auto-bind'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        return (
            <Fragment>
                {this.props.dataModel !== null ? (
                    <Row>
                        <Col xs="6">
                            <h4>
                                <a
                                    href="#"
                                    onClick={() => this.props.backToMenu()}>
                                    Datasets
                                </a>
                                {' > '}
                                {this.props.reportData ? (
                                    <>
                                        <a
                                            href="#"
                                            onClick={() =>
                                                this.props.clearReport()
                                            }>
                                            {this.props.dataModel.name}
                                        </a>
                                        {' > '}
                                        {this.props.loadedSavedReport
                                            ? this.props.loadedSavedReport
                                                  .report_name
                                            : 'Unsaved Report'}
                                    </>
                                ) : (
                                    <>{this.props.dataModel.name}</>
                                )}
                            </h4>
                        </Col>
                        <Col xs="6" className="text-right">
                            {this.props.reportData ? (
                                <Fragment>
                                    {this.props.sentByReportViewer ? (
                                        <Button
                                            className="mr-2"
                                            size="xs"
                                            onClick={() =>
                                                this.props.history.push(
                                                    this.props.previous
                                                )
                                            }>
                                            Back to Report Viewer
                                        </Button>
                                    ) : null}
                                    <Button
                                        className="mr-2"
                                        size="xs"
                                        onClick={this.props.editReportColumns}>
                                        <i className="simple-icon-grid" /> Edit
                                        Columns
                                    </Button>
                                    <Button
                                        className="mr-2"
                                        size="xs"
                                        onClick={this.props.saveReport}>
                                        <i className="iconsmind-Save" /> Save
                                        Report
                                    </Button>
                                    <Button
                                        color="warning"
                                        className="mr-2"
                                        size="xs"
                                        onClick={this.props.analyze}>
                                        <i className="simple-icon-energy" />{' '}
                                        Report Engine
                                    </Button>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Button
                                        color="warning"
                                        className="mr-2"
                                        size="xs"
                                        onClick={this.props.analyze}>
                                        <i className="simple-icon-energy" />{' '}
                                        Report Engine
                                    </Button>
                                </Fragment>
                            )}

                            <Button
                                color="default"
                                className="mr-2"
                                size="xs"
                                onClick={this.props.showApiCall}>
                                Show API Call
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col>
                            <h4>Select a dataset...</h4>
                        </Col>
                    </Row>
                )}
            </Fragment>
        )
    }
}
