import React, {Component} from 'react'
import {Form, FormGroup, Input, Label, Button} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'

import IntlMessages from 'util/IntlMessages'
import CustomSelectInput from 'components/CustomSelectInput'

export default class Initializer extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            name: this.props.editingDataModel.name,
            nameInputBorderColor: 'rgb(215,215,215)',
            description: this.props.editingDataModel.description,
            descriptionInputBorderColor: 'rgb(215,215,215)',
            nameMessage: '',
            descriptionMessage: '',
            modelType: {
                label: 'Static (i.e. users, assets, departments)',
                value: 'Static',
            },
            protectedModel: {
                label: 'Unprotected - can modify name/details once created',
                value: false,
            },
        }
    }

    showButton() {
        return (
            this.state.nameMessage.length === 0 &&
            this.state.descriptionMessage.length === 0
        )
    }

    render() {
        return (
            <div
                style={{
                    position: 'relative',
                    height: '100%',
                }}>
                <Form
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '50%',
                    }}>
                    <FormGroup>
                        <h3>Let's get started...</h3>
                    </FormGroup>
                    <FormGroup>
                        <Label
                            className="form-group has-top-label"
                            style={{marginBottom: '5px'}}>
                            <Input
                                style={{
                                    borderColor:
                                        this.state.nameInputBorderColor,
                                }}
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={(name) => {
                                    let v = name.target.value,
                                        color = 'rgb(215,215,215)',
                                        message = ''
                                    if (v === '') {
                                        color = '#dc3545'
                                        message = 'Data model must have a name!'
                                    } else if (
                                        this.props.dataModels.find(
                                            (m) => m.name === v
                                        )
                                    ) {
                                        color = '#dc3545'
                                        message =
                                            'A data model with this name already exists!'
                                    }
                                    this.setState({
                                        name: v,
                                        nameInputBorderColor: color,
                                        nameMessage: message,
                                    })
                                }}
                            />
                            <IntlMessages id="Name" />
                        </Label>
                        {this.state.nameMessage &&
                        this.state.nameMessage.length ? (
                            <p style={{color: '#dc3545'}}>
                                Error: {this.state.nameMessage}
                            </p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <Label
                            className="form-group has-top-label"
                            style={{marginBottom: '5px'}}>
                            <Input
                                style={{
                                    borderColor:
                                        this.state.descriptionInputBorderColor,
                                }}
                                type="text"
                                name="description"
                                value={this.state.description}
                                onChange={(description) => {
                                    let v = description.target.value,
                                        color = 'rgb(215,215,215)',
                                        message = ''
                                    if (v === '') {
                                        color = '#dc3545'
                                        message =
                                            'Data model must have a description!'
                                    }
                                    this.setState({
                                        description: v,
                                        descriptionInputBorderColor: color,
                                        descriptionMessage: message,
                                    })
                                }}
                            />
                            <IntlMessages id="Description" />
                        </Label>
                        {this.state.descriptionMessage &&
                        this.state.descriptionMessage.length ? (
                            <p style={{color: '#dc3545'}}>
                                Error: {this.state.descriptionMessage}
                            </p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="modelType"
                            value={this.state.modelType}
                            onChange={(modelType) => this.setState({modelType})}
                            options={[
                                {
                                    label: 'Static (i.e. users, assets, departments)',
                                    value: 'Static',
                                },
                                {
                                    label: 'Event (i.e. form data, sensor reading)',
                                    value: 'Event',
                                },
                                {
                                    label: 'State (i.e. any data that has a duration)',
                                    value: 'State',
                                },
                            ]}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="protectedModel"
                            value={this.state.protectedModel}
                            onChange={(protectedModel) =>
                                this.setState({protectedModel})
                            }
                            options={[
                                {
                                    label: 'Protected - cannot modify name/details once created',
                                    value: true,
                                },
                                {
                                    label: 'Unprotected - can modify name/details once created',
                                    value: false,
                                },
                            ]}
                        />
                    </FormGroup>
                    {this.showButton() ? (
                        <FormGroup>
                            <Button
                                color="primary"
                                block
                                onClick={() => {
                                    this.props.initializeDataModel(
                                        this.state.name,
                                        this.state.description,
                                        this.state.modelType.value,
                                        this.state.protectedModel.value
                                    )
                                }}>
                                Initialize {this.state.name}
                            </Button>
                        </FormGroup>
                    ) : null}
                </Form>
            </div>
        )
    }
}
