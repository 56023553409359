import React, {Component, Fragment} from 'react'
import {Row, Card, CardHeader, CardBody, CardFooter} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import Navigation from './navigation'
import ChartMaker from './chartMaker'

export default class OEEInteractiveReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'flash',
            component: 'RealTimeTileFlash',
        },
        {
            prop: 'aggregation',
            component: 'RealTimeAggregation',
        },
        {
            prop: 'black',
            component: 'BlackText',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'OEEInteractiveReport'
    requiredOutputs = ['Part Count', 'In-Cycle', 'Downtime', 'Work Order']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        aggregation: PropTypes.number,
        flash: PropTypes.bool,
        black: PropTypes.bool,
    }
    static defaultProps = {
        black: false,
        aggregation: 1,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            barChartElement: <></>,
        }
    }

    generate(navstate) {
        this.setState({
            barChartElement: <></>,
        })
        this.setState(
            {
                timelineTimeStart: navstate.start,
                timelineTimeEnd: navstate.end,
                navstate,
            },
            () => {
                if (navstate.shifts.length) this.getAvailabilityData()
            }
        )
    }

    async getAvailabilityData() {
        let partRequests = {},
            rejectRequests = {},
            inCycleRequests = {},
            scheduledTargetRequests = {}
        const timeUnit = 'days',
            timeStart = this.state.timelineTimeStart || moment().startOf('day'),
            timeEnd = this.state.timelineTimeEnd || moment().endOf('day')

        this.state.navstate.selectedAssets.forEach((asset) => {
            partRequests[asset.value] = []
            rejectRequests[asset.value] = []
            inCycleRequests[asset.value] = []
            scheduledTargetRequests[asset.value] = []

            this.state.navstate.shifts.forEach((shift) => {
                partRequests[asset.value].push(
                    API.post('historical', {
                        timeStart: moment(timeStart).toISOString(),
                        timeEnd: moment(timeEnd).toISOString(),
                        deviceId: asset.value,
                        state: 'Part Count',
                        unit: timeUnit,
                        shift: shift.name,
                    })
                )
                rejectRequests[asset.value].push(
                    API.post('historical', {
                        timeStart: moment(timeStart).toISOString(),
                        timeEnd: moment(timeEnd).toISOString(),
                        deviceId: asset.value,
                        state: 'Reject',
                        unit: timeUnit,
                        shift: shift.name,
                    })
                )
                inCycleRequests[asset.value].push(
                    API.post('historical', {
                        timeStart: moment(timeStart).toISOString(),
                        timeEnd: moment(timeEnd).toISOString(),
                        deviceId: asset.value,
                        state: 'In-Cycle',
                        unit: timeUnit,
                        shift: shift.name,
                    })
                )
                scheduledTargetRequests[asset.value].push(
                    API.post('historical', {
                        timeStart: moment(timeStart).toISOString(),
                        timeEnd: moment(timeEnd).toISOString(),
                        deviceId: asset.value,
                        state: 'Scheduled Target',
                        unit: timeUnit,
                        shift: shift.name,
                    })
                )
            })
        })

        let partData = {}
        let rejectData = {}
        let inCycleData = {}
        let scheduledTargetData = {}

        // all promises are fulfilled, separated by device id
        for (var i = 0; i < this.state.navstate.selectedAssets.length; ++i) {
            let asset = this.state.navstate.selectedAssets[i]
            partData[asset.value] = await Promise.all(partRequests[asset.value])
            rejectData[asset.value] = await Promise.all(
                rejectRequests[asset.value]
            )
            inCycleData[asset.value] = await Promise.all(
                inCycleRequests[asset.value]
            )
            scheduledTargetData[asset.value] = await Promise.all(
                scheduledTargetRequests[asset.value]
            )
        }

        this.calculateOEE({
            partData,
            rejectData,
            inCycleData,
            scheduledTargetData,
        })
    }

    async mergeTarget(selectedAssets, scheduledTargetData) {
        let targetsByMachine = {},
            targetRecords = await this.getTargetRecords(
                this.state.timelineTimeStart,
                this.state.timelineTimeEnd
            )

        for (var i = 0; i < this.state.navstate.selectedAssets.length; ++i) {
            let asset = this.state.navstate.selectedAssets[i]
            let mostRecent = await this.getMostRecentScheduledTarget(
                asset.value,
                this.state.timelineTimeStart
            )
            if (mostRecent) {
                targetsByMachine[asset.value] = [mostRecent]
            } else {
                targetsByMachine[asset.value] = []
            }
            targetRecords.forEach((tr) => {
                if (tr.deviceId == asset.value)
                    targetsByMachine[asset.value].push(tr)
            })
        }
    }

    calculateOEE(data) {
        let results = {},
            calcs = {}

        for (let i = 0; i < this.state.navstate.selectedAssets.length; ++i) {
            let asset = this.state.navstate.selectedAssets[i]
            results[asset.value] = {
                capacity: data.inCycleData[asset.value]
                    .map((d) => d.capacity[0])
                    .reduce((a, b) => a + b, 0),
                utilization: data.inCycleData[asset.value]
                    .map((d) => d.duration[0])
                    .reduce((a, b) => a + b, 0),
                partcount: data.partData[asset.value]
                    .map((d) => d.count[0])
                    .reduce((a, b) => a + b, 0),
                rejectcount: data.rejectData[asset.value]
                    .map((d) => d.count[0])
                    .reduce((a, b) => a + b, 0),
                scheduledcount: data.scheduledTargetData[asset.value]
                    .map((d) => d.count[0])
                    .reduce((a, b) => a + b, 0),
            }
        }

        for (let i = 0; i < this.state.navstate.selectedAssets.length; ++i) {
            let asset = this.state.navstate.selectedAssets[i]
            calcs[asset.value] = {
                availability: this.sanitizeNumber(
                    results[asset.value].utilization /
                        results[asset.value].capacity
                ),
                quality: this.sanitizeNumber(
                    results[asset.value].partcount /
                        (results[asset.value].partcount -
                            results[asset.value].rejectcount)
                ),
                performance: this.sanitizeNumber(
                    (results[asset.value].partcount -
                        results[asset.value].rejectcount) /
                        results[asset.value].scheduledcount
                ),
            }
        }

        this.setState({calcs, results}, () => {
            this.setBarChartElement()
        })
    }

    sanitizeNumber(val) {
        return Math.max(Math.min(val, 1), 0)
    }

    async getMostRecentScheduledTarget(deviceId, timestamp) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: [deviceId],
                    },
                    name: 'Scheduled Target',
                    timeStart: {
                        $gte: moment(timestamp)
                            .subtract(1, 'week')
                            .startOf('day')
                            .toISOString(),
                    },
                    timeEnd: {
                        $lte: moment(timestamp).toISOString(),
                    },
                },
                options: {
                    sort: {
                        timestamp: -1,
                    },
                    limit: 1,
                },
            },
            2
        )

        if (data.length) {
            return data[0]
        }
    }

    async getTargetRecords(startTime, endTime) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: this.state.navstate.selectedAssets.map(
                            (asset) => asset.value
                        ),
                    },
                    name: 'Scheduled Target',
                    timestamp: {
                        $gte: moment(startTime).toISOString(),
                        $lte: moment(endTime).toISOString(),
                    },
                },
                options: {
                    sort: {
                        timestamp: 1,
                    },
                },
            },
            2
        )

        return data
    }

    async componentWillMount() {
        let devices = await API.get('devices')
        devices = this.props.deviceIds
            ? devices.filter((device) =>
                  this.props.deviceIds.includes(device.deviceId)
              )
            : devices
        this.setState({
            devices,
        })
    }

    setBarChartElement() {
        const assets = this.state.navstate.selectedAssets.map(
            (asset) => asset.label
        )
        const availability = this.state.navstate.selectedAssets.map(
            (asset) => this.state.calcs[asset.value].availability * 100
        )
        const performance = this.state.navstate.selectedAssets.map(
            (asset) => this.state.calcs[asset.value].performance * 100
        )
        const quality = this.state.navstate.selectedAssets.map(
            (asset) => this.state.calcs[asset.value].quality * 100
        )
        const OEE = this.state.navstate.selectedAssets.map(
            (asset) =>
                this.sanitizeNumber(
                    this.state.calcs[asset.value].availability *
                        this.state.calcs[asset.value].quality *
                        this.state.calcs[asset.value].performance
                ) * 100
        )

        this.setState({
            barChartElement: (
                <ChartMaker
                    type="bar"
                    stacked
                    labels={assets}
                    OEE={OEE}
                    availability={availability}
                    performance={performance}
                    quality={quality}
                />
            ),
        })
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Card>
                        <CardBody>
                            <Navigation callback={this.generate} />
                            {this.state.barChartElement
                                ? this.state.barChartElement
                                : null}
                        </CardBody>
                    </Card>
                </Row>
            </Fragment>
        )
    }
}
