import {TRANSFORMS} from './transforms'
import {OUTPUTS} from './outputs'

export const makeTemplate = (model) => {
    return model.nodes.map((node) => {
        const {type} = node.extras

        /*const inputs = model.links.filter(l => l.target === node.id).map(l => {
      const inputNode = model.nodes.find(n => n.id === l.source);
      return inputNode.extras.nodeId
    })*/
        const inputs = node.ports
            .filter((p) => p.in && p.links.length)
            .map((p) => {
                const link = model.links.find((l) => l.id === p.links[0])
                const inputNode = model.nodes.find((n) => n.id === link.source)
                return inputNode.extras.nodeId
            })

        let obj = {
            id: node.extras.nodeId,
            inputs: inputs,
            transform: null,
            params: {},
        }

        if (type === 'input') {
            obj.transform = '$input'
            obj.params.a =
                node.extras.id + ':' + (node.extras.aliasedName || node.name)
            obj.params.a = obj.params.a.split('(')[0]
        } else if (type === 'transform') {
            const transformKey = TRANSFORMS.find((t) => t.name === node.name).id
            obj.transform = transformKey

            let {params} = node.extras

            if (obj.transform === '$addAttribute') {
                // $addAttribute is a special case, inputs are actually parameters
                let attr = []
                for (let i = 1; i < inputs.length; i++) {
                    attr.push(
                        model.nodes.find((n) => n.extras.nodeId === inputs[i])
                            .name
                    )
                }
                obj.params = {a: attr}
            } else if (obj.transform === '$threshold') {
                // $threshold is a special case, two parameters make up one array parameter
                obj.params = {a: [params.a, params.b]}
            } else {
                obj.params = params
            }
        } else if (type === 'output') {
            const output = OUTPUTS.find((o) => o.name === node.name)

            obj.transform = '$output'
            obj.params = {
                a: node.extras.name,
                b: output.route,
                c: parseInt(node.extras.buffer) || 0,
            }
        } else {
            throw Error('invalid node type found when making template.')
        }

        return obj
    })
}
