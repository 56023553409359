import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
} from 'reactstrap'
import autoBind from 'auto-bind'
import Creatable from 'react-select/creatable'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import {functions} from '@accumine/mathjs-extensions'
import FormulaInput from 'SDK/ui/widgets/Schemas/common/FormulaInput'
import modelToDot from 'SDK/ui/widgets/Schemas/SDK/helpers/modelToDot'

import IntlMessages from 'util/IntlMessages'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'
export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.fns = Object.keys(functions)

        this.isSubModelField =
            this.props.editingSubModel._id !== this.props.editingDataModel._id

        this.field = this.props.editingSubModel.fields.find(
            (f) => f._id === this.props.editingFieldId
        )

        this.variables = []
        if (this.field.type === 'ForeignID') {
            const foreignModel = this.props.dataModels.find(
                (a) => a._id === this.field.foreignDataModelId
            )
            this.variables = [
                {
                    id: this.props.editingSubModel._id,
                    name: this.props.editingSubModel.name,
                    fields: modelToDot(
                        this.props.editingSubModel._id,
                        this.props.dataModels,
                        false
                    ),
                    type: 'Record',
                },
                {
                    id: foreignModel._id,
                    name: foreignModel.name,
                    fields: modelToDot(
                        foreignModel._id,
                        this.props.dataModels,
                        false
                    ),
                    type: 'Record',
                },
            ]
        }

        this.state = {
            ready: false,
            nameValue: '',
            descriptionValue: '',
            loading: true,
            uniqueValue: false,
            requiredValue: false,
            fieldTypes: [],
            pickListValues: (this.field.pickListValues || []).map((v) => {
                return {label: v, value: v}
            }),
            kanbanField: false,
            foreignOptionFilter: this.field.foreignOptionFilter || null,
        }
    }

    validate() {
        if (!this.state.nameValid) return false

        return true
    }

    async save() {
        if (!this.validate()) {
            return alert('Validation failed.')
        }
        this.setState({loading: true})
        await this.props.handleUpdateFieldDetails({
            fieldId: this.props.editingFieldId,
            name: this.state.nameValue,
            description: this.state.descriptionValue,
            dataModelId: this.props.editingSubModel._id,
            required: this.state.requiredValue,
            unique: this.state.uniqueValue,
            pickListValues: this.state.pickListValues.map((v) => v.label),
            kanbanField: this.state.kanbanField,
            foreignOptionFilter:
                this.state.foreignOptionFilter &&
                this.state.foreignOptionFilter.plainText !== ''
                    ? this.state.foreignOptionFilter
                    : null,
        })
        this.setState({loading: false})
        this.props.toggle()
    }

    async componentDidMount() {
        if (this.field.type === 'PickList') {
            const kanbanModel = this.props.dataModels.find(
                (model) => model.name === 'System/Kanban Settings'
            )
            let existing = await API.post(
                `data-models/${kanbanModel._id}/paginate`,
                {
                    filter: [
                        {
                            path: 'model_id',
                            type: 'Text',
                            value: [this.props.editingDataModel._id],
                            logic: 'is',
                        },
                        {
                            path: 'status_field_path',
                            type: 'Text',
                            value: [this.field.name],
                            logic: 'is',
                        },
                    ],
                    limit: 1,
                },
                2
            )
            if (existing.count > 0) {
                this.setState({kanbanField: true})
            }
        }

        const f = await API.get('data-models/field-types', 2)
        this.field.canBeUnique = f.find(
            (t) => t.type === this.field.type
        ).unique
        this.setState({ready: true, loading: false})
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                {this.state.ready ? (
                    <Modal isOpen={open} toggle={toggle}>
                        <ModalHeader toggle={toggle}>
                            Edit data model details
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <TextInput
                                        validator={(v) => {
                                            return !(
                                                /\s/.test(v) ||
                                                /[ `!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/.test(
                                                    v
                                                ) ||
                                                v === '' ||
                                                v === 'deleted' ||
                                                v === '_id' ||
                                                v === 'dataModelId'
                                            )
                                        }}
                                        onValueChange={(nameValue) =>
                                            this.setState({nameValue})
                                        }
                                        onValidChange={(nameValid) =>
                                            this.setState({nameValid})
                                        }
                                        fieldName="Field Name"
                                        existing={this.props.editingSubModel.fields
                                            .filter(
                                                (field) =>
                                                    field._id !==
                                                    this.props.editingFieldId
                                            )
                                            .map((d) => d.name)}
                                        initial={this.field.name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextInput
                                        onValueChange={(descriptionValue) =>
                                            this.setState({descriptionValue})
                                        }
                                        onValidChange={() => {}}
                                        fieldName="Field Description"
                                        existing={[]}
                                        initial={this.field.description}
                                    />
                                </Col>
                            </Row>

                            {this.field.type !== 'SingleSubModel' &&
                            this.field.type !== 'MultiSubModel' ? (
                                <Row>
                                    <Col>
                                        <SelectInput
                                            onChange={(requiredValue) =>
                                                this.setState({requiredValue})
                                            }
                                            fieldName="Required"
                                            default={
                                                !this.field.required
                                                    ? {
                                                          label: 'This is not a required field',
                                                          value: false,
                                                      }
                                                    : {
                                                          label: 'This is a required field',
                                                          value: true,
                                                      }
                                            }
                                            initial={
                                                !this.field.required
                                                    ? {
                                                          label: 'This is not a required field',
                                                          value: false,
                                                      }
                                                    : {
                                                          label: 'This is a required field',
                                                          value: true,
                                                      }
                                            }
                                            options={[
                                                {
                                                    label: 'This is a required field',
                                                    value: true,
                                                },
                                                {
                                                    label: 'This is not a required field',
                                                    value: false,
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            ) : null}

                            {this.field.type === 'SingleSubModel' ||
                            this.field.type === 'MultiSubModel' ? (
                                <p>
                                    Note: sub-models cannot be directly
                                    required, only non sub-model fields can be
                                    toggled as a required field.
                                </p>
                            ) : null}

                            {this.field.type === 'DesignatedTimestamp' ||
                            this.field.type === 'DesignatedTimeStart' ||
                            this.field.type === 'DesignatedTimeEnd' ? (
                                <p>
                                    Note: it is recommended to set designated
                                    time fields as required fields. However,
                                    there are cases where not doing so makes
                                    sense. For example - a production schedule
                                    data model with unknown start and end dates
                                    for each job.
                                </p>
                            ) : null}

                            {this.field.canBeUnique ? (
                                <Row>
                                    <Col>
                                        <SelectInput
                                            onChange={(uniqueValue) =>
                                                this.setState({uniqueValue})
                                            }
                                            fieldName="Uniqueness"
                                            default={
                                                !this.field.unique
                                                    ? {
                                                          label: 'This is not a unique field',
                                                          value: false,
                                                      }
                                                    : {
                                                          label: 'This is a unique field',
                                                          value: true,
                                                      }
                                            }
                                            initial={
                                                !this.field.unique
                                                    ? {
                                                          label: 'This is not a unique field',
                                                          value: false,
                                                      }
                                                    : {
                                                          label: 'This is a unique field',
                                                          value: true,
                                                      }
                                            }
                                            options={[
                                                {
                                                    label: 'This is a unique field',
                                                    value: true,
                                                },
                                                {
                                                    label: 'This is not a unique field',
                                                    value: false,
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            ) : null}

                            {this.field.type === 'PickList' ? (
                                <Row>
                                    <Col>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Creatable
                                                    components={{
                                                        Input: CustomSelectInput,
                                                    }}
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    isMulti
                                                    name="pickListValues"
                                                    value={
                                                        this.state
                                                            .pickListValues
                                                    }
                                                    onChange={(
                                                        pickListValues
                                                    ) =>
                                                        this.setState({
                                                            pickListValues,
                                                        })
                                                    }
                                                    options={[]}
                                                    placeholder="Type & press enter to add value..."
                                                />
                                                <IntlMessages id="Pick List Values" />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>
                            ) : null}

                            {this.field.type === 'PickList' &&
                            !this.isSubModelField ? (
                                <Row>
                                    <Col>
                                        <Label>
                                            <Switch
                                                checked={this.state.kanbanField}
                                                onChange={(kanbanField) =>
                                                    this.setState({kanbanField})
                                                }
                                            />{' '}
                                            Kanban Status Field (1 allowed per
                                            data model)
                                        </Label>
                                    </Col>
                                </Row>
                            ) : null}

                            {this.field.type === 'ForeignID' ? (
                                <FormulaInput
                                    variables={this.variables}
                                    formulas={this.fns}
                                    default={this.field.foreignOptionFilter}
                                    onChange={(foreignOptionFilter) =>
                                        this.setState({foreignOptionFilter})
                                    }
                                    type="formula"
                                />
                            ) : null}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={this.save}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
