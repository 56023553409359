import moment from 'moment'

export const currentShift = (shift) => {
    let ts = moment().startOf('minute'),
        dow = ts.isoWeekday()

    if (dow === 7) {
        dow = 0
    }

    if (!shift.days.includes(dow)) {
        return false
    }

    let response = false
    const actualMins = ts.hour() * 60 + ts.minutes(),
        shiftStartMins = shift.timeStart.hour * 60 + shift.timeStart.minute,
        shiftEndMins = shift.timeEnd.hour * 60 + shift.timeEnd.minute

    if (shift.timeStart.hour >= shift.timeEnd.hour) {
        // overnight
        if (actualMins >= shiftStartMins) {
            // first part
            response = {
                shift: shift.name,
                range: [
                    moment(ts).set({
                        hour: shift.timeStart.hour,
                        minute: shift.timeStart.minute,
                    }),
                    moment(ts).add(1, 'days').set({
                        hour: shift.timeEnd.hour,
                        minute: shift.timeEnd.minute,
                    }),
                ],
            }
        } else if (actualMins <= shiftEndMins) {
            // second part
            response = {
                shift: shift.name,
                range: [
                    moment(ts).add(-1, 'days').set({
                        hour: shift.timeStart.hour,
                        minute: shift.timeStart.minute,
                    }),
                    moment(ts).set({
                        hour: shift.timeEnd.hour,
                        minute: shift.timeEnd.minute,
                    }),
                ],
            }
        }
    } else {
        // single day shift
        response = {
            shift: shift.name,
            range: [
                moment(ts).set({
                    hour: shift.timeStart.hour,
                    minute: shift.timeStart.minute,
                }),
                moment(ts).set({
                    hour: shift.timeEnd.hour,
                    minute: shift.timeEnd.minute,
                }),
            ],
        }
    }
    return response
}

export const previousShift = (shifts) => {
    let shiftSchedule = [],
        ts = moment().startOf('day'),
        sevenDaysAgo = moment(ts).add(-7, 'days')

    for (let m = moment(sevenDaysAgo); m.isSameOrBefore(ts); m.add(1, 'days')) {
        for (let shift of shifts) {
            shiftSchedule.push(historicalShift(m, shift))
        }
    }
    shiftSchedule = shiftSchedule
        .filter((s) => {
            return s && moment().isAfter(s.range[1])
        })
        .sort((a, b) => {
            return a.range[1].valueOf() - b.range[1].valueOf()
        })

    if (shiftSchedule.length === 0) {
        return false
    } else {
        return shiftSchedule[shiftSchedule.length - 1]
    }
}

export const historicalShift = (date, shift) => {
    let ts = moment(date).startOf('day'),
        dow = ts.isoWeekday()

    if (dow === 7) {
        dow = 0
    }

    if (!shift.days.includes(dow)) {
        return false
    }
    if (shift.timeStart.hour >= shift.timeEnd.hour) {
        // overnight
        return {
            shift: shift.name,
            range: [
                moment(ts).set({
                    hour: shift.timeStart.hour,
                    minute: shift.timeStart.minute,
                }),
                moment(ts).add(1, 'days').set({
                    hour: shift.timeEnd.hour,
                    minute: shift.timeEnd.minute,
                }),
            ],
        }
    } else {
        // single day shift
        return {
            shift: shift.name,
            range: [
                moment(ts).set({
                    hour: shift.timeStart.hour,
                    minute: shift.timeStart.minute,
                }),
                moment(ts).set({
                    hour: shift.timeEnd.hour,
                    minute: shift.timeEnd.minute,
                }),
            ],
        }
    }
}
