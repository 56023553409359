import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'
import ChartMaker from './chartMaker'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {tileColor} from 'SDK/helpers'

import Timer from './Timer'
import * as SDK from './sdk'
import HMIEntry from '../TVDisplay/HMIEntry'
import TimelineReport from './TimelineChart'

import './tile.css'

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
        aggregation: PropTypes.number,
        flash: PropTypes.bool,
        black: PropTypes.bool,
        device: PropTypes.object,
        HMIEntryEnabled: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.color = '#fff'
        this.cardBodyColor = '#444'

        if (this.props && this.props.black) {
            this.color = '#000'
        }

        this.state = {
            name: '',
            activeState: {
                color: 'rgb(35, 34, 35)',
                name: 'No data',
                timestamp: null,
                elapsed: '0:00',
            },
            activeShift: {
                name: undefined,
                timeStart: moment().startOf('day'),
                timeEnd: moment(),
            },
            shifts: [],
            flash: false,
            ranOnce: false,
            timelineDateIndex: 0,
            timelineShiftIndex: 0,
            timelineTimeStart: moment().startOf('day'),
            timelineTimeEnd: null,
            timelineShiftName: undefined,
            yield: '93%/90% Yield',
            partNumber: 'YAV28NPT',
            operator: 'Joe Puffer',
            barChartData: {dates: [], devices: []},
            barChartElement: <></>,
            availabilityData: {},
            availability: 0,
            quality: 0,
            performance: 0,
            totalParts: 0,
            totalRejects: 0,
            shiftSort: true,
            mostRecentEntry: null,
            scheduledTargets: [],
            oeeModalOpen: false,
            oee: {
                capacity: 0,
                production: 0,
                availability: 0,
                quality: 0,
                performance: 0,
                totalParts: 0,
                totalRejects: 0,
                scheduledTargets: 0,
                goodParts: 0,
            },
        }
    }

    async getLiveStatus() {
        let {activeState, activeShift} = await SDK.getAssetStatus(
            this.props.deviceId,
            this.state.shifts /*this.props.threshold*/
        )

        if (
            activeState.name === 'Downtime' &&
            moment().diff(activeState.timestamp, 'minutes') > 4
        ) {
            const downtimeReason = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Downtime Reason',
                        timeEnd: null,
                    },
                },
                2
            )

            if (downtimeReason.length) {
                activeState.name = downtimeReason[0].value
                activeState.color = tileColor(activeState.name)
            }
        }

        if (
            activeShift.name !== this.state.activeShift.name &&
            this.state.timelineDateIndex === 0
        ) {
            this.setState({activeState, activeShift}, this.currentShift)
        } else {
            this.setState({activeState, activeShift})
        }
    }

    getHoursArray(timeStart, timeEnd) {
        var hoursArray = [moment(timeStart).toISOString()],
            endMoment = moment(timeEnd),
            currentMoment = moment(timeStart)
        while (currentMoment < endMoment) {
            hoursArray.push(currentMoment.add(1, 'hour').toISOString())
        }
        return hoursArray
    }

    async getScheduledTarget(timeStart, timeEnd) {
        const hours = this.getHoursArray(timeStart, timeEnd)
        const {count, result} = await this.getMostRecentEntry(10, 'descending')
        const targets = []
        if (count) {
            hours.forEach((hour, i) => {
                const t = result.results.filter((row) => row.timestamp < hour)
                if (t.length > 0) {
                    for (let tar of t) {
                        if (tar.partNumber.hourlyTarget) {
                            targets.push(tar.partNumber.hourlyTarget)
                            break
                        }
                    }
                } else {
                    targets.push(0)
                }
            })
        }
        return targets
    }

    updateDowntimeFlashStatus() {
        if (this.state.activeState.timestamp === null) return
        let activeState = {...this.state.activeState}
        let flash = false

        if (
            activeState.name !== 'In-Cycle' &&
            activeState.name !== 'Disconnected' &&
            moment().diff(moment(activeState.timestamp), 'minutes') >=
                (this.props.threshold / 60 || 5)
        ) {
            flash = true
        }

        this.setState({
            flash,
            backgroundColor:
                flash &&
                this.state.backgroundColor === this.state.activeState.color
                    ? 'black'
                    : activeState.color,
        })
    }

    fetchDeviceName() {
        this.setState({
            name: this.props.device.name,
        })
    }

    async fetchShiftSchedule() {
        this.setState({
            shifts: await SDK.getShiftSchedule(this.props.deviceId),
        })
    }

    currentShift() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            activeShift,
            shifts,
            timelineShiftName,
        } = this.state
        timelineDateIndex = 0
        timelineTimeStart = activeShift.timeStart
        timelineTimeEnd = null
        timelineShiftIndex = shifts.findIndex(
            (shift) => shift.name === activeShift.name
        )
        timelineShiftName = activeShift.name

        this.setState(
            {
                shiftSort: true,
                timelineDateIndex,
                timelineShiftIndex,
                timelineTimeStart,
                timelineTimeEnd,
                timelineShiftName,
            },
            () => {
                this.calculateOEE()
                this.timelineReportElement()
            }
        )
    }

    currentDay() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            activeShift,
            shifts,
            timelineShiftName,
        } = this.state
        timelineDateIndex = 0
        timelineTimeStart = moment().startOf('day')
        timelineTimeEnd = null
        timelineShiftIndex = null
        timelineShiftName = null

        this.setState(
            {
                shiftSort: false,
                timelineDateIndex,
                timelineShiftIndex,
                timelineTimeStart,
                timelineTimeEnd,
                timelineShiftName,
            },
            () => {
                this.calculateOEE()
                this.timelineReportElement()
            }
        )
    }

    backward() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            shifts,
            timelineShiftName,
        } = this.state

        if (!this.state.shiftSort || !timelineShiftName) {
            let shiftTimerange = [
                moment(timelineTimeStart).subtract(1, 'day').startOf('day'),
                moment(timelineTimeStart).subtract(1, 'day').endOf('day'),
            ]

            this.setState(
                {
                    timelineDateIndex,
                    timelineShiftIndex,
                    timelineTimeStart: shiftTimerange[0],
                    timelineTimeEnd: shiftTimerange[1],
                    timelineShiftName: null,
                },
                () => {
                    this.calculateOEE()
                    this.timelineReportElement()
                }
            )
        } else {
            if (timelineShiftIndex === 0) {
                timelineDateIndex--
                timelineShiftIndex = shifts.length - 1
            } else {
                timelineShiftIndex--
            }

            let shift = shifts[timelineShiftIndex],
                date = moment().add(timelineDateIndex, 'days'),
                shiftTimerange = SDK.getShiftTimerange(date, shift)

            this.setState(
                {
                    timelineDateIndex,
                    timelineShiftIndex,
                    timelineTimeStart: shiftTimerange[0],
                    timelineTimeEnd: shiftTimerange[1],
                    timelineShiftName: shift.name,
                },
                () => {
                    this.calculateOEE()
                    this.timelineReportElement()
                }
            )
        }
    }

    forward() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            shifts,
            timelineShiftName,
        } = this.state

        if (!this.state.shiftSort || timelineShiftName) {
            let shiftTimerange = [
                moment(timelineTimeStart).add(1, 'day').startOf('day'),
                moment(timelineTimeStart).add(1, 'day').endOf('day'),
            ]

            this.setState(
                {
                    timelineDateIndex,
                    timelineShiftIndex,
                    timelineTimeStart: shiftTimerange[0],
                    timelineTimeEnd: shiftTimerange[1].isAfter(moment())
                        ? null
                        : shiftTimerange[1],
                    timelineShiftName: null,
                },
                () => {
                    this.calculateOEE()
                    this.timelineReportElement()
                }
            )
        } else {
            if (timelineShiftIndex === shifts.length - 1) {
                timelineDateIndex++
                timelineShiftIndex = 0
            } else {
                timelineShiftIndex++
            }

            let shift = shifts[timelineShiftIndex],
                date = moment().add(timelineDateIndex, 'days'),
                shiftTimerange = SDK.getShiftTimerange(date, shift)

            this.setState(
                {
                    timelineDateIndex,
                    timelineShiftIndex,
                    timelineTimeStart: shiftTimerange[0],
                    timelineTimeEnd: shiftTimerange[1].isAfter(moment())
                        ? null
                        : shiftTimerange[1],
                    timelineShiftName: shift.name,
                },
                () => {
                    this.calculateOEE()
                    this.timelineReportElement()
                }
            )
        }
    }

    updateUtilization(util) {
        let utilization = Math.floor(util * 100)
        this.setState({utilization})
    }

    async componentWillMount() {
        let dataModelsList = await API.get('data-models', 2)
        const hmiModel = dataModelsList.find(
            (d) => d.name === 'MES/Machine Entry'
        )
        this.fetchDeviceName()
        await this.fetchShiftSchedule()
        await this.getLiveStatus()
        this.currentShift()
        this.setState({
            ranOnce: true,
            dataModelId: hmiModel._id,
            timelineReportElement: <div></div>,
        })

        //this.subscriber.add(this.getMostRecentEntry, 1000 * 20, 'getMostRecentEntry');
        this.subscriber.add(this.calculateOEE, 1000 * 60, 'calculateOEE')
        this.subscriber.add(
            this.timelineReportElement,
            1000 * 60,
            'timelineReportElement'
        )
        this.subscriber.add(this.getLiveStatus, 1000 * 15, 'getLiveStatus')
        if (this.props.flash)
            this.subscriber.add(
                this.updateDowntimeFlashStatus,
                1000,
                'updateDowntimeFlashStatus'
            )

        await this.getMostRecentEntry()
        this.calculateOEE()
        this.timelineReportElement()
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    async getStates() {
        const states = [
            'Part Count',
            'Reject',
            'Scheduled Target',
            'Earned Hours',
        ]
        let timeEnd = this.state.timelineTimeEnd || moment()
        let {data} = await API.post('historical/aggregate2', {
            timeStart: moment(this.state.timelineTimeStart)
                .startOf('hour')
                .toISOString(),
            timeEnd: this.state.timelineTimeEnd
                ? moment(this.state.timelineTimeEnd).endOf('hour').toISOString()
                : moment().endOf('hour').toISOString(),
            state: states,
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'hour',
            logic: 'count',
        })

        this.setState({barChartElement: <></>})
        let rejects = data.devices[this.props.deviceId]['Reject']
            .map((x) => x.count)
            .reduce((a, b) => a + b, 0)
        let partcounts = data.devices[this.props.deviceId]['Part Count']
            .map((x) => x.count)
            .reduce((a, b) => a + b, 0)
        let earnedhours = data.devices[this.props.deviceId]['Earned Hours']
            .map((x) => x.count)
            .reduce((a, b) => a + b, 0)
        let scheduledtargets = await this.getScheduledTarget(
            this.state.timelineTimeStart,
            this.state.timelineTimeEnd || moment()
        )
        this.setState({
            scheduledTargets: scheduledtargets,
            barChartData: data,
            totalParts: partcounts,
            totalRejects: rejects,
            totalEarnedHours: earnedhours,
            barChartElement: (
                <ChartMaker
                    type="bar"
                    stacked
                    labels={data.dates.map((d) =>
                        moment(d).format('hhA MMMM DD, YYYY')
                    )}
                    targets={scheduledtargets}
                    goodParts={data.devices[this.props.deviceId]['Part Count']}
                    rejects={data.devices[this.props.deviceId]['Reject']}
                />
            ),
        })
    }

    async calculateOEE() {
        await this.getStates()
        const {
            shifts,
            totalParts,
            totalRejects,
            scheduledTargets,
            totalEarnedHours,
        } = this.state
        const availabilityData = await this.getAvailabilityDataShiftSequence(
            shifts.map((shift) => shift.name)
        )

        const capacity = availabilityData
            .map((ad) => ad.capacity[0])
            .reduce((a, b) => a + b, 0)
        const production = availabilityData
            .map((ad) => ad.duration[0])
            .reduce((a, b) => a + b, 0)
        const availability = (production / capacity) * 100 || 0
        const goodParts = totalParts - totalRejects
        const quality = (goodParts / totalParts) * 100 || 0
        const hourlyTarget =
            scheduledTargets.reduce((a, b) => a + b, 0) /
            scheduledTargets.length
        const totalTime = scheduledTargets.length * 60
        const earnedHours =
            totalEarnedHours > 0
                ? totalEarnedHours
                : (goodParts * (60 / hourlyTarget)) / 60
        const performance = (earnedHours / (production / 60)) * 100 || 0
        const percentage =
            capacity > 0
                ? (this.sanitizeNumber(availability) / 100) *
                  (this.sanitizeNumber(quality) / 100) *
                  (this.sanitizeNumber(performance) / 100) *
                  100
                : 0

        this.setState({
            availabilityData,
            availability,
            quality,
            performance,
            oee: {
                hourlyTarget,
                percentage: percentage,
                totalTime,
                goodParts,
                capacity,
                production,
                availability: this.sanitizeNumber(availability),
                quality: this.sanitizeNumber(quality),
                performance: this.sanitizeNumber(performance),
                totalParts,
                totalRejects,
                scheduledTargets: scheduledTargets.reduce((a, b) => a + b, 0),
                earnedHours: earnedHours,
            },
        })
    }

    sanitizeNumber(num) {
        let fl = Math.floor(num)
        let mx = Math.max(0, fl)
        let mn = Math.min(100, mx)
        return mn
    }

    async getAvailabilityDataShiftSequence(shiftListArray = []) {
        const {timelineShiftName, shiftSort} = this.state
        const timeUnit = 'years',
            timeEnd = this.state.timelineTimeEnd || moment(),
            deviceId = this.props.device.deviceId
        let requests = []

        if (shiftSort && timelineShiftName) {
            requests.push(
                API.post('historical', {
                    timeStart: moment(
                        this.state.timelineTimeStart
                    ).toISOString(),
                    timeEnd: moment(timeEnd).toISOString(),
                    deviceId: deviceId,
                    state: 'In-Cycle',
                    unit: timeUnit,
                    shift: timelineShiftName,
                })
            )
        } else {
            shiftListArray.forEach((shift) => {
                requests.push(
                    API.post('historical', {
                        timeStart: moment(
                            this.state.timelineTimeStart
                        ).toISOString(),
                        timeEnd: moment(timeEnd).toISOString(),
                        deviceId: deviceId,
                        state: 'In-Cycle',
                        unit: timeUnit,
                        shift: shift,
                    })
                )
            })
        }

        let data = await Promise.all(requests)
        return data
    }

    async getMostRecentEntry(
        limit = 1,
        orderBy = 'descending',
        dataModelId = this.state.dataModelId,
        deviceId = this.props.device.deviceId
    ) {
        const {result, count} = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {
                    fieldName: 'timestamp',
                    direction: orderBy,
                },
                limit: limit,
                next: null,
                previous: null,
                filter: [
                    {
                        type: 'PrimaryID',
                        logic: 'contains',
                        value: [this.props.device.deviceId],
                        path: 'asset.deviceId',
                    },
                ],
                timezone: localStorage['timezone'],
            },
            2
        )

        if (limit > 1) {
            // Any multi-requests are not going to set state
            return {result, count}
        } else if (count) {
            this.setState({
                mostRecentEntry: result.results[0],
                operator: result.results[0].operator,
                partNumber: result.results[0].partNumber,
            })
        }
    }

    timelineReportElement() {
        if (!this.state.timelineTimeStart || !this.state.timelineTimeEnd) {
            ;<TimelineReport
                day={moment().startOf('day')}
                startTime={moment().startOf('day')}
                endTime={moment().endOf('day')}
                device={this.props.device}
            />
        }
        this.setState({
            timelineReportElement: (
                <TimelineReport
                    day={this.state.timelineTimeStart}
                    startTime={this.state.timelineTimeStart}
                    endTime={this.state.timelineTimeEnd || moment()}
                    device={this.props.device}
                />
            ),
        })
    }

    toggle() {
        this.setState({oeeModalOpen: !this.state.oeeModalOpen})
    }

    render() {
        const disableForward = !this.state.timelineTimeEnd
        const {timelineReportElement} = this.state

        return (
            <Col xs="12" sm="6" md="4" className="mb-4">
                <Card
                    style={{
                        background: this.state.flash
                            ? this.state.backgroundColor
                            : this.state.activeState.color,
                    }}>
                    <CardHeader
                        style={{
                            color: this.color,
                            background: 'rgba(0,0,0,0.1)',
                            marginTop: '0px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}>
                        <h3 style={{textAlign: 'center', margin: '0'}}>
                            <strong>{this.state.name}</strong>
                        </h3>
                        <Row>
                            <Col xs="12" sm="12" className="text-center">
                                <h1
                                    style={{
                                        color: this.color,
                                        margin: '0',
                                        padding: '0',
                                    }}>
                                    <strong>
                                        <Timer
                                            timestamp={
                                                this.state.activeState.timestamp
                                            }
                                        />
                                    </strong>
                                </h1>
                                <p
                                    style={{
                                        color: this.color,
                                        textAlign: 'center',
                                    }}>
                                    <strong>
                                        {this.state.activeState.name}
                                    </strong>
                                </p>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody
                        style={{
                            paddingTop: '10px',
                            margin: '0px',
                            backgroundColor: '#FFF',
                            color: '#444',
                        }}>
                        <Row>
                            <Col xs="6">
                                <p
                                    style={{
                                        color: this.cardBodyColor,
                                        textAlign: 'center',
                                    }}>
                                    <strong>
                                        {this.state.operator.username}
                                    </strong>
                                </p>
                            </Col>
                            <Col xs="6" className="text-center">
                                <p
                                    style={{
                                        color: this.cardBodyColor,
                                        textAlign: 'center',
                                    }}>
                                    <strong>
                                        {this.state.partNumber.partNumber} (
                                        {this.state.partNumber.hourlyTarget})
                                    </strong>
                                </p>
                                {/*<UtilizationDisplayDonut device={this.props.device} timerange={[this.state.timelineTimeStart, this.state.timelineTimeEnd]} utilization={50}/>*/}
                            </Col>
                        </Row>
                        <Row style={{cursor: 'pointer'}} onClick={this.toggle}>
                            <Col xs="4">
                                <div
                                    className="text-center"
                                    style={{
                                        marginTop: 10,
                                        color: this.cardBodyColor,
                                    }}>
                                    <div>Availability</div>
                                    <h1>{this.state.oee.availability}%</h1>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div
                                    className="text-center"
                                    style={{
                                        marginTop: 10,
                                        color: this.cardBodyColor,
                                    }}>
                                    <div>Quality</div>
                                    <h1>{this.state.oee.quality}%</h1>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div
                                    className="text-center"
                                    style={{
                                        marginTop: 10,
                                        color: this.cardBodyColor,
                                    }}>
                                    <div>Performance</div>
                                    <h1>{this.state.oee.performance}%</h1>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.barChartData.devices[
                                this.props.deviceId
                            ]
                                ? this.state.barChartElement
                                : null}
                        </Row>
                        {this.state.ranOnce ? (
                            <Fragment>
                                {/* <Timeline
                    deviceId={this.props.deviceId}
                    showReasons={false}
                    timeStart={this.state.timelineTimeStart}
                    timeEnd={this.state.timelineTimeEnd}
                    shiftName={this.state.timelineShiftName}
                    updateUtilization={this.updateUtilization}
                    aggregation={this.props.aggregation}
                    color={this.color}
                  /> */}

                                {this.state.timelineReportElement
                                    ? this.state.timelineReportElement
                                    : null}
                                <Row>
                                    <Col xs="12" className="text-center">
                                        <strong>
                                            <p
                                                style={{
                                                    color: this.cardBodyColor,
                                                    margin: '0',
                                                    fontSize: 10,
                                                }}>
                                                {this.state.timelineShiftName}{' '}
                                                {moment(
                                                    this.state.timelineTimeStart
                                                ).format('MMMM DD, YYYY')}
                                            </p>
                                        </strong>
                                    </Col>
                                    <Col xs="12" className="text-center">
                                        <ButtonGroup>
                                            <Button
                                                size="xs"
                                                color="primary"
                                                disabled={this.state.loading}
                                                onClick={this.backward}>
                                                <i className="simple-icon-arrow-left" />
                                            </Button>
                                            <Button
                                                size="xs"
                                                color="primary"
                                                disabled={this.state.loading}
                                                onClick={this.currentShift}>
                                                Current Shift
                                            </Button>
                                            <Button
                                                size="xs"
                                                color="primary"
                                                disabled={this.state.loading}
                                                onClick={this.currentDay}>
                                                Current Day
                                            </Button>
                                            <Button
                                                size="xs"
                                                color="primary"
                                                disabled={
                                                    disableForward ||
                                                    this.state.loading
                                                }
                                                onClick={this.forward}>
                                                <i className="simple-icon-arrow-right" />
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : null}
                    </CardBody>
                    <CardFooter>
                        {this.props.HMIEntryEnabled ? (
                            <HMIEntry
                                device={this.props.device}
                                onComplete={() => {
                                    this.getMostRecentEntry()
                                    this.calculateOEE()
                                    this.timelineReportElement()
                                }}
                            />
                        ) : null}
                    </CardFooter>
                </Card>
                <Modal isOpen={this.state.oeeModalOpen} toggle={this.toggle}>
                    <ModalHeader>OEE for Time Period</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <strong>
                                    Availability ({this.state.oee.availability}
                                    %)
                                </strong>
                                <p>
                                    {this.state.oee.production} minutes run time
                                    / {this.state.oee.capacity} minutes
                                    operating time
                                </p>
                            </Col>
                            <Col xs="12">
                                <strong>
                                    Quality ({this.state.oee.quality}%)
                                </strong>
                                <p>
                                    {this.state.oee.goodParts} good parts /{' '}
                                    {this.state.oee.totalParts} total parts
                                </p>
                            </Col>
                            <Col xs="12">
                                <strong>
                                    Performance ({this.state.oee.performance}%)
                                </strong>
                                <p>
                                    {this.state.oee.earnedHours} earned hours /{' '}
                                    {this.state.oee.production / 60} runtime
                                </p>
                            </Col>
                            <Col xs="12" className="text-center">
                                <h2>OEE</h2>
                                <h1>{this.state.oee.percentage}%</h1>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Col>
        )
    }
}
