import React, {Component} from 'react'
import {CustomInput, FormGroup, Button} from 'reactstrap'
import readXlsxFile from 'read-excel-file'
import ReactJson from 'react-json-view'
import autoBind from 'auto-bind'
import * as API from 'SDK/api'

export default class FileUpload extends Component {
    state = {
        fileName: '',
        invalidFile: true,
        invalidData: true,
        rows: [],
    }

    constructor(props) {
        super(props)
        autoBind(this)
    }

    handleFileChange = this.handleFileChange.bind(this)
    handleFileChange({target: {files}}) {
        const cancel = !files.length
        if (cancel) return

        const [{size, name}] = files
        const maxSize = 50000

        if (size < maxSize) {
            this.setState({fileName: name, invalidFile: false})
            this.onUpload(files, false)
        } else {
            this.setState({fileName: '', invalidFile: true})
            this.onUpload(files, true)
        }
    }

    onUpload(files, invalid) {
        if (!invalid && this.props.onUpload) this.props.onUpload(files)
        if (!invalid && files) this.readFile(files)
    }

    readFile(files) {
        readXlsxFile(files[0]).then((rows) => {
            // `rows` is an array of rows
            // each row being an array of cells.
            let invalidData = rows.length < 2
            this.setState({invalidData, rows})
        })
    }

    async uploadSchemaRows() {
        let colIndexes = []
        let rowData = []
        let axiosRequests = []
        this.props.dataModel.fields.forEach(
            (f) => (colIndexes[f.name] = this.state.rows[0].indexOf(f.name))
        )

        if (!this.errorsFound(colIndexes)) {
            this.state.rows.forEach((row, rowindex) => {
                let newRow = {...colIndexes}
                if (rowindex !== 0) {
                    for (const [colname, colindex] of Object.entries(
                        colIndexes
                    )) {
                        newRow[colname] = ''
                        if (colindex > -1) {
                            newRow[colname] = row[colindex]
                        }
                    }
                    rowData.push(newRow)
                }
            })

            if (rowData.length > 0) {
                rowData.forEach((row) => {
                    axiosRequests.push(
                        API.post(
                            `data-models/${this.props.dataModel._id}/add-record`,
                            row,
                            2
                        )
                    )
                })

                let result = await Promise.all(axiosRequests)
                alert(`${result.length} rows saved successfully.`)
                if (result.length > 0) this.props.close()
            }
        } else {
            alert('Since errors were found, the upload has failed.')
        }
    }

    errorsFound(colIndexes) {
        if (Object.values(colIndexes).filter((x) => x === -1).length > 0) {
            alert(
                `Warning! There are ${
                    Object.values(colIndexes).filter((x) => x === -1).length
                } missing columns.`
            )
            return true
        } else {
            return false
        }
    }

    render() {
        const {fileName, invalidFile} = this.state
        return (
            <>
                <FormGroup>
                    <CustomInput
                        type="file"
                        id="exampleCustomFileBrowser"
                        name="customFile"
                        label={fileName || 'choose an excel file'}
                        onChange={this.handleFileChange}
                        invalid={invalidFile}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                </FormGroup>

                <div
                    style={{
                        overflowY: 'scroll',
                        height: 200,
                        marginTop: 25,
                        marginBottom: 25,
                        background: '#eee',
                    }}>
                    <ReactJson src={this.state.rows} name={false} />
                </div>

                <Button
                    color="primary"
                    disabled={this.state.invalidData || this.state.invalidFile}
                    onClick={this.uploadSchemaRows}>
                    Upload Rows
                </Button>
            </>
        )
    }
}
