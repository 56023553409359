import React, {Component, Fragment} from 'react'
import {Row, Card, CardHeader, CardBody, Col} from 'reactstrap'
import {Bar} from 'react-chartjs-2'
import {ThemeColors} from 'util/ThemeColors'
const colors = ThemeColors()

const percentYAxisTicks = {
    beginAtZero: true,
    max: 100,
    stepSize: 25,
    padding: 20,
    callback: function (value) {
        return value + '%'
    },
}
const hoursYAxisTicks = {
    beginAtZero: 0,
    padding: 20,
    callback: function (value) {
        return value + 'hrs'
    },
}

const chartConfig = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
        display: true,
    },
    layout: {
        padding: {
            bottom: 15,
        },
    },
    scales: {
        yAxes: [
            {
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: 'rgba(0,0,0,0.1)',
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                gridLines: {
                    display: false,
                },
                ticks: {},
            },
        ],
    },
}

export default class extends Component {
    render() {
        let data = this.props.data.chartdata
        data.datasets[0].borderColor = colors.themeColor1
        data.datasets[0].backgroundColor = '#145388'
        return (
            <Row>
                <Col xs="12" className="mb-4">
                    <Card>
                        <CardHeader>
                            {this.props.data.chartdata.shift}
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <Bar data={data} options={chartConfig} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
