/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
    /* 01.General */
    'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

    /* 02.User Login, Logout, Register */
    'user.login-title': 'Login',
    'user.register': 'Register',
    'user.forgot-password': 'Forgot Password',
    'user.username': 'Username',
    'user.factoryId': 'Factory ID',
    'user.email': 'E-mail',
    'user.password': 'Password',
    'user.forgot-password-question': 'Forget password?',
    'user.fullname': 'Full Name',
    'user.login-button': 'LOGIN',
    'user.register-button': 'REGISTER',
    'user.reset-password-button': 'RESET',

    /* 03.Menu */
    'menu.live': 'Live',
    'menu.utilization': 'Utilization',
    'menu.utilization-live': 'Live',
    'menu.utilization-trends': 'Trends',
    'menu.utilization-settings': 'Settings',
    'menu.downtime': 'Downtime',
    'menu.app': 'Home',
    'menu.gogo': 'Gogo',
    'menu.start': 'Start',
    'menu.second-menu': 'Second Menu',
    'menu.second': 'Second',
    'menu.ui': 'UI',
    'menu.charts': 'Charts',
    'menu.chat': 'Chat',
    'menu.survey': 'Survey',
    'menu.todo': 'Todo',
    'menu.search': 'Search',

    /* 04.Error Page */
    'layouts.error-title': 'Ooops... looks like an error occurred!',
    'layouts.error-code': 'Error code',
    'layouts.go-back-home': 'GO BACK HOME',

    /* Utilization */
    'utilization.settings-timerange-select': 'Timerange',

    'digital-twin.gateways.mac': 'Mac Address',

    Description: 'Description',
    Name: 'Name',
}
