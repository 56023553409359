import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Badge} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import SideModal from './sideModal'
import ConfigurationModal from './ConfigurationModal'

const determineStatus = (record) => {
    if (moment().diff(moment(record.lastSync), 'minutes') > 0) {
        //offline
        return <Badge color="danger">Offline</Badge>
    } else {
        //online
        switch (record.command.value) {
            case 'download_model':
                return <Badge color="primary">Downloading model</Badge>
            case 'predict':
                return <Badge color="primary">Activating</Badge>
            case 'train':
                return <Badge color="primary">Training</Badge>
            case '':
                return <Badge color="success">Online</Badge>
        }
    }
}

const columns = [
    {
        Header: 'Asset',
        accessor: 'assetName',
    },
    {
        Header: 'Serial Number',
        accessor: 'serialNumber',
    },
    {
        Header: 'Model',
        accessor: 'modelPretty',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
    {
        Header: 'Last Known Location',
        accessor: 'lastKnownLocationString',
    },
    {
        Header: 'Last Movement',
        accessor: 'movingString',
    },
]

export default class IPSFieldDeviceManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'IPSFieldDeviceManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            assets: [],
            models: [],
            editingDevice: null,
            addDeviceModal: false,
            configurationModal: false,
        }
    }
    async fetchAssets() {
        let assets = await API.get('devices')
        this.setState({assets})
    }
    async fetchDevices() {
        let devices = await API.get('ips/cloud/devices', 2)
        this.setState({devices})
    }

    async fetchModels() {
        let models = await API.get('ips/cloud/models', 2)
        this.setState({models})
    }

    addDevice() {
        this.setState({
            editingDevice: null,
            sideModal: !this.state.sideModal,
        })
    }
    toggleSideModal() {
        this.setState({
            sideModal: !this.state.sideModal,
        })
    }
    toggleModal(modalName, device) {
        let state = this.state
        state.editingDevice = device
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    toggleConfigurationModal() {
        this.setState({
            configurationModal: !this.state.configurationModal,
        })
    }

    dataTableFormatter(device) {
        device.modelPretty =
            device.model === ''
                ? 'No model assigned'
                : 'Version ' + device.model

        device.lastKnownLocationString = 'Not Available'

        if (device.model && device.lastKnownLocation) {
            const model = this.state.models.find(
                (m) => m.version === device.model
            )
            if (model) {
                const poi = model.pois.find(
                    (p) => p.id === device.lastKnownLocation.id
                )
                if (poi) {
                    device.lastKnownLocationString =
                        poi.name +
                        ' (' +
                        moment(device.lastKnownLocation.timestamp).format(
                            'MMM Do, h:mma'
                        ) +
                        ')'
                }
            }
        }

        device.movingString = 'Not Available'

        if (device.lastMovement) {
            device.movingString = moment(device.lastMovement.timestamp).format(
                'MMM Do, h:mma'
            )
        }

        device.status = determineStatus(device)

        device.assetName =
            this.state.assets &&
            this.state.assets.find((a) => a.deviceId === device.deviceId)
                ? this.state.assets.find((a) => a.deviceId === device.deviceId)
                      .name
                : ''
        return device
    }

    componentWillMount() {
        this.fetchAssets()
        this.subscriber.add(this.fetchDevices, 1000, 'fetchDevices')
        this.subscriber.add(this.fetchModels, 1000 * 10, 'fetchModels')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstDeviceEl = (
                <Button color="primary" onClick={this.addDevice}>
                    Add Your First Field Device
                </Button>
            ),
            tableExists = this.state.devices.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col
                            xs="12"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Button color="primary" onClick={this.addDevice}>
                                <i className="simple-icon-plus" /> Add Field
                                Device
                            </Button>
                            <Button
                                color="primary"
                                className="ml-2"
                                onClick={this.toggleConfigurationModal}>
                                <i className="simple-icon-settings" /> Settings
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="serialNumber"
                    emptyCrudMainText="No Field Devices Found"
                    emptyCrudSubText={addFirstDeviceEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.devices}
                    rowClickFn={(device) => {
                        this.setState({editingDevice: device})
                        this.toggleSideModal(device)
                    }}
                />
                {/*
        {this.state.addDeviceModal ?
          <AddDeviceModal
            toggleModal={this.toggleAddDeviceModal}
            modal={this.state.addDeviceModal}
            devices={this.state.devices}
          />
          : null}
        {this.state.removeModal ?
          <RemoveModal
            toggleModal={this.toggleModal}
            modal={this.state.removeModal}
            nodes={this.state.nodes}
            editingNode={this.state.editingNode}
          />
          : null}
        {this.state.actionsModal ?
          <ActionsModal
            toggleModal={this.toggleModal}
            modal={this.state.actionsModal}
            nodes={this.state.nodes}
            editingNode={this.state.editingNode}
          />
          : null}
        */}
                {this.state.sideModal ? (
                    <SideModal
                        toggleModal={this.toggleSideModal}
                        modal={this.state.sideModal}
                        devices={this.state.devices}
                        editingDevice={this.state.editingDevice}
                        models={this.state.models}
                        assets={this.state.assets}
                    />
                ) : null}

                {this.state.configurationModal ? (
                    <ConfigurationModal
                        toggleModal={this.toggleConfigurationModal}
                        modal={this.state.configurationModal}
                    />
                ) : null}
            </Fragment>
        )
    }
}
