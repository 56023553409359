import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

class AttributeSelect extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            value: this.props.options,
            loading: true,
        }
    }
    render() {
        const {name, options, cb} = this.props
        return (
            <Row>
                <Col xs="12" className="mb-4">
                    <label>{this.props.name}:</label>
                    <Select
                        components={{Input: CustomSelectInput}}
                        className="react-select"
                        classNamePrefix="react-select"
                        isMulti
                        name={name}
                        value={this.state.value}
                        onChange={(e) => {
                            this.setState({value: e})
                            cb(name, e)
                        }}
                        options={options}
                    />
                </Col>
            </Row>
        )
    }
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const selectedAssets = this.props.values.assets
            ? this.props.values.assets.map((x) => {
                  const name = this.props.devices.find(
                      (y) => y.deviceId === x
                  ).name
                  return {label: name, value: x}
              })
            : this.props.devices.map((x) => {
                  return {label: x.name, value: x.deviceId}
              })

        let selectedShifts = this.props.values.shifts
            ? [...new Set(this.props.values.shifts)].map((x) => {
                  return {label: x, value: x}
              })
            : this.props.shifts.map((x) => {
                  return {label: x.name, value: x.name}
              })

        selectedShifts = selectedShifts.filter(
            (thing, index, self) =>
                index === self.findIndex((t) => t.value === thing.value)
        )

        let availableShifts = this.props.shifts.map((a, i) => {
            return {label: a.name, value: a.name, key: i}
        })

        availableShifts = availableShifts.filter(
            (thing, index, self) =>
                index === self.findIndex((t) => t.value === thing.value)
        )

        this.state = {
            selectedAssets,
            selectedShifts,
            availableShifts,
            elements: [],
        }
    }

    async generateElements() {
        this.setState({loading: true})
        this.setState({loading: false, elements: []})
    }

    handleChange(name, values) {
        this.setState({[name]: values})
    }

    componentWillMount() {
        this.generateElements()
    }

    componentWillUnmount() {
        let obj = {}

        if (this.state.selectedAssets.length !== this.props.devices.length)
            obj.assets = this.state.selectedAssets.map((x) => x.value)

        if (this.state.selectedShifts.length !== this.props.shifts.length)
            obj.shifts = this.state.selectedShifts.map((x) => x.value)

        for (let i in this.state.elements) {
            const name = this.state.elements[i].props.name
            if (this.state[name])
                obj[name] = this.state[name].map((x) => x.value)
            else
                obj[name] = this.state.elements[i].props.options.map(
                    (x) => x.value
                )

            if (
                obj[name].length === this.state.elements[i].props.options.length
            )
                delete obj[name]
        }
        this.props.filter(obj)
    }

    render() {
        return (
            <Fragment>
                <Modal isOpen={true} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>
                        Filter Data
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading ? (
                            <div className="loading" />
                        ) : (
                            <>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Assets:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="assets"
                                            value={this.state.selectedAssets}
                                            onChange={(selectedAssets) => {
                                                this.setState({selectedAssets})
                                            }}
                                            options={this.props.devices.map(
                                                (a, i) => {
                                                    return {
                                                        label: a.name,
                                                        value: a.deviceId,
                                                        key: i,
                                                    }
                                                }
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Shifts:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="shifts"
                                            value={this.state.selectedShifts}
                                            onChange={(selectedShifts) => {
                                                this.setState({selectedShifts})
                                            }}
                                            options={this.state.availableShifts}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.props.toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
