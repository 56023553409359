import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'
import {Rnd} from 'react-rnd'

import './element.css'

const MIN_SIZE = 20
const MAX_SIZE = 40

export default class Element extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const width =
            isNaN(this.props.width) && this.props.width.includes('px')
                ? this.props.width.split('px')[0]
                : this.props.width
        const height =
            isNaN(this.props.height) && this.props.height.includes('px')
                ? this.props.height.split('px')[0]
                : this.props.height

        this.size = {
            width: Math.ceil(width * this.props.xScale),
            height: Math.ceil(height * this.props.yScale),
        }

        this.position = {
            x: Math.ceil(this.props.x * this.props.xScale),
            y: Math.ceil(this.props.y * this.props.yScale),
        }

        this.middle = {
            x: Math.ceil(this.position.x + this.size.width / 2),
            y: Math.ceil(this.position.y + this.size.height / 2),
        }

        this.state = {
            showingCounter: false,
        }
    }

    content() {
        let showingImage = false,
            asset = null

        if (this.props.devices.length === 1) {
            asset = this.props.assets.find(
                (a) => a.deviceId === this.props.devices[0].deviceId
            )
            if (asset && asset.assetImage) {
                showingImage = true
            }
        }

        if (showingImage) {
            return (
                <Rnd
                    className={this.props.highlighting ? 'poi' : ''}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                    }}
                    disableDragging={true}
                    enableResizing={true}
                    size={{width: 30 * 2 + 'px', height: 30 * 2 + 'px'}}
                    position={{x: this.middle.x - 30, y: this.middle.y - 30}}
                    bounds="parent"
                    scale={this.props.scale}>
                    <img src={asset.assetImage} style={{width: 30}} />
                </Rnd>
            )
        } else if (this.props.devices.length > 0) {
            let backgroundColor = '#000'
            if (this.props.highlighting) {
                backgroundColor = '#145388'
            } else if (this.props.devices.length) {
                backgroundColor = '#28a745'
            }

            return (
                <Rnd
                    className="poi"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        backgroundColor,
                    }}
                    disableDragging={true}
                    enableResizing={true}
                    size={{
                        width: MIN_SIZE * 2 + 'px',
                        height: MIN_SIZE * 2 + 'px',
                    }}
                    position={{
                        x: this.middle.x - MIN_SIZE,
                        y: this.middle.y - MIN_SIZE,
                    }}
                    bounds="parent"
                    scale={this.props.scale}
                    onMouseEnter={() => {
                        this.props.onInteraction(
                            this.props.name,
                            this.props.devices
                        )
                    }}>
                    {this.props.devices.length}
                </Rnd>
            )
        } else {
            return null
        }
    }

    render() {
        let backgroundColor = '#000'
        if (this.props.highlighting) {
            backgroundColor = '#145388'
        } else if (this.props.devices.length) {
            backgroundColor = '#28a745'
        }

        return <>{this.content()}</>
    }
}
