import React, {Component, Fragment} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import * as sdk from './sdk'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            scrapcodes: [],
            loaded: false,
            selected: null,
        }
    }

    async fetchScrapCodes() {
        let reasoncodes = await API.get('scrapcodes', 2)

        reasoncodes = reasoncodes.filter((code) => {
            if (!code.assets) {
                return true
            } else {
                return code.assets.find((a) => a === this.props.deviceId)
            }
        })

        let categories = [...new Set(reasoncodes.map((r) => r.category))]
        categories = categories.sort((a, b) => a.localeCompare(b))

        let options = categories.map((c) => {
            const suboptions = reasoncodes
                .filter((r) => r.category === c)
                .map((r) => {
                    return {
                        label: r.reason,
                        value: r._id,
                    }
                })
                .sort((a, b) => a.label.localeCompare(b.label))

            return {
                label: c,
                options: suboptions,
            }
        })

        this.setState({
            scrapcodes: options,
            loaded: true,
        })
    }

    componentDidMount() {
        this.fetchScrapCodes()
    }

    async submit() {
        let timestamp = moment().toISOString()

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Reject',
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: timestamp,
                value: 1,
                metaData: [
                    {
                        name: 'reject',
                        value: 1,
                    },
                ],
            },
            2
        )

        this.props.toggleModal()
    }

    render() {
        return (
            <Fragment>
                {this.state.loaded ? (
                    <Modal isOpen={true} toggle={this.props.toggleModal}>
                        <ModalHeader toggle={this.props.toggleModal}>
                            Add Scrap Piece
                        </ModalHeader>
                        <ModalBody>
                            <label>Scrap reason:</label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select forceLeftAlign"
                                classNamePrefix="react-select"
                                name="reason"
                                value={this.state.selected}
                                onChange={(selected) =>
                                    this.setState({selected})
                                }
                                options={this.state.scrapcodes}
                                styles={customStyles}
                                isSearchable={false}
                            />
                            <p className="mt-5">
                                Note: This entry will be automatically
                                associated with the most recent part produced.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                block
                                color="primary"
                                onClick={this.submit}
                                disabled={this.state.selected === null}>
                                SUBMIT
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
