import React, {Fragment, Component} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'

import FormulaInput from '../../../common/FormulaInput/index'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.formulaInput = []

        let initial = {
            input: [
                moment().add(-1, 'days').startOf('day').valueOf(),
                moment().startOf('day').valueOf(),
            ],
        }

        if (props) {
            initial = {
                input: props.value || [
                    moment().add(-1, 'days').startOf('day').valueOf(),
                    moment().startOf('day').valueOf(),
                ],
            }
        }

        this.state = {
            input: initial.input,
        }
    }

    handleFromChange(value) {
        if (!moment.isMoment(value)) return
        let input = this.state.input

        if (value.valueOf() >= parseInt(input[1])) {
            return alert(
                'The start of the period cannot be equal to or later than the end of the period.'
            )
        }

        this.setState(
            {
                input: [value.valueOf(), input[1]],
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    handleToChange(value) {
        if (!moment.isMoment(value)) return
        const input = this.state.input

        if (parseInt(input[0]) >= value.valueOf()) {
            return alert(
                'The start of the period cannot be equal to or later than the end of the period.'
            )
        }
        if (value.diff(moment()) > 0) {
            return alert('The end of the period cannot be in the future.')
        }

        this.setState(
            {
                input: [input[0], value.valueOf()],
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="6">
                        {this.props.formulas && this.props.variables ? (
                            <FormulaInput
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                                type="formula"
                                onChange={(v) => {
                                    v.type = 'formula'
                                    this.formulaInput[0] = v
                                    this.props.onChange(this.formulaInput)
                                }}
                                default={this.props.defaultFormulaInput}
                            />
                        ) : (
                            <Datetime
                                value={moment(
                                    parseInt(this.state.input[0])
                                ).toDate()}
                                onChange={this.handleFromChange}
                                closeOnSelect={true}
                            />
                        )}
                    </Col>
                    <Col xs="6">
                        {this.props.formulas && this.props.variables ? (
                            <FormulaInput
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                                type="formula"
                                onChange={(v) => {
                                    v.type = 'formula'
                                    this.formulaInput[1] = v
                                    this.props.onChange(this.formulaInput)
                                }}
                                default={this.props.defaultFormulaInput}
                            />
                        ) : (
                            <Datetime
                                value={moment(
                                    parseInt(this.state.input[1])
                                ).toDate()}
                                onChange={this.handleToChange}
                                closeOnSelect={true}
                            />
                        )}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
