import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Card, CardBody} from 'reactstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class ResourceManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ResourceManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        environmentId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            showMessage: true,
            env: null,
            devices: [],
            shifts: [],
            users: [],
            selectedDevices: [],
            selectedShifts: [],
            selectedUsers: [],
        }
    }

    hanldeDeviceSelect(e) {
        this.setState({
            selectedDevices: e,
        })
    }

    hanldeShiftSelect(e) {
        this.setState({
            selectedShifts: e,
        })
    }

    handleUserSelect(e) {
        this.setState({
            selectedUsers: e,
        })
    }

    async fetchData() {
        const envs = await API.get('environments', 2),
            shifts = await API.get('shifts?all=true', 2),
            devices = await API.get('devices?all=true'),
            users = await API.get('users?all=true', 2),
            env = envs.find((e) => e._id === this.props.environmentId)

        let selectedShifts = [],
            selectedDevices = [],
            selectedUsers = []

        if (env.resources) {
            this.setState({showMessage: false})
            if (env.resources.devices) {
                selectedDevices = env.resources.devices.map((o) => {
                    const device = devices.find((d) => d._id === o)

                    if (!device) return null

                    return {
                        label: device.name,
                        value: device._id,
                        key: device._id,
                    }
                })
            }

            if (env.resources.shifts) {
                selectedShifts = env.resources.shifts.map((o) => {
                    const shift = shifts.find((d) => d._id === o)

                    if (!shift) return null

                    return {
                        label: shift.description
                            ? shift.name + ' (' + shift.description + ')'
                            : shift.name,
                        value: shift._id,
                        key: shift._id,
                    }
                })
            }

            if (env.resources.users) {
                selectedUsers = env.resources.users.map((o) => {
                    const user = users.find((d) => d._id === o)

                    if (!user) return null

                    return {
                        label: user.firstName + ' ' + user.lastName,
                        value: user._id,
                        key: user._id,
                    }
                })
            }
        } else {
            selectedShifts = shifts.map((x) => {
                return {
                    label: x.name,
                    value: x._id,
                    key: x._id,
                }
            })

            selectedDevices = devices.map((x) => {
                return {
                    label: x.name,
                    value: x._id,
                    key: x._id,
                }
            })

            selectedUsers = users.map((x) => {
                return {
                    label: x.firstName + ' ' + x.lastName,
                    value: x._id,
                    key: x._id,
                }
            })
        }

        selectedShifts = selectedShifts.filter((o) => o !== null)
        selectedDevices = selectedDevices.filter((o) => o !== null)
        selectedUsers = selectedUsers.filter((o) => o !== null)

        this.setState({
            env,
            shifts,
            devices,
            users: users.filter((o) => o.username !== 'accumine'),
            selectedShifts,
            selectedDevices,
            selectedUsers,
        })
    }

    async submit() {
        let env = Object.assign({}, this.state.env)

        if (!env.resources) {
            env.resources = {}
        }

        env.resources.devices = this.state.selectedDevices.map((o) => o.value)
        env.resources.shifts = this.state.selectedShifts.map((o) => o.value)
        env.resources.users = this.state.selectedUsers.map((o) => o.value)

        await API.patch('environments/' + env._id, env, 2)
    }

    componentWillMount() {
        this.fetchData()
    }

    componentWillUnmount() {}

    render() {
        return (
            <>
                {this.state.showMessage ? (
                    <Row>
                        <Col className="text-center">
                            <h5>
                                This application does not currently have any
                                resource filtering. Click below to prevent
                                assets, shifts and/or users from being
                                accessible within this application.
                            </h5>
                            <Button
                                color="primary"
                                className="btn-lg"
                                onClick={() => {
                                    this.setState({showMessage: false})
                                }}>
                                Enable resource management
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs="12" className="mb-4">
                                    <label>Assets:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isMulti
                                        name="assets"
                                        value={this.state.selectedDevices}
                                        onChange={this.hanldeDeviceSelect}
                                        options={this.state.devices.map(
                                            (a, i) => {
                                                return {
                                                    label: a.name,
                                                    value: a._id,
                                                    key: a._id,
                                                }
                                            }
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="mb-4">
                                    <label>Shifts:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isMulti
                                        name="shifts"
                                        value={this.state.selectedShifts}
                                        onChange={this.hanldeShiftSelect}
                                        options={this.state.shifts.map(
                                            (a, i) => {
                                                return {
                                                    label: a.description
                                                        ? a.name +
                                                          ' (' +
                                                          a.description +
                                                          ')'
                                                        : a.name,
                                                    value: a._id,
                                                    key: a._id,
                                                }
                                            }
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="mb-4">
                                    <label>Users:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isMulti
                                        name="users"
                                        value={this.state.selectedUsers}
                                        onChange={this.handleUserSelect}
                                        options={this.state.users.map(
                                            (a, i) => {
                                                return {
                                                    label:
                                                        a.firstName +
                                                        ' ' +
                                                        a.lastName,
                                                    value: a._id,
                                                    key: a._id,
                                                }
                                            }
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="mb-4 text-right">
                                    <Button
                                        color="primary"
                                        onClick={this.submit}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                )}
            </>
        )
    }
}
