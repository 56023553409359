import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import helpers from 'SDK/ui/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const existingPropKeys = this.props.existingProps
            ? Object.keys(this.props.existingProps)
            : false

        const element = new this.props.component()
        const inputs = element.propComponents.map((p) => {
            const fn = helpers.find((o) => {
                const instance = new o()
                return instance.id === p.component
            })

            let obj = {
                el: fn,
            }

            const instance = new fn()

            if (
                existingPropKeys &&
                existingPropKeys.find((k) => k === instance.field)
            ) {
                obj.existing = this.props.existingProps[instance.field]
            }

            return obj
        })

        this.state = {
            element: element,
            inputs: inputs.map((Component, index) => (
                <Component.el
                    key={index}
                    existing={Component.existing}
                    element={element}
                    cb={this.cb}
                />
            )),
            props: {},
        }
    }

    cb(field, value) {
        let state = this.state
        state.props[field] = value
        this.setState(state)
    }

    add() {
        let obj = {
            name: this.state.element.id,
            props: {},
        }
        for (let p in this.state.props) {
            obj.props[p] = this.state.props[p]
        }
        this.props.loadComponent(obj)
        this.props.toggleModal()
    }

    reset() {
        if (this.props.reset) this.props.reset()
        this.props.toggleModal()
    }

    async componentWillMount() {}

    render() {
        const {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        {this.state.element.id}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.inputs.map((component) => {
                            return component
                        })}
                    </ModalBody>
                    <ModalFooter>
                        {this.props.existingProps ? (
                            <>
                                {!this.props.manager && (
                                    <Button
                                        color="warning"
                                        onClick={this.reset}>
                                        Reset to default
                                    </Button>
                                )}
                                <Button color="primary" onClick={this.add}>
                                    Save
                                </Button>
                            </>
                        ) : (
                            <Button color="primary" onClick={this.add}>
                                Add
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
