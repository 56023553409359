import React, {useState, useEffect} from 'react'
import {
    Row,
    Col,
    ButtonGroup,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Input,
    FormGroup,
    Form,
    Label,
    UncontrolledTooltip,
    Button,
    Alert,
} from 'reactstrap'
import {cloneDeep} from 'lodash'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as API from 'SDK/api'
import FlowDetailModal from 'SDK/ui/widgets/Schemas/SDK/FlowBuilder/flowDetailModal'
import {getStatus} from './StatusOptions'
import {validator, ValidatorModal} from './validator'
import generateTemplate from './template/generateTemplate'

export default (props) => {
    const [loading, setLoading] = useState(false)
    const [saveModalOpen, setSaveModalOpen] = useState(false)
    const [validatorOutputModalOpen, setValidatorOutputModalOpen] =
        useState(false)
    const [validatorErrors, setValidatorErrors] = useState([])

    const save = async (
        name = props.flow.name,
        description = props.flow.description,
        exit = false
    ) => {
        const errors = validator(props.elements, props.variables)
        setValidatorErrors(errors)
        if (errors.length) {
            return setValidatorOutputModalOpen(true)
        }

        setLoading(true)

        let updated = cloneDeep(props.flow)
        updated.name = name
        updated.description = description
        updated.nodes = props.elements
            .filter((el) => !el.source)
            .map((el) => {
                delete el.data.opts.documentation
                return el
            })
        updated.edges = props.elements.filter((el) => el.source)
        updated.variables = props.variables
        updated.syncedToFlowServer = false
        delete updated.elements

        try {
            await API.patch(
                `data-models/${updated.dataModelId}/edit-record`,
                updated,
                2
            )
            setSaveModalOpen(false)

            if (exit) props.backToTable()
            toast(
                `Flow saved. It may take several moments to be processed by the Flow Engine.`,
                {
                    position: 'top-right',
                    type: 'success',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        } catch (error) {
            toast(`Error encountered, could not save flow.`, {
                position: 'top-right',
                type: 'error',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return setLoading(false)
        }
        setLoading(false)
    }

    const deleteFlow = async () => {
        setLoading(true)

        try {
            await API.remove(
                `data-models/${props.flow.dataModelId}/delete-record/${props.flow._id}`,
                2
            )
            props.backToTable()
            toast(`Flow deleted`, {
                position: 'top-right',
                type: 'success',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } catch (error) {
            toast(`Error encountered, could not delete flow`, {
                position: 'top-right',
                type: 'error',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return setLoading(false)
        }
        setLoading(false)
    }

    const enableInputs = async () => {
        setLoading(true)
        const enabled = !props.settings.enableInputs
        let response, updated

        try {
            response = await API.post(
                `data-models/${props.flow.dataModelId}/paginate`,
                {
                    filter: [
                        {
                            path: '_id',
                            type: 'ObjectID',
                            logic: 'is',
                            value: [props.flow._id],
                        },
                    ],
                    sort: {},
                    limit: 1,
                },
                2
            )
            updated = response.result.results[0]
            updated.settings.enableInputs = enabled
            updated.syncedToFlowServer = false
        } catch (error) {
            toast(
                `Error encountered, could not ${
                    enabled ? 'enable' : 'disable'
                } flow input`,
                {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
            return setLoading(false)
        }

        try {
            await API.patch(
                `data-models/${props.flow.dataModelId}/edit-record`,
                updated,
                2
            )
            props.setSettings({...props.settings, enableInputs: enabled})
            toast(
                enabled
                    ? 'Flow input enabled. It may take several moments to be processed by the Flow Engine'
                    : 'Flow input disabled. It may take several moments to be processed by the Flow Engine',
                {
                    position: 'top-right',
                    type: 'success',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        } catch (error) {
            toast(
                `Error encountered, could not ${
                    enabled ? 'enable' : 'disable'
                } flow input`,
                {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
            return setLoading(false)
        }
        setLoading(false)
    }

    const enableOutputs = async () => {
        setLoading(true)
        const enabled = !props.settings.enableOutputs
        let response, updated

        try {
            response = await API.post(
                `data-models/${props.flow.dataModelId}/paginate`,
                {
                    filter: [
                        {
                            path: '_id',
                            type: 'ObjectID',
                            logic: 'is',
                            value: [props.flow._id],
                        },
                    ],
                    sort: {},
                    limit: 1,
                },
                2
            )
            updated = response.result.results[0]
            updated.settings.enableOutputs = enabled
            updated.syncedToFlowServer = false
        } catch (error) {
            toast(
                `Error encountered, could not ${
                    enabled ? 'enable' : 'disable'
                } flow output(s)`,
                {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
            return setLoading(false)
        }

        try {
            await API.patch(
                `data-models/${props.flow.dataModelId}/edit-record`,
                updated,
                2
            )
            props.setSettings({...props.settings, enableOutputs: enabled})
            toast(
                enabled
                    ? 'Flow output(s) enabled. It may take several moments to be processed by the Flow Engine'
                    : 'Flow output(s) disabled. It may take several moments to be processed by the Flow Engine',
                {
                    position: 'top-right',
                    type: 'success',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        } catch (error) {
            toast(
                `Error encountered, could not ${
                    enabled ? 'enable' : 'disable'
                } flow output(s)`,
                {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
            return setLoading(false)
        }
        setLoading(false)
    }

    const pauseFlow = async () => {
        setLoading(true)
        const enabled = !props.settings.pauseFlow
        let response, updated

        try {
            response = await API.post(
                `data-models/${props.flow.dataModelId}/paginate`,
                {
                    filter: [
                        {
                            path: '_id',
                            type: 'ObjectID',
                            logic: 'is',
                            value: [props.flow._id],
                        },
                    ],
                    sort: {},
                    limit: 1,
                },
                2
            )
            updated = response.result.results[0]
            updated.settings.pauseFlow = enabled
            updated.syncedToFlowServer = false
        } catch (error) {
            toast(`Error encountered, could not change flow status`, {
                position: 'top-right',
                type: 'error',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return setLoading(false)
        }

        try {
            await API.patch(
                `data-models/${props.flow.dataModelId}/edit-record`,
                updated,
                2
            )
            props.setSettings({...props.settings, pauseFlow: enabled})
            toast(
                enabled
                    ? 'Flow paused. Incoming data will be buffered until un-paused. It may take several moments to be processed by the Flow Engine'
                    : 'Flow activated. It may take several moments to be processed by the Flow Engine',
                {
                    position: 'top-right',
                    type: 'success',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        } catch (error) {
            toast(`Error encountered, could not change flow status`, {
                position: 'top-right',
                type: 'error',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return setLoading(false)
        }
        setLoading(false)
    }

    const flowStatus =
        props.status.server === 'online'
            ? props.status.flows[props.flow.id]
            : 'offline'

    useEffect(() => {
        setValidatorErrors(validator(props.elements, props.variables))
    }, [props.elements, props.variables])

    return (
        <>
            <Row>
                <Col xs="5">
                    <h5>
                        <>
                            <span
                                id={`status_${props.flow._id}`}
                                style={{
                                    height: 15,
                                    width: 15,
                                    background: getStatus(flowStatus).hexColor,
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    marginRight: 5,
                                }}
                            />
                            <UncontrolledTooltip
                                target={`status_${props.flow._id}`}>
                                <p className="m-0">
                                    {getStatus(flowStatus).flowDocumentation}
                                </p>
                            </UncontrolledTooltip>
                        </>
                        {!props.flow.syncedToFlowServer ? (
                            <>
                                <i
                                    id={`sync_${props.flow._id}`}
                                    className="simple-icon-exclamation"
                                    style={{color: '#b69329'}}
                                />
                                <UncontrolledTooltip
                                    target={`sync_${props.flow._id}`}>
                                    <p className="m-0">
                                        There is a new version of this flow that
                                        has not yet been accepted by the Flow
                                        Engine. This can take several minutes,
                                        no action is needed on your behalf.
                                    </p>
                                </UncontrolledTooltip>
                            </>
                        ) : null}{' '}
                        <strong>{props.flow.name}</strong>
                    </h5>
                </Col>

                <Col xs="5" className="text-right">
                    <Form>
                        <FormGroup id="pauseFlow" check inline>
                            <Label check>
                                <Input
                                    disabled={loading}
                                    type="checkbox"
                                    checked={!props.settings.pauseFlow}
                                    onChange={pauseFlow}
                                />
                                <b>Enable Flow</b>
                            </Label>
                        </FormGroup>
                        <FormGroup id="enableInputs" check inline>
                            <Label check>
                                <Input
                                    disabled={loading}
                                    type="checkbox"
                                    checked={props.settings.enableInputs}
                                    onChange={enableInputs}
                                />
                                <b>Enable Input</b>
                            </Label>
                        </FormGroup>
                        <FormGroup id="enableOutputs" check inline>
                            <Label check>
                                <Input
                                    disabled={loading}
                                    type="checkbox"
                                    checked={props.settings.enableOutputs}
                                    onChange={enableOutputs}
                                />
                                <b>Enable Output(s)</b>
                            </Label>
                        </FormGroup>
                    </Form>
                    <UncontrolledTooltip target="pauseFlow">
                        <p>
                            Disabling this will stop flow execution until it is
                            enabled. Input data will be buffered and processed
                            when the flow resumes.
                        </p>
                        <p className="m-0">
                            This action will not save any changes to the flow
                            configuration.
                        </p>
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="enableInputs">
                        <p>
                            Disabling this will prevent all incoming data from
                            being accepted by the flow.
                        </p>
                        <p className="m-0">
                            This action will not save any changes to the flow
                            configuration.
                        </p>
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="enableOutputs">
                        <p>
                            Disabling this setting will block all outgoing flow
                            actions.
                        </p>
                        <p className="m-0">
                            This action will not save any changes to the flow
                            configuration.
                        </p>
                    </UncontrolledTooltip>
                </Col>
                <Col xs="2" className="text-right">
                    {props.debugMode ? (
                        <Button
                            size="xs"
                            color="primary"
                            onClick={() =>
                                props.setDebugMode(!props.debugMode)
                            }>
                            Back to Builder
                        </Button>
                    ) : (
                        <ButtonGroup size="xs">
                            {validatorErrors.length ? (
                                <Button
                                    size="xs"
                                    onClick={() =>
                                        setValidatorOutputModalOpen(true)
                                    }
                                    color={'danger'}>
                                    <i className="simple-icon-exclamation" />{' '}
                                    {validatorErrors.length}
                                </Button>
                            ) : (
                                <Button
                                    size="xs"
                                    onClick={() => setSaveModalOpen(true)}
                                    color={'success'}>
                                    <i className="simple-icon-check" /> Save
                                </Button>
                            )}

                            <UncontrolledButtonDropdown>
                                <DropdownToggle
                                    color="primary"
                                    disabled={loading}
                                    size="xs"
                                    caret>
                                    Actions
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={props.backToTable}>
                                        Cancel Changes
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={async () => {
                                            props.setDebugMode(!props.debugMode)
                                        }}>
                                        {props.debugMode
                                            ? 'Exit Debug Mode'
                                            : 'Start Debugger'}
                                    </DropdownItem>
                                    {validatorErrors.length === 0 ? (
                                        <>
                                            <DropdownItem
                                                onClick={async () => {
                                                    if (!props.debugMode) {
                                                        await save(
                                                            props.flow.name,
                                                            props.flow
                                                                .description,
                                                            false
                                                        )
                                                    }
                                                    props.setDebugMode(
                                                        !props.debugMode
                                                    )
                                                }}>
                                                {props.debugMode
                                                    ? 'Exit Debug Mode'
                                                    : 'Save & Start Debugger'}
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() =>
                                                    generateTemplate(
                                                        props.flow,
                                                        props.dataModels
                                                    )
                                                }>
                                                Generate Template
                                            </DropdownItem>
                                        </>
                                    ) : null}
                                    <DropdownItem divider />
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={deleteFlow}>
                                        Delete
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </ButtonGroup>
                    )}
                </Col>
            </Row>

            <Row>
                <Col>
                    {!props.elements.some((a) => a.type === 'output') ? (
                        <Alert color="warning">
                            No outputs found. The rule can still be saved and
                            ran for debugging purposes but it will not perform
                            any actions.{' '}
                        </Alert>
                    ) : null}
                </Col>
            </Row>
            {saveModalOpen ? (
                <FlowDetailModal
                    cancel={() => setSaveModalOpen(false)}
                    flows={props.flows}
                    flow={props.flow}
                    submit={save}
                    setDebugMode={props.setDebugMode}
                />
            ) : null}

            {validatorOutputModalOpen ? (
                <ValidatorModal
                    errors={validatorErrors}
                    toggle={() =>
                        setValidatorOutputModalOpen(!validatorOutputModalOpen)
                    }
                />
            ) : null}
        </>
    )
}
