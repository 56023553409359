const parseLevel = ({
    dataModelId,
    dataModels,
    path,
    flattened,
    manualDataLink = false,
    ignoreManualDataLinks,
}) => {
    const model = dataModels.find((a) => a._id === dataModelId),
        references = (model.references || []).filter((a) => !a.timeBased)

    if (model.parentFieldId === null) {
        flattened.push({
            path: path === '' ? '_id' : path + '._id',
            field: {},
            references: [],
            manualDataLink,
        })
    }

    for (let field of model.fields.filter((a) => !a.archived)) {
        let fieldPath = `${path}${path ? '.' : ''}${field.name}`

        if (field.type === 'ForeignID' && ignoreManualDataLinks) {
            fieldPath += '._id'
            flattened.push({
                path: fieldPath,
                field,
                references: references.filter(
                    (a) => a.sourcePath === fieldPath
                ),
                manualDataLink,
            })
        } else if (field.type === 'ForeignID' && !ignoreManualDataLinks) {
            if (field.foreignDataModelId === dataModelId) return
            parseLevel({
                dataModelId: field.foreignDataModelId,
                dataModels,
                path: fieldPath,
                flattened,
                manualDataLink,
                ignoreManualDataLinks,
            })
        } else if (field.type !== 'SingleSubModel') {
            flattened.push({
                path: fieldPath,
                field,
                references: references.filter(
                    (a) => a.sourcePath === fieldPath
                ),
                manualDataLink,
            })
        }

        if (field.type === 'SingleSubModel') {
            parseLevel({
                dataModelId: dataModels.find(
                    (a) => a.parentFieldId === field._id
                )._id,
                dataModels,
                path: fieldPath,
                flattened,
                manualDataLink,
                ignoreManualDataLinks,
            })
        }

        if (field.type === 'MultiSubModel') {
            field.dataModelId = dataModels.find(
                (a) => a.parentFieldId === field._id
            )._id
            flattened.push({
                path,
                field,
                references: references.filter(
                    (a) => a.sourcePath === fieldPath
                ),
                manualDataLink,
                hidden: true,
            })
        }
    }
}

const modelToDot = (dataModelId, dataModels, ignoreManualDataLinks = true) => {
    let flattened = []
    parseLevel({
        dataModelId,
        dataModels,
        path: '',
        flattened,
        ignoreManualDataLinks,
    })
    return flattened
}

export default modelToDot
