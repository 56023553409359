import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup} from 'reactstrap'
import autobind from 'auto-bind'

import Navigation from '../Navigation'
import RawMaterialReport from './RawMaterialReport'
import LotsByMaterialReport from './LotsByMaterialReport'
import BlockBatchReport from './BlockBatchReport'
import ScrapReport from './ScrapReport'
import BatchesByTimeReport from './BatchesByTimeReport'
import BlockBatchDataByPressTimeReport from './BlockBatchDataByPressTimeReport'
import BlockBatchReportAllOperations from './BlockBatchReportAllOperations'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Reports',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            selectedSection: null,
        }
    }

    reset() {
        this.setState({selectedSection: null})
    }

    goTo(path) {
        this.props.history.push(path)
    }

    render() {
        return (
            <Fragment>
                {this.state.selectedSection === null ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <Navigation path={this.path} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Reports</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <ButtonGroup vertical>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Raw Material',
                                            })
                                        }}>
                                        RAW MATERIAL
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Lots By Material',
                                            })
                                        }}>
                                        LOTS BY MATERIAL
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Batches By Time',
                                            })
                                        }}>
                                        BATCHES BY TIME
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Scrap',
                                            })
                                        }}>
                                        SCRAP & REWORK
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Block Batch',
                                            })
                                        }}>
                                        BLOCK BATCH
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Block Batch Data By Press Time',
                                            })
                                        }}>
                                        BLOCK BATCH DATA BY PRESS TIME
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection:
                                                    'Block Batch - All Operations',
                                            })
                                        }}>
                                        BLOCK BATCH - ALL OPERATIONS
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <Fragment>
                        {this.state.selectedSection === 'Raw Material' ? (
                            <RawMaterialReport
                                path={this.path}
                                role={this.role}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Lots By Material' ? (
                            <LotsByMaterialReport
                                path={this.path}
                                role={this.role}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Block Batch' ? (
                            <BlockBatchReport
                                path={this.path}
                                role={this.role}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Scrap' ? (
                            <ScrapReport path={this.path} role={this.role} />
                        ) : null}

                        {this.state.selectedSection === 'Batches By Time' ? (
                            <BatchesByTimeReport
                                path={this.path}
                                role={this.role}
                            />
                        ) : null}

                        {this.state.selectedSection ===
                        'Block Batch - All Operations' ? (
                            <BlockBatchReportAllOperations
                                path={this.path}
                                role={this.role}
                            />
                        ) : null}

                        {this.state.selectedSection ===
                        'Block Batch Data By Press Time' ? (
                            <BlockBatchDataByPressTimeReport
                                path={this.path}
                                role={this.role}
                            />
                        ) : null}
                    </Fragment>
                )}
            </Fragment>
        )
    }
}
