import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

const OPTIONS = [
    {
        label: 'Last 24 Hours',
        value: 'last24',
    },
    {
        label: 'Today',
        value: 'today',
    },
    {
        label: 'Active Shift',
        value: 'shift',
    },
]

export default class RealTimeTimerange extends Component {
    id = 'RealTimeTimerange'
    field = 'timerange'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : null

        this.state = {
            selectedAsset: initialValue
                ? OPTIONS.find((o) => o.value === initialValue)
                : null,
        }

        if (this.props && this.props.cb) {
            this.props.cb('timerange', initialValue)
        }
    }

    getSelection() {
        return this.state.selectedAsset.value
    }
    handleAssetChange(e) {
        this.setState({
            selectedAsset: e,
        })

        if (this.props.cb) {
            this.props.cb('timerange', e.value)
        }
    }

    async componentWillMount() {}

    render() {
        return (
            <Fragment>
                <label>Timerange:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="asset"
                    value={this.state.selectedAsset}
                    onChange={this.handleAssetChange}
                    options={OPTIONS}
                />
            </Fragment>
        )
    }
}
