import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup, Table} from 'reactstrap'
import autobind from 'auto-bind'

import Navigation from '../../Navigation'
import {paginate} from '../../Paginate'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Raw Material Report',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            loading: true,
            materials: [],
        }
    }

    async componentDidMount() {
        let lots = await paginate('5fc554e6cca7411395293a68', [], {}),
            materials = await paginate('5fc530725d4bb3001bba8f43', [], {})

        materials = materials.map((m) => {
            return {
                material: m['Value'],
                qty: 0,
            }
        })

        lots.forEach((lot) => {
            let material = materials.find(
                (m) => m.material === lot['Raw Chemical']['Value']
            )
            if (material) {
                material.qty += lot['Raw Quantity']
            }
        })

        materials.sort((a, b) => a.material.localeCompare(b.material))

        this.setState({
            materials,
            loading: false,
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Raw Material Report</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm={{size: 6, offset: 3}}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Raw Material</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.materials.map((m, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <th>{m.material}</th>
                                            <td>{m.qty} kg</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
