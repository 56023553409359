import React from 'react'
import moment from 'moment'

export default class NoteReply extends React.Component {
    render() {
        let userName = ''
        let user = this.props.resources.find(
            (r) => r.id === 'users_' + this.props.reply.userId
        )
        if (user) userName = user.display
        return (
            <div>
                <p className="text-left" style={{marginBottom: 0}}>
                    <strong>{userName}</strong>{' '}
                    <small>
                        {moment(this.props.reply.timestamp).fromNow()}
                    </small>
                </p>
                <p
                    className="text-left"
                    style={{marginBottom: 0, color: '#000'}}>
                    {this.props.reply.plainText}
                </p>
                <hr style={{margin: 3}} />
            </div>
        )
    }
}
