import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardHeader, CardBody, Button} from 'reactstrap'
import autobind from 'auto-bind'
import Moment from 'moment'
import {extendMoment} from 'moment-range'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import * as API from 'SDK/api'
import {getReportAssets} from 'SDK/api/common'
import Subscriber from 'SDK/subscriber'
import {cloneDeep} from 'lodash'

import {TimelineChart} from './chart'
import DowntimeModal from './downtimeModal'

const moment = extendMoment(Moment)

import TimelineGenerator from './TimelineGenerator'

export default class TimelineReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'TimelineReport'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        device: PropTypes.object,
        date: PropTypes.object,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.startingCurrentDay = moment(this.props.startTime)

        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
            shifts: [],
            reasoncodes: [],
            dictionary: {},
            day: this.props.startTime
                ? moment(this.props.startTime)
                : moment().startOf('day'),
            startTime: this.props.startTime
                ? moment(this.props.startTime)
                : moment().startOf('day'),
            endTime: this.props.endTime
                ? moment(this.props.endTime)
                : moment().endOf('day'),
            device: this.props.device ? this.props.device : null,
            lastLiveUpdate: moment().format('h:mm:ssa'),
            live: true,
            loading: true,
        }
    }

    color(name) {
        // Handle pre-existing colors
        // Set in [ReasonCodeManager]
        // Typically found: Settings > Reason Codes
        if (
            this.state.downtimeCodes.find(
                (code) => code.reason == name && code.color
            )
        ) {
            return this.state.downtimeCodes.find(
                (code) => code.reason == name && code.color
            ).color
        }

        if (name === 'In-Cycle') return 'rgb(46, 204, 113)'
        else if (name === 'Uncategorized Downtime') return 'rgb(231, 76, 60)'
        else if (
            name === 'Idle/Dwell Time' ||
            name === 'Performance/Speed Loss'
        )
            return '#0275d8'
        else return '#f0ad4e'
    }

    handleDateChange(day) {
        const live = moment(day).isSame(moment().startOf('day'))
        this.setState({day: moment(day), loading: true, live}, this.fetchData)
    }

    async fetchShifts() {
        const shifts = await API.get('shifts', 2)
        this.setState({shifts})
    }

    async fetchData() {
        let processed = []

        const start = moment(this.state.startTime).toISOString(),
            end = moment(this.state.endTime).toISOString()
        const dow = moment(this.state.startTime).day()
        let shifts = this.state.shifts.filter((s) => {
            if (
                s.timeStart.hour > s.timeEnd.hour &&
                s.days.find((d) => d == (dow + 1 > 6 ? 0 : dow + 1)) !==
                    undefined
            ) {
                // overnight
                return true
            } else {
                // same day
                if (s.days.find((d) => d == dow) !== undefined) {
                    return true
                } else {
                    return false
                }
            }
        })

        let {data} = await API.post('historical/raw', {
            deviceId: {
                $in: this.state.assets.map((x) => x.deviceId),
            },
            name: {
                $in: [
                    'In-Cycle',
                    'Downtime',
                    'Downtime Reason',
                    'Time Based Speed Loss',
                    'Machine Waiting',
                ],
            },
            $or: [
                {
                    timeStart: {
                        $gte: start,
                        $lt: end,
                    },
                },
                {
                    timeStart: {
                        $lt: start,
                    },
                    timeEnd: {
                        $gt: start,
                    },
                },
                {
                    timeStart: {
                        $lt: end,
                    },
                    timeEnd: null,
                },
            ],
        })

        data = data.map((a) => {
            if (a.name === 'Machine Waiting') {
                a.name = 'Downtime'
            }
            return a
        })

        const usedDowntimeReasons = [
            ...new Set(
                data
                    .filter((row) => row.name === 'Downtime Reason')
                    .map((row) => row.value)
            ),
        ]

        this.originalDowntimePeriods = cloneDeep(
            data.filter((a) => a.name === 'Downtime')
        )

        data = data.map((a) => {
            if (a.name === 'Downtime Reason') {
                a.name = a.value
            }
            if (a.name === 'Time Based Speed Loss') {
                a.name = 'Performance/Speed Loss'
            }
            return a
        })

        let priorities = ['Performance/Speed Loss', 'In-Cycle']
        let scheduledBreaks = []
        for (let shift of this.state.shifts) {
            for (let b of shift.breaks) {
                if (!priorities.find((a) => a === b.name)) {
                    priorities.push(b.name)
                    scheduledBreaks.push(b.name)
                }
            }
        }

        priorities = priorities.concat(usedDowntimeReasons)

        priorities = priorities.concat(['Downtime Reason', 'Downtime'])

        let assetDowntimeEntryThresholds = {}
        for (let asset of this.state.assets) {
            assetDowntimeEntryThresholds[asset.name] =
                asset.downtimeThresholdSeconds || 300

            let schedule = shifts.filter((s) =>
                s.assets.find((a) => a === asset.deviceId)
            )
            for (let s of schedule) {
                for (let b of s.breaks) {
                    let scheduledBreak = {
                        timeStart: moment(this.state.timeStart).set({
                            hour: b.timeStart.hour,
                            minute: b.timeStart.minute,
                        }),
                        timeEnd: moment(this.state.timeEnd).set({
                            hour: b.timeEnd.hour,
                            minute: b.timeEnd.minute,
                        }),
                        name: b.name,
                        deviceId: asset.deviceId,
                    }

                    if (b.timeStart.hour > b.timeEnd.hour) {
                        scheduledBreak.timeStart = moment(this.state.timeStart)
                    }
                    if (moment(this.state.timeStart).isSame(moment(), 'day')) {
                        if (scheduledBreak.timeStart.isBefore(moment())) {
                            scheduledBreak.timeEnd =
                                scheduledBreak.timeEnd.isAfter(moment())
                                    ? moment()
                                    : scheduledBreak.timeEnd
                        }
                    }
                    if (scheduledBreak.timeStart.isSameOrBefore(moment()))
                        data.push(scheduledBreak)
                }
            }
        }

        let intervals = []
        for (let asset of this.state.assets) {
            intervals = intervals.concat(
                TimelineGenerator(
                    cloneDeep(
                        data.filter((a) => a.deviceId === asset.deviceId)
                    ),
                    priorities,
                    moment(start),
                    moment(end)
                ).map((a) => {
                    a.deviceName = asset.name
                    if (a.value === 'Downtime') {
                        a.value = 'Uncategorized Downtime'
                    }
                    return a
                })
            )
        }

        processed = intervals.map((a) => {
            if (
                a.value === 'Uncategorized Downtime' &&
                moment(a.end).diff(moment(a.start), 'seconds') <=
                    assetDowntimeEntryThresholds[a.deviceName]
            ) {
                a.value = 'Idle/Dwell Time'
            }
            return [
                a.deviceName,
                a.value,
                scheduledBreaks.find((b) => b === a.value)
                    ? 'grey'
                    : this.color(a.value, a),
                moment(a.start).toDate(),
                moment(a.end).toDate(),
                a._id,
            ]
        })

        this.setState({data: processed, loading: false, init: false})
    }

    async refreshLive() {
        const m = moment().startOf('day')

        if (this.state.live && !this.state.loading) {
            if (!m.isSame(this.startingCurrentDay)) {
                // need to update day to next day
                this.startingCurrentDay = moment(m)
            }

            this.setState(
                {
                    day: moment(m),
                    loading: true,
                    lastLiveUpdate: moment().format('h:mm:ssa'),
                },
                this.fetchData
            )
        }
    }

    async componentWillMount() {
        let assets = [this.props.device],
            reasoncodes = await API.get('reasoncodes', 2),
            dictionary = {}
        await this.fetchShifts()

        for (let i in assets) {
            dictionary[assets[i].deviceId] = assets[i].name
        }

        this.setState({
            assets,
            dictionary,
            downtimeCodes: reasoncodes,
            reasoncodes: [...new Set(reasoncodes.map((a) => a.reason))],
        })

        await this.fetchData()
        this.subscriber.add(this.refreshLive, 1000 * 60 * 5, 'refreshLive()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    componentWillReceiveProps(elem, elem2) {
        if (elem.endTime != this.state.endTime) {
            this.setState(
                {
                    startTime: elem.startTime,
                    endTime: elem.endTime,
                    day: elem.startTime,
                },
                this.fetchData
            )
        }
    }

    //shouldComponentUpdate(nextProps, nextState){
    //   return true;//return (this.state.loading);
    // }

    toggleDowntimeModal() {
        this.setState({downtimeModalOpen: !this.state.downtimeModalOpen})
    }

    onTimelineSelect(row) {
        const record = this.originalDowntimePeriods.find(
            (a) => a._id === row[5]
        )
        if (record) {
            const {downtimeThresholdSeconds} = this.state.assets.find(
                (a) => a.deviceId === record.deviceId
            )
            const duration = record.timeEnd
                ? moment(record.timeEnd).diff(
                      moment(record.timeStart),
                      'seconds'
                  )
                : moment().diff(moment(record.timeStart), 'seconds')
            if (
                (downtimeThresholdSeconds !== undefined &&
                    duration >= downtimeThresholdSeconds) ||
                (downtimeThresholdSeconds === undefined && duration >= 300)
            ) {
                this.setState({
                    downtimeEntryRecord: record,
                    downtimeModalOpen: true,
                })
            }
        }
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.65 + 'px'
        return (
            <Fragment>
                {this.state.loading ? (
                    <div className="loading" />
                ) : (
                    <TimelineChart
                        assets={[this.state.device]}
                        data={this.state.data}
                        day={this.state.timeStart}
                        onTimelineSelect={this.onTimelineSelect}
                    />
                )}

                {this.state.downtimeModalOpen ? (
                    <DowntimeModal
                        record={this.state.downtimeEntryRecord}
                        open={this.state.downtimeModalOpen}
                        toggle={this.toggleDowntimeModal}
                        fetchData={this.fetchData}
                    />
                ) : null}
            </Fragment>
        )
    }
}
