import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormText,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'

const dict = {
    open: 'Open Mesh',
    close: 'Close Mesh',
    start: 'Start Mesh',
    stop: 'Stop Mesh',
    reset: 'Reset Meth',
    pause: 'Pause Mesh',
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const lastModelVersion =
            this.props.models.length > 0
                ? Math.max.apply(
                      Math,
                      this.props.models.map((m) => m.version)
                  )
                : 0

        this.state = {
            version: !this.props.editingModel
                ? parseInt(lastModelVersion) + 1
                : this.props.editingModel.version,
            description: !this.props.editingModel
                ? ''
                : this.props.editingModel.description,
            cloneVersionSelection: null,
            versionBorderColor: 'rgb(215,215,215)',
            descriptionBorderColor: 'rgb(215,215,215)',
            valid: false,
            message: false,
            confirmDelete: false,
            loading: false,
        }
    }

    validateVersion(value) {
        let message = ''
        if (value === '') {
            message = 'Version is a required field.'
        } else if (
            this.props.models.find((model) => model.version === value) &&
            !this.props.editingModel
        ) {
            message = 'This version already exists.'
        }
        return message
    }

    validateDescription(value) {
        let message = ''
        if (value === '') {
            message = 'Please provide a description for this model.'
        }
        return message
    }

    askToConfirmDelete() {
        this.setState({confirmDelete: true})
    }
    revertDelete() {
        this.setState({confirmDelete: false})
    }

    async delete() {
        this.setState({loading: true})
        try {
            let response = await API.remove(
                'ips/cloud/models/' + this.props.editingModel.version,
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error deleting this model.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error deleting this model.')
        }
    }

    async add() {
        this.setState({loading: true})

        let modelToClone = null
        if (this.state.cloneVersionSelection) {
            modelToClone = this.props.models.find(
                (m) => m.version === this.state.cloneVersionSelection.value
            )
        }

        let obj = {
            version: this.state.version,
            description: this.state.description,
            active: false,
        }

        if (modelToClone) {
            ;(obj.pois = modelToClone.pois || []),
                (obj.backgroundImage =
                    modelToClone.backgroundImage || undefined)
            obj.backgroundImageHeight =
                modelToClone.backgroundImageHeight || undefined
            obj.backgroundImageWidth =
                modelToClone.backgroundImageWidth || undefined
            obj.file = obj.file //TODO self assign
        }

        try {
            let response = await API.post('ips/cloud/models/register', obj, 2)

            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                return alert(
                    'There was an error adding your model. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error adding your model.')
        }
    }

    async deploy() {
        this.setState({loading: true})

        try {
            let response = await API.patch(
                'ips/cloud/models/' +
                    this.props.editingModel.version +
                    '/deploy',
                {},
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error deploying this model.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error deploying this model.')
        }
    }

    async activate() {
        this.setState({loading: true})

        try {
            let response = await API.patch(
                'ips/cloud/models/' +
                    this.props.editingModel.version +
                    '/activate',
                {},
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error activating this model.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error activating this model.')
        }
    }

    closeModalAfterAction() {
        this.setState({loading: false})
        this.props.toggleModal()
    }

    renderDeleteModelButton() {
        if (
            !this.state.confirmDelete &&
            this.props.editingModel !== null &&
            this.props.editingModel.usedBy.split(' assets')[0] == 0
        ) {
            return (
                <Button color="danger" onClick={this.askToConfirmDelete}>
                    Delete Model
                </Button>
            )
        } else if (
            this.props.editingModel !== null &&
            this.props.editingModel.usedBy.split(' assets')[0] > 0
        ) {
            return (
                <p className="text-center">
                    This model cannot be deleted until it is no longer used by
                    any field devices.
                </p>
            )
        } else {
            return null
        }
    }

    renderDeleteConfirmation() {
        if (this.state.confirmDelete) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Are you sure?</p>
                    <Button size="sm" color="danger" onClick={this.delete}>
                        Yes
                    </Button>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.revertDelete}>
                        No
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    renderNewModel() {
        if (!this.props.editingModel) {
            return (
                <Fragment>
                    <Button
                        color="primary"
                        onClick={this.add}
                        disabled={!this.state.valid}>
                        Add Model
                    </Button>
                </Fragment>
            )
        }
    }

    renderDeploy() {
        if (this.props.editingModel) {
            return (
                <Button
                    color="primary"
                    className="btn-block"
                    onClick={this.deploy}>
                    Deploy model on all devices
                </Button>
            )
        }
    }

    renderActivation() {
        if (this.props.editingModel) {
            if (this.props.editingModel.active) {
                return (
                    <p className="text-center">
                        This model is currently active.
                    </p>
                )
            } else {
                return (
                    <Button
                        color="primary"
                        className="btn-block"
                        onClick={this.activate}>
                        Activate Model
                    </Button>
                )
            }
        }
    }

    render() {
        let {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggleModal}>
                        Version {this.state.version}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state
                                                        .versionBorderColor,
                                            }}
                                            type="number"
                                            name="version"
                                            value={this.state.version}
                                            onChange={(version) => {
                                                const message =
                                                        this.validateVersion(
                                                            version.target.value
                                                        ),
                                                    color =
                                                        message === ''
                                                            ? 'rgb(215,215,215)'
                                                            : '#dc3545',
                                                    valid =
                                                        message === '' &&
                                                        this.validateDescription(
                                                            this.state
                                                                .description
                                                        ) === ''
                                                this.setState({
                                                    version:
                                                        version.target.value,
                                                    valid: valid,
                                                    versionBorderColor: color,
                                                    versionInputMessage:
                                                        message,
                                                })
                                            }}
                                            disabled={true}
                                        />
                                        <IntlMessages id="Version" />
                                        <FormText color="muted">
                                            {this.state.versionInputMessage}
                                        </FormText>
                                    </Label>
                                    <Label className="form-group has-top-label">
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state
                                                        .descriptionBorderColor,
                                            }}
                                            type="textarea"
                                            name="description"
                                            value={this.state.description}
                                            onChange={(description) => {
                                                const message =
                                                        this.validateDescription(
                                                            description.target
                                                                .value
                                                        ),
                                                    color =
                                                        message === ''
                                                            ? 'rgb(215,215,215)'
                                                            : '#dc3545',
                                                    valid =
                                                        message === '' &&
                                                        this.validateVersion(
                                                            this.state.version
                                                        ) === ''
                                                this.setState({
                                                    description:
                                                        description.target
                                                            .value,
                                                    valid: valid,
                                                    descriptionBorderColor:
                                                        color,
                                                    descriptionInputMessage:
                                                        message,
                                                })
                                            }}
                                            disabled={
                                                this.props.editingModel !== null
                                            }
                                        />
                                        <IntlMessages id="Description" />
                                        <FormText color="muted">
                                            {this.state.descriptionInputMessage}
                                        </FormText>
                                    </Label>
                                    {this.props.models.length &&
                                    this.props.editingModel === null ? (
                                        <Label className="form-group has-top-label">
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="clone-version"
                                                value={
                                                    this.state
                                                        .cloneVersionSelection
                                                }
                                                options={this.props.models.map(
                                                    (m) => {
                                                        return {
                                                            label:
                                                                'Version ' +
                                                                m.version,
                                                            value: m.version,
                                                        }
                                                    }
                                                )}
                                                onChange={(
                                                    cloneVersionSelection
                                                ) => {
                                                    this.setState({
                                                        cloneVersionSelection,
                                                    })
                                                }}
                                            />
                                            <IntlMessages id="Clone existing model" />
                                        </Label>
                                    ) : null}
                                </Form>
                            </Col>
                        </Row>

                        {this.props.editingModel ? (
                            <Row>
                                <Col xs="12" className="mb-2">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="btn-block"
                                        onClick={() => {
                                            this.props.toggleMapModal(
                                                this.props.editingModel.version
                                            )
                                        }}>
                                        Edit Map Layout
                                    </Button>
                                </Col>
                                <Col xs="12" className="mb-2">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="btn-block"
                                        onClick={() => {
                                            this.props.toggleTrainModal(
                                                this.props.editingModel.version
                                            )
                                        }}>
                                        Train Model
                                    </Button>
                                </Col>
                                <Col xs="12" className="mb-2">
                                    {this.renderDeploy()}
                                </Col>
                                <Col xs="12" className="mb-2">
                                    {this.renderActivation()}
                                </Col>
                            </Row>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        {this.renderDeleteModelButton()}
                        {this.renderDeleteConfirmation()}
                        {this.renderNewModel()}
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
