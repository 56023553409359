import React, {Component, Fragment} from 'react'
import autoBind from 'auto-bind'
import AceEditor from 'react-ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/theme-xcode'
import 'ace-builds/src-noconflict/mode-javascript'

export default class CodeEditor extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            loading: true,
            ...props.formData,
            value: props.formData || '',
        }

        this.field = props.uiSchema
    }

    onChange(value) {
        this.setState({value})
        this.props.onChange(value)
    }

    render() {
        return (
            <Fragment>
                <label>{this.field.description}</label>
                <AceEditor
                    placeholder="Placeholder Text"
                    mode="javascript"
                    theme="xcode"
                    name="code-editor"
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={this.state.value}
                    width={'100%'}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        useWorker: true,
                    }}
                />
            </Fragment>
        )
    }
}
