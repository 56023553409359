import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardHeader,
    Input,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import {GoogleSpreadsheet} from 'google-spreadsheet'
import PropTypes from 'prop-types'
import * as API from 'SDK/api'
import PartChart from './PartChart'
import {secondsToHHMMSS} from 'SDK/helpers'
import Subscriber from 'SDK/subscriber'
import DowntimeEntryModal from './DowntimeEntryModal'
import ReworkEntryModal from './ReworkEntryModal'
import RejectEntryModal from './RejectEntryModal'
import SignInModal from './SignInModal'
import WorkOrderModal from './WorkOrderModal'
import Barcode from './Barcode'
import ManualQtyModal from './ManualQtyModal'

export default class HubbellAikenHMI2 extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
        {
            prop: 'HubbellAikenHMI2ManualPartIncrement',
            component: 'HubbellAikenHMI2ManualPartIncrement',
        },
        {
            prop: 'HubbellAikenHMI2PrintLabel',
            component: 'HubbellAikenHMI2PrintLabel',
        },
    ]
    showBorder = false
    id = 'HubbellAikenHMI2'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            dataModels: [],
            activeShift: null,
            forceSignOut: false,
            signIns: [],
            device: null,
            downtimeModalOpen: false,
            rejectModalOpen: false,
            reworkModalOpen: false,
            signInModalOpen: false,
            workOrderModalOpen: false,
            barcodeModalOpen: false,
            goodQty: 0,
            rejectQty: 0,
            reworkQty: 0,
            hourlyActual: 0,
            actualRemaining: 0,
            actualCT: 0,
            oeeAvail: 0,
            oeePerf: 0,
            oeeQual: 0,
            oee: 0,
            reasonCode: null,
            status: null,
            stateTimestamp: null,
            headerColor: '',
            downtimeTimeStart: null,
            hourly: {
                partCount: new Array(24),
                dates: new Array(24),
                target: new Array(24),
            },
            actualRemainingTileColor: '#808080',
            DA_ZFIN_Label_Info: [],
            Active_DA_ZFIN_Materials: [],
            manualQty: 1,
        }
    }

    async getOEE() {
        if (!this.state.workOrder) {
            this.setState({
                oee: 0,
                oeeAvail: 0,
                oeePerf: 0,
                oeeQual: 0,
            })
            return
        }

        const timeEnd = moment().format('YYYY-MM-DD HH:mm')

        try {
            let datasets = await Promise.all(
                this.state.shifts.map((shift) => {
                    return API.post(
                        'oee',
                        {
                            factoryId: JSON.parse(localStorage['userObject'])
                                .factoryId,
                            deviceId: this.props.deviceId,
                            timeStart: moment(
                                this.state.workOrder.Start_Time
                            ).format('YYYY-MM-DD HH:mm'),
                            timeEnd: timeEnd,
                            shift: shift.name,
                            exactRange: true,
                            speedLossType: 'scheduledHourlyTarget',
                        },
                        2
                    )
                })
            )
            datasets = datasets.map((d) => d.data).flat()

            let timeLosses = datasets.reduce((a, b) => a + b.time_losses, 0),
                performanceLosses = datasets.reduce(
                    (a, b) => a + b.speed_losses,
                    0
                ),
                qualityLosses = datasets.reduce(
                    (a, b) => a + b.quality_losses,
                    0
                ),
                ppt = datasets.reduce((a, b) => a + b.ppt, 0),
                oeeAvail = (ppt - timeLosses) / ppt,
                oeePerf = (ppt - performanceLosses) / ppt,
                oeeQual = (ppt - qualityLosses) / ppt

            if (oeeAvail < 0) oeeAvail = 0
            if (oeePerf < 0) oeePerf = 0
            if (oeeQual < 0) oeeQual = 0

            const oee = oeeAvail * oeePerf * oeeQual
            this.setState({
                oee: Math.floor(oee * 100),
                oeeAvail: Math.floor(oeeAvail * 100),
                oeePerf: Math.floor(oeePerf * 100),
                oeeQual: Math.floor(oeeQual * 100),
            })
        } catch (error) {
            console.log(error)
        }
    }

    async getWorkOrderPartData() {
        if (!this.state.workOrder) return

        try {
            const partCountDataModel = this.state.dataModels.find(
                    (d) => d.name === 'Classic/Part Count'
                ),
                rejectDataModel = this.state.dataModels.find(
                    (d) => d.name === 'Classic/Reject'
                ),
                reworkDataModel = this.state.dataModels.find(
                    (d) => d.name === 'Classic/Rework'
                )

            if (!partCountDataModel) {
                throw Error('Cannot find Classic/Part Count data model')
            }
            if (!rejectDataModel) {
                throw Error('Cannot find Classic/Reject data model')
            }
            if (!reworkDataModel) {
                throw Error('Cannot find Classic/Rework data model')
            }

            const partCountResponse = await API.post(
                `data-models/${partCountDataModel._id}/aggregate`,
                {
                    filter: [
                        {
                            path: 'deviceId',
                            type: 'Text',
                            logic: 'is',
                            value: [this.props.deviceId],
                        },
                        !this.props.HubbellAikenHMI2ManualPartIncrement && {
                            path: 'partsPerHourAdjusted',
                            type: 'Boolean',
                            logic: 'isTrue',
                        },
                    ],
                    groups: [],
                    timezone: 'America/Toronto',
                    timerange: {
                        logic: 'after',
                        value: moment(
                            this.state.workOrder.Start_Time
                        ).valueOf(),
                    },
                    aggregate: [
                        {
                            join: [],
                            aggregate: {
                                type: 'sum',
                                path: 'value',
                            },
                        },
                    ],
                },
                2
            )

            const rejectResponse = await API.post(
                `data-models/${rejectDataModel._id}/aggregate`,
                {
                    filter: [
                        {
                            path: 'deviceId',
                            type: 'Text',
                            logic: 'is',
                            value: [this.props.deviceId],
                        },
                        {
                            path: 'partsPerHourAdjusted',
                            type: 'Boolean',
                            logic: 'isTrue',
                        },
                    ],
                    groups: [],
                    timezone: 'America/Toronto',
                    timerange: {
                        logic: 'after',
                        value: moment(
                            this.state.workOrder.Start_Time
                        ).valueOf(),
                    },
                    aggregate: [
                        {
                            join: [],
                            aggregate: {
                                type: 'sum',
                                path: 'value',
                            },
                        },
                    ],
                },
                2
            )

            const reworkResponse = await API.post(
                `data-models/${reworkDataModel._id}/aggregate`,
                {
                    filter: [
                        {
                            path: 'deviceId',
                            type: 'Text',
                            logic: 'is',
                            value: [this.props.deviceId],
                        },
                        {
                            path: 'partsPerHourAdjusted',
                            type: 'Boolean',
                            logic: 'isTrue',
                        },
                    ],
                    groups: [],
                    timezone: 'America/Toronto',
                    timerange: {
                        logic: 'after',
                        value: moment(
                            this.state.workOrder.Start_Time
                        ).valueOf(),
                    },
                    aggregate: [
                        {
                            join: [],
                            aggregate: {
                                type: 'sum',
                                path: 'value',
                            },
                        },
                    ],
                },
                2
            )

            const partCount = partCountResponse.data.length
                    ? partCountResponse.data[0].value
                    : 0,
                reject = rejectResponse.data.length
                    ? rejectResponse.data[0].value
                    : 0,
                rework = reworkResponse.data.length
                    ? reworkResponse.data[0].value
                    : 0

            this.setState({
                goodQty: partCount - reject - rework,
                reworkQty: rework,
                rejectQty: reject,
                actualRemaining:
                    this.state.workOrder.Expected_Parts - partCount + reject,
                actualCT:
                    parseInt(
                        moment().diff(
                            moment(this.state.workOrder.Start_Time),
                            'seconds'
                        ) /
                            (partCount / this.state.workOrder.Parts_Per_Cycle)
                    ) || 0,
            })
        } catch (error) {
            console.log(error)
        }
    }

    async getCurrentWorkOrderHour() {
        if (!this.state.workOrder) return

        try {
            const partCountDataModel = this.state.dataModels.find(
                (d) => d.name === 'Classic/Part Count'
            )

            if (!partCountDataModel) {
                throw Error('Cannot find Classic/Part Count data model')
            }

            const partCountResponse = await API.post(
                `data-models/${partCountDataModel._id}/aggregate`,
                {
                    filter: [
                        {
                            path: 'deviceId',
                            type: 'Text',
                            logic: 'is',
                            value: [this.props.deviceId],
                        },
                        !this.props.HubbellAikenHMI2ManualPartIncrement && {
                            path: 'partsPerHourAdjusted',
                            type: 'Boolean',
                            logic: 'isTrue',
                        },
                    ],
                    groups: [],
                    timezone: 'America/Toronto',
                    timerange: {
                        logic: 'after',
                        value: moment(this.state.workOrder.Start_Time).isAfter(
                            moment().startOf('hour')
                        )
                            ? moment(this.state.workOrder.Start_Time).valueOf()
                            : moment().startOf('hour').valueOf(),
                    },
                    aggregate: [
                        {
                            join: [],
                            aggregate: {
                                type: 'sum',
                                path: 'value',
                            },
                        },
                    ],
                },
                2
            )
            if (partCountResponse.data.length) {
                this.setState({
                    hourlyActual: partCountResponse.data[0].value,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    async getLast24HourPartData() {
        const timestamp = moment()

        let hourly = {
            partCount: new Array(24),
            dates: new Array(24).fill(''),
            target: new Array(24),
        }

        try {
            let targetRecords = await API.post(
                'historical/raw',
                {
                    query: {
                        name: {$in: ['Scheduled Target']},
                        deviceId: this.props.deviceId,
                        $or: [
                            {
                                timeStart: {$lt: timestamp.toISOString()},
                                timeEnd: {
                                    $gt: moment(timestamp)
                                        .add(-23, 'hours')
                                        .toISOString(),
                                },
                            },
                            {
                                timeEnd: null,
                            },
                        ],
                    },
                },
                2
            )

            targetRecords = targetRecords.map((t) => {
                t.timeStart = moment(t.timeStart)
                if (t.timeEnd === null) {
                    t.timeEnd = moment(timestamp)
                } else {
                    t.timeEnd = moment(t.timeEnd)
                }
                return t
            })

            let {data} = await API.post('historical/aggregate2', {
                timeStart: moment(timestamp)
                    .add(-23, 'hours')
                    .startOf('hour')
                    .toISOString(),
                timeEnd: moment(timestamp).endOf('hour').toISOString(),
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'hour',
                logic: 'count',
            })

            if (
                data.devices[this.props.deviceId]['Part Count'][0].count !==
                undefined
            ) {
                data.devices[this.props.deviceId]['Part Count'] = data.devices[
                    this.props.deviceId
                ]['Part Count'].map((a) => {
                    if (a.count !== undefined) {
                        return a.count
                    } else return a
                })
            }
            hourly.partCount = data.devices[this.props.deviceId]['Part Count']
            hourly.dates = data.dates.map((d) => moment(d))

            for (let i in hourly.dates) {
                const relevantTargets = targetRecords.filter((t) => {
                    if (
                        moment(hourly.dates[i])
                            .startOf('hour')
                            .isSameOrBefore(t.timeEnd) &&
                        moment(hourly.dates[i])
                            .endOf('hour')
                            .isSameOrAfter(t.timeStart)
                    ) {
                        return true
                    } else {
                        return false
                    }
                })
                const target =
                    relevantTargets.reduce((a, b) => a + b.value, 0) /
                    relevantTargets.length

                hourly.target[i] = target
            }
            hourly.dates = hourly.dates.map((d) => d.format('ha'))
        } catch (error) {
            console.log(error)
        }

        this.setState({hourly})
    }

    async pollStatus() {
        try {
            // work order status
            const workOrderDataModel = this.state.dataModels.find(
                (d) => d.name === 'Aiken/Work Orders'
            )
            if (!workOrderDataModel) {
                throw Error('Cannot find Aiken/Work Orders data model')
            }
            const workOrderResponse = await API.post(
                `data-models/${workOrderDataModel._id}/paginate`,
                {
                    filter: [
                        {
                            path: 'End_Time',
                            logic: 'isActive',
                            type: 'DesignatedTimeEnd',
                        },
                        {
                            path: 'Machine.deviceId',
                            logic: 'is',
                            type: 'Text',
                            value: [this.props.deviceId],
                        },
                    ],
                },
                2
            )
            if (workOrderResponse.result.results.length > 0) {
                this.setState({workOrder: workOrderResponse.result.results[0]})
            } else {
                this.setState({workOrder: null})
            }

            // sign in(s) status
            const signInDataModel = this.state.dataModels.find(
                (d) => d.name === 'Aiken/Sign Ins'
            )
            if (!signInDataModel) {
                throw Error('Cannot find Aiken/Sign Ins data model')
            }

            const signInsResponse = await API.post(
                `data-models/${signInDataModel._id}/paginate`,
                {
                    filter: [
                        {
                            path: 'End_Time',
                            logic: 'isActive',
                            type: 'DesignatedTimeEnd',
                        },
                        {
                            path: 'Machine.deviceId',
                            logic: 'is',
                            type: 'Text',
                            value: [this.props.deviceId],
                        },
                    ],
                },
                2
            )

            this.setState({
                signIns: signInsResponse.result.results,
            })

            // live data & auto-sign at end of shift
            let liveData = await API.post('live', {
                deviceId: [this.props.deviceId],
            })
            let activeDowntimeReason = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Downtime Reason',
                        timeEnd: null,
                    },
                },
                2
            )
            liveData = liveData[this.props.deviceId]

            const activeShift =
                liveData.activeShifts.length > 0
                    ? liveData.activeShifts[0]
                    : null

            if (
                this.state.activeShift !== activeShift &&
                this.state.activeShift !== null
            ) {
                // auto sign out
                this.setState({forceSignOut: true})
            }

            this.setState({activeShift})

            const downtime = liveData.activeStates.find(
                    (d) => d.name === 'Downtime'
                ),
                incycle = liveData.activeStates.find(
                    (d) => d.name === 'In-Cycle'
                )

            let status = null,
                stateTimestamp = null,
                headerColor = '',
                reasonCode = null,
                downtimeTimeStart = downtime ? downtime.timestamp : null

            if (!downtime) {
                status = 'RUNNING'
                stateTimestamp = moment(incycle.timestamp)
                headerColor = 'rgb(46, 204, 113)'
            } else if (activeDowntimeReason && activeDowntimeReason.length) {
                status = 'DOWNTIME_WITH_ENTRY'
                stateTimestamp = moment(downtime.timestamp)
                headerColor = '#f0ad4e'
                reasonCode = activeDowntimeReason[0].value
            } else if (
                moment().diff(moment(downtime.timestamp), 'seconds') >
                (this.state.device.downtimeThresholdSeconds || 300)
            ) {
                status = 'DOWNTIME_OVER'
                stateTimestamp = moment(downtime.timestamp)
                headerColor = 'rgb(231, 76, 60)'
            } else {
                status = 'DOWNTIME_UNDER'
                stateTimestamp = moment(downtime.timestamp)
                headerColor = 'rgb(231, 76, 60)'
            }
            this.setState({
                status,
                stateTimestamp,
                headerColor,
                reasonCode,
                downtimeTimeStart,
            })
        } catch (error) {
            console.log(error)
        }
    }

    incrementTimer() {
        const {actualRemaining, actualRemainingTileColor, stateTimestamp} =
            this.state
        this.setState({
            actualRemainingTileColor:
                actualRemaining < 0
                    ? actualRemainingTileColor === '#808080'
                        ? 'red'
                        : '#808080'
                    : '#808080',
            timer: secondsToHHMMSS(
                moment().diff(moment(stateTimestamp), 'seconds')
            ),
        })
    }

    async finishWorkOrder() {
        this.setState({loading: true})
        try {
            const {_id} = this.state.dataModels.find(
                (d) => d.name === 'Aiken/Work Orders'
            )
            if (!_id) {
                throw Error('Cannot find Aiken/Work Orders data model')
            }

            const timeEnd = moment().toISOString()

            let updatedWorkOrder = {
                ...this.state.workOrder,
                End_Time: timeEnd,
            }
            this.setState({workOrder: updatedWorkOrder})

            const scheduledTargetResponse = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Scheduled Target',
                        timeEnd: null,
                    },
                },
                2
            )

            if (scheduledTargetResponse.length > 0) {
                let scheduledTarget = scheduledTargetResponse[0]
                scheduledTarget.timeEnd = timeEnd
                await API.patch(
                    'states/' + scheduledTarget._id,
                    scheduledTarget,
                    2
                )
            }

            await API.patch(
                `data-models/${_id}/edit-record`,
                this.state.workOrder,
                2
            )
            window.location.reload()
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
        }
        this.setState({loading: false})
    }

    toggleDowntimeModal() {
        this.setState({
            downtimeModalOpen: !this.state.downtimeModalOpen,
        })
    }

    toggleReworkModal() {
        this.setState({
            reworkModalOpen: !this.state.reworkModalOpen,
        })
    }

    toggleManualQtyModal() {
        this.setState({
            manualQtyModalOpen: !this.state.manualQtyModalOpen,
        })
    }

    toggleRejectModal() {
        this.setState({
            rejectModalOpen: !this.state.rejectModalOpen,
        })
    }

    toggleSignInModal() {
        this.setState({
            signInModalOpen: !this.state.signInModalOpen,
        })
    }

    toggleWorkOrderModal() {
        this.setState({
            workOrderModalOpen: !this.state.workOrderModalOpen,
        })
    }

    async incrementPartCounter() {
        if (
            this.props.HubbellAikenHMI2ManualPartIncrement &&
            !this.state.loading
        ) {
            this.setState({loading: true})
            try {
                if (this.props.HubbellAikenHMI2PrintLabel) {
                    this.setState({barcodeModalOpen: true})
                }
                API.post(
                    'tablets/data',
                    [
                        {
                            nodeId: this.props.deviceId,
                            timestamp: moment().valueOf(),
                            value: this.state.manualQty,
                            name: 'hmi_part_count',
                        },
                    ],
                    2
                )
            } catch (error) {
                this.setState({loading: false})
                return alert('Could not save part entry. Please try again!')
            }

            setTimeout(async () => {
                try {
                    await this.pollEverything()
                    await this.getOEE()
                } catch (error) {
                    this.setState({loading: false})
                    alert(
                        'Part entry saved but could not update display! Please wait until next data refresh.'
                    )
                }
                this.setState({loading: false})
            }, 7000)
        }
    }

    async pollEverything() {
        try {
            await this.pollStatus()
            await this.getLast24HourPartData()
            await this.getWorkOrderPartData()
            await this.getCurrentWorkOrderHour()
        } catch (error) {
            console.log(error)
        }
    }

    async componentDidMount() {
        try {
            let DA_ZFIN_Label_Info = new GoogleSpreadsheet(
                '1eUz7bHPViJcJ4xs4ghY2JF5SW6P484UYMGfiDdIhx6A'
            )
            DA_ZFIN_Label_Info.useApiKey(
                'AIzaSyCxcLx4KwA183ZbtCEuG_cL3e7pv5GWKNs'
            )
            await DA_ZFIN_Label_Info.loadInfo()
            DA_ZFIN_Label_Info = DA_ZFIN_Label_Info.sheetsByTitle['Sheet1']
            DA_ZFIN_Label_Info = await DA_ZFIN_Label_Info.getRows()

            let Active_DA_ZFIN_Materials = new GoogleSpreadsheet(
                '1pox8xu7gH0UJjmNLAFYoD0jw2sZAYkdmp7OA87cG_K8'
            )
            Active_DA_ZFIN_Materials.useApiKey(
                'AIzaSyCxcLx4KwA183ZbtCEuG_cL3e7pv5GWKNs'
            )
            await Active_DA_ZFIN_Materials.loadInfo()
            Active_DA_ZFIN_Materials =
                Active_DA_ZFIN_Materials.sheetsByTitle['Sheet1']
            Active_DA_ZFIN_Materials = await Active_DA_ZFIN_Materials.getRows()

            this.setState(
                {
                    DA_ZFIN_Label_Info,
                    Active_DA_ZFIN_Materials,
                },
                () => {
                    localStorage['DA_ZFIN_Label_Info'] = DA_ZFIN_Label_Info
                    localStorage['Active_DA_ZFIN_Materials'] =
                        Active_DA_ZFIN_Materials
                }
            )
        } catch (error) {
            console.log(error)
            const DA_ZFIN_Label_Info = localStorage['DA_ZFIN_Label_Info'],
                Active_DA_ZFIN_Materials =
                    localStorage['Active_DA_ZFIN_Materials']

            if (DA_ZFIN_Label_Info && Active_DA_ZFIN_Materials) {
                this.setState({
                    DA_ZFIN_Label_Info,
                    Active_DA_ZFIN_Materials,
                })
            } else {
                alert('Could not load label data. Label printing may not work.')
            }
        }

        let devices = [],
            dataModels = [],
            shifts = []
        try {
            devices = await API.get('devices', 1)
            dataModels = await API.get('data-models', 2)
            shifts = await API.get('shifts', 2)
        } catch (error) {
            console.log(error)
            alert('Could not fetch device info. Check internet connection.')
            return
        }

        this.setState({
            dataModels,
            shifts: shifts.filter((s) =>
                s.assets.includes(this.props.deviceId)
            ),
        })

        const device = devices.find((d) => d.deviceId === this.props.deviceId)
        if (!device) {
            alert('Could not find selected asset in list of assets.')
            return
        } else {
            this.setState({device})
        }

        if (this.props.HubbellAikenHMI2PrintLabel) {
            try {
                const response = await API.post(
                    `data-models/${
                        dataModels.find((a) => a.name === 'Aiken/Printers')._id
                    }/paginate`,
                    {
                        filter: [
                            {
                                type: 'Text',
                                logic: 'contains',
                                value: [device.name],
                                path: 'Asset.name',
                            },
                        ],
                    },
                    2
                )
                if (response.count !== 1) {
                    throw Error('No record found')
                }
                localStorage['printer_ip'] =
                    response.result.results[0].IP_Address
            } catch (error) {
                console.log(error)
                alert(
                    'No printer IP address provided! Please go to Digital Twin > Data > Aiken/Printers and declare a printer address for this machine. Perform log out/in for change to take effect.'
                )
                return
            }
            try {
                const response = await API.post(
                    `data-models/${
                        dataModels.find(
                            (a) => a.name === 'Aiken/Printer Gateway IP'
                        )._id
                    }/paginate`,
                    {
                        filter: [],
                    },
                    2
                )
                if (response.count !== 1) {
                    throw Error('No record found')
                }
                localStorage['printer_gateway_ip'] =
                    response.result.results[0].IP_Address
            } catch (error) {
                console.log(error)
                alert(
                    "No printer IP address provided! Please go to Digital Twin > Data > Aiken/Printer Gateway IP and set the Accumine gateway's printer server address. Perform log out/in for change to take effect."
                )
                return
            }
        }

        setTimeout(() => {
            this.pollEverything()
            this.getOEE()
        }, 5000)

        this.subscriber.add(this.pollEverything, 1000 * 30, 'pollEverything()')
        this.subscriber.add(this.getOEE, 1000 * 60 * 5, 'this.getOEE()')
        this.subscriber.add(this.incrementTimer, 500, 'incrementTimer()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.7

        return (
            <Fragment>
                {this.state.barcodeModalOpen ? (
                    <Barcode
                        DA_ZFIN_Label_Info={this.state.DA_ZFIN_Label_Info}
                        Active_DA_ZFIN_Materials={
                            this.state.Active_DA_ZFIN_Materials
                        }
                        workOrderNumber={this.state.workOrder.Work_Order_Number}
                        material={this.state.workOrder.Part_Number}
                        badgeNumber={this.state.signIns.map(
                            (a) => a.Employee.Employee_ID
                        )}
                        qty={this.state.manualQty}
                        toggleBarcodeModal={() =>
                            this.setState({
                                barcodeModalOpen: !this.state.barcodeModalOpen,
                            })
                        }
                    />
                ) : null}
                {this.state.device ? (
                    <div
                        style={{
                            backgroundColor: 'black',
                            position: 'relative',
                            height,
                            marginTop: 50,
                        }}>
                        <Card style={{height, borderRadius: '5px'}}>
                            <CardHeader
                                style={{
                                    background: this.state.headerColor,
                                    color: '#fff',
                                }}>
                                <Row>
                                    <Col xs="3">
                                        <h2 className="mt-4 mb-0">
                                            <strong>
                                                {this.state.device.name}
                                            </strong>
                                        </h2>
                                    </Col>
                                    <Col xs="3">
                                        <h2 className="mt-4 mb-0">
                                            <strong>
                                                W/O:{' '}
                                                {this.state.workOrder
                                                    ? this.state.workOrder
                                                          .Work_Order_Number
                                                    : 'None'}
                                            </strong>
                                        </h2>
                                    </Col>
                                    <Col xs="4">
                                        <h2 className="mt-4 mb-0">
                                            <strong>
                                                Part:{' '}
                                                {this.state.workOrder
                                                    ? this.state.workOrder
                                                          .Part_Number
                                                    : 'None'}
                                            </strong>
                                        </h2>
                                    </Col>

                                    <Col xs="2">
                                        <h2 className="mt-4 mb-0">
                                            <strong>{this.state.timer}</strong>
                                        </h2>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{height, paddingTop: 0}}>
                                <Row className="m-2 p-0">
                                    {this.props.HubbellAikenHMI2PrintLabel ? (
                                        <>
                                            <Col xs="3" className="m-0 p-0">
                                                <InputGroup className="aikenmanualentryqtyinput">
                                                    <InputGroupAddon addonType="prepend">
                                                        INCREMENT BY
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="number"
                                                        value={
                                                            this.state.manualQty
                                                        }
                                                        onClick={
                                                            this
                                                                .toggleManualQtyModal
                                                        }
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        PIECE(S)
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col xs="2">
                                                <Button
                                                    size="sm"
                                                    block
                                                    color="default"
                                                    onClick={() => {
                                                        if (
                                                            confirm(
                                                                'Please confirm you only need to print labels and not increment the piece counter.'
                                                            )
                                                        ) {
                                                            this.setState({
                                                                barcodeModalOpen: true,
                                                            })
                                                        }
                                                    }}
                                                    disabled={
                                                        !this.state.workOrder
                                                    }>
                                                    PRINT ONLY
                                                </Button>
                                            </Col>
                                        </>
                                    ) : null}
                                    <Col
                                        xs={
                                            this.props
                                                .HubbellAikenHMI2PrintLabel
                                                ? 3
                                                : 4
                                        }>
                                        <Button
                                            block
                                            size="sm"
                                            className="m-0"
                                            color="warning"
                                            onClick={this.toggleDowntimeModal}
                                            disabled={
                                                this.state.status === 'RUNNING'
                                            }>
                                            ENTER DOWNTIME REASON
                                        </Button>
                                    </Col>
                                    <Col
                                        xs={
                                            this.props
                                                .HubbellAikenHMI2PrintLabel
                                                ? 2
                                                : 4
                                        }>
                                        <Button
                                            block
                                            size="sm"
                                            className="m-0"
                                            color="warning"
                                            onClick={() => {
                                                if (
                                                    this.state.workOrder &&
                                                    confirm('Are you sure?')
                                                ) {
                                                    this.finishWorkOrder()
                                                }
                                                if (!this.state.workOrder) {
                                                    this.toggleWorkOrderModal()
                                                }
                                            }}>
                                            {this.state.workOrder
                                                ? 'FINISH WORK ORDER'
                                                : 'START WORK ORDER'}
                                        </Button>
                                    </Col>
                                    <Col
                                        xs={
                                            this.props
                                                .HubbellAikenHMI2PrintLabel
                                                ? 2
                                                : 4
                                        }>
                                        <Button
                                            block
                                            size="sm"
                                            className="m-0"
                                            color="warning"
                                            onClick={this.toggleSignInModal}>
                                            SIGN IN / OUT
                                        </Button>
                                    </Col>
                                </Row>
                                {this.state.workOrder ? (
                                    <>
                                        <Row>
                                            <Col xs="4">
                                                <Row>
                                                    <Col xs="4">
                                                        <div
                                                            onClick={() =>
                                                                this.incrementPartCounter(
                                                                    1
                                                                )
                                                            }
                                                            style={{
                                                                cursor: this
                                                                    .state
                                                                    .loading
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#28a745',
                                                                color: '#fff',
                                                            }}>
                                                            <h3>
                                                                Good
                                                                <br />
                                                                Qty
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#dc3545',
                                                                color: '#fff',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={
                                                                this
                                                                    .toggleRejectModal
                                                            }>
                                                            <h3>
                                                                Scrap
                                                                <br />
                                                                Qty
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#b69329',
                                                                color: '#fff',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={
                                                                this
                                                                    .toggleReworkModal
                                                            }>
                                                            <h3>
                                                                Rework
                                                                <br />
                                                                Qty
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="4">
                                                        <div
                                                            onClick={
                                                                this
                                                                    .incrementPartCounter
                                                            }
                                                            style={{
                                                                cursor: this
                                                                    .state
                                                                    .loading
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .goodQty ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={
                                                                this
                                                                    .toggleRejectModal
                                                            }>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .rejectQty ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={
                                                                this
                                                                    .toggleReworkModal
                                                            }>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .reworkQty ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-1">
                                                    <Col xs="12">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h2 className="pt-2">
                                                                Hourly Actual
                                                            </h2>
                                                            <br />
                                                            <h1
                                                                className=""
                                                                style={{
                                                                    fontSize:
                                                                        '4em',
                                                                }}>
                                                                {this.state
                                                                    .hourlyActual ||
                                                                    0}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="4">
                                                <Row>
                                                    <Col xs="6">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3>
                                                                Target CT
                                                                (Seconds)
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3>
                                                                Actual CT
                                                                (Seconds)
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="6">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .workOrder
                                                                    ? Math.ceil(
                                                                          3600 /
                                                                              this
                                                                                  .state
                                                                                  .workOrder
                                                                                  .Cycles_Per_Hour
                                                                      )
                                                                    : 0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .actualCT ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="12">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    this.state
                                                                        .actualRemainingTileColor,
                                                                color: '#fff',
                                                            }}>
                                                            <h2 className="pt-2">
                                                                Actual Remaining
                                                            </h2>
                                                            <br />
                                                            <h1
                                                                className=""
                                                                style={{
                                                                    fontSize:
                                                                        '4em',
                                                                }}>
                                                                {this.state
                                                                    .actualRemaining ||
                                                                    0}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="4">
                                                <Row>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3>
                                                                Avail
                                                                <br />%
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3>
                                                                Perf
                                                                <br />%
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3>
                                                                Qual
                                                                <br />%
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .oeeAvail ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .oeePerf ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h3 className="pt-2 pb-2">
                                                                {this.state
                                                                    .oeeQual ||
                                                                    0}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="12">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                backgroundColor:
                                                                    '#808080',
                                                                color: '#fff',
                                                            }}>
                                                            <h2 className="pt-2">
                                                                OEE %
                                                            </h2>
                                                            <br />
                                                            <h1
                                                                className=""
                                                                style={{
                                                                    fontSize:
                                                                        '4em',
                                                                }}>
                                                                {this.state
                                                                    .oee || 0}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row style={{height: height * 0.4}}>
                                            <Col
                                                xs="12"
                                                style={{height: '100%'}}>
                                                <PartChart
                                                    hourly={
                                                        this.state.hourly || 0
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <hr />
                                        <h2 className="text-center">
                                            NO WORK ORDER ENTERED
                                        </h2>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </div>
                ) : (
                    <div className="loading" />
                )}

                {this.state.downtimeModalOpen && (
                    <DowntimeEntryModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleDowntimeModal}
                        reasonCode={this.state.reasonCode}
                        forceRefresh={this.pollEverything}
                        downtimeTimeStart={this.state.downtimeTimeStart}
                    />
                )}

                {this.state.rejectModalOpen && (
                    <RejectEntryModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleRejectModal}
                        qty={this.state.rejectQty}
                        forceRefresh={this.pollEverything}
                    />
                )}

                {this.state.reworkModalOpen && (
                    <ReworkEntryModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleReworkModal}
                        qty={this.state.reworkQty}
                        forceRefresh={this.pollEverything}
                    />
                )}

                {this.state.manualQtyModalOpen && (
                    <ManualQtyModal
                        toggleModal={this.toggleManualQtyModal}
                        qty={this.state.manualQty}
                        setQty={(manualQty) => this.setState({manualQty})}
                    />
                )}

                {((this.state.signIns.length === 0 &&
                    this.state.activeShift !== null) ||
                    this.state.forceSignOut ||
                    this.state.signInModalOpen) && (
                    <SignInModal
                        forceSignOut={this.state.forceSignOut}
                        clearForceSignOut={() =>
                            this.setState({forceSignOut: false})
                        }
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleSignInModal}
                        forceRefresh={this.pollEverything}
                        dataModels={this.state.dataModels}
                        signIns={this.state.signIns}
                    />
                )}

                {this.state.workOrderModalOpen ? (
                    <WorkOrderModal
                        deviceName={this.state.device.name}
                        deviceId={this.props.deviceId}
                        workOrder={this.state.workOrder}
                        toggleModal={this.toggleWorkOrderModal}
                        forceRefresh={this.pollEverything}
                        dataModels={this.state.dataModels}
                        signIns={this.state.signIns}
                    />
                ) : null}

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
