import * as API from 'SDK/api'

export default async function (editingDataModel, allDataModels) {
    let types = await API.get('data-models/field-types', 2)

    types = types.map((t) => {
        t.value = t.type
        return t
    })

    if (
        !(
            allDataModels.filter((d) => d._id !== editingDataModel._id).length >
            0
        )
    ) {
        types = types.filter((t) => t.type !== 'ForeignID')
    }

    if (editingDataModel.modelType === 'Event') {
        const timestamp = editingDataModel.fields.find(
            (f) => !f.archived && f.type === 'DesignatedTimestamp'
        )

        if (timestamp) {
            types = types.filter((t) => t.type !== 'DesignatedTimestamp')
        }
    }

    if (editingDataModel.modelType === 'State') {
        const timeStart = editingDataModel.fields.find(
            (f) => !f.archived && f.type === 'DesignatedTimeStart'
        )
        if (timeStart) {
            types = types.filter((t) => t.type !== 'DesignatedTimeStart')
        }
        const timeEnd = editingDataModel.fields.find(
            (f) => !f.archived && f.type === 'DesignatedTimeEnd'
        )
        if (timeEnd) {
            types = types.filter((t) => t.type !== 'DesignatedTimeEnd')
        }
    }

    types.sort((a, b) => a.label.localeCompare(b.label))

    return types
}
