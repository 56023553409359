/* eslint no-new-func: 0 */
/* eslint no-fallthrough: 0 */

const R = require('ramda')

const BaseTransform = require('./base-transform').default

let beforeIndex = (item, arr) => {
    return arr.findIndex((e, i) => {
        let filter = e.timestamp - item.timestamp <= 0
        if (filter && i !== arr.length - 1) {
            filter = filter && arr[i + 1].timestamp - item.timestamp > 0
        }
        return filter
    })
}

/** @class
 * Base class for the pre-processor transform library. Whenever a new input
 * becomes available, the base class will update an array named _current.
 * This array will contain the first value for each channel that occurred after
 * the last outputted value. Any operations to produce an output should use this
 * set of values.
 * @param {Number} inputs - The array of input hashes to expect
 * @param {Number} bufferNumber - The number of processed inputs to keep for
 * each channel
 * @param {Function} transform - The function called to perform the business
 * logic and produce outputs
 */
class Transform extends BaseTransform {
    constructor(id, inputs, bufferNumber) {
        if (!R.is(String, id)) {
            throw new Error('Transform output hash parameter not supported')
        }
        if (!(R.is(Array, inputs) && inputs.length !== 0)) {
            throw new Error('Transform input array parameter not supported')
        }
        if (!(R.is(Number, bufferNumber) && Number.isInteger(bufferNumber))) {
            throw new Error('Transform buffer parameter not supported')
        }
        super()

        this._id = id
        this._inputs = inputs
        this._bufferNumber = bufferNumber

        this._dic = {}
        this._input = new Array(inputs.length)
        for (let i = 0, l = inputs.length; i < l; i++) {
            this._input[i] = []
            this._dic[inputs[i]] = i
        }
        this._output = null
        this._latest = null
        this._ordered = []
        this._current = new Array(inputs.length).fill(null)
        this._lastTimestamps = new Array(inputs.length).fill(-1)
        let push = this.push
        this.push = function (obj) {
            obj.id = this._id
            push.call(this, obj)
        }.bind(this)
    }

    get _synced() {
        return (
            this._input.some((input) => input.length < 1) ||
            this._input.some((input) => input[input.length - 1]._processed)
        )
    }

    _clean() {
        for (let i = 0, l = this._input.length; i < l; i++) {
            let input = this._input[i]
            let j = input.findIndex((v) => !v._processed)
            j = j === -1 ? input.length - 1 : j - 1
            if (j + 1 >= this._bufferNumber) {
                this._input[i] = input.slice(j + 1 - this._bufferNumber)
            }
        }
    }

    _transform(chunk, callback) {
        chunk._processed = false

        if (this._lastTimestamps[this._dic[chunk.id]] > chunk.timestamp) {
            this.emit(
                'error',
                'Timestamp ' +
                    chunk.timestamp +
                    ' before the current processed time ' +
                    this._lastTimestamps[this._dic[chunk.id]] +
                    ', discarding.'
            )
            this.emit('error', chunk)
            return callback()
        } else {
            this._lastTimestamps[this._dic[chunk.id]] = chunk.timestamp
        }

        if (this._input[this._dic[chunk.id]].length === 0) {
            this._input[this._dic[chunk.id]].push(chunk)

            if (this._input.every((input) => input.length !== 0)) {
                let latest = this._input
                    .map((input) => input[0])
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .reverse()[0]
                for (let i = 0, l = this._input.length; i < l; i++) {
                    let input = this._input[i]
                    input = input.slice(beforeIndex(latest, input))
                    let item = input[0]
                    item.timestamp = latest.timestamp
                    this._current[this._dic[item.id]] = item
                    this._input[i] = input
                    this._ordered = this._ordered.concat(this._input[i])
                }
            }
        } else {
            this._input[this._dic[chunk.id]].push(chunk)
            this._ordered.push(chunk)
        }
        this._ordered.sort((a, b) => a.timestamp - b.timestamp)
        try {
            while (!this._synced) {
                if (this._ordered.length !== 0) {
                    let earliest = this._ordered[0]
                    this._current[this._dic[earliest.id]] = earliest
                    this._latest = earliest
                }
                this._trans()
                for (let i = 0, l = this._current.length; i < l; i++) {
                    this._current[i]._processed = true
                }
                this._ordered = this._ordered.filter((v) => !v._processed)
            }
            this._clean()
        } catch (error) {
            this.emit('error', error)
        }
        callback()
    }
    _trans() {
        return
    }
    toJSON() {
        let stringify = {}
        stringify._id = this._id
        stringify._inputs = this._inputs
        stringify._bufferNumber = this._bufferNumber
        stringify._dic = this._dic
        stringify._input = this._input
        stringify._output = this._output
        stringify._latest = this._latest
        stringify._ordered = this._ordered
        stringify._current = this._current
        stringify._lastTimestamps = this._lastTimestamps
        return stringify
    }
    static fromJSON(trans, obj) {
        trans._id = obj._id
        if (obj._inputs) {
            trans._inputs = obj._inputs
        }
        if (obj._bufferNumber) {
            trans._bufferNumber = obj._bufferNumber
        }
        if (obj._dic) {
            trans._dic = obj._dic
        }
        if (obj._input) {
            trans._input = obj._input
        }
        if (obj._output) {
            trans._output = obj._output
        }
        if (obj._latest) {
            trans._latest = obj._latest
        }
        if (obj._ordered) {
            trans._ordered = obj._ordered
        }
        if (obj._current) {
            trans._current = obj._current
        }
        if (obj._lastTimestamps) {
            trans._lastTimestamps = obj._lastTimestamps
        }
    }
}

/** @class
 * Passes the input object to the output object without modification
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class PassThrough extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new PassThrough('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Performs the logical OR operator to the current inputs
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Or extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = this._current.some((e) => e.value === true)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new Or('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Performs the logical XOR operator to the current inputs
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Xor extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value =
            !this._current.every((e) => e.value === true) &&
            !this._current.every((e) => e.value === false)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new Xor('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Performs the logical AND operator to the current inputs
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class And extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = this._current.every((e) => e.value === true)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new And('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Performs the logical NOT operator to the current inputs
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Not extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 0)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = !this._output.value
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new Not('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Flip flops the output between TRUE and FALSE when the input goes from
 * FALSE to TRUE. Example: Input goes FALSE to TRUE -> Output becomes TRUE,
 * the input then goes to FALSE and back to TRUE -> Output becomes FALSE.
 * The output changes based on the input signal EDGE or transition.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class RisingEdge extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        let output = R.omit(['_processed'], this._latest)
        if (!this._output) {
            output.value = false
            this.push(output)
            this._output = output
        } else {
            let last = this._input[0][this._input[0].length - 2]
            if (last.value !== output.value) {
                output.value = last.value === false && output.value === true
                this.push(output)
                this._output = output
            }
        }
    }
    static fromJSON(obj) {
        let trans = new RisingEdge('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Flip flops the output between TRUE and FALSE when the input goes from
 * FALSE to TRUE. Example: Input goes TRUE to FALSE -> Output becomes TRUE,
 * the input then goes to TRUE and back to FALSE -> Output becomes FALSE.
 * The output changes based on the input signal EDGE or transition.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class FallingEdge extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        let output = R.omit(['_processed'], this._latest)
        if (!this._output) {
            output.value = false
            this.push(output)
            this._output = output
        } else {
            let last = this._input[0][this._input[0].length - 2]
            if (last.value !== output.value) {
                output.value = last.value === true && output.value === false
                this.push(output)
                this._output = output
            }
        }
    }
    static fromJSON(obj) {
        let trans = new FallingEdge('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Negates the current input (i.e. multplies by -1)
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Negate extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 0)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value *= -1
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new Negate('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Adds the values of all current inputs
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Add extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = this._current.reduce((t, e) => t + e.value, 0)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new Add('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Multiplies the values of all current inputs
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Multiply extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = this._current.reduce((t, e) => t * e.value, 1)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new Multiply('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Takes the absolute value of the provided input
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class AbsoluteValue extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = Math.abs(this._output.value)
        this.push(this._output)
    }
    static fromJSON(obj) {
        let trans = new AbsoluteValue('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Returns true between a start signal from input 1 and end signal from input 2
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Between extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
    }
    _trans() {
        let start = R.omit(['_processed'], this._current[0])
        let end = R.omit(['_processed'], this._current[1])
        let output = R.omit(['_processed'], this._latest)
        if (!this._output) {
            console.log(start)
            output.value = Boolean(start.value)
            this.push(output)
            this._output = output
        } else {
            if (start.value) {
                output.value = true
            } else if (end.value) {
                output.value = false
            } else {
                output.value = this._output.value
            }
            this.push(output)
            this._output = output
        }
    }
    static fromJSON(obj) {
        let trans = new Between('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Merges all inputs into one output without modification
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Merge extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 1)
        for (let i = 0, l = inputs.length; i < l; i++) {
            this._transform(
                {
                    id: inputs[i],
                    timestamp: 0,
                    value: null,
                },
                () => {}
            )
        }
    }
    _trans() {
        let output = R.omit(['_processed'], this._latest)
        if (output.value !== null) {
            this.push(output)
            this._output = output
        }
    }
    static fromJSON(obj) {
        let trans = new Merge('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Merges duration items with gaps less than the specified value in
 * milliseconds
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Function} params.a - The maximum time of the gap in
 * milliseconds between two consecutive durations to merge
 */
class MergeDuration extends Transform {
    constructor(id, inputs, params = {a: 1000}) {
        super(id, inputs, 1)
        this._time = params.a
    }
    _trans() {
        if (!this._output) {
            this._output = R.omit(['_processed'], this._latest)
        } else {
            let gap =
                this._latest.timestamp -
                this._output.timestamp -
                this._output.value
            if (gap <= this._time) {
                this._output.value += gap + this._latest.value
            } else {
                this.push(this._output)
                this._output = R.omit(['_processed'], this._latest)
            }
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._time = this._time
        return stringify
    }
    static fromJSON(obj) {
        let trans = new MergeDuration('', [''])
        super.fromJSON(trans, obj)
        if (obj._time) {
            trans._time = obj._time
        }
        return trans
    }
}

/** @class
 * Modifies the input based on a provided function. The entire object is
 * passed to the function and can be modified arbitrarily.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Function} params.a - Function to call to modify the input object.
 * Accepts one argument: the current input. Should return the modified object.
 */
class Map extends Transform {
    constructor(id, inputs, params = {a: (b) => b}) {
        super(id, inputs, 0)
        this._map = new Function('return ' + params.a)().bind(this)
        this._mapString = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output = this._map(this._output)
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._mapString = this._mapString
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Map('', [''])
        super.fromJSON(trans, obj)
        if (obj._mapString) {
            trans._map = new Function('return ' + obj._mapString)().bind(trans)
            trans._mapString = obj._mapString
        }
        return trans
    }
}

/** @class
 * Computes an n point moving average according the formula:
 @example
 * y(n) = 1/N * SUM from n = n+(N-1)/2 to n-(N-1)/2 of x(n)
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The number of points to compute the moving
 * average
 */
class MovingAverage extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, params.a - 1)
        this._points = params.a
        this._runningSum = 0
        this._pool = []
    }
    _trans() {
        let points = this._points
        let next = R.omit(['_processed'], this._latest)
        let input = this._input[0]

        this._pool.push(next)
        this._runningSum += next.value

        if (this._pool.length >= points) {
            this._output = R.omit(
                ['_processed'],
                input[input.length - ((points - 1) / 2 + 1)]
            )
            this._output.value = this._runningSum / points
            this.push(this._output)

            let last = this._pool.shift()
            this._runningSum -= last.value
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._points = this._points
        stringify._runningSum = this._runningSum
        stringify._pool = this._pool
        return stringify
    }
    static fromJSON(obj) {
        let trans = new MovingAverage('', [''])
        super.fromJSON(trans, obj)
        if (obj._points) {
            trans._points = obj._points
        }
        if (obj._runningSum) {
            trans._runningSum = obj._runningSum
        }
        if (obj._pool) {
            trans._pool = obj._pool
        }
        return trans
    }
}

/** @class
 * Computes an n point moving average according the formula:
 @example
 * y(n) = 1/N * SUM from n = n to n-N of x(n)
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The number of points to compute the moving
 * average
 */
class FrontMovingAverage extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, params.a - 1)
        this._points = params.a
        this._runningSum = 0
        this._pool = []
    }
    _trans() {
        let points = this._points
        let next = R.omit(['_processed'], this._latest)

        this._pool.push(next)
        this._runningSum += next.value

        if (this._pool.length >= points) {
            this._output = next
            this._output.value = this._runningSum / points
            this.push(this._output)

            let last = this._pool.shift()
            this._runningSum -= last.value
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._points = this._points
        stringify._runningSum = this._runningSum
        stringify._pool = this._pool
        return stringify
    }
    static fromJSON(obj) {
        let trans = new FrontMovingAverage('', [''])
        super.fromJSON(trans, obj)
        if (obj._points) {
            trans._points = obj._points
        }
        if (obj._runningSum) {
            trans._runningSum = obj._runningSum
        }
        if (obj._pool) {
            trans._pool = obj._pool
        }
        return trans
    }
}

/** @class
 * Computes a rolling average keeping up to a maximum of 'a' values.
 * The current average will be computed even if there are not 'a' values
 * yet.
 @example
 * y(n) = 1/N * SUM from n = n to n-N of x(n)
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The number of points to compute the moving
 * average
 */
class FrontRollingAverage extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, params.a - 1)
        this._points = params.a
        this._runningSum = 0
        this._pool = []
    }
    _trans() {
        let points = this._points
        let next = R.omit(['_processed'], this._latest)

        this._pool.push(next)
        this._runningSum += next.value

        this._output = next
        this._output.value = this._runningSum / this._pool.length
        this.push(this._output)

        if (this._pool.length >= points) {
            let last = this._pool.shift()
            this._runningSum -= last.value
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._points = this._points
        stringify._runningSum = this._runningSum
        stringify._pool = this._pool
        return stringify
    }
    static fromJSON(obj) {
        let trans = new FrontRollingAverage('', [''])
        super.fromJSON(trans, obj)
        if (obj._points) {
            trans._points = obj._points
        }
        if (obj._runningSum) {
            trans._runningSum = obj._runningSum
        }
        if (obj._pool) {
            trans._pool = obj._pool
        }
        return trans
    }
}

/** @class
 * Counts the non-zero inputs. Does not distinguish between different non-zero
 * inputs.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The number of items to count before emitting an
 * output
 */
class Count extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, 0)
        this._count = 0
        this._num = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        if (this._output.value) {
            this._output.value = ++this._count
            if (this._count === this._num) {
                this._count = 0
                this.push(this._output)
            }
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._count = this._count
        stringify._num = this._num
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Count('', [''])
        super.fromJSON(trans, obj)
        if (obj._count) {
            trans._count = obj._count
        }
        if (obj._num) {
            trans._num = obj._num
        }
        return trans
    }
}

/** @class
 * Counts the non-zero inputs within a configurable interval in milliseconds.
 * Does not distinguish between different non-zero inputs.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The interval in milliseconds
 */
class CountByInterval extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, 0)
        this._count = 0
        this._interval = params.a
        this._timestamp = 0
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)

        if (!this._output.value) {
            if (
                this._timestamp !== 0 &&
                this._output.timestamp - this._timestamp >= this._interval
            ) {
                let currentTimestamp = this._output.timestamp
                this._output.value = this._count
                this._output.timestamp = this._timestamp
                this.push(R.clone(this._output))

                this._count = 0
                this._timestamp += this._interval

                while (currentTimestamp - this._timestamp >= this._interval) {
                    this._output.value = 0
                    this._output.timestamp = this._timestamp
                    this.push(R.clone(this._output))
                    this._timestamp += this._interval
                }
            }
        } else {
            if (this._timestamp === 0) {
                this._timestamp =
                    Math.floor(this._output.timestamp / this._interval) *
                    this._interval
                this._count++
            } else if (
                this._output.timestamp - this._timestamp <
                this._interval
            ) {
                this._count++
            } else {
                let currentTimestamp = this._output.timestamp
                this._output.value = this._count
                this._output.timestamp = this._timestamp
                this.push(R.clone(this._output))

                this._count = 1
                this._timestamp += this._interval

                while (currentTimestamp - this._timestamp >= this._interval) {
                    this._output.value = 0
                    this._output.timestamp = this._timestamp
                    this.push(R.clone(this._output))
                    this._timestamp += this._interval
                }
            }
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._count = this._count
        stringify._interval = this._interval
        stringify._timestamp = this._timestamp
        return stringify
    }
    static fromJSON(obj) {
        let trans = new CountByInterval('', [''])
        super.fromJSON(trans, obj)
        if (obj._count) {
            trans._count = obj._count
        }
        if (obj._interval) {
            trans._interval = obj._interval
        }
        if (obj._timestamp) {
            trans._timestamp = obj._timestamp
        }
        return trans
    }
}

/** @class
 * Calculates the duration in milliseconds of a non-zero input.
 * Does distinguish between different non-zero inputs. Example: first
 * input was value 5 and 3 seconds long. Output will be 3000. Second input
 * was 2 and 5 seconds long. Output will be 5000.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params - There are no parameters for this transform
 */
class Duration extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 0)
    }
    _trans() {
        let output = R.omit(['_processed'], this._latest)
        if (!this._output) {
            this._output = output
        } else {
            if (this._output.value) {
                this._output.value = output.timestamp - this._output.timestamp
                this.push(this._output)
            }
            this._output = output
        }
    }
    static fromJSON(obj) {
        let trans = new Duration('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Counts the duration of non-zero inputs within a configurable interval in
 * milliseconds. Does not distinguish between different non-zero inputs.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The interval in milliseconds
 */
class DurationByInterval extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, 0)

        this._duration = 0
        this._lastValue = 0
        this._lastTimestamp = 0
        this._timestamp = 0
        this._interval = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)

        if (this._timestamp === 0) {
            this._timestamp =
                Math.floor(this._output.timestamp / this._interval) *
                this._interval
            this._lastValue = this._output.value
            this._lastTimestamp = this._output.timestamp
        } else if (this._output.timestamp - this._timestamp < this._interval) {
            if (this._lastValue) {
                this._duration += this._output.timestamp - this._lastTimestamp
            }
            this._lastValue = this._output.value
            this._lastTimestamp = this._output.timestamp
        } else {
            let currentTimestamp = this._output.timestamp
            let currentValue = this._output.value

            if (this._lastValue) {
                this._duration +=
                    this._timestamp + this._interval - this._lastTimestamp
            }
            this._output.value = this._duration / this._interval
            this._output.timestamp = this._timestamp
            this.push(R.clone(this._output))

            this._duration = 0
            this._timestamp += this._interval

            while (currentTimestamp - this._timestamp >= this._interval) {
                this._output.value = this._lastValue ? 1 : 0
                this._output.timestamp = this._timestamp
                this.push(R.clone(this._output))
                this._timestamp += this._interval
            }
            if (this._lastValue) {
                this._duration +=
                    this._timestamp + this._interval - currentTimestamp
            }
            this._lastValue = currentValue
            this._lastTimestamp = currentTimestamp
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._duration = this._duration
        stringify._lastValue = this._lastValue
        stringify._lastTimestamp = this._lastTimestamp
        stringify._timestamp = this._timestamp
        stringify._interval = this._interval
        return stringify
    }
    static fromJSON(obj) {
        let trans = new DurationByInterval('', [''])
        super.fromJSON(trans, obj)
        if (obj._duration) {
            trans._duration = obj._duration
        }
        if (obj._lastValue) {
            trans._lastValue = obj._lastValue
        }
        if (obj._lastTimestamp) {
            trans._lastTimestamp = obj._lastTimestamp
        }
        if (obj._timestamp) {
            trans._timestamp = obj._timestamp
        }
        if (obj._interval) {
            trans._interval = obj._interval
        }
        return trans
    }
}

/** @class
 * Filters the input based on a provided function
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Function} params.a - The function used to perform the filtering.
 * This should return TRUE or FALSE.
 */
class Filter extends Transform {
    constructor(id, inputs, params = {a: () => true}) {
        super(id, inputs, 0)
        this._filter = new Function('return ' + params.a)().bind(this)
        this._filterString = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        if (this._filter(this._output)) {
            this.push(this._output)
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._filterString = this._filterString
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Filter('', [''])
        super.fromJSON(trans, obj)
        if (obj._filterString) {
            trans._filter = new Function('return ' + obj._filterString)().bind(
                trans
            )
            trans._filterString = obj._filterString
        }
        return trans
    }
}

/** @class
 * Filters inputs with duplicate, consecutive values
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Function} params.a - There are no parameters for this transform
 */
class FilterDuplicateValues extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 0)
    }
    _trans() {
        if (!this._output) {
            this._output = R.omit(['_processed'], this._latest)
            this.push(this._output)
        } else {
            if (this._output.value !== this._latest.value) {
                this._output = R.omit(['_processed'], this._latest)
                this.push(this._output)
            }
        }
    }
    static fromJSON(obj) {
        let trans = new FilterDuplicateValues('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Filters inputs with duplicate, consecutive timestamps
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Function} params.a - There are no parameters for this transform
 */
class FilterDuplicateTimestamps extends Transform {
    constructor(id, inputs, params = {}) {
        super(id, inputs, 0)
    }
    _trans() {
        if (this._output && this._latest.timestamp !== this._output.timestamp) {
            this.push(this._output)
        }
        this._output = R.omit(['_processed'], this._latest)
    }
    static fromJSON(obj) {
        let trans = new FilterDuplicateTimestamps('', [''])
        super.fromJSON(trans, obj)
        return trans
    }
}

/** @class
 * Performs a thresholding of the input based on a provided array of
 * thresholds.
 * Example:
 * @example
 * a = [5, 10] the output will be 0, 1, or 2 representing input <= 5,
 * 5 < input <= 10, and input > 10.
 * @example
 * a = [5] the output will be 0, 1 representing input <= 5, and input > 5.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - Array of numbers used to identify threshold
 * regions
 */
class Threshold extends Transform {
    constructor(id, inputs, params = {a: []}) {
        super(id, inputs, 0)
        this._thresholds = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        for (let j = 0, l = this._thresholds.length; j < l; j++) {
            if (this._output.value <= this._thresholds[j]) {
                this._output.value = j
                break
            } else if (j === l - 1) {
                this._output.value = j + 1
                break
            }
        }
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._thresholds = this._thresholds
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Threshold('', [''])
        super.fromJSON(trans, obj)
        if (obj._thresholds) {
            trans._thresholds = obj._thresholds
        }
        return trans
    }
}

/** @class
 * Modifies the input value based on a provided function. Can be used to
 * parse strings or visa versa. Can perform arbitrary modification of the
 * input value. Only the input value is passed to the function, not the
 * entire input object.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Function} params.a - Function to call to modify the input value.
 * Accepts one argument: the value of the current input. Should return the
 * changed value.
 */
class Truthy extends Transform {
    constructor(id, inputs, params = {a: (b) => !!b}) {
        super(id, inputs, 0)
        this._truthy = new Function('return ' + params.a)().bind(this)
        this._truthyString = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value = this._truthy(this._output.value)
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._truthyString = this._truthyString
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Truthy('', [''])
        super.fromJSON(trans, obj)
        if (obj._truthyString) {
            trans._truthy = new Function('return ' + obj._truthyString)().bind(
                trans
            )
            trans._truthyString = obj._truthyString
        }
        return trans
    }
}

/** @class
 * Adds a constant value to the input.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The constant value to add
 */
class AddConstant extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, 0)
        this._constant = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value += this._constant
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._constant = this._constant
        return stringify
    }
    static fromJSON(obj) {
        let trans = new AddConstant('', [''])
        super.fromJSON(trans, obj)
        if (obj._constant) {
            trans._constant = obj._constant
        }
        return trans
    }
}

/** @class
 * Multiplies the input by a constant value.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The constant value to multiply
 */
class MultiplyConstant extends Transform {
    constructor(id, inputs, params = {a: 1}) {
        super(id, inputs, 0)
        this._constant = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.value *= this._constant
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._constant = this._constant
        return stringify
    }
    static fromJSON(obj) {
        let trans = new MultiplyConstant('', [''])
        super.fromJSON(trans, obj)
        if (obj._constant) {
            trans._constant = obj._constant
        }
        return trans
    }
}

/** @class
 * Adds time-based key-value pairs to the first input.
 * @example
 * a = ['shift', 'operator'],
 * inputs = [
 *   {
 *     name: 'test1',
 *     value: 5,
 *     timestamp: 3,
 *   },
 *   {
 *     name: 'shift',
 *     value: 'first shift',
 *     timestamp: 1,
 *   },
 *   {
 *     name: 'operator',
 *     value: 'frank',
 *     timestamp: 2,
 *   },
 *   {
 *     name: 'operator',
 *     value: 'joe',
 *     timestamp: 6,
 *   }
 * ]
 * output = {
 *   name: 'test1',
 *   value: 5,
 *   timestamp: 3,
 *   shift: 'first shift',
 *   operator: 'frank',
 * }
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - An array of strings used to create a key for the
 * all input channels larger than 0. The length of a should be n - 1.
 */
class AddAttribute extends BaseTransform {
    constructor(id, inputs, params = {a: []}) {
        super()

        let a = params.a
        this._id = id
        this._tags = a.reduce((obj, v) => {
            obj[v] = null
            return obj
        }, {})

        let push = this.push
        this.push = function (obj) {
            obj.id = this._id
            push.call(this, obj)
        }.bind(this)
    }
    _transform(chunk, callback) {
        if (this._tags[chunk.name] === undefined) {
            let output = Object.assign({}, chunk, this._tags)
            this.push(output)
        } else {
            this._tags[chunk.name] = chunk.value
        }
        callback()
    }
    toJSON() {
        let stringify = {}
        stringify._id = this._id
        stringify._tags = this._tags
        return stringify
    }
    static fromJSON(obj) {
        let trans = new AddAttribute('', [''])
        if (obj._id) {
            trans._id = obj._id
        }
        if (obj._tags) {
            trans._tags = obj._tags
        }
        return trans
    }
}

/** @class
 * If the input value is non-zero, the output will be FALSE. If the
 * input value is zero for longer than the chosen interval, the
 * output will change to TRUE.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The interval in milliseconds
 */
class Timeout extends Transform {
    constructor(id, inputs, params = {a: 1000}) {
        super(id, inputs, 0)
        this._interval = params.a
        this._timer = null
    }
    _trans() {
        let output = R.omit(['_processed'], this._latest)
        let timeout

        if (!this._output || output.value) {
            this._output = output
            this._output.value = false
            this.push(R.clone(this._output))

            timeout = R.clone(this._output)
            timeout.timestamp += this._interval
            timeout.value = true

            clearTimeout(this._timer)
            this._timer = setTimeout(
                function () {
                    this._output = timeout
                    this.push(R.clone(this._output))
                }.bind(this),
                this._interval
            )
        } else {
            let v = this._output.value
            this._output = output
            this._output.value = v
            this.push(R.clone(this._output))
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._interval = this._interval
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Timeout('', [''])
        super.fromJSON(trans, obj)
        if (obj._interval) {
            trans._interval = obj._interval
        }
        return trans
    }
}

/** @class
 * Produces a uniform time series from non-uniform time series data. Outputs are
 * produced at regular intervals every 'a' milliseconds after the first input.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - Interval in milliseconds between output values
 */
class UniformTime extends Transform {
    constructor(id, inputs, params = {a: 1000}) {
        super(id, inputs, 0)
        this._interval = params.a
    }
    _trans() {
        if (!this._output) {
            this._output = R.omit(['_processed'], this._latest)
            this.push(this._output)
        } else {
            let output = R.omit(['_processed'], this._latest)
            let diff = output.timestamp - this._output.timestamp
            while (diff > this._interval) {
                this._output = R.clone(this._output)
                this._output.timestamp += this._interval
                this.push(this._output)
                diff -= this._interval
            }
            let ts = this._output.timestamp + this._interval
            this._output = output
            this._output.timestamp = ts
            this.push(this._output)
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._interval = this._interval
        return stringify
    }
    static fromJSON(obj) {
        let trans = new UniformTime('', [''])
        super.fromJSON(trans, obj)
        if (obj._interval) {
            trans._interval = obj._interval
        }
        return trans
    }
}

/** @class
 * Rounds the input timestamp based on a minimum value and unit. Example: a = 5,
 * b = 'seconds', the input timestamp will be rounded to the nearest multiple of
 * 5 seconds. Useful to perform before the UniformTime transform if multiple
 * inputs are being manipulated (so they all have the same timestamps).
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - The minimum value to round
 * @param {String} params.b - The unit of the value 'a', possible values are
 * 'hours', 'minutes', 'seconds', 'milliseconds'.
 */
class RoundTime extends Transform {
    constructor(id, inputs, params = {a: 1000, b: 'milliseconds'}) {
        super(id, inputs, 0)
        this._interval = params.a
        switch (params.b) {
            case 'hours':
                this._interval *= 60
            case 'minutes':
                this._interval *= 60
            case 'seconds':
                this._interval *= 1000
        }
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.timestamp =
            Math.round(this._output.timestamp / this._interval) * this._interval
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._interval = this._interval
        return stringify
    }
    static fromJSON(obj) {
        let trans = new RoundTime('', [''])
        super.fromJSON(trans, obj)
        if (obj._interval) {
            trans._interval = obj._interval
        }
        return trans
    }
}

/** @class
 * Holds the output at the value of the last input until at least a given number
 * of milliseconds have passed (similar to debouncing)
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - Interval in milliseconds to hold
 */
class HoldTime extends Transform {
    constructor(id, inputs, params = {a: 1000}) {
        super(id, inputs, 0)
        this._interval = params.a
        this._difference = params.a
    }
    _trans() {
        let output = R.omit(['_processed'], this._latest)
        if (!this._output) {
            this._difference = this._interval

            this.push(output)
            this._output = R.clone(output)
        } else {
            if (output.value === this._output.value) {
                this._difference -= output.timestamp - this._output.timestamp

                this.push(output)
                this._output.timestamp = output.timestamp
            } else {
                if (
                    output.timestamp - this._output.timestamp >=
                    this._difference
                ) {
                    this._difference = this._interval

                    this.push(output)
                    this._output = output
                } else {
                    this._difference -=
                        output.timestamp - this._output.timestamp

                    this._output.timestamp = output.timestamp
                }
            }
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._interval = this._interval
        stringify._difference = this._difference
        return stringify
    }
    static fromJSON(obj) {
        let trans = new HoldTime('', [''])
        super.fromJSON(trans, obj)
        if (obj._interval) {
            trans._interval = obj._interval
        }
        if (obj._difference) {
            trans._difference = obj._difference
        }
        return trans
    }
}

/** @class
 * Adds a given number of milliseconds to the input value timestamp.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - Interval in milliseconds to add
 */
class DelayTime extends Transform {
    constructor(id, inputs, params = {a: 1000}) {
        super(id, inputs, 0)
        this._interval = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.timestamp += this._interval
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._interval = this._interval
        return stringify
    }
    static fromJSON(obj) {
        let trans = new DelayTime('', [''])
        super.fromJSON(trans, obj)
        if (obj._interval) {
            trans._interval = obj._interval
        }
        return trans
    }
}

/** @class
 * Subracts a given number of milliseconds to the input value timestamp.
 * @param {String} id - The index of the output values produced by this
 * transform (used in the next transform in the pipeline)
 * @param {Array} inputs - The array of input hashes to expect
 * @param {Object} params
 * @param {Number} params.a - Interval in milliseconds to subtract
 */
class AdvanceTime extends Transform {
    constructor(id, inputs, params = {a: 1000}) {
        super(id, inputs, 0)
        this._interval = params.a
    }
    _trans() {
        this._output = R.omit(['_processed'], this._latest)
        this._output.timestamp -= this._interval
        this.push(this._output)
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._interval = this._interval
        return stringify
    }
    static fromJSON(obj) {
        let trans = new AdvanceTime('', [''])
        super.fromJSON(trans, obj)
        if (obj._interval) {
            trans._interval = obj._interval
        }
        return trans
    }
}

class TimeSeriesCluster extends Transform {
    constructor(
        id,
        inputs,
        params = {
            a: 500,
            b: 1.0,
            c: 30,
            d: 50,
            e: 10,
        }
    ) {
        super(id, inputs, 0)

        this._alpha = 0.25
        this._numBins = 3
        this._numBinsTake = 2
        this._window = 10
        this._minDensity = this._window * 0.5

        this._minimumThreshold = params.a
        this._sampleTime = params.b
        this._filter = params.c
        this._maxDistance = params.d
        this._minSlope = params.e

        this._cut = []
        this._cutting = false
        this._last = null
    }
    _trans() {
        let current = R.omit(['_processed'], this._latest)
        current.value = (current.value * 1000) / 0.4

        let minimumThreshold = this._minimumThreshold
        if (this._last != null) {
            current.value =
                current.value * this._alpha +
                this._last.value * (1 - this._alpha)
        }
        this._last = current

        if (current.value < minimumThreshold * 1.1) {
            if (this._cutting) {
                if (this._cut.length >= this._filter) {
                    let cuts = this._analyseCut()
                    for (let i = 0, l = cuts.length; i < l; i++) {
                        this._output = R.clone(cuts[i][0])
                        this._output.value = 1
                        this._output.duration =
                            cuts[i][cuts[i].length - 1].timestamp -
                            cuts[i][0].timestamp
                        this.push(this._output)
                    }
                }
                this._cutting = false
                this._cut = []
            }
        } else {
            this._cutting = true
            this._cut.push(current)
        }
    }
    _analyseCut() {
        let cut = this._cut
        let partitions = [[]]
        let partitionIndex = 0
        let window = this._window
        let minSlope = this._minSlope
        let maxDistance = this._maxDistance
        let minDensity = this._minDensity
        let densityFilter = this._filter

        for (let i = 0, l = cut.length; i < l; i++) {
            let density = 0

            let lowerBound = Math.round(i - window / 2)
            let upperBound = Math.round(i + window / 2)
            if (lowerBound < 0) lowerBound = 0
            if (upperBound > l) upperBound = l

            let slopeLeft = this._calculateAverageSlope(
                cut.slice(lowerBound, i)
            )
            let slopeRight = this._calculateAverageSlope(
                cut.slice(i, upperBound)
            )
            let weight = 1

            if (slopeLeft < -minSlope && slopeRight > minSlope) {
                weight = 250
            }
            for (let j = lowerBound; j < upperBound; j++) {
                if (
                    this._calculateDistance(cut[i], cut[j]) * weight <
                    maxDistance
                ) {
                    density++
                }
            }
            if (density >= minDensity) {
                partitions[partitionIndex].push(cut[i])
            } else {
                let cutLength = partitions[partitionIndex].length
                if (cutLength === 0) {
                    continue
                } else if (cutLength < densityFilter) {
                    partitions.pop()
                    partitionIndex--
                }
                partitions.push([])
                partitionIndex++
            }
        }
        if (partitions[partitionIndex].length < densityFilter) {
            partitions.pop()
            partitionIndex--
        }

        if (partitionIndex > 0) {
            let averages = []
            for (let i = 0, l = partitions.length; i < l; i++) {
                averages.push(this._calculateAverage(partitions[i]))
            }
            let binIndexes = this._binPartitions(averages)
            partitions = partitions.filter((partition, index) =>
                binIndexes.includes(index)
            )
        }
        return partitions
    }
    _binPartitions(averages) {
        let numBins = this._numBins
        let numBinsTake = this._numBinsTake
        let averagesLength = averages.length
        let average =
            averages.reduce((acc, val) => acc + val, 0) / averagesLength
        let minRange = 0.9 * average
        let maxRange = 1.1 * average
        let step = (maxRange - minRange) / (this._numBins - 1)
        let levels = new Array(numBins - 1).fill(minRange + 0.5 * step)

        for (let i = 0; i < numBins - 1; i++) {
            levels[i] += i * step
        }

        let bins = []
        for (let i = 0; i < numBins; i++) {
            bins.push([])
            if (i === 0) {
                for (let j = 0; j < averagesLength; j++) {
                    if (averages[j] <= levels[i]) {
                        bins[i].push(j)
                    }
                }
            } else if (i === numBins - 1) {
                for (let j = 0; j < averagesLength; j++) {
                    if (averages[j] > levels[i - 1]) {
                        bins[i].push(j)
                    }
                }
            } else {
                for (let j = 0; j < averagesLength; j++) {
                    if (
                        averages[j] > levels[i - 1] &&
                        averages[j] <= levels[i]
                    ) {
                        bins[i].push(j)
                    }
                }
            }
        }

        for (let i = 0; i < numBins - numBinsTake; i++) {
            bins.shift()
        }
        return bins.reduce((acc, val) => acc.concat(val), [])
    }
    _calculateDistance(p1, p2) {
        return Math.sqrt(
            Math.pow(p1.value - p2.value, 2) +
                Math.pow(
                    (p1.timestamp - p2.timestamp) / 1000 / this._sampleTime,
                    2
                )
        )
    }
    _calculateAverage(region) {
        let N = region.length
        if (N === 0) {
            return 0
        } else if (N === 1) {
            return region[0].value
        } else {
            let sum = 0
            for (let i = 0; i < N; i++) {
                sum += region[i].value
            }
            return sum / N
        }
    }
    _calculateAverageSlope(region) {
        let N = region.length
        if (N <= 1) {
            return 0
        } else {
            let sum = 0
            for (let i = 0; i < N - 1; i++) {
                sum += region[i + 1].value - region[i].value
            }
            return sum / (N - 1)
        }
    }
    toJSON() {
        let stringify = super.toJSON()
        stringify._alpha = this._alpha
        stringify._numBins = this._numBins
        stringify._numBinsTake = this._numBinsTake
        stringify._window = this._window
        stringify._minDensity = this._minDensity

        stringify._idleVoltage = this._idleVoltage
        stringify._sampleTime = this._sampleTime
        stringify._filter = this._filter
        stringify._maxDistance = this._maxDistance
        stringify._minSlope = this._minSlope

        stringify._cut = this._cut
        stringify._cutting = this._cutting
        stringify._last = this._last
        return stringify
    }
    static fromJSON(obj) {
        let trans = new TimeSeriesCluster('', [''])
        super.fromJSON(trans, obj)
        if (obj._alpha) {
            trans._alpha = obj._alpha
        }
        if (obj._numBins) {
            trans._numBins = obj._numBins
        }
        if (obj._numBinsTake) {
            trans._numBinsTake = obj._numBinsTake
        }
        if (obj._window) {
            trans._window = obj._window
        }
        if (obj._minDensity) {
            trans._minDensity = obj._minDensity
        }

        if (obj._idleVoltage) {
            trans._idleVoltage = obj._idleVoltage
        }
        if (obj._sampleTime) {
            trans._sampleTime = obj._sampleTime
        }
        if (obj._filter) {
            trans._filter = obj._filter
        }
        if (obj._maxDistance) {
            trans._maxDistance = obj._maxDistance
        }
        if (obj._minSlope) {
            trans._minSlope = obj._minSlope
        }

        if (obj._cut) {
            trans._cut = obj._cut
        }
        if (obj._cutting) {
            trans._cutting = obj._cutting
        }
        if (obj._last) {
            trans._last = obj._last
        }
        return trans
    }
}

export default {
    $transform: Transform,
    $passThrough: PassThrough,
    $or: Or,
    $xor: Xor,
    $and: And,
    $not: Not,
    $risingEdge: RisingEdge,
    $fallingEdge: FallingEdge,
    $negate: Negate,
    $add: Add,
    $multiply: Multiply,
    $absoluteValue: AbsoluteValue,
    $between: Between,
    $merge: Merge,
    $mergeDuration: MergeDuration,
    $map: Map,
    $movingAverage: MovingAverage,
    $frontMovingAverage: FrontMovingAverage,
    $frontRollingAverage: FrontRollingAverage,
    $count: Count,
    $countByInterval: CountByInterval,
    $duration: Duration,
    $durationByInterval: DurationByInterval,
    $filter: Filter,
    $filterDuplicateValues: FilterDuplicateValues,
    $filterDuplicateTimestamps: FilterDuplicateTimestamps,
    $threshold: Threshold,
    $truthy: Truthy,
    $addConstant: AddConstant,
    $multiplyConstant: MultiplyConstant,
    $addAttribute: AddAttribute,
    $timeout: Timeout,
    $uniformTime: UniformTime,
    $roundTime: RoundTime,
    $holdTime: HoldTime,
    $delayTime: DelayTime,
    $advanceTime: AdvanceTime,
    $timeSeriesCluster: TimeSeriesCluster,
}
