import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button, Label} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import TextInput from '../../../../common/text-input'
import FormulaInput from '../../../../common/FormulaInput'

export default (props) => {
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'SendEmail',
        description: props.element.data.opts.description || '',
        recipients: props.element.data.opts.recipients || {
            '@@isExpression@@': true,
            mentions: [],
            text: '',
            plainText: '',
            type: 'string',
            map: [],
        },
        subjectLine: props.element.data.opts.subjectLine || {
            '@@isExpression@@': true,
            mentions: [],
            text: '',
            plainText: '',
            type: 'string',
            map: [],
        },
        body: props.element.data.opts.body || {
            '@@isExpression@@': true,
            mentions: [],
            text: '',
            plainText: '',
            type: 'html',
            map: [],
        },
        variableId: props.element.data.opts.variableId || null,
        submitted: props.element.data.opts.submitted || true,
    })

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Text',
            multi: false,
        }

        parameters.recipients.type = 'string'
        parameters.subjectLine.type = 'string'
        parameters.body.type = 'html'
        parameters.variableId = variable.id

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Recipients (separate emails with a comma):
                            </Label>
                            <br />
                            <FormulaInput
                                variables={props.variables.filter(
                                    (v) =>
                                        !v.multi &&
                                        v.transformId !== props.element.id
                                )}
                                formulas={props.formulas}
                                default={parameters.recipients}
                                type="formula"
                                onChange={(expression) => {
                                    parameters.recipients = expression
                                    setParameters({...parameters})
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Subject Line:</Label>
                            <br />
                            <FormulaInput
                                variables={props.variables.filter(
                                    (v) =>
                                        !v.multi &&
                                        v.transformId !== props.element.id
                                )}
                                formulas={props.formulas}
                                default={parameters.subjectLine}
                                type="string"
                                onChange={(expression) => {
                                    parameters.subjectLine = expression
                                    setParameters({...parameters})
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email Body:</Label>
                            <br />
                            <FormulaInput
                                variables={props.variables.filter(
                                    (v) => v.transformId !== props.element.id
                                )}
                                formulas={props.formulas}
                                default={parameters.body}
                                type="html"
                                onChange={(expression) => {
                                    parameters.body = expression
                                    setParameters({...parameters})
                                }}
                            />
                        </FormGroup>
                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
