import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    Table,
} from 'reactstrap'
import {CSVLink} from 'react-csv'
import Select from 'react-select'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DatePicker from 'react-datepicker'
import DataTablePagination from 'components/DataTables/pagination'
import COLUMNS from './columns'
import {sum, resinPercentage, requestMix} from './calculations'
import {currentShift, previousShift, historicalShift} from './shift-helpers'
import Subscriber from 'SDK/subscriber'
import {cloneDeep} from 'lodash'

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const VIEWS = [
    {
        label: 'Real-Time Data',
        value: 'Real-Time Data',
        showTotalResin: true,
        showTotalWeight: true,
        showTotalParts: true,
    },
    {
        label: 'Previous Shift Resin Data',
        value: 'Previous Shift Resin Data',
        showTotalResin: true,
        showTotalWeight: false,
        showTotalParts: false,
    },
    {
        label: 'Previous Shift Weight Data',
        value: 'Previous Shift Weight Data',
        showTotalResin: false,
        showTotalWeight: true,
        showTotalParts: false,
    },
    {
        label: 'Previous Shift Part Count Data',
        value: 'Previous Shift Part Count Data',
        showTotalResin: false,
        showTotalWeight: false,
        showTotalParts: true,
    },
    {
        label: 'Historical Shift Data',
        value: 'Historical Shift Data',
        showTotalResin: true,
        showTotalWeight: true,
        showTotalParts: true,
    },
]

export default class HubbellLenoirCityTable extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'HubbellLenoirCityTable'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            view: VIEWS[0],
            shift: null,
            data: [],
            columns: [],
            date: moment().add(-1, 'days'),
            setResinWeightModalRow: {station: ' ( , )'},
            pendingWrites: {},
            plcData: [],
            station1Transactions: [],
            station2Transactions: [],
            transactionModalPlatformName: '',
            showTotalParts: true,
            showTotalResin: true,
            showTotalWeight: true,
            totalParts: 0,
            totalResin: 0,
            totalWeight: 0,
        }
    }

    handleViewChange(view) {
        if (view.value === 'Historical Shift Data') {
            this.setState({view})
        } else {
            this.setState({view}, this.calculate)
        }

        this.setState({
            showTotalParts: view.showTotalParts,
            showTotalResin: view.showTotalResin,
            showTotalWeight: view.showTotalWeight,
        })
    }

    handleShiftChange(shift) {
        this.setState({shift})
    }

    handleDateChange(date) {
        this.setState({date})
    }

    async makeRealTimeDataView() {
        const liveData = await API.post('live', {deviceId: ['main']})
        let shift = liveData.main.activeShifts.length
            ? liveData.main.activeShifts[0]
            : false

        shift = currentShift(this.state.shifts.find((a) => a.name === shift))

        if (!shift) {
            this.setState({
                data: [],
                columns: [],
            })
            return
        }

        const columns = COLUMNS[this.state.view.value]

        let plcData = await API.post(
            'historical/raw',
            {
                query: {
                    timeStart: {
                        $gte: shift.range[0].toISOString(),
                        $lt: shift.range[1].toISOString(),
                    },
                    name: {$in: ['Weight', 'Resin', 'Part Count']},
                    deviceId: {$in: this.state.devices.map((d) => d.deviceId)},
                },
            },
            2
        )

        this.setState({plcData})

        let data = [
            ...new Set(this.state.devices.map((asset) => asset.groups[0])),
        ]

        for (let i in data) {
            const devices = this.state.devices.filter(
                (asset) => asset.groups[0] === data[i]
            )
            const relevantData = plcData.filter((a) =>
                devices.find((d) => d.deviceId === a.deviceId)
            )

            let station1MostRecentWeight = relevantData
                .filter(
                    (a) =>
                        a.name === 'Weight' &&
                        a.deviceId === devices[0].deviceId
                )
                .sort((a, b) => {
                    return (
                        moment(b.timestamp).valueOf() -
                        moment(a.timestamp).valueOf()
                    )
                })
            if (station1MostRecentWeight.length) {
                station1MostRecentWeight = station1MostRecentWeight[0].value
            } else {
                station1MostRecentWeight = 0
            }
            let station1MostRecentResin = relevantData
                .filter(
                    (a) =>
                        a.name === 'Resin' && a.deviceId === devices[0].deviceId
                )
                .sort((a, b) => {
                    return (
                        moment(b.timestamp).valueOf() -
                        moment(a.timestamp).valueOf()
                    )
                })
            if (station1MostRecentResin.length) {
                station1MostRecentResin = station1MostRecentResin[0].value
            } else {
                station1MostRecentResin = 0
            }

            const station1WeightSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[0].deviceId &&
                            d.name === 'Weight'
                    )
                ),
                station1ResinSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[0].deviceId &&
                            d.name === 'Resin'
                    )
                ),
                station1PartSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[0].deviceId &&
                            d.name === 'Part Count'
                    )
                ),
                station1CustomThreshold =
                    devices[0].metadata && devices[0].metadata.resinThreshold
                        ? devices[0].metadata.resinThreshold
                        : 17.5,
                station1DeviceId = devices[0].deviceId

            let station2WeightSum = 0,
                station2ResinSum = 0,
                station2PartSum = 0,
                station2CustomThreshold = 0,
                station2DeviceId = '',
                station2MostRecentWeight = 0,
                station2MostRecentResin = 0

            if (devices.length > 1) {
                station2WeightSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Weight'
                    )
                )
                station2ResinSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Resin'
                    )
                )
                station2PartSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Part Count'
                    )
                )
                station2CustomThreshold =
                    devices[1].metadata && devices[1].metadata.resinThreshold
                        ? devices[1].metadata.resinThreshold
                        : 17.5
                station2DeviceId = devices[1].deviceId

                station2MostRecentWeight = relevantData
                    .filter(
                        (a) =>
                            a.name === 'Weight' &&
                            a.deviceId === devices[1].deviceId
                    )
                    .sort((a, b) => {
                        return (
                            moment(b.timestamp).valueOf() -
                            moment(a.timestamp).valueOf()
                        )
                    })
                if (station2MostRecentWeight.length) {
                    station2MostRecentWeight = station2MostRecentWeight[0].value
                } else {
                    station2MostRecentWeight = 0
                }
                station2MostRecentResin = relevantData
                    .filter(
                        (a) =>
                            a.name === 'Resin' &&
                            a.deviceId === devices[1].deviceId
                    )
                    .sort((a, b) => {
                        return (
                            moment(b.timestamp).valueOf() -
                            moment(a.timestamp).valueOf()
                        )
                    })
                if (station2MostRecentResin.length) {
                    station2MostRecentResin = station2MostRecentResin[0].value
                } else {
                    station2MostRecentResin = 0
                }
            }

            data[i] = {
                platform:
                    data[i] + ' (' + devices.map((d) => d.name).join(',') + ')',
                station1Weight: Math.round(station1WeightSum * 100) / 100,
                station1Resin: Math.round(station1ResinSum * 100) / 100,
                station1Parts: station1PartSum,
                station1ResinPercentCurrent:
                    Math.round(
                        resinPercentage(
                            station1MostRecentWeight,
                            station1MostRecentResin
                        ) * 100
                    ) / 100,
                station1ResinPercent:
                    Math.round(
                        resinPercentage(station1WeightSum, station1ResinSum) *
                            100
                    ) / 100,
                station1CustomThreshold,
                station1DeviceId,
                setStation1CustomThreshold: this.setStation1CustomThreshold,
                station2Weight: Math.round(station2WeightSum * 100) / 100,
                station2Resin: Math.round(station2ResinSum * 100) / 100,
                station2Parts: Math.round(station2PartSum * 100) / 100,
                station2ResinPercentCurrent:
                    Math.round(
                        resinPercentage(
                            station2MostRecentWeight,
                            station2MostRecentResin
                        ) * 100
                    ) / 100,
                station2ResinPercent:
                    Math.round(
                        resinPercentage(station2WeightSum, station2ResinSum) *
                            100
                    ) / 100,
                stationRequestMix:
                    Math.round(
                        requestMix(station1WeightSum, station2WeightSum) * 100
                    ) / 100,
                station2CustomThreshold,
                setStation2CustomThreshold: this.setStation2CustomThreshold,
                station2DeviceId,
                showTransactions: this.showTransactions,
            }
        }

        this.setState({columns, data})
    }

    showTransactions(station1DeviceId, station2DeviceId, platformString) {
        const {plcData} = this.state
        let station1PartCounts = plcData.filter(
                (a) =>
                    a.deviceId === station1DeviceId && a.name === 'Part Count'
            ),
            station2PartCounts = plcData.filter(
                (a) =>
                    a.deviceId === station2DeviceId && a.name === 'Part Count'
            ),
            station1Rows = [],
            station2Rows = []

        for (let part of station1PartCounts) {
            const weight = plcData.find(
                    (a) =>
                        a.timestamp === part.timestamp &&
                        a.deviceId === part.deviceId &&
                        a.name === 'Weight'
                ),
                resin = plcData.find(
                    (a) =>
                        a.timestamp === part.timestamp &&
                        a.deviceId === part.deviceId &&
                        a.name === 'Resin'
                )
            station1Rows.push({
                timestamp: moment(part.timestamp).format('lll'),
                weight: weight ? Math.round(weight.value * 100) / 100 : 0,
                resin: resin ? Math.round(resin.value * 100) / 100 : 0,
            })
        }

        for (let part of station2PartCounts) {
            const weight = plcData.find(
                    (a) =>
                        a.timestamp === part.timestamp &&
                        a.deviceId === part.deviceId &&
                        a.name === 'Weight'
                ),
                resin = plcData.find(
                    (a) =>
                        a.timestamp === part.timestamp &&
                        a.deviceId === part.deviceId &&
                        a.name === 'Resin'
                )
            station2Rows.push({
                timestamp: moment(part.timestamp).format('lll'),
                weight: weight ? Math.round(weight.value * 100) / 100 : 0,
                resin: resin ? Math.round(resin.value * 100) / 100 : 0,
            })
        }

        this.setState({
            transactionsModelOpen: true,
            transactionModalStation1DeviceId: station1DeviceId,
            transactionModalStation2DeviceId: station2DeviceId,
            station1Transactions: station1Rows,
            station2Transactions: station2Rows,
            transactionModalPlatformName: platformString,
        })
    }

    setStation1CustomThreshold(currentValue, deviceId) {
        this.setState({
            customThresholdModalOpen: true,
            customThresholdModalStation: 1,
            customThresholdModalCurrentValue: currentValue,
            customThresholdModalDeviceId: deviceId,
        })
    }

    setStation2CustomThreshold(currentValue, deviceId) {
        this.setState({
            customThresholdModalOpen: true,
            customThresholdModalStation: 2,
            customThresholdModalCurrentValue: currentValue,
            customThresholdModalDeviceId: deviceId,
        })
    }

    async setCustomThreshold() {
        let deviceId = this.state.customThresholdModalDeviceId,
            metadata =
                this.state.devices.find((d) => d.deviceId === deviceId)
                    .metadata || {}

        metadata.resinThreshold = parseFloat(
            this.state.customThresholdModalCurrentValue
        )

        let deviceRecord = this.state.devices.find(
            (d) => d.deviceId === deviceId
        )

        await API.patch('devices/' + deviceRecord._id + '/metadata', {
            metadata,
        })

        this.setState({
            devices: await API.get('devices'),
            customThresholdModalOpen: false,
        })
        this.makeRealTimeDataView()
    }

    toggleCustomThresholdModal() {
        this.setState({
            customThresholdModalOpen: !this.state.customThresholdModalOpen,
        })
    }

    toggleTransactionsModal() {
        this.setState({
            transactionsModelOpen: !this.state.transactionsModelOpen,
        })
    }
    async fetchPendingWrites(row = this.state.setResinWeightModalRow) {
        let pendingWrites = {}
        try {
            const records = await API.post(
                'historical/raw',
                {
                    query: {
                        name: 'WriteToPLC',
                        deviceId: {
                            $in: [row.station1DeviceId, row.station2DeviceId],
                        },
                        timeEnd: null,
                    },
                },
                2
            )
            if (!records || records.constructor !== Array) {
                return alert(
                    'Cannot fetch pending PLC writes. Try again later.'
                )
            }

            pendingWrites = {
                station1Resin: records.find(
                    (a) =>
                        a.deviceId === row.station1DeviceId &&
                        a.value.field === 'station1Resin'
                ),
                station1Weight: records.find(
                    (a) =>
                        a.deviceId === row.station1DeviceId &&
                        a.value.field === 'station1Weight'
                ),
                station2Resin: records.find(
                    (a) =>
                        a.deviceId === row.station2DeviceId &&
                        a.value.field === 'station2Resin'
                ),
                station2Weight: records.find(
                    (a) =>
                        a.deviceId === row.station2DeviceId &&
                        a.value.field === 'station2Weight'
                ),
            }
        } catch (error) {
            console.log(error)
        }
        return pendingWrites
    }

    async pollPendingWrites() {
        this.setState({pendingWrites: await this.fetchPendingWrites()})
    }

    async toggleResinWeightModal(row) {
        if (row.platform) {
            this.setState({loading: true})

            let pendingWrites
            try {
                pendingWrites = await this.fetchPendingWrites(row)
            } catch (error) {
                return alert(
                    'Cannot fetch pending PLC writes. Try again later.'
                )
            }

            this.setState({
                loading: false,
                station1Resin: null,
                station1Weight: null,
                station2Resin: null,
                station2Weight: null,
                setResinWeightModalOpen: true,
                setResinWeightModalRow: row,
                pendingWrites,
            })
            this.subscriber.add(
                this.pollPendingWrites,
                1000 * 5,
                'pollPendingWrites'
            )
        } else {
            this.subscriber.remove('pollPendingWrites')
            this.setState({setResinWeightModalOpen: false})
        }
    }

    handlePLCValueChange(value, field) {
        this.setState({[field]: value})
    }

    async setPLCValue(field) {
        let factoryId = null
        try {
            factoryId = JSON.parse(localStorage['userObject']).factoryId
        } catch (error) {
            console.log(error.message)
        }

        if (factoryId !== 'hubbelllenoircity') {
            return alert('You are not permitted to set values.')
        }

        console.log(field + ': ' + this.state[field])

        if (
            !(
                field === 'station1Resin' ||
                field === 'station1Weight' ||
                field === 'station2Resin' ||
                field === 'station2Weight'
            )
        ) {
            return alert('Trying to set unknown value.')
        }

        let stationDeviceId = null
        if (field === 'station1Resin' || field === 'station1Weight') {
            stationDeviceId = this.state.setResinWeightModalRow.station1DeviceId
        } else if (field === 'station2Resin' || field === 'station2Weight') {
            stationDeviceId = this.state.setResinWeightModalRow.station2DeviceId
        } else {
            return alert('Unknown station id.')
        }

        // prevent users from entering more than 4 gallons on station1Resin & station2Resin
        // for platforms 100, 300 and 400
        if (
            stationDeviceId[0] == 1 ||
            stationDeviceId[0] == 3 ||
            stationDeviceId[0] == 4
        ) {
            if (field === 'station1Resin' || field === 'station2Resin') {
                if (this.state[field] > 4) {
                    return alert(
                        'Resin value for this station cannot exceed 4.0 gallons.'
                    )
                }
            }
        }
        //

        this.setState({loading: true})

        let successful = false

        const ts = moment().toISOString()

        try {
            const {success} = await API.post(
                'states',
                {
                    nodeId: stationDeviceId,
                    deviceId: stationDeviceId,
                    timestamp: ts,
                    timeStart: ts,
                    timeEnd: null,
                    name: 'WriteToPLC',
                    value: {
                        field,
                        value: parseFloat(this.state[field]),
                    },
                },
                2
            )
            successful = success
        } catch (error) {
            console.log(error)
        }

        if (successful) {
            try {
                const pendingWrites = await this.fetchPendingWrites()
                this.setState({pendingWrites})
            } catch (error) {
                console.log(error)
                successful = false
            }
        }

        this.setState({loading: false})

        if (!successful) {
            alert('Could not set value, please try again.')
        }
    }

    async makePreviousResinView() {
        const shift = previousShift(this.state.shifts)

        if (!shift) {
            this.setState({
                data: [],
                columns: [],
            })
            return
        }

        const columns = COLUMNS[this.state.view.value]

        let plcData = []
        for (let device of this.state.devices) {
            plcData.push(
                API.post(
                    'historical/raw',
                    {
                        query: {
                            timeStart: {
                                $gte: shift.range[0].toISOString(),
                                $lt: shift.range[1].toISOString(),
                            },
                            name: {$in: ['Resin']},
                            deviceId: device.deviceId,
                        },
                    },
                    2
                )
            )
        }

        plcData = (await Promise.all(plcData)).flat()

        let data = [
            ...new Set(this.state.devices.map((asset) => asset.groups[0])),
        ]

        for (let i in data) {
            const devices = this.state.devices.filter(
                (asset) => asset.groups[0] === data[i]
            )
            const relevantData = plcData.filter((a) =>
                devices.find((d) => d.deviceId === a.deviceId)
            )

            const station1ResinSum = sum(
                relevantData.filter(
                    (d) =>
                        d.deviceId === devices[0].deviceId && d.name === 'Resin'
                )
            )

            let station2ResinSum = 0

            if (devices.length > 1) {
                station2ResinSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Resin'
                    )
                )
            }

            data[i] = {
                platform:
                    data[i] + ' (' + devices.map((d) => d.name).join(',') + ')',
                station1Resin: Math.round(station1ResinSum * 100) / 100,
                station2Resin: Math.round(station2ResinSum * 100) / 100,
            }
        }
        data = data.filter((a) => !a.platform.includes('undefined'))
        this.setState({columns, data})
    }

    async makePreviousWeightView() {
        const shift = previousShift(this.state.shifts)

        if (!shift) {
            this.setState({
                data: [],
                columns: [],
            })
            return
        }

        const columns = COLUMNS[this.state.view.value]

        let plcData = []
        for (let device of this.state.devices) {
            plcData.push(
                API.post(
                    'historical/raw',
                    {
                        query: {
                            timeStart: {
                                $gte: shift.range[0].toISOString(),
                                $lt: shift.range[1].toISOString(),
                            },
                            name: {$in: ['Weight']},
                            deviceId: device.deviceId,
                        },
                    },
                    2
                )
            )
        }

        plcData = (await Promise.all(plcData)).flat()

        let data = [
            ...new Set(this.state.devices.map((asset) => asset.groups[0])),
        ]

        for (let i in data) {
            const devices = this.state.devices.filter(
                (asset) => asset.groups[0] === data[i]
            )
            const relevantData = plcData.filter((a) =>
                devices.find((d) => d.deviceId === a.deviceId)
            )

            const station1WeightSum = sum(
                relevantData.filter(
                    (d) =>
                        d.deviceId === devices[0].deviceId &&
                        d.name === 'Weight'
                )
            )

            let station2WeightSum = 0

            if (devices.length > 1) {
                station2WeightSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Weight'
                    )
                )
            }

            data[i] = {
                platform:
                    data[i] + ' (' + devices.map((d) => d.name).join(',') + ')',
                station1Weight: Math.round(station1WeightSum * 100) / 100,
                station2Weight: Math.round(station2WeightSum * 100) / 100,
                stationRequestMix:
                    Math.round(
                        requestMix(station1WeightSum, station2WeightSum) * 100
                    ) / 100,
            }
        }
        data = data.filter((a) => !a.platform.includes('undefined'))
        this.setState({columns, data})
    }

    async makePreviousPartCountView() {
        const shift = previousShift(this.state.shifts)

        if (!shift) {
            this.setState({
                data: [],
                columns: [],
            })
            return
        }

        const columns = COLUMNS[this.state.view.value]

        let plcData = []
        for (let device of this.state.devices) {
            plcData.push(
                API.post(
                    'historical/raw',
                    {
                        query: {
                            timeStart: {
                                $gte: shift.range[0].toISOString(),
                                $lt: shift.range[1].toISOString(),
                            },
                            name: {$in: ['Part Count']},
                            deviceId: device.deviceId,
                        },
                    },
                    2
                )
            )
        }

        plcData = (await Promise.all(plcData)).flat()

        let data = [
            ...new Set(this.state.devices.map((asset) => asset.groups[0])),
        ]

        for (let i in data) {
            const devices = this.state.devices.filter(
                (asset) => asset.groups[0] === data[i]
            )
            const relevantData = plcData.filter((a) =>
                devices.find((d) => d.deviceId === a.deviceId)
            )

            const station1PartSum = sum(
                relevantData.filter(
                    (d) =>
                        d.deviceId === devices[0].deviceId &&
                        d.name === 'Part Count'
                )
            )

            let station2PartSum = 0

            if (devices.length > 1) {
                station2PartSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Part Count'
                    )
                )
            }

            data[i] = {
                platform:
                    data[i] + ' (' + devices.map((d) => d.name).join(',') + ')',
                station1Parts: station1PartSum,
                station2Parts: Math.round(station2PartSum * 100) / 100,
            }
        }
        data = data.filter((a) => !a.platform.includes('undefined'))
        this.setState({columns, data})
    }

    async makeHistoricalView() {
        const shift = historicalShift(
            this.state.date,
            this.state.shifts.find((a) => a.name === this.state.shift.value)
        )

        if (!shift) {
            this.setState({
                data: [],
                columns: [],
            })
            return
        }

        const columns = COLUMNS['Real-Time Data']

        let plcData = []
        for (let device of this.state.devices) {
            plcData.push(
                API.post(
                    'historical/raw',
                    {
                        query: {
                            timeStart: {
                                $gte: shift.range[0].toISOString(),
                                $lt: shift.range[1].toISOString(),
                            },
                            name: {$in: ['Weight', 'Resin', 'Part Count']},
                            deviceId: device.deviceId,
                        },
                    },
                    2
                )
            )
        }

        plcData = (await Promise.all(plcData)).flat()

        this.setState({plcData})

        let data = [
            ...new Set(this.state.devices.map((asset) => asset.groups[0])),
        ]

        for (let i in data) {
            const devices = this.state.devices.filter(
                (asset) => asset.groups[0] === data[i]
            )
            const relevantData = plcData.filter((a) =>
                devices.find((d) => d.deviceId === a.deviceId)
            )

            const station1WeightSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[0].deviceId &&
                            d.name === 'Weight'
                    )
                ),
                station1ResinSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[0].deviceId &&
                            d.name === 'Resin'
                    )
                ),
                station1PartSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[0].deviceId &&
                            d.name === 'Part Count'
                    )
                )

            let station2WeightSum = 0,
                station2ResinSum = 0,
                station2PartSum = 0

            if (devices.length > 1) {
                station2WeightSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Weight'
                    )
                )
                station2ResinSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Resin'
                    )
                )
                station2PartSum = sum(
                    relevantData.filter(
                        (d) =>
                            d.deviceId === devices[1].deviceId &&
                            d.name === 'Part Count'
                    )
                )
            }

            data[i] = {
                platform:
                    data[i] + ' (' + devices.map((d) => d.name).join(',') + ')',
                station1Weight: Math.round(station1WeightSum * 100) / 100,
                station1Resin: Math.round(station1ResinSum * 100) / 100,
                station1Parts: station1PartSum,
                station1ResinPercent:
                    Math.round(
                        resinPercentage(station1WeightSum, station1ResinSum) *
                            100
                    ) / 100,
                station2Weight: Math.round(station2WeightSum * 100) / 100,
                station2Resin: Math.round(station2ResinSum * 100) / 100,
                station2Parts: Math.round(station2PartSum * 100) / 100,
                station2ResinPercent:
                    Math.round(
                        resinPercentage(station2WeightSum, station2ResinSum) *
                            100
                    ) / 100,
                stationRequestMix:
                    Math.round(
                        requestMix(station1WeightSum, station2WeightSum) * 100
                    ) / 100,
                showTransactions: this.showTransactions,
                station1DeviceId: devices[0].deviceId,
                station2DeviceId:
                    devices.length > 1 ? devices[1].deviceId : undefined,
            }
        }

        data = data.filter((a) => !a.platform.includes('undefined'))

        this.setState({columns, data})
    }

    async calculate() {
        this.setState({loading: true})
        if (this.state.view.value === 'Real-Time Data') {
            this.setState({
                lastUpdated: moment().format('LT'),
            })
            await this.makeRealTimeDataView()
        } else if (this.state.view.value === 'Previous Shift Resin Data') {
            await this.makePreviousResinView()
        } else if (this.state.view.value === 'Previous Shift Weight Data') {
            await this.makePreviousWeightView()
        } else if (this.state.view.value === 'Previous Shift Part Count Data') {
            await this.makePreviousPartCountView()
        } else if (this.state.view.value === 'Historical Shift Data') {
            await this.makeHistoricalView()
        }

        this.setState({
            totalParts: this.state.data.reduce(
                (a, b) => a + b.station1Parts + b.station2Parts,
                0
            ),
            totalResin: this.state.data.reduce(
                (a, b) => a + b.station1Resin + b.station2Resin,
                0
            ),
            totalWeight: this.state.data.reduce(
                (a, b) => a + b.station1Weight + b.station2Weight,
                0
            ),
            loading: false,
        })
    }

    updateData() {
        if (this.state.view.value === 'Real-Time Data') {
            this.calculate()
        }
    }

    async componentDidMount() {
        this.setState({
            shifts: await API.get('shifts', 2),
            devices: await API.get('devices'),
        })

        this.subscriber.add(this.updateData, 1000 * 60 * 3, 'updateData()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="2">
                                        <label>View:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="view"
                                            value={this.state.view}
                                            onChange={this.handleViewChange}
                                            options={VIEWS}
                                        />
                                    </Col>
                                    {this.state.view.value ===
                                    'Historical Shift Data' ? (
                                        <>
                                            <Col xs="2">
                                                <label>Shift:</label>
                                                <Select
                                                    components={{
                                                        Input: CustomSelectInput,
                                                    }}
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    name="shift"
                                                    value={this.state.shift}
                                                    onChange={
                                                        this.handleShiftChange
                                                    }
                                                    options={this.state.shifts.map(
                                                        (a) => {
                                                            return {
                                                                label: a.name,
                                                                value: a.name,
                                                            }
                                                        }
                                                    )}
                                                />
                                            </Col>
                                            <Col xs="2">
                                                <label>Date:</label>
                                                <DatePicker
                                                    selected={this.state.date}
                                                    onChange={
                                                        this.handleDateChange
                                                    }
                                                    filterDate={(date) => {
                                                        return (
                                                            moment().add(
                                                                -1,
                                                                'days'
                                                            ) > date
                                                        )
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="2">
                                                <label
                                                    style={{
                                                        color: '#fff',
                                                        display: 'block',
                                                    }}>
                                                    A
                                                </label>
                                                <Button
                                                    onClick={this.calculate}>
                                                    Generate
                                                </Button>
                                            </Col>
                                        </>
                                    ) : null}
                                    {this.state.data.length > 0 ? (
                                        <Col xs="2">
                                            <label
                                                style={{
                                                    color: '#fff',
                                                    display: 'block',
                                                }}>
                                                A
                                            </label>
                                            <Button color="warning">
                                                <CSVLink
                                                    data={cloneDeep(
                                                        this.state.data
                                                    )
                                                        .filter(
                                                            (a) =>
                                                                !a.platform.includes(
                                                                    'undefined'
                                                                )
                                                        )
                                                        .map((a) => {
                                                            delete a.showTransactions
                                                            delete a.setStation1CustomThreshold
                                                            delete a.setStation2CustomThreshold
                                                            delete a.station1DeviceId
                                                            delete a.station2DeviceId
                                                            return a
                                                        })}
                                                    filename={
                                                        this.state.view.label +
                                                        '_' +
                                                        moment().format('llll')
                                                    }>
                                                    Export Data
                                                </CSVLink>
                                            </Button>
                                        </Col>
                                    ) : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.view.value === 'Real-Time Data' ? (
                            <p>
                                Last updated: {this.state.lastUpdated} (updates
                                every 3 minutes)
                            </p>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    {this.state.showTotalWeight ? (
                        <Col xs="4" className="mb-4">
                            <Card>
                                <CardBody>
                                    <h2>Total Weight (lbs)</h2>
                                    <h1>
                                        {numberWithCommas(
                                            Math.round(this.state.totalWeight)
                                        )}
                                    </h1>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : null}

                    {this.state.showTotalResin ? (
                        <Col xs="4" className="mb-4">
                            <Card>
                                <CardBody>
                                    <h2>Total Resin (gals)</h2>
                                    <h1>
                                        {numberWithCommas(
                                            Math.round(this.state.totalResin)
                                        )}
                                    </h1>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : null}

                    {this.state.showTotalParts ? (
                        <Col xs="4" className="mb-4">
                            <Card>
                                <CardBody>
                                    <h2>Total Parts</h2>
                                    <h1>
                                        {numberWithCommas(
                                            Math.round(this.state.totalParts)
                                        )}
                                    </h1>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : null}
                </Row>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                {this.state.columns.length > 0 ? (
                                    <ReactTable
                                        defaultPageSize={24}
                                        data={this.state.data}
                                        columns={this.state.columns}
                                        minRows={0}
                                        PaginationComponent={
                                            DataTablePagination
                                        }
                                        loading={false}
                                        filterable={false}
                                        getTrProps={(
                                            state,
                                            rowInfo,
                                            column
                                        ) => {
                                            return {
                                                onClick: (
                                                    e,
                                                    handleOriginal
                                                ) => {
                                                    if (
                                                        this.state.view
                                                            .value ===
                                                            'Real-Time Data' &&
                                                        rowInfo.original
                                                            .station1DeviceId !==
                                                            'main'
                                                    ) {
                                                        this.toggleResinWeightModal(
                                                            rowInfo.original
                                                        )
                                                    }
                                                },
                                            }
                                        }}
                                    />
                                ) : (
                                    <p>No data available</p>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.loading ? <div className="loading" /> : null}

                <Modal
                    isOpen={this.state.customThresholdModalOpen}
                    toggle={this.toggleCustomThresholdModal}>
                    <ModalHeader toggle={this.toggleCustomThresholdModal}>
                        Set Threshold
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            type="number"
                            value={this.state.customThresholdModalCurrentValue}
                            onChange={(e) =>
                                this.setState({
                                    customThresholdModalCurrentValue:
                                        e.target.value,
                                })
                            }
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.setCustomThreshold}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>

                {this.state.transactionsModelOpen ? (
                    <Modal
                        size="lg"
                        isOpen={this.state.transactionsModelOpen}
                        toggle={this.toggleTransactionsModal}>
                        <ModalHeader toggle={this.toggleTransactionsModal}>
                            {this.state.transactionModalPlatformName}{' '}
                            Transactions
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="6">
                                    <h4 className="text-center">
                                        <strong>
                                            {
                                                this.state.devices.find(
                                                    (a) =>
                                                        a.deviceId ===
                                                        this.state
                                                            .transactionModalStation1DeviceId
                                                ).name
                                            }
                                        </strong>
                                    </h4>
                                    <p className="text-center">
                                        Average Weight:{' '}
                                        {Math.round(
                                            (this.state.station1Transactions.reduce(
                                                (a, b) => a + b.weight,
                                                0
                                            ) /
                                                this.state.station1Transactions
                                                    .length) *
                                                100
                                        ) / 100}{' '}
                                        lbs
                                    </p>
                                    <p className="text-center">
                                        Average Resin:{' '}
                                        {Math.round(
                                            (this.state.station1Transactions.reduce(
                                                (a, b) => a + b.resin,
                                                0
                                            ) /
                                                this.state.station1Transactions
                                                    .length) *
                                                100
                                        ) / 100}{' '}
                                        gals
                                    </p>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Timestamp</th>
                                                <th>Weight</th>
                                                <th>Resin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.station1Transactions.map(
                                                (a, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <th>
                                                                {a.timestamp}
                                                            </th>
                                                            <td>{a.weight}</td>
                                                            <td>{a.resin}</td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                                {this.state.transactionModalStation2DeviceId ? (
                                    <Col xs="6">
                                        <h4 className="text-center">
                                            <strong>
                                                {
                                                    this.state.devices.find(
                                                        (a) =>
                                                            a.deviceId ===
                                                            this.state
                                                                .transactionModalStation2DeviceId
                                                    ).name
                                                }
                                            </strong>
                                        </h4>
                                        <p className="text-center">
                                            Average Weight:{' '}
                                            {Math.round(
                                                (this.state.station2Transactions.reduce(
                                                    (a, b) => a + b.weight,
                                                    0
                                                ) /
                                                    this.state
                                                        .station2Transactions
                                                        .length) *
                                                    100
                                            ) / 100}{' '}
                                            lbs
                                        </p>
                                        <p className="text-center">
                                            Average Resin:{' '}
                                            {Math.round(
                                                (this.state.station2Transactions.reduce(
                                                    (a, b) => a + b.resin,
                                                    0
                                                ) /
                                                    this.state
                                                        .station2Transactions
                                                        .length) *
                                                    100
                                            ) / 100}{' '}
                                            gals
                                        </p>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Timestamp</th>
                                                    <th>Weight</th>
                                                    <th>Resin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.station2Transactions.map(
                                                    (a, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th>
                                                                    {
                                                                        a.timestamp
                                                                    }
                                                                </th>
                                                                <td>
                                                                    {a.weight}
                                                                </td>
                                                                <td>
                                                                    {a.resin}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                ) : null}
                            </Row>
                        </ModalBody>
                    </Modal>
                ) : null}

                {this.state.setResinWeightModalOpen ? (
                    <Modal
                        isOpen={this.state.setResinWeightModalOpen}
                        toggle={this.toggleResinWeightModal}>
                        <ModalHeader toggle={this.toggleResinWeightModal}>
                            Set resin & weight for{' '}
                            {this.state.setResinWeightModalRow.platform}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <h5>
                                        <strong>
                                            {
                                                this.state.devices.find(
                                                    (a) =>
                                                        a.deviceId ===
                                                        this.state
                                                            .setResinWeightModalRow
                                                            .station1DeviceId
                                                ).name
                                            }
                                        </strong>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Resin</Label>
                                    {this.state.pendingWrites.station1Resin ? (
                                        <p>
                                            <strong>
                                                Write is currently pending for
                                                value ={' '}
                                                {
                                                    this.state.pendingWrites
                                                        .station1Resin.value
                                                        .value
                                                }
                                            </strong>
                                        </p>
                                    ) : (
                                        <InputGroup>
                                            <Input
                                                placeholder="Enter value to set in PLC"
                                                type="number"
                                                value={this.state.station1Resin}
                                                onChange={(e) =>
                                                    this.handlePLCValueChange(
                                                        e.target.value,
                                                        'station1Resin'
                                                    )
                                                }
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="warning"
                                                    onClick={() =>
                                                        this.setPLCValue(
                                                            'station1Resin'
                                                        )
                                                    }
                                                    disabled={
                                                        !(
                                                            this.state
                                                                .station1Resin >
                                                            0
                                                        )
                                                    }>
                                                    SET VALUE
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Label>Weight</Label>
                                    {this.state.pendingWrites.station1Weight ? (
                                        <p>
                                            <strong>
                                                Write is currently pending for
                                                value ={' '}
                                                {
                                                    this.state.pendingWrites
                                                        .station1Weight.value
                                                        .value
                                                }
                                            </strong>
                                        </p>
                                    ) : (
                                        <InputGroup>
                                            <Input
                                                placeholder="Enter value to set in PLC"
                                                type="number"
                                                minimum={0}
                                                value={
                                                    this.state.station1Weight
                                                }
                                                onChange={(e) =>
                                                    this.handlePLCValueChange(
                                                        e.target.value,
                                                        'station1Weight'
                                                    )
                                                }
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="warning"
                                                    onClick={() =>
                                                        this.setPLCValue(
                                                            'station1Weight'
                                                        )
                                                    }
                                                    disabled={
                                                        !(
                                                            this.state
                                                                .station1Weight >
                                                            0
                                                        )
                                                    }>
                                                    SET VALUE
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    )}
                                </Col>
                            </Row>
                            {this.state.setResinWeightModalRow
                                .station2DeviceId ? (
                                <Fragment>
                                    <Row className="mt-4">
                                        <Col>
                                            <h5>
                                                <strong>
                                                    {
                                                        this.state.devices.find(
                                                            (a) =>
                                                                a.deviceId ===
                                                                this.state
                                                                    .setResinWeightModalRow
                                                                    .station2DeviceId
                                                        ).name
                                                    }
                                                </strong>
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Resin</Label>
                                            {this.state.pendingWrites
                                                .station2Resin ? (
                                                <p>
                                                    <strong>
                                                        Write is currently
                                                        pending for value ={' '}
                                                        {
                                                            this.state
                                                                .pendingWrites
                                                                .station2Resin
                                                                .value.value
                                                        }
                                                    </strong>
                                                </p>
                                            ) : (
                                                <InputGroup>
                                                    <Input
                                                        placeholder="Enter value to set in PLC"
                                                        type="number"
                                                        value={
                                                            this.state
                                                                .station2Resin
                                                        }
                                                        onChange={(e) =>
                                                            this.handlePLCValueChange(
                                                                e.target.value,
                                                                'station2Resin'
                                                            )
                                                        }
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                this.setPLCValue(
                                                                    'station2Resin'
                                                                )
                                                            }
                                                            disabled={
                                                                !(
                                                                    this.state
                                                                        .station2Resin >
                                                                    0
                                                                )
                                                            }>
                                                            SET VALUE
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <Label>Weight</Label>
                                            {this.state.pendingWrites
                                                .station2Weight ? (
                                                <p>
                                                    <strong>
                                                        Write is currently
                                                        pending for value ={' '}
                                                        {
                                                            this.state
                                                                .pendingWrites
                                                                .station2Weight
                                                                .value.value
                                                        }
                                                    </strong>
                                                </p>
                                            ) : (
                                                <InputGroup>
                                                    <Input
                                                        placeholder="Enter value to set in PLC"
                                                        type="number"
                                                        minimum={0}
                                                        value={
                                                            this.state
                                                                .station2Weight
                                                        }
                                                        onChange={(e) =>
                                                            this.handlePLCValueChange(
                                                                e.target.value,
                                                                'station2Weight'
                                                            )
                                                        }
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                this.setPLCValue(
                                                                    'station2Weight'
                                                                )
                                                            }
                                                            disabled={
                                                                !(
                                                                    this.state
                                                                        .station2Weight >
                                                                    0
                                                                )
                                                            }>
                                                            SET VALUE
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            )}
                                        </Col>
                                    </Row>
                                </Fragment>
                            ) : null}
                            <Row>
                                <Col>
                                    <hr />
                                    <p className="text-center">
                                        Values may take up to 30 seconds to be
                                        written to the PLC.
                                    </p>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}
