import {NotificationsProvider} from '@mantine/notifications'
import {MantineProvider} from '@mantine/core'

const AccumineAppWrapper = ({...props}) => {
    return (
        <MantineProvider>
            <NotificationsProvider>{props.children}</NotificationsProvider>
        </MantineProvider>
    )
}

export default AccumineAppWrapper
