import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Table, Button} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Scrollbars} from 'react-custom-scrollbars'
import {Pie} from 'react-chartjs-2'

import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import colors from 'nice-color-palettes'

colors.splice(12, 2)

const formatDuration = (ms) => {
    if (ms === 0) return '0 mins'
    else
        return ms > 1000 * 60 * 60
            ? Math.floor((ms / (1000 * 60 * 60)) * 100) / 100 + ' hrs'
            : Math.floor((ms / (1000 * 60)) * 100) / 100 + ' mins'
}

export default class ChangeoverReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ChangeoverReport'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            assets: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            reasoncodes: [],
            records: [],
            data: {},
            ready: false,
            customFields: [],
        }
    }

    async componentDidMount() {
        let assets = await API.get('devices')
        assets = assets.map((a, i) => {
            return {label: a.name, value: a.deviceId, key: i}
        })

        let reasoncodes = await API.get('reasoncodes', 2)
        reasoncodes = reasoncodes
            .filter((r) => r.category === 'Changeover')
            .map((r) => r.reason)

        if (reasoncodes.length === 0) {
            alert(
                "No changeover types found! Go to 'Settings' > 'Reason Codes' and add your various changeover types under the category name of 'Changeover'."
            )
        }

        this.setState({
            assets,
            selectedAssets: assets,
            reasoncodes,
        })
    }

    async generate() {
        this.setState({loading: true})
        let {reasoncodes, assets, start, end, selectedAssets, customFields} =
                this.state,
            data = Object.fromEntries(
                reasoncodes.map((r) => [r, {duration: 0, count: 0}])
            )

        let records = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: selectedAssets.map((a) => a.value)},
                    name: 'Downtime Reason',
                    timeStart: {
                        $lte: end.toISOString(),
                    },
                    $or: [
                        {
                            timeEnd: {$gte: start.toISOString()},
                        },
                        {
                            timeEnd: null,
                        },
                    ],
                    value: {$in: reasoncodes},
                },
            },
            2
        )

        for (let i = 0, l = records.length; i < l; i++) {
            records[i].assetName = assets.find(
                (a) => a.value === records[i].deviceId
            ).label
            records[i].timeStart = moment(records[i].timeStart)
            records[i].timeEnd =
                records[i].timeEnd === null
                    ? moment()
                    : moment(records[i].timeEnd)

            if (records[i].metaData) {
                for (let m of records[i].metaData) {
                    if (
                        m.name !== 'downtimereason' &&
                        !customFields.find((f) => f === m.name)
                    ) {
                        customFields.push(m.name)
                    }
                }
            }

            if (start.isAfter(records[i].timeStart)) {
                records[i].timeStart = moment(start)
            }
            if (records[i].timeEnd.isAfter(end)) {
                records[i].timeEnd = moment(end)
            }

            records[i].duration = records[i].timeEnd.diff(records[i].timeStart)
            records[i].durationString = formatDuration(records[i].duration)

            data[records[i].value].duration += records[i].duration
            data[records[i].value].count += 1
        }

        let dataArray = [],
            total = 0

        for (let key in data) {
            total += data[key].duration
            data[key].totalDurationString = formatDuration(data[key].duration)
            data[key].averageDurationString = isNaN(
                data[key].duration / data[key].count
            )
                ? '0 mins'
                : formatDuration(data[key].duration / data[key].count)
            data[key].reason = key
            dataArray.push(data[key])
        }

        const piedata = {
            labels: reasoncodes,
            datasets: [
                {
                    data: Object.values(data).map(
                        (v) =>
                            Math.floor((v.duration / total) * 100 * 100) / 100
                    ),
                    backgroundColor: colors.map((c) => c[0]),
                },
            ],
        }

        this.setState({
            records,
            dataArray,
            piedata,
            loading: false,
            ready: true,
            customFields,
        })
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.8 + 'px'
        console.log(this.state.customFields)

        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Assets:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="assets"
                                            value={this.state.selectedAssets}
                                            onChange={(selectedAssets) =>
                                                this.setState({selectedAssets})
                                            }
                                            options={this.state.assets}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="2">
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={(start) =>
                                                this.setState({start})
                                            }
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={(end) =>
                                                this.setState({end})
                                            }
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" style={{textAlign: 'right'}}>
                                        <br />
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {!this.state.loading && this.state.ready ? (
                    <>
                        <Row>
                            <Col xs="6">
                                <Card>
                                    <CardBody style={{paddingBottom: 0}}>
                                        <h3 className="text-center">
                                            <strong>
                                                Changeover Time Summary
                                            </strong>
                                        </h3>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>C/O Type</th>
                                                    <th>Avg. Duration</th>
                                                    <th>Frequency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.dataArray.map(
                                                    (r, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th scope="row">
                                                                    {r.reason}
                                                                </th>
                                                                <td>
                                                                    {
                                                                        r.averageDurationString
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {r.count}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="6">
                                <Card>
                                    <CardBody style={{paddingBottom: 0}}>
                                        <h3 className="text-center">
                                            <strong>
                                                Total Changeover Time
                                            </strong>
                                        </h3>
                                        <Pie
                                            data={this.state.piedata}
                                            options={{}}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-4" style={{height}}>
                            <Col xs="12" style={{height}}>
                                <Card style={{height: '100%'}}>
                                    <CardBody>
                                        <h3 className="text-center">
                                            <strong>Changeover History</strong>
                                        </h3>
                                        <Scrollbars>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Asset</th>
                                                        <th>C/O Type</th>
                                                        <th>Start Time</th>
                                                        <th>Duration</th>
                                                        {this.state.customFields.map(
                                                            (f, idx) => (
                                                                <th key={idx}>
                                                                    {f}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.records.map(
                                                        (r, idx) => {
                                                            return (
                                                                <tr key={idx}>
                                                                    <th scope="row">
                                                                        {
                                                                            r.assetName
                                                                        }
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            r.value
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {r.timeStart.format(
                                                                            'lll'
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            r.durationString
                                                                        }
                                                                    </td>
                                                                    {r.metaData &&
                                                                        r.metaData
                                                                            .filter(
                                                                                (
                                                                                    m
                                                                                ) =>
                                                                                    m.name !==
                                                                                    'downtimereason'
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    m,
                                                                                    idx
                                                                                ) => {
                                                                                    return (
                                                                                        <td
                                                                                            key={
                                                                                                idx
                                                                                            }>
                                                                                            {
                                                                                                m.value
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            )}
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Scrollbars>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
