import React, {Fragment, Component} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import autobind from 'auto-bind'
import {Scrollbars} from 'react-custom-scrollbars'

import DescendingProgressBars from '../Common/Visuals/DescendingProgressBars'

export default class AssetUsageDetail extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            dropdownOpen: false,
            sortBy: 'active',
        }
    }

    toggleDropdown() {
        this.setState({dropdownOpen: !this.state.dropdownOpen})
    }

    sortBy(type) {
        this.setState({sortBy: type})
    }

    render() {
        return (
            <Fragment>
                <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                        <h5 className="d-inline">Asset Usage Detail</h5>
                        <span className="text-muted text-small d-block">
                            Most to Least{' '}
                            {this.state.sortBy.charAt(0).toUpperCase() +
                                this.state.sortBy.slice(1)}
                        </span>
                    </div>
                </div>
                <div className="btn-group float-right float-none-xs mt-2 mb-5">
                    <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleDropdown}>
                        <DropdownToggle
                            caret
                            color="primary"
                            className="btn-xs"
                            outline
                            disabled={this.props.loading}>
                            Sort
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => this.sortBy('active')}>
                                Most to Least Active
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => this.sortBy('dormant')}>
                                Most to Least Dormant
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => this.sortBy('offline')}>
                                Most to Least Offline
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <Scrollbars>
                    {this.props.data ? (
                        <DescendingProgressBars
                            sortBy={this.state.sortBy}
                            data={this.props.data}
                        />
                    ) : null}
                </Scrollbars>
            </Fragment>
        )
    }
}
