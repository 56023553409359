import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

import widgets from 'SDK/ui/widgets'

export default class GenericTile extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            viewSettings: this.props.viewSettings,
        }

        this.component = this.props.library.find(
            (l) => l.component === this.props.element.i.split('Generic::')[1]
        )
        this.element = widgets.find((w) => {
            const instance = new w()
            return instance.id === this.component.component
        })
    }

    async componentWillMount() {}

    render() {
        const Element = this.element
        const props = this.props.viewSettings
            ? this.props.viewSettings.props
            : {}
        const hideBorder = !new Element().showBorder

        if (!hideBorder) {
            return (
                <>
                    <Card key={this.props.element.i} style={{height: '100%'}}>
                        <CardHeader>
                            <h5>{this.component.props.name}</h5>
                            {this.props.mode === 'edit' ? (
                                <span
                                    className="remove"
                                    style={{
                                        position: 'absolute',
                                        right: '2px',
                                        top: 0,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        this.props.onRemoveItem(
                                            this.props.element.i
                                        )
                                    }}>
                                    x
                                </span>
                            ) : null}
                        </CardHeader>
                        <CardBody style={{padding: 0}}>
                            <Element
                                {...props}
                                environmentId={this.props.environmentId}
                                sectionId={this.props.sectionId}
                                viewId={this.props.viewId}
                            />
                        </CardBody>
                    </Card>
                </>
            )
        } else {
            return (
                <div key={this.props.element.i}>
                    <Element
                        {...props}
                        environmentId={this.props.environmentId}
                        sectionId={this.props.sectionId}
                        viewId={this.props.viewId}
                        {...this.props}
                    />
                </div>
            )
        }
    }
}
