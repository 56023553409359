import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'
import FormulaInput from '../../../../common/FormulaInput'
import {cloneDeep} from 'lodash'

export default (props) => {
    const [selectedOutcome, setSelectedOutcome] = useState(null)
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'Decide',
        description: props.element.data.opts.description || '',
        outcomes: props.element.data.opts.outcomes || [],
        submitted: props.element.data.opts.submitted || true,
    })

    const renderOutcomeEditor = () => {
        const idx = parameters.outcomes.findIndex(
                (o) => o.id === selectedOutcome
            ),
            outcome = parameters.outcomes[idx]
        return (
            <>
                <Row>
                    <Col>
                        <h5 className="text-center">{outcome.name}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput
                            onValueChange={(name) => {
                                outcome.name = name.replace(/\s/g, '')
                                parameters.outcomes[idx] = outcome
                                setParameters({...parameters})
                            }}
                            onValidChange={() => {}}
                            fieldName="Outcome Name"
                            existing={props.variables
                                .filter((v) => v.id !== outcome.id)
                                .map((v) => v.name)}
                            initial={outcome.name}
                            disabled={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormulaInput
                            variables={props.variables.filter(
                                (v) =>
                                    !v.multi &&
                                    v.transformId !== props.element.id
                            )}
                            formulas={props.formulas}
                            default={outcome.expression || null}
                            type="formula"
                            onChange={(expression) => {
                                parameters.outcomes[idx].expression = expression
                                parameters.outcomes[idx].expression.type =
                                    'formula'
                                setParameters({...parameters})
                            }}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    const submit = () => {
        let variables = cloneDeep(parameters.outcomes).map((outcome) => {
            let variable = props.variables.find((v) => v.id === outcome.id)

            if (variable) {
                // editing
                variable.name = outcome.name
                variable.expression = outcome.expression
            } else {
                // new
                variable = {
                    id: outcome.id,
                    transformId: props.element.id,
                    name: outcome.name,
                    expression: outcome.expression,
                    type: 'Boolean',
                    fields: [],
                    multi: false,
                }
            }
            return variable
        })

        props.onChange(parameters, variables)
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                onChange={(outcome) => {
                                    setSelectedOutcome(null)
                                    setTimeout(() => {
                                        if (outcome === 'new') {
                                            const id = uuidv4()
                                            parameters.outcomes.push({
                                                variableId: id,
                                                id,
                                                name: `Outcome ${parameters.outcomes.length}`,
                                                expression: {
                                                    text: '',
                                                    plainText: '',
                                                    mentions: [],
                                                    type: 'formula',
                                                    map: [],
                                                },
                                            })
                                            setParameters({...parameters})
                                            setSelectedOutcome(id)
                                        } else {
                                            setSelectedOutcome(outcome)
                                        }
                                    }, 50)
                                }}
                                fieldName="Outcomes"
                                default={null}
                                options={parameters.outcomes
                                    .map((o) => {
                                        return {label: o.name, value: o.id}
                                    })
                                    .concat([
                                        {
                                            label: 'Add new outcome...',
                                            value: 'new',
                                        },
                                    ])}
                            />
                        </FormGroup>

                        <FormGroup>
                            {selectedOutcome ? renderOutcomeEditor() : null}
                        </FormGroup>

                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
