import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Row,
    Col,
    Table,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import * as API from 'SDK/api'
import NumberKeypad from './NumberKeypad'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            signInEmployeeID: '',
            loading: false,
        }
    }

    async signIn() {
        this.setState({loading: true})
        try {
            const signInEmployeeID = Number(this.state.signInEmployeeID)
            if (
                this.props.signIns.some(
                    (s) => s.Employee.Employee_ID === signInEmployeeID
                )
            ) {
                throw Error('A sign-in using this Badge # already exists!')
            }
            const signInModel = this.props.dataModels.find(
                (d) => d.name === 'Aiken/Sign Ins'
            )
            if (!signInModel) {
                throw Error('Cannot find Aiken/Sign Ins data model')
            }

            const employeesModel = this.props.dataModels.find(
                (d) => d.name === 'Aiken/Employees'
            )
            if (!employeesModel) {
                throw Error('Cannot find Aiken/Employees data model')
            }

            const employeeResponse = await API.post(
                `data-models/${employeesModel._id}/paginate`,
                {
                    filter: [
                        {
                            path: 'Employee_ID',
                            type: 'Number',
                            logic: 'equalTo',
                            value: signInEmployeeID,
                        },
                    ],
                },
                2
            )

            if (employeeResponse.result.results.length === 0) {
                throw Error('Cannot find employee record! Please verify entry.')
            }

            const row = employeeResponse.result.results[0]

            await API.post(
                `data-models/${signInModel._id}/add-record`,
                {
                    Employee: {
                        Employee_ID: signInEmployeeID,
                        First_Name: row['First_Name'],
                        Last_Name: row['Last_Name'],
                        Full_Name: `${row['First_Name']} ${row['Last_Name']}`,
                    },
                    Machine: this.props.deviceId,
                    Start_Time: moment().toISOString(),
                    End_Time: 'active',
                },
                2
            )
            this.props.forceRefresh()
            this.setState({signInEmployeeID: ''})
        } catch (error) {
            console.log(error)
            alert(`Could not sign in: ${error}`)
            this.setState({loading: false})
        }
        this.setState({loading: false})
    }

    async signOut(signIn) {
        this.setState({loading: true})
        try {
            const {_id} = this.props.dataModels.find(
                (d) => d.name === 'Aiken/Sign Ins'
            )
            if (!_id) {
                throw Error('Cannot find Aiken/Sign Ins data model')
            }

            signIn.End_Time = moment().toISOString()

            await API.patch(`data-models/${_id}/edit-record`, signIn, 2)
            this.props.forceRefresh()
        } catch (error) {
            console.log(error)
            alert(`Could not sign out: ${error}`)
            this.setState({loading: false})
        }
        this.setState({loading: false})
    }

    async signOutAll() {
        try {
            await Promise.all(this.props.signIns.map(this.signOut))
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.forceSignOut) {
            this.signOutAll()
            this.props.clearForceSignOut()
        }
    }

    render() {
        return (
            <Fragment>
                <Modal size="lg" isOpen={true} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>
                        SIGN IN/OUT
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading ? (
                            <div className="loading" />
                        ) : null}
                        <>
                            <Row>
                                {this.props.signIns.length > 0 ? (
                                    <>
                                        <Col xs="6">
                                            <h5>
                                                <strong>SIGNED IN:</strong>
                                            </h5>
                                            <hr />
                                            <Table>
                                                <tbody>
                                                    {this.props.signIns.map(
                                                        (signIn) => {
                                                            return (
                                                                <tr
                                                                    key={
                                                                        signIn._id
                                                                    }>
                                                                    <th
                                                                        style={{
                                                                            textTransform:
                                                                                'uppercase',
                                                                        }}>
                                                                        {
                                                                            signIn
                                                                                .Employee
                                                                                .Employee_ID
                                                                        }{' '}
                                                                        (
                                                                        {
                                                                            signIn
                                                                                .Employee
                                                                                .Full_Name
                                                                        }
                                                                        )
                                                                    </th>
                                                                    <td>
                                                                        <Button
                                                                            size="sm"
                                                                            color="danger"
                                                                            onClick={() =>
                                                                                this.signOut(
                                                                                    signIn
                                                                                )
                                                                            }>
                                                                            SIGN
                                                                            OUT
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </Table>
                                            {this.props.signIns.length > 1 ? (
                                                <>
                                                    <hr />
                                                    <Button
                                                        block
                                                        color="danger"
                                                        onClick={
                                                            this.signOutAll
                                                        }>
                                                        SIGN OUT EVERYONE
                                                    </Button>
                                                </>
                                            ) : null}
                                        </Col>
                                    </>
                                ) : null}
                                <Col
                                    span={
                                        this.props.signIns.length > 0
                                            ? '6'
                                            : '12'
                                    }>
                                    <h5>
                                        <strong>SIGN IN - ENTER BADGE #</strong>
                                    </h5>
                                    <hr />
                                    <NumberKeypad
                                        value={this.state.signInEmployeeID}
                                        onChange={(signInEmployeeID) =>
                                            this.setState({signInEmployeeID})
                                        }
                                    />
                                    {this.state.signInEmployeeID ? (
                                        <Button
                                            color="success"
                                            block
                                            onClick={this.signIn}>
                                            SUBMIT
                                        </Button>
                                    ) : null}
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
