import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ListGroupItem, Badge, ListGroup} from 'reactstrap'
import autoBind from 'auto-bind'
import {Scrollbars} from 'react-custom-scrollbars'

import ModelTree from '../../../../common/ModelTree'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

const TIME_UNIT_OPTIONS = [
    {
        label: 'Minute',
        value: 'T',
    },
    {
        label: 'Hour',
        value: 'H',
    },
    {
        label: 'Day',
        value: 'D',
    },
    {
        label: 'Week (Mon - Sun)',
        value: 'W',
    },
    {
        label: 'Month',
        value: 'MS',
    },
    {
        label: 'Quarter',
        value: 'QS',
    },
    {
        label: 'Year',
        value: 'YS',
    },
    /*{
        label: 'Minute of hour',
        value: 'minuteOfHour'
    },
    {
        label: 'Hour of day',
        value: 'hourOfDay'
    },
    {
        label: 'Day of week',
        value: 'dayOfWeek'
    },
    {
        label: 'Day of month',
        value: 'dayOfMonth'
    },
    {
        label: 'Day of year',
        value: 'dayOfYear'
    },
    {
        label: 'Week of year',
        value: 'weekOfYear'
    },
    {
        label: 'Month of year',
        value: 'monthOfYear'
    },
    {
        label: 'Quarter of year',
        value: 'quarterOfYear'
    }*/
]

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.height = document.documentElement.offsetHeight * 0.2
        this.list = []
        this.paths = []
        this.treeData = []
        this.flatData = []

        let existingTimeGroup = this.props.groups.find((g) => g.timeUnit)

        this.state = {
            view: 'list', // 'selected'
            trees: [],
            timeUnit: existingTimeGroup
                ? TIME_UNIT_OPTIONS.find(
                      (o) => o.value === existingTimeGroup.timeUnit
                  )
                : TIME_UNIT_OPTIONS.find((o) => o.value === 'MS'),
        }
    }

    handleFieldClick(selectedKeys, {selectedNodes}) {
        let node = selectedNodes[0],
            groups = this.props.groups
        if (
            node.type !== 'MultiSubModel' &&
            node.type !== 'SingleSubModel' &&
            node.type !== 'ForeignID'
        ) {
            if (node.type === 'RegularizedTimestamp') {
                const existing = groups.find(
                    (a) => a.type === 'RegularizedTimestamp'
                )
                if (existing) {
                    alert(
                        `Swapping time group "${existing.path.replace(
                            /@@/g,
                            ''
                        )}" with "${node.path.replace(/@@/g, '')}"`
                    )
                    groups = this.props.groups.filter(
                        (a) => a.path !== existing.path
                    )
                }
                this.handleTimeUnitChange(
                    TIME_UNIT_OPTIONS.find((a) => a.value === 'MS')
                )
            }
            groups.push({
                ...node,
                timeUnit:
                    node.type === 'RegularizedTimestamp' ? 'MS' : undefined,
            })
            this.setState({view: 'selected'}, this.props.setGroup(groups))
        }
    }

    componentDidMount() {
        let trees = []
        const allowedTypes = [
            'UniqueID',
            'SingleSubModel',
            'RegularizedTimestamp',
            'ForeignID',
        ].concat(
            this.props.fieldTypes.filter((a) => a.groupable).map((a) => a.type)
        )
        trees.push(
            <ModelTree
                showMap={(item) => allowedTypes.includes(item.type)}
                onSelect={this.handleFieldClick}
                dataModel={this.props.dataModel}
                dataModels={this.props.dataModels}
                includeJoins={true}
            />
        )
        this.setState({trees})
    }

    handleTimeUnitChange(timeUnit) {
        this.setState({timeUnit}, this.props.setTimeUnit(timeUnit.value))
    }

    handleRemoval(field) {
        let groups = this.props.groups
        groups = groups.filter((a) => a.path !== field.path)
        this.setState(
            {
                view: groups.length ? 'selected' : 'list',
            },
            this.props.setGroup(groups)
        )
    }

    render() {
        return (
            <Fragment>
                <h5 style={{color: '#145388'}}>GROUP BY</h5>
                <p className="text-muted">
                    No groups needed for "Total" report
                </p>
                {this.state.view === 'list' ? (
                    <>
                        {this.props.groups.length ? (
                            <Button
                                className="mb-2"
                                size="xs"
                                block
                                onClick={() =>
                                    this.setState({view: 'selected'})
                                }>
                                Show selected
                            </Button>
                        ) : null}
                        <Scrollbars
                            autoHeight
                            autoHeightMin={this.height}
                            autoHeightMax={this.height}>
                            {this.state.trees}
                        </Scrollbars>
                    </>
                ) : null}

                {this.state.view === 'selected' ? (
                    <>
                        <Button
                            className="mb-2"
                            size="xs"
                            block
                            onClick={() => this.setState({view: 'list'})}>
                            Go back
                        </Button>
                        <ListGroup>
                            {this.props.groups.map((a, idx) => {
                                return (
                                    <ListGroupItem
                                        key={idx}
                                        tag="button"
                                        action
                                        style={{cursor: 'pointer'}}>
                                        <Row>
                                            <Col xs="10">
                                                <Badge>{a.model.name}</Badge>{' '}
                                                {a.path.replace(/@@/g, '')}
                                            </Col>
                                            <Col xs="2">
                                                <i
                                                    className="iconsmind-Close"
                                                    onClick={() =>
                                                        this.handleRemoval(a)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        {a.type === 'RegularizedTimestamp' ? (
                                            <Row>
                                                <Col xs="12">
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        options={
                                                            TIME_UNIT_OPTIONS
                                                        }
                                                        value={
                                                            this.state.timeUnit
                                                        }
                                                        onChange={
                                                            this
                                                                .handleTimeUnitChange
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </>
                ) : null}

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
