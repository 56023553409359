import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class ReasonCodePicker extends Component {
    id = 'ReasonCodePicker'
    field = 'selectedReasonCodes'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props && this.props.existing
                ? this.props.existing.map((state) => {
                      return {label: state, value: state}
                  })
                : []

        this.state = {
            widgets: [],
            selectedStates: initialValue,
            color: 'rgb(215,215,215)',
            reasoncodes: [],
        }

        if (this.props && this.props.cb) {
            this.props.cb('selectedReasonCodes', initialValue)
        }
    }

    async componentWillMount() {
        await this.getReasonCodes()
        // this.setState({selectedReasonCodes: this.state.reasonCodes});
    }

    async getReasonCodes() {
        try {
            const reasonCodes = await API.get('reasoncodes', 2)
            this.setState({
                reasonCodes: reasonCodes.map((code) => {
                    return {
                        label: `${code.category} > ${code.reason}`,
                        value: code.reason,
                        editing: code,
                    }
                }),
            })
        } catch (error) {
            console.error('Could not load reason codes', error)
        }
    }

    render() {
        return (
            <Fragment>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <label>Available Reason Codes:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="reasonCodes"
                    isMulti
                    value={this.state.selectedReasonCodes}
                    onChange={(selectedReasonCodes) => {
                        this.setState({selectedReasonCodes})
                        if (this.props && this.props.cb) {
                            this.props.cb(
                                'selectedReasonCodes',
                                selectedReasonCodes.map((codes) => codes.value)
                            )
                        }
                    }}
                    options={this.state.reasonCodes}
                />
            </Fragment>
        )
    }
}
