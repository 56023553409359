import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Label,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Collapse,
    Card,
    CardBody,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'
import Sortable from 'react-sortablejs'
import {Scrollbars} from 'react-custom-scrollbars'
import {CompactPicker} from 'react-color'

import './checklist.css'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            checklist: props.editing,
            history: [],
            collapsibles: {},
        }

        this.subscriber = new Subscriber()
        this.subscriber.add(
            this.fetchChecklistsComplete,
            10000,
            'fetchChecklistsComplete()'
        )
    }

    async fetchChecklistsComplete(checklist) {
        let history = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'ChecklistComplete',
                    parentId: this.state.checklist._id,
                },
            },
            2
        )

        if (history) {
            this.setState({history})
        }
    }

    getPassedEntries(entryStore) {
        let passed = 0
        entryStore.forEach((entry) => {
            entry.approved ? passed++ : null
        })
        return passed
    }

    getCheckbox(state, id) {
        return state ? (
            <div
                key={id}
                data-id={id}
                className="container"
                style={{marginBottom: 25}}>
                <div className="round-check">
                    <input type="checkbox" id={id} defaultChecked={true} />
                    <Label for={id}></Label>
                </div>
            </div>
        ) : (
            <div
                key={id}
                data-id={id}
                className="container"
                style={{marginBottom: 25}}>
                <div className="round-x">
                    <input type="checkbox" id={id} defaultChecked={true} />
                    <Label for={id}></Label>
                </div>
            </div>
        )
    }

    renderMostRecentTable(record) {
        const totalEntries = record.store.length
        const passedEntries = this.getPassedEntries(record.store)
        const technicianFullName = record.metaData.find(
            (m) => m.name === 'technician'
        )
            ? record.metaData.find((m) => m.name === 'technician').value
            : 'N/A'
        let collapsibles = this.state.collapsibles
        return (
            <Fragment>
                <Row style={{marginBottom: 5}}>
                    <Col xs="3">
                        <span>
                            {moment(record.timestamp).calendar(null, {
                                lastDay: '[Yesterday]',
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                lastWeek: 'ddd, MMM D',
                                nextWeek: 'ddd, MMM D',
                                sameElse: 'ddd, MMM D',
                            })}
                        </span>
                    </Col>
                    <Col xs="3">{technicianFullName}</Col>
                    <Col xs="3" style={{float: 'right'}}>
                        <Badge
                            color={
                                passedEntries < totalEntries
                                    ? 'danger'
                                    : 'success'
                            }>
                            {passedEntries + '/' + totalEntries}
                        </Badge>
                    </Col>
                    <Col xs="3" style={{float: 'right'}}>
                        <Button
                            onClick={() => {
                                collapsibles[record._id] =
                                    !collapsibles[record._id]
                                this.setState({collapsibles})
                            }}>
                            Show Form
                        </Button>
                    </Col>
                </Row>
                <Collapse isOpen={this.state.collapsibles[record._id]}>
                    {record.store.map((entry, id) => {
                        return (
                            <Fragment key={id}>
                                <Row>
                                    <Col>{entry.name}</Col>
                                    <Col>
                                        {this.getCheckbox(
                                            entry.approved,
                                            'checkbox-' + id
                                        )}
                                    </Col>
                                    <Col>
                                        {entry.scheduleRepair && (
                                            <span>
                                                <strong>
                                                    Schedule repair:{' '}
                                                </strong>
                                                {entry.scheduleRepair}
                                            </span>
                                        )}
                                    </Col>
                                    <Col>
                                        {entry.descriptionRepair && (
                                            <span>
                                                <strong>
                                                    Description of repair:{' '}
                                                </strong>
                                                {entry.descriptionRepair}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    })}
                </Collapse>
            </Fragment>
        )
    }

    renderUpcomingTable(checklist, repeatType) {
        const UPCOMING_LIMIT = 5
        let upcomingSequence = []
        let activeMoment = moment()
        let dom = []
        let activeReportPeriod = []

        switch (repeatType) {
            case 'Daily':
                for (let i = 0; i < UPCOMING_LIMIT; ++i) {
                    upcomingSequence.push(moment(activeMoment).endOf('day'))
                    activeMoment.add(1, 'day')
                }
                activeReportPeriod = [
                    moment().startOf('day'),
                    moment().add(1, 'day').startOf('day'),
                ]
                break
            case 'Weekly':
                for (let i = 0; i < UPCOMING_LIMIT; ++i) {
                    upcomingSequence.push(moment(activeMoment).endOf('isoWeek'))
                    activeMoment.add(1, 'weeks')
                }
                activeReportPeriod = [
                    moment().startOf('isoWeek'),
                    moment().add(1, 'weeks').startOf('isoWeek'),
                ]
                break
            case 'Monthly':
                for (let i = 0; i < UPCOMING_LIMIT; ++i) {
                    upcomingSequence.push(moment(activeMoment).endOf('month'))
                    activeMoment.add(1, 'month')
                }
                activeReportPeriod = [
                    moment().startOf('month'),
                    moment().add(1, 'month').startOf('month'),
                ]
                break
            case 'Quarterly':
                for (let i = 0; i < UPCOMING_LIMIT; ++i) {
                    upcomingSequence.push(moment(activeMoment).endOf('quarter'))
                    activeMoment.add(1, 'quarter')
                }
                activeReportPeriod = [
                    moment().startOf('quarter'),
                    moment().add(1, 'quarter').startOf('quarter'),
                ]
                break
            case 'Yearly':
                for (let i = 0; i < UPCOMING_LIMIT; ++i) {
                    upcomingSequence.push(moment(activeMoment).endOf('year'))
                    activeMoment.add(1, 'year')
                }
                activeReportPeriod = [
                    moment().startOf('year'),
                    moment().add(1, 'year').startOf('year'),
                ]
                break
            default:
                activeReportPeriod = [moment().startOf('year'), moment()]
                break
        }

        for (let i = 0; i < UPCOMING_LIMIT; ++i) {
            if (upcomingSequence.length > 0) {
                dom.push(
                    <Fragment>
                        <Row style={{marginBottom: 5}}>
                            <Col xs="8">{upcomingSequence[i].calendar()}</Col>
                            <Col xs="4" style={{float: 'right'}}>
                                {upcomingSequence[i].isBetween(
                                    activeReportPeriod[0],
                                    activeReportPeriod[1]
                                ) ? (
                                    <Badge
                                        color="warning"
                                        onClick={() => {
                                            this.props.checklistFillModal(
                                                this.state.checklist
                                            )
                                        }}>
                                        Current Period
                                    </Badge>
                                ) : (
                                    <Badge color="primary">Not Due</Badge>
                                )}
                            </Col>
                        </Row>
                    </Fragment>
                )
            }
        }

        return dom
    }

    render() {
        const {modal, toggleModal, editing} = this.props
        const {history, checklist} = this.state

        return (
            <Fragment>
                <Modal size="lg" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Scheduled Preventative Maintenance
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <Scrollbars
                                    autoHeightMin={400}
                                    autoHeightMax={400}
                                    autoHeight>
                                    <strong>Most Recent Filings</strong>
                                    {history
                                        .slice(0)
                                        .reverse()
                                        .map((record) => {
                                            return this.renderMostRecentTable(
                                                record
                                            )
                                        })}
                                </Scrollbars>
                            </Col>
                            <Col xs="12">
                                <strong>Next Scheduled Filings</strong>
                                {this.renderUpcomingTable(
                                    checklist,
                                    checklist.metaData.find(
                                        (o) => o.name === 'repeat'
                                    ).value
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() =>
                                this.props.checklistFillModal(
                                    this.state.checklist
                                )
                            }>
                            File Now
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
