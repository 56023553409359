import React from 'react'

export default class NodeLabel extends React.PureComponent {
    render() {
        const {className, nodeData} = this.props
        return (
            <div>
                <p style={{marginBottom: 0}}>
                    <strong>{nodeData.name}</strong>
                </p>
                <h4>{nodeData.attributes[0]}</h4>
            </div>
        )
    }
}
