import Subscription from './subscription'

export default class Subscriber {
    constructor() {
        this.subscriptions = []
    }
    fetch(name) {
        return this.subscriptions.find((sub) => sub.name === name)
    }
    add(func, ms, name) {
        try {
            this.subscriptions.push(new Subscription(func, ms, name))
            let sub = this.fetch(name)
            if (sub) {
                sub.init()
            } else {
                throw Error('Subscription not found')
            }
        } catch (error) {
            console.log(error)
        }
    }
    remove(name) {
        if (!name) {
            throw Error('Must include name of subscription')
        }
        let sub = this.fetch(name)
        sub.stop()
    }
    removeAll() {
        for (let i = 0, l = this.subscriptions.length; i < l; i++) {
            this.subscriptions[i].stop()
        }
    }
}
