import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {TextEditor} from 'react-data-grid'
import * as API from 'SDK/api'
import moment from 'moment'
import {evaluate} from 'mathjs'
import dot from 'dot-object'
import {cloneDeep} from 'lodash'

let editorStyle = {
    appearance: 'none',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: '0px 6px 0 6px',
    border: '2px solid #ccc',
    verticalAlign: 'top',
    fontFamily: 'inherit',
}

function autoFocusAndSelect(input) {
    input?.focus()
    input?.select()
}

const PickListEditor = ({row, column, onRowChange, onClose}) => {
    return (
        <Select
            placeholder={'Type to search...'}
            options={column.field.pickListValues.map((v) => {
                return {label: v, value: v}
            })}
            onChange={({value}) =>
                onRowChange({...row, [column.key]: value}, true)
            }
            menuPortalTarget={document.querySelector('body')}
        />
    )
}

const NumberEditor = ({row, column, onRowChange, onClose}) => {
    return (
        <input
            style={editorStyle}
            ref={autoFocusAndSelect}
            value={row[column.key]}
            onChange={(event) =>
                onRowChange({
                    ...row,
                    [column.key]: parseFloat(event.target.value) || undefined,
                })
            }
            onBlur={() => onClose(true)}
            type="number"
        />
    )
}

const BooleanEditor = ({row, column, onRowChange, onClose}) => {
    return (
        <input
            //style={editorStyle}
            ref={autoFocusAndSelect}
            checked={row[column.key]}
            onChange={(event) =>
                onRowChange({...row, [column.key]: event.target.checked})
            }
            onBlur={() => onClose(true)}
            type="checkbox"
        />
    )
}

const ColorEditor = ({row, column, onRowChange, onClose}) => {
    return (
        <input
            style={editorStyle}
            ref={autoFocusAndSelect}
            value={row[column.key]}
            onChange={(event) =>
                onRowChange({...row, [column.key]: event.target.value})
            }
            onBlur={() => onClose(true)}
            type="color"
        />
    )
}

const TimestampEditor = ({row, column, onRowChange, onClose}) => {
    return (
        <input
            style={editorStyle}
            ref={autoFocusAndSelect}
            value={row[column.key]}
            onChange={(event) =>
                onRowChange({
                    ...row,
                    [column.key]: moment(event.target.value).toISOString(),
                })
            }
            onBlur={() => onClose(true)}
            type="datetime-local"
        />
    )
}

const ManualDataLinkEditor = (
    columnPath,
    dataModelId,
    row,
    onRowChange,
    pathToDisplay,
    searchPath,
    foreignOptionFilter
) => {
    const handleSearch = async (query) => {
        return new Promise((resolve) => {
            API.get(`data-models`, 2).then((dataModels) => {
                const dataModel = dataModels.find(
                    (model) => model._id === dataModelId
                )
                API.post(
                    `data-models/${dataModel._id}/paginate`,
                    {
                        filter: [
                            {
                                path: searchPath,
                                type: pathToDisplay.includes('_id')
                                    ? 'ObjectID'
                                    : 'Text',
                                logic: pathToDisplay.includes('_id')
                                    ? 'is'
                                    : 'contains',
                                value: [query],
                            },
                        ],
                        sort: {},
                        limit: 100,
                        next: null,
                        previous: null,
                    },
                    2
                ).then(({result}) => {
                    let results = result.results
                    if (foreignOptionFilter) {
                        try {
                            const obj = dot.object(cloneDeep(row))
                            let plainText = foreignOptionFilter.plainText
                            for (let instance of foreignOptionFilter.map) {
                                const dataModel = dataModels.find(
                                    (a) => a.name === instance.display
                                )
                                plainText = plainText.replace(
                                    new RegExp(instance.display, 'g'),
                                    'str' + dataModel._id
                                )
                            }
                            results = results.filter((foreignRow) => {
                                return evaluate(plainText, {
                                    ['str' + foreignRow.dataModelId]:
                                        foreignRow,
                                    ['str' + row.dataModelId]: obj,
                                })
                            })
                        } catch (error) {
                            return false
                        }
                    }

                    resolve(
                        results.map((row) => {
                            return {
                                label: row[searchPath],
                                value: row._id,
                            }
                        })
                    )
                })
            })
        })
    }

    return (
        <AsyncSelect
            placeholder={'Type to search...'}
            loadOptions={handleSearch}
            onChange={({value}) =>
                onRowChange({...row, [columnPath]: value}, true)
            }
            menuPortalTarget={document.querySelector('body')}
        />
    )
}

const editors = {
    Text: TextEditor,
    TextArea: TextEditor, // maybe drawer editor?
    Email: TextEditor,
    URL: TextEditor,
    PrimaryID: TextEditor,
    ForeignID: ({column, row, onRowChange}, pathToDisplay, searchPath) => {
        return ManualDataLinkEditor(
            column.key,
            column.field.foreignDataModelId,
            row,
            onRowChange,
            pathToDisplay,
            searchPath,
            column.field.foreignOptionFilter
        )
    },
    Number: NumberEditor,
    Boolean: BooleanEditor,
    Color: ColorEditor,
    DesignatedTimestamp: TimestampEditor,
    DesignatedTimeStart: TimestampEditor,
    DesignatedTimeEnd: TimestampEditor,
    Timestamp: TimestampEditor,
    MultiSubModel: TextEditor,
    PickList: PickListEditor,
}

export default editors
