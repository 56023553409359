

export function _keyedListSearch_(objects, makeKey) {
    // IMPORTANT USAGE NOTES
    // DO NOT CALL IN A LOOP! USE THE RETURNED FUNCTION INSIDE OF YOUR LOOP.
    // The top level builder function (_keyedListSearch_) shuld only be run once!
    // On the first call it will create a dictionary that can later be used to find objects
    // using a key instead of continuous looping.
    let searchableMap = {}
    if(objects) {
        searchableMap = objects.reduce(
            (dict, obj)=> {
                dict[makeKey(obj)] = obj;
                return dict;
            },
            {}
        )
    }
    return (key) => {
        return searchableMap[key];
    }
}
