import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DataTablePagination from 'components/DataTables/pagination'
import moment from 'moment'

import {Alert} from 'reactstrap'

export default class ComponentTable extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            history: this.props.history || [],
            item: this.props.item || [],
            entriesTotal: 0,
            entriesApproved: 0,
            data: [],
            columns: [
                {
                    Header: 'Date',
                    accessor: 'Date', // accessor is the "key" in the data
                },
                {
                    Header: 'Technician',
                    accessor: 'Technician',
                },
                {
                    Header: 'Outcome',
                    accessor: 'Outcome',
                },
                {
                    Header: 'Repair',
                    accessor: 'Repair',
                },
                {
                    Header: 'Time Loss',
                    accessor: 'TimeLoss',
                },
            ],
        }
    }

    componentDidMount() {
        const {history, item} = this.state
        let data = []
        let entriesApproved = 0

        history.forEach((h) => {
            data.push({
                Date: moment(h.timestamp).format('MM-DD-YYYY'),
                Technician: h.metaData.find((m) => m.name === 'technician')
                    .value,
                Outcome: h.store.find((m) => m.name === item.name).approved
                    ? 'PASS'
                    : 'FAIL',
                Repair: h.store.find((m) => m.name === item.name)
                    .descriptionRepair,
                TimeLoss:
                    h.store.find((m) => m.name === item.name).timeloss || 0,
            })

            h.store.find((m) => m.name === item.name).approved
                ? entriesApproved++
                : null
        })

        this.setState({
            data,
            entriesApproved,
            entriesTotal: data.length,
        })
    }

    render() {
        const passRate =
            (this.state.entriesApproved / this.state.entriesTotal) * 100
        return (
            <Fragment>
                <Alert color={passRate > 80 ? 'success' : 'danger'}>
                    This component has a {passRate}% pass rate
                </Alert>
                <ReactTable
                    defaultPageSize={10}
                    data={this.state.data}
                    columns={this.state.columns}
                    minRows={0}
                    PaginationComponent={DataTablePagination}
                    filterable={true}
                />
            </Fragment>
        )
    }
}
