import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            name: '',
            showExistingViewSelection: false,
            environmentOptions: [],
            environment: null,
            section: null,
            sections: [],
            view: null,
            views: [],
        }
    }

    async toggleExistingViewSelection() {
        let environments = await API.get('environments', 2),
            templates = await API.get('environments/templates', 2)

        let e = environments.map((e) => {
            return {label: e.name, value: e._id, obj: e}
        })
        let t = templates.map((t) => {
            return {label: t.name, value: t._id, obj: t}
        })

        this.setState({
            showExistingViewSelection: !this.state.showExistingViewSelection,
            environmentOptions: e.concat(t),
        })
    }

    handleEnvironmentChange(environment) {
        this.setState({
            environment,
            section: null,
            view: null,
            sections: environment.obj.sections.map((s) => {
                return {label: s.name, value: s.id}
            }),
        })
    }

    handleSectionChange(section) {
        this.setState({
            section,
            view: null,
            views: this.state.environment.obj.sections
                .find((s) => s.id === section.value)
                .views.map((v) => {
                    return {label: v.name, value: v.id}
                }),
        })
    }

    handleViewChange(view) {
        this.setState({
            view,
        })
    }

    copyView() {
        let view = {
            ...this.state.environment.obj.sections
                .find((s) => s.id === this.state.section.value)
                .views.find((v) => v.id === this.state.view.value),
        }

        this.props.handleViewCopySelection(view)
    }

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>View Builder</ModalHeader>
                    <ModalBody>
                        {!this.state.showExistingViewSelection ? (
                            <Row>
                                <Col xs="12" className="mb-2">
                                    <Card
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            this.props.handleViewTypeSelection(
                                                'generic'
                                            )
                                        }}>
                                        <CardBody>
                                            <h4>Use an existing module</h4>
                                            <p>
                                                Start with a pre-designed
                                                module.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs="12" className="mb-2">
                                    <Card
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            this.props.handleViewTypeSelection(
                                                'custom'
                                            )
                                        }}>
                                        <CardBody>
                                            <h4>Make a custom dashboard</h4>
                                            <p>
                                                Drag & drop your own custom data
                                                visualizations.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs="12" className="mb-2">
                                    <Card
                                        style={{cursor: 'pointer'}}
                                        onClick={
                                            this.toggleExistingViewSelection
                                        }>
                                        <CardBody>
                                            <h4>Copy an existing view</h4>
                                            <p>
                                                Use an existing view from your
                                                company environment.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.showExistingViewSelection ? (
                            <>
                                <Row>
                                    <Col>
                                        <Label>Application:</Label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="environment"
                                            value={this.state.environment}
                                            onChange={
                                                this.handleEnvironmentChange
                                            }
                                            options={
                                                this.state.environmentOptions
                                            }></Select>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>Section:</Label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="section"
                                            value={this.state.section}
                                            onChange={this.handleSectionChange}
                                            options={
                                                this.state.sections
                                            }></Select>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>View:</Label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="view"
                                            value={this.state.view}
                                            onChange={this.handleViewChange}
                                            options={this.state.views}></Select>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </ModalBody>
                    {this.state.view ? (
                        <ModalFooter>
                            <Button
                                color="primary"
                                className="btn-block"
                                onClick={this.copyView}>
                                Use selected view
                            </Button>
                        </ModalFooter>
                    ) : null}
                </Modal>
            </Fragment>
        )
    }
}
