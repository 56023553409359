import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    Input,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import Dropzone from 'react-dropzone'
import {v4 as uuidv4} from 'uuid'

import * as API from 'SDK/api'
import PointOfInterest from './PointOfInterest'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.height = document.documentElement.offsetHeight * 0.5
        this.width = document.documentElement.offsetWidth * 0.5

        this.state = {
            newPOIName: '',
            pois: this.props.editingModel.pois
                ? this.props.editingModel.pois.map((p) => {
                      p.static = true
                      return p
                  })
                : [],
            backgroundImage: this.props.editingModel.backgroundImage || null,
        }
    }

    addPOI() {
        let {pois} = this.state
        pois.push({
            id: uuidv4(),
            name: this.state.newPOIName,
            x: 0,
            y: 0,
            width: 100,
            height: 100,
            training: {
                samples: 0,
            },
        })

        this.setState({newPOIName: '', pois})
    }

    uploadBackgroundImage(file) {
        this.setState({loading: true})
        if (!file.type.includes('image')) {
            return alert('Invalid file uploaded! Must be an image.')
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
            this.setState({
                loading: false,
                backgroundImage: event.target.result,
            })
        }
    }

    updatePOI({id, name, x, y, width, height}) {
        let {pois} = this.state
        const poi = pois.find((p) => p.id === id)
        if (poi) {
            poi.name = name
            poi.x = x
            poi.y = y
            poi.width = width
            poi.height = height

            this.setState({pois})
        }
    }

    editPOIName() {
        let {pois} = this.state
        const poi = pois.find((p) => p.id === this.state.editing.id)
        if (poi) {
            poi.name = this.state.editing.name

            this.setState({pois, editing: null})
        }
    }

    deletePOI() {
        let {pois} = this.state
        pois = pois.filter((p) => p.id !== this.state.editing.id)

        this.setState({pois, editing: null})
    }

    async saveChanges() {
        await API.patch(
            'ips/cloud/models/' + this.props.editingModel.version,
            {
                pois: this.state.pois,
                backgroundImage: this.state.backgroundImage,
                backgroundImageHeight: this.height,
                backgroundImageWidth: this.width,
            },
            2
        )

        this.props.toggleModal()
    }

    toggleEditMode({id, name}) {
        if (this.state.editing) {
            this.setState({
                editing: null,
            })
        } else {
            this.setState({
                editing: {id, name},
            })
        }
    }

    changeBackgroundImage() {
        this.setState({
            backgroundImage: null,
        })
    }

    render() {
        const {modal, toggleModal} = this.props,
            uploadBackgroundImagePrompt = this.state.backgroundImage === null,
            version = this.props.editingModel
                ? this.props.editingModel.version
                : ''

        return (
            <Fragment>
                <Modal isOpen={modal} size="lg">
                    <ModalHeader>Version {version} Map Editor</ModalHeader>
                    <ModalBody>
                        {!uploadBackgroundImagePrompt && !this.state.editing ? (
                            <>
                                <Row>
                                    <Col className="text-right">
                                        <InputGroup className="mb-3">
                                            <Input
                                                value={this.state.newPOIName}
                                                onChange={(value) => {
                                                    this.setState({
                                                        newPOIName:
                                                            value.target.value,
                                                    })
                                                }}
                                                type="text"
                                                name="newPOIName"
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        this.addPOI()
                                                    }
                                                }}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="primary"
                                                    onClick={this.addPOI}>
                                                    Add Point of Interest
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="warning"
                                                    onClick={
                                                        this
                                                            .changeBackgroundImage
                                                    }>
                                                    Change Floorplan
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {this.state.pois.length ? (
                                    <Row>
                                        <Col>
                                            <p className="text-center mb-0">
                                                Double click on an existing
                                                Point of Interest for further
                                                actions.
                                            </p>
                                        </Col>
                                    </Row>
                                ) : null}
                            </>
                        ) : null}
                        {!uploadBackgroundImagePrompt && this.state.editing ? (
                            <>
                                <Row>
                                    <Col className="text-right">
                                        <InputGroup className="mb-3">
                                            <Input
                                                value={
                                                    this.state.editing
                                                        ? this.state.editing
                                                              .name
                                                        : ''
                                                }
                                                onChange={(value) => {
                                                    this.setState({
                                                        editing: {
                                                            name: value.target
                                                                .value,
                                                            id: this.state
                                                                .editing.id,
                                                        },
                                                    })
                                                }}
                                                type="text"
                                                name="editingPOIName"
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="primary"
                                                    onClick={this.editPOIName}>
                                                    Update Name
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="danger"
                                                    onClick={this.deletePOI}>
                                                    Delete
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="text-center mb-0">
                                            Double click on the same Point of
                                            Interest to exit editing mode.
                                        </p>
                                        <p className="text-muted text-center mb-0">
                                            Note: Points of Interest cannot be
                                            modified on the map once saved.
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        ) : null}

                        <Row>
                            <Col
                                style={{
                                    height: this.height + 'px',
                                    width: this.width + 'px',
                                    padding: 0,
                                }}>
                                {uploadBackgroundImagePrompt ? (
                                    <Dropzone
                                        multi={false}
                                        onDrop={(files) => {
                                            this.uploadBackgroundImage(files[0])
                                        }}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <p className="text-center">
                                                        Drag and drop an floor
                                                        plan image here or click
                                                        to open your files.
                                                    </p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                ) : (
                                    <TransformWrapper
                                        options={{
                                            limitToBounds: false,
                                            minScale: 0.1,
                                        }}
                                        wheel={{
                                            step: 2,
                                        }}
                                        defaultScale={1}
                                        doubleClick={{disabled: true}}>
                                        {({scale, ...rest}) => (
                                            <>
                                                <div
                                                    style={{
                                                        width: 'fit-content',
                                                        height: '100%',
                                                        overflowX: 'hidden',
                                                    }}>
                                                    <TransformComponent>
                                                        <img
                                                            style={{
                                                                width: this
                                                                    .width,
                                                                height: this
                                                                    .height,
                                                            }}
                                                            src={
                                                                this.state
                                                                    .backgroundImage
                                                            }
                                                            alt="test"
                                                        />
                                                        {this.state.pois.map(
                                                            (p) => {
                                                                return (
                                                                    <PointOfInterest
                                                                        key={
                                                                            p.id
                                                                        }
                                                                        id={
                                                                            p.id
                                                                        }
                                                                        name={
                                                                            p.name
                                                                        }
                                                                        scale={
                                                                            scale
                                                                        }
                                                                        updatePOI={
                                                                            this
                                                                                .updatePOI
                                                                        }
                                                                        width={
                                                                            p.width
                                                                        }
                                                                        height={
                                                                            p.height
                                                                        }
                                                                        x={p.x}
                                                                        y={p.y}
                                                                        static={
                                                                            p.static
                                                                        }
                                                                        toggleEditMode={
                                                                            this
                                                                                .toggleEditMode
                                                                        }
                                                                        beingEdited={
                                                                            this
                                                                                .state
                                                                                .editing &&
                                                                            this
                                                                                .state
                                                                                .editing
                                                                                .id ===
                                                                                p.id
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </TransformComponent>
                                                </div>
                                            </>
                                        )}
                                    </TransformWrapper>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn" onClick={toggleModal}>
                            Cancel Changes
                        </Button>
                        <Button
                            color="primary"
                            className="btn"
                            onClick={this.saveChanges}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
