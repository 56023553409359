import React, {Component, Fragment} from 'react'
import {Row} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import DowntimeEntryTile from './DowntimeEntryTile'

// Material Entry
import MaterialAPI from 'SDK/ui/AMC2023/Material/calls'

export default class RealTimeDowntimeEntry extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'RealTimeDowntimeEntry'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            data: [],
            reasoncodes: [],
            machineEntry: false,
        }
    }

    async fetchData() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: this.state.devices.map((d) => d.deviceId)},
                    name: {
                        $in: [
                            'In-Cycle',
                            'Downtime',
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        this.setState({data})
    }

    toggleFlash() {
        this.setState({
            flash: !this.state.flash,
        })
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        this.setState({
            devices,
            reasoncodes: await API.get('reasoncodes', 2),
            materialEntry: await MaterialAPI.isEnabled(),
        })

        this.subscriber.add(this.fetchData, 1000 * 5, 'fetchData()')
        this.subscriber.add(this.toggleFlash, 1000, 'toggleFlash()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    {this.state.devices.length && this.state.data.length
                        ? this.state.devices.map((device, i) => {
                              return (
                                  <DowntimeEntryTile
                                      key={i}
                                      deviceId={device.deviceId}
                                      name={device.name}
                                      flash={this.state.flash}
                                      downtimeThresholdSeconds={
                                          device.downtimeThresholdSeconds
                                      }
                                      data={this.state.data.filter(
                                          (d) => d.deviceId === device.deviceId
                                      )}
                                      reasoncodes={this.state.reasoncodes}
                                      materialEntry={this.state.materialEntry}
                                  />
                              )
                          })
                        : null}
                </Row>
            </Fragment>
        )
    }
}
