import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import View from './view'
import EmptyView from './empty-view'

export default (props) => {
    const editingEnvironment = props.editingEnvironment
    const updateEnvironment = props.updateEnvironment
    const mouseMoving = props.mouseMoving
    const toggleAddNewViewModal = props.toggleAddNewViewModal
    const mqttClient = props.mqttClient

    return (
        <Switch>
            <Route
                path={`${props.match.url}/:environmentId/:sectionId/:viewId/:mode`}
                editingEnvironment={props.editingEnvironment}
                render={(props) => (
                    <View
                        toggleAddNewViewModal={toggleAddNewViewModal}
                        mouseMoving={mouseMoving}
                        key={
                            props.match.params.viewId + props.match.params.mode
                        }
                        editingEnvironment={editingEnvironment}
                        updateEnvironment={updateEnvironment}
                        mqttClient={mqttClient}
                        {...props}
                    />
                )}
            />
            <Route
                path={`${props.match.url}/:environmentId/empty`}
                editingEnvironment={props.editingEnvironment}
                render={(props) => (
                    <EmptyView
                        key={
                            props.match.params.viewId + props.match.params.mode
                        }
                        editingEnvironment={editingEnvironment}
                        {...props}
                    />
                )}
            />
            <Redirect to="/error" />
        </Switch>
    )
}
