import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Badge} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-duration-format'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddModal from './add'
import RemoveModal from './remove'
import EditModal from './edit'
import StatusModal from './status'
import ChecklistModal from '../SimplePreventativeMaintenance/add'

const columns = [
    {
        Header: 'Asset',
        accessor: 'labels.assetName',
    },
    {
        Header: 'Status',
        accessor: 'labels.status',
    },
    {
        Header: 'Progress',
        accessor: 'labels.statusIndicator',
    },
    {
        Header: 'Equipment',
        accessor: 'labels.equipmentName',
    },
    {
        Header: 'Supervisor',
        accessor: 'labels.assignedName',
    },
    {
        Header: 'Department',
        accessor: 'labels.departmentName',
    },
    {
        Header: 'Deadline',
        accessor: 'labels.deadline',
    },
    {
        Header: 'Work Time',
        accessor: 'labels.workTime',
    },
]

const STATUS_COLORS = {
    Opened: 'warning',
    'On Hold': 'danger',
    'In Progress': 'primary',
    Completed: 'success',
}

export default class SimpleMaintenance extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'SimpleMaintenance'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            tickets: [],
            filteredTickets: [],
            editing: null,
            addModal: false,
            users: [],
            devices: [],
            workflows: [],
            STATUS_OPTIONS: [],
            hideStatuses: [],
        }
    }

    async fetchUsers() {
        let users = await API.get('users?all=true', 2)
        this.setState({users})
    }

    async fetchDevices() {
        let devices = await API.get('devices?all=true')
        this.setState({devices})
    }

    async fetchTickets() {
        let tickets = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Maintenance Ticket',
                    timeStart: {
                        $gte: moment().subtract(6, 'months').toISOString(),
                    },
                },
                options: {
                    sort: {
                        timeStart: -1,
                    },
                },
            },
            2
        )

        tickets = tickets.map((t) => this.mapTicket(t))

        if (tickets) {
            this.setState({tickets}, this.filterTickets)
        }
    }

    async fetchWorkflows() {
        let workflows = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Workflow',
                },
            },
            2
        )

        if (workflows) {
            this.setState({workflows})
        }
    }

    filterTickets() {
        if (this.state.hideStatuses.length > 0) {
            let showStatuses = this.state.hideStatuses.map(
                (status) => status.value
            )
            this.setState({
                filteredTickets: this.state.tickets.filter((ticket) => {
                    return ticket.metaData.find((t) => {
                        return (
                            t.name === 'status' &&
                            showStatuses.includes(t.value)
                        )
                    })
                }),
            })
        }
    }

    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal,
        })
    }

    toggleStatusModal() {
        this.setState({
            statusModal: !this.state.statusModal,
        })
    }

    toggleChecklistModal() {
        this.setState({
            checklistModal: !this.state.checklistModal,
        })
    }

    toggleModal(modalName, code) {
        let state = this.state
        state.editing = code
        state[modalName] = !state[modalName]
        this.setState({state})

        this.fetchTickets()
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('editModal', code),
                actionName: 'View/Update',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('removeModal', code),
                actionName: 'Delete',
                buttonColor: 'danger',
            },
        ]
    }

    mapTicket(ticket) {
        const status = ticket.metaData.find((o) => o.name === 'status').value
        const statusIndicator = (
            <div
                style={{
                    width: 15,
                    height: 15,
                    borderRadius: 50,
                    backgroundColor: ticket.metaData.find(
                        (o) => o.name === 'color'
                    ).value,
                }}></div>
        )
        const departmentName = ticket.department && ticket.department.label
        const deadline = ticket.metaData.find(
            (o) => o.name === 'deadline'
        ).value
        const userId = ticket.metaData.find((o) => o.name === 'userId').value,
            user = this.state.users.find((u) => u._id === userId)
        const assignedName = ticket.assignment && ticket.assignment.label
        const equipmentName = ticket.equipment && ticket.equipment.label
        const workTime = ticket.workTime || 0

        let ageDiff = moment(deadline).diff(moment())
        let ticketAgeResult = moment
            .duration(ageDiff, 'ms')
            .format('d [days], h [hours], m [minutes], s [seconds]', {
                useSignificantDigits: true,
                trunc: true,
                precision: 5,
            })

        ticket.labels = {
            assetName: this.state.devices.find(
                (device) => device.deviceId === ticket.deviceId
            )
                ? this.state.devices.find(
                      (device) => device.deviceId === ticket.deviceId
                  ).name
                : '',
            departmentName,
            equipmentName,
            assignedName,
            status,
            statusIndicator,
            deadline: moment(deadline).calendar(null, {
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastWeek: 'ddd, MMM D',
                nextWeek: 'ddd, MMM D',
                sameElse: 'ddd, MMM D',
            }),
            ticketAge: ticketAgeResult,
            workTime,
        }
        return ticket
    }

    dataTableFormatter(ticket) {
        return ticket
    }

    editorModalAttributes(ticket) {
        return [
            [
                'Name',
                ticket.labels.departmentName + ' > ' + ticket.labels.assetName,
            ],
            ['Description', ticket.description],
        ]
    }

    async componentWillMount() {
        await this.fetchUsers()
        await this.fetchDevices()
        await this.fetchWorkflows()
        await this.fetchTickets()

        try {
            const STATUS_OPTIONS = this.state.workflows[0].value.map(
                (value) => {
                    return {label: value.name, value: value.name}
                }
            )
            this.setState(
                {
                    STATUS_OPTIONS,
                    hideStatuses: STATUS_OPTIONS.filter(
                        (value) => value.label != 'COMPLETED'
                    ),
                },
                this.filterTickets
            )
        } catch (err) {
            console.error('There is no workflow set.', err)
        }
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstTicket = (
                <div>
                    <Button color="warning" onClick={this.toggleStatusModal}>
                        Create your default maintenance workflow
                    </Button>
                    <Button color="primary" onClick={this.toggleAddModal}>
                        Then add your first maintenance ticket
                    </Button>
                </div>
            ),
            tableExists = this.state.tickets.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Fragment>
                        <Row>
                            <Col
                                xs="12"
                                className="mb-2"
                                style={{float: 'left'}}>
                                <Button
                                    color="primary"
                                    onClick={this.toggleAddModal}>
                                    Create Ticket
                                </Button>{' '}
                                <Button
                                    color="warning"
                                    onClick={this.toggleStatusModal}>
                                    Manage Workflows
                                </Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleChecklistModal}>
                                    Manage Checklists
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="9"
                                className="mb-2"
                                style={{float: 'left'}}>
                                <label>Show/Hide Status:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="hide-status"
                                    value={this.state.hideStatuses}
                                    isMulti
                                    onChange={(hideStatuses) => {
                                        this.setState(
                                            {hideStatuses},
                                            this.filterTickets
                                        )
                                    }}
                                    options={this.state.STATUS_OPTIONS}
                                />
                            </Col>
                            <Col className="mb-2" style={{float: 'right'}}>
                                <label>Refresh table:</label>
                                <br />
                                <Button onClick={this.fetchTickets}>
                                    Update Now
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Tickets Found"
                    emptyCrudSubText={addFirstTicket}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.filteredTickets}
                    editorModalTitle="Ticket Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addModal ? (
                    <AddModal
                        toggleModal={this.toggleAddModal}
                        modal={this.state.addModal}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        tickets={this.state.tickets}
                        editing={this.state.editing}
                    />
                ) : null}

                {this.state.statusModal ? (
                    <StatusModal
                        toggleModal={this.toggleStatusModal}
                        modal={this.state.statusModal}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                        onSave={this.fetchWorkflows}
                    />
                ) : null}

                {this.state.checklistModal ? (
                    <ChecklistModal
                        toggleModal={this.toggleChecklistModal}
                        modal={this.state.checklistModal}
                        onSave={this.fetchWorkflows}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        tickets={this.state.tickets}
                        editing={this.state.editing}
                        workflows={this.state.workflows}
                    />
                ) : null}
            </Fragment>
        )
    }
}
