import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'

import PropTypes from 'prop-types'

import HourlyTargetActualChart from './HourlyTargetActualChart'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import * as SDK from './RealTimeWorkOrderStatusTile/sdk'
import {Typeahead, Menu, MenuItem} from 'react-bootstrap-typeahead'

import Moment from 'moment'
import {extendMoment} from 'moment-range'
const moment = extendMoment(Moment)

export default class PartAdd extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = ['Part']
    showBorder = false
    id = 'PartAdd'
    static propTypes = {
        deviceId: PropTypes.string,
        size: PropTypes.string,
        range: PropTypes.object, // moment.range
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            parts: [],
            form: ['partNumber', 'target'],
        }
    }

    async componentWillMount() {
        await this.getParts()
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    async savePart() {
        if (!this.state.partNumber || !this.state.target) {
            alert('One of the fields are empty.')
            return false
        }

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Part',
                timestamp: moment(),
                timeStart: moment(),
                timeEnd: moment(),
                value: this.state.partNumber,
                metaData: [
                    {
                        name: 'part',
                        value: this.state.partNumber,
                    },
                    {
                        name: 'target',
                        value: this.state.target,
                    },
                ],
            },
            2
        )

        await this.getParts()

        this.clearForm()
    }

    clearForm() {
        this.state.form.forEach((f) => this.setState({[f]: ''}))
    }

    async getParts() {
        let parts = await API.post(
            'historical/raw',
            {
                query: {
                    timestamp: {
                        $gt: moment().subtract(15, 'years'),
                        $lt: moment(),
                    },
                    name: 'Part',
                },
            },
            2
        )

        this.setState({parts})
    }

    handleChange(input, e) {
        this.setState({[input]: e.target.value})
    }

    render() {
        const {parts} = this.state

        return (
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <InputGroup
                                style={{
                                    borderRadius: 5,
                                    padding: 10,
                                    margin: 5,
                                }}>
                                <InputGroupAddon addonType="prepend">
                                    Part
                                </InputGroupAddon>
                                <Input
                                    placeholder="Number or Name"
                                    value={this.state.partNumber}
                                    onChange={(e) => {
                                        this.handleChange('partNumber', e)
                                    }}
                                />
                            </InputGroup>
                            <InputGroup
                                style={{
                                    borderRadius: 5,
                                    padding: 10,
                                    margin: 5,
                                }}>
                                <InputGroupAddon
                                    addonType="prepend"
                                    style={{backgroundColor: '#ccc'}}>
                                    Target
                                </InputGroupAddon>
                                <Input
                                    placeholder="Amount"
                                    min={0}
                                    max={10000}
                                    type="number"
                                    step="1"
                                    value={this.state.target}
                                    onChange={(e) => {
                                        this.handleChange('target', e)
                                    }}
                                />
                            </InputGroup>
                            <div
                                style={{
                                    borderRadius: 5,
                                    padding: 10,
                                    margin: 5,
                                }}>
                                <Button
                                    color="primary"
                                    size="lg"
                                    active
                                    onClick={this.savePart}>
                                    Add Part
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
