import React, {Component, Fragment} from 'react'
import {Chart, Bar} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import moment from 'moment'

import {ThemeColors} from 'util/ThemeColors'
const colors = ThemeColors()

let chartConfig = {
    responsive: true,
    maintainAspectRatio: true,
    padding: {
        top: 0,
    },
    title: {
        text: '',
        display: true,
    },
    legend: {
        display: false,
    },
    scales: {
        yAxes: [
            {
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: 'rgba(0,0,0,0.1)',
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                    padding: 20,
                },
            },
        ],
        xAxes: [
            {
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)

        if (
            moment(this.props.timerange[1]).diff(
                moment(this.props.timerange[0]),
                'hours'
            ) <= 12
        ) {
            Chart.plugins.register(ChartDataLabels)
        }
    }

    componentWillUnmount() {
        Chart.plugins.unregister(ChartDataLabels)
    }

    render() {
        const data = {
            labels: this.props.hours,
            datasets: [
                {
                    label: 'Target ' + this.props.productionUnit,
                    type: 'line',
                    data: new Array(this.props.data.length).fill(
                        this.props.target
                    ),
                    backgroundColor: 'transparent',
                    borderColor: 'darkgrey',
                    datalabels: {display: false},
                },
                {
                    label: this.props.productionUnit,
                    data: this.props.data,
                    borderColor: colors.themeColor1,
                    backgroundColor: '#145388',
                    datalabels: {
                        color: '#fff',
                        //align: 'top',
                        display: function (context) {
                            return context.dataset.data[context.dataIndex] > 0
                        },
                    },
                },
            ],
        }
        chartConfig.title.text =
            moment(this.props.timerange[0]).format('ddd h:mma') + ' to now'
        return <Bar data={data} options={chartConfig} />
    }
}
