import React, {Component, Fragment} from 'react'
import {Bar, Chart} from 'react-chartjs-2'
import moment from 'moment'
import autobind from 'auto-bind'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {Row, Col} from 'reactstrap'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        if (
            moment(this.props.timerange[1]).diff(
                moment(this.props.timerange[0]),
                'hours'
            ) <= 12
        ) {
            Chart.plugins.register(ChartDataLabels)
        }

        this.subscriber = new Subscriber()

        this.state = {
            loaded: false,
            chartdata: {},
            options: {
                maintainAspectRatio: false,
                title: {
                    text:
                        'Avg. Utilization % (' +
                        moment(this.props.timerange[0]).format('ddd h:mma') +
                        ' to now)',
                    display: true,
                    fontColor: '#fff',
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                            //type: 'time',
                            //time: {
                            //  unit: 'hour'
                            //},
                            ticks: {
                                autoSkip: true,
                                fontColor: '#fff',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                autoSkip: true,
                                fontColor: '#fff',
                                autoSkipPadding: 25,
                                min: 0,
                                max: 100,
                            },
                        },
                    ],
                },
            },
        }
    }

    async makeChart() {
        const data = await API.post('historical', {
            timeStart: this.props.timerange[0],
            timeEnd: this.props.timerange[1],
            deviceId: this.props.deviceId,
            unit: 'hours',
            state: 'In-Cycle',
        })
        if (!data) return

        this.props.updateUtilizationCurrentHourValue(
            Math.floor(
                data.durationPercentage[data.durationPercentage.length - 1] *
                    100
            )
        )

        this.setState({
            loaded: true,
            chartdata: {
                labels: data.dates.map((d) => moment(d).format('ha')),
                datasets: [
                    {
                        label: 'Target Utilization %',
                        type: 'line',
                        data: new Array(data.dates.length).fill(
                            this.state.target
                        ),
                        backgroundColor: 'transparent',
                        borderColor: 'darkgrey',
                        datalabels: {display: false},
                    },
                    {
                        label: 'Hourly Utilization %',
                        data: data.durationPercentage.map((a) =>
                            Math.floor(a * 100)
                        ),
                        backgroundColor: data.durationPercentage.map((a, i) => {
                            a = Math.floor(a * 100)
                            if (!this.state.target) {
                                return this.props.color
                            } else if (
                                data.durationPercentage.length - 1 ===
                                i
                            ) {
                                // current hour
                                return a >= this.state.target
                                    ? 'rgb(46, 204, 113)'
                                    : '#f0ad4e'
                            } else {
                                return a >= this.state.target
                                    ? 'rgb(46, 204, 113)'
                                    : 'rgb(231, 76, 60)'
                            }
                        }),
                        datalabels: {
                            color: '#fff',
                            // align: 'top',
                            display: function (context) {
                                return (
                                    context.dataset.data[context.dataIndex] > 0
                                )
                            },
                        },
                        //borderColor: 'black',
                        //borderWidth: 0.03
                    },
                ],
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.target !== this.state.target) {
            this.setState({target: nextProps.target})
            this.makeChart()
        } else if (nextProps.color !== this.props.color) {
            this.makeChart()
        }
    }

    componentDidMount() {
        this.subscriber.add(this.makeChart, 1000 * 60, 'makeChart()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
        Chart.plugins.unregister(ChartDataLabels)
    }
    render() {
        const isLoading = !this.state.loaded
        return isLoading ? null : (
            <Row>
                <Col>
                    <Bar
                        data={this.state.chartdata}
                        options={this.state.options}
                    />
                </Col>
            </Row>
        )
    }
}
