import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, CardTitle, Button} from 'reactstrap'
import autobind from 'auto-bind'

// Accumine SDK imports
import * as API from 'SDK/api'

import ModuleTile from './module-tile'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            apps: [],
            ready: false,
        }
    }

    async componentDidMount() {
        this.setState({
            apps: await API.get('environments', 2),
            ready: true,
        })
    }

    render() {
        const {editingEnvironment, environment} = this.props
        const showViewApp =
                !editingEnvironment && !environment && this.state.apps.length,
            noAppsToView =
                !editingEnvironment && !environment && !this.state.apps.length,
            showEditApp =
                editingEnvironment && !environment && this.state.apps.length,
            showStartFirstApp =
                editingEnvironment && !environment && !this.state.apps.length

        return (
            <Fragment>
                {this.state.ready ? (
                    <Row>
                        <Col xs="12">
                            <h2 style={{textAlign: 'center'}}>
                                {showViewApp && (
                                    <div>Select an app from above...</div>
                                )}
                                {noAppsToView && (
                                    <div>
                                        No apps available... please speak to
                                        your administrator
                                    </div>
                                )}
                                {showEditApp && (
                                    <div>
                                        Click the dropdown menu above to edit an
                                        existing app or to start a new app...
                                    </div>
                                )}
                                {showStartFirstApp && (
                                    <div>
                                        Click the dropdown menu above to start
                                        your first app...
                                    </div>
                                )}
                            </h2>
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
