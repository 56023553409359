import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class GenericWidgetName extends Component {
    id = 'GenericWidgetName'
    field = 'name'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue = this.props ? this.props.element.id : ''

        this.state = {
            widgets: [],
            value: initialValue,
            color: 'rgb(215,215,215)',
        }

        if (this.props && this.props.cb) {
            this.props.cb('name', initialValue)
        }
    }

    handleValueChange(e) {
        if (this.state.widgets.find((c) => c.name === this.state.value)) {
            this.setState({
                valid: false,
                color: '#dc3545',
                message: 'A visualization with the same name already exists!',
            })
            return
        } else {
            this.setState({
                valid: true,
                color: 'rgb(215,215,215)',
                message: '',
            })
        }

        this.setState({
            value: e.target.value,
        })

        if (this.props.cb) {
            this.props.cb('name', e.target.value)
        }
    }

    async getWidgets() {
        const widgets = await API.get('library', 2)
        if (!widgets) return alert('Could not fetch library.')

        this.setState({widgets})
    }

    async componentWillMount() {
        await this.getWidgets()
    }

    render() {
        return (
            <Fragment>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <label>Name:</label>
                <Input
                    value={this.state.value}
                    style={{borderColor: this.state.color}}
                    onChange={this.handleValueChange}
                    disabled
                />
            </Fragment>
        )
    }
}
