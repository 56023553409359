const BASE_URL = process.env.REACT_APP_PP_WEBHOOK_URL

export const OUTPUTS = [
    /*{
  name: 'Save Raw',
  id: 'accumineDb',
  description: 'Store the raw output in our database.',
  route: BASE_URL + 'savedata'
}, {
  name: 'Real Time Socket Service',
  id: 'accumineSocket',
  description: 'Trigger a socket server push to all listening clients. (Does not store data in database)',
  route: BASE_URL + 'broadcast'
}, */ {
        name: 'Accumine Database',
        id: 'legacyState',
        description:
            'Starts a timer if receive true, ends a timer if receive false. Any other values store a pending event until the next value is received.',
        route: BASE_URL + 'legacy',
    },
    {
        name: 'Send Alert',
        id: 'alert',
        description: 'Trigger an email alert to set of users',
        route: BASE_URL + 'alert',
    } /*, {
  name: 'MTConnect Agent',
  id: 'mtcagent',
  description: 'Sends an output to an MTConnect Agent',
  route: BASE_URL + 'mtc-agent'
}, {
  name: 'Multiple Work Order Scan',
  id: 'multi-work-order-scan',
  description: 'Multi WOrk Order Scan',
  route: BASE_URL + 'multi-scan-work-order'
}, {
    name: 'Downtime Barcode Scan',
    id: 'downtime-scan',
    description: 'Downtime Barcode Scan',
    route: BASE_URL + 'downtime-scan'
}, {
  name: 'Accumine Database With Time Threshold',
  id: 'legacyStateThreshold',
  description: '{State Name},{Alternative State Name},{Duration Seconds}',
  route: BASE_URL + 'legacy-threshold'
}*/,
]
