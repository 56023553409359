import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class AssetPicker extends Component {
    id = 'AssetPicker'
    field = 'deviceId'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : null

        this.state = {
            assets: [],
            selectedAsset: initialValue,
        }

        if (this.props && this.props.cb) {
            this.props.cb('deviceId', initialValue)
        }
    }

    getSelection() {
        return this.state.selectedAsset.value
    }
    handleAssetChange(e) {
        this.setState({
            selectedAsset: e,
        })

        if (this.props.cb) {
            this.props.cb('deviceId', e.value)
        }
    }
    async getAssets() {
        const assets = await API.get('devices')
        if (!assets) return alert('Could not fetch factory assets.')

        let selectedAsset = null

        if (this.state.selectedAsset) {
            selectedAsset = {
                label: assets.find(
                    (a) => a.deviceId === this.state.selectedAsset
                ).name,
                value: this.state.selectedAsset,
            }
        }

        this.setState({assets, selectedAsset})
    }

    async componentWillMount() {
        await this.getAssets()
    }

    render() {
        return (
            <Fragment>
                <label>Asset:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="asset"
                    value={this.state.selectedAsset}
                    onChange={this.handleAssetChange}
                    options={this.state.assets.map((a, i) => {
                        return {label: a.name, value: a.deviceId, key: i}
                    })}
                />
            </Fragment>
        )
    }
}
