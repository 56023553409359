import React, {Component} from 'react'
import {Bar} from 'react-chartjs-2'

let chartConfig = {
    responsive: true,
    maintainAspectRatio: false,
    padding: {
        top: 0,
    },
    title: {
        text: '',
        display: true,
    },
    legend: {
        display: true,
    },
    scales: {
        yAxes: [
            {
                stacked: true,
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: 'rgba(0,0,0,0.1)',
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                    padding: 20,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const data = {
            labels: this.props.hourly.dates,
            datasets: [
                {
                    label: '%',
                    data: this.props.hourly.partCount.map(
                        (a, i) =>
                            parseInt((a / this.props.hourly.target[i]) * 100) //// TARGET
                    ),
                    backgroundColor: 'rgb(46, 204, 113)',
                },
            ],
        }
        chartConfig.title.text = 'Parts: Actual / Target - Last 24 Hours'
        return <Bar data={data} options={chartConfig} />
    }
}
