import autobind from 'auto-bind'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import Asset from './Asset'
import Device from './Device'
import Model from './Model'
import Shift from './Shift'
import RouteDataset from './RouteDataset'

export default class Factory {
    constructor(
        assetSyncRateMs = 1000 * 30,
        deviceSyncRateMs = 1000 * 5,
        modelSyncRateMs = 1000 * 30,
        shiftSyncRateMs = 1000 * 60,
        routeDatasetSyncRateMs = 1000 * 120
    ) {
        autobind(this)

        this._assets = []
        this._devices = []
        this._models = []
        this._shifts = []
        this._routeDatasets = []

        this._assetSyncRateMs = assetSyncRateMs
        this._deviceSyncRateMs = deviceSyncRateMs
        this._modelSyncRateMs = modelSyncRateMs
        this._shiftSyncRateMs = shiftSyncRateMs
        this._routeDatasetSyncRateMs = routeDatasetSyncRateMs

        this._subscriber = new Subscriber()
    }

    async syncAssets() {
        const assets = await API.get('devices')
        if (assets) {
            for (let i = 0; i < assets.length; i++) {
                let asset = assets[i],
                    position = this._assets.findIndex(
                        (a) => a.deviceId === asset.deviceId
                    )
                if (position < 0) {
                    this._assets.push(new Asset(asset))
                } else {
                    this._assets[position].sync(asset)
                }
            }
        } else {
            console.log('could not fetch assets')
        }
    }

    async syncDevices() {
        const devices = await API.get('ips/cloud/devices', 2)
        if (devices) {
            for (let i = 0; i < devices.length; i++) {
                let device = devices[i],
                    position = this._devices.findIndex(
                        (a) => a.serialNumber === device.serialNumber
                    )
                if (position < 0) {
                    this._devices.push(new Device(device))
                } else {
                    this._devices[position].sync(device)
                }
            }
        } else {
            console.log('could not fetch devices')
        }
    }

    async syncModels() {
        const models = await API.get('ips/cloud/models', 2)
        if (models) {
            for (let i = 0; i < models.length; i++) {
                let model = models[i],
                    position = this._models.findIndex(
                        (a) => a.version === model.version
                    )
                if (position < 0) {
                    this._models.push(new Model(model))
                } else {
                    this._models[position].sync(model)
                }
            }
        } else {
            console.log('could not fetch models')
        }
    }

    async syncShifts() {
        const shifts = await API.get('shifts', 2)
        if (shifts) {
            for (let i = 0; i < shifts.length; i++) {
                let shift = shifts[i],
                    position = this._shifts.findIndex(
                        (a) => a._id === shift._id
                    )
                if (position < 0) {
                    this._shifts.push(new Shift(shift))
                } else {
                    this._shifts[position].sync(shift)
                }
            }
        } else {
            console.log('could not fetch shifts')
        }
    }

    async syncRouteDatasets() {
        const datasets = await API.get('ips/cloud/route-datasets', 2)
        if (datasets) {
            for (let i = 0; i < datasets.length; i++) {
                let dataset = datasets[i],
                    position = this._routeDatasets.findIndex(
                        (a) => a.datasetId === dataset.datasetId
                    )
                if (position < 0) {
                    this._routeDatasets.push(new RouteDataset(dataset))
                } else {
                    this._routeDatasets[position].sync(dataset)
                }
            }
        } else {
            console.log('could not fetch route datasets')
        }
    }

    async init() {
        await this.syncAssets()
        await this.syncDevices()
        await this.syncModels()
        await this.addAssetAttributesToDevice()
        await this.syncShifts()
        await this.syncRouteDatasets()

        this._subscriber.add(
            this.syncAssets,
            this._assetSyncRateMs,
            'syncAssets'
        )
        this._subscriber.add(
            this.syncDevices,
            this._deviceSyncRateMs,
            'syncDevices'
        )
        this._subscriber.add(
            this.syncModels,
            this._modelSyncRateMs,
            'syncModels'
        )
        this._subscriber.add(
            this.addAssetAttributesToDevice,
            this._assetSyncRateMs,
            'addAssetAttributesToDevice'
        )
        this._subscriber.add(
            this.syncShifts,
            this._shiftSyncRateMs,
            'syncShifts'
        )
        //this._subscriber.add(this.syncRouteDatasets, this._routeDatasetSyncRateMs, 'syncRouteDatasets');
    }

    kill() {
        this._subscriber.removeAll()
    }

    fetchActiveModel() {
        let result = {
            error: null,
            model: null,
        }

        if (!this._models) {
            result.error = 'There was an error loading data. Please try again.'
        } else if (
            this._models.length === 0 ||
            !this._models.find((m) => m.active === true)
        ) {
            result.error = 'No positioning models found'
        } else {
            result.model = this._models.find((m) => m.active === true)
        }
        return result
    }

    addAssetAttributesToDevice() {
        this._devices.forEach((device) => {
            const asset = this._assets.find(
                (a) => a.deviceId === device.deviceId
            )
            device.alias = asset ? asset.name : null
            device.assetImage = asset ? asset.assetImage : ''
            device.group =
                asset && asset.groups[0] ? asset.groups[0] : 'Unallocated'
        })
    }

    fetchModelDevices(version) {
        let result = {
            error: null,
            devices: [],
        }
        result.devices = this._devices.filter(
            (d) => d.model == version && d.alias
        )

        return result
    }

    fetchAssetGroups() {
        let result = {
            error: null,
            groups: [],
        }

        result.groups = [...new Set(this._assets.map((a) => a.group()))]

        return result
    }

    fetchModelRouteDatasets(version) {
        let result = {
            error: null,
            datasets: [],
        }

        result.datasets = this._routeDatasets.filter((r) => r.model === version)

        return result
    }
}
