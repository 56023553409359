import React, {useState} from 'react'
import CsvDownloader from 'react-csv-downloader'
import {Button, Text, Space} from '@mantine/core'
import MaterialAPI from './calls'
import columns from './columns'
import moment from 'moment'

export const Excel = ({range, deviceId, deviceName, ...props}) => {
    const [datas, setDatas] = useState([])

    const prepareExcelFile = async () => {
        const models = await MaterialAPI.getModels()

        if (models.partModel && models.downtimeModel) {
            let hmiEntryRecords = await MaterialAPI.getHmiEntryRecords(
                models.hmiModel._id,
                deviceId,
                range
            )
            let downtimeRecords = await MaterialAPI.getDowntimeEntryRecords(
                models.downtimeModel._id,
                deviceId,
                range
            )
            return combineExcelData(hmiEntryRecords, downtimeRecords)
        } else {
            console.log('Parts and Downtime Reason schema are required')
            return false
        }
    }

    const getMostRecentMaterialEntry = (downtimeRecord, hmiEntryRecords) => {
        let fastestRow = null
        let fastestTime = Infinity
        hmiEntryRecords.map((hmirec) => {
            let timeDiff = moment(downtimeRecord.timeStart).diff(
                moment(hmirec.Start_Time)
            )
            if (timeDiff < fastestTime && timeDiff >= 0) {
                fastestRow = hmirec
                fastestTime = moment(downtimeRecord.timeStart).diff(
                    moment(hmirec.Start_Time)
                )
            }
        })

        if (!fastestRow) {
            return {}
        } else {
            return {
                assetName: fastestRow.asset.name,
                material: fastestRow.material.partNumber,
                hourlyTarget: fastestRow.material.hourlyTarget,
            }
        }
    }

    const combineExcelData = async (
        hmiEntryRecords = [],
        downtimeRecords = []
    ) => {
        if (hmiEntryRecords.length && downtimeRecords.length) {
            let excelData = downtimeRecords.map((downtimerec) => {
                return {
                    ...getMostRecentMaterialEntry(downtimerec, hmiEntryRecords),
                    downtimeReason: downtimerec.value,
                    duration: moment(downtimerec.timeEnd).diff(
                        downtimerec.timeStart,
                        'seconds'
                    ),
                    assetName: deviceName,
                    ...downtimerec,
                }
            })
            setDatas(excelData)
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <Button
                disabled={datas.length > 0}
                onClick={() => {
                    prepareExcelFile()
                }}>
                Load Data
            </Button>
            {'  '}
            <Button
                disabled={datas.length == 0}
                color="red"
                onClick={() => {
                    setDatas([])
                }}>
                Clear Data
            </Button>
            <Text size="xs" color="dimmed">
                {datas.length} rows loaded, ready to download
            </Text>
            <Space h="xl" />
            <CsvDownloader
                filename="myfile"
                extension=".csv"
                separator=","
                wrapColumnChar=""
                columns={columns}
                datas={datas}
                text="Download Excel">
                <Button disabled={datas.length == 0}>
                    Download Excel File
                </Button>
            </CsvDownloader>
        </>
    )
}
