import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import {NotificationContainer} from 'components/ReactNotifications'
import {defaultStartPath} from 'constants/defaultValues'

import AppLocale from '../lang'
import MainRoute from 'routes'
import login from 'routes/login'
import error from 'routes/error'

import 'assets/css/vendor/bootstrap.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'assets/css/sass/themes/gogo.light.green.scss'

const InitialPath = ({component: Component, authUser, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.location},
                    }}
                />
            )
        }
    />
)

class App extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const {location, match, user, locale} = this.props
        const currentAppLocale = AppLocale[locale]
        if (
            location.pathname === '/' ||
            location.pathname === '/app' ||
            location.pathname === '/app/'
        ) {
            return <Redirect to={defaultStartPath} />
        }
        return (
            <Fragment>
                <NotificationContainer />
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <Fragment>
                        <Switch>
                            <InitialPath
                                path={`${match.url}app`}
                                authUser={user}
                                component={MainRoute}
                            />
                            <Route path={`/login`} component={login} />
                            <Route path={`/error`} component={error} />
                            <Redirect to="/error" />
                        </Switch>
                    </Fragment>
                </IntlProvider>
            </Fragment>
        )
    }
}

const mapStateToProps = ({authUser, settings}) => {
    const {user} = authUser
    const {locale} = settings
    return {user, locale}
}

export default connect(mapStateToProps, {})(App)
