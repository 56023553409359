import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import {stringToDateRange} from 'SDK/helpers'
import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeController,
} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'

import Historian from 'SDK/ui/widgets/InsightEngine/historian'
import ChartMaker from 'SDK/ui/widgets/InsightEngine/chartMaker'
import Table from 'SDK/ui/widgets/InsightEngine/table'
import * as API from 'SDK/api'
import {from} from 'rxjs'

export default class CustomTile extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.component = this.props.config

        this.defaultTimerange =
            this.component.timerange.live !== 'custom'
                ? stringToDateRange(this.component.timerange.live)
                : [
                      moment(this.component.timerange.timeStart),
                      moment(this.component.timerange.timeEnd),
                  ]

        this.state = {
            loading: true,
            output: null,
            timeStart: this.defaultTimerange[0],
            timeEnd: this.defaultTimerange[1],
            disableReset: true,
        }
    }

    handleTimerangeChange({startDate, endDate}) {
        if (startDate && !endDate) {
            this.setState({timeStart: startDate})
        } else if (!startDate && endDate) {
            this.setState({timeEnd: endDate})
        } else if (startDate && endDate) {
            this.setState({timeStart: startDate, timeEnd: endDate})
        }
    }

    applyChangeToAll(override) {
        this.props.applyChangeToAll({
            timeStart: override
                ? moment(this.state.timeStart).startOf('day').toISOString()
                : this.defaultTimerange[0].toISOString(),
            timeEnd: override
                ? moment(this.state.timeEnd).endOf('day').toISOString()
                : this.defaultTimerange[1].endOf('day').toISOString(),
        })
    }

    async fetchData(override, fromProps) {
        this.setState({
            loading: true,
            output: null,
        })

        if (!override) {
            this.setState({
                timeStart: this.defaultTimerange[0],
                timeEnd: this.defaultTimerange[1],
                disableReset: true,
            })
        } else {
            this.setState({
                disableReset: false,
            })
        }

        let timeStart = null,
            timeEnd = null

        if (override && fromProps) {
            timeStart = moment(this.props.globalTimerange[0]).toISOString()
            timeEnd = moment(this.props.globalTimerange[1]).toISOString()
        } else if (override && !fromProps) {
            timeStart = moment(this.state.timeStart)
                .startOf('day')
                .toISOString()
            timeEnd = moment(this.state.timeEnd).endOf('day').toISOString()
        } else {
            timeStart = this.defaultTimerange[0].toISOString()
            timeEnd = this.defaultTimerange[1].endOf('day').toISOString()
        }

        let data = [],
            columns = []
        if (this.component.output === 'Cycle Time (Minutes)') {
            const totalCycleTimeHistorian = new Historian({
                daterange: 'custom',
                devices: this.state.devices,
                timeStart: timeStart,
                timeEnd: timeEnd,
                name: 'In-Cycle',
                groupBy: this.component.groupBy,
                showBy: this.component.showBy,
                timeUnit: this.component.timeUnit,
                metric: 'totalDuration',
                filter: this.component.filter || {},
            })
            let totalCycleTimeResult = await totalCycleTimeHistorian.run()

            const partCountHistorian = new Historian({
                daterange: 'custom',
                timeStart: timeStart,
                timeEnd: timeEnd,
                name: 'Part Count',
                groupBy: this.component.groupBy,
                showBy: this.component.showBy,
                timeUnit: this.component.timeUnit,
                metric: 'count',
                filter: this.component.filter || {},
            })
            let partCountResult = await partCountHistorian.run()
            for (let row of totalCycleTimeResult.data) {
                const id = row['key'] === undefined ? 'date' : 'key'
                for (let key in row) {
                    if (key !== 'key' && key !== 'date') {
                        const parts = partCountResult.data.find(
                            (a) => a[id] === row[id]
                        )
                        row[key] =
                            Math.round((row[key] / parts[key]) * 100) / 100
                    }
                }
            }
            data = totalCycleTimeResult.data
            columns = totalCycleTimeResult.columns
        } else {
            let historian = new Historian({
                daterange: 'custom', //override ? 'custom' : this.component.timerange.live,
                timeStart: timeStart,
                timeEnd: timeEnd,
                name: this.component.output,
                groupBy: this.component.groupBy,
                showBy: this.component.showBy,
                timeUnit: this.component.timeUnit,
                metric: this.component.metric,
                filter: this.component.filter || {},
            })

            let result = await historian.run()
            data = result.data
            columns = result.columns
        }

        let output = null
        if (this.component.visualization === 'table') {
            output = <Table data={data} columns={columns} />
        } else {
            let percentage = this.component.metric === 'totalDurationPercent'
            output = (
                <ChartMaker
                    lowerLimit={this.component.lowerLimit}
                    upperLimit={this.component.upperLimit}
                    percentage={percentage}
                    data={data}
                    type={this.component.visualization}
                    timeUnit={
                        this.component.showBy === 'Time'
                            ? this.component.timeUnit
                            : false
                    }
                />
            )
        }

        this.setState({
            loading: false,
            output: output,
        })
    }

    componentWillMount() {
        this.fetchData()
    }

    componentWillReceiveProps(prevProps, prevState) {
        if (
            prevProps.globalTimerange[0] !== this.props.globalTimerange[0] ||
            prevProps.globalTimerange[1] !== this.props.globalTimerange[1]
        ) {
            if (
                this.props.globalTimerange[0] === null &&
                this.props.globalTimerange[1] === null
            ) {
                // reset
                this.fetchData(false)
            } else {
                this.fetchData(true, true)
            }
        }
    }

    render() {
        const editMode = this.props.mode === 'edit'

        return (
            <>
                <Card key={this.props.element.i} style={{height: '100%'}}>
                    <CardHeader>
                        <h5>{this.component.name}</h5>
                        {editMode ? (
                            <span
                                className="remove"
                                style={{
                                    position: 'absolute',
                                    right: '2px',
                                    top: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.onRemoveItem(
                                        this.props.element.i
                                    )
                                }}>
                                x
                            </span>
                        ) : null}
                    </CardHeader>
                    <CardBody style={{padding: 0}}>
                        <Row style={{height: '100%'}}>
                            <Col>{this.state.output}</Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-center">
                                {!editMode ? (
                                    <>
                                        <DateRangePicker
                                            small
                                            startDate={this.state.timeStart}
                                            startDateId="start"
                                            endDate={this.state.timeEnd}
                                            endDateId="end"
                                            onDatesChange={
                                                this.handleTimerangeChange
                                            }
                                            focusedInput={
                                                this.state.focusedInput
                                            }
                                            onFocusChange={(focusedInput) =>
                                                this.setState({focusedInput})
                                            }
                                            hideKeyboardShortcutsPanel
                                            isOutsideRange={(date) => {
                                                return date.isAfter(moment())
                                            }}
                                        />
                                        <Button
                                            style={{marginLeft: '10px'}}
                                            size="xs"
                                            color="primary"
                                            onClick={() => {
                                                this.fetchData(true, false)
                                            }}>
                                            Apply
                                        </Button>
                                        <Button
                                            style={{marginLeft: '10px'}}
                                            size="xs"
                                            color="default"
                                            disabled={this.state.disableReset}
                                            onClick={() => {
                                                this.fetchData(false, false)
                                            }}>
                                            Reset
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            style={{marginLeft: '10px'}}
                                            size="xs"
                                            color="primary"
                                            onClick={() => {
                                                this.props.editCustomItem(
                                                    this.props.element.i
                                                )
                                            }}>
                                            Edit
                                        </Button>
                                        <Button
                                            style={{marginLeft: '10px'}}
                                            size="xs"
                                            color="primary"
                                            onClick={() => {
                                                this.props.cloneCustomItem(
                                                    this.props.element.i
                                                )
                                            }}>
                                            Clone
                                        </Button>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                {this.state.loading ? <div className="loading" /> : null}
            </>
        )
    }
}
