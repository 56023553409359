import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            name: '',
            borderColor: 'rgb(215,215,215)',
            valid: false,
            message: true,
        }
    }

    async submit() {
        this.props.toggleModal()
        this.props.handleDeleteEnvironmentSubmit()
    }

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Are you sure?
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <p>
                                    Deleting an application is final. All custom
                                    visualizations, settings and preferences
                                    will be removed as well.
                                </p>
                                <p>
                                    Note that any collected data will not be
                                    affected by this action.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs="12">
                                <Label>
                                    Please type in the name of the application
                                    to continue:
                                </Label>
                                <Input
                                    style={{
                                        borderColor: this.state.borderColor,
                                    }}
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            v !== this.props.environment.name
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            borderColor: color,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleModal}>
                            Cancel
                        </Button>
                        <Button
                            color="danger"
                            disabled={!this.state.valid}
                            onClick={this.submit}>
                            Delete
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
