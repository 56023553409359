import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    ButtonGroup,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    ButtonDropdown,
} from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'

const makeRange = (referenceDate, unit, offset) => {
    if (!referenceDate) referenceDate = moment().subtract(1, 'days')
    if (!unit) throw Error('Unit is a required field')
    else unit = unit.toLowerCase()
    if (offset === undefined) offset = 0

    let timeStart = moment(referenceDate)
            .add(offset, unit + 's')
            .startOf(unit),
        timeEnd = moment(timeStart).endOf(unit)

    let obj = {timeStart, timeEnd}

    if (obj.timeEnd.diff(moment()) > 0) {
        obj.timeEnd = moment().startOf('hour')
    }
    return obj
}

export default class extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        onChangeCallback: PropTypes.func,
    }
    static defaultProps = {
        disabled: false,
        onChangeCallback: () => {},
    }
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.handleUnitChange = this.handleUnitChange.bind(this)
        this.handleOffset = this.handleOffset.bind(this)

        this.state = {
            isOpen: false,
            unit: 'Day',
            referenceDate: null,
            timerange: makeRange(null, 'Day', 0),
            blockRightOffset: true,
            blockLeftOffset: false,
            blockDropdown: false,
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    formatRange() {
        let str = ''
        switch (this.state.unit) {
            case 'Day': {
                str = moment(this.state.timerange.timeStart).format(
                    'MMMM DD, YYYY'
                )
                break
            }
            case 'Week': {
                str =
                    moment(this.state.timerange.timeStart).format('MMM DD') +
                    ' - ' +
                    moment(this.state.timerange.timeEnd).format('MMM DD, YYYY')
                break
            }
            case 'Month': {
                str = moment(this.state.timerange.timeStart).format('MMM, YYYY')
                break
            }
            case 'Quarter': {
                str =
                    moment(this.state.timerange.timeStart).format('YYYY') +
                    ' Q' +
                    moment(this.state.timerange.timeStart).format('Q')
                break
            }
            case 'Year': {
                str = moment(this.state.timerange.timeStart).format('YYYY')
                break
            }
            default: {
                str =
                    moment(this.state.timerange.timeStart).format(
                        'MMM DD, YYYY'
                    ) +
                    ' - ' +
                    moment(this.state.timerange.timeEnd).format('MMM DD, YYYY')
            }
        }
        return str
    }

    handleUnitChange(e) {
        this.setState({
            unit: e.target.value,
            timerange: makeRange(
                moment(this.state.timerange.timeStart),
                e.target.value,
                0
            ),
            blockRightOffset: false,
            blockLeftOffset: false,
        })
        //this.handleOffset(0)
    }

    handleOffset(offset) {
        const timerange = makeRange(
            moment(this.state.timerange.timeStart),
            this.state.unit,
            offset
        )
        console.log(moment(timerange.timeStart).format())
        if (moment(timerange.timeStart).diff(moment()) > 0) {
            this.setState({
                blockRightOffset: true,
            })
        } else {
            const blockRightOffset = false
            this.setState({timerange, blockRightOffset})
        }
    }

    makeDropdownOptions() {
        const units = ['Day', 'Week', 'Month', 'Quarter', 'Year']

        let options = []

        units.forEach((unit, i) => {
            const isActive = unit === this.state.unit
            options.push(
                <DropdownItem
                    key={i}
                    value={unit}
                    style={{cursor: 'pointer'}}
                    onClick={this.handleUnitChange}
                    active={isActive}>
                    {unit}
                </DropdownItem>
            )
        })

        return options
    }

    componentDidUpdate(prevProps, prevState) {
        const oldTimestart = moment(prevState.timerange.timeStart).unix(),
            newTimestart = moment(this.state.timerange.timeStart).unix(),
            oldTimeend = moment(prevState.timerange.timeEnd).unix(),
            newTimeend = moment(this.state.timerange.timeEnd).unix()

        if (oldTimestart !== newTimestart || oldTimeend !== newTimeend) {
            this.props.onChangeCallback(this.state.timerange)
        }
    }

    render() {
        let {
                isOpen,
                timerange,
                blockRightOffset,
                blockLeftOffset,
                blockDropdown,
                unit,
            } = this.state,
            formattedRange = this.formatRange(),
            dropdownOptions = this.makeDropdownOptions()

        if (this.props.disabled) {
            blockRightOffset = true
            blockLeftOffset = true
            blockDropdown = true
        }

        return (
            <Fragment>
                <Row>
                    <Col xs="12" style={{textAlign: 'center'}}>
                        {formattedRange}
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <ButtonGroup>
                            <Button
                                disabled={blockLeftOffset}
                                onClick={() => {
                                    this.handleOffset(-1)
                                }}>
                                <i className="iconsmind-Arrow-Left" />
                            </Button>
                            <ButtonDropdown
                                isOpen={this.state.isOpen}
                                toggle={this.toggle}>
                                <DropdownToggle
                                    disabled={blockDropdown}
                                    style={{width: '100px'}}
                                    caret>
                                    {this.state.unit}
                                </DropdownToggle>
                                <DropdownMenu>{dropdownOptions}</DropdownMenu>
                            </ButtonDropdown>
                            <Button
                                disabled={blockRightOffset}
                                onClick={() => {
                                    this.handleOffset(1)
                                }}>
                                <i className="iconsmind-Arrow-Right" />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
