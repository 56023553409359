import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup, Form, Input, Label} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import {cloneDeep} from 'lodash'
import * as API from 'SDK/api'
import SchemaForm from '../../Schemas/common/Form'
import Navigation from '../Navigation'
import {deincrementRawMaterial} from '../Scripts/DeincrementRawMaterial'
import {paginate} from '../Paginate'
import moment from 'moment'

const Templates = {
    'Additive Batches': AdditiveTemplate,
    'Calcined Additive Batches': CalAdditiveTemplate,
    'PVA Batches': PVATemplate,
    'Dispersion Batches': DispersionTemplate,
    'Powder Batches': PowderTemplate,
    'Collar Batches': CollarTemplate,
}

function AdditiveTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col xs="3" className="wadsworth" key="Batch Number">
                        {
                            properties.find((a) => a.name === 'Batch Number')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Type">
                        {properties.find((a) => a.name === 'Type').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Operator">
                        {properties.find((a) => a.name === 'Operator').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Archive">
                        {properties.find((a) => a.name === 'Archive').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Date">
                        {properties.find((a) => a.name === 'Date').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Campaign Start">
                        {
                            properties.find((a) => a.name === 'Campaign Start')
                                .content
                        }
                    </Col>
                    <Col
                        xs="3"
                        className="wadsworth"
                        key="Number_batch_in_additive_tank">
                        {
                            properties.find(
                                (a) =>
                                    a.name === 'Number_batch_in_additive_tank'
                            ).content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Visc_10">
                        {properties.find((a) => a.name === 'Visc_10').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="VIsc_20">
                        {properties.find((a) => a.name === 'VIsc_20').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SG">
                        {properties.find((a) => a.name === 'SG').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="PSD_D10">
                        {properties.find((a) => a.name === 'PSD_D10').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="PSD_D50">
                        {properties.find((a) => a.name === 'PSD_D50').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="PSD_D90">
                        {properties.find((a) => a.name === 'PSD_D90').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className="wadsworth" key="Boron_Lot">
                        {properties.find((a) => a.name === 'Boron_Lot').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Silver_Lot">
                        {
                            properties.find((a) => a.name === 'Silver_Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Tin_Lot">
                        {properties.find((a) => a.name === 'Tin_Lot').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Manganese_Lot">
                        {
                            properties.find((a) => a.name === 'Manganese_Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Cobalt_Lot">
                        {
                            properties.find((a) => a.name === 'Cobalt_Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Nickel_Lot">
                        {
                            properties.find((a) => a.name === 'Nickel_Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Antimony_Lot">
                        {
                            properties.find((a) => a.name === 'Antimony_Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bismuth_Lot">
                        {
                            properties.find((a) => a.name === 'Bismuth_Lot')
                                .content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="4" className="wadsworth" key="Comments">
                        {properties.find((a) => a.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function CalAdditiveTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col xs="3" className="wadsworth" key="Batch Number">
                        {
                            properties.find((a) => a.name === 'Batch Number')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Operator">
                        {properties.find((a) => a.name === 'Operator').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Additive Batch">
                        {
                            properties.find((a) => a.name === 'Additive Batch')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Sweco Date">
                        {
                            properties.find((a) => a.name === 'Sweco Date')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bead Date">
                        {properties.find((a) => a.name === 'Bead Date').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Aluminum Lot">
                        {
                            properties.find((a) => a.name === 'Aluminum Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Dispersant Lot">
                        {
                            properties.find((a) => a.name === 'Dispersant Lot')
                                .content
                        }
                    </Col>
                    <Col xs="1" className="wadsworth" key="Archive">
                        {properties.find((a) => a.name === 'Archive').content}
                    </Col>
                    <Col xs="1" className="wadsworth" key="Used">
                        {properties.find((a) => a.name === 'Used').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className="wadsworth" key="Temp">
                        {properties.find((a) => a.name === 'Temp').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="PSD D10">
                        {properties.find((a) => a.name === 'PSD D10').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="PSD D50">
                        {properties.find((a) => a.name === 'PSD D50').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="PSD D90">
                        {properties.find((a) => a.name === 'PSD D90').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bead Time">
                        {properties.find((a) => a.name === 'Bead Time').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SG Mill">
                        {properties.find((a) => a.name === 'SG Mill').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Persolids">
                        {properties.find((a) => a.name === 'Persolids').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SG Post">
                        {properties.find((a) => a.name === 'SG Post').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="pH">
                        {properties.find((a) => a.name === 'pH').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bead Temp">
                        {properties.find((a) => a.name === 'Bead Temp').content}
                    </Col>

                    <Col xs="3" className="wadsworth" key="Bead PSD D10">
                        {
                            properties.find((a) => a.name === 'Bead PSD D10')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bead PSD D50">
                        {
                            properties.find((a) => a.name === 'Bead PSD D50')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bead PSD D90">
                        {
                            properties.find((a) => a.name === 'Bead PSD D90')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bead Energy">
                        {
                            properties.find((a) => a.name === 'Bead Energy')
                                .content
                        }
                    </Col>
                    <Col xs="4" className="wadsworth" key="Comments">
                        {properties.find((a) => a.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function PVATemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col xs="3" className="wadsworth" key="Batch Number">
                        {
                            properties.find((a) => a.name === 'Batch Number')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Operator">
                        {properties.find((a) => a.name === 'Operator').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Date">
                        {properties.find((a) => a.name === 'Date').content}
                    </Col>
                    <Col xs="1" className="wadsworth" key="Archive">
                        {properties.find((a) => a.name === 'Archive').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className="wadsworth" key="PVA Lot">
                        {properties.find((a) => a.name === 'PVA Lot').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Defoamer Lot">
                        {
                            properties.find((a) => a.name === 'Defoamer Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Peak Temp">
                        {properties.find((a) => a.name === 'Peak Temp').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Per solids">
                        {
                            properties.find((a) => a.name === 'Per solids')
                                .content
                        }
                    </Col>

                    <Col xs="4" className="wadsworth" key="Comments">
                        {properties.find((a) => a.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function DispersionTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col xs="3" className="wadsworth" key="Batch Number">
                        {
                            properties.find((a) => a.name === 'Batch Number')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Operator">
                        {properties.find((a) => a.name === 'Operator').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Date">
                        {properties.find((a) => a.name === 'Date').content}
                    </Col>
                    <Col xs="1" className="wadsworth" key="Used">
                        {properties.find((a) => a.name === 'Used').content}
                    </Col>
                    <Col xs="1" className="wadsworth" key="Archive">
                        {properties.find((a) => a.name === 'Archive').content}
                    </Col>

                    <Col xs="3" className="wadsworth" key="Zinc Bag 1">
                        {
                            properties.find((a) => a.name === 'Zinc Bag 1')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Zinc Bag 2">
                        {
                            properties.find((a) => a.name === 'Zinc Bag 2')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Zinc Bag 3">
                        {
                            properties.find((a) => a.name === 'Zinc Bag 3')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Zinc Bag 4">
                        {
                            properties.find((a) => a.name === 'Zinc Bag 4')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="SD">
                        {properties.find((a) => a.name === 'SD').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SD Tank">
                        {properties.find((a) => a.name === 'SD Tank').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Filter Size">
                        {
                            properties.find((a) => a.name === 'Filter Size')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Orifice">
                        {properties.find((a) => a.name === 'Orifice').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Orifice Size">
                        {
                            properties.find((a) => a.name === 'Orifice Size')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Temp 1">
                        {properties.find((a) => a.name === 'Temp 1').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Temp 2">
                        {properties.find((a) => a.name === 'Temp 2').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Temp 3">
                        {properties.find((a) => a.name === 'Temp 3').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="pH 1">
                        {properties.find((a) => a.name === 'pH 1').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="pH 2">
                        {properties.find((a) => a.name === 'pH 2').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="pH 3">
                        {properties.find((a) => a.name === 'pH 3').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SG 1">
                        {properties.find((a) => a.name === 'SG 1').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SG 2">
                        {properties.find((a) => a.name === 'SG 2').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="SG 3">
                        {properties.find((a) => a.name === 'SG 3').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Visc 1">
                        {properties.find((a) => a.name === 'Visc 1').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Visc 2">
                        {properties.find((a) => a.name === 'Visc 2').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Visc 3">
                        {properties.find((a) => a.name === 'Visc 3').content}
                    </Col>

                    <Col xs="3" className="wadsworth" key="Swirl">
                        {properties.find((a) => a.name === 'Swirl').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Dispersant Lot">
                        {
                            properties.find((a) => a.name === 'Dispersant Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Zinc Lot">
                        {properties.find((a) => a.name === 'Zinc Lot').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className="wadsworth" key="Defoamer Lot">
                        {
                            properties.find((a) => a.name === 'Defoamer Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Plasticizer Lot">
                        {
                            properties.find((a) => a.name === 'Plasticizer Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="PVA Batch">
                        {properties.find((a) => a.name === 'PVA Batch').content}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs="3"
                        className="wadsworth"
                        key="Cal Additive Batches">
                        {
                            properties.find(
                                (a) => a.name === 'Cal Additive Batches'
                            ).content
                        }
                    </Col>
                    <Col xs="4" className="wadsworth" key="Comments">
                        {properties.find((a) => a.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function PowderTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col xs="3" className="wadsworth" key="Batch Number">
                        {
                            properties.find((a) => a.name === 'Batch Number')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Operator">
                        {properties.find((a) => a.name === 'Operator').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Date">
                        {properties.find((a) => a.name === 'Date').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Archive">
                        {properties.find((a) => a.name === 'Archive').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className="wadsworth" key="Spray Time">
                        {
                            properties.find((a) => a.name === 'Spray Time')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Moisture">
                        {properties.find((a) => a.name === 'Moisture').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bulk 1">
                        {properties.find((a) => a.name === 'Bulk 1').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Bulk 2">
                        {properties.find((a) => a.name === 'Bulk 2').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Tap 1">
                        {properties.find((a) => a.name === 'Tap 1').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Tap 2">
                        {properties.find((a) => a.name === 'Tap 2').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Fluidity 1">
                        {
                            properties.find((a) => a.name === 'Fluidity 1')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Fluidity 2">
                        {
                            properties.find((a) => a.name === 'Fluidity 2')
                                .content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="6" className="wadsworth" key="Dispersion Batches">
                        {
                            properties.find(
                                (a) => a.name === 'Dispersion Batches'
                            ).content
                        }
                    </Col>
                    <Col xs="6" className="wadsworth" key="Comments">
                        {properties.find((a) => a.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function CollarTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col xs="3" className="wadsworth" key="Batch Number">
                        {
                            properties.find((a) => a.name === 'Batch Number')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Operator">
                        {properties.find((a) => a.name === 'Operator').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Date">
                        {properties.find((a) => a.name === 'Date').content}
                    </Col>
                    <Col xs="2" className="wadsworth" key="Size">
                        {properties.find((a) => a.name === 'Size').content}
                    </Col>
                    <Col xs="1" className="wadsworth" key="Archive">
                        {properties.find((a) => a.name === 'Archive').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className="wadsworth" key="Laponite Lot">
                        {
                            properties.find((a) => a.name === 'Laponite Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="CMC Lot">
                        {properties.find((a) => a.name === 'CMC Lot').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="TiO2 Lot">
                        {properties.find((a) => a.name === 'TiO2 Lot').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Mullite Lot">
                        {
                            properties.find((a) => a.name === 'Mullite Lot')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Glass Lot">
                        {properties.find((a) => a.name === 'Glass Lot').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Visc">
                        {properties.find((a) => a.name === 'Visc').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="Per Solids">
                        {
                            properties.find((a) => a.name === 'Per Solids')
                                .content
                        }
                    </Col>
                    <Col xs="3" className="wadsworth" key="Temp">
                        {properties.find((a) => a.name === 'Temp').content}
                    </Col>
                    <Col xs="3" className="wadsworth" key="pH">
                        {properties.find((a) => a.name === 'pH').content}
                    </Col>
                    <Col xs="4" className="wadsworth" key="Comments">
                        {properties.find((a) => a.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
        this.path = [...this.props.path]
        this.path.push({
            name: this.props.title,
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            loading: true,

            dataModels: [],
            validator: null,

            selectedSection: null,

            enteringNewBatch: false,
            newBatchNumberValue: '',

            existingBatches: [],
            editingRecord: null,
        }
    }

    onNewBatchNumberValueChange(e) {
        const v = e.target.value
        this.setState({newBatchNumberValue: v})
    }

    async submitNewBatchNumber() {
        let {validator, newBatchNumberValue, editingRecord} = this.state

        const {result} = await API.post(
            'data-models/' + this.props.dataModelId + '/paginate',
            {
                sort: {},
                limit: 1,
                next: null,
                previous: null,
                filter: [
                    {
                        type: 'Text',
                        logic: 'is',
                        value: [newBatchNumberValue],
                        path: this.props.batchNumberField,
                    },
                ],
                timezone: localStorage['timezone'],
            },
            2
        )

        if (result.results.length) {
            alert('There is already a batch with the provided batch number.')
            return
        }

        editingRecord = {
            [this.props.batchNumberField]: newBatchNumberValue,
        }

        //validator.properties[this.props.batchNumberField].default = newBatchNumberValue;
        this.setState({
            editingRecord,
            validator,
            selectedSection: 'Enter Data For Batch',
            enteringNewBatch: true,
        })
    }

    RENDER_INITIAL() {
        if (!this.state.loading && this.state.selectedSection === null) {
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <Navigation path={this.path} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h1>{this.props.title}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <ButtonGroup vertical>
                                <Button
                                    size="xl"
                                    onClick={() => {
                                        this.setState({
                                            selectedSection: 'Enter New Batch',
                                        })
                                    }}>
                                    ENTER NEW BATCH
                                </Button>
                                <Button
                                    size="xl"
                                    onClick={() => {
                                        this.setState({
                                            selectedSection:
                                                'Select Existing Batch',
                                        })
                                    }}>
                                    ENTER DATA FOR BATCH
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Fragment>
            )
        } else {
            return null
        }
    }

    RENDER_ENTER_NEW_BATCH_NUMBER() {
        if (
            !this.state.loading &&
            this.state.selectedSection === 'Enter New Batch'
        ) {
            let path = [...this.path]
            path.push({
                name: 'Enter New Batch',
                fn: () => {},
            })
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <Navigation path={path} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h1>Enter New Batch</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Form>
                                <Label className="form-group has-top-label">
                                    <Input
                                        type="text"
                                        name="lot-number"
                                        value={this.state.newBatchNumberValue}
                                        onChange={
                                            this.onNewBatchNumberValueChange
                                        }
                                    />
                                    <IntlMessages id="Batch Number" />
                                </Label>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Button block onClick={this.submitNewBatchNumber}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )
        } else {
            return null
        }
    }

    RENDER_SELECT_EXISTING_BATCH() {
        if (
            !this.state.loading &&
            this.state.selectedSection === 'Select Existing Batch'
        ) {
            let path = [...this.path]
            path.push({
                name: 'Select Existing Batch',
                fn: () => {},
            })
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <Navigation path={path} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Label className="form-group has-top-label">
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="existing-batch"
                                    value={null}
                                    onChange={({label, value}) => {
                                        let schema = this.state.validator

                                        for (let fieldId in schema.properties) {
                                            if (value[fieldId]) {
                                                schema.properties[
                                                    fieldId
                                                ].default = value[fieldId]
                                            }
                                        }
                                        this.setState({
                                            editingRecord: value,
                                            selectedSection:
                                                'Enter Data For Batch',
                                        })
                                    }}
                                    options={this.state.existingBatches
                                        .map((b) => {
                                            return {
                                                label: b[
                                                    this.props.batchNumberField
                                                ],
                                                value: b,
                                            }
                                        })
                                        .sort((a, b) =>
                                            a.label.localeCompare(b.label)
                                        )}
                                />
                                <IntlMessages id="Select existing batch" />
                            </Label>
                        </Col>
                    </Row>
                </Fragment>
            )
        }
    }

    RENDER_ENTER_DATA_FOR_BATCH() {
        if (
            !this.state.loading &&
            this.state.selectedSection === 'Enter Data For Batch'
        ) {
            let path = [...this.path]
            path.push({
                name: 'Select Existing Batch',
                fn: () => {
                    this.setState({selectedSection: 'Select Existing Batch'})
                },
            })
            path.push({
                name: 'Enter Data For Batch',
                fn: () => {},
            })

            let validator = cloneDeep(this.state.validator),
                editingRecord = this.state.editingRecord

            if (validator.title === 'Additive Batch') {
                validator.properties['Visc_10'].multipleOf = 5
                validator.properties['VIsc_20'].multipleOf = 5
                validator.properties['SG'].multipleOf = 0.001
                validator.properties['PSD_D10'].multipleOf = 0.001
                validator.properties['PSD_D50'].multipleOf = 0.001
                validator.properties['PSD_D90'].multipleOf = 0.001
            }
            if (validator.title === 'Calcined Additive Batch') {
                validator.properties['PSD D10'].multipleOf = 0.001
                validator.properties['PSD D50'].multipleOf = 0.001
                validator.properties['PSD D90'].multipleOf = 0.001

                validator.properties['Bead Time'].multipleOf = 5
                validator.properties['SG Mill'].multipleOf = 0.001
                validator.properties['Persolids'].multipleOf = 0.01
                validator.properties['SG Post'].multipleOf = 0.001
                validator.properties['pH'].multipleOf = 0.01

                validator.properties['Bead Temp'].multipleOf = 0.1
                validator.properties['Bead PSD D10'].multipleOf = 0.001
                validator.properties['Bead PSD D50'].multipleOf = 0.001
                validator.properties['Bead PSD D90'].multipleOf = 0.001
                validator.properties['Bead Energy'].multipleOf = 0.01
            }
            if (validator.title === 'Dispersion Batch') {
                validator.properties['SD'].multipleOf = 1
                validator.properties['SD'].minimum = 1
                validator.properties['SD'].maximum = 2

                validator.properties['SD Tank'].multipleOf = 1
                validator.properties['SD Tank'].minimum = 1
                validator.properties['SD Tank'].maximum = 2

                validator.properties['pH 1'].multipleOf = 0.01
                validator.properties['pH 2'].multipleOf = 0.01
                validator.properties['pH 3'].multipleOf = 0.01

                validator.properties['SG 1'].multipleOf = 0.001
                validator.properties['SG 2'].multipleOf = 0.001
                validator.properties['SG 3'].multipleOf = 0.001

                validator.properties['Orifice'].minimum = 39
                validator.properties['Orifice'].maximum = 39

                validator.properties['Orifice Size'].minimum = 37
                validator.properties['Orifice'].multipleOf = 1
                validator.properties['Orifice Size'].maximum = 39

                if (!editingRecord['Orifice']) {
                    editingRecord['Orifice'] = 39
                }
                if (!editingRecord['Orifice Size']) {
                    editingRecord['Orifice Size'] = 37
                }

                validator.properties['Zinc Bag 1'].minimum = 1
                validator.properties['Zinc Bag 1'].multipleOf = 1
                validator.properties['Zinc Bag 1'].maximum = 24

                validator.properties['Zinc Bag 2'].minimum = 1
                validator.properties['Zinc Bag 2'].multipleOf = 1
                validator.properties['Zinc Bag 2'].maximum = 24

                validator.properties['Zinc Bag 3'].minimum = 1
                validator.properties['Zinc Bag 3'].multipleOf = 1
                validator.properties['Zinc Bag 3'].maximum = 24

                validator.properties['Zinc Bag 4'].minimum = 1
                validator.properties['Zinc Bag 4'].multipleOf = 1
                validator.properties['Zinc Bag 4'].maximum = 24

                if (!editingRecord['Zinc Bag 1']) {
                    editingRecord['Zinc Bag 1'] = 1
                }
                if (!editingRecord['Zinc Bag 2']) {
                    editingRecord['Zinc Bag 2'] = 1
                }
                if (!editingRecord['Zinc Bag 3']) {
                    editingRecord['Zinc Bag 3'] = 1
                }
                if (!editingRecord['Zinc Bag 4']) {
                    editingRecord['Zinc Bag 4'] = 1
                }
            }
            if (validator.title === 'Powder Batch') {
                validator.properties['Moisture'].multipleOf = 0.01
                validator.properties['Bulk 1'].multipleOf = 0.01
                validator.properties['Bulk 1'].multipleOf = 0.01
                validator.properties['Tap 1'].multipleOf = 0.01
                validator.properties['Tap 2'].multipleOf = 0.01
                validator.properties['Fluidity 1'].multipleOf = 0.01
                validator.properties['Fluidity 2'].multipleOf = 0.01
            }

            return (
                <Fragment>
                    <Row>
                        <Col>
                            <Navigation path={path} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h1>Enter Data For Batch</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 12}}>
                            <SchemaForm
                                schema={validator}
                                editingRecord={editingRecord}
                                dataModels={this.state.dataModels}
                                dataModel={this.state.dataModels.find(
                                    (d) => d._id === this.props.dataModelId
                                )}
                                manualDataLinkFilters={
                                    this.props.manualDataLinkFilters
                                }
                                presetValues={this.props.presetValues}
                                onSubmit={this.onSubmit}
                                hideArchive={true}
                                timestampsToToday={true}
                                submitting={() =>
                                    this.setState({loading: true})
                                }
                                ObjectFieldTemplate={
                                    Templates[this.props.title]
                                }
                            />
                        </Col>
                    </Row>
                </Fragment>
            )
        } else {
            return null
        }
    }

    async onSubmit(document) {
        this.setState({loading: true})
        if (this.state.enteringNewBatch) {
            const insertedBatch = await API.post(
                'data-models/' + this.props.dataModelId + '/paginate',
                {
                    filter: [
                        {
                            type: 'Text',
                            logic: 'is',
                            value: [document[this.props.batchNumberField]],
                            path: this.props.batchNumberField,
                        },
                    ],
                    sort: {},
                    timezone: localStorage['timezone'],
                },
                2
            )
            await deincrementRawMaterial(insertedBatch.result.results[0])
        }
        this.setState({
            loading: false,
            selectedSection: null,
            newBatchNumberValue: '',
        })
        this.fetchBatches()
    }

    async fetchBatches() {
        const existingBatches = await paginate(
            this.props.dataModelId,
            [
                {
                    type: 'DesignatedTimestamp',
                    logic: 'previous',
                    value: {
                        unit: 'day',
                        value: 45,
                    },
                    path: this.designatedTimestamp.name,
                },
            ],
            {}
        )

        this.setState({
            existingBatches,
        })
    }

    async componentDidMount() {
        const dataModels = await API.get('data-models', 2),
            dataModel = dataModels.find((d) => d._id === this.props.dataModelId)

        this.designatedTimestamp = dataModel.fields.find(
            (f) => !f.archived && f.type === 'DesignatedTimestamp'
        )
        if (!this.designatedTimestamp)
            return alert(
                'Cannot find DesignatedTimestamp field. Please verify schema.'
            )

        await this.fetchBatches()

        this.setState({
            dataModels,
            validator: await API.get(
                'data-models/' + this.props.dataModelId + '/validator',
                2
            ),
            loading: false,
        })
    }

    render() {
        return (
            <Fragment>
                {this.RENDER_INITIAL()}
                {this.RENDER_ENTER_NEW_BATCH_NUMBER()}
                {this.RENDER_ENTER_DATA_FOR_BATCH()}
                {this.RENDER_SELECT_EXISTING_BATCH()}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
