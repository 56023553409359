import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import autobind from 'auto-bind'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

const RANGES = [
    {
        label: 'Very Light Traffic',
        range: [0, 0.2],
    },
    {
        label: 'Light Traffic',
        range: [0.21, 0.4],
    },
    {
        label: 'Moderate Traffic',
        range: [0.41, 0.6],
    },
    {
        label: 'Heavy Traffic',
        range: [0.61, 0.8],
    },
    {
        label: 'Very Heavy Traffic',
        range: [0.81, 1],
    },
]

export default class DensityRangeSelector extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            range: [0, 4],
        }

        this.props.onChange([
            RANGES[this.state.range[0]].range[0],
            RANGES[this.state.range[1]].range[1],
        ])
    }

    handleChange(range) {
        this.setState({range})

        this.props.onChange([
            RANGES[range[0]].range[0],
            RANGES[range[1]].range[1],
        ])
    }

    render() {
        const range = [RANGES[this.state.range[0]], RANGES[this.state.range[1]]]
        let label = ''
        if (this.state.range[0] === 0 && this.state.range[1] === 4) {
            label = 'All Traffic'
        } else {
            label =
                range[0].label === range[1].label
                    ? range[0].label
                    : range[0].label + ' - ' + range[1].label
        }

        return (
            <Fragment>
                <Row>
                    <Col className="text-center">{label}</Col>
                </Row>
                <Row>
                    <Col>
                        <Range
                            min={0}
                            max={4}
                            defaultValue={[0, 4]}
                            allowCross={false}
                            trackStyle={{
                                backgroundColor: 'red',
                            }}
                            onChange={this.handleChange}
                            tipFormatter={(value) => RANGES[value].label}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
