import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Table,
    Badge,
} from 'reactstrap'
import autobind from 'auto-bind'
import PartSelect from './PartSelect'

import PropTypes from 'prop-types'

import HourlyTargetActualChart from './HourlyTargetActualChart'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import * as CommonSDK from 'SDK/api/common'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import {Typeahead, Menu, MenuItem} from 'react-bootstrap-typeahead'
import {getAssets} from 'SDK/api/common'
import styled from 'styled-components'

import Moment from 'moment'
import {extendMoment} from 'moment-range'
const moment = extendMoment(Moment)

const ContentWrapper = styled.section`
    padding: 1em;
    background: #eee;
    border-radius: 5;
    border: 1px solid #ddd;
`

const ContentBlock = styled.section`
    padding: 1em;
    background: #fff;
    border: 1px solid #eee;
    color: #555;
`

export default class AssetQueue extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = ['Part']
    showBorder = false
    id = 'AssetQueue'
    static propTypes = {
        deviceId: PropTypes.string,
        size: PropTypes.string,
        range: PropTypes.object, // moment.range
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            chosenMomentDate: moment(),
            assets: [],
            shifts: [],
            assetJSX: [],
            hourlyTargets: [],
            content: [
                {id: 0, key: 1, draggableId: 'abc1', text: 'abc1'},
                {id: 1, key: 2, draggableId: 'abc2', text: 'abc2'},
                {id: 2, key: 3, draggableId: 'abc3', text: 'abc3'},
                {id: 3, key: 4, draggableId: 'abc4', text: 'abc4'},
                {id: 4, key: 5, draggableId: 'abc5', text: 'abc5'},
            ],
        }
    }

    async componentWillMount() {
        await this.getShiftSchedule()
        await this.getAssets()
        this.dateUpdate()
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    async savePart() {
        if (!this.state.partNumber || !this.state.target) {
            alert('One of the fields are empty.')
            return false
        }

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Part',
                timestamp: moment(),
                timeStart: moment(),
                timeEnd: moment(),
                value: this.state.partNumber,
                metaData: [
                    {
                        name: 'part',
                        value: this.state.partNumber,
                    },
                    {
                        name: 'target',
                        value: this.state.target,
                    },
                ],
            },
            2
        )

        await this.getParts()

        this.clearForm()
    }

    clearForm() {
        this.state.form.forEach((f) => this.setState({[f]: ''}))
    }

    async saveHourlyTarget(shift, part, deviceId) {
        const partName = part.metaData.find((m) => m.name == 'part').value
        const target = part.metaData.find((m) => m.name == 'target').value

        const response = await API.post(
            'states',
            {
                nodeId: deviceId,
                deviceId: deviceId,
                name: 'Scheduled Target',
                timestamp: shift.range[0],
                timeStart: shift.range[0],
                timeEnd: shift.range[1],
                value: target,
                metaData: [
                    {
                        name: 'part',
                        value: partName,
                    },
                    {
                        name: 'target',
                        value: target,
                    },
                ],
            },
            2
        )

        if (response.success === true) {
            this.dateUpdate()
            alert('Saved Successfully')
        }
    }

    async getAssets() {
        const assets = await getAssets()
        this.setState({assets})
    }

    async getShiftSchedule(deviceId = this.props.deviceId) {
        this.setState({
            shifts: await CommonSDK.getShiftSchedule(deviceId),
        })
    }

    handleChange(input, e) {
        this.setState({[input]: e.target.value})
    }

    onDragEnd = (result) => {
        if (!result.destination) return
        const content = this.reorder(
            this.state.content,
            result.source.index,
            result.destination.index
        )
        this.setState({content})
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    backward = () => {
        const chosenMomentDate = moment(this.state.chosenMomentDate).subtract(
            1,
            'day'
        )
        this.setState({chosenMomentDate}, this.dateUpdate)
    }

    forward = () => {
        const chosenMomentDate = moment(this.state.chosenMomentDate).add(
            1,
            'day'
        )
        this.setState({chosenMomentDate}, this.dateUpdate)
    }

    dateUpdate = async () => {
        await this.getHourlyTarget()
        this.getFilteredShifts()
        this.setAssetJSX()
    }

    onSelectTargetForShift = (shift, part, deviceId) => {
        this.saveHourlyTarget(shift, part, deviceId)
    }

    onDeleteTargetForShift = async () => {
        this.dateUpdate()
    }

    getHourlyTarget = async (timestamp = this.state.chosenMomentDate) => {
        let hourlyTargets = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Scheduled Target',
                    timeStart: {
                        $lte: timestamp.endOf('day').toISOString(),
                    },
                    timeEnd: {
                        $gte: timestamp.startOf('day').toISOString(),
                    },
                },
            },
            2
        )
        this.setState({hourlyTargets})
    }

    getAllHourlyTargetsByShiftAndDeviceId = (shift, deviceId) => {
        let elements = []
        this.state.hourlyTargets.forEach((hourlyTarget) => {
            if (hourlyTarget.deviceId === deviceId) {
                if (moment(hourlyTarget.timeStart) < shift.range[1]) {
                    // This target must start at or before the end of shift
                    if (moment(hourlyTarget.timeEnd) > shift.range[0]) {
                        // This target must end after the start of shift
                        elements.push(hourlyTarget)
                    }
                }
            }
        })
        return elements
    }

    getHourlyTargetByShiftAndDeviceId = (shift, deviceId) => {
        let elements = []
        this.state.hourlyTargets.forEach((hourlyTarget) => {
            if (
                hourlyTarget.deviceId === deviceId &&
                shift.range[0].toISOString() >=
                    moment(hourlyTarget.timeStart).toISOString() &&
                shift.range[1].toISOString() <=
                    moment(hourlyTarget.timeEnd).toISOString()
            ) {
                elements.push(hourlyTarget)
            }
        })
        return elements
    }

    getFilteredShifts = () => {
        this.setState({
            filteredShifts: CommonSDK.getShiftsByDay(
                this.state.chosenMomentDate,
                this.state.shifts,
                false
            ),
        })
    }

    deleteHourlyTarget = async (hourlyTargetId) => {
        if (hourlyTargetId) {
            let data = await API.remove('states/' + hourlyTargetId, 2)
            if (data.success === true) {
                this.dateUpdate()
                alert('Item Deleted Successfully')
            } else {
                alert('Item Not Deleted Successfully')
            }
        }
    }

    setAssetJSX = (
        shifts = this.state.filteredShifts,
        assets = this.state.assets
    ) => {
        let elements = []
        const hourlyTargets = this.state.hourlyTargets
        assets.forEach((asset) => {
            var jsx = []
            shifts.forEach((shift) => {
                let hourlyTarget = this.getHourlyTargetByShiftAndDeviceId(
                    shift,
                    asset.deviceId
                )
                let allHourlyTargets =
                    this.getAllHourlyTargetsByShiftAndDeviceId(
                        shift,
                        asset.deviceId
                    )
                jsx.push(
                    <Row style={{marginTop: 25}}>
                        <Col md="12">
                            <Card style={{borderRadius: 25}}>
                                <CardHeader>
                                    <strong>
                                        {asset.name + ' - ' + shift.name}
                                    </strong>
                                </CardHeader>
                                <CardBody>
                                    <PartSelect
                                        timestamp={moment().unix()}
                                        deviceId={asset.deviceId}
                                        shift={shift}
                                        onSelect={(part) => {
                                            this.onSelectTargetForShift(
                                                shift,
                                                part,
                                                asset.deviceId
                                            )
                                        }}
                                        onSave={() => this.dateUpdate()}
                                    />
                                    <div style={{marginTop: 10, padding: 20}}>
                                        {allHourlyTargets.length > 0 ? (
                                            <Row style={{marginBottom: 10}}>
                                                <Col
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}>
                                                    <strong>Part</strong>
                                                </Col>
                                                <Col
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}>
                                                    <strong>Quantity</strong>
                                                </Col>
                                                <Col
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}>
                                                    <strong>Start Time</strong>
                                                </Col>
                                                <Col
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}>
                                                    <strong>End Time</strong>
                                                </Col>
                                                <Col
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}>
                                                    <strong>Action</strong>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row></Row>
                                        )}
                                        {allHourlyTargets.map((target, idx) => {
                                            return (
                                                <Row
                                                    key={idx}
                                                    style={{marginBottom: 10}}>
                                                    <Col>
                                                        {
                                                            target.metaData.find(
                                                                (t) =>
                                                                    t.name ==
                                                                    'part'
                                                            ).value
                                                        }
                                                    </Col>
                                                    <Col>
                                                        {
                                                            target.metaData.find(
                                                                (t) =>
                                                                    t.name ==
                                                                    'target'
                                                            ).value
                                                        }
                                                    </Col>
                                                    <Col>
                                                        {moment(
                                                            target.timeStart
                                                        ).format(
                                                            'hh:mmA, MMM Do'
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        {moment(
                                                            target.timeEnd
                                                        ).format(
                                                            'hh:mmA, MMM Do'
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            outline
                                                            color="danger"
                                                            onClick={() =>
                                                                this.deleteHourlyTarget(
                                                                    target._id
                                                                )
                                                            }>
                                                            Delete
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            })

            elements.push(<Fragment>{jsx}</Fragment>)
        })
        this.setState({assetJSX: elements})
    }

    render() {
        const self = this
        const {assets, filteredShifts, hourlyTarget, assetJSX} = this.state

        return (
            <Fragment>
                <Row
                    style={{
                        margin: 2,
                        marginBottom: 15,
                        padding: 5,
                        backgroundColor: 'rgba(100,200,255,1)',
                    }}>
                    <Col className="text-left">
                        <Button
                            size="xs"
                            color="primary"
                            onClick={this.backward}>
                            <i className="simple-icon-arrow-left" />
                        </Button>
                    </Col>
                    <Col className="text-center">
                        <Button type="button" className="btn btn-xs">
                            {this.state.chosenMomentDate.format(
                                'dddd, MMMM DD, YYYY'
                            )}
                        </Button>
                    </Col>
                    <Col className="text-right">
                        <Button
                            size="xs"
                            color="primary"
                            onClick={this.forward}>
                            <i className="simple-icon-arrow-right" />
                        </Button>
                    </Col>
                </Row>
                {assetJSX}
            </Fragment>
        )
    }
}
