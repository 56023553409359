import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    CardHeader,
    CardFooter,
} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'

import {secondsToHHMMSS, tileColor} from 'SDK/helpers'

import Subscriber from 'SDK/subscriber'

import SignInModal from './SignInModal'
import SignOutModal from './SignOutModal'
import WorkOrderModal from './WorkOrderModal'
import ScrapModal from './ScrapModal'
import ReworkModal from './ReworkModal'
import DowntimeEntryModule from '../DowntimeEntryModule'
import PartChart from './PartChart'
import ChangeoverModal from './ChangeOverModal'

function divideBy10(n) {
    return n / 10
}
function round(n) {
    return Math.round(n * 100) / 100
}

export default class HubbellAikenHMI extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'HubbellAikenHMI'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            deviceName: '',
            data: [],
            header: <></>,
            stateTimestamp: moment(),
            workOrder: {
                workorder: 'N/A',
                quantity: 'N/A',
                part: 'N/A',
                moldsheds: 'N/A',
                tool: 'N/A',
                compound: 'N/A',
                mixdate: 'N/A',
                batch: 'N/A',
            },
            parts: {
                total: 0,
                scrap: 0,
                rework: 0,
            },
            chartData: {
                countdata: [],
                hours: [],
                target: 0,
                reworkdata: [],
                scrapdata: [],
            },
            editingWorkOrderRecord: null,
        }

        this.timerange = [
            moment().startOf('hour').add(-23, 'hours').toISOString(),
            moment().startOf('minute').toISOString(),
        ]
    }

    toggleSignInModal() {
        if (this.state.signInModalOpen) this.fetchData()
        this.setState({
            signInModalOpen: !this.state.signInModalOpen,
        })
    }

    toggleSignOutModal() {
        if (this.state.signOutModalOpen) this.fetchData()
        this.setState({
            signOutModalOpen: !this.state.signOutModalOpen,
        })
    }

    toggleWorkOrderModal(editingWorkOrderRecord = null) {
        if (this.state.workOrderModalOpen) this.fetchData()
        this.setState({
            workOrderModalOpen: !this.state.workOrderModalOpen,
            editingWorkOrderRecord,
        })
    }

    toggleScrapModal() {
        if (this.state.scrapModalOpen) this.fetchData()
        this.setState({
            scrapModalOpen: !this.state.scrapModalOpen,
        })
    }

    toggleReworkModal() {
        if (this.state.reworkModalOpen) this.fetchData()
        this.setState({
            reworkModalOpen: !this.state.reworkModalOpen,
        })
    }

    toggleDowntimeModal() {
        this.setState({
            downtimeModalOpen: !this.state.downtimeModalOpen,
        })
    }

    async toggleChangeoverModal() {
        if (this.state.changeOverModalOpen) {
            await this.fetchData()
        }
        this.setState({
            changeOverModalOpen: !this.state.changeOverModalOpen,
        })
    }

    makeKPI(name, alias, fn) {
        const record = this.state.data.find((d) => d.name === name),
            value = record ? record.value : 'N/A'

        return (
            <Card
                className="mt-4"
                style={{
                    textAlign: 'center',
                    border: '1px solid #145388',
                    borderRadius: '5px',
                }}>
                <CardHeader
                    style={{
                        paddingTop: '5px',
                        paddingBottom: '0px',
                        background: '#145388',
                        color: '#fff',
                    }}>
                    <p>{alias || name}</p>
                </CardHeader>
                <CardBody style={{padding: '0.5rem'}}>
                    <h3>{fn && value !== 'N/A' ? fn(value) : value}</h3>
                </CardBody>
            </Card>
        )
    }

    async fetchData() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    timeEnd: null,
                },
            },
            2
        )

        const workOrderRecord = data.find((d) => d.name === 'Work Order')

        let workOrder = {
            workorder: 'N/A',
            quantity: 'N/A',
            part: 'N/A',
            moldsheds: 'N/A',
            tool: 'N/A',
            compound: 'N/A',
            mixdate: 'N/A',
            batch: 'N/A',
        }
        if (workOrderRecord) {
            workOrder.timestamp = moment(workOrderRecord.timestamp)
            workOrder.workorder = workOrderRecord.metaData.find(
                (m) => m.name === 'workorder'
            ).value
            workOrder.quantity = workOrderRecord.metaData.find(
                (m) => m.name === 'quantity'
            ).value
            workOrder.part = workOrderRecord.metaData.find(
                (m) => m.name === 'part'
            ).value
            workOrder.moldsheds = workOrderRecord.metaData.find(
                (m) => m.name === 'moldsheds'
            ).value
            workOrder.tool = workOrderRecord.metaData.find(
                (m) => m.name === 'tool'
            ).value
            workOrder.compound = workOrderRecord.metaData.find(
                (m) => m.name === 'compound'
            ).value
            workOrder.mixdate = workOrderRecord.metaData.find(
                (m) => m.name === 'mixdate'
            ).value
            workOrder.batch = workOrderRecord.metaData.find(
                (m) => m.name === 'batch'
            ).value
        }

        this.setState({data, workOrder}, () => {
            this.determineStatus()
            this.fetchParts()
            this.makeChart()
        })
    }

    async fetchParts() {
        let parts = {
            total: 0,
            scrap: 0,
            rework: 0,
        }

        if (this.state.workOrder.workorder !== 'N/A') {
            const data = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: {$in: ['Part Count', 'Reject', 'Rework']},
                        timeStart: {
                            $gt: this.state.workOrder.timestamp.toISOString(),
                        },
                    },
                },
                2
            )
            parts.total = data
                .filter((d) => d.name === 'Part Count')
                .reduce((a, b) => a + b.value, 0)
            parts.scrap = data
                .filter((d) => d.name === 'Reject')
                .reduce((a, b) => a + b.value, 0)
            parts.rework = data
                .filter((d) => d.name === 'Rework')
                .reduce((a, b) => a + b.value, 0)
        }
        this.setState({parts})
    }

    async makeChart() {
        let data = await API.post('historical/aggregate2', {
            timeStart: this.timerange[0],
            timeEnd: this.timerange[1],
            state: ['Part Count', 'Reject', 'Rework'],
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'hour',
            logic: 'count',
        })
        data = data.data

        let countdata = []
        let scrapdata = []
        let reworkdata = []
        let hourdata = []
        let target = this.state.data.find((d) => d.name === 'Hourly Target')
        if (target) {
            target = target.value
        } else {
            target = 0
        }

        for (let j in data.devices[this.props.deviceId]['Part Count']) {
            let hour = moment(data.dates[j]).format('ha'),
                count = data.devices[this.props.deviceId]['Part Count'][j]
            if (count.constructor === Object) count = count.count
            countdata.push(count)
            hourdata.push(hour)
        }
        for (let j in data.devices[this.props.deviceId]['Reject']) {
            let count = data.devices[this.props.deviceId]['Reject'][j]
            if (count.constructor === Object) count = count.count
            scrapdata.push(count)
        }
        for (let j in data.devices[this.props.deviceId]['Rework']) {
            let count = data.devices[this.props.deviceId]['Rework'][j]
            if (count.constructor === Object) count = count.count
            reworkdata.push(count)
        }

        this.setState({
            chartData: {
                countdata,
                hourdata,
                target,
                reworkdata,
                scrapdata,
            },
        })
    }

    renderRunning() {
        return (
            <CardHeader
                style={{background: 'rgb(46, 204, 113)', color: '#fff'}}>
                <Row>
                    <Col xs="6">
                        <h1 className="mt-4">
                            <strong>{this.state.deviceName}</strong>
                        </h1>
                    </Col>
                    <Col xs="6" className="text-right">
                        <h5 className="mt-4">{this.state.timer}</h5>
                    </Col>
                </Row>
            </CardHeader>
        )
    }

    renderReasonEntered() {
        let reason = this.state.data.find((d) => d.name === 'Downtime Reason')
        if (reason) reason = reason.value
        else reason = ''
        return (
            <CardHeader
                style={{
                    background: '#f0ad4e',
                    color: '#fff',
                    cursor: 'pointer',
                }}
                onClick={this.toggleDowntimeModal}>
                <Row>
                    <Col xs="6">
                        <h1 className="mt-4">
                            <strong>{this.state.deviceName}</strong>
                        </h1>
                    </Col>
                    <Col xs="6" className="text-right">
                        <h5 className="mt-4">
                            {reason} - {this.state.timer}
                        </h5>
                    </Col>
                </Row>
            </CardHeader>
        )
    }

    renderDowntimeUnder() {
        return (
            <CardHeader
                style={{
                    background: 'rgb(231, 76, 60)',
                    color: '#fff',
                    cursor: 'pointer',
                }}>
                <Row>
                    <Col xs="6">
                        <h1 className="mt-4">
                            <strong>{this.state.deviceName}</strong>
                        </h1>
                    </Col>
                    <Col xs="6" className="text-right">
                        <h5 className="mt-4">{this.state.timer}</h5>
                    </Col>
                </Row>
            </CardHeader>
        )
    }

    renderDowntimeOver() {
        return (
            <CardHeader
                style={{
                    background: 'rgb(231, 76, 60)',
                    color: '#fff',
                    cursor: 'pointer',
                }}
                onClick={this.toggleDowntimeModal}>
                <Row>
                    <Col xs="6">
                        <h1 className="mt-4">
                            <strong>{this.state.deviceName}</strong>
                        </h1>
                    </Col>
                    <Col xs="6" className="text-right">
                        <h5 className="mt-4">
                            Click to enter reason - {this.state.timer}
                        </h5>
                    </Col>
                </Row>
            </CardHeader>
        )
    }

    determineStatus() {
        const incycle = this.state.data.find((d) => d.name === 'In-Cycle'),
            downtime = this.state.data.find((d) => d.name === 'Downtime'),
            reason = this.state.data.find((d) => d.name === 'Downtime Reason')

        let status = null,
            stateTimestamp = null

        if (incycle) {
            status = 'RUNNING'
            stateTimestamp = moment(incycle.timestamp)
        } else if (reason) {
            status = 'DOWNTIME_WITH_ENTRY'
            stateTimestamp = moment(downtime.timestamp)
        } else if (
            moment().diff(moment(downtime.timestamp), 'seconds') >
            this.state.downtimeThreshold
        ) {
            status = 'DOWNTIME_OVER'
            stateTimestamp = moment(downtime.timestamp)
        } else {
            status = 'DOWNTIME_UNDER'
            stateTimestamp = moment(downtime.timestamp)
        }

        this.setState({status, stateTimestamp})
    }

    incrementTimer() {
        this.setState({
            timer: secondsToHHMMSS(
                moment().diff(moment(this.state.stateTimestamp), 'seconds')
            ),
        })
    }

    async endWorkOrder() {
        let workOrder = this.state.data.find((d) => d.name === 'Work Order')

        workOrder.timeEnd = moment().toISOString()

        if (workOrder) {
            await API.patch('states/' + workOrder._id, workOrder, 2)
        }

        this.fetchData()
    }

    editWorkOrder() {
        let workOrder = this.state.data.find((d) => d.name === 'Work Order')
        this.toggleWorkOrderModal(workOrder)
    }

    async componentDidMount() {
        const devices = await API.get('devices')
        try {
            this.setState({
                deviceName: devices.find(
                    (d) => d.deviceId === this.props.deviceId
                ).name,
                downtimeThreshold:
                    devices.find((d) => d.deviceId === this.props.deviceId)
                        .downtimeThresholdSeconds || 300,
            })
        } catch (error) {
            alert('could not load view')
        }
        this.subscriber.add(this.fetchData, 1000 * 15, 'fetchData()')
        this.subscriber.add(this.incrementTimer, 500, 'incrementTimer()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.75 + 'px'
        const parts = this.state.parts.total - this.state.parts.scrap

        return (
            <Fragment>
                <div
                    style={{
                        backgroundColor: 'black',
                        position: 'relative',
                        height,
                    }}>
                    <Card style={{height: height, borderRadius: '5px'}}>
                        {this.state.status === 'RUNNING'
                            ? this.renderRunning()
                            : null}
                        {this.state.status === 'DOWNTIME_WITH_ENTRY'
                            ? this.renderReasonEntered()
                            : null}
                        {this.state.status === 'DOWNTIME_UNDER'
                            ? this.renderDowntimeUnder()
                            : null}
                        {this.state.status === 'DOWNTIME_OVER'
                            ? this.renderDowntimeOver()
                            : null}
                        <CardBody style={{height}}>
                            <Row>
                                <Col xs="3">
                                    <ButtonGroup>
                                        <Button
                                            color="warning"
                                            onClick={this.toggleSignInModal}>
                                            SIGN IN
                                        </Button>
                                        <Button
                                            color="warning"
                                            onClick={this.toggleSignOutModal}>
                                            SIGN OUT
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                                <Col xs="3">
                                    <ButtonGroup>
                                        {this.state.workOrder.workorder ===
                                        'N/A' ? (
                                            <Button
                                                color="warning"
                                                onClick={
                                                    this.toggleWorkOrderModal
                                                }>
                                                START WORK ORDER
                                            </Button>
                                        ) : (
                                            <ButtonGroup>
                                                <Button
                                                    color="warning"
                                                    onClick={
                                                        this.editWorkOrder
                                                    }>
                                                    EDIT W/O
                                                </Button>
                                                <Button
                                                    color="warning"
                                                    onClick={this.endWorkOrder}>
                                                    END W/O
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                    </ButtonGroup>
                                </Col>
                                <Col xs="3">
                                    <ButtonGroup>
                                        <Button
                                            color="warning"
                                            onClick={this.toggleScrapModal}>
                                            ENTER SCRAP
                                        </Button>
                                        <Button
                                            color="warning"
                                            onClick={this.toggleReworkModal}>
                                            ENTER REWORK
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                                <Col xs="3">
                                    <ButtonGroup>
                                        {!this.state.data.find(
                                            (d) => d.name === 'Downtime Reason'
                                        ) &&
                                        this.state.data.find(
                                            (d) => d.name === 'Downtime'
                                        ) ? (
                                            <Button
                                                color="warning"
                                                onClick={
                                                    this.toggleChangeoverModal
                                                }>
                                                START CHANGEOVER
                                            </Button>
                                        ) : null}
                                    </ButtonGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="7">
                                    <Row>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT07 Zone 7 Temperature Sensor Engineering Units',
                                                'Zone 4 Top',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT05 Zone 5 Temperature Sensor Engineering Units',
                                                'Zone 3 Top',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT03 Zone 3 Temperature Sensor Engineering Units',
                                                'Zone 2 Top',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT01 Zone 1 Temperature Sensor Engineering Units',
                                                'Zone 1 Top',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT08 Zone 8 Temperature Sensor Engineering Units',
                                                'Zone 4 Bottom',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT06 Zone 6 Temperature Sensor Engineering Units',
                                                'Zone 3 Bottom',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT04 Zone 4 Temperature Sensor Engineering Units',
                                                'Zone 2 Bottom',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="3">
                                            {this.makeKPI(
                                                'TT02 Zone 2 Temperature Sensor Engineering Units',
                                                'Zone 1 Bottom',
                                                divideBy10
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="5">
                                    <Card
                                        className="mt-4"
                                        style={{
                                            border: '1px solid #145388',
                                            borderRadius: '5px',
                                        }}>
                                        <CardHeader
                                            style={{
                                                textAlign: 'center',
                                                paddingTop: '5px',
                                                paddingBottom: '0px',
                                                background: '#145388',
                                                color: '#fff',
                                            }}>
                                            <p>Status</p>
                                        </CardHeader>
                                        <CardBody style={{padding: '0.5rem'}}>
                                            <Row>
                                                <Col xs="7">
                                                    <p style={{margin: 1}}>
                                                        <strong>
                                                            Work Order:{' '}
                                                        </strong>{' '}
                                                        {
                                                            this.state.workOrder
                                                                .workorder
                                                        }
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>Part: </strong>{' '}
                                                        {
                                                            this.state.workOrder
                                                                .part
                                                        }
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>
                                                            Mold Sheds:{' '}
                                                        </strong>{' '}
                                                        {
                                                            this.state.workOrder
                                                                .moldsheds
                                                        }
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>Tool: </strong>{' '}
                                                        {
                                                            this.state.workOrder
                                                                .tool
                                                        }
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>
                                                            Compound #:{' '}
                                                        </strong>{' '}
                                                        {
                                                            this.state.workOrder
                                                                .compound
                                                        }
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>
                                                            Mix Date:{' '}
                                                        </strong>{' '}
                                                        {moment(
                                                            this.state.workOrder
                                                                .mixdate
                                                        ).format('l')}
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>
                                                            Batch #:{' '}
                                                        </strong>{' '}
                                                        {
                                                            this.state.workOrder
                                                                .batch
                                                        }
                                                    </p>
                                                    <p style={{margin: 1}}>
                                                        <strong>Count: </strong>{' '}
                                                        {parts} of{' '}
                                                        {
                                                            this.state.workOrder
                                                                .quantity
                                                        }
                                                    </p>
                                                </Col>
                                                <Col xs="5">
                                                    <p style={{margin: 3}}>
                                                        <strong>
                                                            Signed In Operators
                                                        </strong>
                                                    </p>
                                                    {this.state.data
                                                        .filter(
                                                            (d) =>
                                                                d.name ===
                                                                'Operator Sign In'
                                                        )
                                                        .map((o, idx) => {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    style={{
                                                                        margin: 3,
                                                                    }}>
                                                                    {o.value}
                                                                </p>
                                                            )
                                                        })}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <Row>
                                        <Col xs="4">
                                            {this.makeKPI(
                                                'TT09 Zone 9 Temperature Sensor Engineering Units',
                                                'Post Cure Entry',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="4">
                                            {this.makeKPI(
                                                'IT11 RF Grid Current Engineering Units',
                                                'RF Grid Current',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="4">
                                            {this.makeKPI(
                                                'Line Speed Inches per Minute',
                                                'Line Speed',
                                                round
                                            )}
                                        </Col>
                                        <Col xs="4">
                                            {this.makeKPI(
                                                'TT10 Zone 10 Temperature Sensor Engineering Units',
                                                'Post Cure Exit',
                                                divideBy10
                                            )}
                                        </Col>
                                        <Col xs="4">
                                            {this.makeKPI(
                                                'Pull Force Engineering Units',
                                                'Pull Force'
                                            )}
                                        </Col>
                                        <Col xs="4">
                                            {this.makeKPI(
                                                'Length of Current Rod',
                                                'Curr Rod Length',
                                                round
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="6">
                                    <PartChart
                                        timerange={this.timerange}
                                        target={this.state.chartData.target}
                                        data={this.state.chartData.countdata}
                                        hours={this.state.chartData.hourdata}
                                        scrapdata={
                                            this.state.chartData.scrapdata
                                        }
                                        reworkdata={
                                            this.state.chartData.reworkdata
                                        }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                {this.state.signInModalOpen ? (
                    <SignInModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleSignInModal}
                    />
                ) : null}

                {this.state.signOutModalOpen ? (
                    <SignOutModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleSignOutModal}
                    />
                ) : null}

                {this.state.workOrderModalOpen ? (
                    <WorkOrderModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleWorkOrderModal}
                        editingWorkOrderRecord={
                            this.state.editingWorkOrderRecord
                        }
                    />
                ) : null}

                {this.state.scrapModalOpen ? (
                    <ScrapModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleScrapModal}
                    />
                ) : null}

                {this.state.reworkModalOpen ? (
                    <ReworkModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleReworkModal}
                    />
                ) : null}

                {this.state.downtimeModalOpen ? (
                    <Modal
                        isOpen={this.state.downtimeModalOpen}
                        toggle={this.toggleDowntimeModal}>
                        <ModalBody style={{padding: 0}}>
                            <DowntimeEntryModule
                                disableSearch={true}
                                deviceId={this.props.deviceId}
                                threshold={this.state.downtimeThreshold}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={this.toggleDowntimeModal}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}

                {this.state.changeOverModalOpen ? (
                    <ChangeoverModal
                        deviceId={this.props.deviceId}
                        toggleModal={this.toggleChangeoverModal}
                        downtimeRecord={this.state.data.find(
                            (d) => d.name === 'Downtime'
                        )}
                    />
                ) : null}
            </Fragment>
        )
    }
}
