import React from 'react'
import ReactDOM from 'react-dom'

import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import App from 'containers/App'
import {configureStore} from 'redux/store'

const MainApp = () => (
    <Provider store={configureStore()}>
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route path="/" component={App} />
            </Switch>
        </Router>
    </Provider>
)

export default ReactDOM.render(<MainApp />, document.getElementById('root'))
