import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Button,
    Input,
    Table,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import Switch from 'rc-switch'
import autobind from 'auto-bind'
import moment from 'moment'
import Creatable from 'react-select/creatable'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            name: this.props.name ? this.props.name : 'Part Count',
            value: '',
            timestart: moment().subtract(1, 'minutes'),
            timeend: moment(),
            loading: false,
            rows: [],
            alertComponent: [],
        }
    }

    validateForm() {
        return (
            this.state.name &&
            this.state.timestart &&
            this.state.timeend &&
            this.state.value
        )
    }

    async save(
        validated = this.validateForm(),
        timestart = this.state.timestart.toISOString(),
        timeend = moment.isMoment(this.state.timeend)
            ? this.state.timeend.toISOString()
            : null,
        timestamp = this.state.timestart.toISOString()
    ) {
        if (!validated) {
            this.setState({
                alertComponent: (
                    <Alert color="danger">Cannot submit form.</Alert>
                ),
            })
            return
        }

        let result = await API.post(
            'states',
            {
                deviceId: this.state.deviceId,
                nodeId: this.state.deviceId,
                timestamp: timestamp,
                timeStart: timestart,
                timeEnd: timeend,
                name: this.state.name,
                metaData: [],
                value: this.state.value,
            },
            2
        )

        if (result) {
            this.setState({value: ''})
            this.fetchRows()
            this.setState({
                alertComponent: (
                    <Alert color="success">Saved successfully</Alert>
                ),
            })
        } else {
            alert('Could not be saved')
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deviceId !== this.state.deviceId) {
            this.setState({deviceId: nextProps.deviceId}, () => {
                this.fetchRows()
            })
        }
    }

    async fetchRows(
        deviceId = this.state.deviceId,
        stateName = this.state.name,
        timeStart = this.state.timestart.toISOString(),
        timeEnd = this.state.timeend.toISOString()
    ) {
        let rows = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: [this.state.deviceId],
                    },
                    timestamp: {
                        $gte: timeStart,
                        $lte: timeEnd,
                    },
                    name: stateName,
                },
                options: {
                    sort: {
                        timestamp: 1,
                    },
                },
            },
            2
        )

        this.setState({rows}, () => {
            this.setState({alertComponent: []})
        })
    }

    async delete(row) {
        const deleted = await API.remove('states/' + row._id, 2)
        if (!deleted) {
            alert('Could not delete record.')
        } else {
            this.fetchRows()
        }
    }

    render() {
        return (
            <Fragment>
                <Card style={{marginBottom: 25}}>
                    <CardBody>
                        <Col xs="12">
                            <br />
                            <div>{this.state.name}</div>
                            <Input
                                type="text"
                                name="value"
                                value={this.state.value}
                                onChange={(field) => {
                                    let value = field.target.value
                                    this.setState({value})
                                }}
                            />
                        </Col>
                        <Col xs="12">
                            <br />
                            <label>Time Start</label>
                            <Datetime
                                value={this.state.timestart}
                                onChange={(start) => {
                                    this.setState({timestart: start}, () => {
                                        this.fetchRows()
                                    })
                                }}
                            />
                        </Col>
                        <Col xs="12">
                            <br />
                            <label>Time End</label>
                            <Datetime
                                value={this.state.timeend}
                                onChange={(end) => {
                                    this.setState({timeend: end}, () => {
                                        this.fetchRows()
                                    })
                                }}
                            />
                        </Col>
                        <Table style={{marginTop: 25, marginBottom: 25}}>
                            <tr>
                                <th>Start Time</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                            {this.state.rows.map((row, idx) => (
                                <tr key={idx}>
                                    <td>
                                        {moment(row.timeStart).format('LTS')}
                                    </td>
                                    <td>{row.value}</td>
                                    <td>
                                        <i
                                            className="simple-icon-trash"
                                            style={{
                                                cursor: 'pointer',
                                                color: 'rgba(255,100,100,1)',
                                            }}
                                            onClick={() => this.delete(row)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <Button
                            disabled={this.state.deviceId ? '' : 'disabled'}
                            onClick={() => {
                                this.save()
                            }}>
                            Save
                        </Button>
                        <div style={{marginTop: 25}}>
                            {this.state.alertComponent}
                        </div>
                    </CardFooter>
                </Card>
            </Fragment>
        )
    }
}
