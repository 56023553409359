import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Badge} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import {Colxx, Separator} from 'components/CustomBootstrap'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddGatewayModal from './add'
import RemoveModal from './remove'
import ActionsModal from './actions'
import SideModal from './sideModal'

const STATUS_COLORS = {
    opened: 'primary',
    closed: 'secondary',
    started: 'success',
    stopped: 'danger',
    paused: 'warning',
    reset: 'info',
}

const makeBadge = (status) => {
    const color = STATUS_COLORS[status],
        statusString = status
            ? status.charAt(0).toUpperCase() + status.slice(1)
            : 'N/A'
    return <Badge color={STATUS_COLORS[status]}>{statusString}</Badge>
}

const columns = [
    {
        Header: 'Nickname',
        accessor: 'alias',
    },
    {
        Header: 'MAC',
        accessor: 'nodeId',
    },
    {
        Header: 'IP Address',
        accessor: 'ipAddress',
    },
    {
        Header: 'Last Heartbeat',
        accessor: 'lastHeartbeat',
    },
    {
        Header: 'Last Data Push',
        accessor: 'lastPush',
    },
    {
        Header: 'Status',
        accessor: 'action',
    },
]

export default class GatewayManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'GatewayManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            nodes: [],
            editingNode: null,
            addGatewayModal: false,
        }
    }
    async fetchNodes() {
        let nodes = await API.get('nodes', 2)
        nodes = nodes.filter(
            (node) => node.nodeType === 'GWY' && node.nodeComm === 'TCP'
        )
        this.setState({nodes})
    }

    addGateway() {
        this.setState({
            editingNode: null,
            sideModal: !this.state.sideModal,
        })
    }
    toggleSideModal() {
        this.setState({
            sideModal: !this.state.sideModal,
        })
    }
    toggleModal(modalName, node) {
        let state = this.state
        state.editingNode = node
        state[modalName] = !state[modalName]
        this.setState({state})
    }
    actions() {
        return [
            {
                columnSize: 12,
                onClickFn: (node) => this.toggleModal('actionsModal', node),
                actionName: 'Global Actions',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (node) => this.toggleModal('removeModal', node),
                actionName: 'Remove Gateway',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(node) {
        node.lastPush =
            moment(node.lastPush).unix() === 0
                ? 'Never'
                : moment(node.lastPush).fromNow()
        node.lastHeartbeat =
            moment(node.lastHeartbeat).unix() === 0
                ? 'Never'
                : moment(node.lastHeartbeat).fromNow()
        //node.action = node.settings.globalAction === '' ? 'None' : node.settings.globalAction.charAt(0).toUpperCase() + node.settings.globalAction.slice(1);
        node.action = makeBadge(node.status)
        return node
    }

    editorModalAttributes(node) {
        return [['MAC', node.nodeId]]
    }

    componentWillMount() {
        if (localStorage['migratedToEdge'] == '0') {
            this.subscriber.add(
                this.fetchNodes,
                1000,
                'DigitalTwin_Gateways_fetchNodes'
            )
        }
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstGatewayEl = (
                <Button color="primary" onClick={this.addGateway}>
                    Add Your First Gateway
                </Button>
            ),
            tableExists = this.state.nodes.length > 0

        return (
            <Fragment>
                {localStorage['migratedToEdge'] == '1' ? (
                    <div className="text-center">
                        <h2 className="text-center">
                            Your organization has been upgraded to Accumine Edge
                            - our new machine connectivity suite.
                        </h2>
                        <Button
                            className="text-center"
                            color="primary"
                            onClick={() => {
                                window.open(
                                    `${process.env.REACT_APP_EDGE_URL}?token=${
                                        JSON.parse(localStorage['userObject'])
                                            .token
                                    }`,
                                    '__blank'
                                )
                            }}>
                            Open Accumine Edge
                        </Button>
                    </div>
                ) : (
                    <>
                        {tableExists ? (
                            <Row>
                                <Colxx
                                    xs="12"
                                    className="mb-2"
                                    style={{textAlign: 'right'}}>
                                    <Button
                                        color="primary"
                                        onClick={this.addGateway}>
                                        Add Gateway
                                    </Button>
                                </Colxx>
                            </Row>
                        ) : null}
                        <CRUD
                            uniqueElementId="nodeId"
                            emptyCrudMainText="No Gateways Found"
                            emptyCrudSubText={addFirstGatewayEl}
                            crudTableColumns={columns}
                            crudTableResolveDataMapFn={this.dataTableFormatter}
                            crudTableData={this.state.nodes}
                            rowClickFn={(node) => {
                                this.setState({editingNode: node})
                                this.toggleSideModal(node)
                            }}
                        />
                        {this.state.addGatewayModal ? (
                            <AddGatewayModal
                                toggleModal={this.toggleAddGatewayModal}
                                modal={this.state.addGatewayModal}
                                nodes={this.state.nodes}
                            />
                        ) : null}
                        {this.state.removeModal ? (
                            <RemoveModal
                                toggleModal={this.toggleModal}
                                modal={this.state.removeModal}
                                nodes={this.state.nodes}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}
                        {this.state.actionsModal ? (
                            <ActionsModal
                                toggleModal={this.toggleModal}
                                modal={this.state.actionsModal}
                                nodes={this.state.nodes}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}
                        {this.state.sideModal ? (
                            <SideModal
                                toggleModal={this.toggleSideModal}
                                modal={this.state.sideModal}
                                nodes={this.state.nodes}
                                editingNode={this.state.editingNode}
                                sync={async () => {
                                    await this.fetchNodes()
                                    this.setState({
                                        editingNode: this.state.nodes.find(
                                            (node) =>
                                                node._id ===
                                                this.state.editingNode._id
                                        ),
                                    })
                                }}
                            />
                        ) : null}
                    </>
                )}
            </Fragment>
        )
    }
}
