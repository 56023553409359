const R = require('ramda')
const Moment = require('moment')
const Axios = require('axios')

const BaseTransform = require('./base-transform').default

/** @class
 * Cleans the output object and passes it to a route
 * @param {Number} name - Name of the output items
 * @param {Number} url - The url to push values to
 * @param {Number} throttle - The URL to push alerts to
 */
class Output extends BaseTransform {
    constructor(id, inputs, params = {a: '', b: '', c: ''}) {
        super()
        this._id = id
        this._name = params.a
        this._url = params.b
        this._throttle = params.c
        this._pool = []
        this._lastTimestamp = -1

        this._request = null
        this._retry = true

        let consecutiveEvents = 0
        let numberToNextAlert = 6
        this._throttlingAlert = setInterval(() => {
            if (this._pool.length >= 25) {
                consecutiveEvents++
            } else {
                consecutiveEvents = 0
                numberToNextAlert = 6
            }

            if (consecutiveEvents >= numberToNextAlert) {
                Axios.post(
                    this._throttle,
                    {
                        transformId: this._id,
                        transformName: this._name,
                        bufferSize: this._pool.length,
                    },
                    {
                        timeout: 5000,
                    }
                ).catch((error) => {
                    this.emit('error', error)
                })
                numberToNextAlert = Math.min(numberToNextAlert * 2, 360)
            }
        }, 5000)
        setTimeout(() => {
            this._outputStream = setInterval(() => {
                if (!this._request && this._pool.length !== 0) {
                    console.log(this._id + ' buffer size: ' + this._pool.length)

                    let length = Math.min(this._pool.length, 500)
                    this._request = Axios.post(
                        this._url,
                        this._pool.slice(0, length),
                        {
                            timeout: 4000,
                        }
                    )
                        .then(() => {
                            this._pool = this._pool.slice(length)
                            this._request = null
                        })
                        .catch((error) => {
                            this.emit('error', error)
                            this._request = null
                        })
                }
            }, 5000)
        }, Math.random() * 5000)
    }
    _transform(chunk, callback) {
        chunk = R.omit(['id', '_processed'], chunk)

        if (this._lastTimestamp > chunk.timestamp) {
            this.emit(
                'error',
                'Timestamp ' +
                    chunk.timestamp +
                    ' before the current processed time ' +
                    this._lastTimestamp +
                    ', discarding.'
            )
            this.emit('error', chunk)
            return callback()
        } else {
            this._lastTimestamp = chunk.timestamp
        }

        chunk.name = this._name
        chunk.timestamp = Moment.utc(chunk.timestamp).toISOString()
        this._pool.push(chunk)
        callback()
    }
    destroy() {
        super.destroy()
        this._retry = false
        if (this._request && this._request.cancel) {
            this._request.cancel()
        }
        if (this._outputStream) {
            clearInterval(this._outputStream)
        }
        if (this._throttlingAlert) {
            clearInterval(this._throttlingAlert)
        }
    }
    toJSON() {
        let stringify = {}

        stringify._id = this._id
        stringify._name = this._name
        stringify._url = this._url
        stringify._pool = this._pool
        stringify._throttle = this._throttle
        stringify._lastTimestamp = this._lastTimestamp

        return stringify
    }
    static fromJSON(obj) {
        let trans = new Output('', [])

        if (obj._id) {
            trans._id = obj._id
        }
        if (obj._name) {
            trans._name = obj._name
        }
        if (obj._url) {
            trans._url = obj._url
        }
        if (obj._pool) {
            trans._pool = obj._pool
        }
        if (obj._throttle) {
            trans._throttle = obj._throttle
        }
        if (obj._lastTimestamp) {
            trans._lastTimestamp = obj._lastTimestamp
        }
        return trans
    }
}

export default Output
