import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    CardHeader,
    CardFooter,
} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import DataTablePagination from 'components/DataTables/pagination'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import './css/print.css'
import ReactToPrint from 'react-to-print'
import {CSVLink} from 'react-csv'

import Subscriber from 'SDK/subscriber'
import Queries from './queries'

export default class CustomTable extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            deviceIds: this.props.deviceIds || [],
            shifts: [],
            operators: [],
            data: [],
            dates: [],
            start: moment().subtract(1, 'weeks').startOf('day'),
            end: moment().startOf('day'),
            columns: [],
            index: this.props.table ? this.props.table.index.value : '',
            keys: {
                assets: [],
                shifts: [],
            },
            table_definition: [],
            queries: [],
            pageLimit: 24,
            csvData: [],
            assets: [],
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.startTime !== this.props.startTime ||
            prevProps.endTime !== this.props.endTime
        ) {
            this.setState(
                {
                    start: this.props.startTime,
                    end: this.props.endTime,
                    columns: [],
                    data: [],
                    dates: [],
                },
                () => {
                    this.componentDidMount()
                }
            )
        }
    }

    getColumns(tableColumns = [], tableIndex = this.state.index) {
        var column_definitions = [
            {
                Header: 'period_start',
                accessor: 'period_start',
            },
            {
                Header: 'period_end',
                accessor: 'period_end',
            },
        ]

        if (tableIndex == 'assets') {
            column_definitions.push(
                {
                    Header: tableIndex,
                    accessor: tableIndex,
                },
                {
                    Header: 'Name',
                    accessor: 'Name',
                }
            )
        }

        if (tableIndex == 'shifts') {
            column_definitions.push(
                {
                    Header: 'Shifts',
                    accessor: 'shifts',
                },
                {
                    Header: 'Assets',
                    accessor: 'assets',
                },
                {
                    Header: 'Name',
                    accessor: 'Name',
                }
            )
        }

        if (tableIndex == 'cell') {
            // TODO empty block
        }

        let fixedCols = column_definitions.length
        let tableData = []
        tableColumns.forEach((tc) => {
            tableData.push(...tc.data)
        })
        tableColumns.forEach((col, tabi) => {
            col.data.forEach((c, i) => {
                column_definitions.push({
                    Header: c,
                    accessor: c,
                    Cell: (props) => {
                        let results = this.customLogicOperations(
                            props.row,
                            props.value,
                            tableData.indexOf(c),
                            fixedCols,
                            props
                        )
                        props.styles['backgroundColor'] =
                            results.style.backgroundColor
                        return results.value
                    },
                    ...col,
                })
                //colIndex++
            })
        })

        return column_definitions
    }

    parseTarget(def) {
        try {
            return def.split(' ')
        } catch (err) {
            console.error(err, 'Invalid target', def)
        }
    }

    parseFormula(def) {
        try {
            return def.split(' ')
        } catch (err) {
            console.error(err, 'Invalid formula', def)
        }
    }

    getCellValue(datapoint, row, colIndex) {
        if (!isNaN(datapoint)) {
            return datapoint
        }
        if (datapoint.toUpperCase().indexOf('COL') != -1) {
            let colName = this.state.columns[colIndex].accessor
            return row[colName]
        }
        if (!/=\+-\/\*></.test(datapoint)) {
            return datapoint
        }
        return NaN
    }

    customLogicOperations(row, value, index, fixedCols) {
        const logic_definitions = this.state.logic
        const COLOR_BROKEN_FORMULA = 'rgba(100,100,200,1)'
        let returnObject = {
            style: {backgroundColor: 'transparent'},
            value: value,
        }

        logic_definitions.forEach((logic) => {
            switch (logic.operation) {
                case 'FORMULA': {
                    let formula = this.parseFormula(logic.definition)
                    let sourceIndex = formula[0].slice(3)
                    if (sourceIndex == index) {
                        let colName =
                            this.state.columns[
                                parseInt(formula[0].slice(3)) + fixedCols
                            ].accessor
                        let cellValues = formula.map((f) =>
                            this.getCellValue(
                                f,
                                row,
                                parseInt(formula[0].slice(3)) + fixedCols
                            )
                        )
                        let formulaString = cellValues.slice(2).join(' ')
                        try {
                            let result = eval(formulaString)
                            returnObject.value = result
                        } catch (err) {
                            console.error(
                                'Data invalid to apply formula',
                                formulaString
                            )
                            returnObject.backgroundColor = COLOR_BROKEN_FORMULA
                        }
                    }
                    break
                }
                case 'TARGET': {
                    let target = this.parseTarget(logic.definition)
                    sourceIndex = target[0].slice(3)
                    if (sourceIndex == index) {
                        let colName =
                            this.state.columns[
                                parseInt(target[0].slice(3)) + fixedCols
                            ].accessor
                        let cellValues = target.map((f) =>
                            this.getCellValue(
                                f,
                                row,
                                parseInt(target[0].slice(3)) + fixedCols
                            )
                        )
                        let targetString = cellValues.join(' ')
                        try {
                            if (returnObject.value && eval(targetString)) {
                                returnObject.style.backgroundColor =
                                    'rgba(100,200,100,1)'
                            } else {
                                returnObject.style.backgroundColor =
                                    'rgba(200,100,100,1)'
                            }
                        } catch (err) {
                            console.error(
                                'Data invalid to apply target',
                                targetString
                            )
                            returnObject.style.backgroundColor =
                                COLOR_BROKEN_FORMULA
                        }
                    }
                    break
                }
            }
        })

        return returnObject
    }

    getIndexKeys(index = this.state.index) {
        switch (index) {
            case 'operators':
                return this.state.operators.map((o) => o.value)
            case 'assets':
                return this.state.deviceIds
            case 'shifts':
                return this.state.shifts.map((s) => s.name)
            default:
                return this.state.deviceIds
        }
    }

    getDates(
        timeStart = this.state.start,
        timeEnd = this.state.end,
        date = moment(this.state.start),
        arrayOfDates = [],
        range = this.state.range ? this.state.range : 'day'
    ) {
        if (this.state.index === 'assets' || this.state.index === 'cell') {
            while (date.unix() < timeEnd.unix()) {
                let dateRange = {
                    period_start: moment(date).toISOString(),
                    period_end: null,
                }
                date.add(1, range)
                dateRange.period_end = moment(date).toISOString()
                arrayOfDates.push(dateRange)
            }
        } else if (this.state.index === 'shifts') {
            while (date.unix() < timeEnd.unix()) {
                let shifts = [...this.state.shifts]
                shifts.forEach((shift) => {
                    let period_start = moment(date)
                        .hours(shift.timeStart.hour)
                        .minutes(shift.timeStart.minute)
                    let period_end = moment(date)
                        .hours(shift.timeEnd.hour)
                        .minutes(shift.timeEnd.minute)
                    if (period_end < period_start) period_end.add(1, 'days')
                    arrayOfDates.push({
                        period_start: period_start.toISOString(),
                        period_end: period_end.toISOString(),
                        shift: shift.name,
                    })
                })
                date.add(1, range)
            }
        } else if (this.state.index === 'Operators') {
            while (date.unix() < timeEnd.unix()) {
                let operators = [...this.state.operators]
                operators.forEach((operator) => {
                    arrayOfDates.push({
                        period_start: operator.timeStart,
                        period_end: operator.timeEnd,
                        Operator: operator.value,
                    })
                })
                date.add(1, range)
            }
        }

        return arrayOfDates
    }

    async addSyncToData(
        data = [...this.state.data],
        table_definition = [...this.state.table_definition]
    ) {
        let promises = []
        let columnDictionary = []
        let queries = []

        table_definition.columns.forEach((columnSet, columnIndex) => {
            this.state.dates.forEach((date) => {
                columnDictionary.push({
                    index: this.state.index,
                    columnIndex: columnIndex,
                    type: columnSet.type,
                    unit: columnSet.selectedUnit,
                    date: date,
                    states: columnSet.data,
                    property: columnSet.property,
                })
                if (columnSet.type === 'States') {
                    if (columnSet.selectedUnit === 'Duration') {
                        promises.push(
                            this.setStateDurationData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end)
                            )
                        )
                    } else if (columnSet.selectedUnit === 'Count') {
                        promises.push(
                            this.setStateCountData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end)
                            )
                        )
                    } else if (columnSet.selectedUnit === 'Values') {
                        promises.push(
                            this.setStateValuesData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end)
                            )
                        )
                    } else if (columnSet.selectedUnit === 'Property') {
                        promises.push(
                            this.setStatePropertyData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end),
                                [columnSet.property]
                            )
                        )
                    }
                } else if (columnSet.type === 'Reason Codes') {
                    if (columnSet.selectedUnit === 'Duration') {
                        promises.push(
                            this.setReasonCodeDurationData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end)
                            )
                        )
                    } else if (columnSet.selectedUnit === 'Count') {
                        promises.push(
                            this.setReasonCodeCountData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end)
                            )
                        )
                    } else if (columnSet.selectedUnit === 'Values') {
                        promises.push(
                            this.setReasonCodeValuesData(
                                columnSet.data,
                                this.state.deviceIds,
                                moment(date.period_start),
                                moment(date.period_end)
                            )
                        )
                    }
                }
            })
        })

        queries = await Promise.all(promises)

        // merge the promise result and the column dictionary
        queries = queries.map((query, i) => {
            return {query, ...columnDictionary[i]}
        })

        this.setState(
            {
                queries,
            },
            () => {
                this.addIndexesToQueries(queries, this.state.keys)
            }
        )
    }

    addIndexesToQueries(
        queries = [...this.state.queries],
        keys = this.state.keys
    ) {
        queries = queries.map((q) => {
            return {...q, ...keys}
        })

        this.setState(
            {
                queries,
            },
            () => {
                this.addAccessorsToData([], queries)
            }
        )
    }

    getDeviceName(deviceId = '') {
        let name = 'N/A'

        this.state.assets.forEach((asset) => {
            if (asset.deviceId === deviceId) name = asset.name
        })

        return name
    }

    getIndexKeyName(tableIndex = this.state.index, key = '') {
        if (tableIndex === 'assets') {
            return this.getDeviceName(key)
        } else {
            return 'N/A'
        }
    }

    addAccessorsToData(data = [], queries = this.state.queries) {
        if (this.state.index == 'assets') {
            queries.forEach((query) => {
                query['assets'].forEach((key) => {
                    if (
                        !data.find(
                            (d) =>
                                d.period_start === query.date.period_start &&
                                d.period_end === query.date.period_end &&
                                d[query.index] === key
                        )
                    ) {
                        let columns = this.findDataForQuery(query, key)
                        let keyName = this.getIndexKeyName('assets', key)
                        data.push({
                            period_start: query.date.period_start,
                            period_end: query.date.period_end,
                            [query.index]: key,
                            Name: keyName,
                            ...columns,
                        })
                    } else {
                        data.forEach((d, i) => {
                            let match =
                                d.period_start === query.date.period_start &&
                                d.period_end === query.date.period_end &&
                                d[query.index] === key
                            if (match) {
                                let columns = this.findDataForQuery(query, key)
                                data[i] = {...d, ...columns}
                            }
                        })
                    }
                })
            })
        } else if (this.state.index == 'cell') {
            queries.forEach((query) => {
                if (
                    !data.find(
                        (d) =>
                            d.period_start === query.date.period_start &&
                            d.period_end === query.date.period_end
                    )
                ) {
                    let columns = this.findDataForQuery(query, query['assets'])
                    // let keyName = this.getIndexKeyName('assets', key);
                    data.push({
                        period_start: query.date.period_start,
                        period_end: query.date.period_end,
                        ...columns,
                    })
                } else {
                    data.forEach((d, i) => {
                        let match =
                            d.period_start === query.date.period_start &&
                            d.period_end === query.date.period_end
                        if (match) {
                            let columns = this.findDataForQuery(
                                query,
                                query['assets']
                            )
                            data[i] = {...d, ...columns}
                        }
                    })
                }
            })
        } else if (this.state.index === 'shifts') {
            queries.forEach((query) => {
                query['assets'].forEach((key) => {
                    if (
                        !data.find(
                            (d) =>
                                d.period_start === query.date.period_start &&
                                d.period_end === query.date.period_end &&
                                d['assets'] === key
                        )
                    ) {
                        // add new row and initial columns
                        let columns = this.findDataForQuery(query, key)
                        let keyName = this.getIndexKeyName('assets', key)
                        data.push({
                            period_start: query.date.period_start,
                            period_end: query.date.period_end,
                            assets: key,
                            Name: keyName,
                            ...columns,
                            shifts: query.date.shift,
                        })
                    } else {
                        // add additional columns to matching data
                        data.forEach((d, i) => {
                            let match =
                                d.period_start === query.date.period_start &&
                                d.period_end === query.date.period_end &&
                                d['assets'] === key
                            if (match) {
                                let columns = this.findDataForQuery(query, key)
                                data[i] = {...d, ...columns}
                            }
                        })
                    }
                })
            })
        }

        this.setState(
            {
                data: data.map((d) => {
                    d.period_start = moment(d.period_start).format(
                        'YYYY-MM-DD HH:mm:ss'
                    )
                    d.period_end = moment(d.period_end).format(
                        'YYYY-MM-DD HH:mm:ss'
                    )
                    return d
                }),
            },
            () => {
                this.prepareCSVfromReactTable()
            }
        )
    }

    async componentDidMount() {
        if (this.props.table) {
            const assets = await API.get('devices')
            const shifts = await API.get('shifts')
            const operators = await Queries.statesByLiteral(
                this.state.deviceIds,
                this.state.start,
                this.state.end,
                ['Operator Sign In']
            )

            this.setState(
                {
                    assets,
                    operators,
                    shifts,
                    index: this.props.table.index.value,
                    range: this.props.table.range
                        ? this.props.table.range.value
                        : null,
                },
                () => {
                    this.setState(
                        {
                            logic: this.props.table.logic || [],
                            columns: this.getColumns(
                                this.props.table.columns,
                                this.props.table.index.value
                            ),
                            keys: {
                                shifts: this.getIndexKeys('shifts'),
                                assets: this.getIndexKeys('assets'),
                            },
                            dates: this.getDates(
                                this.state.start,
                                this.state.end
                            ),
                            table_definition: this.props.table,
                            data: [],
                            assets,
                        },
                        () => {
                            var dataSynced = this.addSyncToData(
                                [],
                                this.state.table_definition,
                                this.state.queries
                            )
                        }
                    )
                }
            )
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    setStateDurationData(
        listOfStates = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.statesByDuration(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfStates,
            'total',
            'duration'
        )

        return query
    }

    setStateCountData(
        listOfStates = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.statesByDuration(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfStates,
            'total',
            'count'
        )

        return query
    }

    setStateValuesData(
        listOfCodes = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.statesByValue(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfCodes
        )

        return query
    }

    setStatePropertyData(
        listOfCodes = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.statesByProperty(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfCodes
        )

        return query
    }

    setReasonCodeDurationData(
        listOfCodes = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.reasonCodesByDuration(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfCodes
        )

        return query
    }

    setReasonCodeCountData(
        listOfCodes = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.reasonCodesByCount(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfCodes
        )

        return query
    }

    setReasonCodeValuesData(
        listOfCodes = [],
        listOfDeviceIds = [...this.state.deviceIds],
        customStartTime = moment(this.state.timeStart),
        customEndTime = moment(this.state.timeEnd)
    ) {
        const query = Queries.reasonCodesByValue(
            listOfDeviceIds,
            customStartTime,
            customEndTime,
            listOfCodes
        )

        return query
    }

    fetchReasonCodeDuration(query = {}, deviceId = null, codeName = null) {
        if (!query.length) {
            return 0
        } else {
            let sum = 0
            let results = []
            query.forEach((q) => {
                if (q.value === codeName && q.deviceId === deviceId) {
                    results.push(q)
                }
            })
            if (results.length > 0) {
                results.forEach((r) => {
                    sum += moment(r.timeEnd).unix() - moment(r.timeStart).unix()
                })
            }

            return sum / 60 // Notes: return value = sum of unix / 60 = minutes
        }
    }

    fetchReasonCodeCount(query = {}, deviceId = null, codeName = null) {
        if (!query.length) {
            return 0
        } else {
            let results = []
            query.forEach((q) => {
                if (q.value === codeName && q.deviceId === deviceId) {
                    results.push(q)
                }
            })
            return results.length
        }
    }

    fetchReasonCodeValues(query = {}, deviceId = null, codeName = null) {
        return ''
    }

    fetchStateDuration(query = {}, deviceId = [], stateName = null) {
        let sum = 0
        if (query === false || !query.data.devices[deviceId][stateName]) {
            return 0
        } else {
            sum = query.data.devices[deviceId][stateName]
            return sum / 1000 / 60
        }
    }

    fetchStateCount(query = {}, deviceId = [], stateName = null) {
        try {
            if (query && query.data.devices[deviceId][stateName][0].count) {
                return query.data.devices[deviceId][stateName][0].count
            } else {
                return 0
            }
        } catch (err) {
            return 0
        }
    }

    fetchStateValues(query = {}, deviceId = null, stateName = null) {
        let result = query.filter(
            (q) => q.deviceId === deviceId && stateName === q.name
        )
        if (Array.isArray(result) && result.length > 0) {
            return result.map((r) => r.value)
        } else {
            return ''
        }
    }

    fetchStateProperty(
        query = {},
        deviceId = null,
        stateName = null,
        property = null
    ) {
        let result = query.filter(
            (q) => q.deviceId === deviceId && stateName === q.name
        )
        let arrayOfNums = [0]
        if (Array.isArray(result) && result.length > 0) {
            result.forEach((r) => {
                let num = parseInt(r[property])
                if (!isNaN(num) && num) arrayOfNums.push(num)
            })
        }

        return arrayOfNums
    }

    findDataForQuery(query = {}, deviceId = '') {
        let columns = {}

        if (Array.isArray(deviceId)) {
            query.states.forEach((stateName) => {
                query.unit === 'Values'
                    ? (columns[stateName] = '')
                    : (columns[stateName] = 0)
                if (query.type === 'Reason Codes' && query.unit === 'Count') {
                    deviceId.forEach((id) => {
                        columns[stateName] += this.fetchReasonCodeCount(
                            query.query,
                            id,
                            stateName
                        )
                    })
                } else if (
                    query.type === 'Reason Codes' &&
                    query.unit === 'Duration'
                ) {
                    deviceId.forEach((id) => {
                        columns[stateName] += this.fetchReasonCodeDuration(
                            query.query,
                            id,
                            stateName
                        )
                    })
                } else if (
                    query.type === 'Reason Codes' &&
                    query.unit === 'Values'
                ) {
                    deviceId.forEach((id) => {
                        let value = this.fetchReasonCodeValues(
                            query.query,
                            id,
                            stateName
                        )
                        if (value) {
                            columns[stateName].concat(`, ${value}`)
                        }
                    })
                } else if (query.type === 'States' && query.unit === 'Count') {
                    deviceId.forEach((id) => {
                        columns[stateName] += this.fetchStateCount(
                            query.query,
                            id,
                            stateName
                        )
                    })
                } else if (
                    query.type === 'States' &&
                    query.unit === 'Duration'
                ) {
                    deviceId.forEach((id) => {
                        columns[stateName] += this.fetchStateDuration(
                            query.query,
                            id,
                            stateName
                        )
                    })
                } else if (query.type === 'States' && query.unit === 'Values') {
                    let allValues = []
                    deviceId.forEach((id) => {
                        let value = this.fetchStateValues(
                            query.query,
                            id,
                            stateName
                        )
                        if (value) {
                            allValues.push(value)
                        }
                    })
                    columns[stateName] = allValues.join(', ')
                } else if (
                    query.type === 'States' &&
                    query.unit === 'Property'
                ) {
                    deviceId.forEach((id) => {
                        let total = 0
                        let results = this.fetchStateProperty(
                            query.query,
                            id,
                            stateName,
                            query.property
                        )
                        Array.isArray(results)
                            ? results.forEach((val) => (total += val))
                            : (total = 0)
                        columns[stateName] += total
                    })
                }
            })
        } else {
            query.states.forEach((stateName) => {
                query.unit === 'Values'
                    ? (columns[stateName] = '')
                    : (columns[stateName] = 0)
                if (query.type === 'Reason Codes' && query.unit === 'Count') {
                    columns[stateName] = this.fetchReasonCodeCount(
                        query.query,
                        deviceId,
                        stateName
                    )
                } else if (
                    query.type === 'Reason Codes' &&
                    query.unit === 'Duration'
                ) {
                    columns[stateName] = this.fetchReasonCodeDuration(
                        query.query,
                        deviceId,
                        stateName
                    )
                } else if (
                    query.type === 'Reason Codes' &&
                    query.unit === 'Values'
                ) {
                    let value = this.fetchReasonCodeValues(
                        query.query,
                        deviceId,
                        stateName
                    )
                    if (value) {
                        columns[stateName]
                            ? (columns[stateName] = columns[stateName].concat(
                                  `, ${value}`
                              ))
                            : ' '
                    }
                } else if (query.type === 'States' && query.unit === 'Count') {
                    columns[stateName] = this.fetchStateCount(
                        query.query,
                        deviceId,
                        stateName
                    )
                } else if (
                    query.type === 'States' &&
                    query.unit === 'Duration'
                ) {
                    columns[stateName] = this.fetchStateDuration(
                        query.query,
                        deviceId,
                        stateName
                    )
                } else if (query.type === 'States' && query.unit === 'Values') {
                    let value = this.fetchStateValues(
                        query.query,
                        deviceId,
                        stateName
                    )
                    if (value) {
                        columns[stateName] = value.join(', ')
                    }
                } else if (
                    query.type === 'States' &&
                    query.unit === 'Property'
                ) {
                    let total = 0
                    let results = this.fetchStateProperty(
                        query.query,
                        deviceId,
                        stateName,
                        query.property
                    )
                    Array.isArray(results)
                        ? results.forEach((val) => (total += val))
                        : (total = 0)
                    columns[stateName] = total
                }
            })
        }

        return columns
    }

    prepareCSVfromReactTable(
        reactTableColumns = [...this.state.columns],
        reactTableData = [...this.state.data]
    ) {
        let columns = reactTableColumns.map((col) => col.accessor)
        let data = []
        reactTableData.map((row) => {
            let rowCSV = []
            columns.forEach((col) => {
                rowCSV.push(row[col])
            })
            data.push(rowCSV)
        })

        this.setState({csvData: [[...columns], ...data]})
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <ReactToPrint
                        trigger={() => {
                            return <Button>Print this out!</Button>
                        }}
                        content={() => this.componentRef}
                    />{' '}
                    <CSVLink
                        data={this.state.csvData}
                        filename={
                            'Accumine-Report-' +
                            this.state.start.format('MM-DD-YYYY')
                        }
                        className="btn btn-primary"
                        target="_blank">
                        Download Excel (CSV)
                    </CSVLink>
                    <div ref={(el) => (this.componentRef = el)}>
                        <ReactTable
                            defaultPageSize={this.state.pageLimit}
                            data={this.state.data}
                            columns={this.state.columns}
                            minRows={0}
                            PaginationComponent={DataTablePagination}
                            loading={false}
                            filterable={false}
                        />
                    </div>
                </CardBody>
            </Card>
        )
    }
}
