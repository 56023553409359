import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import Switch from 'rc-switch'
import autobind from 'auto-bind'
import Creatable from 'react-select/creatable'
import {CompactPicker} from 'react-color'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let selectedAssets = []
        if (this.props.editingCode.assets) {
            selectedAssets = this.props.editingCode.assets.map((a) => {
                const asset = this.props.devices.find(
                    (ass) => ass.deviceId === a
                )
                if (asset) {
                    return {
                        label: asset.name,
                        value: asset.deviceId,
                    }
                } else {
                    return null
                }
            })
        }

        selectedAssets = selectedAssets.filter((a) => a !== null)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            reasoncode: this.props.editingCode.reason,
            category: {
                label: this.props.editingCode.category,
                value: this.props.editingCode.category,
            },
            selectedAssets: selectedAssets,
            offsetCapacity: this.props.editingCode.offsetCapacity === 'Yes',
            valid: true,
            message: false,
            color: this.props.editingCode.color
                ? this.props.editingCode.color
                : '#f0ad4e',
        }
    }

    handleChangeComplete(color) {
        this.setState({color: color.hex})
    }

    handleCategoryEvent(category) {
        if (
            this.props.reasoncodes.find(
                (code) =>
                    code._id !== this.props.editingCode._id &&
                    code.reason === this.state.reasoncode
            )
        ) {
            // reason code already exists
            this.setState({
                category: category,
                inputBorderColor: '#dc3545',
                valid: false,
                message: 'This reason code already exists.',
            })
        } else {
            this.setState({
                category: category,
                inputBorderColor: 'rgb(215,215,215)',
                valid: true,
                message: '',
            })
        }
    }
    async save() {
        const obj = {
            _id: this.props.editingCode._id,
            reason: this.state.reasoncode,
            category: this.state.category.value,
            offsetCapacity: this.state.offsetCapacity,
            assets: this.state.selectedAssets.map((a) => a.value),
            color: this.state.color,
        }

        const response = await API.patch('reasoncodes/' + obj._id, obj, 2)
        if (response) {
            this.props.toggleModal('editModal')
        } else {
            return alert(
                'There was an error editing your reason code. Please try again.'
            )
        }
    }

    render() {
        const {modal, toggleModal, reasoncodes, devices} = this.props

        let categories = reasoncodes.map((code) => code.category).flat()
        categories = [...new Set(categories)].map((category) => {
            return {label: category, value: category}
        })

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        this.props.toggleModal('edit')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            this.props.toggleModal('edit')
                        }}>
                        Edit Reason Code
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>
                                    Category (start typing to create new
                                    category):
                                </label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="category"
                                    value={this.state.category}
                                    onChange={this.handleCategoryEvent}
                                    options={categories}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Reason Code:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.reasoncode}
                                    onChange={(reasoncode) => {
                                        let v = reasoncode.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (this.state.category !== '') {
                                            // category has been selected
                                            const category =
                                                this.state.category.value
                                            if (
                                                this.props.reasoncodes.find(
                                                    (code) =>
                                                        code._id !==
                                                            this.props
                                                                .editingCode
                                                                ._id &&
                                                        code.reason === v
                                                )
                                            ) {
                                                // exact match exists
                                                color = '#dc3545'
                                                valid = false
                                                message =
                                                    'This reason code already exists.'
                                            }
                                        }
                                        this.setState({
                                            reasoncode: v,
                                            valid: valid,
                                            inputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Applies to:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isMulti
                                    name="assets"
                                    value={this.state.selectedAssets}
                                    onChange={(selectedAssets) =>
                                        this.setState({selectedAssets})
                                    }
                                    options={devices.map((d) => {
                                        return {
                                            label: d.name,
                                            value: d.deviceId,
                                        }
                                    })}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>
                                    Reduce Available Capacity When Active:
                                </label>
                                <Switch
                                    className="custom-switch custom-switch-primary"
                                    checked={this.state.offsetCapacity}
                                    onChange={(offsetCapacity) => {
                                        this.setState({offsetCapacity})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <div>
                                    <label>Choose Color Family</label>
                                </div>
                                <CompactPicker
                                    color={this.state.color}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.props.toggleModal('editModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
