import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {tileColor} from 'SDK/helpers'

import Timeline from './Timeline'
import CircularProgressBar from './CircularProgressBar'
import Timer from './Timer'
import * as SDK from './sdk'

import './index.css'

export default class RealTimeWorkOrderStatusBoard2 extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
        {
            prop: 'flash',
            component: 'RealTimeTileFlash',
        },
        {
            prop: 'aggregation',
            component: 'RealTimeAggregation',
        },
        {
            prop: 'black',
            component: 'BlackText',
        },
    ]
    requiredOutputs = ['Part Count', 'Work Order', 'In-Cycle', 'Downtime']
    showBorder = false
    id = 'RealTimeWorkOrderStatusBoard2'
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
        aggregation: PropTypes.number,
        flash: PropTypes.bool,
        black: PropTypes.bool,
    }
    static defaultProps = {
        black: false,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.height = document.documentElement.offsetHeight - 120 + 'px'
        this.rawHeight = document.documentElement.offsetHeight - 120
        this.headerHeight = Math.floor(this.rawHeight * 0.1) + 'px'
        this.bodyHeight = Math.floor(this.rawHeight * 0.75) + 'px'
        this.footerHeight = Math.floor(this.rawHeight * 0.17) + 'px'

        this.subscriber = new Subscriber()

        this.color = '#fff'

        if (this.props && this.props.black) {
            this.color = '#000'
        }

        this.state = {
            name: '',
            activeState: {
                color: 'rgb(35, 34, 35)',
                name: 'No data',
                timestamp: null,
                elapsed: '0:00',
            },
            activeShift: {
                name: 'Today',
                timeStart: moment().startOf('day'),
                timeEnd: moment(),
            },
            workOrder: {
                timestamp: null,
                name: 'Fetching Work Order...',
            },
            shifts: [],
            flash: false,
            ranOnce: false,
            timelineDateIndex: 0,
            timelineShiftIndex: 0,
            timelineTimeStart: moment().startOf('day'),
            timelineTimeEnd: null,
            timelineShiftName: 'Today',
            shiftOrders: 0,
        }
    }

    async getWorkOrder() {
        this.setState({
            workOrder: await SDK.getWorkOrder(this.props.deviceId),
        })
    }

    async getLiveStatus() {
        let {activeState, activeShift} = await SDK.getAssetStatus(
            this.props.deviceId,
            this.state.shifts,
            this.props.threshold
        )

        if (
            activeState.name === 'Downtime' &&
            moment().diff(activeState.timestamp, 'minutes') > 4
        ) {
            const downtimeReason = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Downtime Reason',
                        timeEnd: null,
                    },
                },
                2
            )

            if (downtimeReason.length) {
                activeState.name = downtimeReason[0].value
                activeState.color = tileColor(activeState.name)
            }
        }

        if (
            activeShift.name !== this.state.activeShift.name &&
            this.state.timelineDateIndex === 0
        ) {
            this.setState({activeState, activeShift}, this.currentShift)
        } else {
            this.setState({activeState, activeShift})
        }
    }

    updateDowntimeFlashStatus() {
        if (this.state.activeState.timestamp === null) return
        let activeState = {...this.state.activeState}
        let flash = false

        if (
            activeState.name !== 'In-Cycle' &&
            activeState.name !== 'Disconnected' &&
            moment().diff(moment(activeState.timestamp), 'minutes') >= 5
        ) {
            flash = true
        }
        this.setState({flash})
    }

    async fetchDeviceName() {
        this.setState({
            name: await SDK.getDeviceName(this.props.deviceId),
        })
    }

    async fetchShiftSchedule() {
        this.setState({
            shifts: await SDK.getShiftSchedule(this.props.deviceId),
        })
    }

    currentShift() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            activeShift,
            shifts,
            timelineShiftName,
        } = this.state
        timelineDateIndex = 0
        timelineTimeStart = activeShift.timeStart
        timelineTimeEnd = null
        timelineShiftIndex = shifts.findIndex(
            (shift) => shift.name === activeShift.name
        )
        timelineShiftName = activeShift.name

        this.setState({
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            timelineShiftName,
        })
    }

    backward() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            shifts,
            timelineShiftName,
        } = this.state

        if (timelineShiftIndex === 0) {
            timelineDateIndex--
            timelineShiftIndex = shifts.length - 1
        } else {
            timelineShiftIndex--
        }

        let shift = shifts[timelineShiftIndex],
            date = moment().add(timelineDateIndex, 'days'),
            shiftTimerange = SDK.getShiftTimerange(date, shift)

        this.setState({
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart: shiftTimerange[0],
            timelineTimeEnd: shiftTimerange[1],
            timelineShiftName: shift.name,
        })
    }

    forward() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            shifts,
            timelineShiftName,
        } = this.state

        if (timelineShiftIndex === shifts.length - 1) {
            timelineDateIndex++
            timelineShiftIndex = 0
        } else {
            timelineShiftIndex++
        }

        let shift = shifts[timelineShiftIndex],
            date = moment().add(timelineDateIndex, 'days'),
            shiftTimerange = SDK.getShiftTimerange(date, shift)

        this.setState({
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart: shiftTimerange[0],
            timelineTimeEnd: shiftTimerange[1].isAfter(moment())
                ? null
                : shiftTimerange[1],
            timelineShiftName: shift.name,
        })
    }

    updateUtilization(util) {
        let utilization = Math.floor(util * 100)
        this.setState({utilization})
    }

    async updateWorkOrderCount() {
        let shiftOrders = await SDK.getWorkOrderCount(
            this.props.deviceId,
            this.state.timelineTimeStart,
            this.state.timelineTimeEnd || moment()
        )
        this.setState({shiftOrders})
    }

    async componentWillMount() {
        await this.fetchDeviceName()
        await this.fetchShiftSchedule()
        await this.getLiveStatus()
        this.currentShift()
        this.setState({ranOnce: true})

        this.subscriber.add(this.getWorkOrder, 1000, 'getWorkOrder')
        this.subscriber.add(this.getLiveStatus, 1000, 'getLiveStatus')
        this.subscriber.add(
            this.updateWorkOrderCount,
            1000 * 60,
            'updateWorkOrderCount'
        )

        if (this.props.flash)
            this.subscriber.add(
                this.updateDowntimeFlashStatus,
                1000,
                'updateDowntimeFlashStatus'
            )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const workOrderExists = this.state.workOrder.name !== 'No Work Order',
            disableForward =
                this.state.timelineDateIndex === 0 &&
                this.state.timelineShiftName === this.state.activeShift.name,
            height = this.height, //document.documentElement.offsetHeight - 120 + 'px',
            rawHeight = this.rawHeight, //document.documentElement.offsetHeight - 120,
            timelineTitle =
                this.state.timelineTimeStart.format('ddd, MMM DD') +
                ' - ' +
                this.state.timelineShiftName,
            headerHeight = this.headerHeight, //Math.floor(rawHeight * 0.10) + 'px',
            bodyHeight = this.bodyHeight, //Math.floor(rawHeight * 0.75) + 'px',
            footerHeight = this.footerHeight //Math.floor(rawHeight * 0.17) + 'px'
        return (
            <>
                <div
                    className={this.state.flash ? 'flash' : ''}
                    style={{
                        background: this.state.activeState.color,
                        height: height,
                    }}>
                    <div
                        style={{
                            height: headerHeight,
                            fontSize: Math.floor(rawHeight * 0.07) + 'px',
                            textAlign: 'center',
                            color: this.color,
                            background: 'rgba(0,0,0,0.1)',
                            marginTop: '0px',
                            marginBottom: '0px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                        }}>
                        <strong>{this.state.name}</strong>
                    </div>
                    <div
                        style={{
                            padding: '0px',
                            margin: '0px',
                            height: bodyHeight,
                        }}>
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.035) + 'px',
                                }}>
                                Shift Utilization
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.07) + 'px',
                                }}>
                                <CircularProgressBar
                                    strokeWidth="12"
                                    sqSize={Math.floor(rawHeight * 0.275)}
                                    percentage={this.state.utilization}
                                    color={this.color}
                                />
                            </Col>
                        </Row>
                        <hr style={{marginTop: 0, marginBottom: 5}} />
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.035) + 'px',
                                }}>
                                Shift Orders
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.075) + 'px',
                                }}>
                                <strong>{this.state.shiftOrders}</strong>
                            </Col>
                        </Row>
                        <hr style={{marginTop: 0, marginBottom: 5}} />
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.035) + 'px',
                                }}>
                                {this.state.activeState.name} Timer
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.075) + 'px',
                                }}>
                                <strong>
                                    <Timer
                                        timestamp={
                                            this.state.activeState.timestamp
                                        }
                                    />
                                </strong>
                            </Col>
                        </Row>
                        <hr style={{marginTop: 0, marginBottom: 5}} />
                        <Row>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.035) + 'px',
                                }}>
                                Work Order
                            </Col>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.04) + 'px',
                                }}>
                                <strong>{this.state.workOrder.name}</strong>
                            </Col>
                            <Col
                                xs="12"
                                className="text-center"
                                style={{
                                    color: this.color,
                                    fontSize:
                                        Math.floor(rawHeight * 0.04) + 'px',
                                }}>
                                <strong>
                                    <Timer
                                        timestamp={
                                            this.state.workOrder.timestamp
                                        }
                                    />
                                </strong>
                            </Col>
                        </Row>
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            color: this.color,
                            backgroundColor: 'rgba(0,0,0,0.20)',
                            height: '0px',
                            fontSize: Math.floor(rawHeight * 0.035) + 'px',
                        }}>
                        {this.state.ranOnce ? (
                            <>
                                <Timeline
                                    hidden={true}
                                    deviceId={this.props.deviceId}
                                    showReasons={false}
                                    timeStart={this.state.timelineTimeStart}
                                    timeEnd={this.state.timelineTimeEnd}
                                    shiftName={this.state.timelineShiftName}
                                    updateUtilization={this.updateUtilization}
                                    aggregation={this.props.aggregation}
                                    color={this.color}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </>
        )
    }
}
