import React, {Component, Fragment} from 'react'
import 'react-table/react-table.css'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    Alert,
    Collapse,
    Table,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import {ThemeColors} from 'util/ThemeColors'
import regression from 'regression'
import Switch from 'rc-switch'

import OEEViewChart from './OEEViewChart'
import OEEViewTable from './OEEViewTable'
import OEETimeSeriesChart from './OEETimeSeriesChart'
import OEERealTimeChart from './OEERealTimeChart'
import OEEHistoricalChart from './OEEHistoricalChart'
import * as API from 'SDK/api'
import * as _ from 'lodash'

/* The colors obj is initialized in the component constructor to prevent a 
   potential race condition that causes the colors to be empty strings
*/
let colors = null

export default class OEEView extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'hideOEEBenchmarks',
            component: 'HideOEEBenchmarks',
        },
    ]
    requiredOutputs = [
        'In-Cycle',
        'Part Count',
        'Scrap',
        'Static Hourly Target',
    ]
    showBorder = false
    id = 'OEEView'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        colors = ThemeColors()

        this.state = {
            start: moment().startOf('isoWeek').add(-1, 'isoWeeks'),
            end: moment().add(-1, 'isoWeeks').endOf('isoWeek'),
            assets: [],
            selectedAssets: [],
            shifts: [],
            shiftRecords: [],
            data: [],
            loading: false,
            ready: false,
            selectedAsset: {
                label: 'All Assets',
                value: 'all',
            },
            selectedShift: {
                label: 'All Shifts',
                value: 'all',
            },
            selectedTimeUnit: {label: 'Days', value: 'day'},
            selectedFilter: {label: 'Show all data', value: 'all'},
        }
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }

    handleShiftChange(selectedShift) {
        this.setState({selectedShift})
    }

    async fetchAssets() {
        const assets = await API.get('devices')
        if (!assets) return alert('Could not fetch assets')

        this.setState({
            assets,
            selectedAssets: assets.map((a, i) => {
                return {label: a.name, value: a.deviceId, key: i}
            }),
        })
    }

    async fetchShifts() {
        let shiftRecords = await API.get('shifts')
        if (!shiftRecords) return alert('Could not fetch shifts')

        let shifts = shiftRecords.filter(function ({name}) {
            return !this[name] && (this[name] = name)
        }, {})

        this.setState({shifts, shiftRecords})
    }

    async generate(filter = null) {
        this.setState({
            loading: true,
            selectedAsset: {
                label: 'All Assets',
                value: 'all',
            },
        })
        let promises = [],
            shifts =
                this.state.selectedShift.value === 'all'
                    ? this.state.shifts
                    : [
                          this.state.shifts.find(
                              (s) => s.name === this.state.selectedShift.value
                          ),
                      ],
            data = []
        if (filter === null) {
            for (let asset of this.state.selectedAssets) {
                let relevantShifts = shifts.filter((shift) =>
                    this.state.shiftRecords.find(
                        (a) =>
                            a.name === shift.name &&
                            a.assets.find((b) => b === asset.value)
                    )
                )
                for (let shift of relevantShifts) {
                    let range = [
                        moment(this.state.start),
                        moment(this.state.end),
                    ]
                    if (
                        this.state.offsetOvernightShift &&
                        shift.timeStart.hour > shift.timeEnd.hour
                    ) {
                        range = [
                            range[0].add(-1, 'days'),
                            range[1].add(-1, 'days'),
                        ]
                    }
                    promises.push(
                        API.post(
                            'oee',
                            {
                                factoryId: JSON.parse(
                                    localStorage['userObject']
                                ).factoryId,
                                deviceId: asset.value,
                                timeStart: range[0].format('YYYY-MM-DD'),
                                timeEnd: range[1].format('YYYY-MM-DD'),
                                shift: shift.name,
                                useBaselineTarget:
                                    this.state.useBaselineTargets,
                                speedLossType:
                                    this.state.assets.find(
                                        (a) => a.deviceId === asset.value
                                    ).speedLossType || 'fixedHourlyTarget',
                            },
                            2
                        )
                    )
                }
            }
            data = (await Promise.all(promises)).map((a) => a.data).flat()
            if (data.some((a) => a === undefined)) {
                this.setState({loading: false})
                alert(
                    'There was an error generating your report. Please refresh your page, try again and contact Accumine if issues persist'
                )
                return
            }
            data = data.map((a) => {
                a.date = moment(a.date.split('T')[0])
                if (this.state.offsetOvernightShift && a.shift) {
                    const shift = this.state.shifts.find(
                        (b) =>
                            b.name === a.shift && b.assets.includes(a.device_id)
                    )
                    if (shift && shift.timeStart.hour > shift.timeEnd.hour) {
                        a.date.add(1, 'days')
                    }
                }
                a.date = a.date.startOf(this.state.selectedTimeUnit.value)

                return a
            })
        } else {
            data = this.state.data.filter((a) => a.device_id === filter)
        }

        if (this.state.selectedFilter.value === 'weekdays') {
            data = data.filter((a) => {
                return a.date.isoWeekday() !== 6 && a.date.isoWeekday() !== 7
            })
        }
        if (this.state.selectedFilter.value === 'weekends') {
            data = data.filter((a) => {
                return a.date.isoWeekday() === 6 || a.date.isoWeekday() === 7
            })
        }

        let ppt = data.reduce((a, b) => a + b.ppt, 0),
            timeLosses = data.reduce((a, b) => a + b.time_losses, 0),
            speedLosses = data.reduce((a, b) => a + b.speed_losses, 0),
            qualityLosses = data.reduce((a, b) => a + b.quality_losses, 0)

        let tabledata = [
            ppt,
            timeLosses,
            speedLosses,
            qualityLosses,
            ppt - timeLosses - speedLosses - qualityLosses,
        ]

        let chartdata = {
            labels: [
                'Potential Production Time: ',
                'Actual Production Time - Time Losses: ',
                'Theoretical Output: ',
                'Actual Output - Speed Losses: ',
                'Actual Output: ',
                'Good Output - Quality Losses: ',
            ],
            datasets: [
                {
                    label: 'Data',
                    backgroundColor: colors.themeColor1,
                    borderColor: '#145388',
                    data: [
                        100,
                        ((ppt - timeLosses) / ppt) * 100,
                        ((ppt - timeLosses) / ppt) * 100,
                        ((ppt - timeLosses - speedLosses) / ppt) * 100,
                        ((ppt - timeLosses - speedLosses) / ppt) * 100,
                        ((ppt - timeLosses - speedLosses - qualityLosses) /
                            ppt) *
                            100,
                    ],
                },
                {
                    label: 'Losses',
                    backgroundColor: 'red',
                    data: [
                        0,
                        (timeLosses / ppt) * 100,
                        0,
                        (speedLosses / ppt) * 100,
                        0,
                        (qualityLosses / ppt) * 100,
                    ],
                },
            ],
        }

        let timeSeriesData = []
        if (this.state.byShift) {
            timeSeriesData = data.reduce((a, b) => {
                ;(a[`${b.date}::${b.shift}`] =
                    a[`${b.date}::${b.shift}`] || []).push(b)
                return a
            }, {})
            for (let date in timeSeriesData) {
                const ppt = timeSeriesData[date].reduce((a, b) => a + b.ppt, 0),
                    time_losses = timeSeriesData[date].reduce(
                        (a, b) => a + b.time_losses,
                        0
                    ),
                    speed_losses = timeSeriesData[date].reduce(
                        (a, b) => a + b.speed_losses,
                        0
                    ),
                    quality_losses = timeSeriesData[date].reduce(
                        (a, b) => a + b.quality_losses,
                        0
                    )
                timeSeriesData[date] = {
                    date,
                    shift: timeSeriesData[date][0].shift,
                    oee: parseInt(
                        ((ppt - time_losses - speed_losses - quality_losses) /
                            ppt) *
                            100
                    ),
                }
            }
        } else {
            timeSeriesData = data.reduce((a, b) => {
                ;(a[b.date] = a[b.date] || []).push(b)
                return a
            }, {})
            for (let date in timeSeriesData) {
                const ppt = timeSeriesData[date].reduce((a, b) => a + b.ppt, 0),
                    time_losses = timeSeriesData[date].reduce(
                        (a, b) => a + b.time_losses,
                        0
                    ),
                    speed_losses = timeSeriesData[date].reduce(
                        (a, b) => a + b.speed_losses,
                        0
                    ),
                    quality_losses = timeSeriesData[date].reduce(
                        (a, b) => a + b.quality_losses,
                        0
                    )
                timeSeriesData[date] = {
                    date,
                    oee: parseInt(
                        ((ppt - time_losses - speed_losses - quality_losses) /
                            ppt) *
                            100
                    ),
                }
            }
        }
        timeSeriesData = Object.values(timeSeriesData)
        timeSeriesData.sort((left, right) => {
            return moment(left.date).diff(moment(right.date))
        })
        let timeseriesdata = {
            labels: [
                ...new Set(
                    timeSeriesData.map((a) =>
                        moment(a.date.split('::')[0]).format('ll')
                    )
                ),
            ],
            datasets: [],
        }

        if (this.state.byShift) {
            let shifts = [...new Set(timeSeriesData.map((a) => a.shift))]
            for (let i in shifts) {
                timeseriesdata.datasets.push({
                    type: 'bar',
                    label: shifts[i],
                    backgroundColor: colors[`themeColor${parseInt(i) + 1}`],
                    borderColor: '#145388',
                    data: timeSeriesData
                        .filter((a) => a.shift === shifts[i])
                        .map((a) => a.oee),
                    datalabels: {
                        color: colors[`themeColor${parseInt(i) + 1}`],
                        display: 'true',
                    },
                })
            }
        } else {
            timeseriesdata.datasets.push({
                type: 'bar',
                label: 'OEE',
                backgroundColor: colors.themeColor1,
                borderColor: '#145388',
                data: timeSeriesData.map((a) => a.oee),
                datalabels: {
                    color: colors.themeColor1,
                    display: 'true',
                },
            })
        }

        let regressionInput = []
        for (let i in timeseriesdata.labels) {
            regressionInput[i] =
                timeseriesdata.datasets.reduce((a, b) => a + b.data[i], 0) /
                timeseriesdata.datasets.length
        }

        const result = regression.linear(regressionInput.map((a, i) => [i, a]))

        let trendline = [],
            b = result.equation[1],
            m = result.equation[0]
        for (let i in timeseriesdata.labels) {
            trendline.push(m * i + b)
        }

        timeseriesdata.datasets = [
            {
                type: 'line',
                label: 'Trend',
                data: trendline,
                backgroundColor: colors.themeColor6,
                borderColor: colors.themeColor6,
                fill: false,
                datalabels: {
                    color: colors.themeColor6,
                    display: 'auto',
                },
            },
        ].concat(timeseriesdata.datasets)

        let stateObj = {
            loading: false,
            ready: true,
            chartdata,
            timeseriesdata,
            tabledata,
        }

        if (!filter) {
            stateObj.data = data
        }

        this.setState(stateObj)
    }

    handleAssetChange(selectedAsset) {
        this.generate(selectedAsset.value)
        this.setState({selectedAsset})
    }

    async componentDidMount() {
        await this.fetchAssets()
        await this.fetchShifts()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Button
                            block
                            color="success"
                            onClick={() =>
                                this.setState({
                                    showOEEMessage: !this.state.showOEEMessage,
                                })
                            }
                            style={{marginBottom: '1rem'}}>
                            {!this.state.showOEEMessage ? (
                                <>WHAT'S NEW</>
                            ) : (
                                <>HIDE</>
                            )}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Collapse isOpen={this.state.showOEEMessage}>
                            <Alert>
                                <h4 className="alert-heading mb-4">
                                    An updated OEE Reporting Tool
                                </h4>
                                <p>
                                    OEE Reporting has been updated to utilize
                                    the full power of Accumine Cloud Reporting
                                    Engine 3.
                                </p>
                                <h5>
                                    <strong>
                                        NO ACTION IS REQUIRED ON YOUR BEHALF.
                                        THE DETAILS BELOW PROVIDE AN OUTLINE OF
                                        THE CHANGE.
                                    </strong>
                                </h5>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Speed Loss Enhancements</th>
                                            <td>
                                                Each asset is assigned an
                                                explicit speed loss definition
                                                in the Assets settings page
                                                (Setting Name: "OEE
                                                Speed/Performance Framework").
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>New Speed Loss Option</th>
                                            <td>
                                                Speed losses can be calculated
                                                using a rolling 30 day average
                                                of historical cycle times.
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Faster Reporting</th>
                                            <td>
                                                Version 3 of the Reporting
                                                Engine has been optimized to cut
                                                down bandwidth requirements and
                                                processing times.
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <p>
                                    Your dedicated Accumine Account Manager
                                    reviewed your data and reports to ensure the
                                    update was performed successfully. In the
                                    event that you notice any discrepencies or
                                    issues, please do not hesitate to reach out
                                    to the Accumine team.
                                </p>
                            </Alert>
                        </Collapse>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Assets:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="assets"
                                            value={this.state.selectedAssets}
                                            onChange={(selectedAssets) =>
                                                this.setState({selectedAssets})
                                            }
                                            options={this.state.assets.map(
                                                (a, i) => {
                                                    return {
                                                        label: a.name,
                                                        value: a.deviceId,
                                                        key: i,
                                                    }
                                                }
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="2">
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleStartChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleEndChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>Filter Data:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="filter"
                                            value={this.state.selectedFilter}
                                            onChange={(selectedFilter) =>
                                                this.setState({selectedFilter})
                                            }
                                            options={[
                                                {
                                                    label: 'Show all data',
                                                    value: 'all',
                                                },
                                                {
                                                    label: 'Only weekdays',
                                                    value: 'weekdays',
                                                },
                                                {
                                                    label: 'Only weekends',
                                                    value: 'weekends',
                                                },
                                            ]}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>Shift(s):</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="shift"
                                            value={this.state.selectedShift}
                                            onChange={this.handleShiftChange}
                                            options={[
                                                {
                                                    label: 'All Shifts',
                                                    value: 'all',
                                                },
                                            ].concat(
                                                this.state.shifts.map(
                                                    (a, i) => {
                                                        return {
                                                            label: a.name,
                                                            value: a.name,
                                                        }
                                                    }
                                                )
                                            )}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>Time Unit:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="timeUnit"
                                            value={this.state.selectedTimeUnit}
                                            onChange={(selectedTimeUnit) =>
                                                this.setState({
                                                    selectedTimeUnit,
                                                })
                                            }
                                            options={[
                                                {label: 'Days', value: 'day'},
                                                {
                                                    label: 'Weeks',
                                                    value: 'isoWeek',
                                                },
                                                {
                                                    label: 'Months',
                                                    value: 'month',
                                                },
                                            ]}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>Group by shift:</label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={this.state.byShift}
                                            onChange={(byShift) => {
                                                this.setState({byShift})
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs="2" sm="2" className="">
                                        <label>Offset overnight shifts:</label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={
                                                this.state.offsetOvernightShift
                                            }
                                            onChange={(
                                                offsetOvernightShift
                                            ) => {
                                                if (offsetOvernightShift) {
                                                    alert(
                                                        'WARNING: Offsetting overnight shifts in this report will cause discrepencies between this report and all other reporting tools. Accumine is not liable for issues resulting from misinformation caused by using this feature.\n\nEXAMPLE: with this feature enabled, an overnight shift starting from Sunday at 11pm and ending on Monday at 7am will have its data allocated on Monday rather than Sunday (when the shift started).'
                                                    )
                                                }
                                                this.setState({
                                                    offsetOvernightShift,
                                                })
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="2" sm="2" className="">
                                        <label>Use baseline targets:</label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={
                                                this.state.useBaselineTargets
                                            }
                                            onChange={(useBaselineTargets) => {
                                                if (useBaselineTargets) {
                                                    alert(
                                                        'For each asset, the first hourly target entry made will be used as the target value. THIS WILL ONLY BE USED FOR ASSET USING THE FIXED HOURLY TARGET SPEED LOSS METHOD.'
                                                    )
                                                }
                                                this.setState({
                                                    useBaselineTargets,
                                                })
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="8" sm="8" className="text-right">
                                        <Button
                                            color="primary"
                                            onClick={() => this.generate(null)}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.ready ? (
                    <Fragment>
                        <Row className="mt-2">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form>
                                                            <Label className="form-group has-top-label">
                                                                <Select
                                                                    components={{
                                                                        Input: CustomSelectInput,
                                                                    }}
                                                                    className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    name="asset-commission"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .selectedAsset
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleAssetChange
                                                                    }
                                                                    options={[
                                                                        {
                                                                            label: 'All Assets',
                                                                            value: 'all',
                                                                        },
                                                                    ].concat(
                                                                        this
                                                                            .state
                                                                            .selectedAssets
                                                                    )}
                                                                />
                                                                <IntlMessages id="Asset Selection" />
                                                            </Label>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="6">
                                                        <OEEViewChart
                                                            data={
                                                                this.state
                                                                    .chartdata
                                                            }
                                                        />
                                                    </Col>
                                                    <Col xs="6">
                                                        <OEEViewTable
                                                            data={
                                                                this.state
                                                                    .tabledata
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {!this.props.hideOEEBenchmarks ? (
                            <>
                                <Card style={{marginTop: 15, marginBottom: 15}}>
                                    <CardBody>
                                        <Row>
                                            <Col
                                                xs="12"
                                                style={{height: '500px'}}>
                                                <OEETimeSeriesChart
                                                    data={_.cloneDeep(
                                                        this.state
                                                            .timeseriesdata
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card style={{marginTop: 15, marginBottom: 15}}>
                                    <CardBody>
                                        <Row>
                                            <Col
                                                xs="12"
                                                style={{height: '500px'}}>
                                                <OEERealTimeChart
                                                    data={_.cloneDeep(
                                                        this.state
                                                            .timeseriesdata
                                                    )}
                                                    selectedAssets={
                                                        this.state.assets
                                                    }
                                                    target={100}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card style={{marginTop: 15, marginBottom: 15}}>
                                    <CardBody>
                                        <Row>
                                            <Col
                                                xs="12"
                                                style={{height: '500px'}}>
                                                <OEEHistoricalChart
                                                    data={_.cloneDeep(
                                                        this.state
                                                            .timeseriesdata
                                                    )}
                                                    selectedAssets={
                                                        this.state.assets
                                                    }
                                                    target={50}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </>
                        ) : null}
                    </Fragment>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
