import React, {Component, Fragment} from 'react'
import {Button, Badge} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AssetModal from './asset'
import LiveModal from './live'
import DataSamplingModal from './data-sampling'
import RemoveModal from './remove'
import SideModal from './sideModal'

const STATUS_COLORS = {
    opened: 'primary',
    closed: 'secondary',
    started: 'success',
    stopped: 'danger',
    paused: 'warning',
    reset: 'info',
    interrupted: 'danger',
    discovered: 'info',
}

const STATUS_STRINGS = {
    opened: 'Connection Opened',
    closed: 'Connection Closed',
    interrupted: 'Connection Interrupted',
    started: 'Data Streaming Started',
    stopped: 'Data Streaming Stopped',
    paused: 'Data Streaming Paused',
    reset: 'SensorBot Restarted',
    discovered: 'Discovered',
}

const makeBadge = (status) => {
    const color = STATUS_COLORS[status],
        statusString =
            status && STATUS_STRINGS[status] ? STATUS_STRINGS[status] : 'N/A'
    return <Badge color={STATUS_COLORS[status]}>{statusString}</Badge>
}

export default class SensorBotManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'SensorBotManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
            nodes: [],
            gateways: [],
            editingNode: null,
        }
    }
    columns() {
        return [
            {
                Header: 'Asset',
                accessor: 'deviceName',
            },
            {
                Header: 'Gateway',
                accessor: 'gatewayAlias',
            },
            {
                Header: 'Mesh ID',
                accessor: 'nodeId',
            },
            {
                Header: 'Firmware',
                accessor: 'version',
                minWidth: 50,
            },
            {
                Header: 'Signal',
                accessor: 'rssi',
                minWidth: 50,
            },
            {
                Header: 'Last Heartbeat',
                accessor: 'lastHeartbeat',
            },
            {
                Header: 'Last Data Push',
                accessor: 'lastPush',
            },
            {
                Header: 'Pending Update',
                accessor: 'pendingUpdate',
            },
            {
                Header: 'Heartbeat %',
                accessor: 'heartbeatPercentage',
            },
            {
                Header: 'Dataframe %',
                accessor: 'dataframePercentage',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                id: 'edit',
                accessor: 'nodeId',
                Cell: ({value}) => (
                    <Button
                        color="primary"
                        size="xs"
                        className="btn-block"
                        onClick={(e) => {
                            e.stopPropagation()
                            this.toggleLiveStream(value)
                        }}>
                        Data Live Stream
                    </Button>
                ),
                sortable: false,
                filterable: false,
                Header: '',
            },
        ]
    }
    toggleLiveStream(nodeId) {
        this.setState({
            liveModal: !this.state.liveModal,
            gateways: this.state.gateways,
            editingNode:
                this.state.nodes.find((x) => x.nodeId === nodeId) || null,
        })
    }
    toggleSideModal() {
        this.setState({
            sideModal: !this.state.sideModal,
        })
    }
    async fetchNodes() {
        let nodes = await API.get('nodes', 2)
        const sensorbots = nodes.filter(
            (node) =>
                node.nodeType === 'ARD' &&
                node.nodeComm === '900MHz' &&
                !node.ignore
        )
        const gateways = nodes.filter((node) => node.nodeType === 'GWY')
        this.setState({nodes: sensorbots, gateways: gateways})
    }
    async fetchAssets() {
        let assets = await API.get('assets/devices')
        this.setState({assets})
    }
    toggleModal(modalName, node) {
        let state = this.state
        state.editingNode = node
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (node) => this.toggleModal('assetModal', node),
                actionName: 'Asset Commission',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (node) => this.toggleModal('liveModal', node),
                actionName: 'Live Stream',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (node) => this.toggleModal('refVoltageModal', node),
                actionName: 'Reference Voltage',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (node) =>
                    this.toggleModal('dataSamplingModal', node),
                actionName: 'Data Sampling',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (node) => this.toggleModal('actionsModal', node),
                actionName: 'Local Actions',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (node) => this.toggleModal('removeModal', node),
                actionName: 'Remove SensorBot',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(node) {
        const attachedDevice = this.state.assets.find(
            (device) => device.deviceId === node.deviceId
        )
        node.deviceName =
            attachedDevice !== undefined
                ? attachedDevice.name
                : 'Not commissioned'

        node.gatewayAlias = this.state.gateways.find(
            (gwy) => gwy.nodeId === node.gatewayId
        )

        if (node.gatewayAlias) node.gatewayAlias = node.gatewayAlias.alias
        else node.gatewayAlias = 'No Gateway'

        if (
            moment().diff(moment(node.lastHeartbeat), 'minutes') > 5 &&
            node.status !== 'closed'
        ) {
            node.status = makeBadge('interrupted')
        } else {
            node.status = makeBadge(node.status)
        }

        node.lastPush =
            moment(node.lastPush).unix() === 0
                ? 'Never'
                : moment(node.lastPush).fromNow()
        node.lastHeartbeat =
            moment(node.lastHeartbeat).unix() === 0
                ? 'Never'
                : moment(node.lastHeartbeat).fromNow()

        node.pendingUpdate =
            (node.settings.vref.pendingUpdate ||
                node.settings.pins.find((x) => x.pendingUpdate) !==
                    undefined) === true
                ? 'Yes'
                : 'No'

        node.dataframePercentage = node.dataframePercentage
            ? Math.floor(node.dataframePercentage * 100) + '%'
            : 'N/A'
        node.heartbeatPercentage = node.heartbeatPercentage
            ? Math.floor(node.heartbeatPercentage * 100) + '%'
            : 'N/A'

        return node
    }

    editorModalAttributes(node) {
        return [
            ['Mesh ID', node.nodeId],
            ['Gateway', node.gatewayAlias],
            ['Asset', node.deviceName],
        ]
    }

    componentWillMount() {
        if (localStorage['migratedToEdge'] == '0') {
            this.subscriber.add(
                this.fetchAssets,
                5000,
                'DigitalTwin_SensorBots_fetchAssets'
            )
            this.subscriber.add(
                this.fetchNodes,
                1000,
                'DigitalTwin_SensorBots_fetchNodes'
            )
        }
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        return (
            <Fragment>
                {localStorage['migratedToEdge'] == '1' ? (
                    <div className="text-center">
                        <h2 className="text-center">
                            Your organization has been upgraded to Accumine Edge
                            - our new machine connectivity suite.
                        </h2>
                        <Button
                            className="text-center"
                            color="primary"
                            onClick={() => {
                                window.open(
                                    `${process.env.REACT_APP_EDGE_URL}?token=${
                                        JSON.parse(localStorage['userObject'])
                                            .token
                                    }`,
                                    '__blank'
                                )
                            }}>
                            Open Accumine Edge
                        </Button>
                    </div>
                ) : (
                    <>
                        <CRUD
                            uniqueElementId="nodeId"
                            emptyCrudMainText="No SensorBots Found"
                            emptyCrudSubText="SensorBots are automatically initialized in the Accumine platform."
                            crudTableColumns={this.columns()}
                            crudTableResolveDataMapFn={this.dataTableFormatter}
                            crudTableData={this.state.nodes}
                            rowClickFn={(node) => {
                                this.setState({editingNode: node})
                                this.toggleSideModal(node)
                            }}
                        />
                        {this.state.assetModal ? (
                            <AssetModal
                                toggleModal={this.toggleModal}
                                modal={this.state.assetModal}
                                nodes={this.state.nodes}
                                assets={this.state.assets}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}

                        {this.state.liveModal ? (
                            <LiveModal
                                toggleModal={this.toggleModal}
                                modal={this.state.liveModal}
                                nodes={this.state.nodes}
                                gateways={this.state.gateways}
                                assets={this.state.assets}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}

                        {this.state.dataSamplingModal ? (
                            <DataSamplingModal
                                toggleModal={this.toggleModal}
                                modal={this.state.dataSamplingModal}
                                nodes={this.state.nodes}
                                gateways={this.state.gateways}
                                assets={this.state.assets}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}

                        {this.state.removeModal ? (
                            <RemoveModal
                                toggleModal={this.toggleModal}
                                modal={this.state.removeModal}
                                nodes={this.state.nodes}
                                assets={this.state.assets}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}

                        {this.state.sideModal ? (
                            <SideModal
                                toggleModal={this.toggleSideModal}
                                modal={this.state.sideModal}
                                nodes={this.state.nodes}
                                gateways={this.state.gateways}
                                assets={this.state.assets}
                                editingNode={this.state.editingNode}
                            />
                        ) : null}
                    </>
                )}
            </Fragment>
        )
    }
}
