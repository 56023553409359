import _ from 'lodash'
import regression from 'regression'

const peakUsageByTimeOfDay = (capacityAnalyzer, compare) => {
    let chartdata = {
        data: capacityAnalyzer._peak.map((h) => h.currentMax * 100),
    }

    if (compare === 'last1') {
        chartdata.comparison = capacityAnalyzer._peak.map((h) =>
            Math.ceil(h.previousMax * 100)
        )
    } else if (compare === 'all') {
        chartdata.comparison = capacityAnalyzer._peak.map((h) =>
            Math.ceil(h.allTimeMax * 100)
        )
    }
    return chartdata
}

const capacityRecommendation = (capacityAnalyzer, assetGroup) => {
    const max = Math.max(...capacityAnalyzer._peak.map((h) => h.currentMax))

    if (max === 1) {
        return 'Consider increasing the number of ' + assetGroup
    } else if (!isNaN(max) && isFinite(max)) {
        return (
            'Reduce ' +
            assetGroup +
            ' by ' +
            Math.floor(capacityAnalyzer._assets.length * (1 - max)) +
            ' assets'
        )
    } else {
        return 'No recommendation available'
    }
}

const maximumPeakUsage = (capacityAnalyzer) => {
    const value = Math.ceil(
        Math.max(...capacityAnalyzer._peak.map((h) => h.currentMax)) * 100
    )
    return !isNaN(value) && isFinite(value) ? value : false
}

const roundMultipleNumbersToTarget = (array, target) => {
    let roundedDown = array.map((a) => Math.floor(a)),
        left = target - roundedDown.reduce((a, b) => a + b, 0)

    for (let i = 0; i < left; i++) {
        roundedDown[i]++
    }
    return roundedDown
}

const aggregateStates = (analyzer) => {
    let result = []

    for (let asset of analyzer._assets) {
        if (analyzer._schedule === 'alltime') {
            const incycle =
                analyzer._days.reduce((sum, next) => {
                    return (
                        next[asset.deviceId + '_In-Cycle'].durationPercentage *
                            100 +
                        sum
                    )
                }, 0) / analyzer._days.length
            const downtime =
                analyzer._days.reduce((sum, next) => {
                    return (
                        next[asset.deviceId + '_Downtime'].durationPercentage *
                            100 +
                        sum
                    )
                }, 0) / analyzer._days.length
            const offline =
                analyzer._days.reduce((sum, next) => {
                    return (
                        next[asset.deviceId + '_Offline'].durationPercentage *
                            100 +
                        sum
                    )
                }, 0) / analyzer._days.length

            if (incycle === 0 && downtime === 0 && offline === 0) {
                result.push({
                    name: asset.name,
                    active: 0,
                    dormant: 0,
                    offline: 0,
                })
            } else {
                const rounded = roundMultipleNumbersToTarget(
                    [incycle, downtime, offline],
                    100
                )
                result.push({
                    name: asset.name,
                    active: rounded[0],
                    dormant: rounded[1],
                    offline: rounded[2],
                })
            }
        } else {
            let incycle =
                analyzer._days.reduce((sum, next) => {
                    return (
                        next[asset.deviceId + '_scheduled_In-Cycle']
                            .durationPercentage + sum
                    )
                }, 0) / analyzer._days.length
            let downtime =
                analyzer._days.reduce((sum, next) => {
                    return (
                        next[asset.deviceId + '_scheduled_Downtime']
                            .durationPercentage + sum
                    )
                }, 0) / analyzer._days.length
            let offline =
                analyzer._days.reduce((sum, next) => {
                    return (
                        next[asset.deviceId + '_scheduled_Offline']
                            .durationPercentage + sum
                    )
                }, 0) / analyzer._days.length

            let incycleAdj = (incycle / (incycle + downtime + offline)) * 100
            let downtimeAdj = (downtime / (incycle + downtime + offline)) * 100
            let offlineAdj = (offline / (incycle + downtime + offline)) * 100

            if (incycleAdj === 0 && downtimeAdj === 0 && offlineAdj === 0) {
                result.push({
                    name: asset.name,
                    active: 0,
                    dormant: 0,
                    offline: 0,
                })
            } else {
                const rounded = roundMultipleNumbersToTarget(
                    [incycleAdj, downtimeAdj, offlineAdj],
                    100
                )
                result.push({
                    name: asset.name,
                    active: rounded[0],
                    dormant: rounded[1],
                    offline: rounded[2],
                })
            }
        }
    }
    return result
}

const timeSeriesUsageDataset = (
    capacityAnalyzer,
    xAxisValue,
    yAxisValue,
    groupByShift,
    showTrendline
) => {
    let chartdata = {
        dates: [],
        data: [],
        type: '',
        xAxis: xAxisValue,
    }

    let data = null

    if (xAxisValue === 'weeks') {
        data = capacityAnalyzer.weeks()
    } else if (xAxisValue === 'months') {
        data = capacityAnalyzer.months()
    } else {
        data = capacityAnalyzer._days
    }

    chartdata.dates = data.map((x) => x.date)

    if (yAxisValue === 'peak-percent') {
        ///////////////////////////////////////////////////////////
    } else if (yAxisValue === 'peak-count') {
        ///////////////////////////////////////////////////////////
    } else if (yAxisValue === 'avg-hours') {
        chartdata.type = 'hours'
        if (capacityAnalyzer._schedule === 'alltime') {
            chartdata.data.push({
                label: 'Average Usage Hours',
                data: data.map((d) => d['total_In-Cycle'].duration),
                backgroundColor: '#28a745',
            })
        } else {
            if (groupByShift) {
                for (let shift of capacityAnalyzer._schedule) {
                    chartdata.data.push({
                        label: shift + ' Average Usage Hours',
                        data: data.map((d) => d[shift + '_In-Cycle'].duration),
                        backgroundColor: '#28a745',
                    })
                }
            } else {
                chartdata.data.push({
                    label: 'Average Scheduled Usage Hours',
                    data: data.map((d) => d['scheduled_In-Cycle'].duration),
                    backgroundColor: '#28a745',
                })
            }
        }
    } else {
        chartdata.type = 'percent'
        if (capacityAnalyzer._schedule === 'alltime') {
            chartdata.data.push({
                label: 'Average Usage %',
                data: data.map(
                    (d) => d['total_In-Cycle'].durationPercentage * 100
                ),
                backgroundColor: '#28a745',
            })
        } else {
            if (groupByShift) {
                for (let shift of capacityAnalyzer._schedule) {
                    chartdata.data.push({
                        label: shift + ' Average Usage %',
                        data: data.map(
                            (d) =>
                                d[shift + '_In-Cycle'].durationPercentage * 100
                        ),
                        backgroundColor: '#28a745',
                    })
                }
            } else {
                chartdata.data.push({
                    label: 'Average Scheduled Usage %',
                    data: data.map(
                        (d) => d['scheduled_In-Cycle'].durationPercentage * 100
                    ),
                    backgroundColor: '#28a745',
                })
            }
        }
    }

    if (showTrendline) {
        let regdata = []
        for (let dataset of chartdata.data) {
            for (let i in dataset.data) {
                if (!regdata[i]) {
                    regdata.push([parseInt(i), 0])
                }
                regdata[i][1] += dataset.data[i]
            }
        }
        const result = regression.linear(
            regdata
                .map((r) => [r[0], r[1] / chartdata.data.length])
                .filter((r) => r[1] > 0)
        )
        chartdata.data.unshift({
            label: 'Trendline',
            type: 'line',
            data: regdata.map((x, i) => result.predict(i)[1]),
            borderColor: '#145388',
            backgroundColor: 'transparent',
        })
    }

    return chartdata
}

export {
    peakUsageByTimeOfDay,
    capacityRecommendation,
    maximumPeakUsage,
    aggregateStates,
    timeSeriesUsageDataset,
}
