import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Button} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import moment from 'moment'

import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import * as API from 'SDK/api'

import Template from './Template'
import Navigation from '../Navigation'
import {paginate} from '../Paginate'

import {DATA_MODEL_ID_MAP} from './DATA_MODEL_ID_MAP'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Enter BMR Data',
            fn: () => {
                this.setState({selectedOperation: null})
            },
        })

        this.state = {
            loading: true,
            selectedBlockBatch: null,
            blockBatchOptions: [],
            selectedOperation: null,
            operationRecords: {},
        }
    }

    async fetchOperationRecords() {
        this.setState({loading: true})
        let operationRecords = {}
        for (let operation in DATA_MODEL_ID_MAP) {
            const op = DATA_MODEL_ID_MAP[operation]
            const {result} = await API.post(
                'data-models/' + op.dataModelId + '/paginate',
                {
                    filter: [
                        {
                            type: 'Text',
                            logic: 'is',
                            value: [
                                this.state.selectedBlockBatch.value[
                                    'Batch Number'
                                ],
                            ],
                            path: op.masterBlockFieldPath,
                        },
                    ],
                    sort: {},
                    limit: 1,
                    next: null,
                    previous: null,
                    timezone: localStorage['timezone'],
                },
                2
            )
            operationRecords[operation] = result.results.length
                ? result.results[0]
                : null
        }
        this.setState({operationRecords, loading: false})
    }

    async fetchBlockBatches() {
        let batches = [],
            records
        records = await paginate(
            '5fc9015bae96bc6be2c1a13e',
            [
                {
                    type: 'Timestamp',
                    logic: 'isEmpty',
                    value: [],
                    path: 'Finish Date',
                },
            ],
            {}
        )

        batches = records.map((r) => {
            return {label: r['Batch Number'], value: r}
        })

        records = await paginate(
            '5fc9015bae96bc6be2c1a13e',
            [
                {
                    type: 'Timestamp',
                    logic: 'previous',
                    value: {
                        unit: 'day',
                        value: 45,
                    },
                    path: 'Finish Date',
                },
            ],
            {}
        )

        batches = records.map((r) => {
            return {label: r['Batch Number'], value: r}
        })

        records = await paginate(
            '5fc9015bae96bc6be2c1a13e',
            [
                {
                    type: 'Timestamp',
                    logic: 'on',
                    value: moment.utc('1970-01-01T00:00:00.000Z').toISOString(),
                    path: 'Finish Date',
                },
            ],
            {}
        )

        batches = batches.concat(
            records.map((r) => {
                return {label: r['Batch Number'], value: r}
            })
        )

        if (this.props.initialBatchNumber) {
            this.setState({
                selectedBlockBatch: batches.find(
                    (r) => r.label === this.props.initialBatchNumber
                ),
            })
        }

        return batches
    }

    handleBlockBatchSelectionChange(selectedBlockBatch) {
        this.setState({selectedBlockBatch}, this.fetchOperationRecords)
    }

    goTo(selectedOperation) {
        this.setState({
            selectedOperation,
        })
    }

    is3DAType() {
        const {selectedBlockBatch} = this.state
        if (
            selectedBlockBatch &&
            selectedBlockBatch.value['Block Description']['Type'] === '3DA'
        ) {
            return true
        } else {
            return false
        }
    }

    async reset() {
        this.setState({loading: true})
        await this.fetchOperationRecords()

        this.setState({selectedOperation: null, loading: false})
    }

    async componentDidMount() {
        this.setState({
            blockBatchOptions: await this.fetchBlockBatches(),
            loading: false,
        })
    }

    async reRunBatch() {
        this.setState({loading: true})
        let record = this.state.selectedBlockBatch.value
        record.Re_run = true

        await API.patch(
            'data-models/5fc9015bae96bc6be2c1a13e/edit-record',
            record,
            2
        )
        this.setState({loading: false})
        alert('Batch tagged for re-run')
    }

    render() {
        const is3DAType = this.is3DAType()

        return (
            <Fragment>
                {this.state.loading ? (
                    <div className="loading" />
                ) : (
                    <Fragment>
                        {this.state.selectedOperation === null ? (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <Navigation path={this.path} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign: 'center'}}>
                                        <h1>Enter BMR Data</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{size: 4, offset: 4}}>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Select
                                                    components={{
                                                        Input: CustomSelectInput,
                                                    }}
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    name="powder-batch"
                                                    value={
                                                        this.state
                                                            .selectedBlockBatch
                                                    }
                                                    onChange={
                                                        this
                                                            .handleBlockBatchSelectionChange
                                                    }
                                                    options={this.state.blockBatchOptions.sort(
                                                        (a, b) =>
                                                            a.label.localeCompare(
                                                                b.label
                                                            )
                                                    )}
                                                />
                                                <IntlMessages id="Batches" />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : null}

                        {this.state.selectedBlockBatch !== null &&
                        this.state.selectedOperation === null ? (
                            <Fragment>
                                <Row>
                                    <Col className="text-center">
                                        <i>
                                            <span style={{color: '#dc3545'}}>
                                                Red Button
                                            </span>{' '}
                                            means form has never been submitted
                                        </i>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{size: 12, offset: 0}}>
                                        <Row>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo('Press')
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Press'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    PRESS
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo('BBO')
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'BBO'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    BBO
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo('Kiln')
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Kiln'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    KILN
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo(
                                                            'Collar Spray'
                                                        )
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Collar Spray'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    COLLAR SPRAY
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo('Collar Cure')
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Collar Cure'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    COLLAR CURE
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo('Grinding')
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Grinding'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    GRINDING
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo(
                                                            'Visual Inspection'
                                                        )
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Visual Inspection'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    VISUAL INSPECTION
                                                </Button>
                                            </Col>
                                            {is3DAType ? (
                                                <Col xs="6" className="mt-2">
                                                    <Button
                                                        size="xl"
                                                        block
                                                        onClick={() =>
                                                            this.goTo(
                                                                '3DA Bordering'
                                                            )
                                                        }
                                                        color={
                                                            this.state
                                                                .operationRecords[
                                                                '3DA Bordering'
                                                            ] === null
                                                                ? 'danger'
                                                                : 'secondary'
                                                        }>
                                                        3DA BORDERING
                                                    </Button>
                                                </Col>
                                            ) : null}
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    onClick={() =>
                                                        this.goTo('Metallizing')
                                                    }
                                                    color={
                                                        this.state
                                                            .operationRecords[
                                                            'Metallizing'
                                                        ] === null
                                                            ? 'danger'
                                                            : 'secondary'
                                                    }>
                                                    STATION METALLIZING
                                                </Button>
                                            </Col>
                                            {is3DAType ? (
                                                <Fragment>
                                                    <Col
                                                        xs="6"
                                                        className="mt-2">
                                                        <Button
                                                            size="xl"
                                                            block
                                                            onClick={() =>
                                                                this.goTo(
                                                                    '3DA Transmission'
                                                                )
                                                            }
                                                            color={
                                                                this.state
                                                                    .operationRecords[
                                                                    '3DA Transmission'
                                                                ] === null
                                                                    ? 'danger'
                                                                    : 'secondary'
                                                            }>
                                                            3DA TRANSMISSION
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        xs="6"
                                                        className="mt-2">
                                                        <Button
                                                            size="xl"
                                                            block
                                                            onClick={() =>
                                                                this.goTo(
                                                                    '3DA High Energy'
                                                                )
                                                            }
                                                            color={
                                                                this.state
                                                                    .operationRecords[
                                                                    '3DA High Energy'
                                                                ] === null
                                                                    ? 'danger'
                                                                    : 'secondary'
                                                            }>
                                                            3DA HIGH ENERGY
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        xs="6"
                                                        className="mt-2">
                                                        <Button
                                                            size="xl"
                                                            block
                                                            onClick={() =>
                                                                this.goTo(
                                                                    '3DA 100A'
                                                                )
                                                            }
                                                            color={
                                                                this.state
                                                                    .operationRecords[
                                                                    '3DA 100A'
                                                                ] === null
                                                                    ? 'danger'
                                                                    : 'secondary'
                                                            }>
                                                            3DA 100 A
                                                        </Button>
                                                    </Col>
                                                </Fragment>
                                            ) : null}
                                            {is3DAType ? (
                                                <Col xs="6" className="mt-2">
                                                    <Button
                                                        size="xl"
                                                        block
                                                        onClick={() =>
                                                            this.goTo(
                                                                'Final Inspection (3DA Batches)'
                                                            )
                                                        }
                                                        color={
                                                            this.state
                                                                .operationRecords[
                                                                'Final Inspection (3DA Batches)'
                                                            ] === null
                                                                ? 'danger'
                                                                : 'secondary'
                                                        }>
                                                        FINAL INSPECTION
                                                    </Button>
                                                </Col>
                                            ) : (
                                                <Col xs="6" className="mt-2">
                                                    <Button
                                                        size="xl"
                                                        block
                                                        onClick={() =>
                                                            this.goTo(
                                                                'Test & Inspect'
                                                            )
                                                        }
                                                        color={
                                                            this.state
                                                                .operationRecords[
                                                                'Test & Inspect'
                                                            ] === null
                                                                ? 'danger'
                                                                : 'secondary'
                                                        }>
                                                        TEST & INSPECT
                                                    </Button>
                                                </Col>
                                            )}
                                            <Col xs="6" className="mt-2">
                                                <Button
                                                    size="xl"
                                                    block
                                                    color="warning"
                                                    onClick={() =>
                                                        this.goTo(
                                                            '2 Shot Re-Run Tracker'
                                                        )
                                                    }>
                                                    2 SHOT RE-RUN TRACKER
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : null}

                        {this.state.selectedBlockBatch !== null &&
                        this.state.selectedOperation !== null ? (
                            <Fragment>
                                <Template
                                    dataModelId={
                                        DATA_MODEL_ID_MAP[
                                            this.state.selectedOperation
                                        ].dataModelId
                                    }
                                    blockBatchRecord={
                                        this.state.selectedBlockBatch
                                    }
                                    editingRecord={
                                        this.state.operationRecords[
                                            this.state.selectedOperation
                                        ]
                                    }
                                    editingBlockHeightsRecord={
                                        this.state.operationRecords[
                                            'Block Heights'
                                        ]
                                    }
                                    path={this.path}
                                    title={this.state.selectedOperation}
                                    operationRecords={
                                        this.state.operationRecords
                                    }
                                    operationMap={DATA_MODEL_ID_MAP}
                                    reset={this.reset}
                                />
                            </Fragment>
                        ) : null}
                    </Fragment>
                )}
            </Fragment>
        )
    }
}
