import React, {Component, Fragment} from 'react'
import {Row} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import StaticHourlyActualTile from './StaticHourlyActualTile'

export default class HourlyTargetActualDisplayWithTimeline extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'realtimeactiveshift',
            component: 'RealTimeActiveShift',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'MCSUseSimpleDisplay',
            component: 'MCSUseSimpleDisplay',
        },
    ]
    showBorder = false
    id = 'HourlyTargetActualDisplayWithTimeline'
    requiredOutputs = [
        'Part Count',
        'In-Cycle',
        'Downtime',
        'Static Hourly Target',
    ]
    static propTypes = {
        name: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
        deviceIds: PropTypes.array,
        useRollingCycleTimeTargets: PropTypes.bool,
        MCSUseSimpleDisplay: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            live: {},
            downtimeReasons: [],
        }
    }

    async fetchLive() {
        // get live data
        let live = await API.post('live', {
            deviceId: this.state.devices.map((d) => d.deviceId),
        })
        const downtimeReasons = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: this.state.devices.map((d) => d.deviceId)},
                    name: 'Downtime Reason',
                    timeEnd: null,
                },
            },
            2
        )
        if (!live) return
        this.setState({live, downtimeReasons})
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        const dataModels = await API.get('data-models', 2)
        const fixedSpmModel = dataModels.find(
            (m) => m.name === 'Fixed SPM Targets'
        )
        const fixedSpmData = await API.post(
            'data-models/' + fixedSpmModel._id + '/paginate',
            {
                filter: [],
                sort: {},
            },
            2
        )
        this.setState(
            {
                fixedSpmData: fixedSpmData.result.results,
                devices,
            },
            async () => {
                await this.fetchLive()
                this.subscriber.add(this.fetchLive, 1000 * 5, 'fetchData()')
            }
        )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    {Object.keys(this.state.live).length
                        ? this.state.devices.map((device, i) => {
                              return (
                                  <StaticHourlyActualTile
                                      simpleMode={
                                          this.props.MCSUseSimpleDisplay
                                      }
                                      key={i}
                                      deviceId={device.deviceId}
                                      device={device}
                                      name={device.name}
                                      productionUnit={
                                          device.productionUnit
                                              ? device.productionUnit
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                device.productionUnit.slice(1) +
                                                's'
                                              : 'Parts'
                                      }
                                      realtimeactiveshift={
                                          this.props.realtimeactiveshift
                                      }
                                      live={this.state.live}
                                      assets={this.state.devices}
                                      downtimeReason={this.state.downtimeReasons.find(
                                          (d) => d.deviceId === device.deviceId
                                      )}
                                      useRollingCycleTimeTargets={
                                          this.props.useRollingCycleTimeTargets
                                      }
                                      fixedSpmData={this.state.fixedSpmData}
                                  />
                              )
                          })
                        : null}
                </Row>
            </Fragment>
        )
    }
}
