import moment from 'moment';
import * as optimize from './optimization'


export function _createDateKey(timeStart, timeEnd) {
    // some may ask why anyone would want to make date durations into dictionary keys?
    // This allows us to match related states using the unique "timeStart-timeEnd"
    // with big O notation O(C) instead of the previous looping to find states
    return timeStart?.toString()+'-'+timeEnd?.toString();
}

export function _stateMeetsDurationRequirements(state, downtimeInclusionThreshold) {
    if(!state) {
        return false;
    }

    if(!state.timeStart) {
        return false;
    }

    if(!state.timeEnd) {
        return true;
    }

    if(!downtimeInclusionThreshold) {
        return true;
    }

    let stateDuration = moment(state.timeEnd).diff(
        moment(state.timeStart),
        'seconds'
    );

    return stateDuration >= downtimeInclusionThreshold;
}

export function _mergeStatesToDowntime_(availableUsers) {

    const _findUserRecord = optimize._keyedListSearch_(availableUsers, (u) => u._id);

    const _doStateMerge = (state1, state2) => {
        /*
        * This fuction will merge dictionaries and ensure that the "Downtime" state
        * is the top level dictionary and the other states are just made into attributes
        * of the "Downtime" state
        */

        const _isValidAttributeState = (state) => {
            return state && state.name && state.name !== "Downtime";
        }

        const _isValidDowntimeState = (state) => {
            return state && state.name === "Downtime";
        }

        const _isPlaceholderState = (state) => {
            return state && !state.name;
        }

        const _isValidTargetState = (state) => {
            return  _isValidDowntimeState(state) || _isPlaceholderState(state);
        }

        const _mergeLeft = (leftState, rightState) => {
            if(!leftState.fullUserName) {
                // make sure the defaults are always there.
                leftState.fullUserName = "N/A";
            }

            if(rightState.name === 'Downtime Category') {
                leftState.category = rightState.value;
            } else if(rightState.name === 'Downtime Reason') {
                leftState.reason = rightState.value;
            } else if(rightState.name === 'Downtime Comment') {
                leftState.comment = rightState.value;
            } else if(rightState.name === 'Downtime User') {
                // override the full username if the actual user has been discovered
                const userRecord = _findUserRecord(rightState.value);
                if (userRecord) {
                    leftState.fullUserName =
                        userRecord.firstName + ' ' + userRecord.lastName
                    leftState.user = rightState.value
                }
            } else if(rightState.name === undefined) {
                // when the right state has no name we know that it isn't a real state, just a placeholder target
                leftState =  Object.assign(leftState, rightState);
            }
            return leftState;
        }

        if(!state1 && !state2) {
            return {};
        }

        // there is a target state but undefined second state
        if(_isValidTargetState(state1) && !state2) {
            return state1;
        }
        if(_isValidTargetState(state2) && !state1) {
            return state2;
        }

        // there is an attribute state, but undefined second state
        if(_isValidAttributeState(state1) && !state2) {
            // attribute states should be attributeState when the current attributeState is invalid
            return _mergeLeft({}, state1);
        }
        if(_isValidAttributeState(state2) && !state1) {
            // attribute states should be attributeState when the current attributeState is invalid
            return _mergeLeft({}, state2);
        }

        // there is a target state and an attibute state
        if( _isValidTargetState(state1) && _isValidAttributeState(state2)) {
            return _mergeLeft(state1, state2);
        }
        if( _isValidTargetState(state2) && _isValidAttributeState(state1)) {
            return _mergeLeft(state2, state1);
        }

        // Two attribute states. merge them into a default target state
        if(_isValidAttributeState(state1) && _isValidAttributeState(state2)) {
            return _mergeLeft(_mergeLeft({}, state1), state2)
        }

        // Two placeholder states
        if(_isPlaceholderState(state1) && _isPlaceholderState(state2)) {
            _mergeLeft(state2, state1);
        }

        // when we have a downtime state and placeholder state the downtime should be the merge target
        if(_isValidDowntimeState(state2) && _isPlaceholderState(state1)) {
            return _mergeLeft(state2, state1);
        }
        if(_isValidDowntimeState(state1) && _isPlaceholderState(state2)) {
            return _mergeLeft(state1, state2);
        }

        // when we have a downtime state and placeholder state the downtime should be the merge target
        if(_isValidDowntimeState(state1) && _isValidDowntimeState(state2)) {
            console.warn(
                "Recieved two downtime states when merging. This is not expected!",
                "Returning state1 by default",
                "\nstate1", JSON.stringify(state1),
                "\nstate2", JSON.stringify(state2)
            );
            return state1;
        }

    }
    return _doStateMerge;
}

export function createDowntimeEntriesList(pastStates, availableUsers, downtimeInclusionThreshold) {
    const merge = _mergeStatesToDowntime_(availableUsers);

    let downtimesMap = {};
    for(let state of pastStates) {
        if(_stateMeetsDurationRequirements(state, downtimeInclusionThreshold)){
            let dateKey = _createDateKey(state.timeStart, state.timeEnd);
            downtimesMap[dateKey] = merge(downtimesMap[dateKey], state);
        }
    }
    const _noDowntimeState = (x) => Boolean(x.deviceId);
    return Object.values(downtimesMap).filter(_noDowntimeState);
}

