import React, {useEffect, useState} from 'react'
import {Form, FormGroup, Row, Col, Button, Alert, Collapse} from 'reactstrap'
import {cloneDeep} from 'lodash'
import {v4 as uuidv4} from 'uuid'

import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'
import ReportModal from '../../../../SchemaViewer/modals/Analyze'

const makeSubQuery = (q, allFields) => {
    let nestedJoins = []
    let joins = cloneDeep(q.groups)
        .concat(cloneDeep(q.filter))
        .concat(cloneDeep(q.aggregate))
        .map((a) => {
            let sourcePrefix = ''
            if (a.parentFieldId) {
                sourcePrefix =
                    a.parentFieldId.split('.' + a.model.name).length > 1
                        ? a.parentFieldId.split('.' + a.model.name)[0]
                        : ''
            }
            if (a.parentReferences) {
                for (let p of a.parentReferences) {
                    let sPrefix = ''
                    if (p.parentFieldId) {
                        sPrefix =
                            p.parentFieldId.split('.' + p.model.name).length > 1
                                ? p.parentFieldId.split('.' + p.model.name)[0]
                                : ''
                    }
                    nestedJoins.push({
                        dataModelId: p.dataModelId,
                        referenceId: p.referenceId,
                        sourcePrefix: sPrefix,
                        targetPrefix: p.parentFieldId
                            ? p.parentFieldId
                                  .split('.')
                                  .filter((b) => !allFields.includes(b))
                                  .join('.')
                            : null,
                        depth: p.referenceDepth,
                    })
                }
            }

            return {
                dataModelId: a.referenceDataModelId,
                referenceId: a.referenceId,
                sourcePrefix,
                targetPrefix: a.parentFieldId
                    ? a.parentFieldId
                          .split('.')
                          .filter((b) => !allFields.includes(b))
                          .join('.')
                    : null,
            }
        })
    nestedJoins = nestedJoins.sort((a, b) => (a.depth > b.depth ? 1 : -1))

    joins = [
        ...new Set(
            nestedJoins
                .filter((a) => a.referenceId)
                .concat(joins)
                .map(({referenceId}) => referenceId)
        ),
    ].map(
        (e) =>
            joins.find(({referenceId}) => referenceId == e) ||
            nestedJoins.find(({referenceId}) => referenceId == e)
    )

    return {
        join: joins.filter((a) => a.referenceId),
        aggregate: q.aggregate.map((a) => {
            return {dataModelId: a.dataModelId, type: a.type, path: a.path}
        })[0],
    }
}

export default (props) => {
    const [advancedIsOpen, toggleAdvanced] = useState(false)
    const [parameters, setParameters] = useState({
        dataModelId: props.element.data.opts.dataModelId || null,
        name: props.element.data.opts.name || 'GetReport',
        description: props.element.data.opts.description || '',
        reportConfiguration:
            props.element.data.opts.reportConfiguration || null,
        requestBody: props.element.data.opts.requestBody || null,
        requestURL:
            props.element.data.opts.requestURL ||
            'https://data-models.accuminetech.com/data-models',
        variableId: props.element.data.opts.variableId || null,
        submitted: props.element.data.opts.submitted || true,
    })
    const [reportModalOpen, setReportModalOpen] = useState(false)

    const setReportConfiguration = (q) => {
        let requestBody = {
            filter: q.filter.map((a) => {
                return {...a, dataModelId: a.rootDataModelId}
            }),
            groups: q.groups.map((a) => {
                return {
                    dataModelId: a.rootDataModelId,
                    path: a.path,
                    timeUnit: a.timeUnit,
                }
            }),
            timezone: q.timezone,
            timerange: q.timerange,
            aggregate: [],
        }

        const allFields = props.dataModels
            .map((a) => a.fields.map((a) => a._id))
            .flat()

        requestBody.aggregate = q.aggregate.map((a) => {
            let copy = cloneDeep(q)
            copy.aggregate = [a]
            return makeSubQuery(copy, allFields)
        })

        parameters.reportConfiguration = q
        parameters.requestBody = requestBody
        setParameters({...parameters})
        setReportModalOpen(false)
    }

    const submit = () => {
        if (parameters.reportConfiguration === null)
            return alert('Configure a report to save.')
        const existingVariable = props.variables.find(
            (v) => v.transformId === props.element.id
        )
        const groupFields = parameters.reportConfiguration.groups.map((g) => {
            return {
                alias: g.title,
                name: g.path,
                path: g.path,
                type: g.type,
            }
        })
        const aggregateFields = parameters.reportConfiguration.aggregate.map(
            (a) => {
                return {
                    alias: a.title,
                    name: a.path,
                    path: a.path,
                    type: 'Number',
                }
            }
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Report',
            multi: !(parameters.reportConfiguration.groups.length === 0),
            fields: groupFields.concat(aggregateFields),
            dataModelId: parameters.dataModelId,
        }

        parameters.variableId = variable.id

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                onChange={(m) => {
                                    parameters.dataModelId = m
                                    parameters.reportConfiguration = null
                                    parameters.requestBody = null
                                    setParameters({...parameters})
                                }}
                                fieldName="Data Model"
                                default={
                                    parameters.dataModelId
                                        ? {
                                              label: props.dataModels.find(
                                                  (m) =>
                                                      m._id ===
                                                      parameters.dataModelId
                                              ).name,
                                              value: parameters.dataModelId,
                                          }
                                        : null
                                }
                                options={props.dataModels.map((m) => {
                                    return {
                                        label: m.name,
                                        value: m._id,
                                    }
                                })}
                            />
                        </FormGroup>

                        {parameters.dataModelId ? (
                            <>
                                <Alert>
                                    To use the report as a variable, ensure
                                    there are no groups selected.
                                </Alert>
                                <FormGroup>
                                    <Button
                                        color="default"
                                        size="sm"
                                        block
                                        onClick={() =>
                                            setReportModalOpen(true)
                                        }>
                                        Show Report Builder...
                                    </Button>
                                </FormGroup>
                            </>
                        ) : null}

                        <hr />

                        <FormGroup>
                            <p
                                color="link"
                                style={{cursor: 'pointer'}}
                                onClick={() => toggleAdvanced(!advancedIsOpen)}>
                                {advancedIsOpen ? (
                                    <>
                                        <i className="simple-icon-arrow-down" />{' '}
                                        Hide advanced
                                    </>
                                ) : (
                                    <>
                                        <i className="simple-icon-arrow-right" />{' '}
                                        Show advanced
                                    </>
                                )}
                            </p>
                            <Collapse isOpen={advancedIsOpen}>
                                <FormGroup>
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.requestURL = v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Request URL"
                                        existing={[]}
                                        initial={parameters.requestURL}
                                        disabled={false}
                                    />
                                </FormGroup>
                            </Collapse>
                        </FormGroup>

                        <hr />

                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>

            {reportModalOpen ? (
                <ReportModal
                    open={true}
                    //toggle={() => setReportModalOpen(!reportModalOpen)}
                    dataModel={props.dataModels.find(
                        (m) => m._id === parameters.dataModelId
                    )}
                    dataModels={props.dataModels}
                    fieldTypes={props.fieldTypes}
                    filter={parameters.filter}
                    setReportConfiguration={setReportConfiguration}
                    reportConfiguration={parameters.reportConfiguration}
                    savedReports={[]}
                    loadReport={() => {}}
                    loadedSavedReport={() => {}}
                    clearSavedReport={() => {}}
                    formulas={props.formulas}
                    variables={props.variables.filter(
                        (v) => !v.multi && v.transformId !== props.element.id
                    )}
                />
            ) : null}
        </>
    )
}
