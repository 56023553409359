import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    CardHeader,
    CardFooter,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import Subscriber from 'SDK/subscriber'
import GenericChart from './GenericChart'

export default class HubbellMeramecCapping extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'HubbellMeramecCapping'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            timerange: [
                moment().startOf('day').subtract(7, 'days'),
                moment().endOf('day'),
            ],
            deviceName: '',
            data: [],
            meramecData: {},
            kpiInCycleDurationToday: [],
            chartInCycleDurationWeekly: [],
            kpiPartCountToday: [],
            chartPartCountWeekly: [],
            kpiRejectCountToday: [],
            chartRejectCountWeekly: [],
            kpiBomTimeToday: [],
            chartBomTimeWeekly: [],
            kpiActualTimeToday: [],
            kpiActualTimeWeekly: [],
            refresh_time: moment(),
            primaryDeviceId: [''],
        }
    }

    mapDataRequired(
        startDate = this.state.timerange[0].toISOString(),
        endDate = this.state.timerange[1].toISOString()
    ) {
        const durationAggregates = this.fetchAggregates(
            ['In-Cycle'],
            startDate,
            endDate,
            'duration',
            'day',
            (data) => {
                var chartdata = [
                    [
                        'Values shown in hours by operator sign in time',
                        'In-Cycle Duration',
                    ],
                ]
                data.data.dates.map((d, i) => {
                    chartdata.push([
                        moment(d).toDate(),
                        data.data.devices[this.state.deviceIds[0]]['In-Cycle'][
                            i
                        ] /
                            1000 /
                            60 /
                            60,
                    ])
                })
                var chartInCycleDurationWeekly = (
                    <GenericChart
                        title={'In-Cycle This Week'}
                        subtitle={'Daily Total'}
                        data={chartdata}
                    />
                )
                var kpiInCycleDurationToday = this.makeKPI(
                    'In-Cycle Today',
                    'In-Cycle Today',
                    (
                        data.data.devices[this.state.deviceIds[0]]['In-Cycle'][
                            data.data.devices[this.state.deviceIds[0]][
                                'In-Cycle'
                            ].length - 1
                        ] /
                        1000 /
                        60 /
                        60
                    ).toFixed(2),
                    'info',
                    'Hours'
                )

                this.setState({
                    kpiInCycleDurationToday,
                    chartInCycleDurationWeekly,
                })
            }
        )
        const countAggregates = this.fetchAggregates(
            ['In-Cycle', 'Part Count', 'Reject'],
            startDate,
            endDate,
            'count',
            'day',
            (data) => {
                /* Cycle Time KPI and chart */
                /*var cycletimechartdata = [['Values shown in minutes by average time per operation', 'Cycle Time Average']];
        data.data.dates.map((d, i) => {cycletimechartdata.push([moment(d).toDate(), data.data.devices[this.state.deviceId]['In-Cycle'][i].averageMs /1000/60])});
        const chartCycleTimeWeekly = <GenericChart title={'Cycle Time This Week'} subtitle={'Daily'} data={cycletimechartdata} />
        const kpiCycleTimeToday = this.makeKPI('Cycle Time Avg Today', 'Cycle Time Avg Today', data.data.devices[this.state.deviceId]['In-Cycle'][data.data.devices[this.state.deviceId]['In-Cycle'].length-1].averageMs /1000/60, 'info', 'Minutes');*

        /* Part Count KPI and Chart */
                /*var partchartdata = [['Values shown as sum of sign in events for this operation', 'Part Count Total']];
        data.data.dates.map((d, i) => {partchartdata.push([moment(d).toDate(), data.data.devices[this.state.deviceIds[0]]['Part Count'][i].count])});
        const chartPartCountWeekly = <GenericChart title={'Part Count This Week'} subtitle={'Daily Total'} data={partchartdata} />
        const kpiPartCountToday = this.makeKPI('Part Count Today', 'Part Count Today', data.data.devices[this.state.deviceIds[0]]['Part Count'][data.data.devices[this.state.deviceIds[0]]['Part Count'].length-1].count, 'info', 'Pieces');
        */
                /* Reject Count KPI and Chart */
                var rejectchartdata = [
                    [
                        'Values shown as sum of defects registered for operation',
                        'Defect Count Total',
                    ],
                ]
                data.data.dates.map((d, i) => {
                    rejectchartdata.push([
                        moment(d).toDate(),
                        data.data.devices[this.state.deviceIds[0]]['Reject'][i]
                            .count,
                    ])
                })
                const chartRejectCountWeekly = (
                    <GenericChart
                        title={'Reject Count This Week'}
                        subtitle={'Daily Total'}
                        data={rejectchartdata}
                    />
                )
                const kpiRejectCountToday = this.makeKPI(
                    'Defect Count Today',
                    'Defect Count Today',
                    data.data.devices[this.state.deviceIds[0]]['Reject'][
                        data.data.devices[this.state.deviceIds[0]]['Reject']
                            .length - 1
                    ].count,
                    'info',
                    'Pieces'
                )

                this.setState({
                    kpiRejectCountToday,
                    chartRejectCountWeekly,
                })
            }
        )

        const processSchemaRecords = this.fetchSchema(
            this.state.deviceIds.length > 0 ? 'ticket' : 'aggregate',
            ['bom_time', 'actual_time'],
            '6025777e01c07a00132f2f41',
            this.state.timerange[0],
            this.state.timerange[1],
            (data) => {
                const bomToday =
                    data[data.length - 1].data.length > 0
                        ? data[data.length - 1].data
                              .map((item) => item.bom_time)
                              .reduce((prev, curr) => prev + curr, 0)
                        : 0
                const actualToday =
                    data[data.length - 1].data.length > 0
                        ? data[data.length - 1].data
                              .map((item) => item.actual_time)
                              .reduce((prev, curr) => prev + curr, 0)
                        : 0
                const processTimeToday =
                    data[data.length - 1].data.length > 0
                        ? data[data.length - 1].data
                              .map(
                                  (item) =>
                                      moment(item.timeEnd) -
                                      moment(item.timeStart)
                              )
                              .reduce((prev, curr) => prev + curr, 0) /
                          1000 /
                          60
                        : 0
                const processCountToday =
                    data[data.length - 1].data.length > 0
                        ? data[data.length - 1].data.length
                        : 0

                var chartdata = [
                    [
                        'Values shown in hours as sum of BOM time',
                        'Earned Hours By Day',
                    ],
                ]
                data.map((d) => {
                    chartdata.push([
                        moment(d.date).toDate(),
                        d.data.length > 0
                            ? d.data
                                  .map((item) => item.bom_time)
                                  .reduce((prev, curr) => prev + curr, 0) / 60
                            : 0,
                    ])
                })
                const chartBomTimeWeekly = (
                    <GenericChart
                        title={'Earned Hours This Week'}
                        subtitle={'Daily Total'}
                        data={chartdata}
                    />
                )
                const kpiBomTimeToday = this.makeKPI(
                    'Earned Hours Today',
                    'Earned Hours Today',
                    (bomToday / 60).toFixed(2),
                    'info',
                    'Hours'
                )

                var chartdata = [
                    [
                        'Values shown in hours as sum of Actual Time',
                        'Actual Hours By Day',
                    ],
                ]
                data.map((d, i) => {
                    chartdata.push([
                        moment(d.date).toDate(),
                        d.data.length > 0
                            ? d.data
                                  .map((item) => item.actual_time)
                                  .reduce((prev, curr) => prev + curr, 0) / 60
                            : 0,
                    ])
                })
                const chartActualTimeWeekly = (
                    <GenericChart
                        title={'Actual Hours This Week'}
                        subtitle={'Daily Total'}
                        data={chartdata}
                    />
                )
                const kpiActualTimeToday = this.makeKPI(
                    'Actual Hours Today',
                    'Actual Hours Today',
                    (actualToday / 60).toFixed(2),
                    'info',
                    'Hours'
                )

                var cycletimechartdata = [
                    [
                        'Values shown in minutes by average time per operation',
                        'Cycle Time Average',
                    ],
                ]
                data.map((d, i) => {
                    let dur =
                        d.data.length > 0
                            ? d.data
                                  .map(
                                      (item) =>
                                          moment(item.timeEnd) -
                                          moment(item.timeStart)
                                  )
                                  .reduce((prev, curr) => prev + curr, 0) /
                              1000 /
                              60
                            : 0
                    let cnt = d.data.length > 0 ? d.data.length : 0
                    let dt = moment(d.date).toDate()
                    cycletimechartdata.push([dt, dur / cnt])
                })
                const chartCycleTimeWeekly = (
                    <GenericChart
                        title={'Cycle Time This Week'}
                        subtitle={'Daily'}
                        data={cycletimechartdata}
                    />
                )
                const kpiCycleTimeToday = this.makeKPI(
                    'Cycle Time Today',
                    'Cycle Time Today',
                    processTimeToday / processCountToday
                        ? (processTimeToday / processCountToday).toFixed(2)
                        : 0,
                    'info',
                    'Minutes'
                )

                const leadTimeToday =
                    data[data.length - 1].data.length > 0
                        ? data[data.length - 1].data
                              .map(
                                  (item) =>
                                      (moment(item.timeEnd) -
                                          moment(item.timeStart)) /
                                          1000 /
                                          60 -
                                      item.actual_time
                              )
                              .reduce(
                                  (avg, value, _, {length}) =>
                                      avg + value / length,
                                  0
                              )
                        : 0
                var leadtimechartdata = [
                    [
                        'Values shown in minutes by average time between operations',
                        'Lead Time Average',
                    ],
                ]
                data.map((d, i) => {
                    let leadtime =
                        d.data.length > 0
                            ? d.data
                                  .map(
                                      (item) =>
                                          (moment(item.timeEnd) -
                                              moment(item.timeStart)) /
                                              1000 /
                                              60 -
                                          item.actual_time
                                  )
                                  .reduce(
                                      (avg, value, _, {length}) =>
                                          avg + value / length,
                                      0
                                  )
                            : 0
                    let dt = moment(d.date).toDate()
                    leadtimechartdata.push([dt, leadtime])
                })
                const chartLeadTimeWeekly = (
                    <GenericChart
                        title={'Lead Time This Week'}
                        subtitle={'Daily'}
                        data={leadtimechartdata}
                    />
                )
                const kpiLeadTimeToday = this.makeKPI(
                    'Lead Time Today',
                    'Lead Time Today',
                    leadTimeToday.toFixed(2),
                    'info',
                    'Minutes'
                )

                var partchartdata = [
                    [
                        'Values shown as sum of sign in events for this operation',
                        'Part Count Total',
                    ],
                ]
                data.map((d, i) => {
                    partchartdata.push([moment(d.date).toDate(), d.data.length])
                })
                const chartPartCountWeekly = (
                    <GenericChart
                        title={'Part Count This Week'}
                        subtitle={'Daily Total'}
                        data={partchartdata}
                    />
                )
                const kpiPartCountToday = this.makeKPI(
                    'Part Count Today',
                    'Part Count Today',
                    data[data.length - 1].data.length,
                    'info',
                    'Pieces'
                )

                const takttimefn = (minutes, length) => {
                    return length > 10 ? minutes / length : 0
                }

                var takttimechartdata = [
                    [
                        'Values shown as total time divided by total cycles',
                        'Avg Takt Time',
                    ],
                ]
                data.map((d, i) => {
                    takttimechartdata.push([
                        moment(d.date).toDate(),
                        takttimefn(1440, d.data.length),
                    ])
                })
                const chartTaktTimeWeekly = (
                    <GenericChart
                        title={'Takt Time This Week'}
                        subtitle={'Takt Time Avg'}
                        data={takttimechartdata}
                    />
                )
                const kpiTaktTimeToday = this.makeKPI(
                    'Takt Time Today',
                    'Takt Time Today',
                    takttimefn(1440, data[data.length - 1].data.length).toFixed(
                        2
                    ),
                    'info',
                    'Minutes'
                )

                this.setState({
                    kpiBomTimeToday,
                    chartBomTimeWeekly,
                    kpiActualTimeToday,
                    chartActualTimeWeekly,
                    kpiCycleTimeToday,
                    chartCycleTimeWeekly,
                    kpiLeadTimeToday,
                    chartLeadTimeWeekly,
                    chartPartCountWeekly,
                    kpiPartCountToday,
                    chartTaktTimeWeekly,
                    kpiTaktTimeToday,
                })
            }
        )

        this.setState({refresh_time: moment()})
    }

    async fetchData() {
        const data = await API.post(
            'meramec/6025777e01c07a00132f2f41/data',
            {
                timeStart: moment().subtract(24, 'hours'),
                timeEnd: moment(),
            },
            2
        )

        this.setState({meramecData: data})
    }

    async fetchAggregates(
        states = ['In-Cycle', 'Part Count', 'Reject'],
        timeStart = moment().toISOString(),
        timeEnd = moment().toISOString(),
        logic = 'count',
        timeUnit = 'total',
        callback = () => {}
    ) {
        let data = await API.post('historical/aggregate2', {
            timeStart: this.state.timerange[0],
            timeEnd: this.state.timerange[1],
            deviceId: this.state.deviceIds,
            state: states,
            logic: logic,
            groupByTimeUnit: timeUnit,
        })

        callback(data)
    }

    async fetchStates(
        states = ['Reject'],
        timeStart = moment().toISOString(),
        timeEnd = moment().toISOString(),
        callback = () => {}
    ) {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    timeStart: {
                        $gte: timeStart,
                        $lt: timeEnd,
                    },
                    name: {$in: states},
                    deviceId: this.state.deviceIds,
                },
            },
            2
        )

        callback(data)
    }

    async fetchSchema(
        schemaType = 'aggregate',
        properties = ['defect'],
        schemaId = '',
        timeStart = moment().toISOString(),
        timeEnd = moment().toISOString(),
        callback = () => {}
    ) {
        let activeMoment = moment(timeStart),
            requests = []

        while (activeMoment < moment(timeEnd)) {
            requests.push(
                API.post(
                    `meramec/${schemaId}/${schemaType}`,
                    {
                        properties,
                        deviceId: this.state.deviceIds,
                        timeStart: activeMoment.toISOString(),
                        timeEnd: activeMoment.add(1, 'day').toISOString(),
                        logic: 'count',
                        timeUnit: 'day',
                    },
                    2
                )
            )
        }

        let data = await Promise.all(requests)

        callback(data)
    }

    makeKPI(name, alias, value, colorId = 'info', subtitle) {
        let color = {
            info: 'rgba(150,150,150,1)',
            danger: 'rgba(225,50,50,1)',
            success: 'rgba(50,225,50,1)',
        }
        return (
            <Card
                className="mt-4"
                style={{
                    textAlign: 'center',
                    border: color[colorId],
                    borderRadius: '5px',
                }}>
                <CardHeader
                    style={{
                        paddingTop: '5px',
                        paddingBottom: '0px',
                        background: color[colorId],
                        color: '#fff',
                    }}>
                    <p>{alias || name}</p>
                </CardHeader>
                <CardBody style={{padding: '0.5rem'}}>
                    <h3>{value || 0}</h3>
                </CardBody>
                <CardFooter>{subtitle}</CardFooter>
            </Card>
        )
    }

    async componentDidMount() {
        const devices = await API.get('devices')

        this.setState(
            {
                deviceIds: this.props.deviceIds
                    ? this.props.deviceIds
                    : [this.props.deviceId],
            },
            () => {
                this.setState({
                    deviceName: devices.find(
                        (x) => x.deviceId === this.state.deviceIds[0]
                    ).name,
                })
            }
        )

        this.mapDataRequired()

        this.subscriber.add(this.mapDataRequired, 1000 * 60, 'mapDataRequired')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="4">{this.state.kpiTaktTimeToday}</Col>
                    <Col xs="4">{this.state.kpiPartCountToday}</Col>
                    <Col xs="4">{this.state.kpiRejectCountToday}</Col>
                    <Col xs="2">{this.state.kpiCycleTimeToday}</Col>
                    <Col xs="2">{this.state.kpiLeadTimeToday}</Col>
                    <Col xs="2">{this.state.kpiInCycleDurationToday}</Col>
                    <Col xs="2">{this.state.kpiProcessTimeToday}</Col>
                    <Col xs="2">{this.state.kpiBomTimeToday}</Col>
                    <Col xs="2">{this.state.kpiActualTimeToday}</Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartTaktTimeWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartRejectCountWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartCycleTimeWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartLeadTimeWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs="6">
                        <Card>
                            <CardBody>{this.state.chartBomTimeWeekly}</CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartInCycleDurationWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartActualTimeWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        <Card>
                            <CardBody>
                                {this.state.chartPartCountWeekly}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Alert color="secondary">
                            <h4 className="alert-heading">
                                {this.state.deviceName}
                            </h4>
                            <p>
                                Report refreshed at{' '}
                                {this.state.refresh_time.toISOString()} showing
                                values for the last seven days.
                            </p>
                            {/*<hr />
              <p className="mb-0">
                <Button>Print Report</Button>
              </p>*/}
                        </Alert>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
