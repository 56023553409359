import moment from 'moment'

import * as API from './index'

const getAssets = async () => {
    const result = await API.get('devices')
    if (result) {
        return result
    } else {
        return false
    }
}

const getLiveAssets = async () => {
    let options = await API.get('options', 2),
        devices = await API.get('devices')

    if (
        !options.liveDevices ||
        options.liveDevices.constructor !== Array ||
        options.liveDevices.length === 0
    ) {
        options.liveDevices = devices.map((d) => d.deviceId)
    }

    return options.liveDevices.map((deviceId) => {
        return devices.find((device) => device.deviceId === deviceId)
    })
}

const getReportAssets = async () => {
    const devices = await API.get('devices'),
        options = await API.get('options', 2)

    if (!options || !devices) return false
    if (
        !options.reportDevices ||
        options.reportDevices.constructor !== Array ||
        options.reportDevices.length === 0
    ) {
        options.reportDevices = devices.map((d) => d.deviceId)
    }

    return options.reportDevices.map((deviceId) => {
        return devices.find((device) => device.deviceId === deviceId)
    })
}

const getLiveProductionMetric = async () => {
    const result = await API.get('options', 2)
    if (result) {
        return result.metric
    } else {
        return false
    }
}

const getTimerange = async () => {
    const data = await API.get('options', 2)
    if (!data) return false

    let range = [],
        now = moment()
    if (data.timeline.type === 'rolling') {
        range = [
            moment(now)
                .startOf('hour')
                .subtract(data.timeline.rolling.length - 1, 'hours')
                .toISOString(),
            moment(now).toISOString(),
        ]
    } else if (data.timeline.type === 'fixed') {
        if (data.timeline.fixed.from.hour < data.timeline.fixed.to.hour) {
            // same day
            range = [
                moment(now)
                    .startOf('day')
                    .add(data.timeline.fixed.from.hour, 'hours')
                    .toISOString(),
                moment(now)
                    .startOf('day')
                    .add(data.timeline.fixed.to.hour, 'hours')
                    .toISOString(),
            ]
        } else if (moment(now).hour() >= data.timeline.fixed.from.hour) {
            // [today, tomorrow]
            range = [
                moment(now)
                    .startOf('day')
                    .add(data.timeline.fixed.from.hour, 'hours')
                    .toISOString(),
                moment(now)
                    .startOf('day')
                    .add(24 + data.timeline.fixed.to.hour, 'hours')
                    .toISOString(),
            ]
        } else {
            // [yesterday, tomorrow]
            range = [
                moment(now)
                    .startOf('day')
                    .add(data.timeline.fixed.from.hour - 24, 'hours')
                    .toISOString(),
                moment(now)
                    .startOf('day')
                    .add(data.timeline.fixed.to.hour, 'hours')
                    .toISOString(),
            ]
        }
    }
    if (moment(range[1]).isAfter(now)) {
        range[1] = moment(now).toISOString()
    }
    return range
}

const logUserEvent = async (view) => {
    API.post('users/event', {
        timestamp: moment().toDate(),
        view: view,
    })
}

const getActiveShift = (date, shifts, live) => {
    const base = moment(date)

    let activeShift = false
    for (let i = 0; i < shifts.length; i++) {
        let range = getShiftTimerange(date, shifts[i], live)
        if (base.isBetween(range[0], range[1], null, '[]')) {
            activeShift = shifts[i].name
        }
    }
    return activeShift
}

const getActiveShiftObject = async (date, shifts, live) => {
    if (!shifts) {
        return {activeShift: false, range: []}
    } else {
        const base = moment(date)

        for (let i = 0; i < shifts.length; i++) {
            let range = getShiftTimerange(date, shifts[i], live)
            if (base.isBetween(range[0], range[1], null, '[]')) {
                return {activeShift: shifts[i], range: range}
            }
        }
        return {activeShift: false, range: []}
    }
}

// Only includes shifts by day
// * Checks the day match of the date and the shift.days array
const getShiftsByDay = (date, shifts, live) => {
    let filteredShifts = []
    if (!shifts) {
        return shifts
    } else {
        for (let i = 0; i < shifts.length; i++) {
            if (shifts[i].days.indexOf(date.day()) !== -1) {
                shifts[i].range = getShiftTimerange(date, shifts[i], live)
                filteredShifts.push(shifts[i])
            }
        }
        return filteredShifts
    }
}

const getShiftSchedule = async (deviceId) => {
    let shifts = await API.get('shifts', 2)

    if (!shifts) return []

    return shifts.sort((a, b) => {
        return a.timeStart.hour - b.timeStart.hour
    })
}

const getShiftTimerange = (date, shift, live) => {
    const {timeStart, timeEnd} = shift,
        startOffset = timeStart.hour * 60 + timeStart.minute,
        endOffset = timeEnd.hour * 60 + timeEnd.minute,
        base = moment(date).startOf('day')

    let range = [
        moment(base).set({hour: timeStart.hour, minute: timeStart.minute}),
        moment(base).set({hour: timeEnd.hour, minute: timeEnd.minute}),
    ]

    if (range[0].isAfter(range[1])) {
        if (!live) {
            range[1].add(1, 'days')
        } else {
            if (moment().isAfter(range[0])) {
                // in evening
                range[1].add(1, 'days')
            } else {
                // in morning
                range[0].add(-1, 'days')
            }
        }
    }
    return range
}

const fetchShiftTimerange = async (deviceId, shiftName) => {
    const shifts = await API.get('shifts', 2)
    let shift = shifts.find(
        (s) => s.name === shiftName && s.assets.find((d) => d === deviceId)
    )
    if (!shift) {
        shift = shifts.find((s) => s.name === shiftName)
    }

    const {timeStart, timeEnd} = shift,
        base = moment().startOf('day')

    let range = [
        moment().set({hour: timeStart.hour, minute: timeStart.minute}),
        moment().set({hour: timeEnd.hour, minute: timeEnd.minute}),
    ]

    if (range[0].isAfter(range[1])) {
        if (moment().isAfter(range[0])) {
            // in evening
            range[1].add(1, 'days')
        } else {
            // in morning
            range[0].add(-1, 'days')
        }
    }
    return range.map((x) => x.toISOString())
}

export {
    getAssets,
    getLiveAssets,
    getReportAssets,
    getLiveProductionMetric,
    getTimerange,
    logUserEvent,
    getActiveShift,
    getActiveShiftObject,
    getShiftTimerange,
    getShiftsByDay,
    getShiftSchedule,
    fetchShiftTimerange,
}
