import React, {Component, Fragment} from 'react'
import {Button, Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddModal from './add'
import RemoveModal from './remove'
import EditModal from './edit'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
]

export default class ManualStateManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ManualStateManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            manualstates: [],
            editingState: null,
            addModal: false,
        }
    }
    async fetchManualStates() {
        let manualstates = await API.get('manualstates', 2)
        if (manualstates) this.setState({manualstates})
    }
    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal,
        })
    }
    toggleModal(modalName, code) {
        let state = this.state
        state.editingState = code
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('editModal', code),
                actionName: 'Edit',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (code) => this.toggleModal('removeModal', code),
                actionName: 'Remove',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(code) {
        return code
    }

    editorModalAttributes(code) {
        return [['Name', code.name]]
    }

    componentWillMount() {
        this.subscriber.add(this.fetchManualStates, 3000, 'fetchManualStates()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstCodeEl = (
                <Button color="primary" onClick={this.toggleAddModal}>
                    Add Your First Manual State
                </Button>
            ),
            tableExists = this.state.manualstates.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col
                            xs="12"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Button
                                color="primary"
                                onClick={this.toggleAddModal}>
                                Add Manual State
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Manual States Found"
                    emptyCrudSubText={addFirstCodeEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.manualstates}
                    editorModalTitle="Manual State Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addModal ? (
                    <AddModal
                        toggleModal={this.toggleAddModal}
                        modal={this.state.addModal}
                        manualstates={this.state.manualstates}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        manualstates={this.state.manualstates}
                        editingState={this.state.editingState}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        manualstates={this.state.manualstates}
                        editingState={this.state.editingState}
                    />
                ) : null}
            </Fragment>
        )
    }
}
