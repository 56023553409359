import React, {Component} from 'react'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

import KanbanViewer from '../Schemas/SDK/KanbanViewer'

export default class SchemaKanbanViewerFullAccess extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'dataModelId',
            component: 'DataModelPicker',
        },
    ]
    showBorder = false
    id = 'SchemaKanbanViewerFullAccess'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string.isRequired,
        dataModelId: PropTypes.string.isRequired,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            ready: false,
            dataModels: [],
        }
    }

    async componentDidMount() {
        let dataModels = [],
            dataModelName = ''
        try {
            dataModels = await API.get('data-models', 2)
        } catch (error) {
            console.log(error)
            alert('Error loading necessary data. Check internet connection.')
        }

        const dataModel = dataModels.find(
            (model) => model._id === this.props.dataModelId
        )

        if (!dataModel) {
            alert('Data model cannot be found. View cannot be loaded.')
            return
        } else {
            dataModelName = dataModel.name
        }

        this.setState({
            ready: true,
            dataModels,
            dataModelName,
        })
    }

    render() {
        return (
            <>
                {this.state.ready ? (
                    <>
                        <h5 className="text-center">
                            <strong>{this.state.dataModelName}</strong>
                        </h5>
                        <KanbanViewer
                            dataModelId={this.props.dataModelId}
                            dataModels={this.state.dataModels}
                        />
                    </>
                ) : null}
            </>
        )
    }
}
