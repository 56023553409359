export const StatusOptions = {
    active: {
        badgeColor: 'success',
        hexColor: '#3e884f',
        serverDocumentation: 'No documentation found',
        nodeDocumentation: '',
        flowDocumentation:
            'Flow is processing incoming data and running the appropriate actions. No errors encountered.',
    },
    ready: {
        badgeColor: 'primary',
        hexColor: '#145388',
        serverDocumentation: 'No documentation found',
        flowDocumentation: 'No documentation found',
        nodeDocumentation:
            'Element is ready for the next task. No errors encountered.',
    },
    processing: {
        badgeColor: 'warning',
        hexColor: '#b69329',
        serverDocumentation: 'No documentation found',
        flowDocumentation: 'No documentation found',
        nodeDocumentation:
            'Element is currently processing data. No errors encountered.',
    },
    paused: {
        badgeColor: 'info',
        hexColor: '#3195a5',
        serverDocumentation: 'No documentation found',
        flowDocumentation:
            'Flow & all its elements are paused. Incoming data is buffered until flow is un-paused. No errors encountered.',
        nodeDocumentation:
            'Element is paused. Incoming data is buffered until flow is un-paused. No errors encountered.',
    },
    disabled: {
        badgeColor: 'dark',
        hexColor: '#bbb',
        serverDocumentation: 'No documentation found',
        flowDocumentation:
            'Flow & all its elements are disabled. Incoming data is ignored until flow is enabled. No errors encountered.',
        nodeDocumentation:
            'Element is disabled. Incoming data is ignored until flow is enabled. No errors encountered.',
    },
    error: {
        badgeColor: 'danger',
        hexColor: '#c43d4b',
        serverDocumentation: 'No documentation found',
        flowDocumentation:
            'Error encountered. Check logs (Actions > Enter Debug Mode).',
        nodeDocumentation:
            'Error encountered. Check logs (Actions > Enter Debug Mode).',
    },
    online: {
        badgeColor: 'success',
        hexColor: '#3e884f',
        serverDocumentation:
            'You are connected to the flow server. No errors encountered.',
        flowDocumentation: 'No documentation found',
        nodeDocumentation: 'No documentation found',
    },
    offline: {
        badgeColor: 'danger',
        hexColor: '#c43d4b',
        serverDocumentation:
            'You are disconnected from the flow server. Check your internet connection.',
        flowDocumentation:
            'You are disconnected from the flow server. Check your internet connection.',
        nodeDocumentation:
            'You are disconnected from the flow server. Check your internet connection.',
    },
    deleted: {
        badgeColor: 'danger',
        hexColor: '#c43d4b',
        serverDocumentation: 'No documentation found',
        flowDocumentation: 'No documentation found',
        nodeDocumentation: 'Element has been deleted. No errors encountered.',
    },
    unknown: {
        badgeColor: 'dark',
        hexColor: '#bbb',
        serverDocumentation: 'Status unknown, check back later.',
        flowDocumentation: 'Status unknown, check back later.',
        nodeDocumentation: 'Status unknown, check back later.',
    },
}

export const getStatus = (status) => {
    return StatusOptions[status] || StatusOptions['unknown']
}
