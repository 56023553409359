import {Row, Col, Button} from 'reactstrap'

const NumberKeypad = (props) => {
    const setValue = (entry) => {
        if ((props.value === '0' || props.value === '') && entry === '0') {
            // prevent leading 0
            return
        }
        if (props.value.includes('.') && props.value === '.') {
            // prevent multiple decimal points
            return
        }
        props.onChange(`${props.value}${entry}`)
    }
    const backspace = () => {
        props.onChange(props.value.slice(0, -1))
    }

    return (
        <>
            <Row>
                <Col
                    xs="12"
                    className="text-center"
                    style={{color: props.value === '' ? '#fff' : '#000'}}>
                    <h5>{String(props.value) || ' - '}</h5>
                </Col>
            </Row>
            <Row>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('1')}>
                        <strong>1</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('2')}>
                        <strong>2</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('3')}>
                        <strong>3</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{
                            borderRadius: 0,
                            textAlign: 'center',
                            background: '#fff',
                            border: 'none',
                        }}
                        onClick={backspace}>
                        <img
                            src="https://pic.onlinewebfonts.com/svg/img_522338.png"
                            height={20}
                        />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('4')}>
                        <strong>4</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('5')}>
                        <strong>5</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('6')}>
                        <strong>6</strong>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('7')}>
                        <strong>7</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('8')}>
                        <strong>8</strong>
                    </Button>
                </Col>
                <Col xs="3" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('9')}>
                        <strong>9</strong>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs="6" className="m-0 p-1">
                    <Button
                        block
                        style={{borderRadius: 0}}
                        onClick={() => setValue('0')}>
                        <strong>0</strong>
                    </Button>
                </Col>
                {props.showDecimal && (
                    <Col xs="3" className="m-0 p-1">
                        <Button
                            block
                            style={{borderRadius: 0}}
                            onClick={() => setValue('.')}
                            disabled={(props.value || '').includes('.')}>
                            <strong>.</strong>
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    )
}
export default NumberKeypad
