import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Input} from 'reactstrap'
import autobind from 'auto-bind'
import ReactTable from 'react-table'
import Select from 'react-select'
import moment from 'moment'

import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import DataTablePagination from 'components/DataTables/pagination'

import Navigation from '../../Navigation'
import {paginate} from '../../Paginate'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Lots By Material Report',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            ready: false,
            generatingData: false,
            lots: [],
            materials: [],
            selectedMaterial: null,
        }
    }

    async fetchRawMaterials() {
        let materials = await paginate('5fc530725d4bb3001bba8f43', [], {})
        materials = materials.map((m) => {
            return {
                label: m['Value'],
                value: m['Value'],
            }
        })
        this.setState({materials, ready: true})
    }

    async fetchData() {
        this.setState({generatingData: true})
        let lots = await paginate(
            '5fc554e6cca7411395293a68',
            [
                {
                    type: 'Text',
                    logic: 'is',
                    value: [this.state.selectedMaterial.value],
                    path: 'Raw Chemical.Value',
                },
            ],
            {}
        )

        this.setState({
            lots,
            generatingData: false,
        })
    }

    handleMaterialChange(selectedMaterial) {
        this.setState({selectedMaterial}, this.fetchData)
    }

    async componentDidMount() {
        this.fetchRawMaterials()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Lots By Material Report</h1>
                    </Col>
                </Row>

                {this.state.ready ? (
                    <Fragment>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="material"
                                            value={this.state.selectedMaterial}
                                            onChange={this.handleMaterialChange}
                                            options={this.state.materials.sort(
                                                (a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                            )}
                                        />
                                        <IntlMessages id="Raw Material" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        {this.state.lots.length &&
                        this.state.selectedMaterial ? (
                            <Row>
                                <Col xs="12">
                                    <ReactTable
                                        defaultPageSize={10}
                                        data={this.state.lots}
                                        columns={[
                                            {
                                                Header: 'Lot Number',
                                                accessor: 'Raw Lot Number',
                                            },
                                            {
                                                Header: 'Total Quantity',
                                                accessor: 'Total_Quantity',
                                            },
                                            {
                                                Header: 'Remaining Quantity',
                                                accessor: 'Raw Quantity',
                                            },
                                            {
                                                Header: 'Released By',
                                                accessor: 'Released_By',
                                            },
                                            {
                                                Header: 'Release Date',
                                                accessor: 'Raw Release Date',
                                                Cell: (row) =>
                                                    moment(row.value).format(
                                                        'll'
                                                    ),
                                            },
                                        ]}
                                        minRows={0}
                                        PaginationComponent={
                                            DataTablePagination
                                        }
                                        filterable={true}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <p className="text-center">No lots found.</p>
                        )}
                    </Fragment>
                ) : (
                    <div className="loading" />
                )}

                {this.state.generatingData ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
