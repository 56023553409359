import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Label,
    CardFooter,
    Form,
    Input,
    CardHeader,
    InputGroup,
    InputGroupAddon,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'
import IntlMessages from 'util/IntlMessages'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import {secondsToHHMMSS} from 'SDK/helpers'
import './index.css'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import Board from 'react-trello'
import EditModal from 'SDK/ui/widgets/SimpleMaintenance/edit'

/* https://github.com/rcdexta/react-trello */
let eventBus = null
const setEventBus = (handle) => {
    eventBus = handle
}

const CARD_DEFAULT = {
    id: 'Card1',
    title: 'Sample Title',
    description: 'Sample Description',
    label: 'Sample Label',
    metadata: {ticket: {}},
}

const FILTER_STATUS_OPTIONS = [
    {
        label: 'All',
        value: 'All',
    },
    {
        label: 'Active',
        value: 'Active',
    },
    {
        label: 'Complete',
        value: 'Complete',
    },
]

const FILTER_DEADLINE_OPTIONS = [
    {
        label: 'All',
        value: 'All',
    },
    {
        label: 'Overdue',
        value: 'Overdue',
    },
]

const FILTER_SUPERVISOR_OPTIONS = [
    {
        label: 'All',
        value: 'All',
    },
]

const FILTER_ASSET_OPTIONS = [
    {
        label: 'All',
        value: 'All',
    },
]

export default class MaintenanceKanban extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'MaintenanceKanban'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()
        this.state = {
            devices: [],
            users: [],
            filterStatus: FILTER_STATUS_OPTIONS[0],
            filterAsset: null,
            filterDeadline: FILTER_DEADLINE_OPTIONS[0],
            filterSupervisor: null,
            workflows: [],
            workflow: [],
            tickets: [],
            kanban: {lanes: []},
            kanbanBoardElement: null,
            collapsed: true,
            setCollapsed: () =>
                this.setState({collapsed: !this.state.collapsed}),
        }
    }

    async fetchWorkflow() {
        let workflows = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Workflow',
                },
            },
            2
        )

        if (workflows) {
            this.setState({workflows, workflow: workflows[0]})
        }
    }

    async fetchTickets() {
        let tickets = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Maintenance Ticket',
                    isComplete: false,
                    timeStart: {
                        $gte: moment().subtract(6, 'months').toISOString(),
                    },
                },
                options: {
                    sort: {
                        timeStart: -1,
                    },
                },
            },
            2
        )

        if (tickets) {
            this.setState({tickets})
        }
    }

    kanbanAddLane(title, color, tickets) {
        let {kanban} = this.state,
            cards = []

        // creating a list of cards
        tickets.forEach((ticket) => {
            let card = this.kanbanTicketToCardMorph(ticket)
            if (card) cards.push(card)
        })

        kanban.lanes.push({
            id: title,
            title: (
                <div>
                    <div
                        style={{
                            height: 15,
                            width: 15,
                            backgroundColor: color,
                            display: 'inline-block',
                        }}></div>
                    {title}
                </div>
            ),
            label: (
                <div>
                    {'0/'}
                    {tickets.length}
                </div>
            ),
            cards,
        })
        this.setState({kanban})
    }

    kanbanClearLanes(title, color, tickets) {
        let {kanban} = this.state
        kanban.lanes = []
        this.setState({kanban})
    }

    kanbanRenderLanes() {
        this.kanbanClearLanes()
        this.state.workflow.value.forEach((item) => {
            this.kanbanAddLane(
                item.name,
                item.color,
                this.ticketsMatchColumn(item.name)
            )
        })
    }

    async fetchUsers() {
        let users = await API.get('users?all=true', 2)
        this.setState({users})
    }

    async fetchDevices() {
        let devices = await API.get('devices')
        if (!devices) {
            return alert('There was an error fetching data')
        }
        this.setState({devices})
    }

    getDeviceName(deviceId) {
        return this.state.devices.find((a) => a.deviceId === deviceId)
            ? this.state.devices.find((a) => a.deviceId === deviceId).name
            : 'N/A'
    }

    kanbanTicketToCardMorph(ticket) {
        switch (this.state.filterStatus.value) {
            case 'All':
                break
            case 'Complete':
                if (ticket.isComplete === false) return false
                break
            case 'Active':
                if (ticket.isComplete === true) return false
                break
            default:
                return false
        }

        if (this.state.filterSupervisor) {
            if (this.state.filterSupervisor.value !== ticket.assignment.value) {
                return false
            }
        }

        if (this.state.filterAsset) {
            if (this.state.filterAsset.value !== ticket.deviceId) {
                return false
            }
        }

        switch (this.state.filterDeadline.value) {
            case 'All':
                break
            case 'Overdue':
                if (
                    moment(
                        ticket.metaData.find((m) => m.name === 'deadline').value
                    ).unix() > moment().unix()
                )
                    return false
                break
            default:
                return false
        }

        const deadline = moment(
            ticket.metaData.find((o) => o.name === 'deadline').value
        ).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: 'ddd, MMM D',
            nextWeek: 'ddd, MMM D',
            sameElse: 'ddd, MMM D',
        })
        return {
            ...CARD_DEFAULT,
            title: <div>{ticket.department.value}</div>,
            id: ticket._id,
            description: (
                <Fragment>
                    <div>
                        <strong>{this.getDeviceName(ticket.deviceId)}</strong>
                    </div>
                    <div>{ticket.description}</div>
                    <div>{ticket.equipment ? ticket.equipment.value : ''}</div>
                    <div>
                        <strong>{ticket.assignment.value}</strong>
                    </div>
                </Fragment>
            ),
            label: <div>{deadline}</div>,
            metadata: {ticket},
        }
    }

    ticketsMatchColumn(columnName = '', tickets = this.state.tickets) {
        return this.state.tickets.filter((ticket) =>
            ticket.metaData.find(
                (meta) => meta.name === 'status' && meta.value === columnName
            )
        )
    }

    kanbanHandleDragEnd(
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
        cardDetails
    ) {
        this.toggleModal('editModal', cardDetails.metadata.ticket, targetLaneId)
    }

    kanbanHandleClick(cardId, metadata, laneId) {
        this.toggleModal('editModal', metadata.ticket)
    }

    async componentDidMount() {
        await this.fetchDevices()
        await this.fetchUsers()
        await this.fetchWorkflow()
        await this.fetchTickets()

        this.kanbanRenderLanes()
        this.setState({
            kanbanBoardElement: (
                <Board
                    data={this.state.kanban}
                    hideCardDeleteIcon={true}
                    eventBusHandle={setEventBus}
                    handleDragEnd={this.kanbanHandleDragEnd}
                    onCardClick={this.kanbanHandleClick}
                />
            ),
        })

        // this.subscriber.add(this.fetchWorkflows, 5000, 'fetchLiveData');
    }

    async toggleModal(modalName, code, targetLaneId) {
        let state = this.state
        state.editing = code
        state.targetLaneId = targetLaneId
        state[modalName] = !state[modalName]
        this.setState({state})

        // When then modal is closed, lets refresh kanban
        if (this.state[modalName] === false) {
            await this.fetchTickets()
            this.setState({kanbanBoardElement: <div />}, () => {
                this.kanbanRenderLanes()
                this.setState({
                    kanbanBoardElement: (
                        <Board
                            data={this.state.kanban}
                            hideCardDeleteIcon={true}
                            eventBusHandle={setEventBus}
                            handleDragEnd={this.kanbanHandleDragEnd}
                            onCardClick={this.kanbanHandleClick}
                        />
                    ),
                })
            })
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    filterStatusOnChange(filterStatus) {
        this.setState({filterStatus}, () => {
            this.setState({kanbanBoardElement: <div />}, () => {
                this.kanbanRenderLanes()
                this.setState({
                    kanbanBoardElement: (
                        <Board
                            data={this.state.kanban}
                            hideCardDeleteIcon={true}
                            eventBusHandle={setEventBus}
                            handleDragEnd={this.kanbanHandleDragEnd}
                            onCardClick={this.kanbanHandleClick}
                        />
                    ),
                })
            })
        })
    }

    filterDeadlineOnChange(filterDeadline) {
        this.setState({filterDeadline}, () => {
            this.setState({kanbanBoardElement: <div />}, () => {
                this.kanbanRenderLanes()
                this.setState({
                    kanbanBoardElement: (
                        <Board
                            data={this.state.kanban}
                            hideCardDeleteIcon={true}
                            eventBusHandle={setEventBus}
                            handleDragEnd={this.kanbanHandleDragEnd}
                            onCardClick={this.kanbanHandleClick}
                        />
                    ),
                })
            })
        })
    }

    filterAssetOnChange(filterAsset) {
        this.setState({filterAsset}, () => {
            this.setState({kanbanBoardElement: <div />}, () => {
                this.kanbanRenderLanes()
                this.setState({
                    kanbanBoardElement: (
                        <Board
                            data={this.state.kanban}
                            hideCardDeleteIcon={true}
                            eventBusHandle={setEventBus}
                            handleDragEnd={this.kanbanHandleDragEnd}
                            onCardClick={this.kanbanHandleClick}
                        />
                    ),
                })
            })
        })
    }

    filterSupervisorOnChange(filterSupervisor) {
        this.setState({filterSupervisor}, () => {
            this.setState({kanbanBoardElement: <div />}, () => {
                this.kanbanRenderLanes()
                this.setState({
                    kanbanBoardElement: (
                        <Board
                            data={this.state.kanban}
                            hideCardDeleteIcon={true}
                            eventBusHandle={setEventBus}
                            handleDragEnd={this.kanbanHandleDragEnd}
                            onCardClick={this.kanbanHandleClick}
                        />
                    ),
                })
            })
        })
    }

    kanbanSearchClear() {
        this.setState(
            {
                filterAsset: null,
                filterSupervisor: null,
                filterDeadline: FILTER_DEADLINE_OPTIONS[0],
                filterStatus: FILTER_STATUS_OPTIONS[0],
            },
            () => {
                this.setState({kanbanBoardElement: <div />}, () => {
                    this.kanbanRenderLanes()
                    this.setState({
                        kanbanBoardElement: (
                            <Board
                                data={this.state.kanban}
                                hideCardDeleteIcon={true}
                                eventBusHandle={setEventBus}
                                handleDragEnd={this.kanbanHandleDragEnd}
                                onCardClick={this.kanbanHandleClick}
                            />
                        ),
                    })
                })
            }
        )
    }

    render() {
        const toggleNavbar = () => this.state.setCollapsed()
        return (
            <Fragment>
                <Navbar color="light" light expand="md">
                    <Nav className="ml-auto" navbar>
                        <NavItem style={{width: 200}}>
                            <Label>Sort by status</Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="filterStatus"
                                placeholder="Filter | Status"
                                value={this.state.filterStatus}
                                onChange={this.filterStatusOnChange}
                                options={FILTER_STATUS_OPTIONS}
                            />
                        </NavItem>
                        <NavItem style={{width: 200, marginLeft: 15}}>
                            <Label>Sort by supervisor</Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="filterSupervisor"
                                placeholder="Filter | Supervisor"
                                value={this.state.filterSupervisor}
                                onChange={this.filterSupervisorOnChange}
                                options={this.state.users.map((user) => {
                                    return {
                                        label: `${user.username} (${user.firstName} ${user.lastName})`,
                                        value: user.username,
                                    }
                                })}
                            />
                        </NavItem>
                        <NavItem style={{width: 200, marginLeft: 15}}>
                            <Label>Sort by deadline</Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="filterDeadline"
                                placeholder="Filter | Deadline"
                                value={this.state.filterDeadline}
                                onChange={this.filterDeadlineOnChange}
                                options={FILTER_DEADLINE_OPTIONS}
                            />
                        </NavItem>
                        <NavItem style={{width: 200, marginLeft: 15}}>
                            <Label>Sort by asset</Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="filterAsset"
                                placeholder="Filter | Asset"
                                value={this.state.filterAsset}
                                onChange={this.filterAssetOnChange}
                                options={this.state.devices.map((device) => {
                                    return {
                                        label: device.name,
                                        value: device.deviceId,
                                    }
                                })}
                            />
                        </NavItem>
                        <NavItem style={{marginLeft: 15, marginTop: 12}}>
                            <Button onClick={this.kanbanSearchClear}>
                                Clear
                            </Button>
                        </NavItem>
                    </Nav>
                </Navbar>
                {this.state.kanbanBoardElement}
                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        tickets={this.state.tickets}
                        editing={this.state.editing}
                        workflows={this.state.workflows}
                        status={this.state.targetLaneId}
                    />
                ) : null}
            </Fragment>
        )
    }
}
