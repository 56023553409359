import React, {Component, Fragment} from 'react'
import 'react-table/react-table.css'
import {Row, Col, Card, CardBody, Alert, Form, Label} from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import {Typeahead} from 'react-bootstrap-typeahead'

import {paginate} from '../HubbellWadsworth/Paginate'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'
import ParetoChart from './ParetoChart'
import {secondsToHHMMSS} from 'SDK/helpers'

// TODO: filter incomplete work orders

export default class HubbellWorkOrderSummary extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    requiredOutputs = []
    showBorder = false
    id = 'HubbellWorkOrderSummary'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            assets: [],
            dataModel: undefined,
            field: undefined,
            selectedWorkOrder: undefined,
            workorders: [],
            data: {
                oee: 100,
                availability: 100,
                performance: 100,
                quality: 100,
                avgCycleTime: '00:00:00',
            },
            chartdata: {labels: [], datasets: []},

            loading: true,
            ready: false,
        }
    }

    handleWorkOrderChange(selectedWorkOrder) {
        this.setState({selectedWorkOrder}, () => {
            if (selectedWorkOrder.length) this.generate()
            else this.setState({ready: false})
        })
    }

    async generate() {
        this.setState({loading: true})
        let workorder = this.state.selectedWorkOrder[0].value
        workorder.timeStart = moment(workorder.timeStart)
        workorder.timeEnd = moment(workorder.timeEnd)

        let oeeData = await API.post(
            'oee',
            {
                factoryId: JSON.parse(localStorage['userObject']).factoryId,
                deviceId: workorder.deviceId,
                timeStart: workorder.timeStart.format('YYYY-MM-DD HH:mm'),
                timeEnd: workorder.timeEnd.format('YYYY-MM-DD HH:mm'),
                speedLossType:
                    this.state.assets.find(
                        (a) => a.deviceId === workorder.deviceId
                    ).speedLossType || 'fixedHourlyTarget',
                exactRange: true,
            },
            2
        )

        oeeData = oeeData.data

        let ppt = oeeData.reduce((a, b) => a + b.ppt, 0),
            time_losses = oeeData.reduce((a, b) => a + b.time_losses, 0),
            speed_losses = oeeData.reduce((a, b) => a + b.speed_losses, 0),
            quality_losses = oeeData.reduce((a, b) => a + b.quality_losses, 0),
            data = {
                oee: parseInt(
                    ((ppt - time_losses - speed_losses - quality_losses) /
                        ppt) *
                        100
                ),
                availability: parseInt(((ppt - time_losses) / ppt) * 100),
                performance: parseInt(((ppt - speed_losses) / ppt) * 100),
                quality: parseInt(((ppt - quality_losses) / ppt) * 100),
            }

        // downtime
        let paretoData = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: workorder.deviceId,
                    name: {$in: ['Downtime Reason']},
                    timeStart: {
                        $lte: workorder.timeEnd.toISOString(),
                    },
                    $or: [
                        {
                            timeEnd: {
                                $gte: workorder.timeStart.toISOString(),
                            },
                        },
                        {
                            timeEnd: null,
                        },
                    ],
                },
            },
            2
        )
        let reasons = [],
            cumulative = [],
            total = 0
        paretoData = paretoData.map((a) => {
            a.timeStart = moment(a.timeStart).isBefore(workorder.timeStart)
                ? moment(workorder.timeStart)
                : moment(a.timeStart)
            a.timeEnd =
                a.timeEnd && moment(a.timeEnd).isBefore(workorder.timeEnd)
                    ? moment(a.timeEnd)
                    : moment(a.timeEnd)
            a.duration = a.timeEnd.diff(a.timeStart, 'minutes')
            return a
        })
        for (let row of paretoData) {
            if (!reasons.find((a) => a.name === row.value)) {
                reasons.push({
                    name: row.value,
                    duration: 0,
                })
            }
            const index = reasons.findIndex((a) => a.name === row.value)
            reasons[index].duration += row.duration
        }
        reasons = reasons.sort((a, b) => (a.duration > b.duration ? -1 : 1))

        total = reasons.reduce((a, b) => a + b.duration, 0)

        cumulative = reasons.length
            ? [(reasons[0].duration / total) * 100]
            : [100]

        for (let i = 1; i < reasons.length; i++) {
            cumulative.push(
                cumulative[i - 1] + (reasons[i].duration / total) * 100
            )
        }

        if (cumulative.length) {
            cumulative[cumulative.length - 1] = 100
        }

        let chartdata = {
            labels: reasons.map((a) => a.name),
            datasets: [
                {
                    yAxisID: 'b',
                    type: 'line',
                    label: 'Cumulative',
                    data: cumulative,
                    borderColor: '#2a93d5',
                    backgroundColor: 'transparent',
                },
                {
                    yAxisID: 'a',
                    label: 'Downtime Reasons',
                    data: reasons.map(
                        (a) => Math.floor((a.duration / 60) * 100) / 100
                    ),
                    backgroundColor: '#145388',
                },
            ],
        }

        // cycle time
        let totalParts = await API.post('historical/aggregate2', {
            timeStart: moment(workorder.timeStart).toISOString(),
            timeEnd: moment(workorder.timeEnd).toISOString(),
            state: ['Part Count'],
            deviceId: [workorder.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })
        data.avgCycleTime =
            (ppt - time_losses) /
            totalParts.data.devices[workorder.deviceId]['Part Count'][0].count
        data.avgCycleTime = secondsToHHMMSS(parseInt(data.avgCycleTime * 60))

        this.setState({chartdata, data, ready: true, loading: false})
    }

    async componentDidMount() {
        const dataModel = await API.get(
            'data-models/fetch-by-name/Work Orders',
            2
        )
        if (!dataModel) {
            return alert('Could not find "Work Orders" model.')
        }
        const field = dataModel.fields.find((a) => a.type === 'PrimaryID')

        let workorders = await paginate(dataModel._id, [], {})
        workorders = workorders.map((a) => {
            return {
                label: a[field.name],
                value: a,
            }
        })

        this.setState({
            assets: await API.get('devices'),
            dataModel,
            workorders,
            field,
            loading: false,
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        <Alert color="primary" className="text-center">
                            <p>
                                <strong>
                                    This report requires a data model called
                                    "Work Orders".
                                </strong>
                            </p>
                            <p>
                                If work order data has been or is currently
                                being stored in the Accumine Cloud, speak to
                                your Accumine Account Manager about exposing the
                                existing work order data to the "Work Order"
                                model.{' '}
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Typeahead
                                            id={Math.random()}
                                            labelKey={(option) =>
                                                `${option.label}`
                                            }
                                            options={this.state.workorders.sort(
                                                (a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                            )}
                                            renderMenuItemChildren={(option) =>
                                                option.label
                                            }
                                            onChange={
                                                this.handleWorkOrderChange
                                            }
                                        />
                                        <IntlMessages id="Select or search for a work order" />
                                    </Label>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                {this.state.ready && this.state.selectedWorkOrder.length ? (
                    <Fragment>
                        <Row className="mb-2">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row className="text-center">
                                            <Col>
                                                <p>Work Order #</p>
                                                <h4>
                                                    {
                                                        this.state
                                                            .selectedWorkOrder[0]
                                                            .value
                                                            .WorkOrderNumber
                                                    }
                                                </h4>
                                            </Col>
                                            <Col>
                                                <p>Workcenter</p>
                                                <h4>
                                                    {
                                                        this.state.assets.find(
                                                            (a) =>
                                                                a.deviceId ===
                                                                this.state
                                                                    .selectedWorkOrder[0]
                                                                    .value
                                                                    .deviceId
                                                        ).name
                                                    }
                                                </h4>
                                            </Col>
                                            <Col>
                                                <p>Work Order Start Time</p>
                                                <h4>
                                                    {moment(
                                                        this.state
                                                            .selectedWorkOrder[0]
                                                            .value.timeStart
                                                    ).format('lll')}
                                                </h4>
                                            </Col>
                                            <Col>
                                                <p>Work Order End Time</p>
                                                <h4>
                                                    {moment(
                                                        this.state
                                                            .selectedWorkOrder[0]
                                                            .value.timeEnd
                                                    ).format('lll')}
                                                </h4>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center">
                                                <h5>OEE</h5>
                                                <h1>{this.state.data.oee}%</h1>
                                            </Col>
                                            <Col className="text-center">
                                                <h5>
                                                    Average Cycle Time
                                                    (hh:mm:ss)
                                                </h5>
                                                <h1>
                                                    {
                                                        this.state.data
                                                            .avgCycleTime
                                                    }
                                                </h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-center">
                                                <p>Availability</p>
                                                <h3>
                                                    {
                                                        this.state.data
                                                            .availability
                                                    }
                                                    %
                                                </h3>
                                            </Col>
                                            <Col className="text-center">
                                                <p>Performance</p>
                                                <h3>
                                                    {
                                                        this.state.data
                                                            .performance
                                                    }
                                                    %
                                                </h3>
                                            </Col>
                                            <Col className="text-center">
                                                <p>Quality</p>
                                                <h3>
                                                    {this.state.data.quality}%
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <ParetoChart
                                            data={this.state.chartdata}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <h2 className="text-center">
                        Choose a work order above...
                    </h2>
                )}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
