import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DataTablePagination from 'components/DataTables/pagination'

import './CrudTable.css'

export default class CrudTable extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                Header: PropTypes.string.isRequired,
                accessor: PropTypes.string.isRequired,
            })
        ).isRequired,
        resolveDataMapFn: PropTypes.func,
        rowClickFn: PropTypes.func.isRequired,
        data: PropTypes.array,
    }
    static defaultProps = {
        resolveDataMapFn: (a) => {
            return a
        },
        data: [],
    }
    render() {
        const {columns, resolveDataMapFn, rowClickFn, data} = this.props

        return (
            <Fragment>
                <ReactTable
                    defaultPageSize={20}
                    data={data}
                    resolveData={(data) => data.map(resolveDataMapFn)}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                rowClickFn(rowInfo.original)
                                if (handleOriginal) {
                                    handleOriginal()
                                }
                            },
                        }
                    }}
                    columns={columns}
                    minRows={0}
                    PaginationComponent={DataTablePagination}
                    loading={false}
                    filterable={true}
                />
            </Fragment>
        )
    }
}
