import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {tileColor} from 'SDK/helpers'

import Timeline from './Timeline'
import CircularProgressBar from './CircularProgressBar'
import Timer from './Timer'
import * as SDK from './sdk'

import './index.css'

export default class RealTimeWorkOrderStatusTile extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
        {
            prop: 'flash',
            component: 'RealTimeTileFlash',
        },
        {
            prop: 'aggregation',
            component: 'RealTimeAggregation',
        },
        {
            prop: 'black',
            component: 'BlackText',
        },
    ]
    requiredOutputs = ['Part Count', 'Work Order', 'In-Cycle', 'Downtime']
    showBorder = false
    id = 'RealTimeWorkOrderStatusTile'
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
        aggregation: PropTypes.number,
        flash: PropTypes.bool,
        black: PropTypes.bool,
    }
    static defaultProps = {
        black: false,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.color = '#fff'

        if (this.props && this.props.black) {
            this.color = '#000'
        }

        this.state = {
            name: '',
            activeState: {
                color: 'rgb(35, 34, 35)',
                name: 'No data',
                timestamp: null,
                elapsed: '0:00',
            },
            activeShift: {
                name: 'Today',
                timeStart: moment().startOf('day'),
                timeEnd: moment(),
            },
            workOrder: {
                timestamp: null,
                name: 'Fetching Work Order...',
            },
            shifts: [],
            flash: false,
            ranOnce: false,
            timelineDateIndex: 0,
            timelineShiftIndex: 0,
            timelineTimeStart: moment().startOf('day'),
            timelineTimeEnd: null,
            timelineShiftName: 'Today',
        }
    }

    async getWorkOrder() {
        this.setState({
            workOrder: await SDK.getWorkOrder(this.props.deviceId),
        })
    }

    async getLiveStatus() {
        const {activeState, activeShift} = await SDK.getAssetStatus(
            this.props.deviceId,
            this.state.shifts
        )

        if (
            activeShift.name !== this.state.activeShift.name &&
            this.state.timelineDateIndex === 0
        ) {
            this.setState({activeState, activeShift}, this.currentShift)
        } else {
            this.setState({activeState, activeShift})
        }
    }

    updateDowntimeFlashStatus() {
        if (this.state.activeState.timestamp === null) return
        let activeState = {...this.state.activeState}
        let flash = false

        if (
            activeState.name === 'Downtime' &&
            moment().diff(moment(activeState.timestamp), 'minutes') >= 5
        ) {
            flash = true
        }
        this.setState({flash})
    }

    async fetchDeviceName() {
        this.setState({
            name: await SDK.getDeviceName(this.props.deviceId),
        })
    }

    async fetchShiftSchedule() {
        this.setState({
            shifts: await SDK.getShiftSchedule(this.props.deviceId),
        })
    }

    currentShift() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            activeShift,
            shifts,
            timelineShiftName,
        } = this.state
        timelineDateIndex = 0
        timelineTimeStart = activeShift.timeStart
        timelineTimeEnd = null
        timelineShiftIndex = shifts.findIndex(
            (shift) => shift.name === activeShift.name
        )
        timelineShiftName = activeShift.name

        this.setState({
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            timelineShiftName,
        })
    }

    backward() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            shifts,
            timelineShiftName,
        } = this.state

        if (timelineShiftIndex === 0) {
            timelineDateIndex--
            timelineShiftIndex = shifts.length - 1
        } else {
            timelineShiftIndex--
        }

        let shift = shifts[timelineShiftIndex],
            date = moment().add(timelineDateIndex, 'days'),
            shiftTimerange = SDK.getShiftTimerange(date, shift)

        this.setState({
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart: shiftTimerange[0],
            timelineTimeEnd: shiftTimerange[1],
            timelineShiftName: shift.name,
        })
    }

    forward() {
        let {
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart,
            timelineTimeEnd,
            shifts,
            timelineShiftName,
        } = this.state

        if (timelineShiftIndex === shifts.length - 1) {
            timelineDateIndex++
            timelineShiftIndex = 0
        } else {
            timelineShiftIndex++
        }

        let shift = shifts[timelineShiftIndex],
            date = moment().add(timelineDateIndex, 'days'),
            shiftTimerange = SDK.getShiftTimerange(date, shift)

        this.setState({
            timelineDateIndex,
            timelineShiftIndex,
            timelineTimeStart: shiftTimerange[0],
            timelineTimeEnd: shiftTimerange[1].isAfter(moment())
                ? null
                : shiftTimerange[1],
            timelineShiftName: shift.name,
        })
    }

    updateUtilization(util) {
        let utilization = Math.floor(util * 100)
        this.setState({utilization})
    }

    async componentWillMount() {
        await this.fetchDeviceName()
        await this.fetchShiftSchedule()
        await this.getLiveStatus()
        this.currentShift()
        this.setState({ranOnce: true})

        this.subscriber.add(this.getWorkOrder, 1000 * 60, 'getWorkOrder')
        this.subscriber.add(this.getLiveStatus, 1000 * 15, 'getLiveStatus')
        if (this.props.flash)
            this.subscriber.add(
                this.updateDowntimeFlashStatus,
                1000,
                'updateDowntimeFlashStatus'
            )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const workOrderExists = this.state.workOrder.name !== 'No Work Order',
            disableForward =
                this.state.timelineDateIndex === 0 &&
                this.state.timelineShiftName === this.state.activeShift.name

        return (
            <Card
                className={this.state.flash ? 'flash' : ''}
                style={{background: this.state.activeState.color}}>
                <CardHeader
                    style={{
                        color: this.color,
                        background: 'rgba(0,0,0,0.1)',
                        marginTop: '0px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}>
                    <h3 style={{textAlign: 'center', margin: '0'}}>
                        <strong>{this.state.name}</strong>
                    </h3>
                </CardHeader>
                <CardBody style={{padding: '0px', margin: '0px'}}>
                    <Row>
                        <Col xs="12" sm="6" className="text-center mt-5">
                            <h1
                                style={{
                                    color: this.color,
                                    margin: '0',
                                    padding: '0',
                                }}>
                                <strong>
                                    <Timer
                                        timestamp={
                                            this.state.activeState.timestamp
                                        }
                                    />
                                </strong>
                            </h1>
                            <p style={{color: this.color, textAlign: 'center'}}>
                                <strong>{this.state.activeState.name}</strong>
                            </p>
                        </Col>
                        <Col xs="12" sm="6" className="mt-2 text-center">
                            <strong>
                                <p style={{color: this.color, margin: '0'}}>
                                    {this.state.timelineShiftName}
                                </p>
                            </strong>
                            <CircularProgressBar
                                strokeWidth="10"
                                percentage={this.state.utilization}
                                color={this.color}
                            />
                        </Col>
                    </Row>
                    {this.state.ranOnce ? (
                        <Fragment>
                            <Timeline
                                deviceId={this.props.deviceId}
                                showReasons={false}
                                timeStart={this.state.timelineTimeStart}
                                timeEnd={this.state.timelineTimeEnd}
                                shiftName={this.state.timelineShiftName}
                                updateUtilization={this.updateUtilization}
                                aggregation={this.props.aggregation}
                                color={this.color}
                            />
                            <Row>
                                <Col className="text-center">
                                    <ButtonGroup>
                                        <Button
                                            size="xs"
                                            color="primary"
                                            disabled={this.state.loading}
                                            onClick={this.backward}>
                                            <i className="simple-icon-arrow-left" />
                                        </Button>
                                        <Button
                                            size="xs"
                                            color="primary"
                                            disabled={
                                                disableForward ||
                                                this.state.loading
                                            }
                                            onClick={this.currentShift}>
                                            Current Shift
                                        </Button>
                                        <Button
                                            size="xs"
                                            color="primary"
                                            disabled={
                                                disableForward ||
                                                this.state.loading
                                            }
                                            onClick={this.forward}>
                                            <i className="simple-icon-arrow-right" />
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}
                </CardBody>
                <CardFooter>
                    {workOrderExists ? (
                        <Row>
                            <Col xs="8">
                                <p
                                    style={{
                                        color: this.color,
                                        textAlign: 'left',
                                        margin: 0,
                                    }}>
                                    <strong>{this.state.workOrder.name}</strong>
                                </p>
                            </Col>
                            <Col xs="4">
                                <p
                                    style={{
                                        color: this.color,
                                        textAlign: 'right',
                                        margin: 0,
                                    }}>
                                    <strong>
                                        <Timer
                                            timestamp={
                                                this.state.workOrder.timestamp
                                            }
                                        />
                                    </strong>
                                </p>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <p
                                    style={{
                                        color: this.color,
                                        textAlign: 'center',
                                        margin: 0,
                                    }}>
                                    <strong>{this.state.workOrder.name}</strong>
                                </p>
                            </Col>
                        </Row>
                    )}
                </CardFooter>
            </Card>
        )
    }
}
