import React, {Component, Fragment} from 'react'
import {
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Input,
    Label,
    Row,
    Col,
    Button,
} from 'reactstrap'
import autoBind from 'auto-bind'
import {models} from './models'
import {references} from './references'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            options: Object.keys(models)
                .filter((a) => !this.props.dataModels.find((b) => b.name === a))
                .map((a) => {
                    return {label: a, checked: false}
                }),
            log: [],
        }
    }

    checkAll() {
        this.setState({
            options: this.state.options.map(({label}) => {
                return {label, checked: true}
            }),
        })
    }

    handleChange(e) {
        let {options} = this.state
        options[options.findIndex((a) => a.label === e.target.name)].checked =
            e.target.checked
        this.setState({options})
    }

    async importSelected() {
        let options = this.state.options
                .filter((a) => a.checked)
                .map((a) => a.label),
            log = this.state.log
        for (let option of options) {
            await models[option]()
            log.push(
                <p>
                    <b>{option}</b> successfully imported.
                </p>
            )
            this.setState({log})
        }
        await this.props.syncDataModels()
        for (let option of options) {
            await references[option](this.props.dataModels)
            log.push(
                <p>
                    <b>{option}</b> references generated.
                </p>
            )
            this.setState({log})
        }
        await this.props.syncDataModels()
    }

    render() {
        let {toggle} = this.props

        return (
            <Fragment>
                <Modal size="lg" isOpen={true} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        Import Classic & Templated Models
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Alert color="primary">
                                    References are automatically generated for
                                    imported data models if the references
                                    models exist.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Button block onClick={this.checkAll}>
                                    Check All
                                </Button>
                            </Col>
                            <Col xs="10">
                                <Button
                                    block
                                    onClick={this.importSelected}
                                    disabled={this.state.options.every(
                                        (a) => !a.checked
                                    )}>
                                    Import Selected
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4">
                                {this.state.options.map(
                                    ({label, checked}, index) => {
                                        return (
                                            <FormGroup key={index} check>
                                                <Input
                                                    type="checkbox"
                                                    name={label}
                                                    id={label}
                                                    checked={checked}
                                                    onChange={this.handleChange}
                                                />
                                                <Label for={label} check>
                                                    {label}
                                                </Label>
                                            </FormGroup>
                                        )
                                    }
                                )}
                            </Col>
                            <Col xs="8">{this.state.log}</Col>
                        </Row>
                    </ModalBody>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
