import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import TextInput from '../../../../common/text-input'

export default (props) => {
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'OnDemandTrigger',
        description: props.element.data.opts.description || '',
        variableId: props.element.data.opts.variableId || null,
        path: props.element.data.opts.path || '',
        submitted: props.element.data.opts.submitted || true,
    })

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: 'Flow Start Timestamp',
            transformId: props.element.id,
            type: 'Timestamp',
            multi: false,
        }

        parameters.path = `${
            JSON.parse(localStorage['userObject']).factoryId
        }/cloud/${props.flowId}/${props.element.id}/trigger`
        parameters.variableId = variable.id

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
