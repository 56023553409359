import React, {Component, Fragment} from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Input,
} from 'reactstrap'
import {Scrollbars} from 'react-custom-scrollbars'
import autoBind from 'auto-bind'
import Sortable from 'react-sortablejs'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.height = document.documentElement.offsetHeight * 0.6

        const fields = this.props.reportData.schema.fields.map((a) => {
            a.alias = a.alias || a.name
            a.visible = a.visible !== undefined ? a.visible : true
            return a
        })

        this.state = {
            fields: fields,
        }
    }

    handleShowChange(name) {
        let {fields} = this.state
        let index = fields.findIndex((a) => a.name === name)
        fields[index].visible = !fields[index].visible

        this.setState({fields})
    }

    handleAliasChange(name, alias) {
        let {fields} = this.state
        let index = fields.findIndex((a) => a.name === name)
        fields[index].alias = alias

        this.setState({fields})
    }

    submit() {
        this.props.setReportDataColumns(this.state.fields)
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={open}
                    toggle={toggle}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggle}>
                        <strong>Edit Report Columns</strong>
                    </ModalHeader>
                    <ModalBody style={{height: '100%'}}>
                        <p>Drag & drop columns to change their order.</p>
                        <Button
                            className="mb-2"
                            size="xs"
                            onClick={() =>
                                this.setState({
                                    checked: this.state.checked.map(
                                        (a) => false
                                    ),
                                })
                            }>
                            Uncheck all
                        </Button>
                        <Row>
                            <Col xs="4">
                                <h5>
                                    <strong>Show</strong>
                                </h5>
                            </Col>
                            <Col xs="4">
                                <h5>
                                    <strong>Name</strong>
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Scrollbars
                            autoHeight
                            autoHeightMin={this.height}
                            autoHeightMax={this.height}>
                            <Sortable
                                onChange={(order) =>
                                    this.setState({
                                        fields: order.map((a) =>
                                            this.state.fields.find(
                                                (b) => b.name === a
                                            )
                                        ),
                                    })
                                }>
                                {this.state.fields.map((a, i) => {
                                    return (
                                        <div key={a.name} data-id={a.name}>
                                            <Row>
                                                <Col
                                                    xs="4"
                                                    style={{
                                                        paddingLeft: 50,
                                                        paddingRight: 0,
                                                    }}>
                                                    <Input
                                                        type="checkbox"
                                                        onChange={() =>
                                                            this.handleShowChange(
                                                                a.name
                                                            )
                                                        }
                                                        checked={a.visible}
                                                    />
                                                </Col>
                                                <Col xs="8">
                                                    <Input
                                                        type="text"
                                                        onChange={(e) =>
                                                            this.handleAliasChange(
                                                                a.name,
                                                                e.target.value
                                                            )
                                                        }
                                                        value={a.alias}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <hr />
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </Sortable>
                        </Scrollbars>
                    </ModalBody>

                    <ModalFooter>
                        <Button block color="primary" onClick={this.submit}>
                            SAVE CHANGES
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
