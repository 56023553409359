import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Label,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'
import Sortable from 'react-sortablejs'
import {CompactPicker} from 'react-color'

import './checklist.css'

const DEFAULT_COLOR = 'rgba(0,0,100,0.9)'

var STATUS_DEFAULTS = [
    {id: 0, name: 'Opened', color: DEFAULT_COLOR},
    {id: 1, name: 'On Hold', color: DEFAULT_COLOR},
    {id: 2, name: 'In Progress', color: DEFAULT_COLOR},
    {id: 3, name: 'Complete', color: DEFAULT_COLOR},
]

var REPEAT_DEFAULTS = [
    {id: 0, name: 'Weekly'},
    {id: 1, name: 'Monthly'},
    {id: 2, name: 'Quarterly'},
    {id: 3, name: 'Yearly'},
    {id: 4, name: 'Daily'},
    {id: 5, name: 'No Repeat'},
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            name: this.props.editing.metaData.find((o) => o.name === 'name')
                .value,
            description: this.props.editing.metaData.find(
                (o) => o.name === 'description'
            ).value,
            deviceId: this.props.editing.metaData.find(
                (o) => o.name === 'asset'
            )
                ? this.props.editing.metaData.find((o) => o.name === 'asset')
                      .value
                : 'N/A',
            equipment: this.props.editing.metaData.find(
                (o) => o.name === 'equipment'
            )
                ? this.props.editing.metaData.find(
                      (o) => o.name === 'equipment'
                  ).value
                : 'N/A',
            color: this.props.editing.metaData.find((o) => o.name === 'color')
                .value,
            valid: false,
            message: false,
            checklists: this.props.checklists ? this.props.checklists : [],
            checklist: this.props.editing,
            order: this.props.editing.value,
            status: {name: '', color: 'rgba(0,0,100,0.9)'},
            dropdownTypeOpen: false,
            repeatType: this.props.editing.metaData.find(
                (o) => o.name === 'repeat'
            ).value,
            devices: [],
        }

        this.fetchDevices()
    }

    dropdownTypeToggle() {
        this.setState({dropdownTypeOpen: !this.state.dropdownTypeOpen})
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
    }

    async update() {
        const timestamp = moment().toISOString()
        const updatedChecklist = {
            timestamp: timestamp,
            timeStart: timestamp,
            timeEnd: null,
            name: 'Checklist',
            metaData: [
                {
                    name: 'setByUser',
                    value: JSON.parse(localStorage['userObject'])._id,
                },
                {
                    name: 'name',
                    value: this.state.name,
                },
                {
                    name: 'description',
                    value: this.state.description,
                },
                {
                    name: 'color',
                    value: this.state.color,
                },
                {
                    name: 'repeat',
                    value: this.state.repeatType,
                },
                {
                    name: 'asset',
                    value: this.state.deviceId,
                },
                {
                    name: 'equipment',
                    value: this.state.equipment,
                },
            ],
            value: this.state.order,
        }

        await API.patch(
            'states/' + this.state.checklist._id,
            updatedChecklist,
            2
        )

        this.props.onSave()
        this.props.toggleModal('checklistEditModal', this.state.checklist)
    }

    handleChangeComplete = (picker) => {
        this.setState({color: picker.hex})
    }

    addStatus = () => {
        let status = this.state.status
        let order = this.state.order
        status.id = order.length
        order.push(status)
        order.forEach((entry, i) => (entry.id = i))
        this.setState({order, status})
        this.clearStatus()
    }

    clearStatus = () => {
        this.setState({status: {id: 0, name: '', color: DEFAULT_COLOR}})
    }

    removeItem = (itemId) => {
        let order = this.state.order
        let filtered = order.filter(function (value, index, arr) {
            return value.id != itemId
        })
        this.setState({order: filtered})
    }

    renderItem(item) {
        return (
            <div
                key={item.id}
                data-id={item.id}
                className="container"
                style={{marginBottom: 25}}>
                <div className="round">
                    <input type="checkbox" id={item.id} />
                    <Label for={item.id}></Label>
                    <span
                        style={{
                            marginLeft: 25,
                            lineHeight: '28px',
                            verticalAlign: 'middle',
                        }}>
                        {item.name}
                    </span>
                    <span
                        color="none"
                        style={{
                            float: 'right',
                            height: '100%',
                            cursor: 'pointer',
                            color: '#AAA',
                            lineHeight: '28px',
                            verticalAlign: 'middle',
                        }}
                        onClick={() => {
                            this.removeItem(item.id)
                        }}>
                        <i
                            className="simple-icon-trash"
                            style={{color: '#555'}}
                        />
                    </span>
                </div>
            </div>
        )
    }

    async fetchDevices() {
        let devices = await API.get('devices')
        if (!devices) {
            return alert('There was an error fetching data')
        }
        this.setState({devices})
    }

    getDeviceName(deviceId) {
        return this.state.devices.find((a) => a.deviceId === deviceId)
            ? this.state.devices.find((a) => a.deviceId === deviceId).name
            : 'N/A'
    }

    render() {
        const {modal, toggleModal, editing} = this.props
        const {checklist, equipment} = this.state
        const equipmentName = () => equipment && equipment.label

        return (
            <Fragment>
                <Modal size="lg" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Edit Checklist
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <strong>Name list</strong>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(input) => {
                                        let name = this.state.name
                                        name = input.target.value
                                        this.setState({name})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <strong>Description of list</strong>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={(input) => {
                                        let description = this.state.description
                                        description = input.target.value
                                        this.setState({description})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <strong>Asset</strong>
                                <br />
                                {this.getDeviceName(this.state.deviceId)}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <strong>Equipment</strong>
                                <br />
                                {equipmentName}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <Label
                                    for="ButtonDropdown"
                                    style={{marginTop: 7.5, marginBottom: 7.5}}>
                                    Repeat this checklist?
                                </Label>
                                <br />
                                <ButtonDropdown
                                    isOpen={this.state.dropdownTypeOpen}
                                    toggle={this.dropdownTypeToggle}>
                                    <Button id="caret" color="primary">
                                        {this.state.repeatType}
                                    </Button>
                                    <DropdownToggle caret color="primary" />
                                    <DropdownMenu>
                                        {REPEAT_DEFAULTS.map((item) => {
                                            return (
                                                <DropdownItem
                                                    key={item.id}
                                                    onClick={() => {
                                                        this.setState({
                                                            repeatType:
                                                                item.name,
                                                        })
                                                    }}>
                                                    {item.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <Label
                                    for="CompactPicker"
                                    style={{marginTop: 7.5, marginBottom: 7.5}}>
                                    Choose Color:
                                </Label>
                                <br />
                                <CompactPicker
                                    color={this.state.color}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="mb-4">
                                <strong>Re-order the checklist:</strong>
                                <hr />
                                <Sortable
                                    list={this.state.order}
                                    setList={(newState) =>
                                        this.setState({order: newState})
                                    }
                                    animation={200}
                                    delayOnTouchStart={true}
                                    delay={2}
                                    onChange={(newOrder, sort) => {
                                        const newItems = _.sortBy(
                                            this.state.order,
                                            (item) => {
                                                return newOrder.indexOf(
                                                    item.id.toString()
                                                )
                                            }
                                        )
                                        newItems.forEach(
                                            (entry, i) => (entry.id = i)
                                        )
                                        this.setState({order: newItems})
                                    }}>
                                    {this.state.order.map((item) =>
                                        this.renderItem(item)
                                    )}
                                </Sortable>
                            </Col>
                            <Col xs="6" className="mb-4">
                                <strong>Create new checklist item:</strong>
                                <hr />
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.status.name}
                                    onChange={(name) => {
                                        let status = this.state.status
                                        status.name = name.target.value
                                        this.setState({status})
                                    }}
                                />
                                <Button
                                    color="secondary"
                                    disabled={false}
                                    onClick={this.addStatus}
                                    style={{marginTop: 15}}>
                                    Add Checklist Item
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={this.update}
                            style={{marginTop: 15}}>
                            Update Checklist
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
