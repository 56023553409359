import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button, Label} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import {CSVLink, CSVDownload} from 'react-csv'
import PropTypes from 'prop-types'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

import PreviewTable from './previewTable'

export default class DataExplorer extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'DataExplorer'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            outputs: [],
            devices: {},
            selectedOutput: null,
            loading: false,
            showTable: false,
            showExport: false,
            assets: [],
            selectedAssets: [],
        }
    }
    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }

    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }

    handleAssetChange(e) {
        this.setState({
            selectedAssets: e,
        })
    }

    async generate() {
        this.setState({loading: true, showExport: false})

        let columns = [
            {
                Header: 'Asset Name',
                accessor: 'assetName',
            },
            {
                Header: 'Asset Group',
                accessor: 'assetGroup',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Start Time',
                accessor: 'timeStart',
            },
            {
                Header: 'End Time',
                accessor: 'timeEnd',
            },
            {
                Header: 'Duration (seconds)',
                accessor: 'duration',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
        ]

        let allData = []

        for (
            let m = moment(this.state.start);
            m.isBefore(moment(this.state.end).add(1, 'days'));
            m.add(1, 'days')
        ) {
            let data = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: {
                            $in: this.state.selectedAssets.map(
                                (asset) => asset.value
                            ),
                        },
                        timestamp: {
                            $gte: moment(m).toISOString(),
                            $lte: moment(m).add(1, 'days').toISOString(),
                        },
                        name: this.state.selectedOutput.label,
                    },
                    options: {
                        sort: {
                            timestamp: 1,
                        },
                    },
                },
                2
            )
            data = data.map((row) => {
                let timeEnd = row.timeEnd === null ? moment() : row.timeEnd

                let obj = {
                    assetName: this.state.devices[row.deviceId].name,
                    assetGroup: this.state.devices[row.deviceId].group,
                    name: row.name,
                    timeStart: moment(row.timeStart).format(
                        'YYYY-MM-DD HH:mm:ss'
                    ),
                    timeEnd: moment(timeEnd).format('YYYY-MM-DD HH:mm:ss'),
                    duration: moment(timeEnd).diff(
                        moment(row.timeStart),
                        'seconds'
                    ),
                    value: row.value || '',
                }

                for (let i in row.metaData) {
                    const {name, value} = row.metaData[i]
                    if (!columns.find((c) => c.accessor === name)) {
                        columns.push({
                            Header: name,
                            accessor: name,
                        })
                    }
                    obj[name] = value
                }

                return obj
            })
            allData = allData.concat(data)
        }

        this.setState({
            loading: false,
            showTable: false,
            showExport: true,
            data: allData,
            columns: columns,
        })
    }

    async preview() {
        this.setState({loading: true, showExport: false})

        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: this.state.selectedAssets.map(
                            (asset) => asset.value
                        ),
                    },
                    timestamp: {
                        $gte: moment(this.state.start).toISOString(),
                        $lte: moment(this.state.end).toISOString(),
                    },
                    name: this.state.selectedOutput.label,
                },
                options: {
                    limit: 20,
                    sort: {
                        timestamp: -1,
                    },
                },
            },
            2
        )

        let columns = [
            {
                Header: 'Asset Name',
                accessor: 'assetName',
            },
            {
                Header: 'Asset Group',
                accessor: 'assetGroup',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Start Time',
                accessor: 'timeStart',
            },
            {
                Header: 'End Time',
                accessor: 'timeEnd',
            },
            {
                Header: 'Duration (seconds)',
                accessor: 'duration',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
        ]

        data = data.map((row) => {
            let timeEnd = row.timeEnd === null ? moment() : row.timeEnd

            let obj = {
                assetName: this.state.devices[row.deviceId].name,
                assetGroup: this.state.devices[row.deviceId].group,
                name: row.name,
                timeStart: moment(row.timeStart).format('YYYY-MM-DD HH:mm:ss'),
                timeEnd: moment(timeEnd).format('YYYY-MM-DD HH:mm:ss'),
                duration: moment(timeEnd).diff(
                    moment(row.timeStart),
                    'seconds'
                ),
                value: row.value || '',
            }

            for (let i in row.metaData) {
                const {name, value} = row.metaData[i]
                if (!columns.find((c) => c.accessor === name)) {
                    columns.push({
                        Header: name,
                        accessor: name,
                    })
                }
                obj[name] = value
            }

            return obj
        })

        this.setState({
            loading: false,
            showTable: true,
            data: data,
            columns: columns,
        })
    }

    async fetchDevices() {
        this.setState({loading: true})

        const records = await API.get('devices')

        if (!records) {
            alert('Cannot fetch list of assets!')
            return
        }

        let devices = {}
        for (let i in records) {
            devices[records[i].deviceId] = {
                name: records[i].name,
                group: records[i].groups[0],
            }
        }
        this.setState({
            loading: false,
            assets: records,
            devices: devices,
            selectedAssets: records.map((a, i) => {
                return {label: a.name, value: a.deviceId, key: i}
            }),
        })
    }

    async fetchOutputs() {
        const outputs = await API.get('states/unique', 2)
        this.setState({
            outputs: outputs.map((o) => {
                return {label: o, value: o}
            }),
            selectedOutput: outputs.length
                ? {label: outputs[0], value: outputs[0]}
                : null,
            loading: false,
        })
    }

    async componentWillMount() {
        this.fetchOutputs()
        this.fetchDevices()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Label>Assets:</Label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="assets"
                                            value={this.state.selectedAssets}
                                            onChange={this.handleAssetChange}
                                            options={this.state.assets.map(
                                                (a, i) => {
                                                    return {
                                                        label: a.name,
                                                        value: a.deviceId,
                                                        key: i,
                                                    }
                                                }
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleStartChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col>
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleEndChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col>
                                        <label>Output:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="output"
                                            value={this.state.selectedOutput}
                                            onChange={(selectedOutput) =>
                                                this.setState({selectedOutput})
                                            }
                                            options={this.state.outputs}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" style={{textAlign: 'right'}}>
                                        <br />
                                        <Button
                                            color="primary"
                                            onClick={this.preview}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Preview Data
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.showTable ? (
                    <Fragment>
                        <Row className="mt-4">
                            <Col>
                                <Button
                                    color="primary"
                                    className="btn-block"
                                    onClick={this.generate}
                                    disabled={this.state.loading}>
                                    {' '}
                                    Generate Full Dataset
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <PreviewTable
                                                    data={this.state.data}
                                                    columns={this.state.columns}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                ) : null}
                {this.state.showExport ? (
                    <Row>
                        <Col>
                            <CSVLink
                                data={this.state.data}
                                filename="Accumine_Data_Export.csv">
                                Click to download
                            </CSVLink>
                        </Col>
                    </Row>
                ) : null}

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
