import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

export default class StaticSectionText extends Component {
    deprecated = true
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'StaticSectionText'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col className="text-center">
                        <h5>
                            <strong>{this.props.name}</strong>
                        </h5>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
