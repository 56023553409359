import React, {Component, Fragment} from 'react'
import Switch from 'rc-switch'
import autobind from 'auto-bind'

export default class OffsetOvernightShifts extends Component {
    id = 'OffsetOvernightShifts'
    field = 'offsetOvernightShifts'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : false

        this.state = {
            value: initialValue,
        }

        if (this.props && this.props.cb) {
            this.props.cb('offsetOvernightShifts', initialValue)
        }
    }

    handleValueChange(value) {
        if (value) {
            alert(
                'WARNING: Offsetting overnight shifts in this report will cause discrepencies between this report and all other reporting tools. Accumine is not liable for issues resulting from misinformation caused by using this feature.\n\nEXAMPLE: with this feature enabled, an overnight shift starting from Sunday at 11pm and ending on Monday at 7am will have its data allocated on Monday rather than Sunday (when the shift started).'
            )
        }
        this.setState({value})

        if (this.props.cb) {
            this.props.cb('offsetOvernightShifts', value)
        }
    }

    render() {
        return (
            <Fragment>
                <label>Offset overnight shift to next day:</label>
                <Switch
                    className="custom-switch custom-switch-primary"
                    checked={this.state.value}
                    onChange={this.handleValueChange}
                />
            </Fragment>
        )
    }
}
