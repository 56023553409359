import * as API from 'SDK/api'

export const references = {
    'Classic/In-Cycle': async (dataModels) => {
        const source = dataModels.find((a) => a.name === 'Classic/In-Cycle')

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Downtime': async (dataModels) => {
        const source = dataModels.find((a) => a.name === 'Classic/Downtime')

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }

        // downtime category
        if (dataModels.find((a) => a.name === 'Classic/Downtime Category')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Downtime Category'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: true,
                },
                2
            )
        }

        // downtime reason
        if (dataModels.find((a) => a.name === 'Classic/Downtime Reason')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Downtime Reason'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: true,
                },
                2
            )
        }

        // downtime comment
        if (dataModels.find((a) => a.name === 'Classic/Downtime Comment')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Downtime Comment'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: true,
                },
                2
            )
        }

        // downtime user
        if (dataModels.find((a) => a.name === 'Classic/Downtime User')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Downtime User'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: true,
                },
                2
            )
        }
    },
    'Classic/Downtime Reason': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'Classic/Downtime Reason'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Downtime Comment': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'Classic/Downtime Comment'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Downtime Category': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'Classic/Downtime Category'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Downtime User': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'Classic/Downtime User'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Part Count': async (dataModels) => {
        const source = dataModels.find((a) => a.name === 'Classic/Part Count')

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Reject': async (dataModels) => {
        const source = dataModels.find((a) => a.name === 'Classic/Reject')

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Rework': async (dataModels) => {
        const source = dataModels.find((a) => a.name === 'Classic/Rework')

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Devices': async (dataModels) => {},
    'Classic/Static Hourly Targets': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'Classic/Static Hourly Targets'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'Classic/Scheduled Targets': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'Classic/Scheduled Targets'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'System/Saved Reports': async (dataModels) => {},
    'System/Post Processor/Flows': async (dataModels) => {},
    'System/Kanban Settings': async (dataModels) => {},
    'System/Calendar Settings': async (dataModels) => {},
    'MES/Parts': async (dataModels) => {},
    'MES/Operators': async (dataModels) => {},
    'MES/Machine Entry': async (dataModels) => {
        const source = dataModels.find((a) => a.name === 'MES/Machine Entry')

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }

        // operators
        if (dataModels.find((a) => a.name === 'MES/Operators')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'MES/Operators'
                    )._id,
                    sourcePath: 'username',
                    targetPath: 'username',
                    timeBased: false,
                },
                2
            )
        }
    },
    'MES/FPY/Injection Molding': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'MES/FPY/Injection Molding'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
    'MES/FPY/Metal Fabrication': async (dataModels) => {
        const source = dataModels.find(
            (a) => a.name === 'MES/FPY/Metal Fabrication'
        )

        // devices
        if (dataModels.find((a) => a.name === 'Classic/Devices')) {
            await API.post(
                `data-models/${source._id}/reference`,
                {
                    sourceDataModelId: source._id,
                    targetDataModelId: dataModels.find(
                        (a) => a.name === 'Classic/Devices'
                    )._id,
                    sourcePath: 'deviceId',
                    targetPath: 'deviceId',
                    timeBased: false,
                },
                2
            )
        }
    },
}
