import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Input, Button} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import * as API from 'SDK/api'

import Navigation from '../Navigation'
import {paginate} from '../Paginate'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Create New Block Batch',
            fn: () => {},
        })

        this.state = {
            loading: true,
            selectedPowderBatch: null,
            powderBatchOptions: [],
            sizeOptions: [],
            selectedSize: null,
            batchNumber: '',
        }
    }

    async fetchPowderBatches() {
        const records = await paginate(
            '5fc7e66407ceae4f317ffd22',
            [
                {
                    type: 'DesignatedTimestamp',
                    logic: 'previous',
                    value: {
                        unit: 'day',
                        value: 45,
                    },
                    path: 'Date',
                },
            ],
            {}
        )

        return records.map((r) => {
            return {label: r['Batch Number'], value: r}
        })
    }

    async fetchSizes() {
        const records = await paginate('5fc8f5a3ae96bc6be2c1a131', [], {})

        return records.map((r) => {
            return {label: r['Block Description'], value: r}
        })
    }

    handlePowderBatchSelection(selectedPowderBatch) {
        this.setState({selectedPowderBatch})
    }

    handleSizeSelection(selectedSize) {
        this.setState({selectedSize})
    }

    handleBatchNumberChange(e) {
        this.setState({batchNumber: e.target.value})
    }

    async submit() {
        this.setState({loading: true})
        const {result} = await API.post(
            'data-models/5fc9015bae96bc6be2c1a13e/paginate',
            {
                sort: {},
                limit: 1,
                next: null,
                previous: null,
                filter: [
                    {
                        type: 'Text',
                        logic: 'is',
                        value: [this.state.batchNumber],
                        path: 'Batch Number',
                    },
                ],
            },
            2
        )

        if (result.results.length) {
            alert('There is already a batch with the provided batch number.')
            this.setState({loading: false})
            return
        }

        await this.props.setInitialBlockBatchValues({
            batchNumber: this.state.batchNumber,
            powderBatch: this.state.selectedPowderBatch.value,
            size: this.state.selectedSize.value,
        })
        this.setState({loading: false})
    }

    async componentDidMount() {
        this.setState({
            powderBatchOptions: await this.fetchPowderBatches(),
            sizeOptions: await this.fetchSizes(),
            loading: false,
        })
    }

    render() {
        return (
            <Fragment>
                {!this.state.loading ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <Navigation path={this.path} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Create New Block Batch</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="powder-batch"
                                            value={
                                                this.state.selectedPowderBatch
                                            }
                                            onChange={
                                                this.handlePowderBatchSelection
                                            }
                                            options={this.state.powderBatchOptions.sort(
                                                (a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                            )}
                                        />
                                        <IntlMessages id="Powder Batch Number" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        {this.state.selectedPowderBatch !== null ? (
                            <Row>
                                <Col xs={{size: 4, offset: 4}}>
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="size"
                                                value={this.state.selectedSize}
                                                onChange={
                                                    this.handleSizeSelection
                                                }
                                                options={this.state.sizeOptions.sort(
                                                    (a, b) =>
                                                        a.label.localeCompare(
                                                            b.label
                                                        )
                                                )}
                                            />
                                            <IntlMessages id="Size" />
                                        </Label>
                                    </Form>
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.selectedSize !== null ? (
                            <Row>
                                <Col xs={{size: 4, offset: 4}}>
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="text"
                                                name="batch-number"
                                                value={this.state.batchNumber}
                                                onChange={
                                                    this.handleBatchNumberChange
                                                }
                                            />
                                            <IntlMessages id="Batch Number" />
                                        </Label>
                                    </Form>
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.batchNumber !== '' ? (
                            <Row>
                                <Col xs={{size: 4, offset: 4}}>
                                    <Button
                                        size="xl"
                                        block
                                        onClick={this.submit}>
                                        SUBMIT
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                    </Fragment>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
