import React, {Component, Fragment} from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import {Line} from 'react-chartjs-2'
import moment from 'moment'

import {Colxx, Separator} from 'components/CustomBootstrap'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import autoBind from 'auto-bind'

const chartConfig = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: 'rgba(0,0,0,0.1)',
                    drawBorder: false,
                },
                ticks: {
                    fontColor: 'gray',
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                type: 'time',
                distribution: 'linear',
                time: {
                    displayFormats: {
                        millisecond: 'h:mm:ss a',
                    },
                },
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontColor: 'gray',
                    autoSkip: false,
                    maxRotation: 0,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.subscriber = new Subscriber()

        this.node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        this.pins = this.node.settings.pins

        this.aliases = this.pins.map((x) => x.pinId)

        let sets = this.pins.map((pin, i) => {
            return {
                data: [],
                labels: [],
                alias: pin.pinId,
            }
        })

        let ts = moment()

        this.state = {
            sets: sets,
            lastFetch: this.pins.map((p) => moment(ts)),
        }
    }
    async fetchData() {
        let promises = []
        for (let i = 0; i < this.pins.length; i++) {
            promises.push(
                API.post(
                    'raw/sensorbot-data',
                    {
                        timestamp: this.state.lastFetch[i].toISOString(),
                        nodeId: this.props.editingNode.nodeId,
                        name: 'A' + i,
                    },
                    2
                )
            )
        }

        let data = await Promise.all(promises)
        let lastFetch = this.state.lastFetch

        for (let i in data) {
            if (data[i].length) {
                lastFetch[i] = moment(data[i][data[i].length - 1].timestamp)
                for (let row of data[i]) {
                    if (row.nodeId === this.node.nodeId && !isNaN(row.value)) {
                        let sets = this.state.sets,
                            set = sets.find((s) => s.alias === row.name)
                        set.labels.push(moment(row.timestamp))
                        set.data.push(parseInt(row.value))
                        this.setState({sets})
                    }
                }
            }
        }
        this.setState({lastFetch})
    }
    async componentDidMount() {
        this.subscriber.add(this.fetchData, 5000, 'fetchData')
    }
    reloadData(alias) {
        let set = this.state.sets.find((x) => x.alias === alias)

        if (!set) return
        return {
            labels: set.labels,
            datasets: [
                {
                    label: alias,
                    data: set.data,
                    backgroundColor: '#2a93d5',
                    borderColor: '#2a93d5',
                    fill: 'transparent',
                },
            ],
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    size="lg"
                    toggle={() => {
                        toggleModal('liveModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('liveModal')
                        }}>
                        Live Stream
                    </ModalHeader>
                    <ModalBody>
                        {this.aliases.map((alias, i) => {
                            return (
                                <Row key={i}>
                                    <Colxx xs="12">
                                        <Line
                                            key={Math.random()}
                                            data={this.reloadData(alias)}
                                            options={chartConfig}
                                        />
                                    </Colxx>
                                </Row>
                            )
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('liveModal')
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
