import * as MQTTMessager from '@accumine/mqtt-messager'
export default new MQTTMessager.Messager({
    url: process.env.REACT_APP_MQTT_URL,
    credentials: {
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
    },
    protocol: parseInt(process.env.REACT_APP_MQTT_PROTOCOL),
    keepAlive: parseInt(process.env.REACT_APP_MQTT_KEEP_ALIVE),
    connectionTimeout: parseInt(process.env.REACT_APP_MQTT_CONNECTION_TIMEOUT),
})
