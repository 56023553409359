import React, {useState} from 'react'
import {CSVLink} from 'react-csv'
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'

export const Toolbar = (props) => {
    const [dropdownOpen, setOpen] = useState(false)
    const toggle = () => setOpen(!dropdownOpen)
    const sendToEdit = () => props.history.push(props.schemaViewerPath)

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} size="xs">
            <DropdownToggle caret>
                <i className="iconsmind-Gear" /> Settings
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem disabled={!props.csvData.length}>
                    <CSVLink
                        data={props.csvData}
                        filename="Accumine Report Export.csv"
                        className="btn-block"
                        target="_blank">
                        {' '}
                        Export spreadsheet
                    </CSVLink>
                </DropdownItem>
                <DropdownItem
                    disabled={!props.showEditLink}
                    onClick={sendToEdit}>
                    Edit Report
                </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    )
}
