import React, {Component} from 'react'
import {Row, Col, Card, CardHeader, CardBody, CardFooter} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'

import HourlyTargetActualChart from './HourlyTargetActualChart'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {fetchShiftTimerange} from 'SDK/api/common'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import TimelineReport from './TimelineChart'

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.spm = this.props.fixedSpmData.find(
            (a) => a.deviceId === this.props.device.deviceId
        )
        if (!this.spm && !this.props.device.activated) {
            alert(
                '"' +
                    this.props.device.deviceId +
                    '" does not have a fixed spm. Setting to 0'
            )
            this.spm = 0
        } else if (this.spm) {
            this.spm = this.spm.spm
        }

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            live: {
                name: '...',
                timestamp: null,
                color: '',
                elapsed: '0:00',
            },
            actual: 0,
            target: 0,
            partNumber: 'No Scheduled Production',
            left: 0,
            progress: 0,
            hoursRemaining: '',
            activeBreak: false,
            employeeCheckIns: [],
            itemNumber: 'a',
            pressShaftSpeed: 0,
        }
    }
    async fetchLiveData() {
        //let live = await API.post('live', {
        //  deviceId: this.props.deviceId
        //});
        let live = this.props.live
        if (!live) {
            return alert('There was an error fetching data')
        } else if (live[this.props.deviceId].activeStates.length) {
            if (live[this.props.deviceId].activeShiftBreaks.length) {
                this.setState({
                    activeBreak:
                        live[this.props.deviceId].activeShiftBreaks[0].name,
                })
            } else {
                this.setState({activeBreak: false})
            }
            let shifts = live[this.props.deviceId].activeShifts
            live[this.props.deviceId].activeStates = live[
                this.props.deviceId
            ].activeStates.filter(
                (state) =>
                    state.name === 'Downtime' || state.name === 'In-Cycle'
            )
            live = live[this.props.deviceId].activeStates[0]
            if (live && live.name === 'Downtime') {
                const downtimeReason = this.props.downtimeReason
                    ? [this.props.downtimeReason]
                    : []

                if (downtimeReason.length) {
                    live.name = downtimeReason[0].value
                }
            }
            live.color = tileColor(live.name)
            live.elapsed = secondsToHHMMSS(
                moment().diff(moment(live.timestamp), 'seconds')
            )
            let timerange = [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().toISOString(),
            ]

            if (this.props.realtimeactiveshift) {
                if (shifts.length) {
                    timerange = await fetchShiftTimerange(
                        this.props.deviceId,
                        shifts[0]
                    )
                    timerange[0] = moment(timerange[0])
                        .startOf('minute')
                        .toISOString()
                    timerange[1] = moment().endOf('minute').toISOString()
                }
            }
            this.setState({live, timerange})
        }
    }

    updateElapsed() {
        if (this.state.live.timestamp === null) return
        let live = {...this.state.live}
        live.elapsed = secondsToHHMMSS(
            moment().diff(moment(live.timestamp), 'seconds')
        )
        this.setState({live})
    }

    async fetchActualThisHour() {
        if (!this.state.timerange) {
            let self = this
            return setTimeout(() => {
                self.fetchActualThisHour()
            }, 1000)
        }
        const minuteOffset = moment(this.state.timerange[0]).minute(),
            current = moment()
        let startOfHour = moment(current).startOf('hour')

        if (minuteOffset > 0) {
            if (current.minute() <= minuteOffset) {
                startOfHour = moment(current).add(-1, 'hours')
                startOfHour.set('minute', minuteOffset)
            } else {
                startOfHour = moment(current)
                startOfHour.set('minute', minuteOffset)
            }
        }
        startOfHour.startOf('minute')

        let {data} = await API.post('historical/aggregate2', {
            timeStart: startOfHour.toISOString(),
            timeEnd: moment().toISOString(),
            state: ['Part Count'],
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })

        let parts = data.devices[this.props.deviceId]['Part Count'][0]
        if (parts.constructor === Object) parts = parts.count

        if (this.props.device.type === 'scale') {
            const record = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Small Scale Value',
                        timeEnd: null,
                    },
                },
                2
            )
            if (record.length) {
                parts += record[0].value
            }
        }

        if (this.props.device.mcsDisplayMode === 'strokesPerMinute') {
            this.setState({actual: this.state.pressShaftSpeed})
        } else {
            this.setState({
                actual: parts,
            })
        }
        const status = parseInt(
            100 - (this.state.actual / this.state.target) * 100
        )
        if (status > 0) {
            this.setState({status: status + '% below target'})
        } else if (status < 0) {
            this.setState({status: status * -1 + '% above target'})
        } else {
            this.setState({status: 'On target'})
        }
    }

    async fetchTargetThisHour() {
        if (this.props.device.mcsDisplayMode === 'strokesPerMinute') {
            const checkIns = this.state.employeeCheckIns
            let expected = 'N/A'
            if (checkIns.length) {
                expected = Math.floor(
                    checkIns[0].expectedHourlyRunRate /
                        0.8 /
                        60 /
                        checkIns.reduce((a, b) => a + b.piecesPerCycle, 0)
                )
            }
            this.setState({
                target: expected,
                hourlyTarget: checkIns.reduce(
                    (a, b) => a + b.expectedHourlyRunRate,
                    0
                ),
            })
        } else {
            const checkIns = this.state.employeeCheckIns
            this.setState({
                target: checkIns.reduce(
                    (a, b) => a + b.expectedHourlyRunRate,
                    0
                ),
                hourlyTarget: checkIns.reduce(
                    (a, b) => a + b.expectedHourlyRunRate,
                    0
                ),
            })
        }
    }

    async fetchEmployeeCheckIn() {
        const employeeCheckIns = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Employee Check-In',
                    timeEnd: null,
                },
            },
            2
        )
        const containers = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Job',
                    timeEnd: null,
                },
            },
            2
        )
        let totalExpected = employeeCheckIns.reduce(
                (a, b) => a + b.containerQuantity,
                0
            ),
            totalActual = 0
        for (let container of containers) {
            if (
                container.metaData.find((a) => a.name === 'Run Type').value ===
                'A'
            ) {
                if (container.spillover && container.spillover > 0) {
                    totalActual += container.spillover
                }
                const manualPosts = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Partial Container Post',
                            runType: 'A',
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )

                totalActual -= manualPosts.reduce((a, b) => a + b.value, 0)
                const data = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: {$in: ['A Part Count', 'Reject']},
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual += data
                    .filter((a) => a.name === 'A Part Count')
                    .reduce((a, b) => a + b.value, 0)
                totalActual -= data
                    .filter(
                        (a) =>
                            a.name === 'Reject' &&
                            a.itemNumber ===
                                container.metaData.find(
                                    (a) => a.name === 'Item Number'
                                ).value
                    )
                    .reduce((a, b) => a + b.value, 0)
            } else if (
                container.metaData.find((a) => a.name === 'Run Type').value ===
                'B'
            ) {
                if (container.spillover && container.spillover > 0) {
                    totalActual += container.spillover
                }
                const manualPosts = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Partial Container Post',
                            runType: 'B',
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual -= manualPosts.reduce((a, b) => a + b.value, 0)
                const data = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: {$in: ['B Part Count', 'Reject']},
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual += data
                    .filter((a) => a.name === 'B Part Count')
                    .reduce((a, b) => a + b.value, 0)
                totalActual -= data
                    .filter(
                        (a) =>
                            a.name === 'Reject' &&
                            a.itemNumber ===
                                container.metaData.find(
                                    (a) => a.name === 'Item Number'
                                ).value
                    )
                    .reduce((a, b) => a + b.value, 0)
            } else if (
                container.metaData.find((a) => a.name === 'Run Type').value ===
                'C'
            ) {
                if (container.spillover && container.spillover > 0) {
                    totalActual += container.spillover
                }
                const manualPosts = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Partial Container Post',
                            runType: 'C',
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual -= manualPosts.reduce((a, b) => a + b.value, 0)
                const data = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: {$in: ['C Part Count', 'Reject']},
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual += data
                    .filter((a) => a.name === 'C Part Count')
                    .reduce((a, b) => a + b.value, 0)
                totalActual -= data
                    .filter(
                        (a) =>
                            a.name === 'Reject' &&
                            a.itemNumber ===
                                container.metaData.find(
                                    (a) => a.name === 'Item Number'
                                ).value
                    )
                    .reduce((a, b) => a + b.value, 0)
            } else {
                if (container.spillover && container.spillover > 0) {
                    totalActual += container.spillover
                }
                const manualPosts = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Partial Container Post',
                            runType: '',
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual -= manualPosts.reduce((a, b) => a + b.value, 0)
                const data = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: {$in: ['Part Count', 'Reject']},
                            timeStart: {$gte: container.timeStart},
                        },
                    },
                    2
                )
                totalActual += data
                    .filter((a) => a.name === 'Part Count')
                    .reduce((a, b) => a + b.value, 0)
                totalActual -= data
                    .filter((a) => a.name === 'Reject')
                    .reduce((a, b) => a + b.value, 0)
            }
        }

        if (this.props.device.type === 'scale') {
            const record = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Small Scale Value',
                        timeEnd: null,
                    },
                },
                2
            )
            if (record.length) {
                totalActual = record[0].value
            }
        }

        this.setState({
            employeeCheckIns,
            totalExpected,
            totalActual,
        })
    }

    async fetchSpeed() {
        const speeds = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Press Shaft Speed',
                    timeEnd: null,
                },
            },
            2
        )
        if (speeds.length) {
            this.setState({pressShaftSpeed: speeds[0].value})
        }
    }

    setColor() {
        let {actual, target} = this.state
        target = parseInt(target)

        if (target === 0 || isNaN(target)) {
            return 'grey'
        }

        if (target < 1) {
            return '#28a745' //green
        } else if (actual / target < 0.8) {
            return '#dc3545' //red
        } else if (actual / target < 1) {
            return '#ffc107' //yellow
        } else if (actual / target < 1.2) {
            return '#28a745' //green
        } else {
            return '#007bff' // blue
        }
    }

    async componentWillMount() {
        await this.fetchEmployeeCheckIn()
        this.subscriber.add(this.fetchLiveData, 5000, 'fetchLiveData')
        this.subscriber.add(this.updateElapsed, 750, 'updateElapsed')
        this.subscriber.add(
            this.fetchActualThisHour,
            15 * 1000,
            'fetchActualThisHour'
        )
        this.subscriber.add(
            this.fetchTargetThisHour,
            15 * 1000,
            'fetchTargetThisHour'
        )
        this.subscriber.add(
            this.fetchEmployeeCheckIn,
            15 * 1000,
            'fetchEmployeeCheckIn'
        )
        this.subscriber.add(this.fetchSpeed, 15 * 1000, 'fetchSpeed')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        let color = this.state.employeeCheckIns.length
            ? this.setColor()
            : 'grey'
        if (!this.props.device.activated) {
            if (this.state.live.name === 'In-Cycle') {
                color = '#28a745'
            } else if (this.state.live.name === 'Downtime') {
                color = '#dc3545'
            } else {
                color = '#ffc107'
            }
        }
        return (
            <>
                {!this.props.simpleMode ? (
                    <Col xs="12" sm="6" className="mb-4">
                        <Card style={{color: '#fff', backgroundColor: color}}>
                            <CardHeader
                                style={{
                                    cursor: 'pointer',
                                    margin: 0,
                                    padding: '5px',
                                    backgroundColor: 'rgba(47, 50, 59,0.2)',
                                }}>
                                <Row>
                                    <Col>
                                        <strong>
                                            {this.props.device.name}
                                        </strong>
                                    </Col>
                                    <Col style={{textAlign: 'right'}}>
                                        {this.state.live.elapsed}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody
                                style={{
                                    padding: 0,
                                    filter:
                                        !this.props.device.activated ||
                                        this.state.employeeCheckIns.length > 0
                                            ? undefined
                                            : 'blur(10px)',
                                }}>
                                <Row style={{margin: 0}}>
                                    <Col xs="6">
                                        <Row>
                                            <Col className="text-center">
                                                <h2>
                                                    {this.state.live.name
                                                        .length > 20
                                                        ? this.state.live.name.substring(
                                                              0,
                                                              17
                                                          ) + '...'
                                                        : this.state.live.name}
                                                </h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-center">
                                                <p>
                                                    {this.props.device
                                                        .mcsDisplayMode ===
                                                    'strokesPerMinute'
                                                        ? 'Actual SPM'
                                                        : 'Hourly Actual'}
                                                </p>
                                                <h1>
                                                    {this.props.device
                                                        .mcsDisplayMode ===
                                                    'strokesPerMinute'
                                                        ? this.state
                                                              .pressShaftSpeed
                                                        : this.state.actual}
                                                </h1>
                                            </Col>
                                            <Col className="text-center">
                                                <p>
                                                    {this.props.device
                                                        .mcsDisplayMode ===
                                                    'strokesPerMinute'
                                                        ? 'Target SPM'
                                                        : 'Hourly Target'}
                                                </p>
                                                <h1>
                                                    {this.props.device
                                                        .activated ? (
                                                        <>{this.state.target}</>
                                                    ) : (
                                                        <>{this.spm}</>
                                                    )}
                                                </h1>
                                            </Col>
                                        </Row>
                                        {this.props.device.activated ? (
                                            <Row>
                                                <Col className="text-center">
                                                    <h5>
                                                        <strong>
                                                            {this.state
                                                                .employeeCheckIns
                                                                .length
                                                                ? this.state.employeeCheckIns
                                                                      .map(
                                                                          (a) =>
                                                                              a.itemNumber
                                                                      )
                                                                      .join(
                                                                          ' / '
                                                                      )
                                                                : 'No part number'}
                                                        </strong>
                                                    </h5>
                                                    <p className="m-0 p-0">
                                                        {this.state
                                                            .totalActual ||
                                                            ' '}{' '}
                                                        /{' '}
                                                        {this.state
                                                            .totalExpected ||
                                                            ' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </Col>
                                    <Col
                                        xs="6"
                                        style={{
                                            backgroundColor:
                                                'rgba(68, 70, 79, 0.5)',
                                        }}>
                                        {this.state.timerange && (
                                            <HourlyTargetActualChart
                                                timerange={this.state.timerange}
                                                deviceId={this.props.deviceId}
                                                device={this.props.device}
                                                target={this.state.hourlyTarget}
                                                productionUnit={
                                                    this.props.productionUnit
                                                }
                                                currentHour={this.state.actual}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter style={{margin: 0, padding: 0}}>
                                {this.state.timerange ? (
                                    <TimelineReport
                                        day={moment().startOf('day')}
                                        startTime={this.state.timerange[0]}
                                        endTime={this.state.timerange[1]}
                                        device={this.props.assets.find(
                                            (a) =>
                                                a.deviceId ===
                                                this.props.deviceId
                                        )}
                                    />
                                ) : null}
                                {this.props.device.activated ? (
                                    <>
                                        {this.state.employeeCheckIns.length ===
                                        0 ? (
                                            <h5 className="text-center">
                                                No employee check-in
                                            </h5>
                                        ) : (
                                            <h5 className="text-center">
                                                {this.state.status}
                                            </h5>
                                        )}
                                    </>
                                ) : (
                                    <h5 className="text-center">
                                        Not activated for AIM integration
                                    </h5>
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                ) : (
                    <Col xs="12" sm="3" className="mb-4">
                        <Card style={{color: '#fff', backgroundColor: color}}>
                            <CardHeader
                                style={{
                                    cursor: 'pointer',
                                    margin: 0,
                                    padding: '5px',
                                    backgroundColor: 'rgba(47, 50, 59,0.2)',
                                }}>
                                <Row>
                                    <Col className="text-center">
                                        <h1>
                                            <strong>
                                                {this.props.device.name}
                                            </strong>
                                        </h1>
                                    </Col>
                                </Row>
                                <Row style={{margin: 0, padding: 0}}>
                                    <Col
                                        style={{margin: 0, padding: 0}}
                                        className="text-center">
                                        <h5 style={{margin: 0, padding: 0}}>
                                            {this.state.live.elapsed}
                                        </h5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody
                                style={{
                                    padding: 0,
                                    filter:
                                        !this.props.device.activated ||
                                        this.state.employeeCheckIns.length > 0
                                            ? undefined
                                            : 'blur(10px)',
                                }}>
                                <Row style={{margin: 0}}>
                                    <Col xs="12">
                                        <Row>
                                            <Col className="text-center">
                                                <h2
                                                    style={{fontSize: '3em'}}
                                                    className="text-center">
                                                    {this.state.live.name
                                                        .length > 30
                                                        ? this.state.live.name.substring(
                                                              0,
                                                              17
                                                          ) + '...'
                                                        : this.state.live.name}
                                                </h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-center" xs="6">
                                                <h2
                                                    style={{fontSize: '2.5em'}}
                                                    className="text-center">
                                                    {this.props.device
                                                        .mcsDisplayMode ===
                                                    'strokesPerMinute'
                                                        ? 'Actual SPM'
                                                        : 'Hourly Actual'}
                                                </h2>
                                                <h2
                                                    style={{fontSize: '4em'}}
                                                    className="text-center">
                                                    <h2>
                                                        {this.props.device
                                                            .mcsDisplayMode ===
                                                        'strokesPerMinute'
                                                            ? this.state
                                                                  .pressShaftSpeed
                                                            : this.state.actual}
                                                    </h2>
                                                </h2>
                                            </Col>
                                            <Col className="text-center" xs="6">
                                                <h2
                                                    style={{fontSize: '2.5em'}}
                                                    className="text-center">
                                                    {this.props.device
                                                        .mcsDisplayMode ===
                                                    'strokesPerMinute'
                                                        ? 'Target SPM'
                                                        : 'Hourly Target'}
                                                </h2>
                                                <h2
                                                    style={{fontSize: '4em'}}
                                                    className="text-center">
                                                    <strong>
                                                        {this.props.device
                                                            .activated ? (
                                                            <>
                                                                {
                                                                    this.state
                                                                        .target
                                                                }
                                                            </>
                                                        ) : (
                                                            <>{this.spm}</>
                                                        )}
                                                    </strong>
                                                </h2>
                                            </Col>
                                        </Row>
                                        {this.props.device.activated ? (
                                            <Row>
                                                <Col className="text-center">
                                                    <h2>
                                                        <strong>
                                                            {this.state
                                                                .employeeCheckIns
                                                                .length
                                                                ? this.state.employeeCheckIns
                                                                      .map(
                                                                          (a) =>
                                                                              a.itemNumber
                                                                      )
                                                                      .join(
                                                                          ' / '
                                                                      )
                                                                : 'No part number'}
                                                        </strong>
                                                    </h2>
                                                    <h2>
                                                        {this.state
                                                            .totalActual ||
                                                            ' '}{' '}
                                                        /{' '}
                                                        {this.state
                                                            .totalExpected ||
                                                            ' '}
                                                    </h2>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col className="text-center">
                                                    <h2>
                                                        <strong>
                                                            No Part Number
                                                        </strong>
                                                    </h2>
                                                    <h2></h2>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter style={{margin: 0, padding: 0}}>
                                {this.props.device.activated ? (
                                    <>
                                        {this.state.employeeCheckIns.length ===
                                        0 ? (
                                            <h2
                                                style={{fontSize: '2.5em'}}
                                                className="text-center">
                                                No employee check-in
                                            </h2>
                                        ) : (
                                            <h2
                                                style={{fontSize: '2.5em'}}
                                                className="text-center">
                                                {this.state.status}
                                            </h2>
                                        )}
                                    </>
                                ) : (
                                    <h2
                                        style={{fontSize: '2.5em'}}
                                        className="text-center">
                                        Not activated for AIM integration
                                    </h2>
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                )}
            </>
        )
    }
}
