import React, {Component, Fragment} from 'react'
import {Breadcrumb, BreadcrumbItem} from 'reactstrap'
import autobind from 'auto-bind'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    render() {
        return (
            <Breadcrumb
                style={{background: '#e9ecef', color: '#fff', marginTop: 0}}>
                {this.props.path.map((p, i) => {
                    if (this.props.path.length - 1 === i) {
                        return (
                            <BreadcrumbItem key={i} active>
                                {p.name}
                            </BreadcrumbItem>
                        )
                    } else {
                        return (
                            <BreadcrumbItem key={i}>
                                <a href="#" onClick={p.fn}>
                                    {p.name}
                                </a>
                            </BreadcrumbItem>
                        )
                    }
                })}
            </Breadcrumb>
        )
    }
}
