import {Component} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import {Row, Col, Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import Board from 'react-trello'
import arrayMove from 'array-move'
import dot from 'dot-object'
import moment from 'moment'

import * as API from 'SDK/api'
import FilterModal from '../../SchemaViewer/modals/Filter'
import Form from '../../common/Form'

// NOTE: fetches a maximum of 300 records
class KanbanViewer extends Component {
    static propTypes = {
        // dataModelId of datamodel we're loading
        dataModelId: PropTypes.string.isRequired,

        // GET v2/data-models
        dataModels: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string,
                name: PropTypes.string,
                fields: PropTypes.arrayOf(PropTypes.object),
                parentDataModelId: PropTypes.any,
                parentFieldId: PropTypes.any,
            })
        ).isRequired,

        // optional filter for data, same format as pagination filter field
        filter: PropTypes.arrayOf(PropTypes.object),

        // optional error handling if fetching data fails
        onDataFetchError: PropTypes.func,

        // optional error handling if editing row fails
        onEditError: PropTypes.func,

        // optional error handling if validator fetching fails
        onGetValidatorError: PropTypes.func,

        // optional error handling for kanban setting fetching fails (System/Kanban Settings, needed for status field, etc
        onGetSettingsError: PropTypes.func,

        // optional error handling for kanban settings set (i.e. picklist field list, order)
        onSetSettingsError: PropTypes.func,

        // optional width for component, by default it tries to fit entire parent element
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // optional height for component, by default it tries to fit the entire screen
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // by default, users can add, update & delete rows, this provides ability to prevent
        // one or more of those actions
        permissions: PropTypes.shape({
            create: PropTypes.bool,
            update: PropTypes.bool,
            delete: PropTypes.bool,
            filter: PropTypes.bool,
            editKanban: PropTypes.bool,
        }),

        // optional custom action fired when user clicks on card (by default it shows the auto generated schema form)
        // this does not adhere to edit permissions (i.e. it will fire even if permissions.update = false )
        onClickOverride: PropTypes.func,
    }

    static defaultProps = {
        onDataFetchError: console.log,
        onEditError: console.log,
        onGetValidatorError: console.log,
        onGetSettingsError: console.log,
        onSetSettingsError: console.log,
        filter: [],
        width: '100%',
        height: 0,
        permissions: {
            create: true,
            update: true,
            delete: true,
            filter: true,
            editKanban: true,
        },
    }

    constructor(props) {
        super(props)
        autoBind(this)

        this.height =
            this.props.height || document.documentElement.offsetHeight * 0.72
        this.dataModel = this.props.dataModels.find(
            (a) => a._id === this.props.dataModelId
        )
        this.timestampField = undefined

        if (this.dataModel.modelType === 'State') {
            this.timestampField = this.dataModel.fields.find(
                (f) => f.type === 'DesignatedTimeStart'
            ).name
        }
        if (this.dataModel.mdoelType === 'Event') {
            this.timestampField = this.dataModel.fields.find(
                (f) => f.type === 'DesignatedTimestamp'
            ).name
        }

        if (!this.dataModel) {
            throw new Error('Data model not found.')
        }

        this.eventBus = undefined

        this.state = {
            loading: true,
            ready: false,
            data: {lanes: []},
            filter: this.props.filter || [],
            fieldTypes: [],
        }
    }

    makeLaneHeader({label, cards, title, current, target}) {
        return (
            <div>
                <header
                    style={{
                        borderBottom: '2px solid #c5c5c5',
                        paddingBottom: 6,
                        marginBottom: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                    <div style={{fontSize: 14, fontWeight: 'bold'}}>
                        {title}
                    </div>
                    {this.props.permissions.editKanban ? (
                        <div
                            style={{
                                width: '30%',
                                textAlign: 'right',
                                fontSize: 13,
                            }}>
                            <Button
                                size="xs"
                                color="default"
                                onClick={() => this.handleLaneDelete(title)}
                                style={{cursor: 'pointer'}}>
                                X
                            </Button>
                        </div>
                    ) : null}
                </header>
            </div>
        )
    }

    async fetchKanbanSettings() {
        let id = this.props.dataModels.find(
            (model) => model.name === 'System/Kanban Settings'
        )._id
        if (!id) {
            this.props.onGetSettingsError(
                `Cannot find data model with name "System/Kanban Settings"`
            )
            return
        }

        let settings = {},
            data = {lanes: []}
        try {
            settings = await API.post(
                `data-models/${id}/paginate`,
                {
                    filter: [
                        {
                            path: 'model_id',
                            type: 'Text',
                            logic: 'is',
                            value: [this.dataModel._id],
                        },
                    ],
                    sort: {},
                    limit: 1,
                },
                2
            )
            if (settings.count === 0) {
                throw new Error(
                    'Data model does have a Kanban Settings record.'
                )
            }
            settings = settings.result.results[0]

            data.lanes = this.dataModel.fields
                .find((f) => f.name === settings.status_field_path)
                .pickListValues.map((status) => {
                    return {
                        id: status,
                        title: status,
                        label: '',
                        cards: [],
                        disallowAddingCard: true,
                    }
                })
        } catch (error) {
            this.props.onGetSettingsError(error)
        }

        this.setState({settings, data})
    }

    async fetchFieldTypes() {
        this.setState({
            fieldTypes: await API.get('data-models/field-types', 2),
        })
    }

    async fetchData() {
        this.setState({loading: true})

        let {data} = this.state,
            statusField = this.state.settings.status_field_path,
            titleField = this.state.settings.title_field_path,
            descriptionField = this.state.settings.description_field_path
        try {
            let {result} = await API.post(
                `data-models/${this.dataModel._id}/paginate`,
                {
                    filter: this.state.filter,
                    sort: {},
                    limit: 300,
                    timezone: localStorage['timezone'],
                },
                2
            )

            data.lanes = data.lanes.map((lane) => {
                lane.cards = []
                return lane
            })

            for (let row of result.results) {
                let statusValue = row[statusField]
                if (statusValue) {
                    let laneIdx = data.lanes.findIndex(
                        ({title}) => title === statusValue
                    )
                    if (laneIdx >= 0) {
                        data.lanes[laneIdx].cards.push({
                            id: row._id,
                            title: dot.pick(titleField, row),
                            description: dot.pick(descriptionField, row),
                            label: this.timestampField
                                ? moment(
                                      dot.pick(this.timestampField, row)
                                  ).fromNow()
                                : '',
                            metadata: row,
                            editable: false,
                            draggable: this.props.permissions.update,
                        })
                    }
                }
            }
            if (this.eventBus) {
                this.eventBus.publish({type: 'UPDATE_LANES', lanes: data.lanes})
            }
        } catch (error) {
            this.props.onDataFetchError(error)
        }
        this.setState({loading: false, data})
    }

    async updateLaneOrder(removedIndex, addedIndex, payload) {
        this.setState({loading: true})
        let {data} = this.state
        data.lanes = arrayMove(data.lanes, removedIndex, addedIndex)

        try {
            let pickListValues = data.lanes.map((l) => l.title),
                fieldId = this.dataModel.fields.find(
                    (f) => f.name === this.state.settings.status_field_path
                )._id
            await API.patch(
                `data-models/${this.dataModel._id}/change-field-picklistvalues`,
                {fieldId, pickListValues},
                2
            )
            this.setState({
                data,
            })
        } catch (error) {
            this.props.onSetSettingsError(error)
        }
        this.setState({loading: false})
    }

    async handleLaneAdd({id, title}) {
        this.setState({loading: true})
        let {data} = this.state
        data.lanes.push({
            id,
            title,
            label: '',
            cards: [],
            disallowAddingCard: true,
        })

        try {
            let pickListValues = data.lanes.map((l) => l.title),
                fieldId = this.dataModel.fields.find(
                    (f) => f.name === this.state.settings.status_field_path
                )._id
            await API.patch(
                `data-models/${this.dataModel._id}/change-field-picklistvalues`,
                {fieldId, pickListValues},
                2
            )
            this.setState({data})
            this.eventBus.publish({type: 'UPDATE_LANES', lanes: data.lanes})
        } catch (error) {
            this.props.onSetSettingsError(error)
        }
        this.setState({loading: false})
    }

    async handleCardMove(fromLaneId, toLaneId, cardId, index) {
        this.setState({loading: true})
        let {data} = this.state
        try {
            const fromLaneIdx = data.lanes.findIndex(
                    ({id}) => id === fromLaneId
                ),
                toLaneIdx = data.lanes.findIndex(({id}) => id === toLaneId),
                card = data.lanes[fromLaneIdx].cards.find(
                    (c) => c.id === cardId
                )
            data.lanes[fromLaneIdx].cards = data.lanes[
                fromLaneIdx
            ].cards.filter(({id}) => id !== cardId)
            card.metadata[this.state.settings.status_field_path] =
                data.lanes[toLaneIdx].title
            data.lanes[toLaneIdx].cards.push(card)
            await API.patch(
                `data-models/${this.dataModel._id}/edit-record`,
                card.metadata,
                2
            )
            this.setState({data})
        } catch (error) {
            this.props.onEditError(error)
        }
        this.setState({loading: false})
    }

    async handleCardDelete(cardId, laneId) {
        this.setState({loading: true})
        let {data} = this.state
        try {
            const laneIdx = data.lanes.findIndex(({id}) => id === laneId)
            data.lanes[laneIdx].cards = data.lanes[laneIdx].cards.filter(
                ({id}) => id !== cardId
            )
            await API.remove(
                `data-models/${this.dataModel._id}/delete-record/${cardId}`,
                2
            )
            this.setState({data})
        } catch (error) {
            this.props.onArchiveError(error)
        }
        this.setState({loading: false})
    }

    async handleLaneDelete(title) {
        if (
            window.confirm(
                'Cards with this status will no longer be visible on the Kanban board. Are you sure?'
            )
        ) {
            this.setState({loading: true})
            let {data} = this.state
            try {
                data.lanes = data.lanes.filter((lane) => lane.title !== title)
                let pickListValues = data.lanes.map((l) => l.title),
                    fieldId = this.dataModel.fields.find(
                        (f) => f.name === this.state.settings.status_field_path
                    )._id
                await API.patch(
                    `data-models/${this.dataModel._id}/change-field-picklistvalues`,
                    {fieldId, pickListValues},
                    2
                )
                await this.fetchData()
            } catch (error) {
                this.props.onSetSettingsError(error)
            }
            this.setState({loading: false})
        }
    }

    async openForm(editingCard = undefined) {
        if (editingCard && !this.props.permissions.update)
            return alert('No permission to edit')
        this.setState({loading: true})
        try {
            const formValidator = await API.get(
                `data-models/${this.dataModel._id}/validator`,
                2
            )
            this.setState({
                formValidator,
                formModalOpen: true,
                editingCard,
            })
        } catch (error) {
            this.props.onGetValidatorError(error)
        }
        this.setState({loading: false})
    }

    async componentDidMount() {
        await this.fetchKanbanSettings()
        await this.fetchFieldTypes()
        await this.fetchData()
        this.setState({ready: true, loading: false})
    }

    render() {
        return (
            <div style={{height: this.height, width: this.props.width}}>
                <Row className="mb-1">
                    <Col xs="6">
                        {this.props.permissions.create ? (
                            <Button
                                size="xs"
                                color="success"
                                onClick={(e) => this.openForm()}>
                                <i className="simple-icon-plus" /> Add Card
                            </Button>
                        ) : null}
                    </Col>
                    <Col xs="6" className="text-right">
                        {this.props.permissions.filter ? (
                            <Button
                                className="mr-2"
                                size="xs"
                                color="default"
                                onClick={() =>
                                    this.setState({filterModalOpen: true})
                                }>
                                <i className="iconsmind-Filter-2" />
                                {this.state.filter.length} filter
                                {this.state.filter.length === 1 ? '' : 's'}
                            </Button>
                        ) : null}

                        <Button
                            color="default"
                            className="mr-2"
                            size="xs"
                            onClick={this.fetchData}>
                            <i className="simple-icon-refresh" />
                        </Button>
                    </Col>
                </Row>
                {this.state.ready ? (
                    <Row style={{height: '90%'}}>
                        <Col style={{height: '100%'}}>
                            <Board
                                data={this.state.data}
                                style={{
                                    backgroundColor: 'inherit',
                                    height: '100%',
                                }}
                                draggable={this.props.permissions.editKanban}
                                handleLaneDragEnd={this.updateLaneOrder}
                                onLaneAdd={this.handleLaneAdd}
                                canAddLanes={this.props.permissions.editKanban}
                                editable={this.props.permissions.editKanban}
                                onCardMoveAcrossLanes={this.handleCardMove}
                                eventBusHandle={(handle) =>
                                    (this.eventBus = handle)
                                }
                                onCardDelete={this.handleCardDelete}
                                hideCardDeleteIcon={
                                    !this.props.permissions.delete
                                }
                                onCardClick={(cardId, metadata, laneId) => {
                                    if (this.props.onClickOverride)
                                        return this.props.onClickOverride(
                                            metadata
                                        )
                                    this.openForm(metadata)
                                }}
                                components={{LaneHeader: this.makeLaneHeader}}
                            />
                        </Col>
                    </Row>
                ) : null}

                {this.state.loading && !this.state.ready ? (
                    <>Loading data...</>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}

                {this.state.formModalOpen ? (
                    <Modal
                        size="lg"
                        isOpen={true}
                        toggle={() =>
                            this.setState({
                                formModalOpen: !this.state.formModalOpen,
                            })
                        }>
                        <ModalHeader
                            toggle={() =>
                                this.setState({
                                    formModalOpen: !this.state.formModalOpen,
                                })
                            }>
                            {this.state.editingCard ? 'Edit' : 'Add'}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                schema={this.state.formValidator}
                                dataModel={this.dataModel}
                                dataModels={this.props.dataModels}
                                editingRecord={this.state.editingCard}
                                onSubmit={() => {
                                    this.fetchData()
                                    this.setState({formModalOpen: false})
                                }}
                            />
                        </ModalBody>
                    </Modal>
                ) : null}

                {this.state.filterModalOpen ? (
                    <FilterModal
                        open={true}
                        toggle={() =>
                            this.setState({
                                filterModalOpen: !this.state.filterModalOpen,
                            })
                        }
                        dataModel={this.dataModel}
                        dataModels={this.props.dataModels}
                        updateFilters={(filter) =>
                            this.setState({filter}, this.fetchData)
                        }
                        filters={this.state.filter}
                        refresh={() => {}}
                        fieldTypes={this.state.fieldTypes}
                    />
                ) : null}
            </div>
        )
    }
}

export default KanbanViewer
