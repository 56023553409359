import React, {Component} from 'react'
import autobind from 'auto-bind'

const divStyle = {
    position: 'fixed',
    top: -50,
    height: 75,
    left: 0,
    width: '100%',
    backgroundColor: '#2a93d5',
    padding: 0,
    textAlign: 'center',
    zIndex: 10000,
    cursor: 'pointer',
}

const spanStyle = {
    color: '#fff',
    paddingTop: 53,
    display: 'block',
}

export default class EnvironmentEditStatus extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }
    async componentWillMount() {}

    render() {
        return (
            <div style={{...divStyle}} onClick={this.props.exitEditingMode}>
                <span style={{...spanStyle}}>
                    Click here to exit editing mode
                </span>
            </div>
        )
    }
}
