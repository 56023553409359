import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'

const InjectMassage = (props) => <FormattedMessage {...props} />

export default injectIntl(InjectMassage, {
    withRef: false,
})

const consoleError = console.error.bind(console)
// eslint-disable-next-line
console.error = (message, ...args) => {
    if (typeof message === 'string' && message.startsWith('[React Intl]')) {
        return
    }
    consoleError(message, ...args)
}
