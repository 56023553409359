import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap'

import autobind from 'auto-bind'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        return (
            <Fragment>
                <Col xs="12" sm="4" lg="3">
                    <div className="icon-row-item">
                        <Card
                            className="mb-4"
                            onClick={() => {
                                this.props.handleClickEvent(this.props.flow._id)
                            }}>
                            <CardBody className="text-center">
                                <p className="lead text-center">
                                    <i className="iconsmind-Copy" />{' '}
                                    {this.props.flow.name}
                                </p>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Fragment>
        )
    }
}
