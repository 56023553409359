import React, {Component, Fragment} from 'react'
import {Bar} from 'react-chartjs-2'
import autobind from 'auto-bind'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default class OEEHistoricalChart extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            options: {
                maintainAspectRatio: !true,
                responsive: true,
                title: {
                    display: true,
                    text: 'Benchmark Efficiency Trend %',
                },
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: 100,
                            },
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: (val) => {
                            return parseFloat(val.toFixed(2))
                        },
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            },
        }
    }

    getTarget(assets = this.props.selectedAssets) {
        let values = []
        assets.forEach((asset) => {
            if (asset.oeeTargets && !isNaN(asset.oeeTargets.benchmark)) {
                values.push(parseFloat(asset.oeeTargets.benchmark))
            }
        })

        if (values.length > 0)
            return (
                values.reduce((previous, current) => (current += previous)) /
                values.length
            )
        else return 100
    }

    render() {
        let target = this.getTarget()
        let data = {...this.props.data}

        for (let set of data.datasets) {
            if (set.type == 'bar') {
                set.data.forEach((d, i) => {
                    set.data[i] =
                        (d / target) * 100 > 100 ? 100 : (d / target) * 100
                })
            }
            if (set.type == 'line') {
                set.data.forEach((d, i) => {
                    set.data[i] =
                        (d / target) * 100 > 100 ? 100 : (d / target) * 100
                })
            }
        }

        return (
            <Fragment>
                <h5>BENCHMARK EFFICIENCY TREND</h5>
                <Bar
                    data={data}
                    options={this.state.options}
                    plugins={[ChartDataLabels]}
                />
            </Fragment>
        )
    }
}
