import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Switch from 'rc-switch'
import {CirclePicker} from 'react-color'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            display: this.props.flow ? this.props.flow.display : '',
            color: this.props.flow ? this.props.flow.color : '',
            loading: false,
        }
    }

    async handleDisplayChange(display) {
        this.setState({display: display, loading: true})

        let obj = Object.assign(this.props.flow, {})
        obj.display = display

        await API.patch('flows/' + obj._id, obj, 2)
        this.setState({loading: false})
    }

    async handleColorChange(color) {
        this.setState({color: color.hex, loading: true})

        let obj = Object.assign(this.props.flow, {})
        obj.color = color.hex

        await API.patch('flows/' + obj._id, obj, 2)
        this.setState({loading: false})
    }

    render() {
        const {modal, toggleModal, flow} = this.props
        const name = flow ? flow.name : ''

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>{name}</ModalHeader>
                    <ModalBody>
                        <Row className="mb-2">
                            <Col xs="12">
                                <label>
                                    Display flow on dashboards when it's active?
                                </label>
                                <Switch
                                    className="custom-switch custom-switch-primary"
                                    checked={this.state.display}
                                    onChange={this.handleDisplayChange}
                                    disabled={this.state.loading}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs="12">
                                <label>Flow color:</label>
                                <CirclePicker
                                    color={this.state.color}
                                    onChangeComplete={this.handleColorChange}
                                    disabled={this.state.loading}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
