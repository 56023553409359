import * as API from 'SDK/api'
import moment from 'moment'

const fetchCycleTime = (employeeRecords, start, end, relevantDevices) => {
    return {
        filter: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Employee.Employee_ID',
                type: 'Text',
                logic: 'is',
                value: employeeRecords.map((a) => a.value),
                rootDataModelId: '61d8aaa2049e1a001bea2f94',
            },
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '60bf76d755b8f70013e25a16',
                path: 'Classic/Devices.name',
            },
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Aiken/Sign Ins.Employee.Employee_ID',
            },
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                path: 'timeStart',
            },
            {
                path: 'timeEnd',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '628fc3dc5632f5001320ef88',
                        referenceId: '628fcb12afe9e90013699295',
                        sourcePrefix: '',
                        targetPrefix: 'Classic/Devices',
                    },
                    {
                        dataModelId: '628fc3dc5632f5001320ef88',
                        referenceId: '628fc44363af0000135ac428',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Sign Ins',
                    },
                    {
                        dataModelId: '628fc3dc5632f5001320ef88',
                        referenceId: '628fc452ac75c000138bd303',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '628fc3dc5632f5001320ef88',
                    type: 'mean',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchInCycle = (employeeRecords, start, end, relevantDevices) => {
    return {
        filter: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Employee.Employee_ID',
                type: 'Text',
                logic: 'is',
                value: employeeRecords.map((a) => a.value),
            },
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Aiken/Sign Ins.Employee.Employee_ID',
            },
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76da96dbd30013d326f1',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                dataModelId: '60bf76d755b8f70013e25a16',
                path: 'Classic/Devices.name',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76da96dbd30013d326f1',
                        referenceId: '60bf76db51a8fe0013c7775e',
                        sourcePrefix: '',
                        targetPrefix: 'Classic/Devices',
                    },
                    {
                        dataModelId: '60bf76da96dbd30013d326f1',
                        referenceId: '622a06d587fc6819387f5cf7',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                    {
                        dataModelId: '60bf76da96dbd30013d326f1',
                        referenceId: '622a073487fc6819387f5cf8',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Sign Ins',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76da96dbd30013d326f1',
                    type: 'sum',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchPartCount = (employeeRecords, start, end, relevantDevices) => {
    return {
        filter: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Employee.Employee_ID',
                type: 'Text',
                logic: 'is',
                value: employeeRecords.map((a) => a.value),
            },
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Aiken/Sign Ins.Employee.Employee_ID',
            },
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76da97ca2600133c639f',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                dataModelId: '60bf76d755b8f70013e25a16',
                path: 'Classic/Devices.name',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76da97ca2600133c639f',
                        referenceId: '62278b8d48fdd500135c2ba1',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Sign Ins',
                    },
                    {
                        dataModelId: '60bf76da97ca2600133c639f',
                        referenceId: '62278698be117900133ad90c',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                    {
                        dataModelId: '60bf76da97ca2600133c639f',
                        referenceId: '60bf76dce2151c0013ff9c12',
                        sourcePrefix: '',
                        targetPrefix: 'Classic/Devices',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76da97ca2600133c639f',
                    type: 'sum',
                    path: 'value',
                },
            },
        ],
    }
}

const fetchScrapQty = (employeeRecords, start, end, relevantDevices) => {
    return {
        filter: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Employee.Employee_ID',
                type: 'Text',
                logic: 'is',
                value: employeeRecords.map((a) => a.value),
            },
            {
                path: 'deviceId',
                type: 'Text',
                logic: 'is',
                value: relevantDevices,
            },
        ],
        groups: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Aiken/Sign Ins.Employee.Employee_ID',
            },
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '60bf76dae2151c0013ff9c10',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                dataModelId: '60bf76d755b8f70013e25a16',
                path: 'Classic/Devices.name',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '60bf76dae2151c0013ff9c10',
                        referenceId: '622a069a87fc6819387f5cf5',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Sign Ins',
                    },
                    {
                        dataModelId: '60bf76dae2151c0013ff9c10',
                        referenceId: '622a06b687fc6819387f5cf6',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                    {
                        dataModelId: '60bf76dae2151c0013ff9c10',
                        referenceId: '60bf76dc55b8f70013e25a50',
                        sourcePrefix: '',
                        targetPrefix: 'Classic/Devices',
                    },
                ],
                aggregate: {
                    dataModelId: '60bf76dae2151c0013ff9c10',
                    type: 'sum',
                    path: 'value',
                },
            },
        ],
    }
}

const fetchWorkOrderData = (employeeRecords, start, end, devices) => {
    return {
        filter: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Employee.Employee_ID',
                type: 'Text',
                logic: 'is',
                value: employeeRecords.map((a) => a.value),
            },
            {
                path: 'Machine.deviceId',
                type: 'Text',
                logic: 'is',
                value: devices.map((a) => a.value),
            },
        ],
        groups: [
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Employee.Employee_ID',
            },
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Part_Number',
            },
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: '@@time@@',
                timeUnit: 'D',
            },
            {
                dataModelId: '61d8aaa2049e1a001bea2f94',
                path: 'Machine.name',
            },
            {
                dataModelId: '61e197a942c319001389da6f',
                path: 'Aiken/Work Orders.Parts_Per_Hour',
            },
        ],
        timezone: 'America/Toronto',
        timerange: {
            logic: 'between',
            value: [start.valueOf(), end.valueOf()],
        },
        aggregate: [
            {
                join: [
                    {
                        dataModelId: '61d8aaa2049e1a001bea2f94',
                        referenceId: '622a117987fc6819387f5cf9',
                        sourcePrefix: '',
                        targetPrefix: 'Aiken/Work Orders',
                    },
                ],
                aggregate: {
                    dataModelId: '61d8aaa2049e1a001bea2f94',
                    type: 'sum',
                    path: '@@duration@@',
                },
            },
        ],
    }
}

const fetchData = async (devices, employeeRecords, start, end) => {
    let results = employeeRecords.map((employeeRecord) => {
        return {
            employeeID: employeeRecord.value,
            dateRangeString: `${start.format('MM/DD/YYYY')} to ${end.format(
                'MM/DD/YYYY'
            )}`,
            employeeString: `${employeeRecord.value} ${employeeRecord.label}`,
            rows: [],
        }
    })

    {
        let {data} = await API.post(
            'data-models/61d8aaa2049e1a001bea2f94/aggregate',
            fetchWorkOrderData(employeeRecords, start, end, devices),
            2
        )
        //console.log("Work Order Data", data);
        for (let row of data) {
            let key = row['@@key@@'].split('__')
            key.pop()
            key = key.join('__')
            let idx = results.findIndex(
                (a) => a.employeeID === row['Employee.Employee_ID']
            )
            results[idx].rows.push({
                key: key,
                partsPerHour: row['Aiken/Work Orders.Parts_Per_Hour'],
                date: moment(row['@@time@@']).format('MM/DD/YYYY'),
                machine: row['Machine.name'], // missing
                partNumber: row['Aiken/Work Orders.Part_Number'],
                operatingMins: Math.round(row['@@duration@@']),
                performancePercentage: 0,
                uptimeMins: 0,
                downtimeMins: 0,
                utilPercentage: 0,
                goalPcs: 0,
                actualPcs: 0,
                goodQty: 0,
                scrapQty: 0,
                accPercentage: 0,
                allCycles: [],
                averageCycleTime: 0,
            })
        }
        results = results.filter((a) => a.rows.length)
    }

    if (results.length === 0) {
        return null
    }

    const relevantDevices = [
        ...new Set(
            results
                .map((a) => a.rows)
                .flat()
                .map((r) => r.machine)
        ),
    ].map((a) => {
        return devices.find((d) => d.label === a).value
    })

    {
        let {data} = await API.post(
            'data-models/60bf76da97ca2600133c639f/aggregate',
            fetchPartCount(employeeRecords, start, end, relevantDevices),
            2
        )
        //console.log("Part Count", data);
        for (let row of data) {
            const employeeIdx = results.findIndex(
                    (a) =>
                        a.employeeID ===
                        row['Aiken/Sign Ins.Employee.Employee_ID']
                ),
                signInIdx = results[employeeIdx].rows.findIndex(
                    (r) => r.key === row['@@key@@']
                )
            if (signInIdx >= 0) {
                results[employeeIdx].rows[signInIdx].actualPcs = row.value
            } else {
                console.log('Part Count: Missing row - ' + row)
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76da97ca2600133c639f/aggregate',
            fetchCycleTime(employeeRecords, start, end, relevantDevices),
            2
        )
        //console.log("Cycle Time", data);
        data = data.sort(
            (a, b) =>
                moment(a.timeStart).valueOf() - moment(b.timeStart).valueOf()
        )
        for (let row of data) {
            const employeeIdx = results.findIndex(
                    (a) =>
                        a.employeeID ===
                        row['Aiken/Sign Ins.Employee.Employee_ID']
                ),
                signInIdx = results[employeeIdx].rows.findIndex((r) => {
                    return (
                        r.partNumber === row['Aiken/Work Orders.Part_Number'] &&
                        r.machine === row['Classic/Devices.name'] &&
                        moment(row.timeStart).isBetween(
                            moment(r['date']).startOf('day'),
                            moment(r['date']).endOf('day')
                        )
                    )
                })
            if (signInIdx >= 0) {
                results[employeeIdx].rows[signInIdx].allCycles.push(row)
            } else {
                console.log('Cycle Time: Missing row - ' + row)
            }
        }
        for (let i in results) {
            for (let j in results[i].rows) {
                results[i].rows[j].allCycles = results[i].rows[
                    j
                ].allCycles.sort(
                    (a, b) =>
                        moment(a.timeStart).valueOf() -
                        moment(b.timeStart).valueOf()
                )
                results[i].rows[j].allCycles.pop()
                results[i].rows[j].averageCycleTime =
                    results[i].rows[j].allCycles.reduce((a, b) => {
                        return (
                            a +
                            moment(b.timeEnd).diff(
                                moment(b.timeStart),
                                'seconds'
                            )
                        )
                    }, 0) / results[i].rows[j].allCycles.length
                results[i].rows[j].averageCycleTime = Math.ceil(
                    results[i].rows[j].averageCycleTime
                )
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76dae2151c0013ff9c10/aggregate',
            fetchScrapQty(employeeRecords, start, end, relevantDevices),
            2
        )
        //console.log("Scrap", data);
        for (let row of data) {
            const employeeIdx = results.findIndex(
                    (a) =>
                        a.employeeID ===
                        row['Aiken/Sign Ins.Employee.Employee_ID']
                ),
                signInIdx = results[employeeIdx].rows.findIndex(
                    (r) => r.key === row['@@key@@']
                )
            if (signInIdx >= 0) {
                results[employeeIdx].rows[signInIdx].scrapQty = row.value
            } else {
                console.log('Scrap: Missing row - ' + row)
            }
        }
    }

    {
        let {data} = await API.post(
            'data-models/60bf76da96dbd30013d326f1/aggregate',
            fetchInCycle(employeeRecords, start, end, relevantDevices),
            2
        )
        //console.log("InCycle", data);
        for (let row of data) {
            const employeeIdx = results.findIndex(
                    (a) =>
                        a.employeeID ===
                        row['Aiken/Sign Ins.Employee.Employee_ID']
                ),
                signInIdx = results[employeeIdx].rows.findIndex(
                    (r) => r.key === row['@@key@@']
                )
            if (signInIdx >= 0) {
                results[employeeIdx].rows[signInIdx].uptimeMins = Math.round(
                    row['@@duration@@']
                )
            } else {
                console.log('InCycle: Missing row - ' + row)
            }
        }
    }

    results = results.map((result) => {
        result.rows = result.rows.map((row) => {
            if (!isFinite(row.averageCycleTime)) {
                row.averageCycleTime = 0
            }
            if (row.uptimeMins >= row.operatingMins) {
                row.uptimeMins = row.operatingMins
            }
            row.downtimeMins = row.operatingMins - row.uptimeMins
            row.utilPercentage =
                Math.round((row.uptimeMins / row.operatingMins) * 100) || 0
            if (!isFinite(row.utilPercentage)) {
                row.utilPercentage = 0
            }
            row.goodQty = row.actualPcs - row.scrapQty
            row.accPercentage =
                Math.round((row.goodQty / row.actualPcs) * 100) || 0
            if (!isFinite(row.accPercentage)) {
                row.accPercentage = 0
            }
            row.goalPcs =
                Math.round(row.partsPerHour * (row.operatingMins / 60)) || 0
            row.performancePercentage =
                Math.round((row.actualPcs / row.goalPcs) * 100) || 0
            if (!isFinite(row.performancePercentage)) {
                row.performancePercentage = 0
            }
            return row
        })

        result.rows.sort((a, b) => a.date.localeCompare(b.date))
        let totalRow = {
            isTotalRow: true,
            performancePercentage:
                Math.round(
                    (result.rows.reduce((a, b) => a + b.actualPcs, 0) /
                        result.rows.reduce((a, b) => a + b.goalPcs, 0)) *
                        100
                ) || 0,
            machine: '',
            partNumber: '',
            uptimeMins:
                Math.round(
                    (result.rows.reduce((a, b) => a + b.uptimeMins, 0) / 60) *
                        10
                ) / 10,
            downtimeMins:
                Math.round(
                    (result.rows.reduce((a, b) => a + b.downtimeMins, 0) / 60) *
                        10
                ) / 10,
            operatingMins:
                Math.round(
                    (result.rows.reduce((a, b) => a + b.operatingMins, 0) /
                        60) *
                        10
                ) / 10,
            utilPercentage:
                Math.round(
                    (result.rows.reduce((a, b) => a + b.uptimeMins, 0) /
                        result.rows.reduce((a, b) => a + b.operatingMins, 0)) *
                        100
                ) || 0,
            actualPcs: result.rows.reduce((a, b) => a + b.actualPcs, 0),
            goalPcs: result.rows.reduce((a, b) => a + b.goalPcs, 0),
            goodQty: result.rows.reduce((a, b) => a + b.goodQty, 0),
            scrapQty: result.rows.reduce((a, b) => a + b.scrapQty, 0),
            accPercentage:
                Math.round(
                    (result.rows.reduce((a, b) => a + b.goodQty, 0) /
                        result.rows.reduce((a, b) => a + b.actualPcs, 0)) *
                        100
                ) || 0,
        }

        if (!isFinite(totalRow.performancePercentage)) {
            totalRow.performancePercentage = 0
        }
        if (!isFinite(totalRow.utilPercentage)) {
            totalRow.utilPercentage = 0
        }
        if (!isFinite(totalRow.accPercentage)) {
            totalRow.accPercentage = 0
        }
        result.rows.push(totalRow)
        return result
    })
    return results
}

export default fetchData
