import React, {Component} from 'react'
import {HorizontalBar} from 'react-chartjs-2'
import autobind from 'auto-bind'

import {ThemeColors} from 'util/ThemeColors'
const colors = ThemeColors()

export default class OEEViewChart extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            options: {
                events: [],
                animation: {
                    onComplete: function () {
                        var ctx = this.chart.ctx
                        ctx.font = Chart.helpers.fontString(
                            Chart.defaults.global.defaultFontFamily,
                            'normal',
                            Chart.defaults.global.defaultFontFamily
                        )
                        ctx.textAlign = 'left'
                        ctx.textBaseline = 'bottom'
                        this.data.datasets.forEach(function (dataset, j) {
                            for (var i = 0; i < dataset.data.length; i++) {
                                var model =
                                        dataset._meta[
                                            Object.keys(dataset._meta)[0]
                                        ].data[i]._model,
                                    left =
                                        dataset._meta[
                                            Object.keys(dataset._meta)[0]
                                        ].data[i]._xScale.left
                                ctx.fillStyle = '#A8A8A8' // label color
                                var label = model.label
                                if (j === 0)
                                    label +=
                                        ' ' + Math.round(dataset.data[i]) + '%'
                                ctx.fillText(label, left + 15, model.y + 8)
                            }
                        })
                    },
                },
                title: {
                    display: true,
                    text: 'OEE Overview',
                },
                maintainAspectRatio: true,
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            ticks: {
                                max: 100,
                                display: false,
                                fontColor: '#fff',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            stacked: true,
                        },
                    ],
                },
            },
        }
    }

    render() {
        return (
            <HorizontalBar
                data={this.props.data}
                options={this.state.options}
            />
        )
    }
}
