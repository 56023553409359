import React, {Component, Fragment} from 'react'
import Select from 'react-select'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import CustomSelectInput from 'components/CustomSelectInput'
import {Alert} from 'reactstrap'

const OPTIONS = moment.tz.names().map((t) => {
    return {
        label: t,
        value: t,
    }
})

export default class TimezoneSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        default: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            selectedTimeZone: {
                label: this.props.default,
                value: this.props.default,
            },
        }
    }

    handleSelection(selectedTimeZone) {
        this.setState({selectedTimeZone})
        if (this.props.onChange) {
            this.props.onChange(selectedTimeZone.value)
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.selectedTimeZone.value === 'EST' ? (
                    <Alert color="danger">
                        "EST" does not account for Daylight Savings Time which
                        may result in an incorrect time. Consider using
                        "EST5EDT" as it accounts for Daylight Savings Time.
                    </Alert>
                ) : null}
                <label>Timezone:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="timezone"
                    value={this.state.selectedTimeZone}
                    onChange={this.handleSelection}
                    options={OPTIONS}
                />
            </Fragment>
        )
    }
}
