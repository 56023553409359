import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import './index.css'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import HMIComponent from './component'

export default class HMI extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'hideLightsOutEntry',
            component: 'HideLightsOutEntry',
        },
    ]
    showBorder = false
    id = 'HMI'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        hideLightsOutEntry: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.devices = []
        if (this.props) {
            this.devices = this.props.deviceIds
                ? this.props.deviceIds
                : [this.props.deviceId]
        }

        this.state = {
            devices: [],
            liveData: {},
            users: [],
            shifts: [],
            loading: true,
        }
    }

    async fetchLiveData() {
        let data = await API.post('live', {
            deviceId: this.devices,
        })
        if (data) {
            this.setState({liveData: data})
        }
    }

    async fetchUsers() {
        let users = await API.get('users?all=true', 2)
        if (!users || users.length === 0) return

        this.setState({users})
    }

    async fetchShifts() {
        let shifts = await API.get('shifts?all=true', 2)
        if (!shifts || shifts.length === 0) return

        this.setState({shifts})
    }

    async componentDidMount() {
        this.setState({loading: true})
        if (this.devices.length > 3) {
            alert('No more than 3 assets can be shown at one time.')
        }

        this.colSize = 12
        if (this.devices.length === 2) {
            this.colSize = 6
        } else if (this.devices.length >= 3) {
            this.colSize = 4
        }

        const devices = await API.get('devices')
        this.setState({devices})

        await this.fetchUsers()
        await this.fetchShifts()
        this.fetchLiveData()

        this.subscriber.add(this.fetchLiveData, 5000, 'fetchLiveData')
        this.subscriber.add(this.fetchUsers, 1000 * 60, 'fetchUsers')
        this.subscriber.add(this.fetchShifts, 1000 * 60, 'fetchShifts')
        this.setState({loading: false})
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                {!this.state.loading ? (
                    <Row>
                        {this.devices.slice(0, 3).map((device, idx) => {
                            const deviceRecord = this.state.devices.find(
                                (d) => d.deviceId === device
                            )
                            let name = ''
                            if (deviceRecord) name = deviceRecord.name
                            return (
                                <Col key={idx} xs={this.colSize}>
                                    <h2 className="text-center m-0">
                                        <strong>{name}</strong>
                                    </h2>
                                    <HMIComponent
                                        deviceId={device}
                                        deviceName={name}
                                        devices={this.state.devices}
                                        liveData={this.state.liveData}
                                        users={this.state.users}
                                        shifts={this.state.shifts}
                                        forceRefresh={this.fetchLiveData}
                                        hideLightsOutEntry={
                                            this.props.hideLightsOutEntry
                                        }
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
