import React, {Component, Fragment} from 'react'
import {Button, Row} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import moment from 'moment'
import {Colxx, Separator} from 'components/CustomBootstrap'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddPartModal from './add'
import RemoveModal from './remove'
import EditModal from './edit'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Target',
        accessor: 'target',
    },
]

export default class PartManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'PartManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.fetchParts = this.fetchParts.bind(this)
        this.dataTableFormatter = this.dataTableFormatter.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.actions = this.actions.bind(this)
        this.toggleAddPartModal = this.toggleAddPartModal.bind(this)

        this.state = {
            allParts: [],
            editingPart: null,
            addPartModal: false,
        }
    }
    async fetchParts() {
        let allParts = await API.post(
            'historical/raw',
            {
                query: {
                    timestamp: {
                        $gt: moment().subtract(15, 'years'),
                        $lt: moment(),
                    },
                    name: 'Part',
                },
            },
            2
        )

        this.setState({allParts})
    }
    toggleAddPartModal() {
        this.setState({
            addPartModal: !this.state.addPartModal,
        })
    }
    toggleModal(modalName, part) {
        let state = this.state
        state.editingPart = part
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (part) => this.toggleModal('editModal', part),
                actionName: 'Edit Part',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (part) => this.toggleModal('removeModal', part),
                actionName: 'Remove Part',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(part) {
        part.name = part.metaData.find((data) => data.name === 'part').value
        part.target = part.metaData.find((data) => data.name === 'target').value
        return part
    }

    editorModalAttributes(part) {
        return [
            ['Name', part.metaData.find((data) => data.name === 'part').value],
            [
                'Target',
                part.metaData.find((data) => data.name === 'target').value,
            ],
        ]
    }

    async addNewPartToResources(part) {
        if (part) {
            const environments = await API.get('environments', 2)
            let env = environments.find(
                (e) => e._id === this.props.environmentId
            )
            if (env && env.resources && env.resources.parts) {
                env.resources.parts.push(part._id)
                await API.patch('environments/' + env._id, env, 2)
            }
        }
    }

    componentWillMount() {
        this.subscriber.add(
            this.fetchParts,
            3000,
            'DigitalTwin_FactoryParts_fetchParts'
        )
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstPartEl = (
                <Button color="primary" onClick={this.toggleAddPartModal}>
                    Add Your First Part
                </Button>
            ),
            tableExists = this.state.allParts.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Colxx
                            xs="12"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Button
                                color="primary"
                                onClick={this.toggleAddPartModal}>
                                Add Part
                            </Button>
                        </Colxx>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Parts Found"
                    emptyCrudSubText={addFirstPartEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.allParts}
                    editorModalTitle="Part Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addPartModal ? (
                    <AddPartModal
                        toggleModal={this.toggleAddPartModal}
                        modal={this.state.addPartModal}
                        parts={this.state.allParts}
                        postSaveHook={this.addNewPartToResources}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        parts={this.state.allParts}
                        editingPart={this.state.editingPart}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        parts={this.state.allParts}
                        editingPart={this.state.editingPart}
                    />
                ) : null}
            </Fragment>
        )
    }
}
