import React, {Component} from 'react'
import {Bar} from 'react-chartjs-2'
import autoBind from 'auto-bind'

let chartConfig = {
    title: {
        display: true,
        text: 'Pareto Chart (values in hours)',
    },
    responsive: true,
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    layout: {
        padding: {
            bottom: 50,
        },
    },
    scales: {
        yAxes: [
            {
                stacked: false,
                id: 'a',
                position: 'left',
                type: 'linear',
                ticks: {
                    min: 0,
                },
            },
            {
                id: 'b',
                position: 'right',
                type: 'linear',
                ticks: {
                    max: 100,
                    min: 0,
                    callback: function (value) {
                        return value + '%'
                    },
                },
                gridLines: {
                    display: false,
                },
            },
        ],
        xAxes: [
            {
                stacked: false,
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }

    render() {
        if (this.props.data.datasets.length > 2) {
            chartConfig.scales.xAxes[0].stacked = true
            chartConfig.scales.yAxes[0].stacked = true
        } else {
            chartConfig.scales.xAxes[0].stacked = false
            chartConfig.scales.yAxes[0].stacked = false
        }

        return <Bar data={this.props.data} options={chartConfig} />
    }
}
