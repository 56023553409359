import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import Select from 'react-select'
import moment from 'moment'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class HMI2 extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'HMI2'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            operator: null,
            partNumber: null,
            operators: [],
            partNumbers: [],
            flash: false,
            backgroundColor: '#fff',
        }
    }

    async fetchOperators() {
        let {result} = await API.post(
            'data-models/60143d08d2534d001a18d1df/paginate',
            {
                filter: [],
                sort: {},
                limit: 1000,
                timezone: localStorage['timezone'],
            },
            2
        )

        if (result && result.results.length) {
            this.setState({
                operators: result.results
                    .map((o) => o.Name)
                    .sort((a, b) => a.localeCompare(b)),
            })
        } else {
            alert('Could not fetch operators')
        }
    }

    async fetchPartNumbers() {
        let {result} = await API.post(
            'data-models/60144818540718001ba06329/paginate',
            {
                filter: [],
                sort: {},
                limit: 1000,
                timezone: localStorage['timezone'],
            },
            2
        )

        if (result && result.results.length) {
            this.setState({
                partNumbers: result.results
                    .map((o) => o.Name)
                    .sort((a, b) => a.localeCompare(b)),
            })
        } else {
            alert('Could not fetch partNumbers')
        }
    }

    async handleOperatorSignIn({label, value}) {
        const ts = moment().toISOString()

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                timestamp: ts,
                timeStart: ts,
                timeEnd: null,
                name: 'Operator Sign In',
                value: value,
            },
            2
        )

        this.setState({operator: value})
    }

    async handleOperatorSignOut() {
        const records = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Operator Sign In',
                    timeEnd: null,
                },
            },
            2
        )

        if (records && records.length) {
            let operator = records.find((r) => r.name === 'Operator Sign In')
            operator.timeEnd = moment().toISOString()
            if (operator) {
                await API.patch('states/' + operator._id, operator, 2)
                this.setState({operator: null})
            }
        }
    }

    async handlePartNumberStart({label, value}) {
        const ts = moment().toISOString()

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                timestamp: ts,
                timeStart: ts,
                timeEnd: null,
                name: 'Part Number Instance',
                value: value,
            },
            2
        )

        this.setState({partNumber: value})
    }

    async handlePartNumberFinish() {
        const records = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Part Number Instance',
                    timeEnd: null,
                },
            },
            2
        )

        if (records && records.length) {
            let partNumber = records.find(
                (r) => r.name === 'Part Number Instance'
            )
            partNumber.timeEnd = moment().toISOString()
            if (partNumber) {
                await API.patch('states/' + partNumber._id, partNumber, 2)
                this.setState({partNumber: null})
            }
        }
    }

    async checkExisting() {
        const records = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: [
                            'Operator Sign In',
                            'Part Number Instance',
                            'In-Cycle',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        if (records) {
            const operator = records.find((r) => r.name === 'Operator Sign In')
            const partNumber = records.find(
                (r) => r.name === 'Part Number Instance'
            )
            const incycle = records.find((r) => r.name === 'In-Cycle')
            if (operator) {
                this.setState({operator: operator.value})
            } else {
                this.setState({operator: undefined})
            }
            if (partNumber) {
                this.setState({partNumber: partNumber.value})
            } else {
                this.setState({partNumber: undefined})
            }
            if (!operator && incycle) {
                this.setState({flash: true})
            } else {
                this.setState({flash: false, backgroundColor: '#fff'})
            }
        }
    }

    setBackgroundColor() {
        let {flash, backgroundColor} = this.state

        if (flash) {
            if (backgroundColor === '#fff') {
                backgroundColor = '#d9534f'
            } else {
                backgroundColor = '#fff'
            }
        }

        this.setState({backgroundColor})
    }

    async componentDidMount() {
        await this.fetchOperators()
        await this.fetchPartNumbers()

        //check for existing statuses
        await this.checkExisting()

        this.subscriber.add(this.checkExisting, 1000 * 10, 'fetchExisting')
        this.subscriber.add(this.fetchOperators, 1000 * 60, 'fetchOperators')
        this.subscriber.add(
            this.fetchPartNumbers,
            1000 * 60,
            'fetchPartNumbers'
        )
        this.subscriber.add(this.setBackgroundColor, 1000, 'setBackgroundColor')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.8 + 'px'

        return (
            <Fragment>
                <Row style={{height}}>
                    <Col xs="6" style={{height: '100%'}}>
                        <Card
                            style={{
                                height: '100%',
                                background: this.state.backgroundColor,
                            }}>
                            <CardBody style={{position: 'relative'}}>
                                <div
                                    style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%',
                                    }}>
                                    {this.state.operator ? (
                                        <div
                                            style={{
                                                width: '300px',
                                                textAlign: 'center',
                                            }}>
                                            <h5>
                                                Signed in as:{' '}
                                                <b>{this.state.operator}</b>
                                            </h5>
                                            <Button
                                                onClick={
                                                    this.handleOperatorSignOut
                                                }>
                                                Sign out
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                width: '300px',
                                                textAlign: 'center',
                                            }}>
                                            <h5>Sign in:</h5>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="operator"
                                                value={{
                                                    label: this.state.operator,
                                                    value: this.state.operator,
                                                }}
                                                onChange={
                                                    this.handleOperatorSignIn
                                                }
                                                options={this.state.operators.map(
                                                    (o) => {
                                                        return {
                                                            label: o,
                                                            value: o,
                                                        }
                                                    }
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6" style={{height: '100%'}}>
                        <Card
                            style={{
                                height: '100%',
                                background: this.state.backgroundColor,
                            }}>
                            <CardBody style={{position: 'relative'}}>
                                <div
                                    style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%',
                                    }}>
                                    {this.state.partNumber ? (
                                        <div
                                            style={{
                                                width: '300px',
                                                textAlign: 'center',
                                            }}>
                                            <h5>
                                                Part Number:{' '}
                                                <b>{this.state.partNumber}</b>
                                            </h5>
                                            <Button
                                                onClick={
                                                    this.handlePartNumberFinish
                                                }>
                                                Finish
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                width: '300px',
                                                textAlign: 'center',
                                            }}>
                                            <h5>Start working on:</h5>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="partNumber"
                                                value={{
                                                    label: this.state
                                                        .partNumber,
                                                    value: this.state
                                                        .partNumber,
                                                }}
                                                onChange={
                                                    this.handlePartNumberStart
                                                }
                                                options={this.state.partNumbers.map(
                                                    (o) => {
                                                        return {
                                                            label: o,
                                                            value: o,
                                                        }
                                                    }
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
