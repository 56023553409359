import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import StaticHourlyActualTile from './StaticHourlyActualTile'

export default class TVDisplayCustom extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'realtimeactiveshift',
            component: 'RealTimeActiveShift',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'TVDisplayCustom'
    requiredOutputs = [
        'Part Count',
        'In-Cycle',
        'Downtime',
        'Static Hourly Target',
    ]
    static propTypes = {
        name: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
        }
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        this.setState({
            devices,
        })
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const colWidth = this.state.devices.length == 1 ? 12 : 6
        return (
            <Fragment>
                <Row>
                    {this.state.devices.length
                        ? this.state.devices.map((device, i) => {
                              return (
                                  <Col xs={colWidth} key={i}>
                                      <StaticHourlyActualTile
                                          deviceId={device.deviceId}
                                          name={device.name}
                                          realtimeactiveshift={
                                              this.props.realtimeactiveshift
                                          }
                                      />
                                  </Col>
                              )
                          })
                        : null}
                </Row>
            </Fragment>
        )
    }
}
