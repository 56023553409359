import React, {Component, Fragment} from 'react'
import {Tooltip} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.userId = localStorage['user_id']

        this.state = {
            favoriteView: null,
            tooltipOpen: false,
            users: [],
        }
    }

    getCurrentView() {
        let obj = {
            environmentId: null,
            sectionId: null,
            viewId: null,
        }

        const currentPath = this.props.history.location.pathname.split('/')
        if (
            currentPath.constructor === Array &&
            currentPath[3] &&
            currentPath[4] &&
            currentPath[5]
        ) {
            obj = {
                environmentId: currentPath[3],
                sectionId: currentPath[4],
                viewId: currentPath[5],
            }
        }
        return obj
    }

    async fetchUsers() {
        const users = await API.get('users?all=true', 2)
        if (users) this.setState({users})
    }

    async getUser() {
        let user = null
        if (this.userId) {
            user = this.state.users.find((u) => u._id === this.userId) || null
        }
        return user
    }

    async getFavoriteView() {
        let user = await this.getUser(),
            set = false

        if (user && user.favoriteView) {
            const {environmentId, sectionId, viewId} = user.favoriteView
            // check if view exists
            let environment = this.props.environments.find(
                    (e) => e._id === environmentId
                ),
                section = null,
                view = null

            if (environment) {
                section = environment.sections.find((s) => s.id === sectionId)

                if (section) {
                    view = section.views.find((v) => v.id === viewId)

                    if (view) {
                        this.setState({
                            favoriteView: user.favoriteView,
                        })
                        set = true
                    }
                }
            }
        }
        if (!set) {
            this.setState({favoriteView: null})
        }
    }

    currentViewIsFavorite() {
        if (!this.state.favoriteView) return false

        const {environmentId, sectionId, viewId} = this.state.favoriteView,
            currentView = this.getCurrentView()

        return (
            currentView.environmentId === environmentId &&
            currentView.sectionId === sectionId &&
            currentView.viewId === viewId
        )
    }

    toggleTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        })
    }

    async clearFavorite() {
        let user = await this.getUser()
        if (user) {
            await API.patch('users/' + this.userId, {favoriteView: null}, 2)
        }
        await this.getFavoriteView()
    }

    async setFavorite() {
        let success = false
        const currentView = this.getCurrentView()
        if (
            currentView.environmentId &&
            currentView.sectionId &&
            currentView.viewId
        ) {
            let user = await this.getUser()
            if (user) {
                let obj = {
                    favoriteView: currentView,
                }
                await API.patch('users/' + this.userId, obj, 2)
                await this.getFavoriteView()
                success = true
            }
        }

        if (!success) alert('Cannot set this view as a default.')
    }

    componentWillReceiveProps(nextProps) {
        this.getFavoriteView()
        this.routeToFavorite()
    }

    async routeToFavorite() {
        if (this.props.history.location.pathname === '/app/core/splash') {
            await this.getFavoriteView()
            if (this.state.favoriteView) {
                const {environmentId, sectionId, viewId} =
                    this.state.favoriteView
                this.props.changeEnvironment(
                    this.props.environments.find((e) => e._id === environmentId)
                        .name,
                    sectionId,
                    viewId
                )
            }
        }
    }

    thereIsView() {
        const currentView = this.getCurrentView()
        return (
            currentView.environmentId &&
            currentView.sectionId &&
            currentView.viewId
        )
    }

    async componentDidMount() {
        await this.fetchUsers()

        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Users/#`,
            this.fetchUsers
        )
    }

    componentWillUnmount() {
        try {
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Users/#`,
                this.fetchUsers
            )
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <Fragment>
                {this.thereIsView() ? (
                    <div className="position-relative d-inline-block">
                        <div className="dropdown-menu-right dropdown show">
                            {this.currentViewIsFavorite() ? (
                                <>
                                    <button
                                        id="faveViewButton"
                                        className="header-icon btn btn-empty"
                                        onClick={this.clearFavorite}>
                                        <i
                                            className="simple-icon-star"
                                            style={{color: '#145388'}}></i>
                                    </button>
                                    <Tooltip
                                        placement="auto"
                                        isOpen={this.state.tooltipOpen}
                                        target="faveViewButton"
                                        toggle={this.toggleTooltip}>
                                        This is your default view. Click here to
                                        remove as the default.
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <button
                                        id="faveViewButton"
                                        className="header-icon btn btn-empty"
                                        onClick={this.setFavorite}>
                                        <i className="simple-icon-star"></i>
                                    </button>
                                    <Tooltip
                                        placement="auto"
                                        isOpen={this.state.tooltipOpen}
                                        target="faveViewButton"
                                        toggle={this.toggleTooltip}>
                                        Click here to set this view as your
                                        default.
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
            </Fragment>
        )
    }
}
