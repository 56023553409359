import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import RawMaterial from '../RawMaterial'
import Powder from '../PowderAndCollar/Powder'
import Collar from '../PowderAndCollar/Collar'
import Block from '../Block'
import Reports from '../Reports'

export default class HubbellWadsworthHome extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'HubbellWadsworthHome'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.role = JSON.parse(localStorage['userObject']).role

        this.state = {
            selectedSection: null,
        }
    }

    reset() {
        this.setState({selectedSection: null})
    }

    goTo(path) {
        this.props.history.push(path)
    }

    render() {
        return (
            <Fragment>
                {this.state.selectedSection === null ? (
                    <Fragment>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Home Page</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <ButtonGroup vertical>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Raw Material',
                                            })
                                        }}>
                                        RAW MATERIAL
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Powder',
                                            })
                                        }}>
                                        POWDER
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Collar',
                                            })
                                        }}>
                                        COLLAR
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Block',
                                            })
                                        }}>
                                        BLOCK
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() => {
                                            this.setState({
                                                selectedSection: 'Reports',
                                            })
                                        }}>
                                        REPORTS
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div
                            className="wadsworth-content"
                            style={{
                                height:
                                    document.documentElement.offsetHeight * 0.9,
                            }}>
                            <div
                                style={{
                                    minHeight:
                                        document.documentElement.offsetHeight *
                                        0.9,
                                    width: '95%',
                                }}>
                                {this.state.selectedSection ===
                                'Raw Material' ? (
                                    <RawMaterial
                                        path={[{name: 'Home', fn: this.reset}]}
                                        role={this.role}
                                    />
                                ) : null}
                                {this.state.selectedSection === 'Powder' ? (
                                    <Powder
                                        path={[{name: 'Home', fn: this.reset}]}
                                        role={this.role}
                                    />
                                ) : null}
                                {this.state.selectedSection === 'Collar' ? (
                                    <Collar
                                        path={[{name: 'Home', fn: this.reset}]}
                                        role={this.role}
                                    />
                                ) : null}
                                {this.state.selectedSection === 'Block' ? (
                                    <Block
                                        path={[{name: 'Home', fn: this.reset}]}
                                        role={this.role}
                                    />
                                ) : null}
                                {this.state.selectedSection === 'Reports' ? (
                                    <Reports
                                        path={[{name: 'Home', fn: this.reset}]}
                                        role={this.role}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        )
    }
}
