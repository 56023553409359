import React, {Component, Fragment} from 'react'
import {Button, Label, Row, Col} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AssetModal from './modal'
import RemoveModal from './remove'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Device ID',
        id: 'deviceId',
        accessor: (row) => {
            return (
                <div
                    onClick={(e) => {
                        alert(row.deviceId)
                        e.preventDefault()
                        e.stopPropagation()
                    }}>
                    {row.deviceId}
                </div>
            )
        },
    },
    {
        Header: 'Group',
        accessor: 'groupsString',
    },
    {
        Header: 'ERP Name',
        accessor: 'erpAssetName',
    },
]

export default class AssetManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'AssetManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
            nodes: [],
            editingDevice: null,
            addAssetModal: false,
            globalERPNaming: localStorage['globalERPNaming'],
        }
    }
    async fetchAssets() {
        let assets = await API.get('devices')
        let allAssets = await API.get('devices?all=true')

        if (assets && allAssets) this.setState({assets, allAssets})
    }

    async fetchNodes() {
        let nodes = await API.get('nodes', 2)
        this.setState({nodes})
    }
    toggleAddAssetModal() {
        this.setState({
            addAssetModal: !this.state.addAssetModal,
        })
    }
    toggleEditAssetModal() {
        this.setState({
            editAssetModal: !this.state.editAssetModal,
        })
    }
    toggleModal(modalName, device) {
        let state = this.state
        state.editingDevice = device
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 12,
                onClickFn: (device) =>
                    this.toggleModal('editAssetModal', device),
                actionName: 'Edit Asset',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (device) => {
                    if (
                        this.state.nodes.find(
                            (node) => node.deviceId === device.deviceId
                        )
                    ) {
                        alert(
                            'This asset is associated with active SensorBots/E-Series. Please detach associated SensorBots/E-Series first.'
                        )
                    } else {
                        this.toggleModal('removeModal', device)
                    }
                },
                actionName: 'Remove Asset',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(device) {
        if (!device.groups) device.groupsString = []
        else device.groupsString = device.groups.join(', ')
        return device
    }

    editorModalAttributes(device) {
        return [['Name', device.name]]
    }

    async addNewAssetToResources(asset) {
        if (asset) {
            const environments = await API.get('environments', 2)
            let env = environments.find(
                (e) => e._id === this.props.environmentId
            )
            if (env && env.resources && env.resources.devices) {
                env.resources.devices.push(asset.deviceId)
                await API.patch('environments/' + env._id, env, 2)
            }
        }
    }

    async handleGlobalERPNamingChange(globalERPNaming) {
        const missing = this.state.assets.filter(
            (a) => a.erpAssetName === '' || !a.erpAssetName
        )
        if (missing.length) {
            alert(
                `${missing
                    .map((a) => a.name)
                    .join(
                        ', '
                    )} do not have an ERP asset name set. Please set one for each and try again`
            )
            return
        }
        this.setState({globalERPNaming})

        const allUsers = await API.get('users?all=true', 2),
            me = allUsers.find(
                (a) => a._id === JSON.parse(localStorage['userObject'])._id
            )

        if (!me.favoriteView) {
            me.favoriteView = {}
        }

        me.globalERPNaming = globalERPNaming
        delete me.username
        delete me.password
        delete me.firstName
        delete me.lastName
        delete me.role
        delete me.favoriteView
        await API.patch('users/' + me._id, me, 2)

        alert(
            'Please sign out and sign back in for these changes to take effect.'
        )
    }

    componentDidMount() {
        this.subscriber.add(
            this.fetchAssets,
            3000,
            'DigitalTwin_FactoryAssets_fetchAssets'
        )
        this.subscriber.add(
            this.fetchNodes,
            1000 * 10,
            'DigitalTwin_FactoryAssets_fetchNodes'
        )
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstGatewayEl = (
                <Button color="primary" onClick={this.toggleAddAssetModal}>
                    Add Your First Asset
                </Button>
            ),
            tableExists = this.state.assets.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col xs="6" className="mb-2">
                            <Button
                                color="primary"
                                onClick={this.toggleAddAssetModal}>
                                Add Asset
                            </Button>
                        </Col>
                        <Col
                            xs="6"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Label>
                                <Switch
                                    checked={this.state.globalERPNaming}
                                    onChange={this.handleGlobalERPNamingChange}
                                />{' '}
                                Display assets as their ERP name (only for my
                                account)
                            </Label>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Assets Found"
                    emptyCrudSubText={addFirstGatewayEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.assets}
                    editorModalTitle="Asset Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addAssetModal ? (
                    <AssetModal
                        toggleModal={this.toggleAddAssetModal}
                        modal={this.state.addAssetModal}
                        assets={this.state.allAssets}
                        postSaveHook={this.addNewAssetToResources}
                    />
                ) : null}
                {this.state.editAssetModal ? (
                    <AssetModal
                        toggleModal={this.toggleEditAssetModal}
                        modal={this.state.editAssetModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                    />
                ) : null}

                {this.state.renameModal ? (
                    <RenameModal // TODO undefined component
                        toggleModal={this.toggleModal}
                        modal={this.state.renameModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                    />
                ) : null}

                {this.state.editGroupsModal ? (
                    <EditGroupsModal // TODO undefined component
                        toggleModal={this.toggleModal}
                        modal={this.state.editGroupsModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                    />
                ) : null}

                {this.state.editImageModal ? (
                    <EditImageModal // TODO undefined component
                        toggleModal={this.toggleModal}
                        modal={this.state.editImageModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                    />
                ) : null}

                {this.state.editDowntimeThresholdModal ? (
                    <ChangeDowntimeThresholdModal // TODO undefined component
                        toggleModal={this.toggleModal}
                        modal={this.state.editDowntimeThresholdModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                    />
                ) : null}

                {this.state.editOEETargetsModal ? (
                    <ChangeOEETargetsModal // TODO undefined component
                        toggleModal={this.toggleModal}
                        modal={this.state.editOEETargetsModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                        refresh={() => {
                            this.fetchAssets()
                        }}
                    />
                ) : null}

                {this.state.editERPMetaDataModal ? (
                    <SetERPMetadataModal // TODO undefined component
                        toggleModal={this.toggleModal}
                        modal={this.state.editERPMetaDataModal}
                        assets={this.state.allAssets}
                        editingAsset={this.state.editingDevice}
                        refresh={() => {
                            this.fetchAssets()
                        }}
                    />
                ) : null}
            </Fragment>
        )
    }
}
