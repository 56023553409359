import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button, Form, Label} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import autobind from 'auto-bind'
import {ThemeColors} from 'util/ThemeColors'
import CustomSelectInput from 'components/CustomSelectInput'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import {getReportAssets} from 'SDK/api/common'
import ReportChart from './chart'
import ExportData from './export'

const colors = ThemeColors()

const TIME_UNIT_OPTIONS = [
    {
        label: 'Total Time',
        value: 'total',
        key: 0,
    },
    {
        label: 'Hours',
        value: 'hours',
        key: 1,
    },
    {
        label: 'Days',
        value: 'days',
        key: 2,
    },
    {
        label: 'Weeks',
        value: 'weeks',
        key: 3,
    },
    {
        label: 'Months',
        value: 'months',
        key: 4,
    },
    {
        label: 'Quarters',
        value: 'quarters',
        key: 5,
    },
    {
        label: 'Years',
        value: 'years',
        key: 6,
    },
]

const getRandomColor = () => {
    let letters = '0123456789ABCDEF'.split(''),
        color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export default class PartReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'offsetOvernightShifts',
            component: 'OffsetOvernightShifts',
        },
    ]
    showBorder = false
    id = 'PartReport'
    requiredOutputs = ['Part Count']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        offsetOvernightShifts: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            ready: false,
            loading: false,
            assets: [],
            selectedAssets: [],
            data: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            timeUnit: TIME_UNIT_OPTIONS[0],
            byShift: false,
            hours: false,
            shifts: [],
            type: '',
            csvData: [],
            shiftRecords: [],
        }
    }
    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }

    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }

    handleTimeUnitChange(e) {
        this.setState({
            timeUnit: TIME_UNIT_OPTIONS.find((x) => e.label === x.label),
        })
    }

    handleAssetChange(e) {
        this.setState({
            selectedAssets: e,
        })
    }

    async getAssets() {
        const assets = await getReportAssets()
        if (!assets) return alert('Could not fetch factory assets.')

        this.setState({
            assets: assets,
            selectedAssets: assets.map((a, i) => {
                const productionUnit = a.productionUnit
                    ? a.productionUnit.charAt(0).toUpperCase() +
                      a.productionUnit.slice(1) +
                      's'
                    : 'Parts'
                return {
                    label: `${a.name} (${productionUnit})`,
                    value: a.deviceId,
                    key: i,
                    name: a.name,
                }
            }),
        })
    }

    async getShifts() {
        const shifts = await API.get('shifts')
        if (!shifts) return alert('Could not fetch factory shifts.')

        this.setState({
            shifts: [...new Set(shifts.map((x) => x.name))],
            shiftRecords: shifts,
        })
    }

    getDeviceData(deviceId, shift) {
        let timeUnit =
            this.state.timeUnit.value === 'total'
                ? 'years'
                : this.state.timeUnit.value
        let obj = {
            timeStart: moment(this.state.start).toISOString(),
            timeEnd: moment(this.state.end).subtract(0, 'hours').toISOString(),
            deviceId: deviceId,
            state: 'Part Count',
            unit: timeUnit,
        }
        if (shift) {
            if (this.props.offsetOvernightShifts) {
                const shiftObject = this.state.shiftRecords.find(
                    (s) => s.name === shift && s.assets.includes(deviceId)
                )
                if (
                    shiftObject &&
                    shiftObject.timeStart.hour > shiftObject.timeEnd.hour
                ) {
                    obj.timeStart = moment(obj.timeStart)
                        .add(-1, 'days')
                        .toISOString()
                    obj.timeEnd = moment(obj.timeEnd)
                        .add(-1, 'days')
                        .toISOString()
                }
            }
            obj.shift = shift
        }
        return API.post('historical', obj)
    }
    async generateTotal() {
        const {data} = await API.post('historical/aggregate2', {
            timeStart: moment(this.state.start).toISOString(),
            timeEnd: moment(this.state.end).toISOString(),
            deviceId: this.state.selectedAssets.map((x) => x.value),
            state: ['Part Count'],
            logic: 'count',
            groupByTimeUnit: 'total',
        })

        const capacity = moment(this.state.end).diff(
            moment(this.state.start),
            'minutes'
        )
        let chartdata = {
            labels: [],
            datasets: [
                {
                    label: 'Count',
                    data: [],
                    backgroundColor: colors.themeColor1,
                },
            ],
        }
        for (let i in data.devices) {
            let row = data.devices[i],
                deviceName = this.state.assets.find(
                    (x) => x.deviceId === i
                ).name
            chartdata.labels.push(deviceName)
            chartdata.datasets[0].data.push(
                row['Part Count'][0].constructor === Object
                    ? row['Part Count'][0].count
                    : row['Part Count'][0]
            )
        }
        this.setState({
            data: {chartdata: chartdata, hours: this.state.hours},
            ready: true,
            loading: false,
        })
    }
    async generateTotalByShift() {
        await this.getShifts()
        let self = this
        let chartdata = {
            labels: this.state.selectedAssets.map((x) => x.label),
            datasets: this.state.shifts.map((x) => {
                return {
                    label: x,
                    data: [],
                    backgroundColor: getRandomColor(),
                }
            }),
        }
        let promises = []
        for (let i in this.state.selectedAssets) {
            for (let j in this.state.shifts) {
                if (
                    this.state.shiftRecords.find(
                        (s) =>
                            s.name === this.state.shifts[j] &&
                            s.assets.find(
                                (asset) =>
                                    asset === this.state.selectedAssets[i].value
                            )
                    )
                ) {
                    promises.push(
                        this.getDeviceData(
                            this.state.selectedAssets[i].value,
                            this.state.shifts[j]
                        )
                    )
                }
            }
        }
        Promise.all(promises)
            .then((data) => {
                for (let i in data) {
                    let d = data[i]
                    const device = this.state.assets.find(
                        (y) => y.deviceId === d.query.deviceId
                    )
                    const productionUnit = device.productionUnit
                        ? device.productionUnit.charAt(0).toUpperCase() +
                          device.productionUnit.slice(1) +
                          's'
                        : 'Parts'
                    let datasetIndex = chartdata.labels.findIndex(
                        (x) =>
                            x ===
                            this.state.assets.find(
                                (y) => y.deviceId === d.query.deviceId
                            ).name +
                                ` (${productionUnit})`
                    )
                    let chartdataIndex = chartdata.datasets.findIndex(
                        (x) => x.label === d.query.shift
                    )
                    chartdata.datasets[chartdataIndex].data[datasetIndex] =
                        d.count[0]
                }
                self.setState({
                    data: {chartdata: chartdata, hours: self.state.hours},
                    ready: true,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    generate() {
        // validation
        if (this.state.selectedAssets.length === 0)
            return alert('At least one asset must be selected.')

        this.setState({loading: true})
        if (this.state.timeUnit.value === 'total' && !this.state.byShift) {
            this.generateTotal()
            this.setState({type: 'total'})
        } else if (
            this.state.timeUnit.value === 'total' &&
            this.state.byShift
        ) {
            this.generateTotalByShift()
            this.setState({type: 'totalByShift'})
        } else if (
            this.state.timeUnit.value !== 'total' &&
            !this.state.byShift
        ) {
            this.generateTimeSeries()
            this.setState({type: 'timeSeries'})
        } else if (
            this.state.timeUnit.value !== 'total' &&
            this.state.byShift
        ) {
            if (this.state.timeUnit.value === 'hours') {
                this.setState({loading: false})
                return alert(
                    'Time Unit must be greater than hours when generating shift reports.'
                )
            }
            this.generateTimeSeriesByShift()
            this.setState({type: 'timeSeriesByShift'})
        }
    }
    async generateTimeSeries() {
        let self = this
        let chartdata = {
            labels: [],
            datasets: [],
        }
        Promise.all(
            this.state.selectedAssets.map((x) => this.getDeviceData(x.value))
        )
            .then((data) => {
                if (self.state.timeUnit.value === 'hours') {
                    chartdata.labels = data[0].dates.map((x) =>
                        moment(x).format('YYYY-MM-DD h a')
                    )
                } else if (self.state.timeUnit.value !== 'total') {
                    chartdata.labels = data[0].dates.map((x) =>
                        moment(x).format('YYYY-MM-DD')
                    )
                }
                chartdata.datasets = data.map((d) => {
                    return {
                        label: self.state.assets.find(
                            (x) => x.deviceId === d.query.deviceId
                        ).name,
                        data: d.count,
                        backgroundColor: getRandomColor(),
                    }
                })
                self.setState({
                    data: {chartdata: chartdata, hours: self.state.hours},
                    ready: true,
                    loading: false,
                })
                //console.log(chartdata)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    async generateTimeSeriesByShift() {
        await this.getShifts()
        let self = this
        let chartdatas = []

        const colors = this.state.selectedAssets.map((x) => getRandomColor())

        for (let i in this.state.shifts) {
            chartdatas[i] = {
                shift: this.state.shifts[i],
                labels: [],
                datasets: this.state.selectedAssets.map((x, i) => {
                    return {
                        label: x.label,
                        data: [],
                        backgroundColor: colors[i],
                    }
                }),
            }
        }
        let promises = []
        for (let i in this.state.selectedAssets) {
            for (let j in this.state.shifts) {
                if (
                    this.state.shiftRecords.find(
                        (s) =>
                            s.name === this.state.shifts[j] &&
                            s.assets.find(
                                (asset) =>
                                    asset === this.state.selectedAssets[i].value
                            )
                    )
                ) {
                    promises.push(
                        this.getDeviceData(
                            this.state.selectedAssets[i].value,
                            this.state.shifts[j]
                        )
                    )
                }
            }
        }
        Promise.all(promises)
            .then((data) => {
                if (this.props.offsetOvernightShifts) {
                    for (let dataset of data) {
                        const shiftRecord = this.state.shiftRecords.find(
                            (s) =>
                                s.name === dataset.query.shift &&
                                s.assets.includes(dataset.query.deviceId)
                        )
                        if (
                            shiftRecord &&
                            shiftRecord.timeStart.hour >
                                shiftRecord.timeEnd.hour
                        ) {
                            dataset.dates = dataset.dates.map((d) => {
                                return moment(d).add(1, 'days').toISOString()
                            })
                        }
                    }
                }

                for (let i in chartdatas) {
                    if (self.state.timeUnit.value === 'hours') {
                        chartdatas[i].labels = data[0].dates.map((x) =>
                            moment(x).format('YYYY-MM-DD h a')
                        )
                    } else if (self.state.timeUnit.value !== 'total') {
                        chartdatas[i].labels = data[0].dates.map((x) =>
                            moment(x).format('YYYY-MM-DD')
                        )
                    }
                }
                for (let i in data) {
                    let d = data[i] //.data;
                    // chartIndex by shift
                    let chartdataIndex = chartdatas.findIndex(
                        (x) => x.shift === d.query.shift
                    )
                    // datasetIndex by device
                    const asset = this.state.assets.find(
                        (y) => y.deviceId === d.query.deviceId
                    )
                    const productionUnit = asset.productionUnit
                        ? asset.productionUnit.charAt(0).toUpperCase() +
                          asset.productionUnit.slice(1) +
                          's'
                        : 'Parts'
                    let datasetIndex = chartdatas[
                        chartdataIndex
                    ].datasets.findIndex(
                        (x) =>
                            x.label ===
                            this.state.assets.find(
                                (y) => y.deviceId === d.query.deviceId
                            ).name +
                                ` (${productionUnit})`
                    )
                    chartdatas[chartdataIndex].datasets[datasetIndex].data =
                        d.count
                }
                self.setState({
                    data: chartdatas,
                    ready: true,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    generateCharts() {
        const exportSection = (
            <Row>
                <Col xs="12" style={{textAlign: 'right'}} className="mb-4">
                    <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={this.exportData}>
                        <i className="iconsmind-Printer" /> Export Data
                    </Button>
                </Col>
            </Row>
        )

        if (this.state.ready && !this.state.loading) {
            let charts = []
            if (this.state.data.constructor === Array) {
                for (let i in this.state.data) {
                    let data = {
                        chartdata: this.state.data[i],
                        hours: this.state.hours,
                    }
                    charts.push(<ReportChart data={data} />)
                }
            } else {
                charts.push(<ReportChart data={this.state.data} />)
            }
            //this.exportData();
            return charts
        } else {
            return <p>Loading...</p>
        }
    }
    async componentWillMount() {
        await this.getAssets()
    }

    render() {
        const readyToExport = this.state.ready && !this.state.loading
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Assets:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="assets"
                                            value={this.state.selectedAssets}
                                            onChange={this.handleAssetChange}
                                            options={this.state.assets.map(
                                                (a, i) => {
                                                    const productionUnit =
                                                        a.productionUnit
                                                            ? a.productionUnit
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                              a.productionUnit.slice(
                                                                  1
                                                              ) +
                                                              's'
                                                            : 'Parts'
                                                    return {
                                                        label: `${a.name} (${productionUnit})`,
                                                        value: a.deviceId,
                                                        key: i,
                                                    }
                                                }
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="2">
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleStartChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleEndChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>Time Unit:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="time-unit"
                                            value={this.state.timeUnit}
                                            onChange={this.handleTimeUnitChange}
                                            options={TIME_UNIT_OPTIONS}
                                            isDisabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>
                                            By Shift (group by shift):
                                        </label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={this.state.byShift}
                                            onChange={(byShift) => {
                                                this.setState({byShift})
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" style={{textAlign: 'right'}}>
                                        <br />
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {readyToExport ? (
                    <ExportData
                        type={this.state.type}
                        selectedAssets={this.state.selectedAssets}
                        data={this.state.data}
                        hours={this.state.hours}
                        shifts={this.state.shifts}
                    />
                ) : null}
                {this.state.ready ? this.generateCharts() : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
