import React, {Component, Fragment} from 'react'
import {Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            dom: [],
            showModal: false,
        }
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async fetchUpdates() {
        let updates = await API.get('updates', 2),
            dom = []

        if (updates && updates.length) {
            updates.sort(
                (a, b) =>
                    moment(b.timestamp).valueOf() -
                    moment(a.timestamp).valueOf()
            )

            for (let update of updates) {
                dom.push(
                    <>
                        <Row>
                            <Col>
                                <p className="text-muted mb-0">
                                    {moment(update.timestamp).format('MMMM Do')}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4>{update.title}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>{update.description}</p>
                                <hr />
                            </Col>
                        </Row>
                    </>
                )
            }
            this.setState({dom}, () => {
                let latestVersion = updates[0].version,
                    clientVersion =
                        localStorage['update_log_up_to_version'] || null

                if (latestVersion != clientVersion) {
                    localStorage['update_log_up_to_version'] = latestVersion
                    this.toggleModal()
                    const role = JSON.parse(localStorage['userObject']).role
                    if (role === 'Display') {
                        setTimeout(() => {
                            this.toggleModal()
                        }, 1000 * 30)
                    }
                }
            })
        }
    }

    async UNSAFE_componentWillMount() {
        this.subscriber.add(this.fetchUpdates, 1000 * 60 * 15, 'fetchUpdates()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <div className="position-relative d-inline-block">
                    <div className="dropdown-menu-right dropdown show">
                        <button
                            className="header-icon notificationButton btn btn-empty"
                            onClick={this.toggleModal}>
                            <i className="simple-icon-present"></i>
                        </button>
                    </div>
                </div>
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        What's New
                    </ModalHeader>
                    <ModalBody>{this.state.dom}</ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
