import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Alert,
    Button,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import autobind from 'auto-bind'
import {cloneDeep} from 'lodash'
import moment from 'moment'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'

import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'

const SPEED_LOSS_TYPES = [
    {
        label: 'None',
        value: 'none',
    },
    {
        label: 'Fixed Hourly Target',
        value: 'fixedHourlyTarget',
    },
    {
        label: 'Scheduled Hourly Target',
        value: 'scheduledHourlyTarget',
    },
    {
        label: 'Total Microstoppage Time',
        value: 'microstoppageTime',
    },
    {
        label: 'Rolling 30 Day Hourly Target',
        value: 'rolling30DayHourlyTarget',
    },
]

const OEE_DATA_TYPES = [
    {
        label: 'Automatic',
        value: 'automatic',
    },
    {
        label: 'Manual',
        value: 'manual',
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const {editingAsset} = this.props

        if (editingAsset && !editingAsset.speedLossType) {
            alert('Please configure speed loss type.')
        }

        this.state = {
            ready: false,
            //form
            name: editingAsset ? editingAsset.name : '',
            group:
                editingAsset &&
                editingAsset.groups &&
                editingAsset.groups.length
                    ? {
                          label: editingAsset.groups[0],
                          value: editingAsset.groups[0],
                      }
                    : null,
            downtimeThresholdSeconds:
                editingAsset && editingAsset.downtimeThresholdSeconds
                    ? editingAsset.downtimeThresholdSeconds
                    : 300,
            erpAssetName:
                editingAsset && editingAsset.erpAssetName
                    ? editingAsset.erpAssetName
                    : '',
            erpAssetDescription:
                editingAsset && editingAsset.erpAssetDescription
                    ? editingAsset.erpAssetDescription
                    : '',
            hourlyTarget: 0,
            productionUnit:
                editingAsset && editingAsset.productionUnit
                    ? editingAsset.productionUnit
                    : 'part',
            benchmark:
                editingAsset &&
                editingAsset.oeeTargets &&
                editingAsset.oeeTargets.benchmark
                    ? editingAsset.oeeTargets.benchmark
                    : 100,
            realTime:
                editingAsset &&
                editingAsset.oeeTargets &&
                editingAsset.oeeTargets.realTime
                    ? editingAsset.oeeTargets.realTime
                    : 100,
            speedLossType:
                editingAsset && editingAsset.speedLossType
                    ? SPEED_LOSS_TYPES.find(
                          (a) => a.value === editingAsset.speedLossType
                      )
                    : SPEED_LOSS_TYPES[1],
            oeeRejectType:
                editingAsset && editingAsset.oeeRejectType
                    ? OEE_DATA_TYPES.find(
                          (a) => a.value === editingAsset.oeeRejectType
                      )
                    : OEE_DATA_TYPES[1],
            oeePartType:
                editingAsset && editingAsset.oeePartType
                    ? OEE_DATA_TYPES.find(
                          (a) => a.value === editingAsset.oeePartType
                      )
                    : OEE_DATA_TYPES[0],
            oeeTargetType:
                editingAsset && editingAsset.oeeTargetType
                    ? OEE_DATA_TYPES.find(
                          (a) => a.value === editingAsset.oeeTargetType
                      )
                    : OEE_DATA_TYPES[1],

            autoAddToShifts: true,
            autoAddToReasonCodes: true,
            autoAddOneToOneFlow: true,
        }
    }

    async save() {
        let form = cloneDeep(this.state)
        form.downtimeThresholdSeconds = parseInt(form.downtimeThresholdSeconds)
        const hourlyTarget = form.hourlyTarget

        delete form.hourlyTarget
        delete form.ready

        delete form.autoAddToShifts
        delete form.autoAddToReasonCodes
        delete form.autoAddOneToOneFlow

        form.oeeTargets = {
            benchmark: form.benchmark,
            realTime: form.realTime,
        }
        delete form.benchmark
        delete form.realTime

        form.groups = form.group && form.group.value ? [form.group.value] : []
        delete form.group

        form.speedLossType = form.speedLossType.value
        form.oeePartType = form.oeePartType.value
        form.oeeRejectType = form.oeeRejectType.value
        form.oeeTargetType = form.oeeTargetType.value

        if (
            !this.props.editingAsset &&
            this.props.assets.map((a) => a.name).includes(form.name)
        ) {
            return alert('An asset with this name already exists.')
        } else if (
            this.props.editingAsset &&
            this.props.assets
                .filter((a) => a.deviceId !== this.props.editingAsset.deviceId)
                .map((a) => a.name)
                .includes(form.name)
        ) {
            return alert('An asset with this name already exists.')
        }
        if (!form.name || form.name === '') {
            return alert('"Name" is a mandatory field.')
        }

        if (!form.oeeTargets.benchmark || form.oeeTargets.benchmark < 1) {
            return alert('OEE Benchmark Target must be greater than 0.')
        }
        if (!form.oeeTargets.realTime || form.oeeTargets.realTime < 1) {
            return alert('OEE Real Time Target must be greater than 0.')
        }

        if (form.hourlyTarget < 1) {
            return alert('Hourly target must be greater than 0.')
        }

        let deviceId

        if (this.props.editingAsset) {
            deviceId = this.props.editingAsset.deviceId
            const rootPath = `devices/${this.props.editingAsset._id}/`
            try {
                if (form.name !== this.props.editingAsset.name)
                    await API.patch(`${rootPath}rename`, {name: form.name})
                await API.patch(`${rootPath}downtime-threshold`, {
                    downtimeThresholdSeconds: form.downtimeThresholdSeconds,
                })
                await API.patch(`${rootPath}groups`, {groups: form.groups})
                await API.patch(`${rootPath}production-unit`, {
                    productionUnit: form.productionUnit,
                })
                await API.patch(`${rootPath}oee-targets`, {
                    oeeTargets: form.oeeTargets,
                })
                await API.patch(`${rootPath}set-erp-metadata`, {
                    erpAssetName: form.erpAssetName,
                    erpAssetDescription: form.erpAssetDescription,
                })
                await API.patch(`${rootPath}set-speedloss-type`, {
                    speedLossType: form.speedLossType,
                })
                await API.patch(`${rootPath}oee-settings`, {
                    oeePartType: form.oeePartType,
                    oeeRejectType: form.oeeRejectType,
                    oeeTargetType: form.oeeTargetType,
                })
            } catch (error) {
                console.log(error)
                return alert(
                    'There was an error editing this asset. Please try again.'
                )
            }
        } else {
            try {
                let path = 'devices/register?'
                if (this.state.autoAddToShifts) {
                    path += 'autoAddToShifts=true&'
                }
                if (this.state.autoAddToReasonCodes) {
                    path += 'autoAddToReasonCodes=true&'
                }
                if (this.state.autoAddOneToOneFlow) {
                    path += 'autoAddOneToOneFlow=true'
                }

                let response = await API.post(path, form)
                if (response) {
                    const devices = await API.get('devices?all=true'),
                        device = devices.find((a) => a.name === form.name)
                    deviceId = device.deviceId
                    this.props.postSaveHook(device)
                } else {
                    return alert(
                        'There was an error adding this asset. Please try again.'
                    )
                }
            } catch (error) {
                console.log(error)
                return alert(
                    'There was an error adding this asset. Please try again.'
                )
            }
        }
        if (hourlyTarget > 0) {
            try {
                await API.post(
                    'tablets/data',
                    [
                        {
                            nodeId: deviceId,
                            name: 'Static Hourly Target',
                            value: parseInt(hourlyTarget),
                            timestamp: moment().valueOf(),
                        },
                    ],
                    2
                )
            } catch (error) {
                console.log(error)
                return alert('Could not set hourly target')
            }
        }
        this.props.toggleModal()
    }

    async componentDidMount() {
        if (this.props.editingAsset) {
            const hourlyTarget = await API.post(
                'historical/raw',
                {
                    query: {
                        name: 'Hourly Target',
                        deviceId: this.props.editingAsset.deviceId,
                        timeEnd: null,
                    },
                },
                2
            )
            if (hourlyTarget && hourlyTarget.length) {
                this.setState({hourlyTarget: hourlyTarget[0].value})
            }
        }
        this.setState({ready: true})
    }

    render() {
        const {modal, toggleModal, assets} = this.props
        let options = assets.map((asset) => asset.groups || []).flat()
        options = [...new Set(options)].map((group) => {
            return {label: group, value: group}
        })

        return (
            <Fragment>
                {this.state.ready ? (
                    <Modal size="lg" isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>
                            {this.props.editingAsset
                                ? 'Edit Asset'
                                : 'Add Asset'}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <h5>
                                        <strong>Asset Name & Placement</strong>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="mb-2">
                                    <label>Name:</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={(e) =>
                                            this.setState({
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col xs="12">
                                    <label>Group:</label>
                                    <Creatable
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="group"
                                        value={this.state.group}
                                        onChange={(group) =>
                                            this.setState({group})
                                        }
                                        options={options}
                                        placeholder="Select or start typing to create new group..."
                                    />
                                    <Alert color="primary">
                                        Group your assets by type, cell or
                                        however assets in your plant are
                                        categorized. Adding assets to a group
                                        provides access to more comprehensive
                                        reporting tools, such as the "Drilldown
                                        Tree".
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>
                                        <strong>Downtime Entry Settings</strong>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <label>
                                        Downtime Entry Threshold (in seconds):
                                    </label>
                                    <Input
                                        type="number"
                                        name="downtime-threshold"
                                        value={
                                            this.state.downtimeThresholdSeconds
                                        }
                                        onChange={(e) => {
                                            const v = parseInt(e.target.value)
                                            if (v < 1) {
                                                return alert(
                                                    'Value cannot be less than 1'
                                                )
                                            } else {
                                                this.setState({
                                                    downtimeThresholdSeconds: v,
                                                })
                                            }
                                        }}
                                    />
                                    <Alert color="primary">
                                        This setting only applies to how long a
                                        downtime period has to be active before
                                        users are allowed to enter a reason
                                        code. (i.e. a value of 15 seconds means
                                        that users cannot enter a downtime
                                        reason until the machine has been down
                                        for longer than 15 seconds).
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>
                                        <strong>Production Settings</strong>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <label>Production Unit:</label>
                                    <Input
                                        type="text"
                                        value={this.state.productionUnit}
                                        onChange={(e) =>
                                            this.setState({
                                                productionUnit: e.target.value,
                                            })
                                        }
                                    />
                                    <Alert color="primary">
                                        Changing this value will change the
                                        production unit in the Accumine Cloud
                                        for this asset.
                                        <br />
                                        <b>
                                            <i>
                                                Note: the underlying counter
                                                data point is called "Part
                                                Count" no matter what the
                                                production unit is set to.
                                            </i>
                                        </b>
                                    </Alert>
                                </Col>
                                <Col xs="12">
                                    <label>Hourly Target:</label>
                                    <Input
                                        type="number"
                                        value={this.state.hourlyTarget}
                                        onChange={(e) => {
                                            const v = parseInt(e.target.value)
                                            if (v < 1) {
                                                return alert(
                                                    'Value cannot be less than 1'
                                                )
                                            } else {
                                                this.setState({hourlyTarget: v})
                                            }
                                        }}
                                    />
                                    <Alert color="primary">
                                        How many {this.state.productionUnit}s
                                        should this asset produce per hour?
                                        <br />
                                        This vaue is used in real-time displays
                                        and OEE reporting ONLY IF hourly targets
                                        are not pulled automatically from your
                                        production schedule.
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>
                                        <strong>OEE Settings</strong>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <label>OEE Benchmark Target %:</label>
                                    <Input
                                        type="number"
                                        value={this.state.benchmark}
                                        onChange={(event) => {
                                            const benchmark = event.target.value
                                            this.setState({benchmark})
                                        }}
                                        disabled={this.state.benchmarkDisabled}
                                    />
                                    <Alert color="primary">
                                        This historical target{' '}
                                        {this.state.benchmark}% will be divided
                                        from the OEE calculation to find your
                                        historical efficiency trend.
                                        <u>
                                            This target cannot be modified once
                                            configured
                                        </u>
                                        .
                                    </Alert>
                                </Col>
                                <Col xs="6">
                                    <label>OEE Real-Time Target %:</label>
                                    <Input
                                        type="number"
                                        value={this.state.realTime}
                                        onChange={(event) => {
                                            const realTime = event.target.value
                                            this.setState({realTime})
                                        }}
                                    />
                                    <Alert color="primary">
                                        This real-time target{' '}
                                        {this.state.realTime}% will be divided
                                        from the OEE calculation to find your
                                        real-time efficiency.
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="4">
                                    <label>Part Count:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="oee-part-type"
                                        value={this.state.oeePartType}
                                        onChange={(oeePartType) =>
                                            this.setState({oeePartType})
                                        }
                                        options={OEE_DATA_TYPES}
                                        placeholder="Select an option..."
                                    />
                                    <Alert color="primary">
                                        Primarily for OEE, choose whether Part
                                        Count data should be collected
                                        Automatically or Manually.
                                    </Alert>
                                </Col>
                                <Col xs="4">
                                    <label>Rejects:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="oee-reject-type"
                                        value={this.state.oeeRejectType}
                                        onChange={(oeeRejectType) =>
                                            this.setState({oeeRejectType})
                                        }
                                        options={OEE_DATA_TYPES}
                                        placeholder="Select an option..."
                                    />
                                    <Alert color="primary">
                                        Primarily for OEE, choose whether Bad
                                        Parts/Rejects data should be collected
                                        Automatically or Manually.
                                    </Alert>
                                </Col>
                                <Col xs="4">
                                    <label>Hourly Target:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="oee-target-type"
                                        value={this.state.oeeTargetType}
                                        onChange={(oeeTargetType) =>
                                            this.setState({oeeTargetType})
                                        }
                                        options={OEE_DATA_TYPES}
                                        placeholder="Select an option..."
                                    />
                                    <Alert color="primary">
                                        Primarily for OEE, choose whether Hourly
                                        Target data should be collected
                                        Automatically or Manually.
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <label>
                                        OEE Speed/Performance Framework (select
                                        an option to see additional details):
                                    </label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="speed-loss-type"
                                        value={this.state.speedLossType}
                                        onChange={(speedLossType) =>
                                            this.setState({speedLossType})
                                        }
                                        options={SPEED_LOSS_TYPES}
                                        placeholder="Select an option..."
                                    />
                                    {this.state.speedLossType &&
                                    this.state.speedLossType.value ===
                                        'microstoppageTime' ? (
                                        <Alert color="danger" className="mt-2">
                                            Ensure you have an Hourly Target set
                                            above in order to calculate quality
                                            losses.
                                        </Alert>
                                    ) : null}
                                    {this.state.speedLossType &&
                                    this.state.speedLossType.value ===
                                        'none' ? (
                                        <Alert color="danger" className="mt-2">
                                            Quality losses will not be
                                            calculated if set to "None".
                                        </Alert>
                                    ) : null}
                                    <Alert color="primary">
                                        {this.state.speedLossType &&
                                        this.state.speedLossType.value ===
                                            'none' ? (
                                            <>
                                                <p>
                                                    <strong>Use for:</strong>{' '}
                                                    part count data is not
                                                    provided to the Accumine
                                                    system or when speed losses
                                                    are not applicable to the
                                                    process.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Requirements:
                                                    </strong>{' '}
                                                    no inputs/data flows needed.
                                                </p>
                                            </>
                                        ) : null}
                                        {this.state.speedLossType &&
                                        this.state.speedLossType.value ===
                                            'fixedHourlyTarget' ? (
                                            <>
                                                <p>
                                                    <strong>Use for:</strong>{' '}
                                                    assets that have a narrow
                                                    range of cycle times or when
                                                    high precision of speed loss
                                                    is not important.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Requirements:
                                                    </strong>
                                                    <ul>
                                                        <li>
                                                            A fixed hourly
                                                            target (set in the
                                                            "Production
                                                            Settings" above)
                                                        </li>
                                                        <li>
                                                            A data flow
                                                            containing
                                                            input/output for
                                                            "Static Hourly
                                                            Target" {'=>'}{' '}
                                                            "Hourly Target"
                                                            (configured by
                                                            default)
                                                        </li>
                                                    </ul>
                                                </p>
                                            </>
                                        ) : null}
                                        {this.state.speedLossType &&
                                        this.state.speedLossType.value ===
                                            'scheduledHourlyTarget' ? (
                                            <>
                                                <p>
                                                    <strong>Use for:</strong>{' '}
                                                    assets that have their
                                                    production schedule along
                                                    with product based hourly
                                                    targets fed to the Accumine
                                                    Cloud.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Requirements:
                                                    </strong>
                                                    <ul>
                                                        <li>
                                                            Integration between
                                                            your scheduling
                                                            system and Accumine
                                                        </li>
                                                        <li>
                                                            A data flow
                                                            containing an output
                                                            called "Scheduled
                                                            Target"
                                                        </li>
                                                    </ul>
                                                </p>
                                            </>
                                        ) : null}
                                        {this.state.speedLossType &&
                                        this.state.speedLossType.value ===
                                            'microstoppageTime' ? (
                                            <>
                                                <p>
                                                    <strong>Use for:</strong> to
                                                    avoid setting hourly targets
                                                    and instead use
                                                    microstoppage time between
                                                    cycles to calculate speed
                                                    loss.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Requirements:
                                                    </strong>
                                                    <ul>
                                                        <li>
                                                            A data flow
                                                            containing an output
                                                            called "Time Based
                                                            Speed Loss"
                                                        </li>
                                                        <li>
                                                            Consideration of
                                                            what the
                                                            microstoppage versus
                                                            downtime threshold
                                                            should be in the
                                                            relevant data flows.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </>
                                        ) : null}
                                        {this.state.speedLossType &&
                                        this.state.speedLossType.value ===
                                            'rolling30DayHourlyTarget' ? (
                                            <>
                                                <p>
                                                    <strong>Use for:</strong> to
                                                    avoid setting hourly targets
                                                    and microstoppage thresholds
                                                    and instead use the previous
                                                    30 day average cycle time
                                                    for calculating speed loss.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Requirements:
                                                    </strong>
                                                    <ul>
                                                        <li>
                                                            A data flow
                                                            containing an output
                                                            called "Total Cycle
                                                            Time"
                                                        </li>
                                                    </ul>
                                                </p>
                                            </>
                                        ) : null}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>
                                        <strong>
                                            ERP Integration Settings
                                        </strong>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <label>ERP Asset Name:</label>
                                    <Input
                                        type="text"
                                        value={this.state.erpAssetName}
                                        onChange={(e) =>
                                            this.setState({
                                                erpAssetName: e.target.value,
                                            })
                                        }
                                    />
                                    <Alert color="primary">
                                        Entering the ERP workcenter # associated
                                        with this asset provides the ability to
                                        use ERP naming instead of the name
                                        provided across all Accumine modules.
                                    </Alert>
                                </Col>
                                <Col xs="6">
                                    <label>ERP Asset Description:</label>
                                    <Input
                                        type="textarea"
                                        value={this.state.erpAssetDescription}
                                        onChange={(e) =>
                                            this.setState({
                                                erpAssetDescription:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                    <Alert color="primary">
                                        There is no direct use by Accumine Cloud
                                        for this, it is purely informational.
                                    </Alert>
                                </Col>
                            </Row>
                            {!this.props.editingAsset ? (
                                <>
                                    <Row>
                                        <Col>
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>
                                                <strong>
                                                    New Asset Options
                                                </strong>
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <Switch
                                                    checked={
                                                        this.state
                                                            .autoAddToShifts
                                                    }
                                                    onChange={(
                                                        autoAddToShifts
                                                    ) =>
                                                        this.setState({
                                                            autoAddToShifts,
                                                        })
                                                    }
                                                />{' '}
                                                Add asset to all scheduled
                                                shifts{' '}
                                                <strong>
                                                    (by default, asset does not
                                                    belong to any shifts)
                                                </strong>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <Switch
                                                    checked={
                                                        this.state
                                                            .autoAddToReasonCodes
                                                    }
                                                    onChange={(
                                                        autoAddToReasonCodes
                                                    ) =>
                                                        this.setState({
                                                            autoAddToReasonCodes,
                                                        })
                                                    }
                                                />{' '}
                                                Add asset to all downtime reason
                                                codes{' '}
                                                <strong>
                                                    (by default, asset is not
                                                    associated with any reason
                                                    codes)
                                                </strong>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <Switch
                                                    checked={
                                                        this.state
                                                            .autoAddOneToOneFlow
                                                    }
                                                    onChange={(
                                                        autoAddOneToOneFlow
                                                    ) =>
                                                        this.setState({
                                                            autoAddOneToOneFlow,
                                                        })
                                                    }
                                                />{' '}
                                                Automatically initialize "One to
                                                One" data flow{' '}
                                                <strong>
                                                    (required if you set an
                                                    hourly target or plan to
                                                    enter in reason codes)
                                                </strong>
                                            </Label>
                                        </Col>
                                    </Row>
                                </>
                            ) : null}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={toggleModal}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={this.save}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
