import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import {
    Row,
    Col,
    Table,
    Button,
    Card,
    CardBody,
    UncontrolledTooltip,
} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'
import {functions} from '@accumine/mathjs-extensions'

import Diagram from './diagram'
import * as API from 'SDK/api'
import {getStatus} from './StatusOptions'
import {cloneDeep} from 'lodash-es'
import modelToDot from '../helpers/modelToDot'

export default class FlowBuilder extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'FlowBuilder'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    static propTypes = {}

    static defaultProps = {}

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            loading: false,
            ready: true,
            dataModels: [],
            fieldTypes: [],
            flows: [],
            editingFlow: null,
            flowModel: null,

            status: {
                server: null,
                flows: {},
            },
        }
    }

    async addNewFlow() {
        const newFlowInstances = this.state.flows
            .map((flow) => flow.name)
            .filter((flow) => flow.includes('New Flow'))
        let flowName = 'New Flow 1'

        if (newFlowInstances.length > 0) {
            newFlowInstances.sort().reverse()
            const lastInstance = parseInt(
                newFlowInstances[0].split('New Flow ')[1]
            )
            flowName = 'New Flow ' + parseInt(lastInstance + 1)
        }

        const flow = await API.post(
            `data-models/${this.state.flowModel._id}/add-record`,
            {
                name: flowName,
                description: '',
                id: uuidv4(),
                nodes: [],
                variables: [],
                edges: [],
                settings: {
                    enableInputs: false,
                    enableOutputs: false,
                    pauseFlow: false,
                },
                syncedToFlowServer: false,
            },
            2
        )

        this.setState({
            editingFlow: flow,
        })
    }

    async sync() {
        let flows = await API.post(
            `data-models/${this.state.flowModel._id}/paginate`,
            {
                sort: {},
                filter: [],
                limit: 1000,
            },
            2
        )

        flows = flows.result.results.map((flow) => {
            flow.elements = (flow.nodes || []).concat(flow.edges || [])
            return flow
        })

        this.setState({
            flows,
            editingFlow: null,
        })
    }

    handleFlowChange(editingFlow) {
        for (let i in editingFlow.variables) {
            if (
                editingFlow.variables[i].dataModelId &&
                editingFlow.variables[i].type !== 'Report'
            ) {
                editingFlow.variables[i].fields = modelToDot(
                    editingFlow.variables[i].dataModelId,
                    this.state.dataModels,
                    false
                )
            }
            if (!editingFlow.variables[i].fields) {
                editingFlow.variables[i].fields = []
            }
        }
        this.setState({editingFlow})
    }

    handleFlowStatus(data, params, topic) {
        let {status} = this.state
        const id = topic.split('/')[2]

        if (id === 'server') {
            status.server = data.status
        } else {
            status.flows[id] = data.status
        }

        this.setState({status})
    }

    handleFlowSyncStatus(data, params, topic) {
        let {flows} = this.state,
            idx = flows.findIndex((a) => a._id === data._id)

        if (idx >= 0) {
            flows[idx] = data
        }
        this.setState({flows})
        if (this.state.editingFlow && this.state.editingFlow._id === data._id) {
            this.setState({
                editingFlow: data,
            })
        }
    }

    async copyFlow(flow) {
        const copyFlowInstances = this.state.flows
            .map((flow) => flow.name)
            .filter((flow) => flow.includes(`Copy of ${flow.name}`))
        let flowName = `Copy of ${flow.name} 1`

        if (copyFlowInstances.length > 0) {
            copyFlowInstances.sort().reverse()
            const lastInstance = parseInt(
                copyFlowInstances[0].split(`Copy of ${flow.name} `)[1]
            )
            flowName = `Copy of ${flow.name} ` + parseInt(lastInstance + 1)
        }

        let newFlow = cloneDeep(flow)
        newFlow.name = flowName
        delete newFlow._id
        newFlow.id = uuidv4()
        newFlow.syncedToFlowServer = false
        newFlow.settings = {
            enableInputs: false,
            enableOutputs: false,
            pauseFlow: false,
        }

        for (let variable of newFlow.variables) {
            const newId = uuidv4(),
                oldId = variable.id
            newFlow = JSON.parse(
                JSON.stringify(newFlow).replace(new RegExp(oldId, 'g'), newId)
            )
        }
        for (let node of newFlow.nodes) {
            const newId = uuidv4(),
                oldId = node.id
            newFlow = JSON.parse(
                JSON.stringify(newFlow).replace(new RegExp(oldId, 'g'), newId)
            )
        }
        for (let edge of newFlow.edges) {
            const newId = uuidv4(),
                oldId = edge.id
            newFlow = JSON.parse(
                JSON.stringify(newFlow).replace(new RegExp(oldId, 'g'), newId)
            )
        }
        const record = await API.post(
            `data-models/${this.state.flowModel._id}/add-record`,
            newFlow,
            2
        )

        this.setState({
            editingFlow: record,
        })
    }

    async componentDidMount() {
        this.height = document.documentElement.offsetHeight * 0.75
        this.db = JSON.parse(localStorage['userObject']).factoryId

        const dataModels = await API.get('data-models', 2),
            flowModel = (dataModels || []).find(
                (model) => model.name === 'System/Post Processor/Flows'
            )

        if (!flowModel) {
            return alert(
                'Data model "System/Post Processor/Flows" must be created first.'
            )
        }

        this.setState({
            fieldTypes: await API.get('data-models/field-types', 2),
            dataModels,
            flowModel,
            formulas: Object.keys(functions),
        })
        this.sync()

        this.props.mqttClient.on(
            `${this.db}/${flowModel._id}/update`,
            this.handleFlowSyncStatus
        )
        this.props.mqttClient.on(
            `${this.db}/cloud/+/status`,
            this.handleFlowStatus
        )
    }

    async componentWillUnmount() {
        this.props.mqttClient.router.removeListener(
            `${this.db}/cloud/+/status`,
            this.handleFlowStatus
        )

        if (this.state.editingFlow) {
            try {
                await this.props.mqttClient.publishAction(
                    `${this.db}/cloud/${this.state.editingFlow.id}/subscribe`,
                    {data: false}
                )
                this.props.mqttClient.router.removeListener(
                    `${this.db}/cloud/${this.state.editingFlow.id}/+/state`,
                    this.handleNodeState
                )
                this.props.mqttClient.router.removeListener(
                    `${this.db}/cloud/${this.state.editingFlow.id}/+/status`,
                    this.handleNodeStatus
                )
            } catch (error) {
                console.log(error)
            }
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.editingFlow ? (
                    <Row style={{height: this.height}}>
                        <Col>
                            <Diagram
                                dataModels={this.state.dataModels}
                                fieldTypes={this.state.fieldTypes}
                                formulas={this.state.formulas}
                                flow={this.state.editingFlow}
                                flows={this.state.flows}
                                backToTable={this.sync}
                                mqtt={this.props.mqttClient}
                                status={this.state.status}
                                data={this.state.data}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row style={{height: this.height}}>
                        <Col xs="12">
                            <>
                                <Row className="mb-4">
                                    <Col className="text-right">
                                        <Button
                                            onClick={this.addNewFlow}
                                            size="xs">
                                            Add Flow
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Flow Name</th>
                                                            <th>Description</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.flows.map(
                                                            (f) => {
                                                                let flowStatus =
                                                                    this.state
                                                                        .status
                                                                        .flows[
                                                                        f.id
                                                                    ]
                                                                if (
                                                                    this.state
                                                                        .status
                                                                        .server ===
                                                                    'offline'
                                                                )
                                                                    flowStatus =
                                                                        'offline'

                                                                return (
                                                                    <tr
                                                                        key={
                                                                            f.id
                                                                        }
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() =>
                                                                            this.handleFlowChange(
                                                                                f
                                                                            )
                                                                        }>
                                                                        <th>
                                                                            <>
                                                                                <span
                                                                                    id={`status_${f._id}`}
                                                                                    style={{
                                                                                        height: 10,
                                                                                        width: 10,
                                                                                        background:
                                                                                            getStatus(
                                                                                                flowStatus
                                                                                            )
                                                                                                .hexColor,
                                                                                        borderRadius:
                                                                                            '50%',
                                                                                        display:
                                                                                            'inline-block',
                                                                                        marginRight: 5,
                                                                                    }}
                                                                                />
                                                                                <UncontrolledTooltip
                                                                                    target={`status_${f._id}`}>
                                                                                    <p className="m-0">
                                                                                        {
                                                                                            getStatus(
                                                                                                flowStatus
                                                                                            )
                                                                                                .flowDocumentation
                                                                                        }
                                                                                    </p>
                                                                                </UncontrolledTooltip>
                                                                            </>
                                                                            {!f.syncedToFlowServer ? (
                                                                                <>
                                                                                    <i
                                                                                        id={`sync_${f._id}`}
                                                                                        className="simple-icon-exclamation"
                                                                                        style={{
                                                                                            color: '#b69329',
                                                                                        }}
                                                                                    />
                                                                                    <UncontrolledTooltip
                                                                                        target={`sync_${f._id}`}>
                                                                                        <p className="m-0">
                                                                                            There
                                                                                            is
                                                                                            a
                                                                                            new
                                                                                            version
                                                                                            of
                                                                                            this
                                                                                            flow
                                                                                            that
                                                                                            has
                                                                                            not
                                                                                            yet
                                                                                            been
                                                                                            accepted
                                                                                            by
                                                                                            the
                                                                                            Flow
                                                                                            Engine.
                                                                                            This
                                                                                            can
                                                                                            take
                                                                                            several
                                                                                            minutes,
                                                                                            no
                                                                                            action
                                                                                            is
                                                                                            needed
                                                                                            on
                                                                                            your
                                                                                            behalf.
                                                                                        </p>
                                                                                    </UncontrolledTooltip>
                                                                                </>
                                                                            ) : null}{' '}
                                                                            {
                                                                                f.name
                                                                            }
                                                                        </th>
                                                                        <td>
                                                                            {
                                                                                f.description
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                size="xs"
                                                                                color="default"
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation()
                                                                                    this.copyFlow(
                                                                                        f
                                                                                    )
                                                                                }}>
                                                                                Make
                                                                                a
                                                                                copy
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        </Col>
                    </Row>
                )}
            </Fragment>
        )
    }
}
