import {Validator} from 'jsonschema'

import Flow from './node-preprocess/flow'

const templateSchema = Object.freeze({
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: {
                type: 'string',
                required: true,
            },
            oneOf: [
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$passthrough',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                    },
                    inputs: {
                        type: 'array',
                        required: true,
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$input',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'string',
                                required: true,
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: true,
                        minLength: 0,
                        maxLength: 0,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$output',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'string',
                                required: true,
                            },
                            b: {
                                type: 'string',
                                required: true,
                            },
                            c: {
                                type: 'integer',
                                required: true,
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: true,
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$or',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: true,
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$xor',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$and',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$not',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$risingEdge',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                    },
                    minLength: 1,
                    maxLength: 1,
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$fallingEdge',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$negate',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$add',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$multiply',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$absoluteValue',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$merge',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$mergeDuration',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$map',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'function',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$movingAverage',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                                not: {
                                    multipleOf: 2,
                                },
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$frontMovingAverage',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$frontRollingAverage',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$count',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$countByInterval',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$duration',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$durationByInterval',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$filter',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'function',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$filterDuplicateValues',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$filterDuplicateTimestamps',
                    },
                    params: {
                        type: 'object',
                        properties: {},
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$threshold',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'array',
                                items: {
                                    type: 'number',
                                },
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$truthy',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'function',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$addConstant',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'number',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$multiplyConstant',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'number',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$addAttribute',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 2,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$timeout',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$uniformTime',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$roundTime',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                            b: {
                                type: 'string',
                                enum: [
                                    'milliseconds',
                                    'seconds',
                                    'minutes',
                                    'hours',
                                ],
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$holdTime',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$delayTime',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$advanceTime',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
                {
                    transform: {
                        type: 'string',
                        required: true,
                        const: '$timeSeriesCluster',
                    },
                    params: {
                        type: 'object',
                        properties: {
                            a: {
                                type: 'integer',
                            },
                            b: {
                                type: 'number',
                            },
                            c: {
                                type: 'integer',
                            },
                            d: {
                                type: 'integer',
                            },
                            e: {
                                type: 'integer',
                            },
                        },
                        additionalProperties: false,
                    },
                    inputs: {
                        type: 'array',
                        required: 'true',
                        items: {
                            type: 'string',
                            required: true,
                        },
                        minLength: 1,
                        maxLength: 1,
                    },
                },
            ],
        },
    },
})

const dataSchema = Object.freeze({
    type: 'array',
    items: {
        type: 'object',
        properties: {
            name: {
                type: 'string',
            },
            timestamp: {
                type: 'number',
            },
            value: {
                type: ['string', 'number', 'boolean'],
            },
        },
    },
})

export default class BrowserFlow {
    constructor() {
        this._validator = new Validator()
        this._flow = null
    }
    addFlow(inputtedTemplate) {
        let template = inputtedTemplate.slice(0)
        const results = this._validator.validate(template, templateSchema)

        template.companyId = 'test'
        template.flowId = 'test'

        if (results.valid) {
            this._flow = new Flow(template)
            this._flow.build()
        } else {
            throw new Error(results.errors)
        }
    }
    pushData(data) {
        let results = this._validator.validate(data, dataSchema)

        if (results.valid) {
            // TODO what goes in here?
        }
    }
}
