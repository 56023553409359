import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Creatable from 'react-select/creatable'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import * as sdk from './sdk'

/*
WorkOrder: string/number
expected qty: number
Part: dropdown
Mold sheds: number
Tool: dropdown
Compound #: dropdown
mixDate: date
Batch #: number
*/

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const {editingWorkOrderRecord} = this.props

        this.state = {
            workOrderInput: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find(
                      (m) => m.name === 'workorder'
                  ).value
                : '',
            workOrderQuantityInput: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find(
                      (m) => m.name === 'quantity'
                  ).value
                : 1,
            moldShedsInput: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find(
                      (m) => m.name === 'moldsheds'
                  ).value
                : 1,
            mixDateInput: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find(
                      (m) => m.name === 'mixdate'
                  ).value
                : '',
            batchNumberInput: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find(
                      (m) => m.name === 'batch'
                  ).value
                : 1,

            selectedPart: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find((m) => m.name === 'part')
                      .value
                : '',
            selectedTool: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find((m) => m.name === 'tool')
                      .value
                : '',
            selectedCompound: editingWorkOrderRecord._id
                ? editingWorkOrderRecord.metaData.find(
                      (m) => m.name === 'compound'
                  ).value
                : '',

            uniqueParts: [],
            uniqueTools: [],
            uniqueCompounds: [],

            loaded: false,
        }
    }

    async fetchWorkOrders() {
        const workOrders = await sdk.fetchAllWorkOrders()

        // part
        let uniqueParts = [
            ...new Set(
                workOrders.map(
                    (w) => w.metaData.find((m) => m.name === 'part').value
                )
            ),
        ]
        uniqueParts = uniqueParts.map((a) => {
            return {
                label: a,
                value: a,
            }
        })

        // tool
        let uniqueTools = [
            ...new Set(
                workOrders.map(
                    (w) => w.metaData.find((m) => m.name === 'tool').value
                )
            ),
        ]
        uniqueTools = uniqueTools.map((a) => {
            return {
                label: a,
                value: a,
            }
        })

        // compound
        let uniqueCompounds = [
            ...new Set(
                workOrders.map(
                    (w) => w.metaData.find((m) => m.name === 'compound').value
                )
            ),
        ]
        uniqueCompounds = uniqueCompounds.map((a) => {
            return {
                label: a,
                value: a,
            }
        })

        this.setState({
            uniqueParts,
            uniqueTools,
            uniqueCompounds,
            loaded: true,
        })
    }

    componentDidMount() {
        this.fetchWorkOrders()
    }

    async submit() {
        let timestamp = moment().toISOString()

        if (this.props.editingWorkOrderRecord._id) {
            let workOrderRecord = {...this.props.editingWorkOrderRecord}

            workOrderRecord.value = this.state.workOrderInput

            workOrderRecord.metaData = [
                {
                    name: 'workorder',
                    value: this.state.workOrderInput,
                },
                {
                    name: 'quantity',
                    value: this.state.workOrderQuantityInput,
                },
                {
                    name: 'part',
                    value: this.state.selectedPart,
                },
                {
                    name: 'moldsheds',
                    value: this.state.moldShedsInput,
                },
                {
                    name: 'tool',
                    value: this.state.selectedTool,
                },
                {
                    name: 'compound',
                    value: this.state.selectedCompound,
                },
                {
                    name: 'mixdate',
                    value: moment(this.state.mixDateInput).toISOString(),
                },
                {
                    name: 'batch',
                    value: this.state.batchNumberInput,
                },
            ]
            await API.patch('states/' + workOrderRecord._id, workOrderRecord, 2)
        } else {
            let activeWorkOrder = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Work Order',
                        timeEnd: null,
                    },
                },
                2
            )

            if (activeWorkOrder.length) {
                activeWorkOrder[0].timeEnd = timestamp
                await API.patch(
                    'states/' + activeWorkOrder[0]._id,
                    activeWorkOrder[0],
                    2
                )
            }

            await API.post(
                'states',
                {
                    nodeId: this.props.deviceId,
                    deviceId: this.props.deviceId,
                    name: 'Work Order',
                    timestamp: timestamp,
                    timeStart: timestamp,
                    timeEnd: null,
                    value: this.state.workOrderInput,
                    metaData: [
                        {
                            name: 'workorder',
                            value: this.state.workOrderInput,
                        },
                        {
                            name: 'quantity',
                            value: this.state.workOrderQuantityInput,
                        },
                        {
                            name: 'part',
                            value: this.state.selectedPart,
                        },
                        {
                            name: 'moldsheds',
                            value: this.state.moldShedsInput,
                        },
                        {
                            name: 'tool',
                            value: this.state.selectedTool,
                        },
                        {
                            name: 'compound',
                            value: this.state.selectedCompound,
                        },
                        {
                            name: 'mixdate',
                            value: moment(
                                this.state.mixDateInput
                            ).toISOString(),
                        },
                        {
                            name: 'batch',
                            value: this.state.batchNumberInput,
                        },
                    ],
                },
                2
            )
        }

        this.props.toggleModal()
    }

    render() {
        const invalid =
            this.state.workOrderInput === '' ||
            this.state.selectedPart === null ||
            this.state.selectedCompound === null ||
            this.state.selectedTool === null ||
            this.state.mixDateInput === ''
        return (
            <Fragment>
                {this.state.loaded ? (
                    <Modal isOpen={true} toggle={this.props.toggleModal}>
                        <ModalHeader toggle={this.props.toggleModal}>
                            {this.props.editingWorkOrderRecord._id
                                ? 'Edit Work Order'
                                : 'New Work Order'}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <label>Work Order #: </label>
                                <Input
                                    type="text"
                                    name="workorder"
                                    value={this.state.workOrderInput}
                                    onChange={(e) =>
                                        this.setState({
                                            workOrderInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Quantity: </label>
                                <Input
                                    type="number"
                                    name="qty"
                                    value={this.state.workOrderQuantityInput}
                                    min={1}
                                    onChange={(e) =>
                                        this.setState({
                                            workOrderQuantityInput:
                                                e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Part:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name=""
                                    value={
                                        this.state.selectedPart
                                            ? this.state.uniqueParts.find(
                                                  (u) =>
                                                      u.value ===
                                                      this.state.selectedPart
                                              )
                                            : null
                                    }
                                    onChange={(a) =>
                                        this.setState({selectedPart: a.value})
                                    }
                                    options={this.state.uniqueParts}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Mold Sheds:</label>
                                <Input
                                    type="number"
                                    name="mold-sheds"
                                    min={1}
                                    value={this.state.moldShedsInput}
                                    onChange={(e) =>
                                        this.setState({
                                            moldShedsInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Tool:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name=""
                                    value={
                                        this.state.selectedTool
                                            ? this.state.uniqueTools.find(
                                                  (u) =>
                                                      u.value ===
                                                      this.state.selectedTool
                                              )
                                            : null
                                    }
                                    onChange={(a) =>
                                        this.setState({selectedTool: a.value})
                                    }
                                    options={this.state.uniqueTools}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Compound #:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name=""
                                    value={
                                        this.state.selectedCompound
                                            ? this.state.uniqueCompounds.find(
                                                  (u) =>
                                                      u.value ===
                                                      this.state
                                                          .selectedCompound
                                              )
                                            : null
                                    }
                                    onChange={(a) =>
                                        this.setState({
                                            selectedCompound: a.value,
                                        })
                                    }
                                    options={this.state.uniqueCompounds}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Mix Date:</label>
                                <Input
                                    type="date"
                                    name="mix-date"
                                    value={moment(
                                        this.state.mixDateInput
                                    ).format('YYYY-MM-DD')}
                                    onChange={(e) =>
                                        this.setState({
                                            mixDateInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Batch #:</label>
                                <Input
                                    type="number"
                                    name="batch"
                                    min={1}
                                    value={this.state.batchNumberInput}
                                    onChange={(e) =>
                                        this.setState({
                                            batchNumberInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                block
                                color="primary"
                                onClick={this.submit}
                                disabled={invalid}>
                                SUBMIT
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
