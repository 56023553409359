import moment from 'moment'
import {stringToDateRange} from 'SDK/helpers'
import * as API from 'SDK/api'

export default class Historian {
    constructor({
        devices,
        name,
        daterange,
        timeStart,
        timeEnd,
        groupBy,
        showBy,
        timeUnit,
        metric,
        filter,
    }) {
        this.devices = devices
        this.name = name
        //  this.timeStart = timeStart;
        //  this.timeEnd = timeEnd;
        this.groupBy = groupBy
        this.showBy = showBy
        this.timeUnit = timeUnit
        this.metric = metric
        this.filter = filter

        if (daterange === 'custom') {
            this.timeStart = timeStart
            this.timeEnd = timeEnd
        } else {
            const range = stringToDateRange(daterange)
            this.timeStart = range[0]
            this.timeEnd = range[1]
        }

        this.data = null
    }
    async fetchData(queries) {
        const promises = queries.map((q) => API.post('historical', q, 1))
        return await Promise.all(promises)
    }
    async timeSeries() {
        let queries = []
        if (this.groupBy === 'None' || this.groupBy === 'Asset') {
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }

            for (let i in assets) {
                queries.push({
                    timeStart: moment(this.timeStart).toISOString(),
                    timeEnd: moment(this.timeEnd).toISOString(),
                    deviceId: assets[i],
                    state: this.name,
                    unit: this.timeUnit,
                })
            }
        } else if (this.groupBy === 'Shift') {
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            let shifts = []
            if (this.filter.shifts) shifts = this.filter.shifts
            else {
                shifts = await API.get('shifts')
                shifts = shifts.map((x) => x.name)
            }

            shifts = [...new Set(shifts)]
            const shiftRecords = await API.get('shifts', 2)
            for (let i in assets) {
                for (let j in shifts) {
                    if (
                        shiftRecords.find(
                            (a) =>
                                a.name === shifts[j] &&
                                a.assets.find((b) => b === assets[i])
                        )
                    ) {
                        queries.push({
                            timeStart: moment(this.timeStart).toISOString(),
                            timeEnd: moment(this.timeEnd).toISOString(),
                            deviceId: assets[i],
                            state: this.name,
                            unit: this.timeUnit,
                            shift: shifts[j],
                        })
                    }
                }
            }
        } else {
            const possibilities = this.filter[this.groupBy]
                ? this.filter[this.groupBy]
                : await API.get('datainputs/distinct/' + this.groupBy, 2)
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            for (let i in assets) {
                for (let j in possibilities) {
                    queries.push({
                        timeStart: moment(this.timeStart).toISOString(),
                        timeEnd: moment(this.timeEnd).toISOString(),
                        deviceId: assets[i],
                        state: this.name,
                        unit: this.timeUnit,
                        filter: [{name: this.groupBy, value: possibilities[j]}],
                        //metaDataFilter: [[this.groupBy, possibilities[j]]]
                    })
                }
            }
        }
        let data = await this.fetchData(queries)
        let organized = []

        for (let i in data) {
            for (let j in data[i].dates) {
                let obj = {
                    date: moment(data[i].dates[j]),
                    deviceId: data[i].query.deviceId,
                    name: data[i].query.name,
                    shift: data[i].query.shift,
                    metaDataFilter: data[i].query.filter
                        ? [
                              data[i].query.filter[0].name,
                              data[i].query.filter[0].value,
                          ]
                        : false,
                    //metaDataFilter: data[i].query.metaDataFilter ? [data[i].query.metaDataFilter[0][0], data[i].query.metaDataFilter[0][1]] : false,
                    duration: data[i].duration[j],
                    durationPercentage: data[i].durationPercentage[j],
                    count: data[i].count[j],
                    averageValue: data[i].averageValue[j],
                    averageDuration: data[i].duration[j] / data[i].count[j],
                }
                organized.push(obj)
            }
        }

        let formatted = []
        let dates = []
        for (let i in data) {
            for (let j in data[i].dates) {
                if (this.timeUnit !== 'hours')
                    dates.push(
                        moment(data[i].dates[j]).startOf('day').toISOString()
                    )
                else dates.push(moment(data[i].dates[j]).toISOString())
            }
        }
        dates = [...new Set(dates)]

        for (let i = 0, l = dates.length; i < l; i++) {
            let obj = {
                date: moment(dates[i]).toISOString(), //format('YYYY-MM-DD h:mm:ss a')
            }

            if (this.groupBy === 'None') {
                const relevant = organized.filter((x) =>
                    x.date.isSame(moment(dates[i]))
                )
                if (this.metric === 'totalDuration')
                    obj.total = Math.floor(
                        relevant.reduce((a, b) => {
                            return a + b.duration
                        }, 0)
                    )
                if (this.metric === 'totalDurationHours')
                    obj.total =
                        Math.round(
                            (relevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0) /
                                60) *
                                100
                        ) / 100
                if (this.metric === 'totalDurationPercent')
                    obj.total = Math.floor(
                        (relevant.reduce((a, b) => {
                            return a + b.durationPercentage
                        }, 0) /
                            relevant.length) *
                            100
                    )
                if (this.metric === 'averageDuration')
                    obj.total =
                        Math.round(
                            (relevant.reduce((a, b) => {
                                return a + b.averageDuration
                            }, 0) /
                                relevant.length) *
                                100
                        ) / 100
                if (this.metric === 'count')
                    obj.total = Math.floor(
                        relevant.reduce((a, b) => {
                            return a + b.count
                        }, 0)
                    )
                if (this.metric === 'average')
                    obj.total = Math.floor(
                        relevant.reduce((a, b) => {
                            return a + b.averageValue
                        }, 0) / relevant.length
                    )
            } else if (this.groupBy === 'Shift') {
                const relevant = organized.filter((x) => {
                    return x.date.isSame(moment(dates[i]), 'day')
                })
                const allShifts = [...new Set(organized.map((x) => x.shift))]

                const unique = [...new Set(relevant.map((item) => item.shift))]

                const notAccountedFor = allShifts.filter(
                    (s) => unique.indexOf(s) < 0
                )

                for (let j in unique) {
                    const shiftRelevant = relevant.filter(
                        (x) => x.shift === unique[j]
                    )
                    if (this.metric === 'totalDuration')
                        obj[unique[j]] = Math.floor(
                            shiftRelevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0)
                        )
                    if (this.metric === 'totalDurationHours')
                        obj[unique[j]] =
                            Math.round(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0) /
                                    60) *
                                    100
                            ) / 100
                    if (this.metric === 'totalDurationPercent')
                        obj[unique[j]] = Math.floor(
                            (shiftRelevant.reduce((a, b) => {
                                return a + b.durationPercentage
                            }, 0) /
                                shiftRelevant.length) *
                                100
                        )
                    if (this.metric === 'averageDuration')
                        obj[unique[j]] =
                            Math.round(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.averageDuration
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            ) / 100
                    if (this.metric === 'count')
                        obj[unique[j]] = Math.floor(
                            shiftRelevant.reduce((a, b) => {
                                return a + b.count
                            }, 0)
                        )
                    if (this.metric === 'average')
                        obj[unique[j]] = Math.floor(
                            shiftRelevant.reduce((a, b) => {
                                return a + b.averageValue
                            }, 0) / shiftRelevant.length
                        )
                }

                for (let j in notAccountedFor) {
                    obj[notAccountedFor[j]] = 0
                }
            } else if (this.groupBy === 'Asset') {
                let assets = []
                if (this.filter.assets) {
                    assets = this.filter.assets
                } else {
                    assets = await API.get('devices')
                    assets = assets.map((x) => x.deviceId)
                }
                for (let j in assets) {
                    const id = assets[j]
                    const name = this.deviceMap[id]
                    const relevant = organized.filter((x) => {
                        return (
                            x.date.isSame(moment(dates[i])) && x.deviceId === id
                        )
                    })
                    if (this.metric === 'totalDuration')
                        obj[name] = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0)
                        )
                    if (this.metric === 'totalDurationHours')
                        obj[name] =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0) /
                                    60) *
                                    100
                            ) / 100
                    if (this.metric === 'totalDurationPercent')
                        obj[name] = Math.floor(
                            (relevant.reduce((a, b) => {
                                return a + b.durationPercentage
                            }, 0) /
                                relevant.length) *
                                100
                        )
                    if (this.metric === 'averageDuration')
                        obj[name] =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.averageDuration
                                }, 0) /
                                    relevant.length) *
                                    100
                            ) / 100
                    if (this.metric === 'count')
                        obj[name] = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.count
                            }, 0)
                        )
                    if (this.metric === 'average')
                        obj[name] = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.averageValue
                            }, 0) / relevant.length
                        )
                }
            } else {
                const relevant = organized.filter((x) => {
                    return x.date.isSame(moment(dates[i]))
                })

                const unique = [
                    ...new Set(relevant.map((item) => item.metaDataFilter[1])),
                ]

                for (let j in unique) {
                    const shiftRelevant = relevant.filter(
                        (x) => x.metaDataFilter[1] === unique[j]
                    )
                    if (this.metric === 'totalDuration')
                        obj[unique[j]] = Math.floor(
                            shiftRelevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0)
                        )
                    if (this.metric === 'totalDurationHours')
                        obj[unique[j]] =
                            Math.round(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0) /
                                    60) *
                                    100
                            ) / 100
                    if (this.metric === 'totalDurationPercent')
                        obj[unique[j]] = Math.floor(
                            (shiftRelevant.reduce((a, b) => {
                                return a + b.durationPercentage
                            }, 0) /
                                shiftRelevant.length) *
                                100
                        )
                    if (this.metric === 'averageDuration')
                        obj[unique[j]] =
                            Math.round(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.averageDuration
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            ) / 100
                    if (this.metric === 'count')
                        obj[unique[j]] = Math.floor(
                            shiftRelevant.reduce((a, b) => {
                                return a + b.count
                            }, 0)
                        )
                    if (this.metric === 'average')
                        obj[unique[j]] = Math.floor(
                            shiftRelevant.reduce((a, b) => {
                                return a + b.averageValue
                            }, 0) / shiftRelevant.length
                        )
                }
            }
            formatted.push(obj)
        }

        this.data = formatted

        this.columns = []
        for (let i in formatted) {
            this.columns = this.columns.concat(Object.keys(formatted[i]))
        }
        this.columns = [...new Set(this.columns)]
        this.columns = this.columns.map((x) => {
            return {
                accessor: x,
                Header: x,
            }
        })
    }
    async attributes() {
        let queries = []

        const showByMeta = this.showBy !== 'Asset' && this.showBy !== 'Shift',
            groupByMeta =
                this.groupBy !== 'Asset' &&
                this.groupBy !== 'Shift' &&
                this.groupBy !== 'None'

        if (this.showBy === 'Asset' && this.groupBy === 'None') {
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            for (let i in assets) {
                queries.push({
                    timeStart: moment(this.timeStart).toISOString(),
                    timeEnd: moment(this.timeEnd).toISOString(),
                    deviceId: assets[i],
                    state: this.name,
                    unit: 'years',
                })
            }
        } else if (
            (this.showBy === 'Shift' || this.groupBy === 'Shift') &&
            !showByMeta &&
            !groupByMeta
        ) {
            // need shift data
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            let shifts = []
            if (this.filter.shifts) shifts = this.filter.shifts
            else {
                shifts = await API.get('shifts')
                shifts = shifts.map((x) => x.name)
            }
            shifts = [...new Set(shifts)]
            const shiftRecords = await API.get('shifts', 2)
            for (let i in assets) {
                for (let j in shifts) {
                    if (
                        shiftRecords.find(
                            (a) =>
                                a.name === shifts[j] &&
                                a.assets.find((b) => b === assets[i])
                        )
                    ) {
                        queries.push({
                            timeStart: moment(this.timeStart).toISOString(),
                            timeEnd: moment(this.timeEnd).toISOString(),
                            deviceId: assets[i],
                            state: this.name,
                            unit: 'years',
                            shift: shifts[j],
                        })
                    }
                }
            }
        } else if (
            this.showBy !== 'Shift' &&
            this.groupBy !== 'Shift' &&
            groupByMeta &&
            !showByMeta
        ) {
            // groupByMeta
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            const possibilities = this.filter[this.groupBy]
                ? this.filter[this.groupBy]
                : await API.get('datainputs/distinct/' + this.groupBy, 2)
            for (let i in assets) {
                for (let j in possibilities) {
                    queries.push({
                        timeStart: moment(this.timeStart).toISOString(),
                        timeEnd: moment(this.timeEnd).toISOString(),
                        deviceId: assets[i],
                        state: this.name,
                        unit: 'years',
                        filter: [{name: this.groupBy, value: possibilities[j]}],
                    })
                }
            }
        } else if (showByMeta && this.groupBy === 'None') {
            // show by meta
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            const possibilities = this.filter[this.showBy]
                ? this.filter[this.showBy]
                : await API.get('datainputs/distinct/' + this.showBy, 2)
            for (let i in assets) {
                for (let j in possibilities) {
                    queries.push({
                        timeStart: moment(this.timeStart).toISOString(),
                        timeEnd: moment(this.timeEnd).toISOString(),
                        deviceId: assets[i],
                        state: this.name,
                        unit: 'years',
                        filter: [{name: this.showBy, value: possibilities[j]}],
                    })
                }
            }
        } else if (showByMeta && groupByMeta) {
            // show and group by meta
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            const showByPossibilities = this.filter[this.showBy]
                ? this.filter[this.showBy]
                : await API.get('datainputs/distinct/' + this.showBy, 2)
            const groupByPossibilities = this.filter[this.groupBy]
                ? this.filter[this.groupBy]
                : await API.get('datainputs/distinct/' + this.groupBy, 2)

            let possibilities = []

            for (let i in showByPossibilities) {
                for (let j in groupByPossibilities) {
                    possibilities.push([
                        {name: this.showBy, value: showByPossibilities[i]},
                        {name: this.groupBy, value: groupByPossibilities[j]},
                    ])
                }
            }

            for (let i in assets) {
                for (let j in possibilities) {
                    queries.push({
                        timeStart: moment(this.timeStart).toISOString(),
                        timeEnd: moment(this.timeEnd).toISOString(),
                        deviceId: assets[i],
                        state: this.name,
                        unit: 'years',
                        filter: possibilities[j],
                    })
                }
            }
        } else {
            // need shift and metadata
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            let shifts = []
            if (this.filter.shifts) shifts = this.filter.shifts
            else {
                shifts = await API.get('shifts')
                shifts = shifts.map((x) => x.name)
            }
            let attrName = showByMeta ? this.showBy : this.groupBy
            const possibilities = this.filter[attrName]
                ? this.filter[attrName]
                : await API.get('datainputs/distinct/' + attrName, 2)
            for (let i in assets) {
                for (let j in shifts) {
                    for (let k in possibilities) {
                        queries.push({
                            timeStart: moment(this.timeStart).toISOString(),
                            timeEnd: moment(this.timeEnd).toISOString(),
                            deviceId: assets[i],
                            state: this.name,
                            unit: 'years',
                            shift: shifts[j],
                            filter: [{name: attrName, value: possibilities[k]}],
                        })
                    }
                }
            }
        }

        let data = await this.fetchData(queries)
        let organized = []

        for (let i in data) {
            let metaDataFilter = data[i].query.filter
            if (metaDataFilter && metaDataFilter.length === 1) {
                metaDataFilter = [
                    data[i].query.filter[0].name,
                    data[i].query.filter[0].value,
                ]
            }
            let obj = {
                deviceId: data[i].query.deviceId,
                name: data[i].query.name,
                shift: data[i].query.shift,
                metaDataFilter: metaDataFilter,
                duration: data[i].duration[0],
                durationPercentage: data[i].durationPercentage[0],
                count: data[i].count[0],
                averageValue: data[i].averageValue[0],
                averageDuration: data[i].duration[0] / data[i].count[0],
            }
            organized.push(obj)
        }
        console.log('alldata ' + organized.length)
        let formatted = []

        if (this.showBy === 'Asset') {
            let assets = []
            if (this.filter.assets) {
                assets = this.filter.assets
            } else {
                assets = await API.get('devices')
                assets = assets.map((x) => x.deviceId)
            }
            for (let i in assets) {
                let obj = {
                    _key: assets[i],
                    key: this.deviceMap[assets[i]],
                }
                if (this.groupBy === 'None') {
                    const relevant = organized.filter(
                        (x) => x.deviceId === obj._key
                    )
                    if (this.metric === 'totalDuration')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0)
                        )
                    if (this.metric === 'totalDurationHours')
                        obj.value =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0) /
                                    60) *
                                    100
                            ) / 100
                    if (this.metric === 'totalDurationPercent')
                        obj.value = Math.floor(
                            (relevant.reduce((a, b) => {
                                return a + b.durationPercentage
                            }, 0) /
                                relevant.length) *
                                100
                        )
                    if (this.metric === 'averageDuration')
                        obj.value =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.averageDuration
                                }, 0) /
                                    relevant.length) *
                                    100
                            ) / 100
                    if (this.metric === 'count')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.count
                            }, 0)
                        )
                    if (this.metric === 'average')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.averageValue
                            }, 0) / relevant.length
                        )
                } else if (this.groupBy === 'Shift') {
                    const unique = [
                        ...new Set(organized.map((item) => item.shift)),
                    ]
                    const relevant = organized.filter(
                        (x) => x.deviceId === obj._key
                    )
                    //console.log(unique)

                    for (let j in unique) {
                        const shiftRelevant = relevant.filter(
                            (x) => x.shift === unique[j]
                        )
                        if (this.metric === 'totalDuration')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[unique[j]] = Math.floor(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        shiftRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / shiftRelevant.length
                            )
                    }
                } else {
                    const relevant = organized.filter(
                        (x) => x.deviceId === obj._key
                    )
                    const unique = [
                        ...new Set(
                            relevant.map((item) => item.metaDataFilter[1])
                        ),
                    ]

                    for (let j in unique) {
                        const shiftRelevant = relevant.filter(
                            (x) => x.metaDataFilter[1] === unique[j]
                        )
                        if (this.metric === 'totalDuration')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[unique[j]] = Math.floor(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        shiftRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / shiftRelevant.length
                            )
                    }
                }
                formatted.push(obj)
            }
            for (let i in formatted) delete formatted[i]._key
        } else if (this.showBy === 'Shift') {
            const shifts = [...new Set(organized.map((item) => item.shift))]
            for (let i in shifts) {
                let obj = {
                    key: shifts[i],
                }
                if (this.groupBy === 'None') {
                    const relevant = organized.filter(
                        (x) => x.shift === obj.key
                    )
                    if (this.metric === 'totalDuration')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0)
                        )
                    if (this.metric === 'totalDurationHours')
                        obj.value =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0) /
                                    60) *
                                    100
                            ) / 100
                    if (this.metric === 'totalDurationPercent')
                        obj.value = Math.floor(
                            (relevant.reduce((a, b) => {
                                return a + b.durationPercentage
                            }, 0) /
                                relevant.length) *
                                100
                        )
                    if (this.metric === 'averageDuration')
                        obj.value =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.averageDuration
                                }, 0) /
                                    relevant.length) *
                                    100
                            ) / 100
                    if (this.metric === 'count')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.count
                            }, 0)
                        )
                    if (this.metric === 'average')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.averageValue
                            }, 0) / relevant.length
                        )
                } else if (this.groupBy === 'Asset') {
                    const relevant = organized.filter(
                        (x) => x.shift === obj.key
                    )
                    let assets = []
                    if (this.filter.assets) {
                        assets = this.filter.assets
                    } else {
                        assets = await API.get('devices')
                        assets = assets.map((x) => x.deviceId)
                    }
                    for (let j in assets) {
                        const id = assets[j]
                        const name = this.deviceMap[assets[j]]
                        const deviceRelevant = relevant.filter((x) => {
                            return x.deviceId === id
                        })
                        if (this.metric === 'totalDuration')
                            obj[name] = Math.floor(
                                deviceRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[name] =
                                Math.round(
                                    (deviceRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[name] = Math.floor(
                                (deviceRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    deviceRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[name] =
                                Math.round(
                                    (deviceRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        deviceRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[name] = Math.floor(
                                deviceRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[name] = Math.floor(
                                deviceRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / deviceRelevant.length
                            )
                    }
                } else {
                    const relevant = organized.filter(
                        (x) => x.shift === obj.key
                    )
                    const unique = [
                        ...new Set(
                            relevant.map((item) => item.metaDataFilter[1])
                        ),
                    ]

                    for (let j in unique) {
                        const shiftRelevant = relevant.filter(
                            (x) => x.metaDataFilter[1] === unique[j]
                        )
                        if (this.metric === 'totalDuration')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[unique[j]] = Math.floor(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        shiftRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / shiftRelevant.length
                            )
                    }
                }
                formatted.push(obj)
            }
        } else {
            let possibilities = []
            if (showByMeta && groupByMeta) {
                possibilities = [
                    ...new Set(
                        organized.map((item) => item.metaDataFilter[0][1])
                    ),
                ]
            } else {
                possibilities = [
                    ...new Set(organized.map((item) => item.metaDataFilter[1])),
                ]
            }

            for (let i in possibilities) {
                let obj = {
                    key: possibilities[i],
                }

                if (this.groupBy === 'None') {
                    const relevant = organized.filter(
                        (x) => x.metaDataFilter[1] === obj.key
                    )
                    if (this.metric === 'totalDuration')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.duration
                            }, 0)
                        )
                    if (this.metric === 'totalDurationHours')
                        obj.value =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0) /
                                    60) *
                                    100
                            ) / 100
                    if (this.metric === 'totalDurationPercent')
                        obj.value = Math.floor(
                            (relevant.reduce((a, b) => {
                                return a + b.durationPercentage
                            }, 0) /
                                relevant.length) *
                                100
                        )
                    if (this.metric === 'averageDuration')
                        obj.value =
                            Math.round(
                                (relevant.reduce((a, b) => {
                                    return a + b.averageDuration
                                }, 0) /
                                    relevant.length) *
                                    100
                            ) / 100
                    if (this.metric === 'count')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.count
                            }, 0)
                        )
                    if (this.metric === 'average')
                        obj.value = Math.floor(
                            relevant.reduce((a, b) => {
                                return a + b.averageValue
                            }, 0) / relevant.length
                        )
                } else if (this.groupBy === 'Asset') {
                    const relevant = organized.filter(
                        (x) => x.metaDataFilter[1] === obj.key
                    )
                    let assets = []
                    if (this.filter.assets) {
                        assets = this.filter.assets
                    } else {
                        assets = await API.get('devices')
                        assets = assets.map((x) => x.deviceId)
                    }
                    for (let j in assets) {
                        const id = assets[j]
                        const name = this.deviceMap[assets[j]]
                        const deviceRelevant = relevant.filter((x) => {
                            return x.deviceId === id
                        })
                        if (this.metric === 'totalDuration')
                            obj[name] = Math.floor(
                                deviceRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[name] =
                                Math.round(
                                    (deviceRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[name] = Math.floor(
                                (deviceRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    deviceRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[name] =
                                Math.round(
                                    (deviceRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        deviceRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[name] = Math.floor(
                                deviceRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[name] = Math.floor(
                                deviceRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / deviceRelevant.length
                            )
                    }
                } else if (this.groupBy === 'Shift') {
                    const relevant = organized.filter(
                        (x) => x.metaDataFilter[1] === obj.key
                    )
                    const unique = [
                        ...new Set(organized.map((item) => item.shift)),
                    ]
                    //console.log(unique)

                    for (let j in unique) {
                        const shiftRelevant = relevant.filter(
                            (x) => x.shift === unique[j]
                        )
                        if (this.metric === 'totalDuration')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[unique[j]] = Math.floor(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        shiftRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / shiftRelevant.length
                            )
                    }
                } else {
                    const relevant = organized.filter(
                        (x) => x.metaDataFilter[0][1] === obj.key
                    )
                    const unique = [
                        ...new Set(
                            relevant.map((item) => item.metaDataFilter[1][1])
                        ),
                    ]

                    for (let j in unique) {
                        const shiftRelevant = relevant.filter(
                            (x) => x.metaDataFilter[1][1] === unique[j]
                        )
                        if (this.metric === 'totalDuration')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.duration
                                }, 0)
                            )
                        if (this.metric === 'totalDurationHours')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.duration
                                    }, 0) /
                                        60) *
                                        100
                                ) / 100
                        if (this.metric === 'totalDurationPercent')
                            obj[unique[j]] = Math.floor(
                                (shiftRelevant.reduce((a, b) => {
                                    return a + b.durationPercentage
                                }, 0) /
                                    shiftRelevant.length) *
                                    100
                            )
                        if (this.metric === 'averageDuration')
                            obj[unique[j]] =
                                Math.round(
                                    (shiftRelevant.reduce((a, b) => {
                                        return a + b.averageDuration
                                    }, 0) /
                                        shiftRelevant.length) *
                                        100
                                ) / 100
                        if (this.metric === 'count')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.count
                                }, 0)
                            )
                        if (this.metric === 'average')
                            obj[unique[j]] = Math.floor(
                                shiftRelevant.reduce((a, b) => {
                                    return a + b.averageValue
                                }, 0) / shiftRelevant.length
                            )
                    }
                }
                formatted.push(obj)
            }
        }

        this.data = formatted
        this.columns = Object.keys(formatted[0])
        this.columns = this.columns.map((x) => {
            return {
                accessor: x,
                Header: x,
            }
        })
    }
    async deviceNameMap() {
        const devices = await API.get('devices')
        let data = {}
        for (let i in devices) {
            data[devices[i].deviceId] = devices[i].name
        }
        return data
    }

    async run() {
        this.deviceMap = await this.deviceNameMap()

        if (this.showBy === 'Time') {
            await this.timeSeries()
        } else await this.attributes()

        return {
            data: this.data,
            columns: this.columns,
        }
    }
}
