import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'

import PropTypes from 'prop-types'

import RealTimeHourlyActualTile from './RealTimeHourlyActualTile'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import * as CommonSDK from 'SDK/api/common'

import Moment from 'moment'
import {extendMoment} from 'moment-range'
const moment = extendMoment(Moment)

export default class RealTimeHourlyActual extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    requiredOutputs = [
        'Part Count',
        'In-Cycle',
        'Downtime',
        'Static Hourly Target',
    ]
    showBorder = false
    id = 'RealTimeHourlyActual'
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
        range: PropTypes.object, // use moment.range
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            live: {
                name: '...',
                timestamp: null,
                color: '',
                elapsed: '0:00',
            },
            actual: 0,
            target: 0,
            partNumber: 'No Scheduled Production',
            left: 0,
            progress: 0,
            hoursRemaining: '',
            activeBreak: false,
            dataThisHour: [],
            dataPastHours: [],
            range: moment.range(
                moment().hour(8).startOf('hour'),
                moment().hour(16).startOf('hour')
            ),
            activeState: {
                color: 'rgb(35, 34, 35)',
                name: 'No data',
                timestamp: null,
                elapsed: '0:00',
            },
            activeShift: {
                name: 'Today',
                timeStart: moment().startOf('day'),
                timeEnd: moment(),
            },
        }
    }

    async componentWillMount() {
        await this.fetchDeviceName()
        await this.fetchShiftSchedule()
        await this.getLiveStatus()
        this.subscriber.add(this.fetchShiftSchedule, 30 * 1000, 'fetchLiveData')
        this.subscriber.add(this.getLiveStatus, 30 * 1000, 'getLiveStatus')
        this.subscriber.add(this.collectHours, 10 * 1000, 'collectHours')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    async fetchDeviceName() {
        let assets = await API.get('devices')
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                name: assets.find((a) => a.deviceId === this.props.deviceId)
                    .name,
            })
        }
    }

    async fetchShiftSchedule(deviceId = this.props.deviceId) {
        const shiftSchedule = await CommonSDK.getShiftSchedule(deviceId)
        this.setState({
            shifts: CommonSDK.getShiftsByDay(moment(), shiftSchedule, false),
        })
    }

    async getLiveStatus() {
        const shiftObject = await CommonSDK.getActiveShiftObject(
            moment(),
            this.state.shifts,
            true
        )

        if (!shiftObject) return

        if (shiftObject.activeShift) {
            this.setState({
                activeShift: shiftObject.activeShift,
                range: moment.range(shiftObject.range[0], shiftObject.range[1]),
            })
        }
    }

    async fetchLiveData() {
        let live = await API.post('live', {
            deviceId: this.props.deviceId,
        })
        if (!live) {
            return alert('There was an error fetching data')
        } else if (live[this.props.deviceId].activeStates.length) {
            if (live[this.props.deviceId].activeShiftBreaks.length) {
                this.setState({
                    activeBreak:
                        live[this.props.deviceId].activeShiftBreaks[0].name,
                })
            } else {
                this.setState({activeBreak: false})
            }
            live[this.props.deviceId].activeStates = live[
                this.props.deviceId
            ].activeStates.filter(
                (state) =>
                    state.name === 'Downtime' || state.name === 'In-Cycle'
            )
            live = live[this.props.deviceId].activeStates[0]
            if (live.name === 'Downtime') {
                const downtimeReason = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Downtime Reason',
                            timeEnd: null,
                        },
                    },
                    2
                )

                if (downtimeReason.length) {
                    live.name = downtimeReason[0].value
                }
            }
            live.color = tileColor(live.name)
            live.elapsed = secondsToHHMMSS(
                moment().diff(moment(live.timestamp), 'seconds')
            )
            this.setState({live})
        }
    }

    updateElapsed() {
        if (this.state.live.timestamp === null) return
        let live = {...this.state.live}
        live.elapsed = secondsToHHMMSS(
            moment().diff(moment(live.timestamp), 'seconds')
        )
        this.setState({live})
    }

    collectHours() {
        let dataPastHours = [],
            accumTarget = 0

        for (let momentHour of this.state.range.by('hour')) {
            if (momentHour < moment().subtract(1, 'hour')) {
                dataPastHours.unshift(
                    <RealTimeHourlyActualTile
                        shift={this.state.range}
                        key={this.props.deviceId + momentHour.unix()}
                        size="micro"
                        range={moment.range(
                            momentHour,
                            moment(momentHour).add(1, 'hour')
                        )}
                        deviceId={this.props.deviceId}
                    />
                )
            } else {
                dataPastHours.unshift(
                    <RealTimeHourlyActualTile
                        shift={this.state.range}
                        key={this.props.deviceId + momentHour.unix()}
                        size="large"
                        range={moment.range(
                            momentHour,
                            moment(momentHour).add(1, 'hour')
                        )}
                        deviceId={this.props.deviceId}
                    />
                )
                break
            }
        }

        this.setState({dataPastHours})
    }

    async fetchPartNumber() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Part Number',
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                partNumber: data[0].metaData[0].value,
            })
        }
    }

    async fetchProgress() {
        const target = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Target Parts',
                    timeEnd: null,
                },
            },
            2
        )
        if (target && target.length) {
            let {data} = await API.post('historical/aggregate2', {
                timeStart: moment(target[0].timeStart).toISOString(),
                timeEnd: moment().toISOString(),
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'total',
                logic: 'count',
            })

            let actual = data.devices[this.props.deviceId]['Part Count'][0]
            if (actual.constructor === Object) actual = actual.count

            let left = target[0].metaData[0].value - actual,
                progress = parseInt((actual / left) * 100),
                timeElapsedThisHour = moment().diff(moment().startOf('hour')),
                cycleTime = timeElapsedThisHour / this.state.actual,
                hoursRemaining = (left * cycleTime) / (60 * 60 * 1000)

            if (isNaN(hoursRemaining)) {
                hoursRemaining = ''
            } else {
                hoursRemaining =
                    '(~' + Math.round((hoursRemaining / 100) * 100) + ' hours)'
            }

            this.setState({
                left,
                progress,
                hoursRemaining,
            })
        }
    }

    toggleEditTarget() {
        this.setState({
            editingTarget: !this.state.editingTarget,
        })
    }

    async saveTarget() {
        await API.post(
            'tablets/data',
            [
                {
                    nodeId: this.props.deviceId,
                    name: 'Static Hourly Target',
                    value: parseInt(this.state.target),
                    timestamp: moment().valueOf(),
                },
            ],
            2
        )
        this.toggleEditTarget()
    }

    render() {
        return (
            <div>
                <Row>{this.state.dataPastHours}</Row>
            </div>
        )
    }
}
