import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import Factory from '../Common/Twin/Factory'
import Map from '../Common/Map'
import Sidebar from './sidebar'
import DatasetModal from './DatasetModal'
import Route from './Route'
import RouteDataset from '../Common/Twin/RouteDataset'
import DensityRangeSelector from './DensityRangerSelector'
import HeatmapTimeline from './HeatmapTimeline'
import Heatmap from '../Common/Visuals/Heatmap'

export default class IPSRouting extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'IPSRouting'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        const windowHeight = document.documentElement.offsetHeight,
            windowWidth = document.documentElement.offsetWidth

        this.height =
            windowWidth > 1439 ? windowHeight - 210 : windowHeight - 180

        this.state = {
            datasetModelOpen: false,
            generationUnix: 0,
            timerange: null,
            assets: null,
            datasets: [],
            routes: [],
            totalPaths: 0,
            densityRange: [0, 1],
            activeDataset: null,
            mode: 'routes', // heatmap
            heatmapSettings: {
                mode: 'all', // days
                date: null,
            },
        }
    }

    toggleDatasetModal() {
        this.setState({datasetModalOpen: !this.state.datasetModalOpen})
    }

    onAddDatasetClick() {
        this.setState({
            editingDataset: new RouteDataset({model: this.state.modelVersion}),
        })
        this.toggleDatasetModal()
    }

    onEditDatasetClick(dataset) {
        this.setState({
            editingDataset: dataset,
        })
        this.toggleDatasetModal()
    }

    addDataset(dataset) {
        let datasets = this.state.datasets

        let existingDatasetIndex = datasets.findIndex(
            (d) => d.datasetId === dataset.datasetId
        )
        if (existingDatasetIndex >= 0) {
            existingDatasetIndex = dataset
        } else {
            datasets.push(dataset)
        }
        this.setState({datasets})
    }

    setDensity(densityRange) {
        this.setState({densityRange})
    }

    async handleDatasetClick(activeDataset) {
        if (
            this.state.activeDataset &&
            this.state.activeDataset.datasetId === activeDataset.datasetId
        ) {
            this.setState({activeDataset: null, routes: []})
        } else {
            this.setState({loading: true})
            const {routes, timelineData} = await activeDataset.fetch()
            this.setState({activeDataset, routes, timelineData, loading: false})
        }
    }

    removeDatasetFromList(datasetId) {
        this.setState({
            datasets: this.state.datasets.filter(
                (d) => d.datasetId !== datasetId
            ),
        })
    }

    changeMode(mode) {
        this.setState({mode})
    }

    setHeatmapSettings(mode, date) {
        this.setState({
            heatmapSettings: {
                mode,
                date,
            },
        })
    }

    mapObjectArray() {
        if (this.state.activeDataset) {
            if (this.state.mode === 'routes') {
                return this.state.routes
                    .filter(
                        (r) =>
                            r.density >= this.state.densityRange[0] &&
                            r.density < this.state.densityRange[1]
                    )
                    .map((r, idx) => {
                        return (
                            <Route
                                key={idx}
                                model={this.state.model}
                                densityRange={this.state.densityRange}
                                {...r}
                            />
                        )
                    })
            } else {
                const {heatmapSettings} = this.state
                return [
                    <Heatmap
                        model={this.state.model}
                        data={this.state.routes}
                        timelineData={this.state.timelineData}
                        date={
                            heatmapSettings.mode === 'days'
                                ? heatmapSettings.date
                                : null
                        }
                    />,
                ]
            }
        } else {
            return [
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '100%',
                        width: '100%',
                    }}>
                    <h2>Select dataset from left hand menu to visualize</h2>
                </div>,
            ]
        }
    }

    async componentWillMount() {
        this.factory = new Factory()
        await this.factory.init()

        const model = this.factory.fetchActiveModel(),
            devices = this.factory.fetchModelDevices(model.model.version),
            datasets = this.factory.fetchModelRouteDatasets(model.model.version)

        if (model.error || devices.error) {
            return alert('There was an error initializing this view.')
        }

        const assets = this.factory._assets.filter((a) => {
            return devices.devices.find((d) => d.deviceId === a.deviceId)
        })

        this.setState({
            model: model.model,
            devices: devices.devices,
            assets: assets,
            datasets: datasets.datasets,
            ready: true,
            modelVersion: model.model.version,
        })
    }

    render() {
        return (
            <Fragment>
                {this.state.ready ? (
                    <Container fluid={true}>
                        <Row className="mb-2">
                            <Col xs="3" style={{paddingRight: 0}}>
                                <Button
                                    size="xs"
                                    className="btn-block"
                                    color="primary"
                                    onClick={this.onAddDatasetClick}>
                                    <i className="simple-icon-plus" /> Add
                                    Dataset
                                </Button>
                            </Col>
                            {this.state.activeDataset ? (
                                <>
                                    {this.state.mode === 'routes' ? (
                                        <Col xs="7" className="text-right">
                                            <DensityRangeSelector
                                                onChange={this.setDensity}
                                            />
                                        </Col>
                                    ) : (
                                        <Col xs="7" className="text-right">
                                            <HeatmapTimeline
                                                dataset={
                                                    this.state.activeDataset
                                                }
                                                onChange={
                                                    this.setHeatmapSettings
                                                }
                                            />
                                        </Col>
                                    )}
                                    <Col xs="2" className="text-right">
                                        <ButtonGroup size="xs">
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.changeMode('routes')
                                                }}
                                                active={
                                                    this.state.mode === 'routes'
                                                }>
                                                Routes
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.changeMode('heatmap')
                                                }}
                                                active={
                                                    this.state.mode ===
                                                    'heatmap'
                                                }>
                                                Heatmap
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </>
                            ) : null}
                        </Row>
                        <Row>
                            <Col xs="3" style={{paddingRight: '0px'}}>
                                <Card style={{height: '100%'}}>
                                    <CardBody style={{padding: 0}}>
                                        <Sidebar
                                            devices={this.state.devices}
                                            assets={this.state.assets}
                                            factory={this.factory}
                                            datasets={this.state.datasets}
                                            editDataset={
                                                this.onEditDatasetClick
                                            }
                                            handleDatasetClick={
                                                this.handleDatasetClick
                                            }
                                            activeDataset={
                                                this.state.activeDataset
                                            }
                                            removeDatasetFromList={
                                                this.removeDatasetFromList
                                            }
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="9" style={{paddingLeft: '0px'}}>
                                <Card>
                                    <CardBody style={{padding: 0}}>
                                        <Map
                                            model={this.state.model}
                                            objects={this.mapObjectArray()}
                                            height={this.height}
                                            width={this.width}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <div className="loading" />
                )}
                {this.state.datasetModalOpen ? (
                    <DatasetModal
                        toggle={this.toggleDatasetModal}
                        open={this.state.datasetModalOpen}
                        assets={this.state.assets}
                        factory={this.factory}
                        addDataset={this.addDataset}
                        editingDataset={this.state.editingDataset}
                    />
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
