import React from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, Table} from 'reactstrap'

const VariablesModal = (props) => {
    return (
        <Modal size="lg" isOpen={true} toggle={props.close}>
            <ModalHeader toggle={props.close}>Variable List</ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Created By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.variables.map((variable, idx) => {
                            const element = props.elements.find(
                                (el) => el.id === variable.transformId
                            )
                            let variableType = variable.type
                            if (
                                variableType === 'Record' &&
                                variable.multi === true
                            ) {
                                variableType += 's'
                            }
                            return (
                                <tr key={idx}>
                                    <td>{variable.name}</td>
                                    <td>{variableType}</td>
                                    <td>{element.data.opts.name}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}

VariablesModal.propTypes = {
    close: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
    elements: PropTypes.array.isRequired,
}

export default VariablesModal
