import React, {Fragment, Component} from 'react'
import {Row, Col, Input, Button} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import Select from 'react-select'
import moment from 'moment'
import CustomSelectInput from 'components/CustomSelectInput'

import Previous from './Previous'
import Current from './Current'
import Between from './Between'
import Before from './Before'
import After from './After'
import On from './On'
import {cloneDeep} from 'lodash-es'

const OPTIONS = [
    {
        label: 'Previous',
        value: 'previous',
    },
    {
        label: 'Current',
        value: 'current',
    },
    {
        label: 'Between',
        value: 'between',
    },
    {
        label: 'Before',
        value: 'before',
    },
    {
        label: 'After',
        value: 'after',
    },
    {
        label: 'On',
        value: 'on',
    },
    {
        label: 'Is empty',
        value: 'isEmpty',
    },
    {
        label: 'Is not empty',
        value: 'isNotEmpty',
    },
]

export default class TimestampFilter extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = []
    showBorder = false
    id = 'QueryBuilder.TimestampFilter'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.options = cloneDeep(OPTIONS)

        if (this.props.isDesignatedTimeEnd) {
            this.options.push({
                label: 'Is active',
                value: 'isActive',
            })
            this.options.push({
                label: 'Is not active',
                value: 'isNotActive',
            })
        }

        let initial = {
            selected: this.options[0],
            input: {unit: 'day', value: 30},
        }

        if (props) {
            initial = {
                selected: this.options.find((o) => o.value === props.logic)
                    ? this.options.find((o) => o.value === props.logic)
                    : this.options[0],
                input: props.value || {unit: 'day', value: 30},
            }
        }

        this.state = {
            selected: initial.selected,
            input: initial.input,
        }
    }

    handleSelectChange(selected) {
        let input = null

        if (selected.value === 'previous') {
            input = {unit: 'day', value: 30}
        } else if (selected.value === 'current') {
            input = 'day'
        } else if (selected.value === 'between') {
            input = [
                moment().add(-1, 'days').startOf('day').valueOf(),
                moment().startOf('day').valueOf(),
            ]
        } else if (selected.value === 'before') {
            input = moment().startOf('day').valueOf()
        } else if (selected.value === 'after') {
            input = moment().add(-1, 'days').startOf('day').valueOf()
        } else if (selected.value === 'on') {
            input = moment().startOf('day').valueOf()
        } else if (
            selected.value === 'isEmpty' ||
            selected.value === 'isNotEmpty'
        ) {
            input = []
        } else if (
            selected.value === 'isActive' ||
            selected.value === 'isNotActive'
        ) {
            input = []
        }

        this.setState({selected, input}, () => {
            if (this.props.autoSubmit) {
                this.submit()
            }
        })
    }

    handleInputChange(input) {
        this.setState({input}, () => {
            if (this.props.autoSubmit) {
                this.submit()
            }
        })
    }

    makePrettyString() {
        const {label, value} = this.state.selected
        const expression = this.props.formulas && this.props.variables

        let prettyString = this.props.fieldName + ' ' + label.toLowerCase()

        if (value === 'previous') {
            if (expression) {
                prettyString +=
                    ' ' +
                    this.state.input.value.plainText +
                    ' ' +
                    this.state.input.unit +
                    's'
            } else {
                prettyString +=
                    ' ' +
                    this.state.input.value +
                    ' ' +
                    this.state.input.unit +
                    's'
            }
        } else if (value === 'current') {
            prettyString += ' ' + this.state.input
        } else if (value === 'between') {
            if (expression) {
                prettyString += ` ${this.state.input[0].plainText} and ${this.state.input[1].plainText}`
            } else {
                prettyString +=
                    ' ' +
                    moment(this.state.input[0]).format('llll') +
                    ' and ' +
                    moment(this.state.input[1]).format('llll')
            }
        } else if (value === 'before') {
            if (expression) {
                prettyString += ` ${this.state.input.plainText}`
            } else {
                prettyString += ' ' + moment(this.state.input).format('llll')
            }
        } else if (value === 'after') {
            if (expression) {
                prettyString += ` ${this.state.input.plainText}`
            } else {
                prettyString += ' ' + moment(this.state.input).format('llll')
            }
        } else if (value === 'on') {
            if (expression) {
                prettyString += ` ${this.state.input.plainText}`
            } else {
                prettyString += ' ' + moment(this.state.input).format('llll')
            }
        }

        return prettyString
    }

    submit() {
        if (this.props.onSubmit) {
            this.props.onSubmit({
                logic: this.state.selected.value,
                value: this.state.input,
                prettyString: this.makePrettyString(),
            })
        }
    }

    componentDidMount() {
        if (this.props.autoSubmit) {
            this.submit()
        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="timestamp-filter-options"
                            value={this.state.selected}
                            onChange={this.handleSelectChange}
                            options={this.options}
                        />
                    </Col>

                    <Col xs="12">
                        {this.state.selected.value === 'previous' ? (
                            <Previous
                                onChange={this.handleInputChange}
                                value={this.state.input}
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                            />
                        ) : null}

                        {this.state.selected.value === 'current' ? (
                            <Current
                                onChange={this.handleInputChange}
                                value={this.state.input}
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                            />
                        ) : null}

                        {this.state.selected.value === 'between' ? (
                            <Between
                                onChange={this.handleInputChange}
                                value={this.state.input}
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                            />
                        ) : null}

                        {this.state.selected.value === 'before' ? (
                            <Before
                                onChange={this.handleInputChange}
                                value={this.state.input}
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                            />
                        ) : null}

                        {this.state.selected.value === 'after' ? (
                            <After
                                onChange={this.handleInputChange}
                                value={this.state.input}
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                            />
                        ) : null}

                        {this.state.selected.value === 'on' ? (
                            <On
                                onChange={this.handleInputChange}
                                value={this.state.input}
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                            />
                        ) : null}
                    </Col>
                </Row>

                {!this.props.autoSubmit ? (
                    <Row>
                        <Col className="text-right">
                            <Button
                                className="mt-2"
                                color="secondary"
                                size="xs"
                                disabled={
                                    this.state.selected.showInput &&
                                    this.state.input.length === 0
                                }
                                onClick={this.submit}>
                                Update filter
                            </Button>
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
