import React, {Component, Fragment} from 'react'
import IntlMessages from 'util/IntlMessages'
import {
    Row,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Col,
    ButtonGroup,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    ButtonDropdown,
    Collapse,
} from 'reactstrap'
import moment from 'moment'
import Select from 'react-select'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'

import CustomSelectInput from 'components/CustomSelectInput'
import {Colxx, Separator} from 'components/CustomBootstrap'

import Tree from 'react-d3-tree'

import TreeMaker from './TreeMaker'
import DowntimeTreeMaker from './DowntimeTreeMaker'
import ButtonDatePicker from 'SDK/ui/ButtonDatePicker'
import NodeLabel from './NodeLabel'
import UtilizationSummary from './UtilizationSummary'
import PartCountSummary from './PartCountSummary'
import DowntimeSummary from './DowntimeSummary'
import * as API from 'SDK/api'

const METRIC_OPTIONS = [
    {
        label: 'Avg  Daily Utilization %',
        value: 'In-Cycle',
        key: 0,
    },
    {
        label: 'Avg. Daily Count',
        value: 'Part Count',
        key: 1,
    },
    {
        label: 'Top 5 Downtime Reasons',
        value: 'Downtime',
        key: 2,
    },
]

export default class DrilldownTree extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'DrilldownTree'
    requiredOutputs = ['In-Cycle', 'Part Count']
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)

        this.treemaker = new TreeMaker()
        this.downtimeTreeMaker = new DowntimeTreeMaker()

        this.updateTimerange = this.updateTimerange.bind(this)
        this.handleMetricChange = this.handleMetricChange.bind(this)

        this.state = {
            loading: true,
            treeData: [],
            viewing: null,
            metric: METRIC_OPTIONS[0],
            timerange: {
                timeStart: moment().add(-1, 'days').startOf('day'),
                timeEnd: moment().add(-1, 'days').endOf('day'),
            },
        }
    }

    updateTimerange(timerange) {
        this.setState({timerange})
    }

    componentDidUpdate(prevProps, prevState) {
        // check for timerange update
        if (
            prevState.timerange.timeStart.diff(
                this.state.timerange.timeStart
            ) !== 0 ||
            prevState.timerange.timeEnd.diff(this.state.timerange.timeEnd) !== 0
        ) {
            this.renderTree()
        }
        // check for metric change
        if (prevState.metric.value !== this.state.metric.value) {
            this.renderTree()
        }
    }

    async renderTree() {
        this.setState({
            viewing: null,
            loading: true,
        })

        let treeData = []

        if (
            this.state.metric.value === 'In-Cycle' ||
            this.state.metric.value === 'Part Count'
        ) {
            treeData = await this.treemaker.make(
                this.state.timerange,
                this.state.metric.value,
                this.state.metric.value === 'In-Cycle'
            )
        } else {
            treeData = await this.downtimeTreeMaker.make(this.state.timerange)
        }

        console.log(treeData)
        this.setState({
            treeData: treeData,
            loading: false,
        })
    }

    async componentWillMount() {
        this.renderTree()
    }

    handleMetricChange(metric) {
        this.setState({viewing: null, metric})
    }

    handleNodeClick(nodeData) {
        this.setState({viewing: nodeData})
    }
    render() {
        const height = document.documentElement.offsetHeight * 0.76 + 'px',
            treeHeight = document.documentElement.offsetHeight * 0.63 + 'px',
            horizontalTranslation = document.documentElement.offsetWidth * 0.3,
            isUtilization =
                this.state.metric.value === 'In-Cycle' && this.state.viewing,
            isCount =
                this.state.metric.value === 'Part Count' && this.state.viewing,
            isDowntime =
                this.state.metric.value === 'Downtime' && this.state.viewing
        const loaderOffset = {
            top: (document.documentElement.offsetHeight * 0.82) / 4,
            left: document.documentElement.offsetWidth * 0.28,
        }
        return (
            <Fragment>
                <Row style={{height: height}}>
                    <Col xs="12" sm="9" style={{height: height}}>
                        <Card style={{height: height}}>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="3" className="text-center">
                                        <ButtonDatePicker
                                            disabled={this.state.loading}
                                            onChangeCallback={
                                                this.updateTimerange
                                            }
                                        />
                                    </Col>
                                    <Col xs="12" sm="3" className="ml-3">
                                        <label style={{marginBottom: '0px'}}>
                                            Metric:
                                        </label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="metric"
                                            value={this.state.metric}
                                            onChange={this.handleMetricChange}
                                            isDisabled={this.state.loading}
                                            options={METRIC_OPTIONS}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{height: treeHeight}}>
                                    <Col xs="12">
                                        {!this.state.loading ? (
                                            <Tree
                                                pathFunc="elbow"
                                                orientation="vertical"
                                                depthFactor={100}
                                                transitionDuration={500}
                                                allowForeignObjects
                                                onClick={(nodeData) => {
                                                    this.setState({
                                                        viewing: nodeData,
                                                    })
                                                }}
                                                translate={{
                                                    x: horizontalTranslation,
                                                    y: 50,
                                                }}
                                                nodeLabelComponent={{
                                                    render: <NodeLabel />,
                                                    foreignObjectWrapper: {
                                                        y: 10,
                                                        x: 22,
                                                    },
                                                }}
                                                data={this.state.treeData}
                                            />
                                        ) : (
                                            <div className="loading" />
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="3" style={{height: height}}>
                        <Card style={{height: height}}>
                            <CardBody>
                                {isUtilization ? (
                                    <UtilizationSummary
                                        viewing={this.state.viewing}
                                    />
                                ) : null}
                                {isCount ? (
                                    <PartCountSummary
                                        viewing={this.state.viewing}
                                    />
                                ) : null}
                                {isDowntime ? (
                                    <DowntimeSummary
                                        viewing={this.state.viewing}
                                    />
                                ) : null}
                                {!this.state.viewing ? (
                                    <p
                                        style={{
                                            color: 'grey',
                                            textAlign: 'center',
                                        }}>
                                        Click on "All Assets", a group/cell or
                                        asset to view summary.
                                    </p>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
