import React, {Fragment, Component} from 'react'
import {Bar} from 'react-chartjs-2'
import autobind from 'auto-bind'

export default class InteractiveCharter extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.options = {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        offset: true,
                        type: 'time',
                        time: {
                            unit: 'day',
                        },
                    },
                ],
                yAxes: [{}],
            },
        }

        this.state = {}
    }

    makeDataset() {
        if (this.props.data.data.length === 0) return {labels: [], datasets: []}
        let data = {
            labels: this.props.data.dates,
            datasets: this.props.data.data,
        }

        this.options.scales.xAxes[0].time.unit =
            this.props.data.xAxis.substring(
                0,
                this.props.data.xAxis.length - 1
            ) || 'day'

        if (this.props.data.type === 'hours') {
            let max = 0

            for (let dataset of data.datasets) {
                for (let v of dataset.data) {
                    if (v > max) max = v
                }
            }
            this.options.scales.yAxes[0].ticks = {
                min: 0,
                max: Math.ceil(max),
                callback: (value, index, values) => {
                    return value + 'hrs'
                },
            }
        } else if (this.props.data.type === 'percent') {
            this.options.scales.yAxes[0].ticks = {
                min: 0,
                max: 100,
                callback: (value, index, values) => {
                    return value + '%'
                },
            }
        }

        return data
    }

    render() {
        return (
            <Bar
                data={this.makeDataset()}
                options={this.options}
                height={this.props.height}
            />
        )
    }
}
