import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    ButtonGroup,
    Row,
    Col,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            selectedCategory: null,
            categories: [],
            reasoncodes: [],
        }
    }

    async fetchCodes() {
        this.setState({loading: true})
        try {
            let reasoncodes = await API.get('reasoncodes', 2)

            reasoncodes = reasoncodes.filter((code) => {
                if (!code.assets) {
                    return true
                } else {
                    return code.assets.find((a) => a === this.props.deviceId)
                }
            })

            let categories = [...new Set(reasoncodes.map((r) => r.category))]
            categories = categories.sort((a, b) => a.localeCompare(b))

            this.setState({
                reasoncodes,
                categories,
            })
        } catch (error) {
            alert(error)
            console.log(error)
        }
        this.setState({loading: false})
    }

    async undoReason() {
        this.setState({loading: true})
        try {
            const data = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: {
                            $in: [
                                'Downtime Category',
                                'Downtime Reason',
                                'Downtime Comment',
                                'Downtime User',
                            ],
                        },
                        timeEnd: null,
                    },
                },
                2
            )

            for (let i in data) {
                API.remove('states/' + data[i]._id, 2)
            }
            setTimeout(async () => {
                try {
                    await this.props.forceRefresh()
                } catch (error) {
                    console.log(error)
                    alert(error)
                }
                this.setState({loading: false})
            }, 1000 * 7)
        } catch (error) {
            alert(error)
            console.log(error)
            this.setState({loading: false})
        }
    }

    async changeReason() {
        this.setState({loading: true})
        try {
            let data = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: {
                            $in: [
                                'Downtime',
                                'Downtime Category',
                                'Downtime Reason',
                                'Downtime Comment',
                                'Downtime User',
                            ],
                        },
                        timeEnd: null,
                    },
                },
                2
            )

            const timeEnd = moment().toDate()

            let newDowntime = {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                value: true,
                name: 'Downtime',
                timestamp: timeEnd,
                timeStart: timeEnd,
                timeEnd: null,
                metaData: [],
            }
            await API.post('states', newDowntime, 2)

            for (let i in data) {
                data[i].timeEnd = timeEnd
                await API.patch('states/' + data[i]._id, data[i], 2)
            }
            await this.props.forceRefresh()
        } catch (error) {
            alert(error)
            console.log(error)
        }
        this.setState({loading: false})
    }

    async submit(record) {
        if (!this.props.downtimeTimeStart) {
            this.props.toggleModal()
            return alert('Downtime period has ended.')
        }

        this.setState({loading: true})
        try {
            const userId = JSON.parse(localStorage['userObject'])._id
            API.post(
                'tablets/data',
                [
                    {
                        nodeId: this.props.deviceId,
                        timestamp: moment(
                            this.props.downtimeTimeStart
                        ).valueOf(),
                        value: record.reason,
                        name: 'Downtime Reason',
                        src: 'HubbellAikenHMI2/DowntimeEntryModal.js',
                    },
                    {
                        nodeId: this.props.deviceId,
                        timestamp: moment(
                            this.props.downtimeTimeStart
                        ).valueOf(),
                        value: record.category,
                        name: 'Downtime Category',
                        src: 'HubbellAikenHMI2/DowntimeEntryModal.js',
                    },
                    {
                        nodeId: this.props.deviceId,
                        timestamp: moment(
                            this.props.downtimeTimeStart
                        ).valueOf(),
                        value: '',
                        name: 'Downtime Comment',
                        src: 'HubbellAikenHMI2/DowntimeEntryModal.js',
                    },
                    {
                        nodeId: this.props.deviceId,
                        timestamp: moment(
                            this.props.downtimeTimeStart
                        ).valueOf(),
                        value: userId,
                        name: 'Downtime User',
                        src: 'HubbellAikenHMI2/DowntimeEntryModal.js',
                    },
                ],
                2
            )
            setTimeout(async () => {
                try {
                    await this.props.forceRefresh()
                } catch (error) {
                    console.log(error)
                    alert(error)
                }
                this.setState({loading: false})
            }, 1000 * 7)
        } catch (error) {
            this.setState({loading: false})
            alert(error)
            console.log(error)
        }
    }

    componentDidMount() {
        this.fetchCodes()
    }

    render() {
        return (
            <Fragment>
                <Modal size="lg" isOpen={true} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>
                        DOWNTIME ENTRY
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading ? (
                            <h2 style={{textAlign: 'center'}}>
                                Loading, please wait...
                            </h2>
                        ) : (
                            <>
                                {this.props.reasonCode ? (
                                    <>
                                        <Row>
                                            <Col xs="12">
                                                <h2
                                                    style={{
                                                        textAlign: 'center',
                                                    }}>
                                                    {this.props.reasonCode}
                                                </h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6">
                                                <Button
                                                    block
                                                    onClick={this.undoReason}>
                                                    UNDO
                                                </Button>
                                            </Col>
                                            <Col xs="6">
                                                <Button
                                                    block
                                                    onClick={this.changeReason}>
                                                    CHANGE
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        {!this.state.selectedCategory ? (
                                            <>
                                                <Row>
                                                    <Col xs="12">
                                                        <h5
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                            }}>
                                                            <strong>
                                                                SELECT CATEGORY:
                                                            </strong>
                                                        </h5>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {this.state.categories.map(
                                                        (category) => {
                                                            return (
                                                                <Col
                                                                    xs="6"
                                                                    key={
                                                                        category
                                                                    }>
                                                                    <Button
                                                                        block
                                                                        className="m-1"
                                                                        style={{
                                                                            textTransform:
                                                                                'uppercase',
                                                                        }}
                                                                        onClick={() =>
                                                                            this.setState(
                                                                                {
                                                                                    selectedCategory:
                                                                                        category,
                                                                                }
                                                                            )
                                                                        }>
                                                                        {
                                                                            category
                                                                        }
                                                                    </Button>
                                                                </Col>
                                                            )
                                                        }
                                                    )}
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Row>
                                                    <Col
                                                        xs="12"
                                                        style={{
                                                            textAlign: 'center',
                                                        }}>
                                                        <ButtonGroup>
                                                            <Button
                                                                color="default"
                                                                disabled>
                                                                <h5>
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .selectedCategory
                                                                        }
                                                                    </strong>
                                                                </h5>
                                                            </Button>
                                                            <Button
                                                                color="danger"
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            selectedCategory:
                                                                                null,
                                                                        }
                                                                    )
                                                                }>
                                                                CHANGE
                                                            </Button>
                                                        </ButtonGroup>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {this.state.reasoncodes
                                                        .filter(
                                                            (r) =>
                                                                r.category ===
                                                                this.state
                                                                    .selectedCategory
                                                        )
                                                        .map((r) => {
                                                            return (
                                                                <Col
                                                                    xs="6"
                                                                    key={r._id}>
                                                                    <Button
                                                                        block
                                                                        className="m-1"
                                                                        style={{
                                                                            textTransform:
                                                                                'uppercase',
                                                                        }}
                                                                        onClick={() =>
                                                                            this.submit(
                                                                                r
                                                                            )
                                                                        }>
                                                                        {
                                                                            r.reason
                                                                        }
                                                                    </Button>
                                                                </Col>
                                                            )
                                                        })}
                                                </Row>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
