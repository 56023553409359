import React from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import {FormGroup, Label} from 'reactstrap'
import Select from 'react-select'

import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'

class SelectInput extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            value: this.props.default,
        }
    }

    onChange({label, value}) {
        this.setState({
            value: {label, value},
        })
        this.props.onChange(value)
    }

    componentDidMount() {
        if (this.props.initial) {
            this.onChange(this.props.initial)
        }
    }

    render() {
        return (
            <FormGroup>
                <Label
                    className="form-group has-top-label"
                    style={{marginBottom: '5px'}}>
                    <Select
                        components={{Input: CustomSelectInput}}
                        className="react-select"
                        classNamePrefix="react-select"
                        name={Math.random()}
                        value={this.state.value}
                        onChange={this.onChange}
                        options={this.props.options}
                    />

                    <IntlMessages id={this.props.fieldName} />
                </Label>
            </FormGroup>
        )
    }
}

SelectInput.propTypes = {
    default: PropTypes.object,
    options: PropTypes.array,
    onChange: PropTypes.func,
    fieldName: PropTypes.string,
}

export default SelectInput
