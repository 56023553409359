import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class StatePicker extends Component {
    id = 'StatePicker'
    field = 'selectedStates'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props && this.props.existing
                ? this.props.existing.map((state) => {
                      return {label: state, value: state}
                  })
                : []

        this.state = {
            widgets: [],
            selectedStates: initialValue,
            color: 'rgb(215,215,215)',
            reasoncodes: [],
        }

        if (this.props && this.props.cb) {
            this.props.cb('selectedStates', initialValue)
        }
    }

    async getStates() {
        //const widgets = await API.get('library', 2);
        //if (!widgets) return alert('Could not fetch library.');
        let states = await API.get('states/unique', 2)

        if (states.length > 0) {
            this.setState({
                states: states.map((state) => {
                    return {value: state, label: state}
                }),
            })
        } else {
            this.setState({
                states: [
                    'Downtime',
                    'Hourly Target',
                    'In-Cycle',
                    'Operator Sign In',
                    'Part Count',
                    'Part Number',
                    'Reject',
                    'Work Order',
                ].map((state) => {
                    return {value: state, label: state}
                }),
            })
        }
    }

    async componentWillMount() {
        await this.getStates()
    }

    render() {
        return (
            <Fragment>
                <small style={{color: '#dc3545'}}>{this.state.message}</small>
                <label>Available States:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="states"
                    isMulti
                    value={this.state.selectedStates.map((s) => s.value)}
                    onChange={(selected) => {
                        this.setState({selectedStates: selected})
                        if (this.props && this.props.cb) {
                            this.props.cb('selectedStates', selected)
                        }
                    }}
                    options={this.state.states}
                />
            </Fragment>
        )
    }
}
