import React from 'react'
import {Row, Col} from 'reactstrap'
import {Handle} from 'react-flow-renderer'

export const NodeIcons = {
    ON_DEMAND_TRIGGER: 'iconsmind-User',
    SCHEDULED_TRIGGER: 'iconsmind-Calendar-4',
    RECORD_TRIGGER: 'iconsmind-Arrow-Inside',
    CREATE_VARIABLE: 'iconsmind-Add',
    DECIDE: 'iconsmind-Arrow-Junction',
    GET_REPORT: 'iconsmind-Line-Chart',
    LOOP: 'iconsmind-Arrow-Loop',
    MODIFY: 'iconsmind-File-Edit',
    PAUSE: 'iconsmind-Pause',
    GET_RECORDS: 'iconsmind-Files',
    CREATE_RECORD: 'iconsmind-Folder-Add',
    DELETE_RECORD: 'iconsmind-Folder-Delete',
    SEND_EMAIL: 'iconsmind-Email',
    SEND_FORM_DATA: 'iconsmind-File-Edit',
    UPDATE_RECORD: 'iconsmind-Folder-Edit',
}

export const NodeLogos = {}

export default ({id, data, isConnectable}) => {
    const showInput = () => {
        return data.nodeType !== 'input'
    }

    return (
        <>
            {showInput() ? (
                <Handle
                    type="target"
                    position="left"
                    isConnectable={isConnectable}
                    style={{marginRight: 25}}
                />
            ) : null}
            <div
                style={{
                    textAlign: 'center',
                }}>
                <Row className="m-0">
                    <Col xs="12">
                        {NodeIcons[data.opts.type] && (
                            <i
                                style={{fontSize: '40px'}}
                                className={NodeIcons[data.opts.type]}
                            />
                        )}
                        {NodeLogos[data.opts.type] && (
                            <>
                                {NodeLogos[data.opts.type]}
                                <hr />
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col>
                        <strong>{data.label}</strong>
                    </Col>
                </Row>
            </div>
            <Handle
                type="source"
                position="right"
                isConnectable={isConnectable}
            />

            {data.opts.type === 'LOOP' ? (
                <div>
                    <Row>
                        <Col>Start</Col>
                        <Col>End</Col>
                    </Row>
                    <Handle
                        id={`loopStart_${id}`}
                        type="source"
                        position="bottom"
                        style={{left: 30, background: '#555'}}
                        isConnectable={true}
                    />
                    <Handle
                        id={`loopEnd_${id}`}
                        type="target"
                        position="bottom"
                        style={{right: 30, left: 'auto', background: '#555'}}
                        isConnectable={true}
                    />
                </div>
            ) : null}
        </>
    )
}
