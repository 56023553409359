import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'
import Sortable from 'react-sortablejs'

// Accumine SDK imports
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            assets: [],
        }
    }
    async getAssets() {
        let allAssets = await API.get('devices'),
            assets = []

        if (
            this.props.libraryObject.filter &&
            this.props.libraryObject.filter.assets
        ) {
            assets = this.props.libraryObject.filter.assets.map((x) =>
                allAssets.find((y) => y.deviceId === x)
            )
        } else {
            assets = allAssets
        }

        this.setState({
            assets,
        })
    }

    async handleSort(order) {
        let assets = order.map((x) =>
            this.state.assets.find((y) => y.deviceId === x)
        )
        this.setState({assets})
    }

    async componentWillMount() {
        await this.getAssets()
    }

    render() {
        return (
            <Fragment>
                <Modal
                    isOpen={true}
                    toggle={() => {
                        this.props.toggleModal(this.state.assets)
                    }}>
                    <ModalHeader
                        toggle={() => {
                            this.props.toggleModal(this.state.assets)
                        }}>
                        Filter Data
                    </ModalHeader>
                    <ModalBody>
                        <Row className="mb-4">
                            <Col xxs="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="text-center">
                                            Drag & drop assets to rearrange
                                            reporting order
                                        </CardTitle>
                                        <Row>
                                            <Col xs="12" className="mb-5">
                                                {this.state.assets.length ? (
                                                    <Sortable
                                                        onChange={
                                                            this.handleSort
                                                        }>
                                                        {this.state.assets.map(
                                                            (x, i) => {
                                                                return (
                                                                    <Row
                                                                        data-id={
                                                                            x.deviceId
                                                                        }
                                                                        key={
                                                                            x.deviceId
                                                                        }>
                                                                        <Col xs="12">
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <p>
                                                                                        {
                                                                                            x.name
                                                                                        }
                                                                                    </p>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        )}
                                                    </Sortable>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.props.toggleModal(this.state.assets)
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.updating ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
