import React, {Component, Fragment} from 'react'
import {Button, Row, Col} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddSchemaModal from './add'
import UploadModal from './upload'
import RemoveModal from './remove'
import EditModal from './edit'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Group',
        accessor: 'group',
    },
]

export default class DataTables extends Component {
    deprecated = true
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'DataTables'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            schemas: [],
            editingSchema: null,
            addSchemaModal: false,
        }
    }
    async fetchSchemas() {
        this.setState({
            schemas: await API.get('schemas', 2),
        })
    }
    toggleAddSchemaModal() {
        this.setState({
            addSchemaModal: !this.state.addSchemaModal,
        })
    }
    toggleModal(modalName, schema) {
        let state = this.state
        state.editingSchema = schema
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (schema) => this.toggleModal('editModal', schema),
                actionName: 'Edit Schema',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (schema) => {
                    alert('This feature is not activated.')
                }, // this.toggleModal('uploadModal', schema),
                actionName: 'Manual Upload',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (schema) => this.toggleModal('removeModal', schema),
                actionName: 'Remove Schema',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(schema) {
        return schema
    }

    editorModalAttributes(schema) {
        return [
            ['Name', schema.name],
            ['Group', schema.group],
        ]
    }

    componentWillMount() {
        this.subscriber.add(this.fetchSchemas, 3000, 'fetchSchemas()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstSchemaEl = (
                <Button color="primary" onClick={this.toggleAddSchemaModal}>
                    Add Your First Schema
                </Button>
            ),
            tableExists = this.state.schemas.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col
                            xs="12"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Button
                                color="primary"
                                onClick={this.toggleAddSchemaModal}>
                                Add Schema
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="name"
                    emptyCrudMainText="No Schemas Found"
                    emptyCrudSubText={addFirstSchemaEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.schemas}
                    editorModalTitle="Schema Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addSchemaModal ? (
                    <AddSchemaModal
                        toggleModal={this.toggleAddSchemaModal}
                        modal={this.state.addSchemaModal}
                        schemas={this.state.schemas}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        schemas={this.state.schemas}
                        editingSchema={this.state.editingSchema}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        schemas={this.state.schemas}
                        editingSchema={this.state.editingSchema}
                    />
                ) : null}

                {this.state.uploadModal ? (
                    <UploadModal
                        toggleModal={this.toggleModal}
                        modal={this.state.uploadModal}
                        schemas={this.state.schemas}
                        editingSchema={this.state.editingSchema}
                    />
                ) : null}
            </Fragment>
        )
    }
}
