import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        console.log(this.props)

        this.state = {
            name: this.props.environment.sections.find(
                (section) => section.id === this.props.editingSectionId
            ).name,
        }
    }
    async submit() {
        let env = {...this.props.environment}
        env.sections.find(
            (section) => section.id === this.props.editingSectionId
        ).name = this.state.name

        await API.patch('environments/' + this.props.environment._id, env, 2)
        this.props.toggleModal()
        this.props.handleRenameSectionSubmit()
    }

    componentWillMount() {}

    componentWillUnmount() {}

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Rename Your Section
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <Label>Name:</Label>
                                <Input
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        this.setState({name: name.target.value})
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.submit()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.submit}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
