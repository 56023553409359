export const VISUALIZATION_OPTIONS = [
    {
        label: 'Table (export to CSV)',
        value: 'table',
    },
    {
        label: 'Bar Chart',
        value: 'bar',
    },
    {
        label: 'Stacked Bar Chart',
        value: 'stackedBar',
    },
    {
        label: 'Line Chart',
        value: 'line',
    },
    {
        label: 'Pie Chart',
        value: 'pie',
    },
    {
        label: 'Statistical Process Control (SPC)',
        value: 'spc',
    },
    {
        label: 'KPI (1 value only)',
        value: 'kpi',
    },
]

export const DATE_RANGE_OPTIONS = [
    {
        label: 'Today',
        value: 'today',
    },
    {
        label: 'Yesterday',
        value: 'yesterday',
    },
    {
        label: 'This Week',
        value: 'thisWeek',
    },
    {
        label: 'Last Week',
        value: 'lastWeek',
    },
    {
        label: 'This Month',
        value: 'thisMonth',
    },
    {
        label: 'Last Month',
        value: 'lastMonth',
    },
    {
        label: 'This Year',
        value: 'thisYear',
    },
    {
        label: 'Custom',
        value: 'custom',
    },
]

export const METRIC_OPTIONS = [
    {
        label: 'Total Duration (minutes)',
        value: 'totalDuration',
    },
    {
        label: 'Total Duration (hours)',
        value: 'totalDurationHours',
    },
    {
        label: 'Total Duration (%)',
        value: 'totalDurationPercent',
    },
    {
        label: 'Average Duration (minutes)',
        value: 'averageDuration',
    },
    {
        label: 'Occurances',
        value: 'count',
    },
    {
        label: 'Average Raw Value',
        value: 'average',
    },
]

export const TIME_UNIT_OPTIONS = [
    {
        label: 'Hours',
        value: 'hours',
        key: 1,
    },
    {
        label: 'Days',
        value: 'days',
        key: 2,
    },
    {
        label: 'Weeks',
        value: 'weeks',
        key: 3,
    },
    {
        label: 'Months',
        value: 'months',
        key: 4,
    },
    {
        label: 'Quarters',
        value: 'quarters',
        key: 5,
    },
    {
        label: 'Years',
        value: 'years',
        key: 6,
    },
]
