import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    CardHeader,
    CardFooter,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import Subscriber from 'SDK/subscriber'
import {CSVLink} from 'react-csv'

import ReactTable from 'react-table'
import DataTablePagination from 'components/DataTables/pagination'
import 'react-table/react-table.css'
import DateRangePicker from 'SDK/ui/helpers/DateRangePicker'

export default class HubbellMeramecCapping extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'CMMSWorkflowTable'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            csvData: [{}],
            timeStart: moment().subtract(1, 'weeks'),
            timeEnd: moment(),
            tableData: [],
            tableColumns: [
                {
                    Header: 'Machine',
                    accessor: 'machine',
                },
                {
                    Header: 'Equipment',
                    accessor: 'equipment',
                },
                {
                    Header: 'Department',
                    accessor: 'department',
                },
                {
                    Header: 'Start Timestamp',
                    accessor: 'timestamp',
                },
                {
                    Header: 'In Process Timestamp',
                    accessor: 'assigntimestamp',
                },
                {
                    Header: 'Complete Timestamp',
                    accessor: 'completetimestamp',
                },
                {
                    Header: 'Time To Assign',
                    accessor: 'assign',
                },
                {
                    Header: 'Time To Complete',
                    accessor: 'complete',
                },
                {
                    Header: 'Mechanic Time',
                    accessor: 'worktime',
                },
            ],
        }
    }

    async componentDidMount() {
        const devices = await API.get('devices')

        // this.subscriber.add(this.mapDataRequired, 1000 * 60, 'mapDataRequired');
        this.fetchWorkflow()
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    async fetchTickets(tableData = this.state.tableData) {
        let tickets = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Maintenance Ticket',
                    timestamp: {
                        $gte: this.state.timeStart.toISOString(),
                        $lte: this.state.timeEnd.toISOString(),
                    },
                },
            },
            2
        )

        tickets.map((t) => {
            let tableRow = {
                worktime: t.workTime ? t.workTime : 0,
                machine:
                    t.labels && t.labels.assetName ? t.labels.assetName : '',
                equipment:
                    t.labels && t.labels.equipmentName
                        ? t.labels.equipmentName
                        : '',
                department:
                    t.labels && t.labels.departmentName
                        ? t.labels.departmentName
                        : '',
                timestamp: t.timestamp,
                assign: 'N/A',
                complete: 'N/A',
                assigntimestamp: 'N/A',
                completetimestamp: 'N/A',
            }

            this.state.workflow.value.map((wf) => {
                tableRow[wf.name] = 0
            })

            let store = t.store.map((s) => {
                let status = s.find((st) => st.name === 'status').value
                let timeUpdated = s.find(
                    (st) => st.name === 'timeUpdated'
                ).value

                if (status == 'IN PROCESS') {
                    tableRow['assign'] = (
                        (moment(timeUpdated) - moment(t.timestamp)) /
                        1000 /
                        60
                    ).toFixed(2)
                    tableRow['assigntimestamp'] = timeUpdated
                }

                if (status == 'COMPLETED') {
                    tableRow['complete'] = (
                        (moment(timeUpdated) - moment(t.timestamp)) /
                        1000 /
                        60
                    ).toFixed(2)
                    tableRow['completetimestamp'] = timeUpdated
                }

                if (status && timeUpdated) {
                    tableRow[status] = timeUpdated
                }
            })

            tableData.push(tableRow)
        })

        if (tickets) {
            this.setState({tableData}, () => {
                this.renderTable()
                this.generateCSV()
            })
        }
    }

    generateCSV() {
        let csv = []
        csv.push(this.state.tableColumns.map((tc) => tc.Header))
        this.state.tableData.map((td) => {
            let row = []
            this.state.tableColumns.map((tc) => {
                row.push(td[tc.accessor])
            })
            csv.push(row)
        })
        this.setState({csvData: csv})
    }

    async fetchWorkflow(columns = this.state.tableColumns) {
        let workflows = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Workflow',
                },
            },
            2
        )

        if (workflows) {
            this.setState({workflow: workflows[0]}, () => {
                this.fetchTickets()
            })
        }
    }

    renderTable() {
        this.setState({
            table: (
                <ReactTable
                    defaultPageSize={15}
                    data={this.state.tableData}
                    columns={this.state.tableColumns}
                    minRows={0}
                    PaginationComponent={DataTablePagination}
                    loading={false}
                    filterable={false}
                />
            ),
        })
    }

    handleDateChange(field, dates) {
        if (dates && dates.start) {
            this.setState(
                {
                    table: [],
                    tableData: [],
                    timeStart: dates.start,
                    timeEnd: dates.end,
                },
                () => {
                    this.fetchTickets()
                }
            )
        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <DateRangePicker
                                    cb={this.handleDateChange}
                                    reportLimit={1}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>{this.state.table}</CardBody>
                            <CardFooter>
                                <CSVLink
                                    data={this.state.csvData}
                                    filename={
                                        'Accumine-CMMS-Table-' +
                                        moment().format('MM-DD-YYYY')
                                    }
                                    className="btn btn-primary"
                                    target="_blank">
                                    Download Excel (CSV)
                                </CSVLink>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
