export default {
    control: {
        backgroundColor: '#fff',

        fontSize: 14,
        fontWeight: 'normal',
    },

    highlighter: {
        overflow: 'hidden',
    },

    input: {
        margin: 0,
    },

    '&singleLine': {
        input: {
            width: '100%',
            border: '1px solid #d7d7d7',
            backgroundColor: '#fff',
        },
        control: {},
        highlighter: {padding: 0},
    },

    '&multiLine': {
        control: {},

        highlighter: {
            padding: 9,
        },

        input: {
            padding: 9,
            minHeight: 63,
            outline: 0,
            border: 0,
        },
    },

    suggestions: {
        zIndex: 2,
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
            zIndex: 2,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#d7d7d7',
            },
        },
    },
}
