const COLUMNS = [
    {
        Header: 'Work center',
        accessor: 'value.Work center',
    },
    {
        Header: 'Station',
        accessor: 'assetName',
    },
    {
        Header: 'Priority',
        accessor: 'value.Priority',
    },
    {
        Header: 'Order',
        accessor: 'value.Order',
    },
    {
        Header: 'Open Qty (MEINH)',
        accessor: 'value.Open Qty (MEINH)',
    },
    {
        Header: 'Material',
        accessor: 'value.Material',
    },
    {
        Header: 'Material Description',
        accessor: 'value.Material Description',
    },
    {
        Header: 'TargetHour',
        accessor: 'value.TargetHour',
    },
    {
        Header: 'Basic finish date',
        accessor: 'value.Basic finish date',
    },
    {
        Header: 'RemProc (KEINH)',
        accessor: 'value.RemProc (KEINH)',
    },
    {
        Header: 'Resin Gal',
        accessor: 'value.Resin Gal',
    },
    {
        Header: 'Dry Mix (lb)',
        accessor: 'value.Dry Mix (lb)',
    },
    {
        Header: 'Marker',
        accessor: 'value.Marker',
    },
    {
        Header: 'Min',
        accessor: 'value.Min',
    },
    {
        Header: 'Max',
        accessor: 'value.Max',
    },
    {
        Header: 'GoalPartShift',
        accessor: 'value.GoalPartShift',
    },
]

export default COLUMNS
