import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Alert} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'

import EntryBox from './entryBox'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'

export default class CustomHMIEntry extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'selectedStates',
            component: 'StatePicker',
        },
    ]
    showBorder = false
    id = 'CustomHMIEntry'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        selectedStates: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            deviceId: null,
            devices: [],
            editingCode: null,
            addModal: false,
            alertMessage: '',
            alertColor: 'success',
            alertComponent: [],
            selectedStates: [],
        }
    }

    render() {
        const selectedStates = this.props.selectedStates
            ? this.props.selectedStates
            : []
        return (
            <Fragment>
                <Row className="mb-2">
                    <Col xs="12" className="text-left">
                        <AssetPicker
                            cb={(field, deviceId) => {
                                this.setState({deviceId})
                            }}
                        />
                    </Col>
                    <Col xs="12" className="text-left">
                        {this.state.alertComponent}
                    </Col>
                </Row>
                <Row className="mb-2">
                    {selectedStates.map((state, idx) => (
                        <Col key={idx} xs="4" className="text-left">
                            <EntryBox
                                name={state.value}
                                deviceId={this.state.deviceId}
                            />
                        </Col>
                    ))}
                </Row>
            </Fragment>
        )
    }
}
