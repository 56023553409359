import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import NavigationPrompt from 'react-router-navigation-prompt'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import dot from 'dot-object'
import {cloneDeep, isEqual, omit} from 'lodash'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import SchemaForm from '../../Schemas/common/Form'
import {DATA_MODEL_ID_MAP} from './DATA_MODEL_ID_MAP'

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    var a = s.split('.')
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i]
        if (k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o
}

const Templates = {
    Press: PressTemplate,
    BBO: BBOTemplate,
    Kiln: KilnTemplate,
    'Collar Spray': CollarSprayTemplate,
    'Collar Cure': CollarCureTemplate,
    Grinding: GrindingTemplate,
    'Visual Inspection': VisualInspectionTemplate,
    Metallizing: MetallizingTemplate,
    'Test & Inspect': TestAndInspectTemplate,
    '3DA Bordering': ThreeDABorderingTemplate,
    '3DA High Energy': Three3DAHighEnergyTemplate,
    '3DA Transmission': Three3DATransmissionTemplate,
    '3DA 100A': ThreeDA100ATemplate,
    'Final Inspection (3DA Batches)': FinalInspectionTemplate,
    '2 Shot Re-Run Tracker': TwoShotReRunTrackerTemplate,
}

function PressTemplate({TitleField, properties, title, description}) {
    if (title === 'Scrap') {
        return (
            <>
                <TitleField title={title} />
                {properties.map((p) => (
                    <Col className="wadsworth" xs={12} key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </>
        )
    } else {
        return (
            <Row>
                <Col style={{display: 'none'}}>
                    {
                        properties.find((p) => p.name === 'Block Batch Link')
                            .content
                    }
                </Col>
                <Col xs="4">
                    <Col xs="12" className="wadsworth" key="Start Date">
                        {
                            properties.find((p) => p.name === 'Start Date')
                                .content
                        }
                    </Col>
                    <Col xs="12" className="wadsworth" key="Press Machine ID">
                        {
                            properties.find(
                                (p) => p.name === 'Press Machine ID'
                            ).content
                        }
                    </Col>
                    <Col xs="12" className="wadsworth" key="Hoppers">
                        {properties.find((p) => p.name === 'Hoppers').content}
                    </Col>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Col>
                <Col xs="4">
                    <Col xs={12} className="wadsworth" key="Target Length">
                        {
                            properties.find((p) => p.name === 'Target Length')
                                .content
                        }
                    </Col>
                    <Col xs={12} className="wadsworth" key="Target Weight">
                        {
                            properties.find((p) => p.name === 'Target Weight')
                                .content
                        }
                    </Col>
                    <Col
                        xs={12}
                        className="wadsworth"
                        key="Total Press Tonnage">
                        {
                            properties.find(
                                (p) => p.name === 'Total Press Tonnage'
                            ).content
                        }
                    </Col>
                    <Col xs={12} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                    <Col
                        xs={12}
                        className="wadsworth"
                        key="Parts Pressed"
                        style={{fontWeight: 'bold', fontSize: 15}}>
                        {
                            properties.find((p) => p.name === 'Parts Pressed')
                                .content
                        }
                    </Col>
                </Col>
                <Col xs="4">
                    <Col xs={12} className="wadsworth" key="Scrap">
                        {properties.find((p) => p.name === 'Scrap').content}
                    </Col>
                </Col>
            </Row>
        )
    }
}

function BBOTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col
                        xs={{size: 4, offset: 4}}
                        className="wadsworth"
                        key="Total Blocks Scrap Both Parts">
                        {
                            properties.find(
                                (p) =>
                                    p.name === 'Total Blocks Scrap Both Parts'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="1"></Col>
                    {properties
                        .filter(
                            (a) =>
                                ![
                                    'dataModelId',
                                    'Block Batch Link',
                                    'Total Blocks Scrap Both Parts',
                                    'Comments',
                                ].includes(a.name)
                        )
                        .map((p) => (
                            <Col xs="2" key={p.name}>
                                {p.content}
                            </Col>
                        ))}
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs="12" className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function KilnTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <Row>
                <Col style={{display: 'none'}}>
                    {
                        properties.find((p) => p.name === 'Block Batch Link')
                            .content
                    }
                </Col>
                <Col xs="12" key="Machine ID">
                    {properties.find((p) => p.name === 'Machine ID').content}
                </Col>
                <Col xs="6" key="Load">
                    {properties.find((p) => p.name === 'Load').content}
                </Col>
                <Col xs="6" key="Unload">
                    {properties.find((p) => p.name === 'Unload').content}
                </Col>
            </Row>
        )
    } else {
        const largeFields = [
            'Operator Initials',
            'Start Date',
            'Kiln Scrap',
            'Comments',
            'Kiln Unload Scrap',
        ]
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => {
                        if (p.name === 'QA Sample') {
                            return (
                                <Col
                                    xs={largeFields.includes(p.name) ? 12 : 6}
                                    className="wadsworth"
                                    key={p.name}>
                                    <label>QA Sample</label>
                                    <br />
                                    <h5 style={{textAlign: 'center'}}>
                                        <strong>7</strong>
                                    </h5>
                                </Col>
                            )
                        } else {
                            return (
                                <Col
                                    xs={largeFields.includes(p.name) ? 12 : 6}
                                    className="wadsworth"
                                    key={p.name}>
                                    {p.content}
                                </Col>
                            )
                        }
                    })}
            </Row>
        )
    }
}

function CollarSprayTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs="6">
                        <Col xs={12} className="wadsworth" key="Start Date">
                            {
                                properties.find((p) => p.name === 'Start Date')
                                    .content
                            }
                        </Col>
                        <Col
                            xs={12}
                            className="wadsworth"
                            key="Operator Initials">
                            {
                                properties.find(
                                    (p) => p.name === 'Operator Initials'
                                ).content
                            }
                        </Col>
                        <Col
                            xs={12}
                            className="wadsworth"
                            key="Part 1 Machine ID">
                            {
                                properties.find(
                                    (p) => p.name === 'Part 1 Machine ID'
                                ).content
                            }
                        </Col>
                    </Col>
                    <Col xs="6">
                        <Col xs={12} className="wadsworth" key="Total Rewashed">
                            {
                                properties.find(
                                    (p) => p.name === 'Total Rewashed'
                                ).content
                            }
                        </Col>
                        <Col xs={12} className="wadsworth" key="Collar Batch">
                            {
                                properties.find(
                                    (p) => p.name === 'Collar Batch'
                                ).content
                            }
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="wadsworth" key="Scrap_Categories">
                        {
                            properties.find(
                                (p) => p.name === 'Scrap_Categories'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={3} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function CollarCureTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col
                        className="wadsworth"
                        key="Total Blocks Scrap"
                        xs={{size: 4, offset: 4}}>
                        {
                            properties.find(
                                (p) => p.name === 'Total Blocks Scrap'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="wadsworth" key="Collar Cure Part 1" xs="3">
                        {
                            properties.find(
                                (p) => p.name === 'Collar Cure Part 1'
                            ).content
                        }
                    </Col>
                    <Col className="wadsworth" key="Collar Cure Part 2" xs="3">
                        {
                            properties.find(
                                (p) => p.name === 'Collar Cure Part 2'
                            ).content
                        }
                    </Col>
                    <Col className="wadsworth" key="Collar Cure Part 3" xs="3">
                        {
                            properties.find(
                                (p) => p.name === 'Collar Cure Part 3'
                            ).content
                        }
                    </Col>
                    <Col className="wadsworth" key="Collar Cure Part 4" xs="3">
                        {
                            properties.find(
                                (p) => p.name === 'Collar Cure Part 4'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="wadsworth" key="Comments" xs="12">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties.map((p) => (
                    <Col xs={12} className="wadsworth" key={p.name}>
                        {p.content}
                    </Col>
                ))}
            </Row>
        )
    }
}

function GrindingTemplate({TitleField, properties, title, description}) {
    return (
        <>
            <Row>
                <Col style={{display: 'none'}}>
                    {
                        properties.find((p) => p.name === 'Block Batch Link')
                            .content
                    }
                </Col>
                <Col xs="6">
                    <Col xs={12} className="wadsworth" key="Start Timestamp">
                        {
                            properties.find((p) => p.name === 'Start Timestamp')
                                .content
                        }
                    </Col>
                    <Col xs={12} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                    <Col xs={12} className="wadsworth" key="Part 1 Machine ID">
                        {
                            properties.find(
                                (p) => p.name === 'Part 1 Machine ID'
                            ).content
                        }
                    </Col>
                </Col>
                <Col>
                    <Col xs={12} className="wadsworth" key="Total Blocks Scrap">
                        {
                            properties.find(
                                (p) => p.name === 'Total Blocks Scrap'
                            ).content
                        }
                    </Col>
                    <Col
                        xs={12}
                        className="wadsworth"
                        key="Actual Grind Length">
                        {
                            properties.find(
                                (p) => p.name === 'Actual Grind Length'
                            ).content
                        }
                    </Col>
                    <Col xs={12} className="wadsworth" key="Part 2 Machine ID">
                        {
                            properties.find(
                                (p) => p.name === 'Part 2 Machine ID'
                            ).content
                        }
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="wadsworth" key="Comments">
                    {properties.find((p) => p.name === 'Comments').content}
                </Col>
            </Row>
        </>
    )
}

function VisualInspectionTemplate({
    TitleField,
    properties,
    title,
    description,
}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Start Date">
                        {
                            properties.find((p) => p.name === 'Start Date')
                                .content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={6}
                        className="wadsworth"
                        key="Visual_Inspection_Rework">
                        {
                            properties.find(
                                (p) => p.name === 'Visual_Inspection_Rework'
                            ).content
                        }
                    </Col>
                    <Col
                        xs={6}
                        className="wadsworth"
                        key="Visual Inspection Scrap">
                        {
                            properties.find(
                                (p) => p.name === 'Visual Inspection Scrap'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={6} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function MetallizingTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={3} className="wadsworth" key="Start Timestamp">
                        {
                            properties.find((p) => p.name === 'Start Timestamp')
                                .content
                        }
                    </Col>
                    <Col xs={3} className="wadsworth" key="Machine ID">
                        {
                            properties.find((p) => p.name === 'Machine ID')
                                .content
                        }
                    </Col>
                    <Col xs={3} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                    <Col xs={3} className="wadsworth" key="Bordered">
                        {properties.find((p) => p.name === 'Bordered').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Metallizing Scrap">
                        {
                            properties.find(
                                (p) => p.name === 'Metallizing Scrap'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={3} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function TestAndInspectTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Start Timestamp">
                        {
                            properties.find((p) => p.name === 'Start Timestamp')
                                .content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Machine ID">
                        {
                            properties.find((p) => p.name === 'Machine ID')
                                .content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Good Block Count">
                        {
                            properties.find(
                                (p) => p.name === 'Good Block Count'
                            ).content
                        }
                    </Col>
                    {/*<Col xs={4}  key="Diff_Between_Actual_And_Calculated_Count">{properties.find(p => p.name === 'Diff_Between_Actual_And_Calculated_Count').content}</Col>*/}
                </Row>
                <Row>
                    <Col
                        xs={6}
                        className="wadsworth"
                        key="Test & Inspect Scrap">
                        {
                            properties.find(
                                (p) => p.name === 'Test & Inspect Scrap'
                            ).content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Rework">
                        {properties.find((p) => p.name === 'Rework').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter(
                        (f) =>
                            f.name !== 'dataModelId' &&
                            f.name !== 'Misallocated_Block'
                    )
                    .map((p) => (
                        <Col xs={6} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function ThreeDABorderingTemplate({
    TitleField,
    properties,
    title,
    description,
}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Date">
                        {properties.find((p) => p.name === 'Date').content}
                    </Col>
                    <Col xs={6} className="wadsworth" key="Initials">
                        {properties.find((p) => p.name === 'Initials').content}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        className="wadsworth"
                        key="3DA Bordering Scrap">
                        {
                            properties.find(
                                (p) => p.name === '3DA Bordering Scrap'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={3} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function Three3DAHighEnergyTemplate({
    TitleField,
    properties,
    title,
    description,
}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Date">
                        {properties.find((p) => p.name === 'Date').content}
                    </Col>
                    <Col xs={4} className="wadsworth" key="Initials">
                        {properties.find((p) => p.name === 'Initials').content}
                    </Col>
                    <Col xs={4} className="wadsworth" key="# Shots">
                        {properties.find((p) => p.name === '# Shots').content}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        className="wadsworth"
                        key="3DA High Energy Scrap">
                        {
                            properties.find(
                                (p) => p.name === '3DA High Energy Scrap'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={3} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function Three3DATransmissionTemplate({
    TitleField,
    properties,
    title,
    description,
}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Date">
                        {properties.find((p) => p.name === 'Date').content}
                    </Col>
                    <Col xs={4} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Qas Added">
                        {properties.find((p) => p.name === 'Qas Added').content}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        className="wadsworth"
                        key="3DA Transmission Scrap">
                        {
                            properties.find(
                                (p) => p.name === '3DA Transmission Scrap'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={3} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function ThreeDA100ATemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Date">
                        {properties.find((p) => p.name === 'Date').content}
                    </Col>
                    <Col xs={6} className="wadsworth" key="Initials">
                        {properties.find((p) => p.name === 'Initials').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="3DA 100A Scrap">
                        {
                            properties.find((p) => p.name === '3DA 100A Scrap')
                                .content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={3} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function FinalInspectionTemplate({TitleField, properties, title, description}) {
    if (title === ' ') {
        return (
            <>
                <Row>
                    <Col style={{display: 'none'}}>
                        {
                            properties.find(
                                (p) => p.name === 'Block Batch Link'
                            ).content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Start Timestamp">
                        {
                            properties.find((p) => p.name === 'Start Timestamp')
                                .content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Operator Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator Initials'
                            ).content
                        }
                    </Col>
                    <Col xs={4} className="wadsworth" key="Good Block Count">
                        {
                            properties.find(
                                (p) => p.name === 'Good Block Count'
                            ).content
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="wadsworth" key="Scrap">
                        {properties.find((p) => p.name === 'Scrap').content}
                    </Col>
                    {/*<Col xs={4} className="wadsworth" key="Rework">{properties.find(p => p.name === 'Rework').content}</Col>*/}
                    <Col xs={4} className="wadsworth" key="Comments">
                        {properties.find((p) => p.name === 'Comments').content}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="wadsworth" key="Diameter_Checks">
                        {
                            properties.find((p) => p.name === 'Diameter_Checks')
                                .content
                        }
                    </Col>
                    <Col xs={6} className="wadsworth" key="Height_Checks">
                        {
                            properties.find((p) => p.name === 'Height_Checks')
                                .content
                        }
                    </Col>
                </Row>
            </>
        )
    } else {
        let subtext = undefined
        if (title === 'Diameter_Checks') {
            subtext = 'Min: 76.40, Max: 79.25'
        } else if (title === 'Height_Checks') {
            subtext = 'Min: 34.50, Max: 35.70'
        }
        return (
            <Row>
                <Col xs="12">
                    <TitleField className="wadsworth" title={title} />
                </Col>
                {subtext ? (
                    <Col xs="12">
                        <p>
                            <strong>{subtext}</strong>
                        </p>
                    </Col>
                ) : null}
                {properties
                    .filter(
                        (f) =>
                            f.name !== 'dataModelId' &&
                            f.name !== 'Misallocated_Block'
                    )
                    .map((p) => (
                        <Col xs={6} className="wadsworth" key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }
}

function TwoShotReRunTrackerTemplate({
    TitleField,
    properties,
    title,
    description,
}) {
    if (title === ' ') {
        return (
            <Row>
                <Col style={{display: 'none'}}>
                    {
                        properties.find((p) => p.name === 'Block Batch Link')
                            .content
                    }
                </Col>
                <Col xs="12" key="Re_Runs">
                    {properties.find((p) => p.name === 'Re_Runs').content}
                </Col>
            </Row>
        )
    } else {
        if (properties.find((p) => p.name === 'Re_Run')) {
            return properties.find((p) => p.name === 'Re_Run').content
        } else {
            return (
                <Row>
                    <Col xs="6" key="Date">
                        {properties.find((p) => p.name === 'Date').content}
                    </Col>
                    <Col xs="6" key="Operator_Initials">
                        {
                            properties.find(
                                (p) => p.name === 'Operator_Initials'
                            ).content
                        }
                    </Col>
                    <Col xs="4" style={{border: '1px solid black'}}>
                        <Row>
                            <Col xs="12">
                                <p>
                                    <strong>1st Shift</strong>
                                </p>
                            </Col>
                            <Col xs="12" key="1st_Shift_Good">
                                {
                                    properties.find(
                                        (p) => p.name === '1st_Shift_Good'
                                    ).content
                                }
                            </Col>
                            <Col xs="12" key="1st_Shift_Scrap">
                                {
                                    properties.find(
                                        (p) => p.name === '1st_Shift_Scrap'
                                    ).content
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="4" style={{border: '1px solid black'}}>
                        <Row>
                            <Col xs="12">
                                <p>
                                    <strong>2nd Shift</strong>
                                </p>
                            </Col>
                            <Col xs="12" key="2nd_Shift_Good">
                                {
                                    properties.find(
                                        (p) => p.name === '2nd_Shift_Good'
                                    ).content
                                }
                            </Col>
                            <Col xs="12" key="2nd_Shift_Scrap">
                                {
                                    properties.find(
                                        (p) => p.name === '2nd_Shift_Scrap'
                                    ).content
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="4" style={{border: '1px solid black'}}>
                        <Row>
                            <Col xs="12">
                                <p>
                                    <strong>3rd Shift</strong>
                                </p>
                            </Col>
                            <Col xs="12" key="3rd_Shift_Good">
                                {
                                    properties.find(
                                        (p) => p.name === '3rd_Shift_Good'
                                    ).content
                                }
                            </Col>
                            <Col xs="12" key="3rd_Shift_Scrap">
                                {
                                    properties.find(
                                        (p) => p.name === '3rd_Shift_Scrap'
                                    ).content
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12">
                        <hr />
                    </Col>
                </Row>
            )
        }
    }
}

const SavingState = Object.freeze({
    NOT_SAVED: 0,
    SAVING: 1,
    SAVED: 2,
    SAVE_FAILED: 3,
})

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.path = [...this.props.path]
        this.path.push({
            name: this.props.title,
            fn: () => {},
        })

        console.log(
            `${this.props.title} - ${
                this.props.editingRecord ? 'EXISTING' : 'NEW'
            }`
        )

        this.state = {
            loading: true,
            ready: false,

            dataModels: [],
            validator: null,

            blockHeightModalOpen: false,

            count: 0,
            countOut: 0,
            missingBlocks: 0,
            twoShotGood: 0,
            twoShotScrap: 0,

            autoSaveStatus: SavingState.NOT_SAVED,
            autoSaveFormData: null,
        }
    }

    async openBlockHeightModal() {
        this.setState({loading: true})

        this.measuredBlockHeightsValidator = await API.get(
            'data-models/5fc92f0ed02f3471d06e6ba8/validator',
            2
        )

        await this.fetchBlockHeightsRecord()

        let batchLinkFieldPath =
            DATA_MODEL_ID_MAP['Block Heights'].masterBlockFieldPath.split(
                '.'
            )[0]
        //this.measuredBlockHeightsValidator.properties[batchLinkFieldPath].default = this.props.blockBatchRecord.value;

        this.blockHeightRecord[batchLinkFieldPath] =
            this.props.blockBatchRecord.value

        if (!this.blockHeightRecord._id) {
            this.blockHeightRecord['Block Heigh Values'] = new Array(21).fill({
                Value: 0,
                dataModelId: '5fc932a1d02f3471d06e6bad',
            })
            //this.measuredBlockHeightsValidator.properties['Block Heigh Values'].default = new Array(15).fill({
            //	Value: 0,
            //	dataModelId: '5fc932a1d02f3471d06e6bad'
            //});
        } else {
            //this.measuredBlockHeightsValidator.properties['Block Heigh Values'].default = this.blockHeightRecord.result.results[0]['Block Heigh Values'];
        }

        this.setState({
            blockHeightModalOpen: true,
            loading: false,
            autoSaveStatus: SavingState.NOT_SAVED,
            autoSaveFormData: null,
            kilnFormData: this.state.autoSaveFormData,
        })
    }

    async closeBlockHeightModal() {
        const {autoSaveStatus} = this.state

        if (
            autoSaveStatus === SavingState.SAVE_FAILED ||
            autoSaveStatus === SavingState.SAVING
        ) {
            if (
                window.confirm(
                    'Are you sure you want to exit? You will lose your changes.'
                )
            ) {
                this.setState({
                    blockHeightModalOpen: false,
                    autoSaveStatus: SavingState.NOT_SAVED,
                    autoSaveFormData: this.state.kilnFormData,
                })
                await this.fetchBlockHeightsRecord()
            }
        } else {
            this.setState({
                blockHeightModalOpen: false,
                autoSaveStatus: SavingState.NOT_SAVED,
                autoSaveFormData: this.state.kilnFormData,
            })
            await this.fetchBlockHeightsRecord()
        }
    }

    calculateCount(record) {
        const {operationRecords, operationMap, title} = this.props

        if (title === '2 Shot Re-Run Tracker') {
            this.setState(
                {
                    twoShotGood:
                        DATA_MODEL_ID_MAP[
                            '2 Shot Re-Run Tracker'
                        ].goodPartCounter(record),
                    twoShotScrap:
                        DATA_MODEL_ID_MAP[
                            '2 Shot Re-Run Tracker'
                        ].complexScrapFieldParser(record),
                },
                () => this.calculateCountOut(record)
            )
        } else {
            let count = 0

            const press =
                title === 'Press' && record ? record : operationRecords['Press']
            if (press && press[operationMap['Press']['totalCountFieldPath']]) {
                count = press[operationMap['Press']['totalCountFieldPath']]
            }

            for (let operation in operationRecords) {
                if (operation === title) {
                    break
                } else {
                    for (let scrapFieldPath of operationMap[operation][
                        'scrapFieldPaths'
                    ]) {
                        if (operationRecords[operation]) {
                            let v = Object.byString(
                                operationRecords[operation],
                                scrapFieldPath
                            )
                            if (v) {
                                count -= v
                            }
                        }
                    }
                    for (let negateScrapFieldPath of operationMap[operation][
                        'negateScrapFieldPaths'
                    ]) {
                        if (operationRecords[operation]) {
                            let v = Object.byString(
                                operationRecords[operation],
                                negateScrapFieldPath
                            )
                            if (v) {
                                count -= v
                            }
                        }
                    }
                }
            }
            this.setState({count}, () => this.calculateCountOut(record))
        }
    }

    calculateCountOut(record) {
        if (this.props.title === '2 Shot Re-Run Tracker')
            this.onFormChange(record)
        let count = 0

        const r = record || this.props.operationRecords[this.props.title]
        if (r) {
            for (let scrapFieldPath of DATA_MODEL_ID_MAP[this.props.title]
                .scrapFieldPaths) {
                if (
                    !(
                        this.props.title === 'Test & Inspect' &&
                        scrapFieldPath ===
                            'Test & Inspect Scrap.Misallocated_Block'
                    ) &&
                    !(
                        this.props.title === 'Final Inspection (3DA Batches)' &&
                        scrapFieldPath === 'Scrap.Misallocated_Block'
                    )
                ) {
                    let c = Object.byString(r, scrapFieldPath)
                    if (c) count += c
                }
            }
        }

        this.setState(
            {
                countOut: this.state.count - count,
                missingBlocks:
                    this.state.count - count - (r ? r['Good Block Count'] : 0),
            },
            () => this.onFormChange(record)
        )
    }

    async onSubmit(document, reset = true) {
        this.setState({loading: true})
        if (
            document.dataModelId === '5fc94685d02f3471d06e6c52' &&
            this.state.countOut > 0
        ) {
            // set end timestamp to block batch record, it's finished
            let blockBatchRecord = this.props.blockBatchRecord.value
            blockBatchRecord['Finish Date'] = moment().toISOString()
            /*for (let k in blockBatchRecord) {
					if (blockBatchRecord[k].constructor === Object && blockBatchRecord[k]._id) {
						blockBatchRecord[k] = blockBatchRecord[k]._id;
					}
				}*/
            await API.patch(
                'data-models/5fc9015bae96bc6be2c1a13e/edit-record',
                blockBatchRecord,
                2
            )

            // track discrepency between operator entered good block count and calculated good block count
            document['Test & Inspect Scrap']['Misallocated_Block'] =
                this.state.countOut - document['Good Block Count']

            await API.patch(
                'data-models/5fc94685d02f3471d06e6c52/edit-record',
                document,
                2
            )
        } else if (
            document.dataModelId === '606cb3fdffbe5700134f9683' &&
            this.state.countOut > 0
        ) {
            // set end timestamp to block batch record, it's finished
            let blockBatchRecord = this.props.blockBatchRecord.value
            blockBatchRecord['Finish Date'] = moment().toISOString()
            /*for (let k in blockBatchRecord) {
					if (blockBatchRecord[k].constructor === Object && blockBatchRecord[k]._id) {
						blockBatchRecord[k] = blockBatchRecord[k]._id;
					}
				}*/
            await API.patch(
                'data-models/5fc9015bae96bc6be2c1a13e/edit-record',
                blockBatchRecord,
                2
            )

            // track discrepency between operator entered good block count and calculated good block count
            document['Scrap']['Misallocated_Block'] =
                this.state.countOut - document['Good Block Count']

            await API.patch(
                'data-models/606cb3fdffbe5700134f9683/edit-record',
                document,
                2
            )
        }
        if (reset) {
            this.props.reset()
        }

        this.setState({loading: false})
    }

    async fetchBlockHeightsRecord() {
        this.blockHeightRecord = {}
        const results = await API.post(
            'data-models/' +
                DATA_MODEL_ID_MAP['Block Heights'].dataModelId +
                '/paginate',
            {
                filter: [
                    {
                        type: 'Text',
                        logic: 'is',
                        value: [
                            this.props.blockBatchRecord.value['Batch Number'],
                        ],
                        path: 'Block Batch Link.Batch Number',
                    },
                ],
                sort: {},
                limit: 1,
                next: null,
                previous: null,
                timezone: localStorage['timezone'],
            },
            2
        )

        let average = 0,
            stdev = 0

        if (results.result.results.length) {
            const record = results.result.results[0],
                values = record['Block Heigh Values']
                    .map((v) => v['Value'])
                    .filter((v) => v > 0)
            this.blockHeightRecord = record

            if (values.length) {
                average = values.reduce((a, b) => a + b, 0) / values.length
                stdev = Math.sqrt(
                    values
                        .map((x) => Math.pow(x - average, 2))
                        .reduce((a, b) => a + b) / values.length
                )
            }
        }

        this.setState({
            blockHeightsAverage: Math.round(average * 100) / 100,
            blockHeightsStDev: Math.round(stdev * 100) / 100,
        })
    }

    autoSave(formData) {
        clearTimeout(this.timer)
        this.setState({
            autoSaveFormData: formData,
            autoSaveStatus: SavingState.NOT_SAVED,
        })
        this.timer = setTimeout(async () => {
            this.setState({
                autoSaveFormData: formData,
                autoSaveStatus: SavingState.SAVING,
            })
            try {
                // save record
                let document
                if (formData._id) {
                    document = await API.patch(
                        `data-models/${
                            this.state.blockHeightModalOpen
                                ? '5fc92f0ed02f3471d06e6ba8'
                                : this.props.dataModelId
                        }/edit-record`,
                        formData,
                        2,
                        15000
                    )
                } else {
                    document = await API.post(
                        `data-models/${
                            this.state.blockHeightModalOpen
                                ? '5fc92f0ed02f3471d06e6ba8'
                                : this.props.dataModelId
                        }/add-record`,
                        formData,
                        2,
                        15000
                    )
                    if (document && this.state.blockHeightModalOpen) {
                        this.blockHeightsDocumentId = document._id
                    } else if (document && !this.state.blockHeightModalOpen) {
                        this.documentId = document._id
                    }
                }

                if (!document) {
                    throw Error('Save failed')
                }

                if (!this.state.blockHeightModalOpen) {
                    await this.onSubmit(document, false)
                }

                this.setState({autoSaveStatus: SavingState.SAVED})
            } catch (error) {
                this.setState({
                    autoSaveStatus: SavingState.SAVE_FAILED,
                })
                toast(
                    "Could not save data! Try manually submitting form by clicking 'SAVE'",
                    {
                        toastId: `wadsworth-autosave-${
                            Math.round(moment().seconds() / 10) * 10
                        }`,
                        position: 'top-right',
                        type: 'error',
                        //autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                )
                console.log(error)
            }
        }, 500)
    }

    async onFormChange(formData) {
        if (!formData) {
            return
        }

        if (!this.state.autoSaveFormData) {
            let cloned = cloneDeep(formData)
            delete cloned['Block Batch Link']
            let flat = dot.dot(cloned)
            for (let k in flat) {
                if (k.endsWith('._id')) {
                    let arr = k.split('.')
                    arr.pop()
                    flat[arr.join('.')] = flat[k]
                    flat = omit(
                        flat,
                        Object.keys(flat).filter((a) =>
                            a.startsWith(arr.join('.'))
                        )
                    )
                }
            }
            this.setState({autoSaveFormData: dot.object(flat)})
        } else {
            let cloned = cloneDeep(formData)
            delete cloned['Block Batch Link']
            let flat = dot.dot(cloned)
            for (let k in flat) {
                if (k.endsWith('._id')) {
                    let arr = k.split('.')
                    arr.pop()
                    flat[arr.join('.')] = flat[k]
                    flat = omit(
                        flat,
                        Object.keys(flat).filter((a) =>
                            a.startsWith(arr.join('.'))
                        )
                    )
                }
            }
            cloned = dot.object(flat)
            if (!isEqual(this.state.autoSaveFormData, cloned)) {
                if (this.state.blockHeightModalOpen) {
                    if (this.blockHeightsDocumentId) {
                        formData._id = this.blockHeightsDocumentId
                    }
                } else {
                    if (this.props.editingRecord) {
                        formData._id = this.props.editingRecord._id
                    } else if (this.documentId) {
                        formData._id = this.documentId
                    }
                }
                this.autoSave(formData)
            }
        }
    }

    async componentDidMount() {
        this.timer = null

        let dataModels = await API.get('data-models', 2),
            validator = await API.get(
                'data-models/' + this.props.dataModelId + '/validator',
                2
            )

        if (this.props.editingRecord && this.props.editingRecord._id) {
            this.editingRecord = this.props.editingRecord
            for (let fieldId in validator.properties) {
                if (this.props.editingRecord[fieldId]) {
                    validator.properties[fieldId].default =
                        this.props.editingRecord[fieldId]
                }
            }
        } else {
            let batchLinkFieldPath =
                DATA_MODEL_ID_MAP[this.props.title].masterBlockFieldPath.split(
                    '.'
                )[0]
            this.editingRecord = {
                [batchLinkFieldPath]: this.props.blockBatchRecord.value,
            }

            if (this.props.title === '2 Shot Re-Run Tracker') {
                this.editingRecord.Re_Runs = new Array(3).fill({
                    Re_Run: {
                        Operator_Initials: '',
                        //Date,
                        '1st_Shift_Good': 0,
                        '1st_Shift_Scrap': 0,
                        '2nd_Shift_Good': 0,
                        '2nd_Shift_Scrap': 0,
                        '3rd_Shift_Good': 0,
                        '3rd_Shift_Scrap': 0,
                    },
                })
            }
        }

        if (this.props.title === 'Kiln') {
            if (this.editingRecord._id) {
                this.editingRecord.Unload['Kiln Unload Scrap']['QA Sample'] = 7
            } else {
                this.editingRecord.Unload = {
                    'Kiln Unload Scrap': {
                        'QA Sample': 7,
                    },
                }
            }
            await this.fetchBlockHeightsRecord()
        }

        this.calculateCount()

        this.setState({
            dataModels,
            validator,
            loading: false,
            ready: true,
        })
    }

    render() {
        const blockDescription =
                this.props.blockBatchRecord.value['Block Description'],
            size =
                blockDescription && blockDescription.constructor === Object
                    ? blockDescription['Block Description']
                    : 'N/A',
            partNumber =
                blockDescription && blockDescription.constructor === Object
                    ? blockDescription['Part Number']
                    : 'N/A',
            {autoSaveStatus} = this.state,
            dirty =
                autoSaveStatus === SavingState.SAVE_FAILED ||
                autoSaveStatus === SavingState.SAVING

        return (
            <Fragment>
                {this.state.loading ? <div className="loading" /> : null}
                <ToastContainer />
                <NavigationPrompt
                    when={dirty}
                    afterConfirm={() => {
                        if (this.state.clickedGoBack) {
                            this.setState({
                                clickedGoBack: false,
                            })
                            this.props.reset()
                        }
                    }}>
                    {({onConfirm, onCancel}) => (
                        <Modal isOpen={dirty}>
                            <ModalHeader>
                                <b>YOU HAVE UNSAVED CHANGES!</b>
                            </ModalHeader>
                            <ModalBody>
                                <Button
                                    block
                                    color="danger"
                                    onClick={onConfirm}>
                                    <b>EXIT WITHOUT SAVING</b>
                                </Button>
                                <Button
                                    block
                                    color="default"
                                    onClick={onCancel}>
                                    <b>CANCEL</b>
                                </Button>
                            </ModalBody>
                        </Modal>
                    )}
                </NavigationPrompt>
                {this.state.ready ? (
                    <Fragment>
                        <Row className="mb-2">
                            <Col xs="5">
                                <Link
                                    to="#"
                                    onClick={() => {
                                        if (dirty) {
                                            this.setState({clickedGoBack: true})
                                        } else {
                                            this.props.reset()
                                        }
                                    }}
                                    disabled={
                                        autoSaveStatus === SavingState.SAVING
                                    }>
                                    <Button
                                        color="light"
                                        size="sm"
                                        disabled={
                                            autoSaveStatus ===
                                            SavingState.SAVING
                                        }>
                                        {'<'} GO BACK
                                    </Button>
                                </Link>
                                <span className="ml-2" style={{fontSize: 15}}>
                                    {this.props.title}
                                </span>
                            </Col>
                            <Col xs="1">
                                {autoSaveStatus === SavingState.NOT_SAVED ? (
                                    <Badge color="secondary">NOT SAVED</Badge>
                                ) : null}
                                {autoSaveStatus === SavingState.SAVING ? (
                                    <Badge color="secondary">SAVING...</Badge>
                                ) : null}
                                {autoSaveStatus === SavingState.SAVED ? (
                                    <Badge color="success">SAVED</Badge>
                                ) : null}
                                {autoSaveStatus === SavingState.SAVE_FAILED ? (
                                    <Badge color="danger">SAVE FAILED</Badge>
                                ) : null}
                            </Col>
                            <Col xs="6">
                                <p
                                    style={{
                                        marginTop: 15,
                                        textAlign: 'right',
                                        marginBottom: 0,
                                        paddingBottom: 0,
                                    }}>
                                    Batch #:{' '}
                                    <strong>
                                        {
                                            this.props.blockBatchRecord.value[
                                                'Batch Number'
                                            ]
                                        }
                                    </strong>
                                    , Size: <strong>{size}</strong>, Part #:{' '}
                                    <strong>{partNumber}</strong>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            {this.props.title !== '2 Shot Re-Run Tracker' ? (
                                <>
                                    <Col
                                        xs="3"
                                        style={{
                                            textAlign: 'center',
                                            border: '1px solid black',
                                        }}>
                                        <h2>
                                            <strong>
                                                BLOCK COUNT IN:{' '}
                                                {this.state.count}
                                            </strong>
                                        </h2>
                                    </Col>
                                    <Col
                                        xs="3"
                                        style={{
                                            textAlign: 'center',
                                            border: '1px solid black',
                                        }}>
                                        <h2>
                                            <strong>
                                                {this.props.title ===
                                                    'Test & Inspect' ||
                                                this.props.title ===
                                                    'Final Inspection (3DA Batches)'
                                                    ? 'CALCULATED GOOD BLOCK COUNT'
                                                    : 'BLOCK COUNT OUT'}
                                                {': '}
                                                {this.state.countOut}
                                            </strong>
                                        </h2>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col
                                        xs="3"
                                        style={{
                                            textAlign: 'center',
                                            border: '1px solid black',
                                        }}>
                                        <h2>
                                            <strong>
                                                TOTAL GOOD:{' '}
                                                {this.state.twoShotGood}
                                            </strong>
                                        </h2>
                                    </Col>
                                    <Col
                                        xs="3"
                                        style={{
                                            textAlign: 'center',
                                            border: '1px solid black',
                                        }}>
                                        <h2>
                                            <strong>
                                                TOTAL SCRAP:{' '}
                                                {this.state.twoShotScrap}
                                            </strong>
                                        </h2>
                                    </Col>
                                </>
                            )}
                            {this.props.title === 'Test & Inspect' ||
                            this.props.title ===
                                'Final Inspection (3DA Batches)' ? (
                                <Col
                                    xs="3"
                                    style={{
                                        textAlign: 'center',
                                        border: '1px solid black',
                                    }}>
                                    <h2>
                                        <strong>
                                            MISALLOCATED BLOCKS:{' '}
                                            {this.state.missingBlocks}
                                        </strong>
                                    </h2>
                                </Col>
                            ) : null}
                            {this.props.title === 'Kiln' ? (
                                <Col
                                    xs="6"
                                    className="text-right"
                                    style={{border: '1px solid black'}}>
                                    <h5>
                                        <Button
                                            size="md"
                                            disabled={dirty}
                                            onClick={this.openBlockHeightModal}>
                                            ENTER MEASURED BLOCK HEIGHT
                                        </Button>{' '}
                                        <strong>
                                            AVERAGE BLOCK HEIGHT:{' '}
                                            {this.state.blockHeightsAverage},
                                            BLOCK HEIGHT ST DEV:{' '}
                                            {this.state.blockHeightsStDev}
                                        </strong>
                                    </h5>
                                </Col>
                            ) : null}
                        </Row>
                        <Row>
                            <Col xs={{size: 12}}>
                                <SchemaForm
                                    schema={this.state.validator}
                                    editingRecord={this.editingRecord}
                                    dataModels={this.state.dataModels}
                                    dataModel={this.state.dataModels.find(
                                        (d) => d._id === this.props.dataModelId
                                    )}
                                    onSubmit={this.calculateCount}
                                    hideArchive={true}
                                    timestampsToToday={true}
                                    onChange={this.calculateCount}
                                    manualDataLinkFilters={[
                                        {
                                            type: 'Text',
                                            logic: 'is',
                                            value: [
                                                this.props.blockBatchRecord
                                                    .label,
                                            ],
                                            fieldId: 'Block Batch Link',
                                            path: 'Batch Number',
                                        },
                                    ]}
                                    useRadioLinks={
                                        DATA_MODEL_ID_MAP[this.props.title]
                                            .useRadioLinks
                                    }
                                    radioLinksInline={
                                        DATA_MODEL_ID_MAP[this.props.title]
                                            .radioLinksInline
                                    }
                                    ObjectFieldTemplate={
                                        Templates[this.props.title]
                                    }
                                />
                            </Col>
                        </Row>

                        {this.state.blockHeightModalOpen ? (
                            <Modal
                                isOpen={true}
                                toggle={this.closeBlockHeightModal}>
                                <ModalHeader
                                    toggle={this.closeBlockHeightModal}>
                                    Measured Block Heights
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                                        {autoSaveStatus ===
                                        SavingState.NOT_SAVED ? (
                                            <Badge color="secondary">
                                                NOT SAVED
                                            </Badge>
                                        ) : null}
                                        {autoSaveStatus ===
                                        SavingState.SAVING ? (
                                            <Badge color="secondary">
                                                SAVING...
                                            </Badge>
                                        ) : null}
                                        {autoSaveStatus ===
                                        SavingState.SAVED ? (
                                            <Badge color="success">SAVED</Badge>
                                        ) : null}
                                        {autoSaveStatus ===
                                        SavingState.SAVE_FAILED ? (
                                            <Badge color="danger">
                                                SAVE FAILED
                                            </Badge>
                                        ) : null}
                                    </p>
                                    <SchemaForm
                                        schema={
                                            this.measuredBlockHeightsValidator
                                        }
                                        editingRecord={this.blockHeightRecord}
                                        dataModels={this.state.dataModels}
                                        dataModel={this.state.dataModels.find(
                                            (d) =>
                                                d._id ===
                                                '5fc92f0ed02f3471d06e6ba8'
                                        )}
                                        hideArchive={true}
                                        timestampsToToday={true}
                                        onSubmit={this.onFormChange}
                                        onChange={this.onFormChange}
                                        manualDataLinkFilters={[
                                            {
                                                type: 'Text',
                                                logic: 'is',
                                                value: [
                                                    this.props.blockBatchRecord
                                                        .label,
                                                ],
                                                fieldId: 'Block Batch Link',
                                                path: 'Batch Number',
                                            },
                                        ]}
                                    />
                                </ModalBody>
                                <ModalFooter></ModalFooter>
                            </Modal>
                        ) : null}
                    </Fragment>
                ) : null}
            </Fragment>
        )
    }
}
