import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import DatePicker from 'react-datepicker'
import Switch from 'rc-switch'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {tileColor} from 'SDK/helpers'
import * as SDK from './sdk'

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            selectedAssets: [],
            assets: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            shifts: [],
        }
    }

    async componentWillMount() {
        let assets = await API.get('devices')
        assets = this.props.deviceIds
            ? assets.filter((asset) =>
                  this.props.deviceIds.includes(asset.deviceId)
              )
            : assets
        this.setState({
            assets,
        })

        this.setState({
            shifts: await API.get('shifts'),
        })
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    generate() {
        this.props.callback(this.state)
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }

    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }

    handleAssetChange(e) {
        this.setState({
            selectedAssets: e,
        })
    }

    render() {
        return (
            <Row className="mb-2">
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs="12" className="mb-4">
                                    <label>Assets:</label>
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isMulti
                                        name="assets"
                                        value={this.state.selectedAssets}
                                        onChange={(selectedAssets) =>
                                            this.setState({selectedAssets})
                                        }
                                        options={this.state.assets.map(
                                            (a, i) => {
                                                return {
                                                    label: a.name,
                                                    value: a.deviceId,
                                                    key: i,
                                                }
                                            }
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="2">
                                    <label>From:</label>
                                    <DatePicker
                                        selected={this.state.start}
                                        selectsStart
                                        startDate={this.state.start}
                                        endDate={this.state.end}
                                        onChange={this.handleStartChange}
                                        filterDate={(date) => {
                                            return moment() > date
                                        }}
                                        disabled={this.state.loading}
                                    />
                                </Col>
                                <Col xs="12" sm="2">
                                    <label>To:</label>
                                    <DatePicker
                                        selected={this.state.end}
                                        selectsEnd
                                        startDate={this.state.start}
                                        endDate={this.state.end}
                                        onChange={this.handleEndChange}
                                        filterDate={(date) => {
                                            return moment() > date
                                        }}
                                        disabled={this.state.loading}
                                    />
                                </Col>
                                {/*<Col xs="12" sm="2">
                  <label>Filter Data:</label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="filter"
                    value={this.state.selectedFilter}
                    onChange={(selectedFilter) => this.setState({ selectedFilter })}
                    options={[
                      { label: "Show all data", value: "all" },
                      { label: "Only weekdays", value: "weekdays" },
                      { label: "Only weekends", value: "weekends" },
                    ]}
                  />
                </Col>
                <Col xs="12" sm="2">
                  <label>Shift(s):</label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="shift"
                    value={this.state.selectedShift}
                    onChange={this.handleShiftChange}
                    options={[{ label: "All Shifts", value: "all" }].concat(
                      this.state.shifts.map((a, i) => {
                        return { label: a.name, value: a.name };
                      })
                    )}
                  />
                </Col>
                <Col xs="12" sm="2">
                  <label>Time Unit:</label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="timeUnit"
                    value={this.state.selectedTimeUnit}
                    onChange={(selectedTimeUnit) => this.setState({ selectedTimeUnit })}
                    options={[
                      { label: "Days", value: "day" },
                      { label: "Weeks", value: "isoWeek" },
                      { label: "Months", value: "month" },
                    ]}
                  />
                </Col>
                <Col xs="12" sm="2">
                  <label>Group by shift:</label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={this.state.byShift}
                    onChange={(byShift) => {
                      this.setState({ byShift });
                    }}
                    disabled={this.state.loading}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="2" sm="2" className="">
                  <label>Offset overnight shifts:</label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={this.state.offsetOvernightShift}
                    onChange={(offsetOvernightShift) => {
                      if (offsetOvernightShift) {
                        alert(
                          "WARNING: Offsetting overnight shifts in this report will cause discrepencies between this report and all other reporting tools. Accumine is not liable for issues resulting from misinformation caused by using this feature.\n\nEXAMPLE: with this feature enabled, an overnight shift starting from Sunday at 11pm and ending on Monday at 7am will have its data allocated on Monday rather than Sunday (when the shift started)."
                        );
                      }
                      this.setState({ offsetOvernightShift });
                    }}
                    disabled={this.state.loading}
                  />
                </Col>
                <Col xs="2" sm="2" className="">
                  <label>Use baseline targets:</label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={this.state.useBaselineTargets}
                    onChange={(useBaselineTargets) => {
                      if (useBaselineTargets) {
                        alert(
                          "For each asset, the first hourly target entry made will be used as the target value. THIS WILL ONLY BE USED FOR ASSET USING THE FIXED HOURLY TARGET SPEED LOSS METHOD."
                        );
                      }
                      this.setState({ useBaselineTargets });
                    }}
                    disabled={this.state.loading}
                  />
                </Col>*/}
                                <Col xs="8" sm="8" className="text-right">
                                    <Button
                                        color="primary"
                                        onClick={() => this.generate(null)}
                                        disabled={this.state.loading}>
                                        <i className="iconsmind-Arrow-Refresh" />{' '}
                                        Generate
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
