import React, {Fragment, Component} from 'react'
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import TIMERANGES from '../Common/TIMERANGES'

export default class Controls extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            timerangeDropdown: false,
            timerange: TIMERANGES[0],
            assetGroupDropdownOpen: false,
            assetGroup: null,
            scheduleDropdown: false,
            schedule: 'alltime',
        }

        const timerange = {
            name: TIMERANGES[0].name,
            timerange: TIMERANGES[0].timerange(),
        }

        this.props.onChange({timerange, assetGroup: null})
    }

    toggleTimerangeDropdown() {
        this.setState({timerangeDropdown: !this.state.timerangeDropdown})
    }

    toggleAssetGroupDropdown() {
        this.setState({
            assetGroupDropdownOpen: !this.state.assetGroupDropdownOpen,
        })
    }

    toggleScheduleDropdown() {
        this.setState({scheduleDropdown: !this.state.scheduleDropdown})
    }

    handleTimerangeSelection(timerange) {
        if (timerange.name !== this.state.timerange.name) {
            this.setState({timerange})
            this.props.onChange({
                timerange: {
                    name: timerange.name,
                    timerange: timerange.timerange(),
                },
                assetGroup: this.state.assetGroup,
                schedule: this.state.schedule,
            })
        }
    }

    handleAssetGroupSelection(assetGroup) {
        if (assetGroup !== this.state.assetGroup) {
            this.setState({assetGroup})
            this.props.onChange({
                timerange: {
                    name: this.state.timerange.name,
                    timerange: this.state.timerange.timerange(),
                },
                assetGroup,
                schedule: this.state.schedule,
            })
        }
    }

    handleScheduleSelection(schedule) {
        if (schedule !== this.state.schedule) {
            this.setState({schedule})
            this.props.onChange({
                timerange: {
                    name: this.state.timerange.name,
                    timerange: this.state.timerange.timerange(),
                },
                assetGroup: this.state.assetGroup,
                schedule: schedule,
            })
        }
    }

    render() {
        let scheduleMsg =
            this.state.schedule === 'alltime'
                ? 'All Time'
                : this.state.schedule === 'scheduled'
                ? 'Scheduled'
                : this.state.schedule.split('shift-')[1]
        return (
            <Row>
                <Col xs="12" sm="6" className="text-left">
                    <h2>
                        {this.state.assetGroup || 'All Assets'}
                        {' - '}
                        {this.state.timerange.name}
                        {' - '}
                        {scheduleMsg}
                    </h2>
                </Col>
                <Col xs="12" sm="6" className="text-right">
                    <div className="btn-group mr-2">
                        <Dropdown
                            isOpen={this.state.scheduleDropdown}
                            toggle={this.toggleScheduleDropdown}>
                            <DropdownToggle
                                caret
                                color="primary"
                                className="btn-xs"
                                outline
                                disabled={this.props.loading}>
                                Schedule
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem
                                    active={this.state.schedule === 'alltime'}
                                    disabled={this.state.schedule === 'alltime'}
                                    onClick={() => {
                                        this.handleScheduleSelection('alltime')
                                    }}>
                                    All time (24 hours / day)
                                </DropdownItem>
                                <DropdownItem
                                    active={this.state.schedule === 'scheduled'}
                                    disabled={
                                        this.state.schedule === 'scheduled'
                                    }
                                    onClick={() => {
                                        this.handleScheduleSelection(
                                            'scheduled'
                                        )
                                    }}>
                                    Scheduled time (all shifts)
                                </DropdownItem>
                                {[
                                    ...new Set(
                                        this.props.factory._shifts.map(
                                            (s) => s.name
                                        )
                                    ),
                                ].map((shift, idx) => {
                                    return (
                                        <DropdownItem
                                            key={idx}
                                            active={
                                                this.state.schedule ===
                                                'shift-' + shift
                                            }
                                            disabled={
                                                this.state.schedule ===
                                                'shift-' + shift
                                            }
                                            onClick={() => {
                                                this.handleScheduleSelection(
                                                    'shift-' + shift
                                                )
                                            }}>
                                            {shift}
                                        </DropdownItem>
                                    )
                                })}
                                <DropdownItem
                                    active={
                                        this.state.schedule === 'unscheduled'
                                    }
                                    disabled={
                                        this.state.schedule === 'unscheduled'
                                    }
                                    onClick={() => {
                                        this.handleScheduleSelection(
                                            'unscheduled'
                                        )
                                    }}>
                                    Unscheduled time (overtime)
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="btn-group mr-2">
                        <Dropdown
                            isOpen={this.state.timerangeDropdown}
                            toggle={this.toggleTimerangeDropdown}>
                            <DropdownToggle
                                caret
                                color="primary"
                                className="btn-xs"
                                outline
                                disabled={this.props.loading}>
                                Timerange
                            </DropdownToggle>
                            <DropdownMenu right>
                                {TIMERANGES.map((timerange, idx) => {
                                    return (
                                        <DropdownItem
                                            key={idx}
                                            active={
                                                this.state.timerange.name ===
                                                timerange.name
                                            }
                                            disabled={
                                                this.state.timerange.name ===
                                                timerange.name
                                            }
                                            onClick={() => {
                                                this.handleTimerangeSelection(
                                                    timerange
                                                )
                                            }}>
                                            {timerange.name}
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="btn-group">
                        <Dropdown
                            isOpen={this.state.assetGroupDropdownOpen}
                            toggle={this.toggleAssetGroupDropdown}>
                            <DropdownToggle
                                caret
                                color="primary"
                                className="btn-xs"
                                outline
                                disabled={this.props.loading}>
                                Assets
                            </DropdownToggle>
                            <DropdownMenu right>
                                {[
                                    ...new Set(
                                        this.props.factory._assets.map(
                                            (a) => a.groups[0]
                                        )
                                    ),
                                ].map((group, idx) => {
                                    return (
                                        <DropdownItem
                                            key={idx}
                                            active={
                                                this.state.assetGroup === group
                                            }
                                            disabled={
                                                this.state.assetGroup === group
                                            }
                                            onClick={() => {
                                                this.handleAssetGroupSelection(
                                                    group
                                                )
                                            }}>
                                            {group}
                                        </DropdownItem>
                                    )
                                })}
                                <DropdownItem divider />
                                <DropdownItem
                                    active={this.state.assetGroup === null}
                                    disabled={this.state.assetGroup === null}
                                    onClick={() => {
                                        this.handleAssetGroupSelection(null)
                                    }}>
                                    All Assets
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        )
    }
}
