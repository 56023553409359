import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Label,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'
import Sortable from 'react-sortablejs'
import {CompactPicker} from 'react-color'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import './checklist.css'

const DEFAULT_COLOR = 'rgba(0,0,100,0.9)'

var STATUS_DEFAULTS = [
    {id: 0, name: 'Step 1', color: DEFAULT_COLOR},
    {id: 1, name: 'Step 2', color: DEFAULT_COLOR},
    {id: 2, name: 'Step 3', color: DEFAULT_COLOR},
    {id: 3, name: 'Step 4', color: DEFAULT_COLOR},
]

var REPEAT_DEFAULTS = [
    {id: 0, name: 'Weekly'},
    {id: 1, name: 'Monthly'},
    {id: 2, name: 'Quarterly'},
    {id: 3, name: 'Yearly'},
    {id: 4, name: 'Daily'},
    {id: 5, name: 'No Repeat'},
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            name: '',
            description: '',
            color: '#fff',
            valid: false,
            message: false,
            checklists: this.props.checklists ? this.props.checklists : [],
            checklist: null,
            order: STATUS_DEFAULTS,
            status: {name: '', color: 'rgba(0,0,100,0.9)'},
            dropdownTypeOpen: false,
            repeatType: 'Weekly',
            equipmentOptions: [],
            equipmentHistory: [],
            devices: [],
        }

        this.getEquipmentOptions()
        this.fetchDevices()
    }

    dropdownTypeToggle() {
        this.setState({dropdownTypeOpen: !this.state.dropdownTypeOpen})
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
    }

    async save() {
        const timestamp = moment().toISOString()

        await API.post(
            'states',
            {
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                name: 'Checklist',
                metaData: [
                    {
                        name: 'setByUser',
                        value: JSON.parse(localStorage['userObject'])._id,
                    },
                    {
                        name: 'name',
                        value: this.state.name,
                    },
                    {
                        name: 'description',
                        value: this.state.description,
                    },
                    {
                        name: 'color',
                        value: this.state.color,
                    },
                    {
                        name: 'repeat',
                        value: this.state.repeatType,
                    },
                    {
                        name: 'asset',
                        value: this.state.deviceId,
                    },
                    {
                        name: 'equipment',
                        value: this.state.equipment,
                    },
                ],
                value: this.state.order,
            },
            2
        )
        this.props.onSave()
        this.props.toggleModal()
    }

    handleChangeComplete = (picker) => {
        this.setState({color: picker.hex})
    }

    addStatus = () => {
        let status = this.state.status
        let order = this.state.order
        status.id = order.length
        order.push(status)
        order.forEach((entry, i) => (entry.id = i))
        this.setState({order, status})
        this.clearStatus()
    }

    clearStatus = () => {
        this.setState({status: {id: 0, name: '', color: DEFAULT_COLOR}})
    }

    removeItem = (itemId) => {
        let order = this.state.order
        let filtered = order.filter(function (value, index, arr) {
            return value.id != itemId
        })
        this.setState({order: filtered})
    }

    async getEquipmentOptions() {
        let equipment = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Equipment',
                },
            },
            2
        )

        if (equipment) {
            this.setState({equipmentHistory: equipment})
        }
    }

    setEquipmentOptions(lineName) {
        if (
            !this.state.equipmentHistory[0] ||
            !this.state.equipmentHistory[0].store
        )
            return
        let equipmentOptions = []

        this.state.equipmentHistory[0].store.map((u) => {
            if (u.Line == lineName)
                equipmentOptions.push({
                    label: u.Asset_Desc,
                    value: u.Asset,
                    line: lineName,
                })
        })

        this.setState({equipmentOptions})
    }

    async fetchDevices() {
        let devices = await API.get('devices')
        if (!devices) {
            return alert('There was an error fetching data')
        }
        this.setState({devices})
    }

    getDeviceName(deviceId) {
        return this.state.devices.find((a) => a.deviceId === deviceId)
            ? this.state.devices.find((a) => a.deviceId === deviceId).name
            : 'N/A'
    }

    renderItem(item) {
        return (
            <div
                key={item.id}
                data-id={item.id}
                className="container"
                style={{marginBottom: 25}}>
                <div className="round">
                    <input type="checkbox" id={item.id} />
                    <Label for={item.id}></Label>
                    <span
                        style={{
                            marginLeft: 25,
                            lineHeight: '28px',
                            verticalAlign: 'middle',
                        }}>
                        {item.name}
                    </span>
                    <span
                        color="none"
                        style={{
                            float: 'right',
                            height: '100%',
                            cursor: 'pointer',
                            color: '#AAA',
                            lineHeight: '28px',
                            verticalAlign: 'middle',
                        }}
                        onClick={() => {
                            this.removeItem(item.id)
                        }}>
                        <i
                            className="simple-icon-trash"
                            style={{color: '#555'}}
                        />
                    </span>
                </div>
            </div>
        )
    }

    render() {
        const {modal, toggleModal} = this.props
        const {checklist} = this.state

        return (
            <Fragment>
                <Modal size="lg" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Create Checklist
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <strong>Name list</strong>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(input) => {
                                        let name = this.state.name
                                        name = input.target.value
                                        this.setState({name})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <strong>Description of list</strong>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={(input) => {
                                        let description = this.state.description
                                        description = input.target.value
                                        this.setState({description})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <AssetPicker
                                    cb={(field, deviceId) => {
                                        this.setState({deviceId})
                                        this.setEquipmentOptions(
                                            this.getDeviceName(deviceId)
                                        )
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                Equipment:
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="environment"
                                    value={this.state.equipment}
                                    onChange={(equipment) => {
                                        this.setState({equipment})
                                    }}
                                    options={this.state.equipmentOptions}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <Label
                                    for="ButtonDropdown"
                                    style={{marginTop: 7.5, marginBottom: 7.5}}>
                                    Repeat this checklist?
                                </Label>
                                <br />
                                <ButtonDropdown
                                    isOpen={this.state.dropdownTypeOpen}
                                    toggle={this.dropdownTypeToggle}>
                                    <Button id="caret" color="primary">
                                        {this.state.repeatType}
                                    </Button>
                                    <DropdownToggle caret color="primary" />
                                    <DropdownMenu>
                                        {REPEAT_DEFAULTS.map((item) => {
                                            return (
                                                <DropdownItem
                                                    key={item.id}
                                                    onClick={() => {
                                                        this.setState({
                                                            repeatType:
                                                                item.name,
                                                        })
                                                    }}>
                                                    {item.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15}}>
                            <Col>
                                <Label
                                    for="CompactPicker"
                                    style={{marginTop: 7.5, marginBottom: 7.5}}>
                                    Choose Color:
                                </Label>
                                <br />
                                <CompactPicker
                                    color={this.state.color}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" className="mb-4">
                                <strong>Re-order the checklist:</strong>
                                <hr />
                                <Sortable
                                    list={this.state.order}
                                    setList={(newState) =>
                                        this.setState({order: newState})
                                    }
                                    animation={200}
                                    delayOnTouchStart={true}
                                    delay={2}
                                    onChange={(newOrder, sort) => {
                                        const newItems = _.sortBy(
                                            this.state.order,
                                            (item) => {
                                                return newOrder.indexOf(
                                                    item.id.toString()
                                                )
                                            }
                                        )
                                        newItems.forEach(
                                            (entry, i) => (entry.id = i)
                                        )
                                        this.setState({order: newItems})
                                    }}>
                                    {this.state.order.map((item) =>
                                        this.renderItem(item)
                                    )}
                                </Sortable>
                            </Col>
                            <Col xs="6" className="mb-4">
                                <strong>Create new checklist item:</strong>
                                <hr />
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.status.name}
                                    onChange={(name) => {
                                        let status = this.state.status
                                        status.name = name.target.value
                                        this.setState({status})
                                    }}
                                />
                                <Button
                                    color="secondary"
                                    disabled={false}
                                    onClick={this.addStatus}
                                    style={{marginTop: 15}}>
                                    Add Checklist Item
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={this.save}
                            style={{marginTop: 15}}>
                            Save Checklist
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
