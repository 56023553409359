const BaseTransform = require('./base-transform').default

/** @class
 * Passes the input to output unchanged except for assigning an index
 * @param {Number} i - The index of the output values produced by this transform
 * (used in the next transform in the pipeline)
 */
class Input extends BaseTransform {
    constructor(id) {
        super()
        this._id = id
        this._lastTimestamp = -1
    }
    _transform(chunk, callback) {
        try {
            for (let i = 0, l = chunk.length; i < l; i++) {
                if (this._lastTimestamp > chunk[i].timestamp) {
                    this.emit(
                        'error',
                        'Timestamp ' +
                            chunk[i].timestamp +
                            ' before the current processed time ' +
                            this._lastTimestamp +
                            ', discarding.'
                    )
                    this.emit('error', chunk[i])
                    continue
                } else {
                    this._lastTimestamp = chunk.timestamp
                }

                chunk[i].id = this._id
                this.push(chunk[i])
            }
        } catch (error) {
            this.emit('error', error)
        }
        callback()
    }
    toJSON() {
        let stringify = {}
        stringify._id = this._id
        stringify._lastTimestamp = this._lastTimestamp
        return stringify
    }
    static fromJSON(obj) {
        let trans = new Input(obj._id)
        if (obj._lastTimestamp) {
            trans._lastTimestamp = obj._lastTimestamp
        }
        return trans
    }
}

export default Input
