import React, {Component, Fragment} from 'react'
import {Row, Col, InputGroup, Input, InputGroupAddon, Button} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import {iteratePlaceholderName} from 'SDK/helpers'
import LibraryModal from './libraryModal'
import ViewPermissionsModal from './ViewPermissionsModal'
import GenericModuleLibraryModal from './GenericModuleLibraryModal'
import ViewSettings from './ViewSettings'

export default class TemplateManager extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            message: '',
            ack: '',
            color: 'rgb(215,215,215)',
            valid: true,
            name: this.props.view,
            layout: [],
            generic: [],
            components: [],
            libraryModal: false,
            permissionsModal: false,
            availablePermissions: [],
            permissions: [],
            showGenericModuleLibraryModal: false,
        }
    }

    update() {
        this.props.updateView(
            this.state.name,
            this.props.layout,
            this.state.generic,
            this.state.permissions,
            false
        )

        this.setState({ack: 'Your changes have been saved'}, () => {
            let self = this
            setTimeout(() => {
                self.setState({ack: ''})
            }, 4000)
        })
    }

    async remove() {
        try {
            await API.remove('dashboards/' + this.props._id, 2)
        } catch (error) {
            console.log(error)
            return alert('There was an error deleting this dashboard')
        }
    }

    toggleLibraryModal() {
        this.setState({
            libraryModal: !this.state.libraryModal,
        })
    }

    toggleGenericModuleLibraryModal() {
        this.setState({
            showGenericModuleLibraryModal:
                !this.state.showGenericModuleLibraryModal,
        })
    }

    togglePermissionsModal() {
        this.setState({
            permissionsModal: !this.state.permissionsModal,
        })
    }

    loadCustomComponent(component) {
        this.props.addCustomItem(component)
    }

    async loadGenericComponent(component) {
        let obj = {
            component: component.name,
            props: {},
        }

        for (let p in component.props) {
            obj.props[p] = component.props[p]
        }

        await API.post(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId +
                '?default=true',
            obj,
            2
        )

        delete obj.props
        this.props.addGenericItem(obj)
    }

    setPermissions(permissions) {
        this.setState({permissions})
        this.props.updateView(
            this.state.name,
            this.props.layout,
            this.state.generic,
            permissions
        )

        this.togglePermissionsModal()
    }

    switchGenericModule() {
        this.props.onRemoveItem(this.props.layout[0].i)
    }

    async componentWillMount() {
        const view = this.props.environment.sections
            .find((section) => section.id === this.props.sectionId)
            .views.find((view) => view.id === this.props.viewId)
        this.views = this.props.environment.sections.find(
            (section) => section.id === this.props.sectionId
        ).views

        const users = await API.get('users', 2),
            roles = [
                ...new Set(users.map((user) => user.role).filter((p) => p)),
            ]

        this.setState({
            name: view.name,
            layout: view.layout,
            generic: view.generic,
            availablePermissions: roles,
            permissions: view.permissions,
            type: view.type,
        })
    }

    async routeToInsightEngine() {
        await this.props.updateView(
            this.state.name,
            this.props.layout,
            this.state.generic,
            this.state.permissions,
            false
        )

        this.props.history.push(
            '/app/core/insight-engine?environmentId=' +
                this.props.environmentId +
                '&sectionId=' +
                this.props.sectionId +
                '&viewId=' +
                this.props.viewId +
                '&visualId=new'
        )
    }

    render() {
        const custom = this.state.type === 'custom',
            emptyGeneric =
                this.state.type === 'generic' && this.props.layout.length === 0,
            showWarningMessage = this.state.message !== '',
            showSaveAckMessage = this.state.ack !== ''

        return (
            <Fragment>
                {showWarningMessage && (
                    <p style={{color: '#dc3545', textAlign: 'center'}}>
                        {this.state.message}
                    </p>
                )}
                {showSaveAckMessage && (
                    <p style={{color: '#28a745', textAlign: 'center'}}>
                        {this.state.ack}
                    </p>
                )}
                <InputGroup className="mb-3">
                    <Input
                        value={this.state.name}
                        style={{
                            borderColor: this.state.color,
                            borderTop: 'transparent',
                            borderRight: 'transparent',
                            borderLeft: 'transparent',
                            backgroundColor: 'transparent',
                        }}
                        onChange={(name) => {
                            let v = name.target.value,
                                color = 'rgb(215,215,215)',
                                valid = true,
                                message = ''
                            if (v === '') {
                                color = '#dc3545'
                                valid = false
                            } else if (
                                this.views.find((view) => view.name === v)
                            ) {
                                color = '#dc3545'
                                valid = false
                                message =
                                    'A view with this name already exists.'
                            }
                            this.setState({
                                name: v,
                                valid: valid,
                                borderColor: color,
                                message: message,
                            })
                        }}
                    />
                    <InputGroupAddon addonType="append">
                        {custom && (
                            <Button
                                outline
                                color="info"
                                onClick={this.routeToInsightEngine}>
                                Chart Builder
                            </Button>
                        )}
                        {!custom && (
                            <Button
                                outline
                                color="info"
                                onClick={this.switchGenericModule}>
                                Change Module
                            </Button>
                        )}
                        {!custom && (
                            <ViewSettings
                                reload={this.props.reload}
                                manager={true}
                                environmentId={this.props.environmentId}
                                sectionId={this.props.sectionId}
                                viewId={this.props.viewId}
                                component={this.props.component}
                            />
                        )}
                        <Button
                            outline
                            color="info"
                            onClick={this.togglePermissionsModal}>
                            Permissions
                        </Button>
                        <Button
                            outline
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.update}>
                            Save
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <LibraryModal
                    toggleModal={this.toggleLibraryModal}
                    modal={this.state.libraryModal}
                    loadCustomComponent={this.loadCustomComponent}
                    loadGenericComponent={this.loadGenericComponent}
                />

                {this.state.permissionsModal ? (
                    <ViewPermissionsModal
                        toggleModal={this.togglePermissionsModal}
                        modal={this.state.permissionsModal}
                        permissions={this.state.permissions}
                        availablePermissions={this.state.availablePermissions}
                        setPermissions={this.setPermissions}
                    />
                ) : null}

                {emptyGeneric ? (
                    <GenericModuleLibraryModal
                        toggleModal={this.toggleGenericModuleLibraryModal}
                        modal={true}
                        loadGenericComponent={this.loadGenericComponent}
                    />
                ) : null}
            </Fragment>
        )
    }
}
