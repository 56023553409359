import React, {Component, Fragment} from 'react'
import {Button, Row, Col} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import EditModal from './edit'

const columns = [
    {
        Header: 'Section',
        accessor: 'sectionName',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Permissions',
        accessor: 'permissionsString',
    },
]

export default class PermissionManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'PermissionManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        environmentId: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            views: [],
            env: null,
            editingView: null,
        }
    }
    async fetchEnvironments() {
        const envs = await API.get('environments', 2),
            users = await API.get('users', 2),
            userRoles = [
                ...new Set(users.map((user) => user.role).filter((p) => p)),
            ],
            env = envs.find((e) => e._id === this.props.environmentId),
            sections = env.sections

        let views = [],
            permissionRoles = []

        sections.forEach((section) => {
            section.views.forEach((view) => {
                if (!view.divider) {
                    let cloned = Object.assign({}, view)
                    cloned.sectionName = section.name
                    cloned.sectionId = section.id
                    views.push(cloned)
                    if (cloned.permissions)
                        permissionRoles = permissionRoles.concat(
                            cloned.permissions
                        )
                }
            })
        })

        let roles = [...new Set(userRoles.concat(permissionRoles))]

        this.setState({views, env, roles})
    }

    toggleModal(modalName, view) {
        let state = this.state
        state.editingView = view
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 12,
                onClickFn: (view) => this.toggleModal('editModal', view),
                actionName: 'Edit Permissions',
                buttonColor: 'primary',
            },
        ]
    }

    dataTableFormatter(view) {
        view.permissions = view.permissions.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        })
        view.permissionsString = view.permissions.join(', ')
        return view
    }

    editorModalAttributes(view) {
        return [['Name', view.name]]
    }

    async setPermissions(permissions) {
        let env = Object.assign({}, this.state.env),
            section = env.sections.find(
                (s) => s.id === this.state.editingView.sectionId
            )

        section.views.find(
            (v) => v.id === this.state.editingView.id
        ).permissions = permissions

        await API.patch('environments/' + env._id, env, 2)

        this.toggleModal('editModal')
    }

    componentWillMount() {
        this.subscriber.add(this.fetchEnvironments, 3000, 'fetchEnvironments()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        return (
            <Fragment>
                <p className="text-center">
                    Please note that the "Administrator" is granted default
                    permission to all applications, sections and views.
                </p>
                <CRUD
                    uniqueElementId="id"
                    emptyCrudMainText="No Views Found"
                    emptyCrudSubText=""
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.views}
                    editorModalTitle="View Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        views={this.state.views}
                        permissions={this.state.editingView.permissions}
                        availablePermissions={this.state.roles}
                        setPermissions={this.setPermissions}
                    />
                ) : null}
            </Fragment>
        )
    }
}
