import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
} from 'reactstrap'
import moment from 'moment'
import {Textfit} from 'react-textfit'
import autobind from 'auto-bind'

// Accumine SDK imports
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '-',
            elapsed: '-',
            color: 'rgb(35, 34, 35)',
        }
    }
    async componentWillMount() {
        this.subscriber.add(
            this.updateActiveStateElapsedTime,
            1000,
            'RealTime_DownTimeDisplay_updateActiveStateElapsedTime'
        )
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    async updateActiveStateElapsedTime() {
        let name = this.props.data.name
        if (this.props.data.name === 'Downtime') {
            const downtimeReason = this.props.data.downtimeReason
                ? [this.props.data.downtimeReason]
                : []
            /*const downtimeReason = await API.post('historical/raw', {
        query: {
          deviceId: this.props.data.deviceId,
          name: 'Downtime Reason',
          timeEnd: null
        }
      }, 2);*/

            if (downtimeReason.length) {
                name = downtimeReason[0].value
            }
        }

        let color = tileColor(name),
            elapsed = secondsToHHMMSS(
                moment().diff(moment(this.props.data.timestamp), 'seconds')
            )
        this.setState({elapsed, name, color})
    }

    render() {
        return (
            <Fragment>
                <Col xs="12" sm="2" className="mb-4">
                    <Card
                        style={{
                            background: this.state.color,
                            opacity: this.props.data.opacity,
                        }}>
                        <CardHeader className="mt-2" style={{color: '#fff'}}>
                            <h3 style={{textAlign: 'center'}}>
                                <Textfit
                                    mode="single"
                                    forceSingleModeWidth={false}>
                                    {this.props.data.deviceName}
                                </Textfit>
                            </h3>
                        </CardHeader>
                        <CardBody style={{padding: '0px', margin: '0px'}}>
                            <Row>
                                <Col xs="12" sm="12" className="text-center">
                                    <h1 style={{color: '#fff'}}>
                                        {this.state.elapsed}
                                    </h1>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <p style={{color: '#fff', textAlign: 'center'}}>
                                <Textfit
                                    mode="single"
                                    forceSingleModeWidth={false}>
                                    {this.state.name}
                                </Textfit>
                            </p>
                        </CardFooter>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}
