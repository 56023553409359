import {Component} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import {
    Row,
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledButtonDropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonGroup,
    Alert,
    ModalFooter,
} from 'reactstrap'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import dot from 'dot-object'
import moment from 'moment'

import * as API from 'SDK/api'
import Form from '../../common/Form'
import FilterModal from '../../SchemaViewer/modals/Filter'
import ModelTree from '../../common/ModelTree'

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)

// NOTE: fetches a maximum of 300 records
class CalendarViewer extends Component {
    static propTypes = {
        // dataModelIds of datamodels we're loading
        dataModelIds: PropTypes.arrayOf(PropTypes.string).isRequired,

        // GET v2/data-models
        dataModels: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string,
                name: PropTypes.string,
                fields: PropTypes.arrayOf(PropTypes.object),
                parentDataModelId: PropTypes.any,
                parentFieldId: PropTypes.any,
            })
        ).isRequired,

        // optional filter for data, same format as pagination filter field
        filter: PropTypes.arrayOf(PropTypes.object),

        // optional error handling if fetching data fails
        onDataFetchError: PropTypes.func,

        // optional error handling if editing row fails
        onEditError: PropTypes.func,

        // optional error handling if validator fetching fails
        onGetValidatorError: PropTypes.func,

        // optional error handling for calendar setting fetching fails (System/Calendar Settings, needed for event color/what field to use for title)
        onGetSettingsError: PropTypes.func,

        // optional width for component, by default it tries to fit entire parent element
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // optional height for component, by default it tries to fit the entire screen
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // by default, users can add, update & delete rows, this provides ability to prevent
        // one or more of those actions
        permissions: PropTypes.shape({
            create: PropTypes.bool,
            update: PropTypes.bool,
            delete: PropTypes.bool,
            filter: PropTypes.bool,
            // the prop below prevents the user from changing the resource mode
            resourceMode: PropTypes.bool,
        }),

        // resources.enabled = true will group events by specified resources in week/day views
        // resources.resourceMap is an object specifying the resource field path for each model
        // e.g. { 'dataModelId123': 'deviceId', 'dataModelId456': 'assetLink.deviceId' }
        // available resource values are gathered when fetching data, if record does not have a value
        // in the resource path, it will show up as "Not Assigned" in the calendar
        resources: PropTypes.shape({
            enabled: PropTypes.bool,
            resourceMap: PropTypes.object,
        }),
    }

    static defaultProps = {
        onDataFetchError: console.log,
        onEditError: console.log,
        onGetValidatorError: console.log,
        onGetSettingsError: console.log,
        width: '100%',
        height: 0,
        permissions: {
            create: true,
            update: true,
            delete: true,
            filter: true,
            resourceMode: true,
        },
        filter: [],
        resources: {
            enabled: false,
            resourceMap: {},
        },
    }

    constructor(props) {
        super(props)
        autoBind(this)

        this.height =
            this.props.height || document.documentElement.offsetHeight * 0.72
        this.dataModels = this.props.dataModels.filter((a) =>
            this.props.dataModelIds.includes(a._id)
        )
        if (this.dataModels.length !== this.props.dataModelIds.length) {
            throw new Error('Data model(s) not found.')
        }

        this.state = {
            loading: false,
            ready: true,
            fieldTypes: [],
            data: [],
            editingRow: null,
            filter: this.props.filter,
            selectedDataModelId: null,
            resourceMode: this.props.resources.enabled,
            resourceMap: this.props.resources.resourceMap,
            resources: [],
            view: 'month',
            range: [
                moment().startOf('month').valueOf(),
                moment().endOf('month').valueOf(),
            ],
        }
    }

    async fetchCalendarSettings() {
        let id = this.props.dataModels.find(
            (model) => model.name === 'System/Calendar Settings'
        )._id
        if (!id) {
            this.props.onGetSettingsError(
                `Cannot find data model with name "System/Calendar Settings"`
            )
            return
        }

        let settings = []
        try {
            settings = await API.post(
                `data-models/${id}/paginate`,
                {
                    filter: [
                        {
                            path: 'model_id',
                            type: 'Text',
                            logic: 'is',
                            value: this.dataModels.map((m) => m._id),
                        },
                    ],
                    sort: {},
                    limit: 300,
                },
                2
            )
            if (settings.count !== this.dataModels.length) {
                throw new Error(
                    'Not every selected data model has a settings record.'
                )
            }
            settings = settings.result.results
        } catch (error) {
            this.props.onGetSettingsError(error)
        }

        this.setState({settings})
    }

    async fetchData() {
        try {
            this.designatedFieldMap = {}
            let resources = [],
                data = [],
                filter = this.state.filter.filter(
                    (f) => f.dataModelId === dataModelId
                )
            for (let dataModelId of this.props.dataModelIds) {
                const model = this.props.dataModels.find(
                    (a) => a._id === dataModelId
                )
                if (model.modelType === 'State') {
                    this.designatedFieldMap[dataModelId] = {
                        timeStart: model.fields.find(
                            (f) =>
                                !f.archived && f.type === 'DesignatedTimeStart'
                        ).name,
                        timeEnd: model.fields.find(
                            (f) => !f.archived && f.type === 'DesignatedTimeEnd'
                        ).name,
                    }
                    filter = filter.concat([
                        {
                            dataModelId,
                            path: this.designatedFieldMap[dataModelId]
                                .timeStart,
                            type: 'DesignatedTimeStart',
                            logic: 'before',
                            value: this.state.range[1],
                        },
                        {
                            dataModelId,
                            path: this.designatedFieldMap[dataModelId].timeEnd,
                            type: 'DesignatedTimeEnd',
                            logic: 'after',
                            value: this.state.range[0],
                        },
                    ])
                }
                if (model.modelType === 'Event') {
                    this.designatedFieldMap[dataModelId] = {
                        timestamp: model.fields.find(
                            (f) =>
                                !f.archived && f.type === 'DesignatedTimestamp'
                        ).name,
                    }
                    filter.push({
                        dataModelId,
                        path: this.designatedFieldMap[dataModelId].timestamp,
                        type: 'DesignatedTimestamp',
                        logic: 'between',
                        value: this.state.range,
                    })
                }
                this.designatedFieldMap[dataModelId].title =
                    this.state.settings.find(
                        (s) => s.model_id == dataModelId
                    ).title_field_path
                this.designatedFieldMap[dataModelId].color =
                    this.state.settings.find(
                        (s) => s.model_id == dataModelId
                    ).event_background_color
            }

            data = await Promise.all(
                this.props.dataModelIds.map((dataModelId) => {
                    return API.post(
                        `data-models/${dataModelId}/paginate`,
                        {
                            filter,
                            sort: {},
                            limit: 300,
                        },
                        2
                    )
                })
            )
            data = data
                .flat()
                .map((a) => a.result.results)
                .flat()
            data = data.map((row) => {
                row.id = row._id
                row.title = dot.pick(
                    this.designatedFieldMap[row.dataModelId].title,
                    row
                )

                if (this.state.resourceMode) {
                    row['@@resourceId@@'] =
                        dot.pick(
                            this.state.resourceMap[row.dataModelId],
                            row
                        ) || 'Not Assigned'
                    if (
                        !resources.find(
                            (resource) =>
                                resource['@@resourceId@@'] ===
                                row['@@resourceId@@']
                        )
                    ) {
                        resources.push({
                            '@@resourceId@@': row['@@resourceId@@'],
                        })
                    }
                }

                return row
            })

            this.setState({data, resources})
        } catch (error) {
            this.props.onDataFetchError(error)
        }
    }

    async fetchFieldTypes() {
        this.setState({
            fieldTypes: await API.get('data-models/field-types', 2),
        })
    }

    toggleFilterModal() {
        this.setState({filterModalOpen: !this.state.filterModalOpen})
    }

    openFilter(selectedDataModelId) {
        this.setState({selectedDataModelId}, this.toggleFilterModal)
    }

    toggleFormModal() {
        const {formModalOpen, selectedDataModelId} = this.state
        this.setState({
            formModalOpen: !formModalOpen,
            selectedDataModelId: formModalOpen ? null : selectedDataModelId,
        })
    }

    async openForm(selectedDataModelId, editingRow = undefined) {
        this.setState({loading: true})
        try {
            const formValidator = await API.get(
                `data-models/${selectedDataModelId}/validator`,
                2
            )
            this.setState({
                formValidator,
                formModalOpen: true,
                selectedDataModelId,
                editingRow,
            })
        } catch (error) {
            this.props.onGetValidatorError(error)
        }
        this.setState({loading: false})
    }

    closeForm() {
        this.fetchData()
        this.toggleFormModal()
    }

    async updateEvent({event, start, end}) {
        if (!this.props.permissions.update) return alert('Invalid permission')
        this.setState({loading: true})

        try {
            const fieldMap = this.designatedFieldMap[event.dataModelId]
            event[fieldMap.timestamp || fieldMap.timeStart] =
                moment(start).toISOString()
            event[fieldMap.timestamp || fieldMap.timeEnd] =
                moment(end).toISOString()

            await API.patch(
                `data-models/${event.dataModelId}/edit-record`,
                event,
                2
            )
            this.fetchData()
        } catch (error) {
            this.props.onEditError(error)
        }

        this.setState({loading: false})
    }

    handleViewChange(view) {
        this.setState({view})
    }

    setupResourceMode() {
        let {resourceMap} = this.state
    }

    async componentDidMount() {
        await this.fetchCalendarSettings()
        this.fetchFieldTypes()
        this.fetchData()
    }

    render() {
        return (
            <div style={{height: this.height, width: this.props.width}}>
                <Row className="mb-1">
                    <Col xs="6">
                        {this.props.permissions.create ? (
                            <UncontrolledDropdown>
                                <DropdownToggle caret size="xs" color="success">
                                    <i className="simple-icon-plus" /> Add Event
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.dataModels.map((model, idx) => {
                                        return (
                                            <DropdownItem
                                                key={idx}
                                                onClick={() =>
                                                    this.openForm(model._id)
                                                }>
                                                {model.name}
                                            </DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        ) : null}
                    </Col>
                    <Col xs="6" className="text-right">
                        <ButtonGroup>
                            {this.props.permissions.filter ? (
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                        caret
                                        size="xs"
                                        color="default">
                                        <i className="iconsmind-Filter-2" />{' '}
                                        {this.state.filter.length} filter
                                        {this.state.filter.length === 1
                                            ? ''
                                            : 's'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {this.dataModels.map((model, idx) => {
                                            const len =
                                                this.state.filter.filter(
                                                    (f) =>
                                                        f.dataModelId ===
                                                        model._id
                                                ).length
                                            return (
                                                <DropdownItem
                                                    key={idx}
                                                    onClick={() =>
                                                        this.openFilter(
                                                            model._id
                                                        )
                                                    }>
                                                    {model.name}{' '}
                                                    {len ? `(${len})` : ''}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            ) : null}

                            {this.props.permissions.resourceMode ? (
                                <>
                                    {this.state.resourceMode ? (
                                        <Button
                                            color="danger"
                                            size="xs"
                                            onClick={() => {
                                                this.setState({
                                                    resourceMode: false,
                                                })
                                            }}>
                                            Disable Resource Mode
                                        </Button>
                                    ) : (
                                        <Button
                                            color="default"
                                            size="xs"
                                            onClick={() => {
                                                this.setState({
                                                    resourceMode: true,
                                                    resourceModalOpen: true,
                                                })
                                            }}>
                                            Enable Resource Mode
                                        </Button>
                                    )}
                                </>
                            ) : null}
                            <Button
                                color="default"
                                size="xs"
                                onClick={this.fetchData}>
                                <i className="simple-icon-refresh" />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row style={{height: '90%'}}>
                    <Col style={{height: '100%'}}>
                        <DragAndDropCalendar
                            popup
                            localizer={localizer}
                            events={this.state.data}
                            startAccessor={(row) => {
                                const fieldMap =
                                    this.designatedFieldMap[row.dataModelId]
                                return moment(
                                    row[
                                        fieldMap.timestamp || fieldMap.timeStart
                                    ]
                                ).toDate()
                            }}
                            endAccessor={(row) => {
                                const fieldMap =
                                    this.designatedFieldMap[row.dataModelId]
                                return moment(
                                    row[fieldMap.timestamp || fieldMap.timeEnd]
                                ).toDate()
                            }}
                            style={{height: this.height}}
                            onSelectEvent={(editingRow) => {
                                if (!this.props.permissions.update) return
                                this.openForm(
                                    editingRow.dataModelId,
                                    editingRow
                                )
                            }}
                            onEventResize={this.updateEvent}
                            onEventDrop={this.updateEvent}
                            eventPropGetter={(
                                event,
                                start,
                                end,
                                isSelected
                            ) => {
                                return {
                                    style: {
                                        backgroundColor:
                                            this.designatedFieldMap[
                                                event.dataModelId
                                            ].color,
                                    },
                                }
                            }}
                            resources={
                                this.state.resourceMode
                                    ? this.state.resources
                                    : undefined
                            }
                            resourceIdAccessor="@@resourceId@@"
                            resourceTitleAccessor="@@resourceId@@"
                            resourceAccessor="@@resourceId@@"
                            onView={this.handleViewChange}
                            onRangeChange={(newRange) => {
                                let range = [0, 0]
                                if (newRange.constructor === Array) {
                                    range = [
                                        moment(newRange[0]).valueOf(),
                                        moment(newRange[newRange.length - 1])
                                            .endOf('day')
                                            .valueOf(),
                                    ]
                                } else {
                                    range = [
                                        moment(newRange.start).valueOf(),
                                        moment(newRange.end)
                                            .endOf('day')
                                            .valueOf(),
                                    ]
                                }
                                this.setState({range})
                            }}
                        />
                    </Col>
                </Row>

                {this.state.loading && !this.state.ready ? (
                    <>Loading data...</>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}

                {this.state.formModalOpen ? (
                    <Modal
                        size="lg"
                        isOpen={true}
                        toggle={this.toggleFormModal}>
                        <ModalHeader toggle={this.toggleFormModal}>
                            {this.state.editingRow ? 'Edit' : 'Add'} Event
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                schema={this.state.formValidator}
                                dataModel={this.props.dataModels.find(
                                    (a) =>
                                        a._id === this.state.selectedDataModelId
                                )}
                                dataModels={this.props.dataModels}
                                editingRecord={this.state.editingRow}
                                onSubmit={this.closeForm}
                            />
                        </ModalBody>
                    </Modal>
                ) : null}

                {this.state.filterModalOpen ? (
                    <FilterModal
                        open={true}
                        toggle={() =>
                            this.setState({
                                filterModalOpen: !this.state.filterModalOpen,
                            })
                        }
                        dataModel={this.props.dataModels.find(
                            (a) => a._id === this.state.selectedDataModelId
                        )}
                        dataModels={this.props.dataModels}
                        updateFilters={(filter) => {
                            filter = filter.map((f) => {
                                f.dataModelId = this.state.selectedDataModelId
                                return f
                            })
                            this.setState({filter}, () => {
                                this.fetchData()
                            })
                        }}
                        filters={this.state.filter.filter(
                            (f) =>
                                f.dataModelId === this.state.selectedDataModelId
                        )}
                        refresh={() => {}}
                        fieldTypes={this.state.fieldTypes}
                    />
                ) : null}

                {this.state.resourceModalOpen ? (
                    <Modal
                        size="lg"
                        isOpen={true}
                        toggle={() => {
                            this.setState({
                                resourceModalOpen:
                                    !this.state.resourceModalOpen,
                                resourceMode: false,
                            })
                        }}>
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    resourceModalOpen:
                                        !this.state.resourceModalOpen,
                                    resourceMode: false,
                                })
                            }}>
                            Resource Configuration
                        </ModalHeader>
                        <ModalBody>
                            <Alert color="primary">
                                In Resource Mode, the "Week" and "Day" views
                                group events according to the selected
                                resources. Ensure the same resource type is
                                chosen across different data models for
                                consistent calendar result.
                            </Alert>
                            {this.dataModels.map((model) => {
                                return (
                                    <>
                                        <h5>
                                            <b>{model.name}</b>
                                        </h5>
                                        <ModelTree
                                            showMap={(item) =>
                                                ['SingleSubModel', 'ForeignID']
                                                    .concat(
                                                        this.state.fieldTypes
                                                            .filter(
                                                                (t) =>
                                                                    t.groupable
                                                            )
                                                            .map((t) => t.type)
                                                    )
                                                    .includes(item.type)
                                            }
                                            onSelect={(
                                                selectedKeys,
                                                {selectedNodes}
                                            ) => {
                                                let {resourceMap} = this.state
                                                resourceMap[model._id] =
                                                    selectedNodes[0].path
                                                this.setState({resourceMap})
                                            }}
                                            dataModel={model}
                                            dataModels={this.props.dataModels}
                                            includeJoins={false}
                                            excludeJoinPrefixes={true}
                                        />
                                    </>
                                )
                            })}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={() => {
                                    this.setState(
                                        {
                                            resourceModalOpen: false,
                                        },
                                        this.fetchData
                                    )
                                }}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
            </div>
        )
    }
}

export default CalendarViewer
