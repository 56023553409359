import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Collapse,
    ButtonGroup,
    Button,
    Badge,
} from 'reactstrap'
import PropTypes from 'prop-types'
import {Scrollbars} from 'react-custom-scrollbars'
import autobind from 'auto-bind'

import Subscriber from 'SDK/subscriber'
import SCHEDULES from '../Common/SCHEDULES'

export default class extends Component {
    static propTypes = {
        devices: PropTypes.array,
        assets: PropTypes.array,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            accordion: [false, false, false],
        }
    }

    toggleAccordion(tab) {
        const prevState = this.state.accordion
        const state = prevState.map((x, index) => (tab === index ? !x : false))
        this.setState({
            accordion: state,
        })
    }

    makeElement(dataset) {
        const active =
            this.props.activeDataset &&
            this.props.activeDataset.datasetId === dataset.datasetId

        let scheduleStr = dataset.schedule
        const scheduleObj = SCHEDULES.find((s) => s.value === dataset.schedule)
        if (scheduleObj) scheduleStr = scheduleObj.label

        let assetString = ''
        if (dataset.assets.length === this.props.assets.length) {
            assetString = 'All assets'
        } else {
            const assets = this.props.assets.filter((a) =>
                    dataset.assets.find((d) => d === a.deviceId)
                ),
                groups = [...new Set(assets.map((a) => a.group()))]

            if (groups.length > 1) {
                assetString = assets.length + ' mixed assets'
            } else {
                assetString = assets.length + ' ' + groups[0]
            }
        }

        return (
            <Row
                key={dataset.datasetId}
                style={{
                    cursor: 'pointer',
                    borderBottom: '1px solid grey',
                }}
                onClick={() => {
                    this.props.handleDatasetClick(dataset)
                }}>
                <Col
                    xs="1"
                    className="text-center"
                    style={{
                        backgroundColor: active ? '#145388' : '#8f8f8f',
                        paddingRight: 0,
                    }}
                />
                <Col xs="11">
                    <Row>
                        <Col>
                            <p style={{margin: 0}}>
                                <strong>Assets: </strong>
                                {assetString}
                            </p>
                            <p style={{margin: 0}}>
                                <strong>Timerange: </strong>
                                {dataset.timerange}
                            </p>
                            <p style={{margin: 0}}>
                                <strong>Schedule: </strong> {scheduleStr}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            className="text-right"
                            style={{
                                paddingRight: '20px',
                                paddingBottom: '5px',
                            }}>
                            <ButtonGroup size="xs">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        dataset.publish()
                                    }}>
                                    <i className="simple-icon-cloud-upload" />
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.editDataset(dataset)
                                    }}>
                                    <i className="simple-icon-pencil" />
                                </Button>
                                <Button
                                    color="danger"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.removeDatasetFromList(
                                            dataset.datasetId
                                        )
                                        dataset.remove()
                                    }}>
                                    <i className="simple-icon-trash" />
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    componentWillMount() {}

    render() {
        return (
            <Scrollbars>
                {this.props.datasets.map((d) => this.makeElement(d))}
            </Scrollbars>
        )
    }
}
