import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            nameInputBorderColor: 'rgb(215,215,215)',
            usernameInputBorderColor: 'rgb(215,215,215)',
            passwordBorderColor: 'rgb(215,215,215)',
            username: this.props.editingUser.username,
            firstName: this.props.editingUser.firstName,
            lastName: this.props.editingUser.lastName,
            password: '',
            confirmPassword: '',
            role: {
                label: this.props.editingUser.role,
                value: this.props.editingUser.role,
            },
            roles: [],
            valid: true,
            message: '',
        }
    }

    handleRoleEvent(role) {
        this.setState({role})
    }

    async save() {
        if (this.validate()) {
            let obj = {
                username: this.state.username,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                role: this.state.role.value,
            }

            if (this.state.password !== '') {
                obj.password = this.state.password
            }

            const response = await API.patch(
                'users/' + this.props.editingUser._id,
                obj,
                2
            )
            if (response) {
                this.props.toggleModal('editModal')
            } else {
                return alert(
                    'There was an error adding this user. Please try again.'
                )
            }
        }
    }

    validate() {
        const {firstName, lastName, password, confirmPassword, role, username} =
                this.state,
            {_id} = this.props.editingUser

        if (firstName === '' || lastName === '') {
            this.setState({
                nameInputBorderColor: '#dc3545',
                valid: false,
                message: 'Please enter first and last name.',
            })
            return
        } else {
            this.setState({
                nameInputBorderColor: 'rgb(215,215,215)',
            })
        }

        if (username === '') {
            this.setState({
                usernameInputBorderColor: '#dc3545',
                valid: false,
                message: 'Please enter a username.',
            })
            return
        } else {
            this.setState({
                usernameInputBorderColor: 'rgb(215,215,215)',
            })
        }

        // check if user with same name already exists
        if (
            this.props.users.find(
                (user) =>
                    user._id !== _id &&
                    user.firstName === firstName &&
                    user.lastName === lastName
            )
        ) {
            this.setState({
                nameInputBorderColor: '#dc3545',
                valid: false,
                message:
                    'A user with the same first and last name already exists.',
            })
            return
        }

        // check if user with same username already exists
        if (
            this.props.users.find(
                (user) => user._id !== _id && user.username === username
            )
        ) {
            this.setState({
                usernameInputBorderColor: '#dc3545',
                valid: false,
                message: 'A user with the same username already exists.',
            })
            return
        }

        // check if passwords match
        if (password !== '' && password !== confirmPassword) {
            this.setState({
                passwordInputBorderColor: '#dc3545',
                valid: false,
                message: 'Password and password confirmation do not match',
            })
            return
        }
        this.setState({
            nameInputBorderColor: 'rgb(215,215,215)',
            usernameInputBorderColor: 'rgb(215,215,215)',
            passwordBorderColor: 'rgb(215,215,215)',
            valid: true,
        })
        return true
    }

    async componentDidMount() {
        let roles = [...new Set(this.props.users.map((u) => u.role))].map(
            (r) => {
                return {label: r, value: r}
            }
        )
        const envs = await API.get('environments', 2)

        let discoveredRoles = []
        envs.forEach((env) => {
            env.sections.forEach((section) => {
                section.views.forEach((view) => {
                    if (view.permissions)
                        discoveredRoles = discoveredRoles.concat(
                            view.permissions
                        )
                })
            })
        })

        discoveredRoles = [...new Set(discoveredRoles)].map((r) => {
            return {label: r, value: r}
        })
        roles = roles.concat(discoveredRoles)
        this.setState({roles})
    }

    render() {
        const {modal, toggleModal, users} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('editModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('editModal')
                        }}>
                        Edit User
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>First Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={(firstName) => {
                                        this.setState({
                                            firstName: firstName.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Last Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={(lastName) => {
                                        this.setState({
                                            lastName: lastName.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Username:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.usernameInputBorderColor,
                                    }}
                                    type="text"
                                    name="username"
                                    value={this.state.username}
                                    onChange={(username) => {
                                        this.setState({
                                            username: username.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Role:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="role"
                                    value={this.state.role}
                                    onChange={this.handleRoleEvent}
                                    options={this.state.roles}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Password:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.passwordInputBorderColor,
                                    }}
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={(password) => {
                                        this.setState({
                                            password: password.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Confirm Password:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.passwordInputBorderColor,
                                    }}
                                    type="password"
                                    name="password-confirm"
                                    value={this.state.confirmPassword}
                                    onChange={(password) => {
                                        this.setState({
                                            confirmPassword:
                                                password.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('editModal')
                            }}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
