import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'

import HourlyTargetActualChartGreenStrength from './HourlyTargetActualChartGreenStrength'
import HourlyTargetActualChartPartCount from './HourlyTargetActualChartPartCount'
import HourlyTargetActualChartUtilization from './HourlyTargetActualChartUtilization'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {fetchShiftTimerange} from 'SDK/api/common'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        const record = this.props.assets.find(
            (x) => x.deviceId === this.props.deviceId
        )
        this.showGreenStrength =
            record.groups.length && record.groups[0] === 'Green Strength'
        this.showUtilization =
            record.groups.length && record.groups[0] === 'Utilization'

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            live: {
                name: '...',
                timestamp: null,
                color: '',
                elapsed: '0:00',
            },
            actual: 0,
            target: 0,
            partNumber: 'No Scheduled Production',
            left: 0,
            progress: 0,
            hoursRemaining: '',
            activeBreak: false,
        }
    }

    async fetchDeviceName() {
        let assets = this.props.assets //await API.get('devices');
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                name: assets.find((a) => a.deviceId === this.props.deviceId)
                    .name,
            })
        }
    }

    async fetchLiveData() {
        //let live = await API.post('live', {
        //  deviceId: this.props.deviceId
        //});
        let live = this.props.live
        if (!live) {
            return alert('There was an error fetching data')
        } else if (live[this.props.deviceId].activeStates.length) {
            if (live[this.props.deviceId].activeShiftBreaks.length) {
                this.setState({
                    activeBreak:
                        live[this.props.deviceId].activeShiftBreaks[0].name,
                })
            } else {
                this.setState({activeBreak: false})
            }
            let shifts = live[this.props.deviceId].activeShifts
            live[this.props.deviceId].activeStates = live[
                this.props.deviceId
            ].activeStates.filter(
                (state) =>
                    state.name === 'Downtime' || state.name === 'In-Cycle'
            )
            live = live[this.props.deviceId].activeStates[0]
            if (!live) return
            if (live.name === 'Downtime') {
                const downtimeReason = this.props.downtimeReason
                    ? [this.props.downtimeReason]
                    : []

                if (downtimeReason.length) {
                    live.name = downtimeReason[0].value
                }
            }
            live.color = tileColor(live.name)
            live.elapsed = secondsToHHMMSS(
                moment().diff(moment(live.timestamp), 'seconds')
            )
            let timerange = [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().toISOString(),
            ]

            if (this.props.realtimeactiveshift) {
                if (shifts.length) {
                    timerange = await fetchShiftTimerange(
                        this.props.deviceId,
                        shifts[0]
                    )
                    timerange[0] = moment(timerange[0])
                        .startOf('minute')
                        .toISOString()
                    timerange[1] = moment().startOf('minute').toISOString()
                }
            }
            this.setState({live, timerange})
        }
    }

    updateElapsed() {
        if (this.state.live.timestamp === null) return
        let live = {...this.state.live}
        live.elapsed = secondsToHHMMSS(
            moment().diff(moment(live.timestamp), 'seconds')
        )
        this.setState({live})
    }

    async fetchActualThisHour() {
        if (!this.state.timerange) {
            let self = this
            return setTimeout(() => {
                self.fetchActualThisHour()
            }, 1000)
        }
        const minuteOffset = moment(this.state.timerange[0]).minute(),
            current = moment()
        let startOfHour = moment(current).startOf('hour')

        if (minuteOffset > 0) {
            if (current.minute() <= minuteOffset) {
                startOfHour = moment(current).add(-1, 'hours')
                startOfHour.set('minute', minuteOffset)
            } else {
                startOfHour = moment(current)
                startOfHour.set('minute', minuteOffset)
            }
        }
        startOfHour.startOf('minute')

        let {data} = await API.post('historical/aggregate2', {
            timeStart: startOfHour.toISOString(),
            timeEnd: moment().toISOString(),
            state: ['Part Count'],
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })

        let parts = data.devices[this.props.deviceId]['Part Count'][0]
        if (parts.constructor === Object) parts = parts.count

        this.setState({
            actual: parts,
        })
    }

    async fetchTargetThisHour() {
        if (!this.props.useRollingCycleTimeTargets) {
            const data = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: {
                            $in: [
                                'Hourly Target',
                                'Scheduled Target',
                                'Green Strength Range',
                            ],
                        },
                        timeEnd: null,
                    },
                },
                2
            )
            if (data && data.length) {
                if (
                    this.showGreenStrength &&
                    data.find((a) => a.name === 'Green Strength Range')
                ) {
                    this.setState({
                        target: data.find(
                            (a) => a.name === 'Green Strength Range'
                        ).value,
                        greenStrengthRangeRecord: data.find(
                            (a) => a.name === 'Green Strength Range'
                        ),
                    })
                } else {
                    this.setState({
                        target: data[0].metaData[0].value,
                    })
                }
            }
        } else {
            const totalCycleTime = await API.post('historical', {
                timeStart: moment().add(-30, 'days').toISOString(),
                timeEnd: moment().toISOString(),
                deviceId: this.props.deviceId,
                state: 'Total Cycle Time',
                unit: 'years',
            })
            const totalParts = await API.post('historical', {
                timeStart: moment().add(-30, 'days').toISOString(),
                timeEnd: moment().toISOString(),
                deviceId: this.props.deviceId,
                state: 'Part Count',
                unit: 'years',
            })
            const target =
                Math.round(
                    (60 / (totalCycleTime.duration[0] / totalParts.count[0])) *
                        100
                ) / 100
            this.setState({target})
        }
    }

    async fetchPartNumber() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Part Number',
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                partNumber: data[0].metaData[0].value,
            })
        }
    }

    async fetchProgress() {
        const target = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Target Parts',
                    timeEnd: null,
                },
            },
            2
        )
        if (target && target.length) {
            let {data} = await API.post('historical/aggregate2', {
                timeStart: moment(target[0].timeStart).toISOString(),
                timeEnd: moment().toISOString(),
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'total',
                logic: 'count',
            })

            let actual = data.devices[this.props.deviceId]['Part Count'][0]
            if (actual.constructor === Object) actual = actual.count

            let left = target[0].metaData[0].value - actual,
                progress = parseInt((actual / left) * 100),
                timeElapsedThisHour = moment().diff(moment().startOf('hour')),
                cycleTime = timeElapsedThisHour / this.state.actual,
                hoursRemaining = (left * cycleTime) / (60 * 60 * 1000)

            if (isNaN(hoursRemaining)) {
                hoursRemaining = ''
            } else {
                hoursRemaining =
                    '(~' + Math.round((hoursRemaining / 100) * 100) + ' hours)'
            }

            this.setState({
                left,
                progress,
                hoursRemaining,
            })
        }
    }

    toggleEditTarget() {
        const role = JSON.parse(localStorage['userObject']).role
        if (role === 'Plant Management' || role === 'Administrator') {
            this.setState({
                editingTarget: !this.state.editingTarget,
            })
        } else {
            return alert(
                'You role must be "Plant Management" or "Administrator" in order to change this hourly target.'
            )
        }
    }

    async saveTarget() {
        if (this.showGreenStrength) {
            await API.patch(
                'states/' + this.state.greenStrengthRangeRecord._id,
                {
                    ...this.state.greenStrengthRangeRecord,
                    value: this.state.target,
                },
                2
            )
        } else {
            if (this.state.target < 1) {
                return alert('Target cannot be less than 1')
            }
            API.post(
                'tablets/data',
                [
                    {
                        nodeId: this.props.deviceId,
                        name: 'Static Hourly Target',
                        value: parseInt(this.state.target),
                        timestamp: moment().valueOf(),
                    },
                ],
                2
            )
        }
        this.toggleEditTarget()
    }

    updateUtilizationCurrentHourValue(actual) {
        this.setState({actual: actual + '%'})
    }

    updateGreenStrengthCurrentHourValue(actual) {
        this.setState({actual})
    }

    componentWillMount() {
        this.fetchDeviceName()
        this.subscriber.add(this.fetchLiveData, 5000, 'fetchLiveData')
        this.subscriber.add(this.updateElapsed, 750, 'updateElapsed')
        this.subscriber.add(
            this.fetchTargetThisHour,
            60 * 1000,
            'fetchTargetThisHour'
        )
        const record = this.props.assets.find(
            (d) => d.deviceId === this.props.deviceId
        )
        if (
            !(record.groups.length && record.groups[0] === 'Utilization') &&
            !(record.groups.length && record.groups[0] === 'Green Strength')
        ) {
            this.subscriber.add(
                this.fetchActualThisHour,
                60 * 1000,
                'fetchActualThisHour'
            )
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const activeState = this.state.live

        return (
            <Col xs="12" sm="6" className="mb-4">
                <Card
                    style={{color: '#fff', backgroundColor: activeState.color}}>
                    <CardHeader
                        style={{
                            cursor: 'pointer',
                            margin: 0,
                            padding: '5px',
                            backgroundColor: 'rgba(47, 50, 59,0.2)',
                        }}>
                        <Row>
                            <Col>
                                <strong>{this.state.name}</strong>
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                                {this.state.live.elapsed}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{padding: 0}}>
                        <Row style={{margin: 0}}>
                            <Col xs="6">
                                <Row>
                                    <Col className="text-center">
                                        <h1>
                                            {this.state.live.name.length > 15
                                                ? this.state.live.name.substring(
                                                      0,
                                                      12
                                                  ) + '...'
                                                : this.state.live.name}
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <p>Hourly Actual</p>
                                        <h1>{this.state.actual}</h1>
                                    </Col>
                                    <Col className="text-center">
                                        <p>Hourly Target</p>
                                        {this.state.editingTarget ? (
                                            <>
                                                {this.showGreenStrength ? (
                                                    <>
                                                        <Input
                                                            type="number"
                                                            value={
                                                                this.state
                                                                    .target[0]
                                                            }
                                                            style={{
                                                                borderColor:
                                                                    this.state
                                                                        .color,
                                                                borderTop:
                                                                    'transparent',
                                                                borderRight:
                                                                    'transparent',
                                                                borderLeft:
                                                                    'transparent',
                                                                backgroundColor:
                                                                    'transparent',
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value <
                                                                    0
                                                                )
                                                                    return
                                                                this.setState({
                                                                    target: [
                                                                        Math.round(
                                                                            e
                                                                                .target
                                                                                .value *
                                                                                100
                                                                        ) / 100,
                                                                        this
                                                                            .state
                                                                            .target[1],
                                                                    ],
                                                                })
                                                            }}
                                                        />
                                                        <Input
                                                            type="number"
                                                            value={
                                                                this.state
                                                                    .target[1]
                                                            }
                                                            style={{
                                                                borderColor:
                                                                    this.state
                                                                        .color,
                                                                borderTop:
                                                                    'transparent',
                                                                borderRight:
                                                                    'transparent',
                                                                borderLeft:
                                                                    'transparent',
                                                                backgroundColor:
                                                                    'transparent',
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value <
                                                                    0
                                                                )
                                                                    return
                                                                this.setState({
                                                                    target: [
                                                                        this
                                                                            .state
                                                                            .target[0],
                                                                        Math.round(
                                                                            e
                                                                                .target
                                                                                .value *
                                                                                100
                                                                        ) / 100,
                                                                    ],
                                                                })
                                                            }}
                                                        />

                                                        <Button
                                                            className="mt-2"
                                                            outline
                                                            color="primary"
                                                            size="sm"
                                                            onClick={
                                                                this.saveTarget
                                                            }>
                                                            Save
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <InputGroup className="mb-3">
                                                            <Input
                                                                type="number"
                                                                value={
                                                                    this.state
                                                                        .target
                                                                }
                                                                style={{
                                                                    borderColor:
                                                                        this
                                                                            .state
                                                                            .color,
                                                                    borderTop:
                                                                        'transparent',
                                                                    borderRight:
                                                                        'transparent',
                                                                    borderLeft:
                                                                        'transparent',
                                                                    backgroundColor:
                                                                        'transparent',
                                                                }}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.target
                                                                            .value <
                                                                        0
                                                                    )
                                                                        return
                                                                    this.setState(
                                                                        {
                                                                            target: e
                                                                                .target
                                                                                .value,
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    size="sm"
                                                                    onClick={
                                                                        this
                                                                            .saveTarget
                                                                    }>
                                                                    Save
                                                                </Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <h1
                                                style={{cursor: 'pointer'}}
                                                onClick={this.toggleEditTarget}>
                                                {this.showGreenStrength ? (
                                                    <>{`${this.state.target[0]} - ${this.state.target[1]}`}</>
                                                ) : (
                                                    <>{this.state.target}</>
                                                )}
                                                {this.showUtilization
                                                    ? '%'
                                                    : ''}
                                            </h1>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xs="6"
                                style={{
                                    backgroundColor: 'rgba(68, 70, 79, 0.5)',
                                }}>
                                {this.showGreenStrength ? (
                                    <>
                                        {this.state.timerange && (
                                            <HourlyTargetActualChartGreenStrength
                                                updateGreenStrengthCurrentHourValue={
                                                    this
                                                        .updateGreenStrengthCurrentHourValue
                                                }
                                                timerange={this.state.timerange}
                                                deviceId={this.props.deviceId}
                                                target={this.state.target}
                                                color={this.state.live.color}
                                                productionUnit={
                                                    this.props.productionUnit
                                                }
                                            />
                                        )}
                                    </>
                                ) : null}

                                {this.showUtilization ? (
                                    <>
                                        {this.state.timerange && (
                                            <HourlyTargetActualChartUtilization
                                                updateUtilizationCurrentHourValue={
                                                    this
                                                        .updateUtilizationCurrentHourValue
                                                }
                                                timerange={this.state.timerange}
                                                deviceId={this.props.deviceId}
                                                target={this.state.target}
                                                color={this.state.live.color}
                                                productionUnit={
                                                    this.props.productionUnit
                                                }
                                            />
                                        )}
                                    </>
                                ) : null}

                                {!this.showUtilization &&
                                !this.showGreenStrength ? (
                                    <>
                                        {this.state.timerange && (
                                            <HourlyTargetActualChartPartCount
                                                timerange={this.state.timerange}
                                                deviceId={this.props.deviceId}
                                                target={this.state.target}
                                                color={this.state.live.color}
                                                productionUnit={
                                                    this.props.productionUnit
                                                }
                                                currentHour={this.state.actual}
                                            />
                                        )}
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    </CardBody>
                    {this.state.activeBreak ? (
                        <CardFooter>
                            <p style={{textAlign: 'center'}}>
                                {this.state.activeBreak}
                            </p>
                        </CardFooter>
                    ) : null}
                </Card>
            </Col>
        )
    }
}
