import React, {Fragment, Component} from 'react'
import {Row, Col, Button, Card, CardHeader, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import GridLayout from 'react-grid-layout'
import {WidthProvider} from 'react-grid-layout'
import moment from 'moment'
import axios from 'axios'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Manager from './manager'
import * as API from 'SDK/api'
import CustomTile from './customTile'
import GenericTile from './genericTile'
import {generateHash} from 'SDK/helpers'

import ViewSettings from './ViewSettings'

const Grid = WidthProvider(GridLayout)

export default class View extends Component {
    constructor(props) {
        super(props)
        autobind(this)
        this.environmentId = this.props.match.params.environmentId
        this.sectionId = this.props.match.params.sectionId
        this.viewId = this.props.match.params.viewId

        this.state = {
            mode: this.props.editingEnvironment ? 'edit' : 'view',
            env: null,
            layout: [],
            library: [],
            generic: [],
            custom: [],
            globalTimerange: [null, null],
            ready: false,
            type: null,
            k: 0,
        }
    }

    async removeView() {
        let {env} = this.state
        const views = env.sections
            .find((section) => section.id === this.sectionId)
            .views.filter((view) => view.id !== this.viewId)
        env.sections.find((section) => section.id === this.sectionId).views =
            views

        await API.patch('environments/' + this.environmentId, env, 2)
        this.setState({env})
    }

    async updateView(name, layout, generic, permissions, goToView) {
        let {env} = this.state

        let view = env.sections
            .find((section) => section.id === this.sectionId)
            .views.find((view) => view.id === this.viewId)
        if (name) view.name = name
        view.layout = this.state.layout
        view.generic = this.state.generic
        view.custom = this.state.custom
        if (permissions) view.permissions = permissions

        await API.patch('environments/' + this.environmentId, env, 2)
        this.setState({env})
        this.props.updateEnvironment(env)
        if (goToView)
            this.props.history.push(
                '/app/env/' +
                    this.environmentId +
                    '/' +
                    this.sectionId +
                    '/' +
                    this.viewId +
                    '/view'
            )
    }

    addCustomItem(item) {
        let custom = this.state.custom
        custom.push(item)
        this.setState({
            layout: this.state.layout.concat({
                i: item.id,
                x: 0, //(this.state.layout.length * 2) % this.state.cols,
                y: 0, //Infinity,
                w: 4,
                h: 6,
                minW: 1,
                minH: 1,
            }),
            custom,
        })
    }

    async addGenericItem(item) {
        let generic = this.state.generic

        generic.push(item)

        this.setState({
            layout: this.state.layout.concat({
                i: 'Generic::' + item.component,
                x: (this.state.layout.length * 2) % 12,
                y: Infinity,
                w: 100,
                h: 6,
                minW: 1,
                minH: 1,
            }),
            generic,
        })
    }

    async onRemoveItem(i) {
        if (this.state.type === 'custom') {
            this.setState({
                layout: this.state.layout.filter((item) => item.i != i),
                custom: this.state.custom.filter((item) => item.id != i),
            })
        } else if (this.state.type === 'generic') {
            this.setState({
                layout: this.state.layout.filter((item) => item.i != i),
                generic: [],
            })
            await API.remove(
                'view-settings/' +
                    this.environmentId +
                    '/' +
                    this.sectionId +
                    '/' +
                    this.viewId +
                    '?all=true',
                2
            )
        }
    }

    onLayoutChange(layout) {
        this.setState({layout: layout})
    }

    edit() {
        this.props.history.push(
            '/app/env/' +
                this.environmentId +
                '/' +
                this.sectionId +
                '/' +
                this.viewId +
                '/edit'
        )
    }

    applyChangeToAll({timeStart, timeEnd}) {
        this.setState({
            globalTimerange: [timeStart, timeEnd],
        })
    }

    cloneCustomItem(id) {
        let config = {...this.state.custom.find((o) => o.id === id)},
            layoutItem = this.state.layout.find((l) => l.i === id)
        config.id = generateHash()

        let custom = this.state.custom
        custom.push(config)

        this.setState({
            layout: this.state.layout.concat({
                i: config.id,
                x: layoutItem.x + layoutItem.w,
                y: layoutItem.y,
                w: layoutItem.w,
                h: layoutItem.h,
                minW: 1,
                minH: 1,
            }),
            custom,
        })

        //this.addCustomItem(config);
    }

    async editCustomItem(id) {
        await this.updateView(null, null, null, null, false)
        localStorage[id] = JSON.stringify(
            this.state.custom.find((a) => a.id === id)
        )
        this.props.history.push(
            '/app/core/insight-engine?environmentId=' +
                this.environmentId +
                '&sectionId=' +
                this.sectionId +
                '&viewId=' +
                this.viewId +
                '&visualId=' +
                id
        )
    }

    async componentWillMount() {
        const library = await API.get('library', 2)
        const environments = await API.get('environments', 2)
        const env = environments.find((env) => env._id === this.environmentId)
        if (!env) {
            this.props.history.push('/app/core/splash')
        }
        const section = env.sections.find(
            (section) => section.id === this.sectionId
        )
        if (!section) {
            this.props.history.push('/app/core/splash')
        }
        const view = section.views.find((view) => view.id === this.viewId)
        if (!view) {
            this.props.history.push('/app/core/splash')
        }

        if (view.type) {
            let layout = view.layout
            let generic = view.generic
            let custom = view.custom

            const viewSettings = await API.get(
                'view-settings/' +
                    this.environmentId +
                    '/' +
                    this.sectionId +
                    '/' +
                    this.viewId,
                2
            )

            if (
                viewSettings &&
                viewSettings.props &&
                viewSettings.props.deviceIds
            ) {
                this.interceptor = axios.interceptors.response.use(
                    async (response) => {
                        if (
                            response.config.url.includes('v1/devices') &&
                            !response.config.url.includes('?all=true') &&
                            response.config.method === 'get'
                        ) {
                            let arr = []
                            for (let i in viewSettings.props.deviceIds) {
                                const device = response.data.find(
                                    (d) =>
                                        d.deviceId ===
                                        viewSettings.props.deviceIds[i]
                                )
                                if (device) {
                                    arr.push(device)
                                }
                            }
                            response.data = arr
                        }
                        return response
                    },
                    (error) => {}
                )
            }

            this.setState({
                library,
                env,
                layout,
                generic,
                custom,
                type: view.type,
                viewSettings,
            })

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
            }, 500)

            let self = this
            setTimeout(() => {
                let pendingCustomWidget = localStorage['visual']
                if (pendingCustomWidget)
                    pendingCustomWidget = JSON.parse(pendingCustomWidget)
                if (
                    pendingCustomWidget &&
                    !self.state.custom.find(
                        (a) => a.id === pendingCustomWidget.id
                    )
                ) {
                    self.addCustomItem(pendingCustomWidget)
                    localStorage.removeItem('visual')
                } else if (pendingCustomWidget) {
                    let custom = self.state.custom
                    let layout = self.state.layout
                    custom[
                        custom.findIndex((a) => a.id === pendingCustomWidget.id)
                    ] = pendingCustomWidget
                    self.setState({custom, layout})
                    localStorage.removeItem('visual')
                }
                self.setState({ready: true})
            }, 500)
        } else {
            if (this.props.editingEnvironment)
                this.props.toggleAddNewViewModal(section.id, view.id)
            else this.props.history.push('/app/core/splash')
        }

        if (this.environmentId === '5fc53042befe3f001ad8111c') {
            document.body.classList.add('wadsworth-body')
        }
    }

    componentWillUnmount() {
        if (this.interceptor)
            axios.interceptors.response.eject(this.interceptor)
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.editingEnvironment &&
            !this.props.editingEnvironment &&
            this.state.env
        ) {
            // changed to edit mode
            this.setState({mode: 'edit'})
        } else if (
            !nextProps.editingEnvironment &&
            this.props.editingEnvironment
        ) {
            // changed to view mode
            this.setState({mode: 'view'})
        }
    }

    async reloadViewSettings() {
        const viewSettings = await API.get(
            'view-settings/' +
                this.environmentId +
                '/' +
                this.sectionId +
                '/' +
                this.viewId,
            2
        )

        if (this.interceptor)
            axios.interceptors.response.eject(this.interceptor)

        if (
            viewSettings &&
            viewSettings.props &&
            viewSettings.props.deviceIds
        ) {
            this.interceptor = axios.interceptors.response.use(
                async (response) => {
                    if (
                        response.config.url.includes('v1/devices') &&
                        !response.config.url.includes('?all=true') &&
                        response.config.method === 'get'
                    ) {
                        response.data = response.data.filter((a) =>
                            viewSettings.props.deviceIds.find(
                                (b) => b === a.deviceId
                            )
                        )
                    }
                    return response
                },
                (error) => {}
            )
        }

        this.setState({viewSettings, k: Math.random()})
    }

    render() {
        const showManager = this.state.mode === 'edit' && this.state.env,
            custom = this.state.layout.filter(
                (o) => !o.i.includes('Generic::')
            ),
            generic = this.state.layout.filter((o) =>
                o.i.includes('Generic::')
            ),
            showViewSettingsButton =
                this.state.mode === 'view' &&
                this.state.layout.length === 1 &&
                this.state.layout.find((l) => l.i.includes('Generic'))

        let genericWidgetComponent = null

        if (showViewSettingsButton) {
            genericWidgetComponent = this.state.generic.find(
                (g) =>
                    g.component === this.state.layout[0].i.split('Generic::')[1]
            )
        }

        return (
            <Fragment>
                <Row
                    style={{
                        marginTop: '-10px',
                        marginRight:
                            this.environmentId === '5fc53042befe3f001ad8111c'
                                ? '0px'
                                : '75px',
                    }}>
                    <Col>
                        {showManager ? (
                            <Row>
                                <Col className="pull-right">
                                    <Manager
                                        viewId={this.viewId}
                                        sectionId={this.sectionId}
                                        environmentId={this.environmentId}
                                        environment={this.state.env}
                                        layout={this.state.layout}
                                        generic={this.state.generic}
                                        history={this.props.history}
                                        addCustomItem={this.addCustomItem}
                                        addGenericItem={this.addGenericItem}
                                        removeView={this.removeView}
                                        updateView={this.updateView}
                                        onRemoveItem={this.onRemoveItem}
                                        reload={this.reloadViewSettings}
                                        component={this.state.generic.find(
                                            (g) =>
                                                g.component ===
                                                this.state.layout[0].i.split(
                                                    'Generic::'
                                                )[1]
                                        )}
                                    />
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.ready && (
                            <Grid
                                key={this.state.k}
                                className="layout"
                                isDraggable={
                                    this.state.type === 'custom' &&
                                    this.state.mode === 'edit'
                                }
                                isResizable={
                                    this.state.type === 'custom' &&
                                    this.state.mode === 'edit'
                                }
                                cols={12}
                                layout={this.state.layout}
                                rowHeight={30}
                                onLayoutChange={this.onLayoutChange}
                                useCSSTransforms={false}
                                verticalCompact={true}>
                                {custom.map((x, i) => {
                                    return (
                                        <div key={x.i}>
                                            <CustomTile
                                                element={x}
                                                config={this.state.custom.find(
                                                    (a) => a.id === x.i
                                                )}
                                                onRemoveItem={this.onRemoveItem}
                                                mode={this.state.mode}
                                                applyChangeToAll={
                                                    this.applyChangeToAll
                                                }
                                                globalTimerange={
                                                    this.state.globalTimerange
                                                }
                                                cloneCustomItem={
                                                    this.cloneCustomItem
                                                }
                                                editCustomItem={
                                                    this.editCustomItem
                                                }
                                            />
                                        </div>
                                    )
                                })}
                                {generic.map((x, i) => {
                                    return (
                                        <div key={x.i}>
                                            <GenericTile
                                                element={x}
                                                onRemoveItem={this.onRemoveItem}
                                                library={this.state.generic}
                                                mode={this.state.mode}
                                                environmentId={
                                                    this.environmentId
                                                }
                                                sectionId={this.sectionId}
                                                viewId={this.viewId}
                                                viewSettings={
                                                    this.state.viewSettings
                                                }
                                                {...this.props}
                                            />
                                        </div>
                                    )
                                })}
                            </Grid>
                        )}
                    </Col>
                </Row>
                {showViewSettingsButton ? (
                    <ViewSettings
                        environmentId={this.environmentId}
                        sectionId={this.sectionId}
                        viewId={this.viewId}
                        component={genericWidgetComponent}
                        reload={this.reloadViewSettings}
                    />
                ) : null}
            </Fragment>
        )
    }
}
