import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }
    async remove() {
        try {
            let response = await API.remove(
                'nodes/' + this.props.editingNode.nodeId,
                2
            )
            if (response.success) {
                this.props.toggleModal('removeModal')
            } else {
                return alert(
                    'There was an error deleting this SensorBot. Please try again later.'
                )
            }
        } catch (error) {
            return alert(
                'There was an error deleting this SensorBot. Please try again later.'
            )
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}

    render() {
        let {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('removeModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('removeModal')
                        }}>
                        Remove SensorBot
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <p>
                                    Are you sure you want to remove this
                                    SensorBot?
                                </p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('removeModal')
                            }}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={this.remove}>
                            Remove
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
