import React, {Component, Fragment} from 'react'
import {Bar, Chart} from 'react-chartjs-2'
import moment from 'moment'
import autobind from 'auto-bind'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {Row, Col} from 'reactstrap'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        if (
            moment(this.props.timerange[1]).diff(
                moment(this.props.timerange[0]),
                'hours'
            ) <= 12
        ) {
            Chart.plugins.register(ChartDataLabels)
        }

        this.subscriber = new Subscriber()

        this.state = {
            loaded: false,
            chartdata: {},
            options: {
                maintainAspectRatio: false,
                title: {
                    text:
                        moment(this.props.timerange[0]).format('ddd h:mma') +
                        ' to now',
                    display: true,
                    fontColor: '#fff',
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                            //type: 'time',
                            //time: {
                            //  unit: 'hour'
                            //},
                            ticks: {
                                autoSkip: true,
                                fontColor: '#fff',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                        },
                    ],
                },
            },
        }
    }

    async makeChart() {
        const timeStart = moment(this.props.timerange[0]).minute(),
            timeEnd = moment(this.props.timerange[1]).minute()

        let data = null

        if (timeStart > 0) {
            let raw = await API.post(
                'historical/raw',
                {
                    query: {
                        timeStart: {
                            $ne: null,
                        },
                        timeEnd: {
                            $gte: this.props.timerange[0],
                            $lte: moment(this.props.timerange[1])
                                .endOf('minute')
                                .toISOString(),
                        },
                        name: 'Part Count',
                        deviceId: this.props.deviceId,
                    },
                },
                2
            )

            data = {
                dates: [],
                dateRanges: [],
                devices: {},
            }

            for (
                let m = moment(this.props.timerange[0]);
                m.diff(moment(this.props.timerange[1]), 'minutes') <= 0;
                m.add(1, 'hours')
            ) {
                data.dates.push(m.toISOString())
                data.dateRanges.push([moment(m), moment(m).add(1, 'hours')])
            }

            data.devices[this.props.deviceId] = {
                'Part Count': new Array(data.dates.length).fill(0),
            }

            for (let i in raw) {
                const {value, timeEnd, metaData} = raw[i]
                const dateIndex = data.dateRanges.findIndex((d) =>
                    moment(timeEnd).isBetween(d[0], d[1])
                )
                if (dateIndex > -1) {
                    let metaDataObj = metaData.find(
                        (m) => m.name === 'partcount'
                    )
                    if (metaDataObj) {
                        data.devices[this.props.deviceId]['Part Count'][
                            dateIndex
                        ] += metaDataObj.value
                    } else {
                        data.devices[this.props.deviceId]['Part Count'][
                            dateIndex
                        ] += value
                    }
                }
            }
        } else {
            data = await API.post('historical/aggregate2', {
                timeStart: this.props.timerange[0],
                timeEnd: this.props.timerange[1],
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'hour',
                logic: 'count',
            })
            data = data.data

            if (
                data.devices[this.props.deviceId]['Part Count'][0].count !==
                undefined
            ) {
                data.devices[this.props.deviceId]['Part Count'] = data.devices[
                    this.props.deviceId
                ]['Part Count'].map((a) => {
                    if (a.count !== undefined) {
                        return a.count
                    } else return a
                })
            }
            if (!data) return
        }

        data.devices[this.props.deviceId]['Part Count'][
            data.devices[this.props.deviceId]['Part Count'].length - 1
        ] = this.props.currentHour

        this.setState({
            loaded: true,
            chartdata: {
                labels: data.dates.map((d) => moment(d).format('ha')),
                datasets: [
                    {
                        label: 'Target ' + this.props.productionUnit,
                        type: 'line',
                        data: new Array(data.dates.length).fill(
                            this.state.target
                        ),
                        backgroundColor: 'transparent',
                        borderColor: 'darkgrey',
                        datalabels: {display: false},
                    },
                    {
                        label: 'Actual ' + this.props.productionUnit,
                        data: data.devices[this.props.deviceId]['Part Count'],
                        backgroundColor: this.props.color,
                        datalabels: {
                            color: '#fff',
                            // align: 'top',
                            display: function (context) {
                                return (
                                    context.dataset.data[context.dataIndex] > 0
                                )
                            },
                        },
                        //borderColor: 'black',
                        //borderWidth: 0.03
                    },
                ],
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentHour !== this.props.currentHour) {
            this.makeChart()
        } else if (nextProps.target !== this.state.target) {
            this.setState({target: nextProps.target})
            this.makeChart()
        } else if (nextProps.color !== this.props.color) {
            this.makeChart()
        }
    }

    componentDidMount() {
        this.subscriber.add(this.makeChart, 1000 * 60, 'makeChart()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
        Chart.plugins.unregister(ChartDataLabels)
    }
    render() {
        const isLoading = !this.state.loaded
        return isLoading ? null : (
            <Row>
                <Col>
                    <Bar
                        data={this.state.chartdata}
                        options={this.state.options}
                    />
                </Col>
            </Row>
        )
    }
}
