import React, {Component} from 'react'
import {injectIntl} from 'react-intl'

import {
    Nav,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Input,
} from 'reactstrap'
import IntlMessages from 'util/IntlMessages'
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as API from 'SDK/api'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    setContainerClassnames,
    clickOnMobileMenu,
    logoutUser,
    changeLocale,
} from 'redux/actions'

import {
    menuHiddenBreakpoint,
    searchPath,
    localeOptions,
} from 'constants/defaultValues'

import Offline from './offline'
import UpdateLog from './UpdateLog'
import DefaultViewManager from './default'
import MobileLogout from './MobileLogout'
import moment from 'moment-timezone'
import KnowledgeBase from './KnowledgeBase'
import HMIConfigurator from './HMIConfigurator'
import LargeLogo from '../../assets/img/L2L_ConnectLogo_greenblack.svg'
import SmallLogo from '../../assets/img/Connect_icon.svg'

class TopNav extends Component {
    constructor(props) {
        super(props)
        this.menuButtonClick = this.menuButtonClick.bind(this)
        this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this)
        this.toggleEnvironmentDropdown =
            this.toggleEnvironmentDropdown.bind(this)

        const userObj = JSON.parse(localStorage['userObject'])

        this.isSuperAdmin =
            userObj.username === 'accumine' || userObj.role === 'Administrator'
        this.role = userObj.role

        this.state = {
            isInFullScreen: false,
            user: userObj,
            environment: this.props.environment,
            environments: this.props.environments,
            environmentDropdownOpen: false,
        }
    }

    toggleEnvironmentDropdown() {
        this.setState({
            environmentDropdownOpen: !this.state.environmentDropdownOpen,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            environment: nextProps.environment,
            environments: nextProps.environments,
        })
    }

    isInFullScreen = () => {
        return (
            (document.fullscreenElement &&
                document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement &&
                document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement &&
                document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement &&
                document.msFullscreenElement !== null)
        )
    }

    toggleFullScreen = () => {
        const isInFullScreen = this.isInFullScreen()

        var docElm = document.documentElement
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen()
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen()
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen()
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        }
        this.setState({
            isInFullScreen: !isInFullScreen,
        })
    }

    handleLogout = async () => {
        try {
            await API.post('users/logout', {}, 2)
        } catch (error) {
            alert('Logout unsuccessful. Please try again.')
            return
        }
        localStorage.clear()
        this.props.logoutUser(this.props.history)
        window.location.reload()
    }

    menuButtonClick(e, menuClickCount, containerClassnames) {
        e.preventDefault()

        setTimeout(() => {
            var event = document.createEvent('HTMLEvents')
            event.initEvent('resize', false, false)
            window.dispatchEvent(event)
        }, 350)
        this.props.setContainerClassnames(++menuClickCount, containerClassnames)
    }
    mobileMenuButtonClick(e, containerClassnames) {
        e.preventDefault()
        this.props.clickOnMobileMenu(containerClassnames)
    }

    hasAppAccess(environment) {
        if (this.isSuperAdmin) return true
        let authorized = false

        environment.sections.forEach((section) => {
            section.views.forEach((view) => {
                if (
                    view.permissions &&
                    view.permissions.find((p) => p === this.role)
                ) {
                    authorized = true
                }
            })
        })
        return authorized
    }

    componentDidMount() {
        let self = this
        setInterval(() => {
            self.setState({
                liveDate:
                    moment().format('LTS') +
                    ' (GMT ' +
                    moment().utcOffset() / 60 +
                    ')',
            })
        }, 1000)
    }

    render() {
        const {containerClassnames, menuClickCount} = this.props
        const {messages} = this.props.intl

        const appName = this.state.environment
            ? this.state.environment.name
            : 'Choose App...'

        const environments = this.state.environments || []

        const isMobile = document.documentElement.offsetWidth < 992

        return (
            <nav className="navbar fixed-top">
                <NavLink
                    to="#"
                    className="menu-button d-none d-md-block"
                    onClick={(e) =>
                        this.menuButtonClick(
                            e,
                            menuClickCount,
                            containerClassnames
                        )
                    }>
                    <svg
                        className="main"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 9 17">
                        <rect x="0.48" y="0.5" width="7" height="1" />
                        <rect x="0.48" y="7.5" width="7" height="1" />
                        <rect x="0.48" y="15.5" width="7" height="1" />
                    </svg>
                    <svg
                        className="sub"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 17">
                        <rect x="1.56" y="0.5" width="16" height="1" />
                        <rect x="1.56" y="7.5" width="16" height="1" />
                        <rect x="1.56" y="15.5" width="16" height="1" />
                    </svg>
                </NavLink>
                <NavLink
                    to="#"
                    className="menu-button-mobile d-xs-block d-sm-block d-md-none"
                    onClick={(e) =>
                        this.mobileMenuButtonClick(e, containerClassnames)
                    }>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                        <rect x="0.5" y="0.5" width="25" height="1" />
                        <rect x="0.5" y="7.5" width="25" height="1" />
                        <rect x="0.5" y="15.5" width="25" height="1" />
                    </svg>
                </NavLink>

                <div className="d-inline-block">
                    <Dropdown
                        isOpen={this.state.environmentDropdownOpen}
                        toggle={this.toggleEnvironmentDropdown}
                        className="ml-2">
                        <DropdownToggle
                            caret
                            color="light"
                            size="sm"
                            className="language-button">
                            <span className="name">{appName}</span>
                        </DropdownToggle>
                        <DropdownMenu className="mt-3" right>
                            {environments.map((a) => {
                                if (this.hasAppAccess(a))
                                    return (
                                        <DropdownItem
                                            key={Math.random()}
                                            onClick={() => {
                                                this.props.changeEnvironment(
                                                    a.name
                                                )
                                            }}>
                                            {a.name}
                                        </DropdownItem>
                                    )
                            })}
                            {this.props.editingEnvironment && (
                                <DropdownItem divider />
                            )}
                            {this.props.editingEnvironment && (
                                <DropdownItem
                                    onClick={
                                        this.props.handleAddNewEnvironmentEvent
                                    }>
                                    New Application...
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {!isMobile && (
                    <div className="d-inline-block ml-2">
                        <p className="text-center" style={{margin: 0}}>
                            {this.state.liveDate}
                        </p>
                    </div>
                )}

                {this.props.editingEnvironment && this.props.environment ? (
                    <div className="d-inline-block">
                        <UncontrolledDropdown className="ml-2">
                            <DropdownToggle size="sm">
                                <span className="name">
                                    Application Manager
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-3" right>
                                <DropdownItem
                                    onClick={
                                        this.props.handleRenameEnvironmentEvent
                                    }>
                                    Rename
                                </DropdownItem>
                                <DropdownItem
                                    onClick={
                                        this.props.handleCopyEnvironmentEvent
                                    }>
                                    Copy
                                </DropdownItem>
                                <DropdownItem
                                    onClick={
                                        this.props.handlePublishEnvironmentEvent
                                    }>
                                    Publish
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem
                                    onClick={
                                        this.props.handleDeleteEnvironmentEvent
                                    }>
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ) : null}

                <a className="navbar-logo m-auto" href="/">
                    <img
                        className="logo d-none d-lg-block d-xl-block"
                        src={LargeLogo}
                        alt="App Logo"
                        role="button"
                        title={this.state.user.factoryId}
                    />
                    <img
                        className="logo logo-small d-none d-md-block d-sm-block d-xs-block d-lg-none d-xl-none"
                        src={SmallLogo}
                        alt="App Logo"
                        role="button"
                        title={this.state.user.factoryId}
                    />
                </a>

                <div className="navbar-right">
                    <div className="header-icons d-inline-block align-middle">
                        {!this.props.editingEnvironment && (
                            <DefaultViewManager {...this.props} />
                        )}
                        <KnowledgeBase />
                        <HMIConfigurator />
                        <UpdateLog />
                        <Offline />
                        <MobileLogout handleLogout={this.handleLogout} />
                        <button
                            className="header-icon btn btn-empty d-none d-sm-inline-block"
                            type="button"
                            id="fullScreenButton"
                            onClick={this.toggleFullScreen}>
                            {this.state.isInFullScreen ? (
                                <i className="simple-icon-size-actual d-block" />
                            ) : (
                                <i className="simple-icon-size-fullscreen d-block" />
                            )}
                        </button>
                    </div>

                    <div className="user d-inline-block">
                        <UncontrolledDropdown className="dropdown-menu-right">
                            <DropdownToggle className="p-0" color="empty">
                                <span className="name mr-1">
                                    {this.state.user.username}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-3" right>
                                {!this.props.editingEnvironment &&
                                    this.isSuperAdmin && (
                                        <DropdownItem
                                            onClick={
                                                this.props.toggleEditEnvironment
                                            }>
                                            Edit Environment
                                        </DropdownItem>
                                    )}
                                {this.props.editingEnvironment &&
                                    this.isSuperAdmin && (
                                        <DropdownItem
                                            onClick={
                                                this.props.toggleEditEnvironment
                                            }>
                                            Finish Editing
                                        </DropdownItem>
                                    )}
                                {this.isSuperAdmin && (
                                    <DropdownItem
                                        onClick={() => {
                                            this.props.clearEnvironment()
                                            this.props.history.push(
                                                '/app/core/flows'
                                            )
                                        }}>
                                        Data Flows
                                    </DropdownItem>
                                )}
                                {this.isSuperAdmin && (
                                    <DropdownItem
                                        onClick={() => {
                                            this.props.clearEnvironment()
                                            this.props.history.push(
                                                '/app/core/insight-engine'
                                            )
                                        }}>
                                        Insight Engine
                                    </DropdownItem>
                                )}
                                <DropdownItem
                                    onClick={() => this.handleLogout()}>
                                    Sign out
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = ({menu, settings}) => {
    const {containerClassnames, menuClickCount} = menu
    const {locale} = settings

    return {containerClassnames, menuClickCount, locale}
}
export default injectIntl(
    connect(mapStateToProps, {
        setContainerClassnames,
        clickOnMobileMenu,
        logoutUser,
        changeLocale,
    })(TopNav)
)
