import React, {Fragment, Component} from 'react'
import {Progress, Row, Col} from 'reactstrap'
import autobind from 'auto-bind'

export default class DescendingProgressBars extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    generateDataset() {
        let sorted = this.props.data.sort(
                (a, b) => b[this.props.sortBy] - a[this.props.sortBy]
            ),
            dom = []

        for (let i = 0; i < sorted.length; i++) {
            const {name, active, dormant, offline} = sorted[i]
            const nodata = active === 0 && dormant === 0 && offline === 0
            dom.push(
                <Row className="mb-2" style={{borderBottom: '1px solid grey'}}>
                    <Col
                        xs="4"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        title={name}>
                        {name}
                    </Col>
                    <Col xs="8">
                        {nodata ? (
                            <Progress multi style={{height: '15px'}}>
                                <Progress bar color="default" value={100}>
                                    No data
                                </Progress>
                            </Progress>
                        ) : (
                            <Progress multi style={{height: '15px'}}>
                                <Progress bar color="danger" value={offline}>
                                    {offline > 10 ? offline + '%' : null}
                                </Progress>
                                <Progress bar color="warning" value={dormant}>
                                    {dormant > 10 ? dormant + '%' : null}
                                </Progress>
                                <Progress bar color="success" value={active}>
                                    {active > 10 ? active + '%' : null}
                                </Progress>
                            </Progress>
                        )}
                    </Col>
                </Row>
            )
        }
        return dom
    }

    render() {
        return <Fragment>{this.generateDataset()}</Fragment>
    }
}
