import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {extendMoment} from 'moment-range'
import Moment from 'moment'
import autobind from 'auto-bind'
import Switch from 'rc-switch'
import {CSVLink, CSVDownload} from 'react-csv'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import pos from 'pos'
import lodash from 'lodash'

import * as API from 'SDK/api'

import ReportChart from './chart'
import ExportData from './export'
import Comments from './comments'

import { generateShiftData } from './generateShiftData'


const moment = extendMoment(Moment)

const UNIT_OPTIONS = [
    {
        label: 'Total Duration (in hours)',
        value: 'totalDuration',
        key: 0,
    },
    {
        label: 'Average Duration (in hours)',
        value: 'averageDuration',
        key: 1,
    },
    {
        label: 'Total Occurrences',
        value: 'totalOccurances',
        key: 2,
    },
]


export default class ParetoReport extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'ParetoReport'
    requiredOutputs = [
        'Downtime',
        'Downtime Reason',
        'Downtime Category',
        'Downtime Comment',
    ]
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            ready: false,
            loading: false,
            assets: [],
            selectedAssets: [],
            users: [],
            data: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            unit: UNIT_OPTIONS[0],
            comments: [],
            byShift: false,
            showUncategorized: false,
            shifts: [],
            type: '',
            csvData: [],
            commentExport: [],
        }
    }
    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }
    handleUnitChange(unit) {
        this.setState({
            unit,
        })
    }
    handleAssetChange(e) {
        this.setState({
            selectedAssets: e,
        })
    }
    async getAssets() {
        const assets = await API.get('devices')
        if (!assets) return alert('Could not fetch factory assets.')

        this.setState({
            assets: assets,
            selectedAssets: assets.map((a, i) => {
                return {label: a.name, value: a.deviceId, key: i}
            }),
        })
    }
    async getReasonCodes() {
        let reasonCodes = await API.get('reasoncodes', 2)
        if (!reasonCodes) return alert('Could not fetch reason codes')

        reasonCodes.push({
            reason: 'Uncategorized',
            category: 'Uncategorized',
            offsetCapacity: false,
            assets: this.state.assets.map((a) => a.value),
        })

        this.setState({
            reasonCodes,
        })
    }

    async getShifts() {
        const shifts = await API.get('shifts', 2)
        if (!shifts) return alert('Could not fetch shifts')

        this.setState({shifts})
    }

    async getUsers() {
        const users = await API.get('users?all=true', 2)
        if (!users) return alert('Could not fetch users')

        this.setState({users})
    }

    generate() {
        const {selectedAssets, start, end, byShift} = this.state

        if (selectedAssets.length === 0) {
            return alert('Select at least one asset to report.')
        }

        this.setState({loading: true})

        if (byShift) {
            generateShiftData(this.state, this.setState, this.exportComments, API.post)
        } else {
            this.generateTotalData()
        }
    }

    async generateTotalData() {
        const {showUncategorized} = this.state
        const states = showUncategorized
            ? ['Downtime Reason', 'Downtime Category', 'Downtime']
            : ['Downtime Reason', 'Downtime Category']

        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: this.state.selectedAssets.map((a) => a.value),
                    },
                    name: {$in: states},
                    timeStart: {
                        $lte: this.state.end.toISOString(),
                    },
                    $or: [
                        {
                            timeEnd: {$gte: this.state.start.toISOString()},
                        },
                        {
                            timeEnd: null,
                        },
                    ],
                },
            },
            2
        )

        if (showUncategorized) {
            let assetDowntimeEntryThresholds = {}
            for (let asset of this.state.assets) {
                assetDowntimeEntryThresholds[asset.deviceId] =
                    asset.downtimeThresholdSeconds || 300
            }
            data = data.filter((row) => {
                let tE = row.timeEnd ? moment(row.timeEnd) : moment()
                if (
                    row.name === 'Downtime' &&
                    tE.diff(moment(row.timeStart), 'seconds') >
                        assetDowntimeEntryThresholds[row.deviceId] &&
                    !data.find(
                        (d) =>
                            d.name === 'Downtime Reason' &&
                            d.timeStart === row.timeStart
                    )
                ) {
                    return true
                } else if (row.name !== 'Downtime') {
                    return true
                } else {
                    return false
                }
            })
            data = data.map((row) => {
                if (row.name === 'Downtime') {
                    row.name = 'Downtime Reason'
                    row.value = 'Uncategorized'
                }
                return row
            })
        }

        if (data.length === 0) {
            this.setState({
                loading: false,
            })
            return alert('No data found')
        }

        if (this.state.hideCapacityCodes) {
            const capacityCodes = [
                ...new Set(
                    this.state.reasonCodes
                        .filter((code) => code.offsetCapacity)
                        .map((code) => code.reason)
                ),
            ]
            data = data.filter((d) => !capacityCodes.find((c) => c === d.value))
        }

        this.setState({
            rawData: JSON.parse(
                JSON.stringify(data.filter((d) => d.name === 'Downtime Reason'))
            ),
            totalData: JSON.parse(JSON.stringify(data)),
        })

        data = data
            .filter((d) => d.name === 'Downtime Reason')
            .map((d) => {
                d.timeStart = moment(d.timeStart)
                d.timeEnd = d.timeEnd === null ? moment() : moment(d.timeEnd)
                if (this.state.start.isAfter(d.timeStart)) {
                    d.timeStart = moment(this.state.start)
                }
                if (d.timeEnd.isAfter(this.state.end)) {
                    d.timeEnd = moment(this.state.end)
                }
                return d
            })
        let aggregated = [...new Set(data.map((d) => d.value))].map((d) => {
            return {
                field: d,
                totalMs: 0,
                totalCount: 0,
            }
        })
        for (let row of data) {
            const agg = aggregated.find((a) => a.field === row.value)
            if (agg) {
                agg.totalMs += row.timeEnd.diff(row.timeStart)
                agg.totalCount++
            }
        }

        let unitString = ''

        if (this.state.unit.value === 'totalDuration') {
            unitString = 'total hrs'
            aggregated = aggregated.map((a) => {
                a.value = Math.round((a.totalMs / (1000 * 60 * 60)) * 100) / 100
                return a
            })
        }
        if (this.state.unit.value === 'averageDuration') {
            unitString = 'avg. hrs'
            aggregated = aggregated.map((a) => {
                a.value =
                    Math.round(
                        (a.totalMs / a.totalCount / (1000 * 60 * 60)) * 100
                    ) / 100
                return a
            })
        }
        if (this.state.unit.value === 'totalOccurances') {
            unitString = ' occurrences'
            aggregated = aggregated.map((a) => {
                a.value = a.totalCount
                return a
            })
        }
        aggregated = aggregated.sort((a, b) => b.value - a.value)

        const total = aggregated.reduce((a, b) => a + b.value, 0)
        let cum = [(aggregated[0].value / total) * 100]

        for (let i = 1; i < aggregated.length; i++) {
            cum.push(cum[i - 1] + (aggregated[i].value / total) * 100)
        }

        cum[cum.length - 1] = 100

        let chartdata = {
            labels: aggregated.map((a) => a.field),
            datasets: [
                {
                    yAxisID: 'b',
                    type: 'line',
                    label: 'Cumulative',
                    data: cum,
                    borderColor: '#2a93d5',
                    backgroundColor: 'transparent',
                },
                {
                    yAxisID: 'a',
                    label: 'Downtime Reasons',
                    data: aggregated.map((a) => a.value),
                    backgroundColor: '#145388',
                },
            ],
        }
        await this.exportComments()
        this.setState({
            data: {chartdata: chartdata, hours: true},
            ready: true,
            loading: false,
            unitString,
        })
    }


    generateCharts() {
        const exportSection = (
            <Row>
                <Col xs="12" style={{textAlign: 'right'}} className="mb-4">
                    <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={this.exportData}>
                        <i className="iconsmind-Printer" /> Export Data
                    </Button>
                </Col>
            </Row>
        )

        if (this.state.ready && !this.state.loading) {
            let charts = [],
                max = 0
            if (this.state.data.constructor === Array) {
                for (let i in this.state.data) {
                    for (let j in this.state.data[i].datasets) {
                        for (let k in this.state.data[i].datasets[j].data) {
                            if (this.state.data[i].datasets[j].data[k] > max) {
                                max = this.state.data[i].datasets[j].data[k]
                            }
                        }
                    }
                    let data = {
                        chartdata: this.state.data[i],
                        hours: this.state.hours,
                        max: max,
                    }
                    charts.push(data)
                }
            } else {
                charts.push(this.state.data)
            }
            return charts.map((c, idx) => (
                <ReportChart
                    key={idx}
                    data={c}
                    unitString={this.state.unitString}
                    handleReasonClick={this.showComments}
                />
            ))
        } else {
            return <p>Loading...</p>
        }
    }

    async showComments(reason) {
        this.setState({showLoader: true})

        let comments = JSON.parse(
            JSON.stringify(
                this.state.commentExport.filter(
                    (comment) => comment['Reason Code'] === reason
                )
            )
        )

        comments
            .map((comment) => {
                comment.timestamp = moment(comment['From']).format(
                    'dddd MMM DD, h:mma'
                )
                let minutes = moment(comment['To']).diff(
                    moment(comment['From']),
                    'minutes'
                )
                if (minutes < 60) {
                    comment.duration = minutes + ' mins'
                } else if (minutes < 1440) {
                    comment.duration =
                        Math.round((minutes / 60) * 100) / 100 + ' hrs'
                } else {
                    comment.duration =
                        Math.round((minutes / 1400) * 100) / 100 + ' days'
                }
                comment.minutes = minutes
                comment.deviceName = comment['Asset']
                comment.value = comment['Comment']
                return comment
            })
            .sort((a, b) => b.minutes - a.minutes)
        this.setState({comments, highlightedReason: reason, showLoader: false})
    }

    async exportComments() {
        let exportObject = []
        //let exportObject = [['Asset', 'Reason Code', 'Comment', 'From', 'To', 'Duration (minutes)']];
        const timestamps = this.state.totalData.map((row) => {
            return {
                name: row.name,
                reason: row.value,
                timestamp: row.timestamp,
                m: moment(row.timestamp),
            }
        })

        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: this.state.selectedAssets.map((a) => a.value),
                    },
                    timeStart: {
                        $lte: this.state.end.toISOString(),
                    },
                    $or: [
                        {
                            timeEnd: {$gte: this.state.start.toISOString()},
                        },
                        {
                            timeEnd: null,
                        },
                    ],
                    name: {
                        $in: ['Downtime Comment', 'Downtime User'],
                    },
                },
                options: {
                    sort: {
                        timestamp: 1,
                    },
                },
            },
            2
        )

        let deviceDictionary = {}
        for (let device of this.state.selectedAssets) {
            deviceDictionary[device.value] = device.label
        }

        let userDictionary = {}
        for (let user of this.state.users) {
            userDictionary[user._id] = user.firstName + ' ' + user.lastName
        }

        let comments = data.filter(
            (c) => c.name === 'Downtime Comment' && c.value && c.value !== ''
        )
        comments = comments.map((c, i) => {
            c['Asset'] = deviceDictionary[c.deviceId]
            c.timestamp = moment(c.timestamp)
            const code = timestamps.find(
                (t) => t.m.isSame(c.timestamp) && t.name === 'Downtime Reason'
            )
            const category = timestamps.find(
                (t) => t.m.isSame(c.timestamp) && t.name === 'Downtime Category'
            )
            const userObject = data.find(
                (d) =>
                    d.name === 'Downtime User' &&
                    c.timestamp.isSame(moment(d.timestamp))
            )
            if (userObject) {
                c['Entered By'] = userDictionary[userObject.value] || 'N/A'
            } else {
                c['Entered By'] = 'N/A'
            }
            if (category) c['Category'] = category.reason
            if (code) c['Reason Code'] = code.reason
            c['From'] = c.timestamp.format()
            c['To'] = c.timeEnd ? moment(c.timeEnd).format() : moment().format()
            c['Duration'] = (
                (moment(c['To']).unix() - moment(c['From']).unix()) /
                60
            ).toFixed(2)
            c['Comment'] = c.value

            delete c._id
            delete c.nodeId
            delete c.deviceId
            delete c.timestamp
            delete c.timeStart
            delete c.timeEnd
            delete c.name
            delete c.metaData
            delete c.nodeComm
            delete c.nodeType
            delete c.samples
            delete c.value

            return c
        })

        comments = comments.filter((c) => c['Reason Code'])

        exportObject = exportObject.concat(comments)

        let nouns = comments.map((a) => a.Comment).join(',')
        nouns = new pos.Lexer().lex(nouns)
        nouns = new pos.Tagger().tag(nouns)
        nouns = nouns
            .filter((a) => a[1].startsWith('N'))
            .map((a) => a[0].toLowerCase())
        nouns = lodash.countBy(nouns)

        let nounExport = []
        for (let noun in nouns) {
            nounExport.push({
                Noun: noun,
                Count: nouns[noun],
            })
        }

        this.setState({commentExport: exportObject, nounExport: nounExport})
    }

    async componentWillMount() {
        await this.getAssets()
        await this.getShifts()
        await this.getReasonCodes()
        await this.getUsers()
    }

    render() {
        const readyToExport = this.state.ready && !this.state.loading
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12" className="mb-4">
                                        <label>Assets:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isMulti
                                            name="assets"
                                            value={this.state.selectedAssets}
                                            onChange={this.handleAssetChange}
                                            options={this.state.assets.map(
                                                (a, i) => {
                                                    return {
                                                        label: a.name,
                                                        value: a.deviceId,
                                                        key: i,
                                                    }
                                                }
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="2">
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleStartChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleEndChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>Unit:</label>
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="unit"
                                            value={this.state.unit}
                                            onChange={this.handleUnitChange}
                                            options={UNIT_OPTIONS}
                                            isDisabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>
                                            By Shift (group by shift):
                                        </label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={this.state.byShift}
                                            onChange={(byShift) => {
                                                this.setState({byShift})
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>
                                            Hide capacity affecting codes:
                                        </label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={
                                                this.state.hideCapacityCodes
                                            }
                                            onChange={(hideCapacityCodes) => {
                                                this.setState({
                                                    hideCapacityCodes,
                                                })
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <label>
                                            Show uncategorized downtime:
                                        </label>
                                        <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={
                                                this.state.showUncategorized
                                            }
                                            onChange={(showUncategorized) =>
                                                this.setState({
                                                    showUncategorized,
                                                })
                                            }
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" style={{textAlign: 'right'}}>
                                        <br />
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {readyToExport ? (
                    <ExportData
                        type={this.state.type}
                        unitString={this.state.unitString}
                        selectedAssets={this.state.selectedAssets}
                        data={this.state.data}
                        hours={this.state.hours}
                        shifts={this.state.shifts}
                        start={this.state.start}
                        end={this.state.end}
                        rawData={this.state.rawData}
                        commentExport={this.state.commentExport}
                        nounExport={this.state.nounExport}
                    />
                ) : null}
                <Row>
                    {this.state.ready ? (
                        <>
                            {!this.state.byShift ||
                            this.state.showUncategorized ? (
                                <Col xs="12" className="mb-2">
                                    <Card>
                                        <CardBody>
                                            {!this.state.byShift ? (
                                                <p className="text-center">
                                                    Note: If "By Shift" is not
                                                    enabled, shift schedules
                                                    will not be considered (i.e.
                                                    24/7 available will be used){' '}
                                                </p>
                                            ) : null}
                                            {this.state.showUncategorized ? (
                                                <p className="text-center">
                                                    Note: The "Uncategorized"
                                                    category only accounts for
                                                    downtime greater than the
                                                    downtime entry threshold
                                                    (i.e. microstoppages are not
                                                    included)
                                                </p>
                                            ) : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                            ) : null}
                        </>
                    ) : null}
                    {this.state.ready ? this.generateCharts() : null}
                    {this.state.ready && !this.state.loading ? (
                        <Comments
                            comments={this.state.comments}
                            reason={this.state.highlightedReason}
                        />
                    ) : null}
                </Row>
                {this.state.loading ? <div className="loading" /> : null}
                {this.state.showLoader ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
