import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Input,
    UncontrolledTooltip,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import CRUD from 'SDK/ui/crud'
import Slider, {createSliderWithTooltip} from 'rc-slider'
import {CSVLink, CSVDownload} from 'react-csv'
import 'rc-slider/assets/index.css'
import * as downtimeAggregation from '../../../../src/SDK/logic/downtime-aggregation';
import * as optimize from '../../logic/optimization'
import * as sorters from '../../logic/array-sorters'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

const SliderWithTooltip = createSliderWithTooltip(Slider)

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

export default class DowntimeHistoryModule extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'DowntimeHistoryModule'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            exportData: [],
            device: null,
            data: [],
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            comment: '',
        }

        const role = JSON.parse(localStorage['userObject']).role

        this.hasClearPermission = [
            'Administrator',
            'Plant Management',
            'Supervisor',
        ].includes(role)
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: moment(date).endOf('day'),
        })
    }


    populateAssetsDropdown() {
        const allOption = {label: 'All Assets', value: 'all'};

        let assetsOptions = this.state.devices.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.deviceId,
                }
            }
        )
        assetsOptions.unshift(allOption);

        return assetsOptions;
    }

    async _fetchClosedDowntimeEntries(deviceId) {
        return await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: deviceId,
                    name: {
                        $in: [
                            'Downtime',
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeStart: {
                        $gte: this.state.start,
                    },
                    timeEnd: {
                        $lte: this.state.end,
                        $ne: null,
                    },
                }
            },
            2
        )
    }

    async _fetchActiveDowntimeEntry(deviceId) {
        return await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: deviceId,
                    name: {
                        $in: [
                            'Downtime',
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeStart: {
                        $gte: this.state.start,
                    },
                    timeEnd: null,
                }
            },
            2
        )
    }

    async fetchDeviceDowntimeEntries(deviceId) {
        const findAssetByDeviceId = optimize._keyedListSearch_(this.state.devices, (a) => a.deviceId);
        const pastStates = await this._fetchClosedDowntimeEntries(deviceId);
        const activeStates = await this._fetchActiveDowntimeEntry(deviceId);
        const allStates = pastStates.concat(activeStates);
        const downtimes = downtimeAggregation.createDowntimeEntriesList(
            allStates,
            this.state.users,
            this.state.device.downtimeThresholdSeconds || 300
        ).map(
            (x) => {
                let asset = findAssetByDeviceId(x.deviceId);
                if(asset) {
                    x.assetName = asset.name;
                }
                return x;
            }
        );
        return downtimes;
    }


    async populateStateWithDowntimeEntries(device) {
        this.setState({ loading: true });
        device = device || this.state.device;
        let devices = [device];
        if(device.value === 'all') {
            console.log(`Populating for all ${this.state?.devices?.length} devices`);
            devices = this.state.devices;
        }

        const dtPromise = devices.map(
            (d) => this.fetchDeviceDowntimeEntries(d.deviceId)
        )

        const downtimes = (await Promise.all(dtPromise)).flat().sort(
            sorters.sortByDecendingTime_(obj=>obj.timeStart)
        );

        this.setState({data: downtimes, loading: false})
    }

    columns() {
        return [
            {
                Header: 'Asset Name',
                accessor: 'assetName',
            },
            {
                Header: 'From',
                accessor: 'timeStartPretty',
            },
            {
                Header: 'To',
                accessor: 'timeEndPretty',
            },
            {
                Header: 'Duration',
                accessor: 'duration',
            },
            {
                Header: 'Category',
                accessor: 'category',
            },
            {
                Header: 'Reason',
                accessor: 'reason',
            },
            {
                Header: 'Comment (Hover for more)',
                accessor: 'comment',
                Cell: (row) => (
                    <div>
                        <span title={row.value}>{row.value}</span>
                    </div>
                ),
            },
            {
                Header: 'Entered By',
                accessor: 'fullUserName',
            },
            {
                id: 'edit',
                accessor: '_id',
                width: 75,
                Cell: ({value}) => (
                    <Button
                        color="primary"
                        size="xs"
                        className="btn"
                        onClick={async (e) => {
                            e.stopPropagation()

                            const record = this.state.data.find(
                                (o) => o._id === value
                            )

                            let reasoncodes = await API.get('reasoncodes', 2)
                            reasoncodes = reasoncodes
                                .filter((code) => {
                                    if (!code.assets) {
                                        return true
                                    } else {
                                        return code.assets.find(
                                            (a) => a === record.deviceId
                                        )
                                    }
                                })
                                .sort((a, b) => {
                                    return (
                                        a.category - b.category ||
                                        a.reason - b.reason
                                    )
                                })
                                .map((a) => {
                                    return {
                                        reason: a.reason,
                                        category: a.category,
                                        label: a.category + ' > ' + a.reason,
                                        value: a._id,
                                    }
                                })
                            this.setState(
                                {
                                    editing: value,
                                    category: record.category,
                                    reason: record.reason,
                                    comment: record.comment,
                                    reasoncodes,
                                    selection: reasoncodes.find(
                                        (o) =>
                                            o.category === record.category &&
                                            o.reason === record.reason
                                    ),
                                },
                                this.toggleModal
                            )
                        }}>
                        Edit
                    </Button>
                ),
                sortable: false,
                filterable: false,
                Header: 'Edit',
            },
            {
                id: 'split',
                accessor: '_id',
                width: 75,
                Cell: ({value}) => {
                    const record = this.state.data.find((o) => o._id === value)
                    const role = JSON.parse(localStorage['userObject']).role,
                        canSplitActive = [
                            'Administrator',
                            'Plant Management',
                            'Supervisor',
                        ].includes(role)
                    if (!record) return <></>
                    else if (
                        (record.timeEnd &&
                            moment(record.timeEnd).diff(
                                moment(record.timeStart),
                                'seconds'
                            ) >=
                                (this.state.device.downtimeThresholdSeconds ||
                                    300) *
                                    2) ||
                        (!record.timeEnd &&
                            canSplitActive &&
                            moment().diff(
                                moment(record.timeStart),
                                'seconds'
                            ) >=
                                (this.state.device.downtimeThresholdSeconds ||
                                    300) *
                                    2)
                    ) {
                        return (
                            <Button
                                color="primary"
                                size="xs"
                                className="btn"
                                onClick={async (e) => {
                                    e.stopPropagation()

                                    let reasoncodes = await API.get(
                                        'reasoncodes',
                                        2
                                    )
                                    reasoncodes = reasoncodes
                                        .filter((code) => {
                                            if (!code.assets) {
                                                return true
                                            } else {
                                                return code.assets.find(
                                                    (a) => a === record.deviceId
                                                )
                                            }
                                        })
                                        .sort((a, b) => {
                                            return (
                                                a.category - b.category ||
                                                a.reason - b.reason
                                            )
                                        })
                                        .map((a) => {
                                            return {
                                                reason: a.reason,
                                                category: a.category,
                                                label:
                                                    a.category +
                                                    ' > ' +
                                                    a.reason,
                                                value: a._id,
                                            }
                                        })
                                    this.setState(
                                        {
                                            editing: value,
                                            category: record.category,
                                            reason: record.reason,
                                            comment: record.comment,
                                            reasoncodes,
                                            selection: reasoncodes.find(
                                                (o) =>
                                                    o.category ===
                                                        record.category &&
                                                    o.reason === record.reason
                                            ),
                                            record,
                                            splitPoint: moment(
                                                record.timeStart
                                            ),
                                        },
                                        this.toggleSplitModal
                                    )
                                }}>
                                Split
                            </Button>
                        )
                    } else if (!record.timeEnd) {
                        if (
                            moment().diff(moment(record.timeStart), 'seconds') <
                            (this.state.device.downtimeThresholdSeconds ||
                                300) *
                                2
                        ) {
                            // too short
                            return (
                                <>
                                    <UncontrolledTooltip
                                        target={`split-tt-${record._id}`}>
                                        Period too short to split
                                    </UncontrolledTooltip>
                                    <Button
                                        style={{cursor: 'not-allowed'}}
                                        color="default"
                                        size="xs"
                                        id={`split-tt-${record._id}`}>
                                        Split
                                    </Button>
                                </>
                            )
                        } else {
                            // no permission
                            return (
                                <>
                                    <UncontrolledTooltip
                                        target={`split-tt-${record._id}`}>
                                        Only 'Administrator' & 'Plant
                                        Management' roles can split active
                                        period
                                    </UncontrolledTooltip>
                                    <Button
                                        style={{cursor: 'not-allowed'}}
                                        color="default"
                                        size="xs"
                                        id={`split-tt-${record._id}`}>
                                        Split
                                    </Button>
                                </>
                            )
                        }
                    } else {
                        return (
                            <>
                                <UncontrolledTooltip
                                    target={`split-tt-${record._id}`}>
                                    Period too short to split
                                </UncontrolledTooltip>
                                <Button
                                    style={{cursor: 'not-allowed'}}
                                    color="default"
                                    size="xs"
                                    id={`split-tt-${record._id}`}>
                                    Split
                                </Button>
                            </>
                        )
                    }
                },
                sortable: false,
                filterable: false,
                Header: 'Split',
            },
        ]
    }

    dataTableFormatter(record) {

        const timeEnd = record.timeEnd || moment()
        record.duration =
            moment(timeEnd).diff(moment(record.timeStart), 'minutes') +
            ' minutes'
        record.timeStartPretty = moment(record.timeStart).format('MMM D, h:mma')
        if (record.timeEnd) {
            record.timeEndPretty = moment(timeEnd).format('MMM D, h:mma')
        } else {
            record.timeEndPretty = 'ACTIVE NOW'
        }

        return record
    }

    async toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async toggleSplitModal() {
        this.setState({
            showSplitModal: !this.state.showSplitModal,
        })
    }

    async handleDowntimeSelection(selection) {
        this.setState({selection})
    }

    async submit() {
        const reasoncode = this.state.reasoncodes.find(
                (a) => a.value === this.state.selection.value
            ),
            downtime = this.state.data.find((x) => x._id === this.state.editing)

        if (downtime.reason) {
            const previousReason = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.state.device.deviceId,
                        name: 'Downtime Reason',
                        timeStart: downtime.timeStart,
                        timeEnd: downtime.timeEnd,
                    },
                },
                2
            )
            await API.remove('states/' + previousReason[0]._id, 2)
        }
        if (downtime.category) {
            const previousCategory = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.state.device.deviceId,
                        name: 'Downtime Category',
                        timeStart: downtime.timeStart,
                        timeEnd: downtime.timeEnd,
                    },
                },
                2
            )
            await API.remove('states/' + previousCategory[0]._id, 2)
        }

        // comment can be an empty string, have to query to see if a record exists
        const previousComment = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Comment',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )

        if (previousComment.length) {
            // clear all due to potential of multiple values from this bug
            await Promise.all(
                previousComment.map((a) => API.remove('states/' + a._id, 2))
            )
        }

        if (downtime.user) {
            const previousUser = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.state.device.deviceId,
                        name: 'Downtime User',
                        timeStart: downtime.timeStart,
                        timeEnd: downtime.timeEnd,
                    },
                },
                2
            )
            await API.remove('states/' + previousUser[0]._id, 2)
        }

        const category = {
            nodeId: this.state.device.deviceId,
            deviceId: this.state.device.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Category',
            value: reasoncode.label.split(' > ')[0],
        }
        const reason = {
            nodeId: this.state.device.deviceId,
            deviceId: this.state.device.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Reason',
            value: reasoncode.label.split(' > ')[1],
        }
        const comment = {
            nodeId: this.state.device.deviceId,
            deviceId: this.state.device.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Comment',
            value: this.state.comment || '',
        }
        const user = {
            nodeId: this.state.device.deviceId,
            deviceId: this.state.device.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime User',
            value: JSON.parse(localStorage['userObject'])._id,
        }

        await API.post('states', category, 2)
        await API.post('states', reason, 2)
        await API.post('states', comment, 2)
        await API.post('states', user, 2)

        this.generate()
        this.toggleModal()
    }

    async clear() {
        const downtime = this.state.data.find(
            (x) => x._id === this.state.editing
        )

        const previousReason = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Reason',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousReason.length) {
            await API.remove('states/' + previousReason[0]._id, 2)
        }
        const previousCategory = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Category',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousCategory.length) {
            await API.remove('states/' + previousCategory[0]._id, 2)
        }
        const previousComment = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Comment',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousComment.length) {
            await API.remove('states/' + previousComment[0]._id, 2)
        }
        const previousUser = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime User',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousUser.length) {
            await API.remove('states/' + previousUser[0]._id, 2)
        }
        this.generate()
        this.toggleModal()
    }

    async submitSplitOnFirstPartition() {
        if (!this.validateSplitTimerange()) {
            return alert(
                'Each downtime period must be at least 5 minutes long!'
            )
        }
        // first, change the end time of the existing downtime state, category, reason & comment
        let firstPartitionTimerange = {
            timeStart: moment(this.state.record.timeStart).toISOString(),
            timeEnd: moment(this.state.splitPoint).toISOString(),
        }

        let existingCategory = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Category',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingCategory.length) {
            existingCategory[0].timeEnd = firstPartitionTimerange.timeEnd
            await API.patch(
                'states/' + existingCategory[0]._id,
                existingCategory[0],
                2
            )
        }

        let existingReason = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Reason',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingReason.length) {
            existingReason[0].timeEnd = firstPartitionTimerange.timeEnd
            await API.patch(
                'states/' + existingReason[0]._id,
                existingReason[0],
                2
            )
        }

        let existingComment = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Comment',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingComment.length) {
            existingComment[0].timeEnd = firstPartitionTimerange.timeEnd
            await API.patch(
                'states/' + existingComment[0]._id,
                existingComment[0],
                2
            )
        }

        let existingUser = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime User',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingUser.length) {
            existingUser[0].timeEnd = firstPartitionTimerange.timeEnd
            await API.patch('states/' + existingUser[0]._id, existingUser[0], 2)
        }

        let existingDowntime = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )
        existingDowntime[0].timeEnd = firstPartitionTimerange.timeEnd

        // then add in a new downtime state for the next period
        let secondPartitionTimerange = {
            timeStart: moment(this.state.splitPoint).toISOString(),
            timeEnd:
                this.state.record.timeEnd === null
                    ? null
                    : moment(this.state.record.timeEnd).toISOString(),
        }

        await API.post(
            'states',
            {
                nodeId: this.state.device.deviceId,
                deviceId: this.state.device.deviceId,
                timestamp: secondPartitionTimerange.timeStart,
                timeStart: secondPartitionTimerange.timeStart,
                timeEnd: secondPartitionTimerange.timeEnd,
                name: 'Downtime',
                value: true,
                metaData: [],
            },
            2
        )

        await API.patch(
            'states/' + this.state.record._id,
            existingDowntime[0],
            2
        )

        this.generate()
        this.toggleSplitModal()
    }

    async submitSplitOnSecondPartition() {
        if (!this.validateSplitTimerange()) {
            return alert(
                'Each downtime period must be at least 5 minutes long!'
            )
        }
        // first, change the end time of the existing downtime period
        let firstPartitionTimerange = {
            timeStart: moment(this.state.record.timeStart).toISOString(),
            timeEnd: moment(this.state.splitPoint).toISOString(),
        }

        // then add in a new downtime state for the next period
        let secondPartitionTimerange = {
            timeStart: moment(this.state.splitPoint).toISOString(),
            timeEnd:
                this.state.record.timeEnd === null
                    ? null
                    : moment(this.state.record.timeEnd).toISOString(),
        }
        console.log(secondPartitionTimerange)

        let existingCategory = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Category',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingCategory.length) {
            existingCategory[0].timeStart = secondPartitionTimerange.timeStart
            existingCategory[0].timestamp = secondPartitionTimerange.timeStart
            existingCategory[0].timeEnd = secondPartitionTimerange.timeEnd
            await API.patch(
                'states/' + existingCategory[0]._id,
                existingCategory[0],
                2
            )
        }

        let existingReason = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Reason',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingReason.length) {
            existingReason[0].timeStart = secondPartitionTimerange.timeStart
            existingReason[0].timestamp = secondPartitionTimerange.timeStart
            existingReason[0].timeEnd = secondPartitionTimerange.timeEnd
            await API.patch(
                'states/' + existingReason[0]._id,
                existingReason[0],
                2
            )
        }

        let existingComment = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime Comment',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingComment.length) {
            existingComment[0].timeStart = secondPartitionTimerange.timeStart
            existingComment[0].timestamp = secondPartitionTimerange.timeStart
            existingComment[0].timeEnd = secondPartitionTimerange.timeEnd
            await API.patch(
                'states/' + existingComment[0]._id,
                existingComment[0],
                2
            )
        }

        let existingUser = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime User',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )

        if (existingUser.length) {
            existingUser[0].timeStart = secondPartitionTimerange.timeStart
            existingUser[0].timestamp = secondPartitionTimerange.timeStart
            existingUser[0].timeEnd = secondPartitionTimerange.timeEnd
            await API.patch('states/' + existingUser[0]._id, existingUser[0], 2)
        }

        let existingDowntime = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Downtime',
                    timeStart: moment(
                        this.state.record.timeStart
                    ).toISOString(),
                    timeEnd:
                        this.state.record.timeEnd === null
                            ? null
                            : moment(this.state.record.timeEnd).toISOString(),
                },
            },
            2
        )
        existingDowntime[0].timeEnd = firstPartitionTimerange.timeEnd

        await API.post(
            'states',
            {
                nodeId: this.state.device.deviceId,
                deviceId: this.state.device.deviceId,
                timestamp: secondPartitionTimerange.timeStart,
                timeStart: secondPartitionTimerange.timeStart,
                timeEnd: secondPartitionTimerange.timeEnd,
                name: 'Downtime',
                value: true,
                metaData: [],
            },
            2
        )

        await API.patch(
            'states/' + this.state.record._id,
            existingDowntime[0],
            2
        )

        this.generate()
        this.toggleSplitModal()
    }

    handleDeviceChange(deviceSelection) {
        if (deviceSelection.value != 'all') {
            this.setState({
                device: this.state.devices.find((d) => d.deviceId === deviceSelection.value),
            })
        }
        else {
            this.setState({
                device: {
                    ...deviceSelection,
                    downtimeThresholdSeconds: 1,
                },
            })
        }
    }

    handleDowntimeCommentEntry(e) {
        this.setState({comment: e.target.value})
    }

    generate() {
        this.populateStateWithDowntimeEntries()
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        const users = await API.get('users?all=true', 2)
        let filteredDevices = devices

        // Remove devices that aren't included in props
        if (this.props.deviceIds) {
            filteredDevices = devices.filter((device) =>
                this.props.deviceIds.includes(device.deviceId)
            )
        }

        // Set a default device
        const device = filteredDevices.length ? filteredDevices[0] : null

        this.setState({devices: filteredDevices, device, users})

        this.populateStateWithDowntimeEntries()

    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    validateSplitTimerange() {
        const timeEnd =
                this.state.record.timeEnd === null
                    ? moment()
                    : moment(this.state.record.timeEnd),
            firstPartitionMinutes =
                this.state.splitPoint.diff(
                    moment(this.state.record.timeStart),
                    'seconds'
                ) >= (this.state.device.downtimeThresholdSeconds || 300),
            secondPartitionMinutes =
                timeEnd.diff(this.state.splitPoint, 'seconds') >=
                (this.state.device.downtimeThresholdSeconds || 300)
        return firstPartitionMinutes && secondPartitionMinutes
    }

    exportToCSV() {
        this.setState({ loading: true });
        const findAsset = optimize._keyedListSearch_(this.state.devices, (d) => d.deviceId);
        let exportData = []
        for (let row of this.state.data) {
            let exportRow = {}
            let asset = findAsset(row.deviceId)
            if (asset) {
                exportRow['Asset Name'] = asset.name
            }
            const timeEnd = row.timeEnd ? moment(row.timeEnd) : moment()
            exportRow['From'] = moment(row.timeStart).format(
                'YYYY-MM-DD HH:mm:ss'
            )
            exportRow['To'] = row.timeEnd
                ? moment(row.timeEnd).format('YYYY-MM-DD HH:mm:ss')
                : 'ACTIVE NOW'
            exportRow['Duration (minutes)'] = timeEnd.diff(
                row.timeStart,
                'minutes'
            )
            exportRow['Entered By'] = row.fullUserName || ''
            exportRow['Category'] = row.category || ''
            exportRow['Reason'] = row.reason || ''
            exportRow['Comment'] = row.comment || ''
            exportData.push(exportRow)
        }

        this.setState({exportData})
        this.setState({ loading: false });
    }

    render() {
        const selectedDevice = this.state.device?.deviceId
            ? {label: this.state.device.name, value: this.state.device.deviceId}
            : {label: 'All Assets', value: 'all'}

        const comment = this.state.comment || ''

        let options = []

        if (this.state.reasoncodes) {
            let categories = [
                ...new Set(this.state.reasoncodes.map((r) => r.category)),
            ]
            categories = categories.sort((a, b) => a.localeCompare(b))

            options = categories.map((c) => {
                const suboptions = this.state.reasoncodes
                    .filter((r) => r.category === c)
                    .map((r) => {
                        return {
                            label: r.reason,
                            value: r.value,
                        }
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))

                return {
                    label: c,
                    options: suboptions,
                }
            })
        }

        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center">
                                    <strong>Downtime Reason History</strong>
                                </CardTitle>
                                <Row>
                                    <Col xs="12">
                                        <Form>
                                            <Row>
                                                <Col xs="12" sm="4">
                                                    <label>From:</label>
                                                    <DatePicker
                                                        selected={
                                                            this.state.start
                                                        }
                                                        selectsStart
                                                        startDate={
                                                            this.state.start
                                                        }
                                                        endDate={this.state.end}
                                                        onChange={
                                                            this
                                                                .handleStartChange
                                                        }
                                                        filterDate={(date) => {
                                                            return (
                                                                moment() > date
                                                            )
                                                        }}
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    <label>To:</label>
                                                    <DatePicker
                                                        selected={
                                                            this.state.end
                                                        }
                                                        selectsEnd
                                                        startDate={
                                                            this.state.start
                                                        }
                                                        endDate={this.state.end}
                                                        onChange={
                                                            this.handleEndChange
                                                        }
                                                        filterDate={(date) => {
                                                            return (
                                                                moment() > date
                                                            )
                                                        }}
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    <label>Asset:</label>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        name="deviceId"
                                                        value={selectedDevice}
                                                        onChange={this.handleDeviceChange}
                                                        options={this.populateAssetsDropdown()}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="text-right">
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CSVLink
                                            data={this.state.exportData}
                                            onClick={this.exportToCSV}
                                            filename="Accumine_Downtime_History.csv">
                                            Click to export downtime log
                                        </CSVLink>
                                    </Col>
                                </Row>
                                <CRUD
                                    uniqueElementId="_id"
                                    emptyCrudMainText="No Downtime Entries Found"
                                    emptyCrudSubText=""
                                    crudTableColumns={this.columns()}
                                    crudTableResolveDataMapFn={
                                        this.dataTableFormatter
                                    }
                                    crudTableData={this.state.data}
                                    rowClickFn={() => {}}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.loading ? <div className="loading" /> : null}
                </Row>
                {this.state.showModal ? (
                    <Modal
                        isOpen={this.state.showModal}
                        toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>
                            Set Downtime Reason
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="12">
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="reason"
                                                value={this.state.selection}
                                                onChange={
                                                    this.handleDowntimeSelection
                                                }
                                                options={options}
                                                styles={customStyles}
                                            />
                                            <IntlMessages id="Enter Downtime Reason" />
                                        </Label>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="textarea"
                                                name="commentEntry"
                                                rows={5}
                                                value={this.state.comment}
                                                onChange={
                                                    this
                                                        .handleDowntimeCommentEntry
                                                }
                                            />
                                            <IntlMessages
                                                id={'Optional Comment'}
                                            />
                                        </Label>
                                        {this.hasClearPermission ? (
                                            <Row>
                                                <Col xs="4">
                                                    <Button
                                                        color="danger"
                                                        className="btn btn-block"
                                                        onClick={this.clear}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col xs="8">
                                                    <Button
                                                        className="btn btn-primary btn-block"
                                                        onClick={this.submit}>
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col xs="12">
                                                    <Button
                                                        className="btn btn-primary btn-block"
                                                        onClick={this.submit}>
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </Form>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                ) : null}
                {this.state.showSplitModal ? (
                    <Modal
                        isOpen={this.state.showSplitModal}
                        toggle={this.toggleSplitModal}
                        size="lg">
                        <ModalHeader toggle={this.toggleSplitModal}>
                            Split Downtime Period
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Form>
                                        <Label className="form-group">
                                            Select a point in time to split:
                                        </Label>
                                        <p className="text-center">
                                            {this.state.splitPoint.format(
                                                'YYYY-MM-DD h:mma'
                                            )}
                                        </p>
                                        <SliderWithTooltip
                                            min={0}
                                            max={
                                                this.state.record.timeEnd
                                                    ? moment(
                                                          this.state.record
                                                              .timeEnd
                                                      ).diff(
                                                          moment(
                                                              this.state.record
                                                                  .timeStart
                                                          ),
                                                          'minutes'
                                                      )
                                                    : moment().diff(
                                                          moment(
                                                              this.state.record
                                                                  .timeStart
                                                          ),
                                                          'minutes'
                                                      )
                                            }
                                            defaultValue={0}
                                            onChange={(value) => {
                                                this.setState({
                                                    splitPoint: moment(
                                                        this.state.record
                                                            .timeStart
                                                    ).add(value, 'minutes'),
                                                })
                                            }}
                                        />
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <Button
                                        className="btn btn-primary btn-block"
                                        onClick={
                                            this.submitSplitOnFirstPartition
                                        }>
                                        Split and assign existing reason to{' '}
                                        <strong>first partition</strong>
                                    </Button>
                                </Col>
                                <Col xs="6">
                                    <Button
                                        className="btn btn-primary btn-block"
                                        onClick={
                                            this.submitSplitOnSecondPartition
                                        }>
                                        Split and assign existing reason to{' '}
                                        <strong>second partition</strong>
                                    </Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}
