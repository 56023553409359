import React, {Component, Fragment} from 'react'
import {Row, Card, CardHeader, CardBody, CardTitle} from 'reactstrap'
import Sortable from 'react-sortablejs'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'

// Accumine SDK imports
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {getLiveAssets, fetchShiftTimerange} from 'SDK/api/common'

// Accumine component imports
import Tile from './tile'

export default class UtilizationDisplay extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'realtimeactiveshift',
            component: 'RealTimeActiveShift',
        },
    ]
    showBorder = false
    id = 'UtilizationDisplay'
    requiredOutputs = ['In-Cycle']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        realtimeactiveshift: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
            utilization: null,
            live: null,
            order: [],
            downtimeReasons: [],
            timerange: [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().toISOString(),
            ],
        }
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
        let viewSettings = await API.get(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId,
            2
        )
        viewSettings.order = order
        await API.post(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId,
            viewSettings,
            2
        )
    }

    async getAssets() {
        const assets = await API.get('devices')
        if (!assets) {
            //alert('There was an error initalizing this view.');
        } else {
            this.setState({assets, order: assets.map((x) => x.deviceId)})
        }
    }

    async fetchLiveData() {
        let live = await API.post('live', {
            deviceId: this.state.assets.map((x) => x.deviceId),
        })
        if (!live) {
            //alert('There was an error fetching data');
        } else {
            let timerange = [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().toISOString(),
            ]

            if (this.props.realtimeactiveshift) {
                const keys = Object.keys(live)
                if (keys.length) {
                    let shifts = live[keys[0]].activeShifts
                    if (shifts.length) {
                        timerange = await fetchShiftTimerange(
                            keys[0],
                            shifts[0]
                        )
                    }
                }
            }
            this.setState({live, timerange})
        }
    }

    async fetchUtilization() {
        const {data} = await API.post('historical/aggregate', {
            timeStart: this.state.timerange[0],
            timeEnd: this.state.timerange[1],
            deviceId: this.state.assets.map((x) => x.deviceId),
            state: ['In-Cycle'],
            tight: false,
        })
        if (!data) {
            return // alert('There was an error fetching data');
        }

        let downtimeReasons = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: this.state.assets.map((a) => a.deviceId)},
                    name: 'Downtime Reason',
                    timeEnd: null,
                },
            },
            2
        )

        let utilization = {},
            cap = moment(this.state.timerange[1]).diff(
                moment(this.state.timerange[0]),
                'minutes'
            )
        for (let i in data) {
            utilization[data[i]._id.deviceId] = data[i].totalMinutes / cap
        }
        this.setState({utilization, downtimeReasons})
    }

    async componentWillMount() {
        await this.getAssets()
        await this.fetchUtilization()
        await this.fetchLiveData()
        this.subscriber.add(this.getAssets, 1000 * 60, 'getAssets')
        this.subscriber.add(
            this.fetchUtilization,
            1000 * 60,
            'fetchUtilization'
        )
        this.subscriber.add(this.fetchLiveData, 1000 * 15, 'fetchLiveData')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const isLoaded =
            this.state.utilizaton !== null && this.state.live !== null

        return (
            <Fragment>
                <Sortable
                    className="row icon-cards-row mb-2"
                    onChange={this.handleSort}>
                    {isLoaded ? (
                        this.state.order.map((x, i) => {
                            const m = this.state.assets.find(
                                (a) => a.deviceId === x
                            )
                            return (
                                <Tile
                                    key={i}
                                    device={m}
                                    timerange={this.state.timerange}
                                    utilization={this.state.utilization[x]}
                                    live={this.state.live[x]}
                                    downtimeReason={this.state.downtimeReasons.find(
                                        (d) => d.deviceId === m.deviceId
                                    )}
                                />
                            )
                        })
                    ) : (
                        <div className="loading"></div>
                    )}
                </Sortable>
            </Fragment>
        )
    }
}
