import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import {Textfit} from 'react-textfit'

// Accumine SDK imports
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import Material from 'SDK/ui/AMC2023/Material/Material'
import 'SDK/helpers.css'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.downtimeThreshold =
            this.props.device.downtimeThresholdSeconds || 300

        this.state = {
            flash: false,
            activeState: {
                color: 'rgb(35, 34, 35)',
                name: 'Loading',
                timestamp: null,
                elapsed: '...',
            },
            activeBreak: '',
            alertFlash: false,
            color: this.props.color,
            lightsOut: false,
        }
    }
    async componentWillMount() {
        this.getLiveStatus()
        this.subscriber.add(this.getLiveStatus, 2000, 'getLiveStatus')
        this.subscriber.add(
            this.updateActiveStateElapsedTime,
            1000,
            'updateActiveStateElapsedTime'
        )
        this.HMIEntry = this.props.HMIEntry || false
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    checkLightsOut(state) {
        this.setState({lightsOut: state.length > 0})
    }

    async getLiveStatus() {
        let data = this.props.data
        if (!data) return

        if (data.activeShiftBreaks.length) {
            this.setState({
                activeBreak: data.activeShiftBreaks[0].name,
            })
        } else {
            this.setState({
                activeBreak: '',
            })
        }
        let activeStates = data.activeStates

        this.checkLightsOut(
            activeStates.filter((state) => state.name === 'Lights Out')
        )

        if (activeStates.length === 0) {
            this.setState({
                activeState: {
                    color: 'rgb(35, 34, 35)',
                    name: 'No Data',
                    timestamp: null,
                    elapsed: '-',
                },
            })
            return
        }

        let flash = false

        activeStates = activeStates.sort((a, b) => a.priority - b.priority)
        activeStates = activeStates.filter(
            (state) =>
                state.name === 'Downtime' ||
                state.name === 'In-Cycle' ||
                state.name === 'Machine Off' ||
                state.name === 'Machine Waiting'
        )
        let activeState = activeStates[0]
        if (activeState) {
            if (activeState.name === 'Downtime') {
                /*const downtimeReason = await API.post('historical/raw', {
          query: {
            deviceId: this.props.device.deviceId,
            name: 'Downtime Reason',
            timeEnd: null
          }
        }, 2);*/
                const downtimeReason = this.props.downtimeReason
                    ? [this.props.downtimeReason]
                    : []

                if (downtimeReason.length) {
                    activeState.name = downtimeReason[0].value
                    activeState.color = downtimeReason[0].color
                }

                if (
                    moment().diff(moment(activeState.timestamp), 'seconds') >
                    this.downtimeThreshold
                ) {
                    flash = true
                }
            }

            activeState.elapsed = secondsToHHMMSS(
                moment().diff(moment(activeState.timestamp), 'seconds')
            )
            this.setState({activeState, flash})
        }
    }
    updateActiveStateElapsedTime() {
        let backgroundColor = tileColor(this.state.activeState.name),
            isRed = false
        if (this.state.flash && this.state.isRed && this.props.flash) {
            backgroundColor = 'black'
        } else if (this.state.flash) {
            isRed = true
        }

        if (this.state.activeState.timestamp === null) return
        let activeState = {...this.state.activeState}
        activeState.elapsed = secondsToHHMMSS(
            moment().diff(moment(activeState.timestamp), 'seconds')
        )
        this.setState({
            activeState,
            backgroundColor: this.state.color
                ? this.state.color
                : backgroundColor,
            isRed,
        })
    }

    render() {
        const {lightsOut} = this.state
        const {size} = this.props
        return (
            <Col
                data-id={this.props.device.deviceId}
                xs="12"
                sm={size || 2}
                className="mb-4">
                <Card style={{background: this.state.backgroundColor}}>
                    <CardHeader
                        onClick={() => {
                            this.props.handleClickOnTile(
                                this.props.device.deviceId
                            )
                        }}
                        className="mt-2"
                        style={{color: '#fff'}}>
                        <h3 style={{textAlign: 'center'}}>
                            <Textfit mode="single" forceSingleModeWidth={false}>
                                {this.props.device.name}
                            </Textfit>
                        </h3>
                    </CardHeader>
                    <CardBody
                        onClick={() => {
                            this.props.handleClickOnTile(
                                this.props.device.deviceId
                            )
                        }}
                        style={{padding: '0px', margin: '0px'}}>
                        <Row>
                            <Col xs="12" sm="12" className="text-center">
                                <h1
                                    style={{
                                        color: '#fff',
                                        fontSize: size == 12 ? 75 : 'auto',
                                    }}>
                                    {this.state.activeState.elapsed}
                                </h1>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <p
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                marginBottom: 0,
                            }}>
                            <Textfit mode="single" forceSingleModeWidth={false}>
                                {this.state.activeState.name}
                            </Textfit>
                            <small style={{color: '#fff', textAlign: 'center'}}>
                                {this.state.activeBreak}
                                {lightsOut ? <>Lights Out</> : null}
                            </small>
                            {this.props.HMIEntryEnabled ? (
                                <this.props.HMIEntry
                                    device={this.props.device}
                                />
                            ) : null}
                            {this.props.MaterialEntry ? (
                                <div>
                                    <Material
                                        options={[
                                            {value: 123, label: 'PART 1'},
                                        ]}
                                        deviceId={this.props.device.deviceId}
                                        deviceName={this.props.device.name}
                                    />
                                </div>
                            ) : null}
                        </p>
                    </CardFooter>
                </Card>
            </Col>
        )
    }
}
