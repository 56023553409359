import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'

import Subscriber from 'SDK/subscriber'

const timeToMinutesSeconds = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
}

const timeToHoursMinutes = (s) => {
    let hours = Math.floor(s / 3600),
        minutes = Math.floor((s - hours * 3600) / 60)

    if (minutes === 0) {
        return hours + 'h'
    } else {
        return hours + 'h ' + minutes + 'm'
    }
}

const formatSeconds = (s) => {
    if (s / 3600 >= 1) {
        return timeToHoursMinutes(s)
    } else {
        return timeToMinutesSeconds(s)
    }
}

import './tile.css'

export default class Timer extends Component {
    static propTypes = {
        timestamp: PropTypes.date,
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            elapsed: '0:00',
        }
    }

    updateElapsedTime() {
        const m = moment(this.props.timestamp)
        if (m.isValid()) {
            this.setState({
                elapsed: formatSeconds(moment().diff(m, 'seconds')),
            })
        }
    }

    async componentWillMount() {
        this.subscriber.add(this.updateElapsedTime, 1000, 'updateElapsedTime')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return <Fragment>{this.state.elapsed}</Fragment>
    }
}
