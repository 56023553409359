import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            permissions: this.props.permissions.map((p) => {
                return {label: p, value: p}
            }),
        }
    }

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('editModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('editModal')
                        }}>
                        View Permissions
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <Label>
                                    Roles (start typing to create a new role):
                                </Label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="role"
                                    isMulti
                                    value={this.state.permissions}
                                    onChange={(permissions) => {
                                        this.setState({permissions})
                                    }}
                                    options={this.props.availablePermissions.map(
                                        (p) => {
                                            return {label: p, value: p}
                                        }
                                    )}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.props.setPermissions(
                                    this.state.permissions.map((p) => p.value)
                                )
                            }}>
                            Save Permissions
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
