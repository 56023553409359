import React, {Component, Fragment} from 'react'
import {Button} from 'reactstrap'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from '@react-pdf/renderer'
import {PDFDownloadLink} from '@react-pdf/renderer'
import autoBind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'

export default class BarcodeSheets extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            requested: false,
            loading: false,
            document: null,
        }
    }

    componentDidMount() {}

    makeAssetPage(deviceId, reasoncodes) {
        const relevantReasonCodes = reasoncodes.filter((r) => {
            return (
                r.assets === undefined || r.assets.find((a) => a === deviceId)
            )
        })

        let categories = [
            ...new Set(relevantReasonCodes.map((r) => r.category)),
        ]
        categories = categories.sort()

        for (let i in categories) {
            categories[i] = {
                name: categories[i],
                reasoncodes: relevantReasonCodes
                    .filter((r) => r.category === categories[i])
                    .sort((a, b) => a.reason.localeCompare(b.reason)),
            }
        }

        const deviceName = this.props.devices.find(
            (d) => d.deviceId === deviceId
        ).name

        return (
            <Page
                size="LETTER"
                style={{backgroundColor: '#fff', margin: 20, padding: 20}}>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flexGrow: 1}}>
                        <Image
                            style={{height: 75, width: 200}}
                            src="/assets/img/logo.png"
                        />
                    </View>
                    <View style={{flexGrow: 1, marginTop: 30}}>
                        <Text style={{fontWeight: 'bold'}}>{deviceName}</Text>
                    </View>
                </View>

                {categories.map((c, idx) => {
                    return (
                        <View key={idx} style={{border: '1px solid gray'}}>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    borderBottom: '1px solid gray',
                                }}>
                                {c.name}
                            </Text>
                            <View
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                }}>
                                {c.reasoncodes.map((r, idx) => {
                                    return (
                                        <View
                                            key={idx}
                                            style={{
                                                width: '50%',
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingBottom: 5,
                                                textAlign: 'center',
                                            }}>
                                            <Text
                                                style={{
                                                    fontSize: '10px',
                                                    fontWeight: 'bolder',
                                                }}>
                                                {r.reason}
                                            </Text>
                                            <Image src={r.barcode}></Image>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
                <Text
                    style={{
                        position: 'absolute',
                        fontSize: 12,
                        bottom: 30,
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        color: 'grey',
                    }}>
                    Accumine Downtime ScanSheet (generated on: {this.state.date}
                    )
                </Text>
            </Page>
        )
    }

    async makeDocument() {
        this.setState({loading: true, date: moment().format('llll')})
        const reasoncodes = await API.get('reasoncodes/barcodes', 2)

        const document = (
            <Document>
                {this.props.devices.map((d) =>
                    this.makeAssetPage(d.deviceId, reasoncodes)
                )}
            </Document>
        )

        this.setState({
            loading: false,
            requested: true,
            document,
        })
    }

    reset() {
        this.setState({requested: false, loading: false, document: null})
    }

    render() {
        return (
            <Fragment>
                {this.state.requested ? (
                    <Fragment>
                        {this.state.loading ? null : (
                            <Button color="secondary" onClick={this.reset}>
                                <PDFDownloadLink
                                    style={{color: '#fff'}}
                                    document={this.state.document}
                                    fileName="Downtime Barcode Scanner Sheets.pdf">
                                    {({blob, url, loading, error}) =>
                                        loading
                                            ? 'Generating...'
                                            : 'Download ScanSheet'
                                    }
                                </PDFDownloadLink>
                            </Button>
                        )}
                    </Fragment>
                ) : (
                    <Button color="secondary" onClick={this.makeDocument}>
                        Generate ScanSheet
                    </Button>
                )}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
