import React, {Fragment, Component} from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
} from 'reactstrap'
import autobind from 'auto-bind'

import {peakUsageByTimeOfDay} from '../Common/Twin/Metrics'
import PeakUsageChart from '../Common/Visuals/PeakUsageChart'

const comparisonDictionary = {
    last1: {
        label: 'Previous',
        subtitle: 'Comparing against previous period',
    },
    all: {
        label: 'All previous data',
        subtitle: 'Comparing against all data',
    },
    none: {
        label: 'none',
        subtitle: 'No comparison selected',
    },
}

export default class PeakUsageSummary extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            compareDropdownOpen: false,
            compareBy: 'none',
            data: null,
        }

        this.unix = 0
    }

    toggleCompareDropdown() {
        this.setState({compareDropdownOpen: !this.state.compareDropdownOpen})
    }

    async shouldComponentUpdate(nextProps, nextState) {
        if (
            this.unix !== nextProps.unix ||
            this.state.compareBy !== nextState.compareBy
        ) {
            this.unix = nextProps.unix
            await this.generateData(nextState.compareBy)
            return true
        } else {
            return false
        }
    }

    async generateData(compareBy) {
        this.setState({
            data: await peakUsageByTimeOfDay(
                this.props.capacityAnalyzer,
                compareBy
            ),
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <div className="float-left float-none-xs">
                            <div className="d-inline-block">
                                <h5 className="d-inline">Peak Usage Summary</h5>
                                <span className="text-muted text-small d-block">
                                    {
                                        comparisonDictionary[
                                            this.state.compareBy
                                        ].subtitle
                                    }
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="btn-group float-right float-none-xs mt-2 mb-5">
                            <Dropdown
                                isOpen={this.state.compareDropdownOpen}
                                toggle={this.toggleCompareDropdown}>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn-xs"
                                    outline
                                    disabled={this.props.loading}>
                                    Compare
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({compareBy: 'last1'})
                                        }}
                                        active={
                                            this.state.compareBy === 'last1'
                                        }
                                        disabled={
                                            this.state.compareBy === 'last1'
                                        }>
                                        Previous Period
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({compareBy: 'all'})
                                        }}
                                        active={this.state.compareBy === 'all'}
                                        disabled={
                                            this.state.compareBy === 'all'
                                        }>
                                        All Periods
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({compareBy: 'none'})
                                        }}
                                        active={this.state.compareBy === 'none'}
                                        disabled={
                                            this.state.compareBy === 'none'
                                        }>
                                        None
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>

                {this.state.data ? (
                    <Row>
                        <Col>
                            <PeakUsageChart
                                data={this.state.data}
                                trendlineLabel={
                                    comparisonDictionary[this.state.compareBy]
                                        .label
                                }
                                height={this.props.height * 0.7}
                            />
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
