import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    InputGroup,
    Input,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import Select from 'react-select'

import IntlMessages from 'util/IntlMessages'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import PointOfInterest from './PointOfInterest'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.height = document.documentElement.offsetHeight * 0.5
        this.width = document.documentElement.offsetWidth * 0.5

        this.subscriber = new Subscriber()

        this.state = {
            selectedDevice: null,
            devices: [],
            pois: this.props.editingModel.pois
                ? this.props.editingModel.pois.map((p) => {
                      p.static = true
                      return p
                  })
                : [],
            backgroundImage: this.props.editingModel.backgroundImage || null,
            training: null,
        }
    }

    async getDevices() {
        let devices = await API.get('ips/cloud/devices', 2),
            assets = await API.get('devices')

        if (this.state.training) {
            // check if training session is finished
            const device = devices.find(
                (d) => d.serialNumber === this.state.selectedDevice.value
            )
            if (device && device.command.value === '') {
                this.setState({
                    training: null,
                    pois: this.props.editingModel.pois.map((p) => {
                        p.static = true
                        return p
                    }),
                })
            }
        }

        // filter devices with the right model
        devices = devices.filter(
            (d) => d.model === this.props.editingModel.version
        )
        //filter devices that are online
        devices = devices.filter(
            (d) => moment().diff(moment(d.lastSync), 'minutes') < 1
        )

        devices = devices.map((d) => {
            const asset = assets.find((a) => a.deviceId === d.deviceId)
            let obj = {
                label: null,
                value: d.serialNumber,
            }

            if (asset) {
                obj.label = asset.name
            }
            return obj
        })

        devices = devices.filter((d) => d.label)

        this.setState({devices})
    }

    toggleTrainModel({id, name}) {
        if (this.state.training) {
            this.setState({training: null})
        } else {
            this.setState({training: {id, name}})
            API.patch(
                'ips/cloud/devices/' +
                    this.state.selectedDevice.value +
                    '/train',
                {id},
                2
            )
        }
    }

    componentWillMount() {
        this.subscriber.add(this.getDevices, 1000 * 5, 'getDevices')
    }

    render() {
        const {modal, toggleModal} = this.props,
            height = document.documentElement.offsetHeight * 0.5 + 'px',
            version = this.props.editingModel
                ? this.props.editingModel.version
                : ''

        return (
            <Fragment>
                <Modal isOpen={modal} size="lg">
                    <ModalHeader>Version {version} Trainer</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Label className="form-group has-top-label">
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="select-device"
                                        value={this.state.selectedDevice}
                                        options={this.state.devices}
                                        onChange={(selectedDevice) => {
                                            this.setState({selectedDevice})
                                        }}
                                    />
                                    <IntlMessages id="Choose a field device" />
                                </Label>
                            </Col>
                        </Row>

                        {this.state.selectedDevice &&
                        this.state.training === null ? (
                            <Row>
                                <Col>
                                    <p className="text-center">
                                        <b>
                                            When the field device is physically
                                            within the bounds of the Point of
                                            Interest
                                        </b>
                                        , double a click the region to begin
                                        training.
                                    </p>
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.training ? (
                            <Row>
                                <Col>
                                    <p className="text-center text-danger">
                                        <b>
                                            Point of Interest "
                                            {this.state.training.name}" is being
                                            trained. Ensure the field device
                                            remains within the bounds of "
                                            {this.state.training.name}".
                                        </b>
                                    </p>
                                    <p className="text-center text-muted">
                                        This can take a few minutes...
                                    </p>
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.selectedDevice ? (
                            <Row>
                                <Col
                                    style={{
                                        height: this.height + 'px',
                                        width: this.width + 'px',
                                    }}>
                                    <TransformWrapper
                                        options={{
                                            limitToBounds: false,
                                        }}
                                        wheel={{
                                            step: 2,
                                        }}
                                        defaultScale={1}
                                        doubleClick={{disabled: true}}>
                                        {({scale, ...rest}) => (
                                            <>
                                                <div
                                                    style={{
                                                        width: 'fit-content',
                                                        height: '100%',
                                                        overflowX: 'hidden',
                                                    }}>
                                                    <TransformComponent>
                                                        <img
                                                            style={{
                                                                width: this
                                                                    .width,
                                                                height: this
                                                                    .height,
                                                            }}
                                                            src={
                                                                this.state
                                                                    .backgroundImage
                                                            }
                                                            alt="test"
                                                        />
                                                        {this.state.pois.map(
                                                            (p, idx) => {
                                                                return (
                                                                    <PointOfInterest
                                                                        key={
                                                                            idx
                                                                        }
                                                                        id={
                                                                            p.id
                                                                        }
                                                                        name={
                                                                            p.name
                                                                        }
                                                                        scale={
                                                                            scale
                                                                        }
                                                                        updatePOI={
                                                                            this
                                                                                .updatePOI
                                                                        }
                                                                        width={
                                                                            p.width
                                                                        }
                                                                        height={
                                                                            p.height
                                                                        }
                                                                        x={p.x}
                                                                        y={p.y}
                                                                        samples={
                                                                            p
                                                                                .training
                                                                                .samples
                                                                        }
                                                                        static={
                                                                            true
                                                                        }
                                                                        toggleEditMode={
                                                                            this
                                                                                .toggleTrainModel
                                                                        }
                                                                        beingEdited={
                                                                            this
                                                                                .state
                                                                                .training &&
                                                                            this
                                                                                .state
                                                                                .training
                                                                                .id ===
                                                                                p.id
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </TransformComponent>
                                                </div>
                                            </>
                                        )}
                                    </TransformWrapper>
                                </Col>
                            </Row>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn"
                            onClick={toggleModal}>
                            Close Trainer
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
