import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            borderColor: 'rgb(215,215,215)',
            valid: false,
            message: true,
            name: '',
            templates: [],
        }
    }
    async submit() {
        try {
            await API.get(
                'environments/' +
                    this.props.environment._id +
                    '/publish?name=' +
                    this.state.name,
                2
            )
        } catch (error) {
            console.log(error)
            alert('There was an error publishing your application.')
        }

        this.props.toggleModal()
    }

    async componentWillMount() {
        const templates = await API.get('environments/templates', 2)
        console.log(templates)
        this.setState({
            templates,
        })
    }

    componentWillUnmount() {}

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Publish Application
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <p>
                                    Publishing your application to the Accumine
                                    Cloud will not distribute any company data
                                    (i.e. assets, shifts, users) across the
                                    Accumine Cloud.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Please note that custom visualizations from
                                    the Insight Engine will not appear within
                                    the template. In addition, default and user
                                    level settings will have to be reconfigured.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <Label>Name:</Label>
                                <Input
                                    style={{
                                        borderColor: this.state.borderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.state.templates.find(
                                                (t) => t.name === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'A template with this name already exists.'
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            borderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.submit}>
                            Publish
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
