import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    ButtonGroup,
    Breadcrumb,
    BreadcrumbItem,
} from 'reactstrap'
import autobind from 'auto-bind'

import Navigation from '../Navigation'
import AddNewLot from './AddNewLot'
import ReleaseLot from './ReleaseLot'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({name: 'Raw Material', fn: this.reset})

        this.state = {
            selectedSection: null,
        }
    }

    reset() {
        this.setState({selectedSection: null})
    }

    render() {
        return (
            <Fragment>
                {this.state.selectedSection === null ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <Navigation path={this.path} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Raw Material</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <ButtonGroup vertical>
                                    <Button
                                        size="xl"
                                        onClick={() =>
                                            this.setState({
                                                selectedSection: 'Add New Lot',
                                            })
                                        }>
                                        ADD NEW LOT / ADD TO INVENTORY
                                    </Button>
                                    {this.props.role === 'BMR - Admin' ||
                                    this.props.role === 'Administrator' ? (
                                        <Button
                                            size="xl"
                                            onClick={() =>
                                                this.setState({
                                                    selectedSection:
                                                        'Release Lot',
                                                })
                                            }>
                                            RELEASE LOT
                                        </Button>
                                    ) : null}
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <Fragment>
                        {this.state.selectedSection === 'Add New Lot' ? (
                            <AddNewLot
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                path={this.path}
                            />
                        ) : null}
                        {this.state.selectedSection === 'Release Lot' ? (
                            <ReleaseLot
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                path={this.path}
                            />
                        ) : null}
                    </Fragment>
                )}
            </Fragment>
        )
    }
}
