import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
} from 'reactstrap'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash, iteratePlaceholderName} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.groupOptions = [
            ...new Set(this.props.schemas.map((s) => s.group)),
        ].map((g) => {
            return {label: g, value: g}
        })

        const group = this.props.editingSchema.group

        this.state = {
            nameInputBorderColor: 'rgb(215,215,215)',
            name: this.props.editingSchema.name,
            columns: this.props.editingSchema.columns,
            group: {label: group, value: group},
            valid: true,
            message: false,
        }
    }

    handleGroupEvent(group) {
        this.setState({group})
    }

    async save() {
        const obj = {
            name: this.state.name,
            columns: this.state.columns,
            group: this.state.group.value,
        }

        await API.patch('schemas/' + this.props.editingSchema._id, obj, 2)

        this.props.toggleModal('editModal')
    }

    renderColumnsList() {
        return this.state.columns.map((b) => {
            return (
                <tr key={b.id}>
                    <td>
                        <Input
                            value={
                                this.state.columns.find((a) => a.id === b.id)
                                    .name
                            }
                            style={{
                                borderTop: 'transparent',
                                borderRight: 'transparent',
                                borderLeft: 'transparent',
                                backgroundColor: 'transparent',
                            }}
                            onChange={(e) => {
                                const columns = this.state.columns
                                columns.find((a) => a.id === b.id).name =
                                    e.target.value
                                this.setState({columns})
                            }}
                        />
                    </td>
                    <td
                        style={{
                            textAlign: 'right',
                            paddingTop: '20px',
                        }}>
                        <Button
                            size="xs"
                            color="danger"
                            onClick={() => {
                                let columns = this.state.columns
                                columns = columns.filter((a) => a.id !== b.id)
                                this.setState({columns})
                            }}>
                            <i className="simple-icon-trash" />
                        </Button>
                    </td>
                </tr>
            )
        })
    }

    addColumn() {
        let columns = this.state.columns
        columns.push({
            id: generateHash(),
            name: iteratePlaceholderName(columns, 'New Column'),
        })

        this.setState({
            columns,
        })
    }

    render() {
        const {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    size="lg"
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('editModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('editModal')
                        }}>
                        Edit Data Schema
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="6" className="mb-4">
                                <label>Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    placeholder="New Schema"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.props.schemas.find(
                                                (schema) => schema.name === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'A Schema with this name already exists.'
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            nameInputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                            </Col>
                            <Col xs="6" className="mb-4">
                                <label>Group (e.g. Payroll):</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="group"
                                    value={this.state.group}
                                    onChange={this.handleGroupEvent}
                                    options={this.groupOptions}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Columns:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{width: '90%'}}>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderColumnsList()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    size="xs"
                                    className="btn-block"
                                    onClick={this.addColumn}>
                                    Add Column
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('editModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
