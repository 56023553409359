import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Label,
    Form,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'

import LightsOutModule from './LightsOutModule'
import './DowntimeEntryModule.css'

const color = (name) => {
    if (name === 'In-Cycle') return 'rgb(46, 204, 113)'
    else if (
        name === 'Downtime Under 5 Minutes' ||
        name === 'Uncategorized Downtime'
    )
        return 'rgb(231, 76, 60)'
    else return '#f0ad4e'
}
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

export default class DowntimeEntryModule extends Component {
    deprecated = true
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'DowntimeEntryModule'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
        disableSearch: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            forceEntry: false,
            status: 'unknown',
            selection: null,
            commentEntry: '',
        }
    }

    async determineStatus() {
        // running, downUnderThreshold, downOverThresholdWithNoEntry, downOverThresholdWithEntry, unknown

        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: [
                            'In-Cycle',
                            'Downtime',
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        if (!data || !data.length) {
            return
        }

        let status = '',
            timestamp = null,
            category = '',
            reason = '',
            comment = '',
            user = '',
            downtime = data.find((o) => o.name === 'Downtime'),
            inCycle = data.find((o) => o.name === 'In-Cycle'),
            downtimeCategory = data.find((o) => o.name === 'Downtime Category'),
            downtimeReason = data.find((o) => o.name === 'Downtime Reason'),
            downtimeComment = data.find((o) => o.name === 'Downtime Comment'),
            downtimeUser = data.find((o) => o.name === 'Downtime User'),
            color = downtimeReason
                ? this.state.reasoncodes.find(
                      (rc) => rc.reason === downtimeReason.value
                  ).color
                : 'lightgrey'

        if (inCycle) {
            status = 'running'
            color = 'rgb(46, 204, 113)'
            timestamp = moment(inCycle.timeStart)
        } else if (
            downtime &&
            moment().diff(moment(downtime.timeStart), 'seconds') <
                this.props.threshold &&
            !downtimeCategory &&
            !downtimeReason
        ) {
            timestamp = moment(downtime.timeStart)
            status = 'downUnderThreshold'
            color = 'rgb(231, 76, 60)'
        } else if (
            (downtime &&
                moment().diff(moment(downtime.timeStart), 'seconds') >=
                    this.props.threshold) ||
            (downtimeCategory && downtimeReason)
        ) {
            timestamp = moment(downtime.timeStart)
            if (downtimeCategory && downtimeReason) {
                status = 'downOverThresholdWithEntry'
                color = color || '#f0ad4e'
                category = downtimeCategory.value
                reason = downtimeReason.value
                if (downtimeComment) comment = downtimeComment.value
                if (downtimeUser) user = downtimeUser.value
            } else {
                color = 'rgb(231, 76, 60)'
                status = 'downOverThresholdWithNoEntry'
            }
        }

        this.setState({
            status,
            color,
            timestamp,
            category,
            reason,
            comment,
            user,
        })
    }

    updateElapsed() {
        let tileColorStatus = 'Downtime'
        if (this.state.status === 'running') {
            tileColorStatus = 'In-Cycle'
        } else if (this.state.status === 'downOverThresholdWithEntry') {
            tileColorStatus = ''
        }
        let backgroundColor = tileColor(tileColorStatus),
            isRed = false
        if (
            this.state.status === 'downOverThresholdWithNoEntry' &&
            this.state.isRed
        ) {
            backgroundColor = 'black'
        } else if (this.state.status === 'downOverThresholdWithNoEntry') {
            isRed = true
        }

        this.setState({
            elapsed: moment().diff(this.state.timestamp),
            backgroundColor,
            isRed,
        })
    }

    handleDowntimeSelection(selection) {
        this.setState({selection})
    }

    submit() {
        const userId = JSON.parse(localStorage['userObject'])._id
        const record = this.state.reasoncodes.find(
            (a) => a._id === this.state.selection.value
        )
        API.post(
            'tablets/data',
            [
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: record.reason,
                    name: 'Downtime Reason',
                    src: 'DowntimeEntryModule.js',
                },
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: record.category,
                    name: 'Downtime Category',
                    src: 'DowntimeEntryModule.js',
                },
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: this.state.commentEntry,
                    name: 'Downtime Comment',
                    src: 'DowntimeEntryModule.js',
                },
                {
                    nodeId: this.props.deviceId,
                    timestamp: moment(this.state.timestamp).valueOf(),
                    value: userId,
                    name: 'Downtime User',
                    src: 'DowntimeEntryModule.js',
                },
            ],
            2
        )
        this.setState({
            forceEntry: false,
            selection: null,
            commentEntry: '',
        })
    }

    async undoReason() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: [
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        for (let i in data) {
            API.remove('states/' + data[i]._id, 2)
        }
    }

    async changeReason() {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {
                        $in: [
                            'Downtime',
                            'Downtime Category',
                            'Downtime Reason',
                            'Downtime Comment',
                            'Downtime User',
                        ],
                    },
                    timeEnd: null,
                },
            },
            2
        )

        const timeEnd = moment().toDate()

        let newDowntime = {
            nodeId: this.props.deviceId,
            deviceId: this.props.deviceId,
            value: true,
            name: 'Downtime',
            timestamp: timeEnd,
            timeStart: timeEnd,
            timeEnd: null,
            metaData: [],
        }
        await API.post('states', newDowntime, 2)

        for (let i in data) {
            data[i].timeEnd = timeEnd
            await API.patch('states/' + data[i]._id, data[i], 2)
        }

        this.setState({forceEntry: true})
    }

    renderRunningStatus() {
        return (
            <Row>
                <Col className="text-center" xs="12">
                    <h1>
                        {secondsToHHMMSS(Math.floor(this.state.elapsed / 1000))}
                    </h1>
                    <h5>Asset is running</h5>
                </Col>
            </Row>
        )
    }

    renderDowntimeUnderThresholdStatus() {
        return (
            <Row>
                <Col className="text-center">
                    <h1>
                        {secondsToHHMMSS(Math.floor(this.state.elapsed / 1000))}
                    </h1>
                    <h5>Asset is down</h5>
                </Col>
            </Row>
        )
    }

    handleDowntimeCommentEntry(e) {
        this.setState({commentEntry: e.target.value})
    }

    renderDowntimeOverThresholdWithNoEntry() {
        let reasoncodes = this.state.reasoncodes.filter((code) => {
            if (!code.assets) {
                return true
            } else {
                return code.assets.find((a) => a === this.props.deviceId)
            }
        })

        let categories = [...new Set(reasoncodes.map((r) => r.category))]
        categories = categories.sort((a, b) => a.localeCompare(b))

        let options = categories.map((c) => {
            const suboptions = reasoncodes
                .filter((r) => r.category === c)
                .map((r) => {
                    return {
                        label: r.reason,
                        value: r._id,
                    }
                })
                .sort((a, b) => a.label.localeCompare(b.label))

            return {
                label: c,
                options: suboptions,
            }
        })
        return (
            <Row>
                <Col className="text-center">
                    <Row>
                        <Col>
                            <h1>
                                {secondsToHHMMSS(
                                    Math.floor(this.state.elapsed / 1000)
                                )}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form>
                                <Label className="form-group has-top-label">
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select forceLeftAlign"
                                        classNamePrefix="react-select"
                                        name="reason"
                                        value={this.state.selection}
                                        onChange={this.handleDowntimeSelection}
                                        options={options}
                                        styles={customStyles}
                                        isSearchable={!this.props.disableSearch}
                                    />
                                    <IntlMessages id="Reason" />
                                </Label>
                                <Label className="form-group has-top-label">
                                    <Input
                                        type="textarea"
                                        name="commentEntry"
                                        rows={5}
                                        value={this.state.commentEntry}
                                        onChange={
                                            this.handleDowntimeCommentEntry
                                        }
                                    />
                                    <IntlMessages id={'Optional Comment'} />
                                </Label>
                                <Button
                                    className="btn btn-primary btn-block"
                                    disabled={!this.state.selection}
                                    onClick={this.submit}>
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    renderDowntimeOverThresholdWithEntry() {
        return (
            <Row>
                <Col className="text-center">
                    <Row>
                        <Col>
                            <h1>
                                {secondsToHHMMSS(
                                    Math.floor(this.state.elapsed / 1000)
                                )}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                {this.state.category} > {this.state.reason}
                            </h5>
                            <p>{this.state.comment}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6">
                            <Button
                                className="btn btn-primary"
                                onClick={this.undoReason}>
                                Undo Reason
                            </Button>
                        </Col>
                        <Col xs="12" sm="6">
                            <Button
                                className="btn btn-primary"
                                onClick={this.changeReason}>
                                Change Reason
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        this.setState({
            name: devices.find((d) => d.deviceId === this.props.deviceId).name,
            reasoncodes: await API.get('reasoncodes', 2),
        })
        this.subscriber.add(this.determineStatus, 3000, 'fetchStatus()')
        this.subscriber.add(this.updateElapsed, 1000, 'updateElapsed()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const running = this.state.status === 'running',
            downUnderThreshold =
                this.state.status === 'downUnderThreshold' &&
                !this.state.forceEntry,
            downOverThresholdWithNoEntry =
                this.state.status === 'downOverThresholdWithNoEntry' ||
                this.state.forceEntry,
            downOverThresholdWithEntry =
                this.state.status === 'downOverThresholdWithEntry' &&
                !this.state.forceEntry

        return (
            <Fragment>
                <Row style={{height: '100%'}}>
                    <Col style={{height: '100%'}}>
                        <Card
                            style={{
                                height: '100%',
                                backgroundColor: this.state.backgroundColor,
                                color: '#fff',
                            }}>
                            <CardBody style={{height: '100%'}}>
                                <CardTitle className="text-center">
                                    <strong>
                                        {this.state.name} - Downtime Reason
                                        Entry
                                    </strong>
                                </CardTitle>
                                {running ? this.renderRunningStatus() : null}
                                {downUnderThreshold
                                    ? this.renderDowntimeUnderThresholdStatus()
                                    : null}
                                {downOverThresholdWithNoEntry
                                    ? this.renderDowntimeOverThresholdWithNoEntry()
                                    : null}
                                {downOverThresholdWithEntry
                                    ? this.renderDowntimeOverThresholdWithEntry()
                                    : null}
                                {!this.props.hideLightsOutEntry ? (
                                    <Col
                                        className="text-center"
                                        style={{marginTop: 25}}>
                                        <LightsOutModule
                                            deviceId={this.props.deviceId}
                                        />
                                    </Col>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
