import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            template: {label: 'Blank Application', value: 'blank'},
            templates: [],
            name: '',
            borderColor: 'rgb(215,215,215)',
            valid: false,
            message: true,
        }
    }

    handleTemplateEvent(template) {
        this.setState({template})
    }

    async submit() {
        let environment = {
            name: this.state.name,
        }

        if (this.state.template.value === 'blank') {
            environment.sections = [
                {
                    id: generateHash(),
                    name: 'Section 1',
                    views: [
                        /*{
          id: generateHash(),
          name: 'New View 1',
          layout: [],
          custom: [],
          generic: [],
          divider: false,
          permissions: [],
          type: 'generic'
        }*/
                    ],
                },
            ]
        } else {
            const template = this.state.templates.find(
                (t) => t._id === this.state.template.value
            )
            environment.sections = template.sections
            environment.template = template._id
        }

        await API.post('environments', environment, 2)
        const environments = await API.get('environments', 2)
        this.props.toggleModal()
        this.props.handleAddNewEnvironmentSubmit(
            environments.find((env) => env.name === this.state.name)
        )
    }

    async componentWillMount() {
        let templates = await API.get('environments/templates', 2),
            environments = await API.get('environments', 2)
        this.setState({
            templates,
            environments,
        })
    }

    componentWillUnmount() {}

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Create Your Application
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Label>Select starting point:</Label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="template"
                                    value={this.state.template}
                                    onChange={this.handleTemplateEvent}
                                    options={[
                                        {
                                            label: 'Blank Application',
                                            value: 'blank',
                                        },
                                    ].concat(
                                        this.state.templates.map((t) => {
                                            return {label: t.name, value: t._id}
                                        })
                                    )}></Select>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs="12">
                                <Label>Name:</Label>
                                <Input
                                    style={{
                                        borderColor: this.state.borderColor,
                                    }}
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.state.environments.find(
                                                (t) => t.name === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'An application with this name already exists.'
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            borderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.submit}>
                            Start Building
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
