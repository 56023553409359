import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
} from 'reactstrap'
import autoBind from 'auto-bind'

import * as API from 'SDK/api'
import ModelTree from 'SDK/ui/widgets/Schemas/common/ModelTree'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.calendarSettingsId = this.props.dataModels.find(
            (model) => model.name === 'System/Calendar Settings'
        )._id

        this.state = {
            ready: false,
            event_background_color: '#ffffff',
            title_field_path: '',
            fieldTypes: [],
        }
    }

    async save() {
        this.setState({loading: true})

        if (this.existing) {
            let updated = {...this.existing}
            updated.title_field_path = this.state.title_field_path
            updated.event_background_color = this.state.event_background_color
            await API.patch(
                `data-models/${this.calendarSettingsId}/edit-record`,
                updated,
                2
            )
        } else {
            await API.post(
                `data-models/${this.calendarSettingsId}/add-record`,
                {
                    title_field_path: this.state.title_field_path,
                    event_background_color: this.state.event_background_color,
                    model_id: this.props.editingDataModel._id,
                },
                2
            )
        }
        this.setState({loading: false})
        this.props.toggle()
    }

    async componentDidMount() {
        let fieldTypes = await API.get('data-models/field-types', 2),
            title_field_path = '',
            event_background_color = '#ffffff'
        const existing = await API.post(
            `data-models/${this.calendarSettingsId}/paginate`,
            {
                filter: [
                    {
                        type: 'Text',
                        path: 'model_id',
                        value: [this.props.editingDataModel._id],
                        logic: 'is',
                    },
                ],
                sort: {},
                limit: 1,
            },
            2
        )

        if (existing.count) {
            title_field_path = existing.result.results[0].title_field_path
            event_background_color =
                existing.result.results[0].event_background_color
            this.existing = existing.result.results[0]
        }

        this.setState({
            fieldTypes,
            title_field_path,
            event_background_color,
            ready: true,
        })
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal isOpen={open} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Calendar Settings</ModalHeader>
                    <ModalBody>
                        {this.state.ready ? (
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label>
                                                Calendar event background color:
                                            </label>
                                            <Input
                                                type="color"
                                                value={
                                                    this.state
                                                        .event_background_color
                                                }
                                                onChange={(e) =>
                                                    this.setState({
                                                        event_background_color:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            Title to display on events:{' '}
                                            <b>
                                                {this.state.title_field_path ||
                                                    'None selected'}
                                            </b>
                                        </p>
                                        <ModelTree
                                            showMap={(item) =>
                                                ['SingleSubModel', 'ForeignID']
                                                    .concat(
                                                        this.state.fieldTypes
                                                            .filter(
                                                                (t) =>
                                                                    t.groupable
                                                            )
                                                            .map((t) => t.type)
                                                    )
                                                    .includes(item.type)
                                            }
                                            onSelect={(
                                                selectedKeys,
                                                {selectedNodes}
                                            ) => {
                                                this.setState({
                                                    title_field_path:
                                                        selectedNodes[0].path,
                                                })
                                            }}
                                            dataModel={
                                                this.props.editingDataModel
                                            }
                                            dataModels={this.props.dataModels}
                                            includeJoins={false}
                                            excludeJoinPrefixes={true}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <div className="loading" />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
