import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autobind from 'auto-bind'

import AssetCommission from './asset'
import RefVoltage300 from './refVoltage/3.0.0'
import RefVoltage3XX from './refVoltage/3.x.x'
import RefVoltage400 from './refVoltage/4.x.x'
import DataSamplingModal from './data-sampling'
import GatewayCommission from './gwy'

import * as API from 'SDK/api'

const dict = {
    open: 'Open Connection',
    close: 'Close Connection',
    start: 'Start Data Stream',
    stop: 'Stop Data Stream',
    reset: 'Restart SensorBot',
    pause: 'Pause Data Stream',
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.settings = this.props.editingNode.settings

        this.state = {
            valid: false,
            message: false,
            confirmDelete: false,
            loading: false,
            dataSamplingModal: false,
        }
    }

    toggleDataSamplingModal() {
        if (this.props.editingNode.version === '0.0.0') {
            return alert(
                'SensorBots must be assigned to a gateway before configuring data sampling'
            )
        }
        this.setState({dataSamplingModal: !this.state.dataSamplingModal})
    }

    askToConfirmDelete() {
        this.setState({confirmDelete: true})
    }
    revertDelete() {
        this.setState({confirmDelete: false})
    }
    async delete() {
        this.setState({loading: true})
        try {
            let response = await API.remove(
                'nodes/' + this.props.editingNode.nodeId,
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error deleting this SensorBot.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error deleting this SensorBot.')
        }
    }

    revertChanges() {
        alert('Empty fn')
    }

    async handleAction(action) {
        this.setState({loading: true})
        try {
            let node = {...this.props.editingNode}
            node.settings.action = action

            const response = await API.patch(
                'nodes/' + node.nodeId,
                {
                    settings: node.settings,
                },
                2
            )
            this.setState({loading: false})
            if (!response.success) {
                return alert('There was an error triggering the action.')
            } else {
                const x = (
                    <Fragment>
                        <p style={{marginBottom: '0px'}}>
                            <strong>{dict[action]}</strong> has been triggered.
                        </p>
                    </Fragment>
                )
                this.setState({actionMessage: x})
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error triggering the action.')
        }
    }

    closeModalAfterAction() {
        this.setState({loading: false})
        this.props.toggleModal()
    }

    renderDeleteButton() {
        if (!this.state.confirmDelete) {
            return (
                <Button color="danger" onClick={this.askToConfirmDelete}>
                    Delete SensorBot
                </Button>
            )
        } else {
            return null
        }
    }

    renderDeleteConfirmation() {
        if (this.state.confirmDelete) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Are you sure?</p>
                    <Button size="sm" color="danger" onClick={this.delete}>
                        Yes
                    </Button>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.revertDelete}>
                        No
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    renderActionsMenu() {
        const action = this.props.editingNode.settings.action
        if (action === '') {
            return (
                <Fragment>
                    <Row className="mb-4">
                        <Col>
                            <hr />
                            <div className="text-center">
                                <h4 className="text-center">Mesh Network</h4>
                                <small className="text-center text-muted">
                                    Triggering local mesh network actions only
                                    affects this SensorBot.
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('open')
                                }}>
                                Open Connection
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('close')
                                }}>
                                Close Connection
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('start')
                                }}>
                                Start Data Stream
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('stop')
                                }}>
                                Stop Data Stream
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('pause')
                                }}>
                                Pause Data Stream
                            </Button>
                        </Col>

                        <Col xs="12" sm="6" className="mb-2">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-block"
                                onClick={() => {
                                    this.handleAction('reset')
                                }}>
                                Restart SensorBot
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )
        } else if (action !== '') {
            const pendingAction = action
            return (
                <Fragment>
                    <Row className="mb-4">
                        <Col>
                            <hr />
                            <div className="text-center">
                                <h4 className="text-center">Mesh Network</h4>
                                <small className="text-center text-muted">
                                    Triggering global mesh network actions
                                    affects all SensorBots associated with this
                                    gateway.
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <p
                                style={{marginBottom: '5px'}}
                                className="text-muted">
                                Pending Action:
                            </p>
                            <h2>{dict[pendingAction]}</h2>
                            <Button
                                size="sm"
                                onClick={() => {
                                    this.handleAction('')
                                }}>
                                Cancel Action
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )
        }
    }

    componentWillMount() {
        try {
            const deviceId = this.props.nodes.find(
                (x) => x.nodeId === this.props.editingNode.nodeId
            ).deviceId
            const name =
                deviceId === 'none'
                    ? 'Uncomissioned SensorBot'
                    : this.props.assets.find((a) => a.deviceId === deviceId)
                          .name
            this.setState({name})
        } catch (error) {
            alert(
                'This SensorBot is commissioned to a deleted Asset. Please select new asset.'
            )
        }
    }

    componentWillUnmount() {}

    render() {
        let {modal, toggleModal, editingNode} = this.props
        const version = editingNode.version

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggleModal}>{name}</ModalHeader>
                    <ModalBody>
                        <AssetCommission
                            nodes={this.props.nodes}
                            assets={this.props.assets}
                            editingNode={this.props.editingNode}
                        />
                        <GatewayCommission
                            nodes={this.props.nodes}
                            gateways={this.props.gateways}
                            editingNode={this.props.editingNode}
                        />
                        <Row>
                            <Col>
                                <hr />
                                <div className="text-center">
                                    <h4 className="text-center">
                                        Data Sampling
                                    </h4>
                                    <small className="text-center text-muted">
                                        Modify the data sampling configuration
                                        for up to 6 unique data signals.
                                    </small>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    color="primary"
                                    className="btn-block"
                                    onClick={this.toggleDataSamplingModal}>
                                    Data Sampling Editor
                                </Button>
                                <hr />
                            </Col>
                        </Row>
                        {version === '3.0.0' ? (
                            <RefVoltage300
                                nodes={this.props.nodes}
                                gateways={this.props.gateways}
                                editingNode={this.props.editingNode}
                            />
                        ) : null}

                        {version.split('.')[0] == 3 &&
                        (version.split('.')[1] != 0 ||
                            version.split('.')[2] != 0) ? (
                            <RefVoltage3XX
                                nodes={this.props.nodes}
                                gateways={this.props.gateways}
                                editingNode={this.props.editingNode}
                            />
                        ) : null}

                        {version.split('.')[0] >= 4 ? (
                            <RefVoltage400
                                nodes={this.props.nodes}
                                gateways={this.props.gateways}
                                editingNode={this.props.editingNode}
                            />
                        ) : null}

                        {this.renderActionsMenu()}
                    </ModalBody>
                    <ModalFooter>
                        {this.renderDeleteButton()}
                        {this.renderDeleteConfirmation()}
                    </ModalFooter>
                </Modal>
                {this.state.dataSamplingModal ? (
                    <DataSamplingModal
                        toggleModal={this.toggleDataSamplingModal}
                        modal={this.state.dataSamplingModal}
                        nodes={this.props.nodes}
                        gateways={this.props.gateways}
                        assets={this.props.assets}
                        editingNode={this.props.editingNode}
                    />
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
