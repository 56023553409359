import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Collapse, Button, Badge} from 'reactstrap'
import PropTypes from 'prop-types'
import {Scrollbars} from 'react-custom-scrollbars'
import autobind from 'auto-bind'

import Subscriber from 'SDK/subscriber'

export default class extends Component {
    static propTypes = {
        devices: PropTypes.array,
        assets: PropTypes.array,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            accordion: [true, false, false],
            active: [],
            dormant: [],
            offline: [],
        }
    }

    toggleAccordion(tab) {
        const prevState = this.state.accordion
        const state = prevState.map((x, index) => (tab === index ? !x : false))
        this.setState({
            accordion: state,
        })
    }

    makeDeviceElement(device) {
        const status = device.status()
        return (
            <Row
                onMouseEnter={() => {
                    this.props.highlightPOI(device.lastKnownLocation.id)
                }}
                onMouseLeave={() => {
                    this.props.highlightPOI()
                }}>
                <Col className="ml-2 mt-1">
                    <Row>
                        <Col xs="12">
                            <h4>
                                <strong>{device.alias}</strong>
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <img
                                style={{width: '100%'}}
                                src={device.assetImage}
                            />
                        </Col>
                        <Col xs="6">
                            <Row>
                                <Col>
                                    <p className="text-muted mb-0">Since</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>{status.since}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className="text-muted mb-0">
                                        Hover to see last location
                                    </small>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    updateList() {
        let active = [],
            dormant = [],
            offline = []

        for (let i in this.props.devices) {
            const d = this.props.devices[i],
                status = d.status()
            if (status.name === 'Active') {
                active.push(this.makeDeviceElement(d))
            }
            if (status.name === 'Dormant') {
                dormant.push(this.makeDeviceElement(d))
            }
            if (status.name === 'Offline') {
                offline.push(this.makeDeviceElement(d))
            }
        }
        this.setState({active, dormant, offline})
    }

    componentWillMount() {
        this.subscriber.add(this.updateList, 1000 * 5, 'updateList')
    }

    render() {
        return (
            <Scrollbars>
                <>
                    <div className="border">
                        <Button
                            block
                            className="text-left"
                            onClick={() => this.toggleAccordion(0)}
                            aria-expanded={this.state.accordion[0]}
                            style={{borderRadius: 0, border: 'none'}}>
                            Active{' '}
                            <Badge color="success" style={{float: 'right'}}>
                                {this.state.active.length}
                            </Badge>
                        </Button>
                        <Collapse isOpen={this.state.accordion[0]}>
                            {this.state.active}
                        </Collapse>
                    </div>
                    <div className="border">
                        <Button
                            block
                            className="text-left"
                            onClick={() => this.toggleAccordion(1)}
                            aria-expanded={this.state.accordion[1]}
                            style={{borderRadius: 0, border: 'none'}}>
                            Dormant{' '}
                            <Badge color="warning" style={{float: 'right'}}>
                                {this.state.dormant.length}
                            </Badge>
                        </Button>
                        <Collapse isOpen={this.state.accordion[1]}>
                            {this.state.dormant}
                        </Collapse>
                    </div>
                    <div className="border">
                        <Button
                            block
                            className="text-left"
                            onClick={() => this.toggleAccordion(2)}
                            aria-expanded={this.state.accordion[2]}
                            style={{borderRadius: 0, border: 'none'}}>
                            Offline{' '}
                            <Badge color="danger" style={{float: 'right'}}>
                                {this.state.offline.length}
                            </Badge>
                        </Button>
                        <Collapse isOpen={this.state.accordion[2]}>
                            {this.state.offline}
                        </Collapse>
                    </div>
                </>
            </Scrollbars>
        )
    }
}
