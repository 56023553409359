import {cloneDeep} from 'lodash'
import moment from 'moment'

import {DATA_MODEL_ID_MAP} from '../Block/DATA_MODEL_ID_MAP'
import * as API from 'SDK/api'

export const splitBatch = async ({
    parentBatchRecord,
    childBatchNumber,
    childBatchDeviation,
    splitQuantity,
}) => {
    let parentOperationRecords = await fetchOperationRecords(
            parentBatchRecord['Batch Number']
        ),
        childOperationRecords = cloneDeep(parentOperationRecords)

    // change the parent batch press record for blocks pressed
    parentOperationRecords['Press']['Parts Pressed'] =
        parentOperationRecords['Press']['Parts Pressed'] - splitQuantity
    childOperationRecords['Press']['Parts Pressed'] = splitQuantity

    // create the child batch record
    let childBatchRecord = cloneDeep(parentBatchRecord)
    childBatchRecord['Batch Number'] = childBatchNumber
    childBatchRecord['Powder Batch'] = parentBatchRecord['Powder Batch']._id // powder batch link
    childBatchRecord['Block Description'] =
        parentBatchRecord['Block Description']._id
    delete childBatchRecord['_id']
    delete childBatchRecord['dataModelId']
    delete childBatchRecord['deleted']

    await API.post(
        'data-models/5fc9015bae96bc6be2c1a13e/add-record',
        childBatchRecord,
        2
    )
    childBatchRecord = await fetchBatchRecord(childBatchNumber)

    for (let operation in DATA_MODEL_ID_MAP) {
        let parent = parentOperationRecords[operation],
            child = childOperationRecords[operation],
            op = DATA_MODEL_ID_MAP[operation]

        if (parent) {
            for (let k in child) {
                if (child[k].constructor === Object && child[k]._id) {
                    child[k] = child[k]._id
                }
                delete child['deleted']
                delete child['dataModelId']
                delete child['_id']
            }
            for (let k in parent) {
                if (parent[k].constructor === Object && parent[k]._id) {
                    parent[k] = parent[k]._id
                }
            }
            child[op.masterBlockFieldPath.split('.')[0]] = childBatchRecord._id

            await API.patch(
                'data-models/' + op.dataModelId + '/edit-record',
                parent,
                2
            )
            await API.post(
                'data-models/' + op.dataModelId + '/add-record',
                child,
                2
            )
        }
    }

    const blockBatchSplitRecord = {
        'Creation Timestamp': moment().toISOString(),
        'Parent Batch': parentBatchRecord._id,
        'Child Batch': childBatchRecord._id,
        'Deviation Number': childBatchDeviation,
        'Quantity Split': splitQuantity,
    }

    await API.post(
        'data-models/5fd7887900ff6f0013b76ad4/add-record',
        blockBatchSplitRecord,
        2
    )
}

const fetchBatchRecord = async (batchNumber) => {
    const {result} = await API.post(
        'data-models/5fc9015bae96bc6be2c1a13e/paginate',
        {
            filter: [
                {
                    type: 'Text',
                    logic: 'is',
                    value: [batchNumber],
                    path: 'Batch Number',
                },
            ],
            sort: {},
            limit: 1,
            next: null,
            previous: null,
            timezone: localStorage['timezone'],
        },
        2
    )
    return result.results[0]
}

const fetchOperationRecords = async (batchNumber) => {
    let operations = {}
    for (let operation in DATA_MODEL_ID_MAP) {
        const op = DATA_MODEL_ID_MAP[operation]

        const {result} = await API.post(
            'data-models/' + op.dataModelId + '/paginate',
            {
                filter: [
                    {
                        type: 'Text',
                        logic: 'is',
                        value: [batchNumber],
                        path: op.masterBlockFieldPath,
                    },
                ],
                sort: {},
                limit: 1,
                next: null,
                previous: null,
                timezone: localStorage['timezone'],
            },
            2
        )
        operations[operation] = result.results.length ? result.results[0] : null
    }
    return operations
}
