import Moment from 'moment'
import {extendMoment} from 'moment-range'

import * as API from 'SDK/api'
import {tileColor} from 'SDK/helpers'

const moment = extendMoment(Moment)

export const getWorkOrder = async (deviceId) => {
    const data = await API.get('states/' + deviceId + '/Work Order/recent')

    let workOrder = {
        timestamp: null,
        name: 'No Work Order',
    }

    if (data.length > 0 && data[0].timeEnd === null) {
        workOrder.timestamp = moment(data[0].timestamp)

        let value = data[0].value.split(';')
        if (value.length > 1) {
            workOrder.name = value.length + ' active work orders'
        } else {
            workOrder.name = data[0].value
        }
    }

    return workOrder
}

export const getShiftTimerange = (date, shift, live) => {
    const {timeStart, timeEnd} = shift,
        startOffset = timeStart.hour * 60 + timeStart.minute,
        endOffset = timeEnd.hour * 60 + timeEnd.minute,
        base = moment(date).startOf('day')

    let range = [
        moment(base).set({hour: timeStart.hour, minute: timeStart.minute}),
        moment(base).set({hour: timeEnd.hour, minute: timeEnd.minute}),
    ]

    if (range[0].isAfter(range[1])) {
        if (!live) {
            range[1].add(1, 'days')
        } else {
            if (moment().isAfter(range[0])) {
                // in evening
                range[1].add(1, 'days')
            } else {
                // in morning
                range[0].add(-1, 'days')
            }
        }
    }

    return range
}

export const getActiveShift = (date, shifts, live) => {
    const base = moment(date)

    let activeShift = false
    for (let i = 0; i < shifts.length; i++) {
        let range = getShiftTimerange(date, shifts[i], live)
        if (base.isBetween(range[0], range[1], null, '[]')) {
            activeShift = shifts[i].name
        }
    }
    return activeShift
}

export const getAssetStatus = async (deviceId, shifts) => {
    let data = await API.post('live', {
        deviceId: deviceId,
    })

    let status = {
        activeState: {
            color: 'rgb(35, 34, 35)',
            name: 'No Data',
            timestamp: null,
        },
        activeShift: {
            name: 'Today',
            timeStart: moment().startOf('day'),
            timeEnd: moment(),
        },
    }

    if (data[deviceId] && data[deviceId].activeStates.length) {
        let timeNow = moment()

        let {activeStates, activeShifts} = data[deviceId]
        activeStates = activeStates.filter(
            (state) =>
                state.name === 'Disconnected' ||
                state.name === 'Downtime' ||
                state.name === 'In-Cycle'
        )

        status.activeState = activeStates[0]
        status.activeState.color = tileColor(status.activeState.name)

        let activeShift = getActiveShift(timeNow, shifts, true)
        if (activeShift) {
            status.activeShift.name = activeShift
            const shiftTimerange = getShiftTimerange(
                timeNow,
                shifts.find((shift) => shift.name === activeShift),
                true
            )
            status.activeShift.timeStart = shiftTimerange[0]
            status.activeShift.timeEnd = shiftTimerange[1]
            if (
                moment(status.activeState.timestamp).isBefore(shiftTimerange[0])
            ) {
                status.activeState.timestamp = moment(shiftTimerange[0])
            }
        }
    }
    return status
}

export const getTimelineData = async (
    deviceId,
    timeStart,
    timeEnd,
    aggregation
) => {
    let {data} = await API.post('historical/raw', {
        deviceId: deviceId,
        name: {
            $in: ['In-Cycle', 'Downtime', 'Disconnected'],
        },
        $or: [
            {
                timeStart: {
                    $gte: timeStart.toISOString(),
                    $lt: timeEnd.toISOString(),
                },
            },
            {
                timeStart: {
                    $lt: timeStart.toISOString(),
                },
                timeEnd: {
                    $gt: timeStart.toISOString(),
                },
            },
            {
                timeStart: {
                    $lt: timeEnd.toISOString(),
                },
                timeEnd: null,
            },
        ],
    })

    for (let i in data) {
        let row = data[i]

        if (moment(row.timeStart).diff(timeStart) < 0) {
            row.timeStart = moment(timeStart)
        }

        if (moment(row.timeEnd).diff(timeEnd) > 0) {
            row.timeEnd = moment(timeEnd)
        }

        if (row.timeEnd === null) {
            if (moment().diff(timeStart, 'days') === 0) {
                row.timeEnd = moment()
            } else row.timeEnd = moment(timeEnd)
        }

        data[i] = [
            row.name,
            tileColor(row.name),
            moment(row.timeStart)
                .startOf('minute')
                .diff(moment(timeStart), 'minutes'),
            moment(row.timeEnd)
                .startOf('minute')
                .diff(moment(timeStart), 'minutes'),
            moment(row.timeStart).diff(moment(timeStart), 'seconds'),
            moment(row.timeEnd).diff(moment(timeStart), 'seconds'),
            moment(row.timeStart).format(),
            moment(row.timeEnd).format(),
        ]
    }

    let utilizationAggregation = []
    console.log(aggregation)
    const threshold = aggregation,
        thresholdInMinutes = threshold / 60 < 1 ? 1 : Math.floor(threshold / 60)

    for (
        let i = 0;
        i < moment(timeEnd).diff(moment(timeStart), 'seconds');
        i++
    ) {
        if (i % threshold === 0) {
            const x1 = i - threshold,
                x2 = i

            if (
                data.find(
                    (d) => d[0] === 'In-Cycle' && x1 <= d[5] && d[4] <= x2
                )
            ) {
                utilizationAggregation.push(threshold)
            }

            /*if (data.find(d => d[0] === 'In-Cycle' && ((i - 15) >= d[4]) && (i < d[5]))) {
        utilizationAggregation.push(15);
      } else {
        utilizationAggregation.push(0);
      }*/
        }
    }

    let chartdata = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                states: [],
            },
        ],
    }

    for (let i = 0; i < moment(timeEnd).diff(timeStart, 'minutes'); i++) {
        chartdata.labels.push(moment(timeStart).add(i, 'minutes'))
        const x1 = i - thresholdInMinutes + 1,
            x2 = i
        let row = data.find((d) => {
            return d[0] === 'In-Cycle' && x1 <= d[3] && d[2] <= x2
        })

        if (!row) {
            row = data.find((d) => {
                return d[0] === 'Downtime' && i <= d[3] && d[2] <= i
            })
        }

        if (!row) {
            row = data.find((d) => {
                return d[0] === 'Disconnected' && i <= d[3] && d[2] <= i
            })
        }

        if (!row) continue
        chartdata.datasets[0].data.push(1)
        chartdata.datasets[0].backgroundColor.push(row[1])
        chartdata.datasets[0].states.push(row[0])
    }

    const elapsed = moment(timeEnd).diff(timeStart, 'seconds')

    const utilization =
        utilizationAggregation.reduce((total, next) => total + next, 0) /
        elapsed

    return {
        chartdata,
        utilization,
    }
}

export const getDeviceName = async (deviceId) => {
    let assets = await API.get('devices')

    if (!assets) return 'N/A'

    const asset = assets.find((asset) => asset.deviceId === deviceId)

    return asset ? asset.name : 'N/A'
}

export const getShiftSchedule = async (deviceId) => {
    let shifts = await API.get('shifts', 2)

    if (!shifts) return []

    shifts = shifts.filter((shift) => {
        return shift.assets.find((x) => x === deviceId)
    })

    return shifts.sort((a, b) => {
        return a.timeStart.hour - b.timeStart.hour
    })
}

export const getActiveShiftObject = async (date, shifts, live) => {
    if (!shifts) {
        return {activeShift: false, range: []}
    } else {
        const base = moment(date)

        for (let i = 0; i < shifts.length; i++) {
            let range = getShiftTimerange(date, shifts[i], live)
            if (base.isBetween(range[0], range[1], null, '[]')) {
                return {activeShift: shifts[i], range: range}
            }
        }
        return {activeShift: false, range: []}
    }
}
