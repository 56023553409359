import React, {Fragment, Component} from 'react'
import {Bar} from 'react-chartjs-2'
import autobind from 'auto-bind'

export default class PeakUsageChart extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.options = {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            plugins: {
                crosshair: false,
            },
            scales: {
                xAxes: [{}],
                yAxes: [
                    {
                        ticks: {
                            min: 0,
                            max: 100,
                            callback: (value, index, values) => {
                                return value + '%'
                            },
                        },
                    },
                ],
            },
        }

        this.state = {}
    }

    addLabels() {
        let labels = []
        for (let i = 0; i < 24; i++) {
            let fm = null
            if (i === 0) fm = '12am'
            else if (i === 12) fm = '12pm'
            else if (i < 12) fm = i + 'am'
            else fm = i - 12 + 'pm'
            labels.push(fm)
        }
        return labels
    }

    addTrendline() {
        let dataset = {
            label: this.props.trendlineLabel,
            data: [],
            backgroundColor: 'red',
        }

        return dataset
    }

    makeDataset() {
        let data = {
            labels: this.addLabels(),
            datasets: [
                {
                    label: 'data',
                    data: this.props.data.data,
                    backgroundColor: '#28a745',
                },
            ],
        }

        if (this.props.data.comparison !== 'none') {
            data.datasets.unshift({
                type: 'line',
                label: 'comparison',
                data: this.props.data.comparison,
                borderColor: '#145388',
                backgroundColor: 'transparent',
            })
        }

        return data
    }

    render() {
        return (
            <Bar
                data={this.makeDataset()}
                options={this.options}
                height={this.props.height}
            />
        )
    }
}
