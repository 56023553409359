import React, {Component, Fragment} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Creatable from 'react-select/creatable'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import * as sdk from './sdk'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            operators: [],
            loaded: false,
            selected: null,
        }
    }

    async fetchOperators() {
        const operators = await sdk.fetchAllOperatorSignIns()

        this.setState({
            operators,
            loaded: true,
        })
    }

    componentDidMount() {
        this.fetchOperators()
    }

    async submit() {
        let timestamp = moment().toISOString()

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Operator Sign In',
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                value: this.state.selected.value,
                metaData: [
                    {
                        name: 'operatorsignin',
                        value: this.state.selected.value,
                    },
                ],
            },
            2
        )

        this.props.toggleModal()
    }

    render() {
        return (
            <Fragment>
                {this.state.loaded ? (
                    <Modal isOpen={true} toggle={this.props.toggleModal}>
                        <ModalHeader toggle={this.props.toggleModal}>
                            Sign In
                        </ModalHeader>
                        <ModalBody>
                            <label>Type to search:</label>
                            <Creatable
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name=""
                                value={this.state.selected}
                                onChange={(selected) =>
                                    this.setState({selected})
                                }
                                options={this.state.operators}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                block
                                color="primary"
                                onClick={this.submit}
                                disabled={this.state.selected === null}>
                                SIGN IN
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
