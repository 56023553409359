// TODO: add non direct references models

import React, {Fragment, Component} from 'react'
import {Badge, Row, Col, ListGroup, ListGroupItem} from 'reactstrap'
import autobind from 'auto-bind'
import {Scrollbars} from 'react-custom-scrollbars'

import ModelTree from '../../../../common/ModelTree'

const OPTIONS = [
    {
        label: 'Count Records',
        value: 'count',
        fieldRequired: false,
    },
    {
        label: 'Sum of...',
        value: 'sum',
        fieldRequired: true,
    },
    {
        label: 'Average of...',
        value: 'mean',
        fieldRequired: true,
    },
    {
        label: 'Minimum of...',
        value: 'min',
        fieldRequired: true,
    },
    {
        label: 'Maximum of...',
        value: 'max',
        fieldRequired: true,
    },
    {
        label: 'Standard deviation of...',
        value: 'std',
        fieldRequired: true,
    },
    {
        label: 'Distinct values of...',
        value: 'nunique',
        fieldRequired: true,
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.height = document.documentElement.offsetHeight * 0.2

        this.flatData = []

        this.state = {
            view: 'list', // metric-selection, field-selection,
            metric: null,
        }

        this.fieldOptions = []
    }

    makeAddMetric() {
        return (
            <ListGroupItem
                tag="button"
                action
                style={{cursor: 'pointer'}}
                onClick={() => this.setState({view: 'metric-selection'})}>
                <i className="simple-icon-plus" /> Add metric
            </ListGroupItem>
        )
    }

    makeMetricSelectionList() {
        return OPTIONS.map((o, idx) => {
            return (
                <ListGroupItem
                    key={idx}
                    tag="button"
                    action
                    style={{cursor: 'pointer'}}
                    onClick={() => this.handleMetricSelection(o)}>
                    {o.label}
                </ListGroupItem>
            )
        })
    }

    makeSelectedMetricsList() {
        return this.props.aggregate.map((m, idx) => {
            let label = OPTIONS.find((o) => o.value === m.type).label

            if (m.path) {
                label = label.split('...')[0] + ' ' + m.path
            }
            return (
                <ListGroupItem
                    key={idx}
                    tag="button"
                    action
                    style={{cursor: 'pointer'}}
                    color="primary"
                    onClick={() => {}}>
                    <Row>
                        <Col xs="10">
                            {m.dataModelId ? (
                                <Badge>
                                    {
                                        this.props.dataModels.find(
                                            (a) => a._id === m.dataModelId
                                        ).name
                                    }
                                </Badge>
                            ) : null}{' '}
                            {label.replace(/@@/g, '')}
                        </Col>
                        <Col xs="2">
                            <i
                                className="iconsmind-Close"
                                onClick={() => this.handleMetricRemoval(m)}
                            />
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        })
    }

    makeFieldSelectionList() {
        const allowedTypes = [
            'SingleSubModel',
            'RegularizedDuration',
            'ForeignID',
        ].concat(
            this.props.fieldTypes
                .filter((a) => a.summarizable)
                .map((a) => a.type)
        )
        let trees = []
        trees.push(
            <ModelTree
                showMap={(item) => allowedTypes.includes(item.type)}
                onSelect={this.handleFieldClick}
                dataModel={this.props.dataModel}
                dataModels={this.props.dataModels}
                includeJoins={true}
            />
        )
        return trees
    }

    handleFieldClick(selectedKeys, {selectedNodes}) {
        const {type, path, model} = selectedNodes[0]
        if (
            type !== 'MultiSubModel' &&
            type !== 'SingleSubModel' &&
            type !== 'ForeignID'
        ) {
            let {aggregate} = this.props
            aggregate.push({
                ...selectedNodes[0],
                id: Math.random(),
                type: this.state.metric.value,
                path: path,
                dataModelId: model._id,
            })
            this.setState({view: 'list'}, this.props.setSummary(aggregate))
        }
    }

    handleMetricSelection(metric) {
        if (metric.fieldRequired) {
            this.setState({view: 'field-selection', metric})
        } else {
            let {aggregate} = this.props
            aggregate.push({
                id: Math.random(),
                type: metric.value,
                path: '_id',
            })
            this.setState({view: 'list'}, this.props.setSummary(aggregate))
        }
    }

    handleMetricRemoval(metric) {
        let {aggregate} = this.props
        aggregate = aggregate.filter((m) => m.id !== metric.id)
        this.props.setSummary(aggregate)
    }

    render() {
        return (
            <Fragment>
                <h5 style={{color: '#145388'}}>SUMMARIZE BY</h5>
                {this.state.view === 'list' ? (
                    <ListGroup>
                        {this.makeSelectedMetricsList()}
                        {this.makeAddMetric()}
                    </ListGroup>
                ) : null}

                {this.state.view === 'metric-selection' ? (
                    <Fragment>
                        <p className="text-muted">Select a metric...</p>
                        <ListGroup>{this.makeMetricSelectionList()}</ListGroup>
                    </Fragment>
                ) : null}

                {this.state.view === 'field-selection' ? (
                    <Fragment>
                        <p className="text-muted">{this.state.metric.label}</p>
                        <Scrollbars
                            autoHeight
                            autoHeightMin={this.height}
                            autoHeightMax={this.height}>
                            {this.makeFieldSelectionList()}
                        </Scrollbars>
                    </Fragment>
                ) : null}
            </Fragment>
        )
    }
}
