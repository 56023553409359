import * as API from 'SDK/api'
import moment from 'moment'

const Queries = {
    statesByDuration: async function (
        deviceId = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        state = [],
        groupByTimeUnit = 'total',
        logic = 'duration'
    ) {
        let data = await API.post('historical/aggregate2', {
            timeStart: timeStart.toISOString(),
            timeEnd: timeEnd.toISOString(),
            state,
            deviceId,
            groupByTimeUnit,
            logic,
        })

        return data
    },

    statesByCount: async function (
        deviceId = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        state = [],
        groupByTimeUnit = 'total',
        logic = 'count'
    ) {
        let data = await API.post('historical/aggregate2', {
            timeStart: timeStart.toISOString(),
            timeEnd: timeEnd.toISOString(),
            state,
            deviceId,
            groupByTimeUnit,
            logic,
        })

        return data
    },

    statesByValue: async function (
        deviceIds = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        states = []
    ) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: deviceIds,
                    },
                    name: {
                        $in: states,
                    },
                    timeStart: {
                        $lte: timeEnd.toISOString(),
                    },
                    timeEnd: {$gte: timeStart.toISOString()},
                },
            },
            2
        )

        return data
    },

    statesByProperty: async function (
        deviceIds = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        states = [],
        property = []
    ) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: deviceIds,
                    },
                    name: {
                        $in: states,
                    },
                    timeEnd: {
                        $lte: timeEnd.toISOString(),
                        $gte: timeStart.toISOString(),
                    },
                },
            },
            2
        )

        return data
    },

    statesByLiteral: async function (
        deviceIds = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        states = []
    ) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: deviceIds,
                    },
                    name: {
                        $in: states,
                    },
                    timeStart: {
                        $lte: timeEnd.toISOString(),
                    },
                    timeEnd: {$gte: timeStart.toISOString()},
                },
            },
            2
        )

        return data
    },

    reasonCodesByDuration: async function (
        deviceIds = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        reasonCodeList = []
    ) {
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: deviceIds,
                    },
                    name: 'Downtime Reason',
                    value: {
                        $in: reasonCodeList,
                    },
                    timeStart: {
                        $lte: timeEnd.toISOString(),
                    },
                    timeEnd: {$gte: timeStart.toISOString()},
                },
            },
            2
        )

        return data
    },

    reasonCodesByCount: async function (
        deviceIds = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        reasonCodeList = []
    ) {
        const data = await Queries.reasonCodesByDuration(
            deviceIds,
            timeStart,
            timeEnd,
            reasonCodeList
        )
        return data
    },

    reasonCodesByValue: async function (
        deviceIds = [],
        timeStart = moment().subtract(1, 'days'),
        timeEnd = moment(),
        reasonCodeList = []
    ) {
        const data = await Queries.reasonCodesByDuration(
            deviceIds,
            timeStart,
            timeEnd,
            reasonCodeList
        )
        return data
    },
}

export default Queries
