import React, {Component, Fragment} from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DataTablePagination from 'components/DataTables/pagination'
import PropTypes from 'prop-types'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Button,
    Form,
    Label,
    Input,
    TagsInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import moment from 'moment'
import Switch from 'rc-switch'

import {logoutUser} from 'redux/actions'

import {apiUrl} from '../../../constants/defaultValues'

const fmtMSS = (secondInput) => {
    let hours = Math.floor(secondInput / 3600),
        minutes = Math.floor((secondInput - hours * 3600) / 60),
        seconds = secondInput - hours * 3600 - minutes * 60

    if (hours < 10) hours = '0' + hours
    if (minutes < 10) minutes = '0' + minutes
    if (seconds < 10) seconds = '0' + seconds

    return hours + 'h ' + minutes + 'm ' + seconds + 's'
}

export default class WorkOrderInsights extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = ['Part Count', 'Work Order', 'In-Cycle', 'Downtime']
    showBorder = false
    id = 'WorkOrderInsights'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)

        this.getAssets = this.getAssets.bind(this)
        this.handleStartChange = this.handleStartChange.bind(this)
        this.handleEndChange = this.handleEndChange.bind(this)
        this.generate = this.generate.bind(this)

        this.state = {
            token: JSON.parse(localStorage['userObject']).token,
            start: moment().startOf('week').toDate(),
            end: moment().endOf('day').subtract(1, 'days').toDate(),
            loading: true,
            workorders: [],
            assets: [],
        }
    }
    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }
    async getAssets() {
        try {
            let {data} = await axios.get(apiUrl + 'assets/devices', {
                headers: {
                    Authorization: this.state.token,
                },
            })
            this.setState({assets: data})
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }

    async getAllWorkOrders() {
        try {
            const {data} = await axios.post(
                apiUrl + 'historical/raw',
                {
                    name: 'Work Order',
                    timeStart: {
                        $gte: moment(this.state.start).toISOString(),
                        $lt: moment(this.state.end).toISOString(),
                    },
                    timeEnd: {$ne: null},
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            this.setState({workorders: data.data})
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }

    async getCuttingTime(workorder) {
        try {
            const {data} = await axios.post(
                apiUrl + 'historical/aggregate2',
                {
                    timeStart: moment(workorder.timeStart).toISOString(),
                    timeEnd: moment(workorder.timeEnd).toISOString(),
                    deviceId: [workorder.deviceId],
                    state: ['In-Cycle'],
                    logic: 'duration',
                    groupByTimeUnit: 'total',
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            let cuttingMs = data.data.devices[workorder.deviceId]['In-Cycle'][0]
            cuttingMs =
                cuttingMs /
                moment(workorder.timeEnd).diff(moment(workorder.timeStart))
            cuttingMs = parseInt(cuttingMs * 100)
            return cuttingMs
        } catch (error) {
            throw Error(error)
        }
    }

    async getWorkOrderData(workorder) {
        try {
            let {data} = await axios.post(
                apiUrl + 'historical/raw',
                {
                    name: 'Part Count',
                    timeStart: {
                        $gte: moment(workorder.timeStart).toISOString(),
                    },
                    timeEnd: {
                        $lte: moment(workorder.timeEnd).toISOString(),
                    },
                    deviceId: workorder.deviceId,
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            return data.data
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }

    async getFirstCutForWorkOrder(workorder) {
        try {
            let {data} = await axios.post(
                apiUrl + 'historical/raw',
                {
                    name: 'Part Count',
                    timeStart: {
                        $gte: moment(workorder.timeStart).toISOString(),
                    },
                    timeEnd: {
                        $lte: moment(workorder.timeEnd).toISOString(),
                    },
                    deviceId: workorder.deviceId,
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            data = data.data
            if (data.length === 0) return 0
            data = data.sort(
                (a, b) =>
                    moment(a.timeStart).unix() - moment(b.timeStart).unix()
            )[0]
            return moment(data.timeStart).diff(
                moment(workorder.timeStart),
                'seconds'
            )
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }

    async getLastCutForWorkOrder(workorder) {
        try {
            let {data} = await axios.post(
                apiUrl + 'historical/raw',
                {
                    name: 'Part Count',
                    timeStart: {
                        $gte: moment(workorder.timeStart).toISOString(),
                    },
                    timeEnd: {
                        $lte: moment(workorder.timeEnd).toISOString(),
                    },
                    deviceId: workorder.deviceId,
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            data = data.data
            if (data.length === 0) return 0
            data = data.sort(
                (a, b) =>
                    moment(b.timeStart).unix() - moment(a.timeStart).unix()
            )[0]
            return moment(workorder.timeEnd).diff(
                moment(data.timeEnd),
                'seconds'
            )
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }

    async generate() {
        this.setState({loading: true})
        await this.getAllWorkOrders()

        let summary = {}

        for (let i in this.state.assets) {
            const asset = this.state.assets[i]

            const workorders = this.state.workorders.filter(
                (wo) => wo.deviceId === asset.deviceId
            )

            let firstcuts = [],
                lastcuts = [],
                cuttingTimes = []
            for (let j in workorders) {
                const data = this.getWorkOrderData(workorders[j])
                firstcuts.push(
                    await this.getFirstCutForWorkOrder(workorders[j], data)
                )
                lastcuts.push(
                    await this.getLastCutForWorkOrder(workorders[j], data)
                )
                cuttingTimes.push(await this.getCuttingTime(workorders[j]))
            }
            const timeToFirstCut =
                firstcuts.length === 0
                    ? 'N/A'
                    : fmtMSS(
                          parseInt(
                              firstcuts.reduce(function (a, b) {
                                  return a + b
                              }) / firstcuts.length
                          )
                      )
            const timeToLastCut =
                lastcuts.length === 0
                    ? 'N/A'
                    : fmtMSS(
                          parseInt(
                              lastcuts.reduce(function (a, b) {
                                  return a + b
                              }) / lastcuts.length
                          )
                      )
            const utilization =
                cuttingTimes.length === 0
                    ? 'N/A'
                    : parseInt(
                          cuttingTimes.reduce((a, b) => a + b) /
                              cuttingTimes.length
                      ) + '%'
            summary[asset.deviceId] = {
                timeToLastCut: timeToLastCut,
                timeToFirstCut: timeToFirstCut,
                utilization: utilization,
            }
        }

        this.setState({summary: summary, loading: false})

        return
        // TODO unreachable code after return
        try {
            const {data} = await axios.post(
                apiUrl + 'historical/raw',
                {
                    name: 'Work Order',
                    timeEnd: {$ne: null},
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            let workorders = []
            for (let i in data.data) {
                const row = data.data[i]
                const cuttingTime = await this.getCuttingTime(row)
                const numberOfCuts = await this.getCuts(row)
                let workorder = {
                    assetName: this.state.assets.find(
                        (x) => x.deviceId === row.deviceId
                    ).name,
                    wo: row.metaData.find((x) => x.name === 'workorder').value,
                    timeStart: moment(data.data[i].timeStart).format(
                        'MMM d, YYYY h:mm a'
                    ),
                    timeEnd: moment(data.data[i].timeEnd).format(
                        'MMM d, YYYY h:mm a'
                    ),
                    cuts: numberOfCuts,
                    cutting: cuttingTime + '%',
                }
                workorders.push(workorder)
            }
            this.setState({
                loading: false,
                data: workorders,
            })
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }

    async componentWillMount() {
        await this.getAssets()
        this.generate()
    }
    generateData() {
        return this.state.assets.map((a, idx) => {
            const data = this.state.summary[a.deviceId]
            return (
                <Row key={idx}>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <CardTitle>{a.name}</CardTitle>
                                <Row>
                                    <Col xs="12" sm="4" className="text-center">
                                        <p>Avg. Scan To First Part</p>
                                        <h1>{data.timeToFirstCut}</h1>
                                    </Col>
                                    <Col xs="12" sm="4" className="text-center">
                                        <p>Avg. Last Part To Next Scan Time</p>
                                        <h1>{data.timeToLastCut}</h1>
                                    </Col>
                                    <Col xs="12" sm="4" className="text-center">
                                        <p>Avg. Utilization</p>
                                        <h1>{data.utilization}</h1>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        })
    }
    componentWillUnmount() {}
    render() {
        return (
            <Fragment>
                <Row className="mb-2">
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="6">
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleStartChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleEndChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col
                                        xs="12"
                                        sm="12"
                                        className="mt-1 text-right">
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.loading ? (
                    <div className="loading" />
                ) : (
                    this.generateData()
                )}
            </Fragment>
        )
    }
}
