import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL + '/'

const API_URL = (version) => {
    if (version) return BASE_URL + 'v' + version + '/'
    else return BASE_URL + 'v1/'
}

const get = async (route, version, timeout) => {
    let config = {
        headers: {
            Authorization: JSON.parse(localStorage['userObject']).token,
            'Time-Zone': localStorage['timezone'],
        },
    }
    if (timeout) {
        config.timeout = timeout
    }
    try {
        let response = await axios.get(API_URL(version) + route, config)
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const post = async (route, data, version, timeout) => {
    let config = {
        headers: {
            Authorization: JSON.parse(localStorage['userObject']).token,
            'Time-Zone': localStorage['timezone'],
        },
    }
    if (timeout) {
        config.timeout = timeout
    }
    try {
        let response = await axios.post(API_URL(version) + route, data, config)
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const patch = async (route, data, version, timeout) => {
    let config = {
        headers: {
            Authorization: JSON.parse(localStorage['userObject']).token,
            'Time-Zone': localStorage['timezone'],
        },
    }
    if (timeout) {
        config.timeout = timeout
    }
    try {
        let response = await axios.patch(API_URL(version) + route, data, config)
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const remove = async (route, version, timeout) => {
    let config = {
        headers: {
            Authorization: JSON.parse(localStorage['userObject']).token,
            'Time-Zone': localStorage['timezone'],
        },
    }
    if (timeout) {
        config.timeout = timeout
    }
    try {
        let response = await axios.delete(API_URL(version) + route, config)
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export {get, post, patch, remove}
