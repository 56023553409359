import React, {Component, Fragment} from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import Pin from './pin'

const MAJOR_VERSIONS = {
    3: {pins: 6},
    4: {pins: 8},
    5: {pins: 8},
}

const TIME_UNIT_TO_MILLISECONDS = {
    micro: 1 / 1000,
    milli: 1,
    second: 1000,
    minute: 1000 * 60,
    hour: 3.6e6,
    day: 8.64e7,
    month: 2.628e9,
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )
        this.majorVersion = parseInt(this.node.version.split('.')[0])
        this.workingCopy = this.node.settings.pins

        if (MAJOR_VERSIONS[this.majorVersion] === undefined) {
            return alert('This SensorBot version is not yet supported.')
        }

        this.numberOfPins = MAJOR_VERSIONS[this.majorVersion].pins

        this.state = {
            pins: this.node.settings.pins,
            accordion: new Array(this.numberOfPins).fill(false),
            validation: new Array(this.numberOfPins).fill(true),
            isValid: true,
        }
    }
    updateWorkingCopy(index, obj) {
        this.workingCopy[index] = obj
    }
    async save() {
        try {
            let node = {...this.node}
            node.settings.pins = this.workingCopy

            const numberOfEnabledPins = this.workingCopy.filter(
                    (a) => a.pinEnable
                ).length,
                minimumSamplingSpeed =
                    Math.round((0.07 + numberOfEnabledPins * 0.08) * 100) / 100

            for (let pin of this.workingCopy) {
                let samplingRate = null
                if (pin.logic === 'uniform_sampling') {
                    if (
                        pin.uniformSampleRate *
                            TIME_UNIT_TO_MILLISECONDS[
                                pin.uniformSampleRateUnit
                            ] <
                        minimumSamplingSpeed
                    ) {
                        return alert(
                            'Sampling speed too fast for Stream ' +
                                pin.pinNumber +
                                ' - must be at least ' +
                                minimumSamplingSpeed +
                                ' milliseconds'
                        )
                    }
                    samplingRate =
                        pin.uniformSampleRate *
                        TIME_UNIT_TO_MILLISECONDS[pin.uniformSampleRateUnit] *
                        pin.uniformSampleNumber
                } else if (pin.logic === 'counting') {
                    if (
                        pin.countSampleRate *
                            TIME_UNIT_TO_MILLISECONDS[pin.countSampleRateUnit] <
                        minimumSamplingSpeed
                    ) {
                        return alert(
                            'Sampling speed too fast for Stream ' +
                                pin.pinNumber +
                                ' - must be at least ' +
                                minimumSamplingSpeed +
                                ' milliseconds'
                        )
                    }
                    samplingRate =
                        pin.countSampleRate *
                        TIME_UNIT_TO_MILLISECONDS[pin.countSampleRateUnit] *
                        pin.countSampleNumber
                }

                if (samplingRate !== null && samplingRate < 100) {
                    return alert(
                        'The date rate for Stream ' +
                            pin.pinNumber +
                            ' is too fast (' +
                            samplingRate +
                            ' milliseconds). Ensure the rate remains above 100 milliseconds.'
                    )
                }
            }

            let response = await API.patch(
                'nodes/' + node.nodeId,
                {
                    settings: node.settings,
                },
                2
            )
            if (response.success) {
                this.props.toggleModal('dataSamplingModal')
            } else {
                return alert(
                    'There was an error saving your change. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error saving your change. Please try again.'
            )
        }
    }
    validate(tab, valid) {
        let validation = this.state.validation
        validation[tab] = valid
        this.setState({
            validation: validation,
            isValid:
                this.state.validation.find((x) => x === false) === undefined,
        })
    }
    toggleAccordion(tab) {
        if (this.state.validation.find((x) => x === false) !== undefined) {
            return
        }
        const prevState = this.state.accordion
        const state = prevState.map((x, index) => (tab === index ? !x : false))
        this.setState({
            accordion: state,
        })
    }
    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {modal, toggleModal} = this.props
        let {pins} = this.state
        const streams = [...Array(this.numberOfPins).keys()]
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    size="lg"
                    toggle={() => {
                        toggleModal('dataSamplingModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('dataSamplingModal')
                        }}>
                        Data Sampling
                    </ModalHeader>
                    <ModalBody>
                        {streams.map((stream) => {
                            return (
                                <div key={stream} className="border">
                                    <Button
                                        block
                                        color="link"
                                        className="text-left"
                                        onClick={() =>
                                            this.toggleAccordion(stream)
                                        }
                                        aria-expanded={
                                            this.state.accordion[stream]
                                        }>
                                        Stream {stream + 1}
                                    </Button>
                                    <Collapse
                                        isOpen={this.state.accordion[stream]}>
                                        <div className="p-4">
                                            <Pin
                                                obj={pins[stream]}
                                                validate={this.validate}
                                                updateWorkingCopy={
                                                    this.updateWorkingCopy
                                                }
                                                numberOfPins={this.numberOfPins}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            )
                        })}
                        <Alert color="danger" className="mt-4">
                            Data flows are associated with pins, not aliases. If
                            you're setting a new pin to perform data collection
                            for an existing data flow input, go to the affected
                            data flow, delete the input and re-add it to the
                            flow.
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('dataSamplingModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.save}
                            disabled={!this.state.isValid}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
