export const DATA_MODEL_ID_MAP = {
    Press: {
        dataModelId: '5fc91243d02f3471d06e6b4a',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        totalCountFieldPath: 'Parts Pressed',
        scrapFieldPaths: [
            'Scrap.Height/Weight/Density',
            'Scrap.Handling/Palletizer',
            'Scrap.Set-up',
            'Scrap.Other',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: ['Press Machine ID'],
    },
    BBO: {
        dataModelId: '5fc91d1ad02f3471d06e6b6d',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: ['Total Blocks Scrap Both Parts'],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        useRadioLinks: [
            'Part 1.Machine ID',
            'Part 2.Machine ID',
            'Part 3.Machine ID',
            'Part 4.Machine ID',
            'Part 5.Machine ID',
            'Part 1.Program ID',
            'Part 2.Program ID',
            'Part 3.Program ID',
            'Part 4.Program ID',
            'Part 5.Program ID',
        ],
    },
    Kiln: {
        dataModelId: '5fc92956d02f3471d06e6b8c',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            'Load.Kiln Scrap.Handling',
            'Load.Kiln Scrap.Other',
            'Unload.Kiln Unload Scrap.QA Sample',
            'Unload.Kiln Unload Scrap.Scrap',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: ['Machine ID'],
        radioLinksInline: true,
    },
    'Block Heights': {
        dataModelId: '5fc92f0ed02f3471d06e6ba8',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        negateScrapFieldPaths: [],
        useRadioLinks: [],
    },
    'Collar Spray': {
        dataModelId: '5fc93354d02f3471d06e6bb2',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            //'Scrap_Categories.Rewash_Scrap',
            'Scrap_Categories.Handling_Palletizer_Scrap',
            'Scrap_Categories.Chip_Crack_Scrap',
            'Scrap_Categories.Other_Scrap',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: ['Total Rewashed'],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: ['Part 1 Machine ID'],
    },
    'Collar Cure': {
        dataModelId: '5fc93473d02f3471d06e6bbf',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: ['Total Blocks Scrap'],
        reworkScrapFieldPaths: [],
        negateScrapFieldPaths: [],
        useRadioLinks: [
            'Collar Cure Part 1.Machine ID',
            'Collar Cure Part 1.Program ID',
            'Collar Cure Part 2.Machine ID',
            'Collar Cure Part 2.Program ID',
            'Collar Cure Part 3.Machine ID',
            'Collar Cure Part 3.Program ID',
            'Collar Cure Part 4.Machine ID',
            'Collar Cure Part 4.Program ID',
        ],
    },
    Grinding: {
        dataModelId: '5fc93732d02f3471d06e6bdf',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: ['Total Blocks Scrap'],
        reworkScrapFieldPaths: [],
        negateScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: ['Part 1 Machine ID', 'Part 2 Machine ID'],
    },
    'Visual Inspection': {
        dataModelId: '5fc93866d02f3471d06e6be8',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            'Visual Inspection Scrap.First_Pass',
            'Visual Inspection Scrap.Rework_Scrap',
            'Visual Inspection Scrap.Recollar',
            //'Legacy_Scrap_Entry.Side_Crack_Scrap',
            //'Legacy_Scrap_Entry.Other_Crack_Scrap',
            //'Legacy_Scrap_Entry.Post_fire_Chip_Scrap',
            //'Legacy_Scrap_Entry.Parallel_Perpendicular_Scrap',
            //'Legacy_Scrap_Entry.Short_Scrap',
            //'Legacy_Scrap_Entry.Metallizer',
            //'Legacy_Scrap_Entry.Robot', // moved to rework
            //'Legacy_Scrap_Entry.Handling_Palletizer'
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [
            'Visual_Inspection_Rework.Bed_Material',
            'Visual_Inspection_Rework.Surface_Defect',
            'Visual_Inspection_Rework.Prefire_Chip',
            'Visual_Inspection_Rework.Collar_Chip',
            'Visual_Inspection_Rework.Collar_Defect',
            'Visual_Inspection_Rework.Robot',
            'Visual_Inspection_Rework.Other',
        ],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
    '3DA Bordering': {
        dataModelId: '5fc93afad02f3471d06e6bff',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            '3DA Bordering Scrap.Side Crack',
            '3DA Bordering Scrap.Collar Defect',
            '3DA Bordering Scrap.Bed Material',
            '3DA Bordering Scrap.Surface Defect',
            '3DA Bordering Scrap.Pre-fire Chip',
            '3DA Bordering Scrap.Post-fire Chip',
            '3DA Bordering Scrap.Collar Chip',
            '3DA Bordering Scrap.Other',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
    Metallizing: {
        dataModelId: '5fc93cbcd02f3471d06e6c13',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            'Metallizing Scrap.Robot',
            'Metallizing Scrap.Handling/Palletizer',
            'Metallizing Scrap.Bad Spray',
            'Metallizing Scrap.Collar Chip',
            'Metallizing Scrap.First Pass',
            'Metallizing Scrap.Rework',
            'Metallizing Scrap.Other',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: ['Machine ID'],
    },
    '3DA Transmission': {
        dataModelId: '5fc94383d02f3471d06e6c23',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            '3DA Transmission Scrap.Robot',
            '3DA Transmission Scrap.Collar Defect',
            '3DA Transmission Scrap.Collar Chip',
            "3DA Transmission Scrap.Won't Print",
            '3DA Transmission Scrap.Metallizing Defect',
            '3DA Transmission Scrap.Metallizing Burn',
            '3DA Transmission Scrap.Pre-fire Chip',
            '3DA Transmission Scrap.Post-fire Chip',
            '3DA Transmission Scrap.Pinholes',
            '3DA Transmission Scrap.Bed material',
            '3DA Transmission Scrap.Failures',
            //"3DA Transmission Scrap.5fc944b9d02f3471d06e6c35",
            '3DA Transmission Scrap.Other',
        ],
        negateScrapFieldPaths: ['Qas Added'],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
    '3DA High Energy': {
        dataModelId: '5fc944d3d02f3471d06e6c37',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            '3DA High Energy Scrap.Collar Defect',
            '3DA High Energy Scrap.Collar Chip',
            '3DA High Energy Scrap.Block Chip',
            '3DA High Energy Scrap.Metal Defect',
            '3DA High Energy Scrap.Failures',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
    '3DA 100A': {
        dataModelId: '5fc945cdd02f3471d06e6c45',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            '3DA 100A Scrap.Block Chip',
            '3DA 100A Scrap.Collar Chip',
            '3DA 100A Scrap.Metal Defect',
            '3DA 100A Scrap.Other',
        ],
        negateScrapFieldPaths: [],
        reworkScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
    'Test & Inspect': {
        dataModelId: '5fc94685d02f3471d06e6c52',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            'Test & Inspect Scrap.Misallocated_Block',
            'Test & Inspect Scrap.QA Sample',
            'Test & Inspect Scrap.Electrical Failure',
            'Test & Inspect Scrap.Rework',
            'Test & Inspect Scrap.Other_First_Pass_Scrap',

            'Legacy_Scrap_Entry.Robot_Scrap',
            'Legacy_Scrap_Entry.Sorter_Arm_Scrap',
            'Legacy_Scrap_Entry.Printer_Scrap',
            'Legacy_Scrap_Entry.Pre_Fire_Chip_Scrap',
            'Legacy_Scrap_Entry.Post_Fire_Chip_Scrap',
            'Legacy_Scrap_Entry.Pinhole_Scrap',
            'Legacy_Scrap_Entry.Collar_Chip_Scrap',
            'Legacy_Scrap_Entry.Bed_Material_Scrap',
            'Legacy_Scrap_Entry.Metallizeing_Burn_Scrap',
            'Legacy_Scrap_Entry.Other_First_Pass_Scrap',
        ],
        reworkScrapFieldPaths: [
            'Rework.Collar_Defect',
            'Rework.Metallizing_Defect',
            'Rework.Other',
        ],
        negateScrapFieldPaths: [
            //'Add Blocks'
        ],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: ['Machine ID'],
    },
    'Final Inspection (3DA Batches)': {
        dataModelId: '606cb3fdffbe5700134f9683',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [
            'Scrap.Misallocated_Block',
            'Scrap.QA Sample',
            'Scrap.Electrical Failure',
            'Scrap.Rework',
            'Scrap.Collar_Defect',
            'Scrap.Metallizing_Defect',
            'Scrap.Other',
        ],
        reworkScrapFieldPaths: [
            //'Rework.Collar_Defect',
            //'Rework.Metallizing_Defect',
            //'Rework.Other'
        ],
        negateScrapFieldPaths: [
            //'Add Blocks'
        ],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
    '2 Shot Re-Run Tracker': {
        dataModelId: '607595cf070e920013abf36e',
        masterBlockFieldPath: 'Block Batch Link.Batch Number',
        scrapFieldPaths: [],
        complexScrapFieldParser: (record) => {
            if (!record || !record['Re_Runs']) return 0
            let scrap = 0
            for (let instance of record['Re_Runs']) {
                if (instance['Re_Run']['1st_Shift_Scrap']) {
                    scrap += instance['Re_Run']['1st_Shift_Scrap']
                }
                if (instance['Re_Run']['2nd_Shift_Scrap']) {
                    scrap += instance['Re_Run']['2nd_Shift_Scrap']
                }
                if (instance['Re_Run']['3rd_Shift_Scrap']) {
                    scrap += instance['Re_Run']['3rd_Shift_Scrap']
                }
            }
            return scrap
        },
        goodPartCounter: (record) => {
            if (!record || !record['Re_Runs']) return 0
            let scrap = 0
            for (let instance of record['Re_Runs']) {
                if (instance['Re_Run']['1st_Shift_Good']) {
                    scrap += instance['Re_Run']['1st_Shift_Good']
                }
                if (instance['Re_Run']['2nd_Shift_Good']) {
                    scrap += instance['Re_Run']['2nd_Shift_Good']
                }
                if (instance['Re_Run']['3rd_Shift_Good']) {
                    scrap += instance['Re_Run']['3rd_Shift_Good']
                }
            }
            return scrap
        },
        reworkScrapFieldPaths: [],
        negateScrapFieldPaths: [],
        partNumberFieldPath: 'Block Batch Link.Block Description.Part Number',
        useRadioLinks: [],
    },
}
