import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {Scrollbars} from 'react-custom-scrollbars'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {logUserEvent} from 'SDK/api/common'

import Note from './Note'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import Settings from './Settings'

export default class Notes extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.userId = JSON.parse(localStorage['userObject'])._id
        this.subscriber = new Subscriber()

        this.firstFetch = true

        console.log(this.props)

        this.state = {
            devices: [],
            shifts: [],
            users: [],
            notes: [],
            editingNoteId: null,
            listDropdownOpen: false,
            list: 'My List',
            settingsModalOpen: false,
            settings: null,
        }
    }

    async toggleSettingsModal() {
        const settings = await API.get('notes-settings/' + this.userId, 2)

        this.setState({
            settingsModalOpen: !this.state.settingsModalOpen,
            settings,
        })
    }

    toggleListDropdown() {
        this.setState({listDropdownOpen: !this.state.listDropdownOpen})
    }

    async fetchNotes() {
        let notes = await API.get('notes', 2)

        notes = notes.map((note) => {
            note.timestamp = moment(note.timestamp)
            return note
        })

        if (!this.firstFetch) {
            if (this.state.settings.appNotifications.note !== 'none') {
                for (let note of notes) {
                    if (
                        !this.state.notes.find(
                            (n) => n.noteId === note.noteId
                        ) &&
                        note.userId !== this.userId
                    ) {
                        // note not entered in our system and not entered by us in another system
                        if (
                            (this.state.settings.appNotifications.note ===
                                'mentioned' &&
                                note.tags.users.find(
                                    (u) => u === this.userId
                                )) ||
                            this.state.settings.appNotifications.note === 'all'
                        ) {
                            const user = this.state.resources.find(
                                (r) => r.id === 'users_' + note.userId
                            )
                            if (user) {
                                toast.info(
                                    user.display +
                                        ' added a note: ' +
                                        note.plainText,
                                    {
                                        position: 'top-center',
                                        autoClose: 1000 * 30,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    }
                                )
                            }
                        }
                    }
                }
            }
            if (this.state.settings.appNotifications.reply !== 'none') {
                for (let note of notes) {
                    const localNote = this.state.notes.find(
                        (n) => n.noteId === note.noteId
                    )
                    if (localNote) {
                        const newRemoteReplies = note.replies
                            .filter(
                                (r) =>
                                    !localNote.replies.find(
                                        (l) => l.replyId === r.replyId
                                    )
                            )
                            .filter((r) => r.userId !== this.userId)

                        for (let reply of newRemoteReplies) {
                            const user = this.state.resources.find(
                                    (r) => r.id === 'users_' + reply.userId
                                ),
                                sourceUser = this.state.resources.find(
                                    (r) => r.id === 'users_' + note.userId
                                )

                            if (user && sourceUser) {
                                let sendNotification = false

                                if (
                                    this.state.settings.appNotifications
                                        .reply === 'mentioned' &&
                                    reply.tags.users.find(
                                        (u) => u === this.userId
                                    )
                                ) {
                                    sendNotification = true
                                } else if (
                                    this.state.settings.appNotifications
                                        .reply === 'mine' &&
                                    note.userId === this.userId
                                ) {
                                    sendNotification = true
                                } else if (
                                    this.state.settings.appNotifications
                                        .reply === 'all'
                                ) {
                                    sendNotification = true
                                }

                                if (sendNotification) {
                                    toast.info(
                                        user.display +
                                            ' replied to ' +
                                            sourceUser.display +
                                            "'s note (Note: " +
                                            note.plainText +
                                            ') and said: ' +
                                            reply.plainText,
                                        {
                                            position: 'top-center',
                                            autoClose: 1000 * 30,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        } else {
            this.firstFetch = false
        }

        if (this.state.editingNoteId) {
            let editingRecord = this.state.notes.find(
                (note) => note.noteId === this.state.editingNoteId
            )
            if (editingRecord) {
                notes.unshift(editingRecord)
            }
        }
        this.setState({notes})
    }

    async fetchResources() {
        let devices = await API.get('devices'),
            shifts = await API.get('shifts', 2),
            users = await API.get('users?all=true', 2)

        shifts = [...new Map(shifts.map((item) => [item.name, item])).values()]
        let resources = devices.map((d) => {
            return {
                id: 'devices_' + d.deviceId,
                display: d.name,
            }
        })

        resources = resources.concat(
            shifts.map((s) => {
                return {
                    id: 'shifts_' + s.name,
                    display: s.name,
                }
            })
        )

        resources = resources.concat(
            users.map((u) => {
                return {
                    id: 'users_' + u._id,
                    display: u.firstName + ' ' + u.lastName,
                }
            })
        )

        this.setState({resources})
    }

    addNote() {
        let noteObject = {
            noteId: uuidv4(),
            userId: this.userId,
            timestamp: moment(),
            text: '',
            plainText: '',
            notifyUsers: false,
            archived: false,
            tags: {users: [], shifts: [], devices: []},
            downtimeData: [],
            attachments: [],
            replies: [],
        }

        let {notes} = this.state
        notes.unshift(noteObject)
        this.setState({notes, editingNoteId: noteObject.noteId})
    }

    async saveNote({
        noteId,
        userId,
        timestamp,
        text,
        notifyUsers,
        tags,
        archived,
        plainText,
        attachments,
        replies,
    }) {
        let notes = this.state.notes,
            noteIndex = notes.findIndex((n) => n.noteId === noteId)
        if (noteIndex >= 0) {
            // append asset info for mentioned assets
            let downtimeData = []
            if (tags.devices.length) {
                for (let device of tags.devices) {
                    const activeStates = await API.post(
                        'historical/raw',
                        {
                            query: {
                                deviceId: device,
                                name: {
                                    $in: [
                                        'Downtime',
                                        'Downtime Category',
                                        'Downtime Reason',
                                        'Downtime Comment',
                                        'Downtime User',
                                    ],
                                },
                                timeEnd: null,
                            },
                        },
                        2
                    )
                    const downtimeRecord = activeStates.find(
                            (a) => a.name === 'Downtime'
                        ),
                        reasonRecord = activeStates.find(
                            (a) => a.name === 'Downtime Reason'
                        ),
                        commentRecord = activeStates.find(
                            (a) => a.name === 'Downtime Comment'
                        ),
                        userRecord = activeStates.find(
                            (a) => a.name === 'Downtime User'
                        )

                    if (downtimeRecord) {
                        downtimeData.push({
                            deviceId: device,
                            timestamp: downtimeRecord.timeStart,
                            reason: reasonRecord ? reasonRecord.value : null,
                            comment:
                                commentRecord &&
                                commentRecord.value &&
                                commentRecord.value !== ''
                                    ? commentRecord.value
                                    : null,
                            userId: userRecord ? userRecord.value : null,
                        })
                    }
                }
            }
            notes[noteIndex] = {
                noteId,
                userId,
                timestamp,
                text,
                notifyUsers,
                tags,
                archived,
                plainText,
                downtimeData,
                attachments,
                replies,
            }
        }
        API.post('notes', notes[noteIndex], 2)
        this.setState({notes, editingNoteId: null})
        logUserEvent('ADDED A NOTE')
    }

    archiveNote(noteId) {
        let {notes} = this.state,
            noteIndex = notes.findIndex((n) => n.noteId === noteId)

        if (noteIndex >= 0) {
            notes[noteIndex].archived = true
        }

        API.patch('notes/' + notes[noteIndex]._id, notes[noteIndex], 2)
        this.setState({notes, editingNoteId: null})
    }

    deleteNote(noteId) {
        let {notes} = this.state

        const note = notes.find((note) => note.noteId === noteId)
        if (note && note._id) {
            API.remove('notes/' + note._id, 2)
        }

        notes = notes.filter((note) => note.noteId !== noteId)
        this.setState({notes, editingNoteId: null})
    }

    renderNotes() {
        let notes = this.state.notes.filter((note) => !note.archived)

        if (this.state.list === 'My List') {
            notes = notes.filter((note) => note.userId === this.userId)
        } else if (this.state.list === 'Mentioned In') {
            notes = notes
                .filter((note) => {
                    if (note.tags.users.find((u) => u === this.userId)) {
                        // mentioned in note
                        return true
                    } else if (
                        [].concat
                            .apply(
                                [],
                                note.replies.map((r) => r.tags.users)
                            )
                            .find((u) => u === this.userId)
                    ) {
                        // mentioned in a note's reply
                        return true
                    } else if (
                        note.replies.find((r) => r.userId === this.userId)
                    ) {
                        // posted a reply
                        return true
                    } else {
                        return false
                    }
                })
                .filter((note) => note.userId !== this.userId)
        } else if (this.state.list === 'All Notes') {
            // TODO empty block
        }

        notes.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())

        return notes.map((note) => {
            return (
                <Note
                    key={note.noteId}
                    resources={this.state.resources}
                    noteObject={note}
                    editing={this.state.editingNoteId === note.noteId}
                    saveNote={this.saveNote}
                    archiveNote={this.archiveNote}
                    saveReply={this.saveReply}
                    delete={this.deleteNote}
                    owned={note.userId === this.userId}
                />
            )
        })
    }

    handleOutsideClick(e) {
        /*if (this.node && !this.node.contains(e.target)) {
            this.props.close();
        }*/
        return
    }

    async saveSettings(record) {
        await API.patch('notes-settings/' + record._id, record, 2)
        this.toggleSettingsModal()
    }

    async saveReply(_id, reply) {
        await API.patch('notes/' + _id + '/reply', reply, 2)
        this.fetchNotes()
        logUserEvent('ADDED A REPLY')
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.open &&
            localStorage['first-time-using-notes'] === undefined
        ) {
            this.toggleSettingsModal()
            localStorage['first-time-using-notes'] = 'no'
        }
    }

    async componentWillMount() {
        const settings = await API.get('notes-settings/' + this.userId, 2)
        this.setState({settings})

        await this.fetchResources()
        await this.fetchNotes()

        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Devices/#`,
            this.fetchResources
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Users/#`,
            this.fetchResources
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Shifts/#`,
            this.fetchResources
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Notes/#`,
            this.fetchNotes
        )

        //this.subscriber.add(this.fetchResources, 1000 * 60, 'fetchResources');
        //this.subscriber.add(this.fetchNotes, 1000 * 10, 'fetchNotes');

        document.addEventListener('mousedown', this.handleOutsideClick, false)
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleOutsideClick, false)
        this.subscriber.removeAll()
        try {
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Devices/#`,
                this.fetchResources
            )
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Users/#`,
                this.fetchResources
            )
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Shifts/#`,
                this.fetchResources
            )
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Notes/#`,
                this.fetchNotes
            )
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.open ? (
                    <div
                        style={{
                            backgroundColor: '#fff',
                            position: 'fixed',
                            right: 0,
                            top:
                                document.documentElement.offsetHeight -
                                this.props.computeHeight(),
                            width: 350,
                            height: this.props.computeHeight(),
                            zIndex: 100,
                            boxShadow: '-5px 0px 47px -16px rgba(0,0,0,0.75)',
                        }}
                        ref={(node) => (this.node = node)}>
                        <Row>
                            <Col className="xs-10 mt-2 ml-2">
                                <h4>
                                    <strong>Notes (beta)</strong>
                                </h4>
                            </Col>
                            <Col className="xs-2 text-right">
                                <Button
                                    size="sm"
                                    className=""
                                    color="default"
                                    onClick={this.toggleSettingsModal}>
                                    <i
                                        className="iconsmind-Gears"
                                        style={{fontSize: '20px'}}
                                    />
                                </Button>
                                <Button
                                    size="sm"
                                    className=""
                                    color="default"
                                    onClick={this.props.close}>
                                    <i
                                        className="iconsmind-Close"
                                        style={{fontSize: '20px'}}
                                    />
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Dropdown
                                    isOpen={this.state.listDropdownOpen}
                                    toggle={this.toggleListDropdown}>
                                    <DropdownToggle
                                        size="xs"
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'grey',
                                            border: 'none',
                                        }}
                                        caret>
                                        {this.state.list}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            active={
                                                'My List' === this.state.list
                                            }
                                            disabled={
                                                'My List' === this.state.list
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    list: 'My List',
                                                    editingNoteId: null,
                                                })
                                            }}>
                                            My List
                                        </DropdownItem>
                                        <DropdownItem
                                            active={
                                                'Mentioned In' ===
                                                this.state.list
                                            }
                                            disabled={
                                                'Mentioned In' ===
                                                this.state.list
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    list: 'Mentioned In',
                                                    editingNoteId: null,
                                                })
                                            }}>
                                            Mentioned In
                                        </DropdownItem>
                                        <DropdownItem
                                            active={
                                                'All Notes' === this.state.list
                                            }
                                            disabled={
                                                'All Notes' === this.state.list
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    list: 'All Notes',
                                                    editingNoteId: null,
                                                })
                                            }}>
                                            All Notes
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <hr style={{margin: 0}} />
                            </Col>
                        </Row>

                        {!this.state.editingNoteId &&
                        this.state.list === 'My List' ? (
                            <Row>
                                <Col>
                                    <Button
                                        size="sm"
                                        className="text-muted btn-block"
                                        color="default"
                                        onClick={this.addNote}
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            fontSize: '15px',
                                        }}>
                                        <i
                                            className="iconsmind-Add"
                                            style={{paddingRight: '18px'}}
                                        />
                                        Add a note
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}

                        <Scrollbars
                            autoHeightMin={
                                this.props.computeHeight() - 42 - 23 - 40
                            }
                            autoHeightMax={
                                this.props.computeHeight() - 42 - 23 - 40
                            }
                            autoHeight>
                            {this.renderNotes()}
                        </Scrollbars>
                    </div>
                ) : null}

                {this.state.settingsModalOpen ? (
                    <Settings
                        toggle={this.toggleSettingsModal}
                        settings={this.state.settings}
                        saveSettings={this.saveSettings}
                    />
                ) : null}

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Fragment>
        )
    }
}
