export const inputs = [
    {
        label: 'Record Trigger',
        type: 'RECORD_TRIGGER',
        documentation: (
            <>
                Start the flow based on a creation/update/deletion of a record
                from a single data model. The record is stored as a variable and
                can be used throughout the flow. Filters can be specified if the
                execution of the flow is not needed for every
                created/updated/deleted record in the selected data model.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'RECORD_TRIGGER'
            )) {
                if (el.data.opts.dataModelId === null) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a data model`,
                    })
                }
                if (
                    Object.values(el.data.opts.trigger).every(
                        (v) => v === false
                    )
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must have at least one trigger selected`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Scheduled Trigger',
        type: 'SCHEDULED_TRIGGER',
        documentation: (
            <>
                Start the flow based on a specified start time. The flow can run
                only once or at re-occuring frequency.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'SCHEDULED_TRIGGER'
            )) {
                if (el.data.opts.cron === null || el.data.opts.cron === '') {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a schedule`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'On-Demand Trigger',
        type: 'ON_DEMAND_TRIGGER',
        documentation: (
            <>
                Start the flow based on a user or system action. Follow API
                documentation to learn more about initiating the flow.
            </>
        ),
    },
]

export const transforms = [
    {
        label: 'Get Record(s)',
        type: 'GET_RECORDS',
        documentation: (
            <>
                Fetch one or more data model records. If "Multi Records" is
                selected, the resulting variable cannot be used in flow logic
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'GET_RECORDS'
            )) {
                if (el.data.opts.dataModelId === null) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a data model`,
                    })
                }
                if (el.data.opts.requestURL === '') {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must have a valid request URL (default = https://data-models.accuminetech.com/data-models)`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Get Report',
        type: 'GET_REPORT',
        documentation: (
            <>
                Fetches a data model report. If report is not a "Total" report
                (i.e. 1 row), it cannot be used as a variable in future
                elements.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'GET_REPORT'
            )) {
                if (el.data.opts.dataModelId === null) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a data model`,
                    })
                }
                if (el.data.opts.requestBody === null) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a report configuration`,
                    })
                }
                if (el.data.opts.requestURL === '') {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must have a valid request URL (default = https://data-models.accuminetech.com/data-models)`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Modify',
        type: 'MODIFY',
        documentation: (
            <>
                Modify one or more variables using an expression. Note that this
                will overwrite the existing variable value.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'MODIFY'
            )) {
                if (
                    !el.data.opts.modifications ||
                    el.data.opts.modifications.length === 0
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" does not have any modifications defined`,
                    })
                }
                if (
                    el.data.opts.modifications &&
                    el.data.opts.modifications
                        .map((v) => v.path)
                        .some((v) => !v)
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" has missing field update paths`,
                    })
                }
                if (el.data.opts.modifications) {
                    for (let mod of el.data.opts.modifications) {
                        const variable = variables.find(
                            (a) => a.id === mod.variableId
                        )
                        if (!variable) {
                            errors.push({
                                transformId: el.id,
                                message: `Element "${el.data.label}" is trying to modify a missing variable`,
                            })
                        } else {
                            if (
                                variable.type === 'Record' ||
                                variable.type === 'Report'
                            ) {
                                let spl = mod.path.split('.')
                                spl.shift()
                                spl = spl.join('.')
                                if (
                                    !variable.fields.find((a) => a.path === spl)
                                ) {
                                    errors.push({
                                        transformId: el.id,
                                        message: `Element "${el.data.label}" is trying to modify a missing field path`,
                                    })
                                }
                            }
                            if (variable.multi === true) {
                                errors.push({
                                    transformId: el.id,
                                    message: `Element "${el.data.label}" cannot modify a list variable`,
                                })
                            }
                        }
                    }
                }
            }
            return errors
        },
    },
    {
        label: 'Decide',
        type: 'DECIDE',
        documentation: (
            <>
                Create one or more decision paths based on TRUE/FALSE logic from
                specified expressions.
                <br />
                WARNING: The first path that evaluates to TRUE will be used in
                the flow. If all paths are FALSE, the flow execution will end.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'DECIDE'
            )) {
                if (
                    el.data.opts.outcomes &&
                    el.data.opts.outcomes.length === 0
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" does not have any outcomes.`,
                    })
                }
                if (
                    el.data.opts.outcomes &&
                    el.data.opts.outcomes.map((a) => a.name).some((a) => !a)
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" has missing outcome names`,
                    })
                }
                if (
                    el.data.opts.outcomes &&
                    el.data.opts.outcomes.map((a) => a.name).length !==
                        new Set(el.data.opts.outcomes.map((a) => a.name)).size
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" has duplicate outcome names`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Pause',
        type: 'PAUSE',
        documentation: (
            <>
                Pauses the execution of the flow for a specified period of time.
                <br />
                <br />
                WARNING: Pausing a flow does not account for updates in the
                record/report variables that may have occurred during the pause
                period. If you are unsure if any changes may have occurred
                during the pause period, it is good practise to re-fetch the
                required data after the pause step.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'PAUSE'
            )) {
                if (el.data.opts.durationInMS < 1) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must have a duration greater than 0.`,
                    })
                }
                if (!el.data.opts.timeUnit) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must have a valid time unit`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Create Variable(s)',
        type: 'CREATE_VARIABLE',
        documentation: (
            <>
                Create new global flow variables that can be used in future
                elements connected to this element.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'CREATE_VARIABLE'
            )) {
                if (
                    el.data.opts.variables &&
                    el.data.opts.variables.length === 0
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must create at least 1 variable.`,
                    })
                }
                if (
                    el.data.opts.variables &&
                    el.data.opts.variables.map((a) => a.name).some((a) => !a)
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" has missing variable names`,
                    })
                }
                if (el.data.opts.variables) {
                    const within =
                        el.data.opts.variables.map((a) => a.name).length ===
                        new Set(el.data.opts.variables.map((a) => a.name)).size
                    const outside = el.data.opts.variables.some(
                        (a) =>
                            variables.find((b) => b.name === a.name) !==
                            undefined
                    )
                    if (!within || !outside) {
                        errors.push({
                            transformId: el.id,
                            message: `Element "${el.data.label}" has duplicate variable names`,
                        })
                    }
                }
            }
            return errors
        },
    },
    {
        label: 'Loop',
        type: 'LOOP',
        documentation: (
            <>
                Loop through a list of Report rows or Records and perform an
                action on each instance. Note that a temporary instance variable
                will be created automatically. This variable stores the current
                instance in the loop.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'LOOP'
            )) {
                if (
                    !elements.find(
                        (a) => a.id === el.data.opts.loopStartTransformId
                    )
                ) {
                    errors.push({
                        transformId: null, //el.id,
                        message: `Loop "${el.data.label}" is missing a connection to a beginning loop element.`,
                    })
                }
                if (
                    !elements.find(
                        (a) => a.id === el.data.opts.loopEndTransformId
                    )
                ) {
                    errors.push({
                        transformId: null, //el.id,
                        message: `Loop "${el.data.label}" is missing a connection to an ending loop element.`,
                    })
                }
                if (
                    !variables.find(
                        (a) => a.id === el.data.opts.loopThroughVariableId
                    )
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Loop "${el.data.label}" is using a missing loop through variable.`,
                    })
                } else if (
                    variables.find(
                        (a) => a.id === el.data.opts.loopThroughVariableId
                    ).multi === false
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `Loop "${el.data.label}" cannot loop through a non-list variable.`,
                    })
                }
            }
            return errors
        },
    },
]

export const outputs = [
    {
        label: 'Send Email(s)',
        type: 'SEND_EMAIL',
        documentation: (
            <>
                Send an email to one or more recipients. Records & Reports
                variables in the Email Body will automatically render as tables
                if they contain more than 1 record/row.
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'SEND_EMAIL'
            )) {
                if (
                    !el.data.opts.recipients ||
                    el.data.opts.recipients.plainText === ''
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" must have at least 1 recipient.`,
                    })
                }
                if (
                    !el.data.opts.subjectLine ||
                    el.data.opts.subjectLine.plainText === ''
                ) {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" must have a subject line.`,
                    })
                }
                if (!el.data.opts.body || el.data.opts.body.plainText === '') {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" must have an email body.`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Create Record',
        type: 'CREATE_RECORD',
        documentation: (
            <>
                Create one record of a selected data model. Ensure that the data
                model requirements are met when setting field values (i.e.
                required fields, unique fields, etc.).
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'CREATE_RECORD'
            )) {
                if (el.data.opts.dataModelId === null) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a data model`,
                    })
                }
                if (el.data.opts.recordTemplate === null) {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" is missing a record template`,
                    })
                }
                if (el.data.opts.requestURL === '') {
                    errors.push({
                        transformId: el.id,
                        message: `Element "${el.data.label}" must have a valid request URL (default = https://data-models.accuminetech.com/data-models)`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Update Record(s)',
        type: 'UPDATE_RECORD',
        documentation: (
            <>
                Update one or more records based on a selected variable. Ensure
                that the data model requirements are met when setting field
                values (i.e. required fields, unique fields, etc.).
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'UPDATE_RECORD'
            )) {
                const variable = variables.find(
                    (a) => a.id === el.data.opts.variableId
                )
                if (!variable) {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" is trying to update a missing variable.`,
                    })
                } else if (variable.type !== 'Record') {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" is trying to update a non-Record variable.`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Delete Record(s)',
        type: 'DELETE_RECORD',
        documentation: (
            <>
                Update one or more records based on a selected variable. Ensure
                that the data model requirements are met when setting field
                values (i.e. required fields, unique fields, etc.).
            </>
        ),
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'DELETE_RECORD'
            )) {
                const variable = variables.find(
                    (a) => a.id === el.data.opts.variableId
                )
                if (!variable) {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" is trying to update a missing variable.`,
                    })
                } else if (variable.type !== 'Record') {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" is trying to delete a non-Record variable.`,
                    })
                }
            }
            return errors
        },
    },
    {
        label: 'Send Form Data',
        type: 'SEND_FORM_DATA',
        documentation: <>Send a request with a form body type.</>,
        saveValidator: (elements, variables) => {
            let errors = []
            for (let el of elements.filter(
                (el) => !el.source && el.data.opts.type === 'SEND_FORM_DATA'
            )) {
                if (!el.data.opts.url) {
                    errors.push({
                        transformId: el.id,
                        message: `"${el.data.label}" is missing a Request URL value.`,
                    })
                }
            }
            return errors
        },
    },
]
