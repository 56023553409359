import autobind from 'auto-bind'
import moment from 'moment'
import * as API from 'SDK/api'

export default class CapacityAnalytics {
    constructor() {
        this._factory = null
        this._state = null
        this._assets = []
        this._schedule = []
        this._timeStart = null
        this._timeEnd = null
    }

    factory(factory) {
        this._factory = factory
        return this
    }

    state(state) {
        this._state = state.constructor === String ? [state] : state
        return this
    }

    assets(assets) {
        this._assets = assets
        return this
    }

    from(timeStart) {
        this._timeStart = timeStart
        return this
    }

    to(timeEnd) {
        this._timeEnd = timeEnd
        return this
    }

    schedule(schedule) {
        if (schedule === 'alltime') {
            this._schedule = 'alltime'
        } else if (schedule === 'scheduled') {
            this._schedule = this._factory._shifts.map((s) => s.name)
        } else if (schedule.split('shift-').length > 0) {
            this._schedule = [schedule.split('shift-')[1]]
        } else {
            this._schedule = 'alltime'
        }
        return this
    }

    async fetch() {
        this.fetchHourlyPeakUsage()
        this._days = []

        let promises = []

        for (let asset of this._assets) {
            if (this._schedule === 'alltime') {
                for (let state of this._state) {
                    promises.push(
                        asset.fetchHistoricalData({
                            timeStart: this._timeStart,
                            timeEnd: this._timeEnd,
                            name: state,
                        })
                    )
                }
            } else {
                for (let shift of this._schedule) {
                    for (let state of this._state) {
                        promises.push(
                            asset.fetchHistoricalData({
                                timeStart: this._timeStart,
                                timeEnd: this._timeEnd,
                                name: state,
                                shift: shift,
                            })
                        )
                    }
                }
            }
        }
        let data = await Promise.all(promises)

        for (let i = 0; i < data[0].dates.length; i++) {
            let day = {
                date: moment(data[0].dates[i]),
            }

            if (this._schedule === 'alltime') {
                for (let asset of this._assets) {
                    for (let state of this._state) {
                        day[asset.deviceId + '_' + state] = {
                            duration: [],
                            durationPercentage: [],
                            capacity: [],
                            count: [],
                        }

                        if (!day['total_' + state]) {
                            day['total_' + state] = {
                                duration: [],
                                durationPercentage: [],
                                capacity: [],
                                count: [],
                            }
                        }
                    }
                }
            } else {
                for (let asset of this._assets) {
                    for (let shift of this._schedule) {
                        for (let state of this._state) {
                            day[asset.deviceId + '_' + shift + '_' + state] = {
                                duration: null,
                                durationPercentage: null,
                                capacity: null,
                                count: null,
                            }
                            if (!day[asset.deviceId + '_scheduled_' + state]) {
                                day[asset.deviceId + '_scheduled_' + state] = {
                                    duration: [],
                                    durationPercentage: [],
                                    capacity: [],
                                    count: [],
                                }
                            }
                            if (!day['scheduled_' + state]) {
                                day['scheduled_' + state] = {
                                    duration: [],
                                    durationPercentage: [],
                                    capacity: [],
                                    count: [],
                                }
                            }
                            if (!day[shift + '_' + state]) {
                                day[shift + '_' + state] = {
                                    duration: [],
                                    durationPercentage: [],
                                    capacity: [],
                                    count: [],
                                }
                            }
                        }
                    }
                }
            }

            this._days.push(day)
        }

        const shifts = this._schedule !== 'alltime'

        for (let dataset of data) {
            dataset.duration.forEach((d, i) => {
                if (this._days[i]) {
                    this._days[i][
                        dataset.deviceId +
                            '_' +
                            (dataset.shift ? dataset.shift + '_' : '') +
                            dataset.name
                    ].duration = d

                    if (shifts) {
                        this._days[i][
                            dataset.deviceId + '_scheduled_' + dataset.name
                        ].duration.push(d)
                        this._days[i][
                            'scheduled_' + dataset.name
                        ].duration.push(d)
                        this._days[i][
                            dataset.shift + '_' + dataset.name
                        ].duration.push(d)
                    } else {
                        this._days[i]['total_' + dataset.name].duration.push(d)
                    }
                }
            })
            dataset.durationPercentage.forEach((d, i) => {
                if (this._days[i]) {
                    this._days[i][
                        dataset.deviceId +
                            '_' +
                            (dataset.shift ? dataset.shift + '_' : '') +
                            dataset.name
                    ].durationPercentage = d

                    if (shifts) {
                        this._days[i][
                            dataset.deviceId + '_scheduled_' + dataset.name
                        ].durationPercentage.push(d)
                        this._days[i][
                            'scheduled_' + dataset.name
                        ].durationPercentage.push(d)
                        this._days[i][
                            dataset.shift + '_' + dataset.name
                        ].durationPercentage.push(d)
                    } else {
                        this._days[i][
                            'total_' + dataset.name
                        ].durationPercentage.push(d)
                    }
                }
            })
            dataset.capacity.forEach((d, i) => {
                if (this._days[i]) {
                    this._days[i][
                        dataset.deviceId +
                            '_' +
                            (dataset.shift ? dataset.shift + '_' : '') +
                            dataset.name
                    ].capacity = d

                    if (shifts) {
                        this._days[i][
                            dataset.deviceId + '_scheduled_' + dataset.name
                        ].capacity.push(d)
                        this._days[i][
                            'scheduled_' + dataset.name
                        ].capacity.push(d)
                        this._days[i][
                            dataset.shift + '_' + dataset.name
                        ].capacity.push(d)
                    } else {
                        this._days[i]['total_' + dataset.name].capacity.push(d)
                    }
                }
            })
            dataset.count.forEach((d, i) => {
                if (this._days[i]) {
                    this._days[i][
                        dataset.deviceId +
                            '_' +
                            (dataset.shift ? dataset.shift + '_' : '') +
                            dataset.name
                    ].count = d

                    if (shifts) {
                        this._days[i][
                            dataset.deviceId + '_scheduled_' + dataset.name
                        ].count.push(d)
                        this._days[i]['scheduled_' + dataset.name].count.push(d)
                        this._days[i][
                            dataset.shift + '_' + dataset.name
                        ].count.push(d)
                    } else {
                        this._days[i]['total_' + dataset.name].count.push(d)
                    }
                }
            })
        }

        for (let day of this._days) {
            for (let key in day) {
                if (key === 'date') continue

                if (day[key].duration.constructor === Array) {
                    day[key].duration =
                        Math.round(
                            (day[key].duration.reduce((a, b) => a + b, 0) /
                                day[key].duration.length /
                                60) *
                                100
                        ) / 100
                    day[key].durationPercentage =
                        Math.round(
                            (day[key].durationPercentage.reduce(
                                (a, b) => a + b,
                                0
                            ) /
                                day[key].durationPercentage.length) *
                                100
                        ) / 100
                    day[key].capacity =
                        Math.round(
                            (day[key].capacity.reduce((a, b) => a + b, 0) /
                                day[key].capacity.length /
                                60) *
                                100
                        ) / 100
                    day[key].count =
                        Math.round(
                            (day[key].count.reduce((a, b) => a + b, 0) /
                                day[key].count.length /
                                60) *
                                100
                        ) / 100
                }
            }
        }

        return this
    }

    weeks() {
        let weeks = []

        for (let day of this._days) {
            if (
                !weeks.find((w) =>
                    w.date.isSame(moment(day.date).startOf('isoWeek'))
                )
            ) {
                let week = {
                    date: moment(day.date).startOf('isoWeek'),
                }
                for (let key in day) {
                    if (key === 'date') continue
                    week[key] = {
                        duration: [],
                        durationPercentage: [],
                        capacity: [],
                        count: [],
                    }
                }
                weeks.push(week)
            }
            let week = weeks.find((w) =>
                w.date.isSame(moment(day.date).startOf('isoWeek'))
            )

            for (let key in day) {
                if (key === 'date') continue
                week[key].duration.push(day[key].duration)
                week[key].durationPercentage.push(day[key].durationPercentage)
                week[key].capacity.push(day[key].capacity)
                week[key].count.push(day[key].count)
            }
        }
        for (let week of weeks) {
            for (let key in week) {
                if (key === 'date') continue
                if (week[key].duration.constructor === Array) {
                    week[key].duration =
                        Math.round(
                            week[key].duration.reduce((a, b) => a + b, 0) * 100
                        ) / 100
                    week[key].durationPercentage =
                        Math.round(
                            (week[key].durationPercentage.reduce(
                                (a, b) => a + b,
                                0
                            ) /
                                week[key].durationPercentage.length) *
                                100
                        ) / 100
                    week[key].capacity =
                        Math.round(
                            week[key].capacity.reduce((a, b) => a + b, 0) * 100
                        ) / 100
                    week[key].count =
                        Math.round(
                            week[key].count.reduce((a, b) => a + b, 0) * 100
                        ) / 100
                }
            }
        }
        return weeks
    }

    months() {
        let months = []

        for (let day of this._days) {
            if (
                !months.find((m) =>
                    m.date.isSame(moment(day.date).startOf('month'))
                )
            ) {
                let month = {
                    date: moment(day.date).startOf('month'),
                }
                for (let key in day) {
                    if (key === 'date') continue
                    month[key] = {
                        duration: [],
                        durationPercentage: [],
                        capacity: [],
                        count: [],
                    }
                }
                months.push(month)
            }
            let month = months.find((m) =>
                m.date.isSame(moment(day.date).startOf('month'))
            )

            for (let key in day) {
                if (key === 'date') continue
                month[key].duration.push(day[key].duration)
                month[key].durationPercentage.push(day[key].durationPercentage)
                month[key].capacity.push(day[key].capacity)
                month[key].count.push(day[key].count)
            }
        }
        for (let month of months) {
            for (let key in month) {
                if (key === 'date') continue
                if (month[key].duration.constructor === Array) {
                    month[key].duration =
                        Math.round(
                            month[key].duration.reduce((a, b) => a + b, 0) * 100
                        ) / 100
                    month[key].durationPercentage =
                        Math.round(
                            (month[key].durationPercentage.reduce(
                                (a, b) => a + b,
                                0
                            ) /
                                month[key].durationPercentage.length) *
                                100
                        ) / 100
                    month[key].capacity =
                        Math.round(
                            month[key].capacity.reduce((a, b) => a + b, 0) * 100
                        ) / 100
                    month[key].count =
                        Math.round(
                            month[key].count.reduce((a, b) => a + b, 0) * 100
                        ) / 100
                }
            }
        }
        return months
    }

    async fetchHourlyPeakUsage() {
        this._peak = []

        let data = await API.post(
            'ips/cloud/time-of-day-peak-usage',
            {
                timeStart: moment('1970-01-01').toISOString(),
                timeEnd: moment().startOf('hour').toISOString(),
                deviceIds: this._assets.map((a) => a.deviceId),
                timezone: localStorage['timezone'],
            },
            2
        )

        data = data.map((d) => {
            d._id.date = moment(d._id.date)
            return d
        })

        const currentPeriod = data.filter((d) =>
                d._id.date.isBetween(this._timeStart, this._timeEnd)
            ),
            previousPeriod = data.filter((d) =>
                d._id.date.isBetween(
                    moment(this._timeStart).add(
                        -this._timeEnd.diff(this._timeStart)
                    ),
                    this._timeStart
                )
            )

        for (let i = 0; i < 24; i++) {
            let relevantCurrent = currentPeriod
                    .filter((d) => d._id.date.hour() == i)
                    .map((d) => d.assets.length),
                relevantPrevious = previousPeriod
                    .filter((d) => d._id.date.hour() == i)
                    .map((d) => d.assets.length),
                relevantAll = data
                    .filter((d) => d._id.date.hour() == i)
                    .map((d) => d.assets.length)

            this._peak.push({
                hour: i,
                currentMax: Math.max(...relevantCurrent) / this._assets.length,
                previousMax:
                    Math.max(...relevantPrevious) / this._assets.length,
                allTimeMax: Math.max(...relevantAll) / this._assets.length,
                relevantCurrent,
                relevantPrevious,
                relevantAll,
            })
        }
    }
}
