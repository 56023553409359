import React, {Component, Fragment, useRef} from 'react'
import {Row, Col, Card, Button, CardHeader, CardBody} from 'reactstrap'
import {Bar} from 'react-chartjs-2'
import autoBind from 'auto-bind'
import {
    Document,
    Page,
    Text,
    View,
    PDFDownloadLink,
    Image,
    StyleSheet,
} from '@react-pdf/renderer'
import moment from 'moment'
import logo from '../../../../assets/img/L2L_ConnectLogo_greenblack.png'

let chartConfig = {
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    layout: {
        padding: {
            bottom: 15,
        },
    },
    scales: {
        yAxes: [
            {
                stacked: false,
                id: 'a',
                position: 'left',
                type: 'linear',
                ticks: {},
            },
            {
                id: 'b',
                position: 'right',
                type: 'linear',
                ticks: {
                    max: 100,
                    min: 0,
                    callback: function (value) {
                        return value + '%'
                    },
                },
                gridLines: {
                    display: false,
                },
            },
        ],
        xAxes: [
            {
                stacked: false,
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            document: null,
            ready: false,
        }
        this.chartRef = React.createRef()
    }

    export() {
        const image = this.chartRef.current.chartInstance.toBase64Image()
        const document = (
            <Document>
                <Page orientation={'landscape'} size="LETTER">
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{flexGrow: 0.3}}>
                            <Image
                                style={{
                                    height: 10,
                                    width: 100,
                                    objectFit: 'contain',
                                    margin: '4%',
                                }}
                                src={logo}
                            />
                        </View>
                        <View
                            style={{
                                flexGrow: 1,
                                marginTop: '4%',
                            }}>
                            <Text style={{fontWeight: 'bold'}}>
                                Pareto Chart Report
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            paddingTop: 100,
                            paddingLeft: 50,
                            paddingRight: 50,
                        }}>
                        <Image style={{width: '100%'}} src={image} />
                    </View>
                    <Text
                        style={{
                            position: 'absolute',
                            fontSize: 12,
                            bottom: 30,
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                            color: 'grey',
                        }}>
                        (generated on: {moment().format('llll')})
                    </Text>
                </Page>
            </Document>
        )

        this.setState({document, ready: true})
    }

    render() {
        chartConfig.scales.yAxes[0].ticks.callback = (value) =>
            value + ' ' + this.props.unitString
        chartConfig.onClick = (e, arr) => {
            if (arr.length) {
                const index = arr[0]._index
                this.props.handleReasonClick(
                    this.props.data.chartdata.labels[index]
                )
            }
        }

        if (this.props.data.chartdata.datasets.length > 2) {
            chartConfig.scales.xAxes[0].stacked = true
            chartConfig.scales.yAxes[0].stacked = true
        } else {
            chartConfig.scales.xAxes[0].stacked = false
            chartConfig.scales.yAxes[0].stacked = false
        }

        return (
            <Fragment>
                <Col xs="9" className="mb-4">
                    <Card>
                        <CardHeader>
                            {this.props.data.chartdata.shift}
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-2">
                                <Col>
                                    {this.state.ready ? (
                                        <Button color="secondary" size="xs">
                                            <PDFDownloadLink
                                                style={{color: '#fff'}}
                                                document={this.state.document}
                                                fileName="Pareto Chart.pdf">
                                                {({
                                                    blob,
                                                    url,
                                                    loading,
                                                    error,
                                                }) =>
                                                    loading
                                                        ? 'Generating...'
                                                        : 'Download'
                                                }
                                            </PDFDownloadLink>
                                        </Button>
                                    ) : (
                                        <Button
                                            color="secondary"
                                            size="xs"
                                            onClick={this.export}>
                                            Generate PDF
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Bar
                                        ref={this.chartRef}
                                        data={this.props.data.chartdata}
                                        options={chartConfig}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}
