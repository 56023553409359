import moment from 'moment'
import DataStore from './DataStore'
import * as API from 'SDK/api'

const makeTopDowntimeArray = (arr, key) => {
    const obj = arr.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})

    let data = []
    for (let o in obj) {
        data.push({
            field: o,
            totalMs: obj[o].reduce((a, b) => a + b.totalMs, 0),
        })
    }
    return data.sort((a, b) => b.totalMs - a.totalMs)
}
export default class DowntimeTreeMaker {
    constructor() {
        this.datastore = new DataStore()
        this.tree = []
        this.data = []
    }
    comparisonColor(ratio) {
        let color = '#28a745'
        if (ratio < 0.8 && ratio > 0.6) color = '#ffc107'
        else if (ratio <= 0.6) color = '#dc3545'
        return color
    }
    async make(timerange) {
        this.reset()
        this.datastore.setConfig(timerange, 'Downtime')

        await this.datastore.makeDataset()
        await this.fetchAllData()

        this.makeDurationRoot()

        for (let i in this.datastore.groups) {
            const g = this.datastore.groups[i]
            this.makeDurationGroup(g)
            let groupDevices = this.datastore.getGroupDevices(g)
            for (let j in groupDevices) {
                this.makeDurationDevice(g, groupDevices[j])
            }
        }
        return this.tree
    }
    reset() {
        this.tree = []
        this.data = []
    }
    async fetchAllData() {
        let promises = []
        for (let device of this.datastore.devices) {
            promises.push(this.fetchData(device.deviceId))
        }

        let data = await Promise.all(promises)

        for (let dataset of data) {
            this.data = this.data.concat(dataset)
        }
    }
    async makeDurationRoot() {
        let self = this

        let downtime = makeTopDowntimeArray(this.data, 'field')

        let node = {
            name: 'All Assets',
            _collapsed: true,
            totalDuration: 0,
            summary: downtime,
            attributes: {},
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
            children: [],
        }
        this.tree.push(node)
    }

    makeDurationGroup(group) {
        const devices = this.datastore.devices
            .filter((d) => d.groups[0] === group)
            .map((d) => d.deviceId)
        let downtime = makeTopDowntimeArray(
            this.data.filter((a) => devices.find((b) => b === a.deviceId)),
            'field'
        )

        let self = this
        let node = {
            name: group,
            _collapsed: true,
            totalDuration: 0,
            summary: downtime,
            attributes: {},
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
            children: [],
        }
        this.tree[0].children.push(node)
    }

    makeDurationDevice(group, device) {
        let downtime = makeTopDowntimeArray(
            this.data.filter((d) => d.deviceId === device),
            'field'
        )
        let self = this
        let node = {
            name: this.datastore.devices.find((d) => d.deviceId === device)
                .name,
            _collapsed: true,
            totalDuration: 0,
            summary: downtime,
            attributes: {},
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
        }
        let groupNode = this.tree[0].children.find((x) => x.name === group)
        groupNode.children.push(node)
    }
    async fetchData(device) {
        let start = this.datastore.config.timerange.timeStart,
            end = this.datastore.config.timerange.timeEnd
        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: device,
                    name: 'Downtime Reason',
                    timeStart: {
                        $lte: end.toISOString(),
                    },
                    $or: [
                        {
                            timeEnd: {$gte: start.toISOString()},
                        },
                        {
                            timeEnd: null,
                        },
                    ],
                },
            },
            2
        )

        data = data.map((d) => {
            d.timeStart = moment(d.timeStart)
            d.timeEnd = d.timeEnd === null ? moment() : moment(d.timeEnd)
            if (start.isAfter(d.timeStart)) {
                d.timeStart = moment(start)
            }
            if (d.timeEnd.isAfter(end)) {
                d.timeEnd = moment(end)
            }
            return d
        })
        let aggregated = [...new Set(data.map((d) => d.value))].map((d) => {
            return {
                field: d,
                totalMs: 0,
                totalCount: 0,
            }
        })
        for (let row of data) {
            const agg = aggregated.find((a) => a.field === row.value)
            if (agg) {
                agg.totalMs += row.timeEnd.diff(row.timeStart)
                agg.totalCount++
            }
        }

        aggregated = aggregated.map((a) => {
            a.value = Math.round((a.totalMs / (1000 * 60 * 60)) * 100) / 100
            return a
        })

        aggregated = aggregated.map((a) => {
            a.deviceId = device
            return a
        })
        return aggregated
    }
}
/*
  
  */
