import React, {useState} from 'react'
import {Mention, MentionsInput} from 'react-mentions'
import {Alert, Label, Collapse, Row, Col, Badge} from 'reactstrap'
import PropTypes from 'prop-types'
import {documentation} from '@accumine/mathjs-extensions'
const formulas = Object.keys(documentation)

import flattenVariables from '../../SDK/helpers/flattenVariables.js'
import style from './style.js'

const FormulaInput = (props) => {
    const [docsIsOpen, setDocsIsOpen] = useState(false)
    const toggleDocs = () => setDocsIsOpen(!docsIsOpen)
    const variables = flattenVariables(props.variables)
    const [mentionsValue, setMentionsValue] = useState({
        text: props.default ? props.default.text : '',
        plainText: props.default ? props.default.plainText : '',
        mentions: props.default ? props.default.mentions : [],
    })
    const [docDisplay, setDocDisplay] = useState(null)

    const onChange = (event, text, plainText, mentions) => {
        setMentionsValue({
            '@@isExpression@@': true,
            text,
            plainText,
            mentions,
        })
        props.onChange({
            '@@isExpression@@': true,
            text,
            plainText,
            mentions: mentions.map((m) => {
                return {
                    ...m,
                    isFormula: formulas.some((a) => a === m.id),
                }
            }),
            map: mentions.map((m) => {
                return {
                    id: m.id.split('.')[0],
                    display: m.display.split('.')[0],
                }
            }),
        })
    }

    const renderFormulaSuggestion = (
        entry,
        search,
        highlightedDisplay,
        index,
        focused
    ) => {
        return (
            <>
                <i className="iconsmind-Formula" /> {entry.display}
            </>
        )
    }

    const showDoc = (id, display, startPos, endPos) => {
        let doc = documentation[id]
        if (doc) {
            setDocDisplay({
                name: id,
                description: doc.description,
                usage: doc.usage,
                example: doc.example,
            })
        }
    }

    return (
        <>
            {docDisplay ? (
                <Alert color="primary">
                    <Row
                        onClick={toggleDocs}
                        className="m-0 p-0"
                        style={{cursor: 'pointer'}}>
                        <Col xs="6" className="m-0 p-0">
                            <strong>{docDisplay.name}</strong>
                        </Col>
                        <Col xs="6" className="m-0 p-0 text-right">
                            <i className="simple-icon-arrow-down" />
                        </Col>
                    </Row>
                    <Collapse isOpen={docsIsOpen}>
                        <hr className="m-1" />
                        <p className="m-0">{docDisplay.description}</p>
                        <hr className="m-1" />
                        <p className="m-0">
                            <strong>Usage</strong>:<br /> {docDisplay.usage}
                        </p>
                        <hr className="m-1" />
                        <p className="m-0">
                            <strong>Example</strong>:<br /> {docDisplay.example}
                        </p>
                    </Collapse>
                </Alert>
            ) : null}
            <Label>
                Type <b>:F</b> for formulas, <b>:V</b> for fields
            </Label>
            <MentionsInput
                value={mentionsValue.text}
                onChange={onChange}
                style={style}
                onDoubleClick={(e) =>
                    showDoc(window.getSelection().toString())
                }>
                <Mention
                    trigger={':V'}
                    data={variables}
                    style={{
                        color: '#fff!important',
                        zIndex: 1,
                        backgroundColor: '#2a93d5',
                    }}
                    markup={':V[__display__](__id__)'}
                />
                <Mention
                    trigger={':F'}
                    data={props.formulas.map((f) => {
                        return {id: f, display: f}
                    })}
                    style={{color: '#2a93d5', zIndex: 1}}
                    renderSuggestion={renderFormulaSuggestion}
                    onAdd={showDoc}
                    markup={':F[__display__](__id__)'}
                />
            </MentionsInput>
            <span>
                Current timezone: <strong>{localStorage['timezone']}</strong> |{' '}
                <a
                    target="_blank"
                    href="https://mathjs.org/docs/reference/functions.html"
                    rel="noreferrer">
                    More formulas
                </a>
                {' | '}
                <a
                    target="_blank"
                    href="https://mathjs.org/docs/expressions/syntax.html"
                    rel="noreferrer">
                    Syntax
                </a>
            </span>
            <br />
            <Badge>{props.type.toUpperCase()}</Badge>
            {props.type === 'formula' ? (
                <> Wrap literal text values in "", not needed for variables.</>
            ) : null}
        </>
    )
}

FormulaInput.propTypes = {
    variables: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            display: PropTypes.string,
        })
    ).isRequired,
    // id = display for formulas
    formulas: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    default: PropTypes.shape({
        text: PropTypes.string,
        plainText: PropTypes.string,
        mentions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                display: PropTypes.string,
            })
        ),
    }),
    type: PropTypes.oneOf(['string', 'html', 'formula']).isRequired,
}

export default FormulaInput
