import DataStore from './DataStore'

export default class TreeMaker {
    constructor() {
        this.datastore = new DataStore()
        this.tree = []
    }
    comparisonColor(ratio) {
        let color = '#28a745'
        if (ratio < 0.8 && ratio > 0.6) color = '#ffc107'
        else if (ratio <= 0.6) color = '#dc3545'
        return color
    }
    async make(timerange, state, durationBased = true) {
        this.reset()
        this.datastore.setConfig(timerange, state, durationBased)
        await this.datastore.makeDataset()
        if (durationBased) {
            this.makeDurationRoot()
            for (let i in this.datastore.groups) {
                const g = this.datastore.groups[i]
                this.makeDurationGroup(g)
                let groupDevices = this.datastore.getGroupDevices(g)
                for (let j in groupDevices) {
                    this.makeDurationDevice(g, groupDevices[j])
                }
            }
        } else {
            this.makeCountRoot()
            for (let i in this.datastore.groups) {
                const g = this.datastore.groups[i]
                this.makeCountGroup(g)
                let groupDevices = this.datastore.getGroupDevices(g)
                for (let j in groupDevices) {
                    this.makeCountDevice(g, groupDevices[j])
                }
            }
        }
        return this.tree
    }
    reset() {
        this.tree = []
    }
    makeDurationRoot() {
        const totalDuration = this.datastore.totalPlantDuration(),
            scheduledDuration = this.datastore.scheduledPlantDuration()

        let self = this
        let node = {
            name: 'All Assets',
            _collapsed: true,
            totalDuration: totalDuration,
            summary: {
                dailyHours: Math.round(totalDuration * 24 * 100) / 100 + ' hrs',
                scheduledPercentage: Math.floor(scheduledDuration * 100) + '%',
                shifts: this.datastore.shifts.map((x) => {
                    return {
                        name: x.name,
                        value:
                            Math.floor(
                                self.datastore.shiftPlantDuration(x.name) * 100
                            ) + '%',
                    }
                }),
            },
            attributes: {
                0: Math.floor(totalDuration * 100) + '%',
            },
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
            children: [],
        }
        this.tree.push(node)
    }
    makeCountRoot() {
        let self = this
        const totalCount = this.datastore.totalPlantCount()

        let shiftData = this.datastore.shifts.map((x) => {
            return {
                name: x.name,
                value: Math.floor(self.datastore.shiftPlantCount(x.name)),
            }
        })

        let node = {
            name: 'Plant',
            _collapsed: true,
            totalCount: totalCount,
            summary: {
                dailyCount: Math.floor(totalCount),
                shifts: shiftData,
                scheduledCount: Math.floor(
                    shiftData.reduce((total, next) => total + next.value, 0) /
                        shiftData.length
                ),
            },
            attributes: {
                0: Math.floor(totalCount),
            },
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
            children: [],
        }
        this.tree.push(node)
    }
    makeDurationGroup(group) {
        const totalDuration = this.datastore.totalGroupDuration(group),
            scheduledDuration = this.datastore.scheduledGroupDuration(group)

        const color = this.comparisonColor(
            totalDuration / this.tree[0].totalDuration
        )

        let self = this
        let node = {
            name: group,
            _collapsed: true,
            totalDuration: totalDuration,
            summary: {
                dailyHours: Math.round(totalDuration * 24 * 100) / 100 + ' hrs',
                scheduledPercentage: Math.floor(scheduledDuration * 100) + '%',
                shifts: this.datastore.shifts.map((x) => {
                    return {
                        name: x.name,
                        value:
                            Math.floor(
                                self.datastore.shiftGroupDuration(
                                    group,
                                    x.name
                                ) * 100
                            ) + '%',
                    }
                }),
            },
            attributes: {
                0: Math.floor(totalDuration * 100) + '%',
            },
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
            children: [],
        }
        this.tree[0].children.push(node)
    }
    makeCountGroup(group) {
        const totalCount = this.datastore.totalGroupCount(group)
        //console.log(totalCount)
        const color = this.comparisonColor(totalCount / this.tree[0].totalCount)

        let self = this
        let shiftData = this.datastore.shifts.map((x) => {
            return {
                name: x.name,
                value: Math.floor(
                    self.datastore.shiftGroupCount(group, x.name)
                ),
            }
        })
        //console.log(shiftData)

        let node = {
            name: group,
            _collapsed: true,
            totalCount: totalCount,
            summary: {
                dailyCount: Math.floor(totalCount),
                scheduledCount: Math.floor(
                    shiftData.reduce((total, next) => total + next.value, 0) /
                        shiftData.length
                ),
                shifts: shiftData,
            },
            attributes: {
                0: Math.floor(totalCount),
            },
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
            children: [],
        }
        this.tree[0].children.push(node)
    }
    makeDurationDevice(group, device) {
        const totalDuration = this.datastore.totalDeviceDuration(device),
            scheduledDuration = this.datastore.scheduledDeviceDuration(device)

        const color = this.comparisonColor(
            totalDuration /
                this.tree[0].children.find((c) => c.name === group)
                    .totalDuration
        )

        let self = this
        let node = {
            name: this.datastore.devices.find((d) => d.deviceId === device)
                .name,
            _collapsed: true,
            totalDuration: totalDuration,
            summary: {
                dailyHours: Math.round(totalDuration * 24 * 100) / 100 + ' hrs',
                scheduledPercentage: Math.floor(scheduledDuration * 100) + '%',
                shifts: this.datastore.shifts.map((x) => {
                    return {
                        name: x.name,
                        value:
                            Math.floor(
                                self.datastore.shiftDeviceDuration(
                                    device,
                                    x.name
                                ) * 100
                            ) + '%',
                    }
                }),
            },
            attributes: {
                0: Math.floor(totalDuration * 100) + '%',
            },
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
        }
        let groupNode = this.tree[0].children.find((x) => x.name === group)
        groupNode.children.push(node)
    }
    makeCountDevice(group, device) {
        const totalCount = this.datastore.totalDeviceCount(device)

        const color = this.comparisonColor(
            totalCount /
                this.tree[0].children.find((c) => c.name === group).totalCount
        )

        let self = this
        let shiftData = this.datastore.shifts.map((x) => {
            return {
                name: x.name,
                value: Math.floor(
                    self.datastore.shiftDeviceCount(device, x.name)
                ),
            }
        })

        let node = {
            name: this.datastore.devices.find((d) => d.deviceId === device)
                .name,
            _collapsed: true,
            totalCount: totalCount,
            summary: {
                dailyCount: Math.floor(totalCount),
                scheduledCount: Math.floor(
                    shiftData.reduce((total, next) => total + next.value, 0) /
                        shiftData.length
                ),
                shifts: shiftData,
            },
            attributes: {
                0: Math.floor(totalCount),
            },
            nodeSvgShape: {
                shape: 'circle',
                shapeProps: {
                    r: 20,
                    stroke: '#fff',
                    fill: '#237bb3',
                },
            },
        }
        let groupNode = this.tree[0].children.find((x) => x.name === group)
        groupNode.children.push(node)
    }
}
