import React, {Component, Fragment} from 'react'
import {Row, Col, ButtonGroup, Button} from 'reactstrap'
import Slider, {createSliderWithTooltip} from 'rc-slider'
import 'rc-slider/assets/index.css'
import autobind from 'auto-bind'
import moment from 'moment'

import TIMERANGES from '../Common/TIMERANGES'

const SliderWithTooltip = createSliderWithTooltip(Slider)

export default class HeatmapTimeline extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            mode: 'all', // days
            date: null,
        }
    }

    getTimerange() {
        if (!this.props.dataset) {
            return 0
        }

        const timerange = TIMERANGES.find(
            (t) => t.name === this.props.dataset.timerange
        ).timerange()
        return {
            length: timerange[1].diff(timerange[0], 'days'),
            from: timerange[0],
            to: timerange[1],
        }
    }

    setMode(mode) {
        this.setState({mode})
        this.props.onChange(mode, this.state.date)
    }

    setDay(dateIndex) {
        const timerange = TIMERANGES.find(
                (t) => t.name === this.props.dataset.timerange
            ).timerange(),
            date = moment(timerange[0]).add(dateIndex, 'days')
        this.setState({date})
        this.props.onChange(this.state.mode, date)
    }

    render() {
        const {from, to, length} = this.getTimerange()

        return (
            <Fragment>
                <Row>
                    <Col className="text-center">
                        <ButtonGroup size="xs">
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.setMode('days')
                                }}
                                active={this.state.mode === 'days'}>
                                Daily data
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.setMode('all')
                                }}
                                active={this.state.mode === 'all'}>
                                All data
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {this.state.mode === 'days' ? (
                    <Row>
                        <Col>
                            <SliderWithTooltip
                                min={0}
                                max={length}
                                defaultValue={0}
                                onChange={this.setDay}
                                tipFormatter={(value) =>
                                    moment(from)
                                        .add(value, 'days')
                                        .format('MMM D, YYYY')
                                }
                            />
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
