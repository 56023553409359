const SCHEDULES = [
    {
        label: 'All time (24 hours / day)',
        value: 'alltime',
    },
    {
        label: 'Scheduled time (all shifts)',
        value: 'scheduled',
    } /*, {
	label: 'Unscheduled time (overtime)',
	value: 'unscheduled'
}*/,
]

export default SCHEDULES
