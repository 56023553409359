import React, {Component, Fragment} from 'react'
import {NavLink} from 'react-router-dom'
import {Row, Card, Col, Button, ListGroup, ListGroupItem} from 'reactstrap'

export default class DowntimeSummary extends React.PureComponent {
    render() {
        const {viewing} = this.props
        return (
            <Fragment>
                <Row>
                    <Col xs="12" style={{textAlign: 'center'}}>
                        <h5>
                            <strong>{viewing.name}</strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {viewing.summary.slice(0, 5).map((s, i) => {
                                return (
                                    <ListGroupItem key={i}>
                                        {i + 1 + '. ' + s.field}
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
