import React, {Component, Fragment} from 'react'
import {Button, Row, Alert} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import {Colxx} from 'components/CustomBootstrap'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddShiftModal from './add'
import RemoveModal from './remove'
import EditModal from './edit'

const columns = [
    {
        Header: 'Shift Name',
        accessor: 'name',
    },
    {
        Header: 'Shift Group',
        accessor: 'description',
    },
    {
        Header: 'Timerange',
        accessor: 'timerange',
    },
    {
        Header: 'Days',
        accessor: 'daysString',
    },
    {
        Header: 'Timezone',
        accessor: 'timezone',
    },
]

export default class ShiftManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ShiftManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.fetchShifts = this.fetchShifts.bind(this)
        this.dataTableFormatter = this.dataTableFormatter.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.actions = this.actions.bind(this)
        this.toggleAddShiftModal = this.toggleAddShiftModal.bind(this)

        this.state = {
            assets: [],
            shifts: [],
            editingShift: null,
            addShiftModal: false,
        }
    }
    async fetchShifts() {
        let shifts = await API.get('shifts', 2)

        let allShifts = await API.get('shifts?all=true', 2)
        if (shifts && allShifts) {
            this.setState({shifts, allShifts})
        }
    }
    async fetchAssets() {
        let assets = await API.get('devices')
        this.setState({assets})
    }
    toggleAddShiftModal() {
        this.setState({
            addShiftModal: !this.state.addShiftModal,
        })
    }
    toggleModal(modalName, shift) {
        let state = this.state
        state.editingShift = shift
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (shift) => this.toggleModal('editModal', shift),
                actionName: 'Edit Shift',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (shift) => this.toggleModal('removeModal', shift),
                actionName: 'Remove Shift',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(shift) {
        if (shift.timeStart.hour > 12) {
            shift.from =
                shift.timeStart.hour -
                12 +
                ':' +
                (shift.timeStart.minute < 10 ? '0' : '') +
                shift.timeStart.minute +
                'pm'
        } else {
            shift.from =
                shift.timeStart.hour +
                ':' +
                (shift.timeStart.minute < 10 ? '0' : '') +
                shift.timeStart.minute +
                'am'
        }

        if (shift.timeEnd.hour > 12) {
            shift.to =
                shift.timeEnd.hour -
                12 +
                ':' +
                (shift.timeEnd.minute < 10 ? '0' : '') +
                shift.timeEnd.minute +
                'pm'
        } else {
            shift.to =
                shift.timeEnd.hour +
                ':' +
                (shift.timeEnd.minute < 10 ? '0' : '') +
                shift.timeEnd.minute +
                'am'
        }

        shift.timerange = `${shift.from} - ${shift.to}`

        shift.description = shift.description || 'Default'

        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        shift.daysString = ''
        for (let i in shift.days) {
            shift.daysString += days[shift.days[i]] + ', '
        }
        shift.daysString = shift.daysString.substr(
            0,
            shift.daysString.length - 2
        )
        return shift
    }

    editorModalAttributes(shift) {
        return [['Name', shift.name]]
    }

    async addNewShiftToResources(shift) {
        if (shift) {
            const environments = await API.get('environments', 2)
            let env = environments.find(
                (e) => e._id === this.props.environmentId
            )
            if (env && env.resources && env.resources.shifts) {
                env.resources.shifts.push(shift._id)
                await API.patch('environments/' + env._id, env, 2)
            }
        }
    }

    checkTimezone() {
        const timezones = this.state.shifts.map((a) => a.timezone)
        return [...new Set(timezones)].length === 1
    }

    checkAssets() {
        const shiftAssets = [
            ...new Set(this.state.shifts.map((a) => a.assets).flat()),
        ]
        let unassignedAssets = this.state.assets.filter(
            (a) => !shiftAssets.includes(a.deviceId)
        )

        if (unassignedAssets.length) {
            return (
                <Alert color="danger">
                    <p>
                        <b>
                            Every asset should be assigned to at least one shift
                        </b>
                    </p>
                    <b>{unassignedAssets.map((a) => a.name).join(', ')}</b> are
                    not assigned to any shift schedule. This will affect
                    reporting and real-time displays for these assets.
                </Alert>
            )
        } else {
            return null
        }
    }

    componentWillMount() {
        this.subscriber.add(
            this.fetchShifts,
            3000,
            'DigitalTwin_FactoryShifts_fetchShifts'
        )
        this.subscriber.add(
            this.fetchAssets,
            3000,
            'DigitalTwin_FactoryShifts_fetchAssets'
        )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstShiftEl = (
                <Button color="primary" onClick={this.toggleAddShiftModal}>
                    Add Your First Shift
                </Button>
            ),
            tableExists = this.state.shifts.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <>
                        <Row>
                            <Colxx
                                xs="12"
                                className="mb-2"
                                style={{textAlign: 'right'}}>
                                <Button
                                    color="primary"
                                    onClick={this.toggleAddShiftModal}>
                                    Add Shift
                                </Button>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xs="12" className="mb-2">
                                {!this.checkTimezone() ? (
                                    <Alert color="danger">
                                        <p>
                                            <b>Inconsistent timezones</b>
                                        </p>
                                        <p>
                                            Not every shift is in the timezone.
                                            Multi-timezone shift schedules are
                                            not recommended outside of complex
                                            deployments.
                                        </p>
                                    </Alert>
                                ) : null}
                                {this.checkAssets()}
                            </Colxx>
                        </Row>
                    </>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Shifts Found"
                    emptyCrudSubText={addFirstShiftEl}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.shifts}
                    editorModalTitle="Shift Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />
                {this.state.addShiftModal ? (
                    <AddShiftModal
                        toggleModal={this.toggleAddShiftModal}
                        modal={this.state.addShiftModal}
                        shifts={this.state.allShifts}
                        postSaveHook={this.addNewShiftToResources}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        shifts={this.state.allShifts}
                        editingShift={this.state.editingShift}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        shifts={this.state.allShifts}
                        editingShift={this.state.editingShift}
                    />
                ) : null}
            </Fragment>
        )
    }
}
