import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap'
import moment from 'moment'
import Sortable from 'react-sortablejs'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

// Accumine SDK imports
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import {getLiveProductionMetric, fetchShiftTimerange} from 'SDK/api/common'

// Accumine view components imports
import AssetChart from './chart'

export default class HubbellLeedConnectorsProductionDisplay extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'timerange',
            component: 'RealTimeTimerange',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'HubbellLeedConnectorsProductionDisplay'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        timerange: PropTypes.string,
        deviceIds: PropTypes.array,
        useRollingCycleTimeTargets: PropTypes.bool,
    }
    static defaultProps = {
        timerange: 'last24',
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            timerange: null,
            assets: null,
            dom: [],
            order: [],
            metric: 'hour',
            target: [],
        }
    }

    async fetchTargetThisHour() {
        if (!this.props.useRollingCycleTimeTargets) {
            const data = await API.post(
                'historical/raw',
                {
                    query: {
                        name: {$in: ['Hourly Target', 'Scheduled Target']},
                        timeEnd: null,
                    },
                },
                2
            )
            if (data && data.length) {
                this.setState({
                    target: data,
                })
            }
        } else {
            const totalCycleTime = await Promise.all(
                this.state.assets.map((a) => {
                    return API.post('historical', {
                        timeStart: moment().add(-30, 'days').toISOString(),
                        timeEnd: moment().toISOString(),
                        deviceId: a.deviceId,
                        state: 'Total Cycle Time',
                        unit: 'years',
                    })
                })
            )
            const totalParts = await Promise.all(
                this.state.assets.map((a) => {
                    return API.post('historical', {
                        timeStart: moment().add(-30, 'days').toISOString(),
                        timeEnd: moment().toISOString(),
                        deviceId: a.deviceId,
                        state: 'Part Count',
                        unit: 'years',
                    })
                })
            )
            let target = []
            for (let device of this.state.assets) {
                const time = totalCycleTime.find(
                        (a) => a.query.deviceId === device.deviceId
                    ).duration[0],
                    count = totalParts.find(
                        (a) => a.query.deviceId === device.deviceId
                    ).count[0],
                    hourlyTarget = 60 / (time / count)
                target.push({
                    value: Math.round(hourlyTarget * 100) / 100,
                    deviceId: device.deviceId,
                })
            }
            this.setState({target})
        }
    }

    async getMetric() {
        const metric = await getLiveProductionMetric()
        if (!metric) {
            //alert('There was an error initalizing this view.');
        } else {
            this.setState({metric})
        }
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
        let viewSettings = await API.get(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId,
            2
        )
        viewSettings.order = order
        await API.post(
            'view-settings/' +
                this.props.environmentId +
                '/' +
                this.props.sectionId +
                '/' +
                this.props.viewId,
            viewSettings,
            2
        )
        this.getData()
    }
    async getTimerange() {
        let live = await API.post('live', {
            deviceId: this.state.assets.map((x) => x.deviceId),
        })
        if (!live) {
            //alert('There was an error fetching data');
        } else {
            let timerange = [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().startOf('minute').toISOString(),
            ]

            if (this.props.timerange === 'shift') {
                const keys = Object.keys(live)
                if (keys.length) {
                    let shifts = live[keys[0]].activeShifts
                    if (shifts.length) {
                        timerange = await fetchShiftTimerange(
                            keys[0],
                            shifts[0]
                        )
                        timerange = [
                            moment(timerange[0])
                                .startOf('minute')
                                .toISOString(),
                            moment().startOf('minute').toISOString(),
                        ]
                    }
                }
            } else if (this.props.timerange === 'today') {
                timerange = [
                    moment().startOf('day').toISOString(),
                    moment().startOf('minute').toISOString(),
                ]
            }
            this.setState({live, timerange})
        }
    }

    async getAssets() {
        const assets = await API.get('devices')
        if (!assets) {
            //alert('There was an error initalizing this view.');
        } else {
            this.setState({assets, order: assets.map((x) => x.deviceId)})
        }
    }

    async getPartCountData() {
        let devices = this.state.assets
            .filter(
                (x) =>
                    !x.name.includes('Hartley') &&
                    !(x.groups.length && x.groups[0] === 'CNC')
            )
            .map((x) => x.deviceId)
        const timeStart = moment(this.state.timerange[0]).minute(),
            timeEnd = moment(this.state.timerange[1]).minute()
        let data = null

        if (timeStart > 0) {
            let raw = await API.post(
                'historical/raw',
                {
                    query: {
                        timeStart: {
                            $ne: null,
                        },
                        timeEnd: {
                            $gte: this.state.timerange[0],
                            $lte: moment(this.state.timerange[1])
                                .endOf('minute')
                                .toISOString(),
                        },
                        name: 'Part Count',
                        deviceId: {
                            $in: devices,
                        },
                    },
                },
                2
            )

            data = {
                dates: [],
                dateRanges: [],
                devices: {},
            }

            for (
                let m = moment(this.state.timerange[0]);
                m.diff(moment(this.state.timerange[1]), 'minutes') <= 0;
                m.add(1, 'hours')
            ) {
                data.dates.push(m.toISOString())
                data.dateRanges.push([moment(m), moment(m).add(1, 'hours')])
            }

            for (let i in devices) {
                data.devices[devices[i]] = {
                    'Part Count': new Array(data.dates.length).fill(0),
                }
            }

            for (let i in raw) {
                const {value, deviceId, timeEnd, metaData} = raw[i]
                const dateIndex = data.dateRanges.findIndex((d) =>
                    moment(timeEnd).isBetween(d[0], d[1])
                )
                if (dateIndex > -1) {
                    let metaDataObj = metaData.find(
                        (m) => m.name === 'partcount'
                    )
                    if (metaDataObj) {
                        data.devices[deviceId]['Part Count'][dateIndex] +=
                            metaDataObj.value
                    } else {
                        data.devices[deviceId]['Part Count'][dateIndex] += value
                    }
                }
            }
        } else {
            data = await API.post('historical/aggregate2', {
                timeStart: this.state.timerange[0],
                timeEnd: this.state.timerange[1],
                state: ['Part Count'],
                deviceId: devices,
                groupByTimeUnit: 'hour',
                logic: 'count',
            })
            data = data.data
            if (!data) return
        }

        let elapsedMinutes = moment(this.state.timerange[1]).diff(
                moment(this.state.timerange[0]),
                'minutes'
            ),
            metricMessage =
                this.state.metric === 'minute'
                    ? 'Parts / Minute'
                    : 'Parts / Hour',
            dom = []

        for (let i in this.state.assets.filter(
            (x) =>
                !x.name.includes('Hartley') &&
                !(x.groups.length && x.groups[0] === 'CNC')
        )) {
            let device = this.state.assets.filter(
                    (x) =>
                        !x.name.includes('Hartley') &&
                        !(x.groups.length && x.groups[0] === 'CNC')
                )[i],
                cols = [],
                countdata = [],
                hourdata = [],
                total = 0
            for (let j in data.devices[device.deviceId]['Part Count']) {
                let hour = moment(data.dates[j]).format('ha'),
                    count = data.devices[device.deviceId]['Part Count'][j]
                if (count.constructor === Object) count = count.count
                total += count
                countdata.push(count)
                hourdata.push(hour)
                cols.push(
                    <Col style={{textAlign: 'center'}}>
                        <small>{hour}</small>
                        <p>
                            {count
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                    </Col>
                )
            }

            if (this.state.metric === 'minute') {
                total = parseInt(total / elapsedMinutes)
            } else if (this.state.metric === 'hour') {
                total = parseInt(total / (elapsedMinutes / 60))
            }

            const targetRecord = this.state.target.find(
                (t) => t.deviceId === device.deviceId
            )
            const target = targetRecord ? targetRecord.value : 0
            const metricMessage = device.productionUnit
                ? device.productionUnit.charAt(0).toUpperCase() +
                  device.productionUnit.slice(1) +
                  's / Hour'
                : 'Parts / Hour'
            dom.push(
                <Col
                    data-id={device.deviceId}
                    key={i}
                    xs="12"
                    sm="6"
                    lg="4"
                    className="mb-4">
                    <Card>
                        <CardHeader
                            style={{
                                borderBottom: '1px solid #909090',
                                marginRight: '20px',
                                marginLeft: '20px',
                                paddingBottom: '0px',
                            }}>
                            <h1 className="float-left float-none-xs mt-2 mb-0">
                                {device.name}
                            </h1>
                            <div className="float-right float-none-xs mt-2 mb-0">
                                <h1
                                    style={{
                                        marginBottom: '0px',
                                        paddingBottom: '0px',
                                    }}>
                                    {total
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </h1>
                                <span> {metricMessage}</span>
                            </div>
                        </CardHeader>
                        <CardBody style={{paddingTop: 0}}>
                            <Row>
                                <Col>
                                    <AssetChart
                                        timerange={this.state.timerange}
                                        target={target}
                                        data={countdata}
                                        hours={hourdata}
                                        productionUnit={
                                            device.productionUnit
                                                ? device.productionUnit
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  device.productionUnit.slice(
                                                      1
                                                  ) +
                                                  's'
                                                : 'Parts'
                                        }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        }
        return dom
    }

    async getGreenStrengthData() {
        let devices = this.state.assets
            .filter((x) => x.name.includes('Hartley'))
            .map((x) => x.deviceId)
        let data = {},
            dates = []

        for (let deviceId of devices) {
            const response = await API.post('historical', {
                timeStart: this.state.timerange[0],
                timeEnd: this.state.timerange[1],
                deviceId: deviceId,
                unit: 'hours',
                state: 'Green Strength',
            })
            data[deviceId] = response.averageValue
            if (dates.length === 0) dates = response.dates
        }

        let elapsedMinutes = moment(this.state.timerange[1]).diff(
                moment(this.state.timerange[0]),
                'minutes'
            ),
            metricMessage =
                this.state.metric === 'minute'
                    ? 'Parts / Minute'
                    : 'Parts / Hour',
            dom = []

        for (let deviceId in data) {
            let device = this.state.assets.find((x) => x.deviceId === deviceId),
                cols = [],
                countdata = [],
                hourdata = [],
                total = 0

            for (let j in data[deviceId]) {
                let hour = moment(dates[j]).format('ha'),
                    count = data[deviceId][j]
                total += count
                countdata.push(count)
                hourdata.push(hour)
                cols.push(
                    <Col style={{textAlign: 'center'}}>
                        <small>{hour}</small>
                        <p></p>
                    </Col>
                )
            }

            if (this.state.metric === 'minute') {
                total = Math.round((total / elapsedMinutes) * 100) / 100
            } else if (this.state.metric === 'hour') {
                total = Math.round((total / (elapsedMinutes / 60)) * 100) / 100
            }

            dom.push(
                <Col
                    data-id={device.deviceId}
                    key={device.deviceId}
                    xs="12"
                    sm="6"
                    lg="4"
                    className="mb-4">
                    <Card>
                        <CardHeader
                            style={{
                                borderBottom: '1px solid #909090',
                                marginRight: '20px',
                                marginLeft: '20px',
                                paddingBottom: '0px',
                            }}>
                            <h1 className="float-left float-none-xs mt-2 mb-0">
                                {device.name}
                            </h1>
                            <div className="float-right float-none-xs mt-2 mb-0">
                                <h1
                                    style={{
                                        marginBottom: '0px',
                                        paddingBottom: '0px',
                                    }}>
                                    {total
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </h1>
                                <span> {'Avg. Green Strength'}</span>
                            </div>
                        </CardHeader>
                        <CardBody style={{paddingTop: 0}}>
                            <Row>
                                <Col>
                                    <AssetChart
                                        timerange={this.state.timerange}
                                        greenStrength={device.name.includes(
                                            'Hartley'
                                        )}
                                        data={countdata}
                                        hours={hourdata}
                                        productionUnit={'Avg. Green Strength'}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        }
        return dom
    }

    async getUtilizationData() {
        let devices = this.state.assets
            .filter((x) => x.groups.length && x.groups[0] === 'CNC')
            .map((x) => x.deviceId)
        let data = {},
            dates = [],
            average = {}

        for (let deviceId of devices) {
            const response = await API.post('historical', {
                timeStart: this.state.timerange[0],
                timeEnd: this.state.timerange[1],
                deviceId: deviceId,
                unit: 'hours',
                state: 'In-Cycle',
            })
            data[deviceId] = response.durationPercentage.map((a) =>
                Math.floor(a * 100)
            )
            average[deviceId] =
                Math.round(
                    (response.durationPercentage.reduce((a, b) => a + b, 0) /
                        response.durationPercentage.length) *
                        100 *
                        100
                ) / 100
            if (dates.length === 0) dates = response.dates
        }

        let elapsedMinutes = moment(this.state.timerange[1]).diff(
                moment(this.state.timerange[0]),
                'minutes'
            ),
            metricMessage =
                this.state.metric === 'minute'
                    ? 'Parts / Minute'
                    : 'Parts / Hour',
            dom = []

        for (let deviceId in data) {
            let device = this.state.assets.find((x) => x.deviceId === deviceId),
                cols = [],
                countdata = [],
                hourdata = [],
                total = 0

            for (let j in data[deviceId]) {
                let hour = moment(dates[j]).format('ha'),
                    count = data[deviceId][j]
                total += count
                countdata.push(count)
                hourdata.push(hour)
                cols.push(
                    <Col style={{textAlign: 'center'}}>
                        <small>{hour}</small>
                        <p></p>
                    </Col>
                )
            }

            if (this.state.metric === 'minute') {
                total = Math.round((total / elapsedMinutes) * 100) / 100
            } else if (this.state.metric === 'hour') {
                total = Math.round((total / (elapsedMinutes / 60)) * 100) / 100
            }

            dom.push(
                <Col
                    data-id={device.deviceId}
                    key={device.deviceId}
                    xs="12"
                    sm="6"
                    lg="4"
                    className="mb-4">
                    <Card>
                        <CardHeader
                            style={{
                                borderBottom: '1px solid #909090',
                                marginRight: '20px',
                                marginLeft: '20px',
                                paddingBottom: '0px',
                            }}>
                            <h1 className="float-left float-none-xs mt-2 mb-0">
                                {device.name}
                            </h1>
                            <div className="float-right float-none-xs mt-2 mb-0">
                                <h1
                                    style={{
                                        marginBottom: '0px',
                                        paddingBottom: '0px',
                                    }}>
                                    {average[deviceId]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    %
                                </h1>
                                <span> {'Avg. Hourly Utilization'}</span>
                            </div>
                        </CardHeader>
                        <CardBody style={{paddingTop: 0}}>
                            <Row>
                                <Col>
                                    <AssetChart
                                        timerange={this.state.timerange}
                                        utilization={true}
                                        data={countdata}
                                        hours={hourdata}
                                        productionUnit={'Hourly Utilization %'}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        }
        return dom
    }

    async getData() {
        let dom = (await this.getPartCountData())
            .concat(await this.getGreenStrengthData())
            .concat(await this.getUtilizationData())
        this.setState({dom})
    }

    async componentWillMount() {
        await this.getAssets()
        await this.getTimerange()
        await this.fetchTargetThisHour()
        await this.getData()
        this.subscriber.add(this.getTimerange, 1000 * 60, 'getTimerange')
        this.subscriber.add(this.getAssets, 1000 * 60, 'getAssets')
        this.subscriber.add(this.getData, 1000 * 60, 'getData')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        return (
            <Fragment>
                <Sortable
                    className="row icon-cards-row mb-2"
                    onChange={this.handleSort}>
                    {this.state.dom.length > 0 ? (
                        this.state.dom
                    ) : (
                        <div className="loading" />
                    )}
                </Sortable>
            </Fragment>
        )
    }
}
