import React, {Component, Fragment} from 'react'
import {CSVLink} from 'react-csv'

export default class extends Component {
    exportData() {
        const type = this.props.type,
            data = this.props.data,
            unitString = this.props.unitString

        let exportObject = []

        if (data.chartdata.datasets.find((d) => d.shift === true)) {
            let header = ['Reason Code'].concat(
                data.chartdata.datasets
                    .filter((d) => d.shift === true)
                    .map((d) => d.label + ' ' + unitString)
            )
            exportObject.push(header)

            for (let i in data.chartdata.labels) {
                let row = [data.chartdata.labels[i]]
                for (let dataset of data.chartdata.datasets.filter(
                    (d) => d.shift === true
                )) {
                    row.push(dataset.data[i])
                }
                exportObject.push(row)
            }
        } else {
            let header = ['Reason Code', unitString]
            exportObject.push(header)

            for (let i in data.chartdata.labels) {
                let row = [
                    data.chartdata.labels[i],
                    data.chartdata.datasets[1].data[i],
                ]
                exportObject.push(row)
            }
        }
        return exportObject
    }

    render() {
        const data = this.exportData()
        return (
            <Fragment>
                <CSVLink
                    className="btn btn-primary btn-xs mr-4 mb-2"
                    data={data}
                    filename="Accumine Platform - Pareto Chart Export.csv">
                    Download Pareto Chart Data
                </CSVLink>

                <CSVLink
                    className="btn btn-primary btn-xs mr-4 mb-2"
                    data={this.props.commentExport}
                    filename="Accumine Platform - Pareto Comments Export.csv">
                    Download Comments
                </CSVLink>

                <CSVLink
                    className="btn btn-primary btn-xs mb-2"
                    data={this.props.nounExport}
                    filename="Accumine Platform - Comments Analysis Export.csv">
                    Download Comments Analytics
                </CSVLink>
            </Fragment>
        )
    }
}
