import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

export default class EmptyCrud extends Component {
    static propTypes = {
        mainText: PropTypes.string,
        subText: PropTypes.any,
    }

    static defaultProps = {
        mainText: '',
        subText: '',
    }

    render() {
        return (
            <Fragment>
                <h2 style={{textAlign: 'center'}}>{this.props.mainText}</h2>
                <h5 style={{textAlign: 'center'}}>{this.props.subText}</h5>
            </Fragment>
        )
    }
}
