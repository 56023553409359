import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)

        this.save = this.save.bind(this)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            nicknameBorderColor: 'rgb(215,215,215)',
            mac: '',
            nickname: '',
            valid: false,
            message: false,
        }
    }
    async save() {
        try {
            let response = await API.post(
                'nodes/' + this.state.mac + '/register',
                {
                    nodeType: 'GWY',
                    nodeComm: 'TCP',
                    alias: this.state.nickname,
                },
                2
            )

            if (response.success) {
                this.props.toggleModal()
            } else {
                return alert(
                    'There was an error adding your Gateway. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error adding your Gateway. Please try again.'
            )
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add Gateway</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>MAC Address:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="mac"
                                    value={this.state.mac}
                                    onChange={(mac) => {
                                        let v = mac.target.value.toLowerCase(), //.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase(),
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.props.nodes.find(
                                                (node) => node.nodeId === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'This MAC Address already exists.'
                                        }
                                        this.setState({
                                            mac: v,
                                            valid: valid,
                                            inputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Nickname:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nicknameBorderColor,
                                    }}
                                    type="text"
                                    name="nickname"
                                    value={this.state.nickname}
                                    onChange={(nickname) => {
                                        let v = nickname.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.props.nodes.find(
                                                (node) => node.alias === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'This nickname already exists.'
                                        }
                                        this.setState({
                                            nickname: v,
                                            valid: valid,
                                            nicknameBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Add Gateway
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
