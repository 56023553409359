import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button, Alert} from 'reactstrap'
import * as _ from 'lodash'
import uuidv1 from 'uuid/v1'
import * as SRD from 'storm-react-diagrams'
import './storm-react-diagrams.css'
import {cloneDeep} from 'lodash'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import {makeTemplate} from './flowmaker'

import Sidebar from './sidebar'
import StatusBar from './status-bar'
import ConfirmCancelModal from './confirmCancelModal'
import ConfirmDeleteModal from './confirmDeleteModal'
import ChooseTemplateModal from './chooseTemplateModal'
import StreamDataModal from './streamDataModal'
import AlertModal from './alertModal'
import PublishFlowModal from './PublishFlowModal'

import {TRANSFORMS} from './transforms'

import BrowserFlow from './browser'

const browserFlow = new BrowserFlow()

const BASE_URL = process.env.REACT_APP_PP_WEBHOOK_URL

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.flow = null

        this.state = {
            asset: null,
            selectedNode: null,
            showConfirmCancelModal: false,
            showConfirmDeleteModal: false,
            isNewFlow: this.props.match.params.id === 'new',
            name:
                this.props.match.params.id === 'new'
                    ? new URLSearchParams(this.props.location.search).get(
                          'name'
                      )
                    : '',
            showChooseTemplateModal: this.props.match.params.id === 'new',
            showStreamDataModal: false,
            nodes: [],
            devices: [],
            flows: [],
            showAlertModal: false,
            alerts: {},
            showPublishFlowModal: false,
        }
    }

    toggleChooseTemplateModal() {
        this.setState({
            showChooseTemplateModal: !this.state.showChooseTemplateModal,
        })
    }

    toggleStreamDataModal(node) {
        this.setState({
            showStreamDataModal: !this.state.showStreamDataModal,
            streamDataModalNode: node,
        })
    }

    toggleAlertModal() {
        this.setState({
            showAlertModal: !this.state.showAlertModal,
        })
    }

    togglePublishFlowModal() {
        const serialized = this.model.serializeDiagram(),
            template = makeTemplate(serialized)

        let obj = {
            serialized: serialized,
            template: template,
            color: '#fff',
            display: false,
            alerts: [],
        }

        this.setState({
            showPublishFlowModal: !this.state.showPublishFlowModal,
            publishObj: obj,
        })
    }

    fetchAvailableInputs(asset, allInputs, allNodes) {
        const relatedIds = [asset.deviceId].concat(
            allNodes
                .filter((n) => n.deviceId === asset.deviceId)
                .map((n) => n.nodeId)
        )

        asset.availableInputs = allInputs.filter((o) => {
            return relatedIds.find((x) => x === o.id) !== undefined
        })

        asset.availableInputs = asset.availableInputs.map((input) => {
            const node = allNodes.find((n) => n.nodeId === input.id)
            if (node && node.nodeType === 'ARD') {
                const {pins} = node.settings
                /*const gwy = allNodes.find(n => n.nodeId === node.gatewayId),
              { pins } = gwy.settings.nodes.find(n => n.nodeId === node.nodeId);
        */
                const associatedPin = pins.find((p) => p.pinId === input.name)
                if (associatedPin) {
                    input.aliasedName = input.name
                    input.name = associatedPin.nickname
                }
            }
            return input
        })
        return asset
    }

    async fetchDevice() {
        let assets = await API.get('devices?all=true'),
            asset = assets.find(
                (x) => x.deviceId === this.props.match.params.deviceId
            ),
            allAvailableInputs = await API.get('datainputs/inputs', 2),
            allNodes = await API.get('nodes', 2),
            allOtherAssets = assets.filter((a) => a.deviceId !== asset.deviceId)

        asset = this.fetchAvailableInputs(asset, allAvailableInputs, allNodes)

        allOtherAssets = allOtherAssets.map((a) => {
            return this.fetchAvailableInputs(a, allAvailableInputs, allNodes)
        })

        this.setState({
            asset: asset,
            nodes: allNodes,
            devices: assets,
            allOtherAssets,
        })
    }

    async fetchFlows() {
        const flows = await API.get('flows', 2),
            id = this.props.match.params.id

        let alerts = {}

        if (id !== 'new') {
            const flow = flows.find((x) => x._id === id)
            if (flow) {
                alerts = flow.alerts
            }
        }

        this.setState({flows, alerts})
    }

    handleInputNodeSelectionEvent(event) {
        const selectedNode = {
            type: 'input',
            name: event.entity.name,
            _raw: event,
        }

        this.setState({selectedNode})
    }

    handleTransformNodeSelectionEvent(event) {
        const selectedNode = {
            type: 'transform',
            name: event.entity.name,
            _raw: event,
        }

        this.setState({selectedNode})
    }

    handleOutputNodeSelectionEvent(event) {
        const selectedNode = {
            type: 'output',
            name: event.entity.name,
            _raw: event,
        }

        this.setState({selectedNode})
    }

    addInput({id, name, aliasedName}, x, y) {
        const node = new SRD.DefaultNodeModel(name, '#145388'),
            port = node.addOutPort('Output')

        node.setPosition(x, y)
        node.extras = {
            type: 'input',
            id: id,
            aliasedName: aliasedName,
            nodeId: uuidv1(),
        }

        const m = this.model.addNode(node),
            self = this
        m.addListener({
            selectionChanged: self.handleInputNodeSelectionEvent,
        })
        this.engine.repaintCanvas()
    }

    addTransform(name, x, y) {
        const node = new SRD.DefaultNodeModel(name, '#d39e00'),
            transform = TRANSFORMS.find((x) => x.name === name)

        node.addOutPort('Output')

        if (transform.links[0] === transform.links[1]) {
            for (let i = 1; i <= transform.links[0]; i++) {
                node.addInPort('Input')
            }
        } else if (transform.links[0] < transform.links[1]) {
            for (let i = 1; i <= transform.links[0]; i++) {
                node.addInPort('Input')
            }
            for (let i = transform.links[0]; i < transform.links[1]; i++) {
                node.addInPort('Input (optional)')
            }
        }

        node.setPosition(x, y)
        node.extras = {
            type: 'transform',
            params: {},
            nodeId: uuidv1(),
        }

        const m = this.model.addNode(node),
            self = this
        m.addListener({
            selectionChanged: self.handleTransformNodeSelectionEvent,
        })
        this.engine.repaintCanvas()
    }

    addOutput(name, x, y) {
        const node = new SRD.DefaultNodeModel(name, 'rgb(40, 167, 69)'),
            port = node.addInPort('Input')

        node.setPosition(x, y)
        node.extras = {
            type: 'output',
            nodeId: uuidv1(),
        }

        const m = this.model.addNode(node),
            self = this
        m.addListener({
            selectionChanged: self.handleOutputNodeSelectionEvent,
        })
        this.engine.repaintCanvas()
    }

    renderDiagram() {
        return (
            <div
                className="diagram-layer"
                style={{height: '100%'}}
                onDrop={(event) => {
                    const data = JSON.parse(
                            event.dataTransfer.getData('storm-diagram-node')
                        ),
                        nodesCount = _.keys(this.model.getNodes()).length

                    let node = null,
                        points = this.engine.getRelativeMousePoint(event)

                    if (data.type === 'input') {
                        this.addInput(data.record, points.x, points.y)
                    } else if (data.type === 'transform') {
                        this.addTransform(data.name, points.x, points.y)
                    } else if (data.type === 'output') {
                        this.addOutput(data.name, points.x, points.y)
                    }
                }}
                onDragOver={(event) => {
                    event.preventDefault()
                }}>
                <SRD.DiagramWidget
                    deleteKeys={[46]}
                    diagramEngine={this.engine}
                    maxNumberPointsPerLink={0}
                    style={{height: '1000px'}}
                />
            </div>
        )
    }

    toggleConfirmCancelModal() {
        this.setState({
            showConfirmCancelModal: !this.state.showConfirmCancelModal,
        })
    }

    toggleConfirmDeleteModal() {
        this.setState({
            showConfirmDeleteModal: !this.state.showConfirmDeleteModal,
        })
    }

    setAlertValues(obj) {
        let alerts = this.state.alerts
        alerts[obj.id] = obj
        delete alerts[obj.id].id
        this.setState({
            alerts,
        })
    }

    streamData(node) {
        const template = makeTemplate(this.model.serializeDiagram()),
            id = node._raw.entity.extras.nodeId

        template.forEach((n) => {
            if (n.transform === '$output') {
                n.transform = '$passThrough'
            }
        })

        try {
            browserFlow.addFlow(cloneDeep(template))
            this.toggleStreamDataModal(node)
        } catch (error) {
            console.log(error)
            return alert(error.message)
        }
    }

    async save() {
        this.setState({loading: true})

        const serialized = this.model.serializeDiagram(),
            template = makeTemplate(serialized)

        if (template.length === 0) return alert('Cannot submit empty flow.')

        try {
            browserFlow.addFlow(cloneDeep(template))
        } catch (error) {
            return alert(error.message)
        }

        if (this.state.isNewFlow) {
            const search = new URLSearchParams(this.props.location.search),
                name = search.get('name')

            let obj = {
                name: name,
                deviceId: this.props.match.params.deviceId,
                serialized: serialized,
                template: template,
                color: '#fff',
                display: false,
                alerts: this.state.alerts,
            }
            await API.post('flows/register', obj, 2)
            this.setState({loading: false})
            this.props.history.push({
                pathname: '/app/core/flows',
                state: {
                    device: {
                        label: this.state.asset.name,
                        value: this.state.asset.deviceId,
                    },
                },
            })
        } else {
            const flows = await API.get('flows', 2),
                flow = flows.find(
                    (f) =>
                        f._id === this.props.match.params.id &&
                        f.deviceId === this.props.match.params.deviceId
                )

            flow.serialized = serialized
            flow.template = template
            flow.alerts = this.state.alerts
            API.patch('flows/' + flow._id, flow, 2)
            this.setState({loading: false})
            this.props.history.push({
                pathname: '/app/core/flows',
                state: {
                    device: {
                        label: this.state.asset.name,
                        value: this.state.asset.deviceId,
                    },
                },
            })
        }
    }

    async loadFlow(flowId) {
        if (flowId) {
            this.toggleChooseTemplateModal()
        }

        this.engine = new SRD.DiagramEngine()
        this.engine.installDefaultFactories()
        this.model = new SRD.DiagramModel()

        if (this.props.match.params.id !== 'new') {
            const flows = await API.get('flows', 2),
                flow = flows.find(
                    (f) =>
                        f._id === this.props.match.params.id &&
                        f.deviceId === this.props.match.params.deviceId
                )

            this.setState({name: flow.name})

            this.model.deSerializeDiagram(flow.serialized, this.engine)
            for (let i in this.model.nodes) {
                const node = this.model.nodes[i]
                if (node.extras.type === 'input') {
                    node.addListener({
                        selectionChanged: this.handleInputNodeSelectionEvent,
                    })
                }
                if (node.extras.type === 'transform') {
                    node.addListener({
                        selectionChanged:
                            this.handleTransformNodeSelectionEvent,
                    })
                }
                if (node.extras.type === 'output') {
                    node.addListener({
                        selectionChanged: this.handleOutputNodeSelectionEvent,
                    })
                }
            }
        } else if (flowId) {
            const flows = await API.get('flows', 2)

            let flow = null

            if (flowId.constructor === String) {
                flow = flows.find((f) => f._id === flowId)
            } else {
                flow = flowId
            }
            this.model.deSerializeDiagram(flow.serialized, this.engine)
            for (let i in this.model.nodes) {
                let node = this.model.nodes[i]
                if (node.extras.type === 'input') {
                    const availableDataInput =
                        this.state.asset.availableInputs.find(
                            (input) => input.name === node.name
                        )

                    if (!availableDataInput) {
                        alert(
                            'Data input "' +
                                node.name +
                                '" does not exist for this asset!'
                        )
                        //let otherAssetName = this.state.devices.find(d => d.deviceId === flow.deviceId);
                        //otherAssetName = otherAssetName ? otherAssetName.name : 'Placeholder';
                        //node.name = node.name + ' (' + otherAssetName +')';
                    } else {
                        node.extras.id = availableDataInput.id
                    }

                    node.addListener({
                        selectionChanged: this.handleInputNodeSelectionEvent,
                    })
                }
                if (node.extras.type === 'transform') {
                    node.addListener({
                        selectionChanged:
                            this.handleTransformNodeSelectionEvent,
                    })
                }
                if (node.extras.type === 'output') {
                    node.addListener({
                        selectionChanged: this.handleOutputNodeSelectionEvent,
                    })
                }
            }
        }
        this.model.setGridSize(10)
        this.engine.setDiagramModel(this.model)

        this.model.addListener({
            linksUpdated: (e) => {
                if (e.isCreated) {
                    // prevent input => output links
                    if (e.link.sourcePort.in) {
                        this.model.removeLink(e.link)
                        return
                    }
                    e.link.addListener({
                        targetPortChanged: (f) => {
                            if (Object.keys(f.port.links).length > 2) {
                                e.link.sourcePort.removeLink(e.link)
                                e.link.targetPort.removeLink(e.link)
                                this.model.removeLink(e.link)
                            }
                        },
                    })
                }
            },
        })
        this.engine.repaintCanvas()
    }

    async componentWillMount() {
        await this.loadFlow()
        await this.fetchFlows()
        await this.fetchDevice()
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.67 + 'px'
        return (
            <Fragment>
                <Alert color="primary" className="text-center">
                    <div>
                        Publishing data to: <strong>{BASE_URL}</strong>
                    </div>
                </Alert>
                {this.state.asset ? (
                    <Fragment>
                        <Row>
                            <Col xs="9" className="text-left mb-2">
                                <h4>
                                    {this.state.asset.name} - {this.state.name}
                                </h4>
                            </Col>
                            <Col xs="3" className="mb-2">
                                <Button
                                    color="primary"
                                    size="sm"
                                    className="mr-1"
                                    onClick={this.save}>
                                    Save
                                </Button>
                                <Button
                                    color="default"
                                    size="sm"
                                    className="mr-1"
                                    onClick={() => {
                                        this.setState({
                                            showConfirmCancelModal: true,
                                        })
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    color="info"
                                    size="sm"
                                    className="mr-1"
                                    onClick={this.togglePublishFlowModal}>
                                    Publish
                                </Button>
                                {!this.state.isNewFlow ? (
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                            this.setState({
                                                showConfirmDeleteModal: true,
                                            })
                                        }}>
                                        Delete
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                sm="3"
                                style={{height: height, paddingRight: 0}}>
                                <Sidebar
                                    inputs={this.state.asset.availableInputs}
                                    allOtherAssets={this.state.allOtherAssets}
                                />
                            </Col>

                            <Col xs="12" sm="6" style={{height: height}}>
                                <Card style={{height: '100%'}}>
                                    <CardBody>{this.renderDiagram()}</CardBody>
                                </Card>
                            </Col>

                            <Col
                                xs="12"
                                sm="3"
                                className="mb-1"
                                style={{height: height, paddingRight: 0}}>
                                <StatusBar
                                    engine={this.engine}
                                    model={this.model}
                                    selectedNode={this.state.selectedNode}
                                    streamData={this.streamData}
                                    toggleAlertModal={this.toggleAlertModal}
                                    devices={this.state.devices}
                                    nodes={this.state.nodes}
                                />
                            </Col>
                        </Row>
                        <ConfirmCancelModal
                            history={this.props.history}
                            modal={this.state.showConfirmCancelModal}
                            device={{
                                value: this.state.asset.deviceId,
                                label: this.state.asset.name,
                            }}
                            toggleModal={this.toggleConfirmCancelModal}
                        />

                        <ConfirmDeleteModal
                            history={this.props.history}
                            modal={this.state.showConfirmDeleteModal}
                            toggleModal={this.toggleConfirmDeleteModal}
                            flowId={this.props.match.params.id}
                            device={{
                                value: this.state.asset.deviceId,
                                label: this.state.asset.name,
                            }}
                        />
                    </Fragment>
                ) : (
                    <div className="loading" />
                )}

                <ChooseTemplateModal
                    modal={this.state.showChooseTemplateModal}
                    toggleModal={this.toggleChooseTemplateModal}
                    loadFlow={this.loadFlow}
                />

                {this.state.showStreamDataModal ? (
                    <StreamDataModal
                        modal={this.state.showStreamDataModal}
                        toggleModal={this.toggleStreamDataModal}
                        browserFlow={browserFlow}
                        node={this.state.streamDataModalNode}
                        nodes={this.state.nodes}
                        devices={this.state.devices}
                    />
                ) : null}

                {this.state.showPublishFlowModal ? (
                    <PublishFlowModal
                        modal={this.state.showPublishFlowModal}
                        toggleModal={this.togglePublishFlowModal}
                        publishObj={this.state.publishObj}
                    />
                ) : null}

                {this.state.showAlertModal ? (
                    <AlertModal
                        modal={this.state.showAlertModal}
                        toggleModal={this.toggleAlertModal}
                        flows={this.state.flows}
                        setAlertValues={this.setAlertValues}
                        alerts={this.state.alerts}
                        selectedNode={this.state.selectedNode}
                    />
                ) : null}
            </Fragment>
        )
    }
}
