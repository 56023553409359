import React, {Component, Fragment} from 'react'
import {Modal, ModalHeader, ModalBody, Button, Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import NumberKeypad from './NumberKeypad'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            quantity: String(this.props.qty),
        }
    }

    async submit() {
        this.props.setQty(this.state.quantity)
        this.props.toggleModal()
    }

    render() {
        return (
            <Fragment>
                <Modal size="lg" isOpen={true} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>
                        CHANGE MANUAL ENTRY PIECE INCREMENT
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <h5 style={{textAlign: 'center'}}>
                                    <strong>ENTER QUANTITY:</strong>
                                </h5>
                                <hr />
                                <NumberKeypad
                                    onChange={(quantity) =>
                                        this.setState({quantity})
                                    }
                                    showDecimal={true}
                                    value={this.state.quantity}
                                />
                            </Col>
                        </Row>

                        {Number(this.state.quantity) > 0 && (
                            <>
                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <Button
                                            block
                                            color="success"
                                            onClick={this.submit}>
                                            SUBMIT
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
