import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup} from 'reactstrap'
import autobind from 'auto-bind'

import Template from '../Template'
import Navigation from '../../Navigation'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Powder',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            selectedSection: null,
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.selectedSection === null ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <Navigation path={this.path} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Powder</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <ButtonGroup vertical>
                                    <Button
                                        size="xl"
                                        onClick={() =>
                                            this.setState({
                                                selectedSection:
                                                    'Additive Batches',
                                            })
                                        }>
                                        ADDITIVE BATCHES
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() =>
                                            this.setState({
                                                selectedSection:
                                                    'Calcined Additive Batches',
                                            })
                                        }>
                                        CAL. ADDITIVE BATCHES
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() =>
                                            this.setState({
                                                selectedSection: 'PVA Batches',
                                            })
                                        }>
                                        PVA BATCHES
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() =>
                                            this.setState({
                                                selectedSection:
                                                    'Dispersion Batches',
                                            })
                                        }>
                                        DISPERSION BATCHES
                                    </Button>
                                    <Button
                                        size="xl"
                                        onClick={() =>
                                            this.setState({
                                                selectedSection:
                                                    'Powder Batches',
                                            })
                                        }>
                                        POWDER BATCHES
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <Fragment>
                        {this.state.selectedSection === 'Additive Batches' ? (
                            <Template
                                title={'Additive Batches'}
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                dataModelId={'5fc689840283dc2398844995'}
                                path={this.path}
                                submitting={() =>
                                    this.setState({loading: true})
                                }
                                manualDataLinkFilters={[
                                    // boron
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Boron'],
                                        fieldId: 'Boron_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Boron_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // silver
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Silver Nitrate'],
                                        fieldId: 'Silver_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Silver_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // tin
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Tin'],
                                        fieldId: 'Tin_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Tin_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // manganese
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Manganese'],
                                        fieldId: 'Manganese_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Manganese_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // cobalt
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Cobalt'],
                                        fieldId: 'Cobalt_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Cobalt_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // nickel
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Nickel'],
                                        fieldId: 'Nickel_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Nickel_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // Antimony
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Antimony'],
                                        fieldId: 'Antimony_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Antimony_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                    // bismuth
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Bismuth'],
                                        fieldId: 'Bismuth_Lot.Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Bismuth_Lot.Lot',
                                        path: 'Raw Released',
                                    },
                                ]}
                                batchNumberField={'Batch Number'}
                            />
                        ) : null}

                        {this.state.selectedSection ===
                        'Calcined Additive Batches' ? (
                            <Template
                                title={'Calcined Additive Batches'}
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                dataModelId={'5fc7d67607ceae4f317ffcdf'}
                                submitting={() =>
                                    this.setState({loading: true})
                                }
                                path={this.path}
                                manualDataLinkFilters={[
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Aluminum'],
                                        fieldId: 'Aluminum Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Aluminum Lot',
                                        path: 'Raw Released',
                                    },
                                    {
                                        type: 'Text',
                                        logic: 'is',
                                        value: ['Dispersant'],
                                        fieldId: 'Dispersant Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Dispersant Lot',
                                        path: 'Raw Released',
                                    },
                                ]}
                                batchNumberField={'Batch Number'}
                            />
                        ) : null}

                        {this.state.selectedSection === 'PVA Batches' ? (
                            <Template
                                title={'PVA Batches'}
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                dataModelId={'5fc7df2a07ceae4f317ffcf6'}
                                submitting={() =>
                                    this.setState({loading: true})
                                }
                                path={this.path}
                                manualDataLinkFilters={[
                                    {
                                        type: 'Text',
                                        logic: 'contains',
                                        value: ['PVA'],
                                        fieldId: 'PVA Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'PVA Lot',
                                        path: 'Raw Released',
                                    },
                                    {
                                        type: 'Text',
                                        logic: 'contains',
                                        value: ['Defoamer'],
                                        fieldId: 'Defoamer Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Defoamer Lot',
                                        path: 'Raw Released',
                                    },
                                ]}
                                batchNumberField={'Batch Number'}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Dispersion Batches' ? (
                            <Template
                                title={'Dispersion Batches'}
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                dataModelId={'5fc7e1e907ceae4f317ffd00'}
                                submitting={() =>
                                    this.setState({loading: true})
                                }
                                path={this.path}
                                manualDataLinkFilters={[
                                    {
                                        type: 'Boolean',
                                        logic: 'isFalse',
                                        fieldId:
                                            'Cal Additive Batches.Cal Additive Batch',
                                        path: 'Used',
                                    },
                                    {
                                        type: 'Text',
                                        logic: 'contains',
                                        value: ['Dispersant'],
                                        fieldId: 'Dispersant Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Dispersant Lot',
                                        path: 'Raw Released',
                                    },
                                    {
                                        type: 'Text',
                                        logic: 'contains',
                                        value: ['Zinc'],
                                        fieldId: 'Zinc Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Zinc Lot',
                                        path: 'Raw Released',
                                    },
                                    {
                                        type: 'Text',
                                        logic: 'contains',
                                        value: ['Defoamer'],
                                        fieldId: 'Defoamer Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Defoamer Lot',
                                        path: 'Raw Released',
                                    },
                                    {
                                        type: 'Text',
                                        logic: 'contains',
                                        value: ['Plasticizer'],
                                        fieldId: 'Plasticizer Lot',
                                        path: 'Raw Chemical.Value',
                                    },
                                    {
                                        type: 'Boolean',
                                        logic: 'isTrue',
                                        fieldId: 'Plasticizer Lot',
                                        path: 'Raw Released',
                                    },
                                ]}
                                batchNumberField={'Batch Number'}
                            />
                        ) : null}

                        {this.state.selectedSection === 'Powder Batches' ? (
                            <Template
                                title={'Powder Batches'}
                                reset={() => {
                                    this.setState({selectedSection: null})
                                }}
                                dataModelId={'5fc7e66407ceae4f317ffd22'}
                                submitting={() =>
                                    this.setState({loading: true})
                                }
                                manualDataLinkFilters={[
                                    {
                                        type: 'Boolean',
                                        logic: 'isFalse',
                                        fieldId:
                                            'Dispersion Batches.Dispersion Batch',
                                        path: 'Used',
                                    },
                                ]}
                                path={this.path}
                                batchNumberField={'Batch Number'}
                            />
                        ) : null}
                    </Fragment>
                )}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
