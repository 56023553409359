const {
    clearDelayedInterval,
    setDelayedInterval,
} = require('delayable-setinterval')

export default class Subscription {
    constructor(func, ms, name) {
        if (typeof func !== 'function') {
            throw Error('Invalid function provided')
        }
        if (isNaN(ms)) {
            throw Error('Invalid interval time provided')
        }
        if (ms < 100) {
            throw Error('Interval time cannot be less than 100ms')
        }
        if (!name || typeof name !== 'string') {
            throw Error('Invalid subscription name')
        }
        this.func = func
        this.ms = ms
        this.name = name
        this.interval = 0
    }
    async init() {
        await this.func()
        let self = this
        this.interval = setDelayedInterval(async () => {
            await this.func()
        }, self.ms)
    }
    stop() {
        clearDelayedInterval(this.interval)
    }
}
