import React, {Component, Fragment} from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DataTablePagination from 'components/DataTables/pagination'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Form,
    Label,
    Input,
    TagsInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import moment from 'moment'
import Switch from 'rc-switch'
import PropTypes from 'prop-types'

import {logoutUser} from 'redux/actions'

import {apiUrl} from '../../../constants/defaultValues'

export default class WorkOrderLog extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = ['Part Count', 'Work Order', 'In-Cycle', 'Downtime']
    showBorder = false
    id = 'WorkOrderLog'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)

        this.handleStartChange = this.handleStartChange.bind(this)
        this.handleEndChange = this.handleEndChange.bind(this)
        this.getAssets = this.getAssets.bind(this)
        this.generate = this.generate.bind(this)

        this.state = {
            token: JSON.parse(localStorage['userObject']).token,
            data: [],
            start: moment().startOf('week').toDate(),
            end: moment().endOf('day').subtract(1, 'days').toDate(),
            assets: [],
            ready: false,
            loading: true,
        }
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }
    async getAssets() {
        try {
            let {data} = await axios.get(apiUrl + 'assets/devices', {
                headers: {
                    Authorization: this.state.token,
                },
            })
            this.setState({assets: data})
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
    }
    async getCuttingTime(workorder) {
        try {
            const {data} = await axios.post(
                apiUrl + 'historical/aggregate2',
                {
                    timeStart: moment(workorder.timeStart).toISOString(),
                    timeEnd: moment(workorder.timeEnd).toISOString(),
                    deviceId: [workorder.deviceId],
                    state: ['In-Cycle'],
                    logic: 'duration',
                    groupByTimeUnit: 'total',
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            let cuttingMs = data.data.devices[workorder.deviceId]['In-Cycle'][0]
            cuttingMs =
                cuttingMs /
                moment(workorder.timeEnd).diff(moment(workorder.timeStart))
            cuttingMs = parseInt(cuttingMs * 100)
            return cuttingMs
        } catch (error) {
            throw Error(error)
        }
    }
    async getCuts(workorder) {
        try {
            const {data} = await axios.post(
                apiUrl + 'historical/aggregate2',
                {
                    timeStart: moment(workorder.timeStart).toISOString(),
                    timeEnd: moment(workorder.timeEnd).toISOString(),
                    deviceId: [workorder.deviceId],
                    state: ['Part Count'],
                    logic: 'count',
                    groupByTimeUnit: 'total',
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            let cuts =
                data.data.devices[workorder.deviceId]['Part Count'][0].count
            return cuts
        } catch (error) {
            throw Error(error)
        }
    }
    async generate() {
        this.setState({loading: true})
        try {
            const {data} = await axios.post(
                apiUrl + 'historical/raw',
                {
                    name: 'Work Order',
                    timeStart: {
                        $gte: moment(this.state.start).toISOString(),
                        $lt: moment(this.state.end).toISOString(),
                    },
                    timeEnd: {$ne: null},
                },
                {
                    headers: {
                        Authorization: this.state.token,
                    },
                }
            )
            let workorders = [],
                self = this
            for (let i in data.data) {
                const duration = moment(data.data[i].timeEnd).diff(
                    moment(data.data[i].timeStart),
                    'minutes'
                )
                if (duration < 2) {
                    continue
                }
                workorders.push(
                    new Promise((resolve, reject) => {
                        ;async () => {
                            const row = data.data[i]
                            const cuttingTime = await self.getCuttingTime(row)
                            const numberOfCuts = await self.getCuts(row)
                            let workorder = {
                                assetName: self.state.assets.find(
                                    (x) => x.deviceId === row.deviceId
                                ).name,
                                wo: row.metaData.find(
                                    (x) => x.name === 'workorder'
                                ).value,
                                timeStart: moment(
                                    data.data[i].timeStart
                                ).format('YYYY-MM-DD h:mm a'),
                                timeEnd: moment(data.data[i].timeEnd).format(
                                    'YYYY-MM-DD h:mm a'
                                ),
                                cuts: numberOfCuts,
                                cutting: cuttingTime + '%',
                            }
                            resolve(workorder)
                        }
                    })
                )
            }
            const processedData = await Promise.all(workorders)
            this.setState({
                loading: false,
                data: processedData,
            })
        } catch (error) {
            console.log(error)
            alert('Error encountered. Please logout and refresh page')
        }
        this.setState({loading: false})
    }
    async componentWillMount() {
        await this.getAssets()
        this.generate()
    }
    componentWillUnmount() {}
    render() {
        return (
            <Fragment>
                <Row className="mb-2">
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="6">
                                        <label>From:</label>
                                        <DatePicker
                                            selected={this.state.start}
                                            selectsStart
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleStartChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <label>To:</label>
                                        <DatePicker
                                            selected={this.state.end}
                                            selectsEnd
                                            startDate={this.state.start}
                                            endDate={this.state.end}
                                            onChange={this.handleEndChange}
                                            filterDate={(date) => {
                                                return moment() > date
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </Col>
                                    <Col
                                        xs="12"
                                        sm="12"
                                        className="mt-1 text-right">
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <ReactTable
                                    defaultPageSize={10}
                                    data={this.state.data}
                                    resolveData={(data) => data.map((x) => x)}
                                    columns={[
                                        {
                                            Header: 'Work Order',
                                            accessor: 'wo',
                                        },
                                        {
                                            Header: 'Asset',
                                            accessor: 'assetName',
                                        },
                                        {
                                            Header: 'Start',
                                            accessor: 'timeStart',
                                        },
                                        {
                                            Header: 'End',
                                            accessor: 'timeEnd',
                                        },
                                        {
                                            Header: 'Parts',
                                            accessor: 'cuts',
                                        },
                                        {
                                            Header: 'Utilization',
                                            accessor: 'cutting',
                                        },
                                    ]}
                                    minRows={undefined}
                                    PaginationComponent={DataTablePagination}
                                    loading={false}
                                    filterable={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
