import React, {Component, Fragment} from 'react'
import {Row, Col, Button, ButtonGroup} from 'reactstrap'
import autobind from 'auto-bind'

import Template from '../Template'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <Template
                    title={'Collar Batches'}
                    //reset={}
                    dataModelId={'5fc7ef7c07ceae4f317ffd37'}
                    submitting={() => this.setState({loading: true})}
                    path={this.props.path}
                    manualDataLinkFilters={[
                        {
                            type: 'Text',
                            logic: 'contains',
                            value: ['Laponite'],
                            fieldId: 'Laponite Lot',
                            path: 'Raw Chemical.Value',
                        },
                        {
                            type: 'Boolean',
                            logic: 'isTrue',
                            fieldId: 'Laponite Lot',
                            path: 'Raw Released',
                        },
                        {
                            type: 'Text',
                            logic: 'contains',
                            value: ['CMC'],
                            fieldId: 'CMC Lot',
                            path: 'Raw Chemical.Value',
                        },
                        {
                            type: 'Boolean',
                            logic: 'isTrue',
                            fieldId: 'CMC Lot',
                            path: 'Raw Released',
                        },
                        {
                            type: 'Text',
                            logic: 'contains',
                            value: ['TiO2'],
                            fieldId: 'TiO2 Lot',
                            path: 'Raw Chemical.Value',
                        },
                        {
                            type: 'Boolean',
                            logic: 'isTrue',
                            fieldId: 'TiO2 Lot',
                            path: 'Raw Released',
                        },
                        {
                            type: 'Text',
                            logic: 'contains',
                            value: ['Mullite'],
                            fieldId: 'Mullite Lot',
                            path: 'Raw Chemical.Value',
                        },
                        {
                            type: 'Boolean',
                            logic: 'isTrue',
                            fieldId: 'Mullite Lot',
                            path: 'Raw Released',
                        },
                        {
                            type: 'Text',
                            logic: 'contains',
                            value: ['Glass'],
                            fieldId: 'Glass Lot',
                            path: 'Raw Chemical.Value',
                        },
                        {
                            type: 'Boolean',
                            logic: 'isTrue',
                            fieldId: 'Glass Lot',
                            path: 'Raw Released',
                        },
                    ]}
                    batchNumberField={'Batch Number'}
                />
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
