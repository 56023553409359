import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }
    async remove() {
        if (
            !(await API.remove(
                'manualstates/' + this.props.editingState._id,
                2
            ))
        ) {
            return alert(
                'There was an error deleting this manual state. Please try again.'
            )
        } else {
            this.props.toggleModal('removeModal')
        }
    }

    render() {
        let {modal, toggleModal, editingState} = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('removeModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('removeModal')
                        }}>
                        Remove Manual State
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <p>
                                    Are you sure you want to remove this manual
                                    state?
                                </p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('removeModal')
                            }}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={this.remove}>
                            Remove
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
