export default class Shift {
    constructor({
        _id,
        name,
        days,
        timeStart,
        timeEnd,
        breaks,
        breakTime,
        timezone,
        assets,
    }) {
        this.sync({
            _id,
            name,
            days,
            timeStart,
            timeEnd,
            breaks,
            breakTime,
            timezone,
            assets,
        })
    }

    sync({
        _id,
        name,
        days,
        timeStart,
        timeEnd,
        breaks,
        breakTime,
        timezone,
        assets,
    }) {
        this._id = _id
        this.name = name
        this.days = days
        this.timeStart = timeStart
        this.timeEnd = timeEnd
        this.breaks = breaks
        this.breakTime = breakTime
        this.timezone = timezone
        this.assets = assets
    }
}
