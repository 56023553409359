import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardHeader,
    CardFooter,
    CardBody,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    ButtonDropdown,
} from 'reactstrap'
import AddModal from 'SDK/ui/widgets/SimpleMaintenance/add'
import RemoveModal from 'SDK/ui/widgets/SimpleMaintenance/remove'
import EditModal from 'SDK/ui/widgets/SimpleMaintenance/edit'
import StatusModal from 'SDK/ui/widgets/SimpleMaintenance/status'
import ChecklistAddModal from 'SDK/ui/widgets/SimplePreventativeMaintenance/add'
import ChecklistEditModal from 'SDK/ui/widgets/SimplePreventativeMaintenance/edit'
import ChecklistFillModal from 'SDK/ui/widgets/SimplePreventativeMaintenance/fill'
import ChecklistScheduleModal from 'SDK/ui/widgets/SimplePreventativeMaintenance/schedule'
import autobind from 'auto-bind'
import moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {Scrollbars} from 'react-custom-scrollbars'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classnames from 'classnames'

import {logUserEvent} from 'SDK/api/common'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class MaintenanceApp extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.userId = JSON.parse(localStorage['userObject'])._id
        this.subscriber = new Subscriber()

        this.firstFetch = true

        this.state = {
            tickets: [],
            checklists: [],
            devices: [],
            shifts: [],
            users: [],
            notes: [],
            editingNoteId: null,
            listDropdownOpen: false,
            list: 'My List',
            activeTab: 1,
            workflows: [],
            editing: null,
            optionDropdownOpen: false,
            dropdowns: {},
            checklistDropdowns: {},
            dropdownTypeOpen: false,
            typeSelected: 'Tickets',
            departmentSelected: 'Maintenance',
            checklistAddModal: false,
            checklistEditModal: false,
            checklistFillModal: false,
            checklistScheduleModal: false,
        }

        this.fetchDevices()
        this.fetchTickets()
        this.fetchWorkflows()
        this.fetchChecklists()

        //this.subscriber.add(this.ifOpenDigest, 3000, 'ifOpenDigest()');
    }

    async fetchDevices() {
        let devices = await API.get('devices')
        if (!devices) {
            return alert('There was an error fetching data')
        }
        this.setState({devices})
    }

    getDeviceName(deviceId) {
        return this.state.devices.find((a) => a.deviceId === deviceId)
            ? this.state.devices.find((a) => a.deviceId === deviceId).name
            : 'N/A'
    }

    dropdownTypeToggle() {
        this.setState({dropdownTypeOpen: !this.state.dropdownTypeOpen})
    }

    async fetchChecklists() {
        let checklists = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Checklist',
                },
            },
            2
        )

        if (checklists) {
            this.setState({checklists})
        }
    }

    async fetchTickets() {
        let tickets = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Maintenance Ticket',
                    isComplete: false,
                    timeStart: {
                        $gte: moment().subtract(6, 'months').toISOString(),
                    },
                },
                options: {
                    sort: {
                        timeStart: -1,
                    },
                },
            },
            2
        )

        if (tickets) {
            this.setState({tickets})
        }
    }

    ifOpenDigest() {
        if (this.props.open) {
            this.fetchTickets()
            this.fetchWorkflows()
            this.fetchChecklists()
        }
    }

    async fetchWorkflows() {
        let workflows = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Workflow',
                },
            },
            2
        )

        if (workflows) {
            this.setState({workflows})
        }
    }

    toggleListDropdown() {
        this.setState({listDropdownOpen: !this.state.listDropdownOpen})
    }

    handleOutsideClick(e) {
        //(this.props.open) ? this.props.close() : null;
    }

    toggle(tab) {
        this.setState({activeTab: tab})
    }

    componentDidUpdate(prevProps) {}

    async componentWillMount() {
        const settings = await API.get('notes-settings/' + this.userId, 2)
        this.setState({settings})

        document.addEventListener('mousedown', this.handleOutsideClick, false)

        this.fetchTickets()
        this.fetchWorkflows()
        this.fetchChecklists()

        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/Maintenance Ticket/#`,
            this.ifOpenDigest
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/Workflow/#`,
            this.ifOpenDigest
        )
        this.props.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/Checklist/#`,
            this.ifOpenDigest
        )
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleOutsideClick, false)
        try {
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/States/Maintenance Ticket/#`,
                this.ifOpenDigest
            )
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/States/Workflow/#`,
                this.ifOpenDigest
            )
            this.props.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/States/Checklist/#`,
                this.ifOpenDigest
            )
        } catch (error) {
            console.log(error)
        }
    }

    toggleModal(modalName, code) {
        let state = this.state
        state.editing = code
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal,
        })
    }

    toggleStatusModal() {
        this.setState({
            statusModal: !this.state.statusModal,
        })
    }

    toggleDropdown(ticketId) {
        let dropdowns = this.state.dropdowns
        dropdowns[ticketId] = !this.state.dropdowns[ticketId]
        this.setState({dropdowns})
    }

    toggleChecklistDropdown(checklistId) {
        let checklistDropdowns = this.state.checklistDropdowns
        checklistDropdowns[checklistId] =
            !this.state.checklistDropdowns[checklistId]
        this.setState({checklistDropdowns})
    }

    dropdownTypeSelect(tag) {
        this.setState({typeSelected: tag})
    }

    toggleChecklistEditModal(checklist) {
        let state = this.state
        state.editing = checklist
        state.checklistEditModal = !state.checklistEditModal
        this.setState({state})
    }

    toggleChecklistAddModal() {
        let state = this.state
        state.checklistAddModal = !state.checklistAddModal
        this.setState({state})
    }

    toggleChecklistFillModal(checklist) {
        let state = this.state
        state.editing = checklist
        state.checklistFillModal = !state.checklistFillModal
        this.setState({state})
    }

    toggleChecklistScheduleModal(checklist) {
        let state = this.state
        state.editing = checklist
        state.checklistScheduleModal = !state.checklistScheduleModal
        this.setState({state})
    }

    renderTickets(tickets) {
        return tickets.map((ticket) => {
            const description = ticket.metaData.find(
                (o) => o.name === 'description'
            ).value
            const deadline = ticket.metaData.find(
                (o) => o.name === 'deadline'
            ).value
            const assignment = ticket.assignment.label
            const deviceName = this.getDeviceName(ticket.deviceId)
            const equipment =
                ticket.equipment && ticket.equipment.label
                    ? ticket.equipment.label
                    : 'N/A'
            const ticketId = ticket._id
            return ticket.department.value == this.state.departmentSelected &&
                !ticket.isComplete ? (
                <Fragment>
                    <Card
                        key={ticket._id}
                        style={{
                            margin: 15,
                            marginRight: 35,
                            borderRadius: 5,
                            boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.23)',
                        }}>
                        <CardBody>
                            <div style={{marginBottom: 10}}>
                                <span
                                    onClick={(code) =>
                                        this.toggleModal('editModal', ticket)
                                    }
                                    style={{
                                        fontSize: '8pt',
                                        backgroundColor: ticket.metaData.find(
                                            (o) => o.name === 'color'
                                        ).value,
                                        borderRadius: 10,
                                        padding: 5,
                                        color: '#FFFFFF',
                                    }}>
                                    {
                                        ticket.metaData.find(
                                            (o) => o.name === 'status'
                                        ).value
                                    }
                                </span>
                                <span style={{float: 'right'}}>
                                    <Dropdown
                                        size="xs"
                                        isOpen={this.state.dropdowns[ticketId]}
                                        toggle={() => {
                                            this.toggleDropdown(ticketId)
                                        }}>
                                        <DropdownToggle
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                color: 'black',
                                                paddingRight: 0,
                                            }}>
                                            <i className="simple-icon-options-vertical" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                onClick={(code) =>
                                                    this.toggleModal(
                                                        'editModal',
                                                        ticket
                                                    )
                                                }>
                                                Change Status
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={(code) =>
                                                    this.toggleModal(
                                                        'removeModal',
                                                        ticket
                                                    )
                                                }>
                                                Delete
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </span>
                            </div>
                            <div style={{fontSize: '10pt', marginBottom: 10}}>
                                {ticket.value}
                            </div>
                            <div style={{fontSize: '8pt', marginBottom: 10}}>
                                {description}
                            </div>
                            <div>
                                <span
                                    style={{
                                        padding: 5,
                                        fontSize: '8pt',
                                        borderRadius: 5,
                                        backgroundColor: 'rgba(240,240,240,1)',
                                        border: '1px solid rgba(220,220,220,1)',
                                    }}>
                                    {moment(deadline).calendar(null, {
                                        lastDay: '[Yesterday]',
                                        sameDay: '[Today]',
                                        nextDay: '[Tomorrow]',
                                        lastWeek: 'ddd, MMM D',
                                        nextWeek: 'ddd, MMM D',
                                        sameElse: 'ddd, MMM D',
                                    })}
                                </span>
                            </div>
                            <div style={{fontSize: '8pt', marginTop: 10}}>
                                <span>
                                    <strong>Assigned: </strong>
                                    {assignment}
                                </span>
                                <br />
                                <span>
                                    <strong>Asset: </strong>
                                    {deviceName}
                                </span>
                                <br />
                                <span>
                                    <strong>Equipment: </strong>
                                    {equipment ? equipment : 'N/A'}
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                </Fragment>
            ) : (
                <Fragment></Fragment>
            )
        })
    }

    renderChecklists(checklists) {
        return checklists.map((checklist) => {
            const name = checklist.metaData.find((o) => o.name === 'name').value
            const description = checklist.metaData.find(
                (o) => o.name === 'description'
            ).value
            const repeat = checklist.metaData.find((o) => o.name === 'repeat')
                ? checklist.metaData.find((o) => o.name === 'repeat').value
                : ''
            const checklistId = checklist._id
            const deviceName = checklist.metaData.find(
                (o) => o.name === 'asset'
            )
                ? this.getDeviceName(
                      checklist.metaData.find((o) => o.name === 'asset').value
                  )
                : 'N/A'
            const equipment =
                checklist.metaData.find((o) => o.name === 'equipment') &&
                checklist.metaData.find((o) => o.name === 'equipment').value
                    ? checklist.metaData.find((o) => o.name === 'equipment')
                          .value.label
                    : 'N/A'
            return (
                <Card
                    key={checklistId}
                    style={{
                        margin: 15,
                        marginRight: 35,
                        borderRadius: 5,
                        boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.23)',
                    }}>
                    <CardBody>
                        <div style={{marginBottom: 10}}>
                            <span style={{float: 'right'}}>
                                <Dropdown
                                    size="xs"
                                    isOpen={
                                        this.state.checklistDropdowns[
                                            checklistId
                                        ]
                                    }
                                    toggle={() => {
                                        this.toggleChecklistDropdown(
                                            checklistId
                                        )
                                    }}>
                                    <DropdownToggle
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            color: 'black',
                                            paddingRight: 0,
                                        }}>
                                        <i className="simple-icon-options-vertical" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={() =>
                                                this.toggleChecklistScheduleModal(
                                                    checklist
                                                )
                                            }>
                                            View Schedule
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                this.toggleChecklistEditModal(
                                                    checklist
                                                )
                                            }>
                                            Update
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                this.toggleModal(
                                                    'removeModal',
                                                    checklist
                                                )
                                            }>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </span>
                        </div>
                        <div>
                            <span>
                                <strong>{name}</strong>
                            </span>
                        </div>
                        <div style={{fontSize: '8pt', marginBottom: 10}}>
                            {description}
                        </div>
                        <div style={{marginBottom: 10}}>
                            <span>
                                <strong>Asset: </strong>
                                {deviceName}
                            </span>
                            <br />
                            <span>
                                <strong>Equipment: </strong>
                                {equipment}
                            </span>
                        </div>
                        <div>
                            <span style={{float: 'right', paddingLeft: 10}}>
                                <Button
                                    size="sm"
                                    onClick={() =>
                                        this.toggleModal(
                                            'checklistFillModal',
                                            checklist
                                        )
                                    }
                                    style={{
                                        fontSize: '8pt',
                                        borderRadius: 10,
                                        padding: 5,
                                        color: '#FFFFFF',
                                    }}>
                                    File Now
                                </Button>
                            </span>
                            <span style={{float: 'right', paddingLeft: 10}}>
                                <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                        this.toggleModal(
                                            'checklistEditModal',
                                            checklist
                                        )
                                    }
                                    style={{
                                        fontSize: '8pt',
                                        borderRadius: 10,
                                        padding: 5,
                                        color: '#FFFFFF',
                                    }}>
                                    {repeat}
                                </Button>
                            </span>
                            <span style={{float: 'right'}}>
                                <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                        this.toggleChecklistScheduleModal(
                                            checklist
                                        )
                                    }
                                    style={{
                                        fontSize: '8pt',
                                        borderRadius: 10,
                                        padding: 5,
                                        color: '#FFFFFF',
                                    }}>
                                    See History
                                </Button>
                            </span>
                        </div>
                    </CardBody>
                </Card>
            )
        })
    }

    render() {
        const {tickets, checklists, activeTab} = this.state

        return (
            <Fragment>
                {this.props.open ? (
                    <div
                        style={{
                            backgroundColor: '#fff',
                            position: 'fixed',
                            right: 0,
                            top:
                                document.documentElement.offsetHeight -
                                this.props.computeHeight(),
                            width: 350,
                            height: this.props.computeHeight(),
                            zIndex: 100,
                            boxShadow: '-5px 0px 47px -16px rgba(0,0,0,0.75)',
                            padding: 25,
                        }}
                        ref={(node) => (this.node = node)}>
                        <Row
                            style={{
                                textAlign: 'center',
                                marginTop: 15,
                                marginBottom: 15,
                            }}>
                            <Col>
                                {this.state.typeSelected === 'Tickets' ? (
                                    <Button
                                        size="sm"
                                        onClick={() =>
                                            this.dropdownTypeSelect(
                                                'Checklists'
                                            )
                                        }>
                                        Switch to preventative maintenance
                                    </Button>
                                ) : (
                                    <Button
                                        size="sm"
                                        onClick={() =>
                                            this.dropdownTypeSelect('Tickets')
                                        }>
                                        Switch to tickets
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col className="xs-10 mt-2 ml-2">
                                <strong>{this.state.typeSelected}</strong>
                            </Col>
                            <Col className="xs-2 text-right">
                                {this.state.typeSelected === 'Checklists' ? (
                                    <Button
                                        onClick={(code) =>
                                            this.toggleChecklistAddModal()
                                        }>
                                        <i
                                            className="simple-icon-plus"
                                            style={{fontSize: '20px'}}
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={(code) =>
                                            this.toggleModal('addModal', code)
                                        }>
                                        <i
                                            className="simple-icon-plus"
                                            style={{fontSize: '20px'}}
                                        />
                                    </Button>
                                )}
                                <Button
                                    size="sm"
                                    className=""
                                    color="default"
                                    onClick={this.props.close}>
                                    <i
                                        className="iconsmind-Close"
                                        style={{fontSize: '20px'}}
                                    />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-12 text-center">
                                {this.state.typeSelected === 'Tickets' ? (
                                    <Fragment>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 15,
                                            }}>
                                            <span>
                                                <strong>
                                                    Search by department:
                                                </strong>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 15,
                                            }}>
                                            <span>
                                                <Button
                                                    size="sm"
                                                    style={
                                                        this.state
                                                            .departmentSelected ==
                                                        'Maintenance'
                                                            ? {
                                                                  backgroundColor:
                                                                      '#333333',
                                                              }
                                                            : {}
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            departmentSelected:
                                                                'Maintenance',
                                                        })
                                                    }>
                                                    Maintenance
                                                </Button>
                                            </span>{' '}
                                            <span>
                                                <Button
                                                    size="sm"
                                                    style={
                                                        this.state
                                                            .departmentSelected ==
                                                        'Tool Room'
                                                            ? {
                                                                  backgroundColor:
                                                                      '#333333',
                                                              }
                                                            : {}
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            departmentSelected:
                                                                'Tool Room',
                                                        })
                                                    }>
                                                    Tool Room
                                                </Button>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 15,
                                            }}>
                                            <span>
                                                <Button
                                                    size="sm"
                                                    style={
                                                        this.state
                                                            .departmentSelected ==
                                                        'Engineering'
                                                            ? {
                                                                  backgroundColor:
                                                                      '#333333',
                                                              }
                                                            : {}
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            departmentSelected:
                                                                'Engineering',
                                                        })
                                                    }>
                                                    Engineering
                                                </Button>
                                            </span>{' '}
                                            <span>
                                                <Button
                                                    size="sm"
                                                    style={
                                                        this.state
                                                            .departmentSelected ==
                                                        'Facilities'
                                                            ? {
                                                                  backgroundColor:
                                                                      '#333333',
                                                              }
                                                            : {}
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            departmentSelected:
                                                                'Facilities',
                                                        })
                                                    }>
                                                    Facilities
                                                </Button>
                                            </span>
                                        </div>
                                    </Fragment>
                                ) : null}
                            </Col>
                        </Row>

                        <Row>
                            <Col className="xs-10 mt-2 ml-2">
                                <Scrollbars
                                    autoHeightMin={
                                        this.props.computeHeight() -
                                        42 -
                                        23 -
                                        80
                                    }
                                    autoHeightMax={
                                        this.props.computeHeight() -
                                        42 -
                                        23 -
                                        90
                                    }
                                    autoHeight>
                                    {(() => {
                                        switch (this.state.typeSelected) {
                                            case 'Tickets':
                                                return this.renderTickets(
                                                    tickets
                                                )
                                            case 'Checklists':
                                                return this.renderChecklists(
                                                    checklists
                                                )
                                            default:
                                                return <div>N/A</div>
                                        }
                                    })()}
                                </Scrollbars>
                            </Col>
                        </Row>
                    </div>
                ) : null}

                {this.state.addModal ? (
                    <AddModal
                        toggleModal={this.toggleAddModal}
                        modal={this.state.addModal}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        tickets={this.state.tickets}
                        editing={this.state.editing}
                        onSave={this.fetchTickets}
                    />
                ) : null}

                {this.state.statusModal ? (
                    <StatusModal
                        toggleModal={this.toggleStatusModal}
                        modal={this.state.statusModal}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                        onSave={this.fetchWorkflows}
                    />
                ) : null}

                {this.state.editModal ? (
                    <EditModal
                        toggleModal={this.toggleModal}
                        modal={this.state.editModal}
                        tickets={this.state.tickets}
                        editing={this.state.editing}
                        workflows={this.state.workflows}
                    />
                ) : null}

                {this.state.checklistEditModal ? (
                    <ChecklistEditModal
                        toggleModal={this.toggleChecklistEditModal}
                        modal={this.state.checklistEditModal}
                        editing={this.state.editing}
                        workflows={this.state.workflows}
                        onSave={this.fetchChecklists}
                    />
                ) : null}

                {this.state.checklistAddModal ? (
                    <ChecklistAddModal
                        toggleModal={this.toggleChecklistAddModal}
                        modal={this.state.checklistAddModal}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                        onSave={this.fetchChecklists}
                    />
                ) : null}

                {this.state.checklistFillModal ? (
                    <ChecklistFillModal
                        toggleModal={this.toggleChecklistFillModal}
                        modal={this.state.checklistFillModal}
                        editing={this.state.editing}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                        onSave={(checklist) => {
                            this.fetchChecklists()
                        }}
                    />
                ) : null}

                {this.state.checklistScheduleModal ? (
                    <ChecklistScheduleModal
                        toggleModal={this.toggleChecklistScheduleModal}
                        modal={this.state.checklistScheduleModal}
                        editing={this.state.editing}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                        onSave={this.fetchChecklists}
                        checklistFillModal={(checklist) =>
                            this.toggleChecklistFillModal(checklist)
                        }
                    />
                ) : null}
            </Fragment>
        )
    }
}
