import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import * as API from 'SDK/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import COLUMNS from './columns'
import {currentShift} from '../HubbellLenoirCityTable/shift-helpers'
import Subscriber from 'SDK/subscriber'
import {cloneDeep} from 'lodash'

export default class HubbellLenoirCityCOOIS extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'HubbellLenoirCityCOOIS'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            shifts: [],
            data: [],
            columns: [],
            assets: [],
        }
    }

    async fetchLatestCOOISData(partCounts) {
        const lastRow = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.assets[0].deviceId,
                    name: 'COOIS Import',
                    timeStart: {$ne: null},
                },
                options: {
                    sort: {
                        timeStart: -1,
                    },
                    limit: 1,
                },
            },
            2
        )

        if (!lastRow) {
            this.setState({loading: false})
            return alert('No COOIS data available!')
        }

        let data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {
                        $in: this.state.assets.map((a) => a.deviceId),
                    },
                    name: 'COOIS Import',
                    timestamp: lastRow[0].timestamp,
                },
            },
            2
        )

        data = data.map((row) => {
            row.assetName = this.state.assets.find(
                (a) => a.deviceId === row.deviceId
            ).name
            row.GoalPartShift = parseInt(
                10 /
                    (row.value['RemProc (KEINH)'] /
                        row.value['Open Qty (MEINH)'])
            )
            row.TargetHour = parseInt(row.GoalPartShift / 10)
            row.value['Basic finish date'] = moment(
                row.value['Basic finish date']
            ).format('LL')

            for (let shift of this.state.shifts) {
                const count = partCounts[shift.name].data.find(
                    (a) => a.deviceId === row.deviceId
                )
                row[`shift__${shift.name}`] = count ? count.total : 0
            }
            return row
        })

        this.setState({data, loading: false})
    }

    async fetchPartCountData(start, end) {
        let data = []
        for (let asset of this.state.assets) {
            data.push(
                API.post(
                    'historical/raw',
                    {
                        query: {
                            timeStart: {
                                $gte: start.toISOString(),
                                $lt: end.toISOString(),
                            },
                            name: 'Part Count',
                            deviceId: asset.deviceId,
                        },
                    },
                    2
                )
            )
        }

        data = await Promise.all(data)

        let datasets = []

        for (let dataset of data) {
            if (dataset.length) {
                datasets.push({
                    total: dataset.reduce((a, b) => a + b.value, 0),
                    deviceId: dataset[0].deviceId,
                })
            }
        }

        return datasets
    }

    async componentDidMount() {
        const assets = await API.get('devices')
        const shifts = await API.get('shifts', 2)

        this.columns = cloneDeep(COLUMNS)
        for (let i = 0; i < shifts.length; i++) {
            this.columns.splice(i + 2, 0, {
                Header: shifts[i].name,
                accessor: `shift__${shifts[i].name}`,
            })
        }
        this.setState({assets, shifts})

        this.subscriber.add(this.update, 1000 * 60 * 3, 'update()')
    }

    async update() {
        this.setState({loading: true})

        let partCounts = {}
        for (let shift of this.state.shifts) {
            const schedule = currentShift(shift)
            console.log(schedule)
            if (schedule)
                console.log(
                    schedule.range[0].format(),
                    schedule.range[1].format()
                )
            partCounts[shift.name] = {
                name: shift.name,
                data: schedule
                    ? await this.fetchPartCountData(
                          schedule.range[0],
                          schedule.range[1]
                      )
                    : [],
            }
        }

        this.fetchLatestCOOISData(partCounts)
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <ReactTable
                                    defaultPageSize={20}
                                    data={this.state.data}
                                    columns={this.columns || []}
                                    minRows={0}
                                    loading={false}
                                    filterable={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
