import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
} from 'reactstrap'
import autoBind from 'auto-bind'

import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            nameValue: '',
            descriptionValue: '',
            collectionValue: '',
            queryValue: '',
            classicValue: false,
            loading: false,
        }
    }

    validate() {
        if (!this.state.nameValid) return false

        return true
    }

    async save() {
        if (!this.validate()) {
            return alert('Validation failed.')
        }
        this.setState({loading: true})
        await this.props.editDataModelDetails(
            this.state.nameValue,
            this.state.descriptionValue,
            this.state.classicValue,
            this.state.collectionValue,
            this.state.queryValue
        )
        this.setState({loading: false})
        this.props.toggle()
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal isOpen={open} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        Edit data model details
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <TextInput
                                    onValueChange={(nameValue) =>
                                        this.setState({nameValue})
                                    }
                                    onValidChange={(nameValid) =>
                                        this.setState({nameValid})
                                    }
                                    fieldName="Data Model Name"
                                    existing={this.props.dataModels.map(
                                        (d) => d.name
                                    )}
                                    initial={this.props.editingDataModel.name}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    onValueChange={(descriptionValue) =>
                                        this.setState({descriptionValue})
                                    }
                                    onValidChange={() => {}}
                                    fieldName="Data Model Description"
                                    existing={[]}
                                    initial={
                                        this.props.editingDataModel.description
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert color="danger">
                                    <h5>
                                        <strong>WARNING</strong>
                                    </h5>
                                    The settings below have the potential of
                                    corrupting existing data outside of Data
                                    Models.
                                    <br />
                                    <br />
                                    <strong>
                                        If you modify any values below, Accumine
                                        claims no responsibility for data loss.
                                    </strong>
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    onValueChange={(collectionValue) =>
                                        this.setState({collectionValue})
                                    }
                                    onValidChange={() => {}}
                                    fieldName="Data Collection Name"
                                    existing={[]}
                                    initial={
                                        this.props.editingDataModel
                                            .dataCollection || 'datamodeldata'
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SelectInput
                                    onChange={(classicValue) =>
                                        this.setState({classicValue})
                                    }
                                    fieldName="Data Model Classic Mode"
                                    default={
                                        this.props.editingDataModel.classic
                                            ? {
                                                  label: 'Classic - use existing, non Data Model Data (read-only)',
                                                  value: true,
                                              }
                                            : {
                                                  label: 'Regular - new dataset (read/write)',
                                                  value: false,
                                              }
                                    }
                                    initial={
                                        this.props.editingDataModel.classic
                                            ? {
                                                  label: 'Classic - use existing, non Data Model Data',
                                                  value: true,
                                              }
                                            : {
                                                  label: 'Regular - new dataset',
                                                  value: false,
                                              }
                                    }
                                    options={[
                                        {
                                            label: 'Classic - use existing, non Data Model Data',
                                            value: true,
                                        },
                                        {
                                            label: 'Regular - new dataset',
                                            value: false,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        {this.state.classicValue ? (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <Alert color="warning">
                                            {this.props.editingDataModel
                                                .classic ? (
                                                <>
                                                    <p>
                                                        <strong>
                                                            Note: Existing
                                                            fields
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        It is not necessary to
                                                        include all Classic
                                                        fields for this Data
                                                        Model. They will not be
                                                        removed when updating
                                                        existing records.
                                                    </p>
                                                </>
                                            ) : null}
                                            <p>
                                                <strong>
                                                    Note: Query requirements
                                                </strong>
                                            </p>
                                            <p>
                                                Fields included in your query
                                                must also be present in the
                                                field list for this Data Model.
                                                I.e. if you set a query of
                                                "status": "active", a field must
                                                exist for this Data Model where
                                                the value is{' '}
                                                <strong>always</strong>{' '}
                                                "active".
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextInput
                                            onValueChange={(queryValue) =>
                                                this.setState({queryValue})
                                            }
                                            onValidChange={() => {}}
                                            fieldName="Query (copy & paste JSON record)"
                                            existing={[]}
                                            initial={
                                                this.props.editingDataModel
                                                    .query || '{}'
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
