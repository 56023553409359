import React, {Fragment, Component} from 'react'
import {Row, Col, Modal, ModalHeader, ModalBody} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import Sidebar from '../common/Sidebar'
import TopNavigator from './TopNavigator'
import ManageRecordModal from '../common/Form'
import FilterModal from './modals/Filter'
import AnalyzeModal from './modals/Analyze'
import ApiCallModal from './modals/ApiCall'
import EditReportColumnsModal from './modals/EditReportColumns'
import ReportTable from './ReportTable'
import SaveReportModal from './modals/SaveReport'

import * as API from 'SDK/api'
import {cloneDeep} from 'lodash'
import DataViewer from '../SDK/DataViewer'

export default class SchemaViewer extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'SchemaViewer'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            dataModels: [],
            selectedDataModel: null,
            loading: false,
            fieldTypes: [],

            // adding and editing records
            manageRecordModalOpen: false,
            schema: null,
            editingRecord: null,

            // data fetching for table
            fetchData: false,

            sort: {},

            // filtering data
            filterModalOpen: false,
            filters: [],

            // analyzing data
            analyzeModalOpen: false,

            // api call modal
            apiCallModalOpen: false,

            // report data
            reportConfiguration: null,
            reportChartConfiguration: null,
            reportColumnConfiguration: [],
            reportData: null,
            editReportColumnsModalOpen: false,
            reportQuery: null,

            // report saving
            saveReportModalOpen: false,
            savedReportsDataModel: null,
            savedReports: [],
            loadedSavedReport: null,
            sentByReportViewer: false,
            previous: '',
        }
    }

    async syncDataModels() {
        this.setState({loading: true})
        let dataModels = await API.get('data-models', 2)
        this.allFields = dataModels
            .map((a) => a.fields.map((a) => a._id))
            .flat()
        this.setState({dataModels, loading: false})
    }

    async syncSavedReports() {
        let savedReportsDataModel = this.state.dataModels.find(
                (a) => a.name === 'System/Saved Reports'
            ),
            savedReports = []
        if (!savedReportsDataModel) {
            return alert(
                'Import the "System/Saved Reports" Data Model in the Manager module to begin saving reports.'
            )
        } else {
            const {result} = await API.post(
                `data-models/${savedReportsDataModel._id}/paginate`,
                {filter: [], sort: {}, next: null, previous: null, limit: 300},
                2
            )
            savedReports = result.results
        }
        this.setState({savedReportsDataModel, savedReports})
    }

    handleDataModelChange(selectedDataModel) {
        if (selectedDataModel.warnings.length) {
            return alert(
                `${selectedDataModel.name} has errors that need to be addressed before accessing & adding data. Go to the Manager page to see more.`
            )
        }
        this.setState({
            selectedDataModel: null,
            sort: {},
            filters: [],
            saveReportModalOpen: false,
            loadedSavedReport: null,
            reportConfiguration: null,
            reportChartConfiguration: null,
            reportColumnConfiguration: [],
            reportData: null,
            reportQuery: null,
        })

        setTimeout(() => {
            this.setState({
                selectedDataModel,
            })
        }, 500)
    }

    toggleManageRecordModal() {
        this.setState({
            manageRecordModalOpen: !this.state.manageRecordModalOpen,
            fetchData: !this.state.manageRecordModalOpen,
        })
    }

    async handleManageRecord(editingRecord = null) {
        let schema = await API.get(
            'data-models/' + this.state.selectedDataModel._id + '/validator',
            2
        )

        if (editingRecord) {
            for (let fieldName in schema.properties) {
                if (editingRecord[fieldName]) {
                    schema.properties[fieldName].default =
                        editingRecord[fieldName]
                }
            }
        }

        this.setState({schema, editingRecord}, this.toggleManageRecordModal)
    }

    toggleFilterModal() {
        this.setState({filterModalOpen: !this.state.filterModalOpen})
    }

    toggleAnalyzeModal() {
        this.setState({
            analyzeModalOpen: !this.state.analyzeModalOpen,
        })
    }

    toggleApiCallModal() {
        this.setState({
            apiCallModalOpen: !this.state.apiCallModalOpen,
        })
    }

    toggleEditReportColumnsModal() {
        this.setState({
            editReportColumnsModalOpen: !this.state.editReportColumnsModalOpen,
        })
    }

    toggleSaveReportModal() {
        this.setState({
            saveReportModalOpen: !this.state.saveReportModalOpen,
        })
    }

    setReportDataColumns(fields) {
        let reportData = this.state.reportData
        reportData.schema.fields = fields
        this.setState({reportData, reportColumnConfiguration: fields})
    }

    backToMenu() {
        this.setState({
            selectedDataModel: null,
            filters: [],
            sort: {},
        })
    }

    makeSubQuery(q) {
        let nestedJoins = []
        let joins = cloneDeep(q.groups)
            .concat(cloneDeep(q.filter))
            .concat(cloneDeep(q.aggregate))
            .map((a) => {
                let sourcePrefix = ''
                if (a.parentFieldId) {
                    sourcePrefix =
                        a.parentFieldId.split('.' + a.model.name).length > 1
                            ? a.parentFieldId.split('.' + a.model.name)[0]
                            : ''
                }
                if (a.parentReferences) {
                    for (let p of a.parentReferences) {
                        let sPrefix = ''
                        if (p.parentFieldId) {
                            sPrefix =
                                p.parentFieldId.split('.' + p.model.name)
                                    .length > 1
                                    ? p.parentFieldId.split(
                                          '.' + p.model.name
                                      )[0]
                                    : ''
                        }
                        nestedJoins.push({
                            dataModelId: p.dataModelId,
                            referenceId: p.referenceId,
                            sourcePrefix: sPrefix,
                            targetPrefix: p.parentFieldId
                                ? p.parentFieldId
                                      .split('.')
                                      .filter(
                                          (b) => !this.allFields.includes(b)
                                      )
                                      .join('.')
                                : null,
                            depth: p.referenceDepth,
                        })
                    }
                }

                return {
                    dataModelId: a.referenceDataModelId,
                    referenceId: a.referenceId,
                    sourcePrefix,
                    targetPrefix: a.parentFieldId
                        ? a.parentFieldId
                              .split('.')
                              .filter((b) => !this.allFields.includes(b))
                              .join('.')
                        : null,
                }
            })
        nestedJoins = nestedJoins.sort((a, b) => (a.depth > b.depth ? 1 : -1))

        joins = [
            ...new Set(
                nestedJoins
                    .filter((a) => a.referenceId)
                    .concat(joins)
                    .map(({referenceId}) => referenceId)
            ),
        ].map(
            (e) =>
                joins.find(({referenceId}) => referenceId == e) ||
                nestedJoins.find(({referenceId}) => referenceId == e)
        )

        return {
            join: joins.filter((a) => a.referenceId),
            aggregate: q.aggregate.map((a) => {
                return {dataModelId: a.dataModelId, type: a.type, path: a.path}
            })[0],
        }
    }

    async setReportConfiguration(q) {
        this.setState({loading: true})

        let reportQuery = {
            filter: q.filter.map((a) => {
                return {...a, dataModelId: a.rootDataModelId}
            }),
            groups: q.groups.map((a) => {
                return {
                    dataModelId: a.rootDataModelId,
                    path: a.path,
                    timeUnit: a.timeUnit,
                }
            }),
            timezone: q.timezone,
            timerange: q.timerange,
            aggregate: [],
        }

        reportQuery.aggregate = q.aggregate.map((a) => {
            let copy = cloneDeep(q)
            copy.aggregate = [a]
            return this.makeSubQuery(copy)
        })

        let result = await API.post(
            `data-models/${this.state.selectedDataModel._id}/aggregate`,
            reportQuery,
            2
        )
        if (!result) {
            alert('Could not generate report, please try again.')
            this.setState({loading: false})
            return
        }

        if (this.state.reportColumnConfiguration.length) {
            result.schema.fields = this.state.reportColumnConfiguration
        }

        this.setState({
            loading: false,
            reportQuery,
            reportData: result,
            analyzeModalOpen: false,
            reportConfiguration: q,
        })
    }

    async saveReport(report) {
        this.setState({loading: true})
        report.report_configuration = JSON.stringify(
            this.state.reportConfiguration
        )
        report.body = JSON.stringify(this.state.reportQuery)
        report.chart_config = JSON.stringify(
            this.state.reportChartConfiguration
        )
        report.column_config = JSON.stringify(
            this.state.reportColumnConfiguration
        )
        report.report_data_model_id = this.state.selectedDataModel._id

        if (this.state.loadedSavedReport) {
            report._id = this.state.loadedSavedReport._id
            try {
                await API.patch(
                    `data-models/${this.state.savedReportsDataModel._id}/edit-record`,
                    report,
                    2
                )
            } catch (error) {
                console.log(error)
                alert('Could not save report, try again.')
            }
        } else {
            try {
                report = await API.post(
                    `data-models/${this.state.savedReportsDataModel._id}/add-record`,
                    report,
                    2
                )
            } catch (error) {
                console.log(error)
                alert('Could not save report, try again.')
            }
        }

        await this.syncSavedReports()
        this.loadReport(report._id)
        this.toggleSaveReportModal()
        this.setState({loading: false})
    }

    loadReport(reportId) {
        let report = this.state.savedReports.find((a) => a._id === reportId)
        let loaded = cloneDeep(report)
        loaded.report_configuration = JSON.parse(loaded.report_configuration)
        loaded.body = JSON.parse(loaded.body)
        loaded.chart_config = JSON.parse(loaded.chart_config)
        loaded.column_config = JSON.parse(loaded.column_config)

        this.setState({
            reportConfiguration: loaded.report_configuration,
            reportChartConfiguration: loaded.chart_config,
            reportColumnConfiguration: loaded.column_config,
            loadedSavedReport: report,
            reportQuery: loaded.body,
        })
    }

    async componentDidMount() {
        await this.syncDataModels()
        await this.syncSavedReports()
        this.setState({
            fieldTypes: await API.get('data-models/field-types', 2),
        })

        // check for provided report id
        const query = new URLSearchParams(this.props.history.location.search),
            reportId = query.get('reportId'),
            previous = query.get('previous')

        if (reportId && previous) {
            this.setState({
                selectedDataModel: this.state.dataModels.find(
                    (a) =>
                        a._id ===
                        this.state.savedReports.find((b) => b._id === reportId)
                            .report_data_model_id
                ),
            })
            this.loadReport(reportId)
            let result = await API.post(
                `data-models/${this.state.selectedDataModel._id}/aggregate`,
                this.state.reportQuery,
                2
            )
            if (!result) {
                alert('Could not generate report, please try again.')
                this.setState({loading: false})
                return
            }

            if (this.state.reportColumnConfiguration.length) {
                result.schema.fields = this.state.reportColumnConfiguration
            }

            this.setState({
                loading: false,
                reportData: result,
                analyzeModalOpen: false,
                sentByReportViewer: true,
                previous: `${previous}?reportId=${reportId}`,
            })
        }
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.7
        return (
            <Fragment>
                <TopNavigator
                    dataModel={this.state.selectedDataModel}
                    backToMenu={this.backToMenu}
                    addRecord={() => this.handleManageRecord()}
                    filter={this.toggleFilterModal}
                    analyze={this.toggleAnalyzeModal}
                    download={() => alert('download triggered')}
                    refresh={() => this.setState({fetchData: true})}
                    showApiCall={this.toggleApiCallModal}
                    reportData={this.state.reportData}
                    clearReport={() =>
                        this.setState({reportData: null, reportQuery: null})
                    }
                    editReportColumns={this.toggleEditReportColumnsModal}
                    saveReport={this.toggleSaveReportModal}
                    loadedSavedReport={this.state.loadedSavedReport}
                    history={this.props.history}
                    sentByReportViewer={this.state.sentByReportViewer}
                    previous={this.state.previous}
                />
                <hr />

                <Row>
                    <Col xs="3">
                        <Sidebar
                            height={height}
                            dataModels={this.state.dataModels.filter(
                                (m) => !m.archived && m.parentFieldId === null
                            )}
                            onDataModelChange={this.handleDataModelChange}
                        />
                    </Col>
                    <Col xs="9">
                        {this.state.reportData ? (
                            <ReportTable
                                output={this.state.reportData}
                                reportQuery={this.state.reportQuery}
                                setChartConfiguration={(
                                    reportChartConfiguration
                                ) => this.setState({reportChartConfiguration})}
                                loadedSavedReport={this.state.loadedSavedReport}
                                reportChartConfiguration={
                                    this.state.reportChartConfiguration
                                }
                            />
                        ) : null}

                        {this.state.selectedDataModel &&
                        !this.state.reportData ? (
                            <DataViewer
                                dataModelId={this.state.selectedDataModel._id}
                                dataModels={this.state.dataModels}
                                filter={this.state.filters}
                                sort={this.state.sort}
                                height={height}
                                onFilterChange={(filters) =>
                                    this.setState({filters})
                                }
                                onSortChange={(sort) => this.setState({sort})}
                            />
                        ) : null}
                    </Col>
                </Row>

                {this.state.loading ? <div className="loading" /> : null}

                {this.state.manageRecordModalOpen ? (
                    <Modal isOpen={true} size="lg">
                        <ModalHeader toggle={this.toggleManageRecordModal}>
                            {this.state.selectedDataModel.name}
                        </ModalHeader>
                        <ModalBody>
                            <ManageRecordModal
                                open={true}
                                toggle={this.toggleManageRecordModal}
                                dataModel={this.state.selectedDataModel}
                                dataModels={this.state.dataModels}
                                editingRecord={this.state.editingRecord}
                                schema={this.state.schema}
                                refresh={() => this.setState({fetchData: true})}
                            />
                        </ModalBody>
                    </Modal>
                ) : null}

                {this.state.filterModalOpen ? (
                    <FilterModal
                        open={true}
                        toggle={this.toggleFilterModal}
                        dataModel={this.state.selectedDataModel}
                        dataModels={this.state.dataModels}
                        updateFilters={(filters) => this.setState({filters})}
                        filters={this.state.filters}
                        refresh={() => this.setState({fetchData: true})}
                        fieldTypes={this.state.fieldTypes}
                    />
                ) : null}

                <AnalyzeModal
                    open={this.state.analyzeModalOpen}
                    toggle={this.toggleAnalyzeModal}
                    dataModel={this.state.selectedDataModel}
                    dataModels={this.state.dataModels}
                    fieldTypes={this.state.fieldTypes}
                    filter={this.state.filters}
                    setReportConfiguration={this.setReportConfiguration}
                    reportConfiguration={this.state.reportConfiguration}
                    savedReports={this.state.savedReports}
                    loadReport={this.loadReport}
                    loadedSavedReport={this.state.loadedSavedReport}
                    clearSavedReport={() => {
                        this.setState({
                            loadedSavedReport: null,
                            reportConfiguration: null,
                            reportChartConfiguration: null,
                            reportColumnConfiguration: null,
                            reportQuery: null,
                        })
                    }}
                />

                {this.state.apiCallModalOpen ? (
                    <ApiCallModal
                        open={true}
                        toggle={this.toggleApiCallModal}
                        dataModel={this.state.selectedDataModel}
                        type={this.state.reportData ? 'report' : 'paginate'}
                        paginate={{
                            filters: this.state.filters,
                            sort: this.state.sort,
                            limit: 7,
                            next: null,
                            previous: null,
                            timezone: localStorage['timezone'],
                        }}
                        report={this.state.reportQuery}
                    />
                ) : null}

                {this.state.editReportColumnsModalOpen ? (
                    <EditReportColumnsModal
                        open={true}
                        toggle={this.toggleEditReportColumnsModal}
                        reportData={this.state.reportData}
                        setReportDataColumns={this.setReportDataColumns}
                    />
                ) : null}

                {this.state.saveReportModalOpen ? (
                    <SaveReportModal
                        open={true}
                        toggle={this.toggleSaveReportModal}
                        savedReportsDataModel={this.state.savedReportsDataModel}
                        savedReports={this.state.savedReports}
                        saveReport={this.saveReport}
                        loadedSavedReport={this.state.loadedSavedReport}
                    />
                ) : null}
            </Fragment>
        )
    }
}
