import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'

import PropTypes from 'prop-types'

import HourlyActualChart from './HourlyTargetActualChart'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import * as SDK from './RealTimeWorkOrderStatusTile/sdk'

import Moment from 'moment'
import {extendMoment} from 'moment-range'
const moment = extendMoment(Moment)

const UPDATE_FREQUENCY = 30000

export default class RealTimeHourlyActualTile extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    requiredOutputs = ['Part']
    showBorder = false
    id = 'RealTimeHourlyActualTile'
    static propTypes = {
        deviceId: PropTypes.string,
        size: PropTypes.string,
        range: PropTypes.object, // moment.range
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            actual: 0,
            target: 0,
            partNumber: 'No Scheduled Production',
            deviceName: '',
            left: 0,
            progress: 0,
            hoursRemaining: '',
            activeBreak: false,
            smallCardsJSX: [],
            microCardsJSX: [],
            largeCardsJSX: [],
            prediction: 0,
            shiftPrediction: 0,
            range: moment.range(
                moment().hour(8).startOf('hour'),
                moment().hour(16).startOf('hour')
            ),
            accumulatedTotalParts: 0,
            activeState: {
                color: 'rgb(35, 34, 35)',
                name: 'No data',
                timestamp: null,
                elapsed: '0:00',
            },
            activeShift: {
                name: 'Today',
                timeStart: moment().startOf('day'),
                timeEnd: moment(),
            },
            requiredToUpdate:
                moment(props.range.end).add(5, 'minutes') > moment(), // Allow 5-10 pulls before old times go dormant
        }
    }

    async componentWillMount() {
        const {range} = this.props
        await this.getHourlyTarget(range)
        await this.fetchDeviceName()
        await this.fetchActualThisHour()
        await this.getPartsMadeThisShift()
        this.getPredictionOfActual()
        this.getPredictionForShift()

        if (this.state.requiredToUpdate) {
            this.subscriber.add(
                this.getHourlyTarget,
                UPDATE_FREQUENCY * 2,
                'getHourlyTarget'
            )
            this.subscriber.add(
                this.fetchActualThisHour,
                UPDATE_FREQUENCY,
                'fetchActualThisHour'
            )
            this.subscriber.add(
                this.getPartsMadeThisShift,
                UPDATE_FREQUENCY,
                'getPartsMadeThisShift'
            )
            this.subscriber.add(
                this.getPredictionOfActual,
                UPDATE_FREQUENCY * 2,
                'getPredictionOfActual'
            )
            this.subscriber.add(
                this.getPredictionForShift,
                UPDATE_FREQUENCY * 2,
                'getPredictionForShift'
            )
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    async fetchDeviceName() {
        let assets = await API.get('devices')
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                deviceName: assets.find(
                    (a) => a.deviceId === this.props.deviceId
                ).name,
            })
        }
    }

    async fetchActualThisHour(
        deviceId = this.props.deviceId,
        momentStart = this.props.range.start,
        momentEnd = this.props.range.end
    ) {
        let parts = 0,
            records = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: deviceId,
                        timestamp: {
                            $gt: momentStart,
                            $lt: momentEnd,
                        },
                        name: 'Part Count',
                    },
                },
                2
            )

        if (records && records.length > 0) {
            records = records.map((x) => parseInt(x.value))
            parts = records.reduce((acc, c) => acc + c, 0)
        }

        this.setState({
            actual: parts,
        })
    }

    getHourlyTarget = async (range = this.props.range) => {
        let {data} = await API.post('historical/raw', {
            deviceId: this.props.deviceId,
            name: 'Scheduled Target',
            $or: [
                {
                    timeStart: {
                        $gte: range.start,
                        $lt: range.end,
                    },
                },
                {
                    timeStart: {
                        $lte: range.start,
                    },
                    timeEnd: {
                        $gte: range.end,
                    },
                },
                {
                    timeStart: {
                        $lt: range.end,
                    },
                    timeEnd: null,
                },
            ],
        })

        if (data.length > 0) {
            let r = data.find((d) => d.metaData.find((m) => m.name === 'part'))
            this.setState({
                target: r.value,
                partNumber: r.metaData.find((m) => m.name == 'part').value,
            })
        } else {
            this.setState({target: 0, partNumber: 'No Scheduled Production'})
        }
    }

    async getPartsMadeThisShift(
        deviceId = this.props.deviceId,
        range = this.props.shift
    ) {
        let totalParts = 0,
            records = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: deviceId,
                        timestamp: {
                            $gt: range.start,
                            $lt: range.end,
                        },
                        name: 'Part Count',
                    },
                },
                2
            )

        if (records && records.length > 0) {
            records = records.map((x) => parseInt(x.value))
            totalParts = records.reduce((acc, c) => acc + c, 0)
        }

        this.setState({
            accumulatedTotalParts: totalParts,
        })
    }

    getPredictionOfActual() {
        const timeobject = moment()
        const prediction =
            timeobject < this.props.range.end
                ? Math.floor((moment().format('mm') / 60) * this.state.target)
                : this.state.target
        this.setState({prediction})
    }

    getPredictionForShift() {
        const timeobject = moment()
        const shiftPrediction =
            timeobject < this.props.shift.end
                ? (moment
                      .duration(timeobject.diff(this.props.shift.start))
                      .asMinutes() /
                      60) *
                  this.state.target
                : 0
        this.setState({shiftPrediction: Math.floor(shiftPrediction)})
    }

    render() {
        const {size, range} = this.props
        const cardColor =
            this.state.actual < this.state.prediction
                ? 'rgba(175,0,0,1)'
                : 'rgba(0,175,0,1)'
        const headerColor =
            this.state.actual < this.state.prediction
                ? 'rgba(155,0,0,1)'
                : 'rgba(0,155,0,1)'
        const kpiColor =
            this.state.actual < this.state.prediction
                ? 'rgba(150,0,0,1)'
                : 'rgba(0,150,0,1)'
        const textColor =
            this.state.actual < this.state.prediction
                ? 'rgba(255,50,50,1)'
                : 'rgba(50,255,50,1)'

        if (size === 'large') {
            return (
                <Col xs="12" style={{marginBottom: 25}}>
                    <Card style={{color: '#FFF', backgroundColor: cardColor}}>
                        <CardHeader
                            style={{
                                color: '#FFF',
                                backgroundColor: headerColor,
                            }}>
                            <Row style={{lineHeight: '31px'}}>
                                <Col>
                                    <strong>
                                        <small>
                                            {this.state.deviceName.toUpperCase()}
                                        </small>
                                    </strong>
                                </Col>
                                <Col style={{textAlign: 'right'}}>
                                    {range.start.format('hA')}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{padding: 0}} className="text-center">
                            <Row style={{marginTop: 25}}>
                                <Col xs="4">
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            color: textColor,
                                        }}>
                                        {'PART'}
                                    </h3>
                                    <h1 style={{fontSize: 36}}>
                                        {this.state.partNumber.toUpperCase()}
                                    </h1>
                                </Col>
                                <Col xs="4">
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            color: textColor,
                                        }}>
                                        {'TARGET'}
                                    </h3>
                                    <h1 style={{fontSize: 48}}>
                                        {this.state.target}
                                    </h1>
                                </Col>
                                <Col xs="4">
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            color: textColor,
                                        }}>
                                        {'ACTUAL'}
                                    </h3>
                                    <h1 style={{fontSize: 48}}>
                                        {this.state.actual}
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="text-center">
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            color: textColor,
                                        }}>
                                        RESULT
                                    </h3>
                                    <div
                                        className="text-center"
                                        style={{
                                            borderRadius: 550,
                                            margin: 25,
                                            padding: 30,
                                            color: '#FFF',
                                            fontSize: 48,
                                            fontWeight: 900,
                                            backgroundColor: kpiColor,
                                        }}>
                                        {this.state.actual - this.state.target}
                                    </div>
                                </Col>
                                <Col className="text-center">
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            color: textColor,
                                        }}>
                                        PARTS EXPECTED
                                    </h3>
                                    <div
                                        className="text-center"
                                        style={{
                                            borderRadius: 550,
                                            margin: 25,
                                            padding: 30,
                                            color: '#FFF',
                                            fontSize: 48,
                                            fontWeight: 900,
                                            backgroundColor: kpiColor,
                                        }}>
                                        {this.state.shiftPrediction}
                                    </div>
                                </Col>
                                <Col className="text-center">
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 900,
                                            color: textColor,
                                        }}>
                                        PARTS MADE
                                    </h3>
                                    <div
                                        className="text-center"
                                        style={{
                                            borderRadius: 550,
                                            margin: 25,
                                            padding: 30,
                                            color: '#FFF',
                                            fontSize: 48,
                                            fontWeight: 900,
                                            backgroundColor: kpiColor,
                                        }}>
                                        {this.state.accumulatedTotalParts}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        } else {
            return (
                <Col xs="3" style={{marginBottom: '15px'}}>
                    <Card style={{color: '#333', backgroundColor: cardColor}}>
                        <CardHeader
                            style={{
                                color: '#FFF',
                                backgroundColor: headerColor,
                            }}>
                            <Row>
                                <Col xs="8">
                                    <strong>
                                        <small>
                                            {this.state.partNumber.toUpperCase()}
                                        </small>
                                    </strong>
                                </Col>
                                <Col xs="4" style={{textAlign: 'right'}}>
                                    <small>{range.start.format('hA')}</small>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{padding: 0}}>
                            <Row style={{margin: 0}}>
                                <Col>
                                    <Row className="text-center">
                                        <Col className="text-center">
                                            <div
                                                className="text-center"
                                                style={{
                                                    borderRadius: 550,
                                                    margin: 25,
                                                    padding: 30,
                                                    color: '#FFF',
                                                    fontSize: 30,
                                                    fontWeight: 900,
                                                    backgroundColor: kpiColor,
                                                }}>
                                                {Math.round(
                                                    (this.state.actual -
                                                        this.state.target) *
                                                        100
                                                ) / 100}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )
        }
    }
}
