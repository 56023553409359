import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Input, Button} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import moment from 'moment'

import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import * as API from 'SDK/api'

import Navigation from '../Navigation'
import {splitBatch} from '../Scripts/SplitBlockBatch'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Split Block Batch',
            fn: () => {},
        })

        this.state = {
            loading: true,
            selectedBlockBatch: null,
            blockBatchOptions: [],
            parentBlocksPressed: 0,
            blocksToSplit: 1,
            deviation: '',
            newBatchNumber: '',
        }
    }

    async fetchBlockBatches() {
        let results,
            batches = []

        results = await API.post(
            'data-models/5fc9015bae96bc6be2c1a13e/paginate',
            {
                filter: [
                    {
                        type: 'Timestamp',
                        logic: 'isEmpty',
                        value: [],
                        path: 'Finish Date',
                    },
                ],
                sort: {},
                limit: 100,
                next: null,
                previous: null,
                timezone: localStorage['timezone'],
            },
            2
        )

        batches = results.result.results.map((r) => {
            return {label: r['Batch Number'], value: r}
        })

        results = await API.post(
            'data-models/5fc9015bae96bc6be2c1a13e/paginate',
            {
                filter: [
                    {
                        type: 'Timestamp',
                        logic: 'on',
                        value: moment
                            .utc('1970-01-01T00:00:00.000Z')
                            .toISOString(),
                        path: 'Finish Date',
                    },
                ],
                sort: {},
                limit: 100,
                next: null,
                previous: null,
                timezone: localStorage['timezone'],
            },
            2
        )

        batches = results.result.results.map((r) => {
            return {label: r['Batch Number'], value: r}
        })

        results = await API.post(
            'data-models/5fc9015bae96bc6be2c1a13e/paginate',
            {
                filter: [
                    {
                        type: 'Timestamp',
                        logic: 'previous',
                        value: {
                            unit: 'day',
                            value: 45,
                        },
                        path: 'Finish Date',
                    },
                ],
                sort: {},
                limit: 100,
                next: null,
                previous: null,
                timezone: localStorage['timezone'],
            },
            2
        )

        batches = batches.concat(
            results.result.results.map((r) => {
                return {label: r['Batch Number'], value: r}
            })
        )

        if (this.props.initialBatchNumber) {
            this.setState({
                selectedBlockBatch: batches.find(
                    (r) => r.label === this.props.initialBatchNumber
                ),
            })
        }

        return batches
    }

    async fetchPressRecord(blockBatchNumber) {
        const {result} = await API.post(
            'data-models/5fc91243d02f3471d06e6b4a/paginate',
            {
                filter: [
                    {
                        type: 'Text',
                        logic: 'is',
                        value: [blockBatchNumber],
                        path: 'Block Batch Link.Batch Number',
                    },
                ],
                sort: {},
                limit: 1,
                next: null,
                previous: null,
                timezone: localStorage['timezone'],
            },
            2
        )
        return result.results.length ? result.results[0] : {}
    }

    async handleBlockBatchSelectionChange(selectedBlockBatch) {
        this.setState({loading: true})
        const pressRecord = await this.fetchPressRecord(
            selectedBlockBatch.value['Batch Number']
        )

        let parentBlocksPressed = pressRecord['Parts Pressed']
        if (isNaN(parentBlocksPressed) || parentBlocksPressed < 2) {
            parentBlocksPressed = 0
            alert(
                'There are not enough blocks pressed for this batch to split.'
            )
            return this.props.reset()
        }
        this.setState({selectedBlockBatch, parentBlocksPressed, loading: false})
    }

    onBlocksToSplitChange(e) {
        const v = e.target.value
        if (v >= this.state.parentBlocksPressed) {
            return alert('Amount must be less than total parent blocks.')
        }
        this.setState({blocksToSplit: v})
    }

    onDeviationChange(e) {
        const v = e.target.value
        this.setState({deviation: v})
    }

    onNewBatchNumberChange(e) {
        const v = e.target.value
        this.setState({newBatchNumber: v})
    }

    async submit() {
        this.setState({loading: true})
        await splitBatch({
            parentBatchRecord: this.state.selectedBlockBatch.value,
            childBatchNumber: this.state.newBatchNumber,
            childBatchDeviation: this.state.deviation,
            splitQuantity: parseInt(this.state.blocksToSplit),
        })
        this.setState({loading: false})
        this.props.reset()
    }

    async componentDidMount() {
        this.setState({
            blockBatchOptions: await this.fetchBlockBatches(),
            loading: false,
        })
    }

    render() {
        const valid =
            this.state.selectedBlockBatch !== null &&
            this.state.blocksToSplit > 0 &&
            this.state.deviation !== '' &&
            this.state.newBatchNumber !== ''
        return (
            <Fragment>
                {!this.state.loading ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <Navigation path={this.path} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <h1>Split Block Batch</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="block-batch"
                                            value={
                                                this.state.selectedBlockBatch
                                            }
                                            onChange={
                                                this
                                                    .handleBlockBatchSelectionChange
                                            }
                                            options={this.state.blockBatchOptions.sort(
                                                (a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                            )}
                                        />
                                        <IntlMessages id="Batches" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        {this.state.selectedBlockBatch ? (
                            <Fragment>
                                <Row>
                                    <Col xs={{size: 4, offset: 4}}>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Input
                                                    type="number"
                                                    name="blocks-to-split"
                                                    value={
                                                        this.state.blocksToSplit
                                                    }
                                                    onChange={
                                                        this
                                                            .onBlocksToSplitChange
                                                    }
                                                />
                                                <IntlMessages
                                                    id={
                                                        'Blocks To Split (' +
                                                        this.state
                                                            .parentBlocksPressed +
                                                        ' total)'
                                                    }
                                                />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{size: 4, offset: 4}}>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Input
                                                    type="text"
                                                    name="deviation"
                                                    value={this.state.deviation}
                                                    onChange={
                                                        this.onDeviationChange
                                                    }
                                                />
                                                <IntlMessages
                                                    id={'Deviation Number'}
                                                />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{size: 4, offset: 4}}>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Input
                                                    type="text"
                                                    name="batch-number"
                                                    value={
                                                        this.state
                                                            .newBatchNumber
                                                    }
                                                    onChange={
                                                        this
                                                            .onNewBatchNumberChange
                                                    }
                                                />
                                                <IntlMessages
                                                    id={'New Batch Number'}
                                                />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : null}

                        {valid ? (
                            <Row>
                                <Col xs={{size: 4, offset: 4}}>
                                    <Button
                                        size="xl"
                                        block
                                        onClick={this.submit}>
                                        SPLIT BATCH
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                    </Fragment>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
