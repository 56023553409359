import React, {Fragment, Component} from 'react'
import {Row, Col, Input, Button} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import FormulaInput from '../../common/FormulaInput/index'

const OPTIONS = [
    {
        label: 'Is true',
        value: 'isTrue',
        showInput: false,
    },
    {
        label: 'Is false',
        value: 'isFalse',
        showInput: false,
    },
    {
        label: 'Is empty',
        value: 'isEmpty',
        showInput: false,
    },
    {
        label: 'Is not empty',
        value: 'isNotEmpty',
        showInput: false,
    },
]

export default class BooleanFilter extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = []
    showBorder = false
    id = 'QueryBuilder.BooleanFilter'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        let initial = {
            selected: OPTIONS[0],
        }

        if (props) {
            initial = {
                selected: OPTIONS.find((o) => o.value === props.logic)
                    ? OPTIONS.find((o) => o.value === props.logic)
                    : OPTIONS[0],
            }
        }

        this.state = {
            selected: initial.selected,
        }
    }

    handleSelectChange(selected) {
        this.setState({
            selected,
        })
    }

    handleInputChange(event) {
        this.setState({input: event.target.value})
    }

    handleInputFormulaChange(input) {
        input.type = 'formula'
        this.setState({input})
    }

    disableSubmit() {
        return this.state.selected.showInput && this.state.input === ''
    }

    makePrettyString() {
        return (
            this.props.fieldName + ' ' + this.state.selected.label.toLowerCase()
        )
    }

    submit() {
        if (this.props.onSubmit) {
            this.props.onSubmit({
                logic: this.state.selected.value,
                prettyString: this.makePrettyString(),
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        {this.props.formulas && this.props.variables ? (
                            <FormulaInput
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                                onChange={this.handleInputFormulaChange}
                                default={this.props.defaultFormulaInput}
                                type="formula"
                            />
                        ) : (
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="number-filter-options"
                                value={this.state.selected}
                                onChange={this.handleSelectChange}
                                options={OPTIONS}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col className="text-right">
                        <Button
                            className="mt-2"
                            color="secondary"
                            size="xs"
                            disabled={this.disableSubmit()}
                            onClick={this.submit}>
                            Update filter
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
