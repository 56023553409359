import React, {Component, Fragment} from 'react'
import {
    Row,
    Alert,
    Col,
    Button,
    Label,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autoBind from 'auto-bind'
import Creatable from 'react-select/creatable'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import {functions} from '@accumine/mathjs-extensions'

import IntlMessages from 'util/IntlMessages'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'
import fieldTypes from '../../../../common/fieldTypes'
import ModelTree from 'SDK/ui/widgets/Schemas/common/ModelTree'
import FormulaInput from 'SDK/ui/widgets/Schemas/common/FormulaInput'
import modelToDot from 'SDK/ui/widgets/Schemas/SDK/helpers/modelToDot'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.fns = Object.keys(functions)

        this.editingModel =
            this.props.editingSubModel || this.props.editingDataModel

        this.state = {
            fieldTypes: [],
            fieldNameValue: '',
            fieldNameValid: false,
            fieldDescriptionValue: 'No description',
            fieldType: 'Text',
            fidDataModelId: undefined,
            unique: false,
            required: false,
            loading: false,
            subModelType: 'Static',
            pickListValues: [],
            protectedField: false,
            kanbanField: false,
            variables: [],
            foreignOptionFilter: null,
        }
    }

    onFieldTypeChange(fieldType) {
        this.setState({
            fieldType,
            fieldTypeObject: this.state.fieldTypes.find(
                (f) => f.type === fieldType
            ),
        })
    }

    validate() {
        if (!this.state.fieldNameValid) return false

        return true
    }

    async save() {
        if (!this.validate()) {
            return alert('Validation failed.')
        }
        this.setState({loading: true})

        const {_id, fields} = await this.props.handleAddColumn({
            editingDataModelId: this.editingModel._id,
            name: this.state.fieldNameValue,
            description: this.state.fieldDescriptionValue,
            type: this.state.fieldType,
            fidDataModelId: this.state.fidDataModelId,
            fidAliasPath: this.state.fidAliasPath,
            foreignOptionFilter:
                this.state.foreignOptionFilter &&
                this.state.foreignOptionFilter.plainText !== ''
                    ? this.state.foreignOptionFilter
                    : null,
            pickListValues: this.state.pickListValues.map((v) => v.label),
            unique: this.state.unique,
            required: this.state.required,
            protectedField: this.state.protectedField,
            kanbanField: this.state.kanbanField,
        })

        const field = fields.find(
            (f) => !f.archived && f.name === this.state.fieldNameValue
        )

        if (
            this.state.fieldType === 'SingleSubModel' ||
            this.state.fieldType === 'MultiSubModel'
        ) {
            await API.post(
                'data-models',
                {
                    name: this.state.fieldNameValue,
                    description: this.state.fieldDescriptionValue,
                    modelType: this.state.subModelType,
                    parentDataModelId: _id,
                    parentFieldId: field._id,
                },
                2
            )
            await this.props.syncDataModels()
        }

        this.setState({loading: false})
        this.props.toggle()
    }

    async componentDidMount() {
        let fieldTypesList = await fieldTypes(
            this.editingModel,
            this.props.dataModels
        )
        fieldTypesList = fieldTypesList.filter((field) => {
            if (
                this.editingModel.modelType === 'Static' &&
                (field.value === 'DesignatedTimestamp' ||
                    field.value === 'DesignatedTimeEnd' ||
                    field.value === 'DesignatedTimeStart')
            ) {
                return false
            } else if (
                this.editingModel.modelType === 'Event' &&
                (field.value === 'DesignatedTimeEnd' ||
                    field.value === 'DesignatedTimeStart')
            ) {
                return false
            } else if (
                this.editingModel.modelType === 'State' &&
                field.value === 'DesignatedTimestamp'
            ) {
                return false
            } else {
                return true
            }
        })
        this.setState({
            fieldTypes: fieldTypesList,
        })

        this.showMap = (item) =>
            ['SingleSubModel', 'ForeignID']
                .concat(
                    this.state.fieldTypes
                        .filter((t) => t.filterable)
                        .map((t) => t.type)
                )
                .includes(item.type)
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={open}
                    toggle={() => {
                        toggle()
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggle()
                        }}>
                        Add new data model field
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <SelectInput
                                    onChange={this.onFieldTypeChange}
                                    fieldName="Field Type"
                                    default={{label: 'Text', value: 'Text'}}
                                    options={this.state.fieldTypes}
                                />
                            </Col>
                        </Row>
                        {this.state.fieldType === 'ForeignID' ? (
                            <>
                                <Row>
                                    <Col>
                                        <SelectInput
                                            onChange={(fidDataModelId) => {
                                                this.setState({
                                                    fidDataModelId: null,
                                                    variables: [],
                                                })
                                                setTimeout(() => {
                                                    const foreignModel =
                                                        this.props.dataModels.find(
                                                            (a) =>
                                                                a._id ===
                                                                fidDataModelId
                                                        )
                                                    let variables = [
                                                        {
                                                            id: this
                                                                .editingModel
                                                                ._id,
                                                            name: this
                                                                .editingModel
                                                                .name,
                                                            fields: modelToDot(
                                                                this
                                                                    .editingModel
                                                                    ._id,
                                                                this.props
                                                                    .dataModels,
                                                                false
                                                            ),
                                                            type: 'Record',
                                                        },
                                                        {
                                                            id: foreignModel._id,
                                                            name: foreignModel.name,
                                                            fields: modelToDot(
                                                                fidDataModelId,
                                                                this.props
                                                                    .dataModels,
                                                                false
                                                            ),
                                                            type: 'Record',
                                                        },
                                                    ]
                                                    this.setState({
                                                        fidDataModelId,
                                                        variables,
                                                    })
                                                }, 250)
                                            }}
                                            fieldName="Linked Data Model"
                                            default={() => {
                                                const model =
                                                    this.props.dataModels.find(
                                                        (d) =>
                                                            d._id !==
                                                                this
                                                                    .editingModel
                                                                    ._id &&
                                                            d.fields.length >
                                                                0 &&
                                                            d.parentDataModelId ===
                                                                null &&
                                                            d.parentFieldId ===
                                                                null
                                                    )
                                                return {
                                                    label: model.name,
                                                    value: model._id,
                                                }
                                            }}
                                            options={this.props.dataModels
                                                .filter(
                                                    (d) =>
                                                        d._id !==
                                                            this.editingModel
                                                                ._id &&
                                                        d.fields.length > 0 &&
                                                        d.parentDataModelId ===
                                                            null &&
                                                        d.parentFieldId === null
                                                )
                                                .map((d) => {
                                                    return {
                                                        label: d.name,
                                                        value: d._id,
                                                    }
                                                })}
                                        />
                                    </Col>
                                </Row>
                                {this.state.fidDataModelId ? (
                                    <>
                                        <Row>
                                            <Col xs="12">
                                                <p>
                                                    (Optional) Choose which
                                                    field to use when managing
                                                    this link.
                                                </p>
                                            </Col>
                                            <Col xs="12">
                                                <ModelTree
                                                    showMap={this.showMap}
                                                    onSelect={(
                                                        selectedKeys,
                                                        {selectedNodes}
                                                    ) => {
                                                        this.setState({
                                                            fidAliasPath:
                                                                selectedNodes[0]
                                                                    .path,
                                                        })
                                                    }}
                                                    dataModel={this.props.dataModels.find(
                                                        (m) =>
                                                            m._id ===
                                                            this.state
                                                                .fidDataModelId
                                                    )}
                                                    dataModels={
                                                        this.props.dataModels
                                                    }
                                                    includeJoins={false}
                                                    excludeJoinPrefixes={true}
                                                />
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <p>
                                                    (Optional) Filter available
                                                    options to user
                                                </p>
                                                <FormulaInput
                                                    variables={
                                                        this.state.variables
                                                    }
                                                    formulas={this.fns}
                                                    onChange={(
                                                        foreignOptionFilter
                                                    ) =>
                                                        this.setState({
                                                            foreignOptionFilter,
                                                        })
                                                    }
                                                    type="formula"
                                                />
                                                <hr />
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                            </>
                        ) : null}

                        {this.state.fieldType === 'SingleSubModel' ||
                        this.state.fieldType === 'MultiSubModel' ? (
                            <>
                                <Row>
                                    <Col>
                                        <SelectInput
                                            onChange={(subModelType) =>
                                                this.setState({subModelType})
                                            }
                                            fieldName="Data Model Type"
                                            default={{
                                                label: 'Static (i.e. users, assets, departments)',
                                                value: 'Static',
                                            }}
                                            options={[
                                                {
                                                    label: 'Static (i.e. users, assets, departments)',
                                                    value: 'Static',
                                                },
                                                {
                                                    label: 'Event (i.e. form data, sensor reading)',
                                                    value: 'Event',
                                                },
                                                {
                                                    label: 'State (i.e. any data that has a duration)',
                                                    value: 'State',
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : null}

                        <Row>
                            <Col>
                                <TextInput
                                    validator={(v) => {
                                        return !(
                                            /\s/.test(v) ||
                                            /[ `!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/.test(
                                                v
                                            ) ||
                                            v === '' ||
                                            v === 'deleted' ||
                                            v === '_id' ||
                                            v === 'dataModelId'
                                        )
                                    }}
                                    onValueChange={(fieldNameValue) =>
                                        this.setState({fieldNameValue})
                                    }
                                    onValidChange={(fieldNameValid) =>
                                        this.setState({fieldNameValid})
                                    }
                                    fieldName="Field Name"
                                    existing={this.editingModel.fields.map(
                                        (f) => f.name
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    onValueChange={(fieldDescriptionValue) =>
                                        this.setState({fieldDescriptionValue})
                                    }
                                    onValidChange={() => {}}
                                    fieldName="Field Description"
                                    existing={[]}
                                    initial={
                                        this.state.fieldType === 'ForeignID' &&
                                        this.state.fidDataModelId
                                            ? 'Linked to ' +
                                              this.props.dataModels.find(
                                                  (d) =>
                                                      d._id ===
                                                      this.state.fidDataModelId
                                              ).name
                                            : false
                                    }
                                    disabled={false}
                                />
                            </Col>
                        </Row>

                        {this.state.fieldType !== 'SingleSubModel' &&
                        this.state.fieldType !== 'MultiSubModel' ? (
                            <Row>
                                <Col>
                                    <SelectInput
                                        onChange={(required) =>
                                            this.setState({required})
                                        }
                                        fieldName="Required"
                                        default={{
                                            label: 'This is not a required field',
                                            value: false,
                                        }}
                                        options={[
                                            {
                                                label: 'This is a required field',
                                                value: true,
                                            },
                                            {
                                                label: 'This is not a required field',
                                                value: false,
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.fieldType === 'MultiSubModel' ? (
                            <Alert color="danger">
                                <p>Reporting limitations of Lists</p>
                                Lists can only be used for{' '}
                                <strong>filtering</strong> in reports.
                                Fields/values included within them cannot be
                                used for aggregationg & grouping.
                            </Alert>
                        ) : null}

                        {this.state.fieldType === 'SingleSubModel' ||
                        this.state.fieldType === 'MultiSubModel' ? (
                            <Alert color="warning">
                                Note: sub-models cannot be directly required,
                                only non sub-model fields can be toggled as a
                                required field.
                            </Alert>
                        ) : null}

                        {this.state.fieldType === 'DesignatedTimestamp' ||
                        this.state.fieldType === 'DesignatedTimeStart' ||
                        this.state.fieldType === 'DesignatedTimeEnd' ? (
                            <Alert color="warning">
                                Note: it is recommended to set designated time
                                fields as required fields. However, there are
                                cases where not doing so makes sense. For
                                example - a production schedule data model with
                                unknown start and end dates for each job.
                            </Alert>
                        ) : null}

                        {!this.state.fieldTypeObject ||
                        this.state.fieldTypeObject.unique ? (
                            <Row>
                                <Col>
                                    <SelectInput
                                        onChange={(unique) =>
                                            this.setState({unique})
                                        }
                                        fieldName="Uniqueness"
                                        default={{
                                            label: 'This is not a unique field',
                                            value: false,
                                        }}
                                        options={[
                                            {
                                                label: 'This is a unique field',
                                                value: true,
                                            },
                                            {
                                                label: 'This is not a unique field',
                                                value: false,
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        ) : null}

                        {this.state.fieldType === 'PickList' ? (
                            <Row>
                                <Col>
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Creatable
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                isMulti
                                                name="pickListValues"
                                                value={
                                                    this.state.pickListValues
                                                }
                                                onChange={(pickListValues) =>
                                                    this.setState({
                                                        pickListValues,
                                                    })
                                                }
                                                options={[]}
                                                placeholder="Type & press enter to add value..."
                                            />
                                            <IntlMessages id="Pick List Values" />
                                        </Label>
                                    </Form>
                                </Col>
                            </Row>
                        ) : null}

                        <Row>
                            <Col>
                                <SelectInput
                                    onChange={(protectedField) =>
                                        this.setState({protectedField})
                                    }
                                    fieldName="Protected Field"
                                    default={{
                                        label: 'Unprotected - can modify properties once created',
                                        value: false,
                                    }}
                                    options={[
                                        {
                                            label: 'Protected - cannot modify properties once created',
                                            value: true,
                                        },
                                        {
                                            label: 'Unprotected - can modify properties once created',
                                            value: false,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>

                        {!this.state.kanbanStatusExists &&
                        this.state.fieldType === 'PickList' &&
                        !this.props.editingSubModel ? (
                            <Row>
                                <Col>
                                    <Label>
                                        <Switch
                                            checked={this.state.kanbanField}
                                            onChange={(kanbanField) =>
                                                this.setState({kanbanField})
                                            }
                                        />{' '}
                                        Kanban Status Field (1 allowed per data
                                        model)
                                    </Label>
                                </Col>
                            </Row>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggle()
                            }}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
