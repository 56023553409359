import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Progress,
} from 'reactstrap'
import {Bar} from 'react-chartjs-2'
import autobind from 'auto-bind'
import moment from 'moment'
import {Textfit} from 'react-textfit'
import PropTypes from 'prop-types'

import PieChart from './donut'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'

export default class SummaryDisplayTile extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    requiredOutputs = [
        'Hourly Target',
        'Part Number',
        'Target Parts',
        'Part Count',
        'In-Cycle',
        'Downtime',
    ]
    showBorder = false
    id = 'SummaryDisplayTile'
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            live: {
                name: '...',
                timestamp: null,
                color: '',
                elapsed: '0:00',
            },
            actual: 0,
            target: 0,
            partNumber: 'No Scheduled Production',
            left: 0,
            progress: 0,
            hoursRemaining: '',
        }
    }

    async fetchDeviceName() {
        let assets = await API.get('devices')
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                name: assets.find((a) => a.deviceId === this.props.deviceId)
                    .name,
            })
        }
    }

    async fetchLiveData() {
        let live = await API.post('live', {
            deviceId: this.props.deviceId,
        })
        if (!live) {
            return alert('There was an error fetching data')
        } else if (live[this.props.deviceId].activeStates.length) {
            live = live[this.props.deviceId].activeStates[0]
            if (live.name === 'Downtime') {
                const rows = await API.get(
                    'states/' + this.props.deviceId + '/Downtime/recent'
                )
                if (
                    rows.length > 0 &&
                    rows[0].metaData.find((x) => x.name === 'reason')
                ) {
                    const reason = rows[0].metaData.find(
                        (x) => x.name === 'reason'
                    )
                    live.name = reason.value
                }
            }
            live.color = tileColor(live.name)
            live.elapsed = secondsToHHMMSS(
                moment().diff(moment(live.timestamp), 'seconds')
            )
            this.setState({live})
        }
    }

    updateElapsed() {
        if (this.state.live.timestamp === null) return
        let live = {...this.state.live}
        live.elapsed = secondsToHHMMSS(
            moment().diff(moment(live.timestamp), 'seconds')
        )
        this.setState({live})
    }

    async fetchActualThisHour() {
        let {data} = await API.post('historical/aggregate2', {
            timeStart: moment().startOf('hour').toISOString(),
            timeEnd: moment().toISOString(),
            state: ['Part Count'],
            deviceId: [this.props.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })

        let parts = data.devices[this.props.deviceId]['Part Count'][0]
        if (parts.constructor === Object) parts = parts.count

        this.setState({
            actual: parts,
        })
    }

    async fetchTargetThisHour() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {$in: ['Hourly Target', 'Scheduled Target']},
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                target: data[0].metaData[0].value,
            })
        }
    }

    async fetchPartNumber() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Part Number',
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                partNumber: data[0].metaData[0].value,
            })
        }
    }

    async fetchProgress() {
        const target = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Target Parts',
                    timeEnd: null,
                },
            },
            2
        )
        if (target && target.length) {
            let {data} = await API.post('historical/aggregate2', {
                timeStart: moment(target[0].timeStart).toISOString(),
                timeEnd: moment().toISOString(),
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'total',
                logic: 'count',
            })

            let actual = data.devices[this.props.deviceId]['Part Count'][0]
            if (actual.constructor === Object) actual = actual.count

            let left = target[0].metaData[0].value - actual,
                progress = parseInt((actual / left) * 100),
                timeElapsedThisHour = moment().diff(moment().startOf('hour')),
                cycleTime = timeElapsedThisHour / this.state.actual,
                hoursRemaining = (left * cycleTime) / (60 * 60 * 1000)

            if (isNaN(hoursRemaining)) {
                hoursRemaining = ''
            } else {
                hoursRemaining =
                    '(~' + Math.round((hoursRemaining / 100) * 100) + ' hours)'
            }

            this.setState({
                left,
                progress,
                hoursRemaining,
            })
        }
    }

    componentWillMount() {
        this.fetchDeviceName()
        this.subscriber.add(this.fetchLiveData, 5000, 'fetchLiveData')
        this.subscriber.add(this.updateElapsed, 750, 'updateElapsed')
        this.subscriber.add(
            this.fetchActualThisHour,
            30 * 1000,
            'fetchActualThisHour'
        )
        this.subscriber.add(
            this.fetchTargetThisHour,
            60 * 1000,
            'fetchTargetThisHour'
        )
        this.subscriber.add(this.fetchPartNumber, 60 * 1000, 'fetchPartNumber')
        this.subscriber.add(this.fetchProgress, 60 * 1000, 'fetchProgress')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const activeState = this.state.live

        return (
            <Card style={{color: '#fff', backgroundColor: activeState.color}}>
                <CardHeader
                    style={{
                        cursor: 'pointer',
                        margin: 0,
                        padding: '5px',
                        backgroundColor: 'rgba(47, 50, 59,0.2)',
                    }}>
                    <Row>
                        <Col>
                            <strong>{this.state.name}</strong>
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                            {this.state.live.elapsed}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{padding: 0}}>
                    <Row style={{margin: 0}}>
                        <Col xs="6">
                            <Row>
                                <Col className="text-center">
                                    <h1>
                                        <Textfit
                                            mode="single"
                                            forceSingleModeWidth={false}>
                                            {this.state.live.name}
                                        </Textfit>
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <p>Hourly Actual</p>
                                    <h1>{this.state.actual}</h1>
                                </Col>
                                <Col className="text-center">
                                    <p>Hourly Target</p>
                                    <h1>{this.state.target}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="text-center">
                                    <Progress
                                        animated
                                        value={this.state.progress}
                                        style={{height: '15px'}}
                                    />
                                    <small>
                                        {this.state.left} parts left{' '}
                                        {this.state.hoursRemaining}
                                    </small>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            xs="6"
                            style={{backgroundColor: 'rgba(68, 70, 79, 0.5)'}}>
                            <PieChart
                                deviceId={this.props.deviceId}
                                utilization={0.7}
                            />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <p className="text-center mb-0">{this.state.partNumber}</p>
                </CardFooter>
            </Card>
        )
    }
}
