import React, {Component, Fragment} from 'react'
import {Button} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import {BrowserView, MobileOnlyView} from 'react-device-detect'
import {logUserEvent} from 'SDK/api/common'

import './index.css'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async onClick() {
        logUserEvent('KNOWLEDGE BASE')
        var win = window.open(
            'https://accumine.freshdesk.com/support/solutions',
            '_blank'
        )
        win.focus()
    }

    render() {
        return (
            <Fragment>
                <BrowserView>
                    <div className="kb-button">
                        <Button
                            color="primary"
                            size="sm"
                            className="btn"
                            onClick={this.onClick}>
                            <i
                                className="iconsmind-Information"
                                style={{fontSize: '15px'}}
                            />{' '}
                            Help
                        </Button>
                    </div>
                </BrowserView>
                <MobileOnlyView>
                    <div className="kb-button-mobile">
                        <Button
                            color="primary"
                            size="sm"
                            className="btn"
                            onClick={this.onClick}>
                            <i
                                className="iconsmind-Information"
                                style={{fontSize: '15px'}}
                            />{' '}
                            Help
                        </Button>
                    </div>
                </MobileOnlyView>
            </Fragment>
        )
    }
}
