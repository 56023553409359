import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            name: '',
            borderColor: 'rgb(215,215,215)',
            valid: false,
            message: true,
        }
    }
    async submit() {
        let environment = Object.assign({}, this.props.environment)

        environment.sections.push({
            id: generateHash(),
            name: this.state.name,
            views: [],
        })

        await API.patch('environments/' + environment._id, environment, 2)
        this.props.toggleModal()
        this.props.handleAddNewSectionSubmit(environment)
    }

    componentWillMount() {}

    componentWillUnmount() {}

    render() {
        const {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Name Your Section
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <Label>Name:</Label>
                                <Input
                                    style={{
                                        borderColor: this.state.borderColor,
                                    }}
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.props.environment.sections.find(
                                                (s) => s.name === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'A section with this name already exists.'
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            borderColor: color,
                                            message: message,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.submit}>
                            Add Section
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
