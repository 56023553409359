import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autoBind from 'auto-bind'

import Summarize from './Summarize'
import Group from './Group'
import Timerange from './Timerange'
import Filter from './Filter'
import SelectInput from '../../../common/select-input'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            aggregate: this.props.reportConfiguration
                ? this.props.reportConfiguration.aggregate
                : [],
            groups: this.props.reportConfiguration
                ? this.props.reportConfiguration.groups
                : [],
            timeUnit: null,
            timerange: this.props.reportConfiguration
                ? this.props.reportConfiguration.timerange
                : {logic: 'previous', value: {unit: 'day', value: 30}},
            filter: this.props.reportConfiguration
                ? this.props.reportConfiguration.filter
                : [],
        }
    }

    async generate() {
        this.props.setReportConfiguration({
            filter: this.state.filter,
            aggregate: this.state.aggregate,
            groups: this.state.groups,
            timezone: localStorage['timezone'],
            timerange: this.state.timerange,
        })
    }

    handleTimeUnitChange(timeUnit) {
        let {groups} = this.state
        for (let i in groups) {
            if (groups[i].timeUnit) {
                groups[i].timeUnit = timeUnit
            }
        }
        this.setState({timeUnit, groups})
    }

    componentDidUpdate(prevProps) {
        const {loadedSavedReport, reportConfiguration} = this.props
        if (
            loadedSavedReport &&
            loadedSavedReport._id !==
                (prevProps.loadedSavedReport
                    ? prevProps.loadedSavedReport._id
                    : null)
        ) {
            this.setState({
                aggregate: reportConfiguration.aggregate,
                groups: reportConfiguration.groups,
                timerange: reportConfiguration.timerange,
                filter: reportConfiguration.filter,
            })
        } else if (
            (prevProps.loadedSavedReport && loadedSavedReport === null) ||
            (this.props.dataModel && !prevProps.dataModel)
        ) {
            this.setState({
                aggregate: [],
                groups: [],
                timerange: {logic: 'previous', value: {unit: 'day', value: 30}},
                filter: [],
            })
        }
        return true
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal
                    unmountOnClose={false}
                    isOpen={open}
                    toggle={toggle}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggle}>
                        <strong>Reporting Engine</strong>
                    </ModalHeader>
                    <ModalBody>
                        {this.props.savedReports.length ? (
                            <>
                                <SelectInput
                                    onChange={this.props.loadReport}
                                    fieldName="Load Saved Report"
                                    default={
                                        this.props.loadedSavedReport
                                            ? {
                                                  label: this.props
                                                      .loadedSavedReport
                                                      .report_name,
                                                  value: this.props
                                                      .loadedSavedReport._id,
                                              }
                                            : null
                                    }
                                    options={this.props.savedReports.map(
                                        (a) => {
                                            return {
                                                label: a.report_name,
                                                value: a._id,
                                            }
                                        }
                                    )}
                                />
                                <Row>
                                    <Col className="text-center">
                                        <Button
                                            size="xs"
                                            color="default"
                                            onClick={
                                                this.props.clearSavedReport
                                            }>
                                            Clear Saved Report
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                        <Timerange
                            timerange={this.state.timerange}
                            setTimerange={(timerange) =>
                                this.setState({timerange})
                            }
                            {...this.props}
                        />
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Filter
                            {...this.props}
                            filter={this.state.filter}
                            setFilter={(filter) => this.setState({filter})}
                        />
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Group
                            groups={this.state.groups}
                            setGroup={(groups) => this.setState({groups})}
                            setTimeUnit={this.handleTimeUnitChange}
                            {...this.props}
                        />
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Summarize
                            aggregate={this.state.aggregate}
                            setSummary={(aggregate) =>
                                this.setState({aggregate})
                            }
                            {...this.props}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.generate}
                            //disabled={this.state.groups.length === 0}
                        >
                            {this.props.variables && this.props.formulas ? (
                                <>SUBMIT</>
                            ) : (
                                <>RUN REPORT</>
                            )}
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
