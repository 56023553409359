import React, {Fragment, Component} from 'react'
import {Row, Col, Input} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import FormulaInput from '../../../common/FormulaInput/index'

const TIME_UNITS = [
    {
        label: 'Minutes',
        value: 'minute',
    },
    {
        label: 'Hours',
        value: 'hour',
    },
    {
        label: 'Days',
        value: 'day',
    },
    {
        label: 'Weeks',
        value: 'week',
    },
    {
        label: 'Months',
        value: 'month',
    },
    {
        label: 'Years',
        value: 'year',
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let initial = {
            input: {unit: 'day', value: 30},
        }

        if (props) {
            initial = {
                input: props.value || {unit: 'day', value: 30},
            }
        }

        this.state = {
            input: initial.input,
        }
    }

    handleInputFormulaChange(value) {
        value.type = 'formula'
        let input = this.state.input

        this.setState(
            {
                input: {unit: input.unit, value},
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    handleInputChange(event) {
        if (event.target.value < 1) {
            return alert('Value must be more than 0...')
        }

        let input = this.state.input

        this.setState(
            {
                input: {unit: input.unit, value: event.target.value},
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    handleTimeUnitChange(timeUnit) {
        let input = this.state.input

        this.setState(
            {
                input: {unit: timeUnit.value, value: input.value},
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        {this.props.formulas && this.props.variables ? (
                            <FormulaInput
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                                type="formula"
                                onChange={this.handleInputFormulaChange}
                                default={this.props.defaultFormulaInput}
                            />
                        ) : (
                            <Input
                                type="number"
                                name="timestamp-filter-range-number"
                                value={this.state.input.value}
                                onChange={this.handleInputChange}
                            />
                        )}
                    </Col>
                    <Col xs="12">
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="timestamp-filter-range-timeunit"
                            value={TIME_UNITS.find(
                                (t) => t.value === this.state.input.unit
                            )}
                            onChange={this.handleTimeUnitChange}
                            options={TIME_UNITS}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
