import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    ListGroup,
    ListGroupItem,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Creatable from 'react-select/creatable'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import * as sdk from './sdk'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            operators: [],
            loaded: false,
        }
    }

    async fetchOperators() {
        const operators = await sdk.fetchSignedInOperators(this.props.deviceId)

        this.setState({
            operators,
            loaded: true,
        })
    }

    componentDidMount() {
        this.fetchOperators()
    }

    async submit(operatorRecord) {
        let updated = {...operatorRecord}

        updated.timeEnd = moment().toISOString()

        await API.patch('states/' + operatorRecord._id, updated, 2)

        this.props.toggleModal()
    }

    render() {
        return (
            <Fragment>
                {this.state.loaded ? (
                    <Modal isOpen={true} toggle={this.props.toggleModal}>
                        <ModalHeader toggle={this.props.toggleModal}>
                            Sign Out
                        </ModalHeader>
                        <ModalBody>
                            <p>Click on name to sign out:</p>
                            <ListGroup>
                                {this.state.operators.map((o, idx) => {
                                    const ts = moment(o.timestamp).format('lll')
                                    return (
                                        <ListGroupItem
                                            key={idx}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => this.submit(o)}>
                                            {o.value} (signed in since: {ts})
                                        </ListGroupItem>
                                    )
                                })}
                            </ListGroup>
                        </ModalBody>
                    </Modal>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
