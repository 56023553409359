import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        {this.props.editingEnvironment ? (
                            <>
                                <h2 style={{textAlign: 'center'}}>
                                    No view selected
                                </h2>
                                <h5 style={{textAlign: 'center'}}>
                                    Select an existing view or create a new one
                                    to continue editing
                                </h5>
                            </>
                        ) : (
                            <>
                                <h2 style={{textAlign: 'center'}}>
                                    No view selected
                                </h2>
                                <h5 style={{textAlign: 'center'}}>
                                    Select a view from the left sidebar
                                </h5>
                            </>
                        )}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
