import React, {Component, Fragment} from 'react'
import {InputGroup, InputGroupAddon, Button, Input} from 'reactstrap'
import autoBind from 'auto-bind'

Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0
    return this.toString().split('.')[1].length || 0
}

export default class NumberWithPlusMinus extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            loading: true,
            ...props.formData,
            value: props.formData || 0,
        }

        this.field = props.uiSchema
        this.plusInterval = null
        this.plusTimeout = null
        this.minusInterval = null
        this.minusTimeout = null
        this.multipleOf = this.props.schema.multipleOf || 1
        this.precision = Math.pow(10, this.multipleOf.countDecimals()) || 1
        this.minimum = this.props.schema.minimum || undefined
        this.maximum = this.props.schema.maximum || undefined
    }

    onMinus(e) {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        this.onSingleMinus()

        this.minusTimeout = setTimeout(() => {
            this.minusInterval = setInterval(() => {
                this.onSingleMinus()
            }, 400)
        }, 750)
    }

    onPlus(e) {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        this.onSinglePlus()

        this.plusTimeout = setTimeout(() => {
            this.plusInterval = setInterval(() => {
                this.onSinglePlus()
            }, 400)
        }, 750)
    }

    onSinglePlus() {
        let {value} = this.state
        value =
            Math.round(((value || 0) + this.multipleOf) * this.precision) /
            this.precision
        if (
            this.maximum === undefined ||
            (this.maximum !== undefined && value <= this.maximum)
        ) {
            this.setState({value})
            this.props.onChange(value || 0)
        }
    }

    onSingleMinus() {
        let {value} = this.state
        value =
            Math.round(((value || 0) - this.multipleOf) * this.precision) /
            this.precision
        if (
            this.minimum === undefined ||
            (this.minimum !== undefined && value >= this.minimum)
        ) {
            this.setState({value})
            this.props.onChange(value || 0)
        }
    }

    onChange(e) {
        const value = parseFloat(e.target.value)
        this.setState({value})
        this.props.onChange(value || 0)
    }

    onMouseUp(e, type) {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        clearInterval(type === 'plus' ? this.plusInterval : this.minusInterval)
        clearTimeout(type === 'plus' ? this.plusTimeout : this.minusTimeout)
    }

    render() {
        return (
            <Fragment>
                <label className="text-center">{this.field.name}</label>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button
                            type="button"
                            onTouchStart={this.onMinus}
                            onTouchEnd={(e) => {
                                this.onMouseUp(e, 'minus')
                            }}
                            onTouchCancel={(e) => {
                                this.onMouseUp(e, 'minus')
                            }}
                            onMouseDown={this.onMinus}
                            onMouseUp={(e) => {
                                this.onMouseUp(e, 'minus')
                            }}
                            onMouseOut={(e) => {
                                this.onMouseUp(e, 'minus')
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault()
                                //e.stopPropagation();
                                //return false;
                            }}>
                            -
                        </Button>
                    </InputGroupAddon>
                    <Input
                        type="number"
                        value={this.state.value}
                        onChange={this.onChange}
                    />
                    <InputGroupAddon addonType="append">
                        <Button
                            type="button"
                            onTouchStart={this.onPlus}
                            onTouchEnd={(e) => {
                                this.onMouseUp(e, 'plus')
                            }}
                            onTouchCancel={(e) => {
                                this.onMouseUp(e, 'plus')
                            }}
                            onMouseDown={this.onPlus}
                            onMouseUp={(e) => {
                                this.onMouseUp(e, 'plus')
                            }}
                            onMouseOut={(e) => {
                                this.onMouseUp(e, 'plus')
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault()
                                //e.preventDefault();
                                //e.stopPropagation();
                                //return false;
                            }}>
                            +
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Fragment>
        )
    }
}
