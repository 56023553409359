import React, {Component, Fragment} from 'react'
import {Row} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import StaticHourlyActualTile from './StaticHourlyActualTile'

export default class StaticHourlyActualDisplay extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'realtimeactiveshift',
            component: 'RealTimeActiveShift',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'StaticHourlyActualDisplay'
    requiredOutputs = [
        'Part Count',
        'In-Cycle',
        'Downtime',
        'Static Hourly Target',
    ]
    static propTypes = {
        name: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
        }
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        if (!this.props.deviceIds) {
            return alert('Please select asset(s) to display')
        }
        if (
            this.props.deviceIds &&
            this.props.deviceIds.constructor === Array
        ) {
            if (this.props.deviceIds.length > 2) {
                return alert('Cannot display more than 2 assets')
            }
            this.setState({
                devices: this.props.deviceIds.map((a) =>
                    devices.find((b) => b.deviceId === a)
                ),
            })
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row style={{
                    marginTop:'-40px',
                    marginBottom: '80px',
                    }}>
                    {this.state.devices.length
                        ? this.state.devices.map((device, i) => {
                              return (
                                  <StaticHourlyActualTile
                                      key={i}
                                      deviceId={device.deviceId}
                                      name={device.name}
                                      realtimeactiveshift={
                                          this.props.realtimeactiveshift
                                      }
                                      multi={this.state.devices.length > 1}
                                  />
                              )
                          })
                        : null}
                </Row>
            </Fragment>
        )
    }
}
