import React, {Fragment, Component} from 'react'
import {Row, Col, Input} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

const TIME_UNITS = [
    {
        label: 'Minute',
        value: 'minute',
    },
    {
        label: 'Hour',
        value: 'hour',
    },
    {
        label: 'Day',
        value: 'day',
    },
    {
        label: 'Week',
        value: 'week',
    },
    {
        label: 'Month',
        value: 'month',
    },
    {
        label: 'Year',
        value: 'year',
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let initial = {
            input: 'day',
        }

        if (props) {
            initial = {
                input: props.value || 'day',
            }
        }

        this.state = {
            input: initial.input,
        }
    }

    handleTimeUnitChange(timeUnit) {
        this.setState(
            {
                input: timeUnit.value,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            value={TIME_UNITS.find(
                                (t) => t.value === this.state.input
                            )}
                            onChange={this.handleTimeUnitChange}
                            options={TIME_UNITS}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
