import React, {Component, Fragment} from 'react'
import {Row, Col, Button, Form, Label, Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Select from 'react-select'
import flatten from 'flat'
import {CSVLink, CSVDownload} from 'react-csv'

import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'

import Navigation from '../../Navigation'
import {paginate} from '../../Paginate'

import TimestampFilter from '../../../Schemas/QueryBuilder/Filter/Timestamp'
import * as API from 'SDK/api'

const BATCH_TYPES = {
    Additive: {
        dataModelId: '5fc689840283dc2398844995',
        designatedTimestampFieldPath: 'Date',
    },
    'Calcined Additive': {
        dataModelId: '5fc7d67607ceae4f317ffcdf',
        designatedTimestampFieldPath: 'Sweco Date',
    },
    PVA: {
        dataModelId: '5fc7df2a07ceae4f317ffcf6',
        designatedTimestampFieldPath: 'Date',
    },
    Dispersion: {
        dataModelId: '5fc7e1e907ceae4f317ffd00',
        designatedTimestampFieldPath: 'Date',
    },
    Powder: {
        dataModelId: '5fc7e66407ceae4f317ffd22',
        designatedTimestampFieldPath: 'Date',
    },
    Collar: {
        dataModelId: '5fc7ef7c07ceae4f317ffd37',
        designatedTimestampFieldPath: 'Date',
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Batches By Time Report',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            ready: false,
            loading: true,
            selectedBatchType: {label: 'Additive', value: 'Additive'},
            records: [],
            headers: [],
        }
    }

    async submit() {
        this.setState({loading: true, records: [], headers: []})
        const designatedTimestamp =
            BATCH_TYPES[this.state.selectedBatchType.value]
                .designatedTimestampFieldPath
        const dataModelId =
            BATCH_TYPES[this.state.selectedBatchType.value].dataModelId

        let filter = []
        if (designatedTimestamp) {
            filter.push({...this.state.timefilter})
            filter[0].path = designatedTimestamp
            filter[0].type = 'DesignatedTimestamp'
        }

        let records = await paginate(dataModelId, filter, {})

        let hashTable = {},
            headers = []

        records = records.map((record) => {
            record = flatten(record)

            for (let k in record) {
                if (
                    k.includes('_id') ||
                    k.includes('dataModelId') ||
                    k.includes('deleted')
                ) {
                    delete record[k]
                    continue
                }

                let label = k.split('.')
                for (let l of label) {
                    if (!hashTable[l]) {
                        const dataModel = this.state.dataModels.find((d) =>
                            d.fields.find((f) => f._id === l)
                        )

                        if (dataModel) {
                            const field = dataModel.fields.find(
                                (f) => f._id === l
                            )
                            hashTable[l] = field.name
                        }
                    }
                }

                label = label.map((l) => hashTable[l] || l).join('.')
                record[label] = record[k]
                if (label !== k) delete record[k]

                if (!headers.find((h) => h.key === label)) {
                    headers.push({label, key: label})
                }
            }
            return record
        })

        this.setState({records, headers, loading: false, ready: true})
    }

    async componentDidMount() {
        this.setState({
            dataModels: await API.get('data-models', 2),
            loading: false,
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Batches By Time Report</h1>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm={{size: 6, offset: 3}}>
                        <Row>
                            <Col>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="batch-type"
                                            value={this.state.selectedBatchType}
                                            onChange={(selectedBatchType) =>
                                                this.setState({
                                                    selectedBatchType,
                                                })
                                            }
                                            options={Object.keys(BATCH_TYPES)
                                                .map((o) => {
                                                    return {label: o, value: o}
                                                })
                                                .sort((a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                                )}
                                        />
                                        <IntlMessages id="Batch Type" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TimestampFilter
                                    logic={''}
                                    value={''}
                                    dataModelId={''}
                                    fieldId={''}
                                    path={''}
                                    fieldName={'Timerange'}
                                    onSubmit={(timefilter) =>
                                        this.setState({timefilter})
                                    }
                                    isDesignatedTimeEnd={false}
                                    autoSubmit={true}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Button
                                    size="xl"
                                    block
                                    onClick={this.submit}
                                    disabled={this.state.loading}>
                                    GENERATE
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {this.state.ready ? (
                    <Fragment>
                        <Row className="mt-4">
                            <Col xs="12" sm={{size: 4, offset: 4}}>
                                <Card>
                                    <CardBody style={{textAlign: 'center'}}>
                                        <h5>Total Batches</h5>
                                        <h1>{this.state.records.length}</h1>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {this.state.records.length &&
                        this.state.headers.length ? (
                            <Row className="mt-4">
                                <Col xs="12" sm={{size: 4, offset: 4}}>
                                    <CSVLink
                                        className="btn btn-block"
                                        data={this.state.records}
                                        filename="Accumine Platform - Batches By Time Report Export.csv">
                                        Click To Export Data
                                    </CSVLink>
                                </Col>
                            </Row>
                        ) : null}
                    </Fragment>
                ) : null}

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
