import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormText,
} from 'reactstrap'
import autobind from 'auto-bind'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'

const CONFIGURATION = [
    {
        name: 'REQUEST_TIMEOUT',
        description: 'Api request timeout (ms)',
        type: 'number',
        validate: (v) => {
            return v > 0
        },
    },
    {
        name: 'CLOUD_SYNC_RATE',
        description: 'Cloud command sync frequency (ms)',
        type: 'number',
        validate: (v) => {
            return v > 0
        },
    },
    {
        name: 'PREDICTION_THRESHOLD',
        description: 'AI model prediction probability threshold (0-1)',
        type: 'number',
        validate: (v) => {
            return v > 0 && v <= 1
        },
    },
    {
        name: 'PREDICTION_RATE',
        description: 'Prediction frequency (ms), 0 = real-time',
        type: 'number',
        validate: (v) => {
            return v >= 0
        },
    },
    {
        name: 'DATA_SYNC_RATE',
        description: 'Data push frequency (ms)',
        type: 'number',
        validate: (v) => {
            return v >= 0
        },
    },
    {
        name: 'MOVEMENT_SENSITIVITY',
        description: '"In-use" sensitivity, tweak based on asset type',
        type: 'number',
        validate: (v) => {
            return v > 0
        },
    },
    {
        name: 'MOVEMENT_DATA_RATE',
        description: 'How frequently to detect new movement events (ms)',
        type: 'number',
        validate: (v) => {
            return v > 0
        },
    },
    {
        name: 'TRAINING_SAMPLES',
        description: 'How many samples to take when training AI model',
        type: 'number',
        validate: (v) => {
            return v > 0
        },
    },
    {
        name: 'MAX_ROWS',
        description:
            'Maximum data records to queue (each location and movement)',
        type: 'number',
        validate: (v) => {
            return v > 0 && v < 50000
        },
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            REQUEST_TIMEOUT: 10000,
            CLOUD_SYNC_RATE: 5000,
            PREDICTION_THRESHOLD: 0.5,
            PREDICTION_RATE: 0,
            TRAINING_SAMPLES: 100,
            DATA_SYNC_RATE: 1000,
            MOVEMENT_DATA_RATE: 60000,
            MOVEMENT_SENSITIVITY: 50,
            MAX_ROWS: 5000,
        }
    }

    async fetchSettings() {
        let settings = await API.get('ips/cloud/configuration', 2)

        delete settings._id

        if (settings) {
            this.setState({...settings})
        }
    }

    renderForm() {
        let inputs = []

        for (let i in CONFIGURATION) {
            const {name, description, type, validate} = CONFIGURATION[i]

            inputs.push(
                <Label className="form-group has-top-label">
                    <Input
                        type={type}
                        name={name}
                        value={this.state[name]}
                        onChange={(v) =>
                            this.setState({[name]: v.target.value})
                        }
                    />
                    <IntlMessages id={description} />
                </Label>
            )
        }
        return inputs
    }

    async saveChanges() {
        this.setState({loading: true})

        for (let key in this.state) {
            const obj = CONFIGURATION.find((c) => c.name === key)
            if (!obj) continue
            if (!obj.validate(this.state[key])) {
                this.setState({loading: false})
                return alert('Invalid value provided for: ' + obj.description)
            }
        }

        try {
            await API.patch('ips/cloud/configuration', this.state, 2)
            this.props.toggleModal()
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error saving your changes.')
        }
        this.setState({loading: false})
    }

    async componentWillMount() {
        await this.fetchSettings()
        this.renderForm()
    }

    render() {
        let {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggleModal}>
                        Field Device Configuration
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form>{this.renderForm()}</Form>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveChanges}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
