import React, {Component, Fragment} from 'react'

export default class MapMarker extends Component {
    render() {
        const {lng, lat} = this.props

        if (!lng || !lat) return null

        return (
            <div
                data-lng={lng.value}
                data-lat={lat.value}
                style={{
                    borderRadius: 100,
                    height: 20,
                    width: 20,
                    backgroundColor: 'rgba(100,100,200,1)',
                }}
            />
        )
    }
}
