import React, {Component, Fragment} from 'react'
import {Route, withRouter, Switch, Redirect} from 'react-router-dom'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import axios from 'axios'
import autobind from 'auto-bind'

import TopNav from 'containers/TopNav'
import Sidebar from 'containers/Sidebar'
import SidePane from 'containers/SidePane'

import Core from './core'
import Views from './views'
import AddNewEnvironmentModal from './AddNewEnvironmentModal'
import RenameEnvironmentModal from './RenameEnvironmentModal'
import AddNewSectionModal from './AddNewSectionModal'
import AddNewViewModal from './AddNewViewModal'
import RenameSectionModal from './RenameSectionModal'
import PublishEnvironmentModal from './PublishEnvironmentModal'
import DeleteEnvironmentModal from './DeleteEnvironmentModal'
import HotReloader from './HotReloader'
import {logUserEvent} from 'SDK/api/common'

import {connect} from 'react-redux'
//import { Subscriber } from 'rxjs';

import Subscriber from 'SDK/subscriber'
import {generateHash, iteratePlaceholderName} from 'SDK/helpers'
//import api from './hub/downtime-trends/accumine-sdk/api';

import * as API from 'SDK/api'
import NetworkWarning from './NetworkWarning'
import EnvironmentEditStatus from './EnvironmentEditStatus'
import moment from 'moment-timezone'
import KBWidget from './KBWidget'

import MQTTClient from 'SDK/api/mqtt'

class MainApp extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.mqttClient = MQTTClient

        this.state = {
            environments: [],
            environment: false,
            sectionId: false,
            editingEnvironment: false,
            showAddNewEnvironmentModal: false,
            showRenameEnvironmentModal: false,
            showAddNewSectionModal: false,
            showRenameSectionModal: false,
            showPublishEnvironmentModal: false,
            showAddNewViewModal: false,
            showDeleteEnvironmentModal: false,
            mouseMoving: true,
        }
    }

    toggleEditEnvironment() {
        this.setState({
            editingEnvironment: !this.state.editingEnvironment,
        })
        localStorage['editingEnvironment'] = !this.state.editingEnvironment
    }

    toggleRenameEnvironmentModal() {
        this.setState({
            showRenameEnvironmentModal: !this.state.showRenameEnvironmentModal,
        })
    }

    toggleRenameSectionModal(sectionId) {
        this.setState({
            showRenameSectionModal: !this.state.showRenameSectionModal,
            editingSectionId: sectionId || null,
        })
    }

    toggleAddNewEnvironmentModal() {
        this.setState({
            showAddNewEnvironmentModal: !this.state.showAddNewEnvironmentModal,
        })
    }

    toggleAddNewSectionModal() {
        this.setState({
            showAddNewSectionModal: !this.state.showAddNewSectionModal,
        })
    }

    togglePublishEnvironmentModal() {
        this.setState({
            showPublishEnvironmentModal:
                !this.state.showPublishEnvironmentModal,
        })
    }

    toggleAddNewViewModal(sectionId, viewId) {
        if (sectionId && viewId) {
            this.setState({
                showAddNewViewModal: !this.state.showAddNewViewModal,
                newestView: {
                    sectionId,
                    viewId,
                },
            })
        } else {
            this.setState({
                showAddNewViewModal: !this.state.showAddNewViewModal,
            })
        }
    }

    toggleDeleteEnvironmentModal() {
        this.setState({
            showDeleteEnvironmentModal: !this.state.showDeleteEnvironmentModal,
        })
    }

    async pollTimezone() {
        let shifts = await API.get('shifts', 2)
        if (shifts) {
            shifts = [...new Set(shifts.map((x) => x.timezone))]
            if (shifts.length > 1) {
                moment.tz.setDefault('UTC')
                localStorage['timezone'] = 'UTC'
            } else if (shifts.length === 1) {
                moment.tz.setDefault(shifts[0])
                localStorage['timezone'] = shifts[0]
            } else {
                const guess = moment.tz.guess()
                moment.tz.setDefault(guess)
                localStorage['timezone'] = guess
            }
        }
    }

    async changeEnvironment(environment, sectionId, viewId) {
        const env = this.state.environments.find((a) => a.name === environment)
        this.setState({
            environment: env,
        })
        let path = '/app/env/' + env._id + '/'

        if (sectionId && viewId) {
            path += sectionId + '/' + viewId + '/view'
        } else {
            const {role} = JSON.parse(localStorage['userObject'])
            let foundDefault = false
            if (role && env.sections.length && env.sections[0].views.length) {
                for (let section of env.sections) {
                    for (let view of section.views) {
                        if (
                            view.permissions.find((p) => p === role) &&
                            !foundDefault
                        ) {
                            path += section.id + '/' + view.id + '/view'
                            foundDefault = true
                            break
                        }
                    }
                }
                if (!foundDefault) path += 'empty'
            } else {
                path += 'empty'
            }
        }
        this.props.history.push(path + '?timestamp=' + moment().unix())
    }

    handleAddNewEnvironmentEvent() {
        this.toggleAddNewEnvironmentModal()
    }

    handleAddNewEnvironmentSubmit(environment) {
        this.fetchEnvironments()
        this.setState({environment})
        this.props.history.push('/app/env/' + environment._id + '/empty')
    }

    handleAddNewSectionEvent() {
        this.toggleAddNewSectionModal()
    }

    handleAddNewSectionSubmit(environment) {
        this.setState({environment})
    }

    async handleAddTableEvent(sectionId) {
        this.setState({loading: true})
        let env = this.state.environment,
            section = env.sections.find((section) => section.id === sectionId),
            viewId = generateHash()

        section.views.push({
            id: viewId,
            name: iteratePlaceholderName(section.views, 'Table Generator'),
            layout: [
                {
                    w: 12,
                    h: 6,
                    y: 0,
                    i: 'Generic::HubbellAikenTables',
                    minW: 1,
                    minH: 1,
                    moved: false,
                    static: false,
                },
            ],
            generic: [{component: 'HubbellAikenTables'}],
            custom: [],
            permissions: [],
            type: 'generic',
        })

        await API.patch('environments/' + env._id, env, 2)

        this.setState({
            loading: false,
            environment: env,
            newestView: {sectionId, viewId},
        })

        this.props.history.push(
            `/app/env/${env._id}/${sectionId}/${viewId}/view`
        )
    }

    async handleAddNewViewEvent(sectionId) {
        this.setState({loading: true})
        let env = this.state.environment,
            section = env.sections.find((section) => section.id === sectionId),
            viewId = generateHash()

        section.views.push({
            id: viewId,
            name: iteratePlaceholderName(section.views, 'New View'),
            layout: [],
            generic: [],
            custom: [],
            permissions: [],
        })

        await API.patch('environments/' + env._id, env, 2)

        this.setState(
            {loading: false, environment: env, newestView: {sectionId, viewId}},
            this.toggleAddNewViewModal
        )
    }

    async handleDeleteViewEvent(sectionId, viewId) {
        let {environment} = this.state
        const views = environment.sections
            .find((section) => section.id === sectionId)
            .views.filter((view) => view.id !== viewId)
        environment.sections.find((section) => section.id === sectionId).views =
            views
        await API.patch('environments/' + environment._id, environment, 2)

        this.setState({environment})

        const currentPath = this.props.location.pathname.split('/')
        if (
            currentPath[3] === environment._id &&
            currentPath[4] === sectionId &&
            currentPath[5] === viewId
        ) {
            this.props.history.push(
                '/app/env/' + this.state.environment._id + '/empty'
            )
        }
    }

    async handleRenameSectionEvent(sectionId) {
        this.toggleRenameSectionModal(sectionId)
    }

    async handleDeleteSectionEvent(sectionId) {
        let {environment} = this.state
        environment.sections = environment.sections.filter(
            (a) => a.id !== sectionId
        )
        await API.patch('environments/' + environment._id, environment, 2)
    }

    handleRenameEnvironmentEvent() {
        this.toggleRenameEnvironmentModal()
    }

    async handleRenameEnvironmentSubmit() {
        await this.fetchEnvironments()
        const environment = this.state.environments.find(
            (o) => o._id === this.state.environment._id
        )
        this.setState({environment})
    }

    async handleCopyEnvironmentEvent() {
        let clone = {...this.state.environment}
        delete clone._id

        clone.name = iteratePlaceholderName(
            this.state.environments,
            'Copy Of ' + clone.name
        )
        await API.post('environments', clone, 2)
        await this.fetchEnvironments()

        const environment = this.state.environments.find(
            (o) => o.name === clone.name
        )
        this.setState({environment})
        this.props.history.push(
            '/app/env/' +
                environment._id +
                '/' +
                environment.sections[0].id +
                '/' +
                environment.sections[0].views[0].id +
                '/view'
        )
    }

    handleDeleteEnvironmentEvent() {
        this.toggleDeleteEnvironmentModal()
    }

    async handleDeleteEnvironmentSubmit() {
        const envId = this.state.environment._id
        this.setState({
            environment: false,
            editingEnvironment: false,
        })
        await API.remove('environments/' + envId, 2)
        await this.fetchEnvironments()
        this.props.history.push('/app/core/splash')
    }

    async handlePublishEnvironmentEvent() {
        this.togglePublishEnvironmentModal()
    }

    async handleViewTypeSelection(type) {
        this.toggleAddNewViewModal()
        const {sectionId, viewId} = this.state.newestView

        let environment = this.state.environment,
            section = environment.sections.find((s) => s.id === sectionId),
            view = section.views.find((v) => v.id === viewId)

        view.type = type
        this.setState({environment})
        await API.patch('environments/' + environment._id, environment, 2)
        await this.fetchEnvironments()
        const env = this.state.environments.find(
            (o) => o._id === this.state.environment._id
        )
        this.setState({environment: env})
        this.props.history.push(
            '/app/env/' +
                environment._id +
                '/' +
                sectionId +
                '/' +
                viewId +
                '/edit'
        )
    }

    async handleViewCopySelection(viewObj) {
        this.toggleAddNewViewModal()
        const {sectionId, viewId} = this.state.newestView

        let environment = this.state.environment,
            section = environment.sections.find((s) => s.id === sectionId),
            view = section.views.find((v) => v.id === viewId)

        view.name = viewObj.name
        view.layout = viewObj.layout
        view.generic = viewObj.generic
        view.custom = viewObj.custom
        view.type = viewObj.type
        view.permissions = viewObj.permissions
        this.setState({environment})
        await API.patch('environments/' + environment._id, environment, 2)
        await this.fetchEnvironments()
        const env = this.state.environments.find(
            (o) => o._id === this.state.environment._id
        )
        this.setState({environment: env})
        this.props.history.push(
            '/app/env/' +
                environment._id +
                '/' +
                sectionId +
                '/' +
                viewId +
                '/edit'
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const path = this.props.location.pathname.split('/')
            if (path.length >= 5) {
                if (this.state.environments.length) {
                    const app = this.state.environments.find(
                        (a) => a._id === path[3]
                    )
                    if (app) {
                        const section = app.sections.find(
                            (s) => s.id === path[4]
                        )
                        if (section) {
                            const view = section.views.find(
                                (v) => v.id === path[5]
                            )
                            if (view) {
                                logUserEvent(
                                    app.name +
                                        ' > ' +
                                        section.name +
                                        ' > ' +
                                        view.name
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    async handleSort(sectionId, order) {
        let {environment} = this.state,
            resorted = []

        if (sectionId) {
            let section = environment.sections.find(
                (section) => section.id === sectionId
            )
            for (let i in order) {
                resorted.push(
                    section.views.find((view) => view.id === order[i])
                )
            }
            environment.sections.find(
                (section) => section.id === sectionId
            ).views = resorted
        } else {
            for (let i in order) {
                resorted.push(
                    environment.sections.find(
                        (section) => section.id === order[i]
                    )
                )
            }
            environment.sections = resorted
        }

        this.setState({environment, lastUpdate: Math.random()})
        await API.patch('environments/' + environment._id, environment, 2)
    }

    async fetchEnvironments() {
        let environments = await API.get('environments', 2)
        environments = environments.filter((e) => !e.archived)

        this.setState({environments})
    }

    async pollResourceObject() {
        if (this.state.environment && this.state.environment._id) {
            const envs = await API.get('environments', 2)
            if (envs) {
                const env = envs.find(
                    (e) => e._id === this.state.environment._id
                )
                this.setState({freshResources: env.resources})
            }
        }
    }

    clearEnvironment() {
        this.setState({environment: null})
    }

    async componentWillMount() {
        this.setState({loading: true})
        await this.mqttClient.connect()
        const envs = await API.get('environments', 2)
        this.fetchEnvironments()

        if (this.props.location.pathname.includes('env')) {
            let environment = envs.find(
                (env) => env._id === this.props.location.pathname.split('/')[3]
            )

            if (
                this.props.location.pathname.split('/')[
                    this.props.location.pathname.split('/').length - 1
                ] === 'edit'
            ) {
                let p = this.props.location.pathname.replace('edit', 'view')
                this.props.history.push(p)
            }
            this.setState({
                environment: environment,
                loading: false,
            })
        } else {
            this.setState({
                environment: null,
                loading: false,
            })
        }

        axios.interceptors.response.use(
            async (response) => {
                if (!this.state.environment) {
                    return response
                }
                if (
                    response.config.url.includes('v1/devices') &&
                    response.config.method === 'get'
                ) {
                    response.data = response.data.map((a) => {
                        if (a.erpAssetName && localStorage['globalERPNaming']) {
                            a.name = a.erpAssetName
                        }
                        return a
                    })
                }
                if (
                    response.config.url.includes('v1/devices') &&
                    !response.config.url.includes('?all=true') &&
                    response.config.method === 'get' &&
                    this.state.environment.resources
                ) {
                    let allowedDevices = this.state.freshResources
                        ? this.state.freshResources.devices
                        : this.state.environment.resources.devices
                    if (allowedDevices)
                        response.data = response.data.filter((a) =>
                            allowedDevices.find((b) => b === a._id)
                        )
                }
                if (
                    response.config.url.includes('v2/shifts') &&
                    !response.config.url.includes('?all=true') &&
                    response.config.method === 'get' &&
                    this.state.environment.resources
                ) {
                    let allowedShifts = this.state.freshResources
                        ? this.state.freshResources.shifts
                        : this.state.environment.resources.shifts
                    if (allowedShifts)
                        response.data = response.data.filter((a) =>
                            allowedShifts.find((b) => b === a._id)
                        )
                }
                if (
                    response.config.url.includes('v2/users') &&
                    !response.config.url.includes('?all=true') &&
                    response.config.method === 'get' &&
                    this.state.environment.resources
                ) {
                    let allowedUsers = this.state.freshResources
                        ? this.state.freshResources.users
                        : this.state.environment.resources.users
                    if (allowedUsers)
                        response.data = response.data.filter((a) =>
                            allowedUsers.find((b) => b === a._id)
                        )
                }
                return response
            },
            (error) => {}
        )

        this.pollResourceObject()
        this.pollTimezone()

        this.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Environments/#`,
            this.pollResourceObject
        )
        this.mqttClient.router.on(
            `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/Shifts/#`,
            this.pollTimezone
        )

        //this.subscriber.add(this.pollResourceObject, 5000, 'pollResourceObject');
        //this.subscriber.add(this.pollTimezone, 5000, 'pollTimezone');
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
        try {
            this.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Environments/#`,
                this.pollResourceObject
            )
            this.mqttClient.router.removeListener(
                `${
                    JSON.parse(localStorage['userObject']).factoryId
                }/Mirror/Shifts/#`,
                this.pollTimezone
            )
        } catch (error) {
            console.log(error)
        }
    }

    handleMouseMove(e) {
        if (this.state.mouseMoving === false) this.setState({mouseMoving: true})
        ;(() => {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                if (this.state.mouseMoving === true) {
                    this.setState({mouseMoving: false})
                }
            }, 5000)
        })()
    }

    render() {
        const {match, containerClassnames} = this.props
        const mainOverride = this.state.environment ? {} : {marginLeft: 50}

        return (
            <div
                id="app-container"
                className={containerClassnames}
                onTouchStart={this.handleMouseMove}
                onMouseMove={this.handleMouseMove}
                style={{background: '#f8f8f8'}}>
                <HotReloader />
                {this.state.environment &&
                this.state.environment._id !== '5fc53042befe3f001ad8111c' ? (
                    <SidePane mqttClient={this.mqttClient} />
                ) : null}
                <TopNav
                    environment={this.state.environment}
                    environments={this.state.environments}
                    history={this.props.history}
                    toggleEditEnvironment={this.toggleEditEnvironment}
                    handleAddNewEnvironmentEvent={
                        this.handleAddNewEnvironmentEvent
                    }
                    changeEnvironment={this.changeEnvironment}
                    editingEnvironment={this.state.editingEnvironment}
                    handleDeleteEnvironmentEvent={
                        this.handleDeleteEnvironmentEvent
                    }
                    handleCopyEnvironmentEvent={this.handleCopyEnvironmentEvent}
                    handleRenameEnvironmentEvent={
                        this.handleRenameEnvironmentEvent
                    }
                    handlePublishEnvironmentEvent={
                        this.handlePublishEnvironmentEvent
                    }
                    mqttClient={this.mqttClient}
                    clearEnvironment={this.clearEnvironment}
                />
                {this.state.showAddNewEnvironmentModal ? (
                    <AddNewEnvironmentModal
                        toggleModal={this.toggleAddNewEnvironmentModal}
                        modal={this.state.showAddNewEnvironmentModal}
                        handleAddNewEnvironmentSubmit={
                            this.handleAddNewEnvironmentSubmit
                        }
                    />
                ) : null}

                {this.state.showRenameEnvironmentModal ? (
                    <RenameEnvironmentModal
                        environment={this.state.environment}
                        toggleModal={this.toggleRenameEnvironmentModal}
                        modal={this.state.showRenameEnvironmentModal}
                        handleRenameEnvironmentSubmit={
                            this.handleRenameEnvironmentSubmit
                        }
                    />
                ) : null}
                {this.state.environment ? (
                    <>
                        <Sidebar
                            environment={this.state.environment}
                            editingEnvironment={this.state.editingEnvironment}
                            handleAddNewSectionEvent={
                                this.handleAddNewSectionEvent
                            }
                            handleAddNewViewEvent={this.handleAddNewViewEvent}
                            handleAddTableEvent={this.handleAddTableEvent}
                            handleDeleteViewEvent={this.handleDeleteViewEvent}
                            handleRenameSectionEvent={
                                this.handleRenameSectionEvent
                            }
                            handleDeleteSectionEvent={
                                this.handleDeleteSectionEvent
                            }
                            handleSort={this.handleSort}
                            lastUpdate={this.state.lastUpdate}
                        />

                        {this.state.showAddNewSectionModal ? (
                            <AddNewSectionModal
                                toggleModal={this.toggleAddNewSectionModal}
                                modal={this.state.showAddNewSectionModal}
                                environment={this.state.environment}
                                handleAddNewSectionSubmit={
                                    this.handleAddNewSectionSubmit
                                }
                            />
                        ) : null}

                        {this.state.showRenameSectionModal ? (
                            <RenameSectionModal
                                environment={this.state.environment}
                                editingSectionId={this.state.editingSectionId}
                                toggleModal={this.toggleRenameSectionModal}
                                modal={this.state.showRenameSectionModal}
                                handleRenameSectionSubmit={
                                    this.handleRenameEnvironmentSubmit
                                }
                            />
                        ) : null}
                        {this.state.showPublishEnvironmentModal ? (
                            <PublishEnvironmentModal
                                environment={this.state.environment}
                                toggleModal={this.togglePublishEnvironmentModal}
                                modal={this.state.showPublishEnvironmentModal}
                                handleAddNewEnvironmentSubmit={
                                    this.handleAddNewEnvironmentSubmit
                                }
                            />
                        ) : null}

                        {this.state.showAddNewViewModal ? (
                            <AddNewViewModal
                                environment={this.state.environment}
                                toggleModal={this.toggleAddNewViewModal}
                                modal={this.state.showAddNewViewModal}
                                handleViewTypeSelection={
                                    this.handleViewTypeSelection
                                }
                                handleViewCopySelection={
                                    this.handleViewCopySelection
                                }
                            />
                        ) : null}

                        {this.state.showDeleteEnvironmentModal ? (
                            <DeleteEnvironmentModal
                                environment={this.state.environment}
                                toggleModal={this.toggleDeleteEnvironmentModal}
                                modal={this.state.showDeleteEnvironmentModal}
                                handleDeleteEnvironmentSubmit={
                                    this.handleDeleteEnvironmentSubmit
                                }
                            />
                        ) : null}
                    </>
                ) : null}
                <main style={mainOverride}>
                    <div className="container-fluid">
                        <Switch>
                            <Route
                                path={`${match.url}/env`}
                                toggleAddNewViewModal={
                                    this.toggleAddNewViewModal
                                }
                                mouseMoving={this.state.mouseMoving}
                                editingEnvironment={
                                    this.state.editingEnvironment
                                }
                                render={(props) => (
                                    <Views
                                        toggleAddNewViewModal={
                                            this.toggleAddNewViewModal
                                        }
                                        key={'env'}
                                        mouseMoving={this.state.mouseMoving}
                                        editingEnvironment={
                                            this.state.editingEnvironment
                                        }
                                        updateEnvironment={(environment) => {
                                            this.setState({environment})
                                        }}
                                        mqttClient={this.mqttClient}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}/core`}
                                editingEnvironment={
                                    this.state.editingEnvironment
                                }
                                environment={this.state.environment}
                                render={(props) => (
                                    <Core
                                        editingEnvironment={
                                            this.state.editingEnvironment
                                        }
                                        environment={this.state.environment}
                                        mqttClient={this.mqttClient}
                                        {...props}
                                    />
                                )}
                            />
                            <Redirect to="/error" />
                        </Switch>
                    </div>
                    <NetworkWarning history={this.props.history} />
                    {this.state.editingEnvironment && (
                        <EnvironmentEditStatus
                            exitEditingMode={this.toggleEditEnvironment}
                        />
                    )}
                </main>
            </div>
        )
    }
}

const mapStateToProps = ({menu}) => {
    const {containerClassnames} = menu
    return {containerClassnames}
}

export default withRouter(connect(mapStateToProps, {})(MainApp))
