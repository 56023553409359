export const subHiddenBreakpoint = 2440
export const menuHiddenBreakpoint = 768
export const defaultMenuType = 'menu-default' //'menu-sub-hidden', 'menu-hidden'
export const defaultStartPath = '/app/core/splash'

export const defaultLocale = 'en'
export const localeOptions = [{id: 'en', name: 'English'}]

export const apiUrl = process.env.REACT_APP_API_URL + '/v1/'
export const apiUrl2 = process.env.REACT_APP_API_URL + '/v2/'
