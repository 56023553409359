import React, {Component, Fragment} from 'react'
import {CSVLink, CSVDownload} from 'react-csv'

export default class extends Component {
    exportData() {
        const type = this.props.type,
            data = this.props.data,
            unit = this.props.hours ? 'Hours' : '%'

        let exportObject = []

        if (type === 'total') {
            let header = ['Time']
            for (let i in data.chartdata.labels) {
                header.push(data.chartdata.labels[i] + ' ' + unit)
            }
            exportObject.push(header)
            let onlyRow = ['Total']
            onlyRow = onlyRow.concat(data.chartdata.datasets[0].data)
            exportObject.push(onlyRow)
        } else if (type === 'totalByShift') {
            let header = ['Time'],
                onlyRow = ['Total']
            for (let i in data.chartdata.labels) {
                for (let j in this.props.shifts) {
                    header.push(
                        data.chartdata.labels[i] +
                            ' - ' +
                            this.props.shifts[j] +
                            ' ' +
                            unit
                    )
                    onlyRow.push(data.chartdata.datasets[j].data[i])
                }
            }
            exportObject.push(header)
            exportObject.push(onlyRow)
        } else if (type === 'timeSeries') {
            let header = ['Time']
            for (let i in this.props.selectedAssets) {
                header.push(this.props.selectedAssets[i].label + ' ' + unit)
            }
            exportObject.push(header)
            for (let i in data.chartdata.labels) {
                let row = [data.chartdata.labels[i]]
                for (let j in this.props.selectedAssets) {
                    row.push(data.chartdata.datasets[j].data[i])
                }
                exportObject.push(row)
            }
        } else if (type === 'timeSeriesByShift') {
            let header = ['Time']
            for (let j in this.props.shifts) {
                for (let i in this.props.selectedAssets) {
                    header.push(
                        this.props.selectedAssets[i].label +
                            ' - ' +
                            this.props.shifts[j] +
                            ' ' +
                            unit
                    )
                }
            }
            exportObject.push(header)
            for (let i in data[0].labels) {
                let row = [data[0].labels[i]]
                for (let j in data) {
                    for (let k in data[j].datasets) {
                        row.push(data[j].datasets[k].data[i])
                    }
                }

                exportObject.push(row)
            }
        }
        return exportObject
    }
    render() {
        const data = this.exportData()
        return (
            <Fragment>
                <CSVLink data={data} filename="Accumine Cloud Data Export.csv">
                    Download CSV Data
                </CSVLink>
            </Fragment>
        )
    }
}
