import {Fragment, Component} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import {Row, Col, Card, CardHeader, CardBody, CardFooter} from 'reactstrap'
import Chart from './chart'
import moment from 'moment'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import TimestampFilter from '../../QueryBuilder/Filter/Timestamp'
import {Toolbar} from './toolbar'
import {cloneDeep} from 'lodash-es'
import {CSVDownload} from 'react-csv'

class ReportViewer extends Component {
    static propTypes = {
        // report record, can fetch from "System/Saved Reports" data model
        report: PropTypes.shape({
            _id: PropTypes.string,
            report_data_model_id: PropTypes.string,
            report_name: PropTypes.string,
            body: PropTypes.string,
            report_configuration: PropTypes.string,
            chart_config: PropTypes.string,
            column_config: PropTypes.string,
            cache_enabled: PropTypes.bool,
            cache_ttl_hours: PropTypes.number,
        }).isRequired,

        // how often to reload the report, by default = once per hour
        refreshRateInSeconds: PropTypes.number,

        // optional handling of data fetch error
        onDataFetchError: PropTypes.func,

        // optional handling of edit link generation error
        onEditLinkGenerationError: PropTypes.func,

        // whether or not to let users edit the report
        // works by routing user to the "Schema Viewer" and loading the report in there
        // by default = true
        showEditLink: PropTypes.bool,

        // whether or not to show toolbar, must be true if show edit link
        // by default = true
        showToolbar: (props, propName, componentName) => {
            if (props.showEditLink === true && props[propName] === false) {
                return new Error(
                    '"showToolbar" must be true when "showEditLink" = true.'
                )
            }
        },

        // react-router history object, needed if providing edit capability
        history: (props, propName, componentName) => {
            if (
                props.showEditLink === true &&
                typeof props[propName] !== 'object'
            ) {
                return new Error(
                    'react router history prop object is required when "showEditLink" = true.'
                )
            }
        },

        // whether or not to allow user to modify timerange
        showTimerangeSelector: PropTypes.bool,
    }

    static defaultProps = {
        refreshRateInSeconds: 60 * 60, // every hour
        onDataFetchError: console.log,
        showEditLink: true,
        showToolbar: true,
        showTimerangeSelector: true,
    }

    constructor(props) {
        super(props)
        autoBind(this)

        this.sub = new Subscriber()

        this.state = {
            loading: true,
            ready: false,
            error: false,
            lastFetch: null,
            schemaViewerPath: undefined,

            columns: [],
            rows: [],
            chartConfig: {},

            timerangeOverride: {},

            csvData: [],
        }
    }

    async fetchData(timerangeOverride = false) {
        this.setState({loading: true, ready: false})
        try {
            let body = JSON.parse(this.props.report.body)
            if (timerangeOverride) {
                body.timerange = timerangeOverride
                this.setState({timerangeOverride})
            }
            let result = await API.post(
                `data-models/${this.props.report.report_data_model_id}/aggregate`,
                body,
                2
            )
            const columnConfig = JSON.parse(this.props.report.column_config)
            if (
                columnConfig &&
                columnConfig.constructor === Array &&
                columnConfig.length > 0
            ) {
                result.schema.fields = columnConfig
            }

            const columns = result.schema.fields
                .filter((a) => a.visible || a.visible === undefined)
                .map((a) => {
                    return {
                        name: a.name,
                        alias: a.alias,
                        type: a.type,
                    }
                })

            const rows = result.data.map((row) => {
                let r = []
                for (let c of columns) {
                    if (c.type === 'datetime') {
                        r.push(moment(row[c.name]).format('YYYY-MM-DD hh:mm a'))
                    } else if (
                        row[c.name] !== undefined &&
                        row[c.name] !== null &&
                        row[c.name].constructor !== Array &&
                        row[c.name].constructor !== Object
                    ) {
                        r.push(row[c.name])
                    } else {
                        r.push(null)
                    }
                }
                return r
            })
            this.setState({
                result,
                rows,
                columns,
                chartConfig: JSON.parse(this.props.report.chart_config) || {
                    chartType: 'Table',
                    chartOptions: {},
                },
                ready: true,
                lastFetch: moment(),
                csvData: [columns.map((c) => c.alias || c.name)].concat(rows),
            })
        } catch (error) {
            alert(error)
            this.props.onDataFetchError(error)
        }
        this.setState({loading: false})
    }

    async makeSchemaViewerPath() {
        try {
            let envs = await API.get('environments', 2),
                schemaViewerPath = undefined
            for (let env of envs) {
                for (let section of env.sections) {
                    for (let view of section.views.filter(
                        (v) => v.generic && v.generic.length
                    )) {
                        if (
                            view.type === 'generic' &&
                            view.generic[0].component === 'SchemaViewer'
                        ) {
                            schemaViewerPath = `/app/env/${env._id}/${section.id}/${view.id}/view`
                        }
                    }
                }
            }
            if (schemaViewerPath) {
                schemaViewerPath += `?reportId=${this.props.report._id}&previous=${this.props.history.location.pathname}`
                this.setState({schemaViewerPath})
            }
        } catch (error) {
            this.props.onEditLinkGenerationError(error)
        }
    }

    async componentDidMount() {
        if (this.props.showEditLink) {
            await this.makeSchemaViewerPath()
        }
        this.originalTimerange = cloneDeep(
            JSON.parse(this.props.report.body)
        ).timerange
        this.sub.add(
            this.fetchData,
            1000 * this.props.refreshRateInSeconds,
            'fetchData()'
        )
    }

    componentWillUnmount() {
        this.sub.removeAll()
    }

    componentDidUpdate(prevProps) {
        const prevId = prevProps.report ? prevProps.report._id : null,
            nextId = this.props.report ? this.props.report._id : null
        if (prevId !== nextId) {
            this.setState({ready: false}, async () => {
                if (this.props.showEditLink) {
                    await this.makeSchemaViewerPath()
                }
                await this.fetchData()
                this.setState({ready: true})
            })
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.dataset ? (
                    <CSVDownload data={this.state.dataset} target="_blank" />
                ) : null}
                <Card>
                    <CardHeader className="mt-2">
                        <Row>
                            <Col xs="9">
                                <h4>
                                    <strong>
                                        {this.props.report.report_name}
                                    </strong>
                                </h4>
                            </Col>
                            {this.props.showToolbar ? (
                                <Col xs="3" className="text-right">
                                    <Toolbar {...this.state} {...this.props} />
                                </Col>
                            ) : null}
                        </Row>
                    </CardHeader>

                    <CardBody className="text-center">
                        {this.state.loading && !this.state.ready ? (
                            <>Loading data...</>
                        ) : null}

                        {this.state.ready ? (
                            <Fragment>
                                <Row>
                                    <Col xs="12" sm={{size: 4, offset: 4}}>
                                        <TimestampFilter
                                            logic={
                                                this.originalTimerange.logic ||
                                                this.state.timerangeOverride
                                                    .logic
                                            }
                                            value={
                                                this.originalTimerange.value ||
                                                this.state.timerangeOverride
                                                    .value
                                            }
                                            dataModelId={''}
                                            fieldId={''}
                                            path={''}
                                            fieldName={'Timerange'}
                                            onSubmit={(timerange) =>
                                                this.fetchData(timerange)
                                            }
                                            isDesignatedTimeEnd={false}
                                            autoSubmit={false}
                                        />
                                        <hr />
                                    </Col>
                                </Row>

                                <Chart
                                    chartType={this.state.chartConfig.chartType}
                                    columns={this.state.columns.map(
                                        (a) => a.alias || a.name
                                    )}
                                    rows={this.state.rows}
                                    chartOptions={
                                        this.state.chartConfig.chartOptions
                                    }
                                    lastFetch={this.state.lastFetch}
                                />
                            </Fragment>
                        ) : null}
                    </CardBody>

                    <CardFooter className="p-0">
                        <p className="text-muted text-center">
                            {this.state.lastFetch ? (
                                <>
                                    As of{' '}
                                    {moment(this.state.lastFetch).format('LTS')}
                                </>
                            ) : (
                                'Never fetched'
                            )}
                        </p>
                    </CardFooter>
                </Card>
            </Fragment>
        )
    }
}

export default ReportViewer
