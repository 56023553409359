import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Input, Button} from 'reactstrap'
import autobind from 'auto-bind'
import {Scrollbars} from 'react-custom-scrollbars'
import {TRANSFORMS} from './transforms'
import {OUTPUTS} from './outputs'

const numericToAlpha = (num) => {
    const alpha = ['a', 'b', 'c', 'd', 'e']
    return alpha[num]
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }
    input() {
        const config = this.props.selectedNode._raw.entity.extras,
            node = this.props.nodes.find((a) => a.nodeId === config.id),
            device = this.props.devices.find((a) => a.deviceId === config.id)

        if (node) {
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <h4 className="text-center">
                                <strong>{this.props.selectedNode.name}</strong>
                            </h4>
                            <p
                                className="text-center"
                                style={{fontStyle: 'italic'}}>
                                SensorBot Input
                            </p>
                            {config.aliasedName && config.aliasedName !== '' ? (
                                <p>
                                    <b>SensorBot Input:</b> {config.aliasedName}
                                </p>
                            ) : null}
                            <p>
                                <b>SensorBot Node ID:</b>{' '}
                                <span style={{userSelect: 'all'}}>
                                    {node.nodeId}
                                </span>
                            </p>
                            <p>
                                <b>Device ID:</b>{' '}
                                <span style={{userSelect: 'all'}}>
                                    {node.deviceId}
                                </span>
                            </p>
                            <p>
                                <b>Device Name:</b>{' '}
                                <span style={{userSelect: 'all'}}>
                                    {this.props.devices.find(
                                        (a) => a.deviceId === node.deviceId
                                    )
                                        ? this.props.devices.find(
                                              (a) =>
                                                  a.deviceId === node.deviceId
                                          ).name
                                        : 'Input not associated with any asset!'}
                                </span>
                            </p>
                            <hr />
                            <p className="text-center">
                                Connect it to a Transform or Output node to
                                include it within this flow
                            </p>
                        </Col>
                    </Row>
                </Fragment>
            )
        } else if (device) {
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <h4 className="text-center">
                                <strong>{this.props.selectedNode.name}</strong>
                            </h4>
                            <p
                                className="text-center"
                                style={{fontStyle: 'italic'}}>
                                Asset Input
                            </p>
                            <p>
                                <b>Device ID:</b>{' '}
                                <span style={{userSelect: 'all'}}>
                                    {device.deviceId}
                                </span>
                            </p>
                            <p>
                                <b>Device Name:</b>{' '}
                                <span style={{userSelect: 'all'}}>
                                    {device.name}
                                </span>
                            </p>
                            <hr />
                            <p className="text-center">
                                Connect it to a Transform or Output node to
                                include it within this flow
                            </p>
                        </Col>
                    </Row>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <h4 className="text-center">
                                <strong>{this.props.selectedNode.name}</strong>
                            </h4>
                            <p
                                className="text-center"
                                style={{fontStyle: 'italic'}}>
                                Unknown Input
                            </p>
                            <p>
                                <b>
                                    Available Input ID:{' '}
                                    <span style={{userSelect: 'all'}}>
                                        {config.id}
                                    </span>
                                </b>
                            </p>
                            <hr />
                            <p className="text-center">
                                The source of this input cannot be found!
                            </p>
                            <p className="text-muted text-center">
                                This may be caused by by an invalid flow copy
                                and does not necessarily mean the inputs are
                                incorrect. Please save this flo & check for new
                                warnings.
                            </p>
                        </Col>
                    </Row>
                </Fragment>
            )
        }
    }

    transform() {
        const {name, params, description} = TRANSFORMS.find(
            (x) => x.name === this.props.selectedNode.name
        )

        let paramDom = params.length
            ? []
            : [
                  <Row key="1">
                      <Col>
                          <p style={{color: 'grey'}}>
                              This transformation does not require any
                              parameters
                          </p>
                      </Col>
                  </Row>,
              ]

        for (let i in params) {
            const alpha = numericToAlpha(i)

            let existingValue = this.props.selectedNode._raw.entity.extras
                .params[alpha]
                ? this.props.selectedNode._raw.entity.extras.params[alpha]
                : ''

            paramDom.push(
                <Row className="mb-2">
                    <Col>
                        <label>{params[i].name}</label>
                        <Input
                            name={this.props.selectedNode._raw.id + '_' + alpha}
                            type={params[i].type}
                            value={existingValue}
                            onChange={(e) => {
                                let v = e.target.value
                                if (!isNaN(v)) v = parseFloat(v)
                                let s = this.state
                                s[alpha] = v
                                this.setState({...s})
                                this.props.selectedNode._raw.entity.extras.params[
                                    alpha
                                ] = v
                            }}
                        />
                    </Col>
                </Row>
            )
        }

        return (
            <Fragment>
                <Row>
                    <Col className="text-center">
                        <h3 className="text-center">
                            <strong>{name}</strong>
                        </h3>
                        <Button
                            className="text-center btn btn-default btn-xs"
                            onClick={() => {
                                this.props.streamData(this.props.selectedNode)
                            }}>
                            Stream Data
                        </Button>
                        <hr />
                        <p className="text-center">{description}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            <strong>Parameters</strong>
                        </p>
                    </Col>
                </Row>
                {paramDom.map((p, i) => {
                    return <div key={i}>{p}</div>
                })}
            </Fragment>
        )
    }
    output() {
        const {name, description} = OUTPUTS.find(
            (x) => x.name === this.props.selectedNode.name
        )

        const existingValueName = this.props.selectedNode._raw.entity.extras
                .name
                ? this.props.selectedNode._raw.entity.extras.name
                : '',
            existingValueBuffer = this.props.selectedNode._raw.entity.extras
                .buffer
                ? this.props.selectedNode._raw.entity.extras.buffer
                : 0

        const showBufferInput = name !== 'Send Alert' ? 'block' : 'none',
            showAlertManagerButton = name === 'Send Alert' ? 'block' : 'none'

        let bufferDom = (
            <Fragment>
                <Row className="mb-2">
                    <Col>
                        <label>Output Name</label>
                        <Input
                            name="name"
                            type="text"
                            value={existingValueName}
                            onChange={(e) => {
                                this.setState({name: e.target.value})
                                this.props.selectedNode._raw.entity.extras.name =
                                    e.target.value
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2" style={{display: showBufferInput}}>
                    <Col>
                        <label>Desired Buffer Size</label>
                        <Input
                            name="buffer"
                            type="number"
                            value={existingValueBuffer}
                            onChange={(e) => {
                                this.setState({buffer: e.target.value})
                                this.props.selectedNode._raw.entity.extras.buffer =
                                    e.target.value
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2" style={{display: showAlertManagerButton}}>
                    <Col>
                        <Button size="xs" onClick={this.props.toggleAlertModal}>
                            Set Email/SMS
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )

        return (
            <Fragment>
                <Row>
                    <Col className="text-center">
                        <h3 className="text-center">
                            <strong>{name}</strong>
                        </h3>
                        <hr />
                        <p className="text-center">{description}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            <strong>Buffer</strong>
                        </p>
                        <p>
                            How many events to store before triggering the
                            webhook.
                        </p>
                    </Col>
                </Row>
                {bufferDom}
            </Fragment>
        )
    }
    helper() {
        if (this.props.selectedNode === null) return null
        if (this.props.selectedNode.type === 'input') return this.input()
        else if (this.props.selectedNode.type === 'transform')
            return this.transform()
        else if (this.props.selectedNode.type === 'output') return this.output()
        else return null
    }
    render() {
        const selectedNode = this.props.selectedNode,
            helper = this.helper()

        return (
            <Fragment>
                <Card style={{height: '100%'}}>
                    <CardBody>
                        <Scrollbars>
                            {selectedNode ? (
                                helper
                            ) : (
                                <h5
                                    style={{
                                        color: 'grey',
                                        textAlign: 'center',
                                    }}>
                                    Click on a node to get started
                                </h5>
                            )}
                        </Scrollbars>
                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
