import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Heatmap from 'heatmap.js'

import './Heatmap.css'

export default class ReactHeatmap extends Component {
    constructor(props) {
        super(props)
        this.setData = this.setData.bind(this)
    }

    componentDidMount() {
        this.heatmap = Heatmap.create({
            container: ReactDOM.findDOMNode(this),
        })
        this.setData(this.props.data, this.props.timelineData)
    }

    componentWillReceiveProps(newProps) {
        this.setData(newProps.data, newProps.timelineData)
    }

    setData(data, timelineData) {
        const {max, min, computed} = this.computeData(data, timelineData)
        this.heatmap.setData({
            max,
            min,
            data: computed,
        })
    }

    computeData(data, timelineData) {
        let aggregated = []

        if (this.props.date !== null) {
            let filtered = timelineData.filter((t) =>
                t.date.isSame(this.props.date)
            )
            for (let row of filtered) {
                let existing = aggregated.find((a) => a.poiId === row.from)
                if (existing) {
                    existing.value += row.value
                } else {
                    const poi = this.props.model.pois.find(
                            (p) => p.id === row.from
                        ),
                        width =
                            isNaN(poi.width) && poi.width.includes('px')
                                ? poi.width.split('px')[0]
                                : poi.width,
                        height =
                            isNaN(poi.height) && poi.width.includes('px')
                                ? poi.height.split('px')[0]
                                : poi.height,
                        x = Math.ceil(
                            poi.x * this.props.xScale +
                                (width * this.props.xScale) / 2
                        ),
                        y = Math.ceil(
                            poi.y * this.props.yScale +
                                (height * this.props.yScale) / 2
                        )

                    aggregated.push({
                        poiId: poi.id,
                        x,
                        y,
                        value: row.value,
                    })
                }
            }
        } else {
            for (let row of data) {
                let existing = aggregated.find((a) => a.poiId === row.from)
                if (existing) {
                    existing.value += row.count
                } else {
                    const poi = this.props.model.pois.find(
                            (p) => p.id === row.from
                        ),
                        width =
                            isNaN(poi.width) && poi.width.includes('px')
                                ? poi.width.split('px')[0]
                                : poi.width,
                        height =
                            isNaN(poi.height) && poi.width.includes('px')
                                ? poi.height.split('px')[0]
                                : poi.height,
                        x = Math.ceil(
                            poi.x * this.props.xScale +
                                (width * this.props.xScale) / 2
                        ),
                        y = Math.ceil(
                            poi.y * this.props.yScale +
                                (height * this.props.yScale) / 2
                        )

                    aggregated.push({
                        poiId: poi.id,
                        x,
                        y,
                        value: row.count,
                    })
                }
            }
        }
        console.log(
            Math.max(...aggregated.map((a) => a.value)),
            Math.min(...aggregated.map((a) => a.value))
        )

        return {
            max: Math.max(...aggregated.map((a) => a.value)),
            min: Math.min(...aggregated.map((a) => a.value)),
            computed: aggregated,
        }
    }

    render() {
        return (
            <div
                style={{
                    width: this.props.backgroundWidth || '100px',
                    height: this.props.backgroundHeight || '100px',
                }}
            />
        )
    }
}
