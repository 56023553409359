import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'
import {Rnd} from 'react-rnd'

export default class PointOfInterest extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            width: this.props.width,
            height: this.props.height,
            x: this.props.x,
            y: this.props.y,
        }
    }

    render() {
        const st = this.props.static === true ? false : true

        return (
            <Rnd
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: this.props.beingEdited
                        ? 'solid 3px rgb(182, 147, 41)'
                        : 'solid 3px rgb(42,147,213)',
                    background: this.props.beingEdited
                        ? 'rgb(182, 147, 41)'
                        : 'rgba(42,147,213, 0.4)',
                }}
                disableDragging={this.props.static}
                enableResizing={{
                    bottom: st,
                    bottomLeft: st,
                    bottomRight: st,
                    left: st,
                    right: st,
                    top: st,
                    topLeft: st,
                    topRight: st,
                }}
                size={{width: this.state.width, height: this.state.height}}
                position={{x: this.state.x, y: this.state.y}}
                bounds="parent"
                scale={this.props.scale}
                onDragStart={(e, d) => {
                    e.stopPropagation()
                }}
                onDragStop={(e, d) => {
                    this.props.updatePOI({
                        id: this.props.id,
                        name: this.props.name,
                        x: d.x,
                        y: d.y,
                        width: this.state.width,
                        height: this.state.height,
                    })
                    this.setState({x: d.x, y: d.y})
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    this.props.updatePOI({
                        id: this.props.id,
                        name: this.props.name,
                        x: position.x,
                        y: position.y,
                        width: ref.style.width,
                        height: ref.style.height,
                    })
                    this.setState({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    })
                }}
                onDoubleClick={() => {
                    this.props.toggleEditMode({
                        id: this.props.id,
                        name: this.props.name,
                    })
                }}>
                {this.props.name}
                {this.props.samples !== undefined
                    ? '  (' + this.props.samples + ' samples)'
                    : ''}
            </Rnd>
        )
    }
}
