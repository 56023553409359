import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Label,
    Button,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import IntlMessages from 'util/IntlMessages'
import Select from 'react-select'
import moment from 'moment'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            loading: true,
            reasoncodes: [],
            comment: '',
            selection: null,
        }
    }

    handleDowntimeCommentEntry(e) {
        this.setState({comment: e.target.value})
    }

    async submit() {
        const reasoncode = this.state.reasoncodes.find(
                (a) => a._id === this.state.selection.value
            ),
            downtime = this.props.record

        const previousReason = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: downtime.deviceId,
                    name: 'Downtime Reason',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousReason.length) {
            await API.remove('states/' + previousReason[0]._id, 2)
        }

        const previousCategory = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: downtime.deviceId,
                    name: 'Downtime Category',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousCategory.length) {
            await API.remove('states/' + previousCategory[0]._id, 2)
        }

        const previousComment = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: downtime.deviceId,
                    name: 'Downtime Comment',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousComment.length) {
            await API.remove('states/' + previousComment[0]._id, 2)
        }

        const previousUser = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: downtime.deviceId,
                    name: 'Downtime User',
                    timeStart: downtime.timeStart,
                    timeEnd: downtime.timeEnd,
                },
            },
            2
        )
        if (previousUser.length) {
            await API.remove('states/' + previousUser[0]._id, 2)
        }

        const category = {
            nodeId: downtime.deviceId,
            deviceId: downtime.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Category',
            value: reasoncode.category,
        }
        const reason = {
            nodeId: downtime.deviceId,
            deviceId: downtime.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Reason',
            value: reasoncode.reason,
        }
        const comment = {
            nodeId: downtime.deviceId,
            deviceId: downtime.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Comment',
            value: this.state.comment,
        }
        const user = {
            nodeId: downtime.deviceId,
            deviceId: downtime.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime User',
            value: JSON.parse(localStorage['userObject'])._id,
        }

        await API.post('states', category, 2)
        await API.post('states', reason, 2)
        await API.post('states', comment, 2)
        await API.post('states', user, 2)

        this.props.toggle()
        this.props.fetchData()
    }

    async fetchExisting() {
        let reason = null,
            category = null,
            comment = null,
            user = null

        reason = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.record.deviceId,
                    name: 'Downtime Reason',
                    timeStart: this.props.record.timeStart,
                    timeEnd: this.props.record.timeEnd,
                },
            },
            2
        )

        category = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.record.deviceId,
                    name: 'Downtime Category',
                    timeStart: this.props.record.timeStart,
                    timeEnd: this.props.record.timeEnd,
                },
            },
            2
        )

        comment = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.record.deviceId,
                    name: 'Downtime Comment',
                    timeStart: this.props.record.timeStart,
                    timeEnd: this.props.record.timeEnd,
                },
            },
            2
        )

        user = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.record.deviceId,
                    name: 'Downtime User',
                    timeStart: this.props.record.timeStart,
                    timeEnd: this.props.record.timeEnd,
                },
            },
            2
        )
        if (reason.length) {
            let reasoncode = this.state.reasoncodes.find(
                (a) => a.reason === reason[0].value
            )
            reasoncode = {label: reasoncode.reason, value: reasoncode.reason}
            this.setState({selection: reasoncode})
        }
        if (comment.length && comment[0].value) {
            this.setState({comment: comment[0].value})
        }
    }

    async componentDidMount() {
        this.setState({reasoncodes: await API.get('reasoncodes', 2)})
        await this.fetchExisting()
        this.setState({
            loading: false,
        })
    }

    render() {
        let options = []

        const timeEnd = this.props.record.timeEnd
            ? moment(this.props.record.timeEnd).format('lll')
            : 'Currently Active'

        if (this.state.reasoncodes) {
            let categories = [
                ...new Set(this.state.reasoncodes.map((r) => r.category)),
            ]
            categories = categories.sort((a, b) => a.localeCompare(b))

            options = categories.map((c) => {
                const suboptions = this.state.reasoncodes
                    .filter((r) => r.category === c)
                    .map((r) => {
                        return {
                            label: r.reason,
                            value: r._id,
                            reason: r.reason,
                            category: r.category,
                        }
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))

                return {
                    label: c,
                    options: suboptions,
                }
            })
        }
        return (
            <Fragment>
                {this.state.loading ? (
                    <div className="loading" />
                ) : (
                    <Modal isOpen={true} toggle={this.props.toggle}>
                        <ModalHeader toggle={this.props.toggle}>
                            Set downtime reason for: <br />
                            {moment(this.props.record.timeStart).format(
                                'lll'
                            )}{' '}
                            - {timeEnd}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="12">
                                    <Form className="hmi">
                                        <Label className="form-group has-top-label">
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="reason"
                                                value={this.state.selection}
                                                onChange={(selection) => {
                                                    this.setState({selection})
                                                }}
                                                options={options}
                                                styles={customStyles}
                                                isSearchable={false}
                                            />
                                            <IntlMessages id="Enter Downtime Reason" />
                                        </Label>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="textarea"
                                                name="commentEntry"
                                                rows={5}
                                                value={this.state.comment}
                                                onChange={
                                                    this
                                                        .handleDowntimeCommentEntry
                                                }
                                            />
                                            <IntlMessages
                                                id={'Optional Comment'}
                                            />
                                        </Label>
                                        <Button
                                            className="btn btn-primary btn-block"
                                            onClick={this.submit}>
                                            Submit
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </Fragment>
        )
    }
}
