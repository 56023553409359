import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'

export default class Route extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            from: [0, 0],
            to: [0, 0],
        }
    }

    findPoint(poi) {
        const {xScale, yScale} = this.props,
            width =
                (isNaN(poi.width) && poi.width.includes('px')
                    ? poi.width.split('px')[0]
                    : poi.width) * xScale,
            height =
                (isNaN(poi.height) && poi.height.includes('px')
                    ? poi.height.split('px')[0]
                    : poi.height) * yScale

        return [
            Math.ceil(poi.x * this.props.xScale + width / 2),
            Math.ceil(poi.y * this.props.yScale + height / 2),
        ]
    }

    updatePosition() {
        const fromPOI = this.props.model.pois.find(
                (p) => p.id === this.props.from
            ),
            toPOI = this.props.model.pois.find((p) => p.id === this.props.to)

        if (fromPOI && toPOI) {
            this.setState({
                from: this.findPoint(fromPOI),
                to: this.findPoint(toPOI),
            })
        }
    }

    componentWillMount() {
        setTimeout(this.updatePosition, 1000)
    }

    render() {
        const adjustedDensity = this.props.density / this.props.densityRange[1]

        return (
            <svg height={'100%'} width={'100%'} style={{position: 'absolute'}}>
                <line
                    className="dashed"
                    x1={this.state.from[0]}
                    y1={this.state.from[1]}
                    x2={this.state.to[0]}
                    y2={this.state.to[1]}
                    style={{
                        stroke: '#dc3545',
                        strokeWidth: 5 * adjustedDensity,
                        opacity: adjustedDensity,
                    }}
                />
            </svg>
        )
    }
}
