import React, {Component, Fragment} from 'react'
import 'react-table/react-table.css'
import {Row, Col, Card, CardBody, Button, Form, Label} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import {ThemeColors} from 'util/ThemeColors'
import regression from 'regression'
import Switch from 'rc-switch'
import ReactToPrint from 'react-to-print'

import OEETimeSeriesChart from './OEETimeSeriesChart'
import * as API from 'SDK/api'
import * as _ from 'lodash'

/* The colors obj is initialized in the component constructor to prevent a 
   potential race condition that causes the colors to be empty strings
*/
let colors = undefined

export default class OEEEarnedHours extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    requiredOutputs = []
    showBorder = false
    id = 'OEEEarnedHours'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        colors = ThemeColors()

        this.state = {
            start: moment().startOf('isoWeek').add(-1, 'isoWeeks'),
            end: moment().add(-1, 'isoWeeks').endOf('isoWeek'),
            assets: [],
            selectedAssets: [],
            shifts: [],
            shiftRecords: [],
            data: [],
            loading: false,
            ready: false,
            selectedAsset: {
                label: 'All Assets',
                value: 'all',
            },
            selectedShift: {
                label: 'All Shifts',
                value: 'all',
            },
            selectedTimeUnit: {label: 'Days', value: 'day'},
            selectedFilter: {label: 'Show all data', value: 'all'},
        }
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }
    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }

    handleShiftChange(selectedShift) {
        this.setState({selectedShift})
    }

    async fetchAssets() {
        const assets = await API.get('devices')
        if (!assets) return alert('Could not fetch assets')

        this.setState({
            assets,
            selectedAssets: assets.map((a, i) => {
                return {label: a.name, value: a.deviceId, key: i}
            }),
        })
    }

    async fetchShifts() {
        let shiftRecords = await API.get('shifts')
        if (!shiftRecords) return alert('Could not fetch shifts')

        let shifts = shiftRecords.filter(function ({name}) {
            return !this[name] && (this[name] = name)
        }, {})

        this.setState({shifts, shiftRecords})
    }

    async generate(filter = null) {
        this.setState({
            loading: true,
            selectedAsset: {
                label: 'All Assets',
                value: 'all',
            },
        })
        let promises = [],
            shifts =
                this.state.selectedShift.value === 'all'
                    ? this.state.shifts
                    : [
                          this.state.shifts.find(
                              (s) => s.name === this.state.selectedShift.value
                          ),
                      ],
            data = []
        if (filter === null) {
            for (let asset of this.state.selectedAssets) {
                let relevantShifts = shifts.filter((shift) =>
                    this.state.shiftRecords.find(
                        (a) =>
                            a.name === shift.name &&
                            a.assets.find((b) => b === asset.value)
                    )
                )
                for (let shift of relevantShifts) {
                    let range = [
                        moment(this.state.start),
                        moment(this.state.end),
                    ]

                    promises.push(
                        API.post(
                            'oee/earned-hours',
                            {
                                factoryId: JSON.parse(
                                    localStorage['userObject']
                                ).factoryId,
                                deviceId: asset.value,
                                timeStart: range[0].format('YYYY-MM-DD'),
                                timeEnd: range[1].format('YYYY-MM-DD'),
                                shift: shift.name,
                            },
                            2
                        )
                    )
                }
            }
            data = (await Promise.all(promises)).map((a) => a.data).flat()
            if (data.some((a) => a === undefined)) {
                this.setState({loading: false})
                alert(
                    'There was an error generating your report. Please refresh your page, try again and contact Accumine if issues persist'
                )
                return
            }
            data = data.map((a) => {
                a.date = moment(a.date.split('T')[0])
                a.date = a.date.startOf(this.state.selectedTimeUnit.value)

                return a
            })
        } else {
            data = this.state.data.filter((a) => a.device_id === filter)
        }

        if (this.state.selectedFilter.value === 'weekdays') {
            data = data.filter((a) => {
                return a.date.isoWeekday() !== 6 && a.date.isoWeekday() !== 7
            })
        }
        if (this.state.selectedFilter.value === 'weekends') {
            data = data.filter((a) => {
                return a.date.isoWeekday() === 6 || a.date.isoWeekday() === 7
            })
        }

        const goodAvailabilityCount = data.filter(
            (a) => a.availability !== null
        ).length
        const goodPerformanceCount = data.filter(
            (a) => a.performance !== null
        ).length
        const goodQualityCount = data.filter((a) => a.quality !== null).length
        const goodOeeCount = data.filter((a) => a.oee !== null).length

        let availability =
                goodAvailabilityCount > 0
                    ? data.reduce((a, b) => a + b.availability, 0) /
                      goodAvailabilityCount
                    : 0,
            performance =
                goodPerformanceCount > 0
                    ? data.reduce((a, b) => a + b.performance, 0) /
                      goodPerformanceCount
                    : 0,
            quality =
                goodQualityCount > 0
                    ? data.reduce((a, b) => a + b.quality, 0) / goodQualityCount
                    : 0,
            oee =
                goodOeeCount > 0
                    ? data.reduce((a, b) => a + b.oee, 0) / goodOeeCount
                    : 0

        this.setState({availability, performance, quality, oee})

        let timeSeriesData = []
        if (this.state.byShift) {
            timeSeriesData = data.reduce((a, b) => {
                ;(a[`${b.date}::${b.shift}`] =
                    a[`${b.date}::${b.shift}`] || []).push(b)
                return a
            }, {})
            for (let date in timeSeriesData) {
                const oee =
                    timeSeriesData[date].reduce((a, b) => a + b.oee, 0) /
                    timeSeriesData[date].filter((a) => a.oee !== null).length
                timeSeriesData[date] = {
                    date,
                    shift: timeSeriesData[date][0].shift,
                    oee: parseInt(oee),
                }
            }
        } else {
            timeSeriesData = data.reduce((a, b) => {
                ;(a[b.date] = a[b.date] || []).push(b)
                return a
            }, {})

            for (let date in timeSeriesData) {
                const goodValues = timeSeriesData[date].filter(
                    (a) => a.oee !== null && a.oee !== undefined
                )

                const oee =
                    goodValues.length > 0
                        ? timeSeriesData[date].reduce((a, b) => a + b.oee, 0) /
                          goodValues.length
                        : 0
                timeSeriesData[date] = {
                    date,
                    oee: parseInt(oee),
                }
            }
        }
        timeSeriesData = Object.values(timeSeriesData)
        timeSeriesData.sort((left, right) => {
            return moment(left.date).diff(moment(right.date))
        })
        let timeseriesdata = {
            labels: [
                ...new Set(
                    timeSeriesData.map((a) =>
                        moment(a.date.split('::')[0]).format('ll')
                    )
                ),
            ],
            datasets: [],
        }

        if (this.state.byShift) {
            let shifts = [...new Set(timeSeriesData.map((a) => a.shift))]
            for (let i in shifts) {
                const chosenColor = `themeColor${parseInt(i) + 1}`
                const foundColor = colors.hasOwn(chosenColor)
                    ? colors[chosenColor]
                    : colors.themeColor1
                timeseriesdata.datasets.push({
                    type: 'bar',
                    label: shifts[i],
                    backgroundColor: foundColor,
                    borderColor: '#145388',
                    data: timeSeriesData
                        .filter((a) => a.shift === shifts[i])
                        .map((a) => a.oee),
                    datalabels: {
                        color: foundColor,
                        display: 'true',
                    },
                })
            }
        } else {
            timeseriesdata.datasets.push({
                type: 'bar',
                label: 'OEE',
                backgroundColor: colors.themeColor1,
                borderColor: '#145388',
                data: timeSeriesData.map((a) => a.oee),
                datalabels: {
                    color: colors.themeColor1,
                    display: 'true',
                },
            })
        }

        let regressionInput = []
        for (let i in timeseriesdata.labels) {
            const datasetLength = timeseriesdata.datasets.length
            regressionInput[i] =
                datasetLength > 0
                    ? timeseriesdata.datasets.reduce(
                          (a, b) => a + b.data[i],
                          0
                      ) / datasetLength
                    : 0
        }

        const result = regression.linear(regressionInput.map((a, i) => [i, a]))

        let trendline = [],
            b = result.equation[1],
            m = result.equation[0]
        for (let i in timeseriesdata.labels) {
            trendline.push(m * i + b)
        }

        timeseriesdata.datasets = [
            {
                type: 'line',
                label: 'Trend',
                data: trendline,
                backgroundColor: colors.themeColor6,
                borderColor: colors.themeColor6,
                fill: false,
                datalabels: {
                    color: colors.themeColor6,
                    display: 'auto',
                },
            },
        ].concat(timeseriesdata.datasets)

        let stateObj = {
            loading: false,
            ready: true,
            timeseriesdata,
        }

        if (!filter) {
            stateObj.data = data
        }

        this.setState(stateObj)
    }

    handleAssetChange(selectedAsset) {
        this.generate(
            selectedAsset.value === 'all' ? null : selectedAsset.value
        )
        this.setState({selectedAsset})
    }

    async componentDidMount() {
        await this.fetchAssets()
        await this.fetchShifts()
    }

    render() {
        return (
            <Fragment>
                <div className="print" ref={(el) => (this.componentRef = el)}>
                    <Row className="mb-2">
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs="12" className="mb-4">
                                            <label>Assets:</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                isMulti
                                                name="assets"
                                                value={
                                                    this.state.selectedAssets
                                                }
                                                onChange={(selectedAssets) =>
                                                    this.setState({
                                                        selectedAssets,
                                                    })
                                                }
                                                options={this.state.assets.map(
                                                    (a, i) => {
                                                        return {
                                                            label: a.name,
                                                            value: a.deviceId,
                                                            key: i,
                                                        }
                                                    }
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="2">
                                            <label>From:</label>
                                            <DatePicker
                                                selected={this.state.start}
                                                selectsStart
                                                startDate={this.state.start}
                                                endDate={this.state.end}
                                                onChange={
                                                    this.handleStartChange
                                                }
                                                filterDate={(date) => {
                                                    return moment() > date
                                                }}
                                                disabled={this.state.loading}
                                            />
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label>To:</label>
                                            <DatePicker
                                                selected={this.state.end}
                                                selectsEnd
                                                startDate={this.state.start}
                                                endDate={this.state.end}
                                                onChange={this.handleEndChange}
                                                filterDate={(date) => {
                                                    return moment() > date
                                                }}
                                                disabled={this.state.loading}
                                            />
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label>Filter Data:</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="filter"
                                                value={
                                                    this.state.selectedFilter
                                                }
                                                onChange={(selectedFilter) =>
                                                    this.setState({
                                                        selectedFilter,
                                                    })
                                                }
                                                options={[
                                                    {
                                                        label: 'Show all data',
                                                        value: 'all',
                                                    },
                                                    {
                                                        label: 'Only weekdays',
                                                        value: 'weekdays',
                                                    },
                                                    {
                                                        label: 'Only weekends',
                                                        value: 'weekends',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label>Shift(s):</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="shift"
                                                value={this.state.selectedShift}
                                                onChange={
                                                    this.handleShiftChange
                                                }
                                                options={[
                                                    {
                                                        label: 'All Shifts',
                                                        value: 'all',
                                                    },
                                                ].concat(
                                                    this.state.shifts.map(
                                                        (a, i) => {
                                                            return {
                                                                label: a.name,
                                                                value: a.name,
                                                            }
                                                        }
                                                    )
                                                )}
                                            />
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label>Time Unit:</label>
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="timeUnit"
                                                value={
                                                    this.state.selectedTimeUnit
                                                }
                                                onChange={(selectedTimeUnit) =>
                                                    this.setState({
                                                        selectedTimeUnit,
                                                    })
                                                }
                                                options={[
                                                    {
                                                        label: 'Days',
                                                        value: 'day',
                                                    },
                                                    {
                                                        label: 'Weeks',
                                                        value: 'isoWeek',
                                                    },
                                                    {
                                                        label: 'Months',
                                                        value: 'month',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label>Group by shift:</label>
                                            <Switch
                                                className="custom-switch custom-switch-primary"
                                                checked={this.state.byShift}
                                                onChange={(byShift) => {
                                                    this.setState({byShift})
                                                }}
                                                disabled={this.state.loading}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col
                                            xs="12"
                                            sm="12"
                                            className="text-right">
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    this.generate(null)
                                                }
                                                disabled={this.state.loading}>
                                                <i className="iconsmind-Arrow-Refresh" />{' '}
                                                Generate
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {this.state.ready ? (
                        <Fragment>
                            <ReactToPrint
                                trigger={() => (
                                    <Button color="danger" href="#">
                                        Print/PDF
                                    </Button>
                                )}
                                content={() => this.componentRef}
                            />
                            <Row className="mt-2">
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <Form>
                                                        <Label className="form-group has-top-label">
                                                            <Select
                                                                components={{
                                                                    Input: CustomSelectInput,
                                                                }}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="asset-commission"
                                                                value={
                                                                    this.state
                                                                        .selectedAsset
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleAssetChange
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'All Assets',
                                                                        value: 'all',
                                                                    },
                                                                ].concat(
                                                                    this.state
                                                                        .selectedAssets
                                                                )}
                                                            />
                                                            <IntlMessages id="Asset Selection" />
                                                        </Label>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginTop: 20,
                                                    textAlign: 'center',
                                                }}>
                                                <Col xs="12">
                                                    <h1 className="m-0 text-muted">
                                                        OEE
                                                    </h1>
                                                    <hr className="m-0" />
                                                    <h1 className="m-0 mt-2">
                                                        <b>
                                                            {parseInt(
                                                                this.state.oee
                                                            )}
                                                            %
                                                        </b>
                                                    </h1>
                                                </Col>
                                                <Col xs="4">
                                                    <h2 className="m-0 text-muted">
                                                        Availability
                                                    </h2>
                                                    <hr className="m-0" />
                                                    <h2 className="m-0 mt-2">
                                                        <b>
                                                            {parseInt(
                                                                this.state
                                                                    .availability
                                                            )}
                                                            %
                                                        </b>
                                                    </h2>
                                                </Col>
                                                <Col xs="4">
                                                    <h2 className="m-0 text-muted">
                                                        Performance
                                                    </h2>
                                                    <hr className="m-0" />
                                                    <h2 className="m-0 mt-2">
                                                        <b>
                                                            {parseInt(
                                                                this.state
                                                                    .performance
                                                            )}
                                                            %
                                                        </b>
                                                    </h2>
                                                </Col>
                                                <Col xs="4">
                                                    <h2 className="m-0 text-muted">
                                                        Quality
                                                    </h2>
                                                    <hr className="m-0" />
                                                    <h2 className="m-0 mt-2">
                                                        <b>
                                                            {parseInt(
                                                                this.state
                                                                    .quality
                                                            )}
                                                            %
                                                        </b>
                                                    </h2>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card style={{marginTop: 15, marginBottom: 15}}>
                                <CardBody>
                                    <Row>
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '500px',
                                            }}>
                                            <OEETimeSeriesChart
                                                data={_.cloneDeep(
                                                    this.state.timeseriesdata
                                                )}
                                                elementWidth={this.componentRef}
                                            />
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Fragment>
                    ) : null}
                </div>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
