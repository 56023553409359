import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'
import ReasonCodePicker from './ReasonCodePicker'
import ShiftPicker from './ShiftPicker'
import StatePicker from './StatePicker'
import ScrapPicker from './ScrapPicker'
import ReactTable from 'react-table'
import DataTablePagination from 'components/DataTables/pagination'
import 'react-table/react-table.css'

import * as API from 'SDK/api'

export default class TableColumnGenerator extends Component {
    id = 'TableColumnGenerator'
    field = 'table'
    constructor(props) {
        super(props)
        autobind(this)

        let initialValues = props && props.existing ? props.existing : []

        this.state = {
            modal: false,
            dataTypes: [
                {
                    label: 'Reason Codes',
                    value: 'Reason Codes',
                },
                {
                    label: 'States',
                    value: 'States',
                },
                {
                    label: 'Scrap Codes',
                    value: 'Scrap Codes',
                },
            ],
            indexOptions: [
                {
                    label: 'Assets',
                    value: 'assets',
                },
                {
                    label: 'Shift',
                    value: 'shifts',
                },
                {
                    label: 'Cell',
                    value: 'cell',
                },
            ],
            unitOptions: [
                {
                    label: 'Count',
                    value: 'Count',
                },
                {
                    label: 'Duration',
                    value: 'Duration',
                },
                {
                    label: 'Values',
                    value: 'Values',
                },
                {
                    label: 'Property',
                    value: 'Property',
                },
            ],
            rangeOptions: [
                {
                    label: 'Day',
                    value: 'Day',
                },
                {
                    label: 'Week',
                    value: 'Week',
                },
            ],
            selectedDataType: null,
            selectedUnit: null,
            selectedIndex: initialValues.index ? initialValues.index : null,
            selectedRange: initialValues.range ? initialValues.range : 'Day',
            switchRender: null,
            columns: [],
            logic: [],
            tableColumns: [
                {
                    Header: 'columns',
                    accessor: 'columns',
                },
                {
                    Header: 'type',
                    accessor: 'type',
                },
                {
                    Header: 'data',
                    accessor: 'data',
                },
                {
                    Header: 'unit',
                    accessor: 'unit',
                },
                {
                    Header: 'property',
                    accessor: 'property',
                },
            ],
            tableData: [],
            logicColumns: [
                {
                    Header: 'definition',
                    accessor: 'definition',
                },
                {
                    Header: 'operation',
                    accessor: 'operation',
                    Cell: (cell) => {
                        let logic = [...this.state.logic]
                        return (
                            <div>
                                {cell.value}
                                <Button
                                    color="danger"
                                    style={{float: 'right'}}
                                    onClick={() => {
                                        logic.splice(cell.index, 1)
                                        this.setState({logic}, this.syncLogic)
                                    }}>
                                    Delete
                                </Button>
                            </div>
                        )
                    },
                },
            ],
            logicData: [],
            logicHelper: [],
            logicType: [],
        }

        if (this.props && this.props.cb) {
            this.props.cb('table', initialValues)
        }
    }

    componentDidMount() {
        this.setState(
            {
                columns: this.props.existing ? this.props.existing.columns : [],
                logic: this.props.existing ? this.props.existing.logic : [],
            },
            () => {
                this.addColumnsToTable()
                this.addLogicToTable()
            }
        )
    }

    toggle() {
        this.setState({modal: !this.state.modal})
    }

    submit() {
        if (this.props && this.props.cb) {
            this.props.cb('table', {
                index: this.state.selectedIndex,
                range: this.state.selectedRange,
                columns: this.state.columns,
            })
        }
    }

    switchDataType() {
        switch (this.state.selectedDataType.value) {
            case 'Reason Codes':
                this.setState({
                    switchRender: (
                        <ReasonCodePicker
                            cb={(field, data) => {
                                this.setState({data})
                            }}
                        />
                    ),
                })
                break
            case 'States':
                this.setState({
                    switchRender: (
                        <StatePicker
                            cb={(field, data) => {
                                this.setState({data})
                            }}
                        />
                    ),
                })
                break
            case 'Scrap Codes':
                this.setState({
                    switchRender: (
                        <ScrapPicker
                            cb={(field, data) => {
                                this.setState({data})
                            }}
                        />
                    ),
                })
                break
            case 'Shifts':
                this.setState({
                    switchRender: (
                        <ShiftPicker
                            cb={(field, data) => {
                                this.setState({data})
                            }}
                        />
                    ),
                })
                break
        }
    }

    addColumn(
        e,
        type = this.state.selectedDataType.value,
        data = this.state.data,
        selectedUnit = this.state.selectedUnit.value,
        columns = [...this.state.columns],
        propertyInput = this.state.propertyInput
    ) {
        columns.push({type, data, selectedUnit, property: propertyInput})
        this.setState({columns}, () => {
            this.addColumnsToTable(columns)
            this.toggle()
            if (this.props && this.props.cb) {
                this.props.cb('table', {
                    index: this.state.selectedIndex,
                    range: this.state.selectedRange,
                    columns: this.state.columns,
                    logic: this.state.logic,
                })
            }
        })
    }

    addColumnsToTable(columns = [...this.state.columns]) {
        let columnSet = []
        columns.forEach((col) => {
            col.data.forEach((d) => {
                columnSet.push({
                    columns: `Col${columnSet.length}`,
                    type: col.type,
                    data: d,
                    unit: col.selectedUnit,
                    property: col.property,
                })
            })
        })
        this.setState({
            tableData: columnSet,
        })
    }

    addLogicToTable(logic = [...this.state.logic]) {
        this.setState({
            logicData: logic.map((lo) => {
                return {
                    definition: lo.definition,
                    operation: lo.operation,
                    action: lo.action,
                }
            }),
        })
    }

    customLogicHandler(inputEvent) {
        this.setState({customLogicInputValue: inputEvent.target.value})
    }

    propertyHandler(inputEvent) {
        this.setState({propertyInput: inputEvent.target.value})
    }

    customLogicSave(
        event,
        logic = [...this.state.logic],
        logicType = this.state.selectedLogicType.value
    ) {
        logic.push({
            definition: this.state.customLogicInputValue,
            operation: logicType,
        })
        this.setState(
            {
                logic,
                customLogicInputValue: '',
                logicHelper: [],
            },
            () => {
                this.syncLogic(logic)
            }
        )
    }

    syncLogic(
        logic = [...this.state.logic],
        columns = [...this.state.columns]
    ) {
        if (this.props && this.props.cb) {
            this.props.cb('table', {
                index: this.state.selectedIndex,
                range: this.state.selectedRange,
                columns: this.state.columns,
                logic,
            })
        }
        this.addLogicToTable(logic)
        this.addColumnsToTable(columns)
    }

    logicHelperGenerator(logicType = '', logicHelper = '') {
        if (logicType && logicType === 'FORMULA') {
            logicHelper = (
                <Alert color="primary">Example: COL1 = COL1 + COL2</Alert>
            )
        } else if (logicType && logicType === 'TARGET') {
            logicHelper = <Alert color="primary">Example: COL1 > 100</Alert>
        }

        this.setState({
            logicHelper,
        })
    }

    render() {
        return (
            <Fragment>
                <label style={{marginTop: 15}}>Choose Table Index:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="chooseIndex"
                    value={this.state.selectedIndex}
                    onChange={(selectedIndex) => {
                        this.setState({selectedIndex})
                    }}
                    options={this.state.indexOptions}
                />
                <Fragment>
                    <label>Choose Range:</label>
                    <Select
                        components={{Input: CustomSelectInput}}
                        className="react-select"
                        classNamePrefix="react-select"
                        name="range"
                        value={this.state.selectedRange}
                        onChange={(selectedRange) => {
                            this.setState({selectedRange})
                        }}
                        options={this.state.rangeOptions}
                    />
                </Fragment>
                <Fragment>
                    <p style={{marginTop: 15}}>
                        <strong>Columns</strong>
                    </p>

                    <ReactTable
                        defaultPageSize={5}
                        data={this.state.tableData}
                        columns={this.state.tableColumns}
                        minRows={0}
                        PaginationComponent={DataTablePagination}
                        loading={false}
                        filterable={false}
                    />
                </Fragment>
                <Button onClick={this.toggle}>Add Column</Button>
                <Button
                    color="danger"
                    onClick={() => {
                        let columns = []
                        this.setState({columns}, this.syncLogic)
                    }}>
                    Clear Columns
                </Button>

                <hr />
                <Fragment>
                    <Row>
                        <Col md="12">
                            <p style={{marginTop: 15}}>
                                <strong>Custom Logic</strong>
                            </p>
                        </Col>
                        <Col md="4">
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="dataType"
                                value={this.state.selectedLogicType}
                                onChange={(selectedLogicType) => {
                                    this.setState({selectedLogicType}, () => {
                                        this.logicHelperGenerator(
                                            selectedLogicType.value
                                        )
                                    })
                                }}
                                options={[
                                    {value: 'FORMULA', label: 'FORMULA'},
                                    {value: 'TARGET', label: 'TARGET'},
                                ]}
                            />
                        </Col>
                        <Col md="8">
                            <Input
                                type="text"
                                onChange={this.customLogicHandler}
                                value={this.state.customLogicInputValue}
                            />
                        </Col>
                        <Col md="12">{this.state.logicHelper}</Col>
                    </Row>
                    <Button
                        style={{marginTop: 15, marginBottom: 15}}
                        onClick={this.customLogicSave}>
                        Add Custom Logic
                    </Button>
                    <ReactTable
                        defaultPageSize={5}
                        data={this.state.logicData}
                        columns={this.state.logicColumns}
                        minRows={0}
                        PaginationComponent={DataTablePagination}
                        loading={false}
                        filterable={false}
                    />
                </Fragment>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Add Columns</ModalHeader>
                    <ModalBody>
                        <label>Choose Data Type:</label>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="dataType"
                            value={this.state.selectedDataType}
                            onChange={(selectedDataType) => {
                                this.setState(
                                    {selectedDataType},
                                    this.switchDataType
                                )
                            }}
                            options={this.state.dataTypes}
                        />

                        {this.state.switchRender}

                        {this.state.switchRender ? (
                            <Fragment>
                                <label>Choose Data Unit:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="unit"
                                    value={this.state.selectedUnit}
                                    onChange={(selectedUnit) => {
                                        this.setState({selectedUnit})
                                    }}
                                    options={this.state.unitOptions}
                                />
                            </Fragment>
                        ) : (
                            []
                        )}

                        {this.state.selectedUnit &&
                        this.state.selectedUnit.value === 'Property' ? (
                            <Fragment>
                                <label>Specify Property:</label>
                                <Input
                                    type="text"
                                    onChange={this.propertyHandler}
                                    value={this.state.propertyInput}
                                />
                            </Fragment>
                        ) : (
                            []
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addColumn}>
                            Add
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
