import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'

import * as API from 'SDK/api'

const OPTIONS = [
    {
        label: 'Open',
        value: 'open',
    },
    {
        label: 'Close',
        value: 'close',
    },
    {
        label: 'Start',
        value: 'start',
    },
    {
        label: 'Stop',
        value: 'stop',
    },
    {
        label: 'Pause',
        value: 'pause',
    },
    {
        label: 'Reset',
        value: 'reset',
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let {settings} = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        this.state = {
            selectedAction:
                settings.globalAction !== ''
                    ? OPTIONS.find((x) => x.value === settings.globalAction)
                    : null,
        }
    }
    handleChange(e) {
        this.setState({
            selectedAction: e,
        })
    }
    async save() {
        try {
            let {settings} = this.props.nodes.find(
                (node) => node.nodeId === this.props.editingNode.nodeId
            )
            settings.globalAction = this.state.selectedAction.value
            const response = await API.patch(
                'nodes/' + this.props.editingNode.nodeId,
                {
                    settings: settings,
                },
                2
            )
            console.log(response)
            if (response.success) {
                this.props.toggleModal('actionsModal')
            } else {
                return alert(
                    'There was an error setting your global action. Please try again later'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error setting your global action. Please try again later'
            )
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {modal, toggleModal, assets} = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('actionsModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('actionsModal')
                        }}>
                        Global Actions
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Select action:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="global-action"
                                    value={this.state.selectedAction}
                                    onChange={this.handleChange}
                                    options={OPTIONS}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('actionsModal')
                            }}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
