import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Label,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import * as API from 'SDK/api'
import AddModal from 'SDK/ui/widgets/SimpleMaintenance/add'
import AssetPicker from 'SDK/ui/helpers/AssetPicker'
import Sortable from 'react-sortablejs'
import ReactToPrint from 'react-to-print'
import {CompactPicker} from 'react-color'

import ComponentTable from './tables/ComponentTable'

import './checklist.css'
import './printCSS.css'

const DEFAULT_COLOR = 'rgba(0,0,100,0.9)'
const DEFAULT_ELEMENT = {name: null, approved: null, reason: null}

var STATUS_DEFAULTS = [
    {id: 0, name: 'Opened', color: DEFAULT_COLOR},
    {id: 1, name: 'On Hold', color: DEFAULT_COLOR},
    {id: 2, name: 'In Progress', color: DEFAULT_COLOR},
    {id: 3, name: 'Complete', color: DEFAULT_COLOR},
]

var REPEAT_DEFAULTS = [
    {id: 0, name: 'Weekly'},
    {id: 1, name: 'Monthly'},
    {id: 2, name: 'Quarterly'},
    {id: 3, name: 'Yearly'},
    {id: 4, name: 'Daily'},
    {id: 5, name: 'No Repeat'},
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            name: this.props.editing.metaData.find((o) => o.name === 'name')
                .value,
            description: this.props.editing.metaData.find(
                (o) => o.name === 'description'
            ).value,
            color: this.props.editing.metaData.find((o) => o.name === 'color')
                .value,
            valid: false,
            message: false,
            checklists: this.props.checklists ? this.props.checklists : [],
            checklist: this.props.editing,
            order: this.props.editing.value,
            status: {name: '', color: 'rgba(0,0,100,0.9)'},
            dropdownTypeOpen: false,
            repeatType: this.props.editing.metaData.find(
                (o) => o.name === 'repeat'
            ).value,
            form: [],
            workflows: this.props.workflows,
            addModal: false,
            pageBreakCount: 25,
            itemHistoryModal: false,
            history: [],
        }
    }

    async fetchChecklistsComplete(checklist = this.state.checklist) {
        let history = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'ChecklistComplete',
                    parentId: this.state.checklist._id,
                },
            },
            2
        )

        try {
            this.setState({history})
        } catch (err) {
            console.error('Could not load history.', err)
        }
    }

    dropdownTypeToggle() {
        this.setState({dropdownTypeOpen: !this.state.dropdownTypeOpen})
    }

    async handleSort(order, sortable, evt) {
        this.setState({order})
    }

    async fill() {
        const timestamp = moment().toISOString()
        const data = {
            timestamp: timestamp,
            timeStart: timestamp,
            timeEnd: timestamp,
            name: 'ChecklistComplete',
            metaData: [
                {
                    name: 'setByUser',
                    value: JSON.parse(localStorage['userObject'])._id,
                },
                {
                    name: 'technician',
                    value: this.state.technician,
                },
            ],
            parentId: this.state.checklist._id,
            value: true,
            store: this.state.form,
        }

        await API.post('states', data, 2)

        this.props.onSave(this.state.checklist)
        this.props.toggleModal()
    }

    handleChangeComplete = (picker) => {
        this.setState({color: picker.hex})
    }

    addStatus = () => {
        let status = this.state.status
        let order = this.state.order
        order.push(status)
        this.setState({order, status})
        this.clearStatus()
    }

    clearStatus = () => {
        this.setState({status: {id: 0, name: '', color: DEFAULT_COLOR}})
    }

    removeItem = (itemId) => {
        let order = this.state.order
        let filtered = order.filter(function (value, index, arr) {
            return value.id != itemId
        })
        this.setState({order: filtered})
    }

    toggleItemHistory(item) {
        this.setState({
            itemHistoryModal: !this.state.itemHistoryModal,
            itemHistoryObject: item,
        })
    }

    renderItem(item, count) {
        let checkboxes = this.state.checkboxes
        let form = this.state.form
        !form[item.id] ? (form[item.id] = {...DEFAULT_ELEMENT}) : null
        form[item.id].name = item.name
        return (
            <div
                style={{float: 'none', marginTop: 10}}
                className={
                    count != 0 &&
                    count % this.state.pageBreakCount === 0 &&
                    'page-break'
                }>
                <Row>
                    <Col xs="4">
                        <span
                            style={{
                                lineHeight: '28px',
                                verticalAlign: 'middle',
                                color: 'blue',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                this.toggleItemHistory(item)
                            }}>
                            {item.name}
                        </span>
                    </Col>
                    <Col xs="2">
                        <div
                            key={'approved' + item.id}
                            data-id={'approved' + item.id}
                            className="container"
                            style={{marginBottom: 25}}>
                            <div className="round-check">
                                <input
                                    type="checkbox"
                                    id={'approved' + item.id}
                                    checked={form[item.id].approved === true}
                                    onChange={() => {
                                        form[item.id].approved = true
                                        this.setState({form})
                                    }}
                                />
                                <Label for={'approved' + item.id}></Label>
                            </div>
                        </div>
                    </Col>
                    <Col xs="2">
                        <div
                            key={item.id + 'x'}
                            data-id={item.id + 'x'}
                            className="container"
                            style={{marginBottom: 25}}>
                            <div className="round-x">
                                <input
                                    type="checkbox"
                                    id={item.id + 'x'}
                                    checked={form[item.id].approved === false}
                                    onChange={() => {
                                        form[item.id].approved = false
                                        this.setState({form})
                                    }}
                                />
                                <Label for={item.id + 'x'}></Label>
                            </div>
                        </div>
                    </Col>
                    <Col xs="4">
                        <Input
                            type="text"
                            name={'reason-' + item.id}
                            value={this.state.form[item.id].descriptionRepair}
                            onChange={(text) => {
                                form[item.id].descriptionRepair =
                                    text.target.value
                                this.setState({form})
                            }}
                        />
                    </Col>
                </Row>
                {this.state.form[item.id].approved === false ? (
                    <Row
                        style={{
                            marginTop: 10,
                            marginBottom: 20,
                            borderRadius: 10,
                            backgroundColor: '#EEEEEE',
                            border: '1px #DDDDDD solid',
                            padding: 10,
                        }}>
                        <Col>{this.renderReason(item)}</Col>
                    </Row>
                ) : null}
            </div>
            /* <div key={item.id} data-id={item.id} style={{backgroundColor: item.color, color: '#FFFFFF', padding: 15, marginTop: 7.5}}>
        {item.name}
        <span color="none" style={{float: 'right', height: '100%', cursor: 'pointer'}} onClick={()=>{this.removeItem(item.id)}}>
          <i className="simple-icon-trash" style={{color: '#FFFFFF'}} />
        </span>
      </div> */
        )
    }

    renderReason(item) {
        let reason = this.state.form[item.id].reason
        let form = this.state.form
        return (
            <Fragment>
                <Row>
                    <Col xs="6">
                        <Label for={'reason-' + item.id}>Schedule Repair</Label>
                        <Input
                            type="text"
                            name={'reason-' + item.id}
                            value={this.state.form[item.id].scheduleRepair}
                            onChange={(text) => {
                                form[item.id].scheduleRepair = text.target.value
                                this.setState({form})
                            }}
                        />
                    </Col>
                    <Col xs="6">
                        <Label for={'reason-' + item.id}>
                            Description of Repair
                        </Label>
                        <Input
                            type="text"
                            name={'reason-' + item.id}
                            value={this.state.form[item.id].descriptionRepair}
                            onChange={(text) => {
                                form[item.id].descriptionRepair =
                                    text.target.value
                                this.setState({form})
                            }}
                        />
                    </Col>
                    <Col xs="12" style={{textAlign: 'center', marginTop: 25}}>
                        <Button
                            onClick={() => {
                                this.setState({addModal: true})
                            }}>
                            Create Ticket
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    async componentWillMount() {
        this.fetchChecklistsComplete()
    }

    render() {
        const {modal, toggleModal, editing, style} = this.props
        const {checklist, name, description} = this.state
        const formDate = moment().format('dddd, MMMM Do YYYY, h:mm:ss a')

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    size="lg"
                    toggle={toggleModal}
                    ref={(el) => (this.componentRef = el)}>
                    <ModalHeader toggle={toggleModal}>
                        <Row>
                            <Col>
                                <small>
                                    <u>Preventative Maintenance Form</u>
                                </small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong>{name}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small>{description}</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small style={{fontSize: '6pt'}}>
                                    {formDate}
                                </small>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <label>Technician</label>
                                <small> (Filling out this form)</small>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.technician}
                                    onChange={(field) => {
                                        this.setState({
                                            technician: field.target.value,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 15, marginTop: 15}}>
                            <Col xs="4">
                                <strong>Condition (Click for History)</strong>
                            </Col>
                            <Col xs="2">
                                <strong>Pass</strong>
                            </Col>
                            <Col xs="2">
                                <strong>Fail</strong>
                            </Col>
                            <Col xs="4">
                                <strong>Description of Repair</strong>
                            </Col>
                        </Row>
                        <div className="print-container">
                            {this.state.order.map((item, i) =>
                                this.renderItem(item, i)
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <span>Page Break:</span>
                        <Input
                            type="number"
                            name="pageBreakCount"
                            value={this.state.pageBreakCount}
                            onChange={(text) => {
                                this.setState({
                                    pageBreakCount: text.target.value,
                                })
                            }}
                        />
                        <ReactToPrint
                            trigger={() => (
                                <Button color="danger" href="#">
                                    Print this out!
                                </Button>
                            )}
                            content={() => this.componentRef}
                        />
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={this.fill}>
                            Submit Checklist
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.addModal ? (
                    <AddModal
                        toggleModal={() => {
                            this.setState({addModal: !this.state.addModal})
                        }}
                        modal={this.state.addModal}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                    />
                ) : null}

                <Modal
                    isOpen={this.state.itemHistoryModal}
                    size="lg"
                    toggle={this.toggleItemHistory}>
                    <ModalHeader toggle={this.toggleItemHistory}>
                        <Row>
                            <Col>
                                <small>
                                    <u>Item History Modal</u>
                                </small>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <label>History of actions:</label>
                            </Col>
                            <Col xs="12">
                                <ComponentTable
                                    history={this.state.history}
                                    item={this.state.itemHistoryObject}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={() => {
                                this.setState({addModal: true})
                            }}>
                            Create Ticket
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
