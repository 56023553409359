import React, {Component, Fragment} from 'react'
import {Col, Card, CardBody, CardTitle} from 'reactstrap'

export default class extends Component {
    constructor(props) {
        super(props)
        this.handleClickEvent = this.handleClickEvent.bind(this)
    }
    handleClickEvent() {
        this.props.history.push(this.props.module.path)
        //  const userObj = JSON.parse(localStorage['userObject']);
        //window.open(this.props.module.path + '?token=' + userObj.token,'_black');
    }
    render() {
        return (
            <Fragment>
                <Col
                    xs="12"
                    sm="4"
                    style={{cursor: 'pointer'}}
                    onClick={this.handleClickEvent}>
                    <Card>
                        <CardBody>
                            <CardTitle style={{textAlign: 'center'}}>
                                {this.props.env.name}
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}
