import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ListGroup,
} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={this.props.hidePOIModal}
                    size="sm"
                    backdrop={false}>
                    <ModalHeader toggle={this.props.hidePOIModal}>
                        {this.props.name}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.data.length ? (
                            <ListGroup>{this.props.data}</ListGroup>
                        ) : (
                            <p>No assets at this location.</p>
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
