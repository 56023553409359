import React from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import {FormGroup, Label, Input} from 'reactstrap'

import IntlMessages from 'util/IntlMessages'

const COLORS = {
    valid: 'rgb(215,215,215)',
    invalid: '#dc3545',
}

class TextInput extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            borderColor: COLORS.valid,
            value: this.props.initial || '',
            message: '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.initial !== this.props.initial && this.props.initial) {
            this.setState({value: this.props.initial})
        }
    }

    componentDidMount() {
        if (this.props.initial) {
            this.props.onValueChange(this.props.initial)
            this.props.onValidChange(true)
        }
    }

    render() {
        return (
            <FormGroup>
                <Label
                    className="form-group has-top-label"
                    style={{marginBottom: '5px'}}>
                    <Input
                        style={{borderColor: this.state.borderColor}}
                        type="text"
                        disabled={this.props.disabled}
                        name={Math.random()}
                        value={this.state.value}
                        onChange={(event) => {
                            let value = event.target.value,
                                borderColor = COLORS.valid,
                                message = ''

                            if (
                                this.props.validator &&
                                !this.props.validator(value)
                            ) {
                                borderColor = COLORS.invalid
                                message = 'Invalid value provided'
                                this.props.onValidChange(false)
                            } else if (value === '') {
                                borderColor = COLORS.invalid
                                message = 'This field cannot be empty'
                                this.props.onValueChange(value)
                                this.props.onValidChange(false)
                            } else if (
                                this.props.existing.find((e) => e === value)
                            ) {
                                borderColor = COLORS.invalid
                                message =
                                    'An element with this field already exists'
                                this.props.onValueChange(value)
                                this.props.onValidChange(false)
                            } else {
                                this.props.onValueChange(value)
                                this.props.onValidChange(true)
                            }
                            this.setState({value, borderColor, message})
                        }}
                    />
                    <IntlMessages id={this.props.fieldName} />
                </Label>
                {this.state.message.length ? (
                    <p style={{color: COLORS.invalid}}>
                        Error: {this.state.message}
                    </p>
                ) : null}
            </FormGroup>
        )
    }
}

TextInput.propTypes = {
    onValueChange: PropTypes.func,
    onValidChange: PropTypes.func,
    fieldName: PropTypes.string,
    existing: PropTypes.array,
}

export default TextInput
