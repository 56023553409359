import React, {Component, Fragment} from 'react'
import IntlMessages from 'util/IntlMessages'
import {Row, Card, CardTitle, Button} from 'reactstrap'
import {NavLink} from 'react-router-dom'
import {Colxx} from 'components/CustomBootstrap'
import ConnectLogo from '../../assets/img/L2L_ConnectLogo_greenblack.svg'

class Error404 extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        document.body.classList.add('background')
    }
    componentWillUnmount() {
        document.body.classList.remove('background')
    }
    render() {
        return (
            <Fragment>
                <div className="fixed-background" />
                <main>
                    <div className="container">
                        <Row className="h-100">
                            <Colxx xxs="12" md="8" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side ">
                                        <p className="text-white h2"></p>
                                        <p className="white"></p>
                                    </div>
                                    <div className="form-side text-center">
                                        <NavLink to={`/`} className="white">
                                            <img
                                                className="logo-single"
                                                src={ConnectLogo}
                                                alt="L2L Connect Logo"
                                            />
                                        </NavLink>
                                        <CardTitle className="mb-4">
                                            <IntlMessages id="layouts.error-title" />
                                        </CardTitle>
                                        <p className="mb-0 text-muted text-small mb-0">
                                            <IntlMessages id="layouts.error-code" />
                                        </p>
                                        <p className="display-1 font-weight-bold mb-5">
                                            404
                                        </p>
                                        <Button
                                            href="/app"
                                            color="primary"
                                            className="login-btn btn-shadow"
                                            size="lg">
                                            <IntlMessages id="layouts.go-back-home" />
                                        </Button>
                                    </div>
                                </Card>
                            </Colxx>
                        </Row>
                    </div>
                </main>
            </Fragment>
        )
    }
}
export default Error404
