import React from 'react'
import {Button} from 'reactstrap'
import {inputs, transforms, outputs} from './elements'

export default (props) => {
    const onDragStart = (event, input) => {
        event.dataTransfer.setData(
            'application/reactflow',
            JSON.stringify(input)
        )
        event.dataTransfer.effectAllowed = 'move'
    }

    return (
        <aside>
            <>
                {!props.inputSelected ? (
                    <>
                        <div className="description text-center">
                            Choose an input.
                        </div>
                        {inputs.map((node, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="dndnode input"
                                    onDragStart={(event) =>
                                        onDragStart(event, {
                                            nodeType: 'input',
                                            opts: node,
                                        })
                                    }
                                    draggable>
                                    {node.label}
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <>
                        <div className="description text-center">
                            Choose logic & output.
                        </div>
                        {transforms.map((node, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="dndnode"
                                    onDragStart={(event) =>
                                        onDragStart(event, {
                                            nodeType: 'default',
                                            opts: node,
                                        })
                                    }
                                    draggable>
                                    {node.label}
                                </div>
                            )
                        })}

                        <hr />

                        {outputs.map((node, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="dndnode output"
                                    onDragStart={(event) =>
                                        onDragStart(event, {
                                            nodeType: 'output',
                                            opts: node,
                                        })
                                    }
                                    draggable>
                                    {node.label}
                                </div>
                            )
                        })}
                    </>
                )}

                <Button
                    style={{
                        position: 'absolute',
                        bottom: 0,
                    }}
                    onClick={props.openVariablesModal}
                    size="xs">
                    Show Variables
                </Button>
            </>
        </aside>
    )
}
