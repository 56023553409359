import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    ListGroup,
    ListGroupItem,
    Alert,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import IntlMessages from 'util/IntlMessages'
import {Scrollbars} from 'react-custom-scrollbars'
import classnames from 'classnames'
import {CSVLink, CSVDownload} from 'react-csv'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {stringToDateRange} from 'SDK/helpers'
import Historian from './historian'
import ChartMaker from './chartMaker'
import Table from './table'
import FilterModal from './filterModal'
import SortModal from './sortModal'
import TemplateManager from './templateManager'
import {
    DATE_RANGE_OPTIONS,
    TIME_UNIT_OPTIONS,
    METRIC_OPTIONS,
    VISUALIZATION_OPTIONS,
} from './selectOptions'
import {isNull} from 'lodash-es'

export default class InsightEngine extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'InsightEngine'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        isCore: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        let search = window.location.search
        let params = new URLSearchParams(search)

        this.environmentId = params.get('environmentId')
        this.sectionId = params.get('sectionId')
        this.viewId = params.get('viewId')
        this.visualId = params.get('visualId')

        this.editingVisualForDashboard =
            this.environmentId &&
            this.sectionId &&
            this.viewId &&
            this.visualId &&
            this.visualId !== 'new'

        this.state = {
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            flows: [],
            devices: {},
            selectedDaterange: null, // DATE_RANGE_OPTIONS[0],
            showDatePicker: false,
            selectedModel: null,
            loading: false,
            selectedShowBy: null,
            selectedGroupBy: null,
            groupByOptions: [],
            showByOptions: [],
            attributes: [],
            selectedTimeUnit: TIME_UNIT_OPTIONS[1],
            selectedMetric: null, //METRIC_OPTIONS[0],
            selectedVisualization: null, // VISUALIZATION_OPTIONS[0],
            data: [],
            columns: [],
            showFilterModal: false,
            showSortAssetModal: false,
            shifts: [],
            ready: false,
            filter: {},
            libraryObject: {},
            activeTab: '1',
            library: [],
            lowerLimit: null,
            upperLimit: null,
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    handleVisualizationChange(selectedVisualization) {
        this.setState({selectedVisualization})
    }

    handleDaterangeSelection(e) {
        if (e.value === 'custom') {
            this.setState({
                selectedDaterange: e,
                showDatePicker: true,
            })
        } else {
            this.setState({
                selectedDaterange: e,
                showDatePicker: false,
            })
        }
    }

    toggleFilterModal() {
        this.setState({
            showFilterModal: !this.state.showFilterModal,
        })
    }

    toggleSortAssetModal(assets) {
        this.setState({
            showSortAssetModal: !this.state.showSortAssetModal,
        })
        if (assets && assets.constructor === Array) {
            let filter = this.state.filter
            filter.assets = assets.map((x) => x.deviceId)
            this.setState({filter})
            this.generate()
        }
    }

    handleMetricChange(selectedMetric) {
        this.setState({selectedMetric})
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }

    handleEndChange(date) {
        this.setState({
            end: date,
        })
    }

    handleTimeUnitChange(selectedTimeUnit) {
        this.setState({selectedTimeUnit})
    }

    handleShowByChange(selectedShowBy) {
        const groupByOptions = this.state.attributes.filter(
            (x) => x.label !== selectedShowBy.label && x.label !== 'Time'
        )

        let showByOptions = this.state.attributes.filter(
            (x) => x.label !== 'None'
        )
        if (this.state.selectedGroupBy)
            showByOptions = this.state.showByOptions.filter(
                (x) => x.label !== this.state.selectedGroupBy.label
            )

        this.setState({selectedShowBy, groupByOptions, showByOptions})
    }

    handleGroupByChange(selectedGroupBy) {
        const showByOptions = this.state.attributes.filter(
            (x) => x.label !== selectedGroupBy.label && x.label !== 'None'
        )

        let groupByOptions = this.state.attributes.filter(
            (x) => x.label !== 'Time'
        )
        if (this.state.selectedShowBy)
            groupByOptions = this.state.groupByOptions.filter(
                (x) => x.label !== this.state.selectedShowBy.label
            )

        this.setState({selectedGroupBy, groupByOptions, showByOptions})
    }

    async handleModelChange(selectedModel) {
        this.setState({
            loading: true,
            selectedModel: selectedModel,
            selectedShowBy: null,
            selectedGroupBy: null,
        })
        await this.fetchAttributes(selectedModel.label)
    }

    async fetchDevices() {
        this.setState({loading: true})

        const records = await API.get('devices')

        if (!records) {
            alert('Cannot fetch list of assets!')
            return
        }

        this.setState({
            loading: false,
            devices: records,
        })
    }

    async fetchFlows() {
        this.setState({loading: true})

        let states = await API.get('definitions', 1)

        let manualstates = await API.get('manualstates', 2)

        states = [...new Set(states.map((x) => x.name))]

        manualstates = [...new Set(manualstates.map((x) => x.name))]

        const records = await API.get('flows', 2)
        if (!records) {
            alert('Cannot fetch list of outputs!')
            return
        }

        let outputs = []

        for (let i in records) {
            outputs = outputs.concat(
                records[i].template
                    .filter((n) => n.transform === '$output')
                    .map((n) => n.params.a)
                    .sort()
            )
        }

        outputs = [...new Set(outputs)]

        outputs = outputs.concat(states)

        outputs = outputs.concat(manualstates)

        outputs = outputs.map((x) => {
            return {
                label: x,
                value: x,
            }
        })
        outputs.push({
            label: 'Cycle Time (Minutes)',
            value: 'cycle_time_minutes',
        })

        this.setState({
            flows: outputs,
            // selectedModel: null,
            loading: false,
        })
    }

    async fetchLibrary() {
        const library = await API.get('library/templates', 2)
        if (library) this.setState({library})
        else alert('Cannot fetch library')
    }

    async fetchAttributes(name) {
        let states = await API.get('definitions', 1)

        states = [...new Set(states.map((x) => x.name))]

        const records = await API.get('flows', 2)
        if (!records) {
            alert('Cannot fetch list of outputs!')
            return
        }

        let outputs = []

        for (let i in records) {
            outputs = outputs.concat(
                records[i].template
                    .filter((n) => n.transform === '$output')
                    .map((n) => n.params.a)
                    .sort()
            )
        }

        outputs = outputs.concat(states)

        outputs = [...new Set(outputs)]

        outputs = outputs.filter((o) => o !== name)

        outputs = outputs.map((x) => {
            return {
                label: x,
                value: x,
            }
        })

        outputs.push({
            label: 'Shift',
            value: 'Shift',
        })

        outputs.push({
            label: 'Asset',
            value: 'Asset',
        })

        outputs.push({
            label: 'Time',
            value: 'Time',
        })

        outputs.push({
            label: 'None',
            value: 'None',
        })

        this.setState({
            loading: false,
            showByOptions: outputs.filter((x) => x.label !== 'None'),
            groupByOptions: outputs,
            attributes: outputs,
        })
    }

    async generate(_id) {
        this.setState({loading: true, ready: false})

        if (!this.state.selectedModel) {
            this.setState({loading: false})
            return alert('No output selected')
        } else if (!this.state.selectedMetric) {
            this.setState({loading: false})
            return alert('No metric selected')
        } else if (!this.state.selectedShowBy) {
            this.setState({loading: false})
            return alert('No "Show By" value selected')
        } else if (!this.state.selectedGroupBy) {
            this.setState({loading: false})
            return alert('No "Group By" value selected')
        } else if (!this.state.selectedDaterange) {
            this.setState({loading: false})
            return alert('No date range selected')
        } else if (!this.state.selectedVisualization) {
            this.setState({loading: false})
            return alert('No visualization selected')
        }

        let timeStart, timeEnd
        if (this.state.selectedDaterange.value === 'custom') {
            timeStart = this.state.start
            timeEnd = this.state.end
        } else {
            const range = stringToDateRange(this.state.selectedDaterange.value)
            timeStart = range[0]
            timeEnd = range[1]
        }

        let data = [],
            columns = []

        if (this.state.selectedModel.value === 'cycle_time_minutes') {
            const totalCycleTimeHistorian = new Historian({
                daterange: this.state.selectedDaterange.value,
                devices: this.state.devices,
                timeStart: timeStart,
                timeEnd: timeEnd,
                name: 'In-Cycle',
                groupBy: this.state.selectedGroupBy.value,
                showBy: this.state.selectedShowBy.value,
                timeUnit: this.state.selectedTimeUnit.value,
                metric: 'totalDuration',
                filter: this.state.filter,
            })
            let totalCycleTimeResult = await totalCycleTimeHistorian.run()

            const partCountHistorian = new Historian({
                daterange: this.state.selectedDaterange.value,
                devices: this.state.devices,
                timeStart: timeStart,
                timeEnd: timeEnd,
                name: 'Part Count',
                groupBy: this.state.selectedGroupBy.value,
                showBy: this.state.selectedShowBy.value,
                timeUnit: this.state.selectedTimeUnit.value,
                metric: 'count',
                filter: this.state.filter,
            })
            let partCountResult = await partCountHistorian.run()
            for (let row of totalCycleTimeResult.data) {
                const id = row['key'] === undefined ? 'date' : 'key'
                for (let key in row) {
                    if (key !== 'key' && key !== 'date') {
                        const parts = partCountResult.data.find(
                            (a) => a[id] === row[id]
                        )
                        row[key] =
                            Math.round((row[key] / parts[key]) * 100) / 100
                    }
                }
            }
            data = totalCycleTimeResult.data
            columns = totalCycleTimeResult.columns
        } else {
            const historian = new Historian({
                daterange: this.state.selectedDaterange.value,
                devices: this.state.devices,
                timeStart: timeStart,
                timeEnd: timeEnd,
                name: this.state.selectedModel.label,
                groupBy: this.state.selectedGroupBy.value,
                showBy: this.state.selectedShowBy.value,
                timeUnit: this.state.selectedTimeUnit.value,
                metric: this.state.selectedMetric.value,
                filter: this.state.filter,
            })
            let result = await historian.run()
            data = result.data
            columns = result.columns
        }

        const vis = this.state.selectedVisualization.value
        let libraryObject = {
            timeStart: timeStart,
            timeEnd: timeEnd,
            output: this.state.selectedModel.label,
            showBy: this.state.selectedShowBy.value,
            groupBy: this.state.selectedGroupBy.value,
            timeUnit: this.state.selectedTimeUnit.value,
            metric: this.state.selectedMetric.value,
            visualization: vis,
            live: this.state.selectedDaterange.value,
            filter: this.state.filter,
        }

        if (this.state.lowerLimit) {
            libraryObject.lowerLimit = this.state.lowerLimit
        }
        if (this.state.upperLimit) {
            libraryObject.upperLimit = this.state.upperLimit
        }

        if (_id && _id.constructor === String) libraryObject._id = _id

        let output = null
        if (vis === 'table') {
            output = <Table data={data} columns={columns} />
        } else {
            let percentage =
                this.state.selectedMetric.value === 'totalDurationPercent'
            output = (
                <ChartMaker
                    percentage={percentage}
                    data={data}
                    type={vis}
                    timeUnit={
                        this.state.selectedShowBy.value === 'Time'
                            ? this.state.selectedTimeUnit.value
                            : false
                    }
                    lowerLimit={this.state.lowerLimit}
                    upperLimit={this.state.upperLimit}
                />
            )
        }

        this.setState({
            loading: false,
            data: data,
            columns: columns,
            ready: true,
            output: output,
            libraryObject: libraryObject,
        })
    }

    async fetchShifts() {
        const shifts = await API.get('shifts')
        this.setState({shifts})
    }

    filter(filter) {
        if (Object.keys(filter).length) {
            this.setState({filter})
        } else {
            this.setState({filter: {}})
        }
    }

    async loadComponent(component) {
        this.setState({loading: true})

        await this.fetchAttributes(component.output)

        this.setState(
            {
                selectedTemplateId: component._id || component.id,
                selectedModel: {
                    label: component.output,
                    value: component.output,
                },
                selectedMetric: METRIC_OPTIONS.find(
                    (x) => x.value === component.metric
                ),
                selectedDaterange: DATE_RANGE_OPTIONS.find(
                    (x) => x.value === component.timerange.live
                ),
                selectedTimeUnit: TIME_UNIT_OPTIONS.find(
                    (x) => x.value === component.timeUnit
                ),
                start: moment(component.timeStart),
                end: moment(component.timeEnd),
                selectedVisualization: VISUALIZATION_OPTIONS.find(
                    (x) => x.value === component.visualization
                ),
                selectedShowBy: this.state.attributes.find(
                    (x) => x.value === component.showBy
                ),
                selectedGroupBy: this.state.attributes.find(
                    (x) => x.value === component.groupBy
                ),
                activeTab: '1',
                filter: component.filter || {},
                lowerLimit: isNaN(component.lowerLimit)
                    ? null
                    : component.lowerLimit,
                upperLimit: isNaN(component.upperLimit)
                    ? null
                    : component.upperLimit,
            },
            () => setTimeout(() => this.generate(component._id), 5000)
        )
    }

    reset() {
        this.setState({
            selectedTemplateId: null,
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            selectedDaterange: null, // DATE_RANGE_OPTIONS[0],
            showDatePicker: false,
            selectedModel: null,
            loading: false,
            selectedShowBy: null,
            selectedGroupBy: null,
            groupByOptions: [],
            showByOptions: [],
            attributes: [],
            selectedTimeUnit: TIME_UNIT_OPTIONS[1],
            selectedMetric: null, //METRIC_OPTIONS[0],
            selectedVisualization: null, // VISUALIZATION_OPTIONS[0],
            data: [],
            columns: [],
            showFilterModal: false,
            shifts: [],
            ready: false,
            filter: {},
            libraryObject: {},
            activeTab: '1',
            lowerLimit: null,
            upperLimit: null,
        })
    }

    getComponentConfiguration() {
        if (!this.state.selectedModel) {
            this.setState({loading: false})
            return alert('No output selected')
        } else if (!this.state.selectedMetric) {
            this.setState({loading: false})
            return alert('No metric selected')
        } else if (!this.state.selectedShowBy) {
            this.setState({loading: false})
            return alert('No "Show By" value selected')
        } else if (!this.state.selectedGroupBy) {
            this.setState({loading: false})
            return alert('No "Group By" value selected')
        } else if (!this.state.selectedDaterange) {
            this.setState({loading: false})
            return alert('No date range selected')
        } else if (!this.state.selectedVisualization) {
            this.setState({loading: false})
            return alert('No visualization selected')
        }

        let timeStart, timeEnd
        if (this.state.selectedDaterange.value === 'custom') {
            timeStart = this.state.start
            timeEnd = this.state.end
        } else {
            const range = stringToDateRange(this.state.selectedDaterange.value)
            timeStart = range[0]
            timeEnd = range[1]
        }

        let libraryObject = {
            timeStart: timeStart,
            timeEnd: timeEnd,
            output: this.state.selectedModel.label,
            showBy: this.state.selectedShowBy.value,
            groupBy: this.state.selectedGroupBy.value,
            timeUnit: this.state.selectedTimeUnit.value,
            metric: this.state.selectedMetric.value,
            visualization: this.state.selectedVisualization.value,
            live: this.state.selectedDaterange.value,
            _id: this.state.selectedTemplateId,
            lowerLimit: isNaN(this.state.lowerLimit)
                ? null
                : this.state.lowerLimit,
            upperLimit: isNaN(this.state.upperLimit)
                ? null
                : this.state.upperLimit,
        }

        this.setState({libraryObject})
    }

    async componentWillMount() {
        this.fetchShifts()
        this.fetchFlows()
        this.fetchDevices()
        this.fetchLibrary()

        this.interval = setInterval(this.fetchLibrary, 5000)

        if (this.editingVisualForDashboard) {
            this.loadComponent(JSON.parse(localStorage[this.visualId]))
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.83 + 'px',
            showTimeUnitSelect =
                this.state.selectedShowBy &&
                this.state.selectedShowBy.label === 'Time',
            timeUnitOptions =
                this.state.selectedShowBy &&
                this.state.selectedGroupBy &&
                this.state.selectedShowBy.value === 'Time' &&
                this.state.selectedGroupBy.value === 'Shift'
                    ? TIME_UNIT_OPTIONS.filter((x) => x.value !== 'hours')
                    : TIME_UNIT_OPTIONS,
            metricOptions =
                this.state.selectedModel &&
                this.state.selectedModel.value === 'cycle_time_minutes'
                    ? [{label: 'Average Raw Value', value: 'average'}]
                    : METRIC_OPTIONS

        return (
            <Fragment>
                <Row>
                    <Col>
                        <Alert color="primary">
                            The Insight Engine uses the Data Flow Output called
                            "Part Count" for all assets, regardless of the
                            production unit set. Use the chart title
                            functionality to provide additional details to
                            users.
                        </Alert>
                    </Col>
                </Row>
                <Row style={{marginTop: '0', marginLeft: '-50px'}}>
                    <Col
                        xs="12"
                        sm="4"
                        style={{height: height, paddingRight: 0}}>
                        <Card style={{height: '100%'}}>
                            <CardBody>
                                <Scrollbars>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        this.state.activeTab ===
                                                        '1',
                                                })}
                                                onClick={() => {
                                                    this.toggle('1')
                                                }}>
                                                Builder
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        this.state.activeTab ===
                                                        '2',
                                                })}
                                                onClick={() => {
                                                    this.toggle('2')
                                                }}>
                                                Templates
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent
                                        activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <Row className="mt-2">
                                                <Col xs="12">
                                                    <Label
                                                        className="form-group has-top-label"
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}>
                                                        <Select
                                                            components={{
                                                                Input: CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="output"
                                                            value={
                                                                this.state
                                                                    .selectedModel
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleModelChange
                                                            }
                                                            options={
                                                                this.state.flows
                                                            }
                                                        />{' '}
                                                        <IntlMessages id="Output" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <Label
                                                        className="form-group has-top-label"
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}>
                                                        <Select
                                                            components={{
                                                                Input: CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="metric"
                                                            value={
                                                                this.state
                                                                    .selectedMetric
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleMetricChange
                                                            }
                                                            options={
                                                                metricOptions
                                                            }
                                                        />{' '}
                                                        <IntlMessages id="Metric" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <Label
                                                        className="form-group has-top-label"
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}>
                                                        <Select
                                                            components={{
                                                                Input: CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="showby"
                                                            value={
                                                                this.state
                                                                    .selectedShowBy
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleShowByChange
                                                            }
                                                            options={
                                                                this.state
                                                                    .showByOptions
                                                            }
                                                        />{' '}
                                                        <IntlMessages id="Show By" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            {showTimeUnitSelect ? (
                                                <Row>
                                                    <Col xs="12">
                                                        <Label
                                                            className="form-group has-top-label"
                                                            style={{
                                                                marginBottom:
                                                                    '5px',
                                                            }}>
                                                            <Select
                                                                components={{
                                                                    Input: CustomSelectInput,
                                                                }}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="timeUnit"
                                                                value={
                                                                    this.state
                                                                        .selectedTimeUnit
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleTimeUnitChange
                                                                }
                                                                options={
                                                                    timeUnitOptions
                                                                }
                                                            />{' '}
                                                            <IntlMessages id="Time Unit" />
                                                        </Label>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            <Row>
                                                <Col xs="12">
                                                    <Label
                                                        className="form-group has-top-label"
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}>
                                                        <Select
                                                            components={{
                                                                Input: CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="output"
                                                            value={
                                                                this.state
                                                                    .selectedGroupBy
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleGroupByChange
                                                            }
                                                            options={
                                                                this.state
                                                                    .groupByOptions
                                                            }
                                                        />{' '}
                                                        <IntlMessages id="Group By" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <Label
                                                        className="form-group has-top-label"
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}>
                                                        <Select
                                                            components={{
                                                                Input: CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="definedDateRange"
                                                            value={
                                                                this.state
                                                                    .selectedDaterange
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleDaterangeSelection
                                                            }
                                                            options={
                                                                DATE_RANGE_OPTIONS
                                                            }
                                                        />{' '}
                                                        <IntlMessages id="Date Range" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            {this.state.showDatePicker ? (
                                                <Row>
                                                    <Col xs="6">
                                                        <Label
                                                            className="form-group has-top-label"
                                                            style={{
                                                                marginBottom:
                                                                    '5px',
                                                            }}>
                                                            <DatePicker
                                                                selected={
                                                                    this.state
                                                                        .start
                                                                }
                                                                selectsStart
                                                                startDate={
                                                                    this.state
                                                                        .start
                                                                }
                                                                endDate={
                                                                    this.state
                                                                        .end
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleStartChange
                                                                }
                                                                filterDate={(
                                                                    date
                                                                ) => {
                                                                    return (
                                                                        moment() >
                                                                        date
                                                                    )
                                                                }}
                                                                disabled={
                                                                    this.state
                                                                        .loading
                                                                }
                                                            />
                                                            <IntlMessages id="From" />
                                                        </Label>
                                                    </Col>
                                                    <Col xs="6">
                                                        <Label
                                                            className="form-group has-top-label"
                                                            style={{
                                                                marginBottom:
                                                                    '5px',
                                                            }}>
                                                            <DatePicker
                                                                selected={
                                                                    this.state
                                                                        .end
                                                                }
                                                                selectsEnd
                                                                startDate={
                                                                    this.state
                                                                        .start
                                                                }
                                                                endDate={
                                                                    this.state
                                                                        .end
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleEndChange
                                                                }
                                                                filterDate={(
                                                                    date
                                                                ) => {
                                                                    return (
                                                                        moment() >
                                                                        date
                                                                    )
                                                                }}
                                                                disabled={
                                                                    this.state
                                                                        .loading
                                                                }
                                                            />
                                                            <IntlMessages id="To" />
                                                        </Label>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            <Row>
                                                <Col xs="12">
                                                    <Label
                                                        className="form-group has-top-label"
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}>
                                                        <Select
                                                            components={{
                                                                Input: CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="visualization"
                                                            value={
                                                                this.state
                                                                    .selectedVisualization
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleVisualizationChange
                                                            }
                                                            options={
                                                                VISUALIZATION_OPTIONS
                                                            }
                                                        />{' '}
                                                        <IntlMessages id="Visualization" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            {this.state.selectedVisualization &&
                                            (this.state.selectedVisualization
                                                .value === 'line' ||
                                                this.state.selectedVisualization
                                                    .value === 'bar') ? (
                                                <Row>
                                                    <Col xs="12">
                                                        <p>
                                                            Leave below values
                                                            empty if lower/upper
                                                            control lines not
                                                            needed
                                                        </p>
                                                    </Col>
                                                    <Col xs="6">
                                                        <Label
                                                            className="form-group has-top-label"
                                                            style={{
                                                                marginBottom:
                                                                    '5px',
                                                            }}>
                                                            <Input
                                                                name="lowerLimit"
                                                                type="number"
                                                                value={
                                                                    this.state
                                                                        .lowerLimit
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.target
                                                                            .value
                                                                    ) {
                                                                        this.setState(
                                                                            {
                                                                                lowerLimit:
                                                                                    parseFloat(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    ),
                                                                            }
                                                                        )
                                                                    }
                                                                }}
                                                            />{' '}
                                                            <IntlMessages id="Lower Limit Line" />
                                                        </Label>
                                                    </Col>
                                                    <Col xs="6">
                                                        <Label
                                                            className="form-group has-top-label"
                                                            style={{
                                                                marginBottom:
                                                                    '5px',
                                                            }}>
                                                            <Input
                                                                name="upperLimit"
                                                                type="number"
                                                                value={
                                                                    this.state
                                                                        .upperLimit
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.target
                                                                            .value
                                                                    ) {
                                                                        this.setState(
                                                                            {
                                                                                upperLimit:
                                                                                    parseFloat(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    ),
                                                                            }
                                                                        )
                                                                    }
                                                                }}
                                                            />{' '}
                                                            <IntlMessages id="Upper Limit Line" />
                                                        </Label>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            <Row>
                                                <Col xs="6">
                                                    <Button
                                                        color="secondary"
                                                        className="btn-block"
                                                        size="sm"
                                                        onClick={
                                                            this
                                                                .toggleFilterModal
                                                        }
                                                        disabled={
                                                            this.state.loading
                                                        }>
                                                        Filter
                                                    </Button>
                                                </Col>
                                                <Col xs="6">
                                                    <Button
                                                        color="primary"
                                                        className="btn-block"
                                                        size="sm"
                                                        onClick={this.generate}
                                                        disabled={
                                                            this.state.loading
                                                        }>
                                                        Preview
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent
                                        activeTab={this.state.activeTab}>
                                        <TabPane tabId="2">
                                            <ListGroup>
                                                {this.state.library.map(
                                                    (x, i) => {
                                                        return (
                                                            <ListGroupItem
                                                                key={x._id}
                                                                onClick={() => {
                                                                    this.loadComponent(
                                                                        x
                                                                    )
                                                                }}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}>
                                                                {x.name}
                                                            </ListGroupItem>
                                                        )
                                                    }
                                                )}
                                            </ListGroup>
                                        </TabPane>
                                    </TabContent>
                                </Scrollbars>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col
                        xs="12"
                        sm="8"
                        style={{height: height, paddingRight: 0}}>
                        <Row>
                            <Col>
                                {this.state.ready ? (
                                    <TemplateManager
                                        libraryObject={this.state.libraryObject}
                                        library={this.state.library}
                                        reset={this.reset}
                                        toggle={this.toggle}
                                        getComponentConfiguration={
                                            this.getComponentConfiguration
                                        }
                                        history={this.props.history}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                        <Row style={{height: '93%'}}>
                            <Col>
                                {this.state.ready ? (
                                    <Card style={{height: '100%'}}>
                                        <CardHeader className="text-right">
                                            <Button
                                                size="xs"
                                                onClick={
                                                    this.toggleSortAssetModal
                                                }
                                                outline>
                                                Sort Assets
                                            </Button>
                                        </CardHeader>
                                        <CardBody>{this.state.output}</CardBody>
                                    </Card>
                                ) : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.showFilterModal ? (
                    <FilterModal
                        toggleModal={this.toggleFilterModal}
                        attributes={this.state.attributes}
                        devices={this.state.devices}
                        shifts={this.state.shifts}
                        name={this.state.selectedModel.value}
                        filter={this.filter}
                        values={this.state.filter}
                    />
                ) : null}
                {this.state.showSortAssetModal ? (
                    <SortModal
                        toggleModal={this.toggleSortAssetModal}
                        libraryObject={this.state.libraryObject}
                    />
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
