import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'

import TextInput from 'SDK/ui/widgets/Schemas/common/text-input'

const AddFlowModal = (props) => {
    const [name, setName] = useState(props.flow.name)
    const [description, setDescription] = useState(props.flow.description)

    return (
        <Modal isOpen={true} toggle={props.cancel}>
            <ModalHeader toggle={props.cancel}>Save Flow</ModalHeader>
            <ModalBody>
                <TextInput
                    onValueChange={setName}
                    onValidChange={() => {}}
                    fieldName="Flow Name"
                    existing={props.flows.map((flow) => flow.name)}
                    initial={name}
                    disabled={false}
                />
                <TextInput
                    onValueChange={setDescription}
                    onValidChange={() => {}}
                    fieldName="Flow Description"
                    existing={[]}
                    initial={description}
                    disabled={false}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="default"
                    onClick={() => props.submit(name, description, false)}>
                    Save
                </Button>
                <Button
                    color="default"
                    onClick={() => {
                        props.submit(name, description, false)
                        props.setDebugMode(true)
                    }}>
                    Save & Start Debugger
                </Button>
                <Button onClick={() => props.submit(name, description, true)}>
                    Save & Exit Builder
                </Button>
            </ModalFooter>
        </Modal>
    )
}

AddFlowModal.propTypes = {
    flows: PropTypes.array.isRequired,
    flow: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
}

export default AddFlowModal
