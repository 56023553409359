import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
} from 'reactstrap'
import autoBind from 'auto-bind'

import * as API from 'SDK/api'
import ModelTree from 'SDK/ui/widgets/Schemas/common/ModelTree'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
        this.kanbanSettingsId = this.props.dataModels.find(
            (model) => model.name === 'System/Kanban Settings'
        )._id

        this.state = {
            ready: false,
            status_field_path: '',
            description_field_path: '',
            title_field_path: '',
            fieldTypes: [],
        }
    }

    async save() {
        this.setState({loading: true})

        if (this.existing) {
            let updated = {...this.existing}
            updated.title_field_path = this.state.title_field_path
            updated.description_field_path = this.state.description_field_path
            updated.status_field_path = this.state.status_field_path
            await API.patch(
                `data-models/${this.kanbanSettingsId}/edit-record`,
                updated,
                2
            )
        } else {
            await API.post(
                `data-models/${this.kanbanSettingsId}/add-record`,
                {
                    title_field_path: this.state.title_field_path,
                    status_field_path: this.state.status_field_path,
                    description_field_path: this.state.description_field_path,
                    model_id: this.props.editingDataModel._id,
                },
                2
            )
        }
        this.setState({loading: false})
        this.props.toggle()
    }

    async componentDidMount() {
        let fieldTypes = await API.get('data-models/field-types', 2),
            status_field_path = '',
            description_field_path = '',
            title_field_path = ''
        const existing = await API.post(
            `data-models/${this.kanbanSettingsId}/paginate`,
            {
                filter: [
                    {
                        type: 'Text',
                        path: 'model_id',
                        value: [this.props.editingDataModel._id],
                        logic: 'is',
                    },
                ],
                sort: {},
                limit: 1,
            },
            2
        )

        if (existing.count) {
            title_field_path = existing.result.results[0].title_field_path
            status_field_path = existing.result.results[0].status_field_path
            description_field_path =
                existing.result.results[0].description_field_path
            this.existing = existing.result.results[0]
        }

        this.setState({
            fieldTypes,
            title_field_path,
            status_field_path,
            description_field_path,
            ready: true,
        })
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal isOpen={open} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Kanban Settings</ModalHeader>
                    <ModalBody>
                        {this.state.ready ? (
                            <>
                                <Row>
                                    <Col>
                                        <p>
                                            Status field:{' '}
                                            <b>
                                                {this.state.status_field_path ||
                                                    'None selected'}
                                            </b>
                                        </p>
                                        <ModelTree
                                            showMap={(item) =>
                                                item.type === 'PickList'
                                            }
                                            onSelect={(
                                                selectedKeys,
                                                {selectedNodes}
                                            ) => {
                                                this.setState({
                                                    status_field_path:
                                                        selectedNodes[0].path,
                                                })
                                            }}
                                            dataModel={
                                                this.props.editingDataModel
                                            }
                                            dataModels={this.props.dataModels}
                                            includeJoins={false}
                                            excludeJoinPrefixes={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            Title to display on cards:{' '}
                                            <b>
                                                {this.state.title_field_path ||
                                                    'None selected'}
                                            </b>
                                        </p>
                                        <ModelTree
                                            showMap={(item) =>
                                                ['SingleSubModel', 'ForeignID']
                                                    .concat(
                                                        this.state.fieldTypes
                                                            .filter(
                                                                (t) =>
                                                                    t.groupable
                                                            )
                                                            .map((t) => t.type)
                                                    )
                                                    .includes(item.type)
                                            }
                                            onSelect={(
                                                selectedKeys,
                                                {selectedNodes}
                                            ) => {
                                                this.setState({
                                                    title_field_path:
                                                        selectedNodes[0].path,
                                                })
                                            }}
                                            dataModel={
                                                this.props.editingDataModel
                                            }
                                            dataModels={this.props.dataModels}
                                            includeJoins={false}
                                            excludeJoinPrefixes={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            Description to display on cards:{' '}
                                            <b>
                                                {this.state
                                                    .description_field_path ||
                                                    'None selected'}
                                            </b>
                                        </p>
                                        <ModelTree
                                            showMap={(item) =>
                                                ['SingleSubModel', 'ForeignID']
                                                    .concat(
                                                        this.state.fieldTypes
                                                            .filter(
                                                                (t) =>
                                                                    t.groupable
                                                            )
                                                            .map((t) => t.type)
                                                    )
                                                    .includes(item.type)
                                            }
                                            onSelect={(
                                                selectedKeys,
                                                {selectedNodes}
                                            ) => {
                                                this.setState({
                                                    description_field_path:
                                                        selectedNodes[0].path,
                                                })
                                            }}
                                            dataModel={
                                                this.props.editingDataModel
                                            }
                                            dataModels={this.props.dataModels}
                                            includeJoins={false}
                                            excludeJoinPrefixes={true}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <div className="loading" />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
