import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            nameInputBorderColor: 'rgb(215,215,215)',
            usernameInputBorderColor: 'rgb(215,215,215)',
            passwordBorderColor: 'rgb(215,215,215)',
            username: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            role: null,
            roles: [],
            valid: true,
            message: '',
        }
    }

    handleRoleEvent(role) {
        this.setState({role})
    }

    async save() {
        if (this.validate()) {
            const obj = {
                username: this.state.username,
                password: this.state.password,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                role: this.state.role.value,
            }

            const response = await API.post('users', obj, 2)
            if (response) {
                const users = await API.get('users?all=true', 2)
                this.props.postSaveHook(
                    users.find((u) => u.username === this.state.username)
                )
                this.props.toggleModal()
            } else {
                return alert(
                    'There was an error adding this user. Please try again.'
                )
            }
        }
    }

    validate() {
        const {firstName, lastName, password, confirmPassword, role, username} =
            this.state

        if (firstName === '' || lastName === '') {
            this.setState({
                nameInputBorderColor: '#dc3545',
                valid: false,
                message: 'Please enter first and last name.',
            })
            return
        } else {
            this.setState({
                nameInputBorderColor: 'rgb(215,215,215)',
            })
        }

        if (username === '') {
            this.setState({
                usernameInputBorderColor: '#dc3545',
                valid: false,
                message: 'Please enter a username.',
            })
            return
        } else {
            this.setState({
                usernameInputBorderColor: 'rgb(215,215,215)',
            })
        }

        if (password === '') {
            this.setState({
                passwordInputBorderColor: '#dc3545',
                valid: false,
                message: 'Please enter a valid password.',
            })
            return
        } else {
            this.setState({
                passwordInputBorderColor: 'rgb(215,215,215)',
            })
        }

        // check if user with same name already exists
        if (
            this.props.users.find(
                (user) =>
                    user.firstName === firstName && user.lastName === lastName
            )
        ) {
            this.setState({
                nameInputBorderColor: '#dc3545',
                valid: false,
                message:
                    'A user with the same first and last name already exists.',
            })
            return
        }

        // check if user with same username already exists
        if (this.props.users.find((user) => user.username === username)) {
            this.setState({
                usernameInputBorderColor: '#dc3545',
                valid: false,
                message: 'A user with the same username already exists.',
            })
            return
        }

        // check if passwords match
        if (password !== confirmPassword) {
            this.setState({
                passwordInputBorderColor: '#dc3545',
                valid: false,
                message: 'Password and password confirmation do not match',
            })
            return
        }
        this.setState({
            nameInputBorderColor: 'rgb(215,215,215)',
            usernameInputBorderColor: 'rgb(215,215,215)',
            passwordBorderColor: 'rgb(215,215,215)',
            valid: true,
        })
        return true
    }

    async componentDidMount() {
        let roles = [...new Set(this.props.users.map((u) => u.role))]
        const envs = await API.get('environments', 2)

        let discoveredRoles = []
        envs.forEach((env) => {
            env.sections.forEach((section) => {
                section.views.forEach((view) => {
                    if (view.permissions)
                        discoveredRoles = discoveredRoles.concat(
                            view.permissions
                        )
                })
            })
        })

        roles = [...new Set(discoveredRoles.concat(roles))].map((r) => {
            return {label: r, value: r}
        })
        this.setState({roles})
    }

    render() {
        const {modal, toggleModal, users} = this.props

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add User</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>First Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={(firstName) => {
                                        this.setState({
                                            firstName: firstName.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Last Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.nameInputBorderColor,
                                    }}
                                    type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={(lastName) => {
                                        this.setState({
                                            lastName: lastName.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Username:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.usernameInputBorderColor,
                                    }}
                                    type="text"
                                    name="username"
                                    value={this.state.username}
                                    onChange={(username) => {
                                        this.setState({
                                            username: username.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Role:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="role"
                                    value={this.state.role}
                                    onChange={this.handleRoleEvent}
                                    options={this.state.roles}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Password:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.passwordInputBorderColor,
                                    }}
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={(password) => {
                                        this.setState({
                                            password: password.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs="12" sm="6" className="mb-4">
                                <label>Confirm Password:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.passwordInputBorderColor,
                                    }}
                                    type="password"
                                    name="password-confirm"
                                    value={this.state.confirmPassword}
                                    onChange={(password) => {
                                        this.setState({
                                            confirmPassword:
                                                password.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Add User
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
