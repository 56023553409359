import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'

import ChartEditor from './ChartEditor'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card style={{height: this.props.height}}>
                            <CardBody>
                                <ChartEditor {...this.props} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
