import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormText,
    Alert,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'
import IntlMessages from 'util/IntlMessages'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {CSVDownload} from 'react-csv'

import AssetCommission from './asset'
import RefVoltage from './ref-voltage'
import DataSamplingModal from './data-sampling'
import GatewayCommission from './gwy'
import ImportModal from './import'

import * as API from 'SDK/api'

const dict = {
    open: 'Open Connection',
    close: 'Close Connection',
    start: 'Start Data Stream',
    stop: 'Stop Data Stream',
    reset: 'Restart SensorBot',
    pause: 'Pause Data Stream',
}

const COLUMNS = [
    'Tag Group',
    'Read Frequency (Seconds)',
    'PLC Read Group Count',
    'Asset',
    'Tag Name',
    'Tag Alias',
    'Tag Type',
    'Event_OnChange',
    'Numerical_Calculation',
    'Numerical_SampleNumber',
    'Numerical_Threshold',
    'Bitfield_List',
    'Modbus_32_Bit',
    's7_type',
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.settings = this.props.editingNode.settings

        this.state = {
            valid: false,
            message: false,
            confirmDelete: false,
            loading: false,
            tagManagerModalOpen: false,
        }
    }

    toggleImportModal() {
        this.setState({importModalOpen: !this.state.importModalOpen})
    }

    toggleExportModal() {
        this.setState({exportModalOpen: !this.state.exportModalOpen})
    }

    askToConfirmDelete() {
        this.setState({confirmDelete: true})
    }
    revertDelete() {
        this.setState({confirmDelete: false})
    }
    async delete() {
        this.setState({loading: true})
        try {
            let response = await API.remove(
                'nodes/' + this.props.editingNode.nodeId,
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error deleting this SensorBot.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error deleting this SensorBot.')
        }
    }

    revertChanges() {
        alert('Empty fn')
    }

    async handleAction(action) {
        this.setState({loading: true})
        try {
            let node = {...this.props.editingNode}
            node.settings.action = action

            const response = await API.patch(
                'nodes/' + node.nodeId,
                {
                    settings: node.settings,
                },
                2
            )
            this.setState({loading: false})
            if (!response.success) {
                return alert('There was an error triggering the action.')
            } else {
                const x = (
                    <Fragment>
                        <p style={{marginBottom: '0px'}}>
                            <strong>{dict[action]}</strong> has been triggered.
                        </p>
                    </Fragment>
                )
                this.setState({actionMessage: x})
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error triggering the action.')
        }
    }

    closeModalAfterAction() {
        this.setState({loading: false})
        this.props.toggleModal()
    }

    renderDeleteButton() {
        if (!this.state.confirmDelete) {
            return (
                <Button color="danger" onClick={this.askToConfirmDelete}>
                    Delete SensorBot
                </Button>
            )
        } else {
            return null
        }
    }

    renderDeleteConfirmation() {
        if (this.state.confirmDelete) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Are you sure?</p>
                    <Button size="sm" color="danger" onClick={this.delete}>
                        Yes
                    </Button>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.revertDelete}>
                        No
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    exportCSV() {
        let tagGroups = this.props.editingNode.settings.tagGroups,
            assets = this.props.assets

        let tags = [COLUMNS]

        for (let group of tagGroups) {
            for (let tag of group.tags) {
                let asset = assets.find((a) => a.deviceId === tag.deviceId)
                if (asset) {
                    tags.push([
                        group.name,
                        group.readRateSeconds,
                        group.group,
                        asset.name,
                        tag.tag,
                        tag.alias,
                        tag.type,
                        tag.operation.onChange || '',
                        tag.operation.calculation || '',
                        tag.operation.sampleNumber || '',
                        tag.operation.threshold || '',
                        tag.operation.tags || '',
                        tag.operation.modbus32Bit || false,
                        tag.operation.s7_type || '',
                    ])
                }
            }
        }

        this.setState({
            download: true,
            exportData: tags,
        })
    }

    async saveShellhubHardwareMac() {
        this.setState({loading: true})
        try {
            const response = await API.patch(
                'nodes/' + this.props.editingNode.nodeId,
                {
                    shellhubHardwareMac: this.state.shellhubHardwareMac
                        .replace(/[^a-zA-Z0-9 ]/g, '')
                        .toLowerCase(),
                },
                2
            )
            if (!response.success) {
                return alert('There was an error saving.')
            }
            this.props.sync()
        } catch (error) {
            console.log(error)
            return alert('There was an error saving.')
        }
        this.setState({loading: false})
    }

    componentWillMount() {}

    componentWillUnmount() {}

    render() {
        let {modal, toggleModal, assets, nodes, editingNode} = this.props
        const deviceId = nodes.find(
            (x) => x.nodeId === editingNode.nodeId
        ).deviceId
        const name =
            deviceId === 'none'
                ? 'Uncomissioned SensorBot'
                : assets.find((a) => a.deviceId === deviceId).name
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggleModal}>{name}</ModalHeader>
                    <ModalBody>
                        <AssetCommission
                            nodes={this.props.nodes}
                            assets={this.props.assets}
                            editingNode={this.props.editingNode}
                        />
                        <GatewayCommission
                            nodes={this.props.nodes}
                            gateways={this.props.gateways}
                            editingNode={this.props.editingNode}
                        />

                        <hr />
                        {/*
            {this.props.editingNode ?
              <Row className="mb-4">
                <Col>
                  <hr/>
                  <div className="text-center">
                  <h4 className="text-center">Remote Access</h4>
                  <Button block color="link" className="m-0 p-0" onClick={() => window.open('https://accumine.freshdesk.com/support/solutions/61000134238')}>Open Documentation</Button>
                  </div>
                </Col>
              </Row>
            : null }

            {this.props.editingNode && this.props.editingNode.shellhubHardwareMac ?
              <>
                <Row>
                  <Col>
                    <Select
                      placeholder="Select Connection Method"
                      components={{ Input: CustomSelectInput }}
                      className="react-select"
                      classNamePrefix="react-select"
                      onChange={selectedShellhubConnectionMethod => this.setState({ selectedShellhubConnectionMethod })}
                      options={[
                        {
                          label: 'Putty Client (easier)',
                          value: 'putty'
                        },
                        {
                          label: 'Command Line SSH (harder)',
                          value: 'commandLine'
                        }
                      ]}
                      value={this.state.selectedShellhubConnectionMethod}
                    />

                    {this.state.selectedShellhubConnectionMethod && this.state.selectedShellhubConnectionMethod.value === 'commandLine' ? 
                      <Button
                        className="mt-2"
                        block
                        color="success"
                        onClick={() => {
                          navigator.clipboard.writeText(`ssh -p 9822 enterprise@accumine.${this.props.editingNode.shellhubHardwareMac}@remote.accuminetech.com`);
                          alert('Copied to clipboard');
                        }}
                      >
                        Copy SSH ID to Clipboard
                      </Button>
                    : null }

                    {this.state.selectedShellhubConnectionMethod && this.state.selectedShellhubConnectionMethod.value === 'putty' ? 
                      <>
                        <p className="m-0 p-0 mt-2"><strong>Hostname:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          {`accumine.${this.props.editingNode.shellhubHardwareMac}@remote.accuminetech.com`}
                        </p>
                        <p className="m-0 p-0 mt-2"><strong>Port:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          9822
                        </p>
                        <p className="m-0 p-0 mt-2"><strong>Username:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          enterprise
                        </p>
                        <p className="m-0 p-0 mt-2"><strong>Password:</strong></p>
                        <p className="m-0 p-0" style={{userSelect: 'all'}}>
                          Contact your Accumine Account Manager
                        </p>
                      </>
                    : null }
                    
                  </Col>
                </Row>
              </>
            : null}

            {this.props.editingNode && !this.props.editingNode.shellhubHardwareMac ?
              <>
                <Row>
                  <Col>
                    <Alert color="warning">
                      Please verify the hardware mac address of the device labelled on the underside of the E-Series.
                      <Row>
                        <Col className="text-center">
                          <p className="m-0 p-0 mt-2" style={{fontWeight: 'bold'}}>MAC ADDRESS:</p>
                          <p className="m-0 p-0" style={{fontWeight: 'bold'}}>XX-XX-XX-XX-XX-XX</p>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <Input
                        value={this.state.shellhubHardwareMac}
                        placeholder={'XX-XX-XX-XX-XX-XX'}
                        onChange={e => this.setState({ shellhubHardwareMac: e.target.value })}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="success" onClick={this.saveShellhubHardwareMac}>Save</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </>
            : null }
            */}

                        <hr />

                        <Button className="btn-block" onClick={this.exportCSV}>
                            Export Tag List
                        </Button>

                        <Button
                            className="btn-block"
                            onClick={this.toggleImportModal}>
                            Import Tag List
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        {this.renderDeleteButton()}
                        {this.renderDeleteConfirmation()}
                    </ModalFooter>
                </Modal>
                {this.state.importModalOpen ? (
                    <ImportModal
                        toggleModal={this.toggleImportModal}
                        modal={this.state.importModalOpen}
                        nodes={this.props.nodes}
                        gateways={this.props.gateways}
                        assets={this.props.assets}
                        editingNode={this.props.editingNode}
                    />
                ) : null}
                {this.state.download ? (
                    <CSVDownload data={this.state.exportData} target="_blank" />
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
