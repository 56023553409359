import React, {Component, Fragment} from 'react'
import autoBind from 'auto-bind'

import TimestampFilter from '../../../../QueryBuilder/Filter/Timestamp'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }

    render() {
        return (
            <Fragment>
                <h5 style={{color: '#145388'}}>SELECT TIMERANGE</h5>
                <TimestampFilter
                    logic={this.props.timerange.logic}
                    value={this.props.timerange.value}
                    dataModelId={this.props.dataModel._id}
                    fieldId={this.props.dataModel._id}
                    path={''}
                    fieldName={''}
                    onSubmit={this.props.setTimerange}
                    isDesignatedTimeEnd={false}
                    autoSubmit={true}
                />
            </Fragment>
        )
    }
}
