import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        let belongsTo = this.props.gateways.find(
            (g) => g.nodeId === node.gatewayId
        )

        this.state = {
            selectedGwy: belongsTo
                ? {label: belongsTo.alias, value: belongsTo.nodeId}
                : {label: 'No Gateway', value: ''},
        }
    }
    handleChange(e) {
        this.setState({
            selectedGwy: e,
        })
        this.save(e.value)
    }
    async save(gatewayId) {
        try {
            let response = await API.patch(
                'nodes/' + this.props.editingNode.nodeId,
                {
                    gatewayId: gatewayId,
                },
                2
            )
            if (response.success) {
                //this.props.toggleModal('assetModal');
            } else {
                return alert(
                    'There was an error saving your change. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error saving your change. Please try again.'
            )
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {gateways} = this.props
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-0">
                        <Form>
                            <Label className="form-group has-top-label">
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="gwy-commission"
                                    value={this.state.selectedGwy}
                                    onChange={this.handleChange}
                                    options={[
                                        {label: 'No Gateway', value: ''},
                                    ].concat(
                                        gateways.map((a, i) => {
                                            return {
                                                label: a.alias,
                                                value: a.nodeId,
                                            }
                                        })
                                    )}
                                />
                                <IntlMessages id="Gateway Selection" />
                            </Label>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
