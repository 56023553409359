import React, {Component} from 'react'
import {Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import {Scrollbars} from 'react-custom-scrollbars'

import Initializer from './Initializer'
import ColumnEditor from './ColumnEditor/index'
import ArchiveEditor from './ArchiveEditor/index'
import DetailEditor from './DetailEditor'
import ReferenceEditor from './ReferenceEditor'
import CalendarSettings from './CalendarSettings'
import KanbanSettings from './KanbanSettings'

export default class Editor extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    componentDidMount() {}

    render() {
        return (
            <Card style={{height: this.props.height}}>
                <CardBody style={{height: this.props.height}}>
                    <Scrollbars>
                        {!this.props.editingDataModelIsNew ? (
                            <>
                                <ColumnEditor {...this.props} />
                                {this.props.editingDataModel &&
                                !this.props.editingDataModel.protectedModel ? (
                                    <>
                                        <DetailEditor {...this.props} />
                                        <KanbanSettings {...this.props} />
                                        {this.props.editingDataModel
                                            .modelType === 'State' ||
                                        this.props.editingDataModel
                                            .modelType === 'Event' ? (
                                            <CalendarSettings {...this.props} />
                                        ) : null}
                                        <ReferenceEditor {...this.props} />
                                        <ArchiveEditor {...this.props} />
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <Initializer {...this.props} />
                        )}
                    </Scrollbars>
                </CardBody>
            </Card>
        )
    }
}
