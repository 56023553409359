import React from 'react'
import {Button} from 'reactstrap'

const COLUMNS = {
    'Real-Time Data': [
        {
            Header: 'Platform',
            accessor: 'platform',
            width: 200,
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Weight (lbs)',
                    accessor: 'station1Weight',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
                {
                    Header: 'Resin (gals)',
                    accessor: 'station1Resin',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
                {
                    Header: 'Parts',
                    accessor: 'station1Parts',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
                {
                    Header: 'Current Resin % (l%)',
                    accessor: 'station1ResinPercentCurrent',
                    width: 150,
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                background:
                                    rowInfo &&
                                    rowInfo.row.station1ResinPercentCurrent >
                                        rowInfo.original.station1CustomThreshold
                                        ? '#dc3545'
                                        : '#28a745',
                                color: '#fff',
                                textAlign: 'center',
                                borderRight: '1px solid black',
                            },
                        }
                    },
                },
                {
                    Header: 'Resin % (l%)',
                    accessor: 'station1ResinPercent',
                    headerStyle: {textAlign: 'center'},
                    Cell: (row) => {
                        return (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    row.original.setStation1CustomThreshold(
                                        row.original.station1CustomThreshold,
                                        row.original.station1DeviceId
                                    )
                                }}>
                                {row.value}
                            </div>
                        )
                    },
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                background:
                                    rowInfo &&
                                    rowInfo.row.station1ResinPercent >
                                        rowInfo.original.station1CustomThreshold
                                        ? '#dc3545'
                                        : '#28a745',
                                color: '#fff',
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Weight (lbs)',
                    accessor: 'station2Weight',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
                {
                    Header: 'Resin (gals)',
                    accessor: 'station2Resin',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
                {
                    Header: 'Parts',
                    accessor: 'station2Parts',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
                {
                    Header: 'Current Resin % (l%)',
                    accessor: 'station2ResinPercentCurrent',
                    width: 150,
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                background:
                                    rowInfo &&
                                    rowInfo.row.station2ResinPercentCurrent >
                                        rowInfo.original.station2CustomThreshold
                                        ? '#dc3545'
                                        : '#28a745',
                                color: '#fff',
                                textAlign: 'center',
                                borderRight: '1px solid black',
                            },
                        }
                    },
                },
                {
                    Header: 'Resin % (l%)',
                    accessor: 'station2ResinPercent',
                    Cell: (row) => {
                        return (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    row.original.setStation2CustomThreshold(
                                        row.original.station2CustomThreshold,
                                        row.original.station2DeviceId
                                    )
                                }}>
                                {row.value}
                            </div>
                        )
                    },
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                background:
                                    rowInfo &&
                                    rowInfo.row.station2ResinPercent >
                                        rowInfo.original.station2CustomThreshold
                                        ? '#dc3545'
                                        : '#28a745',
                                color: '#fff',
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        {
            Header: 'Stn. Request Mix',
            accessor: 'stationRequestMix',
        },
        {
            Header: 'Transactions',
            headerStyle: {textAlign: 'center'},
            Cell: (row) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            row.original.showTransactions(
                                row.original.station1DeviceId,
                                row.original.station2DeviceId,
                                row.original.platform
                            )
                        }}>
                        <Button size="xs">Show Transactions</Button>
                    </div>
                )
            },
        },
    ],
    'Previous Shift Resin Data': [
        {
            Header: 'Platform',
            accessor: 'platform',
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Resin (gals)',
                    accessor: 'station1Resin',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Resin (gals)',
                    accessor: 'station2Resin',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
    ],
    'Previous Shift Weight Data': [
        {
            Header: 'Platform',
            accessor: 'platform',
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Weight (lbs)',
                    accessor: 'station1Weight',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Weight (lbs)',
                    accessor: 'station2Weight',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        {
            Header: 'Stn. Request Mix',
            accessor: 'stationRequestMix',
            headerStyle: {textAlign: 'center'},
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: 'center',
                    },
                }
            },
        },
    ],
    'Previous Shift Part Count Data': [
        {
            Header: 'Platform',
            accessor: 'platform',
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Parts',
                    accessor: 'station1Parts',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        {
            Header: '',
            headerStyle: {textAlign: 'center'},
            columns: [
                {
                    Header: 'Parts',
                    accessor: 'station2Parts',
                    headerStyle: {textAlign: 'center'},
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
    ],
}

export default COLUMNS
