import initReactFastclick from 'react-fastclick'
import reportWebVitals from './reportWebVitals'
initReactFastclick()
;(async () => {
    if (navigator.appVersion.includes('Raspbian')) {
        window.addEventListener('keydown', function (e) {
            if (e.key === 'F1') {
                e.preventDefault()
            }
        })
    }
    require('./App')
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
