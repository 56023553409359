import React, {Component, Fragment} from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ListGroup,
    Alert,
    CardBody,
    Card,
    CardTitle,
} from 'reactstrap'
import {Scrollbars} from 'react-custom-scrollbars'
import autoBind from 'auto-bind'

import ModelTree from '../../../common/ModelTree'
import TimestampFilter from '../../../QueryBuilder/Filter/Timestamp/index'
import TextFilter from '../../../QueryBuilder/Filter/Text'
import NumberFilter from '../../../QueryBuilder/Filter/Number'
import BooleanFilter from '../../../QueryBuilder/Filter/Boolean'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.height = document.documentElement.offsetHeight * 0.5
        this.list = []
        this.paths = []
        this.treeData = []
        this.flatData = []

        this.state = {
            selected: [],
            view: 'list', // 'filter', 'selected'
            model: null,
            field: null,
            path: null,
        }
    }

    handleFieldClick(selectedKeys, {selectedNodes}) {
        const node = selectedNodes[0]
        if (
            node.type !== 'MultiSubModel' &&
            node.type !== 'SingleSubModel' &&
            node.type !== 'ForeignID'
        ) {
            this.setState({
                model: node.model,
                field: node.field,
                path: node.path,
                view: 'filter',
            })
        }
    }

    addFilter({logic, value, prettyString}) {
        let filters = this.props.filters
        if (filters.find((f) => f.prettyString === prettyString)) {
            return alert('This filter already exists')
        }
        filters.push({
            logic,
            value,
            prettyString,
            dataModelId: this.state.model._id,
            fieldId: this.state.field._id,
            type: this.state.field.type,
            path: this.state.path,
        })
        this.setState({view: 'list'}, this.props.updateFilters(filters))
        this.props.refresh()
    }

    removeFilter(filter) {
        let filters = this.props.filters
        filters = filters.filter((f) => f.prettyString !== filter.prettyString)
        this.props.updateFilters(filters)
        if (filters.length === 0) {
            this.setState({view: 'list'})
        }
        this.props.refresh()
    }

    showFilterEngine() {
        const {filterEngine} = this.props.fieldTypes.find(
            (t) => t.type === this.state.field.type
        )
        if (
            filterEngine === 'exact_string' ||
            filterEngine === 'contain_string'
        ) {
            return (
                <TextFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    exact={filterEngine === 'exact_string'}
                    onSubmit={this.addFilter}
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                    defaultFormulaInput={this.props.defaultFormulaInput}
                />
            )
        } else if (filterEngine === 'number') {
            return (
                <NumberFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    onSubmit={this.addFilter}
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                    defaultFormulaInput={this.props.defaultFormulaInput}
                />
            )
        } else if (filterEngine === 'timestamp') {
            return (
                <TimestampFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    onSubmit={this.addFilter}
                    isDesignatedTimeEnd={
                        this.state.field.type === 'DesignatedTimeEnd'
                    }
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                    defaultFormulaInput={this.props.defaultFormulaInput}
                />
            )
        } else if (filterEngine === 'boolean') {
            return (
                <BooleanFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    onSubmit={this.addFilter}
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                    defaultFormulaInput={this.props.defaultFormulaInput}
                />
            )
        }
    }

    componentDidMount() {
        let trees = []
        const allowedTypes = [
            'ForeignID',
            'DesignatedTimestamp',
            'DesignatedTimeStart',
            'DesignatedTimeEnd',
            'MultiSubModel',
            'SingleSubModel',
        ].concat(
            this.props.fieldTypes.filter((a) => a.filterable).map((a) => a.type)
        )
        trees.push(
            <ModelTree
                showMap={(item) => allowedTypes.includes(item.type)}
                onSelect={this.handleFieldClick}
                dataModel={this.props.dataModel}
                dataModels={this.props.dataModels}
                includeJoins={false}
            />
        )
        this.setState({trees})
    }

    render() {
        const {toggle, open} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={open}
                    toggle={toggle}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggle}>
                        <strong>Filter by</strong>
                    </ModalHeader>
                    <ModalBody style={{height: '100%'}}>
                        {!this.props.variables && !this.props.formulas ? (
                            <Alert color="warning">
                                Use the <b>Report Engine</b> filter tool for any
                                report based filtering.
                            </Alert>
                        ) : null}
                        {this.state.view !== 'selected' ? (
                            <>
                                {this.props.filters.length === 0
                                    ? 'No filters'
                                    : null}
                                {this.props.filters.length ? (
                                    <Button
                                        size="xs"
                                        className="btn btn-block"
                                        onClick={() => {
                                            this.setState({view: 'selected'})
                                        }}>
                                        <>
                                            {this.props.filters.length}{' '}
                                            {this.props.filters.length > 1
                                                ? 'filters'
                                                : 'filter'}
                                        </>
                                    </Button>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Button
                                    size="xs"
                                    onClick={() => {
                                        this.setState({view: 'list'})
                                    }}
                                    className="btn btn-block">
                                    Show fields
                                </Button>
                            </>
                        )}
                        {this.state.view === 'filter' ? (
                            <>
                                <h5
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        this.setState({view: 'list'})
                                    }}>
                                    <i className="simple-icon-arrow-left mr-2"></i>
                                    {this.state.model.name +
                                        ' - ' +
                                        this.state.field.name}
                                </h5>
                                {this.showFilterEngine()}
                            </>
                        ) : null}

                        {this.state.view === 'list' ? (
                            <>
                                {this.state.selected.length ? (
                                    <Button
                                        className="mb-2"
                                        size="xs"
                                        block
                                        onClick={() =>
                                            this.setState({view: 'selected'})
                                        }>
                                        Show selected
                                    </Button>
                                ) : null}
                                <Scrollbars
                                    autoHeight
                                    autoHeightMin={this.height}
                                    autoHeightMax={this.height}>
                                    {this.state.trees}
                                </Scrollbars>
                            </>
                        ) : null}

                        {this.state.view === 'selected' ? (
                            <ListGroup className="mt-4">
                                {this.props.filters.map((f, idx) => {
                                    return (
                                        <Card key={idx}>
                                            <CardBody>
                                                <CardTitle className="text-right">
                                                    <i
                                                        className="iconsmind-Close"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            this.removeFilter(f)
                                                        }}></i>
                                                </CardTitle>
                                                {f.prettyString}
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </ListGroup>
                        ) : null}
                    </ModalBody>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
