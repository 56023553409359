import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Badge} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import moment from 'moment'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import AddModal from '../SimpleMaintenance/add'
import RemoveModal from '../SimpleMaintenance/remove'
import EditModal from '../SimpleMaintenance/edit'
import StatusModal from '../SimpleMaintenance/status'
import ChecklistModal from './add'
import ChecklistEditModal from './edit'
import ChecklistFillModal from './fill'
import ChecklistScheduleModal from './schedule'

const columns = [
    {
        Header: 'Name',
        accessor: 'labels.name',
    },
    {
        Header: 'Asset',
        accessor: 'labels.deviceName',
    },
    {
        Header: 'Equipment',
        accessor: 'labels.equipmentName',
    },
    {
        Header: 'Supervisor',
        accessor: 'labels.supervisorName',
    },
    {
        Header: 'Frequency',
        accessor: 'labels.frequency',
    },
]

const STATUS_COLORS = {
    Opened: 'warning',
    'On Hold': 'danger',
    'In Progress': 'primary',
    Completed: 'success',
}

const makeBadge = (status, color = 'rgba(0,0,0,1)') => {
    return (
        <span
            style={{
                backgroundColor: color,
                borderRadius: 10,
                padding: 5,
                color: '#FFFFFF',
            }}>
            {status}
        </span>
    )
}

export default class SimplePreventativeMaintenance extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'SimplePreventativeMaintenance'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            checklists: [],
            editing: null,
            addModal: false,
            users: [],
            devices: [],
            workflows: [],
            removeModal: false,
            checklistModal: false,
            checklistEditModal: false,
            checklistFillModal: false,
        }
    }

    async fetchUsers() {
        let users = await API.get('users?all=true', 2)
        this.setState({users})
    }

    async fetchDevices() {
        let devices = await API.get('devices?all=true')
        this.setState({devices})
    }

    async fetchChecklists() {
        let checklists = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Checklist',
                },
            },
            2
        )

        if (checklists) {
            this.setState({checklists})
        }
    }

    async fetchWorkflows() {
        let workflows = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Workflow',
                },
            },
            2
        )

        if (workflows) {
            this.setState({workflows})
        }
    }

    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal,
        })
    }

    toggleStatusModal() {
        this.setState({
            statusModal: !this.state.statusModal,
        })
    }

    toggleChecklistModal() {
        this.setState({
            checklistModal: !this.state.checklistModal,
        })
    }

    toggleChecklistEditModal() {
        this.setState({
            checklistEditModal: !this.state.checklistEditModal,
        })
    }

    toggleChecklistFillModal() {
        this.setState({
            checklistFillModal: !this.state.checklistFillModal,
        })
    }

    toggleChecklistScheduleModal() {
        this.setState({
            checklistScheduleModal: !this.state.checklistScheduleModal,
        })
    }

    toggleModal(modalName, code) {
        let state = this.state
        state.editing = code
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (code) =>
                    this.toggleModal('checklistEditModal', code),
                actionName: 'View/Update',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (code) =>
                    this.toggleModal('checklistFillModal', code),
                actionName: 'Fill Report',
                buttonColor: 'secondary',
            },
            {
                columnSize: 12,
                onClickFn: (code) =>
                    this.toggleModal('checklistScheduleModal', code),
                actionName: 'History',
                buttonColor: 'warning',
            },
            {
                columnSize: 12,
                onClickFn: (code) => this.toggleModal('removeModal', code),
                actionName: 'Delete',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(checklist) {
        const user = this.state.users.find(
            (u) =>
                u._id ==
                checklist.metaData.find((o) => o.name === 'setByUser').value
        )
        checklist.labels = {
            equipmentName:
                checklist.metaData.find((o) => o.name === 'equipment') &&
                checklist.metaData.find((o) => o.name === 'equipment').value
                    ? checklist.metaData.find((o) => o.name === 'equipment')
                          .value.label
                    : 'N/A',
            deviceName: this.state.devices.find(
                (device) =>
                    device.deviceId ==
                    checklist.metaData.find((o) => o.name === 'asset').value
            )
                ? this.state.devices.find(
                      (device) =>
                          device.deviceId ==
                          checklist.metaData.find((o) => o.name === 'asset')
                              .value
                  ).name
                : 'N/A',
            supervisorName: user ? user.firstName + ' ' + user.lastName : 'N/A',
            frequency:
                checklist.metaData.find((o) => o.name === 'repeat') &&
                checklist.metaData.find((o) => o.name === 'repeat').value,
            name:
                checklist.metaData.find((o) => o.name === 'name') &&
                checklist.metaData.find((o) => o.name === 'name').value,
        }
        return checklist
    }

    editorModalAttributes(checklist) {
        return []
    }

    async componentWillMount() {
        await this.fetchUsers()
        await this.fetchDevices()
        await this.fetchWorkflows()
        this.subscriber.add(this.fetchChecklists, 10000, 'fetchChecklists()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstChecklist = (
                <div>
                    <Button color="primary" onClick={this.toggleChecklistModal}>
                        Create your first checklist
                    </Button>
                </div>
            ),
            tableExists = this.state.checklists.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col xs="12" className="mb-2" style={{float: 'left'}}>
                            <Button
                                color="secondary"
                                onClick={this.toggleChecklistModal}>
                                Create Checklist
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="_id"
                    emptyCrudMainText="No Checklists Found"
                    emptyCrudSubText={addFirstChecklist}
                    crudTableColumns={columns}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.checklists}
                    editorModalTitle="Checklist Manager"
                    editorModalAttributes={this.editorModalAttributes}
                    editorModalActions={this.actions()}
                    useEditorModal={true}
                />

                {this.state.checklistModal ? (
                    <ChecklistModal
                        toggleModal={this.toggleChecklistModal}
                        modal={this.state.checklistModal}
                        onSave={this.fetchChecklists}
                    />
                ) : null}

                {this.state.checklistEditModal ? (
                    <ChecklistEditModal
                        toggleModal={this.toggleChecklistEditModal}
                        modal={this.state.checklistEditModal}
                        editing={this.state.editing}
                        onSave={this.fetchChecklists}
                        workflows={this.state.workflows}
                    />
                ) : null}

                {this.state.removeModal ? (
                    <RemoveModal
                        toggleModal={this.toggleModal}
                        modal={this.state.removeModal}
                        tickets={[]}
                        editing={this.state.editing}
                    />
                ) : null}

                {this.state.checklistFillModal ? (
                    <ChecklistFillModal
                        toggleModal={this.toggleChecklistFillModal}
                        modal={this.state.checklistFillModal}
                        editing={this.state.editing}
                        tickets={this.state.tickets}
                        workflows={this.state.workflows}
                        onSave={(checklist) => {
                            this.fetchChecklists()
                        }}
                    />
                ) : null}

                {this.state.checklistScheduleModal ? (
                    <ChecklistScheduleModal
                        toggleModal={this.toggleChecklistScheduleModal}
                        modal={this.state.checklistScheduleModal}
                        editing={this.state.editing}
                        workflows={this.state.workflows}
                        onSave={this.fetchChecklists}
                        checklistFillModal={(checklist) =>
                            this.toggleChecklistFillModal(checklist)
                        }
                    />
                ) : null}
            </Fragment>
        )
    }
}
