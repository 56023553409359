import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardHeader, CardBody, CardTitle} from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

// Accumine SDK imports
import * as API from 'SDK/api'
import {getLiveAssets} from 'SDK/api/common'
import {tileColor} from 'SDK/helpers'
import Subscriber from 'SDK/subscriber'

// Accumine components for this view
import LiveTile from './tile'

export default class DowntimeDisplay extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'DowntimeDisplay'
    requiredOutputs = ['Downtime']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)
        this.subscriber = new Subscriber()

        this.state = {
            timerange: null,
            assets: [],
            data: [],
            tiles: [],
            initialLoad: true,
        }
    }

    async getLiveStatus() {
        const data = await API.post('live', {
            deviceId: this.state.assets.map((x) => x.deviceId),
        })
        if (!data) return // alert('There was an error fetching the live status');

        let downtimeReasons = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: {$in: this.state.assets.map((a) => a.deviceId)},
                    name: 'Downtime Reason',
                    timeEnd: null,
                },
            },
            2
        )

        let tiles = []
        for (let deviceId in data) {
            let activeStates = data[deviceId].activeStates
            if (activeStates.length > 0) {
                activeStates = activeStates.sort(
                    (a, b) => a.priority - b.priority
                )
                let activeState = activeStates.find(
                    (a) =>
                        a.name === 'Machine Waiting' ||
                        a.name === 'Machine Idle' ||
                        a.name === 'Downtime'
                )
                if (
                    !data[deviceId].activeShiftBreaks.length &&
                    activeState /*&& (activeState.name === 'Machine Waiting' || activeState.name === 'Machine Idle' || activeState.name === 'Downtime')*/
                ) {
                    tiles.push({
                        color: tileColor(activeState.name),
                        name: activeState.name,
                        timestamp: activeState.timestamp,
                        deviceName: this.state.assets.find(
                            (x) => x.deviceId === deviceId
                        ).name,
                        opacity: 1,
                        deviceId: deviceId,
                        downtimeReason: downtimeReasons.find(
                            (d) => d.deviceId === deviceId
                        ),
                    })
                }
            }
        }
        tiles = tiles.sort(
            (a, b) =>
                new Date(a.timestamp).getTime() -
                new Date(b.timestamp).getTime()
        )
        let opacityUnit = 1 / tiles.length
        for (let i in tiles) {
            tiles[i].opacity = 1 - opacityUnit * i
            if (tiles[i].opacity < 0.2) tiles[i].opacity = 0.2
        }
        this.setState({tiles, initialLoad: false})
    }

    async getAssets() {
        const assets = await API.get('devices')
        if (!assets) return // alert('There was an error fetching the list of assets');

        this.setState({
            assets: assets,
        })
    }

    async componentWillMount() {
        await this.getAssets()
        this.subscriber.add(
            this.getLiveStatus,
            5000,
            'RealTime_DownTimeDisplay_getLiveStatus'
        )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const noDowntime =
            this.state.tiles.length === 0 && this.state.initialLoad === false

        return (
            <Fragment>
                <Row>
                    {this.state.initialLoad ? (
                        <div className="loading" />
                    ) : null}
                    {noDowntime ? (
                        <Col xs="12" className="text-center">
                            <h2>There is currently no machine downtime!</h2>
                        </Col>
                    ) : (
                        this.state.tiles.map((x, idx) => (
                            <LiveTile key={idx} data={x} />
                        ))
                    )}
                </Row>
            </Fragment>
        )
    }
}
