import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, ListGroupItem} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import Subscriber from 'SDK/subscriber'
import Element from './element'
import * as API from 'SDK/api'
import POIModal from './modal'

import Map from '../Common/Map'
import Sidebar from './sidebar'

import Factory from '../Common/Twin/Factory'
import SidewayStatusBarSummary from '../Common/Visuals/SidewayStatusBarSummary'

export default class IPSRealTimeMap extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'IPSRealTimeMap'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.width = document.documentElement.offsetWidth - 175
        this.height = document.documentElement.offsetHeight - 325

        this.state = {
            model: null,
            error: null,
            assets: [],
            devices: [],
            active: [],
            dormant: [],
            offline: [],
        }
    }

    async fetchModel() {
        const {error, model} = this.factory.fetchActiveModel()
        this.setState({error, model})
    }

    async fetchRelevantDevices() {
        if (this.state.model === null) return

        let {error, devices} = this.factory.fetchModelDevices(
            this.state.model.version
        )

        if (devices) {
            devices = devices.map((d) => {
                d.online = moment().diff(moment(d.lastSync), 'minutes') < 1
                return d
            })
        }

        this.updateDeviceStatus()

        this.setState({error, devices, assets: this.factory._assets})
    }

    showPOIModal(name, devices) {
        let data = []
        for (let i in devices) {
            data.push(
                <ListGroupItem color={devices[i].online ? 'success' : 'danger'}>
                    {devices[i].alias} (
                    {devices[i].online ? 'online' : 'offline'})
                </ListGroupItem>
            )
        }

        this.setState({
            showPOIModal: true,
            poiModalName: name,
            poiModalData: data,
        })
    }

    hidePOIModal() {
        this.setState({showPOIModal: false})
    }

    highlightPOI(id) {
        this.setState({highlighting: id})
    }

    updateDeviceStatus() {
        let active = [],
            dormant = [],
            offline = []

        for (let i in this.state.devices) {
            const d = this.state.devices[i],
                status = d.status()
            if (status.name === 'Active') {
                active.push(d)
            }
            if (status.name === 'Dormant') {
                dormant.push(d)
            }
            if (status.name === 'Offline') {
                offline.push(d)
            }
        }
        this.setState({active, dormant, offline})
    }

    async componentWillMount() {
        this.factory = new Factory()
        await this.factory.init()

        this.fetchModel()
        this.subscriber.add(
            this.fetchRelevantDevices,
            5000,
            'fetchRelevantDevices'
        )
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
        this.factory.kill()
    }
    render() {
        return (
            <Fragment>
                {this.state.error ? (
                    <h4 className="text-center">{this.state.error}</h4>
                ) : null}
                {this.state.model ? (
                    <>
                        <Row className="mb-2">
                            <Col>
                                <SidewayStatusBarSummary
                                    active={this.state.active.length}
                                    dormant={this.state.dormant.length}
                                    offline={this.state.offline.length}
                                    height={20}
                                    unit={'number'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody style={{padding: '10px'}}>
                                        <h4>Real-Time Asset Positioning</h4>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3" style={{paddingRight: '0px'}}>
                                <Card style={{height: '100%'}}>
                                    <CardBody style={{padding: 0}}>
                                        <Sidebar
                                            devices={this.state.devices}
                                            assets={this.state.assets}
                                            factory={this.factory}
                                            highlightPOI={this.highlightPOI}
                                            active={this.state.active}
                                            dormant={this.state.dormant}
                                            offline={this.state.offline}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="9" style={{paddingLeft: '0px'}}>
                                <Card>
                                    <CardBody style={{padding: 0}}>
                                        <Map
                                            model={this.state.model}
                                            objects={this.state.model.pois.map(
                                                (p) => {
                                                    return (
                                                        <Element
                                                            key={Math.random()}
                                                            {...p}
                                                            onInteraction={
                                                                this
                                                                    .showPOIModal
                                                            }
                                                            devices={this.state.devices.filter(
                                                                (d) =>
                                                                    d
                                                                        .lastLocation
                                                                        .id ===
                                                                    p.id
                                                            )}
                                                            assets={
                                                                this.state
                                                                    .assets
                                                            }
                                                            totalDevices={
                                                                this.state
                                                                    .devices
                                                                    .length
                                                            }
                                                            highlighting={
                                                                this.state
                                                                    .highlighting ===
                                                                p.id
                                                            }
                                                        />
                                                    )
                                                }
                                            )}
                                            height={this.height}
                                            width={this.width}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.showPOIModal ? (
                            <POIModal
                                modal={this.state.showPOIModal}
                                data={this.state.poiModalData}
                                name={this.state.poiModalName}
                                hidePOIModal={this.hidePOIModal}
                            />
                        ) : null}
                    </>
                ) : null}
            </Fragment>
        )
    }
}
