import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Table,
    Alert,
    Input,
    FormGroup,
} from 'reactstrap'
import autoBind from 'auto-bind'

import ModelTree from '../../../../common/ModelTree'
import SelectInput from '../../../../common/select-input'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.targetModelOptions = this.props.dataModels
            .filter(
                (a) =>
                    a._id !== this.props.editingDataModel._id &&
                    a.parentDataModelId === null
            )
            .map((a) => {
                return {
                    label: a.name,
                    value: a._id,
                }
            })

        this.state = {
            addReferenceShown: false,
            targetModel: null,
            sourcePath: '',
            targetPath: '',
            timeBased: false,
        }
    }

    onTargetModelChange(_id) {
        this.setState({
            targetModel: _id,
        })
    }

    handleSourceFieldClick(selectedKeys, {selectedNodes}) {
        if (selectedNodes.length === 0) return this.setState({sourcePath: ''})
        let node = selectedNodes[0]
        if (
            node.type !== 'MultiSubModel' &&
            node.type !== 'SingleSubModel' &&
            node.type !== 'ForeignID'
        ) {
            this.setState({sourcePath: node.path})
        }
    }

    handleTargetFieldClick(selectedKeys, {selectedNodes}) {
        if (selectedNodes.length === 0) return this.setState({targetPath: ''})
        let node = selectedNodes[0]
        if (
            node.type !== 'MultiSubModel' &&
            node.type !== 'SingleSubModel' &&
            node.type !== 'ForeignID'
        ) {
            this.setState({
                targetPath: node.path,
            })
        }
    }

    async save() {
        const record = {
            sourceDataModelId: this.props.editingDataModel._id,
            sourcePath: this.state.sourcePath,
            targetDataModelId: this.state.targetModel,
            targetPath: this.state.targetPath,
            timeBased: this.state.timeBased,
        }

        await this.props.addReference(record)
        this.setState({
            addReferenceShown: false,
            targetModel: null,
            sourcePath: '',
            targetPath: '',
            timeBased: false,
        })
    }

    async deleteReference(referenceId) {
        await this.props.deleteReference(referenceId)
    }

    render() {
        const {toggle, open} = this.props
        return (
            <Fragment>
                <Modal size="lg" isOpen={open} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Manage References</ModalHeader>
                    <ModalBody>
                        {!this.state.addReferenceShown ? (
                            <>
                                <Alert color="danger">
                                    Dependencies between references are your
                                    responsibility. Accumine Cloud does not
                                    prevent you from deleting references that
                                    may impact other references
                                </Alert>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Target Model</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.editingDataModel.references
                                            ? this.props.editingDataModel.references.map(
                                                  (ref, idx) => {
                                                      const targetModel =
                                                          this.props.dataModels.find(
                                                              (a) =>
                                                                  a._id ===
                                                                  ref.targetDataModelId
                                                          )
                                                      return (
                                                          <tr key={idx}>
                                                              <td>
                                                                  {
                                                                      targetModel.name
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {ref.timeBased
                                                                      ? 'Time Based'
                                                                      : 'Direct Reference'}
                                                              </td>
                                                              <td>
                                                                  <Button
                                                                      color="danger"
                                                                      size="xs"
                                                                      onClick={() =>
                                                                          this.deleteReference(
                                                                              ref._id
                                                                          )
                                                                      }>
                                                                      Delete
                                                                  </Button>
                                                              </td>
                                                          </tr>
                                                      )
                                                  }
                                              )
                                            : null}
                                    </tbody>
                                </Table>
                                <hr />
                            </>
                        ) : null}

                        {this.state.addReferenceShown ? (
                            <>
                                <Row>
                                    <Col xs="12">
                                        <SelectInput
                                            onChange={this.onTargetModelChange}
                                            fieldName="Target Model"
                                            default={null}
                                            options={this.targetModelOptions}
                                        />
                                    </Col>
                                </Row>
                                {this.state.targetModel ? (
                                    <Fragment>
                                        <Row>
                                            <Col xs="12">
                                                <hr />
                                                <FormGroup check>
                                                    <Input
                                                        type="checkbox"
                                                        name="time-based"
                                                        id="time-based"
                                                        checked={
                                                            this.state.timeBased
                                                        }
                                                        onChange={() =>
                                                            this.setState({
                                                                timeBased:
                                                                    !this.state
                                                                        .timeBased,
                                                            })
                                                        }
                                                    />
                                                    <Label
                                                        for="time-based"
                                                        check>
                                                        Reference is{' '}
                                                        <strong>
                                                            Time Based
                                                        </strong>
                                                    </Label>
                                                </FormGroup>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6">
                                                <h5>Source</h5>
                                                <ModelTree
                                                    showMap={({type}) =>
                                                        ![
                                                            'MultiSubModel',
                                                            'RegularizedTimestamp',
                                                            'RegularizedDuration',
                                                        ].includes(type)
                                                    }
                                                    onSelect={
                                                        this
                                                            .handleSourceFieldClick
                                                    }
                                                    dataModel={
                                                        this.props
                                                            .editingDataModel
                                                    }
                                                    dataModels={
                                                        this.props.dataModels
                                                    }
                                                    includeJoins={true}
                                                />
                                            </Col>
                                            <Col xs="6">
                                                <h5>Target</h5>
                                                <ModelTree
                                                    showMap={({type}) =>
                                                        ![
                                                            'MultiSubModel',
                                                            'RegularizedTimestamp',
                                                            'RegularizedDuration',
                                                        ].includes(type)
                                                    }
                                                    onSelect={
                                                        this
                                                            .handleTargetFieldClick
                                                    }
                                                    dataModel={this.props.dataModels.find(
                                                        (a) =>
                                                            a._id ===
                                                            this.state
                                                                .targetModel
                                                    )}
                                                    dataModels={
                                                        this.props.dataModels
                                                    }
                                                    includeJoins={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ) : null}
                                <Button
                                    block
                                    size="sm"
                                    color="success"
                                    onClick={this.save}>
                                    Submit
                                </Button>
                            </>
                        ) : (
                            <Button
                                block
                                size="sm"
                                color="secondary"
                                onClick={() =>
                                    this.setState({addReferenceShown: true})
                                }>
                                Add reference
                            </Button>
                        )}
                    </ModalBody>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
