import React, {Component} from 'react'
import {Button, Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import Chart from 'react-google-charts'
import {isEqual} from 'lodash'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const {reportChartConfiguration} = this.props

        this.state = {
            chartEditor: null,
            chartWrapper: null,
            google: null,

            chartType: reportChartConfiguration
                ? reportChartConfiguration.chartType
                : 'Table',
            chartOptions: reportChartConfiguration
                ? reportChartConfiguration.chartOptions
                : {},
        }
    }

    componentDidUpdate(prevProps) {
        const {loadedSavedReport, reportChartConfiguration} = this.props

        if (
            loadedSavedReport &&
            !isEqual(
                prevProps.reportChartConfiguration,
                reportChartConfiguration
            )
        ) {
            this.setState({
                chartType: reportChartConfiguration.chartType,
                chartOptions: reportChartConfiguration.chartOptions,
            })
        }
        return true
    }

    render() {
        const columns = this.props.output.schema.fields
            .filter((a) => a.visible || a.visible === undefined)
            .map((a) => {
                return {
                    name: a.name,
                    alias: a.alias,
                }
            })

        const rows = this.props.output.data.map((row) => {
            let r = []
            for (let c of columns) {
                if (c.type === 'datetime') {
                    r.push(moment(row[c.name]).format('YYYY-MM-DD hh:mm a'))
                } else if (
                    row[c.name] !== undefined &&
                    row[c.name] !== null &&
                    row[c.name].constructor !== Array &&
                    row[c.name].constructor !== Object
                ) {
                    r.push(row[c.name])
                } else {
                    r.push(null)
                }
            }
            return r
        })

        return (
            <Row>
                <Col xs="12">
                    <Button
                        block
                        className="mb-4"
                        onClick={() => {
                            const {google, chartEditor, chartWrapper} =
                                this.state
                            if (
                                chartWrapper === null ||
                                google === null ||
                                chartEditor === null
                            )
                                return
                            chartEditor.openDialog(chartWrapper)
                            google.visualization.events.addListener(
                                chartEditor,
                                'ok',
                                () => {
                                    const newChartWrapper =
                                        chartEditor.getChartWrapper()
                                    newChartWrapper.draw()
                                    let chartOptions =
                                        newChartWrapper.getOptions()
                                    chartOptions.title =
                                        newChartWrapper.getChartName()
                                    delete chartOptions.width
                                    delete chartOptions.height

                                    const chartType =
                                        newChartWrapper.getChartType()

                                    this.setState({
                                        chartWrapper: newChartWrapper,
                                        chartType,
                                        chartOptions,
                                    })
                                    this.props.setChartConfiguration({
                                        chartType,
                                        chartOptions,
                                    })
                                }
                            )
                        }}>
                        CHANGE VISUALIZATION
                    </Button>
                    <Chart
                        height={`${
                            document.documentElement.offsetHeight * 0.6
                        }px`}
                        width={`${
                            document.documentElement.offsetWidth * 0.5
                        }px`}
                        chartType={this.state.chartType}
                        loader={<div>Loading Chart</div>}
                        columns={columns.map((a) => a.alias || a.name)}
                        rows={rows}
                        options={this.state.chartOptions}
                        rootProps={{'data-testid': '1'}}
                        getChartEditor={({
                            chartEditor,
                            chartWrapper,
                            google,
                        }) => {
                            this.setState({chartEditor, chartWrapper, google})
                        }}
                        chartPackages={['corechart', 'controls', 'charteditor']}
                    />
                </Col>
            </Row>
        )
    }
}
