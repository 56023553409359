import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ListGroup,
    ListGroupItem,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'

import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            offline: [],
            showModal: false,
            migratedToEdge: undefined,
        }
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async updateStatus() {
        let nodes = await API.get('nodes', 2),
            devices = await API.get('devices'),
            offline = []

        if (nodes && devices) {
            nodes.forEach((node) => {
                if (
                    node.lastHeartbeat &&
                    moment(node.lastHeartbeat).isValid()
                ) {
                    const elapsedMinutes = moment().diff(
                        moment(node.lastHeartbeat),
                        'minutes'
                    )
                    if (elapsedMinutes >= 5) {
                        const elapsed =
                            moment(node.lastHeartbeat).year() > 1970
                                ? moment(node.lastHeartbeat).fromNow()
                                : false
                        node.msg = ''
                        if (node.nodeType === 'GWY') {
                            if (elapsed)
                                node.msg +=
                                    'Gateway "' +
                                    node.alias +
                                    '" has been offline since ' +
                                    elapsed +
                                    '.'
                            else
                                node.msg +=
                                    'Gateway "' +
                                    node.alias +
                                    '" has never been online.'
                            offline.push(node)
                        } else if (
                            node.nodeType === 'ARD' &&
                            node.deviceId &&
                            node.deviceId !== 'none'
                        ) {
                            let device = devices.find(
                                (d) => d.deviceId === node.deviceId
                            )
                            if (device) {
                                if (elapsed)
                                    node.msg +=
                                        'SensorBot attached to asset "' +
                                        device.name +
                                        '" has been offline since ' +
                                        elapsed +
                                        '.'
                                else
                                    node.msg +=
                                        'SensorBot attached to asset "' +
                                        device.name +
                                        '" has never been online.'
                                offline.push(node)
                            }
                        }
                    }
                }
            })
        }
        this.setState({offline})
    }

    async componentWillMount() {
        let networkModel = await API.get(
                'data-models/fetch-by-name/System%2FNetwork',
                2
            ),
            networkRecord = undefined
        if (networkModel) {
            const data = await API.post(
                `data-models/${networkModel._id}/paginate`,
                {
                    filter: [],
                    limit: 1,
                },
                2
            )

            if (data && data.result.results.length) {
                networkRecord = data.result.results[0]
            }
        }
        if (networkRecord) {
            this.setState({migratedToEdge: true})
            localStorage['migratedToEdge'] = '1'
            console.log('Migrated to Accumine Edge')
        } else {
            this.subscriber.add(
                this.updateStatus,
                1000 * 60 * 5,
                'updateStatus()'
            )
            this.setState({migratedToEdge: false})
            localStorage['migratedToEdge'] = '0'
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                {this.state.migratedToEdge === false &&
                this.state.offline.length ? (
                    <div className="position-relative d-inline-block">
                        <div className="dropdown-menu-right dropdown show">
                            <button
                                className="header-icon notificationButton btn btn-empty"
                                onClick={this.toggleModal}>
                                <i className="simple-icon-bell"></i>
                                <span className="count">
                                    {this.state.offline.length}
                                </span>
                            </button>
                        </div>
                    </div>
                ) : null}
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Offline devices
                    </ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {this.state.offline.map((n) => {
                                return (
                                    <ListGroupItem key={n.nodeId}>
                                        {n.msg}
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
