import AssetPicker from './AssetPicker'
import GenericWidgetName from './GenericWidgetName'
import RealTimeTileFlash from './RealTimeTileFlash'
import RealTimeAggregation from './RealTimeAggregation'
import BlackText from './BlackText'
import RealTimeClock from './RealTimeClock'
import ClickToEnterDowntime from './ClickToEnterDowntime'
import HMIEntry from './HMIEntry'
import MultiAssetPicker from './MultiAssetPicker'
import RealTimeActiveShift from './RealTimeActiveShift'
import RealTimeTimerange from './RealTimeTimerange'
import StatePicker from './StatePicker'
import ScrapPicker from './ScrapPicker'
import ShiftPicker from './ShiftPicker'
import ReasonCodePicker from './ReasonCodePicker'
import CategoryPicker from './CategoryPicker'
import DateRangePicker from './DateRangePicker'
import TableColumnGenerator from './TableColumnGenerator'
import OEETimeBasedSpeedLoss from './OEETimeBasedSpeedLoss'
import UseERPAssetNames from './UseERPAssetNames'
import UseRollingCycleTimeTargets from './UseRollingCycleTimeTargets'
import HideOEEBenchmarks from './HideOEEBenchmarks'
import DataModelPicker from './DataModelPicker'
import MultiCalendarDataModelPicker from './MultiCalendarDataModelPicker'
import SortByInCycle from './SortByInCycle'
import SortByTime from './SortByTime'
import MCSUseSimpleDisplay from './MCSUseSimpleDisplay'
import OffsetOvernightShifts from './OffsetOvernightShifts'
import HideLightsOutEntry from './HideLightsOutEntry'
import HubbellAikenHMI2ManualPartIncrement from './HubbellAikenHMI2ManualPartIncrement'
import HubbellAikenHMI2PrintLabel from './HubbellAikenHMI2PrintLabel'

const helpers = [
    AssetPicker,
    GenericWidgetName,
    RealTimeTileFlash,
    RealTimeAggregation,
    BlackText,
    RealTimeClock,
    ClickToEnterDowntime,
    HMIEntry,
    MultiAssetPicker,
    RealTimeActiveShift,
    RealTimeTimerange,
    ReasonCodePicker,
    ScrapPicker,
    ShiftPicker,
    StatePicker,
    CategoryPicker,
    TableColumnGenerator,
    OEETimeBasedSpeedLoss,
    UseERPAssetNames,
    UseRollingCycleTimeTargets,
    HideOEEBenchmarks,
    DataModelPicker,
    MultiCalendarDataModelPicker,
    SortByInCycle,
    SortByTime,
    MCSUseSimpleDisplay,
    OffsetOvernightShifts,
    HideLightsOutEntry,
    HubbellAikenHMI2ManualPartIncrement,
    HubbellAikenHMI2PrintLabel,
]

export default helpers
