import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
} from 'reactstrap'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'
import {generateHash, iteratePlaceholderName} from 'SDK/helpers'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.groupOptions = [
            ...new Set(this.props.schemas.map((s) => s.group)),
        ].map((g) => {
            return {label: g, value: g}
        })

        const group = this.props.editingSchema.group

        this.state = {
            nameInputBorderColor: 'rgb(215,215,215)',
            name: this.props.editingSchema.name,
            columns: this.props.editingSchema.columns,
            group: {label: group, value: group},
            valid: true,
            message: false,
        }
    }

    handleGroupEvent(group) {
        this.setState({group})
    }

    async save() {
        const obj = {
            name: this.state.name,
            columns: this.state.columns,
            group: this.state.group.value,
        }

        await API.patch('schemas/' + this.props.editingSchema._id, obj, 2)

        this.props.toggleModal('editModal')
    }

    renderColumnsList() {
        return this.state.columns.map((b) => {
            return (
                <tr key={b.id}>
                    <td>
                        <Input
                            value={
                                this.state.columns.find((a) => a.id === b.id)
                                    .name
                            }
                            style={{
                                borderTop: 'transparent',
                                borderRight: 'transparent',
                                borderLeft: 'transparent',
                                backgroundColor: 'transparent',
                            }}
                            onChange={(e) => {
                                const columns = this.state.columns
                                columns.find((a) => a.id === b.id).name =
                                    e.target.value
                                this.setState({columns})
                            }}
                        />
                    </td>
                    <td
                        style={{
                            textAlign: 'right',
                            paddingTop: '20px',
                        }}>
                        <Button
                            size="xs"
                            color="danger"
                            onClick={() => {
                                let columns = this.state.columns
                                columns = columns.filter((a) => a.id !== b.id)
                                this.setState({columns})
                            }}>
                            <i className="simple-icon-trash" />
                        </Button>
                    </td>
                </tr>
            )
        })
    }

    addColumn() {
        let columns = this.state.columns
        columns.push({
            id: generateHash(),
            name: iteratePlaceholderName(columns, 'New Column'),
        })

        this.setState({
            columns,
        })
    }

    render() {
        const {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    size="lg"
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('uploadModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('uploadModal')
                        }}>
                        Manual Data Upload
                    </ModalHeader>
                    <ModalBody></ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('uploadModal')
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.upload}>
                            Upload
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
