import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

import RealTimeWorkOrderStatusBoard2 from '../RealTimeWorkOrderStatusBoard2'

export default class RealTimeWorkOrderStatusBoard2View extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'flash',
            component: 'RealTimeTileFlash',
        },
        {
            prop: 'aggregation',
            component: 'RealTimeAggregation',
        },
        {
            prop: 'black',
            component: 'BlackText',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'RealTimeWorkOrderStatusBoard2View'
    requiredOutputs = ['Part Count', 'In-Cycle', 'Downtime', 'Work Order']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
        aggregation: PropTypes.number,
        flash: PropTypes.bool,
        black: PropTypes.bool,
    }
    static defaultProps = {
        black: false,
        aggregation: 1,
        flash: true,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            devices: [],
            size: 4,
        }
    }

    async componentWillMount() {
        const devices = await API.get('devices')

        let size = Math.floor(12 / devices.length)

        this.setState({
            devices,
            size,
        })
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                <Row>
                    {this.state.devices.length
                        ? this.state.devices.map((device, i) => {
                              const marginTop = i > 2 ? 'mt-2' : ''
                              return (
                                  <Col
                                      key={i}
                                      xs="12"
                                      sm={this.state.size}
                                      className={marginTop}>
                                      <RealTimeWorkOrderStatusBoard2
                                          key={i}
                                          deviceId={device.deviceId}
                                          name={device.name}
                                          aggregation={this.props.aggregation}
                                          flash={this.props.flash}
                                          black={this.props.black}
                                          threshold={device.threshold}
                                      />
                                  </Col>
                              )
                          })
                        : null}
                </Row>
            </Fragment>
        )
    }
}
