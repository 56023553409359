import React, {Component, Fragment} from 'react'
import {Bar, Line, Pie, Radar} from 'react-chartjs-2'
import {Row, Col, Card, CardBody} from 'reactstrap'
import {Textfit} from 'react-textfit'
import autobind from 'auto-bind'
import moment from 'moment'
import colors from 'nice-color-palettes'
import regression from 'regression'
colors.splice(12, 2)

const Kpi = (props) => {
    return (
        <h1 style={{color: '#000', textAlign: 'center', width: '100%'}}>
            {props.data}
        </h1>
    )
}

export default class ChartMaker extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.data = this.props.data
        this.type = this.props.type
        this.timeUnit = this.props.timeUnit
        this.chart = {
            type: this.props.type,
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                animation: false,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        }

        this.state = {}
    }
    makeData() {
        if (!this.data.length) {
            this.setState({
                options: this.options,
                data: [],
            })
            return
        }
        if (this.props.percentage) {
            this.chart.options.scales.yAxes[0].ticks.max = 100
        }
        if (this.timeUnit) {
            this.chart.options.scales.xAxes[0].type = 'time'
            this.chart.options.scales.xAxes[0].time = {
                unit: this.timeUnit.substring(0, this.timeUnit.length - 1),
            }
            this.chart.data.labels = this.data.map((x) => moment(x.date))
        } else {
            this.chart.options.scales.xAxes[0].type = 'category'
            this.chart.data.labels = this.data.map((x) => x.key)
        }

        const keys = Object.keys(this.data[0])

        for (let i in keys) {
            if (keys[i] !== 'date' && keys[i] !== 'key') {
                this.chart.data.datasets.push({
                    label: keys[i],
                    data: this.data.map((x) => x[keys[i]]),
                })
            }
        }

        if (this.chart.data.datasets.length === 1 && this.type !== 'pie') {
            if (this.type !== 'line' && this.type !== 'spc') {
                this.chart.data.datasets[0].backgroundColor = '#145388'
            } else {
                this.chart.data.datasets[0].borderColor = '#145388'
                this.chart.data.datasets[0].backgroundColor = 'transparent'
            }
        } else if (this.type !== 'pie') {
            for (let i in this.chart.data.datasets) {
                if (this.type !== 'line' && this.type !== 'spc') {
                    this.chart.data.datasets[i].backgroundColor = colors[i][0]
                } else {
                    this.chart.data.datasets[i].borderColor = colors[i][0]
                    this.chart.data.datasets[i].backgroundColor = 'transparent'
                }
            }
        } else if (this.type === 'pie') {
            for (let i in this.chart.data.datasets) {
                this.chart.data.datasets[i].backgroundColor = []
                for (let j in this.chart.data.datasets[i].data) {
                    this.chart.data.datasets[i].backgroundColor[j] =
                        colors[j][0]
                }
            }
        }

        if (this.type === 'stackedBar' || this.type === 'spc') {
            this.chart.options.legend.display = true
        }

        if (
            (this.props.type === 'bar' ||
                this.props.type === 'stackedBar' ||
                this.props.type === 'spc') &&
            this.props.timeUnit
        ) {
            let arr = new Array(this.chart.data.labels.length).fill(0)

            for (let i in this.chart.data.datasets) {
                for (let j in this.chart.data.datasets[i].data) {
                    arr[j] += this.chart.data.datasets[i].data[j]
                }
            }

            for (let i in arr) {
                arr[i] =
                    Math.round(
                        (arr[i] / this.chart.data.datasets.length) * 100
                    ) / 100
            }

            const result = regression.linear(
                arr.filter((a) => a > 0).map((a, i) => [i, a])
            )
            let trendline = [],
                b = result.equation[1],
                m = result.equation[0]
            for (let i in arr) {
                trendline.push(m * i + b)
            }

            this.chart.data.datasets.unshift({
                type: 'line',
                label: 'Trend',
                data: trendline,
                backgroundColor: '#47799a',
                borderColor: '#47799a',
                fill: false,
                pointRadius: 0,
            })
        }

        if (this.type === 'spc') {
            let arr = new Array(this.chart.data.labels.length).fill(0)
            for (let i in this.chart.data.datasets) {
                for (let j in this.chart.data.datasets[i].data) {
                    arr[j] += this.chart.data.datasets[i].data[j]
                }
            }

            for (let i in arr) {
                arr[i] =
                    Math.round(
                        (arr[i] / this.chart.data.datasets.length) * 100
                    ) / 100
            }

            const nonzero = arr.filter((a) => a > 0),
                result =
                    Math.round(
                        (nonzero.reduce((a, b) => (a += b)) / nonzero.length) *
                            100
                    ) / 100,
                std = Math.sqrt(
                    nonzero
                        .map((x) => Math.pow(x - result, 2))
                        .reduce((a, b) => a + b) / nonzero.length
                )

            let lcl = [],
                ucl = []
            for (let i in arr) {
                arr[i] = result
                lcl.push(Math.round((result - 2 * std) * 100) / 100)
                ucl.push(Math.round((result + 2 * std) * 100) / 100)
            }

            this.chart.data.datasets.unshift({
                type: 'line',
                label: 'Average',
                data: arr,
                backgroundColor: '#000',
                borderColor: '#000',
                fill: false,
                pointRadius: 0,
                borderDash: [10, 5],
            })
            this.chart.data.datasets.unshift({
                type: 'line',
                label: 'LCL',
                data: lcl,
                backgroundColor: '#dc3545',
                borderColor: '#dc3545',
                fill: false,
                borderDash: [10, 5],
                pointRadius: 0,
            })
            this.chart.data.datasets.unshift({
                type: 'line',
                label: 'UCL',
                data: ucl,
                backgroundColor: '#dc3545',
                borderColor: '#dc3545',
                fill: false,
                borderDash: [10, 5],
                pointRadius: 0,
            })
        }

        this.setState({
            data: this.chart.data,
            options: this.chart.options,
        })
    }

    makeChart() {
        if (this.type === 'bar') {
            let data = this.state.data
            if (this.props.lowerLimit) {
                data.datasets.push({
                    type: 'line',
                    label: 'Lower Limit',
                    data: new Array(data.labels.length).fill(
                        this.props.lowerLimit
                    ),
                    backgroundColor: '#dc3545',
                    borderColor: '#dc3545',
                    fill: false,
                    borderDash: [10, 5],
                    pointRadius: 0,
                })
            }
            if (this.props.upperLimit) {
                data.datasets.push({
                    type: 'line',
                    label: 'Upper Limit',
                    data: new Array(data.labels.length).fill(
                        this.props.upperLimit
                    ),
                    backgroundColor: '#dc3545',
                    borderColor: '#dc3545',
                    fill: false,
                    borderDash: [10, 5],
                    pointRadius: 0,
                })
            }
            return <Bar data={data} options={this.state.options} />
        } else if (this.type === 'line') {
            let data = this.state.data
            if (this.props.lowerLimit) {
                data.datasets.push({
                    type: 'line',
                    label: 'Lower Limit',
                    data: new Array(data.labels.length).fill(
                        this.props.lowerLimit
                    ),
                    backgroundColor: '#dc3545',
                    borderColor: '#dc3545',
                    fill: false,
                    borderDash: [10, 5],
                    pointRadius: 0,
                })
            }
            if (this.props.upperLimit) {
                data.datasets.push({
                    type: 'line',
                    label: 'Upper Limit',
                    data: new Array(data.labels.length).fill(
                        this.props.upperLimit
                    ),
                    backgroundColor: '#dc3545',
                    borderColor: '#dc3545',
                    fill: false,
                    borderDash: [10, 5],
                    pointRadius: 0,
                })
            }

            return <Line data={data} options={this.state.options} />
        } else if (this.type === 'stackedBar') {
            let stackedOptions = {...this.state.options}
            stackedOptions.scales.xAxes[0].stacked = true
            stackedOptions.scales.yAxes[0].stacked = true
            this.setState({stackedOptions})
            return <Bar data={this.state.data} options={this.state.options} />
        } else if (this.type === 'pie') {
            let pieOptions = {...this.state.options}
            pieOptions.scales = {}
            this.setState({pieOptions})
            return <Pie data={this.state.data} options={this.state.options} />
        } else if (this.type === 'kpi') {
            return <Kpi data={this.state.data.datasets[0].data[0]} />
        } else if (this.type === 'spc') {
            return <Line data={this.state.data} options={this.state.options} />
        }
    }

    componentWillMount() {
        this.makeData()
    }

    render() {
        return <>{this.makeChart()}</>
    }
}
