import * as API from 'SDK/api'

export const fetchSignedInOperators = async (deviceId) => {
    let operators = await API.post(
        'historical/raw',
        {
            query: {
                deviceId,
                name: {
                    $in: ['Operator Sign In'],
                },
                timeEnd: null,
            },
        },
        2
    )

    return operators
}

export const fetchAllOperatorSignIns = async () => {
    let operators = await API.post(
        'historical/raw',
        {
            query: {
                name: 'Operator Sign In',
            },
        },
        2
    )

    operators = [...new Set(operators.map((o) => o.value))].map((o) => {
        return {
            label: o,
            value: o,
        }
    })

    return operators
}

export const fetchAllWorkOrders = async () => {
    let workorders = await API.post(
        'historical/raw',
        {
            query: {
                name: 'Work Order',
            },
        },
        2
    )

    return workorders
}
