import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import {Line} from 'react-chartjs-2'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

const chartConfig = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: 'rgba(0,0,0,0.1)',
                    drawBorder: false,
                },
                ticks: {
                    fontColor: 'gray',
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                type: 'time',
                distribution: 'linear',
                time: {
                    displayFormats: {
                        millisecond: 'h:mm:ss a',
                    },
                },
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontColor: 'gray',
                    autoSkip: false,
                    maxRotation: 0,
                },
            },
        ],
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.selectedTransform = this.props.node._raw.entity.extras.nodeId
        this.inputs =
            this.props.browserFlow._flow.$transforms[
                this.selectedTransform
            ]._inputs

        this.inputs = this.inputs.map(
            (i) => this.props.browserFlow._flow.$transforms[i]
        )

        this.flowInputs = this.props.browserFlow._flow.$template
            .filter((a) => a.transform === '$input')
            .map((a) => a.params.a.split(':'))
        const ts = moment()

        this.state = {
            output: {
                labels: [],
                datasets: [
                    {
                        label: 'Output',
                        data: [],
                        backgroundColor: '#2a93d5',
                        borderColor: '#2a93d5',
                        fill: 'transparent',
                    },
                ],
            },
            inputs: this.inputs.map((x) => {
                let name = x.constructor.name,
                    key = false
                if (name === 'Input') {
                    key = Object.keys(this.props.browserFlow._flow.$dic).find(
                        (key) =>
                            this.props.browserFlow._flow.$dic[key] === x._id
                    )
                    let deviceId = key.split(':')[0],
                        dataPoint = key.split(':')[1]

                    // check if datum coming from sensor
                    if (
                        this.props.nodes.find(
                            (n) => n.nodeId === deviceId && n.nodeType === 'ARD'
                        )
                    ) {
                        const nodeObj = this.props.nodes.find(
                            (n) => n.nodeId === deviceId && n.nodeType === 'ARD'
                        )

                        if (
                            nodeObj &&
                            nodeObj.settings.pins &&
                            nodeObj.settings.pins.find(
                                (p) => p.pinId === dataPoint
                            )
                        ) {
                            name = nodeObj.settings.pins.find(
                                (p) => p.pinId === dataPoint
                            ).nickname
                        }
                    } else {
                        const device = this.props.devices.find(
                            (d) => d.deviceId === deviceId
                        )
                        if (device) name = device.name + ' - ' + dataPoint
                    }
                }
                return {
                    labels: [],
                    id: x._id,
                    input: key,
                    datasets: [
                        {
                            label: name,
                            data: [],
                            backgroundColor: '#2a93d5',
                            borderColor: '#2a93d5',
                            fill: 'transparent',
                        },
                    ],
                }
            }),
            lastFetch: this.flowInputs.map((a) => moment(ts)),
        }
    }
    async fetchData() {
        let promises = []
        for (let i = 0; i < this.flowInputs.length; i++) {
            promises.push(
                API.post(
                    'raw/sensorbot-data',
                    {
                        timestamp: this.state.lastFetch[i].toISOString(),
                        nodeId: this.flowInputs[i][0],
                        name: this.flowInputs[i][1],
                    },
                    2
                )
            )
        }

        let data = await Promise.all(promises)
        let lastFetch = this.state.lastFetch

        let obj = {}

        for (let i in data) {
            if (data[i].length) {
                lastFetch[i] = moment(data[i][data[i].length - 1].timestamp)
                for (let row of data[i]) {
                    const id = row.nodeId + ':' + row.name
                    if (!obj[id]) {
                        obj[id] = []
                    }
                    obj[id].push(row)
                }
            }
        }
        this.props.browserFlow._flow.write(obj)
        this.setState({lastFetch})
    }
    componentDidMount() {
        let self = this
        self.props.browserFlow._flow.$transforms[self.selectedTransform].on(
            'data',
            (data) => {
                let output = self.state.output
                output.datasets[0].data.push(data.value)
                output.labels.push(moment(data.timestamp))
                self.setState({output})
            }
        )
        for (let i in this.state.inputs) {
            self.props.browserFlow._flow.$transforms[
                self.state.inputs[i].id
            ].on('data', (data) => {
                let inputs = self.state.inputs
                inputs[i].datasets[0].data.push(data.value)
                inputs[i].labels.push(moment(data.timestamp))
                self.setState({inputs})
            })
        }
        this.subscriber.add(this.fetchData, 1000 * 5, 'fetchData')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    reloadOutputData() {
        return {
            labels: this.state.output.labels,
            datasets: [
                {
                    label: 'Output',
                    data: this.state.output.datasets[0].data,
                    backgroundColor: '#2a93d5',
                    borderColor: '#2a93d5',
                    fill: 'transparent',
                },
            ],
        }
    }

    reloadInputData(i) {
        return {
            labels: this.state.inputs[i].labels,
            datasets: [
                {
                    label: this.state.inputs[i].datasets[0].label,
                    data: this.state.inputs[i].datasets[0].data,
                    backgroundColor: '#2a93d5',
                    borderColor: '#2a93d5',
                    fill: 'transparent',
                },
            ],
        }
    }

    render() {
        const {modal, toggleModal, node} = this.props
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>{node.name}</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.inputs.map((input, i) => {
                                return (
                                    <Col key={i} xs="12" className="mb-4">
                                        <Line
                                            key={Math.random()}
                                            data={this.reloadInputData(i)}
                                            options={chartConfig}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <Line
                                    key={Math.random()}
                                    data={this.reloadOutputData()}
                                    options={chartConfig}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
