export const sum = (records) => records.reduce((a, b) => a + b.value, 0)

export const resinPercentage = (weightSum, resinSum) => {
    if (weightSum + resinSum * 9.1 === 0) {
        return 0
    } else {
        return ((resinSum * 9.1) / (weightSum + resinSum * 9.1)) * 100
    }
}

export const requestMix = (station1WeightSum, station2WeightSum) => {
    return (station1WeightSum + station2WeightSum) / 1500
}
