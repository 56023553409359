import React, {Component, Fragment} from 'react'
import {Button} from 'reactstrap'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import Form from '@rjsf/bootstrap-4'
import moment from 'moment'

import * as API from 'SDK/api'
import Link from './Link'
import CodeEditor from './Code'
import NumberWithPlusMinus from './NumberWithPlusMinus'

export default class extends Component {
    static propTypes = {
        // GET /v2/data-models/dataModelId/validator
        schema: PropTypes.object.isRequired,

        // optional filters for manual data link dropdowns, follow paginate call filter format
        manualDataLinkFilters: PropTypes.arrayOf(PropTypes.object),

        // if true, manual data link options will show up as radio inputs instead of dropdown
        useRadioLinks: PropTypes.bool,

        // if true, radio inputs for manual data link will show up horizontally
        radioLinksInline: PropTypes.bool,

        // if true, all empty timestamp fields to will default to current day
        timestampsToToday: PropTypes.bool,

        // existing record object, can also be used to set default values in new record
        // if "_id" field is found, it will update, if not it will insert on submit
        editingRecord: PropTypes.oneOf([undefined, null, PropTypes.object]),

        // returns the submitted & validated document
        onSubmit: PropTypes.func,

        // optional custom template for the form (look through HubbellWadsworth for examples)
        // https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-templates/#objectfieldtemplate
        ObjectFieldTemplate: PropTypes.oneOf([PropTypes.object, undefined]),

        // if true, archive button will be hidden
        hideArchive: PropTypes.bool,

        // if true, submit button will be hidden
        hideSubmit: PropTypes.bool,

        // returns current form data after an onChange event
        onChange: PropTypes.func,

        // DEPRECATED func called when document is being updated/inserted
        submitting: PropTypes.func,

        // DEPRECATED func called to toggle modal
        toggle: PropTypes.func,

        // DEPRECATED func called to refresh dataset after submission
        refresh: PropTypes.func,
    }

    static defaultProps = {
        manualDataLinkFilters: [],
        useRadioLinks: false,
        radioLinksInline: false,
        timestampsToToday: false,
        editingRecord: undefined,
        onSubmit: () => {},
        ObjectFieldTemplate: undefined,
        hideArchive: false,
        hideSubmit: false,
        submitting: () => {},
        toggle: () => {},
        refresh: () => {},
        onChange: () => {},
    }

    constructor(props) {
        super(props)
        autoBind(this)

        this.uiSchema = {
            'ui:title': ' ',
            loading: false,
        }

        this.makeUISchema(this.props.schema.properties, this.uiSchema, '')

        this.state = {
            loading: false,
            data: this.props.editingRecord,
            linkedDocuments: {},
        }
    }

    makeUISchema(schemaLayer, uiSchemaLayer, parentKey) {
        const dataModelId = schemaLayer['dataModelId'].default
        for (let key in schemaLayer) {
            const fieldType = schemaLayer[key].fieldType
            delete schemaLayer[key].default
            schemaLayer[key].description = ' '
            if (key !== 'dataModelId') {
                schemaLayer[key].dataModelId = dataModelId
                if (fieldType === 'ForeignID') {
                    /**
                     * foreignID can be either a string (_id) or embedded object when being
                     * sent to the data-modeller. here, we do not embed the full document so we
                     * are changing the type to string instead of anyof(string, object)
                     */
                    delete schemaLayer[key].anyOf
                    schemaLayer[key].type = 'string'
                    let filters = this.props.manualDataLinkFilters || [],
                        useRadioLinks = this.props.useRadioLinks || []

                    uiSchemaLayer[key] = {
                        'ui:field': 'link',
                        name: schemaLayer[key].title,
                        description: schemaLayer[key].description,
                        foreignDataModelId: schemaLayer[key].foreignDataModelId,
                        foreignOptionFilter:
                            schemaLayer[key].foreignOptionFilter,
                        filters: filters.filter(
                            (f) =>
                                f.fieldId ===
                                (parentKey === '' ? key : parentKey + '.' + key)
                        ),
                        radio:
                            useRadioLinks.find(
                                (f) =>
                                    f ===
                                    (parentKey === ''
                                        ? key
                                        : parentKey + '.' + key)
                            ) !== undefined,
                        radioInline: this.props.radioLinksInline,
                    }
                } else if (fieldType === 'Code') {
                    uiSchemaLayer[key] = {
                        'ui:field': 'code',
                        name: schemaLayer[key].title,
                        description: schemaLayer[key].description,
                    }
                } else if (fieldType === 'SingleSubModel') {
                    uiSchemaLayer[key] = {}
                    this.makeUISchema(
                        schemaLayer[key].properties,
                        uiSchemaLayer[key],
                        parentKey === '' ? key : parentKey + '.' + key
                    )
                } else if (fieldType === 'MultiSubModel') {
                    uiSchemaLayer[key] = {items: {}}
                    this.makeUISchema(
                        schemaLayer[key].items.properties,
                        uiSchemaLayer[key].items,
                        parentKey === '' ? key : parentKey + '.' + key
                    )
                } else if (
                    this.props.timestampsToToday &&
                    !schemaLayer[key].default &&
                    (fieldType === 'Timestamp' ||
                        fieldType === 'DesignatedTimeStart' ||
                        fieldType === 'DesignatedTimeEnd' ||
                        fieldType === 'DesignatedTimestamp')
                ) {
                    schemaLayer[key].default = moment()
                        .startOf('day')
                        .toISOString()
                } else if (fieldType === 'Number') {
                    uiSchemaLayer[key] = {
                        'ui:field': 'numberWithPlusMinus',
                        name: schemaLayer[key].title,
                        description: schemaLayer[key].description,
                    }
                }
            } else {
                uiSchemaLayer[key] = {
                    'ui:widget': 'hidden',
                }
            }
        }
    }

    async handleSubmit({formData}) {
        if (this.props.submitting) this.props.submitting()
        window.scrollTo(0, 0)
        this.setState({loading: true})
        let document = null

        try {
            if (this.props.editingRecord && this.props.editingRecord._id) {
                formData._id = this.props.editingRecord._id
                document = await API.patch(
                    'data-models/' + this.props.dataModel._id + '/edit-record',
                    formData,
                    2
                )
            } else {
                document = await API.post(
                    'data-models/' + this.props.dataModel._id + '/add-record',
                    formData,
                    2
                )
            }
            if (!document) {
                throw Error()
            }
            if (this.props.toggle) this.props.toggle()
            if (this.props.refresh) this.props.refresh()
            if (this.props.onSubmit) this.props.onSubmit(document)
        } catch (error) {
            alert('Could not save form! Please try again.')
        }

        this.setState({loading: false})
    }

    async handleDelete() {
        await API.remove(
            'data-models/' +
                this.props.dataModel._id +
                '/delete-record/' +
                this.props.editingRecord._id +
                '',
            2
        )
        if (this.props.toggle) this.props.toggle()
        if (this.props.refresh) this.props.refresh()

        alert('The document has been archived.')
    }

    //shouldComponentUpdate() {
    //    return this.state.loading;
    //}

    render() {
        return (
            <Fragment>
                <Form
                    schema={this.props.schema}
                    uiSchema={this.uiSchema}
                    fields={{
                        link: Link,
                        code: <CodeEditor />,
                        numberWithPlusMinus: NumberWithPlusMinus,
                    }}
                    onSubmit={this.handleSubmit}
                    formContext={{
                        ...this.props,
                        formData: this.state.data,
                        linkedDocuments: this.state.linkedDocuments,
                        updateLinkedDocument: (document) => {
                            let {linkedDocuments} = this.state
                            if (document && document.constructor === Object) {
                                linkedDocuments[document._id] = document
                            }
                            this.setState({linkedDocuments})
                        },
                    }}
                    formData={this.state.data}
                    onChange={({formData}) => {
                        this.setState({data: formData})
                        if (this.props.onChange) {
                            this.props.onChange(formData)
                        }
                    }}
                    ObjectFieldTemplate={this.props.ObjectFieldTemplate}>
                    <input
                        ref={(button) => {
                            this.submitButton = button
                        }}
                        style={{display: 'none'}}
                        type="submit"
                        value="Submit"
                    />

                    {!this.props.hideSubmit ? (
                        <Button
                            block
                            color="success"
                            onClick={() => {
                                this.submitButton.click()
                            }}
                            type="button">
                            SAVE
                        </Button>
                    ) : null}
                </Form>
                {this.props.editingRecord &&
                this.props.editingRecord._id &&
                !this.props.hideArchive ? (
                    <Button
                        color="danger"
                        block
                        onClick={this.handleDelete}
                        style={{marginTop: 10}}>
                        ARCHIVE
                    </Button>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
