Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    var a = s.split('.')
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i]
        if (k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o
}

import {DATA_MODEL_ID_MAP} from '../Block/DATA_MODEL_ID_MAP'

const REGULAR_BATCH_ORDER = [
    'Press',
    'BBO',
    'Kiln',
    'Collar Spray',
    'Collar Cure',
    'Grinding',
    'Visual Inspection',
    'Metallizing',
    'Test & Inspect',
    '2 Shot Re-Run Tracker',
]

const THREEDA_BATCH_ORDER = [
    'Press',
    'BBO',
    'Kiln',
    'Collar Spray',
    'Collar Cure',
    'Grinding',
    'Visual Inspection',
    '3DA Bordering',
    'Metallizing',
    '3DA Transmission',
    '3DA High Energy',
    '3DA 100A',
    'Final Inspection (3DA Batches)',
    '2 Shot Re-Run Tracker',
]

const count = (operationRecords, title, stopIfPreviousOpIncomplete = false) => {
    let result = {
        in: 0,
        out: 0,
        scrap: 0,
        rework: 0,
    }

    if (title !== 'Press' && stopIfPreviousOpIncomplete) {
        const record = operationRecords[title]
        if (record) {
            if (
                record['Block Batch Link']['Block Description'].Type === '3DA'
            ) {
                const idx = THREEDA_BATCH_ORDER.findIndex((a) => a === title)
                const previous = REGULAR_BATCH_ORDER[idx - 1]
                if (!operationRecords[previous]) {
                    return {
                        ...result,
                        blocked: true,
                    }
                }
            } else {
                const idx = REGULAR_BATCH_ORDER.findIndex((a) => a === title)
                const previous = REGULAR_BATCH_ORDER[idx - 1]
                if (!operationRecords[previous]) {
                    return {
                        ...result,
                        blocked: true,
                    }
                }
            }
        }
    }

    // calculate incoming
    const press = operationRecords['Press']
    if (press && press[DATA_MODEL_ID_MAP['Press']['totalCountFieldPath']]) {
        result.in = press[DATA_MODEL_ID_MAP['Press']['totalCountFieldPath']]
    }

    for (let operation in operationRecords) {
        if (operation === title) {
            break
        } else {
            for (let scrapFieldPath of DATA_MODEL_ID_MAP[operation][
                'scrapFieldPaths'
            ]) {
                if (operationRecords[operation]) {
                    let v = Object.byString(
                        operationRecords[operation],
                        scrapFieldPath
                    )
                    if (v) {
                        result.in -= v
                    }
                }
            }
            for (let negateScrapFieldPath of DATA_MODEL_ID_MAP[operation][
                'negateScrapFieldPaths'
            ]) {
                if (operationRecords[operation]) {
                    let v = Object.byString(
                        operationRecords[operation],
                        negateScrapFieldPath
                    )
                    if (v) {
                        result.in -= v
                    }
                }
            }
        }
    }

    // calculate scrap
    if (operationRecords[title]) {
        for (let i in DATA_MODEL_ID_MAP[title].scrapFieldPaths) {
            let c = Object.byString(
                operationRecords[title],
                DATA_MODEL_ID_MAP[title].scrapFieldPaths[i]
            )
            if (c) {
                result.scrap += c
            }
        }
        for (let i in DATA_MODEL_ID_MAP[title].negateScrapFieldPaths) {
            let c = Object.byString(
                operationRecords[title],
                DATA_MODEL_ID_MAP[title].negateScrapFieldPaths[i]
            )
            if (c) {
                result.scrap -= c
            }
        }
        // calculate rework
        for (let i in DATA_MODEL_ID_MAP[title].reworkScrapFieldPaths) {
            let c = Object.byString(
                operationRecords[title],
                DATA_MODEL_ID_MAP[title].reworkScrapFieldPaths[i]
            )
            if (c) {
                result.rework += c
            }
        }
    }

    if (
        title === '2 Shot Re-Run Tracker' &&
        DATA_MODEL_ID_MAP[title].complexScrapFieldParser
    ) {
        result.scrap += DATA_MODEL_ID_MAP[title].complexScrapFieldParser(
            operationRecords[title]
        )
    }

    result.out = result.in - result.scrap

    return result
}

export default count
