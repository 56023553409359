import React, {Component, Fragment} from 'react'

import Chart from 'react-google-charts'
import moment from 'moment'

export default class extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Chart
                width={'500px'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={this.props.data}
                options={{
                    title: this.props.title,
                    subtitle: this.props.subtitle,
                    chart: {
                        title: this.props.title,
                        subtitle: this.props.subtitle,
                    },
                }}
                rootProps={{'data-testid': '1'}}
            />
        )
    }
}
