import React, {Component, Fragment} from 'react'
import autobind from 'auto-bind'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DataTablePagination from 'components/DataTables/pagination'

export default class PreviewTable extends Component {
    constructor(props) {
        super(props)
        autobind(this)
    }
    render() {
        return (
            <Fragment>
                <ReactTable
                    defaultPageSize={10}
                    data={this.props.data}
                    columns={this.props.columns}
                    minRows={0}
                    PaginationComponent={DataTablePagination}
                    filterable={true}
                />
            </Fragment>
        )
    }
}
