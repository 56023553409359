import React, {Fragment, Component} from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Row,
    Col,
} from 'reactstrap'
import autobind from 'auto-bind'

import {timeSeriesUsageDataset} from '../Common/Twin/Metrics'
import InteractiveCharter from '../Common/Visuals/InteractiveCharter'

const SUBTITLE_DICTIONARY = {
    days: 'day',
    weeks: 'week',
    months: 'month',
    'peak-percent': 'Peak Usage %',
    'peak-count': 'Peak Usage Count',
    'avg-percent': 'Average Usage %',
    'avg-hours': 'Average Usage Hours',
}

export default class Wizard extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            xAxisDropdownOpen: false,
            xAxisValue: 'days',
            yAxisDropdownOpen: false,
            yAxisValue: 'avg-percent',
            compareDropdownOpen: false,
            groupByShift: false,
            showTrendline: false,
        }

        this.unix = 0
    }

    toggleXAxisDropdown() {
        this.setState({xAxisDropdownOpen: !this.state.xAxisDropdownOpen})
    }

    toggleYAxisDropdown() {
        this.setState({yAxisDropdownOpen: !this.state.yAxisDropdownOpen})
    }

    toggleCompareDropdown() {
        this.setState({compareDropdownOpen: !this.state.compareDropdownOpen})
    }

    async shouldComponentUpdate(nextProps, nextState) {
        if (
            this.unix !== nextProps.unix ||
            this.state.xAxisValue !== nextState.xAxisValue ||
            this.state.yAxisValue !== nextState.yAxisValue ||
            this.state.groupByShift !== nextState.groupByShift ||
            this.state.showTrendline !== nextState.showTrendline
        ) {
            this.unix = nextProps.unix
            await this.generateData(
                nextState.xAxisValue,
                nextState.yAxisValue,
                nextState.groupByShift,
                nextState.showTrendline
            )
            return true
        } else {
            return false
        }
    }

    async generateData(xAxisValue, yAxisValue, groupByShift, showTrendline) {
        const data = await timeSeriesUsageDataset(
            this.props.capacityAnalyzer,
            xAxisValue,
            yAxisValue,
            groupByShift,
            showTrendline
        )
        this.setState({data})
    }

    render() {
        const {_timeStart, _timeEnd} = this.props.capacityAnalyzer
        const showWeekXAxisOption = _timeEnd.diff(_timeStart, 'weeks') > 1
        const showMonthXAxisOption = _timeEnd.diff(_timeStart, 'months') > 1
        return (
            <Fragment>
                <Row>
                    <Col>
                        <div className="float-left float-none-xs">
                            <div className="d-inline-block">
                                <h5 className="d-inline">
                                    Time Series Reporting
                                </h5>
                                <span className="text-muted text-small d-block">
                                    {SUBTITLE_DICTIONARY[this.state.yAxisValue]}
                                    {' by '}
                                    {SUBTITLE_DICTIONARY[this.state.xAxisValue]}
                                    {this.state.groupByShift ? (
                                        <>{', grouped by shift '}</>
                                    ) : null}
                                    {this.state.showTrendline ? (
                                        <>{' + trendline '}</>
                                    ) : null}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="btn-group float-right float-none-xs mb-5 mr-2 mt-2">
                            <Dropdown
                                isOpen={this.state.compareDropdownOpen}
                                toggle={this.toggleCompareDropdown}>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn-xs"
                                    outline
                                    disabled={this.props.loading}>
                                    Compare
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {this.props.capacityAnalyzer._schedule !==
                                    'alltime' ? (
                                        <DropdownItem
                                            style={{paddingLeft: '45px'}}
                                            toggle={false}>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={
                                                        this.state.groupByShift
                                                    }
                                                    onChange={(e) => {
                                                        this.setState({
                                                            groupByShift:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }}
                                                />{' '}
                                                Group by shift
                                            </Label>
                                        </DropdownItem>
                                    ) : null}
                                    <DropdownItem
                                        style={{paddingLeft: '45px'}}
                                        toggle={false}>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={
                                                    this.state.showTrendline
                                                }
                                                onChange={(e) => {
                                                    this.setState({
                                                        showTrendline:
                                                            e.target.checked,
                                                    })
                                                }}
                                            />{' '}
                                            Show trendline
                                        </Label>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="btn-group float-right float-none-xs mb-5 mr-2 mt-2">
                            <Dropdown
                                isOpen={this.state.yAxisDropdownOpen}
                                toggle={this.toggleYAxisDropdown}>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn-xs"
                                    outline
                                    disabled={this.props.loading}>
                                    Y Axis
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {/*<DropdownItem
                                            onClick={() => { this.setState({ yAxisValue: 'peak-percent' })}}
                                            active={this.state.yAxisValue === 'peak-percent'}
                                            disabled={this.state.yAxisValue === 'peak-percent'}
                                        >
                                        Peak Usage %
                                    </DropdownItem>
                                    <DropdownItem
                                            onClick={() => { this.setState({ yAxisValue: 'peak-count' })}}
                                            active={this.state.yAxisValue === 'peak-count'}
                                            disabled={this.state.yAxisValue === 'peak-count'}
                                        >
                                        Peak Usage Count
                                    </DropdownItem>*/}
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({
                                                yAxisValue: 'avg-percent',
                                            })
                                        }}
                                        active={
                                            this.state.yAxisValue ===
                                            'avg-percent'
                                        }
                                        disabled={
                                            this.state.yAxisValue ===
                                            'avg-percent'
                                        }>
                                        Avg. Usage %
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({
                                                yAxisValue: 'avg-hours',
                                            })
                                        }}
                                        active={
                                            this.state.yAxisValue ===
                                            'avg-hours'
                                        }
                                        disabled={
                                            this.state.yAxisValue ===
                                            'avg-hours'
                                        }>
                                        Avg. Usage Hours
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="btn-group float-right float-none-xs mb-5 mr-2 mt-2">
                            <Dropdown
                                isOpen={this.state.xAxisDropdownOpen}
                                toggle={this.toggleXAxisDropdown}>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn-xs"
                                    outline
                                    disabled={this.props.loading}>
                                    X Axis
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({xAxisValue: 'days'})
                                        }}
                                        active={
                                            this.state.xAxisValue === 'days'
                                        }
                                        disabled={
                                            this.state.xAxisValue === 'days'
                                        }>
                                        Days
                                    </DropdownItem>
                                    {showWeekXAxisOption ? (
                                        <DropdownItem
                                            onClick={() => {
                                                this.setState({
                                                    xAxisValue: 'weeks',
                                                })
                                            }}
                                            active={
                                                this.state.xAxisValue ===
                                                'weeks'
                                            }
                                            disabled={
                                                this.state.xAxisValue ===
                                                'weeks'
                                            }>
                                            Weeks
                                        </DropdownItem>
                                    ) : null}
                                    {showMonthXAxisOption ? (
                                        <DropdownItem
                                            onClick={() => {
                                                this.setState({
                                                    xAxisValue: 'months',
                                                })
                                            }}
                                            active={
                                                this.state.xAxisValue ===
                                                'months'
                                            }
                                            disabled={
                                                this.state.xAxisValue ===
                                                'months'
                                            }>
                                            Months
                                        </DropdownItem>
                                    ) : null}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>

                {this.state.data ? (
                    <Row>
                        <Col>
                            <InteractiveCharter
                                data={this.state.data}
                                height={this.props.height * 1}
                            />
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
