import React, {Fragment, Component} from 'react'
import {Row, Col, Input, Button} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import Select from 'react-select'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import FormulaInput from '../../common/FormulaInput/index'

const OPTIONS = [
    /*{
    label: 'Is',
    value: 'is',
    showInput: true
}, {
    label: 'Is not',
    value: 'isNot',
    showInput: true
}, */ {
        label: 'Contains',
        value: 'contains',
        showInput: true,
    },
    {
        label: 'Does not contain',
        value: 'doesNotContain',
        showInput: true,
    },
    {
        label: 'Is empty',
        value: 'isEmpty',
        showInput: false,
    },
    {
        label: 'Is not empty',
        value: 'isNotEmpty',
        showInput: false,
    },
    {
        label: 'Starts with',
        value: 'startsWith',
        showInput: true,
    },
    {
        label: 'Ends with',
        value: 'endsWith',
        showInput: true,
    },
]

export default class TextFilter extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = []
    showBorder = false
    id = 'QueryBuilder.TextFilter'
    static propTypes = {
        name: PropTypes.string,
    }
    static defaultProps = {
        dataModelId: '5f0c9cca276c86c36e2e0b81',
        fieldId: '5f0c9d35276c86c36e2e0b82',
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.OPTIONS =
            props && props.exact
                ? OPTIONS
                : OPTIONS.filter((o) => o.value !== 'is' && o.value !== 'isNot')

        let initial = {
            selected: this.OPTIONS[0],
            input: [],
        }

        if (props) {
            initial = {
                selected: OPTIONS.find((o) => o.value === props.logic)
                    ? OPTIONS.find((o) => o.value === props.logic)
                    : this.OPTIONS[0],
                input: props.value || [],
            }
        }

        this.state = {
            selected: initial.selected,
            input: initial.input,
            query: '',
            message: '',
            loading: false,
        }

        this.cache = {}
    }

    handleSelectChange(selected) {
        this.setState({selected})
    }

    handleInputChange(input) {
        if (this.props.formulas && this.props.variables) {
            input.type = 'formula'
            input = [input]
        }
        this.setState({input})
    }

    async handleSearch(query) {
        this.setState({loading: true})

        let options = []

        if (
            this.state.selected.value === 'is' ||
            this.state.selected.value === 'isNot'
        ) {
            let filter = {}
            filter[this.props.path] = {$regex: '.*' + query + '.*'}

            options = await API.post(
                'data-models/' +
                    this.props.dataModelId +
                    '/unique/' +
                    this.props.path,
                filter,
                2
            )
        } else {
            options = [query]
        }

        this.setState({options, loading: false})
    }

    makePrettyString() {
        const {label, value} = this.state.selected
        const expression = this.props.formulas && this.props.variables

        let prettyString = this.props.fieldName + ' ' + label.toLowerCase()

        if (value === 'is' || value === 'isNot') {
            if (expression) {
                prettyString += ` ${this.state.input
                    .map((a) => a.plainText)
                    .join(' or ')}`
            } else {
                prettyString += ' ' + this.state.input.join(' or ')
            }
        } else if (value !== 'isEmpty' && value !== 'isNotEmpty') {
            if (expression) {
                prettyString += ` ${this.state.input[0].plainText}`
            } else {
                prettyString += ' ' + this.state.input[0]
            }
        }
        return prettyString
    }

    submit() {
        if (this.props.onSubmit) {
            this.props.onSubmit({
                logic: this.state.selected.value,
                value: this.state.input,
                prettyString: this.makePrettyString(),
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="text-filter-options"
                            value={this.state.selected}
                            onChange={this.handleSelectChange}
                            options={this.OPTIONS}
                        />
                    </Col>
                </Row>

                {this.state.selected && this.state.selected.showInput ? (
                    <Row>
                        <Col>
                            <hr />
                            {this.props.formulas && this.props.variables ? (
                                <FormulaInput
                                    variables={this.props.variables}
                                    formulas={this.props.formulas}
                                    onChange={this.handleInputChange}
                                    default={this.props.defaultFormulaInput}
                                    type="formula"
                                />
                            ) : (
                                <AsyncTypeahead
                                    isLoading={this.state.loading}
                                    id={Math.random()}
                                    options={this.state.options}
                                    labelKey="id"
                                    minLength={1}
                                    multiple={
                                        this.state.selected.value === 'is' ||
                                        this.state.selected.value === 'isNot'
                                    }
                                    selected={this.state.input}
                                    placeholder={
                                        this.state.selected.label + '...'
                                    }
                                    onChange={this.handleInputChange}
                                    onSearch={this.handleSearch}
                                    searchText="Searching..."
                                />
                            )}
                        </Col>
                    </Row>
                ) : null}

                <Row>
                    <Col className="text-right">
                        <Button
                            className="mt-2"
                            color="secondary"
                            size="xs"
                            disabled={
                                this.state.selected.showInput &&
                                this.state.input.length === 0
                            }
                            onClick={this.submit}>
                            Update filter
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
