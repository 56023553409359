import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import * as sdk from './sdk'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            shedsInput: 1,
            scrapcodes: [],
            loaded: false,
            selected: null,
            moldChangeInput: '',
            shedChangeInput: '',
            jumpChangeInput: '',
            workorderChangeInput: '',
        }
    }

    async fetchScrapCodes() {
        let reasoncodes = await API.get('reasoncodes', 2)

        reasoncodes = reasoncodes.filter((code) => {
            if (!code.assets) {
                return true
            } else {
                return code.assets.find((a) => a === this.props.deviceId)
            }
        })

        reasoncodes = reasoncodes.filter(
            (code) => code.category === 'Changeover'
        )

        let categories = [...new Set(reasoncodes.map((r) => r.category))]
        categories = categories.sort((a, b) => a.localeCompare(b))

        let options = categories.map((c) => {
            const suboptions = reasoncodes
                .filter((r) => r.category === c)
                .map((r) => {
                    return {
                        label: r.reason,
                        value: r._id,
                    }
                })
                .sort((a, b) => a.label.localeCompare(b.label))

            return {
                label: c,
                options: suboptions,
            }
        })

        this.setState({
            scrapcodes: options,
            loaded: true,
        })
    }

    componentDidMount() {
        this.fetchScrapCodes()
    }

    async submit() {
        if (!this.props.downtimeRecord) {
            return alert('Cannot start changeover, not currently in downtime.')
        }

        let timestamp = moment(
            this.props.downtimeRecord.timestamp
        ).toISOString()

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Downtime Category',
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                value: 'Changeover',
                metaData: [
                    {
                        name: 'downtimecategory',
                        value: 'changeover',
                    },
                ],
            },
            2
        )
        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Downtime User',
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                value: JSON.parse(localStorage['userObject'])._id,
                metaData: [
                    {
                        name: 'downtimeuser',
                        value: JSON.parse(localStorage['userObject'])._id,
                    },
                ],
            },
            2
        )
        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Downtime Comment',
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                value: '',
                metaData: [
                    {
                        name: 'downtimecomment',
                        value: '',
                    },
                ],
            },
            2
        )

        await API.post(
            'states',
            {
                nodeId: this.props.deviceId,
                deviceId: this.props.deviceId,
                name: 'Downtime Reason',
                timestamp: timestamp,
                timeStart: timestamp,
                timeEnd: null,
                value: this.state.selected.label,
                metaData: [
                    {
                        name: 'downtimereason',
                        value: this.state.selected.label,
                    },
                    {
                        name: 'mold_change',
                        value: this.state.moldChangeInput,
                    },
                    {
                        name: 'shed_change',
                        value: this.state.shedChangeInput,
                    },
                    {
                        name: 'jump_change',
                        value: this.state.jumpChangeInput,
                    },
                    {
                        name: 'workorder_change',
                        value: this.state.workorderChangeInput,
                    },
                ],
            },
            2
        )

        this.props.toggleModal()
    }

    render() {
        const valid =
            this.state.selected !== null &&
            this.state.moldChangeInput !== '' &&
            this.state.shedChangeInput !== '' &&
            this.state.jumpChangeInput !== '' &&
            this.state.workorderChangeInput !== ''

        return (
            <Fragment>
                {this.state.loaded ? (
                    <Modal isOpen={true} toggle={this.props.toggleModal}>
                        <ModalHeader toggle={this.props.toggleModal}>
                            Start Changeover
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <label>Changeover type:</label>
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select forceLeftAlign"
                                    classNamePrefix="react-select"
                                    name="reason"
                                    value={this.state.selected}
                                    onChange={(selected) =>
                                        this.setState({selected})
                                    }
                                    options={this.state.scrapcodes}
                                    styles={customStyles}
                                    isSearchable={false}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Mold Change:</label>
                                <Input
                                    type="text"
                                    name="mold_change"
                                    value={this.state.moldChangeInput}
                                    onChange={(e) =>
                                        this.setState({
                                            moldChangeInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Shed Change:</label>
                                <Input
                                    type="text"
                                    name="shed_change"
                                    value={this.state.shedChangeInput}
                                    onChange={(e) =>
                                        this.setState({
                                            shedChangeInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Jump Change:</label>
                                <Input
                                    type="text"
                                    name="jump_change"
                                    value={this.state.jumpChangeInput}
                                    onChange={(e) =>
                                        this.setState({
                                            jumpChangeInput: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>W/O Change:</label>
                                <Input
                                    type="text"
                                    name="wo_change"
                                    value={this.state.workorderChangeInput}
                                    onChange={(e) =>
                                        this.setState({
                                            workorderChangeInput:
                                                e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>

                            <p className="mt-5">
                                Note: Changeover will automatically end when
                                your station starts running.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                block
                                color="primary"
                                onClick={this.submit}
                                disabled={!valid}>
                                SUBMIT
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : (
                    <div className="loading" />
                )}
            </Fragment>
        )
    }
}
