import React, {Component, Fragment} from 'react'
import {Row, Col} from 'reactstrap'
import {Bar, Chart} from 'react-chartjs-2'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'

import Subscriber from 'SDK/subscriber'
import * as SDK from './sdk'

Chart.pluginService.register({
    beforeDraw: function (chart, easing) {
        if (
            chart.config.options.chartArea &&
            chart.config.options.chartArea.backgroundColor
        ) {
            var ctx = chart.chart.ctx
            var chartArea = chart.chartArea

            ctx.save()
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor
            ctx.fillRect(
                chartArea.left,
                chartArea.top,
                chartArea.right - chartArea.left,
                chartArea.bottom - chartArea.top
            )
            ctx.restore()
        }
    },
})

export default class Timeline extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        timeStart: PropTypes.date,
        timeEnd: PropTypes.date,
        shiftName: PropTypes.string,
        aggregation: PropTypes.number,
        color: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.color = '#fff'

        if (this.props && this.props.color) {
            this.color = this.props.color
        }

        this.subscriber = new Subscriber()

        this.state = {
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                chartArea: {
                    backgroundColor: 'rgba(0,0,0,1)',
                },
                maintainAspectRatio: false,
                title: {
                    text: '',
                    display: false,
                    fontColor: this.color,
                    fontStyle: 'bold',
                },
                /*tooltips: {
            yAlign: 'top',
            mode: 'label',
            callbacks: {
              title: function(tooltipItem, data) {
                return moment(tooltipItem[0].label).format('h:mm a');
              },
              label: function(tooltipItem, data) {
                return data.datasets[0].states[tooltipItem.index];
              }
            }
        },*/
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: false,
                            categoryPercentage: 1.0,
                            barPercentage: 1.0,
                            type: 'time',
                            time: {
                                unit: 'hour',
                            },
                            ticks: {
                                display: true,
                                fontColor: this.color,
                                fontStyle: 'bold',
                                fontFamily: 'Nunito',
                                maxRotation: 0,
                                autoSkip: true,
                                fontSize: 25,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                max: 1,
                                beginAtZero: 0,
                                display: false,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        }
    }

    async fetchData() {
        this.setState({loading: true})

        const {chartdata, utilization} = await SDK.getTimelineData(
            this.props.deviceId,
            this.props.timeStart,
            this.props.timeEnd || moment(),
            this.props.aggregation
        )

        let options = this.state.options
        options.title.text =
            moment(this.props.timeStart).format('ddd, MMM DD') +
            ' - ' +
            this.props.shiftName

        this.setState({data: chartdata, options, loading: false})

        this.props.updateUtilization(utilization)
    }

    updateLive() {
        if (this.props.timeEnd === null) this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.timeEnd !== null &&
            !moment(this.props.timeStart).isSame(prevProps.timeStart)
        ) {
            this.fetchData()
        } else if (this.props.timeEnd === null && prevProps.timeEnd !== null) {
            this.fetchData()
        } else if (
            this.props.timeEnd === null &&
            !moment(this.props.timeStart).isSame(moment(prevProps.timeStart))
        ) {
            this.fetchData()
        }
    }

    componentWillMount() {
        this.subscriber.add(this.updateLive, 1000 * 60, 'fetchData')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const rawHeight = document.documentElement.offsetHeight - 160,
            height = Math.floor(rawHeight * 0.1)
        return (
            <>
                {!this.props.hidden ? (
                    <Row>
                        <Col>
                            <Bar
                                data={this.state.data}
                                options={this.state.options}
                                height={height}
                            />
                        </Col>
                    </Row>
                ) : null}
            </>
        )
    }
}
