import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import flows from './flows'
import flowEditor from './flow-editor'
import InsightEngine from 'SDK/ui/widgets/InsightEngine'
import Splash from './splash'

export default (props) => {
    const {match, editingEnvironment, environment} = props

    return (
        <Switch>
            <Route path={`${match.url}/flows`} component={flows} />
            <Route
                path={`${match.url}/flow-editor/:deviceId/:id`}
                component={flowEditor}
            />
            <Route
                path={`${match.url}/insight-engine`}
                render={(props) => <InsightEngine isCore={true} {...props} />}
            />
            <Route
                path={`${match.url}/splash`}
                editingEnvironment={editingEnvironment}
                environment={environment}
                render={(props) => (
                    <Splash
                        editingEnvironment={editingEnvironment}
                        environment={environment}
                        {...props}
                    />
                )}
            />
            <Redirect to="/error" />
        </Switch>
    )
}
