import React, {Component, Fragment} from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import autoBind from 'auto-bind'
import ReactJson from 'react-json-view'
import {cloneDeep} from 'lodash-es'

const EXAMPLE_PAGINATE_RESPONSE = {
    count: 1,
    result: {
        hasNext: false,
        hasPrevious: false,
        next: 'eyIkb2lkIjoiNWZjMDNiNDU5MWJiMTlkZjM1NDk2NDgzIn0',
        previous: 'eyIkb2lkIjoiNWZjMDNiNDU5MWJiMTlkZjM1NDk2NDgzIn0',
        results: [
            {
                _id: '5fc03b4591bb19df35496483',
                '5fc00759500d3ace394cc9d6': 5,
                dataModelId: '5fc00745500d3ace394cc9d5',
                deleted: false,
            },
        ],
    },
}

const EXAMPLE_REPORT_RESPONSE = {
    schema: {
        fields: [
            {
                name: 'timestamp',
                type: 'datetime',
            },
            {
                name: 'value',
                type: 'string',
            },
        ],
        pandas_version: '0.20.0',
        primary_key: ['index'],
    },
    data: [
        {
            timestamp: '2021-01-01T00:00:00.000Z',
            value: '5',
        },
    ],
}

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }

    paginate() {
        const {dataModel} = this.props
        let paginate = cloneDeep(this.props.paginate)

        paginate.filter = paginate.filters.map((f) => {
            return {
                type: f.type,
                logic: f.logic,
                value: f.value,
                path: f.path,
            }
        })
        delete paginate.filters
        return (
            <>
                <h3>
                    <strong>Request URL</strong>
                </h3>
                <p style={{userSelect: 'all'}}>
                    POST https://api.accuminetech.com/v2/data-models/
                    {dataModel._id}/paginate
                </p>
                <p>
                    <small>
                        Where {dataModel._id} is the Data Model Id of{' '}
                        {dataModel.name}
                    </small>
                </p>

                <hr />

                <h3>
                    <strong>Request Body</strong>
                </h3>
                <ReactJson src={paginate} name={false} />
                <hr />

                <h3>
                    <strong>Response Format</strong>
                </h3>
                <p>This is not the actual response from the generated query</p>
                <ReactJson src={EXAMPLE_PAGINATE_RESPONSE} name={false} />
            </>
        )
    }

    report() {
        const {dataModel} = this.props
        let report = cloneDeep(this.props.report)

        for (let i in report.filter) {
            delete report.filter[i].prettyString
            delete report.filter[i].fieldId
            delete report.filter[i].model
            delete report.filter[i].key
            delete report.filter[i].parentFieldId
            delete report.filter[i].title
            delete report.filter[i].field
            delete report.filter[i].icon
            delete report.filter[i].referenceDataModelId
            delete report.filter[i].referenceId
            delete report.filter[i].referenceDepth
            delete report.filter[i].parentReferences
        }
        for (let i in report.groups) {
            if (report.groups[i].path !== '@@time@@') {
                delete report.groups[i].timeUnit
            }
        }
        delete report.timerange.prettyString
        return (
            <>
                <h3>
                    <strong>Request URL</strong>
                </h3>
                <p style={{userSelect: 'all'}}>
                    POST https://api.accuminetech.com/v2/data-models/
                    {dataModel._id}/aggregate
                </p>
                <p>
                    <small>
                        Where {dataModel._id} is the Data Model Id of{' '}
                        {dataModel.name}
                    </small>
                </p>

                <hr />

                <h3>
                    <strong>Request Body</strong>
                </h3>
                <ReactJson src={report} name={false} />
                <hr />

                <h3>
                    <strong>Response Format</strong>
                </h3>
                <p>This is not the actual response from the generated query</p>
                <ReactJson src={EXAMPLE_REPORT_RESPONSE} name={false} />
            </>
        )
    }

    render() {
        let {toggle, open} = this.props

        return (
            <Fragment>
                <Modal size="lg" isOpen={open} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        {this.props.type === 'paginate' ? 'Paginate' : 'Report'}{' '}
                        API Call Generator
                    </ModalHeader>
                    <ModalBody>
                        {this.props.type === 'paginate' ? (
                            <>{this.paginate()}</>
                        ) : (
                            <>{this.report()}</>
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
