import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class HubbellCentraliaGalvanizing extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'HubbellCentraliaGalvanizing'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            data: {},
        }
    }

    async fetchData() {
        const records = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: '5ff34b22e80e900014f46941',
                    timeEnd: null,
                },
            },
            2
        )

        let data = {}

        for (let row of records) {
            data[row.name] = row.value
        }

        console.log(data)

        this.setState({data})
    }

    async componentDidMount() {
        this.subscriber.add(this.fetchData, 1000 * 30, 'fetchData()')
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        const {data} = this.state
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <h5 style={{textAlign: 'center'}}>
                                    <strong>C Kettle</strong>
                                </h5>
                                <hr />
                                <Row>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Metal Temp (°F)</p>
                                                <h1>
                                                    {parseInt(
                                                        data[
                                                            'C Kettle Metal Temp'
                                                        ]
                                                    )}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Over Temp (°F)</p>
                                                <h1>
                                                    {parseInt(
                                                        data[
                                                            'C Kettle Over Temp'
                                                        ]
                                                    )}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'C Kettle Bottom East Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'C Kettle Bottom East Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Bottom East Burner</p>
                                                <h1>
                                                    {data[
                                                        'C Kettle Bottom East Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'C Kettle Bottom West Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'C Kettle Bottom West Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Bottom West Burner</p>
                                                <h1>
                                                    {data[
                                                        'C Kettle Bottom West Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'C Kettle Top East Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'C Kettle Top East Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Top East Burner</p>
                                                <h1>
                                                    {data[
                                                        'C Kettle Top East Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'C Kettle Top West Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'C Kettle Top West Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Top West Burner</p>
                                                <h1>
                                                    {data[
                                                        'C Kettle Top West Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>
                        <Card>
                            <CardBody>
                                <h5 style={{textAlign: 'center'}}>
                                    <strong>B Kettle</strong>
                                </h5>
                                <hr />
                                <Row>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Metal Temp (°F)</p>
                                                <h1>
                                                    {parseInt(
                                                        data[
                                                            'B Kettle Metal Temp'
                                                        ]
                                                    )}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Over Temp (°F)</p>
                                                <h1>
                                                    {parseInt(
                                                        data[
                                                            'B Kettle Over Temp'
                                                        ]
                                                    )}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'B Kettle Bottom East Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'B Kettle Bottom East Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Bottom East Burner</p>
                                                <h1>
                                                    {data[
                                                        'B Kettle Bottom East Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'B Kettle Bottom West Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'B Kettle Bottom West Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Bottom West Burner</p>
                                                <h1>
                                                    {data[
                                                        'B Kettle Bottom West Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'B Kettle Top East Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'B Kettle Top East Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Top East Burner</p>
                                                <h1>
                                                    {data[
                                                        'B Kettle Top East Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: !data[
                                                        'B Kettle Top West Burner Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: !data[
                                                        'B Kettle Top West Burner Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Top West Burner</p>
                                                <h1>
                                                    {data[
                                                        'B Kettle Top West Burner Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="2" className="mt-1">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    backgroundColor: data[
                                                        'B Kettle Leak Detector Status'
                                                    ]
                                                        ? '#df4759'
                                                        : '',
                                                    color: data[
                                                        'B Kettle Leak Detector Status'
                                                    ]
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                <p>Leak Detector</p>
                                                <h1>
                                                    {data[
                                                        'B Kettle Leak Detector Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OK'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col xs="12" sm="4">
                        <Card>
                            <CardBody>
                                <h5 style={{textAlign: 'center'}}>
                                    <strong>Dust Collector</strong>
                                </h5>
                                <hr />
                                <Row>
                                    <Col xs="12" sm="6">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Diff Press (in H2O)</p>
                                                <h1>
                                                    {Math.round(
                                                        data[
                                                            'Dust Collector Diff Press'
                                                        ] * 100
                                                    ) / 100}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Status</p>
                                                <h1>
                                                    {data[
                                                        'Dust Collector Running Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="4">
                        <Card>
                            <CardBody>
                                <h5 style={{textAlign: 'center'}}>
                                    <strong>Scrubber</strong>
                                </h5>
                                <hr />
                                <Row>
                                    <Col xs="12" sm="6">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>VFD Amps</p>
                                                <h1>
                                                    {Math.round(
                                                        data[
                                                            'Scrubber VFD Amps'
                                                        ] * 10
                                                    ) / 10}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Status</p>
                                                <h1>
                                                    {data['Scrubber VFD Status']
                                                        ? 'RUNNING'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="4">
                        <Card>
                            <CardBody>
                                <h5 style={{textAlign: 'center'}}>
                                    <strong>Boiler</strong>
                                </h5>
                                <hr />
                                <Row>
                                    <Col xs="12" sm="12">
                                        <Card>
                                            <CardBody
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}>
                                                <p>Status</p>
                                                <h1>
                                                    {data[
                                                        'Boiler Running Status'
                                                    ]
                                                        ? 'ON'
                                                        : 'OFF'}
                                                </h1>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
