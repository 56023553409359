import React, {Component, Fragment} from 'react'
import {Button, Row, Col, Badge} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import SideModal from './sideModal'
import MapModal from './mapModal'
import TrainModal from './trainModal'

export default class IPSModelManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'IPSModelManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            models: [],
            devices: [],
            assets: [],
            editingModel: null,
        }
    }
    async fetchModels() {
        let models = await API.get('ips/cloud/models', 2)
        this.setState({models})
    }

    async fetchDevices() {
        const devices = await API.get('ips/cloud/devices', 2)
        this.setState({devices})
    }

    async fetchAssets() {
        const assets = await API.get('devices')
        this.setState({assets})
    }

    columns() {
        return [
            {
                Header: 'Version',
                accessor: 'version',
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            background:
                                rowInfo.row._original &&
                                rowInfo.row._original.active
                                    ? '#17a2b8'
                                    : null,
                            color:
                                rowInfo.row._original &&
                                rowInfo.row._original.active
                                    ? '#fff'
                                    : null,
                        },
                    }
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            background:
                                rowInfo.row._original &&
                                rowInfo.row._original.active
                                    ? '#17a2b8'
                                    : null,
                            color:
                                rowInfo.row._original &&
                                rowInfo.row._original.active
                                    ? '#fff'
                                    : null,
                        },
                    }
                },
            },
            {
                Header: 'Used By',
                accessor: 'usedBy',
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            background:
                                rowInfo.row._original &&
                                rowInfo.row._original.active
                                    ? '#17a2b8'
                                    : null,
                            color:
                                rowInfo.row._original &&
                                rowInfo.row._original.active
                                    ? '#fff'
                                    : null,
                        },
                    }
                },
            },
        ]
    }

    addModel() {
        this.setState({
            editingModel: null,
            sideModal: !this.state.sideModal,
        })
    }
    toggleSideModal() {
        this.setState({
            sideModal: !this.state.sideModal,
        })
    }

    async toggleMapModal() {
        if (this.state.mapModal && this.state.editingModel) {
            await this.fetchModels()
            this.setState({
                editingModel: this.state.models.find(
                    (m) => m._id === this.state.editingModel._id
                ),
            })
        }
        this.setState({
            mapModal: !this.state.mapModal,
        })
    }

    async toggleTrainModal() {
        if (this.state.trainModal && this.state.editingModel) {
            await this.fetchModels()
            this.setState({
                editingModel: this.state.models.find(
                    (m) => m._id === this.state.editingModel._id
                ),
            })
        }

        this.setState({
            trainModal: !this.state.trainModal,
        })
    }

    dataTableFormatter(model) {
        model.usedBy =
            this.state.devices.filter((d) => d.model === model.version).length +
            ' assets'

        return model
    }

    async componentWillMount() {
        this.subscriber.add(this.fetchAssets, 1000 * 10, 'fetchAssets')
        this.subscriber.add(this.fetchDevices, 1000 * 10, 'fetchDevices')
        this.subscriber.add(this.fetchModels, 1000, 'fetchModels')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const addFirstModelEl = (
                <Button color="primary" onClick={this.addModel}>
                    Add Your First Model
                </Button>
            ),
            tableExists = this.state.models.length > 0

        return (
            <Fragment>
                {tableExists ? (
                    <Row>
                        <Col
                            xs="12"
                            className="mb-2"
                            style={{textAlign: 'right'}}>
                            <Button color="primary" onClick={this.addModel}>
                                Add Model
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <CRUD
                    uniqueElementId="version"
                    emptyCrudMainText="No Models Found"
                    emptyCrudSubText={addFirstModelEl}
                    crudTableColumns={this.columns()}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.models}
                    rowClickFn={(model) => {
                        this.setState({editingModel: model})
                        this.toggleSideModal(model)
                    }}
                />
                {this.state.sideModal ? (
                    <SideModal
                        toggleModal={this.toggleSideModal}
                        modal={this.state.sideModal}
                        models={this.state.models}
                        editingModel={this.state.editingModel}
                        toggleMapModal={this.toggleMapModal}
                        toggleTrainModal={this.toggleTrainModal}
                    />
                ) : null}

                {this.state.mapModal ? (
                    <MapModal
                        toggleModal={this.toggleMapModal}
                        modal={this.state.mapModal}
                        models={this.state.models}
                        editingModel={this.state.editingModel}
                    />
                ) : null}

                {this.state.trainModal ? (
                    <TrainModal
                        toggleModal={this.toggleTrainModal}
                        modal={this.state.trainModal}
                        editingModel={this.state.editingModel}
                    />
                ) : null}
            </Fragment>
        )
    }
}
