export default class Model {
    constructor({
        version,
        description,
        backgroundImage,
        backgroundImageHeight,
        backgroundImageWidth,
        pois,
        active,
    }) {
        this.sync({
            version,
            description,
            backgroundImage,
            backgroundImageHeight,
            backgroundImageWidth,
            pois,
            active,
        })
    }

    sync({
        version,
        description,
        backgroundImage,
        backgroundImageHeight,
        backgroundImageWidth,
        pois,
        active,
    }) {
        this.version = version
        this.description = description
        this.backgroundImage = backgroundImage
        this.backgroundImageHeight = backgroundImageHeight
        this.backgroundImageWidth = backgroundImageWidth
        this.pois = pois
        this.active = active
    }
}
