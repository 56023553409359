import React from 'react'
import ReactExport from 'react-export-excel'
import autoBind from 'auto-bind'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default class FileDownload extends React.Component {
    state = {
        columnDefinitions: [],
    }

    constructor(props) {
        super(props)
        autoBind(this)
    }

    componentDidMount() {
        this.defineColumns()
    }

    defineColumns() {
        let columnHeaders = []
        this.props.columns.forEach((col) => {
            columnHeaders.push(<ExcelColumn label={col} value={col} />)
        })
        this.setState({columnHeaders})
    }

    render() {
        return (
            <ExcelFile element={this.props.element}>
                <ExcelSheet data={[]} name="Sheet 1">
                    {this.state.columnHeaders}
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
