import React, {Component} from 'react'
import {Card, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import Tree from 'rc-tree'
import 'rc-tree/assets/index.css'
import {Scrollbars} from 'react-custom-scrollbars'

export default class Sidebar extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            loading: true,
            treeData: [],
        }
    }

    treeify() {
        let result = []
        let level = {result}
        this.props.dataModels
            .map((a) => a.name)
            .sort((a, b) => a.localeCompare(b))
            .forEach((path) => {
                path.split('/').reduce((r, name, i, a) => {
                    if (!r[name]) {
                        r[name] = {result: []}
                        const endPoint =
                            path.split('/')[path.split('/').length - 1] === name
                        const dataModel = this.props.dataModels.find(
                            (a) =>
                                a.name === path &&
                                a.parentDataModelId === null &&
                                endPoint
                        )
                        r.result.push({
                            key: dataModel ? dataModel._id : Math.random(),
                            title: name,
                            children: r[name].result,
                            dataModel,
                        })
                    }

                    return r[name]
                }, level)
            })
        return result
    }

    handleSelect(selectedKeys, {selectedNodes}) {
        const dataModel = selectedNodes[0].dataModel
        if (dataModel) {
            this.props.onDataModelChange(dataModel)
        }
    }

    render() {
        const treeData = this.treeify()

        return (
            <Card style={{height: this.props.height}}>
                <CardBody style={{height: this.props.height, padding: 0}}>
                    <Scrollbars
                        autoHeight
                        autoHeightMin={this.props.height}
                        autoHeightMax={this.props.height}>
                        <Tree
                            className="filter-tree"
                            showLine
                            treeData={treeData}
                            onSelect={this.handleSelect}
                        />
                    </Scrollbars>
                </CardBody>
            </Card>
        )
    }
}
