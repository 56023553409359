import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        let belongsTo = this.props.assets.find(
            (asset) => asset.deviceId === node.deviceId
        )

        this.state = {
            selectedAsset: belongsTo
                ? {label: belongsTo.name, value: belongsTo.deviceId}
                : {label: 'Not Commissioned', value: 'none'},
        }
    }
    handleChange(e) {
        this.setState({
            selectedAsset: e,
        })
        this.save(e.value)
    }
    async save(deviceId) {
        try {
            //console.log(this.state.selectedAsset.value)
            let response = await API.patch(
                'nodes/' + this.props.editingNode.nodeId,
                {
                    deviceId: deviceId,
                },
                2
            )
            if (response.success) {
                //this.props.toggleModal('assetModal');
            } else {
                return alert(
                    'There was an error saving your change. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error saving your change. Please try again.'
            )
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {assets} = this.props
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="mb-0">
                        <Form>
                            <Label className="form-group has-top-label">
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="asset-commission"
                                    value={this.state.selectedAsset}
                                    onChange={this.handleChange}
                                    options={[
                                        {
                                            label: 'Not Commissioned',
                                            value: 'none',
                                        },
                                    ].concat(
                                        assets.map((a, i) => {
                                            return {
                                                label: a.name,
                                                value: a.deviceId,
                                            }
                                        })
                                    )}
                                />
                                <IntlMessages id="Asset Commission" />
                            </Label>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
