import React, {Component, Fragment} from 'react'
import {Bar, Chart} from 'react-chartjs-2'
import moment from 'moment'
import autobind from 'auto-bind'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {Row, Col} from 'reactstrap'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
Chart.plugins.unregister(ChartDataLabels)

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            loaded: false,
            chartdata: {},
            options: {
                maintainAspectRatio: false,
                title: {
                    text: 'Active Shift',
                    display: true,
                    fontColor: '#fff',
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                            type: 'time',
                            time: {
                                unit: 'hour',
                            },
                            ticks: {
                                fontColor: '#fff',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                        },
                    ],
                },
            },
        }
    }

    async makeChart() {
        const response = await API.post('historical', {
            timeStart: this.props.timerange[0],
            timeEnd: this.props.timerange[1],
            deviceId: this.props.deviceId,
            state: 'Part Count',
            unit: 'hours',
        })
        if (!response) {
            return // alert('There was an error fetching data.');
        }

        this.setState({
            loaded: true,
            chartdata: {
                labels: response.dates.map((d) => moment(d).toDate()),
                datasets: [
                    {
                        label: 'Actual',
                        data: response.count,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        borderColor: 'black',
                        borderWidth: 1,
                        datalabels: {
                            color: '#fff',
                            align: 'top',
                            display: function (context) {
                                return (
                                    context.dataset.data[context.dataIndex] > 0
                                )
                            },
                        },
                    },
                    {
                        label: 'Target',
                        type: 'line',
                        data: new Array(response.count.length).fill(
                            this.state.target
                        ),
                        backgroundColor: 'transparent',
                        borderColor: 'darkgrey',
                        pointRadius: 0,
                        datalabels: {
                            display: false,
                        },
                    },
                ],
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.target !== this.state.target) {
            this.setState({target: nextProps.target})
            this.makeChart()
        }
    }

    componentDidMount() {
        this.subscriber.add(this.makeChart, 1000 * 60 * 5, 'makeChart()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const isLoading = !this.state.loaded
        return isLoading ? null : (
            <Row>
                <Col>
                    <Bar
                        data={this.state.chartdata}
                        options={this.state.options}
                        plugins={[ChartDataLabels]}
                    />
                </Col>
            </Row>
        )
    }
}
