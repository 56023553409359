import React, {Component, Fragment} from 'react'
import {Row, Card, CardBody} from 'reactstrap'
import PropTypes from 'prop-types'

import {Colxx, Separator} from 'components/CustomBootstrap'

import EmptyCrud from './EmptyCrud'
import CrudTable from './CrudTable'
import EditorModal from './EditorModal'

export default class CrudView extends Component {
    static propTypes = {
        // Unique id of list
        uniqueElementId: PropTypes.string.isRequired,

        // EmptyCrud
        emptyCrudMainText: PropTypes.string,
        emptyCrudSubText: PropTypes.any,

        // CrudTable
        crudTableColumns: PropTypes.arrayOf(
            PropTypes.shape({
                Header: PropTypes.string.isRequired,
                accessor: PropTypes.string.isRequired,
            })
        ).isRequired,
        crudTableResolveDataMapFn: PropTypes.func,
        //crudTableRowClickFn: PropTypes.func,
        crudTableData: PropTypes.array,
        rowClickFn: PropTypes.func,
        // EditorModal
        //isOpen: PropTypes.bool.isRequired,
        //toggle: PropTypes.func.isRequired,
        useEditorModal: PropTypes.bool,
        editorModalTitle: PropTypes.string.isRequired,
        editorModalAttributes: PropTypes.func.isRequired,
        editorModalActions: PropTypes.arrayOf(
            PropTypes.shape({
                columnSize: PropTypes.number.isRequired,
                onClickFn: PropTypes.func.isRequired,
                actionName: PropTypes.string.isRequired,
                buttonColor: PropTypes.string.isRequired,
            })
        ).isRequired,
        editorModalMessages: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.toggleEditorModal = this.toggleEditorModal.bind(this)

        this.state = {
            editorModalOpen: false,
            editingElement: null,
        }

        this.handleRowClick = this.props.useEditorModal
            ? this.toggleEditorModal
            : this.props.rowClickFn
    }
    toggleEditorModal(row, override) {
        let toggleValue =
            override !== undefined ? override : !this.state.editorModalOpen
        this.setState({
            editingElement: row,
            editorModalOpen: toggleValue,
        })
    }
    render() {
        const isEmpty = this.props.crudTableData.length === 0
        let editingElement = null

        if (this.state.editingElement) {
            editingElement = this.props.crudTableData.find(
                (el) =>
                    el[this.props.uniqueElementId] ===
                    this.state.editingElement[this.props.uniqueElementId]
            )
        }
        return (
            <Fragment>
                {isEmpty ? (
                    <EmptyCrud
                        mainText={this.props.emptyCrudMainText}
                        subText={this.props.emptyCrudSubText}
                    />
                ) : (
                    <Row>
                        <Colxx xs="12">
                            <Card>
                                <CardBody>
                                    <CrudTable
                                        columns={this.props.crudTableColumns}
                                        resolveDataMapFn={
                                            this.props.crudTableResolveDataMapFn
                                        }
                                        rowClickFn={this.handleRowClick}
                                        data={this.props.crudTableData}
                                    />
                                </CardBody>
                            </Card>
                        </Colxx>
                    </Row>
                )}
                <EditorModal
                    title={this.props.editorModalTitle}
                    isOpen={this.state.editorModalOpen}
                    toggle={this.toggleEditorModal}
                    attributes={this.props.editorModalAttributes}
                    actions={this.props.editorModalActions}
                    messages={this.props.editorModalMessages}
                    editingElement={editingElement}
                />
            </Fragment>
        )
    }
}
