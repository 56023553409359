import React, {useState} from 'react'
import {
    Form,
    FormGroup,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card,
    CardFooter,
    CardBody,
    CardHeader,
    Label,
    Input,
} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'

import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'
import FormulaInput from '../../../../common/FormulaInput'

export default (props) => {
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'SendFormData',
        description: props.element.data.opts.description || '',
        method: 'post',
        saveDataRequestURL:
            props.element.data.opts.saveDataRequestURL ||
            'https://data-models.accuminetech.com/data-models',
        storeResponse: props.element.data.opts.storeResponse || {
            enabled: false,
            dataPath: '',
            multi: false,
            dataModelId: '',
        },
        url: props.element.data.opts.url || {
            '@@isExpression@@': true,
            mentions: [],
            text: '',
            plainText: '',
            type: 'string',
            map: [],
        },
        variableId: props.element.data.opts.variableId || null,
        formFields: props.element.data.opts.formFields || [],
        submitted: props.element.data.opts.submitted || true,
    })

    const renderFieldRow = ({
        id,
        value = {
            text: '',
            plainText: '',
            mentions: [],
            type: 'formula',
            map: [],
        },
    }) => {
        const idx = parameters.formFields.findIndex((m) => m.id === id)

        return (
            <Row className="mb-4">
                <Col xs="12">
                    <Card>
                        <CardHeader className="m-0 p-0">
                            <h5 className="m-0 p-0 pl-4 pt-4">
                                Field #{idx + 1}
                            </h5>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Row>
                                <Col xs="12">
                                    <hr />
                                </Col>
                                <Col xs="12" className="mb-2">
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.formFields[idx].key = v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Field Name (Key)"
                                        existing={parameters.formFields
                                            .filter((a) => a.id !== id)
                                            .map((a) => a.key)}
                                        initial={parameters.formFields[idx].key}
                                        disabled={false}
                                    />
                                </Col>
                                <Col xs="12">
                                    <FormulaInput
                                        variables={props.variables.filter(
                                            (v) =>
                                                !v.multi &&
                                                v.transformId !==
                                                    props.element.id
                                        )}
                                        formulas={props.formulas}
                                        default={value || null}
                                        type="formula"
                                        onChange={(value) => {
                                            parameters.formFields[idx].value =
                                                value
                                            parameters.formFields[
                                                idx
                                            ].value.type = 'formula'
                                            setParameters({...parameters})
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button
                                size="xs"
                                onClick={() => {
                                    parameters.formFields =
                                        parameters.formFields.filter(
                                            (m) => m.id !== id
                                        )
                                    setParameters({...parameters})
                                }}
                                color="danger">
                                Remove
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )

        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Text',
            multi: false,
        }
        parameters.url.type = 'string'
        parameters.variableId = variable.id

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Request URL</Label>
                            <FormulaInput
                                variables={props.variables.filter(
                                    (v) =>
                                        !v.multi &&
                                        v.transformId !== props.element.id
                                )}
                                formulas={props.formulas}
                                default={parameters.url}
                                type="string"
                                onChange={(expression) => {
                                    parameters.url = expression
                                    setParameters({...parameters})
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="mr-2">Method:</Label>
                            <ButtonGroup>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        parameters.method = 'get'
                                        setParameters({...parameters})
                                    }}
                                    active={parameters.method === 'get'}>
                                    Get
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        parameters.method = 'post'
                                        setParameters({...parameters})
                                    }}
                                    active={parameters.method === 'post'}>
                                    Post
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        parameters.method = 'patch'
                                        setParameters({...parameters})
                                    }}
                                    active={parameters.method === 'patch'}>
                                    Patch
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        parameters.method = 'delete'
                                        setParameters({...parameters})
                                    }}
                                    active={parameters.method === 'delete'}>
                                    Delete
                                </Button>
                            </ButtonGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Store response data{' '}
                                <Switch
                                    checked={parameters.storeResponse.enabled}
                                    onChange={() => {
                                        parameters.storeResponse.enabled =
                                            !parameters.storeResponse.enabled
                                        setParameters({...parameters})
                                    }}
                                />
                            </Label>
                        </FormGroup>

                        {parameters.storeResponse.enabled ? (
                            <>
                                <FormGroup>
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.storeResponse.dataPath =
                                                v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Data Path (i.e. 'data')"
                                        existing={[]}
                                        initial={
                                            parameters.storeResponse.dataPath
                                        }
                                        disabled={false}
                                    />
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="multi"
                                            checked={
                                                parameters.storeResponse
                                                    .multi === false
                                            }
                                            onChange={(e) => {
                                                if (
                                                    e.currentTarget.value ===
                                                    'on'
                                                ) {
                                                    parameters.storeResponse.multi = false
                                                    setParameters({
                                                        ...parameters,
                                                    })
                                                }
                                            }}
                                        />
                                        Single Record
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="multi"
                                            checked={
                                                parameters.storeResponse
                                                    .multi === true
                                            }
                                            onChange={(e) => {
                                                if (
                                                    e.currentTarget.value ===
                                                    'on'
                                                ) {
                                                    parameters.storeResponse.multi = true
                                                    setParameters({
                                                        ...parameters,
                                                    })
                                                }
                                            }}
                                        />
                                        Multi Records
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <SelectInput
                                        onChange={(m) => {
                                            parameters.storeResponse.dataModelId =
                                                m
                                            setParameters({...parameters})
                                        }}
                                        fieldName="Data Model"
                                        default={
                                            parameters.storeResponse.dataModelId
                                                ? {
                                                      label: props.dataModels.find(
                                                          (m) =>
                                                              m._id ===
                                                              parameters
                                                                  .storeResponse
                                                                  .dataModelId
                                                      ).name,
                                                      value: parameters
                                                          .storeResponse
                                                          .dataModelId,
                                                  }
                                                : null
                                        }
                                        options={props.dataModels.map((m) => {
                                            return {
                                                label: m.name,
                                                value: m._id,
                                            }
                                        })}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        onValueChange={(v) => {
                                            parameters.saveDataRequestURL = v
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Request URL"
                                        existing={[]}
                                        initial={parameters.saveDataRequestURL}
                                        disabled={false}
                                    />
                                </FormGroup>
                            </>
                        ) : null}

                        <FormGroup className="text-right">
                            <Button
                                size="xs"
                                onClick={() => {
                                    parameters.formFields.push({id: uuidv4()})
                                    setParameters({...parameters})
                                }}>
                                Add field
                            </Button>
                        </FormGroup>

                        <FormGroup>
                            {parameters.formFields.map((m) =>
                                renderFieldRow(m)
                            )}
                        </FormGroup>
                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
