import React, {Fragment, Component} from 'react'
import {Row, Col} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import Sidebar from '../common/Sidebar'

import * as API from 'SDK/api'
import Viewer from '../SDK/ReportViewer'

export default class ReportViewer extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ReportViewer'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            dataModels: [],
            savedReportsDataModel: null,
            reports: [],
            selectedReport: null,
            loading: false,
        }
    }

    async syncDataModels() {
        this.setState({loading: true})
        let dataModels = await API.get('data-models', 2)
        this.setState({dataModels, loading: false})
    }

    async syncSavedReports() {
        let savedReportsDataModel = this.state.dataModels.find(
                (a) => a.name === 'System/Saved Reports'
            ),
            reports = []
        if (!savedReportsDataModel) {
            return alert(
                'Import the "System/Saved Reports" Data Model in the Manager module to begin saving reports.'
            )
        } else {
            const {result} = await API.post(
                `data-models/${savedReportsDataModel._id}/paginate`,
                {filter: [], sort: {}, next: null, previous: null, limit: 300},
                2
            )
            reports = result.results.map((a) => {
                a.name = a.report_name
                a.parentDataModelId = null
                return a
            })
        }
        this.setState({savedReportsDataModel, reports})
    }

    handleReportChange(selectedReport) {
        this.setState({selectedReport})
    }

    backToMenu() {
        this.setState({
            selectedDataModel: null,
            filters: [],
            sort: {},
        })
    }

    async componentDidMount() {
        await this.syncDataModels()
        await this.syncSavedReports()

        const query = new URLSearchParams(this.props.history.location.search),
            reportId = query.get('reportId')
        if (reportId) {
            this.setState({
                selectedReport: this.state.reports.find(
                    (a) => a._id === reportId
                ),
            })
        }
    }

    render() {
        const height = document.documentElement.offsetHeight * 0.7
        return (
            <Fragment>
                <Row>
                    <Col xs="3">
                        <Sidebar
                            height={height}
                            dataModels={this.state.reports}
                            onDataModelChange={this.handleReportChange}
                        />
                    </Col>
                    <Col xs="9">
                        {this.state.selectedReport ? (
                            <Viewer
                                report={this.state.selectedReport}
                                history={this.props.history}
                            />
                        ) : null}
                    </Col>
                </Row>

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
