import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Label,
    CardFooter,
    Form,
    Input,
    CardHeader,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import IntlMessages from 'util/IntlMessages'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import {secondsToHHMMSS} from 'SDK/helpers'
import './index.css'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import DowntimeEntryModule from '../../DowntimeEntryModule'

const COLOR_CODE = {
    RUNNING: 'rgb(46, 204, 113)',
    DOWNTIME_UNDER_NO_REASON: 'rgb(231, 76, 60)',
    DOWNTIME_UNDER_REASON: '#f0ad4e',
    DOWNTIME_OVER_NO_REASON: 'rgb(231, 76, 60)',
    DOWNTIME_OVER_REASON: '#f0ad4e',
    UNKNOWN: 'rgb(85, 85, 92)',
    LOADING: 'rgb(85, 85, 92)',
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        paddingLeft: 20,
        textAlign: 'left',
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: '#000',
        fontSize: 15,
        textAlign: 'left',
    }),
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            status: 'LOADING',
            since: moment(),
            downtime: null,
            showDowntimeEntryModal: false,
            users: [],
            shifts: [],
            activeShift: null,
            lastShiftBreakActive: false,
            totalScrap: 0,
            showScrapEntryModal: false,
            startOfShift: null,
            locked: false,
            comment: '',
            reasoncodes: [],
        }
    }

    toggleDowntimeEntryModal() {
        this.setState({
            showDowntimeEntryModal: !this.state.showDowntimeEntryModal,
        })
    }

    toggleScrapEntryModal() {
        this.setState({
            showScrapEntryModal: !this.state.showScrapEntryModal,
            scrapAmount: this.state.totalScrap,
            enteringOtherScrapAmount: false,
            otherScrapAmount: '',
        })
    }

    async toggleShowPreviousDowntimeModal() {
        let reasoncodes = await API.get('reasoncodes', 2)
        reasoncodes = reasoncodes
            .filter((code) => {
                if (!code.assets) {
                    return true
                } else {
                    return code.assets.find((a) => a === this.props.deviceId)
                }
            })
            .sort((a, b) => {
                return a.category - b.category || a.reason - b.reason
            })
            .map((a) => {
                return {
                    reason: a.reason,
                    category: a.category,
                    label: a.category + ' > ' + a.reason,
                    value: a._id,
                }
            })

        this.setState({
            showPreviousDowntimeModal: !this.state.showPreviousDowntimeModal,
            reasoncodes,
        })
    }

    async fetchUsers() {
        this.setState({users: this.props.users})
    }

    async fetchShifts() {
        this.setState({shifts: this.props.shifts})
    }

    async shiftScrapDataHandler({activeShifts, activeShiftBreaks}) {
        let activeShift = activeShifts.length ? activeShifts[0] : null,
            activeShiftBreak = activeShiftBreaks.length
                ? activeShiftBreaks[0]
                : null,
            lastShiftBreakActive = false,
            startOfShift = null

        if (activeShift) {
            const shiftSchedule = this.state.shifts.find((shift) => {
                return (
                    shift.name === activeShift &&
                    shift.assets.find(
                        (deviceId) => deviceId === this.props.deviceId
                    )
                )
            })

            if (shiftSchedule) {
                let timespan = [
                    moment().set({
                        hour: shiftSchedule.timeStart.hour,
                        minute: shiftSchedule.timeStart.minute,
                    }),
                    moment().set({
                        hour: shiftSchedule.timeEnd.hour,
                        minute: shiftSchedule.timeEnd.minute,
                    }),
                ]

                if (shiftSchedule.timeStart.hour > shiftSchedule.timeEnd.hour) {
                    timespan[0].add(-1, 'days')
                }

                timespan[0].startOf('minute')
                timespan[1].startOf('minute')

                this.setState({shiftStart: moment(timespan[0])})

                startOfShift = timespan[0].toISOString()

                const scrap = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Reject',
                            timeStart: moment(timespan[0])
                                .add(1, 'minutes')
                                .toISOString(),
                        },
                    },
                    2
                )

                if (scrap.length) {
                    this.setState({totalScrap: scrap[0].value})
                } else {
                    this.setState({totalScrap: 0})
                }

                if (activeShiftBreak) {
                    let lastShiftBreak = shiftSchedule.breaks.find((b) => {
                        return (
                            b.timeEnd.hour === shiftSchedule.timeEnd.hour &&
                            b.timeEnd.minute === shiftSchedule.timeEnd.minute
                        )
                    })
                    if (
                        lastShiftBreak &&
                        lastShiftBreak.name === activeShiftBreak.name
                    ) {
                        lastShiftBreakActive = true
                    }
                }
            }
        }

        let locked = false
        if (!this.state.lastShiftBreakActive && lastShiftBreakActive)
            locked = true

        this.setState(
            {lastShiftBreakActive, activeShift, startOfShift, locked},
            () => {
                if (
                    this.state.lastShiftBreakActive &&
                    !this.state.showScrapEntryModal &&
                    this.state.locked
                ) {
                    this.setState({
                        showScrapEntryModal: true,
                        scrapAmount: this.state.totalScrap,
                    })
                }
            }
        )
    }

    async fetchLiveData() {
        if (!this.props.liveData[this.props.deviceId]) return

        let data = this.props.liveData

        this.shiftScrapDataHandler(data[this.props.deviceId])

        let activeStates = data[this.props.deviceId].activeStates
        activeStates = activeStates.sort((a, b) => a.priority - b.priority)
        activeStates = activeStates.filter(
            (state) =>
                state.name === 'Downtime' ||
                state.name === 'In-Cycle' ||
                state.name === 'Machine Off' ||
                state.name === 'Machine Waiting'
        )

        let activeState = activeStates[0]

        let status = 'UNKNOWN'
        let downtime = null
        let since = moment()

        if (activeState.name === 'In-Cycle') {
            status = 'RUNNING'
            since = moment(activeState.timestamp)
        } else if (activeState.name === 'Downtime') {
            since = moment(activeState.timestamp)
            const duration = moment().diff(
                moment(activeState.timestamp),
                'seconds'
            )
            const downtimeEntry = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: {
                            $in: [
                                'Downtime Category',
                                'Downtime Reason',
                                'Downtime Comment',
                                'Downtime User',
                            ],
                        },
                        timeEnd: null,
                    },
                },
                2
            )

            if (
                downtimeEntry.length === 0 &&
                duration < this.downtimeThreshold
            ) {
                status = 'DOWNTIME_UNDER_NO_REASON'
            } else if (
                downtimeEntry.length > 0 &&
                duration < this.downtimeThreshold
            ) {
                status = 'DOWNTIME_UNDER_REASON'
            } else if (
                downtimeEntry.length === 0 &&
                duration >= this.downtimeThreshold
            ) {
                status = 'DOWNTIME_OVER_NO_REASON'
            } else if (
                downtimeEntry.length > 0 &&
                duration >= this.downtimeThreshold
            ) {
                status = 'DOWNTIME_OVER_REASON'
            }

            if (downtimeEntry.length > 0) {
                let category = downtimeEntry.find(
                        (d) => d.name === 'Downtime Category'
                    ),
                    reason = downtimeEntry.find(
                        (d) => d.name === 'Downtime Reason'
                    ),
                    comment = downtimeEntry.find(
                        (d) => d.name === 'Downtime Comment'
                    ),
                    user = downtimeEntry.find((d) => d.name === 'Downtime User')
                let userName = 'Unknown'

                if (
                    user &&
                    this.state.users.find((u) => u._id === user.value)
                ) {
                    const u = this.state.users.find((u) => u._id === user.value)
                    userName = u.firstName + ' ' + u.lastName
                }

                downtime = {
                    category: category ? category.value : 'Unknown category',
                    reason: reason ? reason.value : 'Unknown reason',
                    comment: comment ? comment.value : 'No comment entered',
                    user: userName,
                }
            }
        }

        this.setState({status, downtime, since})
    }

    async fetchLastDowntimeRecord() {
        if (this.state.shiftStart === undefined) return

        let downtimeRecords = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Downtime',
                    timeEnd: {$ne: null},
                    timeStart: {
                        $gte: this.state.shiftStart.toISOString(),
                    },
                },
                options: {
                    sort: {timeStart: 1},
                },
            },
            2
        )

        let needToPopulate = false

        if (downtimeRecords) {
            if (downtimeRecords.length) {
                downtimeRecords = downtimeRecords.filter((r) => {
                    return (
                        moment(r.timeEnd).diff(moment(r.timeStart), 'seconds') >
                        this.downtimeThreshold
                    )
                })
                downtimeRecords.reverse()

                if (downtimeRecords.length) {
                    const lastDowntimePeriod = downtimeRecords[0]

                    const lastDowntimeReason = await API.post(
                        'historical/raw',
                        {
                            query: {
                                deviceId: this.props.deviceId,
                                name: 'Downtime Reason',
                                timestamp: moment(
                                    lastDowntimePeriod.timestamp
                                ).toISOString(),
                            },
                            options: {
                                limit: 1,
                            },
                        },
                        2
                    )

                    if (!lastDowntimeReason.length) {
                        needToPopulate = true
                        this.setState({
                            lastNonPopulatedDowntime: lastDowntimePeriod,
                        })
                    }
                }
            }
        }

        if (!needToPopulate) {
            this.setState({lastNonPopulatedDowntime: null})
        }
    }

    incrementTimer() {
        this.setState({
            timer: secondsToHHMMSS(moment().diff(this.state.since, 'seconds')),
        })
    }

    render_LOADING() {
        return <h1 style={{fontSize: '500%'}}>Loading...</h1>
    }

    render_UNKNOWN() {
        return <h1 style={{fontSize: '500%'}}>Cannot determine status</h1>
    }

    render_RUNNING() {
        return <h1 style={{fontSize: '500%'}}>{this.state.timer}</h1>
    }

    render_DOWNTIME_UNDER_NO_REASON() {
        return <h1 style={{fontSize: '500%'}}>{this.state.timer}</h1>
    }

    render_DOWNTIME_UNDER_REASON() {
        const {downtime} = this.state
        return (
            <>
                <h1
                    style={{
                        fontSize: '500%',
                        width: '100%',
                        textAlign: 'center',
                    }}>
                    {this.state.timer}
                </h1>
                <hr />
                <h1 style={{display: 'block', textAlign: 'center'}}>
                    {downtime.category + ' > ' + downtime.reason}
                </h1>
                <h3 style={{textAlign: 'center'}}>{downtime.comment}</h3>
                <h5 style={{textAlign: 'center'}}>
                    <i className="simple-icon-user" /> {downtime.user}
                </h5>
            </>
        )
    }

    render_DOWNTIME_OVER_NO_REASON() {
        return (
            <>
                <h1
                    style={{
                        fontSize: '500%',
                        width: '100%',
                        textAlign: 'center',
                    }}>
                    {this.state.timer}
                </h1>
                <hr />
                <h2>Click to enter downtime reason</h2>
            </>
        )
    }

    render_DOWNTIME_OVER_REASON() {
        const {downtime} = this.state
        return (
            <>
                <h1
                    style={{
                        fontSize: '500%',
                        width: '100%',
                        textAlign: 'center',
                    }}>
                    {this.state.timer}
                </h1>
                <hr />
                <h1 style={{display: 'block', textAlign: 'center'}}>
                    {downtime.category + ' > ' + downtime.reason}
                </h1>
                <h3 style={{textAlign: 'center'}}>{downtime.comment}</h3>
                <h5 style={{textAlign: 'center'}}>
                    <i className="simple-icon-user" /> {downtime.user}
                </h5>
            </>
        )
    }

    toggleDowntimeColor() {
        const {status, isRed} = this.state

        if (status === 'DOWNTIME_OVER_NO_REASON') {
            this.setState({isRed: !this.state.isRed})
        }
    }

    centraliaHourlyTargetHandler() {
        try {
            const data = this.props.liveData[this.props.deviceId]
            if (!data) return

            const scheduledTargetRecord = data.activeStates.find(
                    (a) => a.name === 'Scheduled Target'
                ),
                scheduledTargetStart = scheduledTargetRecord
                    ? moment(scheduledTargetRecord.timestamp)
                    : moment('1970-01-01'),
                downtimeRecord =
                    this.state.downtime &&
                    this.state.downtime.category === 'Changeover'
                        ? data.activeStates.find((a) => a.name === 'Downtime')
                        : null,
                downtimeStart = downtimeRecord
                    ? moment(downtimeRecord.timestamp)
                    : null

            //console.log('scheduled target record ', scheduledTargetRecord)
            //console.log('scheduled target start ', scheduledTargetStart);
            //console.log('downtime record ', downtimeRecord);
            //console.log('downtime start ', downtimeStart);

            if (
                (this.state.startOfShift &&
                    moment(this.state.startOfShift).isAfter(
                        scheduledTargetStart
                    )) ||
                (downtimeRecord && downtimeStart.isAfter(scheduledTargetStart))
            ) {
                this.setState({
                    centraliaTargetEntryForce: true,
                })
            } else {
                this.setState({
                    centraliaTargetEntryForce: false,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    async fetchCentraliaHourlyTargetOptions() {
        let data,
            centraliaTargetOptions = [],
            promptText
        try {
            data = await API.post(
                `data-models/6262ca692f0df500137cd6ab/paginate`,
                {
                    filter: [
                        {
                            path: 'asset.deviceId',
                            logic: 'is',
                            value: [this.props.deviceId],
                            type: 'Text',
                        },
                    ],
                    sort: {},
                },
                2
            )
            if (data.count > 0) {
                centraliaTargetOptions = data.result.results
            }
            data = await API.post(
                'data-models/6262d05b532e6d0014b7d5e7/paginate',
                {
                    filter: [
                        {
                            path: 'asset.deviceId',
                            logic: 'is',
                            value: [this.props.deviceId],
                            type: 'Text',
                        },
                    ],
                    sort: {},
                },
                2
            )
            if (data.count > 0) {
                promptText = data.result.results[0].prompt
            }
            // TODO empty block
            if (centraliaTargetOptions.length === 0 && !promptText) {
            }
            this.setState({
                centraliaTargetOptions,
                promptText,
            })
        } catch (error) {
            alert('Could not fetch target options.')
        }
    }

    async componentDidMount() {
        const devices = await API.get('devices')
        this.downtimeThreshold =
            devices.find((d) => d.deviceId === this.props.deviceId)
                .downtimeThresholdSeconds || 300

        await this.fetchUsers()
        await this.fetchShifts()
        this.fetchLiveData()

        this.subscriber.add(this.fetchLiveData, 5000, 'fetchLiveData')
        this.subscriber.add(this.incrementTimer, 750, 'incrementTimer')
        this.subscriber.add(this.fetchUsers, 1000 * 60, 'fetchUsers')
        this.subscriber.add(this.fetchShifts, 1000 * 60, 'fetchShifts')
        this.subscriber.add(
            this.toggleDowntimeColor,
            1500,
            'toggleDowntimeColor'
        )
        this.subscriber.add(
            this.fetchLastDowntimeRecord,
            1000 * 15,
            'fetchLastDowntimeRecord'
        )

        if (JSON.parse(localStorage['userObject']).factoryId === 'centralia') {
            await this.fetchCentraliaHourlyTargetOptions()
            if (
                this.state.centraliaTargetOptions &&
                this.state.centraliaTargetOptions.length > 0 &&
                this.state.promptText
            ) {
                this.subscriber.add(
                    this.centraliaHourlyTargetHandler,
                    2500,
                    'centraliaHourlyTargetHandler'
                )
                this.subscriber.add(
                    this.fetchCentraliaHourlyTargetOptions,
                    1000 * 60 * 10,
                    'fetchCentraliaHourlyTargetOptions'
                )
            }
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    handleDowntimeClick() {
        if (
            this.state.status === 'DOWNTIME_OVER_NO_REASON' ||
            this.state.status === 'DOWNTIME_UNDER_REASON' ||
            this.state.status === 'DOWNTIME_OVER_REASON'
        ) {
            this.toggleDowntimeEntryModal()
        }
    }

    handleScrapClick() {
        this.toggleScrapEntryModal()
    }

    async submitScrapEntry() {
        let scrapRecord = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Reject',
                    timeStart: moment(this.state.startOfShift)
                        .add(1, 'minutes')
                        .toISOString(),
                },
            },
            2
        )

        if (!scrapRecord.length && parseInt(this.state.scrapAmount) > 0) {
            const timestamp = moment(this.state.startOfShift)
                .add(1, 'minutes')
                .toDate()
            await API.post(
                'states',
                {
                    nodeId: this.props.deviceId,
                    deviceId: this.props.deviceId,
                    name: 'Reject',
                    timestamp: timestamp,
                    timeStart: timestamp,
                    timeEnd: timestamp,
                    value: parseInt(this.state.scrapAmount),
                    metaData: [
                        {
                            name: 'reject',
                            value: parseInt(this.state.scrapAmount),
                        },
                    ],
                },
                2
            )
        } else if (scrapRecord.length && parseInt(this.state.scrapAmount) > 0) {
            scrapRecord[0].value = parseInt(this.state.scrapAmount)
            scrapRecord[0].metaData = [
                {
                    name: 'reject',
                    value: parseInt(this.state.scrapAmount),
                },
            ]
            await API.patch('states/' + scrapRecord[0]._id, scrapRecord[0], 2)
        } else if (
            scrapRecord.length &&
            parseInt(this.state.scrapAmount) === 0
        ) {
            await API.remove('states/' + scrapRecord[0]._id, 2)
        }
        this.toggleScrapEntryModal()
        if (this.state.locked) {
            this.setState({locked: false})
        }
    }

    async submitPreviousDowntimeEntry() {
        const reasoncode = this.state.reasoncodes.find(
                (a) => a.value === this.state.selection.value
            ),
            downtime = this.state.lastNonPopulatedDowntime

        const category = {
            nodeId: this.props.deviceId,
            deviceId: this.props.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Category',
            value: reasoncode.label.split(' > ')[0],
        }
        const reason = {
            nodeId: this.props.deviceId,
            deviceId: this.props.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Reason',
            value: reasoncode.label.split(' > ')[1],
        }
        const comment = {
            nodeId: this.props.deviceId,
            deviceId: this.props.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime Comment',
            value: this.state.comment,
        }
        const user = {
            nodeId: this.props.deviceId,
            deviceId: this.props.deviceId,
            timestamp: downtime.timestamp,
            timeStart: downtime.timeStart,
            timeEnd: downtime.timeEnd,
            name: 'Downtime User',
            value: JSON.parse(localStorage['userObject'])._id,
        }

        await API.post('states', category, 2)
        await API.post('states', reason, 2)
        await API.post('states', comment, 2)
        await API.post('states', user, 2)
        this.toggleShowPreviousDowntimeModal()
        this.fetchLastDowntimeRecord()
    }

    handleDowntimeCommentEntry(e) {
        this.setState({comment: e.target.value})
    }

    addOtherScrapAmount() {
        const otherScrapAmount = parseInt(this.state.otherScrapAmount)

        this.setState({
            scrapAmount: this.state.scrapAmount + otherScrapAmount,
            enteringOtherScrapAmount: false,
            otherScrapAmount: '',
        })
    }

    subtractOtherScrapAmount() {
        const otherScrapAmount = parseInt(this.state.otherScrapAmount)

        this.setState({
            scrapAmount: this.state.scrapAmount - otherScrapAmount,
            enteringOtherScrapAmount: false,
            otherScrapAmount: '',
        })
    }

    render() {
        const {status, isRed, lastNonPopulatedDowntime} = this.state
        const height = document.documentElement.offsetHeight * 0.75 + 'px'
        let backgroundColor = COLOR_CODE[status]
        if (status === 'DOWNTIME_OVER_NO_REASON' && !isRed) {
            backgroundColor = 'black'
        }

        let options = []

        if (this.state.reasoncodes) {
            let categories = [
                ...new Set(this.state.reasoncodes.map((r) => r.category)),
            ]
            categories = categories.sort((a, b) => a.localeCompare(b))

            options = categories.map((c) => {
                const suboptions = this.state.reasoncodes
                    .filter((r) => r.category === c)
                    .map((r) => {
                        return {
                            label: r.reason,
                            value: r.value,
                        }
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))

                return {
                    label: c,
                    options: suboptions,
                }
            })
        }

        return (
            <Fragment>
                <div
                    style={{
                        backgroundColor: 'black',
                        position: 'relative',
                        height,
                    }}>
                    <Card style={{height, backgroundColor, color: '#fff'}}>
                        {lastNonPopulatedDowntime ? (
                            <CardHeader
                                style={{textAlign: 'center', padding: 0}}>
                                <Button
                                    className="btn-block"
                                    onClick={
                                        this.toggleShowPreviousDowntimeModal
                                    }
                                    style={{
                                        borderRadius: 0,
                                        backgroundColor: 'rgb(231, 76, 60)',
                                    }}>
                                    CLICK TO CLASSIFY LAST DOWNTIME (
                                    {moment(
                                        lastNonPopulatedDowntime.timeStart
                                    ).calendar()}{' '}
                                    -{' '}
                                    {moment(
                                        lastNonPopulatedDowntime.timeEnd
                                    ).calendar()}
                                    )
                                </Button>
                            </CardHeader>
                        ) : null}
                        <CardBody
                            style={{height}}
                            onClick={this.handleDowntimeClick}>
                            <div
                                style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%',
                                }}
                                onClick={this.handleDowntimeClick}>
                                {status === 'LOADING' ? (
                                    <>{this.render_LOADING()}</>
                                ) : null}

                                {status === 'UNKNOWN' ? (
                                    <>{this.render_UNKNOWN()}</>
                                ) : null}

                                {status === 'RUNNING' ? (
                                    <>{this.render_RUNNING()}</>
                                ) : null}

                                {status === 'DOWNTIME_UNDER_NO_REASON' ? (
                                    <>
                                        {this.render_DOWNTIME_UNDER_NO_REASON()}
                                    </>
                                ) : null}

                                {status === 'DOWNTIME_UNDER_REASON' ? (
                                    <>{this.render_DOWNTIME_UNDER_REASON()}</>
                                ) : null}

                                {status === 'DOWNTIME_OVER_NO_REASON' ? (
                                    <>{this.render_DOWNTIME_OVER_NO_REASON()}</>
                                ) : null}

                                {status === 'DOWNTIME_OVER_REASON' ? (
                                    <>{this.render_DOWNTIME_OVER_REASON()}</>
                                ) : null}
                            </div>
                        </CardBody>
                        {this.state.activeShift ? (
                            <CardFooter onClick={this.handleScrapClick}>
                                <Row>
                                    <Col xs="6">
                                        <h2>
                                            Total shift scrap:{' '}
                                            {this.state.totalScrap}
                                        </h2>
                                    </Col>
                                    <Col xs="6" className="text-right">
                                        <h5>(Click to edit scrap)</h5>
                                    </Col>
                                </Row>
                            </CardFooter>
                        ) : null}
                    </Card>
                </div>
                {this.state.showDowntimeEntryModal ? (
                    <Modal
                        isOpen={this.state.showDowntimeEntryModal}
                        toggle={this.toggleDowntimeEntryModal}>
                        <ModalBody style={{padding: 0}} className="hmi">
                            <DowntimeEntryModule
                                disableSearch={true}
                                deviceId={this.props.deviceId}
                                threshold={this.downtimeThreshold}
                                hideLightsOutEntry={
                                    this.props.hideLightsOutEntry
                                }
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={this.toggleDowntimeEntryModal}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.state.showScrapEntryModal ? (
                    <Modal
                        isOpen={this.state.showScrapEntryModal}
                        toggle={this.toggleScrapEntryModal}>
                        <ModalHeader toggle={this.toggleScrapEntryModal}>
                            {this.props.deviceName} {' - '}{' '}
                            {this.state.lastShiftBreakActive
                                ? 'Enter final shift scrap amount'
                                : 'Edit scrap amount'}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.enteringOtherScrapAmount ? (
                                <Row className="mb-4">
                                    <Col>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <Button
                                                    color="success"
                                                    onClick={
                                                        this.addOtherScrapAmount
                                                    }>
                                                    + Scrap
                                                </Button>
                                            </InputGroupAddon>
                                            <Input
                                                className="text-center"
                                                value={
                                                    this.state
                                                        .otherScrapAmount || ''
                                                }
                                                placeholder={
                                                    'Enter other scrap amount here'
                                                }
                                                onChange={(event) => {
                                                    if (
                                                        isNaN(
                                                            event.target.value
                                                        ) ||
                                                        event.target.value < 0
                                                    ) {
                                                        return
                                                    }
                                                    this.setState({
                                                        otherScrapAmount:
                                                            parseInt(
                                                                event.target
                                                                    .value
                                                            ),
                                                    })
                                                }}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="danger"
                                                    onClick={
                                                        this
                                                            .subtractOtherScrapAmount
                                                    }
                                                    disabled={
                                                        this.state
                                                            .otherScrapAmount >
                                                        this.state.scrapAmount
                                                    }>
                                                    - Scrap
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="mb-4">
                                    <Col xs="4">
                                        <Button
                                            color="success"
                                            className="btn-block"
                                            onClick={() => {
                                                this.setState({
                                                    scrapAmount:
                                                        this.state.scrapAmount +
                                                        1,
                                                })
                                            }}>
                                            +1 Scrap
                                        </Button>
                                    </Col>
                                    <Col xs="4">
                                        <Button
                                            color="danger"
                                            className="btn-block"
                                            disabled={
                                                this.state.scrapAmount === 0
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    scrapAmount:
                                                        this.state.scrapAmount -
                                                        1,
                                                })
                                            }}>
                                            -1 Scrap
                                        </Button>
                                    </Col>
                                    <Col xs="4">
                                        <Button
                                            color="warning"
                                            className="btn-block"
                                            onClick={() => {
                                                this.setState({
                                                    enteringOtherScrapAmount: true,
                                                })
                                            }}>
                                            +/- Other
                                        </Button>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col className="text-center">
                                    <p>Total shift scrap:</p>
                                    <h1 className="text-center">
                                        <b>{this.state.scrapAmount}</b>
                                    </h1>
                                </Col>
                            </Row>
                            {/*<Row>
                            <Col xs="12">
                            <Form>
                            <Label className="form-group has-top-label">
                            <Input
                                type="tel"
                                name="reject"
                                value={this.state.scrapAmount}
                                onChange={value => {
                                    console.log(value.target)
                                if (value.target.value < 0) return;
                                this.setState({ scrapAmount: value.target.value });
                                }}
                            />
                            <IntlMessages id="Reject Amount" />
                            </Label>
                            </Form>
                            </Col>
                            </Row>*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="btn-block"
                                onClick={this.submitScrapEntry}>
                                SUBMIT
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}

                {this.state.showPreviousDowntimeModal ? (
                    <Modal
                        isOpen={true}
                        toggle={this.toggleShowPreviousDowntimeModal}>
                        <ModalHeader
                            toggle={this.toggleShowPreviousDowntimeModal}>
                            Set downtime reason for: <br />
                            {moment(
                                lastNonPopulatedDowntime.timeStart
                            ).calendar()}{' '}
                            -{' '}
                            {moment(
                                lastNonPopulatedDowntime.timeEnd
                            ).calendar()}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="12">
                                    <Form className="hmi">
                                        <Label className="form-group has-top-label">
                                            <Select
                                                components={{
                                                    Input: CustomSelectInput,
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="reason"
                                                value={this.state.selection}
                                                onChange={(selection) => {
                                                    this.setState({selection})
                                                }}
                                                options={options}
                                                styles={customStyles}
                                                isSearchable={false}
                                            />
                                            <IntlMessages id="Enter Downtime Reason" />
                                        </Label>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="textarea"
                                                name="commentEntry"
                                                rows={5}
                                                value={this.state.comment}
                                                onChange={
                                                    this
                                                        .handleDowntimeCommentEntry
                                                }
                                            />
                                            <IntlMessages
                                                id={'Optional Comment'}
                                            />
                                        </Label>
                                        <Button
                                            className="btn btn-primary btn-block"
                                            onClick={
                                                this.submitPreviousDowntimeEntry
                                            }>
                                            Submit
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                ) : null}
                {this.state.centraliaTargetEntryForce ? (
                    <Modal isOpen={this.state.centraliaTargetEntryForce}>
                        <ModalHeader>
                            <h2>{this.state.promptText}</h2>
                        </ModalHeader>
                        <ModalBody style={{padding: 20}} className="hmi">
                            {this.state.centraliaTargetLoading ? (
                                <div className="loading" />
                            ) : null}
                            {this.state.centraliaTargetOptions.map(
                                (option, idx) => {
                                    return (
                                        <Button
                                            key={idx}
                                            block
                                            onClick={async () => {
                                                try {
                                                    this.setState({
                                                        centraliaTargetLoading: true,
                                                    })
                                                    await API.post(
                                                        'tablets/data',
                                                        [
                                                            {
                                                                nodeId: this
                                                                    .props
                                                                    .deviceId,
                                                                name: 'Scheduled Target',
                                                                value: option.hourlyTarget,
                                                                timestamp:
                                                                    moment().valueOf(),
                                                            },
                                                        ],
                                                        2
                                                    )
                                                    setTimeout(async () => {
                                                        await this.props.forceRefresh()
                                                        this.setState({
                                                            centraliaTargetLoading: false,
                                                            centraliaTargetEntryForce: false,
                                                        })
                                                    }, 7000)
                                                } catch (error) {
                                                    alert(
                                                        'Could not submit. Please try again.'
                                                    )
                                                    this.setState({
                                                        centraliaTargetLoading: false,
                                                    })
                                                }
                                            }}>
                                            {option.option}
                                        </Button>
                                    )
                                }
                            )}
                        </ModalBody>
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}
