import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Row,
    Col,
    ButtonGroup,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import * as API from 'SDK/api'
import NumberKeypad from './NumberKeypad'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            reasoncodes: [],
            selectedReason: null,
            quantity: '',
        }
    }

    async fetchCodes() {
        this.setState({loading: true})
        try {
            let reasoncodes = await API.get('reworkcodes', 2)

            reasoncodes = reasoncodes.filter((code) => {
                if (!code.assets) {
                    return true
                } else {
                    return code.assets.find((a) => a === this.props.deviceId)
                }
            })

            this.setState({
                reasoncodes,
            })
        } catch (error) {
            alert(error)
            console.log(error)
        }
        this.setState({loading: false})
    }

    async submit() {
        this.setState({loading: true})
        try {
            let timestamp = moment().toISOString()

            await API.post(
                'states',
                {
                    nodeId: this.props.deviceId,
                    deviceId: this.props.deviceId,
                    name: 'Rework',
                    timestamp: timestamp,
                    timeStart: timestamp,
                    timeEnd: timestamp,
                    value: Number(this.state.quantity),
                    reason: this.state.selectedReason.reason,
                    category: this.state.selectedReason.category,
                    partsPerHourAdjusted: true,
                    metaData: [
                        {
                            name: 'rework',
                            value: Number(this.state.quantity),
                        },
                    ],
                },
                2
            )
            await this.props.forceRefresh()
            this.props.toggleModal()
        } catch (error) {
            alert(error)
            console.log(error)
        }
        this.setState({loading: false})
    }

    componentDidMount() {
        this.fetchCodes()
    }

    render() {
        return (
            <Fragment>
                <Modal size="lg" isOpen={true} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>
                        REWORK ENTRY
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading ? (
                            <h2 style={{textAlign: 'center'}}>
                                Loading, please wait...
                            </h2>
                        ) : (
                            <>
                                {!this.state.selectedReason ? (
                                    <Row>
                                        <Col xs="12">
                                            <h5 style={{textAlign: 'center'}}>
                                                <strong>SELECT REASON:</strong>
                                            </h5>
                                            <hr />
                                            <Row>
                                                {this.state.reasoncodes.map(
                                                    (r) => {
                                                        return (
                                                            <Col
                                                                xs="6"
                                                                key={r._id}>
                                                                <Button
                                                                    color={
                                                                        this
                                                                            .state
                                                                            .selectedReason &&
                                                                        this
                                                                            .state
                                                                            .selectedReason
                                                                            ._id ===
                                                                            r._id
                                                                            ? 'success'
                                                                            : 'secondary'
                                                                    }
                                                                    block
                                                                    className="m-1"
                                                                    style={{
                                                                        textTransform:
                                                                            'uppercase',
                                                                    }}
                                                                    onClick={() =>
                                                                        this.setState(
                                                                            {
                                                                                selectedReason:
                                                                                    r,
                                                                            }
                                                                        )
                                                                    }>
                                                                    {r.reason}
                                                                </Button>
                                                            </Col>
                                                        )
                                                    }
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : (
                                    <>
                                        <Row>
                                            <Col
                                                xs="12"
                                                style={{textAlign: 'center'}}>
                                                <ButtonGroup>
                                                    <Button
                                                        color="default"
                                                        disabled>
                                                        <h5>
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .selectedReason
                                                                        .reason
                                                                }
                                                            </strong>
                                                        </h5>
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        onClick={() =>
                                                            this.setState({
                                                                selectedReason:
                                                                    null,
                                                            })
                                                        }>
                                                        CHANGE
                                                    </Button>
                                                </ButtonGroup>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h5
                                                    style={{
                                                        textAlign: 'center',
                                                    }}>
                                                    <strong>
                                                        ENTER QUANTITY:
                                                    </strong>
                                                </h5>
                                                <hr />
                                                <NumberKeypad
                                                    onChange={(quantity) =>
                                                        this.setState({
                                                            quantity,
                                                        })
                                                    }
                                                    showDecimal={true}
                                                    value={this.state.quantity}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {this.state.selectedReason &&
                                    Number(this.state.quantity) > 0 && (
                                        <>
                                            <Row>
                                                <Col xs="12">
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <Button
                                                        block
                                                        color="success"
                                                        onClick={this.submit}>
                                                        SUBMIT
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                            </>
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
