import moment from 'moment'

const TIMERANGES = [
    /*{
    name: 'Today',
    timerange: [moment().startOf('day'), moment().add(-1, 'hours').endOf('hour')]
}, */ {
        name: 'This Week',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('isoWeek'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'This Month',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('month'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'This Year',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('year'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'Yesterday',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('day').add(-1, 'days'),
                moment(m).endOf('day').add(-1, 'days'),
            ]
        },
    },
    {
        name: 'Last Week',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('isoWeek').add(-1, 'isoWeeks'),
                moment(m).endOf('isoWeek').add(-1, 'isoWeeks'),
            ]
        },
    },
    {
        name: 'Last Month',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('month').add(-1, 'months'),
                moment(m).add(-1, 'months').endOf('month').add(1, 'days'),
            ]
        },
    },
    {
        name: 'Last Year',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('year').add(-1, 'years'),
                moment(m).endOf('year').add(-1, 'years'),
            ]
        },
    },
    {
        name: 'Since 30 Days Ago',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('hour').add(-30, 'days'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'Since 60 Days Ago',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('hour').add(-60, 'days'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'Since 90 Days Ago',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('hour').add(-90, 'days'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'Since 180 Days Ago',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('hour').add(-180, 'days'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
    {
        name: 'Since 365 Days Ago',
        timerange: () => {
            const m = moment()
            return [
                moment(m).startOf('hour').add(-365, 'days'),
                moment(m).add(-1, 'days').endOf('day'),
            ]
        },
    },
]

export default TIMERANGES
