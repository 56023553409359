import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    CardHeader,
    CardFooter,
    Label,
} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import * as API from 'SDK/api'
import CustomTable from './CustomTable'
import Subscriber from 'SDK/subscriber'
import DateRangePicker from 'SDK/ui/helpers/DateRangePicker'

import REPORT_OPTIONS from './options'

export default class HubbellAikenTables extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
        {
            prop: 'table',
            component: 'TableColumnGenerator',
        },
    ]
    showBorder = false
    id = 'HubbellAikenTables'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
        deviceId: PropTypes.array,
        table: PropTypes.object,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            deviceName: '',
            data: [],
            reportSelected: REPORT_OPTIONS[0],
            start: moment(),
            end: moment().subtract(1, 'weeks'),
        }
    }

    async componentDidMount() {}

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    handleDateChange(field, dates) {
        if (dates && dates.start) {
            this.setState({start: dates.start, end: dates.end})
        }
    }

    render() {
        const componentRef = this.state.reportSelected.value

        return (
            <Fragment>
                <Label>Customized Table</Label>
                <DateRangePicker cb={this.handleDateChange} reportLimit={1} />
                <div style={{marginTop: 15}}></div>
                <CustomTable
                    table={this.props.table}
                    deviceIds={this.props.deviceIds}
                    startTime={this.state.start}
                    endTime={this.state.end}
                />
            </Fragment>
        )
    }
}
