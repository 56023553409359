import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormText,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'

const dict = {
    open: 'Open Mesh',
    close: 'Close Mesh',
    start: 'Start Mesh',
    stop: 'Stop Mesh',
    reset: 'Reset Meth',
    pause: 'Pause Mesh',
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let asset = null

        if (
            this.props.editingDevice &&
            this.props.assets.find(
                (a) => a.deviceId === this.props.editingDevice.deviceId
            )
        ) {
            asset = this.props.assets.find(
                (a) => a.deviceId === this.props.editingDevice.deviceId
            )
            asset = {
                value: asset.deviceId,
                label: asset.name,
            }
        }

        this.originalAsset = asset

        this.state = {
            asset: asset,
            serialNumber: !this.props.editingDevice
                ? ''
                : this.props.editingDevice.serialNumber,
            model:
                !this.props.editingDevice || !this.props.editingDevice.model
                    ? null
                    : {
                          label: 'Version ' + this.props.editingDevice.model,
                          value: this.props.editingDevice.model,
                      },
            serialNumberBorderColor: 'rgb(215,215,215)',
            valid: this.props.editingDevice !== null,
            message: false,
            confirmDelete: false,
            loading: false,
        }
    }

    validateSerialNumber(value) {
        let message = ''
        if (value === '') {
            message = 'Serial number is a required field.'
        } else if (
            this.props.devices.find(
                (device) => device.serialNumber === value
            ) &&
            !this.props.editingDevice
        ) {
            message = 'This serial number already exists.'
        }
        return message
    }

    askToConfirmDelete() {
        this.setState({confirmDelete: true})
    }
    revertDelete() {
        this.setState({confirmDelete: false})
    }

    async delete() {
        this.setState({loading: true})
        try {
            let response = await API.remove(
                'ips/cloud/devices/' + this.props.editingDevice.serialNumber,
                2
            )
            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                this.setState({loading: false})
                return alert('There was an error deleting this field device.')
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error deleting this field device.')
        }
    }

    revertChanges() {
        this.setState({
            deviceId: !this.props.editingDevice ? '' : this.originalAsset,
            serialNumber: !this.props.editingDevice
                ? ''
                : this.props.editingDevice.serialNumber,
            model: !this.props.editingDevice
                ? null
                : this.props.editingDevice.model,
        })
    }

    async saveChanges() {
        this.setState({loading: true})
        try {
            if (this.props.editingDevice.deviceId !== this.state.asset.value) {
                await API.patch(
                    'ips/cloud/devices/' +
                        this.state.serialNumber +
                        '/change-asset',
                    {
                        deviceId: this.state.asset.value,
                    },
                    2
                )
            }

            if (
                this.state.model &&
                this.props.editingDevice.model !== this.state.model.value
            ) {
                console.log(this.state.model)
                await API.patch(
                    'ips/cloud/devices/' +
                        this.state.serialNumber +
                        '/change-model',
                    {
                        version: this.state.model.value,
                    },
                    2
                )
            }

            setTimeout(this.closeModalAfterAction, 1500)
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error saving your changes.')
        }
        this.setState({loading: false})
    }

    async add() {
        this.setState({loading: true})
        try {
            let response = await API.post(
                'ips/cloud/devices/register',
                {
                    serialNumber: this.state.serialNumber,
                    deviceId: this.state.asset.value,
                    model: this.state.model
                        ? this.state.model.value
                        : undefined,
                },
                2
            )

            if (response.success) {
                setTimeout(this.closeModalAfterAction, 0)
            } else {
                return alert(
                    'There was an error adding your field device. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            this.setState({loading: false})
            return alert('There was an error adding your field device.')
        }
    }

    closeModalAfterAction() {
        this.setState({loading: false})
        this.props.toggleModal()
    }

    renderDeleteDeviceButton() {
        if (!this.state.confirmDelete && !this.changesPending()) {
            return (
                <Button color="danger" onClick={this.askToConfirmDelete}>
                    Delete Field Device
                </Button>
            )
        } else {
            return null
        }
    }

    renderDeleteConfirmation() {
        if (this.state.confirmDelete) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Are you sure?</p>
                    <Button size="sm" color="danger" onClick={this.delete}>
                        Yes
                    </Button>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.revertDelete}>
                        No
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    renderSaveChangesConfirmation() {
        if (this.changesPending() && this.props.editingDevice) {
            return (
                <Fragment>
                    <p style={{marginBottom: '0px'}}>Save Changes?</p>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={this.saveChanges}
                        disabled={!this.state.valid}>
                        Save
                    </Button>
                    <Button
                        size="sm"
                        color="danger"
                        onClick={this.revertChanges}>
                        Discard
                    </Button>
                </Fragment>
            )
        } else {
            return null
        }
    }

    renderNewDevice() {
        if (!this.props.editingDevice) {
            return (
                <Fragment>
                    <Button
                        color="primary"
                        onClick={this.add}
                        disabled={!this.state.valid}>
                        Add Field Device
                    </Button>
                </Fragment>
            )
        }
    }

    changesPending() {
        let changesPending = true
        if (this.props.editingDevice) {
            changesPending = !(
                this.props.editingDevice.deviceId ===
                    (this.state.asset ? this.state.asset.value : null) &&
                this.props.editingDevice.serialNumber ===
                    this.state.serialNumber &&
                this.props.editingDevice.model ==
                    (this.state.model ? this.state.model.value : '')
            )
        }
        return changesPending
    }

    render() {
        let {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    wrapClassName="modal-right">
                    <ModalHeader toggle={toggleModal}>
                        {this.state.asset
                            ? this.state.asset.label
                            : 'New device'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="assigned-asset"
                                            value={this.state.asset}
                                            options={this.props.assets.map(
                                                (a) => {
                                                    return {
                                                        label: a.name,
                                                        value: a.deviceId,
                                                    }
                                                }
                                            )}
                                            onChange={(asset) => {
                                                const validSerialNumber =
                                                    this.validateSerialNumber(
                                                        this.state.serialNumber
                                                    )
                                                if (validSerialNumber === '') {
                                                    this.setState({
                                                        asset,
                                                        valid: true,
                                                    })
                                                } else {
                                                    this.setState({
                                                        asset,
                                                        valid: false,
                                                    })
                                                }
                                            }}
                                        />
                                        <IntlMessages id="Asset Assignment" />
                                        <FormText color="muted"></FormText>
                                    </Label>
                                    <Label className="form-group has-top-label">
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state
                                                        .serialNumberBorderColor,
                                            }}
                                            type="text"
                                            name="serialNumber"
                                            value={this.state.serialNumber}
                                            onChange={(serialNumber) => {
                                                const message =
                                                        this.validateSerialNumber(
                                                            serialNumber.target
                                                                .value
                                                        ),
                                                    color =
                                                        message === ''
                                                            ? 'rgb(215,215,215)'
                                                            : '#dc3545',
                                                    valid = message === ''
                                                this.setState({
                                                    serialNumber:
                                                        serialNumber.target
                                                            .value,
                                                    valid: valid,
                                                    serialNumberBorderColor:
                                                        color,
                                                    serialNumberInputMessage:
                                                        message,
                                                })
                                            }}
                                            disabled={
                                                this.props.editingDevice !==
                                                null
                                            }
                                        />
                                        <IntlMessages id="Serial Number" />
                                        <FormText color="muted">
                                            {
                                                this.state
                                                    .serialNumberInputMessage
                                            }
                                        </FormText>
                                    </Label>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="assigned-model"
                                            value={this.state.model}
                                            options={this.props.models.map(
                                                (m) => {
                                                    return {
                                                        label:
                                                            'Version ' +
                                                            m.version,
                                                        value: m.version,
                                                    }
                                                }
                                            )}
                                            onChange={(model) => {
                                                this.setState({model})
                                            }}
                                            isDisabled={
                                                this.props.editingDevice &&
                                                this.props.editingDevice.command
                                                    .value ===
                                                    'download_model' &&
                                                this.props.editingDevice.status
                                                    .value !== 'download_model'
                                            }
                                        />
                                        <IntlMessages id="Model (optional)" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {this.renderDeleteDeviceButton()}
                        {this.renderDeleteConfirmation()}
                        {this.renderSaveChangesConfirmation()}
                        {this.renderNewDevice()}
                    </ModalFooter>
                </Modal>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
