import React, {Component} from 'react'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'

import * as API from 'SDK/api'
import CalendarViewer from '../Schemas/SDK/CalendarViewer'

export default class SchemaCalendarViewerFullAccess extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'dataModelIds',
            component: 'MultiCalendarDataModelPicker',
        },
    ]
    showBorder = false
    id = 'SchemaCalendarViewerFullAccess'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string.isRequired,
        dataModelIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            ready: false,
            dataModels: [],
        }
    }

    async componentDidMount() {
        let dataModels = []
        try {
            dataModels = await API.get('data-models', 2)
        } catch (error) {
            console.log(error)
            alert('Error loading necessary data. Check internet connection.')
        }

        this.setState({
            ready: true,
            dataModels,
        })
    }

    render() {
        return (
            <>
                {this.state.ready ? (
                    <>
                        <CalendarViewer
                            dataModelIds={this.props.dataModelIds}
                            dataModels={this.state.dataModels}
                        />
                    </>
                ) : null}
            </>
        )
    }
}
