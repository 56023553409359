import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Input, Label, Button} from 'reactstrap'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import moment from 'moment'
import Switch from 'rc-switch'
import * as API from 'SDK/api'

import Navigation from '../Navigation'
import {paginate} from '../Paginate'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Release Lot',
            fn: () => {},
        })

        this.state = {
            rawMaterialOptions: [],
            selectedRawMaterial: null,

            lotOptions: [],
            selectedLot: null,

            released: false,
            releaseDate: moment().format('YYYY-MM-DD'),
        }
    }

    async fetchRawMaterials() {
        const records = await paginate('5fc530725d4bb3001bba8f43', [], {})

        this.setState({
            rawMaterialOptions: records.map((r) => {
                return {label: r['Value'], value: r}
            }),
        })
    }

    async fetchLots() {
        const records = await paginate(
            '5fc554e6cca7411395293a68',
            [
                {
                    type: 'Text',
                    logic: 'is',
                    value: [this.state.selectedRawMaterial.label],
                    path: 'Raw Chemical.Value',
                },
                {
                    type: 'Boolean',
                    logic: 'isFalse',
                    path: 'Raw Released',
                },
            ],
            {}
        )

        this.setState({
            lotOptions: records.map((r) => {
                return {label: r['Raw Lot Number'], value: r}
            }),
        })
    }

    onRawMaterialSelectionChange(selectedRawMaterial) {
        this.setState({selectedRawMaterial}, this.fetchLots)
    }

    onLotSelectionChange(selectedLot) {
        this.setState({selectedLot})
    }

    async submit() {
        this.setState({loading: true})
        // we are releasing existing lot
        let record = this.state.selectedLot.value
        record['Raw Chemical'] = record['Raw Chemical']._id
        record['Raw Released'] = true
        record['Raw Release Date'] = moment(
            this.state.releaseDate
        ).toISOString()
        record['Released_By'] = JSON.parse(localStorage['userObject']).username

        const response = await API.patch(
            'data-models/5fc554e6cca7411395293a68/edit-record',
            record,
            2
        )
        this.setState({loading: false})
        if (response) {
            // go back to previous page
            this.props.reset()
        }
    }

    componentDidMount() {
        this.fetchRawMaterials()
    }

    render() {
        const showSubmitButton =
            this.state.released && this.state.releaseDate !== null

        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Release Lot</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{size: 4, offset: 4}}>
                        <Form>
                            <Label className="form-group has-top-label">
                                <Select
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="reason"
                                    value={this.state.selectedRawMaterial}
                                    onChange={this.onRawMaterialSelectionChange}
                                    options={this.state.rawMaterialOptions.sort(
                                        (a, b) => a.label.localeCompare(b.label)
                                    )}
                                />
                                <IntlMessages id="Select raw material" />
                            </Label>
                        </Form>
                    </Col>
                </Row>
                {this.state.selectedRawMaterial ? (
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Form>
                                <Label className="form-group has-top-label">
                                    <Select
                                        components={{Input: CustomSelectInput}}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="reason"
                                        value={this.state.selectedLot}
                                        onChange={this.onLotSelectionChange}
                                        options={this.state.lotOptions.sort(
                                            (a, b) =>
                                                a.label.localeCompare(b.label)
                                        )}
                                    />
                                    <IntlMessages id="Select lot" />
                                </Label>
                            </Form>
                        </Col>
                    </Row>
                ) : null}
                {this.state.selectedLot ? (
                    <>
                        <Row>
                            <Col xs={{size: 4, offset: 4}}>
                                <Label>Released:</Label>
                                <Switch
                                    className="custom-switch custom-switch-primary"
                                    checked={this.state.released}
                                    onChange={(released) =>
                                        this.setState({released})
                                    }
                                />
                            </Col>
                        </Row>
                        {this.state.released ? (
                            <Row className="mt-4">
                                <Col xs={{size: 4, offset: 4}}>
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="date"
                                                name="release-date"
                                                value={this.state.releaseDate}
                                                onChange={(e) =>
                                                    this.setState({
                                                        releaseDate:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <IntlMessages id="Release Date" />
                                        </Label>
                                    </Form>
                                </Col>
                            </Row>
                        ) : null}
                    </>
                ) : null}

                {this.state.released && this.state.releaseDate !== null ? (
                    <Row>
                        <Col xs={{size: 4, offset: 4}}>
                            <Button size="xl" block onClick={this.submit}>
                                SUBMIT
                            </Button>
                        </Col>
                    </Row>
                ) : null}

                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
