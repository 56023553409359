import React, {Component, Fragment} from 'react'
import {NavLink} from 'react-router-dom'
import {Row, Card, Col, Button} from 'reactstrap'

export default class UtilizationSummary extends React.PureComponent {
    render() {
        const {viewing} = this.props
        return (
            <Fragment>
                <Row>
                    <Col xs="12" style={{textAlign: 'center'}}>
                        <h5>
                            <strong>{viewing.name}</strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" style={{textAlign: 'center'}}>
                        <h1 style={{marginBottom: '0px', paddingBottom: '0px'}}>
                            {viewing.summary.dailyHours}
                        </h1>
                        <p style={{color: 'grey', margin: '0px'}}>
                            Avg. Utilization / Asset
                        </p>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" style={{textAlign: 'center'}}>
                        <h1 style={{marginBottom: '0px', paddingBottom: '0px'}}>
                            {viewing.summary.scheduledPercentage}
                        </h1>
                        <p style={{color: 'grey', margin: '0px'}}>
                            Avg. Scheduled Utilization / Asset
                        </p>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" style={{textAlign: 'center'}}>
                        <p style={{color: 'black', marginTop: '10px'}}>
                            <strong>Shifts</strong>
                        </p>
                    </Col>
                </Row>
                {viewing.summary.shifts.map((shift, i) => {
                    return (
                        <Row key={i}>
                            <Col
                                xs="12"
                                className="mb-2"
                                style={{textAlign: 'center'}}>
                                <h3
                                    style={{
                                        marginBottom: '0px',
                                        paddingBottom: '0px',
                                    }}>
                                    {shift.value}
                                </h3>
                                <p style={{color: 'grey', margin: '0px'}}>
                                    {shift.name}
                                </p>
                            </Col>
                        </Row>
                    )
                })}
                {/*<Row>
          <Col xs="12" className="text-center mt-2">
            <NavLink to="/app/reporting/utilization"><Button className="btn-block">Analyze Further...</Button></NavLink>
          </Col>
        </Row>*/}
            </Fragment>
        )
    }
}
