import React, {Component, Fragment} from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
} from 'reactstrap'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'
import CSVReader from 'react-csv-reader'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        this.state = {
            tagGroups: [],
        }
    }

    async handleUpload(data, fileInfo) {
        console.log(data, fileInfo)

        let uniqueTagGroups = [...new Set(data.map((row) => row['Tag Group']))],
            tagGroups = []

        for (let tagGroup of uniqueTagGroups) {
            const instance = data.find((row) => row['Tag Group'] === tagGroup)

            tagGroups.push({
                name: tagGroup,
                readRateSeconds: parseInt(instance['Read Frequency (Seconds)']),
                group: parseInt(instance['PLC Read Group Count']),
                tags: [],
            })
        }

        for (let tag of data) {
            let asset = this.props.assets.find((a) => a.name === tag['Asset'])
            if (!asset) {
                return alert('Asset=' + tag['Asset'] + ' does not exist')
            }

            let obj = {
                deviceId: asset.deviceId,
                tag: String(tag['Tag Name']),
                alias: tag['Tag Alias'] || tag['Tag Name'],
                type: tag['Tag Type'].toLowerCase(),
                operation: {},
            }

            if (
                obj.type !== 'numerical' &&
                obj.type !== 'event' &&
                obj.type !== 'accumulator' &&
                obj.type !== 'bitfield'
            ) {
                return alert('Invalid type provided for Tag Name=' + obj.tag)
            }

            if (obj.type === 'numerical') {
                let calculation = tag['Numerical_Calculation'],
                    sampleNumber = parseInt(tag['Numerical_SampleNumber']),
                    threshold = parseFloat(tag['Numerical_Threshold'])

                if (
                    !calculation ||
                    (calculation.toLowerCase() !== 'avg' &&
                        calculation.toLowerCase() !== 'min' &&
                        calculation.toLowerCase() !== 'max')
                ) {
                    return alert(
                        'Invalid calculation provided for TagName = ' + obj.tag
                    )
                } else {
                    calculation = calculation.toLowerCase()
                }

                if (isNaN(sampleNumber) || sampleNumber < 1) {
                    return alert(
                        'Invalid sampleNumber provided for Tag Name = ' +
                            obj.tag
                    )
                }

                if (isNaN(threshold) || threshold < 0) {
                    return alert(
                        'Invald threshold provided for Tag Name=' + obj.tag
                    )
                }

                obj.operation = {
                    calculation,
                    sampleNumber,
                    threshold,
                }
            }

            if (obj.type === 'event') {
                let onChange = tag['Event_OnChange']

                if (
                    onChange === 'False' ||
                    onChange === 'false' ||
                    onChange === 'FALSE' ||
                    onChange === false
                ) {
                    onChange = false
                } else if (
                    onChange === 'True' ||
                    onChange === 'true' ||
                    onChange === 'TRUE' ||
                    onChange === true
                ) {
                    onChange = true
                } else {
                    return alert(
                        'Invalid onchange value provided for Tag Name=' +
                            obj.tag
                    )
                }

                obj.operation = {onChange}
            }

            if (obj.type === 'bitfield') {
                let onChange = tag['Event_OnChange']

                if (
                    onChange === 'False' ||
                    onChange === 'false' ||
                    onChange === 'FALSE' ||
                    onChange === false
                ) {
                    onChange = false
                } else if (
                    onChange === 'True' ||
                    onChange === 'true' ||
                    onChange === 'TRUE' ||
                    onChange === true
                ) {
                    onChange = true
                } else {
                    return alert(
                        'Invalid onchange value provided for Tag Name=' +
                            obj.tag
                    )
                }

                const bitfield_list = tag['Bitfield_List'].split(',')
                if (bitfield_list.length === 0) {
                    return alert('bitfield list must have at least one bit')
                }

                obj.operation = {onChange, tags: bitfield_list}
            }

            obj.operation.modbus32Bit = tag['Modbus_32_Bit'] === true

            obj.operation.s7_type = tag['s7_type']

            tagGroups.find((g) => g.name === tag['Tag Group']).tags.push(obj)
        }

        let settings = {
            pendingUpdate: true,
            tagGroups,
        }

        let response = await API.patch(
            'nodes/' + this.node.nodeId,
            {
                settings,
            },
            2
        )

        this.props.toggleModal()
    }

    handleError(error) {
        console.log(error)
    }

    componentWillMount() {}
    componentWillUnmount() {}
    render() {
        let {modal, toggleModal} = this.props

        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal()
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal()
                        }}>
                        Import Tag List
                    </ModalHeader>
                    <ModalBody>
                        <CSVReader
                            cssClass="csv-reader-input"
                            label="Import File"
                            onFileLoaded={this.handleUpload}
                            onError={this.handleError}
                            parserOptions={{
                                header: true,
                                dynamicTyping: true,
                                skipEmptyLines: true,
                            }}
                            inputId="import_plc_csv"
                            //inputStyle={{color: 'red'}}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
