import React, {Component, Fragment} from 'react'
import {
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import autobind from 'auto-bind'
import Creatable from 'react-select/creatable'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

const smsRegexPattern =
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
const emailRegexPattern =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.nodeId = this.props.selectedNode._raw.entity.extras.nodeId

        let email = [],
            sms = []
        if (this.props.alerts[this.nodeId]) {
            email = this.props.alerts[this.nodeId].email.map((x) => {
                return {label: x, value: x}
            })
            sms = this.props.alerts[this.nodeId].sms.map((x) => {
                return {label: x, value: x}
            })
        }

        this.emailOptions = []

        try {
            for (let flow of this.props.flows) {
                for (let i in flow.alerts) {
                    this.emailOptions = this.emailOptions.concat(
                        flow.alerts[i].email
                    )
                }
            }
            this.emailOptions = [...new Set(this.emailOptions)].map((a) => {
                return {
                    label: a,
                    value: a,
                }
            })
        } catch (error) {
            console.log('Could not load previously used emails:')
            console.log(error)
            this.emailOptions = []
        }

        this.state = {
            email: email,
            sms: sms,
        }
    }
    save() {
        let obj = {
            id: this.nodeId,
            email: this.state.email.map((x) => x.value),
            sms: this.state.sms.map((x) => x.value),
        }
        this.props.setAlertValues(obj)
        this.props.toggleModal()
    }
    componentWillUnmount() {}
    render() {
        const {modal, toggleModal, flows} = this.props

        /*let smsOptions = flows.map(flow => flow.sms || []).flat(),
        emailOptions = flows.map(flow => flow.email || []).flat();
    smsOptions = [...new Set(smsOptions)].map(sms => { return { label: sms, value: sms}});
    emailOptions = [...new Set(emailOptions)].map(email => { return { label: email, value: email}});
    */
        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Alert Manager
                    </ModalHeader>
                    <ModalBody>
                        {/*<Row>
              <Col xs="12" className="mb-4">
                <label>SMS:</label>
                <Creatable
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  name="sms"
                  isMulti
                  value={this.state.sms}
                  onChange={(sms) => {
                    for (let i in sms) {
                      if (sms[i].__isNew__ && !smsRegexPattern.test(sms[i].value)) {
                        return;
                      }
                    }
                    this.setState({ sms });
                  }}
                  options={[]}
                />
              </Col>
            </Row>*/}
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Email:</label>
                                <Creatable
                                    components={{Input: CustomSelectInput}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="email"
                                    isMulti
                                    value={this.state.email}
                                    onChange={(email) => {
                                        for (let i in email) {
                                            if (
                                                email[i].__isNew__ &&
                                                !emailRegexPattern.test(
                                                    email[i].value
                                                )
                                            ) {
                                                return
                                            }
                                        }
                                        this.setState({email})
                                    }}
                                    options={this.emailOptions}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
