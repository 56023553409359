import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardBody, Button} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import Chart from 'react-google-charts'
import {cloneDeep} from 'lodash'

export const TimelineChart = (props) => {
    const data = cloneDeep(props.data)
    const dataset = [
        [
            {type: 'string', id: 'Asset'},
            {type: 'string', id: 'Status'},
            {role: 'style'},
            {type: 'date', id: 'Start'},
            {type: 'date', id: 'End'},
        ],
    ].concat(
        cloneDeep(
            data.map((d) => {
                d.pop()
                return d
            })
        )
    )

    return (
        <Chart
            width={'100%'}
            height={'100px'}
            chartType="Timeline"
            loader={<div>Loading Chart</div>}
            data={dataset}
            options={{
                timeline: {showBarLabels: false, showRowLabels: false},
                avoidOverlappingGridLines: false,
            }}
            chartEvents={[
                {
                    eventName: 'select',
                    callback: ({chartWrapper}) => {
                        const chart = chartWrapper.getChart()
                        const selection = chart.getSelection(),
                            row = props.data[selection[0].row]
                        props.onTimelineSelect(row)
                    },
                },
            ]}
            rootProps={{'data-testid': '2'}}
        />
    )
}
