import moment from 'moment'

export const secondsToHHMMSS = (secondInput) => {
    let hours = Math.floor(secondInput / 3600),
        minutes = Math.floor((secondInput - hours * 3600) / 60),
        seconds = secondInput - hours * 3600 - minutes * 60

    if (hours < 10) hours = '0' + hours
    if (minutes < 10) minutes = '0' + minutes
    if (seconds < 10) seconds = '0' + seconds

    if (hours >= 24) {
        return Math.floor(hours / 24) + ' days ago'
    } else return hours + ':' + minutes + ':' + seconds
}

export const generateHash = () => {
    return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    )
}

export const tileColor = (state) => {
    if (state === 'In-Cycle') {
        return 'rgb(46, 204, 113)'
    } else if (
        state === 'Downtime' ||
        state === 'Machine Waiting' ||
        state === 'Machine Idle'
    ) {
        return 'rgb(231, 76, 60)'
    } else if (
        state === 'Machine Off' ||
        state === 'Machine Unavailable' ||
        state === 'Disconnected'
    ) {
        return 'rgb(85, 85, 92)'
    } else {
        return '#f0ad4e'
    }
}

export const stringToDateRange = (str) => {
    if (str === 'today') return [moment().startOf('day'), moment()]
    else if (str === 'yesterday')
        return [
            moment().startOf('day').add(-1, 'days'),
            moment().endOf('day').add(-1, 'days'),
        ]
    else if (str === 'thisWeek') return [moment().startOf('isoWeek'), moment()]
    else if (str === 'lastWeek')
        return [
            moment().startOf('isoWeek').add(-7, 'days'),
            moment().endOf('isoWeek').add(-7, 'days'),
        ]
    else if (str === 'thisMonth') return [moment().startOf('month'), moment()]
    else if (str === 'lastMonth')
        return [
            moment().startOf('month').add(-1, 'months'),
            moment().endOf('month').add(-1, 'months'),
        ]
    else if (str === 'thisYear') return [moment().startOf('year'), moment()]
}

export const iteratePlaceholderName = (array, placeholder) => {
    const newInstances = array
        .map((o) => o.name)
        .filter((o) => o.includes(placeholder))
    let instance = placeholder + ' 1'

    if (newInstances.length > 0) {
        newInstances.sort().reverse()
        const lastInstance = parseInt(
            newInstances[0].split(placeholder + ' ')[1]
        )
        instance = placeholder + ' ' + parseInt(lastInstance + 1)
    }
    return instance
}
