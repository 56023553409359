import React, {Component, Fragment} from 'react'
import {Bar, Line, Pie, Radar, Doughnut} from 'react-chartjs-2'
import {Row, Col, Card, CardBody} from 'reactstrap'
import {Textfit} from 'react-textfit'
import autobind from 'auto-bind'
import moment from 'moment'
const rand = () => Math.round(Math.random() * 20)

export default class ChartMaker extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        var options = {
            legend: {
                display: true,
            },
            elements: {
                line: {
                    tension: 0, // disables bezier curves
                },
                point: {
                    radius: 0,
                },
            },
        }

        if (this.props.stacked) {
            options.scales = {
                xAxes: [
                    {
                        stacked: false,
                        display: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: false,
                    },
                ],
            }
        }

        this.state = {
            options,
            availability: this.props.availability || [],
            performance: this.props.performance || [],
            quality: this.props.quality || [],
            labels: this.props.labels || [],
            targets: this.props.targets || [],
            doughnut: {
                labels: [
                    'In-Cycle',
                    'In-Cycle',
                    'Yellow',
                    'In-Cycle',
                    'Purple',
                    'In-Cycle',
                ],
                datasets: [
                    {
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.9)',
                            'rgba(75, 192, 192, 0.9)',
                            'rgba(255, 206, 86, 0.9)',
                            'rgba(75, 192, 192, 0.9)',
                            'rgba(153, 102, 255, 0.9)',
                            'rgba(75, 192, 192, 0.9)',
                        ],
                        borderColor: [
                            'rgba(75, 192, 192, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(75, 192, 192, 1)',
                        ],
                        borderWidth: 0,
                    },
                ],
            },
            bar: {
                labels: this.props.labels ? this.props.labels : [],
                datasets: [
                    {
                        type: 'line',
                        label: 'Target',
                        borderColor: 'rgb(54, 162, 235)',
                        borderWidth: 2,
                        fill: false,
                        data: this.props.targets,
                    },
                    {
                        type: 'bar',
                        label: 'Availability',
                        backgroundColor: 'rgb(75, 192, 75)',
                        data: this.props.availability,
                    },
                    {
                        type: 'bar',
                        label: 'Performance',
                        backgroundColor: 'rgb(50, 50, 192)',
                        data: this.props.performance,
                    },
                    {
                        type: 'bar',
                        label: 'Quality',
                        backgroundColor: 'rgb(255, 99, 132)',
                        data: this.props.quality,
                    },
                    {
                        type: 'bar',
                        label: 'OEE',
                        backgroundColor: 'rgb(255, 99, 255)',
                        data: this.props.OEE,
                    },
                ],
            },
        }
    }

    componentWillMount() {}

    render() {
        return (
            <>
                <Bar data={this.state.bar} options={this.state.options} />
            </>
        )
    }
}
