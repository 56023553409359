import React, { Component } from 'react'
import {
    Form,
    FormGroup,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Alert,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Label,
} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import SchemaForm from '../Schemas/common/Form'
import OEEInteractiveTimeline from '../OEEInteractiveTimeline'
import SelectInput from '../Schemas/common/select-input'

// Accumine SDK imports
import { secondsToHHMMSS, tileColor } from 'SDK/helpers'
import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'
import 'SDK/helpers.css'

// Accumine Sub-modules
import DowntimeHistoryModule from '../DowntimeHistoryModule'
import SchemaDataViewerFullAccess from '../SchemaDataViewerFullAccess'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            toast: false,
            partOptions: [],
            operatorOptions: [],
            partCount: 0,
            rejectCount: 0,
            earnedHoursCount: 0,
            schemas: {
                hmiId: '',
                partsId: '',
                notesId: '',
                CMMSTicketId: '',
                CMMSPMId: '',
            },
            validators: {
                hmiModelValidator: null,
                partsModelValidator: null,
                notesModelValidator: null,
                CMMSTicketModelValidator: null,
                CMMSPMModelValidator: null,
            },
            dataModels: {
                hmiId: null,
                partsId: null,
                notesId: null,
                CMMSTicketId: null,
                CMMSPMId: null,
            },
            mostRecentEntry: {},
            dataModelsList: [],
            activeColor: 'white',
            totalPartCount: 0,
            timeStart: moment().startOf('day'),
            timeEnd: moment().endOf('day'),
            dropdownOpen: false,
        }
    }
    async componentWillMount() {
        await this.loadDataModels()
        this.getMostRecentEntry(this.state.schemas.hmiId)
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    toggleModal(type) {
        this.setState({ [type]: !this.state[type] })
    }

    infoModal() {
        var previousRow = this.state.mostRecentEntry || []
        return (
            <Modal
                isOpen={this.state.modalInfo}
                toggle={() => {
                    this.toggleModal('modalInfo')
                }}
                size="lg"
                style={{ maxWidth: '90%', width: '80%' }}>
                <ModalHeader>History</ModalHeader>
                <ModalBody>
                    {/*<SchemaDataViewerFullAccess dataModelId={this.state.schemas.hmiId} />*/}
                    {this.state.modalInfo ? (
                        <OEEInteractiveTimeline
                            deviceIds={[this.props.device.deviceId]}
                        />
                    ) : (
                        <></>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.toggleModal('modalInfo')
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    async saveState(
        stateName,
        value,
        timestamp,
        deviceId = this.props.device.deviceId
    ) {
        this.showToast(true)
        await API.post(
            'states',
            {
                nodeId: deviceId,
                deviceId: deviceId,
                name: stateName,
                timestamp: moment(timestamp).toISOString(),
                timeStart: moment(timestamp).toISOString(),
                timeEnd: moment(timestamp).toISOString(),
                value: value,
                metaData: [
                    {
                        name: stateName.toLowerCase(),
                        value: value,
                    },
                ],
            },
            2
        )
        this.showToast(false)
    }

    async savePartModelEntry(partNumber, hourlyTarget) {
        let data = await API.post(
            `data-models/${this.state.schemas.partsId}/add-record`,
            {
                partNumber: partNumber,
                hourlyTarget: hourlyTarget,
            },
            2
        )
    }

    async saveRecordMachineEntry(operator, part) {
        if (
            this.props.device.oeeRejectType &&
            this.props.device.oeeRejectType === 'manual'
        )
            this.saveState(
                'Reject',
                this.state.rejectCount,
                moment().toISOString()
            )
        if (
            this.props.device.oeePartType &&
            this.props.device.oeePartType === 'manual'
        )
            this.saveState(
                'Part Count',
                this.state.partCount
                    ? this.state.partCount
                    : this.state.totalPartCount,
                moment().toISOString()
            )

        let data = await API.post(
            `data-models/${this.state.schemas.hmiId}/add-record`,
            {
                timestamp: moment().toISOString(),
                asset: this.props.device.deviceId,
                operator: this.state.operatorSelected
                    ? this.state.operatorSelected
                    : operator._id,
                partNumber: this.state.partSelected
                    ? this.state.partSelected
                    : part._id,
                earnedHours: this.state.earnedHoursCount,
                rejects: this.state.rejectCount,
                parts: this.state.partCount
                    ? this.state.partCount
                    : this.state.totalPartCount,
                time_end: moment().toISOString(),
            },
            2
        )

        if (data) {
            this.toggleModal('modalHMI')
            if (this.props.onComplete) this.props.onComplete()
            this.getMostRecentEntry(this.state.schemas.hmiId)
        } else {
            alert('Record failed to save')
        }
    }

    showToast(status = false) {
        this.setState({ toast: status })
    }

    modal() {
        const { validators, schemas, dataModelsList, dataModels } = this.state
        const partNumber = this.state.mostRecentEntry[0]
            ? this.state.mostRecentEntry[0]['partNumber']
            : null
        const operator = this.state.mostRecentEntry[0]
            ? this.state.mostRecentEntry[0]['operator']
            : null

        return (
            <Modal
                isOpen={this.state.modalHMI}
                toggle={() => {
                    this.toggleModal('modalHMI')
                }}>
                <ModalHeader>Machine Entry</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <SelectInput
                                onChange={(m) => {
                                    this.setState({ partSelected: m })
                                }}
                                fieldName="Select Part"
                                default={
                                    partNumber
                                        ? {
                                            label: partNumber.partNumber,
                                            value: partNumber._id,
                                        }
                                        : null
                                }
                                options={this.state.partOptions.map((m) => {
                                    return {
                                        label: m.partNumber,
                                        value: m._id,
                                    }
                                })}
                            />
                        </FormGroup>

                        <FormGroup>
                            <SelectInput
                                onChange={(m) => {
                                    this.setState({ operatorSelected: m })
                                }}
                                fieldName="Select Operator"
                                default={
                                    operator
                                        ? {
                                            label: operator.username,
                                            value: operator._id,
                                        }
                                        : null
                                }
                                options={this.state.operatorOptions.map((m) => {
                                    return {
                                        label: m.username,
                                        value: m._id,
                                    }
                                })}
                            />
                        </FormGroup>

                        <FormGroup>
                            <div className="d-flex justify-content-between">
                                <Label>Add Parts</Label>
                                <span className="text-muted">Current Total: {this.state.totalPartCount}</span>
                            </div>

                            <Input
                                type="number"
                                name="partCount"
                                defaultValue={0}
                                onChange={(e) => {
                                    this.setState({
                                        partCount: e.target.valueAsNumber,
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Add Rejects</Label>
                            <Input
                                type="number"
                                name="rejectCount"
                                defaultValue={0}
                                onChange={(e) => {
                                    this.setState({
                                        rejectCount: e.target.valueAsNumber,
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Add Earned Hours</Label>
                            <Input
                                type="number"
                                name="earnedHoursCount"
                                defaultValue={0}
                                onChange={(e) => {
                                    this.setState({
                                        earnedHoursCount:
                                            e.target.valueAsNumber,
                                    })
                                }}
                            />
                        </FormGroup>

                        <hr />
                        <Row>
                            <Col xs="6">
                                <Button
                                    block
                                    size="lg"
                                    onClick={() =>
                                        this.saveRecordMachineEntry(
                                            operator,
                                            partNumber
                                        )
                                    }>
                                    Submit
                                </Button>
                            </Col>
                            <Col xs="6">
                                <Button
                                    block
                                    color="danger"
                                    size="lg"
                                    onClick={() => {
                                        this.toggleModal('modalHMI')
                                    }}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
        )
    }

    partsModal() {
        const { validators, schemas, dataModelsList, dataModels } = this.state
        return (
            <Modal
                isOpen={this.state.modalParts}
                toggle={() => {
                    this.toggleModal('modalParts')
                }}>
                <ModalHeader>Add New Part</ModalHeader>
                <ModalBody>
                    <SchemaForm
                        schema={validators.partsModelValidator}
                        editingRecord={null}
                        dataModels={dataModelsList}
                        dataModel={dataModels.partsModel}
                        manualDataLinkFilters={null}
                        onSubmit={(document) => {
                            this.toggleModal('modalHMI')
                            this.toggleModal('modalParts')
                            setTimeout(() => {
                                this.toggleModal('modalHMI')
                            }, 3000)
                        }}
                        hideArchive={true}
                        timestampsToToday={true}
                        ObjectFieldTemplate={this.StandardTemplate}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.toggleModal('modalParts')
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    notesModal() {
        const { validators, schemas, dataModelsList, dataModels } = this.state
        return (
            <Modal
                isOpen={this.state.modalNotes}
                toggle={() => {
                    this.toggleModal('modalNotes')
                }}>
                <ModalHeader>Machine Entry</ModalHeader>
                <ModalBody>
                    <Alert color="primary">
                        Some values are automatically populated for your
                        convenience.
                    </Alert>
                    <SchemaForm
                        schema={validators.notesModelValidator}
                        editingRecord={{
                            asset: this.props.device,
                            timestamp: moment().toISOString(),
                        }}
                        dataModels={dataModelsList}
                        dataModel={dataModels.notesModel}
                        manualDataLinkFilters={null}
                        onSubmit={(document) => {
                            this.toggleModal('modalNotes')
                        }}
                        hideArchive={true}
                        timestampsToToday={true}
                        ObjectFieldTemplate={this.StandardTemplate}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.toggleModal('modalNotes')
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    CMMSTicketModal() {
        const { validators, schemas, dataModelsList, dataModels } = this.state
        return (
            <Modal
                isOpen={this.state.modalCMMSTicket}
                toggle={() => {
                    this.toggleModal('modalCMMSTicket')
                }}>
                <ModalHeader>Part Information</ModalHeader>
                <ModalBody>
                    <Alert color="primary">
                        Some values are automatically populated for your
                        convenience.
                    </Alert>
                    <SchemaForm
                        schema={validators.CMMSTicketModelValidator}
                        editingRecord={{
                            asset: this.props.device,
                            timestamp: moment().toISOString(),
                        }}
                        dataModels={dataModelsList}
                        dataModel={dataModels.CMMSTicketModel}
                        manualDataLinkFilters={null}
                        onSubmit={(document) => {
                            this.toggleModal('modalNotes')
                        }}
                        hideArchive={true}
                        timestampsToToday={true}
                        ObjectFieldTemplate={this.StandardTemplate}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.toggleModal('modalCMMSTicket')
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    CMMSPMModal() {
        const { validators, schemas, dataModelsList, dataModels } = this.state
        return (
            <Modal
                isOpen={this.state.modalCMMSPM}
                toggle={() => {
                    this.toggleModal('modalCMMSPM')
                }}>
                <ModalHeader>Part Information</ModalHeader>
                <ModalBody>
                    <Alert color="primary">
                        Some values are automatically populated for your
                        convenience.
                    </Alert>
                    <SchemaForm
                        schema={validators.CMMSPMModelValidator}
                        editingRecord={{
                            asset: this.props.device,
                            timestamp: moment().toISOString(),
                        }}
                        dataModels={dataModelsList}
                        dataModel={dataModels.CMMSPMModel}
                        manualDataLinkFilters={null}
                        onSubmit={(document) => {
                            this.toggleModal('modalCMMSPM')
                        }}
                        hideArchive={true}
                        timestampsToToday={true}
                        ObjectFieldTemplate={this.StandardTemplate}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.toggleModal('modalCMMSPM')
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    downtimeModal() {
        const deviceId = this.props.device.deviceId
        return (
            <Modal
                isOpen={this.state.modalDowntime}
                toggle={() => {
                    this.toggleModal('modalDowntime')
                }}
                size="lg"
                style={{ maxWidth: '90%', width: '80%' }}>
                <ModalHeader>Downtime History</ModalHeader>
                <ModalBody>
                    <DowntimeHistoryModule deviceIds={[deviceId]} />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.toggleModal('modalDowntime')
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    async getMostRecentEntry(
        dataModelId = this.state.schemas.hmiId,
        deviceId = this.props.device.deviceId,
        orderBy = 'descending'
    ) {
        const { result, count } = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {
                    fieldName: 'timestamp',
                    direction: orderBy,
                },
                limit: 1,
                next: null,
                previous: null,
                filter: [
                    {
                        type: 'PrimaryID',
                        logic: 'contains',
                        value: [deviceId],
                        path: 'asset.deviceId',
                    },
                ],
                timezone: localStorage['timezone'],
            },
            2
        )

        if (count) {
            let mostRecentEntry = this.state.mostRecentEntry
            mostRecentEntry = result.results
            this.setState({ mostRecentEntry })
        } else {
            //console.log('Could not fetch results');
        }
    }

    async getPartOptions(
        dataModelId = this.state.schemas.partsId,
        deviceId = this.props.device.deviceId,
        orderBy = 'ascending',
        asset = this.props.device
    ) {
        let { result, count } = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {
                    fieldName: 'partNumber',
                    direction: orderBy,
                },
                limit: 1000,
                next: null,
                previous: null,
                filter: [
                    {
                        type: 'PrimaryID',
                        logic: 'contains',
                        value: [deviceId],
                        path: 'asset.deviceId',
                    },
                ],
                timezone: localStorage['timezone'],
            },
            2
        )

        if (count) {
            return result.results
        } else {
            let { result, count } = await API.post(
                `data-models/${dataModelId}/paginate`,
                this.partRequestWithoutFilter(),
                2
            )
            if (!count) return []
            return result.results
        }
    }

    partRequestWithoutFilter(
        deviceId = this.props.device.deviceId,
        orderBy = 'ascending'
    ) {
        return {
            sort: {
                fieldName: 'partNumber',
                direction: orderBy,
            },
            limit: 1000,
            next: null,
            previous: null,
            filter: [],
            timezone: localStorage['timezone'],
        }
    }

    async getOperatorOptions(
        dataModelId = this.state.schemas.operatorsId,
        deviceId = this.props.device.deviceId,
        orderBy = 'descending'
    ) {
        const { result, count } = await API.post(
            `data-models/${dataModelId}/paginate`,
            {
                sort: {},
                limit: 1000,
                next: null,
                previous: null,
                filter: [],
                timezone: localStorage['timezone'],
            },
            2
        )

        if (count) {
            return result.results
        } else {
            return []
        }
    }

    async loadDataModels() {
        let dataModelsList = await API.get('data-models', 2)
        const hmiModel = dataModelsList.find(
            (d) => d.name === 'MES/Machine Entry'
        )
        const partsModel = dataModelsList.find((d) => d.name === 'MES/Parts')
        const operatorsModel = dataModelsList.find(
            (d) => d.name === 'MES/Operators'
        )

        if (!hmiModel || !partsModel) {
            alert(
                'This module does not include the necessary schemas for HMI data entry. You must create an HMI schema.'
            )
        } else {
            const hmiModelValidator = await API.get(
                'data-models/' + hmiModel._id + '/validator',
                2
            )
            const partsModelValidator = await API.get(
                'data-models/' + partsModel._id + '/validator',
                2
            )
            const operatorsModelValidator = await API.get(
                'data-models/' + operatorsModel._id + '/validator',
                2
            )
            const defaultHMI = ''

            this.setState({
                schemas: {
                    hmiId: hmiModel._id,
                    partsId: partsModel._id,
                    operatorsId: operatorsModel._id,
                },
                validators: {
                    hmiModelValidator,
                    partsModelValidator,
                    operatorsModelValidator,
                },
                dataModels: {
                    hmiModel,
                    partsModel,
                    operatorsModel,
                },
                dataModelsList,
            })
        }
    }

    async onSubmit() {
        // ** Force start of day for default part count.
        // ** Need to make sure it doesn't use a default start date that is too long ago (load time).
        const filteredStartTime =
            this.state.mostRecentEntry.length > 0 &&
                moment(this.state.mostRecentEntry[0].timestamp)
                    .add(1, 'second')
                    .toISOString() > moment().startOf('day').toISOString()
                ? moment(this.state.mostRecentEntry[0].timestamp)
                    .add(1, 'second')
                    .toISOString()
                : moment().startOf('day').toISOString()
        const timeEnd = moment().toISOString()
        const partOptions = await this.getPartOptions()
        const operatorOptions = await this.getOperatorOptions()

        let { data } = await API.post('historical/aggregate2', {
            timeStart: filteredStartTime,
            timeEnd: timeEnd,
            state: ['Part Count'],
            deviceId: [this.props.device.deviceId],
            groupByTimeUnit: 'total',
            logic: 'count',
        })

        this.setState({
            totalPartCount: data
                ? data.devices[this.props.device.deviceId]['Part Count'][0]
                    .count
                : 0,
            partOptions,
            operatorOptions,
        })

        this.toggleModal('modalHMI')
    }

    onInfo() {
        this.toggleModal('modalInfo')
    }

    render() {
        const { mostRecentEntry } = this.state
        const mostRecentEntryTimestamp =
            mostRecentEntry.length > 0
                ? moment(mostRecentEntry[0].timestamp).fromNow()
                : 'Never'
        return (
            <div>
                <Row>
                    <Col
                        xs="4"
                        className="text-center"
                        onClick={this.onSubmit}
                        style={{ cursor: 'pointer' }}>
                        <div>
                            <i
                                className="simple-icon-pencil"
                                style={{
                                    color: `${this.state.activeColor}`,
                                    fontSize: 10,
                                }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="4"
                        className="text-center"
                        onClick={() => {
                            this.toggleModal('modalDowntime')
                        }}
                        style={{ cursor: 'pointer' }}>
                        <div>
                            <i
                                className="simple-icon-tag"
                                style={{
                                    color: `${this.state.activeColor}`,
                                    fontSize: 10,
                                }}
                            />
                        </div>
                    </Col>
                    <Col
                        xs="4"
                        className="text-center"
                        style={{ cursor: 'pointer' }}>
                        <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={() => {
                                this.toggleModal('dropdownOpen')
                            }}>
                            <DropdownToggle tag="div">
                                <i
                                    className="simple-icon-menu"
                                    style={{
                                        color: `${this.state.activeColor}`,
                                        fontSize: 10,
                                    }}
                                />
                            </DropdownToggle>
                            <DropdownMenu>
                                {/*<DropdownItem header>Departments</DropdownItem>
                <DropdownItem disabled>Maintenance Call</DropdownItem>
                <DropdownItem disabled>Material Call</DropdownItem>
                <DropdownItem disabled>Supervisor Call</DropdownItem>
                <DropdownItem disabled>Quality Call</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>Maintenance</DropdownItem>
                <DropdownItem onClick={()=>{this.toggleModal('modalCMMSTicket')}}>Add Maintenance Ticket</DropdownItem>
                <DropdownItem onClick={()=>{this.toggleModal('modalCMMSPM')}}>Schedule Preventative Maintenance</DropdownItem>
                <DropdownItem divider /> */}
                                <DropdownItem header>
                                    Operator Tools
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        this.toggleModal('modalHMI')
                                    }}>
                                    Machine Audit
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        this.toggleModal('modalDowntime')
                                    }}>
                                    Downtime History
                                </DropdownItem>
                                {/*<DropdownItem onClick={()=>{this.toggleModal('modalNotes')}}>Machine Notes</DropdownItem>*/}
                                <DropdownItem
                                    onClick={() => {
                                        this.toggleModal('modalInfo')
                                    }}>
                                    Real-Time OEE
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="text-center">
                        <strong
                            style={{
                                fontSize: 10,
                                color: `${this.state.activeColor}`,
                            }}>
                            Most Recent Entry: {mostRecentEntryTimestamp}
                        </strong>
                    </Col>
                </Row>
                {this.modal()}
                {this.infoModal()}
                {this.partsModal()}
                {/*
          this.notesModal()}
          this.CMMSTicketModal()
          this.CMMSPMModal()
        */}
                {this.downtimeModal()}
            </div>
        )
    }
}
