import React from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

import SelectInput from '../../common/select-input'

const DecideModal = (props) => {
    const source = props.elements.find(
            (el) => el.id === props.editingConnection.sourceId
        ),
        allowedOutcomes = source.data.opts.outcomes.filter(
            (o) =>
                !props.elements.find(
                    (el) => el.source && el.data.opts.decisionOutcomeId === o.id
                )
        )

    return (
        <Modal size="sm" isOpen={true}>
            <ModalHeader>Choose outcome</ModalHeader>
            <ModalBody>
                <SelectInput
                    onChange={(outcomeId) =>
                        props.submit(
                            source.data.opts.outcomes.find(
                                (o) => o.id === outcomeId
                            )
                        )
                    }
                    fieldName="Outcome"
                    default={null}
                    options={allowedOutcomes.map((o) => {
                        return {label: o.name, value: o.id}
                    })}
                />
            </ModalBody>
        </Modal>
    )
}

DecideModal.propTypes = {
    editingConnection: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    elements: PropTypes.array.isRequired,
}

export default DecideModal
