import React, {Component, Fragment} from 'react'
import Select from 'react-select'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class MultiAssetPicker extends Component {
    id = 'MultiAssetPicker'
    field = 'deviceIds'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : []

        this.state = {
            assets: [],
            selectedAssets: initialValue,
        }

        if (this.props && this.props.cb) {
            this.props.cb('deviceIds', initialValue)
        }
    }

    getSelection() {
        return this.state.selectedAssets.map((o) => o.value)
    }
    handleAssetChange(selectedAssets) {
        this.setState({
            selectedAssets,
        })

        if (this.props.cb) {
            this.props.cb(
                'deviceIds',
                selectedAssets.map((o) => o.value)
            )
        }
    }
    async getAssets() {
        const assets = await API.get('devices?all=true')
        if (!assets) return alert('Could not fetch factory assets.')

        let init = assets.map((x) => {
            return {label: x.name, value: x.deviceId}
        })
        if (this.state.selectedAssets.length) {
            init = this.state.selectedAssets.map((x) => {
                const asset = assets.find((a) => a.deviceId === x)
                if (asset) {
                    return {
                        label: asset.name,
                        value: asset.deviceId,
                    }
                }
            })
        }

        this.setState({assets, selectedAssets: init})

        if (this.props && this.props.cb) {
            this.props.cb(
                'deviceIds',
                init.map((a) => (a ? a.value : null))
            )
        }
    }

    async componentWillMount() {
        await this.getAssets()
    }

    render() {
        return (
            <Fragment>
                <label>Assets:</label>
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="assets"
                    isMulti
                    value={this.state.selectedAssets}
                    onChange={this.handleAssetChange}
                    options={this.state.assets.map((a, i) => {
                        return {label: a.name, value: a.deviceId, key: i}
                    })}
                />
            </Fragment>
        )
    }
}
