import React, {Fragment, Component} from 'react'
import {Row, Col, Input, Button} from 'reactstrap'
import PropTypes from 'prop-types'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'

import FormulaInput from '../../common/FormulaInput/index'

const OPTIONS = [
    {
        label: 'Equal to',
        value: 'equalTo',
        showInput: true,
    },
    {
        label: 'Not equal to',
        value: 'notEqualTo',
        showInput: true,
    },
    {
        label: 'Greater than',
        value: 'greaterThan',
        showInput: true,
    },
    {
        label: 'Less than',
        value: 'lessThan',
        showInput: true,
    },
    {
        label: 'Between (Exclusive)',
        value: 'between',
        showInput: true,
    },
    {
        label: 'Is empty',
        value: 'isEmpty',
        showInput: false,
    },
    {
        label: 'Is not empty',
        value: 'isNotEmpty',
        showInput: false,
    },
]

export default class NumberFilter extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = []
    showBorder = false
    id = 'QueryBuilder.NumberFilter'
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        let initial = {
            selected: OPTIONS[0],
            input: 0,
        }

        if (props) {
            initial = {
                selected: OPTIONS.find((o) => o.value === props.logic)
                    ? OPTIONS.find((o) => o.value === props.logic)
                    : OPTIONS[0],
                input: props.value || 0,
            }
        }

        this.state = {
            selected: initial.selected,
            input: initial.input,
        }
    }

    handleSelectChange(selected) {
        this.setState({
            selected,
            //input: selected.value === 'between' ? [0, 1] : 0
        })
    }

    handleInputChange(event) {
        this.setState({input: parseFloat(event.target.value)})
    }

    handleInputFormulaChange(input) {
        input.type = 'formula'
        this.setState({input})
    }

    handleBetweenLowerInputFormulaChange(input) {
        input.type = 'formula'
        let lower = input,
            upper = this.state.input[1]

        this.setState({
            input: [lower, upper],
        })
    }

    handleBetweenUpperInputFormulaChange(input) {
        input.type = 'formula'
        let lower = this.state.input[0],
            upper = input

        this.setState({
            input: [lower, upper],
        })
    }

    handleBetweenLowerInputChange(event) {
        let lower = parseFloat(event.target.value),
            upper = this.state.input[1]

        this.setState({
            input: [lower, upper],
        })
    }

    handleBetweenUpperInputChange(event) {
        let lower = this.state.input[0],
            upper = parseFloat(event.target.value)

        this.setState({
            input: [lower, upper],
        })
    }

    disableSubmit() {
        if (!(this.props.variables && this.props.formulas)) {
            if (this.state.selected.value === 'between') {
                if (this.state.input.constructor !== Array) {
                    return true
                } else if (this.state.input.some((i) => isNaN(i))) {
                    return true
                } else {
                    return false
                }
            } else {
                return this.state.selected.showInput && this.state.input === ''
            }
        } else if (this.state.selected.value === 'between') {
            return !(
                this.state.input.constructor === Array &&
                this.state.input.some((i) => i !== undefined)
            )
        }
    }

    makePrettyString() {
        const {label, value} = this.state.selected
        const expression = this.props.variables && this.props.formulas

        let prettyString = this.props.fieldName + ' ' + label.toLowerCase()

        if (
            value === 'equalTo' ||
            value === 'notEqualTo' ||
            value === 'greaterThan' ||
            value === 'lessThan'
        ) {
            if (expression) {
                prettyString += ` ${this.state.input.plainText}`
            } else {
                prettyString += ' ' + this.state.input
            }
        } else if (value === 'between') {
            if (expression) {
                prettyString += ` ${this.state.input[0].plainText} and ${this.state.input[1].plainText}`
            } else {
                prettyString += ' ' + this.state.input.join(' and ')
            }
        }

        return prettyString
    }

    submit() {
        if (this.props.onSubmit) {
            this.props.onSubmit({
                logic: this.state.selected.value,
                value: this.state.input,
                prettyString: this.makePrettyString(),
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Select
                            components={{Input: CustomSelectInput}}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="number-filter-options"
                            value={this.state.selected}
                            onChange={this.handleSelectChange}
                            options={OPTIONS}
                        />
                    </Col>
                </Row>

                {this.state.selected &&
                this.state.selected.value !== 'between' &&
                this.state.selected.showInput ? (
                    <Row>
                        <Col>
                            <hr />
                            {this.props.variables && this.props.formulas ? (
                                <FormulaInput
                                    variables={this.props.variables}
                                    formulas={this.props.formulas}
                                    onChange={this.handleInputFormulaChange}
                                    default={this.props.defaultFormulaInput}
                                    type="formula"
                                />
                            ) : (
                                <Input
                                    type="number"
                                    name="number-filter-input"
                                    value={this.state.input}
                                    placeholder={
                                        this.state.selected.label + '...'
                                    }
                                    onChange={this.handleInputChange}
                                />
                            )}
                        </Col>
                    </Row>
                ) : null}

                {this.state.selected &&
                this.state.selected.value === 'between' &&
                this.state.selected.showInput ? (
                    <Fragment>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                {this.props.variables && this.props.formulas ? (
                                    <FormulaInput
                                        variables={this.props.variables}
                                        formulas={this.props.formulas}
                                        onChange={
                                            this
                                                .handleBetweenLowerInputFormulaChange
                                        }
                                        default={this.props.defaultFormulaInput}
                                        type="formula"
                                    />
                                ) : (
                                    <Input
                                        type="number"
                                        name="number-filter-input-between-0"
                                        value={this.state.input[0]}
                                        placeholder="Enter a number..."
                                        onChange={
                                            this.handleBetweenLowerInputChange
                                        }
                                    />
                                )}
                            </Col>
                            <Col xs="6">
                                {this.props.variables && this.props.formulas ? (
                                    <FormulaInput
                                        variables={this.props.variables}
                                        formulas={this.props.formulas}
                                        onChange={
                                            this
                                                .handleBetweenUpperInputFormulaChange
                                        }
                                        default={this.props.defaultFormulaInput}
                                        type="formula"
                                    />
                                ) : (
                                    <Input
                                        type="number"
                                        name="number-filter-input-between-1"
                                        value={this.state.input[1]}
                                        placeholder="Enter a number..."
                                        onChange={
                                            this.handleBetweenUpperInputChange
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                    </Fragment>
                ) : null}
                <Row>
                    <Col className="text-right">
                        <Button
                            className="mt-2"
                            color="secondary"
                            size="xs"
                            disabled={this.disableSubmit()}
                            onClick={this.submit}>
                            Update filter
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
