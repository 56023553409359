import React, {useState} from 'react'
import {
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Card,
    CardFooter,
    CardBody,
    CardHeader,
} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import flattenVariables from '../../../helpers/flattenVariables'
import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'
import FormulaInput from '../../../../common/FormulaInput'

export default (props) => {
    const [parameters, setParameters] = useState({
        name: props.element.data.opts.name || 'Modify',
        description: props.element.data.opts.description || '',
        modifications: props.element.data.opts.modifications || [],
        submitted: props.element.data.opts.submitted || true,
    })

    const flattened = flattenVariables(
        props.variables.filter(
            (v) => !v.multi && v.transformId !== props.element.id
        )
    )

    const renderModifyRow = ({
        id,
        path = null,
        variableId = null,
        expression = {
            text: '',
            plainText: '',
            mentions: [],
            type: 'formula',
            map: [],
        },
    }) => {
        const idx = parameters.modifications.findIndex((m) => m.id === id) + 1
        let defaultPath = path ? flattened.find((f) => f.id === path) : null

        return (
            <Row className="mb-4">
                <Col xs="12">
                    <Card>
                        <CardHeader className="m-0 p-0">
                            <h5 className="m-0 p-0 pl-4 pt-4">
                                Modifying Field #{idx}
                            </h5>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Row>
                                <Col xs="12">
                                    <hr />
                                </Col>
                                <Col xs="12" className="mb-2">
                                    <SelectInput
                                        onChange={(v) => {
                                            let idx =
                                                parameters.modifications.findIndex(
                                                    (m) => m.id === id
                                                )
                                            parameters.modifications[idx].path =
                                                v
                                            parameters.modifications[
                                                idx
                                            ].variableId = v.split('.')[0]
                                            setParameters({...parameters})
                                        }}
                                        fieldName="Field"
                                        default={
                                            defaultPath
                                                ? {
                                                      label: defaultPath.display,
                                                      value: defaultPath.id,
                                                  }
                                                : null
                                        }
                                        options={flattened.map((f) => {
                                            return {
                                                label: f.display,
                                                value: f.id,
                                            }
                                        })}
                                    />
                                </Col>
                                <Col xs="12">
                                    <FormulaInput
                                        variables={props.variables.filter(
                                            (v) =>
                                                !v.multi &&
                                                v.transformId !==
                                                    props.element.id
                                        )}
                                        formulas={props.formulas}
                                        default={expression || null}
                                        type="formula"
                                        onChange={(expression) => {
                                            let idx =
                                                parameters.modifications.findIndex(
                                                    (m) => m.id === id
                                                )
                                            parameters.modifications[
                                                idx
                                            ].expression = expression
                                            parameters.modifications[
                                                idx
                                            ].expression.type = 'formula'
                                            setParameters({...parameters})
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button
                                size="xs"
                                onClick={() => {
                                    parameters.modifications =
                                        parameters.modifications.filter(
                                            (m) => m.id !== id
                                        )
                                    setParameters({...parameters})
                                }}
                                color="danger">
                                Remove
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }

    const submit = () => {
        props.onChange(parameters, [])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>

                        <FormGroup className="text-right">
                            <Button
                                size="xs"
                                onClick={() => {
                                    parameters.modifications.push({
                                        id: uuidv4(),
                                    })
                                    setParameters({...parameters})
                                }}>
                                Add modification
                            </Button>
                        </FormGroup>

                        <FormGroup>
                            {parameters.modifications.map((m) =>
                                renderModifyRow(m)
                            )}
                        </FormGroup>
                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
