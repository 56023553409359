import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    Label,
    CardHeader,
    Input,
} from 'reactstrap'
import Switch from 'rc-switch'
import autobind from 'auto-bind'

import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class BlackText extends Component {
    id = 'BlackText'
    field = 'black'
    constructor(props) {
        super(props)
        autobind(this)

        const initialValue =
            this.props &&
            Object.prototype.hasOwnProperty.call(this.props, 'existing') &&
            this.props.existing !== undefined
                ? this.props.existing
                : false

        this.state = {
            value: initialValue,
        }

        if (this.props && this.props.cb) {
            this.props.cb('black', initialValue)
        }
    }

    handleValueChange(value) {
        this.setState({value})

        if (this.props.cb) {
            this.props.cb('black', value)
        }
    }

    render() {
        return (
            <Fragment>
                <label>Inverse text color to black:</label>
                <Switch
                    className="custom-switch custom-switch-primary"
                    checked={this.state.value}
                    onChange={this.handleValueChange}
                />
            </Fragment>
        )
    }
}
