import React, {useEffect, useState} from 'react'
import ReactAnsi from 'react-ansi'
import ReactFlow, {Controls, Background, Handle} from 'react-flow-renderer'
import ReactJson from 'react-json-view'
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Button,
    Badge,
    UncontrolledTooltip,
} from 'reactstrap'
import {toast} from 'react-toastify'
import moment from 'moment'
import Select from 'react-select'
import {cloneDeep} from 'lodash'

import {NodeIcons} from './CustomNode'
import {getStatus} from './StatusOptions'
import CustomSelectInput from 'components/CustomSelectInput'

export default (props) => {
    const height = document.documentElement.offsetHeight * 0.75
    const [connected, setConnected] = useState(props.status.server === 'online')
    const [nodeDict, setNodeDict] = useState({})
    const [variableDict, setVariableDict] = useState({})
    const [selected, setSelected] = useState({
        label: 'Show all logs/data',
        value: null,
    })
    const [view, setView] = useState('Flow Status')
    const [logs, setLogs] = useState([
        {id: null, value: `Debug start time: ${moment().format('lll')}`},
    ])
    const [status, setStatus] = useState({flow: 'unknown'})
    const [frames, setFrames] = useState([])
    const [framesToDisplay, setFramesToDisplay] = useState({})

    const DebugNode = (_props) => {
        const status = getStatus(_props.data.status)
        return (
            <>
                {_props.data.nodeType !== 'input' ? (
                    <Handle
                        type="target"
                        position="left"
                        isConnectable={_props.isConnectable}
                        style={{marginRight: 25}}
                    />
                ) : null}
                <div
                    style={{
                        textAlign: 'center',
                    }}>
                    <Row>
                        <Col>
                            <i
                                style={{fontSize: '40px'}}
                                className={NodeIcons[_props.data.opts.type]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>{_props.data.label}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Badge
                                pill
                                id={`tt_${_props.id}`}
                                color={status.badgeColor}>
                                {_props.data.status}
                            </Badge>
                        </Col>
                    </Row>
                    <UncontrolledTooltip target={`tt_${_props.id}`}>
                        <p className="m-0">{status.nodeDocumentation}</p>
                    </UncontrolledTooltip>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>

                    {_props.data.opts.type === 'ON_DEMAND_TRIGGER' ? (
                        <Row className="m-0 mt-2">
                            <Col className="text-center">
                                <Button
                                    block
                                    color="secondary"
                                    size="xs"
                                    onClick={async () => {
                                        props.mqtt.publish(
                                            `${
                                                JSON.parse(
                                                    localStorage['userObject']
                                                ).factoryId
                                            }/cloud/${props.flow.id}/${
                                                _props.id
                                            }/trigger`
                                        )
                                    }}>
                                    Start Flow
                                </Button>
                            </Col>
                        </Row>
                    ) : null}
                </div>
                {_props.data.nodeType !== 'output' ? (
                    <Handle
                        type="source"
                        position="right"
                        isConnectable={_props.isConnectable}
                    />
                ) : null}
            </>
        )
    }

    const handleNodeStatusUpdate = (data, params, topic) => {
        const nodeId = topic.split('/')[3],
            node = nodeDict[nodeId]
        if (node) {
            logs.push({
                id: nodeId,
                value: `${moment().format('LTS')} | Status Change: ${
                    node.data.label
                } => "${data.status}"`,
            })
            setLogs([...logs])
            status[nodeId] = data.status
            setStatus({...status})
        }
    }
    const handleNodeStateUpdate = (data, params, topic) => {
        const nodeId = topic.split('/')[3],
            node = nodeDict[nodeId]
        if (node) {
            for (let variableId in data.state.variables) {
                const variable = variableDict[variableId]
                frames.push({...data.state.variables[variableId], variableId})
                setFrames([...frames])
            }
        }
    }
    const handleNodeError = (data, params, topic) => {
        const nodeId = topic.split('/')[3],
            node = nodeDict[nodeId]
        if (node) {
            logs.push({
                id: nodeId,
                value: `${moment().format('LTS')} | Error: ${
                    node.data.label
                } => "${data.message}"`,
            })
            setLogs([...logs])
            status[nodeId] = data.status
            setStatus({...status})
        }
    }
    const handleFlowStatusUpdate = (data, params, topic) => {
        status.flow = data.status
        setStatus({...status})
    }

    useEffect(() => {
        const mount = async () => {
            for (let node of props.flow.nodes) {
                nodeDict[node.id] = node
                status[node.id] = 'unknown'
            }
            for (let variable of props.flow.variables) {
                variableDict[variable.id] = variable
            }
            setNodeDict({...nodeDict})
            setStatus({...status})
            setVariableDict({...variableDict})

            try {
                await props.mqtt.publishAction(
                    `${
                        JSON.parse(localStorage['userObject']).factoryId
                    }/cloud/${props.flow.id}/subscribe`,
                    {data: true}
                )
                toast(`Subscribed to flow data.`, {
                    position: 'top-right',
                    type: 'success',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                props.mqtt.on(
                    `${
                        JSON.parse(localStorage['userObject']).factoryId
                    }/cloud/${props.flow.id}/+/status`,
                    handleNodeStatusUpdate
                )
                props.mqtt.on(
                    `${
                        JSON.parse(localStorage['userObject']).factoryId
                    }/cloud/${props.flow.id}/+/error`,
                    handleNodeError
                )
                props.mqtt.on(
                    `${
                        JSON.parse(localStorage['userObject']).factoryId
                    }/cloud/${props.flow.id}/+/state`,
                    handleNodeStateUpdate
                )
                props.mqtt.on(
                    `${
                        JSON.parse(localStorage['userObject']).factoryId
                    }/cloud/${props.flow.id}/status`,
                    handleFlowStatusUpdate
                )
            } catch (error) {
                toast(
                    `Could not subscribe to flow data. Check internet connection.`,
                    {
                        position: 'top-right',
                        type: 'error',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                )
            }
        }

        const unmount = async () => {
            await props.mqtt.publishAction(
                `${JSON.parse(localStorage['userObject']).factoryId}/cloud/${
                    props.flow.id
                }/subscribe`,
                {data: false}
            )
            props.mqtt.router.removeListener(
                `${JSON.parse(localStorage['userObject']).factoryId}/cloud/${
                    props.flow.id
                }/+/status`,
                handleNodeStatusUpdate
            )
            props.mqtt.router.removeListener(
                `${JSON.parse(localStorage['userObject']).factoryId}/cloud/${
                    props.flow.id
                }/+/error`,
                handleNodeError
            )
            props.mqtt.router.removeListener(
                `${JSON.parse(localStorage['userObject']).factoryId}/cloud/${
                    props.flow.id
                }/+/state`,
                handleNodeStateUpdate
            )
            props.mqtt.router.removeListener(
                `${JSON.parse(localStorage['userObject']).factoryId}/cloud/${
                    props.flow.id
                }/status`,
                handleFlowStatusUpdate
            )
        }

        mount()

        return () => {
            unmount()
        }
    }, [])

    useEffect(() => {
        let _framesToDisplay = {}
        const relevantFrames = frames.filter((f) =>
            selected.value ? f.transformId === selected.value : true
        )
        for (let frame of relevantFrames) {
            _framesToDisplay[variableDict[frame.variableId].name] = frame.data
        }
        setFramesToDisplay({..._framesToDisplay})
    }, [frames, selected])

    useEffect(() => {
        if (connected && props.status.server === 'offline') {
            // connected => disconnected
            setConnected(false)
        }
        if (!connected && props.status.server === 'online') {
            // disconnected => connected
            setConnected(true)
            props.mqtt.publishAction(
                `${JSON.parse(localStorage['userObject']).factoryId}/cloud/${
                    props.flow.id
                }/subscribe`,
                {data: true}
            )
        }
    }, [props.status])

    return (
        <>
            <Row>
                <Col xs="4">
                    <Nav pills>
                        <NavItem>
                            <NavLink
                                href="#"
                                onClick={() => setView('Flow Status')}
                                active={view === 'Flow Status'}>
                                Flow Status
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                onClick={() => setView('Flow Data')}
                                active={view === 'Flow Data'}>
                                Flow Data
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                onClick={() => setView('Flow Logs')}
                                active={view === 'Flow Logs'}>
                                Flow Logs
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col xs="4" className="text-center">
                    Flow Status:{' '}
                    {connected ? (
                        <>
                            <Badge pill id="tt_flow">
                                {status.flow.toUpperCase()}
                            </Badge>
                            <UncontrolledTooltip target="tt_flow">
                                <p className="m-0">
                                    {getStatus(status.flow).flowDocumentation}
                                </p>
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <div className="text-danger">Disconnected</div>
                    )}
                </Col>
                <Col xs="1" className="text-right pr-0">
                    <span
                        className="align-middle iconsmind-Filter-2"
                        style={{fontSize: 40}}
                    />
                </Col>
                <Col xs="3" className="pl-0">
                    <Select
                        components={{Input: CustomSelectInput}}
                        className="react-select"
                        classNamePrefix="react-select"
                        name="filter"
                        value={selected}
                        onChange={setSelected}
                        options={[
                            {label: 'Show all logs/data', value: null},
                        ].concat(
                            props.flow.nodes.map((el) => {
                                return {
                                    label: el.data.opts.name,
                                    value: el.id,
                                }
                            })
                        )}
                    />
                </Col>
            </Row>

            {view === 'Flow Status' ? (
                <>
                    <ReactFlow
                        elements={cloneDeep(
                            props.flow.nodes.concat(props.flow.edges)
                        ).map((el) => {
                            el.data.status = status[el.id]
                            return el
                        })}
                        nodeTypes={{
                            input: DebugNode,
                            default: DebugNode,
                            output: DebugNode,
                        }}
                        nodesDraggable={false}
                        nodesConnectable={false}
                        elementsSelectable={false}
                        selectNodesOnDrag={false}>
                        <Controls />
                        <Background
                            variant="dots"
                            gap={12}
                            size={0}
                            style={{background: '#fff'}}
                        />
                    </ReactFlow>
                </>
            ) : null}

            {view === 'Flow Data' ? (
                <ReactJson
                    src={framesToDisplay}
                    name={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                />
            ) : null}

            {view === 'Flow Logs' ? (
                <ReactAnsi
                    log={logs
                        .filter((l) =>
                            selected.value ? l.id === selected.value : true
                        )
                        .map((l) => l.value)
                        .join('\n')}
                    style={{height}}
                    bodyStyle={{height: '90%'}}
                    logStyle={{
                        height: '100%',
                        fontFamily:
                            'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                        userSelect: 'all',
                    }}
                    autoScroll={true}
                    linkify={false}
                />
            ) : null}
        </>
    )
}
