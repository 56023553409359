import React, {Component, Fragment} from 'react'
import {Button, Badge} from 'reactstrap'
import moment from 'moment'
import autobind from 'auto-bind'
import PropTypes from 'prop-types'

import CRUD from 'SDK/ui/crud'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'

import SideModal from './sideModal'

const STATUS_COLORS = {
    opened: 'primary',
    closed: 'secondary',
    started: 'success',
    stopped: 'danger',
    paused: 'warning',
    reset: 'info',
    discovered: 'info',
}

const STATUS_STRINGS = {
    opened: 'Connection Opened',
    closed: 'Connection Closed',
    started: 'Data Streaming Started',
    stopped: 'Data Streaming Stopped',
    paused: 'Data Streaming Paused',
    reset: 'SensorBot Restarted',
    discovered: 'Discovered',
}

const makeBadge = (status) => {
    const color = STATUS_COLORS[status],
        statusString =
            status && STATUS_STRINGS[status] ? STATUS_STRINGS[status] : 'N/A'
    return <Badge color={STATUS_COLORS[status]}>{statusString}</Badge>
}

export default class ESeriesSensorBotManager extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    showBorder = false
    id = 'ESeriesSensorBotManager'
    requiredOutputs = []
    static propTypes = {
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            assets: [],
            nodes: [],
            gateways: [],
            editingNode: null,
        }
    }
    columns() {
        return [
            {
                Header: 'Gateway',
                accessor: 'gatewayAlias',
            },
            {
                Header: 'SensorBot ID',
                accessor: 'nodeId',
            },
            {
                Header: 'Firmware',
                accessor: 'version',
                minWidth: 50,
            },
            {
                Header: 'Last Heartbeat',
                accessor: 'lastHeartbeat',
            },
            {
                Header: 'Last Data Push',
                accessor: 'lastPush',
            },
            {
                Header: 'Pending Update',
                accessor: 'settings.pendingUpdate',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
        ]
    }

    toggleSideModal() {
        this.setState({
            sideModal: !this.state.sideModal,
        })
    }
    async fetchNodes() {
        let nodes = await API.get('nodes', 2)
        const sensorbots = nodes.filter(
            (node) => node.nodeType === 'PLC' && node.nodeComm === 'TCP'
        )
        const gateways = nodes.filter((node) => node.nodeType === 'GWY')
        this.setState({nodes: sensorbots, gateways: gateways})
    }
    async fetchAssets() {
        let assets = await API.get('assets/devices')
        this.setState({assets})
    }
    toggleModal(modalName, node) {
        let state = this.state
        state.editingNode = node
        state[modalName] = !state[modalName]
        this.setState({state})
    }

    actions() {
        return [
            {
                columnSize: 6,
                onClickFn: (node) => this.toggleModal('assetModal', node),
                actionName: 'Asset Commission',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (node) => this.toggleModal('liveModal', node),
                actionName: 'Live Stream',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (node) => this.toggleModal('refVoltageModal', node),
                actionName: 'Reference Voltage',
                buttonColor: 'primary',
            },
            {
                columnSize: 6,
                onClickFn: (node) =>
                    this.toggleModal('dataSamplingModal', node),
                actionName: 'Data Sampling',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (node) => this.toggleModal('actionsModal', node),
                actionName: 'Local Actions',
                buttonColor: 'primary',
            },
            {
                columnSize: 12,
                onClickFn: (node) => this.toggleModal('removeModal', node),
                actionName: 'Remove SensorBot',
                buttonColor: 'danger',
            },
        ]
    }

    dataTableFormatter(node) {
        const attachedDevice = this.state.assets.find(
            (device) => device.deviceId === node.deviceId
        )
        node.deviceName =
            attachedDevice !== undefined
                ? attachedDevice.name
                : 'Not commissioned'

        node.gatewayAlias = this.state.gateways.find(
            (gwy) => gwy.nodeId === node.gatewayId
        )

        if (node.gatewayAlias) node.gatewayAlias = node.gatewayAlias.alias
        else node.gatewayAlias = 'No Gateway'

        node.lastPush =
            moment(node.lastPush).unix() === 0
                ? 'Never'
                : moment(node.lastPush).fromNow()
        node.lastHeartbeat =
            moment(node.lastHeartbeat).unix() === 0
                ? 'Never'
                : moment(node.lastHeartbeat).fromNow()

        node.status = makeBadge(node.status)

        return node
    }

    editorModalAttributes(node) {
        return [
            ['Mesh ID', node.nodeId],
            ['Gateway', node.gatewayAlias],
            ['Asset', node.deviceName],
        ]
    }

    componentWillMount() {
        this.subscriber.add(
            this.fetchAssets,
            5000,
            'DigitalTwin_SensorBots_fetchAssets'
        )
        this.subscriber.add(
            this.fetchNodes,
            1000,
            'DigitalTwin_SensorBots_fetchNodes'
        )
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        return (
            <Fragment>
                <CRUD
                    uniqueElementId="nodeId"
                    emptyCrudMainText="No E-Series SensorBots Found"
                    emptyCrudSubText="E-Series SensorBots are automatically initialized in the Accumine platform."
                    crudTableColumns={this.columns()}
                    crudTableResolveDataMapFn={this.dataTableFormatter}
                    crudTableData={this.state.nodes}
                    rowClickFn={(node) => {
                        this.setState({editingNode: node})
                        this.toggleSideModal(node)
                    }}
                />

                {this.state.sideModal ? (
                    <SideModal
                        toggleModal={this.toggleSideModal}
                        modal={this.state.sideModal}
                        nodes={this.state.nodes}
                        gateways={this.state.gateways}
                        assets={this.state.assets}
                        editingNode={this.state.editingNode}
                        sync={async () => {
                            await this.fetchNodes()
                            this.setState({
                                editingNode: this.state.nodes.find(
                                    (node) =>
                                        node._id === this.state.editingNode._id
                                ),
                            })
                        }}
                    />
                ) : null}
            </Fragment>
        )
    }
}
