import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'

import PropTypes from 'prop-types'

import HourlyTargetActualChart from './HourlyTargetActualChart'
import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import * as SDK from './RealTimeWorkOrderStatusTile/sdk'
import {Typeahead, Menu, MenuItem} from 'react-bootstrap-typeahead'

import Moment from 'moment'
import {extendMoment} from 'moment-range'
const moment = extendMoment(Moment)

export default class PartSelect extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
    ]
    requiredOutputs = ['Part']
    showBorder = false
    id = 'PartSelect'
    static propTypes = {
        deviceId: PropTypes.string,
        onSelect: PropTypes.func,
        onSave: PropTypes.func,
        value: PropTypes.string,
        shift: PropTypes.object,
        timestamp: PropTypes.number,
        hourlyTarget: PropTypes.array,
    }

    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            timeStart: null,
            timeEnd: null,
            part: {},
            parts: [],
            form: ['partNumber', 'target'],
            valid: false,
        }
    }

    componentWillMount() {
        this.setState({
            timeStart: this.props.shift.range[0].format(
                moment.HTML5_FMT.DATETIME_LOCAL
            ),
            timeEnd: this.props.shift.range[1].format(
                moment.HTML5_FMT.DATETIME_LOCAL
            ),
        })

        this.getParts()
    }

    deleteHourlyTarget = async (
        hourlyTargetId = this.state.defaultTarget._id
    ) => {
        if (hourlyTargetId) {
            let data = await API.remove('states/' + hourlyTargetId, 2)
            if (data.success === true) {
                alert('Schedule Deleted Successfully')
            } else {
                alert('Schedule Not Deleted Successfully')
            }
        }
    }

    setHourlyTarget = async (
        part = this.state.part,
        timeStart = moment(this.state.timeStart),
        timeEnd = moment(this.state.timeEnd)
    ) => {
        const partName = part[0].metaData.find((m) => m.name == 'part').value
        const target = part[0].metaData.find((m) => m.name == 'target').value
        const {deviceId} = this.props

        const response = await API.post(
            'states',
            {
                nodeId: deviceId,
                deviceId: deviceId,
                name: 'Scheduled Target',
                timestamp: timeStart,
                timeStart: timeStart,
                timeEnd: timeEnd,
                value: target,
                metaData: [
                    {
                        name: 'part',
                        value: partName,
                    },
                    {
                        name: 'target',
                        value: target,
                    },
                ],
            },
            2
        )

        if (response.success === true) {
            alert('Saved Successfully')
        }
    }

    async getParts() {
        let parts = await API.post(
            'historical/raw',
            {
                query: {
                    name: 'Part',
                },
            },
            2
        )

        this.setState({parts})
    }

    render() {
        const {timeStart, timeEnd, parts} = this.state
        const {onSelect, shift, deviceId, hourlyTarget, onSave} = this.props
        const typeAheadId =
            shift && deviceId
                ? 'PartSearch-' + deviceId + '-' + shift._id
                : 'PartSearch'
        return (
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <label>Part:</label>
                            <Typeahead
                                style={{width: '100%'}}
                                id={typeAheadId}
                                selectHintOnEnter={true}
                                labelKey={(option) =>
                                    `${
                                        option.value.toUpperCase() +
                                        ' (Hourly Target: ' +
                                        option.metaData.find(
                                            (o) => o.name == 'target'
                                        ).value +
                                        ')'
                                    }`
                                }
                                options={parts}
                                placeholder={'Select Part'}
                                onChange={(part) => this.setState({part: part})}
                            />
                            <label style={{marginTop: 10}}>From:</label>
                            <Input
                                type="datetime-local"
                                value={this.state.timeStart}
                                style={{backgroundColor: 'transparent'}}
                                onChange={(e) => {
                                    let v = e.target.value,
                                        color = 'rgb(215,215,215)',
                                        valid = true
                                    if (v === '') {
                                        color = '#dc3545'
                                        valid = false
                                    }
                                    this.setState({
                                        timeStart: moment(
                                            e.target.value
                                        ).format(
                                            moment.HTML5_FMT.DATETIME_LOCAL
                                        ),
                                        valid: valid,
                                        fromInputBorderColor: color,
                                    })
                                }}
                            />
                            <label style={{marginTop: 10}}>To:</label>
                            <Input
                                type="datetime-local"
                                value={this.state.timeEnd}
                                style={{backgroundColor: 'transparent'}}
                                onChange={(e) => {
                                    let v = e.target.value,
                                        color = 'rgb(215,215,215)',
                                        valid = true
                                    if (v === '') {
                                        color = '#dc3545'
                                        valid = false
                                    }
                                    this.setState({
                                        timeEnd: v,
                                        valid: valid,
                                        fromInputBorderColor: color,
                                    })
                                }}
                            />
                            <div style={{marginBottom: 10}} />
                            <Button
                                color="primary"
                                size="lg"
                                onClick={() => {
                                    this.setHourlyTarget(this.state.part)
                                    onSave()
                                }}>
                                Save
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
