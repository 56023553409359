import React, {Component, Fragment} from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Select from 'react-select'

import {Colxx, Separator} from 'components/CustomBootstrap'
import CustomSelectInput from 'components/CustomSelectInput'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        this.remove = this.remove.bind(this)
    }
    async remove() {
        if (!(await API.remove('shifts/' + this.props.editingShift._id, 2))) {
            return alert(
                'There was an error deleting this Shift. Please try again.'
            )
        } else {
            this.props.toggleModal('removeModal')
        }
    }
    componentWillMount() {}
    componentWillUnmount() {}

    render() {
        let {modal, toggleModal, editingShift} = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    toggle={() => {
                        toggleModal('removeModal')
                    }}>
                    <ModalHeader
                        toggle={() => {
                            toggleModal('removeModal')
                        }}>
                        Remove Shift
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Colxx xs="12">
                                <p>
                                    Are you sure you want to remove this Shift?
                                </p>
                            </Colxx>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                toggleModal('removeModal')
                            }}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={this.remove}>
                            Remove
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
