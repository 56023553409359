import React, {Component, Fragment} from 'react'
import {Row, Col, Button} from 'reactstrap'
import {Bar, Chart} from 'react-chartjs-2'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'

import * as API from 'SDK/api'

const color = (name) => {
    if (name === 'In-Cycle') return 'rgb(46, 204, 113)'
    else if (
        name === 'Downtime' ||
        name === 'Downtime Under 5 Minutes' ||
        name === 'Uncategorized Downtime'
    )
        return 'rgb(231, 76, 60)'
    else return '#f0ad4e'
}

Chart.pluginService.register({
    beforeDraw: function (chart, easing) {
        if (
            chart.config.options.chartArea &&
            chart.config.options.chartArea.backgroundColor
        ) {
            var ctx = chart.chart.ctx
            var chartArea = chart.chartArea

            ctx.save()
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor
            ctx.fillRect(
                chartArea.left,
                chartArea.top,
                chartArea.right - chartArea.left,
                chartArea.bottom - chartArea.top
            )
            ctx.restore()
        }
    },
})

export default class InteractiveTimeline extends Component {
    deprecated = true
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'InteractiveTimeline'
    requiredOutputs = ['In-Cycle', 'Downtime']
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
        showReasons: PropTypes.bool,
        startTime: PropTypes.object,
        endTime: PropTypes.object,
        getUtilization: PropTypes.func,
    }
    static defaultProps = {
        showReasons: true,
        startTime: null,
        endTime: null,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                chartArea: {
                    backgroundColor: 'rgba(0,0,0,1)',
                },
                maintainAspectRatio: false,
                title: {
                    text: moment().format('MMM D, YYYY'),
                    display: true,
                    fontColor: '#fff',
                },
                tooltips: {
                    yAlign: 'top',
                    mode: 'label',
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return moment(tooltipItem[0].label).format('h:mm a')
                        },
                        label: function (tooltipItem, data) {
                            return data.datasets[0].states[tooltipItem.index]
                        },
                    },
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: false,
                            type: 'time',
                            time: {
                                unit: 'hour',
                            },
                            ticks: {
                                fontColor: '#fff',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                max: 1,
                                beginAtZero: 0,
                                display: false,
                            },
                        },
                    ],
                },
            },
        }
    }

    async fetchData() {
        this.setState({loading: true})
        let start = moment(this.state.start),
            end = moment(this.state.end).endOf('day')

        if (this.props.startTime && this.props.endTime) {
            let startOffset =
                    this.props.startTime.hour * 60 +
                    this.props.startTime.minute,
                endOffset =
                    this.props.endTime.hour * 60 + this.props.endTime.minute,
                shiftStart = moment(this.state.start).add(
                    startOffset,
                    'minutes'
                ),
                shiftEnd = moment(this.state.start).add(endOffset, 'minutes')

            if (startOffset > endOffset) {
                shiftEnd.add(1, 'days')
            }

            start = moment(shiftStart)
            end = moment(shiftEnd)
        }

        let {data} = await API.post('historical/raw', {
            deviceId: this.props.deviceId,
            name: {
                $in: ['In-Cycle', 'Downtime'],
            },
            $or: [
                {
                    timeStart: {
                        $gte: start.toISOString(),
                        $lt: end.toISOString(),
                    },
                },
                {
                    timeStart: {
                        $lt: start.toISOString(),
                    },
                    timeEnd: {
                        $gt: start.toISOString(),
                    },
                },
                {
                    timeStart: {
                        $lt: end.toISOString(),
                    },
                    timeEnd: null,
                },
            ],
        })

        for (let i in data) {
            let row = data[i]

            if (row.name === 'Downtime' && this.props.showReasons) {
                let downtimeReason = []
                if (
                    row.timeEnd &&
                    moment(row.timeEnd).diff(
                        moment(row.timeStart),
                        'minutes'
                    ) >= 5
                ) {
                    downtimeReason = await API.post(
                        'historical/raw',
                        {
                            query: {
                                deviceId: row.deviceId,
                                name: 'Downtime Reason',
                                timeStart: moment(row.timeStart).toDate(),
                            },
                        },
                        2
                    )
                }
                if (
                    row.timeEnd &&
                    moment(row.timeEnd).diff(
                        moment(row.timeStart),
                        'minutes'
                    ) >= 5
                ) {
                    if (downtimeReason.length) {
                        row.name = downtimeReason[0].value
                    } else {
                        row.name = 'Uncategorized Downtime'
                    }
                } else if (row.timeEnd) {
                    row.name = 'Downtime Under 5 Minutes'
                } else if (!downtimeReason.length) {
                    row.name = 'Uncategorized Downtime'
                } else {
                    row.name = downtimeReason[0].value
                }
            }
            if (moment(row.timeStart).diff(start) < 0) {
                row.timeStart = moment(start)
            }

            if (moment(row.timeEnd).diff(end) > 0) {
                row.timeEnd = moment(end)
            }

            if (row.timeEnd === null) {
                if (moment().diff(start, 'days') === 0) {
                    row.timeEnd = moment()
                } else row.timeEnd = moment(end)
            }

            data[i] = [
                row.name,
                color(row.name),
                moment(row.timeStart)
                    .startOf('minute')
                    .add(1, 'minute')
                    .diff(moment(start), 'minutes'),
                moment(row.timeEnd)
                    .endOf('minute')
                    .add(1, 'minute')
                    .diff(moment(start), 'minutes'),
            ]
        }

        let chartdata = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    states: [],
                },
            ],
        }

        for (let i = 0; i < moment(end).diff(start, 'minutes'); i++) {
            chartdata.labels.push(moment(start).add(i, 'minutes'))
            const inCycleRows = data.filter(
                (d) => d[0] === 'In-Cycle' && i >= d[2] - 2 && i < d[3]
            )

            let row = null
            if (inCycleRows.length) {
                row = inCycleRows[0]
            } else {
                row = data.find((d) => i >= d[2] && i < d[3])
            }

            if (!row) continue
            chartdata.datasets[0].data.push(1)
            chartdata.datasets[0].backgroundColor.push(row[1])
            chartdata.datasets[0].states.push(row[0])
        }

        const elapsed = moment().isSame(start, 'day')
            ? moment().diff(start, 'minutes')
            : moment(end).diff(start, 'minutes')

        const utilization =
            chartdata.datasets[0].states.filter((s) => s === 'In-Cycle')
                .length / elapsed
        if (this.props.getUtilization) {
            this.props.getUtilization(utilization)
        }

        this.setState({data: chartdata, loading: false})
    }

    backward() {
        let options = this.state.options
        options.title.text = moment(this.state.start)
            .add(-1, 'days')
            .format('MMM D, YYYY')

        this.setState({
            start: moment(this.state.start.add(-1, 'days')),
            end: moment(this.state.end.add(-1, 'days')),
            options,
        })
        console.log(this.state.start.format())
        this.fetchData()
    }

    forward() {
        if (this.state.start.isSame(moment(), 'day')) {
            return
        }

        let options = this.state.options
        options.title.text = moment(this.state.start)
            .add(1, 'days')
            .format('MMM D, YYYY')

        this.setState({
            start: moment(this.state.start.add(1, 'days')),
            end: moment(this.state.end.add(1, 'days')),
            options,
        })
        this.fetchData()
    }

    componentWillMount() {
        this.fetchData()

        let self = this
        this.interval = setInterval(function () {
            if (self.state.start.isSame(moment(), 'day')) {
                self.fetchData()
            }
        }, 60000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const back = moment(this.state.start)
                .add(-1, 'days')
                .format('MMM D, YYYY'),
            forward = moment(this.state.start)
                .add(1, 'days')
                .format('MMM D, YYYY'),
            disableForward = this.state.start.isSame(moment(), 'day')

        return (
            <Fragment>
                <Row>
                    <Col>
                        <Bar
                            data={this.state.data}
                            options={this.state.options}
                            height={100}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-left">
                        <Button
                            size="xs"
                            color="primary"
                            onClick={this.backward}>
                            <i className="simple-icon-arrow-left" />
                        </Button>
                    </Col>
                    <Col className="text-right">
                        <Button
                            size="xs"
                            color="primary"
                            disabled={disableForward}
                            onClick={this.forward}>
                            <i className="simple-icon-arrow-right" />
                        </Button>
                    </Col>
                </Row>
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
