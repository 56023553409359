import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autobind from 'auto-bind'
import {CirclePicker} from 'react-color'

import * as API from 'SDK/api'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            inputBorderColor: 'rgb(215,215,215)',
            name: '',
            description: '',
            color: '#fff',
            valid: false,
            message: false,
        }
    }

    async save() {
        const obj = {
            name: this.state.name,
            description: this.state.description,
            color: this.state.color,
        }

        const response = await API.post('manualstates', obj, 2)
        if (response) {
            this.props.toggleModal()
        } else {
            return alert(
                'There was an error adding your manual state. Please try again.'
            )
        }
    }

    render() {
        const {modal, toggleModal, manualstates} = this.props

        return (
            <Fragment>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        Add Manual State
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Name:</label>
                                <Input
                                    style={{
                                        borderColor:
                                            this.state.inputBorderColor,
                                    }}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(name) => {
                                        let v = name.target.value,
                                            color = 'rgb(215,215,215)',
                                            valid = true,
                                            message = ''
                                        if (v === '') {
                                            color = '#dc3545'
                                            valid = false
                                        } else if (
                                            this.props.manualstates.find(
                                                (code) => code.name === v
                                            )
                                        ) {
                                            color = '#dc3545'
                                            valid = false
                                            message =
                                                'This manual state already exists.'
                                        }
                                        this.setState({
                                            name: v,
                                            valid: valid,
                                            inputBorderColor: color,
                                            message: message,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Description:</label>
                                <Input
                                    type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={(description) => {
                                        this.setState({
                                            description:
                                                description.target.value,
                                        })
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.valid
                                        ) {
                                            this.save()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-4">
                                <label>Color:</label>
                                <CirclePicker
                                    color={this.state.color}
                                    onChange={(color, event) => {
                                        this.setState({color: color.hex})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{textAlign: 'center'}}>
                                <p>{this.state.message}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!this.state.valid}
                            onClick={this.save}>
                            Add Manual State
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
