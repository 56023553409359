import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'

import HourlyTargetActualChart from './HourlyTargetActualChart'
import Timeline from './timeline/Timeline'

import Subscriber from 'SDK/subscriber'
import * as API from 'SDK/api'
import {fetchShiftTimerange} from 'SDK/api/common'
import {secondsToHHMMSS, tileColor} from 'SDK/helpers'
import SchemaForm from '../Schemas/common/Form'

export default class extends Component {
    static propTypes = {
        deviceId: PropTypes.string,
        realtimeactiveshift: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            name: '',
            live: {
                name: '...',
                timestamp: null,
                color: '',
                elapsed: '0:00',
            },
            actual: 0,
            target: 0,
            partNumber: 'No Scheduled Production',
            left: 0,
            progress: 0,
            hoursRemaining: '',
            activeBreak: false,
            modalPart: false,
            modalOperator: false,
            validator: null,
            editingRecord: null,
            dataModelsList: [],
            schemas: {
                partsId: '',
                operatorsId: '',
            },
            validators: {
                partModelValidator: null,
                operatorModelValidator: null,
            },
            dataModels: {
                partsModel: null,
                operatorsModel: null,
            },
        }
    }

    toggleModal(type) {
        this.setState({[type]: !this.state[type]})
    }

    async fetchDeviceName() {
        let assets = await API.get('devices')
        if (!assets) {
            return alert('There was an error fetching data')
        } else {
            this.setState({
                name: assets.find((a) => a.deviceId === this.props.deviceId)
                    .name,
            })
        }
    }

    async fetchLiveData() {
        let live = await API.post('live', {
            deviceId: this.props.deviceId,
        })
        if (!live) {
            return alert('There was an error fetching data')
        } else if (live[this.props.deviceId].activeStates.length) {
            if (live[this.props.deviceId].activeShiftBreaks.length) {
                this.setState({
                    activeBreak:
                        live[this.props.deviceId].activeShiftBreaks[0].name,
                })
            } else {
                this.setState({activeBreak: false})
            }
            let shifts = live[this.props.deviceId].activeShifts
            live[this.props.deviceId].activeStates = live[
                this.props.deviceId
            ].activeStates.filter(
                (state) =>
                    state.name === 'Downtime' || state.name === 'In-Cycle'
            )
            live = live[this.props.deviceId].activeStates[0]
            if (live.name === 'Downtime') {
                const downtimeReason = await API.post(
                    'historical/raw',
                    {
                        query: {
                            deviceId: this.props.deviceId,
                            name: 'Downtime Reason',
                            timeEnd: null,
                        },
                    },
                    2
                )

                if (downtimeReason.length) {
                    live.name = downtimeReason[0].value
                }
            }
            live.color = tileColor(live.name)
            live.elapsed = secondsToHHMMSS(
                moment().diff(moment(live.timestamp), 'seconds')
            )
            let timerange = [
                moment().startOf('hour').add(-23, 'hours').toISOString(),
                moment().toISOString(),
            ]

            if (this.props.realtimeactiveshift) {
                if (shifts.length) {
                    timerange = await fetchShiftTimerange(
                        this.props.deviceId,
                        shifts[0]
                    )
                }
            }
            this.setState({live, timerange})
        }
    }

    updateElapsed() {
        if (this.state.live.timestamp === null) return
        let live = {...this.state.live}
        live.elapsed = secondsToHHMMSS(
            moment().diff(moment(live.timestamp), 'seconds')
        )
        this.setState({live})
    }

    async fetchActualThisHour() {
        let {data} = await API.post('historical/raw', {
            timeStart: {
                $gte: moment().startOf('hour').toISOString(),
                $lt: moment().toISOString(),
            },
            timeEnd: {$ne: null},
            name: 'Part Count',
            deviceId: this.props.deviceId,
        })

        this.setState({
            actual: data.length,
        })
    }

    async fetchTargetThisHour() {
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {$in: ['Hourly Target', 'Scheduled Target']},
                    timeEnd: null,
                },
            },
            2
        )
        if (data && data.length) {
            this.setState({
                target: data[0].metaData[0].value,
            })
        }
    }

    async fetchJobData() {
        const containerQtyRecord = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Container Quantity',
                    timeEnd: null,
                },
            },
            2
        )

        if (containerQtyRecord && containerQtyRecord.length) {
            const containerStartTime = moment(containerQtyRecord[0].timestamp),
                containerQuantity = containerQtyRecord[0].value

            const actualQtyRecords = await API.post(
                'historical/raw',
                {
                    query: {
                        deviceId: this.props.deviceId,
                        name: 'Part Count',
                        timestamp: {$gte: containerStartTime.toISOString()},
                    },
                },
                2
            )
            let actualQtyCount = actualQtyRecords.reduce(
                    (a, b) => a + b.value,
                    0
                ),
                averageCycleTime = (1000 * 60 * 60) / this.state.target
            if (actualQtyCount > 0) {
                averageCycleTime =
                    moment().diff(containerStartTime) / actualQtyCount
            }

            if (this.props.deviceId === '782') console.log(averageCycleTime)

            let timeLeftInMs = moment.duration(
                (containerQuantity - actualQtyCount) * averageCycleTime
            )

            this.setState({
                predictedTimeLeft: timeLeftInMs.humanize(true),
                progress: (actualQtyCount / containerQuantity) * 100,
                actualContainer: actualQtyCount,
                expectedContainer: containerQuantity,
            })
        } else {
            this.setState({
                predictedTimeLeft: null,
                progress: null,
                actualContainer: null,
                expectedContainer: null,
            })
        }
    }

    async fetchProgress() {
        const target = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: 'Target Parts',
                    timeEnd: null,
                },
            },
            2
        )
        if (target && target.length) {
            let {data} = await API.post('historical/aggregate2', {
                timeStart: moment(target[0].timeStart).toISOString(),
                timeEnd: moment().toISOString(),
                state: ['Part Count'],
                deviceId: [this.props.deviceId],
                groupByTimeUnit: 'total',
                logic: 'count',
            })

            let actual = data.devices[this.props.deviceId]['Part Count'][0]
            if (actual.constructor === Object) actual = actual.count

            let left = target[0].metaData[0].value - actual,
                progress = parseInt((actual / left) * 100),
                timeElapsedThisHour = moment().diff(moment().startOf('hour')),
                cycleTime = timeElapsedThisHour / this.state.actual,
                hoursRemaining = (left * cycleTime) / (60 * 60 * 1000)

            if (isNaN(hoursRemaining)) {
                hoursRemaining = ''
            } else {
                hoursRemaining =
                    '(~' + Math.round((hoursRemaining / 100) * 100) + ' hours)'
            }

            this.setState({
                left,
                progress,
                hoursRemaining,
            })
        }
    }

    toggleEditTarget() {
        this.setState({
            editingTarget: !this.state.editingTarget,
        })
    }

    async saveTarget() {
        await API.post(
            'tablets/data',
            [
                {
                    nodeId: this.props.deviceId,
                    name: 'Static Hourly Target',
                    value: parseInt(this.state.target),
                    timestamp: moment().valueOf(),
                },
            ],
            2
        )
        this.toggleEditTarget()
    }

    setColor() {
        let {actual, target} = this.state
        target = parseInt(target)

        if (target === 0) {
            return 'grey'
        }

        target = Math.floor(
            moment().diff(moment().startOf('hour')) /
                ((60 * 60 * 1000) / target)
        )

        if (target < 1) {
            return '#28a745' //green
        } else if (actual / target < 0.8) {
            return '#dc3545' //red
        } else if (actual / target < 1) {
            return '#ffc107' //yellow
        } else if (actual / target < 1.2) {
            return '#28a745' //green
        } else {
            return '#007bff'
        }
    }

    async componentWillMount() {
        this.fetchDeviceName()
        this.loadDataModels()
        this.subscriber.add(this.fetchLiveData, 5000, 'fetchLiveData')
        this.subscriber.add(this.updateElapsed, 750, 'updateElapsed')
        this.subscriber.add(this.fetchJobData, 1000 * 10, 'fetchJobData')
        this.subscriber.add(
            this.fetchActualThisHour,
            30 * 1000,
            'fetchActualThisHour'
        )
        this.subscriber.add(
            this.fetchTargetThisHour,
            60 * 1000,
            'fetchTargetThisHour'
        )
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    loadDataPoints() {
        this.getMostRecentPart()
        this.getMostRecentOperator()
    }

    async loadDataModels() {
        let dataModelsList = await API.get('data-models', 2)
        const partModel = dataModelsList.find((d) => d.name === 'HMI')
        const operatorModel = dataModelsList.find((d) => d.name === 'Operators')

        if (!partModel || !operatorModel) {
            alert('This module does not include the necessary schemas.')
        } else {
            const partModelValidator = await API.get(
                'data-models/' + partModel._id + '/validator',
                2
            )
            const operatorModelValidator = await API.get(
                'data-models/' + operatorModel._id + '/validator',
                2
            )
            const defaultParts = ''
            const defaultOperators = ''

            this.setState(
                {
                    schemas: {
                        partsId: partModel._id,
                        operatorsId: operatorModel._id,
                    },
                    validators: {
                        partModelValidator,
                        operatorModelValidator,
                    },
                    dataModels: {
                        partModel: partModel,
                        operatorModel: operatorModel,
                    },
                    dataModelsList,
                },
                () => {
                    this.loadDataPoints()
                }
            )
        }
    }

    async saveOperator() {
        try {
            await API.post(
                `data-models/${this.state.schemas.operatorsId}/add-record`,
                {
                    name: this.state.operatorName,
                    deviceId: this.props.deviceId,
                    timeStart: moment().toISOString(),
                },
                2
            )
            this.toggleModal('operatorModal')
            this.loadDataPoints()
        } catch (err) {
            alert('Save failed')
        }
    }

    async savePart() {
        try {
            await API.post(
                `data-models/${this.state.schemas.partsId}/add-record`,
                {
                    name: this.state.partName,
                    deviceId: this.props.deviceId,
                    timeStart: moment().toISOString(),
                },
                2
            )
            this.toggleModal('partModal')
            this.loadDataPoints()
        } catch (err) {
            alert('Save failed')
        }
    }

    async getMostRecentOperator() {
        try {
            let operator = await API.post(
                `data-models/${this.state.schemas.operatorsId}/paginate`,
                {
                    sort: {
                        dataModelId: `${this.state.schemas.operatorsId}`,
                        fieldName: 'timeStart',
                        direction: 'descending',
                        deviceId: this.props.deviceId,
                    },
                    limit: 1,
                    next: null,
                    previous: null,
                    filter: [
                        {
                            type: 'PrimaryID',
                            logic: 'contains',
                            value: [this.props.deviceId],
                            path: 'deviceId.deviceId',
                        },
                    ],
                    timezone: localStorage['timezone'],
                },
                2
            )
            this.setState({
                liveOperator:
                    operator.count > 0
                        ? `${operator.result.results[0].employee.first_name} ${operator.result.results[0].employee.last_name}`
                        : 'Operator',
            })
        } catch (err) {
            console.log(err)
        }
    }

    async getMostRecentPart() {
        try {
            let part = await API.post(
                `data-models/${this.state.schemas.partsId}/paginate`,
                {
                    sort: {
                        dataModelId: `${this.state.schemas.partsId}`,
                        fieldName: 'timeStart',
                        direction: 'descending',
                    },
                    limit: 1,
                    next: null,
                    previous: null,
                    filter: [
                        {
                            type: 'PrimaryID',
                            logic: 'contains',
                            value: [this.props.deviceId],
                            path: 'deviceId.deviceId',
                        },
                    ],
                    timezone: localStorage['timezone'],
                },
                2
            )
            this.setState({
                livePart:
                    part.count > 0
                        ? part.result.results[0].part.number
                        : 'Part',
            })
        } catch (err) {
            console.log(err)
        }
    }

    updatePartName(inputEvent) {
        this.setState({partName: inputEvent.target.value})
    }

    updateOperatorName(inputEvent) {
        this.setState({operatorName: inputEvent.target.value})
    }

    onSubmit() {}

    StandardTemplate({TitleField, properties, title, description}) {
        return (
            <Row>
                <Col xs="12">
                    <TitleField title={title} />
                </Col>
                {properties
                    .filter((f) => f.name !== 'dataModelId')
                    .map((p) => (
                        <Col xs={12} key={p.name}>
                            {p.content}
                        </Col>
                    ))}
            </Row>
        )
    }

    render() {
        const activeState = this.state.live
        const ready = this.state.timerange && this.state.timerange.length
        const {validators, editingRecord, schemas, dataModelsList, dataModels} =
            this.state

        return (
            <Fragment>
                <Col xs="12" sm="12" className="mb-4">
                    <Card
                        style={{
                            color: '#fff',
                            backgroundColor: this.setColor(),
                        }}>
                        <CardHeader
                            style={{
                                cursor: 'pointer',
                                margin: 0,
                                padding: '5px',
                                backgroundColor: 'rgba(47, 50, 59,0.2)',
                            }}>
                            <Row>
                                <Col>
                                    <strong>{this.state.name}</strong>
                                </Col>
                                <Col style={{textAlign: 'right'}}>
                                    {this.state.live.elapsed}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{padding: 0}}>
                            <Row style={{margin: 0}}>
                                <Col xs="6">
                                    <Row>
                                        <Col className="text-center">
                                            <h1>
                                                {this.state.live.name.length >
                                                15
                                                    ? this.state.live.name.substring(
                                                          0,
                                                          12
                                                      ) + '...'
                                                    : this.state.live.name}
                                            </h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center">
                                            <p>Hourly Actual</p>
                                            <h1>{this.state.actual}</h1>
                                        </Col>
                                        <Col className="text-center">
                                            <p>Hourly Target</p>
                                            {this.state.editingTarget ? (
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        type="number"
                                                        value={
                                                            this.state.target
                                                        }
                                                        style={{
                                                            borderColor:
                                                                this.state
                                                                    .color,
                                                            borderTop:
                                                                'transparent',
                                                            borderRight:
                                                                'transparent',
                                                            borderLeft:
                                                                'transparent',
                                                            backgroundColor:
                                                                'transparent',
                                                        }}
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.value <
                                                                0
                                                            )
                                                                return
                                                            this.setState({
                                                                target: e.target
                                                                    .value,
                                                            })
                                                        }}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            outline
                                                            color="primary"
                                                            size="sm"
                                                            onClick={
                                                                this.saveTarget
                                                            }>
                                                            Save
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            ) : (
                                                <Button
                                                    size="sm"
                                                    onClick={
                                                        this.toggleEditTarget
                                                    }>
                                                    {this.state.target}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 15,
                                        }}>
                                        <Col className="text-center">
                                            <p>Operator</p>
                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    this.toggleModal(
                                                        'operatorModal'
                                                    )
                                                }}>
                                                {this.state.liveOperator ||
                                                    'N/A'}
                                            </Button>
                                        </Col>
                                        <Col className="text-center">
                                            <p>Part</p>
                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    this.toggleModal(
                                                        'partModal'
                                                    )
                                                }}>
                                                {this.state.livePart || 'N/A'}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {this.state.expectedContainer !== null ? (
                                        <>
                                            <Row>
                                                <Col>
                                                    {this.state.actualContainer}{' '}
                                                    /{' '}
                                                    {
                                                        this.state
                                                            .expectedContainer
                                                    }
                                                </Col>
                                                {this.state.progress < 1 ? (
                                                    <Col
                                                        style={{
                                                            textAlign: 'right',
                                                        }}>
                                                        {
                                                            this.state
                                                                .predictedTimeLeft
                                                        }
                                                    </Col>
                                                ) : (
                                                    <Col
                                                        style={{
                                                            textAlign: 'right',
                                                        }}>
                                                        Container finished
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Progress
                                                        style={{height: '10px'}}
                                                        value={
                                                            this.state.progress
                                                        }></Progress>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : null}
                                </Col>
                                <Col
                                    xs="6"
                                    style={{
                                        backgroundColor:
                                            'rgba(68, 70, 79, 0.5)',
                                    }}>
                                    {this.state.timerange && (
                                        <HourlyTargetActualChart
                                            timerange={this.state.timerange}
                                            deviceId={this.props.deviceId}
                                            target={this.state.target}
                                            color={this.state.live.color}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                        {ready && (
                            <CardFooter style={{padding: 0}}>
                                <Timeline
                                    deviceId={this.props.deviceId}
                                    showReasons={false}
                                    timeStart={moment(
                                        this.state.timerange[0]
                                    ).startOf('minute')}
                                    timeEnd={null}
                                    shiftName={''}
                                    updateUtilization={() => {}}
                                    aggregation={60}
                                    color={this.color}
                                />
                            </CardFooter>
                        )}
                    </Card>
                </Col>
                <Modal
                    isOpen={this.state.partModal}
                    toggle={() => {
                        this.toggleModal('partModal')
                    }}>
                    <ModalHeader>Part Information</ModalHeader>
                    <ModalBody>
                        <SchemaForm
                            schema={validators.partModelValidator}
                            editingRecord={editingRecord}
                            dataModels={dataModelsList}
                            dataModel={dataModels.partModel}
                            manualDataLinkFilters={null}
                            presetValues={null}
                            onSubmit={this.onSubmit}
                            hideArchive={true}
                            timestampsToToday={true}
                            submitting={() => {
                                this.setState({loading: true}, () => {
                                    this.toggleModal('partModal')
                                    this.loadDataPoints()
                                })
                            }}
                            ObjectFieldTemplate={this.StandardTemplate}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.toggleModal('partModal')
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.operatorModal}
                    toggle={() => {
                        this.toggleModal('operatorModal')
                    }}>
                    <ModalHeader>Operator Information</ModalHeader>
                    <ModalBody>
                        <SchemaForm
                            schema={validators.operatorModelValidator}
                            editingRecord={editingRecord}
                            dataModels={dataModelsList}
                            dataModel={dataModels.operatorModel}
                            manualDataLinkFilters={null}
                            presetValues={null}
                            onSubmit={this.onSubmit}
                            hideArchive={true}
                            timestampsToToday={true}
                            submitting={() => {
                                this.setState({loading: true}, () => {
                                    this.toggleModal('operatorModal')
                                    this.loadDataPoints()
                                })
                            }}
                            ObjectFieldTemplate={this.StandardTemplate}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.toggleModal('operatorModal')
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
