import React, {useState} from 'react'
import {Form, FormGroup, Row, Col, Button} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import TextInput from '../../../../common/text-input'
import SelectInput from '../../../../common/select-input'

const TIME_UNITS = [
    {
        label: 'Seconds',
        value: 1000,
    },
    {
        label: 'Minutes',
        value: 1000 * 60,
    },
    {
        label: 'Hours',
        value: 1000 * 60 * 60,
    },
    {
        label: 'Days',
        value: 1000 * 60 * 60 * 24,
    },
    {
        label: 'Weeks',
        value: 1000 * 60 * 60 * 24 * 7,
    },
]

export default (props) => {
    const data = props.element.data
    const [parameters, setParameters] = useState({
        name: data.opts.name || 'Pause',
        description: data.opts.description || '',
        variableId: data.opts.variableId || null,
        durationInMS: data.opts.durationInMs
            ? parseInt(data.opts.durationInMS / data.opts.timeUnit)
            : 0,
        timeUnit: data.opts.timeUnit || 0,
        submitted: data.opts.submitted || true,
    })

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Text',
            multi: false,
        }

        parameters.durationInMS = parameters.durationInMS * parameters.timeUnit
        parameters.variableId = variable.id

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col xs="6">
                                    <SelectInput
                                        onChange={(timeUnit) => {
                                            parameters.timeUnit = timeUnit
                                            setParameters({...parameters})
                                        }}
                                        fieldName="Time Unit"
                                        default={
                                            parameters.timeUnit
                                                ? TIME_UNITS.find(
                                                      (t) =>
                                                          t.value ===
                                                          parameters.timeUnit
                                                  )
                                                : null
                                        }
                                        options={TIME_UNITS}
                                    />
                                </Col>
                                <Col xs="6">
                                    <TextInput
                                        onValueChange={(durationInMS) => {
                                            parameters.durationInMS =
                                                durationInMS
                                            setParameters({...parameters})
                                        }}
                                        onValidChange={() => {}}
                                        fieldName="Duration"
                                        existing={[]}
                                        initial={parameters.durationInMS}
                                        disabled={false}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
