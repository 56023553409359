import React, {Component, Fragment} from 'react'
import {Row, Col, Form, Label, Card, CardBody, Button, Table} from 'reactstrap'
import {cloneDeep} from 'lodash'
import moment from 'moment'
import autobind from 'auto-bind'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import {DATA_MODEL_ID_MAP} from '../../Block/DATA_MODEL_ID_MAP'
import count from '../../Scripts/CalculateOperationsCounts'

import Navigation from '../../Navigation'
import * as API from 'SDK/api'
import {paginate} from '../../Paginate'

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    var a = s.split('.')
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i]
        if (k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o
}

const templates = {
    Press: (totalBlocksIn, reworkGroups, scrapGroups, totalBlocksOut) => {
        const firstPassScrap = scrapGroups.reduce((a, b) => a + b.qty, 0),
            firstPassScrapPercent = (firstPassScrap / totalBlocksIn) * 100,
            firstPassYield = 100 - firstPassScrapPercent
        return (
            <Row>
                <Col xs="8">
                    <Card>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th colSpan="3" className="text-center">
                                            SCRAP
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scrapGroups.map((row, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <th>{row.name}</th>
                                                <td>{row.qty}</td>
                                                <td>
                                                    {totalBlocksIn
                                                        ? Math.round(
                                                              (row.qty /
                                                                  totalBlocksIn) *
                                                                  100 *
                                                                  100
                                                          ) / 100
                                                        : 0}
                                                    %
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Total Scrap</th>
                                        <th>
                                            {scrapGroups.reduce(
                                                (a, b) => a + b.qty,
                                                0
                                            )}
                                        </th>
                                        <th>
                                            {totalBlocksIn
                                                ? (
                                                      (scrapGroups.reduce(
                                                          (a, b) => a + b.qty,
                                                          0
                                                      ) /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th className="text-center">
                                            First Pass Scrap
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {firstPassScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {(
                                                firstPassScrapPercent || 0
                                            ).toFixed(2)}
                                            %
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            First Pass Yield
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {(firstPassYield || 0).toFixed(2)}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Good Blocks to Next Operation
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {totalBlocksOut}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    },
    'Collar Spray': (
        totalBlocksIn,
        reworkGroups,
        scrapGroups,
        totalBlocksOut
    ) => {
        const firstPassScrap = scrapGroups.reduce((a, b) => a + b.qty, 0),
            firstPassScrapPercent = (firstPassScrap / totalBlocksIn) * 100,
            firstPassYield = 100 - firstPassScrapPercent

        return (
            <Row>
                <Col xs="8">
                    <Card>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th colSpan="3" className="text-center">
                                            SCRAP
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scrapGroups.map((row, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <th>{row.name}</th>
                                                <td>{row.qty}</td>
                                                <td>
                                                    {totalBlocksIn
                                                        ? Math.round(
                                                              (row.qty /
                                                                  totalBlocksIn) *
                                                                  100 *
                                                                  100
                                                          ) / 100
                                                        : 0}
                                                    %
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Total Scrap</th>
                                        <th>
                                            {scrapGroups.reduce(
                                                (a, b) => a + b.qty,
                                                0
                                            )}
                                        </th>
                                        <th>
                                            {totalBlocksIn
                                                ? (
                                                      (scrapGroups.reduce(
                                                          (a, b) => a + b.qty,
                                                          0
                                                      ) /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th className="text-center">
                                            First Pass Scrap
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {firstPassScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {(
                                                firstPassScrapPercent || 0
                                            ).toFixed(2)}
                                            %
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            First Pass Yield
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {(firstPassYield || 0).toFixed(2)}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Total Rewashed
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {
                                                reworkGroups.find(
                                                    (a) =>
                                                        a.name ===
                                                        'Total Rewashed'
                                                ).qty
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Good Blocks to Next Operation
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {totalBlocksOut}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    },
    'Visual Inspection': (
        totalBlocksIn,
        reworkGroups,
        scrapGroups,
        totalBlocksOut
    ) => {
        const firstPassScrap = scrapGroups.find(
                (a) => a.name === 'First_Pass'
            ).qty,
            reworkScrap = scrapGroups.find(
                (a) => a.name === 'Rework_Scrap'
            ).qty,
            recollarScrap = scrapGroups.find((a) => a.name === 'Recollar').qty,
            firstPassScrapPercent = (firstPassScrap / totalBlocksIn) * 100,
            goodFirstPass =
                totalBlocksIn -
                reworkGroups.reduce((a, b) => a + b.qty, 0) -
                firstPassScrap -
                scrapGroups.find((a) => a.name === 'Recollar').qty,
            goodFirstPassYield = (goodFirstPass / totalBlocksIn) * 100
        return (
            <Row>
                <Col xs="8">
                    <Card>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th colSpan="3" className="text-center">
                                            REWORK
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reworkGroups.map((row, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <th>{row.name}</th>
                                                <td>{row.qty}</td>
                                                <td>
                                                    {totalBlocksIn
                                                        ? Math.round(
                                                              (row.qty /
                                                                  totalBlocksIn) *
                                                                  100 *
                                                                  100
                                                          ) / 100
                                                        : 0}
                                                    %
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Total Rework</th>
                                        <th>
                                            {reworkGroups.reduce(
                                                (a, b) => a + b.qty,
                                                0
                                            )}
                                        </th>
                                        <th>
                                            {totalBlocksIn
                                                ? (
                                                      (reworkGroups.reduce(
                                                          (a, b) => a + b.qty,
                                                          0
                                                      ) /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th className="text-center">
                                            First Pass Scrap
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {firstPassScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {(
                                                firstPassScrapPercent || 0
                                            ).toFixed(2)}
                                            %
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Rework Scrap
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {reworkScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Recollar Scrap
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {recollarScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Good First Pass
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {goodFirstPass}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            First Pass Yield
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {(goodFirstPassYield || 0).toFixed(
                                                2
                                            )}
                                            %
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            Good Blocks to Next Operation
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {totalBlocksOut}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    },
    'Test & Inspect': (
        totalBlocksIn,
        reworkGroups,
        scrapGroups,
        totalBlocksOut
    ) => {
        const electricalFailures = scrapGroups.find(
                (a) => a.name === 'Electrical Failure'
            ).qty,
            reworkScrap = scrapGroups.find((a) => a.name === 'Rework').qty,
            otherFirstPassScrap = scrapGroups.find(
                (a) => a.name === 'Other_First_Pass_Scrap'
            ).qty,
            qaSamples = scrapGroups.find((a) => a.name === 'QA Sample').qty,
            missingBlocks = scrapGroups.find(
                (a) => a.name === 'Misallocated_Block'
            ).qty,
            goodFirstPass =
                totalBlocksIn -
                reworkGroups.reduce((a, b) => a + b.qty, 0) -
                electricalFailures -
                reworkScrap -
                otherFirstPassScrap,
            firstPassYield = (goodFirstPass / totalBlocksIn) * 100,
            totalGoodBlocks =
                totalBlocksIn -
                electricalFailures -
                reworkScrap -
                qaSamples -
                missingBlocks -
                otherFirstPassScrap
        return (
            <Row>
                <Col xs="8">
                    <Card>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th colSpan="3" className="text-center">
                                            REWORK
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reworkGroups.map((row, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <th>{row.name}</th>
                                                <td>{row.qty}</td>
                                                <td>
                                                    {totalBlocksIn
                                                        ? Math.round(
                                                              (row.qty /
                                                                  totalBlocksIn) *
                                                                  100 *
                                                                  100
                                                          ) / 100
                                                        : 0}
                                                    %
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Total Rework</th>
                                        <th>
                                            {reworkGroups.reduce(
                                                (a, b) => a + b.qty,
                                                0
                                            )}
                                        </th>
                                        <th>
                                            {totalBlocksIn
                                                ? (
                                                      (reworkGroups.reduce(
                                                          (a, b) => a + b.qty,
                                                          0
                                                      ) /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            First Pass Scrap
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            Electrical Failures
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {electricalFailures}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {totalBlocksIn
                                                ? (
                                                      (electricalFailures /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            Rework Scrap
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {reworkScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {totalBlocksIn
                                                ? (
                                                      (reworkScrap /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            Other First Pass Scrap
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {otherFirstPassScrap}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {totalBlocksIn
                                                ? (
                                                      (otherFirstPassScrap /
                                                          totalBlocksIn) *
                                                      100
                                                  ).toFixed(2)
                                                : 0}
                                            %
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            QA Samples
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {qaSamples}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            Misallocated Blocks
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {missingBlocks}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            Good First Pass
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {goodFirstPass}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            First Pass Yield
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {(firstPassYield || 0).toFixed(2)}%
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            Total Good Blocks
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {totalGoodBlocks}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <th className="m-0 p-0 text-center">
                                            Count by Operator
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="m-0 p-0 text-center">
                                            {totalBlocksOut}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    },
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.path = [...this.props.path]
        this.path.push({
            name: 'Scrap & Rework Report',
            fn: () => {
                this.setState({selectedSection: null})
            },
        })

        this.state = {
            ready: false,
            selectedOperation: null,
            selectedPartNumber: {label: 'All Part Numbers', value: false},
            loading: false,
            scrapGroups: [],
            reworkGroups: [],
            dataModels: [],
            timefilter: {
                logic: 'between',
                value: [
                    moment().add(-1, 'second').startOf('day'),
                    moment().endOf('day'),
                ],
            },
            partNumberOptions: [],
            diameterOptions: [],
            selectedDiameter: {label: 'All Diameters', value: 'all'},
            totalBlocksIn: 0,
            totalBlocksOut: 0,
            blockedBatches: [],
        }
    }

    handleSelectedOperationChange(selectedOperation) {
        this.setState({selectedOperation, ready: false}, this.fetchPartNumbers)
    }

    async fetchPartNumbers() {
        this.setState({loading: true})
        let partNumberOptions = await paginate(
                '5fc8f5a3ae96bc6be2c1a131',
                [],
                {}
            ),
            diameterOptions = [
                ...new Set(partNumberOptions.map((a) => a['Diameter'])),
            ]

        partNumberOptions = partNumberOptions.map((o) => {
            return {
                label: o['Part Number'],
                value: o['Part Number'],
                diameter: o['Diameter'],
            }
        })

        diameterOptions = [{label: 'All Diameters', value: 'all'}].concat(
            diameterOptions.sort().map((o) => {
                return {
                    label: o,
                    value: o,
                }
            })
        )

        this.setState({
            partNumberOptions: partNumberOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
            ),
            loading: false,
            diameterOptions,
        })
    }

    async submit() {
        this.setState({loading: true, ready: false})
        const dataModelInfo =
            DATA_MODEL_ID_MAP[this.state.selectedOperation.label]
        const designatedTimestamp = this.state.dataModels
            .find((d) => d._id === dataModelInfo.dataModelId)
            .fields.find((f) => f.type === 'DesignatedTimestamp')

        let timefilter = cloneDeep(this.state.timefilter)
        timefilter.value[0] = timefilter.value[0].add(-1, 'second').valueOf()
        timefilter.value[1] = timefilter.value[1].valueOf()

        let filter = []
        if (designatedTimestamp) {
            filter.push({...timefilter})
            filter[0].path = designatedTimestamp.name
            filter[0].type = 'DesignatedTimestamp'
        } else if (this.state.selectedOperation.label === 'Kiln') {
            filter.push({...timefilter})
            filter[0].path = 'Load.Start Date'
            filter[0].type = 'DesignatedTimestamp'
            filter.push({...timefilter})
            filter[1].path = 'Unload.Start Date'
            filter[1].type = 'DesignatedTimestamp'
        } else if (
            this.state.selectedOperation.label === '2 Shot Re-Run Tracker'
        ) {
            filter.push({...timefilter})
            filter[0].path = 'Re_Runs.Re_Run.Date'
            filter[0].type = 'DesignatedTimestamp'
        } else if (this.state.selectedOperation.label === 'Collar Cure') {
            filter.push({...timefilter})
            filter[0].path = 'Collar Cure Part 1.Start Timestamp'
            filter[0].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[1].path = 'Collar Cure Part 2.Start Timestamp'
            filter[1].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[2].path = 'Collar Cure Part 3.Start Timestamp'
            filter[2].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[3].path = 'Collar Cure Part 4.Start Timestamp'
            filter[3].type = 'DesignatedTimestamp'
        } else if (this.state.selectedOperation.label === 'BBO') {
            filter.push({...timefilter})
            filter[0].path = 'Part 1.Start Timestamp'
            filter[0].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[1].path = 'Part 2.Start Timestamp'
            filter[1].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[2].path = 'Part 3.Start Time'
            filter[2].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[3].path = 'Part 4.Start Time'
            filter[3].type = 'DesignatedTimestamp'

            filter.push({...timefilter})
            filter[4].path = 'Part 5.Start Time'
            filter[4].type = 'DesignatedTimestamp'
        }

        if (this.state.selectedPartNumber.value !== false) {
            filter.push({
                logic: 'is',
                path: dataModelInfo.partNumberFieldPath,
                type: 'Text',
                value: [this.state.selectedPartNumber.value],
            })
        } else if (this.state.selectedDiameter.value !== 'all') {
            filter.push({
                logic: 'equalTo',
                path: 'Block Batch Link.Block Description.Diameter',
                type: 'Number',
                value: this.state.selectedDiameter.value,
            })
        }

        let records = await paginate(dataModelInfo.dataModelId, filter, {})
        const blockBatches = records
            .filter((a) => a['Block Batch Link'])
            .map((a) => a['Block Batch Link']['Batch Number'])
        let otherOperations = []
        for (let op in DATA_MODEL_ID_MAP) {
            if (
                DATA_MODEL_ID_MAP[op].dataModelId !== dataModelInfo.dataModelId
            ) {
                otherOperations.push(
                    paginate(DATA_MODEL_ID_MAP[op].dataModelId, [
                        {
                            logic: 'is',
                            path: 'Block Batch Link.Batch Number',
                            type: 'Text',
                            value: blockBatches,
                        },
                    ])
                )
            }
        }

        otherOperations = (await Promise.all(otherOperations)).flat().flat()

        let totalBlocksIn = 0,
            totalBlocksOut = 0,
            blockedBatches = []

        for (let batchNumber of blockBatches) {
            let operationRecords = {}
            for (let op in DATA_MODEL_ID_MAP) {
                if (op === this.state.selectedOperation.label) {
                    operationRecords[op] = records
                        .filter((a) => a['Block Batch Link'])
                        .find(
                            (a) =>
                                a['Block Batch Link']['Batch Number'] ===
                                batchNumber
                        )
                } else {
                    operationRecords[op] = otherOperations.find(
                        (a) =>
                            a['Block Batch Link']['Batch Number'] ==
                                batchNumber &&
                            a.dataModelId === DATA_MODEL_ID_MAP[op].dataModelId
                    )
                }
            }
            const result = count(
                operationRecords,
                this.state.selectedOperation.label,
                true
            )
            if (result.blocked) {
                blockedBatches.push(batchNumber)
            }

            totalBlocksIn += result.in
            totalBlocksOut += result.out
        }

        records = records.filter(
            (r) =>
                !blockedBatches.includes(r['Block Batch Link']['Batch Number'])
        )

        let scrapGroups = [],
            reworkGroups = [],
            negateScrapGroups = []

        if (this.state.selectedOperation.label === '2 Shot Re-Run Tracker') {
            scrapGroups = {
                '1st_Shift_Scrap': 0,
                '2nd_Shift_Scrap': 0,
                '3rd_Shift_Scrap': 0,
            }

            for (let record of records) {
                for (let instance of record['Re_Runs']) {
                    // TODO: check if date matches
                    scrapGroups['1st_Shift_Scrap'] +=
                        instance['Re_Run']['1st_Shift_Scrap']
                    scrapGroups['2nd_Shift_Scrap'] +=
                        instance['Re_Run']['2nd_Shift_Scrap']
                    scrapGroups['3rd_Shift_Scrap'] +=
                        instance['Re_Run']['3rd_Shift_Scrap']
                }
            }

            scrapGroups = [
                {
                    name: '1st_Shift_Scrap',
                    qty: scrapGroups['1st_Shift_Scrap'],
                },
                {
                    name: '2nd_Shift_Scrap',
                    qty: scrapGroups['2nd_Shift_Scrap'],
                },
                {
                    name: '3rd_Shift_Scrap',
                    qty: scrapGroups['3rd_Shift_Scrap'],
                },
            ]
        } else {
            scrapGroups = dataModelInfo.scrapFieldPaths.map((s) => {
                const fieldName = s.split('.')[s.split('.').length - 1],
                    model = this.state.dataModels.find((d) =>
                        d.fields.find((f) => f.name === fieldName)
                    ),
                    field = model.fields.find((f) => f.name === fieldName)
                return {
                    path: s,
                    name: field.name,
                    qty: 0,
                }
            })

            for (let i in scrapGroups) {
                const path = scrapGroups[i].path
                for (let j in records) {
                    const v = Object.byString(records[j], path)
                    if (v) {
                        scrapGroups[i].qty += v
                    }
                }
            }
            reworkGroups = dataModelInfo.reworkScrapFieldPaths.map((s) => {
                const fieldName = s.split('.')[s.split('.').length - 1],
                    model = this.state.dataModels.find((d) =>
                        d.fields.find((f) => f.name === fieldName)
                    ),
                    field = model.fields.find((f) => f.name === fieldName)
                return {
                    path: s,
                    name: field.name,
                    qty: 0,
                }
            })

            for (let i in reworkGroups) {
                const path = reworkGroups[i].path
                for (let j in records) {
                    const v = Object.byString(records[j], path)
                    if (v) {
                        reworkGroups[i].qty += v
                    }
                }
            }

            negateScrapGroups = dataModelInfo.negateScrapFieldPaths.map((s) => {
                const fieldName = s.split('.')[s.split('.').length - 1],
                    model = this.state.dataModels.find((d) =>
                        d.fields.find((f) => f.name === fieldName)
                    ),
                    field = model.fields.find((f) => f.name === fieldName)
                return {
                    path: s,
                    name: field.name,
                    qty: 0,
                }
            })

            for (let i in negateScrapGroups) {
                const path = negateScrapGroups[i].path
                for (let j in records) {
                    const v = Object.byString(records[j], path)
                    if (v) {
                        negateScrapGroups[i].qty -= v
                    }
                }
            }
        }

        if (
            this.state.selectedOperation.label === 'Test & Inspect' ||
            this.state.selectedOperation.label ===
                'Final Inspection (3DA Batches)'
        ) {
            totalBlocksOut = 0
            for (let record of records) {
                if (record['Good Block Count'])
                    totalBlocksOut += record['Good Block Count']
            }
        }

        this.setState({
            ready: true,
            loading: false,
            scrapGroups,
            reworkGroups,
            negateScrapGroups,
            totalBlocksIn,
            totalBlocksOut,
            blockedBatches,
        })
    }

    async componentDidMount() {
        this.setState({
            dataModels: await API.get('data-models', 2),
        })
    }

    handleStartChange(start) {
        if (start) {
            let {timefilter} = this.state
            timefilter.value[0] = start.startOf('day')
            this.setState({timefilter})
        }
    }

    handleEndChange(end) {
        if (end) {
            let {timefilter} = this.state
            timefilter.value[1] = end.endOf('day')
            this.setState({timefilter})
        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Navigation path={this.path} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <h1>Scrap & Rework Report</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm={{size: 6, offset: 3}}>
                        <Row>
                            <Col>
                                <Form>
                                    <Label className="form-group has-top-label">
                                        <Select
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="operation"
                                            value={this.state.selectedOperation}
                                            onChange={
                                                this
                                                    .handleSelectedOperationChange
                                            }
                                            options={Object.keys(
                                                DATA_MODEL_ID_MAP
                                            )
                                                .map((o) => {
                                                    return {
                                                        label: o,
                                                        value: o,
                                                    }
                                                })
                                                .filter(
                                                    (a) =>
                                                        DATA_MODEL_ID_MAP[
                                                            a.value
                                                        ]['scrapFieldPaths']
                                                            .length ||
                                                        DATA_MODEL_ID_MAP[
                                                            a.value
                                                        ][
                                                            'complexScrapFieldParser'
                                                        ]
                                                )
                                                .sort((a, b) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                                )}
                                        />
                                        <IntlMessages id="Operation" />
                                    </Label>
                                </Form>
                            </Col>
                        </Row>

                        {this.state.selectedOperation ? (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Select
                                                    components={{
                                                        Input: CustomSelectInput,
                                                    }}
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    name="diameter"
                                                    value={
                                                        this.state
                                                            .selectedDiameter
                                                    }
                                                    onChange={(
                                                        selectedDiameter
                                                    ) =>
                                                        this.setState({
                                                            selectedDiameter,
                                                            ready: false,
                                                        })
                                                    }
                                                    options={
                                                        this.state
                                                            .diameterOptions
                                                    }
                                                />
                                                <IntlMessages id="Diameter" />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Select
                                                    components={{
                                                        Input: CustomSelectInput,
                                                    }}
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    name="partnumber"
                                                    value={
                                                        this.state
                                                            .selectedPartNumber
                                                    }
                                                    onChange={(
                                                        selectedPartNumber
                                                    ) =>
                                                        this.setState({
                                                            selectedPartNumber,
                                                            ready: false,
                                                        })
                                                    }
                                                    options={[
                                                        {
                                                            label: 'All Part Numbers',
                                                            value: false,
                                                        },
                                                    ].concat(
                                                        this.state.partNumberOptions
                                                            .sort((a, b) =>
                                                                a.label.localeCompare(
                                                                    b.label
                                                                )
                                                            )
                                                            .filter((a) =>
                                                                this.state
                                                                    .selectedDiameter
                                                                    .value ===
                                                                'all'
                                                                    ? true
                                                                    : this.state
                                                                          .selectedDiameter
                                                                          .value ===
                                                                      a.diameter
                                                            )
                                                    )}
                                                />
                                                <IntlMessages id="Part Number" />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="6">
                                        <label>Start Date:</label>
                                        <DatePicker
                                            selected={
                                                this.state.timefilter.value[0]
                                            }
                                            selectsStart
                                            startDate={
                                                this.state.timefilter.value[0]
                                            }
                                            endDate={
                                                this.state.timefilter.value[1]
                                            }
                                            onChange={this.handleStartChange}
                                        />
                                    </Col>
                                    <Col xs="6">
                                        <label>End Date:</label>
                                        <DatePicker
                                            selected={
                                                this.state.timefilter.value[1]
                                            }
                                            selectsEnd
                                            startDate={
                                                this.state.timefilter.value[0]
                                            }
                                            endDate={
                                                this.state.timefilter.value[1]
                                            }
                                            onChange={this.handleEndChange}
                                        />
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : null}

                        <Row className="mt-4">
                            <Col>
                                <Button
                                    size="xl"
                                    block
                                    onClick={this.submit}
                                    disabled={this.state.loading}>
                                    GENERATE
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {this.state.selectedOperation &&
                        this.state.selectedOperation.label ===
                            '2 Shot Re-Run Tracker' ? (
                            <Row>
                                <Col className="text-center">
                                    Note: some scrap pieces may be outside of
                                    the bounds of the timerange.
                                </Col>
                            </Row>
                        ) : null}
                        {this.state.ready ? (
                            <>
                                <Row className="mt-4">
                                    <Col xs="12">
                                        <Row>
                                            <Col xs="6">
                                                <h1>
                                                    <strong>
                                                        Total Blocks IN:{' '}
                                                        {
                                                            this.state
                                                                .totalBlocksIn
                                                        }
                                                    </strong>
                                                </h1>
                                            </Col>
                                            {!templates[
                                                this.state.selectedOperation
                                                    .label
                                            ] ? (
                                                <Col xs="6">
                                                    <h1>
                                                        <strong>
                                                            Total Blocks OUT:{' '}
                                                            {
                                                                this.state
                                                                    .totalBlocksOut
                                                            }
                                                        </strong>
                                                    </h1>
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        {templates[
                                            this.state.selectedOperation.label
                                        ] ? (
                                            templates[
                                                this.state.selectedOperation
                                                    .label
                                            ](
                                                this.state.totalBlocksIn,
                                                this.state.reworkGroups,
                                                this.state.scrapGroups,
                                                this.state.totalBlocksOut
                                            )
                                        ) : (
                                            <>
                                                {this.state.reworkGroups
                                                    .length ? (
                                                    <Col
                                                        xs="6"
                                                        className="mt-4">
                                                        <h4 className="text-center">
                                                            <strong>
                                                                Rework
                                                            </strong>
                                                        </h4>
                                                        <Card>
                                                            <CardBody>
                                                                <Table>
                                                                    <tbody>
                                                                        {this.state.reworkGroups.map(
                                                                            (
                                                                                row,
                                                                                idx
                                                                            ) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            idx
                                                                                        }>
                                                                                        <th>
                                                                                            {
                                                                                                row.name
                                                                                            }
                                                                                        </th>
                                                                                        <td>
                                                                                            {
                                                                                                row.qty
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {this
                                                                                                .state
                                                                                                .totalBlocksIn
                                                                                                ? Math.round(
                                                                                                      (row.qty /
                                                                                                          this
                                                                                                              .state
                                                                                                              .totalBlocksIn) *
                                                                                                          100 *
                                                                                                          100
                                                                                                  ) /
                                                                                                  100
                                                                                                : 0}

                                                                                            %
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        )}
                                                                        <tr>
                                                                            <th>
                                                                                TOTAL
                                                                            </th>
                                                                            <th>
                                                                                {this.state.reworkGroups.reduce(
                                                                                    (
                                                                                        a,
                                                                                        b
                                                                                    ) =>
                                                                                        a +
                                                                                        b.qty,
                                                                                    0
                                                                                )}
                                                                            </th>
                                                                            <th>
                                                                                {this
                                                                                    .state
                                                                                    .totalBlocksIn
                                                                                    ? Math.round(
                                                                                          (this.state.reworkGroups.reduce(
                                                                                              (
                                                                                                  a,
                                                                                                  b
                                                                                              ) =>
                                                                                                  a +
                                                                                                  b.qty,
                                                                                              0
                                                                                          ) /
                                                                                              this
                                                                                                  .state
                                                                                                  .totalBlocksIn) *
                                                                                              100 *
                                                                                              100
                                                                                      ) /
                                                                                      100
                                                                                    : 0}

                                                                                %
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                ) : null}

                                                {this.state.scrapGroups
                                                    .length ||
                                                this.state.negateScrapGroups ? (
                                                    <Col
                                                        xs="6"
                                                        className="mt-4">
                                                        <h4 className="text-center">
                                                            <strong>
                                                                Scrap
                                                            </strong>
                                                        </h4>
                                                        <Card>
                                                            <CardBody>
                                                                <Table>
                                                                    <tbody>
                                                                        {this.state.scrapGroups.map(
                                                                            (
                                                                                row,
                                                                                idx
                                                                            ) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            idx
                                                                                        }>
                                                                                        <th>
                                                                                            {
                                                                                                row.name
                                                                                            }
                                                                                        </th>
                                                                                        <td>
                                                                                            {
                                                                                                row.qty
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {this
                                                                                                .state
                                                                                                .totalBlocksIn
                                                                                                ? Math.round(
                                                                                                      (row.qty /
                                                                                                          this
                                                                                                              .state
                                                                                                              .totalBlocksIn) *
                                                                                                          100 *
                                                                                                          100
                                                                                                  ) /
                                                                                                  100
                                                                                                : 0}

                                                                                            %
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        )}
                                                                        {this.state.negateScrapGroups.map(
                                                                            (
                                                                                row,
                                                                                idx
                                                                            ) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            idx
                                                                                        }>
                                                                                        <th>
                                                                                            {
                                                                                                row.name
                                                                                            }
                                                                                        </th>
                                                                                        <td>
                                                                                            {
                                                                                                row.qty
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {this
                                                                                                .state
                                                                                                .totalBlocksIn
                                                                                                ? Math.abs(
                                                                                                      Math.round(
                                                                                                          (row.qty /
                                                                                                              this
                                                                                                                  .state
                                                                                                                  .totalBlocksIn) *
                                                                                                              100 *
                                                                                                              100
                                                                                                      )
                                                                                                  ) /
                                                                                                  100
                                                                                                : 0}

                                                                                            %
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        )}
                                                                        <tr>
                                                                            <th>
                                                                                TOTAL
                                                                            </th>
                                                                            <th>
                                                                                {this.state.negateScrapGroups
                                                                                    .concat(
                                                                                        this
                                                                                            .state
                                                                                            .scrapGroups
                                                                                    )
                                                                                    .reduce(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            a +
                                                                                            b.qty,
                                                                                        0
                                                                                    )}
                                                                            </th>
                                                                            <th>
                                                                                {this
                                                                                    .state
                                                                                    .totalBlocksIn
                                                                                    ? Math.round(
                                                                                          (this.state.scrapGroups.reduce(
                                                                                              (
                                                                                                  a,
                                                                                                  b
                                                                                              ) =>
                                                                                                  a +
                                                                                                  b.qty,
                                                                                              0
                                                                                          ) /
                                                                                              this
                                                                                                  .state
                                                                                                  .totalBlocksIn) *
                                                                                              100 *
                                                                                              100
                                                                                      ) /
                                                                                      100
                                                                                    : 0}

                                                                                %
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                ) : null}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </Col>
                </Row>
                {this.state.blockedBatches.length > 0 ? (
                    <Row>
                        <Col>
                            <p>
                                Blocks that were not included in this report due
                                to incomplete operation forms:
                            </p>
                            <ul>
                                {this.state.blockedBatches.map((a, idx) => (
                                    <li key={idx}>{a}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
