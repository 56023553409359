import {
    LOGIN_USER,
    LOGIN_USER_SSO,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
} from 'constants/actionTypes'

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: {user, history},
})

export const loginUserSSO = (ssoToken, history) => ({
    type: LOGIN_USER_SSO,
    payload: {ssoToken, history},
})

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
})

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: {user, history},
})
export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
})

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: {history},
})
