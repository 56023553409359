import React, {Component, Fragment} from 'react'
import {Button, ListGroup, Card, CardTitle, CardBody} from 'reactstrap'
import {Scrollbars} from 'react-custom-scrollbars'
import autoBind from 'auto-bind'

import ModelTree from '../../../../common/ModelTree'
import TimestampFilter from '../../../../QueryBuilder/Filter/Timestamp/index'
import TextFilter from '../../../../QueryBuilder/Filter/Text'
import NumberFilter from '../../../../QueryBuilder/Filter/Number'
import BooleanFilter from '../../../../QueryBuilder/Filter/Boolean'

export default class extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.height = document.documentElement.offsetHeight * 0.2
        this.list = []
        this.paths = []
        this.treeData = []
        this.flatData = []

        this.state = {
            selected: [],
            view: 'list', // 'filter', 'selected'
            model: null,
            field: null,
            path: null,
            node: null,
        }
    }

    handleFieldClick(selectedKeys, {selectedNodes}) {
        const node = selectedNodes[0]
        if (
            node.type !== 'MultiSubModel' &&
            node.type !== 'SingleSubModel' &&
            node.type !== 'ForeignID'
        ) {
            this.setState({
                model: node.model,
                field: node.field,
                path: node.path,
                view: 'filter',
                node,
            })
        }
    }

    addFilter({logic, value, prettyString, path}) {
        let filter = this.props.filter
        if (filter.find((f) => f.path === path)) {
            return alert('This filter already exists')
        }
        filter.push({
            ...this.state.node,
            logic,
            value,
            prettyString,
            dataModelId: this.state.model._id,
            fieldId: this.state.field._id,
            type: this.state.field.type,
            path: this.state.path,
        })
        this.setState({view: 'list'}, this.props.setFilter(filter))
    }

    removeFilter(fi) {
        const filter = this.props.filter.filter(
            (f) => f.prettyString !== fi.prettyString
        )
        this.props.setFilter(filter)
        if (filter.length === 0) {
            this.setState({view: 'list'})
        }
    }

    showFilterEngine() {
        const {filterEngine} = this.props.fieldTypes.find(
            (t) => t.type === this.state.field.type
        )
        if (
            filterEngine === 'exact_string' ||
            filterEngine === 'contain_string'
        ) {
            return (
                <TextFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    exact={filterEngine === 'exact_string'}
                    onSubmit={this.addFilter}
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                />
            )
        } else if (filterEngine === 'number') {
            return (
                <NumberFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    onSubmit={this.addFilter}
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                />
            )
        } else if (filterEngine === 'timestamp') {
            return (
                <TimestampFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    onSubmit={this.addFilter}
                    isDesignatedTimeEnd={
                        this.state.field.type === 'DesignatedTimeEnd'
                    }
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                />
            )
        } else if (filterEngine === 'boolean') {
            return (
                <BooleanFilter
                    logic={''}
                    value={''}
                    dataModelId={this.state.model._id}
                    fieldId={this.state.field._id}
                    path={this.state.path}
                    fieldName={this.state.field.name}
                    onSubmit={this.addFilter}
                    formulas={this.props.formulas}
                    variables={this.props.variables}
                />
            )
        }
    }

    componentDidMount() {
        let trees = []
        const allowedTypes = [
            'SingleSubModel',
            'MultiSubModel',
            'ForeignID',
        ].concat(
            this.props.fieldTypes.filter((a) => a.filterable).map((a) => a.type)
        )
        trees.push(
            <ModelTree
                showMap={(item) => allowedTypes.includes(item.type)}
                onSelect={this.handleFieldClick}
                dataModel={this.props.dataModel}
                dataModels={this.props.dataModels}
                includeJoins={true}
                excludeJoinPrefixes={true}
            />
        )
        this.setState({trees})
    }

    render() {
        return (
            <Fragment>
                <h5 style={{color: '#145388'}}>FILTER BY</h5>

                {this.state.view !== 'selected' ? (
                    <>
                        {this.props.filter.length ? (
                            <Button
                                size="xs"
                                className="btn btn-block"
                                onClick={() => {
                                    this.setState({view: 'selected'})
                                }}>
                                Show selected
                            </Button>
                        ) : null}
                    </>
                ) : (
                    <>
                        <Button
                            size="xs"
                            onClick={() => {
                                this.setState({view: 'list'})
                            }}
                            className="btn btn-block">
                            Show fields
                        </Button>
                    </>
                )}

                {this.state.view === 'filter' ? (
                    <>
                        <h5
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                this.setState({view: 'list'})
                            }}>
                            <i className="simple-icon-arrow-left mr-2"></i>
                            {this.state.model.name +
                                ' - ' +
                                this.state.field.name}
                        </h5>
                        {this.showFilterEngine()}
                    </>
                ) : null}

                {this.state.view === 'list' ? (
                    <>
                        <Scrollbars
                            autoHeight
                            autoHeightMin={this.height}
                            autoHeightMax={this.height}>
                            {this.state.trees}
                        </Scrollbars>
                    </>
                ) : null}

                {this.state.view === 'selected' ? (
                    <ListGroup className="mt-4">
                        {this.props.filter.map((f, idx) => {
                            return (
                                <Card key={idx}>
                                    <CardBody>
                                        <CardTitle className="text-right">
                                            <i
                                                className="iconsmind-Close"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    this.removeFilter(f)
                                                }}></i>
                                        </CardTitle>
                                        {f.prettyString}
                                    </CardBody>
                                </Card>
                            )
                        })}
                    </ListGroup>
                ) : null}
            </Fragment>
        )
    }
}
