import React, {Component, Fragment} from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Row,
    Col,
    Input,
    Button,
} from 'reactstrap'
import Switch from 'rc-switch'
import Select from 'react-select'
import autobind from 'auto-bind'
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'

import CustomSelectInput from 'components/CustomSelectInput'

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const IN_APP_NOTE_NOTIFICATION_OPTIONS = [
    {
        label: "Don't show any notifications",
        value: 'none',
    },
    {
        label: "Only notify me when I'm mentioned in a note",
        value: 'mentioned',
    },
    {
        label: 'Notify me when any note is added',
        value: 'all',
    },
]

const IN_APP_REPLY_NOTIFICATION_OPTIONS = [
    {
        label: "Don't show any notifications",
        value: 'none',
    },
    {
        label: "Only notify me when I'm mentioned in a reply",
        value: 'mentioned',
    },
    {
        label: 'Only notify me when someone replies to my notes',
        value: 'mine',
    },
    {
        label: 'Notify me when any note is replied to',
        value: 'all',
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            enableSMS: this.props.settings.sms.enabled,
            enableEmail: this.props.settings.email.enabled,
            phone: this.props.settings.sms.phone,
            email: this.props.settings.email.email,
            inAppNoteNotifications: IN_APP_NOTE_NOTIFICATION_OPTIONS.find(
                (o) => o.value === this.props.settings.appNotifications.note
            ),
            inAppReplyNotifications: IN_APP_REPLY_NOTIFICATION_OPTIONS.find(
                (o) => o.value === this.props.settings.appNotifications.reply
            ),
        }
    }

    submit() {
        if (this.state.enableSMS && !isValidPhoneNumber(this.state.phone)) {
            return alert('Invalid phone number provided')
        }
        if (
            this.state.enableEmail &&
            !emailRegex.test(this.state.email.toLowerCase())
        ) {
            return alert('Invalid email address provided')
        }

        let record = {
            _id: this.props.settings._id,
            email: {
                enabled: this.state.enableEmail,
                email: this.state.email,
            },
            sms: {
                enabled: this.state.enableSMS,
                phone: this.state.phone,
            },
            appNotifications: {
                note: this.state.inAppNoteNotifications.value,
                reply: this.state.inAppReplyNotifications.value,
            },
        }
        this.props.saveSettings(record)
    }

    async componentWillMount() {}

    componentWillUnmount() {}

    render() {
        return (
            <Modal isOpen={true} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Settings</ModalHeader>
                <ModalBody>
                    {/*<Row>
                    <Col>
                        <Label>Email me a daily summary of notes every weekday:</Label>
                        <Switch
                        className="custom-switch custom-switch-primary"
                        checked={this.state.hours}
                        onChange={hours => {
                            this.setState({ hours });
                        }}
                        disabled={this.state.loading}
                        />
                    </Col>
                      </Row>*/}
                    <Row>
                        <Col>
                            <h5>Notification Settings</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>
                                Text me when I'm mentioned in a note or when
                                someone replies to my note:
                            </Label>
                            <Row>
                                <Col xs="4">
                                    <Switch
                                        className="custom-switch custom-switch-primary"
                                        checked={this.state.enableSMS}
                                        onChange={(enableSMS) => {
                                            this.setState({enableSMS})
                                        }}
                                    />
                                </Col>
                                {this.state.enableSMS ? (
                                    <Col xs="8">
                                        <PhoneInput
                                            defaultCountry="US"
                                            placeholder="Enter phone number"
                                            value={this.state.phone}
                                            onChange={(phone) =>
                                                this.setState({phone})
                                            }
                                        />
                                    </Col>
                                ) : null}
                            </Row>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>
                                Email me when I'm mentioned in a note or when
                                someone replies to my note:
                            </Label>
                            <Row>
                                <Col xs="4">
                                    <Switch
                                        className="custom-switch custom-switch-primary"
                                        checked={this.state.enableEmail}
                                        onChange={(enableEmail) => {
                                            this.setState({enableEmail})
                                        }}
                                    />
                                </Col>
                                {this.state.enableEmail ? (
                                    <Col xs="8">
                                        <Input
                                            style={{height: 25, padding: 0}}
                                            type="text"
                                            name="lastName"
                                            placeholder="Enter email address"
                                            value={this.state.email}
                                            onChange={(email) =>
                                                this.setState({
                                                    email: email.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                ) : null}
                            </Row>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>In-app notifications for new notes:</Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="inappnotifications-notes"
                                value={this.state.inAppNoteNotifications}
                                onChange={(inAppNoteNotifications) =>
                                    this.setState({inAppNoteNotifications})
                                }
                                options={IN_APP_NOTE_NOTIFICATION_OPTIONS}
                            />
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>In-app notifications for replies:</Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="inappnotifications-replies"
                                value={this.state.inAppReplyNotifications}
                                onChange={(inAppReplyNotifications) =>
                                    this.setState({inAppReplyNotifications})
                                }
                                options={IN_APP_REPLY_NOTIFICATION_OPTIONS}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submit}>
                        Save Changes
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
