import React, {Component, Fragment} from 'react'
import {FormGroup, Input, Label} from 'reactstrap'
import autoBind from 'auto-bind'
import {Typeahead} from 'react-bootstrap-typeahead'
import dot from 'dot-object'
import {evaluate} from 'mathjs'
import _ from 'lodash'

import RowConverter from '../RowConverter'
import {paginate} from '../Paginate'

export default class LinkSelector extends Component {
    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            loading: true,
            options: [],
            filterByFields: [],
            //...props.formData,
            selected: [],
            //formData: {...props.formData}
        }

        this.field = props.uiSchema

        this.id = Math.random()
    }

    onRadioChange(event) {
        const selected = [
            this.state.options.find((o) => o._id === event.target.value),
        ]
        this.setState({selected})
        this.props.onChange(event.target.value)
    }

    onChange(selected) {
        this.setState({selected}, () => {
            if (selected.length) {
                this.props.formContext.updateLinkedDocument(selected[0]._record)
                setTimeout(() => {
                    this.props.onChange(selected[0]._id)
                }, 100)
            } else {
                this.props.onChange(null)
            }
        })
    }

    async componentDidMount() {
        let filter = this.props.uiSchema.filters || [],
            records = await paginate(this.field.foreignDataModelId, filter, {}),
            linkedDataModel = this.props.formContext.dataModels.find(
                (d) => d._id === this.field.foreignDataModelId
            )

        if (this.field.foreignOptionFilter) {
            let plainText = this.field.foreignOptionFilter.plainText
            const domesticDataModelId = this.props.formContext.dataModels.find(
                (m) => m._id === this.props.formContext.schema.$id
            )._id
            for (let instance of this.field.foreignOptionFilter.map) {
                const dataModel = this.props.formContext.dataModels.find(
                    (a) => a.name === instance.display
                )
                plainText = plainText.replace(
                    new RegExp(instance.display, 'g'),
                    'str' + dataModel._id
                )
            }

            this.expressionFilter = {expression: plainText, domesticDataModelId}
        }

        /**
         * first we check if an alias path exists,
         * if not, we check for a primaryId
         * if no primaryId, we stringify the object and present it as an option
         */
        const field = this.props.formContext.dataModels
            .find((m) => m._id === this.props.formContext.schema.$id)
            .fields.find((f) => f.name === this.props.name)
        let pathToDisplay =
            field && field.foreignAliasPath ? field.foreignAliasPath : false

        if (!pathToDisplay) {
            const primaryId = linkedDataModel.fields.find(
                (a) => !a.archived && a.type === 'PrimaryID'
            )
            pathToDisplay = primaryId ? primaryId.name : false
        }

        let options = [],
            selected = []

        if (pathToDisplay) {
            options = records.map((a) => {
                return {
                    _id: a._id,
                    _str: dot.pick(pathToDisplay, a),
                    _record: a,
                }
            })
        } else {
            options = records.map((o) => {
                return {
                    ...RowConverter(linkedDataModel, o, ['Image', 'ForeignID']),
                    _record: o,
                }
            })
        }

        if (options.length === 0) {
            alert(this.field.name + ' does not have any options!')
        }

        if (this.props.formData) {
            selected = options.find((o) => o._id === this.props.formData._id)
            if (!selected) {
                options.push(
                    RowConverter(linkedDataModel, this.props.formData, [
                        'Image',
                        'ForeignID',
                    ])
                )
            }
            selected = selected ? [selected] : []
        }

        if (selected.length) {
            this.onChange(selected)
        }

        this.setState({
            selected,
            options,
            filterByFields: options.length ? Object.keys(options[0]) : [],
            loading: false,
        })
    }

    componentDidUpdate(prevProps) {
        /**
         * This is a hack, because:
         * If we are editing an existing document, formData is an object, when I call props.onChange to set it to the _id,
         * for some reason, react-json-forms reverts back to the object as the form data instead of the _id string.
         * Therefore, unless the same value is re-selected from the dropdown, this field's value is rejected since it is
         * an object, not a string.
         */
        if (this.props.formData && this.props.formData.constructor === String) {
            try {
                if (this.expressionFilter) {
                    let transformed = _.cloneDeep(
                        this.props.formContext.formData
                    )
                    for (let _id in this.props.formContext.linkedDocuments) {
                        transformed = _.cloneDeepWith(transformed, (value) =>
                            value === _id
                                ? this.props.formContext.linkedDocuments[_id]
                                : undefined
                        )
                    }
                    const foreignDocument =
                        this.props.formContext.linkedDocuments[
                            this.props.formData
                        ]
                    if (
                        !evaluate(this.expressionFilter.expression, {
                            ['str' + foreignDocument.dataModelId]:
                                foreignDocument,
                            ['str' + this.expressionFilter.domesticDataModelId]:
                                transformed,
                        })
                    ) {
                        this.onChange([])
                    }
                }
            } catch (error) {
                //console.log(error);
            }
        }

        if (
            prevProps.formData &&
            this.props.formData &&
            prevProps.formData.constructor === String &&
            this.props.formData.constructor === Object
        ) {
            this.props.formContext.updateLinkedDocument(this.props.formData)
            this.props.onChange(this.props.formData._id)
        }
    }

    render() {
        return (
            <Fragment>
                {!this.state.loading ? (
                    <>
                        <label
                            style={{
                                fontWeight: this.field.radio
                                    ? 'bold'
                                    : 'normal',
                            }}
                            className={
                                this.field.radioInline ? 'mr-2' : 'mr-0'
                            }>
                            {this.field.name}
                        </label>
                        {this.field.radio ? (
                            <>
                                {this.state.options
                                    .sort((a, b) =>
                                        a._str.localeCompare(b._str)
                                    )
                                    .map((o, idx) => {
                                        return (
                                            <FormGroup
                                                key={idx}
                                                check
                                                inline={this.field.radioInline}>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name={this.id}
                                                        value={o._id}
                                                        checked={
                                                            this.state
                                                                .selected &&
                                                            this.state.selected
                                                                .length &&
                                                            this.state
                                                                .selected[0]
                                                                ._id === o._id
                                                        }
                                                        onChange={
                                                            this.onRadioChange
                                                        }
                                                    />
                                                    {' ' + o._str}
                                                </Label>
                                            </FormGroup>
                                        )
                                    })}
                            </>
                        ) : (
                            <Typeahead
                                id={Math.random()}
                                filterBy={({_str, _record}, {text}) => {
                                    try {
                                        const contains =
                                            text && _str
                                                ? _str
                                                      .toLowerCase()
                                                      .includes(
                                                          text.toLowerCase()
                                                      )
                                                : true
                                        if (!this.expressionFilter)
                                            return contains

                                        let transformed = _.cloneDeep(
                                            this.props.formContext.formData
                                        )
                                        for (let _id in this.props.formContext
                                            .linkedDocuments) {
                                            transformed = _.cloneDeepWith(
                                                transformed,
                                                (value) =>
                                                    value === _id
                                                        ? this.props.formContext
                                                              .linkedDocuments[
                                                              _id
                                                          ]
                                                        : undefined
                                            )
                                        }
                                        return (
                                            evaluate(
                                                this.expressionFilter
                                                    .expression,
                                                {
                                                    ['str' +
                                                    _record.dataModelId]:
                                                        _record,
                                                    ['str' +
                                                    this.expressionFilter
                                                        .domesticDataModelId]:
                                                        transformed,
                                                }
                                            ) && contains
                                        )
                                    } catch (error) {
                                        console.log(error)
                                        return false
                                    }
                                }}
                                isLoading={this.state.loading}
                                labelKey={(option) => `${option._str}`}
                                options={this.state.options.sort((a, b) =>
                                    a._str.localeCompare(b._str)
                                )}
                                renderMenuItemChildren={(option) => option._str}
                                onChange={this.onChange}
                                selected={this.state.selected}
                            />
                        )}
                    </>
                ) : null}
            </Fragment>
        )
    }
}
