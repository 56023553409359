import React, {Component} from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import autobind from 'auto-bind'
import bwipjs from 'bwip-js'
import moment from 'moment'
import html2canvas from 'html2canvas'
import {hubbell, ge} from './Label/logos'
import axios from 'axios'
const {
    Label,
    PrintDensity,
    PrintDensityName,
    Spacing,
    Graphic,
    GraphicData,
} = require('jszpl')

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.printRef = React.createRef(null)

        this.state = {
            labelData: {
                ProductClass: '',
                BadgeNumber: [],
                Material: '',
                Description: '',
                Misc_Comment_1: '',
                Misc_Comment_2: '',
                Misc_Comment_3: '',
                Misc_Comment_4: '',
                Misc_Comment_5: '',
                Misc_Comment_6: '',
                Misc_Comment_7: '',
                Misc_Comment_8: '',
                UPCEAN: '',
                qty: 0,
                WeightUnit: '',
                UnitWeight: 0,
                date: '',
            },
        }
    }

    async makeLabel({badgeNumber, workOrderNumber, material, qty}) {
        try {
            const Active_DA_ZFIN_Materials_Row =
                    this.props.Active_DA_ZFIN_Materials.find(
                        (row) => row['Material'] === material
                    ),
                DA_ZFIN_Label_Info_Row = this.props.DA_ZFIN_Label_Info.find(
                    (row) => row['Object'] === material
                )

            if (!Active_DA_ZFIN_Materials_Row || !DA_ZFIN_Label_Info_Row) {
                throw Error(
                    'Could not find label information for selected material.'
                )
            }

            let ProductClass = 'HUBBELL'
            if (
                Active_DA_ZFIN_Materials_Row['Product Class'] ===
                'Distribution Arresters'
            ) {
                ProductClass = 'OHIO BRASS'
            } else if (
                DA_ZFIN_Label_Info_Row['General Electric UPC#'] &&
                DA_ZFIN_Label_Info_Row['General Electric UPC#'] !== ''
            ) {
                ProductClass = 'GENERAL ELECTRIC'
            }

            let labelData = {
                ProductClass: ProductClass,
                BadgeNumber: badgeNumber,
                Material: material,
                Description:
                    DA_ZFIN_Label_Info_Row['Alternate Description'] &&
                    DA_ZFIN_Label_Info_Row['Alternate Description'] !== ''
                        ? DA_ZFIN_Label_Info_Row['Alternate Description']
                        : DA_ZFIN_Label_Info_Row['Description'],
                Misc_Comment_1: DA_ZFIN_Label_Info_Row['Misc Comment #1'],
                Misc_Comment_2: DA_ZFIN_Label_Info_Row['Misc Comment #2'],
                Misc_Comment_3: DA_ZFIN_Label_Info_Row['Misc Comment #3'],
                Misc_Comment_4: DA_ZFIN_Label_Info_Row['Misc Comment #4'],
                Misc_Comment_5: DA_ZFIN_Label_Info_Row['Misc Comment #5'],
                Misc_Comment_6: DA_ZFIN_Label_Info_Row['Misc Comment #6'],
                Misc_Comment_7: DA_ZFIN_Label_Info_Row['Misc Comment #7'],
                Misc_Comment_8: DA_ZFIN_Label_Info_Row['Misc Comment #8'],
                UPCEAN: Active_DA_ZFIN_Materials_Row['EAN/UPC'],
                WeightUnit: Active_DA_ZFIN_Materials_Row['WUn'],
                UnitWeight: Active_DA_ZFIN_Materials_Row['Gross Weight'],
                qty: qty,
                workOrderNumber: workOrderNumber,
                date: moment().format('MM/DD/YYYY'),
            }
            this.setState({labelData})
            bwipjs.toCanvas('barcode_canvas', {
                bcid: 'ean13',
                text: Active_DA_ZFIN_Materials_Row['EAN/UPC'],
                scale: 2,
                height: 10, // Bar height, in millimeters
                includetext: true,
                textxalign: 'center',
                paddingleft: 50,
            })
        } catch (error) {
            console.log(error)
            this.props.toggleBarcodeModal()
            return alert('Could not generate label.')
        }

        const label = new Label()
        label.printDensity = new PrintDensity(PrintDensityName['8dpmm'])
        label.width = 140
        label.height = 225
        label.padding = new Spacing(10)
        const graphic = new Graphic()
        const image = new Image()

        const self = this

        image.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height

            const context = canvas.getContext('2d')
            context.drawImage(image, 0, 0)

            const imageData = context.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
            )

            let index = 0
            const imageBits = []

            for (let y = 0; y < imageData.height; y++) {
                for (let x = 0; x < imageData.width; x++) {
                    const red = imageData.data[index++]
                    const green = imageData.data[index++]
                    const blue = imageData.data[index++]
                    const opacity = imageData.data[index++]

                    let value = 0

                    if (opacity != 0) {
                        value = (red + green + blue) / 3 < 180 ? 1 : 0
                    }

                    imageBits.push(value)
                }
            }
            graphic.data = new GraphicData(image.width, image.height, imageBits)
            label.content.push(graphic)
            const zpl = label.generateZPL()
            axios.post(`http://${localStorage['printer_gateway_ip']}`, {
                forwardTo: `http://${localStorage['printer_ip']}/pstprnt`,
                zpl: zpl,
            })
            self.props.toggleBarcodeModal()
        }
        const element = this.printRef.current
        const canvas = await html2canvas(element)
        const data = canvas.toDataURL('image/png')
        image.src = data
    }

    async componentDidMount() {
        setTimeout(() => {
            this.makeLabel({
                badgeNumber: this.props.badgeNumber,
                material: this.props.material,
                workOrderNumber: this.props.workOrderNumber,
                qty: this.props.qty,
            })
        }, 1)
    }

    render() {
        const {labelData} = this.state

        return (
            <Modal size="lg" isOpen={true}>
                <ModalBody>
                    <div
                        ref={this.printRef}
                        style={{
                            width: '6in',
                            height: '4in',
                            transform: 'rotate(90deg)',
                        }}>
                        <svg width="6in" height="4in">
                            <rect
                                x="0"
                                y="0"
                                width="6in"
                                height="4in"
                                style={{fill: 'rgb(255,255,255)'}}
                            />
                            {labelData.ProductClass === 'GENERAL ELECTRIC' ? (
                                <>
                                    <image
                                        href={ge}
                                        x="40"
                                        y="10"
                                        width="105"
                                        height="105"
                                        textAnchor="start"
                                    />
                                    <text x="160" y="60" className="tri32">
                                        {labelData.ProductClass}
                                    </text>
                                </>
                            ) : (
                                <>
                                    <image
                                        href={hubbell}
                                        x="30"
                                        y="-35"
                                        width="175"
                                        height="175"
                                        textAnchor="start"
                                    />
                                    <text x="223" y="60" className="tri32">
                                        {labelData.ProductClass}
                                    </text>
                                </>
                            )}

                            {labelData.BadgeNumber.length === 1 ? (
                                <text x="480" y="100" className="tri14">
                                    {labelData.BadgeNumber[0]}
                                </text>
                            ) : null}

                            {labelData.BadgeNumber.length === 2 ? (
                                <text x="423" y="100" className="tri14">
                                    {labelData.BadgeNumber.join(',')}
                                </text>
                            ) : null}

                            {labelData.BadgeNumber.length > 2 ? (
                                <text x="365" y="100" className="tri14">
                                    {labelData.BadgeNumber.slice(0, 3).join(
                                        ','
                                    )}
                                </text>
                            ) : null}

                            {/* <text x="475" y="100" className="tri24">{labelData.BadgeNumber}</text>*/}

                            <text x="20" y="165" className="tri36">
                                {labelData.Material}
                            </text>
                            <text x="20" y="200" className="tri30">
                                {labelData.Description}
                            </text>

                            <text x="20" y="235" className="tri24">
                                {labelData.Misc_Comment_1}
                            </text>
                            <text x="22" y="255" className="tri14">
                                {labelData.Misc_Comment_2}
                            </text>
                            <text x="22" y="270" className="tri14">
                                {labelData.Misc_Comment_3}
                            </text>
                            <text x="22" y="285" className="tri14">
                                {labelData.Misc_Comment_4}
                            </text>

                            <text x="320" y="235" className="tri24">
                                {labelData.Misc_Comment_5}
                            </text>
                            <text x="320" y="255" className="tri14">
                                {labelData.Misc_Comment_6}
                            </text>
                            <text x="320" y="270" className="tri14">
                                {labelData.Misc_Comment_7}
                            </text>
                            <text x="320" y="285" className="tri14">
                                {labelData.Misc_Comment_8}
                            </text>

                            <text x="30" y="310" className="tri14">
                                {labelData.workOrderNumber}
                            </text>
                            <text x="30" y="330" className="tri16">
                                QTY :
                            </text>
                            <text x="27" y="375" className="tri32">
                                {labelData.qty}
                            </text>

                            <text x="450" y="319" className="tri14">
                                {labelData.date}
                            </text>
                            <text x="499" y="349" className="tri20">
                                WT:
                            </text>
                            <text x="420" y="380" className="tri32">
                                {labelData.qty * labelData.UnitWeight}
                            </text>
                            <text x="515" y="380" className="tri18">
                                {labelData.WeightUnit}
                            </text>

                            <text x="190" y="299" className="tri14">
                                Assembled In USA
                            </text>
                        </svg>
                        <div style={{marginTop: '-85px', marginLeft: '65px'}}>
                            {' '}
                            <canvas id="barcode_canvas"></canvas>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
