import React, {Component, Fragment} from 'react'
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import {Scrollbars} from 'react-custom-scrollbars'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {}
    }

    makeCommentCard(comment) {
        const userDefined = comment['Entered By'] !== 'N/A'
        return (
            <Row>
                <Col>
                    <p>
                        <strong>{comment.timestamp}</strong> ({comment.duration}
                        )
                    </p>
                    <p>
                        <strong>{comment.deviceName}</strong>
                        {': '}
                        {comment.value}
                    </p>
                    {userDefined ? (
                        <p>
                            <small>Entered by {comment['Entered By']}</small>
                        </p>
                    ) : null}
                    <hr />
                </Col>
            </Row>
        )
    }
    render() {
        return (
            <Col xs="3" className="mb-4">
                <Card style={{height: '100%'}}>
                    {this.props.comments.length ? (
                        <>
                            <CardHeader>
                                <h4>{this.props.reason} comments</h4>
                            </CardHeader>
                            <CardBody>
                                <Scrollbars>
                                    {this.props.comments.map((c) =>
                                        this.makeCommentCard(c)
                                    )}
                                </Scrollbars>
                            </CardBody>
                        </>
                    ) : (
                        <>
                            <CardHeader>
                                <h4>No reason selected</h4>
                            </CardHeader>
                            <CardBody>
                                <p className="text-center text-muted">
                                    Click on any bar to load the list of
                                    downtime comments associated with this
                                    reason code.
                                </p>
                            </CardBody>
                        </>
                    )}
                </Card>
            </Col>
        )
    }
}
