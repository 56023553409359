import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'
import CRUD from 'SDK/ui/crud'

import * as API from 'SDK/api'

export default class ShiftScrapHistory extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceIds',
            component: 'MultiAssetPicker',
        },
    ]
    showBorder = false
    id = 'ShiftScrapHistory'
    requiredOutputs = ['Part Count', 'In-Cycle', 'Reject']
    static propTypes = {
        name: PropTypes.string,
        deviceIds: PropTypes.array,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            devices: [],
            device: null,
            data: [],
            value: 0,
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            shifts: [],
        }
    }

    handleStartChange(date) {
        this.setState({
            start: date,
        })
    }

    handleEndChange(date) {
        this.setState({
            end: moment(date).endOf('day'),
        })
    }

    async fetchShifts() {
        const shifts = await API.get('shifts', 2)
        this.setState({shifts})
    }

    async fetchShiftHistory(deviceId) {
        this.setState({loading: true})

        let device = deviceId || this.state.device.deviceId

        let relevantShifts = this.state.shifts.filter((s) =>
            s.assets.find((a) => a === device)
        )

        let promises = relevantShifts.map((shift) => {
            return API.post('historical', {
                deviceId: device,
                state: 'Part Count',
                shift: shift.name,
                unit: 'days',
                timeStart: this.state.start,
                timeEnd: this.state.end,
            })
        })
        promises = promises.concat(
            relevantShifts.map((shift) => {
                return API.post('historical', {
                    deviceId: device,
                    state: 'Reject',
                    shift: shift.name,
                    unit: 'days',
                    timeStart: this.state.start,
                    timeEnd: this.state.end,
                })
            })
        )

        let data = await Promise.all(promises)

        let shiftData = []

        for (let dataset of data) {
            if (dataset.query.state === 'Part Count') {
                for (let date in dataset.dates) {
                    shiftData.push({
                        date: moment(dataset.dates[date]),
                        shift: dataset.query.shift,
                        totalParts: dataset.count[date],
                    })
                }
            }
        }

        for (let dataset of data) {
            if (dataset.query.state === 'Reject') {
                for (let date in dataset.dates) {
                    let shiftRecord = shiftData.find((s) => {
                        return (
                            s.date.isSame(moment(dataset.dates[date])) &&
                            s.shift === dataset.query.shift
                        )
                    })
                    if (shiftRecord) {
                        shiftRecord.badParts = dataset.count[date]
                    }
                }
            }
        }

        shiftData = shiftData.filter((s) => s.totalParts > 0)
        shiftData.sort((a, b) => b.date.valueOf() - a.date.valueOf())
        this.setState({data: shiftData, loading: false})
    }

    async fetchRunTimes(deviceId) {
        this.setState({loading: true})
        const data = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: deviceId || this.state.device.deviceId,
                    name: {$in: ['In-Cycle', 'Reject']},
                    timeStart: {
                        $gte: this.state.start,
                    },
                    timeEnd: {
                        $lte: this.state.end,
                        $ne: null,
                    },
                },
                options: {
                    sort: {
                        timeStart: -1,
                    },
                },
            },
            2
        )

        let runtimes = data.filter((o) => o.name === 'In-Cycle'),
            scraps = data.filter((o) => o.name === 'Reject')

        for (let i in runtimes) {
            const range = [
                moment(runtimes[i].timeStart).valueOf(),
                moment(runtimes[i].timeEnd).valueOf(),
            ]
            runtimes[i].scrap = scraps
                .filter((s) => {
                    const t = moment(s.timeStart).valueOf()
                    return t >= range[0] && t <= range[1]
                })
                .reduce((a, b) => a + b.value, 0)
        }
        this.setState({data: runtimes, loading: false})
    }

    columns() {
        const productionUnit =
            this.state.device && this.state.device.productionUnit
                ? this.state.device.productionUnit.charAt(0).toUpperCase() +
                  this.state.device.productionUnit.slice(1) +
                  's'
                : 'Parts'

        return [
            {
                Header: 'Date',
                accessor: 'prettyDate',
            },
            {
                Header: 'Shift',
                accessor: 'shift',
            },
            {
                Header: 'Total ' + productionUnit,
                accessor: 'totalParts',
            },
            {
                Header: 'Total Rejects',
                accessor: 'badParts',
            },
            {
                id: 'edit',
                accessor: '_id',
                Cell: ({original}) => (
                    <Button
                        color="primary"
                        size="xs"
                        className="btn"
                        onClick={(e) => {
                            e.stopPropagation()
                            this.toggleModal()
                            this.setState({editing: original})
                        }}>
                        Edit Amount
                    </Button>
                ),
                sortable: false,
                filterable: false,
                Header: '',
            },
        ]
    }

    dataTableFormatter(record) {
        record.prettyDate = moment(record.date).format('dddd MMM DD, YYYY')
        return record
    }

    async toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async submit() {
        this.setState({message: 'Submitting rejects...'})

        if (parseInt(this.state.value) > this.state.editing.totalParts) {
            return alert('Rejected count cannot be more than total count.')
        }

        let scrapRecord = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.state.device.deviceId,
                    name: 'Reject',
                    timeStart: moment(this.state.editing.date)
                        .add(1, 'minutes')
                        .toISOString(),
                },
            },
            2
        )

        if (!scrapRecord.length && parseInt(this.state.value) > 0) {
            const timestamp = moment(this.state.editing.date)
                .add(1, 'minutes')
                .toDate()
            await API.post(
                'states',
                {
                    nodeId: this.state.device.deviceId,
                    deviceId: this.state.device.deviceId,
                    name: 'Reject',
                    timestamp: timestamp,
                    timeStart: timestamp,
                    timeEnd: timestamp,
                    value: parseInt(this.state.value),
                    metaData: [
                        {
                            name: 'reject',
                            value: parseInt(this.state.value),
                        },
                    ],
                },
                2
            )
        } else if (scrapRecord.length && parseInt(this.state.value) > 0) {
            scrapRecord[0].value = parseInt(this.state.value)
            scrapRecord[0].metaData = [
                {
                    name: 'reject',
                    value: parseInt(this.state.value),
                },
            ]
            await API.patch('states/' + scrapRecord[0]._id, scrapRecord[0], 2)
        } else if (scrapRecord.length && parseInt(this.state.value) === 0) {
            await API.remove('states/' + scrapRecord[0]._id, 2)
        }
        this.generate()
        this.toggleModal()
    }

    handleDeviceChange(device) {
        this.setState({
            device: this.state.devices.find((d) => d.deviceId === device.value),
        })
    }

    generate() {
        this.fetchShiftHistory()
    }

    async componentWillMount() {
        await this.fetchShifts()
        const devices = await API.get('devices'),
            device = devices.length ? devices[0] : null
        this.setState({devices, device}, this.fetchShiftHistory)
    }

    componentWillUnmount() {}

    render() {
        const selectedDevice = this.state.device
            ? {label: this.state.device.name, value: this.state.device.deviceId}
            : null
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center">
                                    <strong>Reject History</strong>
                                </CardTitle>
                                <Row>
                                    <Col xs="12">
                                        <Form>
                                            <Row>
                                                <Col xs="12" sm="4">
                                                    <label>From:</label>
                                                    <DatePicker
                                                        selected={
                                                            this.state.start
                                                        }
                                                        selectsStart
                                                        startDate={
                                                            this.state.start
                                                        }
                                                        endDate={this.state.end}
                                                        onChange={
                                                            this
                                                                .handleStartChange
                                                        }
                                                        filterDate={(date) => {
                                                            return (
                                                                moment() > date
                                                            )
                                                        }}
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    <label>To:</label>
                                                    <DatePicker
                                                        selected={
                                                            this.state.end
                                                        }
                                                        selectsEnd
                                                        startDate={
                                                            this.state.start
                                                        }
                                                        endDate={this.state.end}
                                                        onChange={
                                                            this.handleEndChange
                                                        }
                                                        filterDate={(date) => {
                                                            return (
                                                                moment() > date
                                                            )
                                                        }}
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    <Label>Asset:</Label>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        name="deviceId"
                                                        value={selectedDevice}
                                                        onChange={
                                                            this
                                                                .handleDeviceChange
                                                        }
                                                        options={this.state.devices.map(
                                                            (d) => {
                                                                return {
                                                                    label: d.name,
                                                                    value: d.deviceId,
                                                                }
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="text-right">
                                        <Button
                                            color="primary"
                                            onClick={this.generate}
                                            disabled={this.state.loading}>
                                            <i className="iconsmind-Arrow-Refresh" />{' '}
                                            Generate
                                        </Button>
                                    </Col>
                                </Row>
                                <CRUD
                                    uniqueElementId="_id"
                                    emptyCrudMainText="No Run Times Found"
                                    emptyCrudSubText=""
                                    crudTableColumns={this.columns()}
                                    crudTableResolveDataMapFn={
                                        this.dataTableFormatter
                                    }
                                    crudTableData={this.state.data}
                                    rowClickFn={() => {}}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.showModal ? (
                    <Modal
                        isOpen={this.state.showModal}
                        toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>
                            Edit Reject Amount
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="12">
                                    <Form>
                                        <Label className="form-group has-top-label">
                                            <Input
                                                type="number"
                                                name="reject"
                                                value={this.state.value}
                                                onChange={(value) => {
                                                    if (value.target.value < 0)
                                                        return
                                                    this.setState({
                                                        value: value.target
                                                            .value,
                                                    })
                                                }}
                                            />
                                            <IntlMessages id="Reject Amount" />
                                        </Label>
                                    </Form>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.submit}>Save Changes</Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}
            </Fragment>
        )
    }
}
