import React, {Component, Fragment} from 'react'
import {Bar} from 'react-chartjs-2'
import moment from 'moment'
import autobind from 'auto-bind'

import {Row, Col} from 'reactstrap'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            loaded: false,
            chartdata: {},
            options: {
                maintainAspectRatio: false,
                title: {
                    text: 'Last 24 Hours',
                    display: true,
                    fontColor: '#fff',
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                            type: 'time',
                            time: {
                                unit: 'hour',
                            },
                            ticks: {
                                fontColor: '#fff',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                        },
                    ],
                },
            },
        }
    }

    async makeChart() {
        const response = await API.post('historical', {
            timeStart: moment().add(-23, 'hours').toISOString(),
            timeEnd: moment().toISOString(),
            deviceId: this.props.deviceId,
            state: 'Part Count',
            unit: 'hours',
        })
        if (!response) {
            return // alert('There was an error fetching data.');
        }

        this.setState({
            loaded: true,
            chartdata: {
                labels: response.dates.map((d) => moment(d).toDate()),
                datasets: [
                    {
                        label: 'Actual',
                        data: response.count,
                        backgroundColor: this.props.color,
                        //borderColor: 'black',
                        //borderWidth: 0.03
                    },
                    {
                        label: 'Target',
                        type: 'line',
                        data: new Array(response.count.length).fill(
                            this.state.target
                        ),
                        backgroundColor: 'transparent',
                        borderColor: 'darkgrey',
                    },
                ],
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.target !== this.state.target) {
            this.setState({target: nextProps.target})
            this.makeChart()
        }
    }

    componentDidMount() {
        this.subscriber.add(this.makeChart, 1000 * 60 * 5, 'makeChart()')
    }
    componentWillUnmount() {
        this.subscriber.removeAll()
    }
    render() {
        const isLoading = !this.state.loaded
        return isLoading ? null : (
            <Row>
                <Col>
                    <Bar
                        data={this.state.chartdata}
                        options={this.state.options}
                    />
                </Col>
            </Row>
        )
    }
}
