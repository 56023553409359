import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Button,
} from 'reactstrap'
import autobind from 'auto-bind'
import Select from 'react-select'
import CustomSelectInput from 'components/CustomSelectInput'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import _ from 'lodash'

import TIMERANGES from '../Common/TIMERANGES'
import SCHEDULES from '../Common/SCHEDULES'

class AssetTreeContainer extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            data: this.props.data,
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (!_.isEqual(nextProps.data, this.state.data)) {
            this.setState({data: nextProps.data})
        }
    }

    shouldComponentUpdate(nextProps) {
        return !_.isEqual(nextProps.data, this.state.data)
    }

    render() {
        const {data, ...rest} = this.props
        return <DropdownTreeSelect data={this.state.data} {...rest} />
    }
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        const {assets, timerange, schedule, color} = this.props.editingDataset

        const initAssetSelection = assets || []
        const initTimerangeSelection = timerange
            ? {label: timerange, value: timerange}
            : null
        let initScheduleSelection = null
        if (schedule !== undefined) {
            const predefinedSchedule = SCHEDULES.find(
                (s) => s.value === schedule
            )
            if (predefinedSchedule) initScheduleSelection = predefinedSchedule
            else initScheduleSelection = {label: schedule, value: schedule}
        }

        this.state = {
            assetSelection: initAssetSelection,
            timerangeSelection: initTimerangeSelection,
            scheduleSelection: initScheduleSelection,
        }

        this.assetOptions = this.makeAssetOptions()
        this.scheduleOptions = this.makeScheduleOptions()
    }

    makeAssetOptions() {
        const {error, groups} = this.props.factory.fetchAssetGroups()
        if (error) {
            return alert('There was an error loading data.')
        }

        let data = []
        groups.forEach((group) => {
            const children = this.props.assets
                .filter((a) => a.group() === group)
                .map((a) => {
                    return {
                        label: a.name,
                        value: a.deviceId,
                        checked:
                            this.state.assetSelection.find(
                                (deviceId) => deviceId === a.deviceId
                            ) !== undefined,
                    }
                })
            data.push({
                label: group,
                value: group,
                checked: children.every((c) => c.checked),
                children,
            })
        })
        return data
    }

    makeScheduleOptions() {
        let data = SCHEDULES

        for (let i in this.props.factory._shifts) {
            const s = this.props.factory._shifts[i]
            data.push({
                label: s.name,
                value: s.name,
            })
        }

        return data
    }

    handleAssetSelectionChange(currentNode, selectedNodes) {
        let assetSelection = []

        for (let i in selectedNodes) {
            const n = selectedNodes[i]

            if (n._depth > 0) {
                // child
                assetSelection.push(n.value)
            } else {
                // parent
                assetSelection = assetSelection.concat(
                    this.props.assets
                        .filter((a) => a.group() === n.label)
                        .map((a) => a.deviceId)
                )
            }
        }
        this.setState({assetSelection})
    }

    validate() {
        return (
            this.state.assetSelection.length &&
            this.state.timerangeSelection !== null &&
            this.state.scheduleSelection !== null
        )
    }

    submit() {
        if (!this.validate()) return alert('Invalid dataset configuration!')

        this.props.editingDataset.assets = this.state.assetSelection
        this.props.editingDataset.timerange =
            this.state.timerangeSelection.value
        this.props.editingDataset.schedule = this.state.scheduleSelection.value

        this.props.addDataset(this.props.editingDataset)
        this.props.toggle()
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    Dataset Manager
                </ModalHeader>
                <ModalBody>
                    <Row className="mb-4">
                        <Col>
                            <Label>
                                <strong>Assets</strong>
                            </Label>
                            <AssetTreeContainer
                                data={this.assetOptions}
                                onChange={this.handleAssetSelectionChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Label>
                                <strong>Timerange</strong>
                            </Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="timerange"
                                value={this.state.timerangeSelection}
                                options={TIMERANGES.map((t) => {
                                    return {label: t.name, value: t.name}
                                })}
                                onChange={(timerangeSelection) => {
                                    this.setState({timerangeSelection})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Label>
                                <strong>Schedule</strong>
                            </Label>
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="schedule"
                                value={this.state.scheduleSelection}
                                options={this.scheduleOptions}
                                onChange={(scheduleSelection) => {
                                    this.setState({scheduleSelection})
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn"
                        onClick={this.submit}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
