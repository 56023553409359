import {cloneDeep, snakeCase} from 'lodash'
import {dot, object} from 'dot-object'

const generateTemplate = (flow, dataModels) => {
    if (flow.nodes.find((n) => n.data.opts.type === 'GET_REPORT')) {
        return alert(
            'At this time, flows containing "Get Report" functionality cannot be converted into a template'
        )
    }
    let template = cloneDeep(flow),
        deps = {
            databaseId: '',
            timezone: '',
            requestURL: '',
            dataModelId: {},
        }

    delete template.dataModelId
    delete template._id
    delete template.deleted
    delete template.elements

    template.syncedToFlowServer = false
    template.templateGeneratedOn = new Date().toISOString()
    template.settings = {
        enableInputs: true,
        enableOutputs: true,
        pauseFlow: false,
    }

    for (let i in template.nodes) {
        let node = template.nodes[i],
            type = node.data.opts.type

        /**
         * INPUTS
         */
        if (type === 'RECORD_TRIGGER') {
            // path, dependant on useClassicTrigger
            if (node.data.opts.useClassicTrigger) {
                const stateName = dataModels
                    .find((a) => a._id === node.data.opts.dataModelId)
                    .name.split('Classic/')[1]
                node.data.opts.path = `<%= databaseId %>/Mirror/States/${stateName}`
            } else {
                const dataModelName = dataModels.find(
                    (a) => a._id === node.data.opts.dataModelId
                ).name
                deps.dataModelId[snakeCase(dataModelName)] = ''
                node.data.opts.path = `<%= databaseId %>/<%= dataModelId.${snakeCase(
                    dataModelName
                )} %>`
            }
        }
        if (type === 'ON_DEMAND_TRIGGER') {
            // path
            // it's easier to rewrite the path value after flow creation
        }
        if (type === 'SCHEDULED_TRIGGER') {
            // none
        }

        /**
         * LOGIC
         */
        if (type === 'CREATE_VARIABLE') {
            // none
        }
        if (type === 'DECIDE') {
            // none
        }
        if (type === 'GET_RECORDS') {
            // none
        }
        if (type === 'GET_REPORT') {
            // DIFFICULT, NEEDS SOME THOUGHT
        }
        if (type === 'LOOP') {
            // none
        }
        if (type === 'MODIFY') {
            // none
        }
        if (type === 'PAUSE') {
            // none
        }

        /**
         * OUTPUT
         */
        if (type === 'CREATE_RECORD') {
            // none
        }
        if (type === 'DELETE_RECORD') {
            // none
        }
        if (type === 'SEND_EMAIL') {
            // none
        }
        if (type === 'UPDATE_RECORD') {
            // none
        }
    }

    for (let i in template.variables) {
        if (
            template.variables[i].fields &&
            template.variables[i].type !== 'Report'
        ) {
            template.variables[i].fields = []
        }
    }

    template = dot(template)

    for (let key in template) {
        const value = template[key]
        if (key.endsWith('dataModelId')) {
            const dataModelName = dataModels.find((a) => a._id === value).name
            template[key] = `<%= dataModelId.${snakeCase(dataModelName)} %>`
            deps.dataModelId[snakeCase(dataModelName)] = ''
        }
        if (key.endsWith('timezone')) {
            template[key] = '<%= timezone %>'
        }
        if (key.endsWith('requestURL')) {
            template[key] = '<%= requestURL %>'
        }
    }

    template = object(template)

    const element = document.createElement('a')
    const file = new Blob([JSON.stringify({template, deps})], {
        type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = `${JSON.parse(localStorage['userObject']).factoryId}_${
        template.name
    }.flow`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
}

export default generateTemplate
