import React, {useState} from 'react'
import {
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Label,
    Input,
    Collapse,
} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

import modelToDot from '../../../helpers/modelToDot'
import SelectInput from '../../../../common/select-input'
import TextInput from '../../../../common/text-input'
import FilterModal from '../../../../SchemaViewer/modals/Filter'

export default (props) => {
    const [parameters, setParameters] = useState({
        dataModelId: props.element.data.opts.dataModelId || null,
        filter: props.element.data.opts.filter || [],
        name: props.element.data.opts.name || 'RecordTrigger',
        description: props.element.data.opts.description || '',
        trigger: props.element.data.opts.trigger || {
            create: false,
            update: false,
            delete: false,
        },
        useClassicTrigger: props.element.data.opts.useClassicTrigger || false,
        variableId: props.element.data.opts.variableId || null,
        path: props.element.data.opts.path || '',
        submitted: props.element.data.opts.submitted || true,
    })
    const [advancedIsOpen, toggleAdvanced] = useState(false)
    const [filterModalOpen, setFilterModalOpen] = useState(false)

    const submit = () => {
        const existingVariable = props.variables.find(
            (v) => v.id === parameters.variableId
        )
        const variable = {
            id: existingVariable ? existingVariable.id : uuidv4(),
            name: parameters.name,
            transformId: props.element.id,
            type: 'Record',
            multi: false,
            dataModelId: parameters.dataModelId,
            fields: modelToDot(parameters.dataModelId, props.dataModels, false),
        }

        parameters.variableId = variable.id
        if (parameters.useClassicTrigger) {
            const stateName = props.dataModels
                .find((d) => d._id === parameters.dataModelId)
                .name.split('Classic/')[1]
            parameters.path = `${
                JSON.parse(localStorage['userObject']).factoryId
            }/Mirror/States/${stateName}`
        } else {
            parameters.path = `${
                JSON.parse(localStorage['userObject']).factoryId
            }/${parameters.dataModelId}`
        }

        props.onChange(parameters, [variable])
    }

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.name = v.replace(/\s/g, '')
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Name"
                                existing={props.elements
                                    .filter((el) => el.id !== props.element.id)
                                    .map((el) => el.data.opts.name)}
                                initial={parameters.name}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                onValueChange={(v) => {
                                    parameters.description = v
                                    setParameters({...parameters})
                                }}
                                onValidChange={() => {}}
                                fieldName="Description"
                                existing={[]}
                                initial={parameters.description}
                                disabled={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                onChange={(m) => {
                                    parameters.dataModelId = m
                                    parameters.filter = []
                                    setParameters({...parameters})
                                }}
                                fieldName="Data Model"
                                default={
                                    parameters.dataModelId
                                        ? {
                                              label: props.dataModels.find(
                                                  (m) =>
                                                      m._id ===
                                                      parameters.dataModelId
                                              ).name,
                                              value: parameters.dataModelId,
                                          }
                                        : null
                                }
                                options={props.dataModels.map((m) => {
                                    return {
                                        label: m.name,
                                        value: m._id,
                                    }
                                })}
                            />
                        </FormGroup>

                        {parameters.dataModelId ? (
                            <FormGroup>
                                <Button
                                    color="default"
                                    size="sm"
                                    block
                                    onClick={() => setFilterModalOpen(true)}>
                                    Add record conditions...
                                </Button>
                            </FormGroup>
                        ) : null}

                        <FormGroup className="mb-0">
                            <Label>
                                <strong>Run after record is:</strong>
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    defaultChecked={parameters.trigger.create}
                                    onChange={(e) => {
                                        parameters.trigger.create =
                                            e.target.checked
                                        setParameters({...parameters})
                                    }}
                                />{' '}
                                Created
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    defaultChecked={parameters.trigger.update}
                                    onChange={(e) => {
                                        parameters.trigger.update =
                                            e.target.checked
                                        setParameters({...parameters})
                                    }}
                                />{' '}
                                Updated
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    defaultChecked={parameters.trigger.delete}
                                    onChange={(e) => {
                                        parameters.trigger.delete =
                                            e.target.checked
                                        setParameters({...parameters})
                                    }}
                                />{' '}
                                Deleted
                            </Label>
                        </FormGroup>

                        <hr />

                        <FormGroup>
                            <p
                                color="link"
                                style={{cursor: 'pointer'}}
                                onClick={() => toggleAdvanced(!advancedIsOpen)}>
                                {advancedIsOpen ? (
                                    <>
                                        <i className="simple-icon-arrow-down" />{' '}
                                        Hide advanced
                                    </>
                                ) : (
                                    <>
                                        <i className="simple-icon-arrow-right" />{' '}
                                        Show advanced
                                    </>
                                )}
                            </p>
                            <Collapse isOpen={advancedIsOpen}>
                                <FormGroup className="m-4">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            defaultChecked={
                                                parameters.useClassicTrigger
                                            }
                                            onChange={(e) => {
                                                parameters.useClassicTrigger =
                                                    e.target.checked
                                                setParameters({...parameters})
                                            }}
                                        />{' '}
                                        Listen for API / Pre-Processor publishes
                                        instead of Data-Modeller publishes
                                    </Label>
                                </FormGroup>
                            </Collapse>
                        </FormGroup>

                        <hr />
                        <Button block onClick={submit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
            {filterModalOpen ? (
                <FilterModal
                    open={true}
                    toggle={() => setFilterModalOpen(!filterModalOpen)}
                    dataModel={props.dataModels.find(
                        (m) => m._id === parameters.dataModelId
                    )}
                    dataModels={props.dataModels}
                    updateFilters={(filter) => {
                        parameters.filter = filter
                        setParameters({...parameters})
                    }}
                    filters={parameters.filter}
                    refresh={() => {}}
                    fieldTypes={props.fieldTypes}
                />
            ) : null}
        </>
    )
}
