import {Component} from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import {Row, Col, Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import DataGrid, {SelectColumn} from 'react-data-grid'
import dot from 'dot-object'
import {cloneDeep} from 'lodash'
import {Menu, Item, Submenu, useContextMenu} from 'react-contexify'
import FileUpload from './FileUpload'
import FileDownload from './FileDownload'
import 'react-contexify/dist/ReactContexify.css'

import * as API from 'SDK/api'
import modelToDot from '../helpers/modelToDot'
import Editors from './editors'
import Formatters from './formatters'
import FilterModal from '../../SchemaViewer/modals/Filter'
import Form from '../../common/Form'

class DataViewer extends Component {
    static propTypes = {
        // dataModelId of datamodel we're loading
        dataModelId: PropTypes.string.isRequired,

        // GET v2/data-models
        dataModels: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string,
                name: PropTypes.string,
                fields: PropTypes.arrayOf(PropTypes.object),
                parentDataModelId: PropTypes.any,
                parentFieldId: PropTypes.any,
            })
        ).isRequired,

        // optional filter for data, same format as pagination filter field
        filter: PropTypes.arrayOf(PropTypes.object),

        // optional data sorting, same format as pagination sort field
        sort: PropTypes.object,

        // optional error handling if fetching data fails
        onDataFetchError: PropTypes.func,

        // optional error handling if editing row fails
        onEditError: PropTypes.func,

        // optional error handling if archiving row fails
        onArchiveError: PropTypes.func,

        // optional error handling if validator fetching fails
        onGetValidatorError: PropTypes.func,

        // optional width for component, by default it tries to fit entire parent element
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // optional height for component, by default it tries to fit the entire screen
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // optional ability to provide rows instead of fetching new ones
        // use cases:
        // 	- show multisubmodel rows
        //  - manage offline/draft rows
        provideOwnRows: PropTypes.arrayOf(PropTypes.object),

        // by default, users can add, update & delete rows, this provides ability to prevent
        // one or more of those actions
        permissions: PropTypes.shape({
            create: PropTypes.bool,
            update: PropTypes.bool,
            delete: PropTypes.bool,
            filter: PropTypes.bool,
            sort: PropTypes.bool,
        }),

        // optional callback triggered when filter array changes
        onFilterChange: PropTypes.func,

        // optional callback triggered when sort object changes
        onSortChange: PropTypes.func,

        // optional ability to choose fields to hide (ignored if length = 0)
        hideFields: PropTypes.arrayOf(PropTypes.string),

        // optional ability to choose fields to show (ignored if length = 0)
        showFields: PropTypes.arrayOf(PropTypes.string),

        // optional custom actions in the "Actions" column
        // e.g. [{
        //			element: <>Delete</>,
        //			onClick: (record) => { ... delete record ... }
        //		}]
        customActions: PropTypes.arrayOf(
            PropTypes.shape({
                element: PropTypes.element,
                onClick: PropTypes.func,
            })
        ),
    }

    static defaultProps = {
        onDataFetchError: console.log,
        onEditError: console.log,
        onArchiveError: console.log,
        onGetValidatorError: console.log,
        onFilterChange: console.log,
        onSortChange: console.log,
        filter: [],
        sort: {},
        width: '100%',
        height: 0,
        permissions: {
            create: true,
            update: true,
            delete: true,
            filter: true,
            sort: true,
        },
        hideFields: ['_id'],
        showFields: [],
        customActions: [],
    }

    constructor(props) {
        super(props)
        autoBind(this)

        this.height =
            this.props.height || document.documentElement.offsetHeight * 0.72

        this.dataModel = this.props.dataModels.find(
            (a) => a._id === this.props.dataModelId
        )
        if (!this.dataModel) {
            throw new Error('Data model not found.')
        }

        this.makeContextMenus()
        this.makeColumns()

        this.state = {
            loading: false,
            ready: true,
            next: null,
            rows: [],
            selectedRows: new Set(),
            activeContextHeaderColumn: null,
            filters: this.props.filter || [],
            sort: this.props.sort || {},
            fieldTypes: [],
            contextTable: null,
            referenceTable: null,
            formValidator: null,
            filterModalOpen: false,
            formModalOpen: false,
            formModalUpload: false,
            totalRowCount: 0,
        }
    }

    renderContextTable(e, dataModelId, filter = [], ownRows = []) {
        this.setState({
            contextTable: (
                <DataViewer
                    dataModelId={dataModelId}
                    dataModels={this.props.dataModels}
                    filter={filter}
                    width={700}
                    height={200}
                    provideOwnRows={ownRows.length ? ownRows : undefined}
                    permissions={{
                        create: false,
                        update: false,
                        delete: false,
                        sort: false,
                        filter: false,
                    }}
                />
            ),
        })
        this.rowDrawerMenu.show(e)
    }

    renderReferenceTable(e, row) {
        let el = (
            <>
                {(this.dataModel.references || []).map((ref, idx) => {
                    const targetModel = this.props.dataModels.find(
                        (model) => model._id === ref.targetDataModelId
                    )
                    return (
                        <Submenu key={idx} label={targetModel.name}>
                            <DataViewer
                                dataModelId={targetModel._id}
                                dataModels={this.props.dataModels}
                                filter={[
                                    {
                                        path: ref.targetPath,
                                        logic: 'is',
                                        value: [row[ref.sourcePath]],
                                        type: 'Text', // TODO: populate with whatever else it should be
                                    },
                                ]}
                                width={700}
                                height={300}
                            />
                        </Submenu>
                    )
                })}
            </>
        )
        this.setState({
            referenceTable: el,
        })
        this.referenceTableMenu.show(e)
    }

    makeColumns() {
        const dotMap = modelToDot(this.props.dataModelId, this.props.dataModels)

        this.columns = []
        if (this.props.permissions.delete) {
            this.columns.push(SelectColumn)
        }
        this.columns.push({
            key: '@@actions@@',
            name: 'Actions',
            resizable: true,
            width: 100 + 50 * this.props.customActions.length,
        })

        this.columns = this.columns.concat(
            dotMap
                .filter(({hidden}) => !hidden)
                .map(({path, field}) => {
                    if (
                        this.props.showFields.length ||
                        this.props.hideFields.length
                    ) {
                        const p = path.split('._id')[0]
                        if (
                            (this.props.showFields.length &&
                                !this.props.showFields.includes(p)) ||
                            (this.props.hideFields.length &&
                                this.props.hideFields.includes(p))
                        ) {
                            return undefined
                        }
                    }
                    let obj = {
                        key: path,
                        name: path,
                        formatter: Formatters[field.type],
                        field,
                        resizable: true,
                        headerRenderer: (props) => {
                            const sorted =
                                this.state.sort &&
                                this.state.sort.columnKey === path
                            return (
                                <div
                                    onClick={(e) => {
                                        this.setState(
                                            {activeContextHeaderColumn: props},
                                            this.headerMenu.show(e)
                                        )
                                    }}>
                                    {props.column.name}
                                    {sorted &&
                                    this.state.sort.direction ===
                                        'ascending' ? (
                                        <>
                                            {' '}
                                            <i className="simple-icon-arrow-up" />
                                        </>
                                    ) : null}
                                    {sorted &&
                                    this.state.sort.direction ===
                                        'descending' ? (
                                        <>
                                            {' '}
                                            <i className="simple-icon-arrow-down" />
                                        </>
                                    ) : null}
                                </div>
                            )
                        },
                    }

                    if (field.type === 'ForeignID') {
                        obj.name = obj.name.split('._id')[0]

                        obj.pathToDisplay = field.foreignAliasPath || null
                        if (obj.pathToDisplay) {
                            obj.pathToDisplay = `${obj.name}.${obj.pathToDisplay}`
                            obj.searchPath = field.foreignAliasPath
                        } else {
                            const foreignPrimaryId = this.props.dataModels
                                .find((m) => m._id === field.foreignDataModelId)
                                .fields.find(
                                    (f) => f.type === 'PrimaryID' && !f.archived
                                )

                            if (foreignPrimaryId) {
                                obj.pathToDisplay = `${obj.name}.${foreignPrimaryId.name}`
                                obj.searchPath = foreignPrimaryId.name
                            }
                        }

                        if (!obj.pathToDisplay) {
                            obj.pathToDisplay = path
                            obj.searchPath = path
                        }
                        obj.formatter = (props) =>
                            Formatters[field.type](
                                props,
                                this.renderContextTable,
                                this.props.dataModels
                            )
                        if (this.props.permissions.update) {
                            obj.editor = (props) =>
                                Editors[field.type](
                                    props,
                                    obj.pathToDisplay,
                                    obj.searchPath
                                )
                        }
                    }
                    if (field.type === 'MultiSubModel') {
                        obj.formatter = (props) =>
                            Formatters[field.type](
                                props,
                                this.renderContextTable
                            )
                    }

                    if (
                        this.props.permissions.update &&
                        field.type !== 'ForeignID' &&
                        field.type !== 'MultiSubModel'
                    ) {
                        obj.editor = Editors[field.type]
                    }

                    return obj
                })
        )
        this.columns = this.columns.filter((a) => a !== undefined)
    }

    makeContextMenus() {
        this.referenceTableMenu = useContextMenu({
            id: 'data-grid-reference-menu',
        })
        this.rowDrawerMenu = useContextMenu({id: 'data-grid-row-drawer-menu'})
        this.headerMenu = useContextMenu({id: 'data-grid-header-context-menu'})
        this.headerMenuActions = {
            sort: {
                ascending: () => {
                    const {key, pathToDisplay} =
                        this.state.activeContextHeaderColumn.column
                    this.setState(
                        {
                            next: undefined,
                            sort: {
                                columnKey: key,
                                fieldName: pathToDisplay || key,
                                direction: 'ascending',
                            },
                        },
                        () => {
                            this.makeColumns()
                            this.fetchData(true)
                            this.props.onSortChange(this.state.sort)
                        }
                    )
                },
                descending: () => {
                    const {key, pathToDisplay} =
                        this.state.activeContextHeaderColumn.column
                    this.setState(
                        {
                            next: undefined,
                            sort: {
                                columnKey: key,
                                fieldName: pathToDisplay || key,
                                direction: 'descending',
                            },
                        },
                        () => {
                            this.makeColumns()
                            this.fetchData(true)
                            this.props.onSortChange(this.state.sort)
                        }
                    )
                },
            },
            filter: () => {
                this.setState({
                    filterModalOpen: true,
                })
            },
        }
    }

    renderActionsColumn(row) {
        return (
            <>
                <Button
                    className="mr-1"
                    size="xs"
                    color="default"
                    onClick={(e) => this.renderReferenceTable(e, row)}>
                    <i className="simple-icon-share" />
                </Button>
                {this.props.permissions.update ? (
                    <Button
                        size="xs"
                        color="default"
                        onClick={() => this.openForm(row)}>
                        <i className="simple-icon-note" />
                    </Button>
                ) : null}
                {this.props.customActions.map((action, idx) => {
                    return (
                        <Button
                            key={idx}
                            id={Math.random()}
                            size="xs"
                            color="default"
                            onClick={() => {
                                let _row = dot.object(cloneDeep(row))
                                delete _row['@@actions@@']
                                action.onClick(_row)
                            }}>
                            {action.element}
                        </Button>
                    )
                })}
            </>
        )
    }

    async fetchData(flush = false) {
        if (this.props.provideOwnRows) {
            this.setState({
                rows: this.props.provideOwnRows.map((row) => {
                    row = dot.dot(row)
                    row['@@actions@@'] = this.renderActionsColumn(row)
                    return row
                }),
            })
            return
        }

        if (!flush && this.state.next === false) return

        if (flush) {
            await this.setState({
                rows: [],
                next: null,
                totalRowCount: 0,
            })
        }

        this.setState({loading: true})
        try {
            let response = await API.post(
                'data-models/' + this.props.dataModelId + '/paginate',
                {
                    limit: 50,
                    filter: this.state.filters,
                    sort: this.state.sort,
                    next: this.state.next,
                    timezone: localStorage['timezone'],
                },
                2
            )

            const newRows = response.result.results.map((row) => {
                row = dot.dot(row)
                row['@@actions@@'] = this.renderActionsColumn(row)
                return row
            })

            this.setState({
                rows: this.state.rows.concat(newRows),
                loading: false,
                next: response.result.hasNext ? response.result.next : false,
                totalRowCount: response.count,
            })
        } catch (error) {
            this.props.onDataFetchError(error)
        }
    }

    async handleRowChange(rows, {column, indexes}) {
        if (this.state.loading) return // prevents handleRowChange from being fired when syncing data
        this.setState({loading: true})

        try {
            let obj = dot.object(cloneDeep(rows[indexes[0]]))
            delete obj['@@actions@@']
            obj = await API.patch(
                `data-models/${this.props.dataModelId}/edit-record`,
                obj,
                2
            )
            obj = dot.dot(obj)
            obj['@@actions@@'] = this.renderActionsColumn(obj)
            rows[indexes[0]] = obj
            this.setState({rows})
        } catch (error) {
            this.props.onEditError(error)
        }

        this.setState({loading: false})
    }

    async archiveSelectedRows() {
        this.setState({loading: true})
        let arr = Array.from(this.state.selectedRows)
        try {
            await Promise.all(
                arr.map((_id) => {
                    API.remove(
                        `data-models/${this.props.dataModelId}/delete-record/${_id}`,
                        2
                    )
                })
            )
            this.setState({
                rows: this.state.rows.filter((row) => !arr.includes(row._id)),
                selectedRows: new Set(),
            })
        } catch (error) {
            this.props.onArchiveError(error)
        }
        this.setState({loading: false})
    }

    async fetchFieldTypes() {
        this.setState({
            fieldTypes: await API.get('data-models/field-types', 2),
        })
    }

    handleFileChange({target: {files}}) {
        const cancel = !files.length
        if (cancel) return

        const [{size, name}] = files
        const maxSize = 50000

        if (size < maxSize) {
            this.setState({fileName: name, invalidFile: false})
        } else {
            this.setState({fileName: '', invalidFile: true})
        }
    }

    async openForm(row = undefined) {
        this.setState({loading: true})
        try {
            const formValidator = await API.get(
                `data-models/${this.props.dataModelId}/validator`,
                2
            )
            if (row) {
                row = cloneDeep(dot.object(row))
                delete row['@@actions@@']
            }
            this.setState({
                formValidator,
                formModalOpen: true,
                formModalRow: row,
            })
        } catch (error) {
            this.props.onGetValidatorError(error)
        }
        this.setState({loading: false})
    }

    async openUploader(row = undefined) {
        this.setState({loading: true})
        this.setState({
            formModalUpload: true,
        })
        this.setState({loading: false})
    }

    closeForm() {
        this.fetchData(true)
        this.setState({
            formModalOpen: false,
            formModalUpload: false,
        })
    }

    async componentDidMount() {
        this.fetchFieldTypes()
        this.fetchData()
    }

    render() {
        return (
            <div style={{height: this.height, width: this.props.width}}>
                <Menu id="data-grid-header-context-menu">
                    {this.props.permissions.sort ? (
                        <Submenu label="Sort">
                            <Item
                                onClick={this.headerMenuActions.sort.ascending}>
                                Ascending
                            </Item>
                            <Item
                                onClick={
                                    this.headerMenuActions.sort.descending
                                }>
                                Descending
                            </Item>
                        </Submenu>
                    ) : null}
                    {this.props.permissions.filter ? (
                        <Item onClick={this.headerMenuActions.filter}>
                            Filter...
                        </Item>
                    ) : null}
                </Menu>
                <Menu
                    id="data-grid-row-drawer-menu"
                    onClick={(e) => e.stopPropagation()}>
                    {this.state.contextTable}
                </Menu>

                <Menu
                    id="data-grid-reference-menu"
                    onClick={(e) => e.stopPropagation()}>
                    {this.state.referenceTable}
                </Menu>

                <Row className="mb-1">
                    <Col xs="6">
                        {this.props.permissions.create ? (
                            <>
                                <Button
                                    size="xs"
                                    color="success"
                                    onClick={(e) => this.openForm()}>
                                    <i className="simple-icon-plus" /> Add Row
                                </Button>{' '}
                                <Button
                                    size="xs"
                                    color="primary"
                                    onClick={(e) => this.openUploader()}>
                                    <i className="simple-icon-plus" /> Upload
                                    Rows
                                </Button>{' '}
                                <FileDownload
                                    element={
                                        <Button size="xs" color="danger">
                                            <i className="simple-icon-plus" />{' '}
                                            Download Template
                                        </Button>
                                    }
                                    columns={this.dataModel.fields.map(
                                        (f) => f.name
                                    )}
                                />
                            </>
                        ) : null}
                        {this.state.selectedRows.size ? (
                            <Button
                                className="ml-2"
                                size="xs"
                                color="danger"
                                onClick={this.archiveSelectedRows}>
                                Archive Row
                                {this.state.selectedRows.size === 1 ? '' : 's'}
                            </Button>
                        ) : null}
                    </Col>
                    <Col xs="6" className="text-right">
                        {this.props.permissions.sort &&
                        this.state.sort &&
                        this.state.sort.columnKey ? (
                            <Button
                                className="mr-2"
                                size="xs"
                                color="default"
                                onClick={() => {
                                    this.setState(
                                        {
                                            next: undefined,
                                            sort: {},
                                        },
                                        () => {
                                            this.makeColumns()
                                            this.fetchData(true)
                                            this.props.onSortChange(
                                                this.state.sort
                                            )
                                        }
                                    )
                                }}>
                                Clear Sorting
                            </Button>
                        ) : null}
                        {this.props.permissions.filter ? (
                            <Button
                                className="mr-2"
                                size="xs"
                                color="default"
                                onClick={this.headerMenuActions.filter}>
                                <i className="iconsmind-Filter-2" />
                                {this.state.filters.length} filter
                                {this.state.filters.length === 1 ? '' : 's'}
                            </Button>
                        ) : null}

                        <Button
                            color="default"
                            className="mr-2"
                            size="xs"
                            onClick={(e) => this.fetchData(true)}>
                            <i className="simple-icon-refresh" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataGrid
                            className="rdg-light"
                            columns={this.columns}
                            rows={this.state.rows}
                            onRowsChange={this.handleRowChange}
                            onScroll={({target}) => {
                                if (
                                    target.clientHeight + target.scrollTop >=
                                    target.scrollHeight
                                ) {
                                    this.fetchData()
                                }
                            }}
                            selectedRows={this.state.selectedRows}
                            onSelectedRowsChange={(selectedRows) =>
                                this.setState({selectedRows})
                            }
                            rowKeyGetter={({_id}) => _id}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-muted text-center">
                        Showing {this.state.rows.length} of{' '}
                        {this.state.totalRowCount} rows
                        <br />
                        Scroll to the bottom to load more
                    </Col>
                </Row>

                {this.state.loading && !this.state.ready ? (
                    <>Loading data...</>
                ) : null}
                {this.state.loading ? <div className="loading" /> : null}

                {this.state.filterModalOpen ? (
                    <FilterModal
                        open={true}
                        toggle={() =>
                            this.setState({
                                filterModalOpen: !this.state.filterModalOpen,
                            })
                        }
                        dataModel={this.dataModel}
                        dataModels={this.props.dataModels}
                        updateFilters={(filters) => {
                            this.setState({filters}, () => {
                                this.props.onFilterChange(filters)
                                this.fetchData(true)
                                setTimeout(() => this.fetchData(true), 250)
                            })
                        }}
                        filters={this.state.filters}
                        refresh={() => {}}
                        fieldTypes={this.state.fieldTypes}
                    />
                ) : null}

                {this.state.formModalOpen ? (
                    <Modal
                        size="lg"
                        isOpen={true}
                        toggle={() =>
                            this.setState({
                                formModalOpen: !this.state.formModalOpen,
                            })
                        }>
                        <ModalHeader
                            toggle={() =>
                                this.setState({
                                    formModalOpen: !this.state.formModalOpen,
                                })
                            }>
                            {this.state.formModalRow ? 'Edit' : 'Add'}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                schema={this.state.formValidator}
                                dataModel={this.dataModel}
                                dataModels={this.props.dataModels}
                                editingRecord={this.state.formModalRow}
                                onSubmit={this.closeForm}
                            />
                        </ModalBody>
                    </Modal>
                ) : null}

                {this.state.formModalUpload ? (
                    <Modal
                        size="lg"
                        isOpen={true}
                        toggle={() =>
                            this.setState({
                                formModalUpload: !this.state.formModalUpload,
                            })
                        }>
                        <ModalHeader
                            toggle={() =>
                                this.setState({
                                    formModalUpload:
                                        !this.state.formModalUpload,
                                })
                            }>
                            Upload Data Into Accumine
                        </ModalHeader>
                        <ModalBody>
                            <FileUpload
                                dataModel={this.dataModel}
                                close={this.closeForm}
                            />
                        </ModalBody>
                    </Modal>
                ) : null}
            </div>
        )
    }
}

export default DataViewer
