import React, {Fragment, Component} from 'react'
import {
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap'
import autobind from 'auto-bind'
import SortableTree, {getTreeFromFlatData} from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import _ from 'lodash'

import AddFieldModal from './modals/add'
import EditFieldModal from './modals/edit'

export default class ColumnEditor extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            treeData: this.fieldsToTree(),
            addFieldModalOpen: false,
            editFieldModalOpen: false,
            editingFieldId: null,
            parentId: undefined,
            editingSubModel: null,
        }
    }

    toggleAddFieldModalOpen(field) {
        let editingSubModel = null

        if (
            field &&
            field._field &&
            (field._field.type === 'MultiSubModel' ||
                field._field.type === 'SingleSubModel')
        ) {
            editingSubModel = this.props.dataModels.find(
                (m) => m.parentFieldId === field._id
            )
            if (editingSubModel === undefined)
                return alert('Cannot find sub-model record.')
        }

        this.setState({
            addFieldModalOpen: !this.state.addFieldModalOpen,
            editingSubModel,
        })
    }

    toggleEditFieldModalOpen(field) {
        this.setState({
            editFieldModalOpen: !this.state.editFieldModalOpen,
            editingFieldId: field && field._id ? field._id : null,
            editingSubModel:
                field && field._id
                    ? this.props.dataModels.find(
                          (m) => m._id === field.dataModelId
                      )
                    : null,
        })
    }

    toggleItemDropdown(_id) {
        if (this.state.openDropdown === _id) {
            this.setState({openDropdown: null})
        } else {
            this.setState({openDropdown: _id})
        }
    }

    fieldsToTree() {
        let fields = []

        for (let dataModel of this.props.dataModels) {
            fields = fields.concat(
                dataModel.fields
                    .filter((f) => !f.archived)
                    .map((f) => {
                        const parentFieldId = dataModel.parentFieldId || null
                        return {
                            _id: f._id,
                            title:
                                f.name +
                                ' (' +
                                (f.type === 'ForeignID' ? 'Link' : f.type) +
                                ')',
                            subtitle:
                                (f.protectedField ? '(protected) ' : '') +
                                (f.name === this.props.kanbanFieldPath
                                    ? '(Kanban Status) '
                                    : '') +
                                f.description,
                            _field: f,
                            expanded: true,
                            parentFieldId: parentFieldId,
                            dataModelId: dataModel._id,
                        }
                    })
            )
        }

        fields = fields.filter(
            (f) =>
                (f.parentFieldId === null &&
                    f.dataModelId === this.props.editingDataModel._id) ||
                f.parentFieldId !== null
        )

        fields = getTreeFromFlatData({
            flatData: fields,
            getKey: (node) => node._id,
            getParentKey: (node) => node.parentFieldId,
            rootKey: null,
        })

        return fields
    }

    treeHeight() {
        return 500 //this.props.editingDataModel.fields.length >= 1 ? this.props.editingDataModel.fields.filter(f => f.archived === false).length * 100 : 150;
    }

    showAddSubFieldOption(node) {
        return (
            node._field.type === 'SingleSubModel' ||
            node._field.type === 'MultiSubModel'
        )
    }

    canEditField(node) {
        const modelType = this.props.editingDataModel.modelType

        if (node._field.protectedField === true) {
            return false
        } else if (modelType === 'Static') {
            return true
        } else if (modelType === 'Event' && node._field.name === '_timestamp') {
            return false
        } else if (
            modelType === 'State' &&
            (node._field.name === '_timeStart' ||
                node._field.name === '_timeEnd')
        ) {
            return false
        } else {
            return true
        }
    }

    loadWarnings() {
        const model = this.props.editingDataModel
        let warnings = []

        if (!model || !model.warnings) {
            return warnings
        }

        for (let warning of model.warnings) {
            warnings.push(
                <Row className="mb-4">
                    <Col className="xs-12" style={{color: '#fff'}}>
                        <Card color="danger">
                            <CardBody>
                                <div style={{display: 'inline-block'}}>
                                    <i
                                        className="iconsmind-Information mr-2"
                                        style={{
                                            fontSize: 30,
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                        }}>
                                        {warning.warning}
                                    </span>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        }

        return warnings
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !_.isEqual(prevProps.dataModels, this.props.dataModels) ||
            !_.isEqual(prevProps.editingDataModel, this.props.editingDataModel)
        ) {
            this.setState({
                treeData: this.fieldsToTree(),
            })
        }
    }

    render() {
        return (
            <Fragment>
                {this.loadWarnings()}

                <Row>
                    <Col xs="8">
                        <h1 className="mb-0">Data Fields</h1>
                        <h4 className="text-muted">
                            Define the data fields of your data model
                        </h4>
                    </Col>
                    <Col xs="4" className="text-right">
                        {!this.props.editingDataModel.archived ? (
                            <Button
                                size="xs"
                                color="primary"
                                onClick={() => {
                                    this.toggleAddFieldModalOpen()
                                }}>
                                Add Field/Sub-Model
                            </Button>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{height: this.treeHeight()}}>
                            <SortableTree
                                canDrag={false}
                                treeData={this.state.treeData}
                                onChange={(treeData) =>
                                    this.setState({treeData})
                                }
                                generateNodeProps={({node, path}) => ({
                                    style: {},
                                    buttons: [
                                        <ButtonDropdown
                                            key={node._field._id}
                                            size="xs"
                                            isOpen={
                                                this.state.openDropdown ===
                                                node._field._id
                                            }
                                            toggle={() => {
                                                this.toggleItemDropdown(
                                                    node._field._id
                                                )
                                            }}>
                                            <DropdownToggle caret>
                                                Actions
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.showAddSubFieldOption(
                                                    node
                                                ) ? (
                                                    <>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.toggleAddFieldModalOpen(
                                                                    node
                                                                )
                                                            }}>
                                                            Add field
                                                        </DropdownItem>

                                                        <DropdownItem divider />
                                                    </>
                                                ) : null}

                                                {this.canEditField(node) ? (
                                                    <>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.toggleEditFieldModalOpen(
                                                                    node
                                                                )
                                                            }}>
                                                            Edit Field
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.props.handleArchiveColumn(
                                                                    {
                                                                        fieldId:
                                                                            node
                                                                                ._field
                                                                                ._id,
                                                                        dataModelId:
                                                                            node.dataModelId,
                                                                    }
                                                                )
                                                            }}>
                                                            Archive
                                                        </DropdownItem>
                                                    </>
                                                ) : null}
                                            </DropdownMenu>
                                        </ButtonDropdown>,
                                    ],
                                })}
                            />
                        </div>
                        <hr />
                    </Col>
                </Row>
                {this.state.addFieldModalOpen ? (
                    <AddFieldModal
                        toggle={this.toggleAddFieldModalOpen}
                        parentId={this.state.parentId}
                        editingSubModel={this.state.editingSubModel}
                        open={true}
                        {...this.props}
                    />
                ) : null}
                {this.state.editFieldModalOpen ? (
                    <EditFieldModal
                        toggle={this.toggleEditFieldModalOpen}
                        editingFieldId={this.state.editingFieldId}
                        editingSubModel={this.state.editingSubModel}
                        open={true}
                        {...this.props}
                    />
                ) : null}
            </Fragment>
        )
    }
}
