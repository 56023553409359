import React, {Fragment, Component} from 'react'
import {Row, Col} from 'reactstrap'
import autobind from 'auto-bind'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'

import FormulaInput from '../../../common/FormulaInput/index'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        let initial = {
            input: moment().add(-1, 'days').startOf('day').valueOf(),
        }

        if (props) {
            initial = {
                input:
                    props.value ||
                    moment().add(-1, 'days').startOf('day').valueOf(),
            }
        }

        this.state = {
            input: initial.input,
        }
    }

    handleChange(value) {
        if (value.diff(moment()) > 0) {
            return alert('Your selection cannot be in the future.')
        }

        this.setState(
            {
                input: value.valueOf(),
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.input)
                }
            }
        )
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12">
                        {this.props.formulas && this.props.variables ? (
                            <FormulaInput
                                variables={this.props.variables}
                                formulas={this.props.formulas}
                                type="formula"
                                onChange={(input) => {
                                    input.type = 'formula'
                                    this.props.onChange(input)
                                }}
                                default={this.props.defaultFormulaInput}
                            />
                        ) : (
                            <Datetime
                                value={moment(
                                    parseInt(this.state.input)
                                ).toDate()}
                                onChange={this.handleChange}
                            />
                        )}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
