import dot from 'dot-object'
import _ from 'lodash'
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap'
import {inputs, transforms, outputs} from './elements'

const missingConnectionsValidator = (elements) => {
    let errors = []
    for (let el of elements.filter((a) => !a.source)) {
        if (el.data.nodeType === 'input') {
            if (!elements.find((a) => a.source === el.id)) {
                errors.push({
                    transformId: null, //el.id,
                    message: `Element "${el.data.label}" is missing an outgoing connection.`,
                })
            }
        } else if (el.data.nodeType === 'default') {
            if (!elements.find((a) => a.target === el.id)) {
                errors.push({
                    transformId: null, //el.id,
                    message: `Element "${el.data.label}" is missing an incoming connection.`,
                })
            }
        } else if (el.data.nodeType === 'output') {
            if (!elements.find((a) => a.target === el.id)) {
                errors.push({
                    transformId: null, // el.id,
                    message: `Element "${el.data.label}" is missing an incoming connection.`,
                })
            }
        }
    }
    return errors
}

const unsubmittedValidator = (elements) => {
    return elements
        .filter((el) => !el.source && !el.data.opts.submitted)
        .map((a) => {
            return {
                transformId: a.id,
                message: `Element "${a.data.label}" has never been configured.`,
            }
        })
}

const expressionValidator = (elements, variables) => {
    let elementObj = {},
        errors = []
    for (let el of elements) {
        elementObj[el.id] = el
    }

    for (let id in elementObj) {
        const flat = dot.dot(elementObj[id]),
            mentionsPath = Object.keys(flat)
                .filter((a) => a.endsWith('@@isExpression@@'))
                .map((a) => a.replace('@@isExpression@@', 'mentions'))

        for (let path of mentionsPath) {
            const mentions = dot
                .pick(path, elementObj[id])
                .filter((a) => !a.isFormula)
            for (let m of mentions) {
                const split = m.id.split('.'),
                    variable = variables.find((a) => a.id === split[0])
                if (!variable) {
                    // variable doesn't exist
                    errors.push({
                        transformId: elements.find((a) => a.id === id).id,
                        message: `An expression in "${
                            elements.find((a) => a.id === id).data.label
                        }" is using missing variable "${m.display}".`,
                    })
                } else {
                    if (variable.multi === true) {
                        errors.push({
                            transformId: elements.find((a) => a.id === id).id,
                            message: `An expression in "${
                                elements.find((a) => a.id === id).data.label
                            }" is using a list variable. Only single Records/Reports or values can be used in expressions.`,
                        })
                    }
                    if (split.length > 1) {
                        if (
                            !variable.fields.find(
                                (a) =>
                                    a.path ===
                                    split.filter((a, i) => i > 0).join('.')
                            )
                        ) {
                            // field doesn't exist
                            errors.push({
                                transformId: elements.find((a) => a.id === id)
                                    .id,
                                message: `An expression in "${
                                    elements.find((a) => a.id === id).data.label
                                }" is using missing variable path "${
                                    m.display
                                }".`,
                            })
                        }
                    }
                }
            }
        }
    }
    return errors
}

const uniqueNameValidator = (elements, variables) => {
    let errors = []

    const elementNames = elements
            .filter((el) => el.type !== 'step')
            .map((el) => el.data.opts.name || el.data.label),
        variableNames = variables.map((v) => v.name),
        duplicateElementNames = _.filter(elementNames, (val, i, iteratee) =>
            _.includes(iteratee, val, i + 1)
        ),
        duplicateVariableNames = _.filter(variableNames, (val, i, iteratee) =>
            _.includes(iteratee, val, i + 1)
        )
    for (let duplicate of duplicateElementNames) {
        const els = elements.filter(
            (el) => (el.data.opts.name || el.data.label) === duplicate
        )
        for (let el of els) {
            errors.push({
                transformId: el.id,
                message: `Element "${
                    el.data.opts.name || el.data.label
                }" is using an existing name`,
            })
        }
    }
    for (let duplicate of duplicateVariableNames) {
        const vars = variables.filter((el) => el.data.opts.name === duplicate)
        for (let v of vars) {
            errors.push({
                transformId: null,
                message: `Variable "${variable.name}" is using an existing name`,
            })
        }
    }
    return errors
}

export const validator = (elements, variables) => {
    let errors = []
    errors = errors.concat(expressionValidator(elements, variables))
    errors = errors.concat(unsubmittedValidator(elements))
    errors = errors.concat(missingConnectionsValidator(elements))
    errors = errors.concat(uniqueNameValidator(elements, variables))

    for (let input of inputs) {
        if (
            input.saveValidator &&
            input.saveValidator.constructor === Function
        ) {
            errors = errors.concat(
                input.saveValidator(
                    elements.filter((el) => el.data.opts.submitted),
                    variables
                )
            )
        }
    }
    for (let transform of transforms) {
        if (
            transform.saveValidator &&
            transform.saveValidator.constructor === Function
        ) {
            errors = errors.concat(
                transform.saveValidator(
                    elements.filter((el) => el.data.opts.submitted),
                    variables
                )
            )
        }
    }
    for (let output of outputs) {
        if (
            output.saveValidator &&
            output.saveValidator.constructor === Function
        ) {
            errors = errors.concat(
                output.saveValidator(
                    elements.filter((el) => el.data.opts.submitted),
                    variables
                )
            )
        }
    }

    return errors
}

export const ValidatorModal = ({errors = [], toggle}) => {
    return (
        <Modal size="lg" toggle={toggle} isOpen={true}>
            <ModalHeader toggle={toggle}>Flow Validator Output</ModalHeader>
            <ModalBody>
                {errors.length === 0 ? (
                    <h5>No errors found!</h5>
                ) : (
                    <>
                        {errors.map((e) => {
                            return (
                                <>
                                    <p>{e.message}</p>
                                    <hr />
                                </>
                            )
                        })}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}
