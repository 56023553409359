import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'
import CustomSelectInput from 'components/CustomSelectInput'
import autobind from 'auto-bind'
import Select from 'react-select'
import * as API from 'SDK/api'
import {getAssets} from 'SDK/api/common'
import Subscriber from 'SDK/subscriber'
import FlowTile from './flow-tile'
import ConfigurationModal from './configurationModal'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)
        this.subscriber = new Subscriber()

        this.state = {
            selectedAsset: props.history.location.state
                ? props.history.location.state.device
                : null,
            flows: [],
            addingNew: false,
            showConfigurationModal: false,
            mixedInputFlowsModalOpen: false,
            selectedFlow: null,
            mixedInputFlows: [],
        }
    }

    toggleConfigurationModal(flowObject) {
        this.setState({
            showConfigurationModal: !this.state.showConfigurationModal,
            selectedFlow: flowObject,
        })
    }

    async fetchFlows() {
        let flows = await API.get('flows', 2),
            devices = await API.get('devices?all=true'),
            nodes = await API.get('nodes', 2)

        flows = flows.filter((a) =>
            devices.find((b) => b.deviceId === a.deviceId)
        )
        let mixedInputFlows = []
        for (let flow of flows) {
            let inputs = flow.template.filter((a) => a.transform === '$input'),
                flowDevices = [],
                flowNodes = [],
                flowOrphanInputs = [],
                mixedInputFlow = {
                    observations: [],
                    title: `${
                        devices.find((a) => a.deviceId === flow.deviceId).name
                    } - ${flow.name}`,
                }
            for (let input of inputs) {
                const [id, name] = input.params.a.split(':'),
                    device = devices.find((a) => a.deviceId === id),
                    node = nodes.find(
                        (a) => a.nodeId === id && a.nodeType === 'ARD'
                    )
                if (!device && !node) {
                    flowOrphanInputs.push({id, name})
                }
                if (
                    device &&
                    !flowDevices.find((a) => a.deviceId === device.deviceId)
                ) {
                    flowDevices.push({...device, $inputName: name})
                }
                if (node && !flowNodes.find((a) => a.nodeId === node.nodeId)) {
                    flowNodes.push({...node, $inputName: name})
                }
                if (node) {
                    const pin = node.settings.pins.find(
                            (a) => a.pinId === name
                        ),
                        serializedElement = flow.serialized.nodes.find(
                            (a) => a.extras.nodeId === input.id
                        )
                    if (
                        pin &&
                        (pin.pinId !== serializedElement.extras.aliasedName ||
                            pin.nickname !== serializedElement.name)
                    ) {
                        mixedInputFlow.observations.push(
                            `Flow Input -- ${serializedElement.name} -- was moved across pins. Please re-add affected input to flow.`
                        )
                    }
                    const aliases = node.settings.pins.filter(
                        (a) => a.nickname === serializedElement.name
                    )
                    if (aliases.length > 1) {
                        mixedInputFlow.observations.push(
                            `Flow Input -- ${
                                serializedElement.name
                            } -- has multiple pin sources: ${aliases
                                .map((a) => a.pinId)
                                .join(
                                    ', '
                                )}. Please re-add affected input to flow.`
                        )
                    }
                }
            }
            if (flowDevices.length === 0 && flowNodes.length === 0) {
                mixedInputFlow.observations.push('Flow has no inputs')
            }
            for (let orphanInput of flowOrphanInputs) {
                mixedInputFlow.observations.push(
                    `Flow Input -- ${orphanInput.name} -- is not associated with any asset or SensorBot (ID: ${orphanInput.id})`
                )
            }
            const otherDevices = flowDevices.filter(
                (a) => a.deviceId !== flow.deviceId
            )
            for (let otherDevice of otherDevices) {
                mixedInputFlow.observations.push(
                    `Flow Input -- ${otherDevice.$inputName} -- from other asset: ${otherDevice.name} (Device ID: ${otherDevice.deviceId})`
                )
            }
            const otherNodes = flowNodes.filter(
                (a) => a.deviceId !== flow.deviceId
            )
            for (let otherNode of otherNodes) {
                if (otherNode.deviceId === 'none') {
                    mixedInputFlow.observations.push(
                        `Flow Input -- ${otherNode.$inputName} -- from SensorBot not associated with any asset:
             Node ID: ${otherNode.nodeId}`
                    )
                } else {
                    const device = devices.find(
                        (a) => a.deviceId === otherNode.deviceId
                    )
                    mixedInputFlow.observations.push(
                        `Flow Input -- ${
                            otherNode.$inputName
                        } -- from SensorBot associated with other asset:
             Node ID: ${otherNode.nodeId}
             (Asset: ${device ? device.name : 'deleted asset'})`
                    )
                }
            }
            if (mixedInputFlow.observations.length > 0) {
                mixedInputFlows.push(mixedInputFlow)
            }
        }
        this.setState({flows, mixedInputFlows})
    }
    handleAssetSelection(selectedAsset) {
        this.setState({
            selectedAsset,
            addingNew: false,
            name: '',
            color: '#d7d7d7',
        })
    }
    addNewFlow() {
        this.setState({addingNew: true})
    }
    editNewFlow() {
        if (this.state.name !== '')
            this.props.history.push(
                '/app/core/flow-editor/' +
                    this.state.selectedAsset.value +
                    '/new?name=' +
                    this.state.name
            )
    }
    editExisting(flowId) {
        this.props.history.push(
            '/app/core/flow-editor/' +
                this.state.selectedAsset.value +
                '/' +
                flowId
        )
    }
    addNewFlowTile() {
        return (
            <Col xs="12" sm="4" lg="3">
                <div className="icon-row-item">
                    <Card className="mb-4" onClick={this.addNewFlow}>
                        <CardBody className="text-left">
                            {!this.state.addingNew ? (
                                <p className="lead text-center">
                                    <i className="iconsmind-Add" />
                                </p>
                            ) : (
                                <Fragment>
                                    <label>
                                        What should we call this flow?
                                    </label>
                                    <InputGroup>
                                        <Input
                                            style={{
                                                borderColor:
                                                    this.state
                                                        .nameInputBorderColor,
                                            }}
                                            type="text"
                                            name="flowName"
                                            value={this.state.name}
                                            onChange={(name) => {
                                                let v = name.target.value,
                                                    color = '#145388',
                                                    valid = true,
                                                    message = ''
                                                if (v === '') {
                                                    color = '#dc3545'
                                                    valid = false
                                                } else if (
                                                    this.state.flows.find(
                                                        (f) =>
                                                            f.name === v &&
                                                            f.deviceId ===
                                                                this.state
                                                                    .selectedAsset
                                                    )
                                                ) {
                                                    color = '#dc3545'
                                                    valid = false
                                                    message =
                                                        'A flow with this name already exists.'
                                                }
                                                this.setState({
                                                    name: v,
                                                    valid: valid,
                                                    nameInputBorderColor: color,
                                                    message: message,
                                                })
                                            }}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <Button
                                                disabled={!this.state.valid}
                                                outline
                                                color="primary"
                                                onClick={this.editNewFlow}>
                                                Go
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Fragment>
                            )}
                        </CardBody>
                    </Card>
                </div>
            </Col>
        )
    }

    toggleMixedInputFlowsModal() {
        this.setState({
            mixedInputFlowsModalOpen: !this.state.mixedInputFlowsModalOpen,
        })
    }

    async componentWillMount() {
        const assets = await getAssets()
        this.setState({
            assets: assets.map((a) => {
                return {label: a.name, value: a.deviceId}
            }),
        })
        this.fetchFlows()
        this.subscriber.add(this.fetchFlows, 1000 * 10, 'fetchFlows()')
    }

    render() {
        return (
            <Fragment>
                {this.state.mixedInputFlows.length > 0 ? (
                    <Button
                        block
                        color="danger"
                        onClick={this.toggleMixedInputFlowsModal}
                        className="mb-2">
                        See Active Warnings
                    </Button>
                ) : null}
                {this.state.mixedInputFlowsModalOpen ? (
                    <Modal
                        isOpen={true}
                        toggle={this.toggleMixedInputFlowsModal}>
                        <ModalHeader toggle={this.toggleMixedInputFlowsModal}>
                            Active Warnings
                        </ModalHeader>
                        <ModalBody style={{userSelect: 'text'}}>
                            {this.state.mixedInputFlows.map((a) => {
                                return (
                                    <Row key={Math.random()}>
                                        <Col>
                                            <h5>
                                                <strong>{a.title}</strong>
                                            </h5>
                                            <ul>
                                                {a.observations.map((b) => (
                                                    <li key={Math.random()}>
                                                        {b}
                                                    </li>
                                                ))}
                                            </ul>
                                            <hr />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </ModalBody>
                    </Modal>
                ) : null}
                <Select
                    components={{Input: CustomSelectInput}}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="output"
                    value={this.state.selectedAsset}
                    onChange={this.handleAssetSelection}
                    options={this.state.assets}
                />

                <Row className="mt-4 icon-cards-row">
                    {this.state.selectedAsset ? (
                        <>
                            {this.state.flows
                                .filter(
                                    (flow) =>
                                        flow.deviceId ===
                                        this.state.selectedAsset.value
                                )
                                .map((flow, i) => {
                                    return (
                                        <FlowTile
                                            key={i}
                                            handleClickEvent={this.editExisting}
                                            handleConfigurationClickEvent={
                                                this.toggleConfigurationModal
                                            }
                                            flow={flow}
                                        />
                                    )
                                })}
                            {this.addNewFlowTile()}
                        </>
                    ) : null}
                </Row>
                <ConfigurationModal
                    modal={this.state.showConfigurationModal}
                    toggleModal={this.toggleConfigurationModal}
                    flow={this.state.selectedFlow}
                />
            </Fragment>
        )
    }
}
