import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Label,
    Form,
    Input,
} from 'reactstrap'
import autobind from 'auto-bind'
import moment from 'moment'
import PropTypes from 'prop-types'
import CustomSelectInput from 'components/CustomSelectInput'
import IntlMessages from 'util/IntlMessages'

import * as API from 'SDK/api'
import Subscriber from 'SDK/subscriber'

export default class ScrapEntryModule extends Component {
    propComponents = [
        {
            prop: 'name',
            component: 'GenericWidgetName',
        },
        {
            prop: 'deviceId',
            component: 'AssetPicker',
        },
    ]
    showBorder = false
    id = 'ScrapEntryModule'
    requiredOutputs = ['Part Count', 'In-Cycle', 'Reject']
    static propTypes = {
        deviceId: PropTypes.string,
        name: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            value: 0,
            message: '',
        }
    }

    async submit() {
        this.setState({message: 'Submitting rejects...'})

        const activeRecords = await API.post(
            'historical/raw',
            {
                query: {
                    deviceId: this.props.deviceId,
                    name: {$in: ['In-Cycle', 'Reject']},
                    timeEnd: null,
                },
            },
            2
        )

        if (
            !activeRecords ||
            !activeRecords.find((x) => x.name === 'In-Cycle')
        ) {
            this.setState({
                message:
                    'No active run found! Please set rejects historically.',
            })
            let self = this
            setTimeout(() => {
                self.setState({message: ''})
            }, 5000)
            return
        } else {
            if (activeRecords.find((x) => x.name === 'Reject')) {
                // update existing
                let record = activeRecords.find((x) => x.name === 'Reject')
                record.value = record.value + parseInt(this.state.value)
                try {
                    await API.patch('states/' + record._id, record, 2)
                    this.setState({
                        message: 'Successfully submitted!',
                        value: 0,
                    })
                    let self = this
                    setTimeout(() => {
                        self.setState({message: ''})
                    }, 5000)
                } catch (error) {
                    console.log(error)
                    this.setState({
                        message: 'Could not submit! Please try again!',
                    })
                }
            } else {
                // make new
                const timestamp = moment(
                    activeRecords.find((x) => x.name === 'In-Cycle').timeStart
                ).toDate()
                try {
                    await API.post(
                        'states',
                        {
                            nodeId: this.props.deviceId,
                            deviceId: this.props.deviceId,
                            name: 'Reject',
                            timestamp: timestamp,
                            timeStart: timestamp,
                            timeEnd: timestamp,
                            value: parseInt(this.state.value),
                            metaData: [],
                        },
                        2
                    )
                    this.setState({
                        message: 'Successfully submitted!',
                        value: 0,
                    })
                    let self = this
                    setTimeout(() => {
                        self.setState({message: ''})
                    }, 5000)
                } catch (error) {
                    console.log(error)
                    this.setState({
                        message: 'Could not submit! Please try again!',
                    })
                }
            }
        }
    }

    async componentWillMount() {
        const devices = await API.get('devices')
        this.setState({
            name: devices.find((d) => d.deviceId === this.props.deviceId).name,
        })
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center">
                                    <strong>
                                        {this.state.name} - Reject Entry
                                    </strong>
                                </CardTitle>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Label className="form-group has-top-label">
                                                <Input
                                                    type="number"
                                                    name="reject"
                                                    value={this.state.value}
                                                    onChange={(value) => {
                                                        if (
                                                            value.target.value <
                                                            0
                                                        )
                                                            return
                                                        this.setState({
                                                            value: value.target
                                                                .value,
                                                        })
                                                    }}
                                                />
                                                <IntlMessages id="Reject Amount" />
                                            </Label>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row className="text-center">
                                    <Col>
                                        <Button
                                            className="btn-block"
                                            onClick={this.submit}
                                            disabled={this.state.value == 0}>
                                            Submit
                                        </Button>
                                        <p>{this.state.message}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
