import InteractiveTimeline from './InteractiveTimeline'
import SummaryDisplayTile from './SummaryDisplayTile'
import RealTimeWorkOrderStatusTile from './RealTimeWorkOrderStatusTile'
import WorkOrderLog from './WorkOrderLog'
import WorkOrderInsights from './WorkOrderInsights'
import OEEView from './OEEView'
import Map from './Map'
import DowntimeEntryModule from './DowntimeEntryModule'
import LightsOutModule from './LightsOutModule'
import DowntimeHistoryModule from './DowntimeHistoryModule'
import StaticTitleText from './StaticTitleText'
import StaticSectionText from './StaticSectionText'
import ScrapEntryModule from './ScrapEntryModule'
import ScrapHistoryModule from './ScrapHistoryModule'
import RealTimeHourlyActual from './RealTimeHourlyActual'
import StaticHourlyActualTile from './StaticHourlyActualTile'
import TVDisplay from './TVDisplay'
import DisplayConfiguration from './DisplayConfiguration'
import DowntimeDisplay from './DowntimeDisplay'
import ProductionDisplay from './ProductionDisplay'
import UtilizationDisplay from './UtilizationDisplay'
import AssetManager from './AssetManager'
import GatewayManager from './GatewayManager'
import ReasonCodeManager from './ReasonCodeManager'
import SensorBotManager from './SensorBotManager'
import ShiftManager from './ShiftManager'
import PartManager from './PartManager'
import UserManager from './UserManager'
import DowntimeReport from './DowntimeReport'
import InsightEngine from './InsightEngine'
import PartReport from './PartReport'
import DataExplorer from './DataExplorer'
import TimelineReport from './TimelineReport'
import UtilizationReport from './UtilizationReport'
import DataTables from './DataTables'
import DrilldownTree from './DrilldownTree'
import PermissionManager from './PermissionManager'
import RealTimeWorkOrderStatusBoard from './RealTimeWorkOrderStatusBoard'
import RealTimeWorkOrderStatusBoard2 from './RealTimeWorkOrderStatusBoard2'
import PartAdd from './PartAdd'
import PartSelect from './PartSelect'
import AssetQueue from './AssetQueue'
import ResourceManager from './ResourceManager'
import RealTimeDowntimeEntry from './RealTimeDowntimeEntry'
import StaticHourlyActualView from './StaticHourlyActualView'
import RealTimeWorkOrderStatusBoardView from './RealTimeWorkOrderStatusBoardView'
import RealTimeWorkOrderStatusBoard2View from './RealTimeWorkOrderStatusBoard2View'
import ProductionScheduler from './ProductionScheduler'
import ManualStates from './ManualStates'
import ManualStateManager from './ManualStateManager'
import MaintenanceKanban from './MaintenanceKanban'
import SimpleMaintenance from './SimpleMaintenance'
import SimplePreventativeMaintenance from './SimplePreventativeMaintenance'
import HourlyTargetActualDisplayWithTimeline from './HourlyTargetActualDisplayWithTimeline'
import IPSFieldDeviceManager from './IPS/IPSFieldDeviceManager'
import IPSModelManager from './IPS/IPSModelManager'
import IPSRealTimeMap from './IPS/IPSRealTimeMap'
import IPSCapacity from './IPS/IPSCapacity'
import IPSRouting from './IPS/IPSRouting'
import ParetoReport from './ParetoReport'
import ShiftScrapHistory from './ShiftScrapHistory'
import ScrapReport from './ScrapReport'
import SchemaManager from './Schemas/SchemaManager'
import SchemaViewer from './Schemas/SchemaViewer'
import ReportViewer from './Schemas/ReportViewer'
import HMI from './HMI'
import HMI2 from './HMI2'
import StaticHourlyActualDisplay from './StaticHourlyActualDisplay'
import ESeriesSensorBotManager from './ESeriesSensorBotManager'
import HubbellLenoirCityTable from './HubbellLenoirCityTable'
import HubbellLenoirCityCOOIS from './HubbellLenoirCityCOOIS'
import HubbellAikenHMI from './HubbellAikenHMI'
import HubbellAikenTables from './HubbellAikenTables'
import ScrapCodeManager from './ScrapCodeManager'
import ReworkCodeManager from './ReworkCodeManager'
import CustomHMIEntry from './CustomHMIEntry'
import ChangeoverReport from './ChangeoverReport'
import HubbellWadsworthHome from './HubbellWadsworth/Home'
import HubbellCentraliaGalvanizing from './HubbellCentraliaGalvanizing'
import HubbellMeramecCapping from './HubbellMeramecCapping'
import CMMSWorkflowTable from './CMMSWorkflowTable'
import MCSWorkOrderTable from './MCSWorkOrderTable'
import HubbellWorkOrderSummary from './HubbellWorkOrderSummary'
import TVDisplayCustom from './TVDisplayCustom'
import SchemaDataViewerFullAccess from './SchemaDataViewerFullAccess'
import SchemaCalendarViewerFullAccess from './SchemaCalendarViewerFullAccess'
import SchemaKanbanViewerFullAccess from './SchemaKanbanViewerFullAccess'
import OEEInteractiveTimeline from './OEEInteractiveTimeline'
import OEEInteractiveReport from './OEEInteractiveReport'
import FlowBuilder from './Schemas/SDK/FlowBuilder'
import RealTimeWorkOrderStatusView from './RealTimeWorkOrderStatusView'
import HubbellLeedConnectorsProductionDisplay from './HubbellLeedConnectorsProductionDisplay'
import HubbellLeedsConnectorsStaticHourlyActualView from './HubbellLeedsConnectorsStaticHourlyActualView'
import OEEEarnedHours from './OEEEarnedHours'
import HubbellAikenHMI2 from './HubbellAikenHMI2'
import HubbellAikenPDFReport from './HubbellAikenPDFReport'
import HubbellAikenEquipmentPerformanceReport from './HubbellAikenEquipmentPerformanceReport'

const widgets = [
    InteractiveTimeline,
    SummaryDisplayTile,
    RealTimeWorkOrderStatusTile,
    WorkOrderLog,
    WorkOrderInsights,
    OEEView,
    Map,
    DowntimeEntryModule,
    LightsOutModule,
    DowntimeHistoryModule,
    StaticTitleText,
    StaticSectionText,
    ScrapEntryModule,
    ScrapHistoryModule,
    StaticHourlyActualTile,
    RealTimeHourlyActual,
    TVDisplay,
    DisplayConfiguration,
    ProductionDisplay,
    DowntimeDisplay,
    UtilizationDisplay,
    AssetManager,
    GatewayManager,
    ReasonCodeManager,
    SensorBotManager,
    ShiftManager,
    PartManager,
    UserManager,
    DowntimeReport,
    InsightEngine,
    PartReport,
    DataExplorer,
    TimelineReport,
    UtilizationReport,
    DataTables,
    DrilldownTree,
    PermissionManager,
    RealTimeWorkOrderStatusBoard,
    RealTimeWorkOrderStatusBoard2,
    RealTimeWorkOrderStatusView,
    PartAdd,
    PartSelect,
    AssetQueue,
    ResourceManager,
    RealTimeDowntimeEntry,
    StaticHourlyActualView,
    RealTimeWorkOrderStatusBoardView,
    RealTimeWorkOrderStatusBoard2View,
    ProductionScheduler,
    ManualStates,
    ManualStateManager,
    SimpleMaintenance,
    SimplePreventativeMaintenance,
    MaintenanceKanban,
    HourlyTargetActualDisplayWithTimeline,
    IPSFieldDeviceManager,
    IPSModelManager,
    IPSRealTimeMap,
    IPSCapacity,
    IPSRouting,
    ParetoReport,
    ShiftScrapHistory,
    ScrapReport,
    SchemaManager,
    SchemaViewer,
    HMI,
    HMI2,
    StaticHourlyActualDisplay,
    ESeriesSensorBotManager,
    HubbellLenoirCityTable,
    HubbellLenoirCityCOOIS,
    HubbellAikenHMI,
    HubbellAikenTables,
    ScrapCodeManager,
    ReworkCodeManager,
    CustomHMIEntry,
    ChangeoverReport,
    HubbellWadsworthHome,
    HubbellCentraliaGalvanizing,
    HubbellMeramecCapping,
    CMMSWorkflowTable,
    MCSWorkOrderTable,
    HubbellWorkOrderSummary,
    ReportViewer,
    TVDisplayCustom,
    SchemaDataViewerFullAccess,
    SchemaCalendarViewerFullAccess,
    SchemaKanbanViewerFullAccess,
    OEEInteractiveTimeline,
    OEEInteractiveReport,
    FlowBuilder,
    HubbellLeedConnectorsProductionDisplay,
    HubbellLeedsConnectorsStaticHourlyActualView,
    OEEEarnedHours,
    HubbellAikenHMI2,
    HubbellAikenPDFReport,
    HubbellAikenEquipmentPerformanceReport,
]

export default widgets
