import React, {Component, Fragment} from 'react'
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Slider from 'rc-slider'
import autobind from 'auto-bind'
import CustomSelectInput from 'components/CustomSelectInput'

import 'rc-slider/assets/index.css'

import * as API from 'SDK/api'
import Select from 'react-select'

const OPTIONS = [
    {
        label: '⅓x gain',
        value: 99,
    },
    {
        label: '1x gain',
        value: 91,
    },
    {
        label: '2x gain',
        value: 76,
    },
    {
        label: '4x gain',
        value: 63,
    },
    {
        label: '8x gain',
        value: 49,
    },
    {
        label: '16x gain',
        value: 35,
    },
    {
        label: '32x gain',
        value: 21,
    },
    {
        label: '64x gain',
        value: 7,
    },
]

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.node = this.props.nodes.find(
            (node) => node.nodeId === this.props.editingNode.nodeId
        )

        this.originalVref = this.node.settings.vref.value
        this.state = {
            selected: OPTIONS.find((o) => o.value == this.originalVref),
            showActions: false,
            show: true,
        }
    }
    async save() {
        try {
            let node = {...this.node}
            node.settings.vref = {
                value: this.state.selected.value,
                pendingUpdate: true,
            }

            let response = await API.patch(
                'nodes/' + node.nodeId,
                {
                    settings: node.settings,
                },
                2
            )
            if (response.success) {
                this.setState({showActions: false})
                this.originalVref = this.state.selected.value
            } else {
                return alert(
                    'There was an error saving your change. Please try again.'
                )
            }
        } catch (error) {
            console.log(error)
            return alert(
                'There was an error saving your change. Please try again.'
            )
        }
    }
    handleChange(selected) {
        if (selected.value !== this.originalVref) {
            this.setState({
                showActions: true,
                selected,
            })
        } else {
            this.setState({showActions: false, selected})
        }
    }
    revert() {
        this.setState({
            show: false,
        })
        const self = this
        setTimeout(() => {
            self.setState({
                show: true,
            })
        }, 100)
    }

    render() {
        let {modal, toggleModal} = this.props
        return (
            <Fragment>
                <Row>
                    <Col>
                        <div className="text-center">
                            <h4 className="text-center">Gain</h4>
                            <small className="text-center text-muted">
                                Use this setting to amplify or dampen captured
                                data signals.
                            </small>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {this.state.show ? (
                            <Select
                                components={{Input: CustomSelectInput}}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="gain"
                                value={this.state.selected}
                                onChange={this.handleChange}
                                options={OPTIONS}
                            />
                        ) : null}
                    </Col>
                </Row>
                {this.state.showActions ? (
                    <Row className="mt-2">
                        <Col xs="12" className="text-right">
                            <Button
                                size="xs"
                                className="mr-1"
                                color="success"
                                onClick={this.save}>
                                Save
                            </Button>
                            {/*<Button size="xs" className="" color="danger" onClick={this.revert}>Revert</Button>*/}
                        </Col>
                    </Row>
                ) : null}
            </Fragment>
        )
    }
}
