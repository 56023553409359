import * as API from 'SDK/api'

export default class Asset {
    constructor({deviceId, name, assetImage, groups}) {
        this.sync({
            deviceId,
            name,
            assetImage,
            groups,
        })
    }

    sync({deviceId, name, assetImage, groups}) {
        this.deviceId = deviceId
        this.name = name
        this.assetImage = assetImage
        this.groups = groups
    }

    group() {
        return this.groups.length ? this.groups[0] : 'Unallocated'
    }

    async fetchHistoricalData({name, timeStart, timeEnd, shift}) {
        let obj = {
            deviceId: this.deviceId,
            state: name,
            timeStart: timeStart.toISOString(),
            timeEnd: timeEnd.toISOString(),
            unit: 'days',
        }

        if (shift) {
            obj.shift = shift
        }

        const {dates, duration, count, capacity, durationPercentage} =
            await API.post('historical', obj, 1)
        return {
            dates,
            duration,
            count,
            capacity,
            durationPercentage,
            name,
            shift,
            deviceId: this.deviceId,
        }
    }
}
