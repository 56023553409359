import React, {Component} from 'react'
import autobind from 'auto-bind'
import {logUserEvent} from 'SDK/api/common'

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.show = navigator.appVersion.includes('Raspbian')
    }

    render() {
        return (
            <div className="position-relative d-inline-block">
                <div className="dropdown-menu-right dropdown show">
                    {this.show ? (
                        <button
                            className="header-icon notificationButton btn btn-empty"
                            onClick={() => {
                                const password = prompt(
                                    'HMI Configuration Password (see HMI documentation)',
                                    ''
                                )
                                if (password === 'z8qb3b') {
                                    logUserEvent('HMI Configurator')
                                    window.location = 'http://127.0.0.1:3000'
                                } else {
                                    alert('Invalid password provided.')
                                }
                            }}>
                            <i className="simple-icon-settings"></i>
                        </button>
                    ) : null}
                </div>
            </div>
        )
    }
}
