import React, {Component, Fragment} from 'react'
import {Bar} from 'react-chartjs-2'
import autobind from 'auto-bind'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default class OEETimeSeriesChart extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            options: {
                maintainAspectRatio: true,
                responsive: false,
                title: {
                    display: true,
                    text: 'OEE %',
                },
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [
                        {
                            offset: true,
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: 100,
                            },
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: (val) => {
                            return parseFloat(val.toFixed(2))
                        },
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            },
        }
    }

    render() {
        return (
            <Bar
                data={this.props.data}
                options={this.state.options}
                height={500}
                width={this.props.elementWidth.offsetWidth - 40}
                plugins={[ChartDataLabels]}
            />
        )
    }
}
