import PropTypes from 'prop-types'
import React, {Fragment, useState, useEffect} from 'react'
import Select from 'react-select'
import {} from 'reactstrap'
import {RangeCalendar} from '@mantine/dates'
import {Modal, Accordion, Grid, Button, Text} from '@mantine/core'
import {showNotification} from '@mantine/notifications'
import {Excel} from './Excel'
import AccumineAppWrapper from '../Core/AccumineAppWrapper'
import MaterialAPI from './calls'
import Subscriber from 'SDK/subscriber'

const Material = ({selected, deviceId, deviceName, ...props}) => {
    const [isDisabled, setIsDisabled] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [isRtl, setIsRtl] = useState(false),
        [isClearable, setIsClearable] = useState(true),
        [isSearchable, setIsSearchable] = useState(true),
        [isModalOpen, setModalOpen] = useState(false),
        [selectedOption, setSelectedOption] = useState(selected),
        [activeRecord, setActiveRecord] = useState(null),
        [options, setOptions] = useState([]),
        [selectedRange, setSelectedRange] = useState([new Date(), new Date()]),
        [materialElement, setMaterialElement] = useState([])

    const subscriber = new Subscriber()

    // Component Mounts
    useEffect(() => {
        subscriber.add(
            getActiveMaterialRecord,
            60000,
            'getActiveMaterialRecord'
        )
        getActiveMaterialRecord()
    }, [])

    // Active Records Updates
    useEffect(() => {
        setMaterialElement(element(activeRecord))
    }, [activeRecord])

    // Component Unmounts
    useEffect(() => {
        return () => {
            subscriber.removeAll()
        }
    }, [])

    const onSelectChange = (option) => {
        setSelectedOption(option)
    }

    const loadSelectOptions = async () => {
        const models = await MaterialAPI.getModels()
        if (models.partModel && models.downtimeModel) {
            let material = await MaterialAPI.getMaterialRecords(
                models.partModel._id,
                deviceId
            )
            setOptions(
                material.map((mat) => {
                    return {value: mat._id, label: mat.partNumber}
                })
            )
        } else {
            console.log('Parts and Downtime Reason schema are required')
        }
    }

    const getActiveMaterialRecord = async () => {
        const models = await MaterialAPI.getModels()
        if (models.hmiModel) {
            let saved = await MaterialAPI.getActiveHmiRecord(
                models.hmiModel._id,
                deviceId
            )
            if (saved) {
                setActiveRecord(saved[0])
                setSelectedOption({
                    label: saved[0].material.partNumber,
                    value: saved[0]._id,
                })
            }
        }
    }

    const apiError = () => {
        showNotification({
            title: 'Save Error',
            message: 'This could not be saved. Try again.',
        })
    }

    const saveMaterialRecord = async (material) => {
        const models = await MaterialAPI.getModels()
        if (models.hmiModel) {
            let saved = await MaterialAPI.saveHmiRecord(
                models.hmiModel._id,
                deviceId,
                material.value
            )
            if (saved) {
                setActiveRecord(saved)
                setSelectedOption({
                    label: saved.material.partNumber,
                    value: saved._id,
                })
                setModalOpen(false)
            } else {
                apiError()
            }
        } else {
            console.log('Parts and Downtime Reason schema are required')
        }
    }

    const endMaterialRecord = async (material) => {
        const models = await MaterialAPI.getModels()
        if (models.hmiModel) {
            let saved = await MaterialAPI.editHmiRecord(
                models.hmiModel._id,
                deviceId,
                activeRecord
            )
            if (saved) {
                setActiveRecord(null)
                setSelectedOption(null)
                setModalOpen(false)
            }
        } else {
            apiError()
        }
    }

    const element = (activeRecord) => {
        if (activeRecord) {
            return (
                <Fragment>
                    <Button
                        fullwidth
                        color="blue"
                        radius="xl"
                        size="xs"
                        compact
                        onClick={() => setModalOpen(true)}>
                        <Text size="xs" color="white" style={{marginRight: 5}}>
                            Material |
                        </Text>
                        <Text size="xs" color="white">
                            {activeRecord.material.partNumber}
                        </Text>
                    </Button>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Button
                        fullwidth
                        color="gray"
                        radius="xl"
                        size="xs"
                        compact
                        onClick={() => {
                            onSelectChange(null)
                            setModalOpen(true)
                            loadSelectOptions()
                        }}>
                        No Material
                    </Button>
                </Fragment>
            )
        }
    }

    return (
        <AccumineAppWrapper style={{marginTop: 15}}>
            {materialElement}

            <Modal
                opened={isModalOpen}
                onClose={() => {
                    setModalOpen(false)
                }}
                size="lg"
                title={'Material for ' + deviceName}
                centered={true}>
                <Accordion initialItem={0}>
                    <Accordion.Item label="Choose Material">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={null}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={options}
                            onChange={onSelectChange}
                        />
                        <Button
                            disabled={activeRecord || !selectedOption}
                            style={{marginTop: 15}}
                            onClick={() => {
                                saveMaterialRecord(selectedOption)
                            }}>
                            Select material
                        </Button>
                        {'  '}
                        <Button
                            disabled={!activeRecord}
                            style={{marginTop: 15}}
                            color="red"
                            onClick={() => {
                                endMaterialRecord(selectedOption)
                            }}>
                            End material
                        </Button>
                    </Accordion.Item>
                    <Accordion.Item label="Download Excel Data">
                        <Grid>
                            <Grid.Col span={6}>
                                <RangeCalendar
                                    value={selectedRange}
                                    onChange={setSelectedRange}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Excel
                                    range={selectedRange}
                                    deviceId={deviceId}
                                    deviceName={deviceName}
                                />
                            </Grid.Col>
                        </Grid>
                    </Accordion.Item>
                </Accordion>
            </Modal>
        </AccumineAppWrapper>
    )
}

Material.propTypes = {
    /**
     * The option already selected
     */
    selected: PropTypes.any,
    /**
     * Options to be displayed in select
     */
    options: PropTypes.array.isRequired,
    /**
     * Options to be displayed in select
     */
    deviceId: PropTypes.string.isRequired,
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
}

Material.defaultProps = {
    options: [],
    deviceId: null,
    selected: null,
    onClick: undefined,
}

export default Material
