import moment from 'moment'

export default class Device {
    constructor({
        serialNumber,
        deviceId,
        model,
        command,
        lastSync,
        lastLocation,
        lastKnownLocation,
        lastMovement,
    }) {
        this.sync({
            serialNumber,
            deviceId,
            model,
            command,
            lastSync,
            lastLocation,
            lastKnownLocation,
            lastMovement,
        })
    }

    sync({
        serialNumber,
        deviceId,
        model,
        command,
        lastSync,
        lastLocation,
        lastKnownLocation,
        lastMovement,
    }) {
        this.serialNumber = serialNumber
        this.deviceId = deviceId
        this.model = model
        this.command = {
            value: command.value,
            trainCommand: {
                location: command.trainCommand.location,
            },
            timestamp: command.timestamp,
        }
        this.lastSync = lastSync
        this.lastLocation = {
            id: lastLocation.id,
            timestamp: lastLocation.timestamp,
        }
        this.lastKnownLocation = {
            id: lastKnownLocation.id,
            timestamp: lastKnownLocation.timestamp,
        }
        this.lastMovement = {
            moving: lastMovement.moving,
            timestamp: lastMovement.timestamp,
        }
    }

    status() {
        let result = {
            name: '',
            since: '',
            color: '',
        }

        const lastSync = moment().diff(moment(this.lastSync), 'seconds'),
            lastMovement = moment().diff(moment(this.lastMovement), 'seconds')

        if (lastSync >= 60) {
            // offline
            result = {
                name: 'Offline',
                since: moment(this.lastSync).fromNow(),
                color: 'default',
            }
        } else if (lastSync < 60 && lastMovement >= 180) {
            // dormant
            result = {
                name: 'Dormant',
                since: moment(this.lastMovement).fromNow(),
                color: 'danger',
            }
        } else {
            // active
            result = {
                name: 'Active',
                since: moment(this.lastMovement).fromNow(),
                color: 'success',
            }
        }
        return result
    }
}
