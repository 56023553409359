import React, {Component, Fragment} from 'react'
import {Button} from 'reactstrap'
import autobind from 'auto-bind'
import axios from 'axios'

import Subscriber from 'SDK/subscriber'

const DEFAULT_TIME_SECONDS = 120

const warningStyle = {
    position: 'fixed',
    bottom: 0,
    marginLeft: '40%',
    width: '20%',
    backgroundColor: '#b69329',
    padding: 10,
    textAlign: 'center',
    zIndex: 1000000000,
}

export default class extends Component {
    constructor(props) {
        super(props)
        autobind(this)

        this.subscriber = new Subscriber()

        this.state = {
            newVersionDetected: false,
            secondsLeft: 0,
        }
    }

    forceRefresh() {
        window.location = window.location.href
    }

    async fetchVersion() {
        try {
            const {data} = await axios.get(
                    process.env.PUBLIC_URL + '/version.json'
                ),
                current = localStorage['build_time'] || null

            if (current != data.build_time) {
                console.log('new version deployed')
                this.setState(
                    {
                        newVersionDetected: true,
                        secondsLeft: DEFAULT_TIME_SECONDS,
                    },
                    this.startCountdown
                )
                localStorage['build_time'] = data.build_time
            }
        } catch (error) {
            console.log('cannot fetch app hash')
        }
    }

    startCountdown() {
        let self = this
        this.interval = setInterval(() => {
            if (self.state.secondsLeft === 0) {
                self.forceRefresh()
            } else {
                self.setState({secondsLeft: self.state.secondsLeft - 1})
            }
        }, 1000)
    }

    stopCountdown() {
        clearInterval(this.interval)
        this.setState({
            newVersionDetected: false,
            secondsLeft: 0,
        })
    }

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            this.subscriber.add(this.fetchVersion, 1000 * 60, 'fetchVersion()')
        } else {
            console.log('not checking app hash, in dev mode...')
        }
    }

    componentWillUnmount() {
        this.subscriber.removeAll()
    }

    render() {
        return (
            <Fragment>
                {this.state.newVersionDetected ? (
                    <div style={warningStyle}>
                        <span style={{color: '#fff'}}>
                            A new version is ready. The update will
                            automatically start in {this.state.secondsLeft}{' '}
                            seconds.
                        </span>
                        <div className="row">
                            <div className="col-sm-6">
                                <Button
                                    onClick={this.forceRefresh}
                                    color="light"
                                    className="btn btn-block mt-2"
                                    style={{display: 'block'}}
                                    size="xs">
                                    Reload
                                </Button>
                            </div>
                            <div className="col-sm-6">
                                <Button
                                    onClick={this.stopCountdown}
                                    color="light"
                                    className="btn btn-block mt-2"
                                    style={{display: 'block'}}
                                    size="xs">
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </Fragment>
        )
    }
}
