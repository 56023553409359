import Moment from 'moment'
import { extendMoment } from 'moment-range'
import colors from 'nice-color-palettes'
import { getShiftTimerange } from '../../../../SDK/api/common'
import { copy } from 'dot-object'
colors.splice(12, 2)

const moment = extendMoment(Moment)


export function assignUncategorizedReasonToDowntimesWithoutAReason(assets, states) {
    let assetDowntimeEntryThresholds = {}
    for (let asset of assets) {
        assetDowntimeEntryThresholds[asset.deviceId] =
            asset.downtimeThresholdSeconds || 300
    }

    states = states.filter((row) => {
        let tE = row.timeEnd ? moment(row.timeEnd) : moment()
        if (
            row.name === 'Downtime' &&
            tE.diff(moment(row.timeStart), 'seconds') >
            assetDowntimeEntryThresholds[row.deviceId] &&
            !states.find(
                (d) =>
                    d.name === 'Downtime Reason' &&
                    d.timeStart === row.timeStart
            )
        ) {
            return true
        } else if (row.name !== 'Downtime') {
            return true
        } else {
            return false
        }
    })

    states = states.map((row) => {
        if (row.name === 'Downtime') {
            row.name = 'Downtime Reason'
            row.value = 'Uncategorized'
        }
        return row
    })
    return states;
}

export async function generateShiftData(thisState, setState, thisExportComments, apiPost) {
    const { showUncategorized } = thisState,
        states = showUncategorized
            ? ['Downtime Reason', 'Downtime Category', 'Downtime']
            : ['Downtime Reason', 'Downtime Category']
    const days = []
    for (
        let m = moment(thisState.start);
        m.isBefore(moment(thisState.end));
        m.add(1, 'days')
    ) {
        days.push(moment(m))
    }

    let statesDataFromApi = await apiPost(
        'historical/raw',
        {
            query: {
                deviceId: {
                    $in: thisState.selectedAssets.map((a) => a.value),
                },
                name: { $in: states },
                timeStart: {
                    $lte: thisState.end.toISOString(),
                },
                $or: [
                    {
                        timeEnd: { $gte: thisState.start.toISOString() },
                    },
                    {
                        timeEnd: null,
                    },
                ],
            },
        },
        2
    )

    if (showUncategorized) {
        statesDataFromApi = assignUncategorizedReasonToDowntimesWithoutAReason(thisState.assets, statesDataFromApi);
    }



    setState({
        rawData: JSON.parse(
            JSON.stringify(statesDataFromApi.filter((d) => d.name === 'Downtime Reason'))
        ),
        totalData: JSON.parse(JSON.stringify(statesDataFromApi)),
    })
    if (statesDataFromApi.length === 0) {
        setState({
            loading: false,
        })
        return alert('No data found')
    }

    let downtimeReasonStates = statesDataFromApi
        .filter((d) => d.name === 'Downtime Reason');
    downtimeReasonStates.forEach((d) => {
        d.timeStart = moment(d.timeStart)
        d.timeEnd = d.timeEnd === null ? moment() : moment(d.timeEnd)
    })

    let downtimeReasonCodes = []

    let hideCapacityAffectingCodes = thisState.hideCapacityCodes;
    if (hideCapacityAffectingCodes) {
        downtimeReasonCodes = [
            ...new Set(
                thisState.reasonCodes
                    .filter((code) => !code.offsetCapacity)
                    .map((code) => code.reason)
            ),
        ]
        downtimeReasonStates = downtimeReasonStates.filter((d) => downtimeReasonCodes.find((c) => c === d.value))
    } else {
        downtimeReasonCodes = [
            ...new Set(thisState.reasonCodes.map((code) => code.reason)),
        ]
    }

    for (let shift of thisState.shifts) {
        if (!hideCapacityAffectingCodes) {
            downtimeReasonCodes = downtimeReasonCodes.concat(shift.breaks.map(x => x.name))
        }
        downtimeReasonCodes = [...new Set(downtimeReasonCodes)]
    }
    
    let downtimesGroupedByShiftAndCodeAndDate = []
    for (let shift of thisState.shifts) {

        let shiftDowntimesGroupedByCodeAndDate = {
            shiftName: shift.name,
            data: [],
        }

        for (let code of downtimeReasonCodes) {
            let downtimesForCodeGroupedByDate = {
                field: code,
                data: [],
            }
            for (let d of days) {
                downtimesForCodeGroupedByDate.data.push({
                    date: moment(d),
                    totalMs: 0,
                    totalCount: 0,
                })
            }
            shiftDowntimesGroupedByCodeAndDate.data.push(downtimesForCodeGroupedByDate)
        }
        downtimesGroupedByShiftAndCodeAndDate.push(shiftDowntimesGroupedByCodeAndDate)
    }

    let rawData = []

    let shifts = [...new Set(thisState.shifts.map((s) => s.name))]
    for (let day of days) {
        for (let device of thisState.selectedAssets) {
            for (let shift of shifts) {
                const shiftRecord = thisState.shifts.find(
                    (s) =>
                        s.name === shift &&
                        s.assets.find((a) => a === device.value)
                )
                if (shiftRecord) {
                    if (
                        shiftRecord.days.find((d) => d === day.day()) !==
                        undefined
                    ) {
                        const shiftTimerange = getShiftTimerange(
                            day,
                            shiftRecord,
                            false
                        )
                        if (shiftTimerange) {
                            let breaksAsDowntimeReasonStates = shiftRecord.breaks
                                .map((shiftBreak) => {
                                    let breakAsDowntimeReason = {
                                        timeStart: null,
                                        timeEnd: null,
                                        name: 'Downtime Reason',
                                        value: shiftBreak.name,
                                        deviceId: device.value,
                                        scheduled: true,
                                        range: null,
                                    }
                                    if (
                                        shiftBreak.timeStart.hour <
                                        shiftRecord.timeStart.hour
                                    ) {
                                        breakAsDowntimeReason.timeStart = moment(
                                            shiftTimerange[0]
                                        )
                                            .add(1, 'days')
                                            .hour(shiftBreak.timeStart.hour)
                                            .minute(shiftBreak.timeStart.minute)
                                    } else {
                                        breakAsDowntimeReason.timeStart = moment(
                                            shiftTimerange[0]
                                        )
                                            .hour(shiftBreak.timeStart.hour)
                                            .minute(shiftBreak.timeStart.minute)
                                    }
                                    if (
                                        shiftBreak.timeEnd.hour <
                                        shiftRecord.timeStart.hour
                                    ) {
                                        breakAsDowntimeReason.timeEnd = moment(
                                            shiftTimerange[0]
                                        )
                                            .add(1, 'days')
                                            .hour(shiftBreak.timeEnd.hour)
                                            .minute(shiftBreak.timeEnd.minute)
                                    } else {
                                        breakAsDowntimeReason.timeEnd = moment(
                                            shiftTimerange[0]
                                        )
                                            .hour(shiftBreak.timeEnd.hour)
                                            .minute(shiftBreak.timeEnd.minute)
                                    }
                                    breakAsDowntimeReason.range = moment.range(
                                        moment(breakAsDowntimeReason.timeStart),
                                        moment(breakAsDowntimeReason.timeEnd)
                                    )
                                    return breakAsDowntimeReason
                                })
                                .filter((a) => a.timeEnd.isBefore(moment()))
                            downtimeReasonStates = downtimeReasonStates.concat(breaksAsDowntimeReasonStates)

                            let allRelevantRows = downtimeReasonStates.filter((row) => {
                                return (
                                    row.deviceId === device.value &&
                                    row.timeEnd.isAfter(
                                        shiftTimerange[0]
                                    ) &&
                                    row.timeStart.isBefore(
                                        shiftTimerange[1]
                                    )
                                )
                            })
                            allRelevantRows = allRelevantRows.map((row) => {
                                let timeStart = moment(row.timeStart),
                                    timeEnd = moment(row.timeEnd)
                                if (shiftTimerange[0].isAfter(timeStart)) {
                                    timeStart = moment(shiftTimerange[0])
                                }
                                if (timeEnd.isAfter(shiftTimerange[1])) {
                                    timeEnd = moment(shiftTimerange[1])
                                }
                                row.duration = timeEnd.diff(timeStart)
                                row.shift = shift

                                if (!row.scheduled) {
                                    let breaks = allRelevantRows.filter(
                                        (a) =>
                                            a.scheduled &&
                                            a.deviceId === device.value
                                    )

                                    const range = moment.range(
                                        moment(timeStart),
                                        moment(timeEnd)
                                    )
                                    for (let b of breaks) {
                                        if (range.overlaps(b.range)) {
                                            row.duration -= range
                                                .intersect(b.range)
                                                .valueOf()
                                        }
                                    }
                                }

                                return row
                            })

                            for (let code of downtimeReasonCodes) {
                                let relevantRows = allRelevantRows.filter(
                                    (row) => {
                                        return row.value === code
                                    }
                                )
                                rawData = rawData.concat(relevantRows)
                                relevantRows.forEach((row) => {
                                    const shiftObject = downtimesGroupedByShiftAndCodeAndDate.find(
                                        (s) => s.shiftName === shift
                                    )
                                    if (shiftObject) {
                                        const codeObject =
                                            shiftObject.data.find(
                                                (c) => c.field === code
                                            )
                                        if (codeObject) {
                                            let dayObject =
                                                codeObject.data.find((d) =>
                                                    d.date.isSame(day)
                                                )
                                            if (dayObject) {
                                                dayObject.totalMs +=
                                                    row.duration
                                                dayObject.totalCount++
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    let unitString = '',
        downtimeTotalsByReasonCode = []

    for (let shift of downtimesGroupedByShiftAndCodeAndDate) {
        for (let code of shift.data) {
            if (!downtimeTotalsByReasonCode.find((t) => t.field === code.field)) {
                downtimeTotalsByReasonCode.push({ field: code.field, value: 0 })
            }
            if (thisState.unit.value === 'totalDuration') {
                unitString = 'total hrs'
                const v =
                    Math.round(
                        (code.data.reduce((a, b) => a + b.totalMs, 0) /
                            (1000 * 60 * 60)) *
                        100
                    ) / 100
                code.value = v
                downtimeTotalsByReasonCode.find((t) => t.field === code.field).value += v
            }
            if (thisState.unit.value === 'averageDuration') {
                unitString = 'avg. hrs'
                let v =
                    code.data.reduce((a, b) => a + b.totalMs, 0) /
                    code.data.reduce((a, b) => a + b.totalCount, 0)
                v = Math.round((v / (1000 * 60 * 60)) * 100) / 100
                code.value = isNaN(v) ? 0 : v
                downtimeTotalsByReasonCode.find((t) => t.field === code.field).value += isNaN(v)
                    ? 0
                    : v
            }
            if (thisState.unit.value === 'totalOccurances') {
                unitString = 'occurrences'
                const v = code.data.reduce((a, b) => a + b.totalCount, 0)
                code.value = isNaN(v) ? 0 : v
                downtimeTotalsByReasonCode.find((t) => t.field === code.field).value += isNaN(v)
                    ? 0
                    : v
            }
        }
    }
    downtimeTotalsByReasonCode = downtimeTotalsByReasonCode
        .filter((a) => a.value > 0)
        .sort((a, b) => b.value - a.value)

    let cumulative = calculateCumulativeLineGraphDatasetFromDowntimeTotals(downtimeTotalsByReasonCode)

    let datasets = []

    for (let i in downtimesGroupedByShiftAndCodeAndDate) {
        let dataset = {
            yAxisID: 'a',
            type: 'bar',
            label: downtimesGroupedByShiftAndCodeAndDate[i].shiftName,
            data: [],
            backgroundColor: colors[i][0],
            shift: true,
        }

        for (let t of downtimeTotalsByReasonCode) {
            downtimesGroupedByShiftAndCodeAndDate[i].data.find((c) => c.field === t.field)
                ? dataset.data.push(
                    downtimesGroupedByShiftAndCodeAndDate[i].data.find((c) => c.field === t.field)
                        .value
                )
                : null
        }

        datasets.push(dataset)
    }

    let chartdata = {
        labels: downtimeTotalsByReasonCode.map((a) => a.field),
        datasets: [
            {
                yAxisID: 'b',
                type: 'line',
                label: 'Cumulative',
                data: cumulative,
                borderColor: '#2a93d5',
                backgroundColor: 'transparent',
            },
            ...datasets,
        ],
    }

    await thisExportComments()
    setState({
        data: { chartdata: chartdata, hours: true },
        ready: true,
        loading: false,
        unitString,
    })
    return chartdata
}

function calculateCumulativeLineGraphDatasetFromDowntimeTotals(totals) {
    let total = totals.reduce((a, b) => a + b.value, 0)

    let cumulative = totals.length ? [(totals[0].value / total) * 100] : [100]

    for (let i = 1; i < totals.length; i++) {
        cumulative.push(cumulative[i - 1] + (totals[i].value / total) * 100)
    }

    if (cumulative.length) cumulative[cumulative.length - 1] = 100
    return cumulative
}
