const flattenVariables = (variables = []) => {
    let flat = variables
        .filter((v) => v.type !== 'Record' && v.type !== 'Report')
        .map((v) => {
            return {
                id: v.id,
                display: v.name,
            }
        })
    for (let v of variables.filter((v) => v.type === 'Record')) {
        for (let field of v.fields) {
            flat.push({
                id: `${v.id}.${field.path}`,
                display: `${v.name}.${field.path}`,
            })
        }
    }
    for (let v of variables.filter((v) => v.type === 'Report')) {
        for (let field of v.fields) {
            flat.push({
                id: `${v.id}.${field.name}`,
                display: `${v.name}.${field.alias || field.name}`,
            })
        }
    }
    return flat
}

export default flattenVariables
